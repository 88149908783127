define("ember-svg-jar/inlined/death-rip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>death-rip</title><path d=\"M16.625 7.859H17a.875.875 0 000-1.75h-.375a.5.5 0 00-.5.5v.75a.5.5 0 00.5.5zM7.625 7.859H8a.875.875 0 000-1.75h-.375a.5.5 0 00-.5.5v.75a.5.5 0 00.5.5z\"/><path d=\"M3.659 18.287a5.775 5.775 0 011.841-.308c.13 0 .26.005.389.013a.5.5 0 00.467-.251 6.514 6.514 0 0111.288 0 .5.5 0 00.467.251 6.29 6.29 0 01.389-.013 5.775 5.775 0 011.841.308.5.5 0 00.659-.474V5.479c0-3.75-4.666-5.458-9-5.458S3 1.729 3 5.479v12.334a.5.5 0 00.659.474zm11.216-9.8v-3a.625.625 0 01.625-.625H17a2.125 2.125 0 010 4.25h-.375a.5.5 0 00-.5.5v1.875a.625.625 0 11-1.25 0zM11.5 6.109a.625.625 0 010-1.25h2a.625.625 0 010 1.25.375.375 0 00-.375.375v4a.375.375 0 00.375.375.625.625 0 010 1.25h-2a.625.625 0 010-1.25.375.375 0 00.375-.375v-4a.375.375 0 00-.375-.375zM5.875 8.484v-3a.625.625 0 01.625-.625H8a2.125 2.125 0 01.49 4.192.25.25 0 00-.183.175.253.253 0 00.064.245l1.571 1.571a.625.625 0 01-.884.884l-1.506-1.507a.25.25 0 00-.427.177v.888a.625.625 0 11-1.25 0z\"/><path d=\"M18.5 19.479a4.47 4.47 0 00-1.177.157.5.5 0 01-.6-.318 5.006 5.006 0 00-9.44 0 .5.5 0 01-.6.318 4.5 4.5 0 00-5.642 3.781.5.5 0 00.121.393.5.5 0 00.375.169h20.932a.5.5 0 00.375-.169.5.5 0 00.121-.393 4.5 4.5 0 00-4.465-3.938z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});