define("ember-svg-jar/inlined/duck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>duck</title><path d=\"M10.737 13.285a.75.75 0 00-.948.475l-.5 1.5a.751.751 0 00.474.949.778.778 0 00.237.038.748.748 0 00.711-.513l.5-1.5a.751.751 0 00-.474-.949zM14.211 13.76a.749.749 0 10-1.422.474l.5 1.5a.748.748 0 00.711.513.778.778 0 00.237-.038.751.751 0 00.474-.949z\"/><path d=\"M20.388 15.441a10 10 0 10-16.777 0 3.5 3.5 0 00-.569 4.186C4.493 22.529 7.506 24 12 24s7.507-1.471 8.958-4.372a3.485 3.485 0 00-.57-4.187zM14.75 5.5a1.25 1.25 0 11-1.25 1.25 1.249 1.249 0 011.25-1.25zm-5.5 0A1.25 1.25 0 118 6.75 1.249 1.249 0 019.25 5.5zm9.919 13.233C18.07 20.932 15.725 22 12 22s-6.07-1.068-7.169-3.267c-.508-1.016-.185-1.324.409-2.13a4.052 4.052 0 00.97-2.515 5.178 5.178 0 00-.654-2.362c-.405-.83-.429-.878.278-1.445.1-.078.177-.135.24-.177A3.215 3.215 0 009 12a2.914 2.914 0 002.338-1.1c.306-.328.38-.4.662-.4s.356.069.662.4A2.914 2.914 0 0015 12a3.214 3.214 0 002.926-1.9c.063.042.142.1.239.177.708.567.684.615.279 1.445a5.178 5.178 0 00-.654 2.362 4.052 4.052 0 00.97 2.515c.577.786.919 1.114.409 2.134z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});