define("ember-svg-jar/inlined/database-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-search-alternate</title><path d=\"M10.413 21.055a1 1 0 00-.692-.358c-4.3-.3-6.971-1.846-6.971-2.944v-.789a.249.249 0 01.373-.217A14.145 14.145 0 007.641 18.2a.989.989 0 001.136-1.07v-.024a.958.958 0 00-.794-.862c-3.267-.535-5.23-1.8-5.23-2.741v-.789a.249.249 0 01.373-.217 15.049 15.049 0 005.324 1.577.959.959 0 00.983-.538l.014-.029a.983.983 0 00-.77-1.41c-3.686-.459-5.927-1.838-5.927-2.844v-.789a.25.25 0 01.373-.218A17.574 17.574 0 0011.251 10c.848 0 5.19-.1 8.126-1.756a.254.254 0 01.249 0 .25.25 0 01.124.216v1.84a.981.981 0 00.469.829l.026.015a.958.958 0 001.459-.84 80.087 80.087 0 01.047-5.3c0-3.248-5.41-5-10.5-5S.763 1.751.752 4.993L.75 5v12.75c0 2.76 4.05 4.615 8.891 4.95a.976.976 0 00.81-1.6zM11.251 2c5.185 0 8.495 1.773 8.5 3v.009C19.74 6.232 16.432 8 11.251 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M20.473 19.811a.25.25 0 01-.034-.311 5.536 5.536 0 10-1.691 1.692.25.25 0 01.311.034l2.484 2.484a1 1 0 001.414-1.41zm-8.223-3.279a3.529 3.529 0 113.529 3.529 3.533 3.533 0 01-3.529-3.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});