define("ember-svg-jar/inlined/business-shark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-shark</title><path d=\"M4.812 13.76a.156.156 0 00.135.078 2.5 2.5 0 012.125 1.178 2.115 2.115 0 001.488.874 2.8 2.8 0 001.889-1.017 2.5 2.5 0 014.286.389 1.281 1.281 0 001.051.628 1.889 1.889 0 001.449-.8 2.445 2.445 0 01.173-.26 20.034 20.034 0 012.978-9.461.5.5 0 00-.406-.792C11.833 4.576 7.228 9.52 4.814 13.6a.158.158 0 00-.002.16z\"/><path d=\"M22.931 17.379a3.407 3.407 0 01-2.677-1.569 1.043 1.043 0 00-1.719.028 3.357 3.357 0 01-2.749 1.552 2.8 2.8 0 01-2.4-1.483 1.043 1.043 0 00-1.716-.155 4.306 4.306 0 01-3.1 1.638 3.566 3.566 0 01-2.77-1.58 1.041 1.041 0 00-1.635-.094c-.015.018-1.433 1.823-3.112 1.705A.988.988 0 000 18.367a1 1 0 00.947 1.052c.074 0 .148.006.221.006a5.708 5.708 0 003.559-1.463.249.249 0 01.328.007 5.185 5.185 0 003.5 1.421 5.792 5.792 0 003.645-1.359.249.249 0 01.332.016 4.548 4.548 0 003.258 1.343 5.118 5.118 0 003.455-1.342.248.248 0 01.334 0 4.7 4.7 0 003.494 1.325 1 1 0 10-.138-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});