define("ember-svg-jar/inlined/travel-user-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-user-pin</title><path d=\"M19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.752 1.752 0 0119 17.75zM18.25 5.25a.25.25 0 01-.25.25h-1.25a.75.75 0 00-.53 1.28l2.5 2.5a.748.748 0 001.06 0l2.5-2.5a.75.75 0 00-.53-1.28H20.5a.25.25 0 01-.25-.25 4 4 0 00-4-4h-4.5a1 1 0 000 2h4.5a2 2 0 012 2z\"/><circle cx=\"5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M5 8a5.006 5.006 0 00-5 5v4a.5.5 0 00.5.5h1.306a.251.251 0 01.249.231l.445 5.807A.5.5 0 003 24h4a.5.5 0 00.5-.462l.446-5.807a.251.251 0 01.249-.231H9.5a.5.5 0 00.5-.5v-4a5.006 5.006 0 00-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});