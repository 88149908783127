define("ember-svg-jar/inlined/microphone-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-alternate</title><path d=\"M18 6.25a6 6 0 00-12 0V11a.5.5 0 00.5.5h11a.5.5 0 00.5-.5zM12 18a6 6 0 005.77-4.364.5.5 0 00-.481-.636H6.711a.5.5 0 00-.481.636A6 6 0 0012 18z\"/><path d=\"M21 8.25a1 1 0 00-1 1v2.25a7.915 7.915 0 01-8 8 7.915 7.915 0 01-8-8V9.25a1 1 0 00-2 0v2.25a9.878 9.878 0 008.779 9.929.25.25 0 01.221.248v1.073a1 1 0 002 0v-1.073a.251.251 0 01.221-.248A9.878 9.878 0 0022 11.5V9.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});