define("ember-svg-jar/inlined/video-game-mario-enemy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-enemy</title><path d=\"M9.319 14.45a2.5 2.5 0 002.5-2.5 2.381 2.381 0 00-.114-.725.5.5 0 00-.6-.331 18.668 18.668 0 01-3.806.568.5.5 0 00-.476.5 2.5 2.5 0 002.496 2.488z\"/><path d=\"M18.316 11.886a.468.468 0 01.506-.413c.206.018.4.027.547.027a4 4 0 003.916-4.832 4.028 4.028 0 00-3.493-3.137.5.5 0 01-.438-.5V2.5a2.5 2.5 0 00-5 0 .7.7 0 01-.315.587A4.462 4.462 0 0011.869 7a5.692 5.692 0 00.931 2.93 3.01 3.01 0 01.406 1.012 5.237 5.237 0 01.11 1.008 3.991 3.991 0 11-7.978-.05.439.439 0 00-.409-.446 15.377 15.377 0 01-3.029-.49 1 1 0 00-1.093.4.985.985 0 00.016 1.146l3.1 4.306a8.784 8.784 0 002.233 1.916c.908.575 1.214.768 1.214 3.768A1.658 1.658 0 008.788 24h5.066c1.327 0 2-.5 2-1.5a2 2 0 00-2-2h-.37a.25.25 0 01-.168-.435 7.109 7.109 0 011.1-.8 5.982 5.982 0 001.945-1.743.5.5 0 01.639-.146l.727.382a1.751 1.751 0 002.639-1.508 1.738 1.738 0 00-1.076-1.613l-1.062-.537a.5.5 0 01-.258-.583 9.882 9.882 0 00.346-1.631zM17.6 6.5a.75.75 0 01-1.5 0V5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});