define("ember-svg-jar/inlined/conversation-smile-type-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-smile-type-1</title><path d=\"M9.624 16.951c0-3.763 3.554-6.825 7.923-6.825a8.624 8.624 0 015.355 1.8.245.245 0 00.39-.136 8.073 8.073 0 00.218-1.838c0-5.4-5.273-9.8-11.755-9.8S0 4.554 0 9.955A8.859 8.859 0 002.709 16.2l-1.391 4.636a.49.49 0 00.669.588l5.358-2.388a13.356 13.356 0 002.5.577.245.245 0 00.258-.338 5.943 5.943 0 01-.479-2.324zM9.061 4.739V6.7a.735.735 0 01-1.469 0V4.739a.735.735 0 111.469 0zM8.9 13.465a6.566 6.566 0 01-3.544-1.84.734.734 0 111.044-1.038 5.115 5.115 0 002.755 1.43.735.735 0 01-.25 1.448zM14.939 6.7a.735.735 0 01-1.47 0V4.739a.735.735 0 111.47 0z\"/><path d=\"M17.547 11.6c-3.559 0-6.454 2.4-6.454 5.355 0 3.45 3.993 6.192 8.283 5.126l3.424 1.708a.49.49 0 00.689-.579l-.882-2.938A4.81 4.81 0 0024 16.951C24 14 21.105 11.6 17.547 11.6zm1.065 5.388a.98.98 0 11.98.979.98.98 0 01-.98-.983zm-3.918 0a.98.98 0 11.979.979.979.979 0 01-.979-.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});