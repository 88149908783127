define("ember-svg-jar/inlined/data-file-bars-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-check</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.566 12.3a.25.25 0 00.434-.172V10.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.229a.325.325 0 00.156.271 8.012 8.012 0 011.41 1.3zM0 17.5A6.5 6.5 0 106.5 11 6.508 6.508 0 000 17.5zm9.624-2.346a.751.751 0 01.149 1.046l-2.9 3.874a1.494 1.494 0 01-1.1.6h-.106a1.5 1.5 0 01-1.061-.442l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.25.25 0 00.377-.027l2.732-3.643a.75.75 0 011.048-.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});