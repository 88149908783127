define("ember-svg-jar/inlined/folder-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-sync</title><path d=\"M17.5 9.5a7.936 7.936 0 013.154.65.244.244 0 00.346-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.606c.318 0 .394-.338.394-.5a8.009 8.009 0 018-8zM22.77 18.6a1 1 0 00-1.255.578 4.074 4.074 0 01-1.277 1.932 4.163 4.163 0 01-5.658-.469.256.256 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.009 6.086 6.086 0 0010.211-2.2 1 1 0 00-.622-1.268z\"/><path d=\"M23.691 11.772a.5.5 0 00-.545.109l-1.03 1.031a.244.244 0 01-.352-.008 6.1 6.1 0 00-10.2 2.225 1 1 0 001.879.686 4.054 4.054 0 011.276-1.915 4.153 4.153 0 015.637.435.255.255 0 01-.012.35l-1.655 1.655a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.459a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});