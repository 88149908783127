define("ember-svg-jar/inlined/music-genre-smoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-smoke</title><path d=\"M22.477 9.324a.5.5 0 01-.275-.385 10.291 10.291 0 00-20.4 0 .5.5 0 01-.275.384 2.709 2.709 0 00-1.517 2.43v2.933a2.709 2.709 0 002.705 2.7h.664a.5.5 0 01.439.261 9.326 9.326 0 0010.407 4.61 1 1 0 00-.477-1.942 7.31 7.31 0 115.568-7.1 1 1 0 00.166.553.5.5 0 01.084.276v2.593a.75.75 0 00.75.75h.977a2.709 2.709 0 002.7-2.7v-2.934a2.707 2.707 0 00-1.516-2.429zM12 3.91a9.3 9.3 0 00-7.236 3.458.25.25 0 01-.425-.254 8.3 8.3 0 0115.322 0 .25.25 0 01-.425.254A9.3 9.3 0 0012 3.91z\"/><circle cx=\"8.333\" cy=\"11.753\" r=\"1.222\"/><circle cx=\"15.471\" cy=\"12.286\" r=\"1.222\"/><path d=\"M21.9 22.491a1.976 1.976 0 00-.427-2.871l-4.184-2.853a.5.5 0 01-.155-.658 5.772 5.772 0 00.266-.534 1 1 0 10-1.832-.8 3.89 3.89 0 01-7.13 0 1 1 0 10-1.832.8 5.891 5.891 0 006.863 3.347.5.5 0 01.477.13l4.4 4.374a1.978 1.978 0 002.938-.168z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});