define("ember-svg-jar/inlined/currency-euro-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-decrease</title><path d=\"M16.96 0a7 7 0 107 7 7.009 7.009 0 00-7-7zm1.089 6.216a.75.75 0 010 1.5h-2.035a.25.25 0 00-.227.354 3.631 3.631 0 003.351 1.957.75.75 0 010 1.5 5 5 0 01-4.981-3.619.25.25 0 00-.243-.192h-.221a.75.75 0 010-1.5h.221a.25.25 0 00.243-.193A5 5 0 0119.138 2.4a.75.75 0 010 1.5 3.632 3.632 0 00-3.351 1.958.25.25 0 00.227.354zM.46 12.685a1.25 1.25 0 011.66-1.87l7.155 6.36a.25.25 0 00.322.009l2.9-2.324a2.163 2.163 0 012.8.1l5.047 4.653a.249.249 0 00.346-.007l1.462-1.462a.5.5 0 01.362-.144.513.513 0 01.192.038.5.5 0 01.308.462v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.353-.854l1.415-1.414a.25.25 0 00-.008-.361l-4.582-4.225a.25.25 0 00-.326-.012l-2.9 2.317a2.152 2.152 0 01-2.771-.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});