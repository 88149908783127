define("ember-svg-jar/inlined/wifi-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-search</title><path d=\"M9.735 6.393a7.686 7.686 0 015.47 2.267 1 1 0 001.414-1.414 9.669 9.669 0 00-6.884-2.853A9.671 9.671 0 002.85 7.246 1 1 0 004.264 8.66a7.685 7.685 0 015.469-2.267z\"/><path d=\"M17.675 5.535a1 1 0 001.414-1.414 13.252 13.252 0 00-18.712 0 1 1 0 001.415 1.414 11.248 11.248 0 0115.883 0zM5.32 10.392a1 1 0 101.414 1.414 4.244 4.244 0 014.8-.832.963.963 0 00.973-.135l.056-.041a.968.968 0 00-.12-1.675 6.467 6.467 0 00-7.123 1.269zM21.164 19.581a.251.251 0 01-.035-.311 5.406 5.406 0 10-1.69 1.69.25.25 0 01.31.035l2.459 2.458a1 1 0 101.415-1.414zm-1.2-3.182a3.394 3.394 0 11-3.393-3.393 3.4 3.4 0 013.391 3.394z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});