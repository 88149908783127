define("ember-svg-jar/inlined/party-decoration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-decoration</title><path d=\"M0 1.75c0 .1.9 6.081.9 6.081a1 1 0 001.74.514l3.331-3.782a.5.5 0 01.517-.148l1.3.384a.5.5 0 01.354.41l.691 4.957a1 1 0 001.735.531l3.391-3.778a.5.5 0 01.514-.145l.976.288a.5.5 0 01.353.41l.685 4.909a1 1 0 001.735.53l3.357-3.74a.5.5 0 01.514-.146l.624.184A.96.96 0 0023 9.25a1 1 0 00.283-1.959l-22-6.5A1 1 0 000 1.75zM24 14.751a1.088 1.088 0 00-.006-.114 1 1 0 00-1.107-.88l-22 2.5A1 1 0 001 18.25a1.093 1.093 0 00.115-.006l.307-.035a.5.5 0 01.489.244l2.511 4.3a1.007 1.007 0 00.868.5h.09a1.006 1.006 0 00.848-.662l1.717-4.841a.5.5 0 01.414-.33l.951-.108a.5.5 0 01.488.245l2.522 4.32a1 1 0 00.867.5h.091a1.006 1.006 0 00.848-.662l1.728-4.857a.5.5 0 01.414-.33l.868-.1a.5.5 0 01.488.245L20.1 20.9a1 1 0 00.862.5h.093a1 1 0 00.851-.66l2.068-5.817a.49.49 0 00.026-.172z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});