define("ember-svg-jar/inlined/chef-gear-mug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-mug</title><path d=\"M21.25 14.5a5.506 5.506 0 00-5.5-5.5.5.5 0 01-.5-.5V5.661a3.761 3.761 0 00-3.416-3.531A3.615 3.615 0 008.387 0a3.638 3.638 0 00-3.725 2.921 3.041 3.041 0 00-1.912 2.74c0 .839.435 2.039 1.331 2.039H4.4a.5.5 0 01.473.337 6.37 6.37 0 01-.859 5.784A6.614 6.614 0 002.75 17.7a6.686 6.686 0 002.433 5.142l.691.575a2.505 2.505 0 001.6.579h4.552a2.505 2.505 0 001.6-.579l.691-.575a6.753 6.753 0 002.033-2.879 5.508 5.508 0 004.9-5.463zM5.819 4.638a1 1 0 00.79-1.019A1.6 1.6 0 018.387 2a1.635 1.635 0 011.743 1.309 1.067 1.067 0 001.034.762 1.915 1.915 0 012.086 1.59v6.732c0 .261-.364.553-.852.553s-.851-.292-.851-.553V8.768a2.927 2.927 0 00-1.08-2.231A3.715 3.715 0 008.078 5.7h-3.03a.25.25 0 01-.209-.114.246.246 0 01-.019-.238 1.417 1.417 0 01.999-.71zm11.653 12.908a.5.5 0 01-.745-.394 7.342 7.342 0 00-1.507-3.737.5.5 0 01-.056-.4 6.992 6.992 0 00.086-1.515.5.5 0 01.5-.5 3.5 3.5 0 011.722 6.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});