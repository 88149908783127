define("ember-svg-jar/inlined/renewable-energy-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-earth</title><path d=\"M9.481 6.192c2.33-1.5 5.949-3.128 8.644-2.538a.6.6 0 01-.258 1.181 8.47 8.47 0 00-4.593.749.5.5 0 00-.293.606c.492 1.652 2.286 2.189 4 1.96 2.09-.279 5.334-2.971 6-5.6a.606.606 0 00-.567-.753A7.858 7.858 0 0119.194.9a8.4 8.4 0 00-2.8-.875 3.305 3.305 0 00-2.949 1.017 2.647 2.647 0 00-.562 1.743.5.5 0 01-.319.471A20.676 20.676 0 008.8 5.191a.6.6 0 00.678 1zM23.5 11.318a1.009 1.009 0 00-1 0l-4.109 2.36a.251.251 0 01-.375-.217V12.2a1 1 0 00-1.49-.872l-4.155 2.338a.25.25 0 01-.371-.219v-1.206a1 1 0 00-1.545-.839L6.9 13.714a.248.248 0 01-.232.021.252.252 0 01-.149-.18L4.905 5.8a1 1 0 00-.979-.8H2.372a1 1 0 00-.99.86L.058 15.29a5.964 5.964 0 00-.058.834V23a1 1 0 001 1h22a1 1 0 001-1V12.184a1 1 0 00-.5-.866z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});