define("ember-svg-jar/inlined/messages-bubble-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-star-alternate</title><path d=\"M10.079 18.6a3.649 3.649 0 01-.717-.99.251.251 0 00-.168-.133c-.2-.046-.4-.094-.593-.151a1 1 0 00-.661.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.37 6.37 0 01-.545 2.554.25.25 0 00.2.349 47.89 47.89 0 011.529.182.247.247 0 00.27-.159A8.262 8.262 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483a14.049 14.049 0 002.214.392.25.25 0 00.2-.428z\"/><path d=\"M20.734 23.827a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45l-1.3-2.981 2.348-2.092a1.264 1.264 0 00.3-1.378 1.234 1.234 0 00-1.165-.76h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028L14.922 15h-2.666a1.23 1.23 0 00-1.166.76 1.27 1.27 0 00.32 1.4l2.329 2.076-1.295 2.98a1.253 1.253 0 00.314 1.451 1.286 1.286 0 001.506.166l3.236-1.827z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});