define("ember-svg-jar/inlined/vegetables-broccoli", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-broccoli</title><path d=\"M14.691 14.746a8.043 8.043 0 00-5.421-5.4.5.5 0 00-.625.267C6.518 14.4 2.935 17.381.391 19.962a1.023 1.023 0 00-.187 1.4L2.637 23.8a1.022 1.022 0 001.4-.187c2.585-2.548 5.572-6.139 10.375-8.264a.5.5 0 00.279-.603z\"/><path d=\"M22.63 9.514a4.512 4.512 0 00-1.167-.839.5.5 0 01-.276-.465 5.195 5.195 0 00-5.394-5.4.5.5 0 01-.466-.275 4.491 4.491 0 00-.838-1.167 4.687 4.687 0 00-6.629 0 4.534 4.534 0 00-1 1.518.5.5 0 01-.379.306A5.02 5.02 0 003.79 4.7a5.209 5.209 0 000 7.369 4.346 4.346 0 00.782.591.5.5 0 00.667-.134 19.931 19.931 0 001.88-3.183.5.5 0 00-.274-.68 3.569 3.569 0 01-.329-.145.75.75 0 11.676-1.339 2.171 2.171 0 002.759-.776.75.75 0 111.272.794 3.731 3.731 0 01-.35.473.5.5 0 00.161.78 9.261 9.261 0 014.566 4.469.5.5 0 00.776.17 3.76 3.76 0 01.425-.31.75.75 0 01.794 1.273 2.128 2.128 0 00-.776 2.76.75.75 0 01-.333 1.007.738.738 0 01-.336.08.75.75 0 01-.671-.413 3.429 3.429 0 01-.144-.328.5.5 0 00-.68-.274 19.928 19.928 0 00-3.183 1.879.5.5 0 00-.134.667 4.355 4.355 0 00.591.783 5.21 5.21 0 007.368 0 5.023 5.023 0 001.507-2.689.5.5 0 01.306-.379 4.534 4.534 0 001.518-1 4.694 4.694 0 00.002-6.631z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});