define("ember-svg-jar/inlined/power-button-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-button-1-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.014 12.014 0 0012 0zm0 21a9 9 0 119-9 9.01 9.01 0 01-9 9z\"/><path d=\"M12 5.477a1.75 1.75 0 00-1.75 1.75V16.3a1.75 1.75 0 003.5 0V7.227A1.749 1.749 0 0012 5.477z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});