define("ember-svg-jar/inlined/ui-scroll-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-scroll-down</title><path d=\"M23 1a1 1 0 00-1-1H2a1 1 0 00-1 1v21.5A1.5 1.5 0 002.5 24h19a1.5 1.5 0 001.5-1.5zm-8.55 19.6l-2 1.5a.75.75 0 01-.9 0l-2-1.5a.75.75 0 01.9-1.2l1.249.937a.5.5 0 00.6 0l1.25-.938a.75.75 0 11.9 1.2zM14 15.25H5.5a.75.75 0 010-1.5H14a.75.75 0 010 1.5zm4.5-5h-13a.75.75 0 010-1.5h13a.75.75 0 010 1.5zm0-5h-13a.75.75 0 010-1.5h13a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});