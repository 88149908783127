define("ember-svg-jar/inlined/designer-community-dribbble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-dribbble</title><path d=\"M24 12A12 12 0 006.28 1.45h-.05A12.09 12.09 0 000 12a11.94 11.94 0 002.71 7.58A12 12 0 0024 12zm-3.93-5.88A9.93 9.93 0 0122 11.65c-.88-.09-1.82-.14-2.62-.14a24.12 24.12 0 00-4.17.38c-.27-.69-.56-1.37-.89-2a23.88 23.88 0 005.75-3.77zm-1.33-1.48a21.88 21.88 0 01-5.42 3.47 23.94 23.94 0 00-4.67-5.52 9.9 9.9 0 0110.09 2zM6.68 3.55a22 22 0 014.75 5.3A22 22 0 014.35 10c-.71 0-1.42 0-2.13-.11a10 10 0 014.46-6.34zM2 11.92c.78.08 1.56.13 2.34.13a23.93 23.93 0 008.09-1.42q.43.84.78 1.71a23.78 23.78 0 00-9.59 5.11A9.9 9.9 0 012 11.92zM4.87 19a21.79 21.79 0 019-4.78 22.1 22.1 0 01.94 6.39v1A9.94 9.94 0 014.87 19zm12 1.76v-.15a24.13 24.13 0 00-1-6.8 22.1 22.1 0 013.5-.3c.77 0 1.66.05 2.49.14a10 10 0 01-5.04 7.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});