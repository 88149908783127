define("ember-svg-jar/inlined/video-game-wii", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-wii</title><circle cx=\"15.5\" cy=\"8.75\" r=\"1.75\"/><path d=\"M11.03 7.47a.75.75 0 00-1.06 1.06l2.116 2.116a.5.5 0 010 .708L7.47 15.97a.75.75 0 101.06 1.06l1.626-1.63a.505.505 0 01.4-.144 2.506 2.506 0 012.216 1.428.751.751 0 00.726.562.763.763 0 00.188-.024.75.75 0 00.538-.914 3.416 3.416 0 00-1.933-2.1.5.5 0 01-.139-.806l.992-.992a.5.5 0 01.708 0l2.118 2.12a.75.75 0 001.06-1.06z\"/><path d=\"M22.5 4h-9a.5.5 0 010-1h1a1.5 1.5 0 000-3h-5a1.5 1.5 0 000 3h1a.5.5 0 010 1h-9A1.5 1.5 0 000 5.5V20a1.5 1.5 0 001.328 1.489.251.251 0 01.2.138.248.248 0 01-.01.239l-.371.619a1 1 0 101.714 1.03l1.064-1.772a.5.5 0 01.428-.243h15.3a.5.5 0 01.428.243l1.064 1.772a1 1 0 001.714-1.03l-.371-.619a.248.248 0 01-.01-.239.251.251 0 01.2-.138A1.5 1.5 0 0024 20V5.5A1.5 1.5 0 0022.5 4zM22 18a.5.5 0 01-.5.5h-19A.5.5 0 012 18V6.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});