define("ember-svg-jar/inlined/power-button-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>power-button-2-alternate</title><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm0-21a9 9 0 11-9 9 9.01 9.01 0 019-9z\"/><path d=\"M10.806 6.249h2.387a.977.977 0 01.977.977v9.545a.978.978 0 01-.978.978h-2.386a.978.978 0 01-.978-.978V7.227a.978.978 0 01.978-.978z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});