define("ember-svg-jar/inlined/kindle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kindle</title><path d=\"M21.75 15h-3a.249.249 0 00-.25.25v1.25a1 1 0 01-1 1h-11a1 1 0 01-1-1v-1.25a.249.249 0 00-.25-.25h-3a.249.249 0 00-.25.25v6.25A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5v-6.25a.249.249 0 00-.25-.25zM14 21a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM9 7.251h6a.75.75 0 000-1.5H9a.75.75 0 000 1.5zM9 10.251h6a.75.75 0 000-1.5H9a.75.75 0 000 1.5zM9 13.251h4a.75.75 0 000-1.5H9a.75.75 0 000 1.5z\"/><path d=\"M19.5 0h-15A2.5 2.5 0 002 2.5v10.75a.25.25 0 00.25.25h3a.25.25 0 00.25-.25V3.5a1 1 0 011-1h11a1 1 0 011 1v9.75a.25.25 0 00.25.25h3a.25.25 0 00.25-.25V2.5A2.5 2.5 0 0019.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});