define("ember-svg-jar/inlined/single-woman-actions-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-heart</title><path d=\"M.141 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.243.2l.921 4.85a.5.5 0 00.484.393h2.969a.5.5 0 00.485-.392l.921-4.85a.247.247 0 01.243-.2h.588a.251.251 0 00.209-.115A.247.247 0 0010 18.2a4.965 4.965 0 01.054-4.321 4.815 4.815 0 01.5-.793.249.249 0 00.043-.216c-.432-1.6-.571-2.563-1.621-3.545a.5.5 0 00-.756.1L6.482 12.16a.5.5 0 01-.837 0L3.922 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM1.834 4.206A4.206 4.206 0 106.04 0a4.211 4.211 0 00-4.206 4.206zM6.04 6.433A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.616 4.616 0 001.8-1.128.249.249 0 01.354 0 4.6 4.6 0 001.8 1.128.247.247 0 01.158.3 2.224 2.224 0 01-2.13 1.623zM12.025 18.568l5.061 5.28a.5.5 0 00.714 0l5.054-5.272a3.487 3.487 0 00.654-4.028 3.489 3.489 0 00-5.588-.907l-.3.3a.249.249 0 01-.35 0l-.3-.3a3.489 3.489 0 00-5.588.907 3.481 3.481 0 00.643 4.02z\"/><path d=\"M.141 17.931a.5.5 0 00.477.626h2.3a.248.248 0 01.243.2l.921 4.85a.5.5 0 00.484.393h2.969a.5.5 0 00.485-.392l.921-4.85a.247.247 0 01.243-.2h.588a.251.251 0 00.209-.115A.247.247 0 0010 18.2a4.965 4.965 0 01.054-4.321 4.815 4.815 0 01.5-.793.249.249 0 00.043-.216c-.432-1.6-.571-2.563-1.621-3.545a.5.5 0 00-.756.1L6.482 12.16a.5.5 0 01-.837 0L3.922 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM1.834 4.206A4.206 4.206 0 106.04 0a4.211 4.211 0 00-4.206 4.206zM6.04 6.433A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.616 4.616 0 001.8-1.128.249.249 0 01.354 0 4.6 4.6 0 001.8 1.128.247.247 0 01.158.3 2.224 2.224 0 01-2.13 1.623zM12.025 18.568l5.061 5.28a.5.5 0 00.714 0l5.054-5.272a3.487 3.487 0 00.654-4.028 3.489 3.489 0 00-5.588-.907l-.3.3a.249.249 0 01-.35 0l-.3-.3a3.489 3.489 0 00-5.588.907 3.481 3.481 0 00.643 4.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});