define("ember-svg-jar/inlined/common-file-text-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-clock</title><path d=\"M20.125 7.375a1 1 0 00-1-1h-7a1 1 0 000 2h7a1 1 0 001-1z\"/><path d=\"M23.539 2.875L21.125.46a2 2 0 00-1.414-.585H8.125a2 2 0 00-2 2V9.15a.248.248 0 00.242.25 19.17 19.17 0 011.485.083.246.246 0 00.273-.248v-6.86a.5.5 0 01.5-.5H19.5a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353v13.379a.5.5 0 01-.5.5h-6.854a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331h7.735a2 2 0 002-2V4.29a2 2 0 00-.586-1.415z\"/><path d=\"M12.625 10.875a.947.947 0 00-.634.245.247.247 0 00.019.351 8.062 8.062 0 011.153 1.3.24.24 0 00.2.1h3.266a1 1 0 000-2zM6.625 10.625a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm0 11.5a4.75 4.75 0 114.75-4.75 4.756 4.756 0 01-4.75 4.75z\"/><path d=\"M8.625 16.375h-.818a.176.176 0 01-.182-.2v-1.3a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});