define("ember-svg-jar/inlined/content-pen-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-2</title><path d=\"M22.639 1.919a2.931 2.931 0 00-3.351-.559.9.9 0 00-.1-.147l-.345-.348a2.965 2.965 0 00-4.186 0L9.486 6.036A1 1 0 1010.9 7.45l5.171-5.171a.962.962 0 011.358 0l.346.347L15.71 4.69a.491.491 0 000 .693l3.465 3.464a.489.489 0 00.693 0l2.771-2.77a2.941 2.941 0 000-4.158z\"/><path d=\"M14.669 6.423a.488.488 0 00-.692 0L6 14.4a1.469 1.469 0 000 2.078l2.078 2.079a1.467 1.467 0 002.078 0l7.974-7.974a.491.491 0 000-.693zM4.972 17.509a.5.5 0 00-.707 0l-2.079 2.078a.5.5 0 000 .707l.332.333L.793 22.3a1 1 0 000 1.414 1.012 1.012 0 001.414 0l1.725-1.674.333.333a.5.5 0 00.707 0l2.078-2.079a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});