define("ember-svg-jar/inlined/video-file-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-question</title><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.933-4.171a.246.246 0 00-.183.215v.206a.75.75 0 01-1.5 0v-.55a1.256 1.256 0 01.988-1.221 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.558 2.454z\"/><path d=\"M23.415 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.006 1.037.034 1.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.229.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M19.5 10a.751.751 0 00-.4-.655l-5.846-3.236a.886.886 0 00-.834-.009.755.755 0 00-.42.665V11.6a.236.236 0 00.073.172 8.043 8.043 0 011.359 1.738.244.244 0 00.331.1l5.337-2.955a.751.751 0 00.4-.655z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});