define("ember-svg-jar/inlined/wedding-cake-couple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-cake-couple</title><circle cx=\"8.5\" cy=\"1.5\" r=\"1.5\"/><circle cx=\"15.5\" cy=\"1.5\" r=\"1.5\"/><path d=\"M13.615 9.5h3.77a.5.5 0 00.49-.6l-.87-4.174a1.537 1.537 0 00-3.01 0l-.87 4.174a.5.5 0 00.49.6zM20.5 11h-17A2.5 2.5 0 001 13.5v.356a1.89 1.89 0 001.708 1.882 2.346 2.346 0 002.131-.944 1.33 1.33 0 011.092-.568 1.422 1.422 0 011.169.594 2.335 2.335 0 003.729 0 1.4 1.4 0 011.142-.587 1.4 1.4 0 011.141.588 2.336 2.336 0 003.729 0 1.438 1.438 0 011.161-.594h.012a1.328 1.328 0 011.091.569 2.348 2.348 0 002.13.943A1.947 1.947 0 0023 13.8v-.3a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M22.639 16.871a3.564 3.564 0 01-1.255.359 3.861 3.861 0 01-3.223-1.225.248.248 0 00-.188-.082.251.251 0 00-.186.086 3.731 3.731 0 01-2.812 1.241 3.817 3.817 0 01-2.816-1.231.246.246 0 00-.185-.082.249.249 0 00-.184.082 3.847 3.847 0 01-5.643 0 .252.252 0 00-.37 0 3.828 3.828 0 01-3.213 1.211 3.571 3.571 0 01-1.206-.336.25.25 0 00-.358.226v4.38A2.5 2.5 0 003.5 24h17a2.5 2.5 0 002.5-2.5v-4.4a.25.25 0 00-.361-.224zM6.25 6.5h.538a.25.25 0 01.247.209l.395 2.373a.5.5 0 00.494.418h1.152a.5.5 0 00.494-.418l.395-2.373a.25.25 0 01.247-.209h.538a.25.25 0 00.25-.25V6a2.5 2.5 0 00-5 0v.25a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});