define("ember-svg-jar/inlined/arrow-thick-right-bottom-corner-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-bottom-corner-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-5 17a.5.5 0 01-.5.5H9.705a.5.5 0 01-.353-.146l-2-2A.5.5 0 017.7 14.5h3.909a.25.25 0 00.177-.427L6.207 8.489a.5.5 0 010-.707l1.42-1.419a.5.5 0 01.7-.006l5.746 5.564a.25.25 0 00.424-.179V7.7a.5.5 0 01.854-.353l2 2a.5.5 0 01.149.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});