define("ember-svg-jar/inlined/lens-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-horizontal</title><path d=\"M12.686 9.538a.251.251 0 00-.248-.288H6.224a.249.249 0 00-.245.205A14.113 14.113 0 005.75 12a14.328 14.328 0 00.15 2.036.25.25 0 00.247.214h6.227a.251.251 0 00.249-.28 16.439 16.439 0 01.068-4.432zM6.535 7.413a.25.25 0 00.234.337h6.124a.25.25 0 00.239-.177A10.147 10.147 0 0114.7 4.4a.25.25 0 00-.2-.4H9.23a.251.251 0 00-.111.026 6.014 6.014 0 00-2.584 3.387zM12.992 15.936a.25.25 0 00-.241-.186H6.6a.249.249 0 00-.238.326 6.4 6.4 0 002.76 3.9.255.255 0 00.108.024h5.27a.251.251 0 00.2-.4 10.482 10.482 0 01-1.708-3.664z\"/><path d=\"M4.25 12a13.457 13.457 0 011.7-6.855.174.174 0 00-.238-.239C.65 7.924 0 8.312 0 12s.542 4.011 5.724 7.1a.17.17 0 00.207-.027.168.168 0 00.024-.207A13.443 13.443 0 014.25 12zM24 12c0-4.486-2.2-8-5-8h-1.52a.251.251 0 00-.111.026C15.456 4.972 14 8.241 14 12s1.456 7.028 3.369 7.974a.255.255 0 00.111.026H19c2.8 0 5-3.514 5-8zm-5 6c-1.419 0-3-2.464-3-6s1.581-6 3-6 3 2.464 3 6-1.581 6-3 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});