define("ember-svg-jar/inlined/hat-gentleman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-gentleman</title><path d=\"M23 9a1 1 0 00-1 1 3.5 3.5 0 01-1.615 2.948.25.25 0 01-.385-.211V8.5a8 8 0 00-16 0v4.237a.251.251 0 01-.385.211A3.5 3.5 0 012 10a1 1 0 00-2 0 5.506 5.506 0 005.5 5.5h13A5.506 5.506 0 0024 10a1 1 0 00-1-1zM13.6 20.039a1.5 1.5 0 00-1.153-.54h-.9a1.517 1.517 0 00-1.187.588l-1.2 1.871A1 1 0 0010 23.5h4a1 1 0 00.841-1.541z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});