define("ember-svg-jar/inlined/cog-play-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-play-1</title><path d=\"M22.5 9.5h-1.862a9.007 9.007 0 00-.762-1.84l1.316-1.316a1.5 1.5 0 000-2.122l-1.415-1.413a1.5 1.5 0 00-2.121 0l-1.317 1.317a8.937 8.937 0 00-1.839-.763V1.5A1.5 1.5 0 0013 0h-2a1.5 1.5 0 00-1.5 1.5v1.863a8.946 8.946 0 00-1.839.763L6.343 2.809a1.5 1.5 0 00-2.121 0L2.807 4.223a1.5 1.5 0 000 2.122l1.317 1.317A8.988 8.988 0 003.361 9.5H1.5A1.5 1.5 0 000 11v2a1.5 1.5 0 001.5 1.5h1.861a8.988 8.988 0 00.763 1.839l-1.317 1.319a1.5 1.5 0 000 2.121l1.415 1.414a1.543 1.543 0 002.121 0l1.316-1.317a8.985 8.985 0 001.841.764v1.86A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-1.86a8.942 8.942 0 001.839-.763l1.318 1.317a1.5 1.5 0 002.121 0l1.414-1.414a1.5 1.5 0 000-2.121l-1.316-1.317a9.077 9.077 0 00.763-1.84H22.5A1.5 1.5 0 0024 13v-2a1.5 1.5 0 00-1.5-1.5zM12 18a6 6 0 116-6 6 6 0 01-6 6z\"/><path d=\"M15.293 11.582l-4.521-2.937a.5.5 0 00-.51-.021.5.5 0 00-.262.44V15a.5.5 0 00.264.441.508.508 0 00.512-.024l4.521-3a.5.5 0 000-.836z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});