define("ember-svg-jar/inlined/shop-cashier-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-cashier-man</title><path d=\"M16.75 5.25a5.25 5.25 0 10-5.25 5.25 5.256 5.256 0 005.25-5.25zM11.5 8.5a3.251 3.251 0 01-3.029-4.429.25.25 0 01.378-.113 7.026 7.026 0 004.068 1.292 7.051 7.051 0 001.6-.184.188.188 0 01.231.184A3.254 3.254 0 0111.5 8.5z\"/><path d=\"M10.889 11.946a.25.25 0 01.244-.2h.734a.25.25 0 01.244.2l.968 4.358a.25.25 0 00.244.2h4.69a.75.75 0 00.7-1.02 7.714 7.714 0 00-14.426 0 .75.75 0 00.7 1.02h4.69a.25.25 0 00.244-.2zM24 19a1 1 0 00-1-1H1a1 1 0 000 2h.25a.25.25 0 01.25.25V23a1 1 0 001 1h19a1 1 0 001-1v-2.75a.25.25 0 01.25-.25H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});