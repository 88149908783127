define("ember-svg-jar/inlined/tag-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-dollar</title><path d=\"M22.5 0H14a2.9 2.9 0 00-2.061.854L.5 12.293a1.707 1.707 0 000 2.414L9.293 23.5a1.707 1.707 0 002.414 0l11.439-11.439A2.892 2.892 0 0024 10V1.5A1.5 1.5 0 0022.5 0zm-8.313 10.714c.561 2.089 1.09 4.062-.044 5.2a2.844 2.844 0 01-4.065 0l-.013-.014a.252.252 0 00-.354 0l-.782.783a1 1 0 11-1.414-1.414l.782-.783a.25.25 0 000-.354l-1.338-1.34a1 1 0 011.414-1.415l2.215 2.215.007.006.008.008.886.886a.848.848 0 001.237 0c.318-.318-.237-2.383-.473-3.264-.561-2.089-1.09-4.062.045-5.2a2.722 2.722 0 014.064 0l.013.013a.252.252 0 00.354 0l.783-.782a1 1 0 111.414 1.414l-.783.782a.25.25 0 000 .353l1.338 1.339a1 1 0 01-1.414 1.414l-2.204-2.199-.01-.009v-.006l-.009-.009-.885-.885a.757.757 0 00-1.236 0c-.323.315.228 2.38.464 3.261z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});