define("ember-svg-jar/inlined/microphone-podcast-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-podcast-laptop</title><path d=\"M14.5 11.563a.251.251 0 00-.265-.25c-.07 0-.139.006-.209.006a3.575 3.575 0 01-1.322-.247 3.992 3.992 0 01-1.431-.991.249.249 0 00-.183-.081H4.251A.25.25 0 014 9.75V3a1 1 0 011-1h11a1 1 0 011 1v1.482a.248.248 0 00.06.162 2.991 2.991 0 01.5.809.242.242 0 00.118.13l.959.488A.251.251 0 0019 5.848V3a3 3 0 00-3-3H5a3 3 0 00-3 3v7.568a.256.256 0 01-.055.156L.463 12.578a2.113 2.113 0 001.648 3.428h12.14a.25.25 0 00.25-.25z\"/><path d=\"M17.558 16.7a.254.254 0 01-.058-.16v-4.654a.25.25 0 00-.175-.239l-1.5-.471a.25.25 0 00-.325.239v5.128a.254.254 0 01-.058.16l-.942 1.13-3.769 4.522a1 1 0 001.538 1.281l2.231-2.678.558-.67a.25.25 0 01.442.16V23a1 1 0 002 0v-2.552a.25.25 0 01.442-.16l.558.67 2.231 2.678a1 1 0 001.538-1.281L18.5 17.833zM15.5 9.506l1.133-2.777c.378-.926-.573-1.854-1.285-2.145a3 3 0 10-2.266 5.556c.711.29 2.04.292 2.418-.634zM23.012 9.887l-.731-.242a.249.249 0 01-.159-.16 1.587 1.587 0 00-.787-.926L17.956 6.84a.25.25 0 00-.207-.009.254.254 0 00-.143.151c-.014.041-.03.082-.046.123l-1.134 2.779a2.25 2.25 0 01-.054.122.251.251 0 00.151.346l3.617 1.135a1.6 1.6 0 001.522-.323.249.249 0 01.246-.05.25.25 0 01.03.46l-.575.287a1.559 1.559 0 000 2.789l.959.511a.25.25 0 01.127.168.253.253 0 01-.047.206.508.508 0 01-.038.043l-.646.646a.75.75 0 001.06 1.061l.647-.647A1.941 1.941 0 0024 15.256a1.548 1.548 0 00-.862-1.394l-1.059-.529a.086.086 0 01-.047-.077.084.084 0 01.047-.076l1.059-.529A1.55 1.55 0 0024 11.256a1.451 1.451 0 00-.988-1.369z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});