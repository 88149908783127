define("ember-svg-jar/inlined/mouse-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-body</title><path d=\"M18.369 10.955c.117-1.347.13-2.687.131-3.991a3.5 3.5 0 10-3.682-4.889 4.5 4.5 0 00-1.636 0A3.494 3.494 0 109.5 6.964V10.5a.5.5 0 01-.5.5 6.5 6.5 0 000 13h10a1 1 0 000-2l-7-.008a.751.751 0 01-.75-.72c-.063-1.577-.525-3.512-2.367-3.778A.75.75 0 019.1 16.01c2.019.291 3.305 1.91 3.6 4.482h1.6a13.552 13.552 0 002.436-3.348l1.063 1.063a1 1 0 001.414-1.414l-1.653-1.647a15.875 15.875 0 00.455-1.717l1.778 1.778a1 1 0 001.414-1.414zM12 7.5a1 1 0 111-1 1 1 0 01-1 1zm3.153 1.854l-.8.8a.5.5 0 01-.708 0l-.8-.8A.5.5 0 0113.2 8.5h1.6a.5.5 0 01.354.854zM16 7.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});