define("ember-svg-jar/inlined/paginate-filter-4-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-4-alternate</title><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/><path d=\"M16 5.5a6.007 6.007 0 00-6 6v1a1 1 0 001 1h4v1a1 1 0 002 0v-8a1 1 0 00-1-1zm-1 5.75a.25.25 0 01-.25.25h-2.492a.25.25 0 01-.249-.266 4.01 4.01 0 012.657-3.5.251.251 0 01.334.228z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});