define("ember-svg-jar/inlined/road-sign-no-u-turn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-u-turn</title><path d=\"M21.164 4.28a.252.252 0 00-.368-.016l-3.26 3.26a.75.75 0 01-1.06-1.06l3.26-3.26a.254.254 0 00.073-.188.251.251 0 00-.089-.18A11.98 11.98 0 002.836 19.72a.25.25 0 00.368.016l2.3-2.3a.75.75 0 011.061 1.06l-2.3 2.3a.254.254 0 00-.073.188.251.251 0 00.089.18A11.98 11.98 0 0021.164 4.28zm-2.99 8.2l-2.093 2.562c-.012.015-.028.027-.041.041l-.023.024a.621.621 0 01-.5.213h-.029a.748.748 0 01-.291-.068.8.8 0 01-.071-.048.722.722 0 01-.175-.136c-.007-.008-.018-.011-.025-.019l-2.126-2.57a.75.75 0 01.577-1.229H14.5a.25.25 0 00.25-.25 2.75 2.75 0 00-5.5 0v6a.75.75 0 01-1.5 0v-6a4.25 4.25 0 018.5 0 .25.25 0 00.25.25h1.094a.75.75 0 01.58 1.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});