define("ember-svg-jar/inlined/hotel-bedroom-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-bedroom-1</title><path d=\"M1.6 9.8l10.1-7.576a.5.5 0 01.6 0L22.4 9.8a1 1 0 101.2-1.6L13.2.4a2.015 2.015 0 00-2.4 0L.4 8.2a1 1 0 101.2 1.6z\"/><circle cx=\"5.5\" cy=\"16.499\" r=\"2\"/><path d=\"M8.5 14.34V18a.5.5 0 00.5.5h10.667a.834.834 0 00.623-1.387C18.233 14.8 14.1 13.37 9.309 13.508a.833.833 0 00-.809.832z\"/><path d=\"M24 14a1 1 0 00-2 0v5.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-7a1 1 0 00-2 0V23a1 1 0 002 0v-.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v.5a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});