define("ember-svg-jar/inlined/email-action-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-music</title><path d=\"M18.409 15.131a.758.758 0 01-.409.119H6a.757.757 0 01-.405-.119l-5.21-3.35a.25.25 0 00-.385.21V22a2 2 0 002 2h20a2 2 0 002-2V11.993a.25.25 0 00-.385-.21z\"/><path d=\"M5.12 13.041a.25.25 0 00.385-.21L5.5 2.25A.249.249 0 015.75 2h12.5a.25.25 0 01.25.25l.01 10.575a.25.25 0 00.385.21l4.053-2.607a.248.248 0 00.115-.206.251.251 0 00-.107-.21L20.5 8.3V2.207a2.114 2.114 0 00-2-2.208h-13a2.115 2.115 0 00-2 2.209V8.3l-2.458 1.71a.249.249 0 00.007.415z\"/><path d=\"M10.677 4.859A1.753 1.753 0 009.25 6.58v3.2A1.708 1.708 0 009 9.75a1.75 1.75 0 101.75 1.75V6.58c0-.121.086-.225.295-.27l3.409-1.1a.25.25 0 01.3.247v2.32a1.708 1.708 0 00-.25-.025A1.75 1.75 0 1016.25 9.5V5.455a1.745 1.745 0 00-.632-1.348 1.776 1.776 0 00-1.532-.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});