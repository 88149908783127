define("ember-svg-jar/inlined/religion-man-muslim-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-man-muslim-2</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.464 2.464 0 00-.967-1.977 12.054 12.054 0 00-2.287-1.331.248.248 0 01-.133-.31 8.457 8.457 0 00.372-1.4 15.763 15.763 0 00-.546-7.776 13.005 13.005 0 00-.187-.517 11.684 11.684 0 00-.722-1.557A.247.247 0 0119 6.4 6.779 6.779 0 0012 0a6.779 6.779 0 00-7 6.4.247.247 0 01-.033.119 11.684 11.684 0 00-.722 1.557c-.063.163-.125.334-.187.517a15.763 15.763 0 00-.546 7.779 8.457 8.457 0 00.372 1.4.249.249 0 01-.133.31 12.068 12.068 0 00-2.288 1.331A2.466 2.466 0 00.5 21.394zM12 2a4.917 4.917 0 014.843 3.435.248.248 0 01-.042.217.251.251 0 01-.2.1H7.4a.251.251 0 01-.2-.1.248.248 0 01-.042-.217A4.917 4.917 0 0112 2zm-1.65 11.5a.75.75 0 01.727-.566h1.846a.75.75 0 01.359 1.408 2.349 2.349 0 01-.432.18 2.874 2.874 0 01-.845.123H12a2.95 2.95 0 01-.847-.123 2.42 2.42 0 01-.431-.179.75.75 0 01-.372-.843zm.207 4.063a.25.25 0 01.3-.333 4.065 4.065 0 002.286 0 .251.251 0 01.253.074.248.248 0 01.047.259q-.174.438-.356.857a.248.248 0 01-.189.147 5.275 5.275 0 01-1.8 0 .248.248 0 01-.189-.147q-.177-.42-.352-.855zm4.648-8.547a32.507 32.507 0 01-.146 1.89.252.252 0 01-.065.142 1.144 1.144 0 01-1.2.314 5.686 5.686 0 00-3.578 0 1.16 1.16 0 01-1.2-.324.261.261 0 01-.072-.151A25.355 25.355 0 018.8 9.018q-.039-.729-.046-1.518A.251.251 0 019 7.25h6a.251.251 0 01.25.252q-.01.786-.045 1.516zm3.31 7.019a6.934 6.934 0 01-3.971 5.072.251.251 0 01-.337-.333A27.935 27.935 0 0017.246 7.9a.156.156 0 01.154-.15.025.025 0 01.024.016 13.375 13.375 0 011.091 8.271zm-12-8.147a.251.251 0 01.225-.14.016.016 0 01.016.015 28 28 0 003.027 12.993.25.25 0 01-.338.331 6.945 6.945 0 01-3.96-5.05A13.455 13.455 0 016.511 7.89z\"/><circle cx=\"14\" cy=\"8.5\" r=\"1\"/><circle cx=\"10\" cy=\"8.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});