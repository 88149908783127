define("ember-svg-jar/inlined/music-genre-baby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-baby</title><path d=\"M15.357 19.3a.248.248 0 00.143-.226v-1.796a.25.25 0 00-.426-.181A6.5 6.5 0 014.23 14.2a1 1 0 00-1.1-.728 1 1 0 110-1.981 1.013 1.013 0 001.1-.728 6.479 6.479 0 012.007-3.184.251.251 0 01.36.033 5.006 5.006 0 007.807 0 .246.246 0 01.172-.092.251.251 0 01.186.06 6.481 6.481 0 012.008 3.186 1.01 1.01 0 001.1.728 1 1 0 110 1.981 1.007 1.007 0 00-1.1.728v.009a.25.25 0 00.366.283 3.248 3.248 0 01.9-.351l.022-.006 2.72-.879a.25.25 0 00.169-.189 2.968 2.968 0 00.053-.587 3.005 3.005 0 00-2.4-2.94.251.251 0 01-.183-.154 8.5 8.5 0 00-2.9-3.763.248.248 0 01-.1-.246A5 5 0 0013 .152a1 1 0 00-1 1.731 3.041 3.041 0 011.483 2.263.25.25 0 01-.327.264 8.451 8.451 0 00-2.656-.427 8.642 8.642 0 00-7.918 5.408.252.252 0 01-.182.152 3 3 0 000 5.879.251.251 0 01.183.154 8.491 8.491 0 0011.7 4.513.26.26 0 00.073-.055 3.281 3.281 0 011.001-.734z\"/><path d=\"M13.943 14.162a1 1 0 00-1.391.249 2.5 2.5 0 01-4.1 0 1 1 0 10-1.64 1.143 4.5 4.5 0 007.384 0 1 1 0 00-.253-1.392z\"/><circle cx=\"8\" cy=\"11.983\" r=\"1\"/><circle cx=\"12.5\" cy=\"11.983\" r=\"1\"/><path d=\"M21.837 14.49l-3.409 1.1A1.753 1.753 0 0017 17.312v2.952a.219.219 0 01-.222.219h-.028a1.75 1.75 0 101.75 1.75v-4.921c0-.118.083-.221.286-.267l.021-.006 3.368-1.088a.247.247 0 01.223.035.25.25 0 01.1.2v2.046a.25.25 0 01-.25.25A1.75 1.75 0 1024 20.233v-4.046a1.753 1.753 0 00-2.163-1.697z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});