define("ember-svg-jar/inlined/allowances-no-smoking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-smoking</title><path d=\"M4.562 11.875a1.5 1.5 0 00-1.5 1.5v2.25a1 1 0 002 0v-1.25a.5.5 0 01.5-.5h1.25a1 1 0 100-2zM24 13a1.5 1.5 0 00-1.5-1.5h-6.75a1 1 0 000 2h1.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-6a1 1 0 000 2H22.5A1.5 1.5 0 0024 17zM1.5 18a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5A1.5 1.5 0 000 13.5v3A1.5 1.5 0 001.5 18zM1.75 10.5a.752.752 0 00.75-.75V9a.5.5 0 01.5-.5h5a2 2 0 001.833-1.2.5.5 0 01.458-.3H13a1.5 1.5 0 000-3H8.5a2 2 0 00-1.833 1.2.5.5 0 01-.458.3H4.5A3.5 3.5 0 001 8.995v.75a.752.752 0 00.75.755zM23.707.586a1 1 0 00-1.414 0L.586 22A1 1 0 002 23.414L23.707 2a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});