define("ember-svg-jar/inlined/smiley-shout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shout</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.6 6.3a1 1 0 01-1.4-.2 1.544 1.544 0 00-2.4 0 1 1 0 11-1.6-1.2 3.5 3.5 0 015.6 0 1 1 0 01-.2 1.4zM14.5 14h-1a1 1 0 010-2h1a1 1 0 010 2zm1-5a1.5 1.5 0 111.5 1.5A1.5 1.5 0 0115.5 9zm-6 3h1a1 1 0 010 2h-1a1 1 0 010-2zM7 10.5A1.5 1.5 0 118.5 9 1.5 1.5 0 017 10.5zm0-7a3.463 3.463 0 012.8 1.4 1 1 0 01-1.6 1.2 1.544 1.544 0 00-2.4 0 1 1 0 01-1.6-1.2A3.463 3.463 0 017 3.5zm2.5 16v-1a2.5 2.5 0 015 0v1a2.5 2.5 0 01-5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});