define("ember-svg-jar/inlined/file-java-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-java-1</title><path d=\"M7.5 12.126a.876.876 0 00-.875.874v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13a.876.876 0 00-.875-.874zM17.5 12.126a.876.876 0 00-.875.875v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13a.876.876 0 00-.875-.874z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zM4.625 16a2.125 2.125 0 01-4.25 0 .625.625 0 011.25 0 .875.875 0 001.75 0v-4.5a.625.625 0 011.25 0zm5 1.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.374a2.212 2.212 0 011.9-2.239A2.128 2.128 0 019.625 13zm5-4.5A8.178 8.178 0 0113 17.876a.625.625 0 01-1 0A8.178 8.178 0 0110.375 13v-1.5a.625.625 0 111.25 0V13a6.908 6.908 0 00.718 3.054.2.2 0 00.315 0A6.912 6.912 0 0013.375 13v-1.5a.625.625 0 111.25 0zm3.75 4.5v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 11-1.25 0v-4.374a2.212 2.212 0 011.9-2.239A2.128 2.128 0 0119.625 13v4.5a.625.625 0 11-1.25 0z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});