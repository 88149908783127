define("ember-svg-jar/inlined/dresser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser</title><path d=\"M1.75 12.75h9a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v5a.5.5 0 00.5.5zm4.5-4a1 1 0 11-1 1 1 1 0 011-1zM22.25 6.75h-9a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm-4.5 4a1 1 0 111-1 1 1 0 01-1 1zM22.75 1.75H1.25a1 1 0 00-1 1v1a1.5 1.5 0 001.5 1.5h20.5a1.5 1.5 0 001.5-1.5v-1a1 1 0 00-1-1zM22.25 14.25H1.75a.5.5 0 00-.5.5v6.5a1 1 0 002 0v-1a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5v1a1 1 0 002 0v-6.5a.5.5 0 00-.5-.5zM12 17.75a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});