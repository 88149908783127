define("ember-svg-jar/inlined/smart-watch-square-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-data</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zM16 13.219a.656.656 0 01.513 1.066l-1.75 2.187a.656.656 0 01-1.026 0l-1.75-2.187a.656.656 0 01.513-1.066h.75a.25.25 0 00.25-.25v-2.938a.75.75 0 111.5 0v2.938a.25.25 0 00.25.25zm-8.5-2.875a.657.657 0 01-.513-1.067l1.75-2.187a.681.681 0 011.026 0l1.75 2.187A.657.657 0 0111 10.344h-.75a.249.249 0 00-.25.25v3.437a.75.75 0 01-1.5 0v-3.437a.249.249 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});