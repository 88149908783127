define("ember-svg-jar/inlined/christmas-bells-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-bells-1</title><path d=\"M19.856 19.783l-4.111 1.59a.5.5 0 00-.172.822 2.917 2.917 0 004.963-1.918.5.5 0 00-.68-.494zM22.067 15.963a3.5 3.5 0 01-2.044-2.117l-1.091-2.82a4.91 4.91 0 00-.819-1.381.5.5 0 00-.8.045 3.5 3.5 0 01-2.248 1.5.5.5 0 00-.378.653 5.852 5.852 0 01-.2 4.262l-1.222 2.765a2.512 2.512 0 00-.067 2.1 2.005 2.005 0 01.1.278L22.1 17.84a1 1 0 00-.033-1.877zM7.914 22.492L4.131 20.82a.5.5 0 00-.7.474 2.794 2.794 0 004.605 2.036.5.5 0 00-.122-.838zM12.351 18.465l1.223-2.765a4.741 4.741 0 00-.76-5.12.5.5 0 00-.615-.1 3.456 3.456 0 01-4.244-.641.5.5 0 00-.608-.093 4.484 4.484 0 00-1.91 2.1l-1.318 2.981a3.5 3.5 0 01-2.141 2.019 1 1 0 00-.126 1.873L11 22.761a1 1 0 001.3-1.354 3.5 3.5 0 01.051-2.942zM3.749 3.914l.968.551a.5.5 0 01.252.432v1.019A1.508 1.508 0 006.061 7.35l.618.176a.252.252 0 00.221-.038A.246.246 0 007 7.3a3.476 3.476 0 013.87-3.336.25.25 0 00.273-.3l-.127-.606A1.5 1.5 0 009.69 1.875l-1.249-.12a.493.493 0 01-.371-.225L7.516.681A1.494 1.494 0 006.26 0H4.493a1.5 1.5 0 00-1.5 1.5v1.111a1.506 1.506 0 00.756 1.303z\"/><path d=\"M7.982 7.44a2.5 2.5 0 002.5 2.5 2.462 2.462 0 00.346-.024.5.5 0 00.384-.7 3.479 3.479 0 01.282-3.4.5.5 0 00-.263-.757A2.5 2.5 0 007.982 7.44zM13.24 4.466a3.384 3.384 0 011.16-.208 3.486 3.486 0 013.417 2.828.247.247 0 00.13.174.25.25 0 00.217.007 1.4 1.4 0 00.9-1.347l.005-1.031a.5.5 0 01.248-.428l.937-.548a1.5 1.5 0 00.743-1.295V1.5a1.5 1.5 0 00-1.5-1.5h-1.68a1.493 1.493 0 00-1.265.7l-.53.833a.5.5 0 01-.373.229l-1.182.116a1.491 1.491 0 00-1.327 1.186l-.23 1.117a.251.251 0 00.082.24.248.248 0 00.248.045z\"/><circle cx=\"14.396\" cy=\"7.759\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});