define("ember-svg-jar/inlined/video-player-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-1</title><path d=\"M23.371 4.919a1.518 1.518 0 00-1.3-1.247 75.831 75.831 0 00-20.146 0 1.518 1.518 0 00-1.3 1.246 40.021 40.021 0 000 14.163 1.519 1.519 0 001.3 1.247A75.824 75.824 0 0012 21a75.813 75.813 0 0010.073-.672 1.517 1.517 0 001.3-1.247 40.039 40.039 0 00-.002-14.162zM8 15.019V8.98a1.04 1.04 0 011.534-.862l5.922 3.019a.957.957 0 010 1.726l-5.922 3.019A1.04 1.04 0 018 15.019z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});