define("ember-svg-jar/inlined/single-woman-actions-alarm.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-alarm</title><path d=\"M12 15.5a5.951 5.951 0 01.942-3.215.25.25 0 00-.124-.37l-1.344-.492a.779.779 0 01-.513-.73v-.467a.25.25 0 01.249-.25A6.377 6.377 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.836-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.347 6.347 0 003.781.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126h11.357a.249.249 0 00.253-.234v-.1zM23.658 19.883a4.076 4.076 0 01-1.158-2.716V15.5a4.5 4.5 0 00-9 0v1.667a4.076 4.076 0 01-1.158 2.714l-.242.319a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.4.194.5.5 0 00-.089.431 1.827 1.827 0 003.541 0 .5.5 0 00-.084-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});