define("ember-svg-jar/inlined/arrow-thick-circle-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-right-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.312 12.722L12.8 18.544a.972.972 0 01-1.4.019l-.295-.3a1.072 1.072 0 01-.042-1.452l2.3-2.573c.366-.41.216-.746-.334-.746h-6.5a1 1 0 01-1-1v-1a1 1 0 011-1h6.485c.55 0 .7-.332.324-.737l-2.371-2.578A1.055 1.055 0 0111 5.734l.3-.3a.982.982 0 011.4.014l5.61 5.825a1.06 1.06 0 01.002 1.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});