define("ember-svg-jar/inlined/hair-dress-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-cut</title><path d=\"M15.625 5.5h2.5a1 1 0 000-2h-2.5a1 1 0 000 2zM15.625 9.5h2.5a1 1 0 000-2h-2.5a1 1 0 000 2zM15.625 13.5h2.5a1 1 0 000-2h-2.5a1 1 0 000 2zM14.625 19.5a3 3 0 00-3-3 2.966 2.966 0 00-1 .184V8.062a1.048 1.048 0 00-.031-.249L8.84.959a1 1 0 00-.325-.518L8.269.234a1 1 0 00-1.288 0l-.246.207a1 1 0 00-.325.518L4.656 7.813a1.048 1.048 0 00-.031.249v8.622a2.966 2.966 0 00-1-.184 3 3 0 103 3v-4.682a.252.252 0 01.085-.189l1.5-1.312a.25.25 0 01.415.188V19.5A3 3 0 0011 22.432 2.993 2.993 0 0013.625 24a1 1 0 000-2 1.015 1.015 0 01-.282-.047 2.994 2.994 0 001.282-2.453zm-11 1a1 1 0 111-1 1 1 0 01-1 1zm3.415-8.817a.25.25 0 01-.415-.188V8.188l1-3.909 1 3.909v1.994a.252.252 0 01-.085.189zm4.585 6.817a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M23.375 2.75A2.753 2.753 0 0020.627 0h-5a1 1 0 000 2h4a.25.25 0 01.25.25v12.5a.25.25 0 01-.25.25h-4a1 1 0 000 2h4a.25.25 0 01.25.25v5a1.75 1.75 0 003.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});