define("ember-svg-jar/inlined/primitive-symbols-shaman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-shaman</title><path d=\"M14.811 13.959a3.294 3.294 0 001.036.168 3.229 3.229 0 002.487-1.172L19.96 11a1.25 1.25 0 10-1.92-1.6l-1.626 1.951a.751.751 0 01-.813.232l-2.18-.727a.25.25 0 01-.171-.237V9.431a.241.241 0 01.121-.207 2.747 2.747 0 001.336-2.115.246.246 0 01.243-.234H17a3 3 0 003-3v-2a1 1 0 00-2 0v2a1 1 0 01-.636.931c-.113.044-.364.011-.364-.184v-.747a1 1 0 00-2 0v.747a.239.239 0 01-.253.254h-.788a.369.369 0 01-.191-.074 2.72 2.72 0 00-3.582-.016.331.331 0 01-.2.085h-.75A.275.275 0 019 4.622v-.747a1 1 0 00-2 0v.737c0 .2-.241.24-.349.2A1 1 0 016 3.875v-2a1 1 0 00-2 0v2a3 3 0 003 3h1.984a.247.247 0 01.243.234 2.743 2.743 0 001.4 2.152.242.242 0 01.127.211v1.151a.25.25 0 01-.171.237l-2.183.726a.751.751 0 01-.815-.233L5.96 9.4A1.25 1.25 0 004.04 11l1.626 1.951a3.228 3.228 0 002.487 1.172 3.277 3.277 0 001.037-.169s1.3-.443 1.388-.463a.131.131 0 01.172.14v.589a.458.458 0 01-.089.292l-5.61 6.545a1.25 1.25 0 101.9 1.627l4.861-5.671a.251.251 0 01.38 0l4.861 5.671a1.429 1.429 0 001.762.136 1.25 1.25 0 00.136-1.763s-5.46-6.367-5.569-6.5a.929.929 0 01-.13-.336v-.611c0-.107.1-.144.191-.115z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});