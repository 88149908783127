define("ember-svg-jar/inlined/music-genre-moon-night-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-moon-night-alternate</title><path d=\"M24 2.2A2.2 2.2 0 0021.4.038a.988.988 0 00-.122.03L17.123 1.4A2.2 2.2 0 0015.4 3.55v3.726a.25.25 0 01-.215.248A1.95 1.95 0 1017.4 9.417V3.55a.2.2 0 01.165-.2C17.605 3.346 21.8 2 21.8 2a.2.2 0 01.2.2v2.676a.25.25 0 01-.216.248A1.95 1.95 0 1024 7.055z\"/><path d=\"M21.972 14.881A10.007 10.007 0 019.119 2.028 1 1 0 007.835.743a12 12 0 1015.421 15.423 1 1 0 00-1.284-1.285zM12 22A10 10 0 016.187 3.865a.25.25 0 01.394.232A12.01 12.01 0 0018.5 17.5a12.135 12.135 0 001.4-.081.25.25 0 01.232.394A10.053 10.053 0 0112 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});