define("ember-svg-jar/inlined/business-deal-handshake-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-handshake-circle</title><path d=\"M7.25 13.75h.65a.753.753 0 01.464.161s1.727 1.362 2.267 1.794a.25.25 0 00.317-.005l2.994-2.528a.252.252 0 00.086-.172.258.258 0 00-.064-.185l-1.2-1.317a.252.252 0 00-.28-.063l-.056.023a1.983 1.983 0 01-.733.143 1.957 1.957 0 01-1.812-1.236 1.953 1.953 0 01-.076-1.211.138.138 0 00-.043-.14.142.142 0 00-.145-.026l-2.467 1.05a.249.249 0 00-.152.23V13.5a.249.249 0 00.25.25zM11.522 10.06a.451.451 0 00.346 0l.7-.287a.753.753 0 01.841.191l1.922 2.117a1.531 1.531 0 01.333.877.253.253 0 00.1.185.248.248 0 00.2.048l.838-.168a.249.249 0 00.2-.245V9.596a.25.25 0 00-.16-.235L14.058 8.3a.948.948 0 00-.706.046l-1.856.9a.782.782 0 01-.112.079.452.452 0 00.138.735z\"/><path d=\"M12 24A12 12 0 100 12a12.013 12.013 0 0012 12zm-9.5-8.5v-7A.5.5 0 013 8h2.5a1.5 1.5 0 011.089.47.25.25 0 00.28.058L9.051 7.6a2.2 2.2 0 012.03.181L12.689 7a2.443 2.443 0 011.892-.1l3.177 1.21a.244.244 0 00.182 0A1.509 1.509 0 0118.5 8H21a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-2.5a1.5 1.5 0 01-1.475-1.229.252.252 0 00-.106-.162.256.256 0 00-.189-.038l-2.828.566-2.255 1.849a1.431 1.431 0 01-1.925-.1L7.707 15.3a.256.256 0 00-.155-.054h-.617a.25.25 0 00-.2.107A1.5 1.5 0 015.5 16H3a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});