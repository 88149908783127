define("ember-svg-jar/inlined/travel-paper-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-paper-plane</title><path d=\"M4.27 12a3 3 0 003-3 1.5 1.5 0 00-1.5-1.5.5.5 0 01-.5-.5 2 2 0 00-2-2 .242.242 0 01-.2-.378A5.991 5.991 0 018.02 2a5.9 5.9 0 012.015.353.5.5 0 01.1.889A2.987 2.987 0 008.77 5.75a1.5 1.5 0 001.5 1.5.5.5 0 01.5.5 2 2 0 002 2h1.03a.5.5 0 01.375.169.955.955 0 00.553.315 1 1 0 001.162-.806A8 8 0 10.02 8a8.026 8.026 0 004.045 6.956 1 1 0 00.99-1.738 5.825 5.825 0 01-.98-.708.292.292 0 01.195-.51z\"/><path d=\"M23.021 10.206a.042.042 0 00-.048-.029L7.364 12.654a1 1 0 00-.4 1.819l3.193 2.139a.5.5 0 00.508.029L23 10.259a.044.044 0 00.021-.053zM23.948 11.566a.13.13 0 00-.155-.029L11.95 17.665a.5.5 0 00-.218.668l2.558 5.115a1 1 0 00.828.55h.066a1 1 0 00.831-.442l7.943-11.835a.128.128 0 00-.01-.155z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});