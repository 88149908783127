define("ember-svg-jar/inlined/real-estate-settings-house-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-settings-house-2</title><path d=\"M24 11a1 1 0 00-1-1h-4.5a.5.5 0 00-.5.5v3a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 016 13.5v-3a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v2a1 1 0 001 1h1.968a.5.5 0 01.483.37A13.253 13.253 0 004 16.028a.5.5 0 01-.108.547l-1.439 1.436a1 1 0 000 1.414l2.122 2.121a.991.991 0 001.414 0l1.434-1.436a.5.5 0 01.549-.11 13.039 13.039 0 001.658.546.5.5 0 01.37.483V23a1 1 0 001 1h2a1 1 0 001-1v-1.968a.5.5 0 01.37-.483A13.183 13.183 0 0016.027 20a.5.5 0 01.548.107l1.436 1.435a1 1 0 001.414 0l2.121-2.121a1 1 0 000-1.414l-1.435-1.436a.5.5 0 01-.111-.543 13.253 13.253 0 00.545-1.658.5.5 0 01.483-.37H23a1 1 0 001-1z\"/><path d=\"M4.168 6.626A.5.5 0 004.5 7.5h2A.5.5 0 017 8v5a1 1 0 001 1h2a.5.5 0 00.5-.5V11a1.5 1.5 0 013 0v2.5a.5.5 0 00.5.5h2a1 1 0 001-1V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874L12.664.252a1 1 0 00-1.328 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});