define("ember-svg-jar/inlined/shipment-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-truck</title><path d=\"M10.5 1.5H.5A.5.5 0 000 2v11.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V2.694A1.358 1.358 0 0010.5 1.5zM9 5v5.5a.75.75 0 01-1.5 0V5A.75.75 0 019 5zM4.5 5v5.5a.75.75 0 01-1.5 0V5a.75.75 0 011.5 0zM11.5 15.5H.5a.5.5 0 00-.5.5v2.273a.25.25 0 00.386.21A2.949 2.949 0 012 18a3 3 0 012.91 2.308.251.251 0 00.243.192h.694a.25.25 0 00.243-.192 2.986 2.986 0 015.453-.883.25.25 0 00.463-.131C12.006 18.7 12 17.706 12 16a.5.5 0 00-.5-.5zM20.221 4.162a.25.25 0 01-.147-.1l-.742-1.112C18.029.973 14.641 1 14.5 1a1 1 0 00-1 1v18a1 1 0 001 1h.26a.25.25 0 00.249-.231 3 3 0 015.983 0 .25.25 0 00.249.231H22a2 2 0 002-2V9a5 5 0 00-3.779-4.838zM22 9v2.734a.25.25 0 01-.074.177.255.255 0 01-.179.073l-2.5-.033A.249.249 0 0119 11.7V6.262a.252.252 0 01.081-.185.255.255 0 01.192-.065A3 3 0 0122 9z\"/><circle cx=\"2\" cy=\"21\" r=\"2\"/><circle cx=\"9\" cy=\"21\" r=\"2\"/><circle cx=\"18\" cy=\"21\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});