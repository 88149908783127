define("ember-svg-jar/inlined/tags-favorite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-favorite</title><path d=\"M19.105 8.413A3.985 3.985 0 0020.091 6V2a2 2 0 00-2-2l-4.258.005a3.945 3.945 0 00-2.415 1L.592 11.833a2 2 0 000 2.829L5.428 19.5a1.976 1.976 0 001.406.581h.011a1.976 1.976 0 001.411-.6l1.012-1.031a.5.5 0 00.1-.539 5.02 5.02 0 016.448-6.578.494.494 0 00.535-.108zM12.591 5.5a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M13.46 12.545a3.524 3.524 0 00-1.935 5.965l5.113 5.335a.5.5 0 00.722 0l5.107-5.328a3.525 3.525 0 00-4.986-4.985l-.305.3a.25.25 0 01-.353 0l-.3-.3a3.5 3.5 0 00-3.063-.987z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});