define("ember-svg-jar/inlined/style-three-pin-oc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-oc</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1 12.5a2.75 2.75 0 01-5.5 0v-4a2.75 2.75 0 015.5 0zm6.75 1.25a.75.75 0 010 1.5 4.75 4.75 0 010-9.5.75.75 0 010 1.5 3.25 3.25 0 000 6.5z\"/><path d=\"M8.25 7.25A1.252 1.252 0 007 8.5v4a1.25 1.25 0 002.5 0v-4a1.252 1.252 0 00-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});