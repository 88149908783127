define("ember-svg-jar/inlined/touch-id-approved-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-approved-1</title><path d=\"M12 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 0012 11zm3.474 5.355l-2.9 3.874a1.751 1.751 0 01-2.64.185l-1.5-1.5a1 1 0 011.41-1.414l1.094 1.1a.251.251 0 00.377-.027l2.559-3.412a1 1 0 111.6 1.2zM10.671 3.988a4.8 4.8 0 016.287 4.554V11.1a.248.248 0 00.093.194 8.082 8.082 0 011.212 1.227.25.25 0 00.445-.156V8.542a6.543 6.543 0 00-8.583-6.216.875.875 0 10.546 1.662zM7.273 11.046a.251.251 0 00.1-.2v-2.3A4.779 4.779 0 018.6 5.348.875.875 0 007.3 4.18a6.528 6.528 0 00-1.675 4.362v3.432a.25.25 0 00.436.167 8.029 8.029 0 011.212-1.095z\"/><path d=\"M10.02 9.748a.25.25 0 00.188-.243v-.963a1.959 1.959 0 113.917 0V9.6a.25.25 0 00.178.239 8 8 0 011.212.475.25.25 0 00.36-.224V8.542a3.709 3.709 0 10-7.417 0v1.416a.234.234 0 00.328.214 7.877 7.877 0 011.234-.424zM5.459 22.106A.253.253 0 005.254 22h-3A.249.249 0 012 21.75V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v20a2 2 0 002 2h4.5a.959.959 0 00.142-.01.249.249 0 00.128-.436 8.018 8.018 0 01-1.311-1.448zM24 2a2 2 0 00-2-2h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25v19.5a.249.249 0 01-.25.25h-3a.253.253 0 00-.2.106 8.018 8.018 0 01-1.311 1.448.25.25 0 00.128.436.959.959 0 00.133.01H22a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});