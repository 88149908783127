define("ember-svg-jar/inlined/information-desk-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk-man</title><path d=\"M22.5 12.25h-21a1.5 1.5 0 00-1.5 1.5v5a1.5 1.5 0 001.5 1.5h1a.25.25 0 01.25.25V23a1 1 0 002 0v-2.5a.25.25 0 01.25-.25h14a.25.25 0 01.25.25V23a1 1 0 002 0v-2.5a.25.25 0 01.25-.25h1a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5zM7.213 11.25h9.574a.25.25 0 00.213-.378 5.854 5.854 0 00-2.879-2.457.249.249 0 01-.029-.451 4.246 4.246 0 10-4.123-.023.249.249 0 01-.034.451A5.833 5.833 0 007 10.872a.249.249 0 00.215.378zm2.594-7a2.155 2.155 0 01.126-.721.249.249 0 01.367-.129 5.657 5.657 0 002.944.851 5.354 5.354 0 00.959-.088.09.09 0 01.106.088 2.25 2.25 0 11-4.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});