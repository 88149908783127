define("ember-svg-jar/inlined/notes-checklist-flip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-checklist-flip</title><path d=\"M21 2.75h-2a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 102 0V5a.25.25 0 01.25-.25h1.5a.5.5 0 01.5.5v10.5a.5.5 0 01-.5.5h-3a2 2 0 00-2 2v3a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-16a.5.5 0 01.5-.5h1a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25H3a2 2 0 00-2 2v17a2 2 0 002 2h13.5a1 1 0 00.737-.324l5.5-6A1 1 0 0023 16.75v-12a2 2 0 00-2-2z\"/><path d=\"M12.25 6.408a1 1 0 001-1V5a.25.25 0 01.25-.25h2a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25h-2a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1zM6.75 6.408a1 1 0 001-1V5A.25.25 0 018 4.75h2a.25.25 0 00.25-.25V3a.25.25 0 00-.25-.25H8a.25.25 0 01-.25-.25V1.25a1 1 0 00-2 0v4.158a1 1 0 001 1zM6.793 12.457a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L7.5 10.336l-.793-.793a1 1 0 00-1.414 1.414zM6.793 17.957a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L7.5 15.836l-.793-.793a1 1 0 00-1.414 1.414zM13 10.75a1 1 0 001 1h4a1 1 0 000-2h-4a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});