define("ember-svg-jar/inlined/plant-bamboo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-bamboo</title><path d=\"M13.75 9.5a.5.5 0 000-1h-5a.5.5 0 000 1 .5.5 0 01.5.5v4a.5.5 0 01-.5.5.5.5 0 000 1h5a.5.5 0 000-1 .5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5zM13.75 17.5a.5.5 0 000-1h-5a.5.5 0 000 1 .5.5 0 01.5.5v4.5a.5.5 0 01-.5.5.5.5 0 000 1h5a.5.5 0 000-1 .5.5 0 01-.5-.5V18a.5.5 0 01.5-.5zM13.75 1a.5.5 0 000-1h-5a.5.5 0 000 1 .5.5 0 01.5.5V6a.5.5 0 01-.5.5.5.5 0 000 1h5a.5.5 0 000-1 .5.5 0 01-.5-.5V1.5a.5.5 0 01.5-.5z\"/><ellipse cx=\"4.25\" cy=\"18.5\" rx=\"1.225\" ry=\"4.062\" transform=\"rotate(-45 4.25 18.5)\"/><ellipse cx=\"19\" cy=\"9.75\" rx=\"5.123\" ry=\"1.369\" transform=\"rotate(-45 19 9.75)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});