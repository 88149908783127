define("ember-svg-jar/inlined/shop-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-open</title><path d=\"M3 19.92a2.377 2.377 0 002.375-2.375v-3a2.375 2.375 0 00-4.75 0v3A2.377 2.377 0 003 19.92zm-.625-2.375v-3a.625.625 0 011.25 0v3a.625.625 0 01-1.25 0zM22.5 12.17a.875.875 0 00-.875.875v1.235a.25.25 0 01-.474.112l-.869-1.738a.874.874 0 00-1.657.391v6a.875.875 0 001.75 0v-1.234a.25.25 0 01.474-.112l.869 1.738a.873.873 0 00.782.483.818.818 0 00.2-.024.873.873 0 00.674-.851v-6a.875.875 0 00-.874-.875zM14.373 14.045a.126.126 0 01.125-.125h2a.875.875 0 000-1.75h-2a1.878 1.878 0 00-1.875 1.875v4A1.877 1.877 0 0014.5 19.92h2a.875.875 0 100-1.75h-2a.125.125 0 01-.125-.125v-.875a.25.25 0 01.25-.25h.875a.875.875 0 100-1.75h-.875a.25.25 0 01-.25-.25zM7.5 19.92a.875.875 0 00.875-.875V17.17a.25.25 0 01.25-.25H9a2.375 2.375 0 000-4.75H7.5a.875.875 0 00-.875.875v6a.875.875 0 00.875.875zm.875-5.75a.25.25 0 01.25-.25H9a.625.625 0 010 1.25h-.377a.25.25 0 01-.25-.25zM20.5 22h-17a1 1 0 000 2h17a1 1 0 000-2zM17.748 5.483a.244.244 0 00-.009-.066c-.109-.4-1.188-4.329-1.434-5.233A.249.249 0 0016.064 0H13a.25.25 0 00-.25.25v9.362a.249.249 0 00.14.224 4.729 4.729 0 002.473.46 4.869 4.869 0 002.379-.843.018.018 0 00.008-.015zM23.956 5.073L22.235.967A1.5 1.5 0 0020.881 0h-2.747a.249.249 0 00-.241.316l1.329 4.832a.8.8 0 01.026.2V8.92a.251.251 0 00.159.233A3.349 3.349 0 0024 6.183v-.91a.532.532 0 00-.044-.2zM11.248.25A.25.25 0 0011 0H7.932a.25.25 0 00-.241.184L6.257 5.417a.244.244 0 00-.009.066V9.3a.251.251 0 00.113.209 4.943 4.943 0 002.289.791c.133.011.266.015.4.015a4.68 4.68 0 002.061-.473.249.249 0 00.14-.224zM4.748 5.348a.8.8 0 01.026-.2L6.1.316A.249.249 0 005.862 0H3.119a1.5 1.5 0 00-1.354.967L.044 5.073a.532.532 0 00-.042.2v.91A3.247 3.247 0 001.99 9.1a3.34 3.34 0 002.6.05.25.25 0 00.159-.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});