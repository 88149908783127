define("ember-svg-jar/inlined/data-file-bars-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-edit</title><rect x=\"11\" y=\"10\" width=\"2\" height=\"3.5\" rx=\".5\" ry=\".5\"/><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.07a.249.249 0 00.073.177l1.5 1.5A.25.25 0 008 12.57V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.823a.248.248 0 00-.235.334c.163.436.359 1.152.448 1.485a.243.243 0 00.24.18H22a2 2 0 002-2V4.415A2 2 0 0023.414 3zM11.938 20.132l-2.809 2.807a.251.251 0 00.128.423l3.143.628a.473.473 0 00.1.01.5.5 0 00.49-.6l-.63-3.14a.25.25 0 00-.422-.128zM11.15 18.445l-6.58-6.58a2.71 2.71 0 00-3.606-.273 2.622 2.622 0 00-.2 3.881l6.679 6.679a.249.249 0 00.353 0L11.15 18.8a.252.252 0 000-.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});