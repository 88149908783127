define("ember-svg-jar/inlined/single-man-focus.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.956 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.956A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5h-5.235a1 1 0 000 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM24 1.957A1.959 1.959 0 0022.044 0h-5.739a1 1 0 000 2l5.195-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0z\"/><path d=\"M9.142 9.121a.253.253 0 01-.029.206 1.579 1.579 0 00-.15 1.177 1.373 1.373 0 00.246.543.242.242 0 01.052.123 2.629 2.629 0 00.753 1.512.247.247 0 01.073.176v.833a.251.251 0 01-.163.235l-.395.145c-2.008.739-3.458 1.272-3.911 2.179A6.223 6.223 0 005 18.767a.5.5 0 00.5.5h13a.5.5 0 00.5-.5 6.223 6.223 0 00-.618-2.517c-.454-.907-1.905-1.44-3.913-2.179l-.393-.145a.251.251 0 01-.163-.235v-.833a.247.247 0 01.073-.176 2.629 2.629 0 00.753-1.512.251.251 0 01.052-.123 1.373 1.373 0 00.246-.543 1.594 1.594 0 00-.131-1.147.25.25 0 01-.018-.218 4.537 4.537 0 00.428-2.371c-.212-1.061-1.58-1.47-2.79-1.5a3.272 3.272 0 00-2.495.772.252.252 0 01-.137.086 1.247 1.247 0 00-.734.47 3.059 3.059 0 00-.018 2.525z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});