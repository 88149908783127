define("ember-svg-jar/inlined/style-three-pin-mailbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-mailbox</title><path d=\"M13.86 8h-3.72a.5.5 0 00-.485.621l.25 1a.5.5 0 00.485.379h3.22a.5.5 0 00.485-.379l.25-1A.5.5 0 0013.86 8z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.5 14.75a.75.75 0 01-1.5 0V14a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0V9a4.5 4.5 0 019 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});