define("ember-svg-jar/inlined/love-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-plant</title><path d=\"M11.263 11.218a1.012 1.012 0 001.46 0l4.862-5.071a3.6 3.6 0 00-1.969-6.1A3.562 3.562 0 0012.5 1.056a.71.71 0 01-1 0 3.6 3.6 0 00-5.764.936 3.592 3.592 0 00.667 4.146zm-4.136-8.4a2.309 2.309 0 011.718-1.253.5.5 0 01.15.988 1.292 1.292 0 00-.973.708A1.305 1.305 0 008 4.391a.5.5 0 11-.909.416 2.3 2.3 0 01.036-1.993zM21.141 10.968a.25.25 0 01-.35-.267c.349-2.167-2.133-3.025-3.433-1.321-.982 1.286-.4 4.331-.088 5.623a.65.65 0 00.651.5c1.33-.039 4.419-.283 5.4-1.568 1.301-1.707-.181-3.875-2.18-2.967zM7.183 11.058C5.766 9.2 3.056 10.15 3.468 12.525a.25.25 0 01-.353.269C.933 11.771-.7 14.134.72 15.991c1.063 1.393 4.411 1.657 5.852 1.7a.7.7 0 00.706-.538c.339-1.401.968-4.702-.095-6.095zM16.2 15.632a.751.751 0 00-1 .134 4.725 4.725 0 01-1.865 1.318.249.249 0 01-.344-.231v-3.6a.751.751 0 00-.75-.75h-.5a.751.751 0 00-.75.75v6.185a.249.249 0 01-.344.232 4.732 4.732 0 01-1.865-1.318.75.75 0 00-1-.135l-.413.286a.75.75 0 00-.152 1.1 6.873 6.873 0 003.416 2.186.5.5 0 01.357.479V23a1 1 0 002 0v-3.321a.5.5 0 01.357-.479 6.88 6.88 0 003.416-2.186.752.752 0 00-.151-1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});