define("ember-svg-jar/inlined/shipment-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-barcode</title><path d=\"M22.5.5H16a.5.5 0 00-.5.5v6.512a.5.5 0 01-.75.433l-.018-.01-2.495-1.31a.5.5 0 00-.472 0L9.24 8.01a.5.5 0 01-.183.058A.491.491 0 018.741 8a.5.5 0 01-.241-.428V1A.5.5 0 008 .5H1.5a1 1 0 00-1 1v21a1 1 0 001 1h21a1 1 0 001-1v-21a1 1 0 00-1-1zm-12.25 19a.75.75 0 01-1.5 0v-5.476a.75.75 0 011.5 0zm3.5 0a.75.75 0 01-1.5 0v-.976a.75.75 0 011.5 0zm0-3.476a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm3.5 3.476a.75.75 0 01-1.5 0v-5.476a.75.75 0 011.5 0zm3.5-.024a.75.75 0 01-1.5 0V18.5a.75.75 0 011.5 0zm0-3.476a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});