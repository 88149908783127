define("ember-svg-jar/inlined/office-drawing-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-drawing-board</title><path d=\"M22.316 22.106h-.85a.25.25 0 01-.25-.25V4.613a.256.256 0 01.019-.1l1.011-2.465a1.5 1.5 0 00-1.4-2.047H7a1.49 1.49 0 00-1.4.951l-3.878 9.46a.249.249 0 00.231.344H18.51a.249.249 0 00.231-.155l.438-1.067s.237-.365.237-.046v12.368a.25.25 0 01-.25.25h-.85a.9.9 0 000 1.8h4a.9.9 0 000-1.8zM15.239 4.391l-1.667 4a1 1 0 01-.923.615H8.816a1 1 0 01-.923-1.384l1.667-4a1 1 0 01.923-.616h3.833a1 1 0 01.923 1.385z\"/><path d=\"M17.936 12.565a.222.222 0 00-.234-.309H1.208a.372.372 0 00-.322.2 1.5 1.5 0 001.4 2.047h.883a.25.25 0 01.25.25v7.1a.25.25 0 01-.25.25h-.85a.9.9 0 000 1.8h4a.9.9 0 000-1.8h-.85a.25.25 0 01-.25-.25v-7.1a.25.25 0 01.25-.25h10.665a1.494 1.494 0 001.395-.948z\"/><path d=\"M15.143 18.95a.248.248 0 01.094-.267 1.491 1.491 0 00.568-1.363 1.572 1.572 0 00-1.59-1.314h-3.9a1.51 1.51 0 00-1.061.44 1.492 1.492 0 00.139 2.236.254.254 0 01.095.271l-1.13 3.766a1 1 0 00.671 1.245 1.044 1.044 0 001.245-.671s.158-.514.185-.618a.228.228 0 01.222-.169H13.9a.278.278 0 01.274.183l.181.6a1.061 1.061 0 001.245.671 1 1 0 00.671-1.245zm-1.809 1.556h-2.079a.118.118 0 01-.1-.163l.348-1.159a.25.25 0 01.239-.178h1.14a.25.25 0 01.239.178s.315 1.04.338 1.127a.151.151 0 01-.125.195z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});