define("ember-svg-jar/inlined/arrow-button-right-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-right-3</title><path d=\"M21.723 11.28L10.857.3a1.006 1.006 0 00-.711-.3H2.989a1 1 0 00-.707 1.707l10.08 10.1a.25.25 0 010 .353L2.285 22.294A1 1 0 002.994 24h7.16a1 1 0 00.712-.3l10.858-11.014a1 1 0 00-.001-1.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});