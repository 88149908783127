define("ember-svg-jar/inlined/blog-blogger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blog-blogger</title><path d=\"M24 10.31a1.53 1.53 0 00-.66-1.19L23.22 9a2.79 2.79 0 00-1.58-.28 4.14 4.14 0 01-1.53-.2 2.8 2.8 0 01-.42-1.49v-.18a6.33 6.33 0 00-.59-2.41l-.1-.17A8.49 8.49 0 0012.89 0h-5.1A7.8 7.8 0 000 7.79v8.51a7.79 7.79 0 007.79 7.78h8.38A7.82 7.82 0 0024 16.31v-5.89a.49.49 0 000-.11zM8 6h4a2 2 0 010 4H8a2 2 0 010-4zm8 12H8a2 2 0 010-4h8a2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});