define("ember-svg-jar/inlined/electronics-resistor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-resistor</title><path d=\"M14.5 20a1 1 0 01-.984-.825l-1.27-7.109a.25.25 0 00-.492 0l-1.27 7.109a1 1 0 01-1.968 0l-1.3-7.275a.25.25 0 00-.492 0l-.242 1.287A1 1 0 015.5 14l-4.5.012a1 1 0 010-2L4.463 12a.25.25 0 00.245-.2l1.31-6.983A1 1 0 017 4a1 1 0 01.98.824l1.27 7.109a.25.25 0 00.492 0l1.27-7.109a1 1 0 011.968 0l1.27 7.109a.25.25 0 00.492 0l1.27-7.109A1 1 0 0117 4a1 1 0 01.982.815l1.31 6.983a.25.25 0 00.245.2l3.463.014a1 1 0 010 2L18.5 14a1 1 0 01-.979-.816l-.245-1.284a.25.25 0 00-.492 0l-1.3 7.276A1 1 0 0114.5 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});