define("ember-svg-jar/inlined/halloween-figure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-figure</title><circle cx=\"15.372\" cy=\"11\" r=\"1.5\"/><circle cx=\"8.628\" cy=\"11\" r=\"1.5\"/><path d=\"M13.757 15.32l-.986-.359a2.257 2.257 0 00-1.542 0l-.986.359a.75.75 0 10.514 1.409l.986-.359a.756.756 0 01.514 0l.986.359a.75.75 0 10.514-1.409z\"/><path d=\"M23 10a1 1 0 00-1 1 .5.5 0 01-1 0V7.086a.5.5 0 01.253-.435A2.423 2.423 0 0022.4 5.215a2.486 2.486 0 00-.741-2.574.5.5 0 01-.166-.334A2.512 2.512 0 0018.989 0H5.011a2.512 2.512 0 00-2.5 2.307.5.5 0 01-.166.334A2.486 2.486 0 001.6 5.215a2.423 2.423 0 001.147 1.436.5.5 0 01.253.435V11a.5.5 0 01-1 0 1 1 0 00-2 0v3a1 1 0 002 0 .5.5 0 011 0v1.25a5.009 5.009 0 003.673 4.821A.444.444 0 017 20.5a.5.5 0 01-.5.5.626.626 0 01-.577-.385A1 1 0 004 21v2a1 1 0 001.923.385.625.625 0 011.154 0A1 1 0 009 23v-2.25a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V23a1 1 0 001.923.385.625.625 0 011.154 0A1 1 0 0020 23v-2a1 1 0 00-1.923-.385.626.626 0 01-.577.385.5.5 0 01-.5-.5.444.444 0 01.327-.429A5.009 5.009 0 0021 15.25V14a.5.5 0 011 0 1 1 0 002 0v-3a1 1 0 00-1-1zm-4 5.25a3 3 0 01-3 3H8a3 3 0 01-3-3V7.086a.5.5 0 01.249-.432 2.56 2.56 0 00.569-.45.248.248 0 01.364 0 2.475 2.475 0 003.635 0 .251.251 0 01.366 0 2.474 2.474 0 003.634 0 .251.251 0 01.366 0 2.475 2.475 0 003.635 0 .248.248 0 01.364 0 2.56 2.56 0 00.569.45.5.5 0 01.249.432z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});