define("ember-svg-jar/inlined/road-sign-road-widens-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-road-widens-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M16 7a1 1 0 00-1 1v3a.249.249 0 01-.065.168l-2.242 2.472-.007.008a1.49 1.49 0 00-.436 1.057V17.5a1 1 0 002 0V15a.249.249 0 01.065-.168l2.242-2.472.007-.008A1.511 1.511 0 0017 11.293V8a1 1 0 00-1-1zM11.339 13.677l-2.274-2.5A.249.249 0 019 11V8a1 1 0 00-2 0v3.293a1.477 1.477 0 00.4 1.022l.007.008 2.275 2.5A.249.249 0 019.75 15v2.5a1 1 0 002 0v-2.793a1.464 1.464 0 00-.4-1.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});