define("ember-svg-jar/inlined/road-sign-light-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-light-guide</title><path d=\"M12.176 8.276a.249.249 0 00-.352 0l-5 4.948a.252.252 0 00-.074.178v2.934a.25.25 0 00.427.177l3.939-3.94a1.251 1.251 0 011.768 0l3.939 3.94a.25.25 0 00.427-.177V13.4a.252.252 0 00-.074-.178z\"/><path d=\"M12.177 13.987a.252.252 0 00-.354 0l-5 5a.251.251 0 00-.073.177v3.172a.25.25 0 00.427.177l3.939-3.94a1.251 1.251 0 011.768 0l3.939 3.939a.25.25 0 00.427-.176v-3.172a.251.251 0 00-.073-.177z\"/><path d=\"M12.177 19.987a.251.251 0 00-.354 0l-3.585 3.586a.25.25 0 00.177.427h7.171a.25.25 0 00.177-.427zM16.5 0h-9a.75.75 0 00-.75.75v4.086a.25.25 0 00.427.177l3.939-3.94a1.251 1.251 0 011.768 0l3.939 3.939a.25.25 0 00.427-.176V.75A.75.75 0 0016.5 0z\"/><path d=\"M12.177 2.487a.252.252 0 00-.354 0l-5 5a.251.251 0 00-.073.177v2.924a.25.25 0 00.426.178l3.943-3.9a1.251 1.251 0 011.765 0l3.94 3.9a.25.25 0 00.426-.178V7.664a.249.249 0 00-.073-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});