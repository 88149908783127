define("ember-svg-jar/inlined/style-one-pin-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-target</title><path d=\"M13.75 9.5a.75.75 0 01.75-.75h.588a.249.249 0 00.2-.1.252.252 0 00.036-.224 3.511 3.511 0 00-2.249-2.249.252.252 0 00-.224.036.249.249 0 00-.1.2V7a.75.75 0 01-1.5 0v-.588a.249.249 0 00-.1-.2.252.252 0 00-.224-.036 3.511 3.511 0 00-2.253 2.247.252.252 0 00.036.224.249.249 0 00.2.1h.59a.75.75 0 010 1.5h-.588a.249.249 0 00-.2.1.252.252 0 00-.036.224 3.511 3.511 0 002.249 2.249.252.252 0 00.224-.036.249.249 0 00.1-.2V12a.75.75 0 011.5 0v.588a.249.249 0 00.1.2.252.252 0 00.224.036 3.511 3.511 0 002.249-2.249.252.252 0 00-.036-.224.249.249 0 00-.2-.1H14.5a.75.75 0 01-.75-.751z\"/><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm7.25 9.5a.75.75 0 01-.75.75h-1.365a.251.251 0 00-.246.2 4.983 4.983 0 01-3.937 3.937.25.25 0 00-.2.245V16a.75.75 0 01-1.5 0v-1.366a.25.25 0 00-.2-.245 4.983 4.983 0 01-3.937-3.937.25.25 0 00-.245-.2H5.5a.75.75 0 010-1.5h1.365a.251.251 0 00.246-.2 4.983 4.983 0 013.937-3.937.25.25 0 00.2-.245V3a.75.75 0 011.5 0v1.366a.25.25 0 00.2.245 4.983 4.983 0 013.937 3.937.25.25 0 00.245.2h1.37a.75.75 0 01.75.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});