define("ember-svg-jar/inlined/casino-chip-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chip-buy</title><circle cx=\"12\" cy=\"14\" r=\"2.25\"/><path d=\"M9.353 11.078a.251.251 0 00.306 0 3.726 3.726 0 011.4-.7.25.25 0 00.187-.242V8.337a.249.249 0 00-.293-.246A5.98 5.98 0 007.9 9.62a.251.251 0 00.02.38zM12.75 10.127a.25.25 0 00.188.242 3.729 3.729 0 011.4.7.251.251 0 00.306 0L16.08 10a.251.251 0 00.02-.383 5.98 5.98 0 00-3.057-1.529.25.25 0 00-.293.246zM17.354 11.289a.254.254 0 00-.166-.131.251.251 0 00-.207.044l-1.433 1.074a.252.252 0 00-.081.3 3.744 3.744 0 010 2.856.251.251 0 00.081.3l1.433 1.068a.246.246 0 00.206.044.254.254 0 00.167-.131 6.011 6.011 0 000-5.422zM8.452 15.724a.252.252 0 00.081-.3 3.744 3.744 0 010-2.856.251.251 0 00-.081-.3L7.019 11.2a.246.246 0 00-.206-.044.254.254 0 00-.167.131 6.011 6.011 0 000 5.422.254.254 0 00.166.131.251.251 0 00.207-.044zM14.647 16.922a.251.251 0 00-.306 0 3.726 3.726 0 01-1.4.7.25.25 0 00-.187.242v1.79a.249.249 0 00.293.246 5.98 5.98 0 003.053-1.52.251.251 0 00-.02-.38zM11.25 17.873a.25.25 0 00-.188-.242 3.729 3.729 0 01-1.4-.7.251.251 0 00-.306 0L7.92 18a.251.251 0 00-.02.383 5.98 5.98 0 003.057 1.529.25.25 0 00.293-.246z\"/><path d=\"M24 3.75a3 3 0 00-3-3H3a3 3 0 00-3 3v16.5a3 3 0 003 3h18a3 3 0 003-3zM10.25 3a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zM22 20.25a1 1 0 01-1 1H3a1 1 0 01-1-1V6.75a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});