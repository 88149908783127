define("ember-svg-jar/inlined/zip-file-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zip-file-sync</title><path d=\"M17.726 9.51A.248.248 0 0018 9.267v-4.06a1.5 1.5 0 00-.439-1.06L13.854.439A1.5 1.5 0 0012.793 0H1.5A1.5 1.5 0 000 1.5v19A1.5 1.5 0 001.5 22h7.75a.248.248 0 00.25-.247V17.5c0-4.335 3.039-8.46 8.226-7.99zM6 18.5a1 1 0 111-1 1 1 0 01-1 1zM6 15a1 1 0 111-1 1 1 0 01-.995 1zm0-3.5a1 1 0 111-1 1 1 0 01-.995 1zm1.5-4.9a1.968 1.968 0 01-1.479.67 1.975 1.975 0 01-2.005-2.221l.49-3.127A.5.5 0 015 1.5h2a.5.5 0 01.494.423l.485 3.111v.015A1.985 1.985 0 017.508 6.6z\"/><path d=\"M22.77 18.6a1 1 0 00-1.256.582 4.046 4.046 0 01-1.278 1.931 4.165 4.165 0 01-5.656-.472.255.255 0 01.014-.349l1.717-1.717a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.854.353l.974-.974a.244.244 0 01.353.008 6.085 6.085 0 0010.211-2.2 1 1 0 00-.622-1.267zM23.691 11.772a.5.5 0 00-.545.109l-1.03 1.03a.244.244 0 01-.352-.007 6.1 6.1 0 00-10.2 2.225 1 1 0 001.88.684 4.018 4.018 0 011.27-1.915 4.155 4.155 0 015.639.435.255.255 0 01-.012.35l-1.655 1.655a.5.5 0 00.354.853h4.46a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});