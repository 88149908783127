define("ember-svg-jar/inlined/giraffe-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>giraffe-body</title><path d=\"M22.624 4.04V1a1 1 0 00-2 0v1.482a2.843 2.843 0 00-.5-.188V1a1 1 0 00-2 0v1.79a2.635 2.635 0 00-2.775-.67.749.749 0 00-.553.859 2.871 2.871 0 002.338 2.489c-.985 2.048-2.94 5.372-5.76 6.782-1.523.761-3.117 1.378-4.509 2.208a5.043 5.043 0 00-4.685.086A3.812 3.812 0 00.124 17.75a1 1 0 002 0 1.859 1.859 0 011.045-1.467 2.888 2.888 0 011.761-.34 7.794 7.794 0 00-2.056 5.807v1.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.75a3.5 3.5 0 017 0v1.75a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.75c0-5-.509-7 3.491-14 0 0 2.024 1.4 3.507.5 1.7-1.032.984-2.845-.248-4.21zM9.372 16.75a1 1 0 111-1 1 1 0 01-1 1zm3.25 1a1 1 0 111-1 1 1 0 01-1 1zm1.25-3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});