define("ember-svg-jar/inlined/messages-bubble-square-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-lock-alternate</title><path d=\"M13.187 14.383a.219.219 0 00-.145-.383H8.5a1 1 0 00-.664.252L5 16.773V15a1 1 0 00-1-1H2.25a.251.251 0 01-.25-.25V2.25A.25.25 0 012.25 2h18a.25.25 0 01.25.25v7.072a.252.252 0 00.166.236 4.982 4.982 0 011.429.785.251.251 0 00.405-.2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v12.053A1.953 1.953 0 002 16h1v3a1 1 0 001.664.748L8.88 16h3.085a.249.249 0 00.235-.166 3.491 3.491 0 01.987-1.451z\"/><path d=\"M22.5 15.5v-1.25a3.5 3.5 0 00-7 0v1.25A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.5-1.5zM20 20.25a1 1 0 11-1-1 1 1 0 011 1zm-1-7.5a1.5 1.5 0 011.5 1.5v1.25h-3v-1.25a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});