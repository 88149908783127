define("ember-svg-jar/inlined/style-two-pin-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-target</title><path d=\"M13.75 9.541a.75.75 0 01.75-.75h.588a.249.249 0 00.2-.1.252.252 0 00.036-.224 3.511 3.511 0 00-2.249-2.249.252.252 0 00-.224.036.249.249 0 00-.1.2v.588a.75.75 0 01-1.5 0v-.589a.249.249 0 00-.1-.2.252.252 0 00-.224-.036 3.511 3.511 0 00-2.253 2.247.252.252 0 00.036.224.249.249 0 00.2.1h.59a.75.75 0 010 1.5h-.588a.249.249 0 00-.2.1.252.252 0 00-.036.224 3.509 3.509 0 002.249 2.249.252.252 0 00.224-.036.249.249 0 00.1-.2v-.588a.75.75 0 011.5 0v.588a.249.249 0 00.1.2.252.252 0 00.224.036 3.509 3.509 0 002.249-2.249.252.252 0 00-.036-.224.249.249 0 00-.2-.1H14.5a.75.75 0 01-.75-.747z\"/><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm6.5 10.291h-1.365a.251.251 0 00-.246.2 4.983 4.983 0 01-3.937 3.937.25.25 0 00-.2.245v1.366a.75.75 0 01-1.5 0v-1.364a.25.25 0 00-.2-.245 4.983 4.983 0 01-3.937-3.937.25.25 0 00-.245-.2H5.5a.75.75 0 010-1.5h1.365a.251.251 0 00.246-.2 4.983 4.983 0 013.937-3.937.25.25 0 00.2-.245v-1.37a.75.75 0 011.5 0v1.366a.25.25 0 00.2.245 4.983 4.983 0 013.937 3.937.25.25 0 00.245.2h1.37a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});