define("ember-svg-jar/inlined/multiple-actions-location.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-location</title><path d=\"M17.362 9.715a.5.5 0 00.123-.917 5.993 5.993 0 00-5.461-.264.5.5 0 00-.182.771 7.48 7.48 0 011.3 2.387.5.5 0 00.86.164 6.5 6.5 0 013.36-2.141z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.275-.119A.372.372 0 0012 13.6z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.707 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm0 3.25A1.75 1.75 0 1117.25 16 1.752 1.752 0 0119 14.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});