define("ember-svg-jar/inlined/video-game-mario-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-cloud</title><path d=\"M15.449 14.007h-7.9a.5.5 0 00-.4.8 5.4 5.4 0 008.706 0 .5.5 0 00-.4-.8zM10 10.257a.75.75 0 00.75-.75v-2a.75.75 0 10-1.5 0v2a.75.75 0 00.75.75zM13.5 10.257a.75.75 0 00.75-.75v-2a.75.75 0 10-1.5 0v2a.75.75 0 00.75.75z\"/><path d=\"M18.977 8.528a.522.522 0 01-.477-.521 6.5 6.5 0 10-13 0 .523.523 0 01-.478.521A5.5 5.5 0 005.283 19.5a1 1 0 01.7.323 8.128 8.128 0 0012.04 0 1 1 0 01.7-.323 5.5 5.5 0 00.26-10.975zm-.477 8.979h-.687a1 1 0 00-.8.406 6.152 6.152 0 01-10.018 0 1 1 0 00-.8-.406H5.5a3.5 3.5 0 010-7h1a1 1 0 001-1v-1.5a4.5 4.5 0 119 0v1.5a1 1 0 001 1h1a3.5 3.5 0 010 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});