define("ember-svg-jar/inlined/space-rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-rocket</title><path d=\"M23.223 17.477l-.367-.734a.993.993 0 01-.106-.447V4.769a1 1 0 00-.086-.406L21.121.891a1.5 1.5 0 00-2.742 0l-1.543 3.472a1 1 0 00-.086.406V8.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V4a2 2 0 00-2-2h-9.5a2 2 0 00-2 2v19a1 1 0 001 1h2.5a1 1 0 001-1v-3a1 1 0 011-1h2a1 1 0 011 1v3a1 1 0 001 1h3a1 1 0 001-1V11.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v4.8a.993.993 0 01-.106.447l-.367.735a5.037 5.037 0 00-.527 2.235v2.743a1.5 1.5 0 002.171 1.34l1.382-.691a1 1 0 01.894 0l1.382.691a1.494 1.494 0 001.459-.066 1.492 1.492 0 00.712-1.276v-2.745a5.044 5.044 0 00-.527-2.236zM11.428 7.958A16.262 16.262 0 0110.4 9.381a1 1 0 00-.22.609 3.452 3.452 0 01-3.441 3.392 1 1 0 00-.61.208c-.4.307-.778.579-1.139.808a4.361 4.361 0 01-2.071.824 1.136 1.136 0 01-.838-.33c-.427-.426-.434-1.092-.024-1.979a.75.75 0 011-.367.76.76 0 01.432.6.251.251 0 00.374.186c.18-.105.383-.233.6-.383a.249.249 0 00.022-.4 3.451 3.451 0 114.9-4.823.255.255 0 00.2.09.252.252 0 00.2-.1c.234-.326.423-.619.571-.87a.249.249 0 00-.2-.376.714.714 0 01-.612-.421.75.75 0 01.357-1c.339-.161 1.376-.653 2.03 0 .735.743.069 2.015-.503 2.909zm9.322 7.311a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V5.194a1 1 0 01.086-.406l.685-1.543a.251.251 0 01.458 0l.685 1.543a1 1 0 01.086.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});