define("ember-svg-jar/inlined/smiley-sleepy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sleepy</title><path d=\"M19 7.5a2.5 2.5 0 01-2.31-1.543l-.032-.081a.249.249 0 00-.409-.091A2.491 2.491 0 0114.5 6.5h-3a2.5 2.5 0 01-2.1-3.863.25.25 0 000-.273A2.489 2.489 0 019 1a2.682 2.682 0 01.014-.27A.249.249 0 008.7.463a12 12 0 1014.47 7.153A.185.185 0 0023 7.5zm-4.857 1.065a1 1 0 011.291.579A1.209 1.209 0 0016.5 10a1.207 1.207 0 001.065-.856 1 1 0 111.869.712 3.08 3.08 0 01-5.869 0 1 1 0 01.578-1.291zM12 14a2.5 2.5 0 012.5 2.5v1a2.5 2.5 0 01-5 0v-1A2.5 2.5 0 0112 14zM9.356 8.565a1 1 0 01.578 1.291A3.184 3.184 0 017 12a3.186 3.186 0 01-2.935-2.144 1 1 0 111.869-.712A1.209 1.209 0 007 10a1.207 1.207 0 001.065-.856 1 1 0 011.291-.579z\"/><path d=\"M23.924.617A1 1 0 0023 0h-4a1 1 0 000 2h.982a.25.25 0 01.177.427l-1.866 1.866A1 1 0 0019 6h4a1 1 0 000-2h-.982a.25.25 0 01-.177-.427l1.866-1.866a1 1 0 00.217-1.09zM14.5 3a.243.243 0 01-.172-.414l.879-.879A1 1 0 0014.5 0h-3a1 1 0 000 2 .243.243 0 01.172.414l-.879.879A1 1 0 0011.5 5h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});