define("ember-svg-jar/inlined/love-gift-diamond-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-gift-diamond-1</title><path d=\"M5.625 2.25h-1a.25.25 0 01-.25-.25V1a.75.75 0 00-1.5 0v1a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h1a.25.25 0 01.25.25v1a.75.75 0 001.5 0V4a.25.25 0 01.25-.25h1a.75.75 0 000-1.5zM10.875 16.307V7.693a.251.251 0 00-.162-.235L7.463 6.24a.25.25 0 00-.338.235v11.05a.25.25 0 00.338.235l3.25-1.219a.251.251 0 00.162-.234zM7.948 4.82l3.338 1.251a.249.249 0 00.264-.057l1.059-1.058a.252.252 0 00.052-.279l-1.625-3.656a.25.25 0 00-.424-.055L7.84 4.43a.25.25 0 00.108.39zM22.3 19.18l-3.338-1.251a.249.249 0 00-.264.057l-1.059 1.058a.25.25 0 00-.051.279l1.625 3.656a.25.25 0 00.423.055l2.771-3.464a.249.249 0 00-.107-.39zM22.787 6.24l-3.25 1.218a.251.251 0 00-.162.235v8.614a.249.249 0 00.162.234l3.25 1.219a.25.25 0 00.338-.234V6.474a.25.25 0 00-.338-.234zM12.609 19.044l-1.059-1.059a.251.251 0 00-.264-.057L7.948 19.18a.25.25 0 00-.108.39l2.772 3.464a.251.251 0 00.424-.055l1.625-3.656a.252.252 0 00-.052-.279zM16.2 19.9a.249.249 0 00-.228-.148h-1.7a.249.249 0 00-.228.148l-1.667 3.75a.25.25 0 00.228.352h5.034a.25.25 0 00.228-.352zM17.641 4.956L18.7 6.014a.249.249 0 00.264.057L22.3 4.82a.249.249 0 00.107-.39L19.638.966a.251.251 0 00-.424.055l-1.625 3.656a.252.252 0 00.052.279zM14.047 4.1a.25.25 0 00.228.148h1.7A.249.249 0 0016.2 4.1L17.87.352A.25.25 0 0017.642 0h-5.033a.251.251 0 00-.229.352zM16.211 5.75h-2.172a.251.251 0 00-.177.073l-1.414 1.414a.251.251 0 00-.073.177v9.172a.251.251 0 00.073.177l1.414 1.414a.251.251 0 00.177.073h2.172a.251.251 0 00.177-.073l1.412-1.414a.251.251 0 00.073-.177V7.414a.251.251 0 00-.073-.177l-1.412-1.414a.251.251 0 00-.177-.073z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});