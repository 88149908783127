define("ember-svg-jar/inlined/single-man-actions-upload.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-upload</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.851 6.467a.5.5 0 01-.451.283H19a.5.5 0 00-.5.5v2.25a1 1 0 01-2 0v-2.25a.5.5 0 00-.5-.5h-.9a.5.5 0 01-.391-.812l2.4-3a.517.517 0 01.782 0l2.4 3a.5.5 0 01.06.529zM7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.258a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.36 6.36 0 00.929-.07.5.5 0 01.425.139.5.5 0 01.144.425A3 3 0 014 5a2.828 2.828 0 01.1-.742zM11.443 12.021a.5.5 0 00-.225-.343 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.7-3.67.5.5 0 00.111-.398z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});