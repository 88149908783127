define("ember-svg-jar/inlined/concert-speakers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-speakers</title><path d=\"M23.249 11.832A.5.5 0 0123 11.4V3.131a1 1 0 00-2 0v8a.5.5 0 01-.5.5h-2a1.5 1.5 0 00-1.5 1.5v8a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-8a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 01-.5-.5v-8a1 1 0 00-2 0V11.4a.5.5 0 01-.249.433A1.5 1.5 0 000 13.131v9a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-.751-1.299zM3.5 13.631a1 1 0 11-1 1 1 1 0 011-1zm0 7.5a1.75 1.75 0 111.75-1.75 1.751 1.751 0 01-1.75 1.75zm17-7.5a1 1 0 11-1 1 1 1 0 011-1zm0 7.5a1.75 1.75 0 111.75-1.75 1.751 1.751 0 01-1.75 1.75z\"/><path d=\"M10 9.677a2 2 0 004 0V5.129a.5.5 0 01.347-.476l2.5-.8a.5.5 0 01.653.476V5.44a.25.25 0 01-.222.249A2 2 0 1019.5 7.677V3.639a2 2 0 00-2.612-1.9l-3.5 1.125A1.994 1.994 0 0012 4.764V7.44a.25.25 0 01-.222.249A2 2 0 0010 9.677zM5.773 6.626a1.5 1.5 0 001.834-1.065L8.4 2.587a.251.251 0 01.429-.1l.631.715a.75.75 0 001.124-.994L9.331.793a1.249 1.249 0 00-2.145.507l-.532 2a.5.5 0 01-.486.372 1.5 1.5 0 00-.4 2.95z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});