define("ember-svg-jar/inlined/religion-angel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-angel</title><path d=\"M12 8.25a29.3 29.3 0 01-6.284-.644.5.5 0 00-.541.225A7.951 7.951 0 004 12v4a8 8 0 0016 0v-4a7.951 7.951 0 00-1.175-4.169.5.5 0 00-.541-.225A29.3 29.3 0 0112 8.25zM12 22a6.006 6.006 0 01-6-6v-3.332a.5.5 0 01.312-.463 4.393 4.393 0 002.2-1.992.249.249 0 01.375-.073c1.1.887 3.542 2.122 8.632 2.326a.5.5 0 01.48.5V16A6.006 6.006 0 0112 22zM12 0C8.286 0 2 .737 2 3.5S8.286 7 12 7s10-.737 10-3.5S15.714 0 12 0zm0 5a19.962 19.962 0 01-7.107-1.037.5.5 0 010-.926A19.962 19.962 0 0112 2a19.971 19.971 0 017.107 1.037.5.5 0 010 .926A19.971 19.971 0 0112 5z\"/><path d=\"M10.53 15.47a.75.75 0 000-1.061 2.306 2.306 0 00-3.182 0A.751.751 0 008.41 15.47a.768.768 0 011.06 0 .749.749 0 001.06 0zM13.47 14.409a.75.75 0 001.06 1.061.768.768 0 011.06 0 .751.751 0 001.062-1.061 2.3 2.3 0 00-3.182 0zM13.237 18.237a1.789 1.789 0 01-2.475 0A.75.75 0 109.7 19.3a3.25 3.25 0 004.6 0 .75.75 0 00-1.061-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});