define("ember-svg-jar/inlined/professions-woman-chef-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-chef-2</title><path d=\"M2.847 14.024a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.116 3.962 3.962 0 002.668-.854.249.249 0 01.3-.034 6.931 6.931 0 006.958.023.249.249 0 01.3.039 4.16 4.16 0 002.7.886 3.8 3.8 0 002.921-1.119.5.5 0 00-.231-.8C18.468 13.222 19 10 19 8.348a.248.248 0 01.136-.222.251.251 0 01.26.019 3.232 3.232 0 002.371.568.284.284 0 00.166-.1.249.249 0 00.054-.166A3.246 3.246 0 0020.413 5.8a.25.25 0 010-.428 3.247 3.247 0 001.574-2.646.282.282 0 00-.066-.181.257.257 0 00-.154-.086A3.222 3.222 0 0018.633 3.8a.25.25 0 01-.433-.03 6.986 6.986 0 00-12.15-.439.248.248 0 01-.044.053A3.841 3.841 0 004.75 6.236V7a.25.25 0 00.125.217.25.25 0 01.125.216c0 2.617.578 5.714-2.153 6.591zM15.1 3.1a.25.25 0 01-.182.444l-5.1-.51a.25.25 0 01-.091-.471A4.919 4.919 0 0115.1 3.1zm1.65 4.15a.25.25 0 01.25.25V9A5 5 0 017 9V7.5a.25.25 0 01.25-.25z\"/><path d=\"M10.38 11.712a.641.641 0 00.317.72 2.4 2.4 0 00.435.179 4.157 4.157 0 00.863.125h.022a2.987 2.987 0 00.853-.125 2.441 2.441 0 00.435-.181.639.639 0 00-.3-1.2H11a.64.64 0 00-.62.482zM16.435 24H23a.5.5 0 00.5-.5v-2.6a2.463 2.463 0 00-.967-1.978 11.994 11.994 0 00-2.45-1.4.251.251 0 00-.319.107l-3.542 6a.251.251 0 000 .255.251.251 0 00.213.116zM.5 20.9v2.6a.5.5 0 00.5.5h7.326a.249.249 0 00.186-.083l.009-.01 3.048-3.042a.249.249 0 00.01-.342l-3.527-3.966a.5.5 0 00-.467-.16 14.53 14.53 0 00-6.118 2.52A2.461 2.461 0 00.5 20.9zM18.31 16.962a.252.252 0 00-.161-.145c-.244-.07-.5-.136-.76-.2a.25.25 0 00-.247.078v.005l-6.36 6.88a.25.25 0 00.183.42h3.191a.251.251 0 00.219-.128l3.925-6.688a.236.236 0 00.033-.1.24.24 0 00-.023-.122z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});