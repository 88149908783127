define("ember-svg-jar/inlined/content-typing-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-typing-machine</title><path d=\"M21.5 22.5H21v-5.609a2.449 2.449 0 00-2.5-2.391h-3.316l-.566 1.132a2.927 2.927 0 01-5.236 0L8.816 14.5H5.5A2.449 2.449 0 003 16.891V22.5h-.5a.75.75 0 000 1.5h19a.75.75 0 000-1.5zM6.75 20a1 1 0 111 1 1 1 0 01-1-1zm8.5 0a1 1 0 111 1 1 1 0 01-1-1zM12 19a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M23 12.5v-1.75a1 1 0 00-1-1 .987.987 0 00-.95.75H2.95A.987.987 0 002 9.75a1 1 0 00-1 1v1.75a1 1 0 000 2v2.25a1 1 0 002 0v-4a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25v4a1 1 0 002 0V14.5a1 1 0 000-2zM5 9.5a1 1 0 001-1V2.25A.25.25 0 016.25 2H16.4a.248.248 0 01.171.068l1.35 1.272a.247.247 0 01.079.182V8.5a1 1 0 002 0V3.414A1.986 1.986 0 0019.414 2l-1.5-1.414A1.986 1.986 0 0016.5 0H6a2 2 0 00-2 2v6.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});