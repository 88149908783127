define("ember-svg-jar/inlined/single-neutral-actions-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-player</title><path d=\"M.5 17.722h1.806a.249.249 0 01.249.228L3 23.254a.5.5 0 00.5.458h3.988a.5.5 0 00.5-.458l.442-5.3a.249.249 0 01.249-.228h1.558a.25.25 0 00.249-.25s.01-5.565.013-5.9a.325.325 0 00-.021-.139A5.488 5.488 0 000 13.728v3.494a.5.5 0 00.5.5z\"/><circle cx=\"5.491\" cy=\"3.744\" r=\"3.494\"/><path d=\"M13.824 23.75h8.294A1.884 1.884 0 0024 21.869v-8.295a1.884 1.884 0 00-1.882-1.881h-8.294a1.884 1.884 0 00-1.882 1.881v8.3a1.884 1.884 0 001.882 1.876zm6.537-6.028a.934.934 0 01-.451.8l-3.028 1.817a.928.928 0 01-1.406-.8V15.9a.928.928 0 011.4-.8l3.029 1.817a.934.934 0 01.456.805zM.5 17.722h1.806a.249.249 0 01.249.228L3 23.254a.5.5 0 00.5.458h3.988a.5.5 0 00.5-.458l.442-5.3a.249.249 0 01.249-.228h1.558a.25.25 0 00.249-.25s.01-5.565.013-5.9a.325.325 0 00-.021-.139A5.488 5.488 0 000 13.728v3.494a.5.5 0 00.5.5z\"/><circle cx=\"5.491\" cy=\"3.744\" r=\"3.494\"/><path d=\"M13.824 23.75h8.294A1.884 1.884 0 0024 21.869v-8.295a1.884 1.884 0 00-1.882-1.881h-8.294a1.884 1.884 0 00-1.882 1.881v8.3a1.884 1.884 0 001.882 1.876zm6.537-6.028a.934.934 0 01-.451.8l-3.028 1.817a.928.928 0 01-1.406-.8V15.9a.928.928 0 011.4-.8l3.029 1.817a.934.934 0 01.456.805z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});