define("ember-svg-jar/inlined/railroad-train-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-train-1</title><path d=\"M6.555 18.418a1 1 0 00-1.387.277l-2.5 3.75a1 1 0 001.664 1.11l2.5-3.75a1 1 0 00-.277-1.387zM21.332 22.445l-2.5-3.75a1 1 0 10-1.664 1.11l2.5 3.75a1 1 0 001.664-1.11zM20 15V4.511A4.516 4.516 0 0015.489 0H8.511A4.516 4.516 0 004 4.511V15a2.23 2.23 0 001.8 2.192A34.368 34.368 0 0012 18a34.407 34.407 0 006.211-.81A2.218 2.218 0 0020 15zM13 2.25a.25.25 0 01.25-.25h2.239A2.513 2.513 0 0118 4.511V8.34a.25.25 0 01-.306.244 24.753 24.753 0 00-4.454-.561.251.251 0 01-.24-.25zM6 4.511A2.513 2.513 0 018.511 2h2.239a.25.25 0 01.25.25v5.523a.251.251 0 01-.24.25 24.753 24.753 0 00-4.454.561A.25.25 0 016 8.34zM7.75 13h-1.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1.5a1 1 0 010 2zm6.5 2.852a.251.251 0 01-.22.249 17.606 17.606 0 01-2.03.149 17.606 17.606 0 01-2.03-.15.251.251 0 01-.22-.249V14a.253.253 0 01.25-.25h4a.253.253 0 01.25.25zM17.75 13h-1.5a1 1 0 010-2h1.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});