define("ember-svg-jar/inlined/social-media-google-plus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-google-plus-1</title><path d=\"M15.46 10.75h-7a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h4.28a4.26 4.26 0 01-4.53 3.5A5.25 5.25 0 1111.7 8a.5.5 0 00.67 0l1.47-1.36a.5.5 0 000-.73 8.25 8.25 0 10-5.59 14.34C14 20.25 16 16 16 12v-.8a.5.5 0 00-.54-.45zM23.5 10.75H22v-1.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.5h-1.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H19v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});