define("ember-svg-jar/inlined/file-xls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-xls</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.059 14.719a.624.624 0 01-.28.838.618.618 0 01-.279.066.625.625 0 01-.559-.346s-.861-1.71-.881-1.761a.065.065 0 00-.121 0l-.88 1.759a.625.625 0 01-.559.346.618.618 0 01-.279-.066.624.624 0 01-.28-.838l1.305-2.61a.248.248 0 000-.222L4.941 9.277a.625.625 0 011.118-.558l.875 1.75a.072.072 0 00.132 0l.875-1.751a.625.625 0 011.118.558l-1.3 2.61a.248.248 0 000 .222zm4.441.9h-2A1.626 1.626 0 019.875 14V9a.625.625 0 111.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zm2.927-4.758l1.84 1.227a1.927 1.927 0 01-1.07 3.531H15.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242L15.733 11.9A1.927 1.927 0 0116.8 8.373h1.7a.625.625 0 010 1.25h-1.7a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});