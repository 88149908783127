define("ember-svg-jar/inlined/idea-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>idea-settings</title><path d=\"M24 11.5a1.5 1.5 0 00-1.5-1.5h-1.662a.248.248 0 01-.241-.188 12.989 12.989 0 00-.657-1.989.249.249 0 01.051-.279l1.2-1.2a1.5 1.5 0 000-2.121l-1.414-1.416a1.5 1.5 0 00-2.121 0l-1.2 1.2a.25.25 0 01-.279.052 12.833 12.833 0 00-1.99-.659.25.25 0 01-.187-.239V1.5A1.5 1.5 0 0012.5 0h-1A1.5 1.5 0 0010 1.5v1.661a.25.25 0 01-.187.242 12.952 12.952 0 00-1.989.656.248.248 0 01-.279-.051l-1.2-1.2a1.5 1.5 0 00-2.121 0L2.807 4.222a1.5 1.5 0 000 2.121l1.2 1.2a.249.249 0 01.051.279A13.136 13.136 0 003.4 9.813a.25.25 0 01-.239.187H1.5A1.5 1.5 0 000 11.5v1A1.5 1.5 0 001.5 14h1.661a.249.249 0 01.242.187 13.16 13.16 0 00.657 1.988.249.249 0 01-.051.279l-1.2 1.2a1.5 1.5 0 000 2.121l1.415 1.415a1.5 1.5 0 002.121 0l.934-.934a.25.25 0 01.4.073A2 2 0 009.5 21.5h.75a.249.249 0 01.25.25v.75a1.5 1.5 0 003 0v-.75a.249.249 0 01.25-.25h.75a2 2 0 001.819-1.168.25.25 0 01.4-.073l.933.934a1.5 1.5 0 002.121 0l1.415-1.415a1.5 1.5 0 000-2.121l-1.2-1.2a.251.251 0 01-.051-.28 12.989 12.989 0 00.657-1.989.249.249 0 01.244-.188H22.5a1.5 1.5 0 001.5-1.5zm-9.5 5.359V19a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-2.141a.49.49 0 00-.307-.461A4.993 4.993 0 016 11.582 5.808 5.808 0 0112 6a5.808 5.808 0 016 5.582 4.993 4.993 0 01-3.193 4.818.49.49 0 00-.307.459z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});