define("ember-svg-jar/inlined/volume-control-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-down-1</title><path d=\"M12.939 3.017a9.728 9.728 0 00-7.924 4.85.249.249 0 01-.221.133H3a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.731 9.731 0 007.924 4.85h.06a1 1 0 001-1V4.015a1 1 0 00-1.06-.998zM22.75 11.25h-6a1.25 1.25 0 000 2.5h6a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});