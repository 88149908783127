define("ember-svg-jar/inlined/photography-equipment-remote-shutter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-remote-shutter</title><path d=\"M13.811 9.117a.249.249 0 00-.187-.27 1.995 1.995 0 01-.755-3.489 8.481 8.481 0 012.216-1.286.062.062 0 00.04-.058V4a2 2 0 00-2-2h-1a.913.913 0 01-.5-.207L10.332.5A1.872 1.872 0 009.125 0h-2a1.872 1.872 0 00-1.207.5L4.625 1.793a.913.913 0 01-.5.207h-1a2 2 0 00-2 2v5a2 2 0 002 2h10a1.97 1.97 0 00.9-.219.251.251 0 00.094-.36 1.952 1.952 0 01-.308-1.304zM10.625 6.5a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5z\"/><path d=\"M13.654 6.332A.75.75 0 1014.6 7.5a5.58 5.58 0 017.058 0 .75.75 0 10.942-1.168 7.068 7.068 0 00-8.946 0z\"/><path d=\"M15.321 8.742a.75.75 0 10.942 1.167 2.944 2.944 0 013.724 0 .75.75 0 10.942-1.167 4.432 4.432 0 00-5.608 0zM18.125 11a4 4 0 00-4 4v7.5a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 001.5-1.5V15a4 4 0 00-4-4zm0 3.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-1.5 5.25h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});