define("ember-svg-jar/inlined/people-man-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-4</title><path d=\"M7.375 17.436a14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 21.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.606a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.48.175c-.083.1-1.26 2.889-4.145 2.889s-4.062-2.791-4.146-2.891a.5.5 0 00-.479-.173z\"/><path d=\"M15.587 16.5a7 7 0 003.413-6v-2a6.961 6.961 0 00-1.5-4.316c0-2.1-1.642-2.383-2.841-2.593S13 1.232 13 .5a.5.5 0 00-.169-.374.491.491 0 00-.392-.126C8.776.454 6.5 2.056 6.5 4.184A6.961 6.961 0 005 8.5v2a6.985 6.985 0 0010.587 6zM7 10.5v-2a4.876 4.876 0 01.326-1.743A.248.248 0 017.5 6.6a.252.252 0 01.224.051A6.6 6.6 0 0012 8a6.611 6.611 0 004.306-1.283.25.25 0 01.4.105A4.919 4.919 0 0117 8.5v2a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"10.75\" r=\"1\"/><circle cx=\"10\" cy=\"10.75\" r=\"1\"/><path d=\"M10.457 13.344a.638.638 0 00.317.719 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});