define("ember-svg-jar/inlined/cursor-double-click-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-double-click-2</title><path d=\"M10.642 6.011a5 5 0 015.21 3.773 1 1 0 101.94-.483A7 7 0 0010.5 4.016a7.111 7.111 0 00-6.48 6.444 7.018 7.018 0 005.138 7.293 1 1 0 10.526-1.93 5.015 5.015 0 01-3.67-5.213 5.075 5.075 0 014.628-4.599z\"/><path d=\"M11 20a9 9 0 01-9-9.256A9.107 9.107 0 0110.744 2 9 9 0 0120 11a1 1 0 002 0A11 11 0 0010.688 0 11.134 11.134 0 000 10.688 11 11 0 0011 22a1 1 0 000-2z\"/><path d=\"M23.509 16.431l-11.653-6.192a.925.925 0 00-1.256 1.242L16.793 23.5a.931.931 0 00.821.5h.024a.933.933 0 00.818-.542l1.685-3.709 3.348-1.674a.923.923 0 00.02-1.642z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});