define("ember-svg-jar/inlined/grid-ruler-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-ruler-alternate</title><path d=\"M15.25 17A1.752 1.752 0 0117 15.25h6.75A.25.25 0 0024 15V3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h12a.25.25 0 00.25-.25zM22 7.25a.25.25 0 01-.25.25h-14a.25.25 0 00-.25.25v14a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-2a.25.25 0 00-.25-.25H3A.75.75 0 013 18h2.75a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25H4A.75.75 0 014 14h1.75a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25H3A.75.75 0 013 10h2.75A.25.25 0 006 9.75v-2a.25.25 0 00-.25-.25h-3.5A.25.25 0 012 7.25v-1A.25.25 0 012.25 6h3.5A.25.25 0 006 5.75v-3.5A.25.25 0 016.25 2h1a.25.25 0 01.25.25v3.5a.25.25 0 00.25.25h2a.25.25 0 00.25-.25V3a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h2a.25.25 0 00.25-.25V4a.75.75 0 011.5 0v1.75a.25.25 0 00.25.25h2a.25.25 0 00.25-.25V3a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h2a.25.25 0 01.25.25z\"/><path d=\"M17 16.75a.253.253 0 00-.25.25v6.7a.251.251 0 00.3.246A3.774 3.774 0 0018.914 23L23 18.914a3.778 3.778 0 00.945-1.867.251.251 0 00-.246-.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});