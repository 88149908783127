define("ember-svg-jar/inlined/breakfast-cereal-bowl-spoon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-cereal-bowl-spoon</title><path d=\"M17.363 11.25a.5.5 0 01-.455-.708L21.29.989a1.142 1.142 0 112.137.805l-2.543 9.091a.5.5 0 01-.482.365zM6.5 9.75A2.253 2.253 0 004.25 12a.75.75 0 00.75.75h3a.75.75 0 00.75-.75A2.253 2.253 0 006.5 9.75zM14.5 11.125a2 2 0 00-2 2 .75.75 0 00.75.75h2.5a.75.75 0 00.75-.75 2 2 0 00-2-2zM13.017 10.125a.754.754 0 000-.751 1.752 1.752 0 00-3.032 0 .751.751 0 00.65 1.126h1.733a.754.754 0 00.649-.375z\"/><path d=\"M23.666 9.988a.759.759 0 00-1.015-.335.748.748 0 00-.337 1.007 1.534 1.534 0 01.175.693c0 1.941-4.308 4.1-10.489 4.1s-10.489-2.162-10.489-4.1S5.819 7.25 12 7.25a23.273 23.273 0 014.26.375.75.75 0 10.279-1.474A24.8 24.8 0 0012 5.75c-6.729 0-12 2.461-12 5.6v2.426c0 5.778 5.047 9.971 12 9.971s12-4.193 12-9.971v-2.423a3.015 3.015 0 00-.334-1.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});