define("ember-svg-jar/inlined/camping-tent-sleep", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camping-tent-sleep</title><path d=\"M23.536 22.787a2.009 2.009 0 00.429-1.66L21.834 9.749a2 2 0 00-.9-1.327L13 3.447V1.5a1 1 0 00-2 0v1.947L3.069 8.422a2 2 0 00-.9 1.327L.034 21.128a2.012 2.012 0 00.43 1.659 1.96 1.96 0 001.511.713h20.051a1.958 1.958 0 001.51-.713zM2.6 21.5a.5.5 0 01-.491-.592L4.09 10.331A.5.5 0 014.316 10l7.419-4.653a.5.5 0 01.531 0L19.685 10a.5.5 0 01.226.331l2 10.576a.5.5 0 01-.492.593z\"/><path d=\"M17 16h-6.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V18a2 2 0 00-2-2z\"/><circle cx=\"7\" cy=\"18\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});