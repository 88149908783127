define("ember-svg-jar/inlined/instrument-dholak-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-dholak-1</title><path d=\"M23.5 6.5V4c0-3.164-7.5-4-11.471-4C8.042 0 .5.836.5 4v2.5a11.486 11.486 0 004.249 8.92.25.25 0 01.072.293L2.125 21.9a1.5 1.5 0 102.75 1.2L7.4 17.306a.25.25 0 01.322-.132 11.337 11.337 0 002.566.7.251.251 0 01.213.248v4.347a1.5 1.5 0 003 0V18.12a.251.251 0 01.213-.248 11.337 11.337 0 002.566-.7.25.25 0 01.322.132l2.523 5.796a1.5 1.5 0 102.75-1.2l-2.7-6.187a.25.25 0 01.072-.293A11.486 11.486 0 0023.5 6.5zM3 6.983a.25.25 0 01.337-.235 14.3 14.3 0 001.474.452.25.25 0 01.189.239V9a1 1 0 01-2 0zM13 10.5a1 1 0 01-2 0V8.24a.251.251 0 01.256-.25c.5.012.973.014 1.488 0a.251.251 0 01.256.25zm6-3.055a.248.248 0 01.189-.242 14.445 14.445 0 001.474-.45.251.251 0 01.23.03.249.249 0 01.107.205V9a1 1 0 01-2 0zm2.321-3.634a.249.249 0 01.087.189.253.253 0 01-.086.189C20.485 4.913 17.077 6 12.029 6 6.9 6 3.445 4.883 2.651 4.227a.251.251 0 01-.091-.185.248.248 0 01.079-.19C3.409 3.128 6.873 2 12.029 2c5.048 0 8.455 1.087 9.292 1.811z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});