define("ember-svg-jar/inlined/receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt</title><path d=\"M24 1.24a1 1 0 00-1-.99H10.584c-1.362 0-2 1.268-2.025 2.468A98.587 98.587 0 017.7 16.042a.251.251 0 01-.247.208H4.5c-2.015 0-2.222 2.068-2.346 3.305-.193 1.922-.419 2.2-1.154 2.2a1 1 0 000 2h11.5c2.376 0 2.713-2.356 2.915-3.764.169-1.176.3-1.736.585-1.736h.4a.249.249 0 01.236.171 6.116 6.116 0 01.157.638c.2.94.567 2.69 2.7 2.69 4.061 0 4.5-11.539 4.5-16.483C24.019 3.8 24 1.24 24 1.24zM12.059 13.5a.75.75 0 110-1.5h6a.75.75 0 010 1.5zm-.25-8.75a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zm6.75 4.75h-5.75a.75.75 0 110-1.5h5.75a.75.75 0 010 1.5zM13.43 19.7c-.253 1.766-.446 2.048-.935 2.048H4.113a.25.25 0 01-.241-.315 10.6 10.6 0 00.267-1.681 4.786 4.786 0 01.28-1.5.214.214 0 01.081-.002h8.891a.25.25 0 01.243.306c-.096.397-.153.794-.204 1.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});