define("ember-svg-jar/inlined/database-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-check</title><path d=\"M8.7 21.391a.25.25 0 00.249-.359 7.932 7.932 0 01-.8-3 .249.249 0 00-.216-.231 12.8 12.8 0 01-6.149-2.211.25.25 0 00-.408.194V17.5c0 1.9 3.13 3.478 7.324 3.891zM1.784 11.09a.25.25 0 00-.408.194V13c0 1.772 2.736 3.272 6.524 3.8a.251.251 0 00.283-.219 7.9 7.9 0 01.874-2.808.25.25 0 00-.2-.366c-2.822-.257-5.507-1.042-7.073-2.317zM16.124 9.5a7.941 7.941 0 013.165.661.249.249 0 00.271-.048 2.261 2.261 0 00.816-1.613V6.784a.249.249 0 00-.407-.193C18.031 8.168 14.381 9 10.877 9s-7.156-.833-9.093-2.411a.25.25 0 00-.408.194V8.5c0 2.054 3.673 3.744 8.4 3.974a.253.253 0 00.2-.09A7.984 7.984 0 0116.124 9.5z\"/><ellipse cx=\"10.877\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M16.124 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.276 5.2l-2.9 3.874a1.493 1.493 0 01-1.1.6h-.111a1.483 1.483 0 01-1.055-.444l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.251.251 0 00.2.073.254.254 0 00.182-.1l2.723-3.638a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});