define("ember-svg-jar/inlined/smiley-hypnotized-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-hypnotized-alternate</title><path d=\"M9.5 16.75a1 1 0 000 2h5a1 1 0 000-2zM2.5 9A4.5 4.5 0 107 4.5 4.505 4.505 0 002.5 9zm7 0A2.5 2.5 0 117 6.5 2.5 2.5 0 019.5 9z\"/><circle cx=\"7\" cy=\"9.001\" r=\"1\"/><path d=\"M17 13.5A4.5 4.5 0 1012.5 9a4.505 4.505 0 004.5 4.5zm0-7A2.5 2.5 0 1114.5 9 2.5 2.5 0 0117 6.5z\"/><circle cx=\"17\" cy=\"9.001\" r=\"1\"/><path d=\"M23 10.75a1 1 0 00-1 1 10 10 0 01-20 0 1 1 0 00-2 0 12 12 0 0024 0 1 1 0 00-1-1zM2.553 4.145A1 1 0 003.9 3.7c.421-.855 1.974-1.45 3.1-1.45a1 1 0 000-2c-1.754 0-4.064.9-4.895 2.553a1 1 0 00.448 1.342zM17 2.25c1.126 0 2.679.6 3.105 1.447A1 1 0 0021.9 2.8C21.064 1.145 18.754.25 17 .25a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});