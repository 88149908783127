define("ember-svg-jar/inlined/style-three-pin-world", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-world</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm.19 16.212a.247.247 0 01-.19.088.254.254 0 01-.194-.09 9.128 9.128 0 01-1.5-2.627.249.249 0 01.234-.336h2.917a.25.25 0 01.205.107.246.246 0 01.029.229 9.223 9.223 0 01-1.501 2.629zm-2.127-4.462a.25.25 0 01-.248-.22 8.12 8.12 0 010-2.061.249.249 0 01.248-.219h3.872a.25.25 0 01.248.219 8.078 8.078 0 010 2.061.25.25 0 01-.248.22zM5.586 9.459a.249.249 0 01.247-.209h2.219a.247.247 0 01.185.082.251.251 0 01.064.192 9.355 9.355 0 000 1.951.251.251 0 01-.249.275H5.833a.25.25 0 01-.247-.21 6.346 6.346 0 010-2.081zm8.1-2.046a.246.246 0 01-.029.229.251.251 0 01-.205.108h-2.908a.251.251 0 01-.205-.108.246.246 0 01-.029-.229 9.186 9.186 0 011.5-2.622.248.248 0 01.386 0 9.214 9.214 0 011.494 2.622zM15.7 9.524a.25.25 0 01.249-.274h2.219a.249.249 0 01.247.209 6.346 6.346 0 010 2.081.25.25 0 01-.247.21h-2.22a.252.252 0 01-.249-.275 9.355 9.355 0 00.001-1.951zm1.993-2.145a.25.25 0 01-.218.371h-1.9a.251.251 0 01-.24-.181 10.462 10.462 0 00-1.249-2.74.25.25 0 01.3-.368 6.523 6.523 0 013.306 2.918zM9.616 4.461a.25.25 0 01.3.368 10.462 10.462 0 00-1.249 2.74.251.251 0 01-.24.181h-1.9a.25.25 0 01-.219-.371 6.523 6.523 0 013.308-2.918zM6.308 13.62a.249.249 0 01.219-.37h1.9a.25.25 0 01.24.18 10.462 10.462 0 001.249 2.74.25.25 0 01-.3.368 6.525 6.525 0 01-3.308-2.918zm8.077 2.918a.25.25 0 01-.3-.368 10.462 10.462 0 001.249-2.74.25.25 0 01.24-.18h1.9a.249.249 0 01.218.37 6.525 6.525 0 01-3.307 2.918z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});