define("ember-svg-jar/inlined/cd-album", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-album</title><path d=\"M13.5 18.5A6.5 6.5 0 107 12a6.5 6.5 0 006.5 6.5zm0-8A1.5 1.5 0 1112 12a1.5 1.5 0 011.5-1.5z\"/><path d=\"M22 1.5H2a2 2 0 00-2 2v17a2 2 0 002 2h20a2 2 0 002-2v-17a2 2 0 00-2-2zM22 20a.5.5 0 01-.5.5h-16A.5.5 0 015 20V4a.5.5 0 01.5-.5h16a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});