define("ember-svg-jar/inlined/car-dashboard-gear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-gear</title><path d=\"M21.25.25a2.746 2.746 0 00-1.15 5.243.251.251 0 01.146.227v5.03A.25.25 0 0120 11h-5.03a.251.251 0 01-.227-.145A2.756 2.756 0 0013.4 9.507a.251.251 0 01-.146-.227V5.72a.251.251 0 01.146-.227 2.746 2.746 0 10-3.643-3.639A.251.251 0 019.53 2H5.47a.251.251 0 01-.227-.146 2.75 2.75 0 100 2.292A.251.251 0 015.47 4h4.06a.251.251 0 01.227.145A2.756 2.756 0 0011.1 5.493a.251.251 0 01.146.227v3.56a.251.251 0 01-.146.227 2.739 2.739 0 000 4.986.251.251 0 01.146.227v3.58a.217.217 0 01-.129.2 2.753 2.753 0 00-1.364 1.356.251.251 0 01-.223.144H5.47a.251.251 0 01-.227-.146 2.75 2.75 0 100 2.292A.251.251 0 015.47 22h4.06a.251.251 0 01.227.146 2.746 2.746 0 103.643-3.639.251.251 0 01-.146-.227V14.7a.217.217 0 01.129-.2 2.753 2.753 0 001.364-1.356.251.251 0 01.223-.144h5.28a2 2 0 002-2V5.72a.251.251 0 01.146-.227A2.746 2.746 0 0021.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});