define("ember-svg-jar/inlined/like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>like</title><path d=\"M23.75 11.565a2.253 2.253 0 00-2.25-2.25h-6.726a.25.25 0 01-.244-.306 25.026 25.026 0 00.72-4.725 3.35 3.35 0 00-.757-2.3 2.349 2.349 0 00-1.748-.8 1.9 1.9 0 00-1.995 1.88A7.193 7.193 0 014 10.315H1a.75.75 0 00-.75.75v9a.75.75 0 00.75.75c4.532 0 6.009.635 7.312 1.193a8.044 8.044 0 003.688.807h6.5a2.251 2.251 0 002.019-3.244 2.251 2.251 0 001-3 2.251 2.251 0 001-3 2.251 2.251 0 001.231-2.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});