define("ember-svg-jar/inlined/cash-payment-bill-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bill-4</title><path d=\"M10.277 11.531a.75.75 0 001.5 0v-.187a.188.188 0 01.188-.188.75.75 0 000-1.5h-.053A.218.218 0 0111.7 9.5a1.757 1.757 0 00-3.31-.146L7.771 10.9a.265.265 0 01-.494-.1V9.281a.75.75 0 00-1.5 0v.188a.187.187 0 01-.187.187.75.75 0 000 1.5h.052a.219.219 0 01.21.156 1.756 1.756 0 003.312.146l.619-1.548a.266.266 0 01.494.1z\"/><path d=\"M16.09 2a1.5 1.5 0 00-1.5-1.5H3.09A1.5 1.5 0 001.59 2v18.5a1.5 1.5 0 001.5 1.5h8.864a1 1 0 000-2H4.59a1 1 0 01-1-1V3.5a1 1 0 011-1h8.5a1 1 0 011 1v7.84a1 1 0 00.321.734 1 1 0 001.679-.734z\"/><path d=\"M11.988 15.43l3.67 4.147a.249.249 0 01.063.166v2.544a2.63 2.63 0 00.306 1.071.251.251 0 00.226.142h5.907a.25.25 0 00.207-.391L20.929 21a.255.255 0 01-.044-.141V15.03c0-1.479-1.453-2.92-2.889-4.077a.249.249 0 00-.406.2v3.744a.251.251 0 00.08.2l.932.862a.75.75 0 01-1.019 1.1l-.4-.368-.96-.888-2.568-2.377a1.151 1.151 0 00-1.662.072 1.418 1.418 0 00-.005 1.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});