define("ember-svg-jar/inlined/earpods-ear", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-ear</title><path d=\"M16.144 17.635a1 1 0 00-1.095.895 3.614 3.614 0 01-5.662 2.657 3.77 3.77 0 01-1.567-2.9 5.405 5.405 0 00-2.713-4.473 6.265 6.265 0 012.4-11.631 5.988 5.988 0 014.716 1.662 1 1 0 001.388-1.44A7.981 7.981 0 007.324.194a8.265 8.265 0 00-3.213 15.357 3.421 3.421 0 011.717 2.926 5.62 5.62 0 0011.211.253 1 1 0 00-.895-1.095z\"/><path d=\"M3.955 6.958a1 1 0 101.922.554 2.793 2.793 0 013.085-1.955 1 1 0 10.293-1.979 4.809 4.809 0 00-5.3 3.38zM23.414 13.107l-7.071-7.071a3.7 3.7 0 00-3.032-1.014 5.387 5.387 0 00-4.66 4.5 1.513 1.513 0 00.426 1.263l2.512 2.515a1.508 1.508 0 001.262.426 5.229 5.229 0 002.961-1.5c.053-.053.105-.107.157-.164a.5.5 0 01.724-.017l3.893 3.893a2 2 0 102.828-2.828zM12.5 8.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});