define("ember-svg-jar/inlined/color-brush-paint-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-brush-paint-alternate</title><path d=\"M7.833 22.28a2.846 2.846 0 011.3-3.008.846.846 0 00.173-.127 6.681 6.681 0 00.073-.914c.02-.423.043-.877.1-1.371a.249.249 0 00-.228-.28c-2.216-.174-3.4-.238-4.758-1.99a2.373 2.373 0 00-2.745-1.148 2.239 2.239 0 00-1.456 1.146A2.988 2.988 0 00.2 16.972c.875 2.567 4.753 5.708 7.624 6.422a.25.25 0 00.281-.36 2.809 2.809 0 01-.272-.754zM23.1.782a1.655 1.655 0 00-2.223.7l-6 10.493a.25.25 0 00.194.373 5.182 5.182 0 013.141 1.459.249.249 0 00.4-.069l5.204-10.72A1.661 1.661 0 0023.1.782zM15.06 13.857a3.655 3.655 0 00-4.081 3.16c-.059.463-.08.889-.1 1.284-.054 1.176-.079 1.713-.964 2.252a1.339 1.339 0 00.517 2.471 7.856 7.856 0 001.055.071 7.149 7.149 0 004.383-1.461 5.961 5.961 0 002.349-3.7 3.655 3.655 0 00-3.159-4.077z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});