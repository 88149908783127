define("ember-svg-jar/inlined/strategy-chess", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>strategy-chess</title><path d=\"M1.105 23.2a1 1 0 00.9.553H22a1 1 0 00.9-.553l1-2a1 1 0 000-.894l-2.224-4.447a1.987 1.987 0 00-1.789-1.106H19a1 1 0 100 2h.727a.249.249 0 01.224.138l1.75 3.5a.25.25 0 01-.224.362H2.523a.25.25 0 01-.213-.119.248.248 0 01-.011-.243l1.75-3.5a.249.249 0 01.224-.138H5a1 1 0 000-2h-.882a1.988 1.988 0 00-1.789 1.105L.105 20.3a1 1 0 000 .894z\"/><path d=\"M9.61 8.521a9.224 9.224 0 00-1.562 5.152.249.249 0 01-.178.229 1.19 1.19 0 00-.839.8.2.2 0 00-.009.03l-.377 1.888a1 1 0 00.98 1.2h8.78a1 1 0 00.981-1.2l-.394-1.947a.178.178 0 00-.009-.033 1.164 1.164 0 00-.838-.741.249.249 0 01-.181-.213 7.728 7.728 0 00-2.137-4.349.25.25 0 01-.021-.319l1.929-2.658a1.484 1.484 0 00.286-.83l.007-.178c.045-1.129.092-2.3-2.289-3.486-1-.5-2.988-1.556-2.989-1.556a.5.5 0 00-.734.441v1.676a.25.25 0 01-.105.2C6.589 5 6.741 4.9 6.736 4.9a1.512 1.512 0 00-.7 1.554c.287 1.465 1.4 1.361 1.479 1.361h2.152a.25.25 0 01.184.418 3.517 3.517 0 00-.241.288z\"/><circle cx=\"19\" cy=\"18.501\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});