define("ember-svg-jar/inlined/voice-id-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-id-approved</title><path d=\"M1 2.5a1 1 0 00-1 1v14a1 1 0 002 0v-14a1 1 0 00-1-1zM4.5 6.5a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zM8 5a1 1 0 00-1 1v9a1 1 0 002 0V6a1 1 0 00-1-1zM22 10.5a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM12.5 11V1a1 1 0 00-2 0v10a1 1 0 002 0zM18.5 9.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM15 10a1 1 0 001-1V6a1 1 0 00-2 0v3a1 1 0 001 1zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.474 5.355l-2.905 3.874a1.751 1.751 0 01-2.64.185l-1.5-1.5a1 1 0 011.415-1.414l1.094 1.094a.25.25 0 00.377-.026l2.559-3.413a1 1 0 111.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});