define("ember-svg-jar/inlined/phone-action-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-credit-card</title><path d=\"M14 19a.5.5 0 01-.5.5h-11A.5.5 0 012 19V3.5a.5.5 0 01.5-.5h6.248A.251.251 0 009 2.748a5.021 5.021 0 01.354-2.4A.247.247 0 009.123 0H3a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3v-8.75a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M22.25 0h-10a1.752 1.752 0 00-1.75 1.75v7a1.752 1.752 0 001.75 1.75h10A1.752 1.752 0 0024 8.75v-7A1.752 1.752 0 0022.25 0zm0 9h-10a.25.25 0 01-.25-.25V5.5h10.5v3.25a.25.25 0 01-.25.25zm-10-7.5h10a.25.25 0 01.25.25V3H12V1.75a.25.25 0 01.25-.25z\"/><path d=\"M21 7.25a.75.75 0 00-.75-.75H19A.75.75 0 0019 8h1.25a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});