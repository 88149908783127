define("ember-svg-jar/inlined/idea-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>idea-message</title><path d=\"M24 6.508a4.832 4.832 0 00-4.826-4.826H4.826A4.832 4.832 0 000 6.508v7.653a4.832 4.832 0 004.578 4.82.25.25 0 01.237.243l.042 1.654a1.479 1.479 0 002.488 1.041l3.064-2.865a.254.254 0 01.171-.067h2.857a.247.247 0 01.166.063l3.226 2.876a1.479 1.479 0 002.462-1.142l-.04-1.552a.248.248 0 01.234-.255A4.833 4.833 0 0024 14.161zm-2 7.653a2.83 2.83 0 01-2.826 2.826h-.956a1 1 0 00-1 1.026l.027 1.041a.252.252 0 01-.145.233.25.25 0 01-.271-.04l-2.251-2.006a.994.994 0 00-.665-.254h-3.826a1 1 0 00-.683.269l-2.172 2.031a.249.249 0 01-.42-.177l-.03-1.15a1 1 0 00-1-.973h-.956A2.83 2.83 0 012 14.161V6.508a2.83 2.83 0 012.826-2.826h14.348A2.83 2.83 0 0122 6.508z\"/><path d=\"M12 4.313a3.873 3.873 0 00-3.869 3.869 3.829 3.829 0 00.98 2.525.251.251 0 00.188.085h5.4a.251.251 0 00.188-.085 3.829 3.829 0 00.98-2.525A3.873 3.873 0 0012 4.313zM9.815 11.792a.249.249 0 00-.25.25v.206a1.952 1.952 0 001.273 1.824.251.251 0 01.162.234v.333a1 1 0 002 0v-.333a.251.251 0 01.162-.234 1.952 1.952 0 001.273-1.824v-.206a.249.249 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});