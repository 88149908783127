define("ember-svg-jar/inlined/laptop-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-refresh</title><path d=\"M9.492 15.207a2.368 2.368 0 01-1.441-1.347.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.007a.25.25 0 00.249-.23 7.71 7.71 0 01.339-1.756.25.25 0 00-.143-.307zM3.5 12.01a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v6.912a.249.249 0 00.2.245 7.668 7.668 0 011.453.439.25.25 0 00.346-.231V3.01a3.009 3.009 0 00-3-3H5.5a3.009 3.009 0 00-3 3v8a1 1 0 001 1z\"/><path d=\"M23.692 11.56a.5.5 0 00-.545.108l-1.3 1.3a.249.249 0 01-.348.005 6.374 6.374 0 10.631 8.627 1 1 0 00-1.562-1.248 4.376 4.376 0 11-.5-5.982.251.251 0 01.082.18.248.248 0 01-.073.183l-1.391 1.39a.5.5 0 00.354.854h4.46a.5.5 0 00.5-.5v-4.455a.5.5 0 00-.308-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});