define("ember-svg-jar/inlined/monitor-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-network</title><path d=\"M1 15v3.5a2 2 0 002 2h1.916a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H4a1 1 0 000 2h4.5a1 1 0 000-2H7.416a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25H9a2 2 0 002-2V15a2 2 0 00-2-2H3a2 2 0 00-2 2zm8 3.25a.247.247 0 01-.073.176.25.25 0 01-.177.074h-5.5a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zM7 2.5V6a2 2 0 002 2h1.916a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H10a1 1 0 000 2h4.5a1 1 0 000-2h-1.084a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25H15a2 2 0 002-2V2.5a2 2 0 00-2-2H9a2 2 0 00-2 2zm8 3.25a.247.247 0 01-.073.176.25.25 0 01-.176.074H9.25A.25.25 0 019 5.75v-3a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25zM21 20.5a2 2 0 002-2V15a2 2 0 00-2-2h-6a2 2 0 00-2 2v3.5a2 2 0 002 2h1.916a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H16a1 1 0 000 2h4.5a1 1 0 000-2h-1.084a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25zm-6-5.25a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v3a.247.247 0 01-.073.176.25.25 0 01-.177.074h-5.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});