define("ember-svg-jar/inlined/login-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-alternate</title><path d=\"M22.5 10.5H14V6.8a1 1 0 00-1.625-.78l-6.5 5.2a1 1 0 000 1.561l6.5 5.2a1.022 1.022 0 001.058.121A1 1 0 0014 17.2v-3.7h8.5a1.5 1.5 0 000-3z\"/><path d=\"M22.45 15.587a1 1 0 00-1.322.5 10 10 0 11-.02-8.223 1 1 0 101.82-.829 12 12 0 10.026 9.871 1 1 0 00-.504-1.319z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});