define("ember-svg-jar/inlined/security-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-lock</title><path d=\"M22 12.126a2.5 2.5 0 00-2.5-2.5h-.25a.5.5 0 01-.5-.5v-2.5a6.75 6.75 0 00-13.5 0v2.5a.5.5 0 01-.5.5H4.5a2.5 2.5 0 00-2.5 2.5v1.5a2.5 2.5 0 002.5 2.5h15a2.5 2.5 0 002.5-2.5zm-16.5 1.5a1 1 0 111-1 1 1 0 01-1 .999zm4 0a1 1 0 111-1 1 1 0 01-1 .999zm6.75-4.5a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5v-2.5a4.25 4.25 0 018.5 0zM19.5 17.625h-15a2.5 2.5 0 00-2.5 2.5v1.5a2.5 2.5 0 002.5 2.5h15a2.5 2.5 0 002.5-2.5v-1.5a2.5 2.5 0 00-2.5-2.5zm-14 4a1 1 0 111-1 1 1 0 01-1 1zm4 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});