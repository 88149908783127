define("ember-svg-jar/inlined/keyboard-return-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-return-1</title><path d=\"M19.5.5h-15a4.012 4.012 0 00-4 4v15a4.011 4.011 0 004 4h15a4.011 4.011 0 004-4v-15a4.012 4.012 0 00-4-4zm-12 14a1 1 0 01-2 0v-6a1 1 0 012 0zm11-2h-4.25a.25.25 0 00-.25.25v1.75a1 1 0 01-.617.924 1 1 0 01-1.09-.217l-3-3a1 1 0 010-1.414l3-3A1 1 0 0114 8.5v1.75a.249.249 0 00.25.25h4.25a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});