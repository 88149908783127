define("ember-svg-jar/inlined/download-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-menu</title><path d=\"M13.45 1.608a1.45 1.45 0 10-2.9 0v5.067a.25.25 0 01-.25.25H8.617a.967.967 0 00-.684 1.65l3.384 3.383a.966.966 0 001.366 0l3.384-3.383a.967.967 0 00-.684-1.65H13.7a.25.25 0 01-.25-.25zM19.25 22.875a.966.966 0 00-.967-.967H5.717a.967.967 0 100 1.933h12.566a.966.966 0 00.967-.966zM18.283 18.525H5.717a.967.967 0 100 1.933h12.566a.967.967 0 100-1.933zM18.283 15.141H5.717a.967.967 0 100 1.934h12.566a.967.967 0 000-1.934z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});