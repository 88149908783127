define("ember-svg-jar/inlined/stairs-descend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stairs-descend</title><path d=\"M21 19.5a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 00-1.5-1.5h-2a.5.5 0 01-.5-.5v-2A1.5 1.5 0 007.5 6H1a1 1 0 00-1 1v16a1 1 0 001 1h19a1 1 0 001-1zM23.691 6.788a.5.5 0 00-.545.108l-1.085 1.086a.5.5 0 01-.708 0L13.811.439a1.5 1.5 0 00-2.122 2.122l7.543 7.539a.5.5 0 010 .708L18.146 11.9a.5.5 0 00.354.854h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.309-.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});