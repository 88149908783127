define("ember-svg-jar/inlined/pencil-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil-2</title><path d=\"M2.649 16.4a.5.5 0 00-.841.245L.394 23.01a.5.5 0 00.134.462.509.509 0 00.462.135l6.364-1.414a.5.5 0 00.245-.842zM17.852 7.208l-10.96 10.96a.252.252 0 000 .354L8.66 20.29a.507.507 0 00.707 0L19.974 9.683a.5.5 0 000-.707l-1.768-1.768a.252.252 0 00-.354 0zM16.792 5.794l-1.768-1.767a.5.5 0 00-.707 0L3.71 14.633a.5.5 0 000 .707l1.768 1.768a.252.252 0 00.354 0l10.96-10.96a.252.252 0 000-.354zM22.449 1.552a4.005 4.005 0 00-5.658 0l-.707.707a.5.5 0 000 .707l4.95 4.949a.513.513 0 00.707 0l.708-.707a4 4 0 000-5.656z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});