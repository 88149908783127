define("ember-svg-jar/inlined/vip-royal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vip-royal</title><path d=\"M24 12a12 12 0 10-12 12 12 12 0 0012-12zM6.5 15.5a1 1 0 01-1-1V9.207a.5.5 0 01.854-.353l2.264 2.264a.5.5 0 00.733-.028l2.269-2.647a.5.5 0 01.76 0l2.291 2.673a.5.5 0 00.709.051l2.791-2.441A.5.5 0 0119 9.1v5.4a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});