define("ember-svg-jar/inlined/microphone-karaoke-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>microphone-karaoke-1</title><path d=\"M8.629 9.948a.247.247 0 00.152.052h6.438a.247.247 0 00.152-.052 5.581 5.581 0 001.884-2.617.25.25 0 00-.237-.331H6.982a.25.25 0 00-.237.331 5.574 5.574 0 001.884 2.617zM6.5 6h11a1.04 1.04 0 001-1 1 1 0 00-1-1 .229.229 0 01-.218-.159 5.552 5.552 0 00-10.564 0A.229.229 0 016.5 4a1 1 0 00-1 1 1.044 1.044 0 001 1zM9.916 20a1.088 1.088 0 00.881.977.25.25 0 01.2.245V23a1 1 0 002 0v-1.774a.249.249 0 01.2-.245 1.088 1.088 0 00.887-.981l.738-8.733a.251.251 0 00-.249-.267H9.427a.251.251 0 00-.249.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});