define("ember-svg-jar/inlined/social-media-diingo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-diingo</title><path d=\"M17.67 5.53a1.5 1.5 0 00-1.57.15c-3 2.23-5.54 2.25-8.12.05A1.5 1.5 0 005.5 6.88v15a1.5 1.5 0 003 0V9.62a8.08 8.08 0 007-.09v12.35a1.5 1.5 0 003 0v-15a1.5 1.5 0 00-.83-1.35z\"/><circle cx=\"7.5\" cy=\"2.38\" r=\"1.75\"/><circle cx=\"16.5\" cy=\"2.38\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});