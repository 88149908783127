define("ember-svg-jar/inlined/wall-socket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wall-socket-1</title><path d=\"M20.5 2h-17C1.227 2 0 7.153 0 12s1.227 10 3.5 10h17c2.273 0 3.5-5.153 3.5-10S22.773 2 20.5 2zm-3.25 4.5V10a.75.75 0 01-1.5 0V6.5a.75.75 0 011.5 0zm-2.25 9v3a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-3a2.778 2.778 0 013-2.5 2.778 2.778 0 013 2.5zm-6.75-9V10a.75.75 0 01-1.5 0V6.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});