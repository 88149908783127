define("ember-svg-jar/inlined/medical-condition-sore-throat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-sore-throat</title><path d=\"M21.428 9.856a7.235 7.235 0 01-.721-1.45C20.707 3.145 15.845 0 11.143 0a9.925 9.925 0 00-10 10.063c0 3.5 1.271 6.124 3.779 7.813a.5.5 0 01.221.415V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h1.5a2.5 2.5 0 002.5-2.5v-1.984a.5.5 0 01.5-.5c.187 0 .378 0 .552-.01a1.245 1.245 0 00.963-.52 1.222 1.222 0 00.19-.743v-.138c.083-1.116-.715-2.514-1.42-3.749zM11.107 22.5a.745.745 0 11.75-.745.747.747 0 01-.75.745zm0-3a.745.745 0 11.75-.745.747.747 0 01-.75.745zm2.786 1.49a.745.745 0 11.75-.745.748.748 0 01-.75.745z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});