define("ember-svg-jar/inlined/disability-blind-read-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read-finger</title><circle cx=\"4.5\" cy=\"6.761\" r=\"1\"/><circle cx=\"7.75\" cy=\"6.761\" r=\"1\"/><circle cx=\"14.5\" cy=\"6.761\" r=\"1\"/><circle cx=\"17.5\" cy=\"6.761\" r=\"1\"/><circle cx=\"17.5\" cy=\"10.011\" r=\"1\"/><circle cx=\"4.5\" cy=\"10.261\" r=\"1\"/><circle cx=\"7.75\" cy=\"10.261\" r=\"1\"/><circle cx=\"4.5\" cy=\"13.761\" r=\"1\"/><path d=\"M21.253 19.99H19.5a.5.5 0 01-.5-.5v-4.25a1.25 1.25 0 00-2.5 0v6.466a.25.25 0 01-.454.145l-1.067-1.5a1.05 1.05 0 00-1.858.978l1.26 2.394a.5.5 0 00.443.268H23.5a.5.5 0 00.5-.5v-.6a2.887 2.887 0 00-2.747-2.901z\"/><path d=\"M19.841 2.175a.5.5 0 01.159.366v14.006a1 1 0 002 0V2.012a1.978 1.978 0 00-.616-1.44A2.05 2.05 0 0019.9.013c-4.618.2-7.248 1.643-8.583 2.715a.5.5 0 01-.625 0C9.353 1.657 6.722.213 2.1.013A2.048 2.048 0 00.616.572 1.978 1.978 0 000 2.012v14.535a1.983 1.983 0 001.905 2c6.276.24 8.247 2.977 8.259 2.994a1 1 0 001.385.287.979.979 0 00.432-.759c0-.029.019-.043.019-.076V5.013a.5.5 0 01.15-.357c.733-.717 2.839-2.31 7.316-2.614a.5.5 0 01.375.133zM10 18.317a.25.25 0 01-.378.215 15.415 15.415 0 00-7.15-1.963A.5.5 0 012 16.07l.017-13.531a.5.5 0 01.535-.5c4.47.305 6.572 1.9 7.3 2.613a.5.5 0 01.15.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});