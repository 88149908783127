define("ember-svg-jar/inlined/family-child-play-ball-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-play-ball-warning</title><path d=\"M23.827 21.305l-10.5-20a1.5 1.5 0 00-2.657 0l-10.5 20A1.5 1.5 0 001.5 23.5h21a1.5 1.5 0 001.328-2.2zM13.04 18.979l.99.991a.75.75 0 11-1.06 1.06l-.99-.99a1.715 1.715 0 01-.473-1.5.25.25 0 00-.247-.291h-.642a.249.249 0 00-.223.138l-.724 1.447a.75.75 0 01-1.342-.67l.724-1.447a1.739 1.739 0 011.565-.968h1.514a.252.252 0 00.224-.138l.25-.5a.25.25 0 00-.224-.362h-.661a1.746 1.746 0 01-1.66-1.2l-.273-.816a.75.75 0 111.424-.474l.272.816a.248.248 0 00.237.171h3.365a1.76 1.76 0 011.237.513l1.207 1.211a.75.75 0 11-1.06 1.06l-1.207-1.207a.252.252 0 00-.177-.073h-.468a.252.252 0 00-.224.138l-1.4 2.8a.251.251 0 00.046.291zm-.29-7.479a1.75 1.75 0 111.75 1.75 1.752 1.752 0 01-1.75-1.75zm-4.5 4.25a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});