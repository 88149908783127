define("ember-svg-jar/inlined/beacon-remote-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-monitor</title><path d=\"M22 0h-8a2 2 0 00-2 2v6a2 2 0 002 2h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H15.5a1 1 0 000 2h5a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.25 2a.25.25 0 01.25.25v4.5a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25zM5.5 8a4.966 4.966 0 00-3.535 1.464 1 1 0 001.414 1.414 3.074 3.074 0 014.242 0 1 1 0 001.414-1.414A4.966 4.966 0 005.5 8z\"/><path d=\"M1.965 7.707a5 5 0 017.07 0 1 1 0 101.414-1.414 7 7 0 00-9.9 0 1 1 0 001.416 1.414zM11 14.5A1.5 1.5 0 009.5 13h-8A1.5 1.5 0 000 14.5v8A1.5 1.5 0 001.5 24h8a1.5 1.5 0 001.5-1.5v-8zm-4.659 5.772a.249.249 0 00-.091.193v1.285a.75.75 0 01-1.5 0v-1.277a.247.247 0 00-.091-.192 1.28 1.28 0 01-.492-.989 1.333 1.333 0 112.666 0 1.264 1.264 0 01-.492.98zM8.75 19.5a.75.75 0 01-.75-.75 2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});