define("ember-svg-jar/inlined/pin-star-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-star-1</title><path d=\"M19.25 7.25a7.25 7.25 0 00-14.5 0c0 2.36 1.749 5.085 3.439 7.721a27.192 27.192 0 012.53 4.49.514.514 0 01.031.174v3.115a1.25 1.25 0 002.5 0v-3.115a.514.514 0 01.031-.174 27.192 27.192 0 012.53-4.49C17.5 12.335 19.25 9.61 19.25 7.25zm-3.729-.357l-.934.935a.5.5 0 00-.137.452l.357 1.785a.782.782 0 01-1.236.779L12.3 9.89a.5.5 0 00-.6 0l-1.271.954a.786.786 0 01-.47.155.778.778 0 01-.416-.119.789.789 0 01-.35-.815L9.55 8.28a.5.5 0 00-.137-.452l-.934-.934a.781.781 0 01.552-1.334h1.089a.5.5 0 00.447-.277l.733-1.469a.782.782 0 011.4 0l.734 1.469a.5.5 0 00.447.277h1.089a.781.781 0 01.552 1.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});