define("ember-svg-jar/inlined/cocktail-glass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cocktail-glass-1</title><path d=\"M19.988 10.451A1 1 0 0019 9.3h-2.011a.5.5 0 01-.5-.568l.136-.98a.5.5 0 01.308-.395 2.482 2.482 0 00.333-4.457l.226-1.727a1 1 0 00-1.984-.254l-.228 1.748a2.481 2.481 0 00-.819 4.341.5.5 0 01.188.463l-.194 1.4a.5.5 0 01-.495.431H5a1 1 0 00-.987 1.161l1.7 10.657A3.426 3.426 0 009 23.951h6a3.422 3.422 0 003.288-2.828zm-2.737.847a.5.5 0 01.494.576l-.308 2a.5.5 0 01-.494.424H16.3a.5.5 0 01-.495-.568l.277-2a.5.5 0 01.5-.432zm-3.707 0a.5.5 0 01.495.569l-.277 2a.5.5 0 01-.5.431H7.087a.5.5 0 01-.493-.42l-.325-2a.5.5 0 01.494-.58z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});