define("ember-svg-jar/inlined/farming-barn-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-barn-1</title><path d=\"M23.78 9.717l-4.364-6.5a.5.5 0 00-.213-.178L12.606.127a1.506 1.506 0 00-1.211 0L4.8 3.043a.492.492 0 00-.213.178L.206 9.744A1.5 1.5 0 001.5 12h1a.5.5 0 01.5.5v10A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.5-1.5v-10a.5.5 0 01.5-.5h1a1.5 1.5 0 001.28-2.283zm-6.7 5.421a.25.25 0 01.417.187v6.021a.25.25 0 01-.417.186l-3.376-3.007a.246.246 0 01-.084-.186.249.249 0 01.083-.187zm-4.923 1.713a.25.25 0 01-.333 0l-2.707-2.415A.25.25 0 019.283 14H14.7a.25.25 0 01.167.436zm-1.878 1.3a.252.252 0 01.084.187.246.246 0 01-.084.186l-3.366 3a.249.249 0 01-.268.042.252.252 0 01-.148-.229v-6a.25.25 0 01.416-.187zm1.547 1.674a.252.252 0 01.333 0l1.949 1.736a.25.25 0 01-.167.437h-3.89a.25.25 0 01-.166-.437zM9.5 7.5a.5.5 0 01.2-.4l2-1.5a.5.5 0 01.6 0l2 1.5a.5.5 0 01.2.4v2a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});