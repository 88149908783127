define("ember-svg-jar/inlined/shorts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shorts</title><path d=\"M22.609 2.5a.5.5 0 00.5-.522l-.024-.543A1.5 1.5 0 0021.586 0H2.413a1.5 1.5 0 00-1.5 1.435l-.023.543a.5.5 0 00.5.522zM24 22.434l-.783-17.956a.5.5 0 00-.5-.478h-8.448a.25.25 0 00-.24.322l1.189 3.962a.75.75 0 01-1.436.432l-1.31-4.364a.493.493 0 00-.945 0l-1.309 4.364a.75.75 0 11-1.436-.431L9.97 4.322A.25.25 0 009.731 4h-8.45a.5.5 0 00-.5.478L0 22.435A1.5 1.5 0 001.5 24h7.587a1.494 1.494 0 001.494-1.364l.826-9.095a.595.595 0 011.185 0l.827 9.095A1.493 1.493 0 0014.913 24H22.5a1.5 1.5 0 001.5-1.566z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});