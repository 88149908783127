define("ember-svg-jar/inlined/building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-1</title><path d=\"M3.5 3.5h17a.5.5 0 00.391-.812L19.19.563A1.492 1.492 0 0018.02 0H5.98a1.492 1.492 0 00-1.17.563l-1.7 2.125a.5.5 0 00.39.812zM20.5 5h-17a.5.5 0 00-.5.5v17A1.5 1.5 0 004.5 24h5a.5.5 0 00.5-.5v-2a2 2 0 014 0v2a.5.5 0 00.5.5h5a1.5 1.5 0 001.5-1.5v-17a.5.5 0 00-.5-.5zM8 16.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm5.5 6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm5.5 6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0zm0-6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});