define("ember-svg-jar/inlined/vintage-car-convertible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-convertible</title><path d=\"M23.791 12.943a.249.249 0 00-.239-.175H21a.5.5 0 010-1h1.746a.25.25 0 00.191-.411A4.486 4.486 0 0019.9 9.768a.252.252 0 01-.179-.1 4.88 4.88 0 00-3.634-1.892 1 1 0 00-1.085 1v1.2a.252.252 0 01-.092.194 3.774 3.774 0 01-2.408.584 6.628 6.628 0 01-1.943-.264.249.249 0 01-.119-.4l1.83-2.2a1 1 0 00-1.536-1.282L8.19 9.66a.251.251 0 01-.19.09H3.5a3.487 3.487 0 00-2.948 1.634.251.251 0 00.211.384H1.5a.5.5 0 010 1H.267a.249.249 0 00-.248.219A2.074 2.074 0 000 13.25a3.494 3.494 0 002.2 3.239.251.251 0 00.339-.277 2.6 2.6 0 01-.039-.462 3 3 0 016 0 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h5.692a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 3 3 0 016 0 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h.846a2.5 2.5 0 002.5-2.5 4.35 4.35 0 00-.209-1.307z\"/><circle cx=\"5.5\" cy=\"15.75\" r=\"2\"/><circle cx=\"17.5\" cy=\"15.75\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});