define("ember-svg-jar/inlined/calendar-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-disable</title><path d=\"M23.5 5a2 2 0 00-2-2h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2zm-2 16.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M12 10a5.5 5.5 0 105.5 5.5A5.506 5.506 0 0012 10zm2.3 6.737a.75.75 0 01-1.063 1.063l-1.06-1.061a.252.252 0 00-.354 0l-1.06 1.061A.75.75 0 019.7 16.738l1.061-1.06a.252.252 0 000-.354L9.7 14.264a.75.75 0 011.063-1.064l1.06 1.061a.252.252 0 00.354 0l1.06-1.061a.75.75 0 011.063 1.064l-1.061 1.06a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});