define("ember-svg-jar/inlined/style-three-pin-sailing-boat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-sailing-boat-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 16.5a3.627 3.627 0 01-2.192-.693.5.5 0 00-.617 0 3.809 3.809 0 01-4.383 0 .5.5 0 00-.617 0A3.621 3.621 0 017 16.5a.5.5 0 010-1c.827 0 1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.19 1.129 2.016 1.129s1.812-.354 2.018-1.129a.52.52 0 01.966 0c.206.775 1.191 1.129 2.016 1.129a.5.5 0 110 1zm.351-2.357a1.1 1.1 0 01-.9.023.5.5 0 01-.319-.3 1.749 1.749 0 00-1.622-1.135 1.726 1.726 0 00-1.633 1.123.5.5 0 01-.3.3 1.809 1.809 0 01-1.126.013.5.5 0 01-.319-.3 1.748 1.748 0 00-1.622-1.135 1.726 1.726 0 00-1.633 1.123.5.5 0 01-.3.3 1.939 1.939 0 01-.671.1.5.5 0 01-.389-.137 1.937 1.937 0 01-.492-1.256.5.5 0 01.456-.549l4.45-.371a.5.5 0 00.447-.6L9.765 3.83a.5.5 0 01.6-.593 7.736 7.736 0 015.929 5.31.5.5 0 01-.385.631l-3.315.644a.5.5 0 00-.393.6l.209.961a.5.5 0 00.53.392l4.6-.383a.5.5 0 01.538.444 2.393 2.393 0 01-.726 2.307z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});