define("ember-svg-jar/inlined/family-outdoors-swing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-swing</title><path d=\"M23.25 22.5a.547.547 0 01-.545-.5L21.083 4.538A4.977 4.977 0 0016.1 0H7.9a4.977 4.977 0 00-4.983 4.538L1.3 22a.547.547 0 01-.545.5.75.75 0 000 1.5h3a.75.75 0 000-1.5.448.448 0 01-.446-.489l1.6-17.288A2.984 2.984 0 016.823 2.2a.5.5 0 01.677.467v12.15a.248.248 0 01-.093.2.251.251 0 01-.211.05.972.972 0 00-.61.055 1 1 0 00.1 1.876A18.059 18.059 0 0012 17.8a18.059 18.059 0 005.315-.8 1 1 0 00.1-1.876.972.972 0 00-.61-.055.251.251 0 01-.211-.05.248.248 0 01-.093-.2V2.664a.5.5 0 01.677-.468 2.981 2.981 0 011.914 2.527L20.7 22.011a.448.448 0 01-.446.489.75.75 0 000 1.5h3a.75.75 0 000-1.5zm-9.185-6.839A16.042 16.042 0 0112 15.8a16.042 16.042 0 01-2.065-.134.5.5 0 01-.435-.5V2.5A.5.5 0 0110 2h4a.5.5 0 01.5.5v12.665a.5.5 0 01-.435.496z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});