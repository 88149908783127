define("ember-svg-jar/inlined/style-three-pin-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-skull</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.5 11a2.5 2.5 0 01-1.667 2.357.5.5 0 00-.333.471v.672a2.5 2.5 0 01-1.875 2.421.5.5 0 01-.625-.485V14.5a.5.5 0 00-1 0v2a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-2a.5.5 0 00-1 0v1.936a.5.5 0 01-.625.485A2.5 2.5 0 017.5 14.5v-.672a.5.5 0 00-.333-.471A2.5 2.5 0 015.5 11V9.5a6.5 6.5 0 0113 0z\"/><circle cx=\"9.5\" cy=\"9.5\" r=\"1.5\"/><circle cx=\"14.5\" cy=\"9.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});