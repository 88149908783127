define("ember-svg-jar/inlined/animal-products-egg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-products-egg</title><path d=\"M12 0C7.58 0 4.5 5.692 4.5 11.25a.7.7 0 00.643.75h13.714a.7.7 0 00.643-.75C19.5 5.692 16.42 0 12 0zm-.151 3.481c-.956 0-1.918.787-2.642 2.159A.635.635 0 018.65 6a.585.585 0 01-.337-.109A.818.818 0 018.1 4.86c.965-1.829 2.333-2.879 3.754-2.879a.706.706 0 01.651.75.706.706 0 01-.656.75zM20.766 14.722a1 1 0 00-.976-1.222H4.21a1 1 0 00-.976 1.222 12.7 12.7 0 003.611 6.731.5.5 0 01.126.58 9.268 9.268 0 00-.254.628A1 1 0 007.658 24h8.684a1 1 0 00.941-1.339 9.268 9.268 0 00-.254-.628.5.5 0 01.126-.58 12.709 12.709 0 003.611-6.731z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});