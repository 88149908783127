define("ember-svg-jar/inlined/smiley-smile-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-smile-1_1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 7a2 2 0 11-2 2 2 2 0 012-2zm-1.156 7.162a1 1 0 011.384.291 4.5 4.5 0 007.544 0 1 1 0 011.676 1.094 6.5 6.5 0 01-10.9 0 1 1 0 01.296-1.385zM16 11a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});