define("ember-svg-jar/inlined/single-woman-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions</title><path d=\"M20.238 17.6c-.553-1.1-2.863-1.857-5.764-2.922a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25 6.374 6.374 0 003.786-.9.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C16.839 4.6 14.236 3.25 12 3.25S7.162 4.6 6.9 8.421c-.146 2.132-.487 2.78-.981 3.317a.388.388 0 00.081.593 6.355 6.355 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73C6.625 15.738 4.314 16.5 3.762 17.6A7.822 7.822 0 003 20.326a.382.382 0 00.1.3.392.392 0 00.29.127h17.217a.392.392 0 00.29-.127.386.386 0 00.1-.3 7.822 7.822 0 00-.759-2.726z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});