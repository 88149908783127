define("ember-svg-jar/inlined/toys-caterpillar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-caterpillar</title><path d=\"M20.431 17a1 1 0 01-.765-.333 4.244 4.244 0 00-2.168-1.3 1 1 0 01-.6-.415 4.971 4.971 0 00-2.324-1.86 1 1 0 01-.548-.518 4.987 4.987 0 00-.259-.5 1 1 0 01-.123-.685 6.5 6.5 0 00-1.543-5.46.5.5 0 010-.669.4.4 0 01.5-.075 2.25 2.25 0 10-1.081-2.227.5.5 0 01-.2.336 4.745 4.745 0 00-.885.852.5.5 0 01-.591.145 6.515 6.515 0 00-4.213-.338.5.5 0 01-.521-.18 5.151 5.151 0 00-.521-.583.5.5 0 01-.143-.467A2.25 2.25 0 102.25 4.5a2.272 2.272 0 00.455-.046.5.5 0 01.437.119.432.432 0 01-.016.656 6.5 6.5 0 00.682 10.533 1 1 0 01.425.554 5.207 5.207 0 003.6 3.494 1 1 0 01.6.458 5.028 5.028 0 004.84 2.455 1 1 0 01.7.191 4.247 4.247 0 003.99.577 1 1 0 01.834.067A3.5 3.5 0 1020.431 17zM7.25 14.75a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5zm5.5 6a3.006 3.006 0 01-1.816-.617.246.246 0 01-.095-.237A.25.25 0 0111 19.7a5.271 5.271 0 003.409-3.983.251.251 0 01.169-.192.248.248 0 01.249.057 3 3 0 01-2.077 5.168zM20.378 22a.25.25 0 01-.2-.374 4.227 4.227 0 00.566-2.259.25.25 0 01.35-.238A1.5 1.5 0 0120.378 22z\"/><circle cx=\"5.5\" cy=\"9\" r=\"1\"/><circle cx=\"9\" cy=\"9\" r=\"1\"/><path d=\"M8.945 11.317a.75.75 0 00-.919.528.818.818 0 01-.776.364.82.82 0 01-.776-.365.75.75 0 00-1.448.394 2.217 2.217 0 002.224 1.471 2.214 2.214 0 002.224-1.473.749.749 0 00-.529-.919z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});