define("ember-svg-jar/inlined/fitness-slim-waist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-slim-waist</title><path d=\"M12 15.5a1 1 0 00-1 1V18a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/><path d=\"M20.5 18.982A3.968 3.968 0 0119.477 16c0-2.344 1.569-4.945 2.411-6.419A6.2 6.2 0 0023 5.75C23 2.418 20.782 0 17.727 0a8.95 8.95 0 00-5.416 1.722.5.5 0 01-.622 0A8.95 8.95 0 006.273 0C3.218 0 1 2.418 1 5.75a6.2 6.2 0 001.112 3.831c.228.433 2.411 3.764 2.411 6.419A3.968 3.968 0 013.5 18.982a11.4 11.4 0 00-1.948 3.71A1 1 0 002.5 24h19a1 1 0 00.951-1.308 11.4 11.4 0 00-1.951-3.71zm-2.228-6.688a10.875 10.875 0 00-.8 3.706 6.984 6.984 0 00.3 2.2.5.5 0 01-.358.641c-.547.133-1.05.29-1.544.446A11.765 11.765 0 0112 20a11.765 11.765 0 01-3.879-.714c-.493-.156-1-.313-1.544-.446a.5.5 0 01-.358-.641 6.984 6.984 0 00.3-2.2 10.875 10.875 0 00-.8-3.706.586.586 0 01.548-.794 6.822 6.822 0 003.722-1.166A4.009 4.009 0 0112 9.591a4.009 4.009 0 012.005.743 6.822 6.822 0 003.722 1.166.586.586 0 01.548.794z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});