define("ember-svg-jar/inlined/natural-disaster-volcano-lava", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-volcano-lava</title><path d=\"M23.1 21.14c-2.57-1.24-6.45-4.08-6.76-6.81A1.467 1.467 0 0014.91 13H9.09a1.466 1.466 0 00-1.43 1.32C7.35 17.06 3.47 19.9.9 21.14a1.531 1.531 0 00-.81 1.68A1.449 1.449 0 001.5 24h21a1.449 1.449 0 001.41-1.18 1.531 1.531 0 00-.81-1.68zm-7.909-4.251A1.756 1.756 0 0014.16 18.5v.5a.485.485 0 01-.48.5.491.491 0 01-.48-.5 1.681 1.681 0 10-3.36 0v1c0 .28-.48.28-.48 0a1.7 1.7 0 00-1.395-1.708.249.249 0 01-.148-.4 7.333 7.333 0 001.407-2.759.5.5 0 01.487-.385h4.58a.5.5 0 01.487.383 6.333 6.333 0 00.534 1.412.249.249 0 01-.121.344zM2 10h6.5a.5.5 0 00.5-.5 1.251 1.251 0 00-1.25-1.25.75.75 0 010-1.5A2.752 2.752 0 0110.5 9.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a2.752 2.752 0 012.75-2.75.75.75 0 010 1.5A1.251 1.251 0 0015 9.5a.5.5 0 00.5.5H22a2.009 2.009 0 001.413-.586A1.987 1.987 0 0024 8a2.483 2.483 0 00-1.87-2.3.187.187 0 01-.13-.18V5.5a4.277 4.277 0 00-4.5-4 5.045 5.045 0 00-1.135.128.5.5 0 01-.455-.121 5.821 5.821 0 00-7.816 0 .5.5 0 01-.455.121A5.051 5.051 0 006.5 1.5a4.277 4.277 0 00-4.5 4v.02a.187.187 0 01-.129.18A2.483 2.483 0 000 8a1.987 1.987 0 00.586 1.413A2.01 2.01 0 002 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});