define("ember-svg-jar/inlined/3d-print-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-settings</title><path d=\"M11.055 17.17a.25.25 0 000-.34l-.174-.187a3.82 3.82 0 01-.187-.22.248.248 0 00-.2-.1H2.249a.25.25 0 01-.25-.25V2.281a.25.25 0 01.25-.25h6.023a.25.25 0 01.25.25V3.1a.4.4 0 00.082.245l.818 1.09a.408.408 0 00.654 0l.818-1.09a.4.4 0 00.082-.245v-.819a.25.25 0 01.25-.25h6.023a.25.25 0 01.25.25v6a.249.249 0 00.25.25 3.286 3.286 0 011.394.308.25.25 0 00.356-.226V1.781a1.752 1.752 0 00-1.75-1.75h-16A1.752 1.752 0 000 1.781v16a1.752 1.752 0 001.75 1.75h8.014a.249.249 0 00.249-.228 3.284 3.284 0 01.869-1.946z\"/><path d=\"M6.8 4.228a.75.75 0 00-1.059 1.061l1.024 1.022a.75.75 0 101.06-1.06zM12.7 4.228l-1.027 1.023a.75.75 0 101.06 1.06l1.024-1.022A.75.75 0 0012.7 4.228zM9.317 7.529l-3.5 6a.5.5 0 00.432.752h3.522a.25.25 0 00.249-.222 3.3 3.3 0 01.428-1.3 3.259 3.259 0 011.628-1.408.251.251 0 00.124-.359l-2.019-3.463a.52.52 0 00-.864 0z\"/><circle cx=\"17.749\" cy=\"17\" r=\"1.5\"/><path d=\"M23.517 15.622a1.783 1.783 0 00-1.7-2.953L20.406 13a.454.454 0 01-.534-.312l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.459.459 0 01-.533.312l-1.411-.328a1.783 1.783 0 00-1.7 2.953l.988 1.065a.464.464 0 010 .626l-.988 1.064a1.783 1.783 0 001.7 2.954L15.092 21a.451.451 0 01.534.311l.424 1.392a1.774 1.774 0 003.4 0l.424-1.391a.452.452 0 01.531-.312l1.411.328a1.783 1.783 0 001.7-2.954l-.988-1.064a.464.464 0 010-.626zM17.749 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});