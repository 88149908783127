define("ember-svg-jar/inlined/money-bag-euro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>money-bag-euro</title><path d=\"M9 8h6a1 1 0 000-2H9a1 1 0 000 2zM9.471 4.5a.5.5 0 00.5.438h4.064a.5.5 0 00.5-.438 9.165 9.165 0 011.206-3.049.5.5 0 00-.638-.7l-1.862.8a.25.25 0 01-.33-.137l-.441-1.1a.5.5 0 00-.928 0l-.441 1.1a.25.25 0 01-.33.137L8.9.755a.5.5 0 00-.638.7A9.165 9.165 0 019.471 4.5zM15.438 9.121A.591.591 0 0015.081 9H8.919a.585.585 0 00-.357.121c-2.551 1.95-5.4 5.4-5.4 8.4C3.16 21.75 5.518 24 12 24s8.84-2.25 8.84-6.482c0-2.995-2.85-6.447-5.402-8.397zm-2.474 6.629a.75.75 0 010 1.5h-1.757a.249.249 0 00-.244.3c.32 1.458 1.374 2.2 3.144 2.2a.75.75 0 010 1.5c-2.633 0-4.311-1.4-4.664-3.787a.249.249 0 00-.243-.213h-.8a.75.75 0 010-1.5h.8a.25.25 0 00.247-.213c.353-2.384 2.031-3.787 4.664-3.787a.75.75 0 010 1.5c-1.77 0-2.824.738-3.144 2.2a.25.25 0 00.244.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});