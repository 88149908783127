define("ember-svg-jar/inlined/museum-painting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>museum-painting</title><path d=\"M22 0H2a2 2 0 00-2 2v9a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 10.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M17.54 4.535a.544.544 0 00-.931-.042l-2.4 3.61a.541.541 0 01-.837.083L11.54 6.343a.543.543 0 00-.8.031L8.631 8.848a.548.548 0 00-.081.584.543.543 0 00.494.318h10.412a.545.545 0 00.468-.267.552.552 0 00.01-.541z\"/><circle cx=\"6.25\" cy=\"5\" r=\"1.75\"/><path d=\"M23.445 18.917a7.981 7.981 0 01-1.745-1.176.248.248 0 01-.071-.268 2.25 2.25 0 10-4.38-.724 2.22 2.22 0 00.12.723.252.252 0 01-.071.268 7.993 7.993 0 01-10.6 0 .252.252 0 01-.071-.268 2.22 2.22 0 00.12-.723 2.25 2.25 0 00-4.5 0 2.226 2.226 0 00.12.724.248.248 0 01-.071.268 7.981 7.981 0 01-1.741 1.176 1 1 0 10.89 1.791A9.939 9.939 0 003.1 19.675a.25.25 0 01.4.2V23a1 1 0 002 0v-3.128a.25.25 0 01.4-.2 10 10 0 0012.194 0 .251.251 0 01.4.2V23a1 1 0 002 0v-3.127a.252.252 0 01.14-.225.249.249 0 01.262.027 9.989 9.989 0 001.653 1.033 1 1 0 10.89-1.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});