define("ember-svg-jar/inlined/army-symbol-airborne-infantry-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-airborne-infantry-1</title><path d=\"M15.085 6.031a.749.749 0 00-1.054-.116l-1.875 1.5a.25.25 0 01-.312 0l-1.875-1.5a.75.75 0 10-.938 1.17l2.11 1.688a1.4 1.4 0 001.718 0l2.11-1.688a.75.75 0 00.116-1.054z\"/><path d=\"M24 5a2 2 0 00-2-2H2a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2zM2 6.432a.25.25 0 01.137-.223.247.247 0 01.26.021l7.63 5.568a.25.25 0 010 .4L2.4 17.77a.247.247 0 01-.26.021.25.25 0 01-.14-.223zm2.723-.98A.25.25 0 014.871 5h14.26a.25.25 0 01.147.452l-7.131 5.2a.249.249 0 01-.294 0zm7.13 7.893a.249.249 0 01.294 0l7.129 5.2a.25.25 0 01-.147.452H4.871a.25.25 0 01-.147-.452zm2.12-1.143a.25.25 0 010-.4L21.6 6.23a.247.247 0 01.26-.021.25.25 0 01.14.223v11.136a.25.25 0 01-.137.223.247.247 0 01-.26-.021z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});