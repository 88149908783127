define("ember-svg-jar/inlined/messages-bubble-square-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-smile</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.553A1.459 1.459 0 002 18h3v4a.5.5 0 00.784.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM5.5 7.833a2.75 2.75 0 115.5 0 .75.75 0 01-1.5 0 1.25 1.25 0 10-2.5 0 .75.75 0 01-1.5 0zm2.966 3.982a.75.75 0 011.06 0 3.577 3.577 0 004.94 0 .75.75 0 011.06 1.061 4.993 4.993 0 01-7.06 0 .75.75 0 010-1.061zM14.5 7.833a.75.75 0 01-1.5 0 2.75 2.75 0 115.5 0 .75.75 0 01-1.5 0 1.25 1.25 0 10-2.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});