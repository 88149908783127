define("ember-svg-jar/inlined/password-desktop-lock-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>password-desktop-lock-approved</title><path d=\"M14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787zM22.5 4.4h-.25V3a2.75 2.75 0 00-5.5 0v1.4h-.25a1 1 0 00-1 1v4.5a1 1 0 001 1h6a1 1 0 001-1V5.4a1 1 0 00-1-1zm-3 3.75a1 1 0 111-1 1 1 0 01-1 1zM18.25 4.4V3a1.25 1.25 0 012.5 0v1.4zM4 8.5A4.5 4.5 0 108.5 4 4.5 4.5 0 004 8.5zm6.956-1.909a.751.751 0 01.149 1.051l-2.178 2.905a1.306 1.306 0 01-.959.521h-.093a1.309 1.309 0 01-.929-.387l-1.124-1.12A.75.75 0 016.883 8.5l.973.974L9.9 6.741a.753.753 0 011.056-.15z\"/><path d=\"M23 13a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5H14a1 1 0 000-2H2a2 2 0 00-2 2v15.045A2.571 2.571 0 002.54 19.5h18.92A2.571 2.571 0 0024 17.045V14a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});