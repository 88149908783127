define("ember-svg-jar/inlined/room-service-plates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-plates</title><circle cx=\"12\" cy=\"3.163\" r=\"2.413\"/><path d=\"M3.869 2.539a.75.75 0 00-.75.75v.382a.249.249 0 01-.17.237 2.886 2.886 0 00-1.811 1.82.254.254 0 01-.2.169.735.735 0 00-.683.74.75.75 0 00.75.75h5.734a.75.75 0 00.75-.75.734.734 0 00-.683-.74.249.249 0 01-.2-.169 2.887 2.887 0 00-1.813-1.82.251.251 0 01-.17-.237v-.382a.75.75 0 00-.754-.75zM20.131 2.539a.75.75 0 00-.75.75v.382a.25.25 0 01-.17.237 2.887 2.887 0 00-1.811 1.82.251.251 0 01-.2.169.735.735 0 00-.683.74.75.75 0 00.75.75H23a.75.75 0 00.75-.75.734.734 0 00-.683-.74.252.252 0 01-.205-.169 2.886 2.886 0 00-1.811-1.82.249.249 0 01-.17-.237v-.382a.75.75 0 00-.75-.75zM9.165 22.054a1.2 1.2 0 102.391 0v-5.479a.479.479 0 01.957 0v5.479a1.2 1.2 0 102.391 0v-7.163a.25.25 0 00-.25-.25H9.415a.25.25 0 00-.25.25z\"/><path d=\"M9.33 6.989a3.1 3.1 0 00-2.714 1.6l-.411.743a.717.717 0 01-.579.367A.762.762 0 015 9.411L3.9 7.946A1.2 1.2 0 001.991 9.38l1.1 1.465a3.1 3.1 0 002.484 1.245c.084 0 .169 0 .255-.01a3.059 3.059 0 002.459-1.58l.418-.754a.244.244 0 01.458.118v3.568a.25.25 0 00.25.25h5.239a.25.25 0 00.25-.25V9.868a.244.244 0 01.458-.118l.42.756a3.059 3.059 0 002.457 1.574c.086.006.17.01.255.01a3.094 3.094 0 002.484-1.244l1.1-1.466a1.2 1.2 0 00-1.913-1.434l-1.1 1.466a.747.747 0 01-.622.285.715.715 0 01-.578-.368l-.413-.742a3.108 3.108 0 00-2.716-1.6h-1a.478.478 0 00-.409.232l-.89 1.481a.478.478 0 01-.82 0l-.888-1.478a.479.479 0 00-.41-.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});