define("ember-svg-jar/inlined/road-sign-speed-limit-20-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-20-1-alternate</title><path d=\"M11.143 20.168a2.084 2.084 0 00.607-1.468 2.2 2.2 0 00-4.292-.7.749.749 0 101.422.474.7.7 0 011.37.223.572.572 0 01-.167.4L7.47 21.72A.75.75 0 008 23h3a.75.75 0 000-1.5h-.586a.25.25 0 01-.176-.427zM14.5 16.5a2.253 2.253 0 00-2.25 2.25v2a2.25 2.25 0 004.5 0v-2a2.253 2.253 0 00-2.25-2.25zm.75 4.25a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zM8.5 8.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM16.5 8.5a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM20.5 8.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM7 7.5a.75.75 0 00.75-.75V5.688a.249.249 0 01.2-.245A2.244 2.244 0 007.5 1H7a.75.75 0 00-.75.75v5A.75.75 0 007 7.5zM8 3a.25.25 0 11-.25.25A.25.25 0 018 3zM6.5 13.5h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-1.5 0v4A1.752 1.752 0 005.5 15h1a.75.75 0 000-1.5zM5.75 5.75a2.553 2.553 0 00-1.3-2.1c-.508-.381-.7-.557-.7-.9A.25.25 0 014 2.5h1A.75.75 0 005 1H4a1.752 1.752 0 00-1.75 1.75 2.553 2.553 0 001.3 2.1c.508.381.7.557.7.9A.25.25 0 014 6H3a.75.75 0 000 1.5h1a1.752 1.752 0 001.75-1.75zM13.143 12.136a.058.058 0 01.107.029v2.085a.75.75 0 001.5 0v-5a.75.75 0 00-1.393-.386l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v5a.75.75 0 001.5 0v-2.085a.058.058 0 01.107-.029.78.78 0 001.286 0zM19 1a.75.75 0 00-.75.75v5a.75.75 0 00.75.75 2.752 2.752 0 002.75-2.75v-1A2.752 2.752 0 0019 1zm1.25 3.75a.25.25 0 01-.5 0v-1a.25.25 0 01.5 0zM12 7.5h1A.75.75 0 0013 6h-1a.25.25 0 010-.5h1A.75.75 0 0013 4h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0112 2.5h1A.75.75 0 0013 1h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0012 7.5zM17 6h-1a.25.25 0 010-.5h1A.75.75 0 0017 4h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0116 2.5h1A.75.75 0 0017 1h-1a1.752 1.752 0 00-1.75 1.75v3A1.752 1.752 0 0016 7.5h1A.75.75 0 0017 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});