define("ember-svg-jar/inlined/hourglass-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hourglass-1-alternate</title><path d=\"M12 11.5a.5.5 0 00.455-.293l2.5-5.5A.5.5 0 0014.5 5h-5a.5.5 0 00-.455.707l2.5 5.5A.5.5 0 0012 11.5z\"/><path d=\"M21 22h-.651l-4.268-8.8a2.734 2.734 0 010-2.4L20.349 2H21a1 1 0 000-2H3a1 1 0 000 2h.651l4.268 8.8a2.734 2.734 0 010 2.4L3.651 22H3a1 1 0 000 2h18a1 1 0 000-2zM9.269 13.854a4.221 4.221 0 000-3.7L5.318 2h13.364l-3.951 8.148a4.221 4.221 0 000 3.705L18.682 22H5.318z\"/><path d=\"M15.511 20.289l-3.059-6.5a.52.52 0 00-.9 0l-3.059 6.5a.5.5 0 00.448.711h6.118a.5.5 0 00.452-.712z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});