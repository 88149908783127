define("ember-svg-jar/inlined/pool-black-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pool-black-ball</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm.006 19.5a7.5 7.5 0 117.5-7.5 7.5 7.5 0 01-7.5 7.5z\"/><path d=\"M14.7 11.1a.5.5 0 01-.088-.61 3 3 0 10-5.2 0 .5.5 0 01-.088.61 3.891 3.891 0 105.38 0zM12.006 8a1 1 0 11-1 1 1 1 0 011-1zm0 7.8a1.891 1.891 0 111.894-1.891 1.893 1.893 0 01-1.894 1.891z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});