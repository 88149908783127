define("ember-svg-jar/inlined/photography-equipment-light-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-light-2</title><circle cx=\"12\" cy=\"9\" r=\"1.5\"/><path d=\"M21 5.388a1 1 0 00-1.316-.95l-4.21 1.4a.251.251 0 01-.316-.316l1.4-4.21A1 1 0 0015.613 0H8.387a1 1 0 00-.948 1.316l1.4 4.21a.251.251 0 01-.316.316l-4.21-1.4A1 1 0 003 5.388v7.224a1 1 0 001.316.95l4.21-1.4a.251.251 0 01.316.316l-1.4 4.21A1 1 0 008.387 18h2.363a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25h2.363a1 1 0 00.948-1.316l-1.4-4.21a.251.251 0 01.316-.316l4.21 1.4A1 1 0 0021 12.612zM15 11.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});