define("ember-svg-jar/inlined/feed-squidoo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-squidoo</title><path d=\"M21.49 13.4a5.71 5.71 0 00-2.71-1.17L18 12a2.42 2.42 0 01.44-2 4.13 4.13 0 00.69-2.09 4.66 4.66 0 00-2.5-3.72 6.74 6.74 0 00-1.74-.64A4.68 4.68 0 0113.35 3a2.07 2.07 0 01-.57-1.19 6.13 6.13 0 00-.18-.61.5.5 0 00-1 .07C11.17 3.83 12.74 4.46 14 5a5.4 5.4 0 011.32.67 2.39 2.39 0 011 1.75 1.64 1.64 0 01-.51 1c-.33.41-.39.52-.59.8a8.27 8.27 0 00-3.91-4.57 7.85 7.85 0 00-1.05-.47 4.71 4.71 0 01-1.24-.62C8.2 2.84 8 1.81 7.7.43A.49.49 0 007.18 0a.5.5 0 00-.47.46C6.5 3.57 7.55 5 8.72 6a10.87 10.87 0 001.38 1c1.29.85 2.32 1.53 2 3.42a7.75 7.75 0 01-1.87-.54L10 9.83a3.56 3.56 0 01-1.51-.89 5.94 5.94 0 01-.92-2.34 4.78 4.78 0 00-1.4-2.89C5 2.85 2 3 .84 4a.5.5 0 00.42.87c1.62-.31 2.82-.17 3.47.4a5.41 5.41 0 011 2.76 10 10 0 00.73 2.54 8.5 8.5 0 002.3 2.54 5.23 5.23 0 00.76.45l.24.13-.39.09c-1 .24-2.86.53-3.44.16s-.48-1.44-.43-2.5a6.2 6.2 0 00-.31-3 2.89 2.89 0 00-2.3-1.4.5.5 0 00-.31.94c1 .46.87 1.5.56 3.15a10.29 10.29 0 00-.24 2.06 4 4 0 003.52 4.18 9.28 9.28 0 002.69-.18 5.17 5.17 0 012.53 0c.26.1.32.26.38 1.09a10 10 0 00.13 1.1 5.42 5.42 0 004.54 4.52 6.54 6.54 0 00.72 0 5.86 5.86 0 005.35-3.67 5.91 5.91 0 00-1.27-6.83zm-1.08 5.37a1.77 1.77 0 01-2.11 1.34 1.78 1.78 0 01-1-2.28 1.77 1.77 0 012.11-1.34 1.78 1.78 0 011 2.29z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});