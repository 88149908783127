define("ember-svg-jar/inlined/shopping-cart-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-download</title><circle cx=\"14.531\" cy=\"21.792\" r=\"2\"/><circle cx=\"4.531\" cy=\"21.792\" r=\"2\"/><path d=\"M23.719 2.292a1.25 1.25 0 00-1.25-1.25h-3.062a1.756 1.756 0 00-1.735 1.518L15.9 15.825a.251.251 0 01-.248.217H1.531a1.25 1.25 0 100 2.5h15a1.758 1.758 0 001.736-1.519l1.768-13.265a.248.248 0 01.247-.216h2.187a1.25 1.25 0 001.25-1.25z\"/><path d=\"M6.644 12.421a1.282 1.282 0 00.887.371 1.3 1.3 0 00.888-.371l5-5.042a1.249 1.249 0 10-1.775-1.759L9.209 8.075a.25.25 0 01-.428-.175V1.458a1.25 1.25 0 00-2.5 0V7.9a.25.25 0 01-.427.176L3.419 5.62a1.249 1.249 0 10-1.775 1.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});