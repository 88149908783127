define("ember-svg-jar/inlined/pollution-fish-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-fish-alternate</title><path d=\"M4.985 12.165a5.745 5.745 0 00.78.55.252.252 0 00.379-.192 12.434 12.434 0 01.231-1.45.505.505 0 00-.162-.488 6.994 6.994 0 01-.783-.822 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 11.409a1 1 0 000 2 5.116 5.116 0 003.345-1.244.5.5 0 01.64 0zM23 11.409a3.745 3.745 0 01-2.9-1.646.864.864 0 00-.244-.2.5.5 0 00-.731.33 9.7 9.7 0 01-.759 2.177.25.25 0 00.374.311c.1-.071.188-.144.275-.217a.5.5 0 01.64 0A5.126 5.126 0 0023 13.409a1 1 0 000-2z\"/><path d=\"M17.4 6.149c-.673-.722-4.747-1.156-7.513 1.421a8.512 8.512 0 00-2.31 6.085.5.5 0 01-.441.492l-1.8.213a1 1 0 00-.614 1.675l1.911 2.053a1 1 0 001.713-.488l.34-1.773a.5.5 0 01.524-.4c.219.015.469.025.743.025a8.048 8.048 0 005.5-1.892c2.768-2.577 2.621-6.677 1.948-7.4zm-2 2.951a.5.5 0 01-.476.347.514.514 0 01-.153-.024l-.122-.04a.5.5 0 00-.629.324l-.04.122a.5.5 0 01-.476.347.514.514 0 01-.153-.024.5.5 0 01-.323-.629l.04-.123a.5.5 0 00-.323-.629l-.123-.04a.5.5 0 11.307-.952l.122.039a.5.5 0 00.629-.322l.04-.122a.5.5 0 11.952.305l-.04.123a.5.5 0 00.032.382.494.494 0 00.291.247l.123.04a.5.5 0 01.322.629z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});