define("ember-svg-jar/inlined/beard-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style</title><path d=\"M23.891 3.276a1.174 1.174 0 00-1.076-1.058 1.239 1.239 0 00-1.356.746 36.5 36.5 0 01-2.494 4.641.5.5 0 01-.641.189c-1.907-.906-5.493-.961-6.324-.961s-4.41.055-6.318.959a.5.5 0 01-.641-.192 37.741 37.741 0 01-2.5-4.684 1.238 1.238 0 00-1.354-.746A1.176 1.176 0 00.11 3.228C-.209 7.243-.1 14.613 4.2 18.871a10.655 10.655 0 007.8 2.973 10.656 10.656 0 007.8-2.975c4.3-4.254 4.408-11.596 4.091-15.593zm-6.32 9.317a3.627 3.627 0 01-3.071 2.359c-.512 0-.777-.168-.86-.548-.057-.256-.256-.56-.881-.56h-1.512c-.626 0-.824.3-.882.562-.09.413-.355.6-.859.6a3.637 3.637 0 01-3.075-2.383 1 1 0 01.493-1.3 13.981 13.981 0 015.118-.694 13.769 13.769 0 015.035.68.993.993 0 01.494 1.284z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});