define("ember-svg-jar/inlined/reptile-hippo-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reptile-hippo-1</title><path d=\"M1.37 16.83c.583.067 1.452-.706 1.781-1.106A2.554 2.554 0 015.1 14.79a2.51 2.51 0 012.126 1.177 2 2 0 001.408.829 2.678 2.678 0 001.826-1 2.585 2.585 0 012-1 2.506 2.506 0 012.261 1.424 1.171 1.171 0 00.978.582 1.769 1.769 0 001.4-.8 2.413 2.413 0 012.138-1.2 2.507 2.507 0 012.127 1.179c.108.159.722.847 1.221.821a2.461 2.461 0 011.162.2V14.2c0-1.989 0-3.707-2.689-5.322a6.685 6.685 0 00-5.258-.242c-.268-1.285-2.177-2.038-3.718-.66a2.827 2.827 0 00-2.639-.784 4.424 4.424 0 00-4.291-3.523 3.455 3.455 0 00-3.434 3.68.436.436 0 000 .051l-.418.838a.75.75 0 001.342.67l.076-.154a.254.254 0 01.169-.132.25.25 0 01.209.047l.327.254a.249.249 0 01.069.309l-.233.468a.75.75 0 001.342.671l.056-.112a.249.249 0 01.377-.085l.064.049a1.665 1.665 0 01-.598 2.929.246.246 0 01-.216-.044.251.251 0 01-.1-.2v-.18a.75.75 0 00-1.5 0v.228a.25.25 0 01-.25.25h-.44a.25.25 0 01-.25-.25v-.226a.75.75 0 00-1.5 0c0 1.077.006 3.23.006 4.307a2.574 2.574 0 011.12-.207zm3.282-9.706a.748.748 0 01-.519-1.283 1.554 1.554 0 01.982-.435.736.736 0 01.78.718.749.749 0 01-.717.78.743.743 0 01-.526.22zm5.147 3.9a.979.979 0 11.978-.979.978.978 0 01-.977.974z\"/><path d=\"M22.691 18.286c-1.5.116-2.59-1.513-2.6-1.525a1.049 1.049 0 00-1.716.023A3.279 3.279 0 0115.7 18.3a2.719 2.719 0 01-2.333-1.436 1.044 1.044 0 00-1.713-.161 4.191 4.191 0 01-3.023 1.6 3.458 3.458 0 01-2.682-1.535 1.042 1.042 0 00-1.636-.094c-.014.019-1.418 1.768-3.025 1.661a.968.968 0 00-1.052.946 1 1 0 00.946 1.052H1.4a5.724 5.724 0 003.65-1.567A5.141 5.141 0 008.635 20.3a5.748 5.748 0 003.726-1.463A4.49 4.49 0 0015.7 20.3a5.056 5.056 0 003.543-1.456 4.724 4.724 0 003.583 1.442 1 1 0 10-.139-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});