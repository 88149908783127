define("ember-svg-jar/inlined/dentistry-tooth-chipped", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-chipped</title><path d=\"M14.979 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM19.929 3.465a1 1 0 10-1.414-1.414l-1.06 1.06a1 1 0 101.414 1.414zM20.979 6h-1.5a1 1 0 000 2h1.5a1 1 0 000-2zM17.479 9.339c0-.047 0-.092-.007-.138A.223.223 0 0017.25 9a2 2 0 01-2-2 .5.5 0 00-.6-.541 2 2 0 01-2.4-1.959.325.325 0 00-.414-.312 4.384 4.384 0 00-1.749.968.5.5 0 01-.673 0A4.408 4.408 0 006.433 4C3.959 4 2.021 6.345 2.021 9.339a8.89 8.89 0 001.867 5.9.5.5 0 01.088.491 13.249 13.249 0 00-.747 4.453 14.2 14.2 0 00.274 2.79A1.3 1.3 0 004.766 24a1.335 1.335 0 001.322-1.178c.3-2.761 1.872-4.843 3.662-4.843s3.364 2.082 3.663 4.85A1.332 1.332 0 0014.734 24 1.3 1.3 0 0016 22.969a14.2 14.2 0 00.273-2.786 13.249 13.249 0 00-.747-4.453.5.5 0 01.088-.491 8.89 8.89 0 001.865-5.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});