define("ember-svg-jar/inlined/heavy-equipment-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-truck</title><path d=\"M23.665 11.62a1.5 1.5 0 00-.681-1.994l-5.1-2.714-.338-1.73a1.51 1.51 0 00-.82-1.062l-4.5-2.175a.5.5 0 00-.708.546l.448 2.291-2.1 4.351a1.5 1.5 0 00.688 2l8.746 4.472H8.685a.5.5 0 01-.5-.5v-4a1.5 1.5 0 00-1.5-1.5H2.844a1.507 1.507 0 00-1.389.934l-1.24 3.394a.506.506 0 00-.03.172v3.5a1.5 1.5 0 001.5 1.5h1a.251.251 0 00.231-.154 3 3 0 015.538 0 .249.249 0 00.23.154h5a.251.251 0 00.231-.154 3 3 0 015.538 0 .25.25 0 00.231.154 1.5 1.5 0 001.5-1.5v-1.5a.5.5 0 00-.025-.156 1.5 1.5 0 00.765-.727zm-7.452-3.045l-1.088 2.251a.75.75 0 01-1.351-.653l1.088-2.251a.75.75 0 011.351.653zm1.42 3.462l1.088-2.251a.75.75 0 011.35.653l-1.088 2.251a.75.75 0 01-1.35-.653zM6.185 13.605a.5.5 0 01-.5.5h-3a.5.5 0 01-.448-.724l.751-2a.5.5 0 01.447-.276h2.25a.5.5 0 01.5.5z\"/><circle cx=\"5.685\" cy=\"20.105\" r=\"2\"/><circle cx=\"16.685\" cy=\"20.105\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});