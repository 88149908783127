define("ember-svg-jar/inlined/social-music-podcast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-podcast</title><path d=\"M12 3.86a6.43 6.43 0 00-5.45 9.83 1 1 0 101.69-1.06 4.43 4.43 0 117.5 0 1 1 0 101.69 1.07A6.43 6.43 0 0012 3.86z\"/><path d=\"M12 0a10.5 10.5 0 00-7.73 17.6 1 1 0 001.47-1.35 8.5 8.5 0 1112.54 0 1 1 0 101.48 1.35A10.5 10.5 0 0012 0z\"/><path d=\"M13.27 15h-2.54a2 2 0 00-2 2.22l.56 5a2 2 0 002 1.78h1.42a2 2 0 002-1.78l.56-5a2 2 0 00-2-2.22z\"/><circle cx=\"12\" cy=\"10.5\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});