define("ember-svg-jar/inlined/tuk-tuk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tuk-tuk</title><path d=\"M21.326 12.708a.244.244 0 01-.129-.142l-1.806-5.015a.247.247 0 01.034-.233.251.251 0 01.213-.1l1.563.077h.05a1 1 0 00.048-2l-2.406-.119a.25.25 0 01-.212-.138l-.281-.572a1.038 1.038 0 00-1.789 0l-.219.438a.25.25 0 01-.236.138L1.052 4.3h-.005a1 1 0 00-.1 2h.061a.25.25 0 01.238.25v9a2.236 2.236 0 00.988 1.85.25.25 0 00.382-.141 2.985 2.985 0 015.834.328.249.249 0 00.25.213h8.863a.251.251 0 01.246.2 2.485 2.485 0 004.891 0 .25.25 0 01.246-.2H23a1 1 0 001-1 4.464 4.464 0 00-2.674-4.092zm-13.076.337A.25.25 0 018 13.3H7a.25.25 0 01-.25-.25A1.752 1.752 0 005 11.3H4a.25.25 0 01-.25-.25V6.7a.251.251 0 01.262-.25l4 .2a.249.249 0 01.238.25zm10.2-3.256l-1.518 1.519a3.57 3.57 0 00-.884 1.781.249.249 0 01-.246.206h-1.568a.25.25 0 01-.234-.171l-1.3-3.9a1 1 0 00-1.9.633l1.035 3.1a.25.25 0 01-.237.329H10.5a.25.25 0 01-.25-.25v-6.02a.251.251 0 01.262-.25l6.964.344a.251.251 0 01.223.166l.8 2.252a.25.25 0 01-.054.261z\"/><circle cx=\"5.5\" cy=\"18.045\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});