define("ember-svg-jar/inlined/kitchenware-ladle-hot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-ladle-hot</title><path d=\"M13.145 12a1 1 0 01-.655-1.756.274.274 0 00.049-.36l-.216-.315a2.24 2.24 0 01.167-3.325 1 1 0 011.31 1.512.274.274 0 00-.049.36l.216.315a2.24 2.24 0 01-.167 3.325 1 1 0 01-.655.244zM17.645 12a1 1 0 01-.655-1.756.274.274 0 00.049-.36l-.216-.315a2.24 2.24 0 01.167-3.325 1 1 0 111.31 1.512.274.274 0 00-.049.36l.216.315a2.24 2.24 0 01-.167 3.325 1 1 0 01-.655.244z\"/><path d=\"M14.755 13.5a13.555 13.555 0 00-4.441.692.5.5 0 01-.669-.471V6.5a6.508 6.508 0 00-6.5-6.5 1 1 0 100 2 4.505 4.505 0 014.5 4.5v10.4a7.105 7.105 0 0014.21 0c0-2.23-3.57-3.4-7.1-3.4zm0 6c-2.943 0-4.9-1.053-4.9-1.746S11.811 16 14.754 16s4.891 1.053 4.891 1.754S17.7 19.5 14.754 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});