define("ember-svg-jar/inlined/common-file-give-hand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-give-hand-1</title><path d=\"M23.482 3.569L20.618.537A1.823 1.823 0 0019.321 0H12.5a2 2 0 00-2 2v12a2.294 2.294 0 002 2.333h9.667A1.835 1.835 0 0024 14.5V4.845a1.8 1.8 0 00-.518-1.276zM22 13.833a.5.5 0 01-.5.5h-8.866A.7.7 0 0112.5 14V2.5A.5.5 0 0113 2h6.034a.5.5 0 01.364.157l2.466 2.61A.5.5 0 0122 5.11zM16.5 19.5H8.139a.625.625 0 110-1.25H9.9a1 1 0 00.92-1.394A2.233 2.233 0 008.764 15.5H5.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h9.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM3 15.5H.5a.5.5 0 00-.5.5v7.5a.5.5 0 00.5.5H3a.5.5 0 00.5-.5V16a.5.5 0 00-.5-.5zm-1.5 7.25a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});