define("ember-svg-jar/inlined/board-game-geometry-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game-geometry-alternate</title><path d=\"M23.6 9.364l-6.537-8.272A1.444 1.444 0 0015.67.557L5.7 2.29a1.423 1.423 0 00-1.093.862L.179 12.814a1.484 1.484 0 00.159 1.313L6.451 22.8a1.461 1.461 0 001.225.667 1.43 1.43 0 00.16-.009l11.733-1.3a1.449 1.449 0 001.236-1.04l3.046-10.44a1.465 1.465 0 00-.251-1.314zM2.611 12.3C2.925 11.619 4 9.278 4.035 9.2s.168-.079.157.036l-.275 3.03a.25.25 0 01-.249.227H2.7c-.071-.004-.143-.072-.089-.193zm2 4.413c-.555-.788-1.66-2.356-1.683-2.386s-.015-.091.032-.091h1.004a.1.1 0 01.1.073c.009.029.453 1.511.7 2.324.092.313.036.349-.156.083zm10.12-10.508l1.306-2.611a.25.25 0 01.42-.043l3.263 4.134a.25.25 0 01-.275.392l-4.569-1.524a.249.249 0 01-.145-.348zM9.464 3.666l4.529-.787a.25.25 0 01.266.358L13.078 5.6a.251.251 0 01-.331.114L9.4 4.139a.25.25 0 01.064-.473zm4.9 12.73a.25.25 0 01-.349.277L6.8 13.364a.249.249 0 01-.044-.428l5.713-4.209a.25.25 0 01.393.152zm-8.571-5.443L6.334 5a.249.249 0 01.355-.2l4.6 2.164a.249.249 0 01.042.427L6.19 11.177a.25.25 0 01-.397-.224zm2.095 10.009L6.248 15.5a.25.25 0 01.343-.3l6.561 3a.25.25 0 01.008.451l-4.921 2.463a.249.249 0 01-.351-.152zm8.982-.522c-.871.1-3.762.417-3.86.429-.116.014-.169-.1-.074-.146l2.524-1.262a.249.249 0 01.25.016s1.074.721 1.173.781.141.165-.013.182zm2.37-1.1a.25.25 0 01-.379.138l-1.648-1.1a.25.25 0 01-.048-.378l2.93-3.3a.25.25 0 01.427.236zm1.929-8.474l-4.669 5.25a.25.25 0 01-.433-.116l-1.459-7.3a.25.25 0 01.324-.287l6.129 2.043a.251.251 0 01.108.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});