define("ember-svg-jar/inlined/dating-smartphone-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dating-smartphone-chat</title><path d=\"M22 0H10a2 2 0 00-2 2v7a2 2 0 002 2h2.5a.5.5 0 01.5.5v2a.5.5 0 00.854.354l2.56-2.561a1 1 0 01.707-.293H22a2 2 0 002-2V2a2 2 0 00-2-2zm-5.551 8.818a.711.711 0 01-.9 0C14.505 7.984 12 5.807 12 4.1c0-2.151 2.448-3.029 3.58-1.272a.5.5 0 00.84 0C17.553 1.073 20 1.951 20 4.1c0 1.707-2.5 3.884-3.551 4.718z\"/><path d=\"M13 15.5h-1a.5.5 0 00-.5.5v4a.5.5 0 01-.5.5H2.5A.5.5 0 012 20V6.5a.5.5 0 01.5-.5H6a.5.5 0 00.5-.5v-2A.5.5 0 006 3H3a3 3 0 00-3 3v15a3 3 0 003 3h7.5a3 3 0 003-3v-5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});