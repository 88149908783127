define("ember-svg-jar/inlined/animal-print-bird-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-bird-3</title><path d=\"M12 2.25a2.968 2.968 0 01.923.146.25.25 0 00.327-.238V1.25a1.25 1.25 0 00-2.5 0v.908a.25.25 0 00.327.238A2.968 2.968 0 0112 2.25zM2.631 6.131a2.945 2.945 0 01.755-.549.25.25 0 00.064-.4l-.642-.642A1.25 1.25 0 001.04 6.308l.642.642a.25.25 0 00.4-.064 2.945 2.945 0 01.549-.755zM20.412 7.088a1.5 1.5 0 00-2.121 0l-3.937 3.937a.5.5 0 01-.854-.353V5a1.5 1.5 0 00-3 0v5.672a.5.5 0 01-.854.353L5.709 7.088a1.5 1.5 0 00-2.121 2.121l6.619 6.619a1 1 0 01.293.707V19a1.5 1.5 0 003 0v-2.465a1 1 0 01.293-.707l6.619-6.619a1.5 1.5 0 000-2.121zM21.369 6.131a2.945 2.945 0 00-.755-.549.25.25 0 01-.064-.4l.642-.642a1.25 1.25 0 111.768 1.768l-.642.642a.25.25 0 01-.4-.064 2.945 2.945 0 00-.549-.755zM12 21.75a2.968 2.968 0 01-.923-.146.25.25 0 00-.327.238v.908a1.25 1.25 0 002.5 0v-.908a.25.25 0 00-.327-.238 2.968 2.968 0 01-.923.146z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});