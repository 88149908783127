define("ember-svg-jar/inlined/people-man-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-2</title><path d=\"M.5 23.313a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.606a2.465 2.465 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.493.493 0 00-.479.174c-.085.1-1.256 2.89-4.146 2.89s-4.061-2.789-4.145-2.889a.5.5 0 00-.48-.175 14.264 14.264 0 00-5.908 2.481A2.465 2.465 0 00.5 21.707z\"/><path d=\"M15.587 16.313a7 7 0 003.413-6v-2a6.958 6.958 0 00-1.078-3.721.247.247 0 01-.029-.2 2.679 2.679 0 00-.032-1.575L17.1.529a.5.5 0 00-.751-.258l-1.329.886a.253.253 0 01-.231.024l-2.4-.958a.5.5 0 00-.371 0l-2.394.958a.254.254 0 01-.224-.024L8.067.271a.5.5 0 00-.752.258l-.762 2.288a2.693 2.693 0 00-.121 1.157.249.249 0 01-.048.177A6.958 6.958 0 005 8.313v2a6.985 6.985 0 0010.587 6zm-8.587-6v-2a4.839 4.839 0 01.328-1.736.25.25 0 01.441-.05l.053.08a2.7 2.7 0 002.254 1.206h4.261a2.7 2.7 0 002.254-1.206.058.058 0 01.054-.025.057.057 0 01.046.037A4.913 4.913 0 0117 8.313v2a5 5 0 11-10 0z\"/><circle cx=\"14\" cy=\"10.563\" r=\"1\"/><circle cx=\"10\" cy=\"10.563\" r=\"1\"/><path d=\"M10.457 13.157a.639.639 0 00.317.72 2.376 2.376 0 00.435.179 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.624.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});