define("ember-svg-jar/inlined/image-document-bmp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-bmp-1</title><path d=\"M7.375 13.5A1.377 1.377 0 006 12.125h-.625a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H6A1.377 1.377 0 007.375 13.5zM5.125 18.625a.25.25 0 00.25.25H6a1.375 1.375 0 000-2.75h-.625a.25.25 0 00-.25.25zM18.5 12.125h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H18.5a1.375 1.375 0 000-2.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.591 17.925a2.72 2.72 0 01-2.723 2.2H4.5a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625h1.368a2.72 2.72 0 012.723 2.2 2.59 2.59 0 01-.721 2.249.246.246 0 000 .352 2.59 2.59 0 01.721 2.249zm6.534 1.575a.625.625 0 01-1.25 0v-4.806c0-.212-.212-.122-.276 0l-.54 1.08a.625.625 0 01-1.118 0s-.482-.972-.553-1.105-.263-.168-.263.026v4.8a.625.625 0 01-1.25 0v-8a.625.625 0 011.184-.279l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.184.279zm3.275-3.375h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.149 2.7 2.7 0 01-2.674 2.101z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});