define("ember-svg-jar/inlined/single-neutral-actions-address.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-address</title><path d=\"M12.1 10.951a.249.249 0 00.087-.234.252.252 0 00-.159-.193l-.531-.195-.627-.229a.586.586 0 01-.138-.309 2.629 2.629 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.6 5.6 0 006.2 8.47a2.511 2.511 0 01.072 1.318.592.592 0 01-.135.307l-.632.234c-2.446.9-4.213 1.548-4.753 2.626A9.16 9.16 0 000 16.5a.5.5 0 00.5.5h8.293a.249.249 0 00.249-.228 8.475 8.475 0 013.058-5.821z\"/><path d=\"M17.424 10.5a6.758 6.758 0 00-6.747 8.314 6.671 6.671 0 004.987 5 6.865 6.865 0 003.322-.044 1 1 0 00.71-1.223.988.988 0 00-1.04-.721.954.954 0 00-.184.012 5.032 5.032 0 01-2.361.027 4.725 4.725 0 01-3.483-3.495 4.8 4.8 0 01.946-4.133 4.721 4.721 0 013.8-1.739 4.894 4.894 0 014.6 4.721c0 .1.027.188.027.285v.82a.8.8 0 01-1.594 0v-1.075a3.156 3.156 0 10-3.156 3.156 3.106 3.106 0 001.476-.377.249.249 0 01.312.062 2.806 2.806 0 001.29.88 2.731 2.731 0 00.875.154 2.8 2.8 0 002.8-2.8v-.811a6.933 6.933 0 00-6.58-7.013zm-.174 7.9a1.157 1.157 0 111.156-1.157 1.157 1.157 0 01-1.156 1.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});