define("ember-svg-jar/inlined/design-document-psd-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-psd-1</title><path d=\"M17.125 12.5v6a.252.252 0 00.312.246 3.376 3.376 0 000-6.486.252.252 0 00-.312.24z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM6.4 16.125H5.375a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.149A2.7 2.7 0 016.4 16.125zm5.409-1.671l1.95.974a2.48 2.48 0 01-1.109 4.7H10.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.329l-1.949-.974a2.479 2.479 0 011.108-4.7H14.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.329zm4.7 5.671a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25z\"/><path d=\"M6.5 12.125H5.375a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H6.5a1.375 1.375 0 000-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});