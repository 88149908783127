define("ember-svg-jar/inlined/binocular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>binocular</title><path d=\"M22.309 13.817l-2.6-6.488a.52.52 0 00-.11-.168L18.18 5.737l-1.462-3.9a.517.517 0 00-.114-.178 2.881 2.881 0 00-3.708 0 .5.5 0 00-.146.354V7a10.81 10.81 0 00-1-.051c-.17 0-.335.008-.5.016v-4.95a.5.5 0 00-.146-.354 2.881 2.881 0 00-3.708 0 .517.517 0 00-.114.178L5.82 5.737 4.4 7.161a.52.52 0 00-.11.168l-2.599 6.488a5.5 5.5 0 109.559 3.7v-5.54c.155-.015.319-.025.5-.025a5.017 5.017 0 011 .094v5.469a5.5 5.5 0 109.559-3.7zM9.25 17.515a3.5 3.5 0 11-3.5-3.5 3.5 3.5 0 013.5 3.5zm9 3.5a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});