define("ember-svg-jar/inlined/plane-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-board</title><path d=\"M20.5 0h-17A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0020.5 0zm-13 19.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-4h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM7.252 8.2a.23.23 0 01-.034-.323c.011-.013 1.082-.954 1.082-.954a.227.227 0 01.221-.038l1.3.777 1.653-1.154-3.132-1.94a.284.284 0 01-.032-.4c.013-.016.811-.611.811-.611a.284.284 0 01.269-.038l4.318 1.607 2-1.363a1.209 1.209 0 011.639.285 1.317 1.317 0 01-.393 1.708L10 10.454a.415.415 0 01-.458-.013zM18.5 19.25h-8a.75.75 0 010-1.5h8a.75.75 0 010 1.5zm0-4h-8a.75.75 0 010-1.5h8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});