define("ember-svg-jar/inlined/famous-character-power-ranger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-power-ranger-1</title><path d=\"M9.265 10.335l.9.337a5.2 5.2 0 003.672 0l.9-.337A2.075 2.075 0 0016 8.361a1.884 1.884 0 00-.665-1.461 1.459 1.459 0 00-1.443-.248l-1.33.5a1.588 1.588 0 01-1.124 0l-1.33-.5a1.459 1.459 0 00-1.443.248A1.884 1.884 0 008 8.361a2.075 2.075 0 001.265 1.974zm3.823-1.909l.89-.5a.5.5 0 01.49.871l-.89.5a.5.5 0 01-.681-.191.5.5 0 01.191-.68zm-3.746-.31a.5.5 0 01.68-.19l.89.5a.5.5 0 01-.245.935.493.493 0 01-.245-.064l-.89-.5a.5.5 0 01-.19-.681zM13 21h-2a.5.5 0 000 1h2a.5.5 0 000-1zM13.5 23.5a.5.5 0 00-.5-.5h-2a.5.5 0 000 1h2a.5.5 0 00.5-.5zM3.357 17.6a.261.261 0 00-.321-.065 11.184 11.184 0 00-1.568.989A2.46 2.46 0 00.5 20.505v3A.5.5 0 001 24h5.22a.286.286 0 00.28-.308V22.61a3 3 0 00-.694-1.919z\"/><path d=\"M6.7 6.571c.077-.033 6.747-2.933 10.19-.458a.266.266 0 01.11.213v2.05a6.051 6.051 0 01-1.81 4.088 4.933 4.933 0 01-3.821 1.111 5.11 5.11 0 01-4.356-4.808 1 1 0 10-1.994.141A7.184 7.184 0 009.5 15.126a.247.247 0 01-.054.479 20.726 20.726 0 00-4.051.951.249.249 0 00-.03.459c1.627.8 5.084 2.314 6.246 2.819a.966.966 0 00.778 0c1.162-.5 4.617-2.015 6.245-2.818a.249.249 0 00-.028-.459 20.748 20.748 0 00-4.013-.946.247.247 0 01-.071-.472 7 7 0 004.226-4.654A6.867 6.867 0 0019 8.561s-.025-2.573-.038-2.636a5.791 5.791 0 00-2.955-4.706C12.5-.812 7.158.018 4.808 1.368a1 1 0 00.33 1.852c.066.011.13.023.193.036.093.019.2.111.022.306a7.337 7.337 0 00-1.4 2.073 1 1 0 00.227 1.136c.595.566 2.49-.186 2.52-.2zM22.533 18.528a11.2 11.2 0 00-1.569-.989.261.261 0 00-.321.065l-2.449 3.087a3 3 0 00-.694 1.919v1.148a.218.218 0 00.251.242H23a.5.5 0 00.5-.5v-3a2.462 2.462 0 00-.967-1.972z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});