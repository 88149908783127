define("ember-svg-jar/inlined/task-list-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-add</title><path d=\"M17.464 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25h-1.5a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5zM12.786 8.5a.75.75 0 00-.75-.75h-6a.75.75 0 000 1.5h6a.75.75 0 00.75-.75zM6.036 14.749a.75.75 0 100 1.5h3.405a.241.241 0 00.237-.2 7.964 7.964 0 01.238-.954c.036-.116-.006-.345-.333-.345z\"/><path d=\"M9.722 19.194a.244.244 0 00-.238-.2H3.536a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v4.08a.247.247 0 00.3.245 18.173 18.173 0 012.464-.293.249.249 0 00.234-.25V3.5a1.5 1.5 0 00-1.5-1.5h-4a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.239.239 0 015.534 2h-4a1.5 1.5 0 00-1.5 1.5v17a1.5 1.5 0 001.5 1.5h8.94a.246.246 0 00.207-.379 7.977 7.977 0 01-.959-2.427z\"/><path d=\"M11.186 12.654a8.065 8.065 0 011.1-1.174c.09-.079-.01-.231-.245-.231h-6a.75.75 0 000 1.5H11a.24.24 0 00.186-.095z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});