define("ember-svg-jar/inlined/single-man-actions-edit-1.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-1</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.505 24a.485.485 0 00.136-.019l3.069-.876a.5.5 0 00.343-.352.494.494 0 00-.129-.474l-2.192-2.192a.5.5 0 00-.826.214l-.877 3.068a.5.5 0 00.476.631zM13.043 19l2.969 2.969a.495.495 0 00.7 0l4.959-4.959a.494.494 0 000-.7L18.7 13.34a.494.494 0 00-.7 0l-4.957 4.96a.494.494 0 000 .7zM19.4 12.64l2.969 2.969a.454.454 0 00.324.124.61.61 0 00.422-.17 2.594 2.594 0 000-3.669 2.614 2.614 0 00-3.717.048.5.5 0 00.002.698z\"/><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h1.791a.494.494 0 00.495-.5V14.1a5.443 5.443 0 10-10.886 0v3.464a.494.494 0 00.495.498z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM11.505 24a.485.485 0 00.136-.019l3.069-.876a.5.5 0 00.343-.352.494.494 0 00-.129-.474l-2.192-2.192a.5.5 0 00-.826.214l-.877 3.068a.5.5 0 00.476.631zM13.043 19l2.969 2.969a.495.495 0 00.7 0l4.959-4.959a.494.494 0 000-.7L18.7 13.34a.494.494 0 00-.7 0l-4.957 4.96a.494.494 0 000 .7zM19.4 12.64l2.969 2.969a.454.454 0 00.324.124.61.61 0 00.422-.17 2.594 2.594 0 000-3.669 2.614 2.614 0 00-3.717.048.5.5 0 00.002.698z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});