define("ember-svg-jar/inlined/pets-certificate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-certificate</title><path d=\"M24 4a2 2 0 00-2-2H2a2 2 0 00-2 2v16a2 2 0 002 2h20a2 2 0 002-2zM6.744 20a.25.25 0 01-.25-.237 4.508 4.508 0 00-4.257-4.257.249.249 0 01-.237-.249V8.744a.25.25 0 01.237-.25 4.508 4.508 0 004.257-4.257A.249.249 0 016.743 4h10.513a.25.25 0 01.25.237 4.508 4.508 0 004.257 4.257.249.249 0 01.237.249v6.513a.25.25 0 01-.237.25 4.508 4.508 0 00-4.257 4.257.249.249 0 01-.249.237z\"/><path d=\"M13.271 11.7a1.637 1.637 0 00-2.541 0l-1.645 2.241a1.614 1.614 0 001.271 2.559h3.289a1.607 1.607 0 001.27-2.546zM9.912 10.857a1.786 1.786 0 10-1.786 1.786 1.788 1.788 0 001.786-1.786zM11.984 10.071A1.786 1.786 0 1010.2 8.286a1.788 1.788 0 001.784 1.785zM15.874 9.071a1.786 1.786 0 101.785 1.786 1.788 1.788 0 00-1.785-1.786z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});