define("ember-svg-jar/inlined/office-file-doc-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-doc-1</title><path d=\"M3.451 12.327c-.1-.046-.326-.048-.326.164v3.978a.237.237 0 00.327.209 2.374 2.374 0 000-4.351zM9 12.123a.877.877 0 00-.875.877v3a.875.875 0 101.75 0v-3A.877.877 0 009 12.123z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zm-15.5-.877a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25zM11.125 16a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 114.25 0zm4.375.875a.625.625 0 110 1.25 3.625 3.625 0 010-7.25.625.625 0 010 1.25 2.375 2.375 0 100 4.75z\"/><path d=\"M23.707 5.705L18.293.291A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.748V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.249A.248.248 0 016 21.749v-.5A.251.251 0 005.749 21H4.25a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});