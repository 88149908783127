define("ember-svg-jar/inlined/iris-scan-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-approved</title><path d=\"M23.092 9.665C20.463 6.77 16.594 4.2 12.584 4.012 12.389 4 12.2 4 12 4c-4.253.034-8.124 2.47-10.942 5.5l-.15.164a3.457 3.457 0 000 4.669C2.113 15.662 6.471 20 11.872 20h.256c5.4 0 9.759-4.338 10.966-5.667a3.456 3.456 0 00-.002-4.668zm-1.478 3.322c-2.17 2.388-5.32 4.655-8.642 4.968A8.541 8.541 0 0112 18c-3.63-.085-6.84-2.11-9.286-4.658q-.167-.174-.329-.353a1.478 1.478 0 010-1.979C4.752 8.4 8.332 5.915 12 6a9.407 9.407 0 013.747.774 15.249 15.249 0 013.4 1.977 18.084 18.084 0 012.47 2.256 1.478 1.478 0 01-.003 1.98z\"/><path d=\"M12 6.5a5.5 5.5 0 105.5 5.5A5.506 5.506 0 0012 6.5zm3.474 4.354l-2.9 3.875a1.751 1.751 0 01-2.64.185l-1.5-1.5A1 1 0 019.844 12l1.094 1.094a.249.249 0 00.377-.027l2.559-3.412a1 1 0 011.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});