define("ember-svg-jar/inlined/landmark-taj-mahal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-taj-mahal</title><rect x=\".5\" y=\"12.499\" width=\"2.5\" height=\"11.5\" rx=\".5\" ry=\".5\"/><rect x=\"21\" y=\"12.499\" width=\"2.5\" height=\"11.5\" rx=\".5\" ry=\".5\"/><path d=\"M14.31 4.224A2.493 2.493 0 0015 2.51a.5.5 0 00-.936-.248.979.979 0 01-.15.2 1 1 0 11-1.448-1.38 1.027 1.027 0 01.2-.159.5.5 0 00.212-.574A.482.482 0 0012.37 0a2.5 2.5 0 101.94 4.221zM.872 7.027A5.2 5.2 0 000 9.249a1.75 1.75 0 003.5 0 5.186 5.186 0 00-.873-2.223 1.04 1.04 0 00-1.755.001zM22.25 11A1.752 1.752 0 0024 9.249a5.2 5.2 0 00-.873-2.224 1.04 1.04 0 00-1.754 0 5.186 5.186 0 00-.873 2.224A1.752 1.752 0 0022.25 11zM19 15H5a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V20a1 1 0 012 0v3.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V20a1 1 0 112 0v3.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V20a1 1 0 112 0v3.5a.5.5 0 00.5.5H19a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5zM6.8 13.5h10.4a.5.5 0 00.459-.3A4.23 4.23 0 0018 11.5c0-2.7-5.17-5.615-5.76-5.939a.5.5 0 00-.48 0C11.17 5.884 6 8.8 6 11.5a4.23 4.23 0 00.336 1.7.5.5 0 00.464.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});