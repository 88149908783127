define("ember-svg-jar/inlined/rating-star-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-badge</title><path d=\"M22 3.071a.5.5 0 00.5-.5v-.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v.5a.5.5 0 00.5.5zM2 4.571a.5.5 0 00-.5.5v2.265a18.809 18.809 0 009.513 16.334 2.012 2.012 0 001.975 0A18.811 18.811 0 0022.5 7.336V5.071a.5.5 0 00-.5-.5zm4.64 5.522a.423.423 0 01.4-.272h2.737a.5.5 0 00.459-.3l1.377-3.193a.422.422 0 01.775 0l1.377 3.193a.5.5 0 00.459.3h2.742a.422.422 0 01.281.737l-2.311 2.06a.5.5 0 00-.126.573l1.352 3.109a.421.421 0 01-.594.535l-3.322-1.871a.5.5 0 00-.491 0l-3.322 1.874a.422.422 0 01-.594-.536l1.352-3.111a.5.5 0 00-.126-.573l-2.311-2.06a.422.422 0 01-.114-.465z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});