define("ember-svg-jar/inlined/single-neutral-actions-flag.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flag</title><path d=\"M12.5 12.621a.475.475 0 010-.054.5.5 0 00-.189-.441A7.459 7.459 0 007.7 10.5a7.543 7.543 0 00-7.183 5.357A.5.5 0 001 16.5h11a.5.5 0 00.5-.5z\"/><circle cx=\"7.496\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.5 12.621a.5.5 0 00-.709-.454l-.884.41a3.053 3.053 0 01-2.716-.37c-2.095-1.418-4.234-.274-4.868-.06a.5.5 0 00-.339.474L14 23a1 1 0 002 0v-4.662a.25.25 0 01.19-.243 3.044 3.044 0 012.447.44 4.025 4.025 0 002.272.7 4.747 4.747 0 002.309-.662.5.5 0 00.29-.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});