define("ember-svg-jar/inlined/tags-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-2</title><path d=\"M22.974 10.451a7.033 7.033 0 000-6.409l-.028-.059c-.076-.145-.164-.284-.25-.424-.038-.06-.07-.122-.109-.181-.06-.09-.13-.177-.2-.265A7.032 7.032 0 0021.7 2.3c-.017-.017-.035-.031-.052-.047a6.977 6.977 0 00-.6-.525c-.075-.059-.153-.114-.23-.169q-.265-.19-.548-.354A7 7 0 0011.8 2.3a6.925 6.925 0 00-1.518 2.28.987.987 0 00.966 1.352h.034a.959.959 0 00.845-.577 5 5 0 115.973 6.706 4.614 4.614 0 01-.716.137.253.253 0 01-.2-.066.249.249 0 01-.078-.2l.17-3.222a1.885 1.885 0 00-2-2l-4.719.248A4.18 4.18 0 008.2 8.017L.84 15.38a2 2 0 000 2.829l4.949 4.95a2 2 0 002.828 0l7.361-7.365a2.568 2.568 0 00.186-.211.247.247 0 00.033-.253.251.251 0 00-.207-.151 7.861 7.861 0 01-3.864-1.413.253.253 0 00-.115-.044 1.977 1.977 0 01-1.176-.563 2 2 0 012.828-2.828 1.94 1.94 0 01.46.731.25.25 0 01-.293.326 1 1 0 00-1.065.45.988.988 0 00.228 1.282 7.039 7.039 0 007.709-.1c.026-.018.051-.038.077-.057a6.739 6.739 0 00.9-.747l.025-.022a6.95 6.95 0 00.692-.818c.065-.088.135-.174.2-.265.039-.059.071-.121.108-.18.087-.14.175-.28.251-.425a.172.172 0 01.019-.055zm-14.514 8a1 1 0 01-1.414 0l-1.5-1.5a1 1 0 011.414-1.413l1.5 1.5a1 1 0 010 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});