define("ember-svg-jar/inlined/web-service-wikipedia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>web-service-wikipedia</title><path d=\"M23 4h-3a1 1 0 000 2h.26l-5.15 10.63L13 11.76 16 6h.53a1 1 0 000-2h-3a1 1 0 000 2h.21l-1.81 3.41L10.42 6h.08a1 1 0 000-2h-3a1 1 0 000 2h.74l2.51 5.7-2.57 4.93L3.88 6h.62a1 1 0 000-2H1a1 1 0 000 2h.72l5.42 13.39A1 1 0 008 20a1 1 0 00.89-.54L11.78 14l2.37 5.38a1 1 0 00.9.6 1 1 0 00.9-.56L22.49 6H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});