define("ember-svg-jar/inlined/medical-room-clock-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-room-clock-man</title><path d=\"M5.5 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm0-8a3 3 0 11-3 3 3 3 0 013-3z\"/><path d=\"M5.5 6.3h1.086a.75.75 0 000-1.5H6.25v-.866a.75.75 0 00-1.5 0v1.619a.75.75 0 00.75.747zM14.5 17.646a.251.251 0 00-.182-.241 2.506 2.506 0 01-1.808-2.178.249.249 0 00-.249-.227H7.734l-2.617-3.675a2 2 0 00-3.237 2.349l3.216 4.5A2.007 2.007 0 006.713 19h7.537a.25.25 0 00.25-.25zM22.5 20h-.75a.25.25 0 00-.25.25V23a2.437 2.437 0 01-.1.681.252.252 0 00.041.219.249.249 0 00.2.1H23a.5.5 0 00.5-.5V21a1 1 0 00-1-1zM14.5 23v-2.75a.25.25 0 00-.25-.25H2a1 1 0 00-1 1v2.5a.5.5 0 00.5.5h12.856a.252.252 0 00.2-.1.248.248 0 00.04-.219A2.473 2.473 0 0114.5 23z\"/><circle cx=\"18\" cy=\"3.5\" r=\"2.5\"/><path d=\"M20 23v-7h1a1 1 0 001-1v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5a1 1 0 001 1h1v7a1 1 0 001 1h2a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});