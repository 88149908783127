define("ember-svg-jar/inlined/landmark-pyramid-sphinx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-pyramid-sphinx</title><path d=\"M19.183 14.812a.251.251 0 00.046.291l1.252 1.251a.249.249 0 00.352 0l.723-.715A1.516 1.516 0 0022 14.58v-1.09a5.5 5.5 0 10-11 0v1.09a1.516 1.516 0 00.44 1.06l.724.715a.249.249 0 00.352 0l1.248-1.255a.252.252 0 00.046-.291 2.876 2.876 0 01-.31-1.319v-1.5a3 3 0 116 0v1.5a2.888 2.888 0 01-.317 1.322z\"/><path d=\"M10.836 9.7a.25.25 0 00.4-.01 6.5 6.5 0 0111.604 2.368.155.155 0 00.152.122 1 1 0 00.79-1.62L15.806.37a1.036 1.036 0 00-1.57 0l-5.248 6.7a.249.249 0 000 .31zM10 13.74a6.614 6.614 0 01.474-2.7.251.251 0 00-.036-.25L6.741 6.17v-.01a1.032 1.032 0 00-1.56.01l-4.96 6.2A1 1 0 001 13.99h8.75a.25.25 0 00.25-.25z\"/><path d=\"M14.5 11.99v1.5a2 2 0 004 0v-1.5a2 2 0 00-4 0zM12.5 21.49h-2a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M21.735 20.49a.25.25 0 00.249-.273 2.88 2.88 0 00-.838-1.787l-2.521-2.521a.249.249 0 00-.326-.023 2.992 2.992 0 01-3.6 0 .25.25 0 00-.327.023l-2.526 2.521a2.879 2.879 0 00-.837 1.787.25.25 0 00.249.273H12.5a2.5 2.5 0 012.5 2.5v.75a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25v-.75a2.5 2.5 0 012.5-2.5z\"/><path d=\"M22.5 21.49h-2a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});