define("ember-svg-jar/inlined/calendar-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-skull</title><path d=\"M11.164 16.5H2.625a.5.5 0 01-.5-.5V7.5a.5.5 0 01.5-.5h13.5a.5.5 0 01.5.5v2.722a7.221 7.221 0 011.75-.222c.084 0 .166.01.25.013V3a1 1 0 00-1-1h-2.5a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5h-4.25a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5h-2.25a1 1 0 00-1 1v13.5a2 2 0 002 2h9.115a7.254 7.254 0 01-.115-1.25 7.412 7.412 0 01.039-.75z\"/><path d=\"M22.389 20.576a4.656 4.656 0 001.486-3.376 5.5 5.5 0 10-11 0 4.669 4.669 0 001.5 3.381c0 .109-.006.219-.006.342v2.58a.5.5 0 00.5.5h1a.25.25 0 00.25-.25V22a.75.75 0 011.5 0v1.75a.25.25 0 00.25.25h1a.25.25 0 00.25-.25V22a.75.75 0 011.5 0v1.748a.249.249 0 00.25.25H21.9a.5.5 0 00.5-.5v-2.582c0-.123-.007-.233-.011-.34zm-5.847-5.159a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zm3.666 0a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});