define("ember-svg-jar/inlined/folder-disable-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-disable-alternate</title><path d=\"M2.5 2h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.07a.242.242 0 00.181.234 7.9 7.9 0 011.459.559.244.244 0 00.36-.215V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h7.3a.244.244 0 00.242-.27 8.181 8.181 0 010-1.46.244.244 0 00-.242-.27H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-3.967 8.6a4.448 4.448 0 01-.367-3.319 4.5 4.5 0 013.112-3.117 4.443 4.443 0 013.325.367.252.252 0 01.052.4l-5.724 5.722a.252.252 0 01-.398-.053zm8.3-.887a4.5 4.5 0 01-3.112 3.117 4.443 4.443 0 01-3.325-.367.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.4.051 4.448 4.448 0 01.366 3.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});