define("ember-svg-jar/inlined/video-player-subtitle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-player-subtitle</title><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v14a3 3 0 003 3h3.25a.25.25 0 01.25.25V23a1 1 0 001.707.707l3.634-3.634a.249.249 0 01.177-.073H21a3 3 0 003-3zM10.25 16.75H3.5a.75.75 0 010-1.5h6.75a.75.75 0 010 1.5zM12.5 16a2 2 0 112 2 2 2 0 01-2-2zm8 .75h-1.75a.75.75 0 010-1.5h1.75a.75.75 0 010 1.5zM2 3a1 1 0 011-1h18a1 1 0 011 1v8.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M15 6.2L9.917 3.608a.961.961 0 00-1.417.8v5.18a.963.963 0 001.417.8L15 7.8a.892.892 0 000-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});