define("ember-svg-jar/inlined/controls-movie-record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-record</title><path d=\"M9.525 17.82q-.032-.329-.032-.664a7.783 7.783 0 018.021-7.5 8.407 8.407 0 014.09 1.05.25.25 0 00.371-.218V6.375a.486.486 0 00-.5-.469H.47a.486.486 0 00-.5.469v9.375a2.431 2.431 0 002.506 2.344h6.8a.25.25 0 00.249-.274z\"/><path d=\"M17.514 11.063A6.323 6.323 0 0011 17.156a6.324 6.324 0 006.517 6.094 6.323 6.323 0 006.517-6.094 6.322 6.322 0 00-6.52-6.093zm0 8.906a2.914 2.914 0 01-3.007-2.813 2.914 2.914 0 013.007-2.812 2.915 2.915 0 013.008 2.812 2.915 2.915 0 01-3.008 2.813zM4.469 4.1a.224.224 0 00-.055.255.252.252 0 00.232.145h3.183a.258.258 0 00.177-.069l3.507-3.281a.222.222 0 00.054-.25.251.251 0 00-.231-.15H8.153a.261.261 0 00-.178.069zM16.5 4.1a.222.222 0 00-.054.255.25.25 0 00.231.145h4.8a.486.486 0 00.5-.469v-.937A2.375 2.375 0 0020.165.843a.264.264 0 00-.247.057zM.471 4.5h1.342a.261.261 0 00.178-.069L5.5 1.15A.224.224 0 005.552.9.252.252 0 005.32.75H2.476A2.431 2.431 0 00-.03 3.094v.937a.486.486 0 00.501.469zM10.484 4.1a.224.224 0 00-.054.255.251.251 0 00.232.145h3.183a.26.26 0 00.177-.069l3.507-3.281a.224.224 0 00.054-.25.251.251 0 00-.232-.15h-3.183a.26.26 0 00-.177.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});