define("ember-svg-jar/inlined/road-sign-side-road-angle-left-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-left-2-alternate</title><path d=\"M8.115 9.779a1 1 0 011.174-.435l1.632.544a.248.248 0 00.225-.034.252.252 0 00.1-.2V8.192a1 1 0 011-1h2a1 1 0 011 1v8a1 1 0 01-1 1h-2a1 1 0 01-1-1v-1.959a.25.25 0 00-.167-.233l-3.4-1.133a1 1 0 01-.537-1.467z\"/><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm.53-21.116a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});