define("ember-svg-jar/inlined/style-three-pin-braille", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-braille</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.561 10a1 1 0 111-1 1 1 0 01-1 1zm9-6a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm-3 0a1 1 0 11-1 1 1 1 0 011-1zm-1 5a1 1 0 111 1 1 1 0 01-1-1zm8.394 5.02c-.108.469-.542 1.855-.784 2.629a.5.5 0 01-.478.351h-2.925a.5.5 0 01-.353-.146L10.061 14.5c-1.126-1.244.371-1.864 1.606-.988a.25.25 0 00.394-.2V10a1 1 0 112 0v1.605a.5.5 0 00.39.487c1.392.316 2.823.546 2.504 1.928z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});