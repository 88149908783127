define("ember-svg-jar/inlined/biking-helmet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>biking-helmet</title><path d=\"M11.5 0a19.094 19.094 0 00-8.745 2.064A1.537 1.537 0 002 3.511l.011.506a.5.5 0 01-.251.445 3.5 3.5 0 00.014 6.081.5.5 0 01.252.4c.083 1.034.389 3.476 1.142 4.213a1.178 1.178 0 00.809.349H10.5a.5.5 0 01.4.2 2.016 2.016 0 00.4.4.5.5 0 01.2.4v3.135a.5.5 0 01-.25.433.5.5 0 00-.25.427V23a1 1 0 001 1h1a1 1 0 001-1v-2.5a.5.5 0 00-.25-.433.5.5 0 01-.25-.433V16.5a.5.5 0 01.2-.4 2 2 0 000-3.2.5.5 0 01-.2-.4v-1a.5.5 0 01.5-.5h8a2 2 0 002-2c0-1.929-4.551-9-12.5-9zM3.977 13.5zm7.523-1a.5.5 0 01-.2.4 2.016 2.016 0 00-.4.4.5.5 0 01-.4.2h-6a13.024 13.024 0 01-.4-1.93.5.5 0 01.5-.57H11a.5.5 0 01.5.5zm9.1-5.469a.751.751 0 01-1.061 0 10.615 10.615 0 00-7.32-2.781 10.26 10.26 0 00-5.33 1.459.75.75 0 11-.778-1.282 11.753 11.753 0 016.108-1.677 11.989 11.989 0 018.386 3.221.751.751 0 01-.005 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});