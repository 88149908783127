define("ember-svg-jar/inlined/style-one-pin-photography", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-photography</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.875-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm5.5 11.5A1.5 1.5 0 0116 13H8a1.5 1.5 0 01-1.5-1.5v-4A1.5 1.5 0 018 6h.8a.5.5 0 00.416-.222l.222-.333a1 1 0 01.83-.445h3.464a1 1 0 01.832.445l.222.332A.5.5 0 0015.2 6h.8a1.5 1.5 0 011.5 1.5z\"/><circle cx=\"12\" cy=\"9.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});