define("ember-svg-jar/inlined/swimming-pool-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-pool-board</title><path d=\"M3.5 17.318a.5.5 0 01.5.5v1.754a.5.5 0 00.544.5 2.4 2.4 0 01.535.011c.625.074 1.55-.742 1.82-1.085a2.5 2.5 0 011.962-.951 2.437 2.437 0 01.941.19.5.5 0 00.692-.461v-6.7c0-2.866 2.557-4.453 4.309-5.2a.493.493 0 01.2-.041h7.234a1 1 0 000-2h-8.749a.5.5 0 01-.5-.5v-.921a2 2 0 00-2-2H6a2 2 0 00-2 2v.666a.5.5 0 01-.5.5H2a2 2 0 00-2 2v14.736a1 1 0 102 0v-2.5a.5.5 0 01.5-.5zM6 2.914a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v.416a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zM2 6.078a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.749a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm0 3.747a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm0 5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.505zM5.163 23.584a4.113 4.113 0 01-.549-.036 1 1 0 11.264-1.98c1.677.215 3.181-1.625 3.2-1.644a1.04 1.04 0 011.636.091 3.463 3.463 0 002.684 1.537 4.194 4.194 0 003.023-1.6 1.044 1.044 0 011.712.159 2.725 2.725 0 002.335 1.439 3.279 3.279 0 002.674-1.514 1.014 1.014 0 01.858-.492 1 1 0 01.814 1.582 5.283 5.283 0 01-4.35 2.424 4.449 4.449 0 01-3.042-1.169.5.5 0 00-.635-.027 5.619 5.619 0 01-3.393 1.2A5.036 5.036 0 019.116 22.3a.5.5 0 00-.632-.01 5.479 5.479 0 01-3.321 1.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});