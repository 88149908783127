define("ember-svg-jar/inlined/halloween-cauldron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-cauldron</title><circle cx=\"21\" cy=\"3\" r=\"1\"/><circle cx=\"6\" cy=\"1.5\" r=\"1\"/><circle cx=\"17\" cy=\".75\" r=\".75\"/><path d=\"M22.886 11.271A1.814 1.814 0 0024 9.5a1.974 1.974 0 00-1.672-1.977.5.5 0 01-.442-.39 3.986 3.986 0 00-4.238-3.117.5.5 0 01-.465-.228 4.968 4.968 0 00-8.2-.237.5.5 0 01-.566.18A4.481 4.481 0 002.613 7.1c-.1.5-.578.374-.678.4A1.97 1.97 0 000 9.5a1.812 1.812 0 001.114 1.77.654.654 0 01.4.8 9.152 9.152 0 00.995 8.25A8.322 8.322 0 009.351 24h5.3a8.321 8.321 0 006.849-3.679 9.147 9.147 0 00.994-8.249.654.654 0 01.392-.801zm-4.221-1.4a1 1 0 00-.665.942V14a1.5 1.5 0 01-3 0V9a1.5 1.5 0 00-1.5-1.5H4.878a.251.251 0 01-.2-.106.245.245 0 01-.031-.227A2.5 2.5 0 017 5.5a2.475 2.475 0 011.57.575 1 1 0 001.586-.475 2.975 2.975 0 015.679-.029 1 1 0 001.357.6A1.98 1.98 0 0118 6a1.993 1.993 0 01.665 3.874z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});