define("ember-svg-jar/inlined/database-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-edit</title><path d=\"M1.625 15.221V17.5c0 1.755 2.683 3.244 6.413 3.784a.25.25 0 00.281-.2l.346-1.727a2.049 2.049 0 01.555-1.037.251.251 0 00.051-.26.249.249 0 00-.21-.16c-3.07-.283-5.969-1.194-7.436-2.679z\"/><path d=\"M1.625 10.721V13c0 2.114 3.9 3.839 8.826 3.985a.249.249 0 00.184-.073l3.122-3.122a.25.25 0 00-.2-.425 22.338 22.338 0 01-2.428.135c-3.772 0-7.71-.963-9.504-2.779z\"/><path d=\"M11.126 9c-3.769 0-7.707-.963-9.5-2.78V8.5c0 2.21 4.255 4 9.5 4a20.671 20.671 0 004.3-.435.252.252 0 00.124-.065l1.292-1.292a4.087 4.087 0 012.91-1.2 2.635 2.635 0 01.368.03.25.25 0 00.251-.127 1.834 1.834 0 00.254-.9V6.222C18.831 8.038 14.894 9 11.126 9z\"/><ellipse cx=\"11.126\" cy=\"4\" rx=\"9.5\" ry=\"4\"/><path d=\"M11.114 20.046a.5.5 0 00-.835.216l-.885 3.1a.5.5 0 00.618.619l3.1-.886a.5.5 0 00.216-.834zM16.439 13.229l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0zM21.606 11.768a2.639 2.639 0 00-3.755.049.5.5 0 000 .705l3 3a.456.456 0 00.327.125.617.617 0 00.426-.172 2.618 2.618 0 000-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});