define("ember-svg-jar/inlined/weather-cloud-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-1</title><path d=\"M22.513 12.443a.252.252 0 01-.071-.218 4.6 4.6 0 00.058-.725 4.489 4.489 0 00-3.517-4.392.251.251 0 01-.171-.136 7 7 0 00-12.988.915.251.251 0 01-.205.173A6.5 6.5 0 006.5 21H19a5 5 0 003.513-8.557zM22 16a3 3 0 01-3 3H6.5a4.5 4.5 0 11.081-9 1 1 0 00.986-.835 5 5 0 019.647-.83 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169A3 3 0 0122 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});