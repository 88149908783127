define("ember-svg-jar/inlined/laptop-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-search</title><path d=\"M9.437 15.178a2.339 2.339 0 01-1.386-1.327.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.245a.249.249 0 00.186-.083.246.246 0 00.063-.193 6.668 6.668 0 01-.033-.684 6.784 6.784 0 01.085-1.083.251.251 0 00-.149-.27zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.606a.249.249 0 00.173.238 6.929 6.929 0 011.449.654.251.251 0 00.378-.215V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1z\"/><path d=\"M23.708 22.294l-2.483-2.483a.248.248 0 01-.033-.311 5.54 5.54 0 10-1.692 1.692.251.251 0 01.311.033l2.483 2.483a1 1 0 001.414-1.414zm-7.177-2.233a3.53 3.53 0 113.53-3.53 3.529 3.529 0 01-3.53 3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});