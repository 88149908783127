define("ember-svg-jar/inlined/trekking-mountain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-mountain</title><path d=\"M14.444 7.291A.974.974 0 0015.6 6.205l-.339-2.824a1 1 0 10-1.986.238l.338 2.816a1.01 1.01 0 00.806.851zM14.887 11.617a.963.963 0 00-.532 1l.4 3.354a.266.266 0 00.44.166l1.245-1.106a.5.5 0 00.165-.435l-.267-2.226a1 1 0 00-1.432-.76z\"/><circle cx=\"10.167\" cy=\"2.5\" r=\"2.5\"/><path d=\"M1.981 17.988a1.277 1.277 0 102.161 1.36l1.931-3.077a1.312 1.312 0 00.14-.589v-1.2a.25.25 0 01.089-.192.253.253 0 01.205-.054l1.262.23a1.32 1.32 0 011.052 1.011l.779 2.9a1.333 1.333 0 00.082.222.248.248 0 00.28.137l2.03-.451a.249.249 0 00.2-.241 1.279 1.279 0 00-.045-.35l-.75-2.794a3.952 3.952 0 00-3.152-3.031l-.988-.18a.251.251 0 01-.175-.365l1.007-1.872a.251.251 0 01.4-.057 3.8 3.8 0 003.037 1.453H13.9a1.318 1.318 0 100-2.636h-2.376a1.326 1.326 0 01-1.074-.552 1.226 1.226 0 00-.1-.125L8.941 6a1.317 1.317 0 00-.973-.426 1.466 1.466 0 00-.162.009 1.318 1.318 0 00-1 .684l-3.068 5.7a1.331 1.331 0 00-.166.64V14.9a2 2 0 01-.211.894zM5.272 3.349l-.758-.444a1.4 1.4 0 00-1.046-.143 1.37 1.37 0 00-.841.638L.189 7.572a1.381 1.381 0 00.5 1.887l.758.443a1.372 1.372 0 00.693.19 1.419 1.419 0 00.353-.046 1.369 1.369 0 00.84-.638l2.434-4.173a1.376 1.376 0 00-.5-1.886z\"/><path d=\"M24 15.789a.5.5 0 00-.723-.447l-.615.307a1.993 1.993 0 01-1.38.152l-2.257-.564a2 2 0 00-1.814.445l-3.323 2.954a2 2 0 01-.895.457l-3.572.794a2.013 2.013 0 00-.766.352l-.991.743a2 2 0 01-1.2.4 1.952 1.952 0 01-.285-.021l-1.474-.211a1.963 1.963 0 00-.285-.021 1.994 1.994 0 00-1.493.673l-1.193 1.349a.5.5 0 00.374.831L23.5 24a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});