define("ember-svg-jar/inlined/style-one-pin-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-restaurant</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm.5 7.75a3.248 3.248 0 01-2.167 3.056.5.5 0 00-.333.471v3.473a.75.75 0 01-1.5 0v-3.473a.5.5 0 00-.333-.471A3.248 3.248 0 016 7.75v-3a.75.75 0 011.5 0V8a.5.5 0 001 0V4.75a.75.75 0 011.5 0V8a.5.5 0 001 0V4.75a.75.75 0 011.5 0zm2-3a.749.749 0 011.084-.671C17.813 5.188 18 8.749 18 12a.75.75 0 01-.75.75h-.75a.5.5 0 00-.5.5v1.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});