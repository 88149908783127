define("ember-svg-jar/inlined/messages-bubble-warning-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-warning-alternate</title><path d=\"M12 .836c-6.617 0-12 4.475-12 9.975a9.009 9.009 0 003.057 6.658L.931 21.713a1 1 0 001.316 1.355l5.981-2.784a14.273 14.273 0 003.772.5c6.617 0 12-4.474 12-9.974S18.617.836 12 .836zm0 17.949a12.186 12.186 0 01-3.563-.523.991.991 0 00-.713.05l-3.07 1.429a.25.25 0 01-.329-.338l.869-1.736a1 1 0 00-.269-1.229A7.207 7.207 0 012 10.811c0-4.4 4.486-7.975 10-7.975s10 3.578 10 7.975-4.486 7.974-10 7.974z\"/><path d=\"M12 12.374a1.25 1.25 0 001.25-1.25v-4.75a1.25 1.25 0 10-2.5 0v4.75a1.25 1.25 0 001.25 1.25z\"/><circle cx=\"12\" cy=\"14.874\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});