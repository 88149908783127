define("ember-svg-jar/inlined/delivery-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-man</title><path d=\"M13.041 11.363a1.863 1.863 0 01-2.082 0 .75.75 0 10-.918 1.186 3.249 3.249 0 003.918 0 .75.75 0 10-.918-1.186z\"/><circle cx=\"14\" cy=\"9.456\" r=\".875\"/><circle cx=\"10\" cy=\"9.456\" r=\".875\"/><path d=\"M5 8.452a.249.249 0 01.25.25v.254a6.75 6.75 0 0013.5 0v-.249a.249.249 0 01.25-.25 1 1 0 00.274-1.957c-.27-.078-.54-.139-.811-.2a.249.249 0 01-.186-.191l-.668-3.151A1.5 1.5 0 0016.568 1.8L12.431.563a1.506 1.506 0 00-.862 0L7.432 1.8a1.5 1.5 0 00-1.039 1.14l-.67 3.16a.249.249 0 01-.186.191c-.271.065-.541.126-.811.2A1 1 0 005 8.452zm5.5-4.058a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm1.5 9.812a5.256 5.256 0 01-5.25-5.25V8.25a.249.249 0 01.2-.244 24 24 0 0110.1 0 .251.251 0 01.2.244v.706a5.256 5.256 0 01-5.25 5.25zM22.3 20.1a13.779 13.779 0 00-5.348-2.533.25.25 0 00-.278.12l-3.049 5.438a.251.251 0 00.218.372H23a.5.5 0 00.5-.5v-.5a2.922 2.922 0 00-1.2-2.397zM11.782 22.577a.25.25 0 00.436 0l2.821-5.03a.251.251 0 00-.186-.371 23.212 23.212 0 00-5.706 0 .251.251 0 00-.186.371zM7.322 17.69a.25.25 0 00-.278-.12A13.779 13.779 0 001.7 20.1a2.922 2.922 0 00-1.2 2.4v.5a.5.5 0 00.5.5h9.153a.251.251 0 00.218-.372z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});