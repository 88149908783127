define("ember-svg-jar/inlined/astronomy-moon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-moon</title><path d=\"M12.167 22.174A12.236 12.236 0 017 12a12.232 12.232 0 015.17-10.174 1.007 1.007 0 00.386-1.138A1.018 1.018 0 0011.57 0a12.007 12.007 0 000 24h.03a1 1 0 00.566-1.826zM22.51 18c-2.4-.05-3.517-1.161-3.51-3.5a.536.536 0 00-.5-.5.5.5 0 00-.5.5c-.009 2.356-1.124 3.47-3.507 3.5a.5.5 0 00.007 1h.006c2.394 0 3.473 1.081 3.494 3.5a.5.5 0 00.49.5h.01a.5.5 0 00.5-.475c.12-2.4 1.224-3.525 3.473-3.525a.261.261 0 00.028 0 .5.5 0 00.01-1z\"/><path d=\"M15.983 11H16a.5.5 0 00.011-1C14.615 9.971 14 9.354 14 8a.519.519 0 00-.5-.5.5.5 0 00-.5.5c0 1.363-.624 1.98-2.007 2A.5.5 0 0011 11h.006c1.385 0 1.982.6 1.994 2a.5.5 0 00.49.5h.01a.5.5 0 00.5-.475c.069-1.4.681-2.025 1.983-2.025zM23.51 4C21.769 3.964 21 3.194 21 1.5a.5.5 0 00-.5-.5.5.5 0 00-.5.5c-.006 1.708-.78 2.479-2.506 2.5a.5.5 0 00.006 1h.006c1.71 0 2.479.772 2.494 2.5a.5.5 0 00.49.5h.01a.5.5 0 00.5-.476C21.086 5.779 21.851 5 23.48 5h.02a.5.5 0 00.01-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});