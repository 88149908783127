define("ember-svg-jar/inlined/ecology-leaf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf</title><path d=\"M23.737 3.161a1.007 1.007 0 00-1.56-.665 13.434 13.434 0 01-7.661 1.949c-6.179 0-8.758 2.519-8.807 2.577a8.056 8.056 0 00-2.673 9.543.5.5 0 01-.1.534L.268 19.988a1.007 1.007 0 001.367 1.471C3.351 19.9 6.15 15.2 14.019 11.911a1.007 1.007 0 01.777 1.858 26.3 26.3 0 00-8.431 5.541l-.009.01-.232.251a.5.5 0 00.1.769 8.01 8.01 0 004.262 1.223 12.212 12.212 0 005.784-1.573c8.769-4.824 7.93-14.101 7.467-16.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});