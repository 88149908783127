define("ember-svg-jar/inlined/science-fiction-weapon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-weapon</title><path d=\"M24 20.94a.51.51 0 00-.027-.239l-3.475-9.322a5.951 5.951 0 012.309-7.3.5.5 0 00.048-.8c-2.318-1.989-7.392-1.346-10.013-.141-1.281.589-1.958 1.287-1.958 2.021v1.088a.25.25 0 01-.25.25H9.88a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v2.25a.25.25 0 01-.25.25h-.75a.25.25 0 01-.25-.25V5a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-.761a.247.247 0 01-.207-.111 2 2 0 100 2.222.25.25 0 01.207-.111h.761a.25.25 0 01.25.25V10a1 1 0 002 0V8.747a.25.25 0 01.25-.25h.75a.25.25 0 01.25.25V11a.75.75 0 001.5 0V8.747a.25.25 0 01.25-.25h.75a.25.25 0 01.25.25V12.5a6.389 6.389 0 005.528 4 .25.25 0 01.235.184 5.182 5.182 0 01-.575 4.136.759.759 0 00.685 1.18h6.272c.251 0 .847-.106.975-1.06zM13.88 8.5a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm.184 5.1a.25.25 0 01.161-.441h1.338a.25.25 0 01.243.189l.188.755a.246.246 0 01-.063.235.25.25 0 01-.233.069 3.84 3.84 0 01-1.634-.807z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});