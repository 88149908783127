define("ember-svg-jar/inlined/focus-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-off</title><path d=\"M12 5.25A6.75 6.75 0 1018.75 12 6.758 6.758 0 0012 5.25zm0 2a4.722 4.722 0 012.294.592.251.251 0 01.126.183.248.248 0 01-.071.212l-6.112 6.112a.25.25 0 01-.4-.056A4.749 4.749 0 0112 7.25zm0 9.5a4.716 4.716 0 01-2.293-.592.249.249 0 01-.056-.4l6.112-6.112a.256.256 0 01.212-.071.252.252 0 01.184.127A4.749 4.749 0 0112 16.75zM4 1.5H2a2 2 0 00-2 2v2a1 1 0 002 0V4a.5.5 0 01.5-.5H4a1 1 0 000-2zM4 20.5H2.5A.5.5 0 012 20v-1.5a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 000-2zM22 1.5h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5v1.5a1 1 0 002 0v-2a2 2 0 00-2-2zM23 17.5a1 1 0 00-1 1V20a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});