define("ember-svg-jar/inlined/cleaning-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cleaning-woman-1</title><circle cx=\"9.088\" cy=\"2.5\" r=\"2.5\"/><path d=\"M8.271 9.56a.251.251 0 00.243.19h1.009a.249.249 0 00.244-.2l.611-2.882a.249.249 0 00-.2-.3 6.221 6.221 0 00-1.183-.118 6.26 6.26 0 00-1.239.136.251.251 0 00-.192.306l.707 2.868z\"/><path d=\"M20.2 18.233a3.266 3.266 0 00-1.517-.86.25.25 0 01-.188-.242V13.5a.25.25 0 01.25-.25H19a1.25 1.25 0 000-2.5h-.25a.25.25 0 01-.25-.25V4a1 1 0 00-2 0v6.5a.25.25 0 01-.25.25h-.33a.936.936 0 01-.854-.555 6.63 6.63 0 00-3.391-3.362.253.253 0 00-.215.006.25.25 0 00-.131.171l-.555 2.608a.247.247 0 00.068.228.2.2 0 01.058.113l1.253 7.5a.248.248 0 01-.055.2.252.252 0 01-.191.088H6.248a.247.247 0 01-.189-.087.241.241 0 01-.058-.2l1.114-7.5c.015-.1.187-.194.187-.2l-.688-2.743a.252.252 0 00-.345-.168 6.492 6.492 0 00-1.6 1l-.938.8A4.251 4.251 0 002.25 11.88v1.62a1.25 1.25 0 002.5 0v-1.62a1.749 1.749 0 01.611-1.329.084.084 0 01.138.076l-.982 6.615a1.75 1.75 0 001.731 2.008h.5A.25.25 0 017 19.5V23a1 1 0 001 1h2a1 1 0 001-1v-3.5a.25.25 0 01.25-.25h.657a1.751 1.751 0 001.726-2.039l-1-5.956a.077.077 0 01.053-.086.076.076 0 01.092.042 3.436 3.436 0 003.142 2.039h.33a.25.25 0 01.25.25v3.62a.25.25 0 01-.189.242 3.244 3.244 0 00-1.519.859c-1.023 1.015-1.542 2.792-1.542 5.279a.5.5 0 00.5.5h7.5a.5.5 0 00.5-.5c0-2.476-.52-4.248-1.55-5.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});