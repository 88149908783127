define("ember-svg-jar/inlined/school-test-art-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-test-art-1</title><path d=\"M5.5 21h-3a.5.5 0 01-.5-.5v-18a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V7a1 1 0 002 0V2a2 2 0 00-2-2H2a2 2 0 00-2 2v19a2 2 0 002 2h3.5a1 1 0 000-2zM23.75 8.856a4.973 4.973 0 00-1.05-1.793 1.249 1.249 0 00-2.054.245c-.813 1.563-1.2.9-2.474 2.037A3.46 3.46 0 0017.04 12.6a.5.5 0 01-.136.454l-8.37 8.386a1.5 1.5 0 002.121 2.122l8.373-8.362a.5.5 0 01.463-.135 3.256 3.256 0 003.009-.873 5.245 5.245 0 001.25-5.336zm-2.311 4.275a1.749 1.749 0 01-2.591-.126 1.846 1.846 0 01.32-2.542 2.5 2.5 0 01.8-.506.249.249 0 01.306.092 2.941 2.941 0 001.884 1.251.248.248 0 01.167.116.251.251 0 01.026.2 3.63 3.63 0 01-.912 1.515z\"/><path d=\"M9.25 14.751a1.526 1.526 0 00.5-.084l1.833-.648a1 1 0 00.667-.943v-.84a.5.5 0 00-.658-.474l-2.105.7a.768.768 0 01-.474 0l-2.1-.7a.5.5 0 00-.658.474v.84a1 1 0 00.667.943l1.834.648a1.516 1.516 0 00.494.084z\"/><path d=\"M9.408 7.528a.5.5 0 00-.316 0l-4.5 1.5a.5.5 0 00-.342.472V12a.5.5 0 101 0v-1.46a.247.247 0 01.1-.2.251.251 0 01.225-.035l3.513 1.171a.493.493 0 00.316 0l4.5-1.5a.5.5 0 000-.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});