define("ember-svg-jar/inlined/vectors-pen-add-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen-add-1</title><path d=\"M23.707 8.548l-5.755-5.755a1 1 0 00-1.526.133l-4.5 6.419a1 1 0 00.112 1.281l3.836 3.836a1 1 0 001.281.112l6.419-4.5a1 1 0 00.133-1.526zM10.547 11.157a.5.5 0 00-.56-.1l-5.275 2.4a.5.5 0 00-.293.455c0 .066-.023 6.6-1.835 9.315a.5.5 0 00.693.693c2.723-1.82 9.249-1.838 9.315-1.838a.5.5 0 00.455-.293l2.4-5.276a.5.5 0 00-.1-.56zm.582 7.043a2 2 0 110-2.828 2 2 0 010 2.828zM3.5 8.25a1.25 1.25 0 002.5 0v-2A.25.25 0 016.25 6h2a1.25 1.25 0 000-2.5h-2A.25.25 0 016 3.25v-2a1.25 1.25 0 00-2.5 0v2a.25.25 0 01-.25.25h-2a1.25 1.25 0 000 2.5h2a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});