define("ember-svg-jar/inlined/army-symbol-signals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-signals</title><path d=\"M24 5.5a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2zm-2 10.968a.25.25 0 01-.427.177l-8.159-8.178A1.367 1.367 0 0011 9.5v3.012a.25.25 0 01-.427.176L3.827 5.927A.25.25 0 014 5.5h17.5a.5.5 0 01.5.5zM2 7.532a.25.25 0 01.427-.177l8.159 8.178A1.367 1.367 0 0013 14.5v-3.012a.25.25 0 01.427-.176l6.747 6.761A.251.251 0 0120 18.5H2.5A.5.5 0 012 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});