define("ember-svg-jar/inlined/battery-charge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>battery-charge</title><path d=\"M23.5 9.628a1.5 1.5 0 00-1.5-1.5h-.25a.25.25 0 01-.25-.25v-1.25a1.5 1.5 0 00-1.5-1.5h-4.25a.25.25 0 00-.25.25v.389a.251.251 0 00.111.208 1.987 1.987 0 01.889 1.653v4.5a3.493 3.493 0 01-2.8 3.415.249.249 0 00-.2.244v1.091a.25.25 0 00.25.25H20a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 01.25-.25H22a1.5 1.5 0 001.5-1.5zM9.5 15.787a.248.248 0 00-.2-.244 3.493 3.493 0 01-2.8-3.415v-4.5a1.987 1.987 0 01.889-1.653.251.251 0 00.111-.208v-.389a.25.25 0 00-.25-.25H2a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h7.25a.25.25 0 00.25-.25zM8.745 5.878a.25.25 0 00.25.25h1.01a.25.25 0 00.25-.25v-2.25a.755.755 0 10-1.51 0zM12.745 5.878a.25.25 0 00.25.25h1.01a.25.25 0 00.25-.25v-2.25a.755.755 0 00-1.51 0z\"/><path d=\"M12.5 14.378a.25.25 0 01.25-.25H13a2 2 0 002-2v-4a.5.5 0 00-.5-.5h-6a.5.5 0 00-.5.5v4a2 2 0 002 2h.25a.25.25 0 01.25.25v5.75a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});