define("ember-svg-jar/inlined/bathroom-hanger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-hanger-1</title><path d=\"M1.125 20a.5.5 0 00-.5.5v2a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.5-1.5v-2a.5.5 0 00-.5-.5zM23.375 5.25a1.992 1.992 0 00-3.589-1.2.5.5 0 01-.4.2h-4.261a.5.5 0 01-.5-.5v-.5a1.25 1.25 0 012.412-.462A1 1 0 0018.9 2.05 3.236 3.236 0 0015.875 0h-12a3.254 3.254 0 00-3.25 3.25V18a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2.5a1.5 1.5 0 001.5-1.5V8.75a1 1 0 00-2 0v4.75a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V6.75a.5.5 0 01.5-.5h4.261a.5.5 0 01.4.2 1.992 1.992 0 003.589-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});