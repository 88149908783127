define("ember-svg-jar/inlined/real-estate-search-house-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house-target</title><path d=\"M12.672 6.437a.991.991 0 00-1.345 0L6.163 11.13A.5.5 0 006.5 12h1a.5.5 0 01.5.5V15a1 1 0 001 1h6a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87z\"/><path d=\"M24 12a1 1 0 00-1-1h-.385a.469.469 0 01-.465-.4 10.268 10.268 0 00-8.721-8.744.5.5 0 01-.429-.498V1a1 1 0 00-2 0v.358a.5.5 0 01-.431.494 10.269 10.269 0 00-8.718 8.718.5.5 0 01-.495.43H1a1 1 0 000 2h.357a.5.5 0 01.494.431 10.269 10.269 0 008.717 8.717.5.5 0 01.431.494V23a1 1 0 002 0v-.358a.5.5 0 01.43-.494 10.268 10.268 0 008.721-8.743.469.469 0 01.464-.4H23A1 1 0 0024 12zm-10.4 8.088a.5.5 0 01-.6-.488v-.1a1 1 0 00-2 0v.1a.5.5 0 01-.6.491A8.266 8.266 0 013.911 13.6.5.5 0 014.4 13h.1a1 1 0 000-2h-.1a.5.5 0 01-.491-.6A8.266 8.266 0 0110.4 3.912a.5.5 0 01.6.488v.1a1 1 0 102 0v-.1a.5.5 0 01.6-.491 8.268 8.268 0 016.487 6.491.5.5 0 01-.491.6h-.1a1 1 0 000 2h.1a.5.5 0 01.491.6 8.268 8.268 0 01-6.487 6.488z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});