define("ember-svg-jar/inlined/controls-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-play</title><path d=\"M23.14 10.608L2.253.164A1.559 1.559 0 000 1.557v20.887a1.558 1.558 0 002.253 1.392L23.14 13.393a1.557 1.557 0 000-2.785z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});