define("ember-svg-jar/inlined/road-sign-speed-limit-50-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-50-alternate</title><path d=\"M6.75 9.978h3.7a1 1 0 000-2H5.5a1 1 0 00-1 1v2.476a2.241 2.241 0 002.238 2.238H8.6a.857.857 0 010 1.714H5.5a1 1 0 000 2h3.1a2.857 2.857 0 000-5.714H6.738a.238.238 0 01-.238-.238v-1.226a.249.249 0 01.25-.25zM16.024 7.978a3.48 3.48 0 00-3.476 3.476v2.476a3.476 3.476 0 106.952 0v-2.476a3.48 3.48 0 00-3.476-3.476zM17.5 13.93a1.476 1.476 0 01-2.952 0v-2.476a1.476 1.476 0 112.952 0z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});