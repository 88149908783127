define("ember-svg-jar/inlined/people-man-cap-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-2</title><path d=\"M7.375 16.436a14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 20.894V23.5a.5.5 0 00.5.5h10a.25.25 0 00.25-.25v-4.123a.25.25 0 00-.193-.244 5.024 5.024 0 01-3.2-2.775.5.5 0 00-.482-.172zM12.943 19.383a.249.249 0 00-.193.243v4.124A.25.25 0 0013 24h10a.5.5 0 00.5-.5v-2.606a2.464 2.464 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.493.493 0 00-.48.174 5.009 5.009 0 01-3.202 2.773zM14.583 15.5A7.007 7.007 0 0019 9V7.75a.25.25 0 01.25-.25h2.25a1 1 0 000-2h-2.468a.248.248 0 01-.242-.19A7 7 0 005 7v2a6.987 6.987 0 009.583 6.5zM11.5 3.5h1A1.5 1.5 0 0114 5a.5.5 0 01-.5.5h-3A.5.5 0 0110 5a1.5 1.5 0 011.5-1.5zM7 9V7.5a.25.25 0 01.249-.25c1.386 0 8.124-.022 9.5-.006a.25.25 0 01.247.25V9A5 5 0 017 9z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M12.082 12.869a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});