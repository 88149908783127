define("ember-svg-jar/inlined/library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>library</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-14 20a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm5.5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm2.5-9.75a.75.75 0 01-.75.75H1.75a.75.75 0 010-1.5H3a.5.5 0 00.5-.5V3.487A.464.464 0 013.938 3h2.624A.464.464 0 017 3.487V10.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V6a.522.522 0 01.542-.5h2.166a.522.522 0 01.542.5v4.5a.5.5 0 00.5.5h3.106a.25.25 0 00.235-.336l-2.565-7a.439.439 0 01.295-.565l1.8-.577a.48.48 0 01.6.276l2.886 7.871a.5.5 0 00.469.328h2.674a.75.75 0 01.75.754z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});