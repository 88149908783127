define("ember-svg-jar/inlined/make-up-brush-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-brush-set</title><path d=\"M24 2.5A2.5 2.5 0 0021.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zM6.5 20a1.5 1.5 0 01-3 0v-9.5A.5.5 0 014 10h2a.5.5 0 01.5.5zm.114-11.842a.5.5 0 01-.474.342H3.86a.5.5 0 01-.474-.342L2 4c0-.653 1.5-1.48 3-1.48S8 3.347 8 4zM14.5 20.666a.833.833 0 01-.833.834h-2.834a.833.833 0 01-.833-.834v-2.834a.833.833 0 01.833-.832h2.834a.833.833 0 01.833.832zm0-7a.833.833 0 01-.833.834h-2.834a.833.833 0 01-.833-.834v-2.834a.833.833 0 01.833-.832h2.834a.833.833 0 01.833.832zm0-7a.833.833 0 01-.833.834h-2.834A.833.833 0 0110 6.666V3.832A.833.833 0 0110.833 3h2.834a.833.833 0 01.833.832zm7 14a.833.833 0 01-.833.834h-2.834a.833.833 0 01-.833-.834v-2.834a.833.833 0 01.833-.832h2.834a.833.833 0 01.833.832zm0-7a.833.833 0 01-.833.834h-2.834a.833.833 0 01-.833-.834v-2.834a.833.833 0 01.833-.832h2.834a.833.833 0 01.833.832zm0-7a.833.833 0 01-.833.834h-2.834A.833.833 0 0117 6.666V3.832A.833.833 0 0117.833 3h2.834a.833.833 0 01.833.832z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});