define("ember-svg-jar/inlined/performance-decrease-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>performance-decrease-1</title><path d=\"M19.25 20.75h3.5a.249.249 0 00.25-.25v-2.25a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2.25a.249.249 0 00.25.25zM13.5 12.75a.5.5 0 00-.5.5v7.25a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-7.25a.5.5 0 00-.5-.5zM7.5 14.75a.5.5 0 00-.5.5v5.25a.249.249 0 00.25.25h3.5a.249.249 0 00.25-.25v-5.25a.5.5 0 00-.5-.5zM1.5 7.75a.5.5 0 00-.5.5V20.5a.249.249 0 00.25.25h3.5A.249.249 0 005 20.5V8.25a.5.5 0 00-.5-.5zM1 23.75h22a1 1 0 000-2H1a1 1 0 000 2zM17.4 11.146a.5.5 0 00.35.854h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.309-.462.5.5 0 00-.545.108L20.54 8a.244.244 0 01-.182.073.249.249 0 01-.179-.073l-3.134-3.4a3.252 3.252 0 00-3.412-.875L9.911 4.962a.755.755 0 01-.711-.135L4.05.54a1.25 1.25 0 00-1.6 1.921L7.6 6.747a3.226 3.226 0 003.107.586l3.722-1.239a.75.75 0 01.786.2l3.209 3.486a.249.249 0 01-.007.346z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});