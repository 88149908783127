define("ember-svg-jar/inlined/mouse-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-2</title><path d=\"M23.239 15.7l-5.616-3.9A4.5 4.5 0 0013.5 5.5a4.05 4.05 0 00-3.041 1.24 4.26 4.26 0 00-1.089 4.153.749.749 0 11-1.444.4A5.761 5.761 0 019.077 6.03a6.417 6.417 0 00-3.41-.476A6.5 6.5 0 002.8 17.344 6.456 6.456 0 006.488 18.5H22.5a1.5 1.5 0 00.74-2.8zM17.5 16a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});