define("ember-svg-jar/inlined/smiley-cowboy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-cowboy</title><path d=\"M23.121 4.336a1 1 0 00-1.414 1.414 1 1 0 01-.567 1.693.25.25 0 01-.267-.145l-2.181-4.853a3.553 3.553 0 00-2.2-2.263c-1.554-.448-3.033.6-3.745 1.1l-.188.131a1 1 0 01-1.11 0 5.776 5.776 0 01-.188-.135C10.562.767 9.115-.29 7.553.145a3.562 3.562 0 00-2.235 2.274L3.127 7.3a.251.251 0 01-.267.145 1 1 0 01-.567-1.695A1 1 0 00.879 4.336 3 3 0 003 9.457h18a3 3 0 002.121-5.121zM21.344 10.957H2.656a1 1 0 00-.983.82 10.774 10.774 0 002.248 8.639A10.058 10.058 0 0012 23.957a10.312 10.312 0 008.112-3.608 10.542 10.542 0 002.215-8.572 1 1 0 00-.983-.82zm-13.208 1.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-.583 5.562a.392.392 0 01.342-.2h8.21a.4.4 0 01.342.593 5.136 5.136 0 01-8.894 0 .393.393 0 010-.393zm9.811-4.062a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});