define("ember-svg-jar/inlined/compass-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-1-alternate</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM2.08 13.545a.49.49 0 01.484-.565h1.354a.98.98 0 000-1.96H2.564a.49.49 0 01-.484-.565A10 10 0 013.9 6.08a.49.49 0 01.742-.057l.955.955a.98.98 0 101.381-1.386l-.956-.954a.493.493 0 01-.142-.385.488.488 0 01.2-.357 9.98 9.98 0 014.375-1.816.49.49 0 01.565.484v1.354a.98.98 0 101.96 0V2.564a.49.49 0 01.565-.484 10.06 10.06 0 018.375 8.375.49.49 0 01-.484.565h-1.354a.98.98 0 000 1.96h1.354a.49.49 0 01.484.565 10 10 0 01-1.815 4.375.49.49 0 01-.742.057l-.955-.955a.98.98 0 00-1.386 1.386l.956.954a.493.493 0 01.142.385.488.488 0 01-.2.357 9.98 9.98 0 01-4.375 1.816.49.49 0 01-.565-.484v-1.354a.98.98 0 00-1.96 0v1.354a.49.49 0 01-.565.484 10.06 10.06 0 01-8.375-8.375z\"/><path d=\"M17.739 6.3a.981.981 0 00-1.039-.251L9.953 8.477a2.439 2.439 0 00-1.476 1.475L6.049 16.7a.98.98 0 001.3 1.237l6.542-2.726a2.438 2.438 0 001.32-1.319l2.725-6.542a.979.979 0 00-.197-1.05zM12 13.714A1.714 1.714 0 1113.714 12 1.716 1.716 0 0112 13.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});