define("ember-svg-jar/inlined/ecology-leaf-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-settings</title><path d=\"M22.421 9.762l-1.266-.449a1.375 1.375 0 01-.78-1.886l.576-1.213a2.376 2.376 0 00-3.165-3.165l-1.213.576a1.374 1.374 0 01-1.885-.781l-.45-1.265a2.376 2.376 0 00-4.476 0l-.45 1.266a1.374 1.374 0 01-1.885.78l-1.213-.576a2.376 2.376 0 00-3.165 3.165l.576 1.213a1.376 1.376 0 01-.78 1.886l-1.266.45a2.375 2.375 0 000 4.475l1.266.45a1.374 1.374 0 01.78 1.885l-.576 1.213a2.376 2.376 0 003.165 3.165l1.213-.576a1.373 1.373 0 011.885.781l.45 1.265a2.376 2.376 0 004.476 0l.45-1.266a1.374 1.374 0 011.885-.78l1.213.576a2.376 2.376 0 003.165-3.165l-.576-1.213a1.374 1.374 0 01.78-1.885l1.266-.451a2.375 2.375 0 000-4.475zm-8.344 5.027a3.237 3.237 0 01-2.907.813 2.087 2.087 0 01-.681-.37.5.5 0 01-.088-.687 6.908 6.908 0 012.844-2.47.5.5 0 00-.337-.942c-2.1.744-4.03 3.548-4.951 5.57a.5.5 0 01-.915-.405A17.2 17.2 0 018.8 13.336a.5.5 0 00.025-.559 2.172 2.172 0 01-.343-1.427A2.739 2.739 0 0110.1 9.344a6.957 6.957 0 012.348-.613 6.514 6.514 0 002.652-.779.5.5 0 01.43-.035.5.5 0 01.3.315 7.306 7.306 0 01-1.753 6.557z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});