define("ember-svg-jar/inlined/read-search-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-search-alternate</title><path d=\"M23.466.49A1.7 1.7 0 0022.211 0c-3.9.116-8.014.905-9.9 2.39a.5.5 0 01-.618 0C9.8.906 5.686.117 1.789 0A1.7 1.7 0 00.534.49 1.766 1.766 0 000 1.752v12.833a1.748 1.748 0 001.713 1.748 31.4 31.4 0 013.205.252.673.673 0 00.1.007.75.75 0 00.1-1.494 32.756 32.756 0 00-3.358-.264.256.256 0 01-.257-.249V1.752a.261.261 0 01.078-.186.275.275 0 01.163-.066c6.241.186 9.506 1.879 9.506 2.735v.735a.75.75 0 001.5 0v-.735c0-.856 3.265-2.549 9.506-2.735a.3.3 0 01.166.066.261.261 0 01.078.186v12.833a.75.75 0 001.5 0V1.752A1.766 1.766 0 0023.466.49z\"/><path d=\"M8.779 6.434a.74.74 0 00.24.039.75.75 0 00.239-1.461 21.854 21.854 0 00-5.186-1.021.75.75 0 00-.144 1.493 20.224 20.224 0 014.851.95zM4.072 11.337a.75.75 0 00-.144 1.493c.336.032 1.071.114 1.09.114a.75.75 0 00.09-1.494c-.327-.04-.671-.077-1.036-.113zM6.408 7.967a30.32 30.32 0 00-2.336-.3.75.75 0 00-.144 1.493c1.626.158 2.213.3 2.353.3a.75.75 0 00.127-1.489zM16.3 6.089a19.826 19.826 0 013.773-.774.75.75 0 00-.144-1.493 21.269 21.269 0 00-4.059.831.749.749 0 10.43 1.436zM16 10.75h-4A1.252 1.252 0 0010.75 12v.445a.75.75 0 001.5.04.249.249 0 01.249-.235H13a.25.25 0 01.25.25V16a.25.25 0 01-.25.25.75.75 0 000 1.5h2a.75.75 0 000-1.5.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h.5a.249.249 0 01.249.233.75.75 0 001.5-.038V12A1.252 1.252 0 0016 10.75z\"/><path d=\"M19.9 18.488a.5.5 0 01-.064-.63 7.006 7.006 0 10-1.98 1.98.5.5 0 01.629.064l3.806 3.805a1 1 0 001.414-1.414zM14 19a5 5 0 115-5 5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});