define("ember-svg-jar/inlined/candy-jar-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>candy-jar-2</title><path d=\"M22.649 11.822a1.016 1.016 0 00-.72-.861 25.834 25.834 0 01-3.052-1.113 3.4 3.4 0 00-1.328-.412 2.972 2.972 0 00-1.934.849A5.746 5.746 0 0112 11.7a5.748 5.748 0 01-3.615-1.415 3.047 3.047 0 00-1.934-.849 3.389 3.389 0 00-1.327.412 25.935 25.935 0 01-3.053 1.113 1.013 1.013 0 00-.72.861c-.6 5.332 3.2 9.513 3.271 9.6C5.98 23.011 7.451 24 8.461 24h7.078c1.01 0 2.481-.989 3.839-2.582.922-1.08 3.801-4.884 3.271-9.596zM7.783 17.55a1.534 1.534 0 111.534-1.533 1.533 1.533 0 01-1.534 1.533zM12.011 21a1.534 1.534 0 111.534-1.533A1.533 1.533 0 0112.011 21zm3.5-4.5a1.534 1.534 0 111.534-1.533 1.533 1.533 0 01-1.536 1.533z\"/><path d=\"M18.4 4.5a1.085 1.085 0 01.781.327l3.724 3.883a.48.48 0 01.055.606.544.544 0 01-.6.207c-2.976-.884-3.534-1.57-4.786-1.587C15.062 7.9 14.429 10.2 12 10.2S8.938 7.9 6.43 7.936c-1.252.017-1.81.7-4.786 1.587a.544.544 0 01-.6-.207.48.48 0 01.056-.606l3.722-3.883A1.085 1.085 0 015.6 4.5zM18.5 3a.5.5 0 00.5-.5v-1A1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5v1a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});