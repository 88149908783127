define("ember-svg-jar/inlined/style-three-pin-shelter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shelter</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2.5 14a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v2a.5.5 0 001 0v-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm4-7a.5.5 0 01-.5.5.5.5 0 00-.5.5v6a.75.75 0 01-1.5 0V8a.5.5 0 00-.5-.5h-7A.5.5 0 008 8v6a.75.75 0 01-1.5 0V8a.5.5 0 00-.5-.5.5.5 0 01-.5-.5 1.568 1.568 0 01.643-1.263A9.847 9.847 0 0112 4a9.847 9.847 0 015.857 1.737A1.568 1.568 0 0118.5 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});