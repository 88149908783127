define("ember-svg-jar/inlined/science-physics-law", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-physics-law</title><path d=\"M24 4a1.752 1.752 0 00-1.75-1.75H1.75A1.752 1.752 0 000 4v16a1.752 1.752 0 001.75 1.75h20.5A1.752 1.752 0 0024 20zM3.25 14.5a.5.5 0 00.5.5h.75a.75.75 0 010 1.5h-.75a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5H5.5a.75.75 0 010 1.5h-2a1.752 1.752 0 01-1.75-1.75v-4A1.752 1.752 0 013.5 12h2a.75.75 0 010 1.5H3.75a.5.5 0 00-.5.5zM9 17.75H8a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-2.5H8a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm6.75 3.5a.75.75 0 01-1.5 0v-2.145a.249.249 0 00-.366-.221.824.824 0 01-.384.082.811.811 0 01-.384-.083.25.25 0 00-.366.222v2.145a.75.75 0 01-1.5 0v-6a.75.75 0 011.421-.335l.605 1.21a.25.25 0 00.448 0l.605-1.21a.75.75 0 011.421.335zM20.5 18a.75.75 0 010 1.5 3.75 3.75 0 010-7.5.75.75 0 010 1.5 2.25 2.25 0 000 4.5zm.563-9.421a.25.25 0 00.237.171h.2a.75.75 0 010 1.5h-3.2a.75.75 0 01-.45-1.35l2.56-1.921a.84.84 0 00.34-.679.85.85 0 10-1.7 0 .75.75 0 11-1.5 0 2.35 2.35 0 113.761 1.88l-.161.12a.25.25 0 00-.087.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});