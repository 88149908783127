define("ember-svg-jar/inlined/playlist-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-subtract</title><path d=\"M16.315 9.249A.249.249 0 0016.537 9V2.5A2.47 2.47 0 0014.105 0H2.432A2.47 2.47 0 000 2.5v12A2.47 2.47 0 002.432 17h6.6a.246.246 0 00.242-.227 8.2 8.2 0 017.041-7.524zM11.187 8a.987.987 0 01-.973-1 1.769 1.769 0 00-1.12-1.729.244.244 0 00-.339.23V11.052A2.467 2.467 0 016.323 13.5a2.5 2.5 0 010-5c.074 0 .148 0 .221.01a.237.237 0 00.186-.064.253.253 0 00.079-.185V4a.987.987 0 01.973-1c1.514 0 4.377.836 4.377 4a.986.986 0 01-.972 1z\"/><path d=\"M17.5 10.636A6.685 6.685 0 0017.5 24a6.685 6.685 0 000-13.364zM15 16.547h5a.771.771 0 010 1.542h-5a.771.771 0 010-1.542z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});