define("ember-svg-jar/inlined/medical-specialty-hearing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-hearing</title><path d=\"M9.844.034a8.156 8.156 0 00-7.9 4.245 1 1 0 101.756.959 6.164 6.164 0 015.957-3.213A6.372 6.372 0 0112.1 13.854a5.471 5.471 0 00-2.745 4.525 3.8 3.8 0 01-1.569 2.932 3.606 3.606 0 01-3.15.543A3.785 3.785 0 012 18.624a1 1 0 00-1.99.2 5.746 5.746 0 004.09 4.954 5.647 5.647 0 004.864-.845 5.793 5.793 0 002.4-4.464 3.438 3.438 0 011.736-2.881A8.371 8.371 0 009.844.034z\"/><path d=\"M12.1 10.315a1.255 1.255 0 00.136.008 1 1 0 00.989-.865 4.765 4.765 0 00-3.778-5.433A4.462 4.462 0 004.16 7.746a1 1 0 001.968.36 2.506 2.506 0 013.04-2.1 2.818 2.818 0 012.077 3.183 1 1 0 00.855 1.126zM23 9.5h-3.75a1 1 0 000 2H23a1 1 0 000-2zM19.991 7.127l2.75-1.5a1 1 0 00-.958-1.756l-2.75 1.5a1 1 0 00-.4 1.357 1.011 1.011 0 001.358.399zM22.479 15.371l-2.75-1.5a1 1 0 00-.958 1.756l2.75 1.5a1 1 0 10.958-1.756z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});