define("ember-svg-jar/inlined/single-woman-actions-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-cart</title><path d=\"M15.615 21.154a1.424 1.424 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.154a1.424 1.424 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.366c1.69-6.041.852-3.241 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.647a.25.25 0 01-.237.171zM.1 18.362a.494.494 0 00.393.194h2.3a.248.248 0 01.243.2l.922 4.844v.007a.5.5 0 00.485.393h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.248.248 0 01.243-.2h2.321a.494.494 0 00.477-.625C9.9 10.769 9.98 10.384 8.852 9.329a.5.5 0 00-.756.1L6.359 12.16a.495.495 0 01-.837 0L3.8 9.411a.5.5 0 00-.755-.1c-1.154 1.061-1.067 1.469-3.028 8.62a.5.5 0 00.083.431zM1.711 4.206A4.206 4.206 0 105.917 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.226A2.224 2.224 0 013.78 4.809a.248.248 0 01.158-.3A4.6 4.6 0 005.74 3.38a.247.247 0 01.353 0A4.629 4.629 0 007.9 4.509a.248.248 0 01.158.3 2.223 2.223 0 01-2.141 1.623z\"/><path d=\"M15.615 21.154a1.424 1.424 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.154a1.424 1.424 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.366c1.69-6.041.852-3.241 2.544-8.318a1 1 0 10-1.9-.633l-.878 2.647a.25.25 0 01-.237.171zM.1 18.362a.494.494 0 00.393.194h2.3a.248.248 0 01.243.2l.922 4.844v.007a.5.5 0 00.485.393h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.248.248 0 01.243-.2h2.321a.494.494 0 00.477-.625C9.9 10.769 9.98 10.384 8.852 9.329a.5.5 0 00-.756.1L6.359 12.16a.495.495 0 01-.837 0L3.8 9.411a.5.5 0 00-.755-.1c-1.154 1.061-1.067 1.469-3.028 8.62a.5.5 0 00.083.431zM1.711 4.206A4.206 4.206 0 105.917 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.226A2.224 2.224 0 013.78 4.809a.248.248 0 01.158-.3A4.6 4.6 0 005.74 3.38a.247.247 0 01.353 0A4.629 4.629 0 007.9 4.509a.248.248 0 01.158.3 2.223 2.223 0 01-2.141 1.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});