define("ember-svg-jar/inlined/tshirt-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brand-circle</title><defs><linearGradient x1=\"0%\" y1=\"0%\" x2=\"102%\" y2=\"101%\" id=\"c\"><stop stop-color=\"#6E3ED9\" offset=\"0%\"/><stop stop-color=\"#A635CD\" offset=\"100%\"/></linearGradient><circle id=\"a\" cx=\"16\" cy=\"16\" r=\"16\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#E6DFF5\" xlink:href=\"#a\"/><g mask=\"url(#b)\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\"><path d=\"M14.583 15.418v-10l2.5 2.083 2.5-2.5-3.363-3.363a4.167 4.167 0 00-2.947-1.22H6.725c-1.105 0-2.165.439-2.946 1.22L.417 5.001l2.5 2.5 2.5-2.083v10h9.166z\" stroke=\"url(#c)\" transform=\"translate(6 8)\"/><path d=\"M18.5 8.418a2.5 2.5 0 01-5 0\" stroke=\"#7F3BD6\"/></g></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});