define("ember-svg-jar/inlined/pasta-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pasta-bowl</title><path d=\"M24 11.5a1 1 0 00-1-1H1a1 1 0 000 2h1.112a.5.5 0 01.5.425 9.48 9.48 0 002.9 5.509.5.5 0 01.047.68L3.724 21.37a1 1 0 101.553 1.26l1.98-2.441a.5.5 0 01.606-.135 9.519 9.519 0 008.274 0 .5.5 0 01.606.134l1.981 2.442a1 1 0 101.552-1.26l-1.83-2.256a.5.5 0 01.047-.68 9.474 9.474 0 002.9-5.509.5.5 0 01.5-.425H23a1 1 0 001-1zM8 16a1 1 0 111-1 1 1 0 01-1 1zm4 2a1 1 0 111-1 1 1 0 01-1 1zm4-2a1 1 0 111-1 1 1 0 01-1 1zM13.5 9.5a1 1 0 01-1-1 4.5 4.5 0 00-9 0 1 1 0 01-2 0 6.5 6.5 0 0113 0 1 1 0 01-1 1z\"/><path d=\"M10.25 9.25a1 1 0 01-1-1 1.25 1.25 0 00-2.5 0 1 1 0 01-2 0 3.25 3.25 0 016.5 0 1 1 0 01-1 1zM21.5 8.5a1 1 0 01-1-1A4.505 4.505 0 0016 3a4.458 4.458 0 00-1.876.408 1 1 0 11-.835-1.817A6.5 6.5 0 0122.5 7.5a1 1 0 01-1 1z\"/><path d=\"M18.375 8.225a1 1 0 01-1-1 1.226 1.226 0 00-1.444-1.175 1 1 0 01-.406-1.959 3.2 3.2 0 013.85 3.134 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});