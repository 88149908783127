define("ember-svg-jar/inlined/smart-watch-square-ringing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-ringing</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-6.263 8.021a.25.25 0 00-.237.25V14a1.58 1.58 0 01-1.5 1.5h-1A1.58 1.58 0 017 14v-1c0-1.474 2.583-2 5-2s5 .525 5 2v1a1.58 1.58 0 01-1.5 1.5h-1A1.627 1.627 0 0113 14v-1.729a.249.249 0 00-.237-.249 14.94 14.94 0 00-1.526-.001zm-2.57-3.794a.5.5 0 01.662.246l.625 1.363a.5.5 0 11-.908.417L8.421 8.89a.5.5 0 01.246-.663zM11.5 9.5V8a.5.5 0 011 0v1.5a.5.5 0 01-1 0zm2.546.336l.625-1.363a.5.5 0 11.908.417l-.625 1.363a.5.5 0 11-.908-.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});