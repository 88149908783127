define("ember-svg-jar/inlined/email-action-reply-all-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-reply-all-1</title><path d=\"M12.189 12.168A1.5 1.5 0 0113 13.5v.073l1.566-1.246A1.5 1.5 0 0117 13.5v.5h2.25A1.749 1.749 0 0021 12.25V1.75a.159.159 0 00-.271-.112L12.2 10.165a2.407 2.407 0 01-3.4 0L.271 1.638A.155.155 0 00.1 1.6a.159.159 0 00-.1.15v10.5A1.749 1.749 0 001.75 14H8.2l2.439-1.724a1.5 1.5 0 011.55-.108z\"/><path d=\"M9.726 9.237a1.1 1.1 0 001.547 0L19.748.762A.438.438 0 0019.5.019 1.751 1.751 0 0019.249 0h-17.5A1.751 1.751 0 001.5.019a.434.434 0 00-.351.3.441.441 0 00.1.448zM10.491 17.792a1.5 1.5 0 01.075-2.284L12 14.368V13.5a.5.5 0 00-.789-.408l-4.5 3.182a.5.5 0 00-.025.8l4.5 3.637a.5.5 0 00.814-.392v-1.155z\"/><path d=\"M16 14.829V13.5a.5.5 0 00-.811-.391l-4 3.182a.5.5 0 00-.025.761l4 3.637a.5.5 0 00.836-.37v-1.763c6.123.237 6.973 4.826 7.007 5.027A.5.5 0 0023.5 24h.042a.5.5 0 00.458-.5c0-.084-.094-8.336-8-8.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});