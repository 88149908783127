define("ember-svg-jar/inlined/folder-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-lock-alternate</title><path d=\"M12.529 16.754a.247.247 0 00-.25-.254H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5H19a.5.5 0 01.5.5v4.088a.244.244 0 00.211.24 4.564 4.564 0 011.416.461.248.248 0 00.373-.212V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h10.252a.245.245 0 00.25-.243c.004-.38.014-1.111.027-1.503z\"/><path d=\"M22.752 15.529a.3.3 0 01-.252-.3v-.98a3.5 3.5 0 00-7 0v.963a.323.323 0 01-.265.318A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.248-1.471zM19 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});