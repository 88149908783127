define("ember-svg-jar/inlined/navigation-arrows-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-right</title><path d=\"M13.087 2.289v1.4a.5.5 0 00.13.336l6.333 6.964a1.493 1.493 0 010 2.019l-6.333 6.965a.5.5 0 00-.13.336v1.4a1 1 0 001.741.666l8.813-9.692a.987.987 0 00.272-.68 1.013 1.013 0 00-.265-.68L14.834 1.63a.969.969 0 00-1.1-.27.992.992 0 00-.647.929z\"/><path d=\"M17.141 12.683a.987.987 0 00.272-.68 1.013 1.013 0 00-.265-.68L8.334 1.63a.969.969 0 00-1.1-.27.992.992 0 00-.649.929v1.4a.5.5 0 00.13.336l6.333 6.964a1.493 1.493 0 010 2.019l-6.331 6.965a.5.5 0 00-.13.336v1.4a1 1 0 001.741.666z\"/><path d=\"M10.652 12.673a1 1 0 000-1.345L1.828 1.621a1 1 0 00-1.741.672v19.413a1 1 0 001.74.672z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});