define("ember-svg-jar/inlined/usb-port-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>usb-port-1</title><path d=\"M20.5 6.668A1.168 1.168 0 0019.333 5.5h-2.666A1.168 1.168 0 0015.5 6.668v2.666a1.168 1.168 0 001.167 1.166h.122a.215.215 0 01.215.214 4.642 4.642 0 01-1.557 1.708l-2.085 1.042a.25.25 0 01-.362-.222V4.751a.25.25 0 01.25-.25h2.25a.5.5 0 00.354-.854l-3.5-3.5a.5.5 0 00-.708 0l-3.5 3.5A.5.5 0 008.5 4.5h2.25a.25.25 0 01.25.25v10.04a.25.25 0 01-.379.215l-3.068-1.846a1 1 0 01-.553-.9v-.317a.252.252 0 01.136-.223 2.5 2.5 0 10-2.272 0 .252.252 0 01.136.228v.317a2.947 2.947 0 001.59 2.646l4.289 2.583a.249.249 0 01.121.214v.311a.25.25 0 01-.153.231 3 3 0 102.306 0 .251.251 0 01-.153-.231v-1.981a.252.252 0 01.138-.224l3.2-1.6c.812-.407 2.658-2.13 2.658-3.448v-.013a.25.25 0 01.25-.25h.083A1.168 1.168 0 0020.5 9.334z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});