define("ember-svg-jar/inlined/single-neutral-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-heart</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.627 1.951a3.526 3.526 0 00-5.646-.916l-.305.305a.252.252 0 01-.354 0l-.3-.305a3.527 3.527 0 00-5.647.916 3.519 3.519 0 00.654 4.063l5.113 5.334a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.657-4.07zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M23.627 1.951a3.526 3.526 0 00-5.646-.916l-.305.305a.252.252 0 01-.354 0l-.3-.305a3.527 3.527 0 00-5.647.916 3.519 3.519 0 00.654 4.063l5.113 5.334a.5.5 0 00.722 0l5.106-5.327a3.522 3.522 0 00.657-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});