define("ember-svg-jar/inlined/drone-remote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-remote</title><path d=\"M2.539 13.446a1.281 1.281 0 011.672 0 .75.75 0 101-1.115 2.819 2.819 0 00-3.68 0 .75.75 0 001 1.115z\"/><path d=\"M6.344 10.168a4.871 4.871 0 00-5.938 0 .75.75 0 10.938 1.17 3.3 3.3 0 014.062 0 .75.75 0 10.938-1.17zM23.125.125h-5a.75.75 0 000 1.5h5a.75.75 0 000-1.5zM5.125 1.625h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5z\"/><path d=\"M23.625 5.875a1.5 1.5 0 00-1.5-1.5h-.5a.25.25 0 01-.25-.25v-1.25a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25h-1a2.563 2.563 0 01-1.9-.845 3.5 3.5 0 00-5.2 0 2.565 2.565 0 01-1.9.845h-.95a.25.25 0 01-.25-.25v-1.25a.75.75 0 10-1.5 0v1.25a.25.25 0 01-.25.25h-.55a1.5 1.5 0 000 3h3.5a2.557 2.557 0 01.637.081.251.251 0 01.182.193.248.248 0 01-.094.248 6.243 6.243 0 00-2.475 4.978.75.75 0 001.5 0 4.753 4.753 0 012.459-4.16.249.249 0 01.266.015l.082.056a.251.251 0 01.069.346 2.25 2.25 0 103.748 0 .251.251 0 01.069-.346l.082-.056a.249.249 0 01.266-.015 4.753 4.753 0 012.459 4.16.75.75 0 001.5 0A6.243 6.243 0 0017.9 7.9a.25.25 0 01.089-.441 2.549 2.549 0 01.636-.081h3.5a1.5 1.5 0 001.5-1.503zm-9.5 5.25a.75.75 0 11.75-.75.751.751 0 01-.75.75zM4.875 14.375h-3a1.752 1.752 0 00-1.75 1.75v6a1.752 1.752 0 001.75 1.75h3a1.752 1.752 0 001.75-1.75v-6a1.752 1.752 0 00-1.75-1.75zm.25 7.75a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h3a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});