define("ember-svg-jar/inlined/asian-interior-painting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-painting</title><path d=\"M6 13a1 1 0 000 2h12a1 1 0 000-2 .5.5 0 01-.5-.5v-10A.5.5 0 0118 2a1 1 0 000-2H6a1 1 0 000 2 .5.5 0 01.5.5v10a.5.5 0 01-.5.5zM8.5 2.5A.5.5 0 019 2h6a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5zM1.332 19.549A2.007 2.007 0 003.254 21H5a1 1 0 000-2H3.632a.5.5 0 01-.481-.363l-.69-2.412a1 1 0 00-1.922.55zM18 20a1 1 0 001 1h1.746a2.007 2.007 0 001.922-1.45l.793-2.775a1 1 0 10-1.922-.55l-.69 2.412a.5.5 0 01-.481.363H19a1 1 0 00-1 1z\"/><path d=\"M24 23a1 1 0 00-1-1h-5.7a.5.5 0 01-.485-.379l-.554-2.216a.5.5 0 01.137-.48A1.982 1.982 0 0017 17.5a1 1 0 00-1.943-.333.5.5 0 01-.472.333h-5.17a.5.5 0 01-.472-.333A1 1 0 007 17.5a1.982 1.982 0 00.606 1.425.5.5 0 01.137.48l-.554 2.216A.5.5 0 016.7 22H1a1 1 0 000 2h22a1 1 0 001-1zM9.686 19.879a.5.5 0 01.485-.379h3.658a.5.5 0 01.485.379l.375 1.5A.5.5 0 0114.2 22H9.8a.5.5 0 01-.485-.621zM14 5.75h-.749a.5.5 0 01-.5-.5V4.5a.75.75 0 10-1.5 0v.75a.5.5 0 01-.5.5H10a.75.75 0 000 1.5h.751a.5.5 0 01.5.5V8a1.246 1.246 0 01-.557 1.04l-.609.406a.75.75 0 10.832 1.248l.609-.406A2.743 2.743 0 0012.751 8v-.25a.5.5 0 01.5-.5H14a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});