define("ember-svg-jar/inlined/add-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-circle-alternate</title><path d=\"M12 23.75A11.75 11.75 0 10.25 12 11.764 11.764 0 0012 23.75zm0-21A9.25 9.25 0 112.75 12 9.26 9.26 0 0112 2.75z\"/><path d=\"M6.273 13.25H10.5a.25.25 0 01.25.25v4.227a1.25 1.25 0 002.5 0V13.5a.25.25 0 01.25-.25h4.227a1.25 1.25 0 000-2.5H13.5a.25.25 0 01-.25-.25V6.272a1.25 1.25 0 10-2.5 0V10.5a.25.25 0 01-.25.25H6.273a1.25 1.25 0 000 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});