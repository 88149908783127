define("ember-svg-jar/inlined/ee-icon-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Group</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#7470F7\" width=\"54\" height=\"54\" rx=\"5\"/><path fill=\"#403CC6\" d=\"M7 8h12v6H7z\"/><path fill=\"#FFF\" d=\"M7 16h12v6H7zM7 24h12v6H7zM7 32h12v6H7z\"/><path fill=\"#403CC6\" d=\"M21 8h12v6H21z\"/><path fill=\"#FFF\" d=\"M21 16h12v6H21zM21 24h12v6H21zM21 32h12v6H21z\"/><path fill=\"#403CC6\" d=\"M35 8h12v6H35z\"/><path fill=\"#FFF\" d=\"M35 16h12v6H35zM35 24h12v6H35zM35 32h12v6H35zM7 40h12v6H7zM21 40h12v6H21zM35 40h12v6H35z\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});