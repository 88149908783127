define("ember-svg-jar/inlined/single-neutral-actions-alarm.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-alarm</title><path d=\"M12 15.5a5.938 5.938 0 01.487-2.353.5.5 0 00-.114-.56 7.477 7.477 0 00-12.352 3.27.5.5 0 00.479.643h11a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.659 19.884a4.086 4.086 0 01-1.159-2.717V15.5a4.5 4.5 0 00-9 0v1.667a4.088 4.088 0 01-1.159 2.717l-.241.316a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.193a.5.5 0 00-.4-.193h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.432z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});