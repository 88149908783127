define("ember-svg-jar/inlined/martial-arts-sumo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-sumo</title><path d=\"M23.26 10.471c-1.146-2.037-4.352-3.058-7.227-3.571a.251.251 0 01-.169-.114.254.254 0 01-.027-.2A3.792 3.792 0 0016 5.5V3a1.5 1.5 0 00-1.136-1.455l-.551-.138a.5.5 0 01-.344-.3 1.618 1.618 0 00-.147-.286A2 2 0 0012 0a2 2 0 00-1.822.818 1.618 1.618 0 00-.147.286.5.5 0 01-.345.3l-.55.138A1.5 1.5 0 008 3v2.5a3.792 3.792 0 00.163 1.082.254.254 0 01-.027.2.251.251 0 01-.169.114C5.092 7.413 1.886 8.434.74 10.471a3.507 3.507 0 00-.086 3.212 1.5 1.5 0 002.027.683c3.081-1.515 3.648-1.179 3.652-1.176a3.042 3.042 0 01.255 1 .731.731 0 01-.043.387l-.306.766a3.973 3.973 0 01-.66 1.1A6.924 6.924 0 003.62 21a8.074 8.074 0 00.214 1.845A1.5 1.5 0 005.294 24h1.73a1.494 1.494 0 001.493-1.351 2.45 2.45 0 011.24-1.941 2.932 2.932 0 011.563-.452h1.331a3.029 3.029 0 011.7.519 2.428 2.428 0 011.134 1.872A1.5 1.5 0 0016.975 24h1.645a1.493 1.493 0 001.46-1.155A8.035 8.035 0 0020.294 21a6.96 6.96 0 00-1.888-4.567 4.055 4.055 0 01-.646-1.085l-.306-.77a.739.739 0 01-.042-.387 3.028 3.028 0 01.255-1s.566-.343 3.652 1.175a1.5 1.5 0 002.027-.683 3.507 3.507 0 00-.086-3.212zM10.193 5.17a1.579 1.579 0 01.66-.613.816.816 0 01.7.088 1 1 0 00.894 0 .818.818 0 01.7-.09 1.561 1.561 0 01.664.615 1 1 0 01.142.778 2 2 0 01-3.9 0 1 1 0 01.14-.778zM9.5 18.269a.5.5 0 01-.275.447 4.617 4.617 0 00-2.542 2.9.531.531 0 01-1.041-.1 5.873 5.873 0 01-.022-.514 5.09 5.09 0 011.533-3.325.5.5 0 01.38-.175H9a.5.5 0 01.5.5zM18.294 21q0 .274-.024.546a.5.5 0 01-.5.454.459.459 0 01-.444-.341 4.61 4.61 0 00-2.554-2.943.5.5 0 01-.275-.446V18a.5.5 0 01.5-.5h1.455a.5.5 0 01.384.18A5.007 5.007 0 0118.294 21zm3.172-9.083a.4.4 0 01-.457.1c-2.076-.9-3.352-1.073-4.261-.6a2.374 2.374 0 00-1.17 1.7.5.5 0 01-.486.384H8.908a.5.5 0 01-.486-.384 2.374 2.374 0 00-1.17-1.7c-.909-.471-2.184-.3-4.261.6a.4.4 0 01-.509-.564c.674-1.2 3.328-2.243 6.915-2.747a.5.5 0 01.354.083 3.908 3.908 0 004.5 0 .5.5 0 01.354-.083c3.587.5 6.241 1.551 6.915 2.747a.4.4 0 01-.054.464z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});