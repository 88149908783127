define("ember-svg-jar/inlined/dog-small-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-small-1</title><path d=\"M23.866 17.981a7.073 7.073 0 01-1.029-2.652 6.046 6.046 0 00-5.66-4.405h-5.75a.643.643 0 01-.649-.65V9.125a9.012 9.012 0 00-3.063-6.716.5.5 0 00-.666.037 8.579 8.579 0 00-1.777 2.873A35.166 35.166 0 00.628 8.387 1.652 1.652 0 000 9.688v.812a1.669 1.669 0 001.325 1.619 19.846 19.846 0 002.716.364A8.892 8.892 0 005.75 17.09v4.11a.5.5 0 00.5.5h2.3a.5.5 0 00.487-.39l.4-1.773a.5.5 0 01.488-.39h6.6a.5.5 0 01.456.3l.884 1.963a.5.5 0 00.456.295h1.725a.5.5 0 00.5-.44c0-.021.21-1.714.378-3.38A2.987 2.987 0 0022.41 18.9a1.762 1.762 0 001.361-.155.51.51 0 00.223-.36.52.52 0 00-.128-.404zM6.386 8.837a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});