define("ember-svg-jar/inlined/canoe-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-2</title><path d=\"M23.3 5.964a1 1 0 00-1.863.389c-.12 1.123-1.777 1.939-3.941 1.939h-.759a.5.5 0 00-.388.184l-4.418 5.416a.25.25 0 00.194.408l6.371-.008a5.506 5.506 0 005.5-5.5 5.638 5.638 0 00-.696-2.828zM7.721 16.752L18.663 3.34a1 1 0 10-1.55-1.264l-4.771 5.848a1 1 0 01-.775.368H6.5c-2.164 0-3.821-.816-3.941-1.938A1 1 0 00.7 5.964 5.638 5.638 0 000 8.792a5.511 5.511 0 005.586 5.516h1.022a.25.25 0 01.194.408l-.686.842a.5.5 0 01-.452.18 2.51 2.51 0 00-2.262.9l-1.888 2.309a.5.5 0 00.071.7l3.1 2.528a.5.5 0 00.7-.071l1.89-2.304a2.5 2.5 0 00.371-2.537.5.5 0 01.075-.511z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});