define("ember-svg-jar/inlined/exotic-food-passion-fruit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-passion-fruit</title><path d=\"M15.944 11a.5.5 0 00.5-.449 11.024 11.024 0 00.056-1.11C16.5 4.235 12.911 0 8.5 0s-8 4.235-8 9.441c0 4.142 2.272 7.67 5.423 8.938a.5.5 0 00.68-.545 9.893 9.893 0 01-.1-1.791C6.5 12.321 12.049 10.978 15.944 11zM22.438 19.316a13.592 13.592 0 01-6.688 1.519 13.6 13.6 0 01-6.688-1.519.251.251 0 00-.352.324 7.864 7.864 0 0014.08 0 .251.251 0 00-.352-.324z\"/><path d=\"M15.75 12.5C11.47 12.5 8 14.086 8 16.043s3.47 3.542 7.75 3.542S23.5 18 23.5 16.043 20.03 12.5 15.75 12.5zm2.622 4.869c-1.174 0-2.125-.614-2.125-1.371s.951-1.371 2.125-1.371S20.5 15.241 20.5 16s-.954 1.369-2.128 1.369z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});