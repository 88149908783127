define("ember-svg-jar/inlined/delivery-parachute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-parachute</title><path d=\"M12.028 23.573a.25.25 0 00.177.427h3.8a1.5 1.5 0 001.5-1.5v-3.8a.25.25 0 00-.426-.177zM20.748 6.745a9.058 9.058 0 00-17.515 0c-.118.442.378 1.323.391 1.341l4.047 5.6a.249.249 0 01-.128.385A1.5 1.5 0 006.49 15.5v3.045a.25.25 0 00.427.177l4.3-4.3a.25.25 0 00-.182-.422h-.566a.25.25 0 01-.2-.105c-.474-.663-3.079-4.286-3.951-5.5a.25.25 0 01.2-.4h10.861a.25.25 0 01.2.393c-.889 1.276-3.65 5.243-3.857 5.585a.233.233 0 01-.037.046L6.563 21.2a.251.251 0 00-.073.177V22.5a1.5 1.5 0 001.5 1.5h1.371a.249.249 0 00.177-.073l7.879-7.879a.251.251 0 00.073-.177V15.5a1.5 1.5 0 00-1.068-1.431.249.249 0 01-.164-.158.253.253 0 01.032-.226l3.961-5.608c.449-.631.617-.877.497-1.332z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});