define("ember-svg-jar/inlined/medical-specialty-rehabilitation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-rehabilitation</title><path d=\"M10.972 14.119a.25.25 0 00.22.131h.156a.25.25 0 00.242-.315 2.368 2.368 0 01.061-1.45 2.416 2.416 0 011.5-1.457l2.007-.719a.5.5 0 11.338.941l-2.017.723a1.424 1.424 0 00-.78 2.085c.717 1.2 1.823.564 3.734-.12a3.015 3.015 0 002.063-2.85v-.838a3 3 0 00-3-3H12a4 4 0 00-3.2 1.6l-1.6 2.138a.249.249 0 00.146.394 5.455 5.455 0 013.626 2.737z\"/><circle cx=\"13.5\" cy=\"3.25\" r=\"2.5\"/><circle cx=\"20.5\" cy=\"17.75\" r=\"2.5\"/><path d=\"M23 21.25H1a1 1 0 000 2h22a1 1 0 000-2zM15 20.25a1.5 1.5 0 000-3h-3.419a.251.251 0 01-.223-.138l-1.187-2.374a4.474 4.474 0 00-4.025-2.488H2.5a1.5 1.5 0 000 3h3.646a1.49 1.49 0 011.341.829l1.671 3.342a1.5 1.5 0 001.342.829z\"/><path d=\"M1.5 20.25h5.691a.5.5 0 00.447-.724l-1-2a.5.5 0 00-.447-.276H1.5a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});