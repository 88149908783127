define("ember-svg-jar/inlined/railroad-metro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-metro</title><path d=\"M23.926 22.489l-3.872-9.5a.251.251 0 010-.179A13.435 13.435 0 0020.9 8.3a8.077 8.077 0 00-2.051-5.7C17.293.962 14.989.134 12 .134S6.706.962 5.153 2.6A8.077 8.077 0 003.1 8.3a13.416 13.416 0 00.826 4.456.254.254 0 010 .178L.072 22.492a1 1 0 001.856.748l.188-.468a.25.25 0 01.232-.156h19.3a.249.249 0 01.231.156l.193.471a1 1 0 00.926.623.981.981 0 00.377-.074 1 1 0 00.551-1.303zm-5.877-5.928q.29-.383.554-.792a.25.25 0 01.441.042l.329.806a.251.251 0 01-.232.345h-.892a.251.251 0 01-.2-.4zM6.614 6.972a.753.753 0 01.639-.356h9.5a.751.751 0 01.67 1.084l-.75 1.5a.75.75 0 01-.673.416H8a.749.749 0 01-.668-.416l-.75-1.5a.75.75 0 01.032-.728zm8.021 10.309a4.514 4.514 0 01-5.27 0 2.021 2.021 0 01-.14-2.094A3.062 3.062 0 0112 13.634a3.062 3.062 0 012.774 1.552 2.023 2.023 0 01-.139 2.095zM4.6 16.618l.338-.838a.249.249 0 01.442-.042q.273.425.574.823a.251.251 0 01-.2.4h-.926a.25.25 0 01-.232-.344zm16.242 3.887a.25.25 0 01-.207.111H3.355a.25.25 0 01-.207-.11.253.253 0 01-.025-.234l.466-1.154a.249.249 0 01.231-.156h4.412a.251.251 0 01.133.038 7.116 7.116 0 002.716 1.058 5.588 5.588 0 001.837 0A7.123 7.123 0 0015.634 19a.251.251 0 01.133-.038h4.393a.25.25 0 01.232.155l.47 1.155a.245.245 0 01-.024.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});