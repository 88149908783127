define("ember-svg-jar/inlined/architecture-fence-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-fence-2</title><path d=\"M22.5 14.5a.5.5 0 00.5-.5V4.379a.5.5 0 00-.066-.248l-1.5-2.625a.5.5 0 00-.868 0l-1.5 2.625a.5.5 0 00-.066.248V10.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V4.379a.5.5 0 00-.066-.248l-1.5-2.625a.5.5 0 00-.868 0l-1.5 2.625a.5.5 0 00-.066.248V10.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V4.379a.5.5 0 00-.066-.248l-1.5-2.625a.5.5 0 00-.868 0l-1.5 2.625A.5.5 0 007 4.379V10.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V4.379a.5.5 0 00-.066-.248l-1.5-2.625a.5.5 0 00-.868 0l-1.5 2.625A.5.5 0 001 4.379V14a.5.5 0 00.5.5z\"/><rect x=\"7\" y=\"15.996\" width=\"4\" height=\"6.75\" rx=\".5\" ry=\".5\"/><rect x=\"1\" y=\"15.996\" width=\"4\" height=\"6.75\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"15.996\" width=\"4\" height=\"6.75\" rx=\".5\" ry=\".5\"/><rect x=\"19\" y=\"15.996\" width=\"4\" height=\"6.75\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});