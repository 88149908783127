define("ember-svg-jar/inlined/shark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark</title><path d=\"M23.249 12.75a12.4 12.4 0 01.719-4.273.5.5 0 00-.135-.548.5.5 0 00-.561-.072 7.43 7.43 0 00-3.2 2.936 15.593 15.593 0 00-6.08-1.914l.354-3.58a.5.5 0 00-.179-.434.507.507 0 00-.459-.1 7.53 7.53 0 00-4.517 4.088c-3.912.3-7.023 1.344-9.012 3.014a.5.5 0 00-.068.7 10.616 10.616 0 002.8 2.539.505.505 0 00.309.07l2.871-.3-.467.552a.5.5 0 00.277.812 26.482 26.482 0 004.727.493 5.986 5.986 0 004.143 2.513.513.513 0 00.443-.159.5.5 0 00.114-.456 16.263 16.263 0 01-.366-2.128 14.791 14.791 0 005.117-1.793 7.417 7.417 0 003.2 2.933.5.5 0 00.7-.62 12.39 12.39 0 01-.73-4.273zm-11.18-.665a.75.75 0 111.342-.67 3.182 3.182 0 010 2.67.75.75 0 01-1.342-.67 1.677 1.677 0 000-1.33zm-3 0a.75.75 0 111.342-.67 3.182 3.182 0 010 2.67.75.75 0 01-1.342-.67 1.677 1.677 0 000-1.33zm-3.579.165a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});