define("ember-svg-jar/inlined/road-sign-two-way-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-two-way-alternate</title><path d=\"M23.882 21.773l-11-20.533a1.042 1.042 0 00-1.762 0l-11 20.533A1 1 0 001 23.246h22a1 1 0 00.881-1.473zm-20.8-.527a.25.25 0 01-.22-.368L11.78 4.24a.249.249 0 01.44 0l8.914 16.638a.25.25 0 01-.22.368z\"/><path d=\"M9.961 10.938l-1.5 1.5a.75.75 0 00.53 1.28h.25a.25.25 0 01.25.25v4.108a1 1 0 102 0v-4.108a.25.25 0 01.25-.25h.25a.75.75 0 00.53-1.28l-1.5-1.5a.749.749 0 00-1.06 0zM12.3 17.192a.752.752 0 00.162.818l1.5 1.5a.75.75 0 001.059 0l1.5-1.5a.75.75 0 00-.53-1.281h-.249a.249.249 0 01-.25-.25v-3.9a1 1 0 10-2 0v3.9a.249.249 0 01-.249.25h-.25a.749.749 0 00-.693.463z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});