define("ember-svg-jar/inlined/ui-phone-slider-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-phone-slider-horizontal</title><path d=\"M22.5 18a1 1 0 001-1V7a1 1 0 00-1-1H20a1 1 0 00-1 1v10a1 1 0 001 1zm-2-7a.5.5 0 01.309-.462.505.505 0 01.545.109l1 1a.5.5 0 010 .707l-1 1A.5.5 0 0120.5 13zM4 6H1.5a1 1 0 00-1 1v10a1 1 0 001 1H4a1 1 0 001-1V7a1 1 0 00-1-1zm-.5 7a.5.5 0 01-.309.462.5.5 0 01-.545-.108l-1-1a.5.5 0 010-.707l1-1A.5.5 0 013.5 11zM16.5 6h-9a1 1 0 00-1 1v10a1 1 0 001 1h9a1 1 0 001-1V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});