define("ember-svg-jar/inlined/history-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-triangle</title><path d=\"M4.97 23.011a15.487 15.487 0 01-3.82-.472 1.493 1.493 0 01-1.087-1.882 15.3 15.3 0 014.4-7.472 1 1 0 111.3 1.519 12.7 12.7 0 00-3.528 5.732.249.249 0 00.193.327c2.712.485 8.482.812 11.669-4.688a10.527 10.527 0 00.659-1.341 1 1 0 011.854.75 12.579 12.579 0 01-.783 1.6c-2.816 4.859-7.387 5.927-10.857 5.927zm-2.99-1.784z\"/><path d=\"M19.042 23.015a14.275 14.275 0 01-4.738-.766 1 1 0 01.666-1.886 12.892 12.892 0 006.6.405.254.254 0 00.172-.121.25.25 0 00.019-.21c-.928-2.6-3.416-7.966-9.769-7.978a8.778 8.778 0 00-1.491.1 1 1 0 11-.279-1.981 10.941 10.941 0 011.773-.12c8.483.016 11.229 7.811 11.945 10.2a1.495 1.495 0 01-1.079 1.882 15.318 15.318 0 01-3.819.475z\"/><path d=\"M9.956 18.949a1 1 0 01-.789-.383 12.62 12.62 0 01-.988-1.474C3.95 9.735 9.2 3.245 10.915 1.432a1.544 1.544 0 012.17 0 16.275 16.275 0 014.15 7.768 1 1 0 11-1.967.367A13.812 13.812 0 0012.2 3.432a.251.251 0 00-.383 0c-1.784 2.122-5.065 7.155-1.9 12.664a10.627 10.627 0 00.83 1.237 1 1 0 01-.787 1.616z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});