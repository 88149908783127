define("ember-svg-jar/inlined/messaging-google-talk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-google-talk</title><path d=\"M12 .84c-6.62 0-12 4.47-12 10a9.08 9.08 0 003.33 6.9c0 1.09-.75 2.34-2.2 3.72a1 1 0 00.69 1.72 1 1 0 00.34-.06c3.12-1.12 6.65-2.39 7.4-2.54a14.52 14.52 0 002.44.21c6.62 0 12-4.47 12-10S18.62.84 12 .84zm0 17.95a12.4 12.4 0 01-2.21-.2c-.23 0-.6-.11-5.05 1.46a5 5 0 00.56-3 1 1 0 00-.37-.65A7.21 7.21 0 012 10.81c0-4.4 4.49-8 10-8s10 3.58 10 8-4.49 7.98-10 7.98z\"/><path d=\"M14.32 4.93a1 1 0 10-.4 2c2.38.49 4 2.17 4 4.09a3.9 3.9 0 01-1.72 3.08 1 1 0 001.17 1.63A5.87 5.87 0 0020 11c0-2.9-2.36-5.39-5.68-6.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});