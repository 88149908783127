define("ember-svg-jar/inlined/smiley-lol-side-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-side-alternate</title><path d=\"M17.926 9.963a.5.5 0 00-.484.13l-7.349 7.35a.5.5 0 00.224.836 6.5 6.5 0 007.962-7.962.5.5 0 00-.353-.354z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M8.111 11.354H3.868a1 1 0 100 2h1.814a.249.249 0 01.2.4l-.356.5a1 1 0 001.63 1.156l1.769-2.475a1 1 0 00-.814-1.581zM11.906 9.005a1 1 0 001.047-.094l2.828-2.121a1 1 0 00-1.2-1.6l-.829.622a.25.25 0 01-.4-.2V3.868a1 1 0 10-2 0v4.243a1 1 0 00.554.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});