define("ember-svg-jar/inlined/weather-cloud-sun-wind-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-sun-wind-2</title><path d=\"M8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM1 9.25h1a1 1 0 000-2H1a1 1 0 000 2zM14.085 2.417a1 1 0 00-1.415 0l-.708.707a1 1 0 00-.292.67.249.249 0 00.337.243 8.545 8.545 0 012.125-.492.252.252 0 00.219-.192 1 1 0 00-.266-.936z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.443 8.443 0 011.375-.92.25.25 0 00.025-.427 5 5 0 00-7.407 5.984.25.25 0 00.4.1 7.069 7.069 0 011.236-.844zM4.965 14.5a.249.249 0 00.235-.167 3.5 3.5 0 013.748-2.3.993.993 0 001.116-.833A5 5 0 0120 12c0 .146-.007.29-.02.433a1 1 0 00.5.959 3 3 0 011.288 3.761.249.249 0 00.232.347h1a2.492 2.492 0 01.515.053.249.249 0 00.292-.175 5 5 0 00-1.713-5.3.25.25 0 01-.1-.193A7 7 0 008.338 9.84a.249.249 0 01-.22.173 5.514 5.514 0 00-4.96 4.177.252.252 0 00.042.21.248.248 0 00.2.1z\"/><path d=\"M19 18a1 1 0 000-2H1a1 1 0 000 2zM23 19H4a1 1 0 000 2h19a1 1 0 000-2zM19 22H1a1 1 0 000 2h18a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});