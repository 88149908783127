define("ember-svg-jar/inlined/rating-star-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-bubble</title><path d=\"M24 12a4.8 4.8 0 00-2.207-4.057 4.831 4.831 0 00-5.736-5.736 4.831 4.831 0 00-8.112 0 4.831 4.831 0 00-5.737 5.736 4.832 4.832 0 000 8.114 4.83 4.83 0 005.736 5.736 4.831 4.831 0 008.112 0 4.83 4.83 0 005.736-5.736A4.8 4.8 0 0024 12zm-7.837 4.232a.422.422 0 01-.594.536L12.245 14.9a.5.5 0 00-.491 0l-3.322 1.868a.423.423 0 01-.594-.537l1.353-3.111a.5.5 0 00-.126-.573l-2.312-2.06a.422.422 0 01.281-.737h2.743a.5.5 0 00.459-.3l1.376-3.193a.422.422 0 01.775 0l1.377 3.193a.5.5 0 00.459.3h2.743a.422.422 0 01.281.737l-2.312 2.06a.5.5 0 00-.126.573z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});