define("ember-svg-jar/inlined/shopping-basket-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-cash</title><path d=\"M17.3 11.129a.248.248 0 00.208-.184l.359-1.363a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.838a.251.251 0 00.245-.2 7.238 7.238 0 016.085-5.671zm-7.3-.015a.5.5 0 01.5.5V14a.5.5 0 01-1 0v-2.386a.5.5 0 01.5-.5zm-3.372 3.531a.488.488 0 01-.085.007.5.5 0 01-.492-.417l-.408-2.385a.5.5 0 01.409-.576.5.5 0 01.577.408l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.251.251 0 01-.222.366H5.4a.249.249 0 01-.213-.121.246.246 0 01-.008-.245l1.51-2.9A1 1 0 104.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M18.281 12.563A5.719 5.719 0 1024 18.281a5.719 5.719 0 00-5.719-5.718zm.943 9.165a.25.25 0 00-.193.243v.206a.75.75 0 01-1.5 0v-.146a.25.25 0 00-.25-.25h-.375a.75.75 0 010-1.5h1.865a.48.48 0 00.177-.925l-1.89-.757a1.979 1.979 0 01.28-3.765.249.249 0 00.193-.243v-.206a.75.75 0 111.5 0v.146a.25.25 0 00.25.25h.375a.75.75 0 010 1.5h-1.864a.48.48 0 00-.178.926l1.891.756a1.979 1.979 0 01-.281 3.765z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});