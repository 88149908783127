define("ember-svg-jar/inlined/human-resources-businessman-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-businessman-clock</title><path d=\"M7.017 0a6.976 6.976 0 106.976 6.975A6.983 6.983 0 007.017 0zm0 11.958A4.983 4.983 0 1112 6.975a4.988 4.988 0 01-4.983 4.983z\"/><path d=\"M9.01 6.088h-.746a.25.25 0 01-.25-.25V4.484a1 1 0 10-1.993 0v2.6a1 1 0 001 1H9.01a1 1 0 100-1.993zM21.312 9.316a.25.25 0 01-.026-.432 4.056 4.056 0 10-4.361.076.25.25 0 01-.011.432 4.761 4.761 0 00-2.471 4.171V16.4a.5.5 0 00.5.5h1.453a.25.25 0 01.249.225l.642 6.425a.5.5 0 00.5.449h2.833a.5.5 0 00.5-.449l.643-6.425a.25.25 0 01.249-.225h1.448a.5.5 0 00.5-.5v-2.84a4.743 4.743 0 00-2.648-4.244zm-1.364 3.44a.748.748 0 01-1.5 0V11.07a.748.748 0 011.5 0zm-2.873-7.837a.252.252 0 01.369-.138 5.059 5.059 0 002.628.734 5.029 5.029 0 00.941-.088.1.1 0 01.073.019.09.09 0 01.033.069 2.068 2.068 0 01-4.136 0 1.992 1.992 0 01.092-.596z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});