define("ember-svg-jar/inlined/expand-6-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-6-alternate</title><path d=\"M11.25 7.242a1 1 0 00-1-1h-3.5a3 3 0 00-3 3v8a3 3 0 003 3h8a3 3 0 003-3v-2.5a1 1 0 00-2 0v2.5a1 1 0 01-1 1h-8a1 1 0 01-1-1v-8a1 1 0 011-1h3.5a1 1 0 001-1z\"/><path d=\"M9.75 15.742a1.5 1.5 0 001.06-.438l6.468-6.458a.249.249 0 01.353 0l1.769 1.765a.5.5 0 00.354.147.512.512 0 00.191-.038.5.5 0 00.309-.462v-6a.5.5 0 00-.5-.5h-6a.5.5 0 00-.354.853l1.761 1.76a.255.255 0 01.073.177.25.25 0 01-.074.177l-6.47 6.456a1.5 1.5 0 001.06 2.561z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});