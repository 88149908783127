define("ember-svg-jar/inlined/people-woman-10.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-10</title><path d=\"M9.3 16.756a.735.735 0 00-.82-.629c-.76.1-1.5.228-2.268.4a.735.735 0 00-.565.622l-.033.255a.246.246 0 01-.167.2 12.709 12.709 0 00-3.77 1.9 2.416 2.416 0 00-.946 1.936v2.07a.489.489 0 00.489.49h9.8a.245.245 0 00.245-.245v-4.476a.246.246 0 00-.156-.229A2.822 2.822 0 019.3 16.756zm-2.281 1.272a.245.245 0 01.2-.209c.2-.039.393-.074.589-.107a.244.244 0 01.27.158 4.381 4.381 0 001 1.584.248.248 0 01.064.229.244.244 0 01-.162.175 8.97 8.97 0 00-1.907.878.245.245 0 01-.373-.236zM12.891 19.05a.246.246 0 00-.156.229v4.476a.245.245 0 00.245.245h9.8a.489.489 0 00.489-.49v-2.063a2.414 2.414 0 00-.947-1.936 12.7 12.7 0 00-3.769-1.9.246.246 0 01-.167-.2l-.033-.255a.735.735 0 00-.565-.622 22.755 22.755 0 00-2.268-.4.734.734 0 00-.82.629 2.822 2.822 0 01-1.809 2.287zM17.3 20.5a.245.245 0 01-.372.239 8.965 8.965 0 00-1.907-.877.245.245 0 01-.1-.405 4.388 4.388 0 001-1.583.244.244 0 01.27-.158c.2.033.392.068.589.107a.244.244 0 01.2.209zM12 0a6.865 6.865 0 00-6.857 6.857v1.959A6.865 6.865 0 0012 15.673a6.788 6.788 0 002.834-.621.145.145 0 01.205.132.245.245 0 00.362.215C18.647 13.628 19.676 9 18.8 5.548A7 7 0 0012 0zm0 13.714a4.9 4.9 0 01-4.9-4.9v-.929a.245.245 0 01.24-.245 7.061 7.061 0 004.206-1.352.244.244 0 01.345.047c.162.215.327.426.5.637a9.879 9.879 0 012.5 5.621.245.245 0 01-.1.232 4.841 4.841 0 01-2.791.889z\"/><circle cx=\"10.041\" cy=\"9.061\" r=\".98\"/><path d=\"M11.1 11.129a.626.626 0 00-.3 1.175 2.289 2.289 0 00.429.179 3 3 0 001.7 0 2.282 2.282 0 00.432-.18.627.627 0 00-.3-1.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});