define("ember-svg-jar/inlined/radiology-scanning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radiology-scanning</title><path d=\"M18.5 8.5h-13A1.5 1.5 0 004 10v8a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 18v-8a1.5 1.5 0 00-1.5-1.5zm-.783 3.468A.751.751 0 0117 12.5a.739.739 0 01-.22-.033l-1.616-.494a10.793 10.793 0 00-2.14-.421.25.25 0 00-.274.249v1.26a.249.249 0 00.223.248 8.742 8.742 0 012.2.535l1.309.507a.75.75 0 01-.271 1.449.761.761 0 01-.271-.051l-1.309-.507a7.181 7.181 0 00-1.593-.412.249.249 0 00-.2.058.254.254 0 00-.086.189v1.173a.25.25 0 00.25.25h1A.75.75 0 0114 18h-4a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-1.174a.249.249 0 00-.285-.247 7.181 7.181 0 00-1.593.412l-1.309.507a.75.75 0 01-.542-1.4l1.309-.507a8.742 8.742 0 012.2-.535.249.249 0 00.223-.248V11.8a.25.25 0 00-.274-.249 10.793 10.793 0 00-2.14.421l-1.616.494a.75.75 0 01-.44-1.434l1.617-.494a12.379 12.379 0 017.208 0l1.616.494a.75.75 0 01.493.936zM14.083 21H9.917a.5.5 0 00-.5.521l.083 2A.5.5 0 0010 24h4a.5.5 0 00.5-.479l.083-2a.5.5 0 00-.5-.521z\"/><circle cx=\"12\" cy=\"3.5\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});