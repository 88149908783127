define("ember-svg-jar/inlined/sauna-heat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sauna-heat-1</title><path d=\"M19.255 3.157a2.626 2.626 0 01.581-1.44.989.989 0 10-1.569-1.206 4.6 4.6 0 00-.992 2.646 7.423 7.423 0 00.437 2.207 5.908 5.908 0 01.35 1.624 2.627 2.627 0 01-.582 1.44.99.99 0 101.57 1.206 4.611 4.611 0 00.991-2.646 7.416 7.416 0 00-.441-2.206 5.939 5.939 0 01-.345-1.625zM14.1 6.988a2.62 2.62 0 01-.582 1.44.99.99 0 101.57 1.206 4.6 4.6 0 00.992-2.646 7.448 7.448 0 00-.437-2.206 5.907 5.907 0 01-.35-1.625 2.62 2.62 0 01.582-1.44.99.99 0 10-1.567-1.206 4.6 4.6 0 00-.992 2.646 7.454 7.454 0 00.437 2.207 5.908 5.908 0 01.347 1.624zM23.563 4.782a5.907 5.907 0 01-.35-1.625 2.627 2.627 0 01.582-1.44.989.989 0 10-1.569-1.206 4.6 4.6 0 00-.992 2.646 7.423 7.423 0 00.437 2.207 5.908 5.908 0 01.35 1.624 2.627 2.627 0 01-.582 1.44.989.989 0 101.569 1.206A4.6 4.6 0 0024 6.988a7.416 7.416 0 00-.437-2.206zM9.629 8.354a1.024 1.024 0 00-1.444 0c-.692.737-7.912 8.408-7.912 8.408a.989.989 0 00.717 1.671h1.484a.5.5 0 01.495.5v3.959a.991.991 0 00.99.99h2.969a.5.5 0 00.5-.495v-3.469a1.485 1.485 0 112.969 0v3.464a.5.5 0 00.5.495h2.969a.99.99 0 00.989-.99v-3.959a.5.5 0 01.495-.5h1.485a.989.989 0 00.717-1.67 2363.32 2363.32 0 01-7.923-8.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});