define("ember-svg-jar/inlined/pin-x-mark-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-x-mark-3</title><path d=\"M11.622 18.827a.5.5 0 00.756 0C12.648 18.515 19 11.138 19 7A7 7 0 005 7c0 4.138 6.352 11.515 6.622 11.827zM9.5 7A2.5 2.5 0 1112 9.5 2.5 2.5 0 019.5 7z\"/><path d=\"M18.794 22.044l-2.617-.8a.25.25 0 010-.478l2.617-.8a1 1 0 10-.588-1.912l-6.132 1.887a.249.249 0 01-.148 0l-6.132-1.897a1 1 0 10-.588 1.912l2.617.8a.25.25 0 010 .478l-2.617.8a1 1 0 00.588 1.912l6.132-1.887a.249.249 0 01.148 0l6.132 1.887a1 1 0 00.588-1.912z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});