define("ember-svg-jar/inlined/radio-search-station", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-search-station</title><path d=\"M24 10.5A2.5 2.5 0 0021.5 8h-1.25a.25.25 0 01-.25-.25V4a1 1 0 00-2 0v3.75a.25.25 0 01-.25.25H2.5A2.5 2.5 0 000 10.5v11A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5zM19.5 15a2 2 0 112-2 2 2 0 01-2 2zm1 3v3a1 1 0 01-2 0v-3a1 1 0 012 0zm-16-4a1.5 1.5 0 010-3h4.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25zm1 4v3a1 1 0 01-2 0v-3a1 1 0 012 0zm3 0a1 1 0 012 0v3a1 1 0 01-2 0zm2.5-6.75a.25.25 0 01.25-.25h2.25a1.5 1.5 0 010 3h-2.25a.25.25 0 01-.25-.25zM13.5 18a1 1 0 012 0v3a1 1 0 01-2 0zM19 2.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM21.25 4.5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/><path d=\"M15.5 4.5h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});