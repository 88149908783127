define("ember-svg-jar/inlined/pet-tracking-cat-chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-cat-chip</title><path d=\"M8.394 13.542a8.087 8.087 0 00-2.048.352.5.5 0 00-.295.681L7.1 16.869a1.051 1.051 0 00.965.631 1.107 1.107 0 00.288-.038 1.057 1.057 0 00.794-1.136l-.219-2.332a.5.5 0 00-.534-.452z\"/><path d=\"M22 6.5a.5.5 0 00-.5-.5c-.336 0-.572-.459-.922-1.214a3.648 3.648 0 00-1.848-2.169.5.5 0 01-.333-.406L18.2.743a.512.512 0 00-.033-.122A1 1 0 0017.246 0a1.017 1.017 0 00-.946.677l-.986 3.669A3.492 3.492 0 0112.08 6.5H5.393a2.453 2.453 0 00-1.047.239.5.5 0 01-.424 0A3.312 3.312 0 012 3.75 1.562 1.562 0 013.5 2a.485.485 0 01.5.5c0 .352-.448.606-.447.606a1 1 0 00.895 1.789A2.806 2.806 0 006 2.5 2.476 2.476 0 003.5 0 3.552 3.552 0 000 3.75a5.326 5.326 0 002.562 4.528.5.5 0 01.207.6C2.02 10.886.538 14.846.094 15.942A1.088 1.088 0 00.63 17.37a1.3 1.3 0 00.572.13 1.423 1.423 0 001.231-.731c.145-.242.28-.479.412-.707 1.278-2.221 2.049-3.562 6.9-3.562h.015a16.392 16.392 0 012.474.163 1.494 1.494 0 001.018-1.413 1.5 1.5 0 011.5-1.5h3.126a.5.5 0 00.447-.277l.348-.7a.5.5 0 01.447-.277h1.07A1.814 1.814 0 0022 6.688zM18.253 6A.75.75 0 1119 5.25a.75.75 0 01-.747.75z\"/><rect x=\"13.753\" y=\"13.75\" width=\"7\" height=\"7\" rx=\"1\" ry=\"1\"/><path d=\"M16 12.25v-1a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0zM20 12.25v-1a.75.75 0 00-1.5 0v1a.75.75 0 001.5 0zM21.5 15.25a.75.75 0 00.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zM23.247 18.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM19.25 21.5a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM15.25 21.5a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM12.251 18.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM12.251 14.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});