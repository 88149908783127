define("ember-svg-jar/inlined/video-game-logo-creeper-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-creeper-alternate</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M19 3.5h-4A1.5 1.5 0 0013.5 5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V5A1.5 1.5 0 009 3.5H5A1.5 1.5 0 003.5 5v3.5A1.5 1.5 0 005 10h3a.5.5 0 010 1H7a1.5 1.5 0 00-1.5 1.5V19A1.5 1.5 0 007 20.5h2a1.5 1.5 0 001.5-1.5v-.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v.5a1.5 1.5 0 001.5 1.5h2a1.5 1.5 0 001.5-1.5v-6.5A1.5 1.5 0 0017 11h-1a.5.5 0 010-1h3a1.5 1.5 0 001.5-1.5V5A1.5 1.5 0 0019 3.5zM8 8H6a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1.5A.5.5 0 018 8zm8 5a.5.5 0 01.5.5V18a.5.5 0 01-1 0v-.5A1.5 1.5 0 0014 16h-4a1.5 1.5 0 00-1.5 1.5v.5a.5.5 0 01-1 0v-4.5A.5.5 0 018 13h1a1.5 1.5 0 001.5-1.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v1A1.5 1.5 0 0015 13zm2.5-5.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});