define("ember-svg-jar/inlined/rain-coat-umbrella", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-coat-umbrella</title><path d=\"M20.667 5.114A.251.251 0 0021 4.878V2.5a.5.5 0 011 0 1 1 0 002 0 2.5 2.5 0 00-5 0v2.378a.251.251 0 00.333.236 2.008 2.008 0 011.334 0zM23.989 7.1a.5.5 0 00-.843-.458c-1.167 1.169-2.781 0-2.847-.047a.5.5 0 00-.6 0c-.066.05-1.663 1.227-2.847.046a.5.5 0 00-.842.458l3.5 16.5a.5.5 0 00.978 0zM15 12.677a1.99 1.99 0 00-1.258-1.857l-.441-.176a.251.251 0 01-.115-.371c1.262-1.892 1.3-1.85 1.314-2.253.005-.286-1.131-8.02-7-8.02s-7 7.733-7 8.02c.012.408.061.374 1.313 2.253a.25.25 0 01-.115.371l-.44.176A1.99 1.99 0 000 12.677V22a2 2 0 002 2h4.5a.25.25 0 00.25-.25V12.313a.249.249 0 00-.1-.2l-2.378-1.784a.263.263 0 01-.059-.061L2.915 8.32a.25.25 0 01.054-.336A7.278 7.278 0 017.5 6.5a7.268 7.268 0 014.531 1.484.249.249 0 01.054.335l-1.3 1.949a.24.24 0 01-.058.061L8.35 12.113a.249.249 0 00-.1.2V23.75a.25.25 0 00.25.25H13a2 2 0 002-2zM10.5 17a1 1 0 111-1 1 1 0 01-1 1zm1 3a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});