define("ember-svg-jar/inlined/tennis-racquet-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-racquet-ball</title><path d=\"M4.444 7.157a3.186 3.186 0 10-3.185-3.185 3.188 3.188 0 003.185 3.185zM22 2.005C19.072-.919 14-.6 10.682 2.711a8.63 8.63 0 00-2.354 8.6 1 1 0 01.035.468c-.16.97-.356 1.858-.531 2.566a1.989 1.989 0 01-.527.932L5.586 17a.5.5 0 01-.513.121 1.75 1.75 0 00-1.8.42L.516 20.3a1.75 1.75 0 000 2.476l.707.707a1.75 1.75 0 002.474 0l2.762-2.762a1.753 1.753 0 00.421-1.8.5.5 0 01.12-.507L8.718 16.7a2 2 0 01.932-.526c.7-.175 1.587-.37 2.551-.53a.994.994 0 01.472.036 8.608 8.608 0 008.616-2.359C24.6 10 24.919 4.928 22 2.005zm-4.173 2.457l1.5-1.5a.5.5 0 01.642-.056A4.693 4.693 0 0121.1 4.034a.5.5 0 01-.057.642l-1.5 1.5a.5.5 0 01-.707 0l-1-1a.5.5 0 01-.009-.714zm-.09 3.3a.5.5 0 01-.147.353l-1.309 1.3a.5.5 0 01-.706 0l-.991-.991a.5.5 0 010-.706l1.3-1.309a.5.5 0 01.707 0l1 1a.5.5 0 01.146.351zm-2.518-5.381a.249.249 0 01.178-.176A6.224 6.224 0 0117.215 2a.25.25 0 01.167.426l-.791.8a.5.5 0 01-.708 0l-.6-.6a.251.251 0 01-.064-.245zm-3.682 2.368a7.686 7.686 0 011.425-1.37.5.5 0 01.65.048l1.038 1.038a.5.5 0 010 .707l-1.3 1.308a.5.5 0 01-.354.147.5.5 0 01-.354-.146l-1.068-1.066a.5.5 0 01-.037-.666zM10.133 9.5a.253.253 0 01-.16-.222 6.332 6.332 0 01.3-2.174.251.251 0 01.179-.168.248.248 0 01.232.064l.723.723a.5.5 0 010 .706L10.4 9.441a.252.252 0 01-.267.059zm4.2 1.859l-1.709 1.7a.5.5 0 01-.643.054 4.712 4.712 0 01-1.091-1.092.5.5 0 01.053-.643l1.7-1.709a.5.5 0 01.707 0l.982.982a.5.5 0 01.146.354.5.5 0 01-.146.352zm2.736 2.2a.253.253 0 01-.168.179 6.347 6.347 0 01-2.176.294.25.25 0 01-.165-.427l1.012-1.008a.5.5 0 01.706 0l.724.725a.252.252 0 01.066.232zm3.553-2.518a7.748 7.748 0 01-1.37 1.427.5.5 0 01-.666-.037l-1.066-1.065a.5.5 0 010-.708l1.309-1.3a.5.5 0 01.706 0l1.037 1.036a.5.5 0 01.049.642zm1-2.255a.249.249 0 01-.242-.065l-.6-.6a.5.5 0 010-.708l.8-.791a.25.25 0 01.42.163 6.277 6.277 0 01-.2 1.815.249.249 0 01-.182.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});