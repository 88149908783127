define("ember-svg-jar/inlined/soft-drinks-juicer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-juicer</title><path d=\"M11.976 10.305a.5.5 0 00.5-.524 16.337 16.337 0 00-1.762-7.071.246.246 0 00-.214-.12.249.249 0 00-.214.121 16.366 16.366 0 00-1.761 7.07.5.5 0 00.5.524zM12.471 2.94a.25.25 0 00-.372.3 20.107 20.107 0 011.377 6.585.5.5 0 00.5.476H16a.5.5 0 00.5-.5c0-2.965-2.173-5.581-4.029-6.861zM8.9 3.247a.249.249 0 00-.372-.3C6.673 4.225 4.5 6.84 4.5 9.805a.5.5 0 00.5.5h2.025a.5.5 0 00.5-.476A20.129 20.129 0 018.9 3.247zM21 11.805H1a1 1 0 00-.851.474.979.979 0 00-.065.925l1.978 5.275a4.519 4.519 0 004.221 2.931H17a2.5 2.5 0 002.5-2.5v-4.605a.5.5 0 01.5-.5h1a1 1 0 011 1v4.106a1 1 0 002 0v-4.106a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});