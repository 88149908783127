define("ember-svg-jar/inlined/couple-film", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-film</title><path d=\"M21.5 2h-19A2.5 2.5 0 000 4.5v15A2.5 2.5 0 002.5 22h19a2.5 2.5 0 002.5-2.5v-15A2.5 2.5 0 0021.5 2zM11 3.5h2a1 1 0 010 2h-2a1 1 0 010-2zm-8 0h2a1 1 0 010 2H3a1 1 0 010-2zm2 17H3a1 1 0 010-2h2a1 1 0 010 2zm8 0h-2a1 1 0 010-2h2a1 1 0 010 2zm8 0h-2a1 1 0 010-2h2a1 1 0 010 2zm1-4a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zm-1-11h-2a1 1 0 010-2h2a1 1 0 010 2z\"/><path d=\"M12.626 9.04l-.276.271a.5.5 0 01-.7 0l-.276-.271a2.3 2.3 0 00-3.634.577 2.19 2.19 0 00.425 2.567l3.376 3.449a.642.642 0 00.919 0l3.375-3.449a2.19 2.19 0 00.425-2.567 2.3 2.3 0 00-3.634-.577z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});