define("ember-svg-jar/inlined/flying-insect-fly-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-fly-alternate</title><path d=\"M23.594 11.88a1 1 0 00-1-1h-.836c-1.172 0-1.791.4-3.187 1.1q-.332-.533-.641-.983c1.684-2.105 1.323-1.872 3.323-2.372a1 1 0 00-.486-1.939l-1.073.268c-1.5.376-2.093 1.251-3.013 2.4a12.898 12.898 0 00-.715-.773 5.692 5.692 0 01-.839 2.039 21.324 21.324 0 012.3 3.339C19.161 17 19.53 19.78 19.33 20.5c-.163-.009-4.845-1.562-5.284-8.746a3.514 3.514 0 01-4.176 0c-.439 7.19-5.135 8.747-5.285 8.754-.2-.712.167-3.5 1.907-6.538a21.267 21.267 0 012.3-3.343 5.7 5.7 0 01-.837-2.041c-.2.194-.424.435-.678.729L6.549 8.4c-.9-1.129-1.791-1.315-3.318-1.707a1 1 0 00-.484 1.939c2.011.5 1.662.294 3.277 2.315q-.318.461-.66 1.007l-1.259-.629a5.223 5.223 0 00-2.7-.441 1 1 0 000 2c1.536 0 1.2-.075 2.96.807-1.434 2.783-2.149 5.872-1.679 7.428A1.815 1.815 0 003.6 22.28c1.858.926 4.123-1.163 5.488-2.721a3.074 3.074 0 002.871 1.824 3.073 3.073 0 002.871-1.826c1.629 1.848 3.242 2.933 4.56 2.933a2.082 2.082 0 00.932-.212 1.817 1.817 0 00.908-1.163c.468-1.55-.24-4.62-1.663-7.394 1.858-.928 1.492-.841 3.028-.841a1 1 0 00.999-1zM10.67 17.373a9.345 9.345 0 00.565-1.5h1.45a9.518 9.518 0 00.566 1.5z\"/><path d=\"M9.688 5.365a4.85 4.85 0 00-.365 1.877c0 2.04 1.181 3.694 2.639 3.694S14.6 9.282 14.6 7.242a4.853 4.853 0 00-.347-1.832 5.391 5.391 0 00.471-2.066 2.887 2.887 0 00-.286-1.3 1 1 0 00-1.789.894 1.322 1.322 0 01.047.754 1.923 1.923 0 00-1.435-.014 1.3 1.3 0 01.05-.74 1 1 0 10-1.79-.894A2.865 2.865 0 009.238 3.3a5.324 5.324 0 00.45 2.065z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});