define("ember-svg-jar/inlined/people-man-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-7</title><path d=\"M.5 20.468V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.032a2.465 2.465 0 00-.967-1.977 14.264 14.264 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19.074a4.557 4.557 0 01-4.073-2.785.5.5 0 00-.552-.279 14.264 14.264 0 00-5.908 2.481A2.465 2.465 0 00.5 20.468z\"/><path d=\"M18.869 10.307a.25.25 0 01.2-.2c.186-.037.372-.071.57-.128a.5.5 0 00.031-.952.251.251 0 01-.151-.148 9.825 9.825 0 01-.3-1.43 18.6 18.6 0 00-.59-2.679A6.207 6.207 0 0012.722.037 7.181 7.181 0 0012 0a6.315 6.315 0 00-.663.034A7.008 7.008 0 005 7v2a7 7 0 0013.869 1.307zM7 9V7a.407.407 0 010-.05.25.25 0 01.16-.21 4.78 4.78 0 001.9-1.54.251.251 0 01.336-.04 8.018 8.018 0 011.213 1.32c1.211 1.5 2.906 3.566 5.875 3.83a.251.251 0 01.188.114.247.247 0 01.026.218A4.986 4.986 0 017 9z\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M12.082 12.869a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});