define("ember-svg-jar/inlined/conversation-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-text</title><path d=\"M9.324 16.967c0-3.842 3.628-6.967 8.088-6.967a8.712 8.712 0 015.9 2.209.246.246 0 00.239.056.25.25 0 00.172-.176A8.245 8.245 0 0024 10c0-5.514-5.383-10-12-10S0 4.486 0 10a9.037 9.037 0 002.766 6.376l-1.419 4.731a.5.5 0 00.681.6L7.5 19.271a13.422 13.422 0 002.091.518.251.251 0 00.27-.349 6.047 6.047 0 01-.537-2.473zM9.5 12.25H6a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm7-4.5H6a.75.75 0 010-1.5h10.5a.75.75 0 010 1.5z\"/><path d=\"M17.412 11.5c-3.633 0-6.588 2.452-6.588 5.467 0 3.521 4.078 6.322 8.456 5.232l3.5 1.748a.5.5 0 00.7-.591l-.9-3A4.908 4.908 0 0024 16.967c0-3.015-2.955-5.467-6.588-5.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});