define("ember-svg-jar/inlined/laptop-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-warning</title><path d=\"M3.5 12.251a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v6.524a.251.251 0 00.153.231 2.9 2.9 0 011.458 1.319l.01.019a.2.2 0 00.227.1.2.2 0 00.152-.2v-8a3 3 0 00-3-3h-12a3 3 0 00-3 3v8a1 1 0 001 1.007z\"/><path d=\"M23.838 21.656l-5.055-9.634a1.45 1.45 0 00-2.562 0l-5.056 9.634a1.429 1.429 0 00.048 1.408 1.453 1.453 0 001.233.687h10.112a1.455 1.455 0 001.232-.687 1.429 1.429 0 00.048-1.408zm-7.087-6.4a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zM17.5 22a1 1 0 111-1 1 1 0 01-1 1zM11.594 17.611l.887-1.691a.25.25 0 00-.3-.355 3.947 3.947 0 01-1.181.186A3.078 3.078 0 018.054 14.1a.5.5 0 00-.479-.356H.5a.5.5 0 00-.465.685 5.446 5.446 0 005.005 3.314h6.332a.251.251 0 00.222-.132z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});