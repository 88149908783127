define("ember-svg-jar/inlined/xml", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>xml</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.059 14.722a.624.624 0 01-.28.838.615.615 0 01-.279.066.627.627 0 01-.559-.346l-.847-1.695a.112.112 0 00-.184-.007l-.851 1.7a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.305-2.609a.252.252 0 000-.224L4.941 9.28a.625.625 0 011.118-.558s.791 1.591.854 1.709.129.09.169.009l.859-1.718a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224zm5.066.278a.625.625 0 01-1.25 0v-2.934c0-.142-.169-.079-.207-.005l-.109.219a.65.65 0 01-1.118 0s-.08-.154-.117-.235-.2-.086-.2.014V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.76 1.533.818 1.635a.148.148 0 00.25-.008l.814-1.627A.625.625 0 0114.125 9zm4.375.625h-2A1.627 1.627 0 0114.875 14V9a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});