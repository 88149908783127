define("ember-svg-jar/inlined/cash-pin-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-pin-map</title><path d=\"M1.8 18.025l-1.333 4A1.5 1.5 0 001.887 24h20.226a1.5 1.5 0 001.422-1.975l-1.333-4A1.5 1.5 0 0020.78 17h-5.569a.252.252 0 00-.213.118 86.227 86.227 0 01-1.754 2.722 1.5 1.5 0 01-2.488 0c-.31-.46-.968-1.452-1.754-2.722A.253.253 0 008.789 17H3.221A1.5 1.5 0 001.8 18.025z\"/><path d=\"M12 18.8a.252.252 0 00.209-.112C13.3 17.029 18.326 9.246 18.326 6.2A6.262 6.262 0 0012 0a6.261 6.261 0 00-6.326 6.2c0 3.049 5.023 10.831 6.117 12.491A.254.254 0 0012 18.8zm-1.334-6.382v-.167a.25.25 0 00-.25-.25h-.166a.75.75 0 010-1.5h1.98a.77.77 0 00.427-1.41L10.511 7.66A2.27 2.27 0 0111.77 3.5a.063.063 0 00.063-.063v-.352a.75.75 0 111.5 0v.166a.25.25 0 00.25.25h.167a.75.75 0 010 1.5h-1.98a.77.77 0 00-.427 1.411l2.147 1.431A2.27 2.27 0 0112.23 12a.064.064 0 00-.064.063v.354a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});