define("ember-svg-jar/inlined/single-neutral-focus.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-focus</title><path d=\"M7.7 22l-5.2.039a.5.5 0 01-.5-.5v-5.234a1 1 0 00-2 0v5.738A1.959 1.959 0 001.957 24H7.7a1 1 0 000-2zM1 8.7a1 1 0 001-1l-.04-5.2a.5.5 0 01.5-.5H7.7a1 1 0 000-2H1.957A1.959 1.959 0 000 1.957V7.7a1 1 0 001 1zM23 15.305a1 1 0 00-1 1l.04 5.191a.5.5 0 01-.5.5H16.3a1 1 0 100 2h5.739A1.959 1.959 0 0024 22.043v-5.738a1 1 0 00-1-1zM22.043 0H16.3a1 1 0 000 2l5.2-.039a.5.5 0 01.5.5V7.7a1 1 0 002 0V1.957A1.959 1.959 0 0022.043 0zM6.007 17.832A1.052 1.052 0 007.051 19h10.1a.846.846 0 00.615-.266A.836.836 0 0018 18.1a6.023 6.023 0 00-11.991-.269z\"/><circle cx=\"11.991\" cy=\"8\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});