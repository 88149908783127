define("ember-svg-jar/inlined/temperature-celsius-high-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius-high-alternate</title><path d=\"M6.25 5.5a.75.75 0 00.6-1.2L4.1.633a.772.772 0 00-1.2 0L.15 4.3a.749.749 0 00.6 1.2H2a.25.25 0 01.25.25V9a1.25 1.25 0 002.5 0V5.751A.25.25 0 015 5.5z\"/><path d=\"M14.5 4.652a9.5 9.5 0 109.5 9.5 9.511 9.511 0 00-9.5-9.5zm0 17a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><circle cx=\"10.5\" cy=\"10.835\" r=\"1.5\"/><path d=\"M16.75 11.335h1.5a.75.75 0 000-1.5h-1.5a4.75 4.75 0 000 9.5h1.5a.75.75 0 000-1.5h-1.5a3.25 3.25 0 010-6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});