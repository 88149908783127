define("ember-svg-jar/inlined/logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>logout</title><path d=\"M11.978 0A12.021 12.021 0 00.094 9.919a.5.5 0 00.5.581h8.32a.5.5 0 00.5-.5V6.4a1 1 0 011.625-.781l7 5.6a1 1 0 010 1.562l-7 5.6a1 1 0 01-.625.219 1.009 1.009 0 01-1-1V14a.5.5 0 00-.5-.5H.6a.506.506 0 00-.5.583 12 12 0 0023.8-2.572A12.113 12.113 0 0011.978 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});