define("ember-svg-jar/inlined/kitchen-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-table</title><path d=\"M23.876 9.649a1.486 1.486 0 00-1.484-1.484h-2.847a.138.138 0 01-.06-.264 2.374 2.374 0 001.422-2.192.5.5 0 00-.5-.513H12.5a.5.5 0 00-.5.513A2.375 2.375 0 0013.423 7.9a.139.139 0 01-.061.264H9.887a.248.248 0 01-.147-.447 3.708 3.708 0 001.518-2.972.743.743 0 00-.743-.745.222.222 0 01-.167-.367 3.672 3.672 0 00.91-2.421.739.739 0 00-.743-.712H4.082a.739.739 0 00-.742.721 3.672 3.672 0 00.91 2.421.222.222 0 01-.168.358.742.742 0 00-.742.742 3.708 3.708 0 001.518 2.976.248.248 0 01-.147.447h-3.1a1.485 1.485 0 000 2.969h20.781a1.486 1.486 0 001.484-1.485zM15.959 21.031a1.982 1.982 0 01-1.98-1.979v-6.433a.494.494 0 00-.494-.495h-2.97a.494.494 0 00-.494.495v6.433a1.982 1.982 0 01-1.98 1.979 1.485 1.485 0 000 2.969h7.918a1.485 1.485 0 000-2.969z\"/><path d=\"M13.237 3.216a.743.743 0 00.742.743h4.949a.743.743 0 00.742-.743 3.217 3.217 0 00-6.433 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});