define("ember-svg-jar/inlined/conversation-smile-type-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-smile-type-1-alternate</title><path d=\"M6.421 18.083L4 19.208l1.19-2.376a1 1 0 00-.269-1.23A7.21 7.21 0 012 9.974C2 5.577 6.486 2 12 2c5.041 0 9.307 3.006 9.924 6.992a1 1 0 001.976-.307C23.134 3.734 18.018 0 12 0 5.383 0 0 4.474 0 9.974a9.011 9.011 0 003.057 6.659L.931 20.877a1 1 0 001.316 1.354L7.265 19.9a1 1 0 10-.844-1.812z\"/><circle cx=\"14.824\" cy=\"16.5\" r=\"1\"/><circle cx=\"18.824\" cy=\"16.5\" r=\"1\"/><path d=\"M9 4.75a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2A.75.75 0 009 4.75zM14.25 5.5v2a.75.75 0 001.5 0v-2a.75.75 0 00-1.5 0zM22.639 19.984A5.355 5.355 0 0024 16.467c0-3.29-3.18-5.967-7.088-5.967s-7.088 2.677-7.088 5.967 3.18 5.967 7.088 5.967a8.166 8.166 0 001.806-.207l3.335 1.667a1 1 0 001.4-1.181zM20.556 20l.321 1.069-1.594-.8a1 1 0 00-.71-.07c-3.511.953-6.749-1.13-6.749-3.736 0-2.188 2.282-3.967 5.088-3.967S22 14.279 22 16.467a3.472 3.472 0 01-1.172 2.519A1 1 0 0020.556 20zM9.177 12.332a5.25 5.25 0 01-2.362-1.362.75.75 0 10-1.061 1.06 6.749 6.749 0 003.039 1.751.75.75 0 10.384-1.449z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});