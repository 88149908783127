define("ember-svg-jar/inlined/navigation-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-monitor</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zm-1 14.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 2h18a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M16.482 8.268a.255.255 0 01-.119-.157l-.7-2.785A1.747 1.747 0 0013.969 4h-3.938a1.747 1.747 0 00-1.7 1.326l-.7 2.785a.255.255 0 01-.119.157 1.981 1.981 0 00-.129 3.384.253.253 0 01.111.208v.89a.75.75 0 001.5 0v-.5A.25.25 0 019.25 12h5.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.89a.253.253 0 01.111-.208 1.981 1.981 0 00-.129-3.384zM14.667 7.9a.252.252 0 01-.2.1H9.531a.252.252 0 01-.2-.1.254.254 0 01-.045-.215l.5-2a.25.25 0 01.243-.189h3.938a.25.25 0 01.243.189l.5 2a.254.254 0 01-.043.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});