define("ember-svg-jar/inlined/shopping-bag-user-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-user-message</title><path d=\"M10.082 2.75a2.691 2.691 0 00-2.75 2.622v.72a.25.25 0 01-.175.238A1.635 1.635 0 006.02 7.62l-.666 4.053a1.768 1.768 0 00-.023.282A1.834 1.834 0 007.2 13.75h5.771a1.833 1.833 0 001.864-1.795 1.734 1.734 0 00-.022-.28l-.665-4.056a1.635 1.635 0 00-1.141-1.289.249.249 0 01-.175-.238v-.72a2.691 2.691 0 00-2.75-2.622zm0 1.5a1.192 1.192 0 011.25 1.122V6a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-.628a1.192 1.192 0 011.25-1.122z\"/><path d=\"M17.5 3v2.293a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11a3 3 0 003 3h4.564a.249.249 0 01.177.073l2.78 2.78a1.49 1.49 0 001.059.44 1.5 1.5 0 001.5-1.5V17.25a.25.25 0 01.25-.25h1.75a1 1 0 000-2h-3a1 1 0 00-1 1v.982a.25.25 0 01-.427.177l-1.864-1.866A1 1 0 008.082 15H3a1 1 0 01-1-1V3a1 1 0 011-1h13.5a1 1 0 011 1zM17.2 17.354a.5.5 0 00.478.646h2.066a.249.249 0 01.25.25v1.25a.5.5 0 00.5.5h1.392a.25.25 0 01.176.428l-1.471 1.455a.5.5 0 00-.1.582A2.412 2.412 0 0022.5 24h1.25a.25.25 0 00.25-.25V5.805a.253.253 0 00-.094-.2.25.25 0 00-.211-.048c-4.577 1.043-5.023 6.97-6.495 11.797zM21.458 14a.961.961 0 11.96-.96.96.96 0 01-.96.96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});