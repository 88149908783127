define("ember-svg-jar/inlined/light-mode-cloudy-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-cloudy-alternate</title><path d=\"M24 15.163a4.587 4.587 0 00-4.1-4.563.5.5 0 01-.434-.382A7.725 7.725 0 0012 4.25a7.707 7.707 0 00-6.485 3.8.5.5 0 01-.372.237 5.8 5.8 0 00-3.4 1.6A5.635 5.635 0 000 13.967a5.81 5.81 0 005.176 5.775 1.117 1.117 0 00.129.008h13.876a5.484 5.484 0 003.584-1.377A4.327 4.327 0 0024 15.163zm-4.826 2.587H5.378A3.834 3.834 0 012 13.967a3.645 3.645 0 011.132-2.648 3.83 3.83 0 012.831-1.069.975.975 0 00.94-.572A5.783 5.783 0 0112 6.25a5.749 5.749 0 015.677 5.462 1 1 0 001.2.92A2.587 2.587 0 0122 15.163c0 2.811-3.126 2.587-2.826 2.587z\"/><path d=\"M15.845 12.945a1 1 0 00-1.407.156l-1.219 1.524a1 1 0 001.562 1.25L16 14.351a1 1 0 00-.155-1.406zM12.125 11.969a1 1 0 00-1.406.156l-2 2.5a1 1 0 001.562 1.25l2-2.5a1 1 0 00-.156-1.406z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});