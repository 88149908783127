define("ember-svg-jar/inlined/single-neutral-actions-home.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-home</title><path d=\"M8.633 16.093l3.621-3.064a.5.5 0 000-.765A7.465 7.465 0 00.271 15.857a.5.5 0 00.479.643h7.27a.5.5 0 00.378-.173 2.164 2.164 0 01.235-.234z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.073 12.119a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.881h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});