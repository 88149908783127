define("ember-svg-jar/inlined/single-man-actions-player.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-player</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.35 6.35 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM10.456 12a1.377 1.377 0 01.035-.31.5.5 0 00-.289-.568A7.4 7.4 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h9.412a.5.5 0 00.5-.5zM22.081 12h-8.25a1.878 1.878 0 00-1.875 1.875v8.25A1.878 1.878 0 0013.831 24h8.25a1.877 1.877 0 001.875-1.875v-8.25A1.877 1.877 0 0022.081 12zm-1.731 6a.934.934 0 01-.451.8l-3.034 1.82a.929.929 0 01-1.408-.8v-3.64a.93.93 0 011.407-.8L19.9 17.2a.934.934 0 01.45.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});