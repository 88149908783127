define("ember-svg-jar/inlined/restaurant-dishes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-dishes</title><path d=\"M12.25 10a6 6 0 106 6 6 6 0 00-6-6zm.307 9.251a.5.5 0 01-.615 0C11.11 18.6 8.88 16.722 8.88 15.26a1.645 1.645 0 013.146-.832.25.25 0 00.448 0 1.645 1.645 0 013.146.832c0 1.463-2.231 3.34-3.063 3.991z\"/><path d=\"M7 1.25a1 1 0 00-1 1v4a1.993 1.993 0 01-.571 1.4.251.251 0 01-.273.056A.249.249 0 015 7.473V2.25a1 1 0 00-2 0v5.223a.249.249 0 01-.156.231.251.251 0 01-.273-.056A1.993 1.993 0 012 6.25v-4a1 1 0 00-2 0v4a4.006 4.006 0 002.4 3.666 1 1 0 01.6.917V22.25a1 1 0 002 0V10.833a1 1 0 01.6-.917A4.006 4.006 0 008 6.25v-4a1 1 0 00-1-1zM20.25.75H20a.5.5 0 00-.5.5v21a1 1 0 002 0v-5.5a.5.5 0 01.5-.5h.75a1.163 1.163 0 00.85-.341 1.222 1.222 0 00.4-.915C23.947 8.089 22.614.75 20.25.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});