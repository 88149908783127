define("ember-svg-jar/inlined/medical-condition-flu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-condition-flu</title><path d=\"M21.428 9.856a7.235 7.235 0 01-.721-1.45C20.707 3.145 15.845 0 11.143 0a9.925 9.925 0 00-10 10.063c0 3.5 1.271 6.124 3.779 7.813a.5.5 0 01.221.415V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5h1.5a2.5 2.5 0 002.5-2.5v-1.984a.5.5 0 01.5-.5c.187 0 .378 0 .552-.01a1.245 1.245 0 00.963-.52 1.222 1.222 0 00.19-.743v-.138c.083-1.116-.715-2.514-1.42-3.749zM11.25 15.012a1.066 1.066 0 111.072-1.066 1.07 1.07 0 01-1.072 1.066zm.572-5.994a1.072 1.072 0 111.071 1.065 1.07 1.07 0 01-1.071-1.065zm2.715 8.732a1.066 1.066 0 111.071-1.065 1.07 1.07 0 01-1.071 1.065zm0-4.381a1.066 1.066 0 111.071-1.069 1.069 1.069 0 01-1.071 1.069zm1.118-6.554a1.072 1.072 0 111.072 1.067 1.07 1.07 0 01-1.072-1.067zm.548 3.834a1.072 1.072 0 111.071 1.066 1.07 1.07 0 01-1.074-1.066zm1.619 5.451a1.066 1.066 0 111.071-1.066 1.07 1.07 0 01-1.071 1.066z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});