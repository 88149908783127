define("ember-svg-jar/inlined/book-library", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-library</title><rect x=\".25\" y=\".5\" width=\"6\" height=\"23\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"7.75\" y=\"7.5\" width=\"6\" height=\"16\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M23.729 21.094L20.634 2.855A1.5 1.5 0 0018.9 1.627l-2.958.5a1.5 1.5 0 00-1.228 1.729L17.813 22.1a1.5 1.5 0 001.73 1.228l2.957-.5a1.5 1.5 0 001.229-1.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});