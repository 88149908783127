define("ember-svg-jar/inlined/3d-print-reel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-reel</title><path d=\"M23.005 19.626a1 1 0 00-1.386.277l-1.231 1.846a.456.456 0 01-.759-.507l2.154-3.23a2.8 2.8 0 00-4.664-3.112l-2.992 4.488a.818.818 0 01-1.369-.9l1.808-2.812a.5.5 0 00-.067-.624l-3.761-3.767a.5.5 0 00-.56-.1 2.834 2.834 0 01-.16.067.5.5 0 00-.322.468v4.629a.5.5 0 00.176.38.5.5 0 00.4.114q.38-.059.746-.152a.25.25 0 01.271.378l-.218.34a2.818 2.818 0 004.72 3.091l2.992-4.488a.8.8 0 011.336.891l-2.154 3.23a2.456 2.456 0 004.087 2.725l1.231-1.845a1 1 0 00-.278-1.387zM7.164 5.61a.5.5 0 00.559.1c.053-.024.106-.046.161-.067a.5.5 0 00.321-.468V.545a.5.5 0 00-.582-.49 8.469 8.469 0 00-3.668 1.524.5.5 0 00-.2.365.5.5 0 00.145.393c.8.805 2.6 2.601 3.264 3.273z\"/><path d=\"M12.224 9.2a.5.5 0 00-.469.321 3.868 3.868 0 01-.066.161.5.5 0 00.1.56c.68.677 2.517 2.512 3.372 3.366a.5.5 0 00.787-.1 11.107 11.107 0 001.4-3.719.5.5 0 00-.493-.581C15.717 9.2 13.173 9.2 12.224 9.2zM9.7 5.179a.5.5 0 00.322.468c.054.021.108.043.16.067a.5.5 0 00.56-.1c.669-.672 2.464-2.469 3.268-3.274a.5.5 0 00-.06-.757A8.456 8.456 0 0010.278.055.5.5 0 009.7.55v4.629zM11.792 6.664a.5.5 0 00-.1.56c.023.053.045.106.066.16a.5.5 0 00.469.322h4.629a.5.5 0 00.493-.579 8.446 8.446 0 00-1.523-3.667.5.5 0 00-.758-.059C14.261 4.2 12.464 6 11.792 6.664zM5.678 7.706a.5.5 0 00.468-.322c.021-.054.043-.108.067-.16a.5.5 0 00-.1-.56C5.438 6 3.64 4.2 2.836 3.4a.5.5 0 00-.758.06A8.455 8.455 0 00.555 7.124a.5.5 0 00.494.578C2.188 7.7 4.73 7.7 5.678 7.706zM6.11 10.239a.5.5 0 00.1-.56 3.181 3.181 0 01-.067-.16.5.5 0 00-.465-.319H1.049a.5.5 0 00-.494.579 8.463 8.463 0 001.523 3.667.5.5 0 00.758.059c.805-.805 2.602-2.598 3.274-3.266zM8.205 11.724a.5.5 0 00-.321-.468 2.866 2.866 0 01-.161-.067.5.5 0 00-.559.1C6.5 11.964 4.7 13.762 3.9 14.566a.5.5 0 00-.145.393.5.5 0 00.205.365 8.446 8.446 0 003.667 1.523.5.5 0 00.578-.494 713.573 713.573 0 010-4.629z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});