define("ember-svg-jar/inlined/multiple-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-man</title><path d=\"M13.791 5.99a.494.494 0 00.19.292A6.723 6.723 0 0017.96 7.5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3.022 3.022 0 0116.5 10.5a2.958 2.958 0 01-2.1-.877.5.5 0 00-.822.175 6.54 6.54 0 01-.417.888.5.5 0 00.112.627 5 5 0 10-.024-7.6.5.5 0 00-.106.631 5.084 5.084 0 01.648 1.646zM16.5 13.5a7.4 7.4 0 00-2.377.393.5.5 0 00-.2.823A8.957 8.957 0 0116.5 21v.024a.469.469 0 00.469.476H23.5a.5.5 0 00.5-.5 7.508 7.508 0 00-7.5-7.5zM12.5 7.5a5 5 0 10-5 5 5.006 5.006 0 005-5zm-7.9-.743a.5.5 0 01.741-.3A6.959 6.959 0 008.96 7.5a6.36 6.36 0 00.929-.07.5.5 0 01.569.564A3 3 0 014.5 7.5a2.841 2.841 0 01.1-.743zM15 21a7.5 7.5 0 00-15 0 .5.5 0 00.5.5h14a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});