define("ember-svg-jar/inlined/shop-star-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-star-rating</title><path d=\"M15.494 14.5h-1.482a.249.249 0 01-.233-.161l-.844-2.229a.368.368 0 00-.023-.049 1.053 1.053 0 00-1.856.048l-.845 2.23a.25.25 0 01-.234.161H8.494a.985.985 0 00-.933.611 1.03 1.03 0 00.3 1.155l1.233.859a.25.25 0 01.086.3l-.716 1.646a1 1 0 00.251 1.157 1.021 1.021 0 001.2.133l1.957-1.1a.249.249 0 01.245 0l1.956 1.1a1.041 1.041 0 00.511.136 1.019 1.019 0 00.687-.269 1 1 0 00.251-1.157l-.717-1.648a.249.249 0 01.086-.3l1.3-.911a1.007 1.007 0 00.239-1.1.987.987 0 00-.936-.612zM6.257 5.415a.257.257 0 00-.009.067V9.3a.25.25 0 00.114.209 4.924 4.924 0 002.288.791c.133.011.266.016.4.016a4.664 4.664 0 002.061-.474.249.249 0 00.14-.224V.25A.25.25 0 0011 0H7.936A.249.249 0 007.7.185c-.1.367-.423 1.525-1.443 5.23zM12.748 9.611a.249.249 0 00.14.224 4.729 4.729 0 002.473.46 4.868 4.868 0 002.379-.842.018.018 0 00.008-.015V5.482a.252.252 0 00-.009-.067L16.3.185A.248.248 0 0016.06 0H13a.25.25 0 00-.25.25z\"/><path d=\"M24 5.262v-.007a.317.317 0 000-.053V5.2a.312.312 0 00-.011-.053v-.01a.441.441 0 00-.023-.064L22.235.966A1.5 1.5 0 0020.881 0h-2.747a.249.249 0 00-.241.316l1.329 4.832a.788.788 0 01.026.2V8.92a.251.251 0 00.159.233 3.458 3.458 0 001.136.24L20.5 21.751a.25.25 0 01-.25.249H3.7a.25.25 0 01-.25-.25V9.391a3.433 3.433 0 001.141-.238.25.25 0 00.159-.233V5.347a.788.788 0 01.026-.2L6.1.316A.249.249 0 005.862 0H3.119a1.5 1.5 0 00-1.354.966L.044 5.073a.441.441 0 00-.023.064v.005a.312.312 0 00-.012.058.317.317 0 000 .053v.927a3.175 3.175 0 001.336 2.55.252.252 0 01.1.2V22A2.029 2.029 0 003.5 24h17a2.029 2.029 0 002.053-2V8.93a.252.252 0 01.1-.2A3.228 3.228 0 0024 6.182v-.92z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});