define("ember-svg-jar/inlined/hat-cowboy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-cowboy-1</title><path d=\"M5.333 15.882A19.989 19.989 0 0012 17a19.989 19.989 0 006.667-1.118.5.5 0 00.333-.472V10.5a7 7 0 00-14 0v4.91a.5.5 0 00.333.472z\"/><path d=\"M23.8 15.405a1 1 0 00-1.4-.209A17.328 17.328 0 0112 18.5a17.328 17.328 0 01-10.4-3.3 1 1 0 10-1.195 1.6A19.313 19.313 0 0012 20.5a19.313 19.313 0 0011.6-3.7 1 1 0 00.2-1.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});