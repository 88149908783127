define("ember-svg-jar/inlined/login-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-3</title><path d=\"M17.508 18.5a1 1 0 00-1 1v.5a.5.5 0 01-.5.5h-5.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v1a1 1 0 002 0V2.5a1 1 0 00-1-1h-7.5v-1A.5.5 0 009.4.012l-9 2a.5.5 0 00-.392.488v19a.5.5 0 00.392.489l9 2a.506.506 0 00.421-.1.5.5 0 00.187-.39v-1h7.5a1 1 0 001-1v-2a1 1 0 00-1-.999zm-10-6a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.5z\"/><path d=\"M22.492 11h-4.125V9.25a1 1 0 00-1.555-.832l-4.875 3.25a1 1 0 000 1.664l4.875 3.25a1 1 0 001.555-.832V14h4.125a1.5 1.5 0 100-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});