define("ember-svg-jar/inlined/appliances-microwave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-microwave</title><path d=\"M12.5 13.5a.5.5 0 00-.5-.5h-1.4a.25.25 0 01-.185-.418 4.246 4.246 0 00.942-1.844 1 1 0 00-.975-1.238H6.618a1 1 0 00-.972 1.238 4.246 4.246 0 00.942 1.844A.25.25 0 016.4 13H5a.5.5 0 000 1h7a.5.5 0 00.5-.5z\"/><path d=\"M24 4.5A2.5 2.5 0 0021.5 2h-19A2.5 2.5 0 000 4.5v12a2.5 2.5 0 001.25 2.164.5.5 0 01.25.433v1.653a1.25 1.25 0 002.5 0V19.5a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v1.25a1.25 1.25 0 002.5 0V19.1a.5.5 0 01.25-.433A2.5 2.5 0 0024 16.5zm-9.5 10a2 2 0 01-2 2h-8a2 2 0 01-2-2v-8a2 2 0 012-2h8a2 2 0 012 2zM21 16a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});