define("ember-svg-jar/inlined/delivery-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-door</title><path d=\"M8.75 14a2.5 2.5 0 012.5 2.5v2.75a.25.25 0 00.25.25h9.25a.5.5 0 00.5-.5V1.5a1.5 1.5 0 00-1.5-1.5h-10a1.5 1.5 0 00-1.5 1.5v12.25a.25.25 0 00.25.25zm11.5-3a1 1 0 11-1-1 1 1 0 011 1zm-9.5-3.5v-4a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1h-6a1 1 0 01-1-1zM22.25 21h-10a1.006 1.006 0 00-1 1.012c0 .411.005.717 0 .962a.994.994 0 001 1.026h10a1 1 0 001-1v-1a1 1 0 00-1-1z\"/><path d=\"M9.75 23v-6.5a1 1 0 00-1-1H7a.25.25 0 00-.25.25v2.783a.25.25 0 01-.389.208l-.972-.649a.251.251 0 00-.278 0l-.972.649a.25.25 0 01-.389-.208V15.75a.25.25 0 00-.25-.25H1.75a1 1 0 00-1 1V23a1 1 0 001 1h7a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});