define("ember-svg-jar/inlined/tags-settings-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-settings-alternate</title><circle cx=\"17.171\" cy=\"5.999\" r=\"1.5\"/><path d=\"M9.9 21.191a.249.249 0 00-.32-.114 1.027 1.027 0 01-1.117-.2l-6.171-6.171a1 1 0 010-1.414l11-11A.993.993 0 0114 2h6.672a.5.5 0 01.5.5v6.67a1 1 0 01-.293.708l-.166.166a.25.25 0 00.058.4 8.038 8.038 0 011.229.813.249.249 0 00.341-.027 2.928 2.928 0 00.826-2.06V2a2 2 0 00-2-2H14a2.978 2.978 0 00-2.121.879l-11 11a3 3 0 000 4.242l6.171 6.171a3.005 3.005 0 003.57.5.25.25 0 00.076-.373 8.079 8.079 0 01-.796-1.228z\"/><circle cx=\"16.973\" cy=\"17.03\" r=\"1.5\"/><path d=\"M22.977 13.541a1.752 1.752 0 00-1.936-.842l-1.412.327a.451.451 0 01-.533-.31l-.423-1.392a1.776 1.776 0 00-3.4 0l-.424 1.392a.454.454 0 01-.533.31l-1.411-.326a1.783 1.783 0 00-1.7 2.953l.988 1.066a.461.461 0 010 .625l-.993 1.064a1.783 1.783 0 001.7 2.953l1.412-.327a.453.453 0 01.532.311l.424 1.391a1.776 1.776 0 003.4 0l.423-1.391a.449.449 0 01.534-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.065a.461.461 0 010-.625l.987-1.066a1.756 1.756 0 00.241-2.111zm-6 6.489a3 3 0 113-3 3 3 0 01-3.004 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});