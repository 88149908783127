define("ember-svg-jar/inlined/taking-pictures-cameras", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-cameras</title><path d=\"M17.423 7.5h-.933a.251.251 0 01-.2-.107 1.979 1.979 0 00-1.76-.893h-2.5a1.975 1.975 0 00-1.759.893.252.252 0 01-.206.107h-3.7A2.191 2.191 0 004.25 9.713v1.318a1 1 0 002 0V9.713a.322.322 0 01.106-.213h4.427a1 1 0 00.9-.554l.148-.3a.252.252 0 01.224-.139l2.431-.007a.252.252 0 01.219.128c.053.095.114.216.164.317a1 1 0 00.895.553h1.642a.276.276 0 01.124.215v6.681a.107.107 0 01-.107.106 1 1 0 000 2 2.109 2.109 0 002.107-2.106V9.713A2.191 2.191 0 0017.423 7.5z\"/><path d=\"M21.893 1h-.933a.25.25 0 01-.205-.107A1.979 1.979 0 0019 0h-2.51a1.977 1.977 0 00-1.759.893.249.249 0 01-.205.107h-3.7A2.191 2.191 0 008.72 3.213V4.5a1 1 0 002 0V3.213A.322.322 0 0110.826 3h4.427a1 1 0 00.894-.554l.149-.3a.252.252 0 01.223-.139L18.955 2a.252.252 0 01.22.128c.053.1.113.216.164.317a1 1 0 00.894.555h1.643a.276.276 0 01.124.213v6.681a.107.107 0 01-.107.106 1 1 0 000 2A2.109 2.109 0 0024 9.894V3.213A2.191 2.191 0 0021.893 1z\"/><circle cx=\"8.5\" cy=\"19\" r=\"2.5\"/><path d=\"M13.394 13.606h-1.919a.249.249 0 01-.22-.131 1.559 1.559 0 00-1.48-.975h-2.5a1.556 1.556 0 00-1.479.975.249.249 0 01-.22.131h-3.97A1.609 1.609 0 000 15.213v7.181A1.609 1.609 0 001.606 24h11.788A1.609 1.609 0 0015 22.394v-7.181a1.609 1.609 0 00-1.606-1.607zM12 19a3.5 3.5 0 11-3.5-3.5A3.5 3.5 0 0112 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});