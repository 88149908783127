define("ember-svg-jar/inlined/single-neutral-actions-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-setting</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.23L3 23.291a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.23H10.3a.249.249 0 00.213-.119 3.177 3.177 0 01.367-.492.441.441 0 00.119-.3v-.111a.444.444 0 00-.118-.3 3.269 3.269 0 01-.433-3.884 3.182 3.182 0 01.22-.332.25.25 0 00.037-.232A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.751\" r=\"3.5\"/><circle cx=\"17.748\" cy=\"16.781\" r=\"1.5\"/><path d=\"M11.98 18.158a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.064a.464.464 0 010-.626l.987-1.064a1.783 1.783 0 00-1.7-2.953l-1.411.327a.452.452 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.455.455 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626zm5.768-4.377a3 3 0 11-3 3 3 3 0 013-3zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.23L3 23.291a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.312a.25.25 0 01.249-.23H10.3a.249.249 0 00.213-.119 3.177 3.177 0 01.367-.492.441.441 0 00.119-.3v-.111a.444.444 0 00-.118-.3 3.269 3.269 0 01-.433-3.884 3.182 3.182 0 01.22-.332.25.25 0 00.037-.232A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.751\" r=\"3.5\"/><circle cx=\"17.748\" cy=\"16.781\" r=\"1.5\"/><path d=\"M11.98 18.158a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.064a.464.464 0 010-.626l.987-1.064a1.783 1.783 0 00-1.7-2.953l-1.411.327a.452.452 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.455.455 0 01-.533.311l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626zm5.768-4.377a3 3 0 11-3 3 3 3 0 013-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});