define("ember-svg-jar/inlined/folder-media-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-media-1-alternate</title><path d=\"M16.287 12.581l-6.232-3.64a1.41 1.41 0 00-1.384-.017 1.355 1.355 0 00-.707 1.185v7.282a1.355 1.355 0 00.707 1.185 1.405 1.405 0 001.383-.016l6.233-3.641a1.348 1.348 0 000-2.338z\"/><path d=\"M24 6.3a1.95 1.95 0 00-1.95-1.95H12a.5.5 0 01-.447-.277l-.747-1.494A1.949 1.949 0 009.061 1.5H1.95A1.95 1.95 0 000 3.45v17.1a1.95 1.95 0 001.95 1.95h20.1A1.95 1.95 0 0024 20.55zM22.048 20a.5.5 0 01-.5.5L2.5 20.549a.5.5 0 01-.5-.5L1.952 4a.5.5 0 01.5-.5l6.256-.025a.5.5 0 01.449.277l.761 1.523A1.941 1.941 0 0011.66 6.35l9.84-.05a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});