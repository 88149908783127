define("ember-svg-jar/inlined/project-notes-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-notes-house</title><path d=\"M14 18.75h1a1 1 0 001-1v-2.5a.5.5 0 01.5-.5h1a.5.5 0 00.336-.87l-5.163-4.694a.994.994 0 00-1.346 0L6.164 13.88a.5.5 0 00.336.87h1a.5.5 0 01.5.5v2.5a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 012 0v2a1 1 0 001 1z\"/><path d=\"M19.75 1.75h-1a1 1 0 000 2h.5a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5H4.75a.5.5 0 01-.5-.5V4.25a.5.5 0 01.5-.5h.5a1 1 0 000-2h-1a2 2 0 00-2 2V22a2 2 0 002 2h15.5a2 2 0 002-2V3.75a2 2 0 00-2-2z\"/><path d=\"M8 6h8a1 1 0 001-1V3a1 1 0 00-1-1h-1.172a.5.5 0 01-.471-.333 2.5 2.5 0 00-4.714 0A.5.5 0 019.172 2H8a1 1 0 00-1 1v2a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});