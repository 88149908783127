define("ember-svg-jar/inlined/programming-language-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-code</title><path d=\"M.929 24h13.5a4 4 0 004-4V5.5a.5.5 0 01.5-.5h4.142a1 1 0 001-1v-.429A3.576 3.576 0 0020.5 0H6.929a4 4 0 00-4 4v16a2 2 0 01-2 2 1 1 0 100 2zm3.645-2.353A3.977 3.977 0 004.929 20V4a2 2 0 012-2h9.627a.25.25 0 01.228.353A3.981 3.981 0 0016.429 4v16a2 2 0 01-2 2H4.8a.25.25 0 01-.227-.353z\"/><path d=\"M7.429 7.25h6.5a.75.75 0 100-1.5h-6.5a.75.75 0 000 1.5zM7.429 11.25h6.5a.75.75 0 100-1.5h-6.5a.75.75 0 000 1.5zM7.429 15.25h6.5a.75.75 0 100-1.5h-6.5a.75.75 0 000 1.5zM7.429 19.25h4a.75.75 0 100-1.5h-4a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});