define("ember-svg-jar/inlined/presentation-projector-screen-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-bars</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M5 16.5h14a.75.75 0 000-1.5h-.25a.25.25 0 01-.25-.25v-8.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v8.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-5.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v5.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.5.5 0 00-.5-.5H6a.5.5 0 00-.5.5v2.5a.25.25 0 01-.25.25H5a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});