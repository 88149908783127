define("ember-svg-jar/inlined/video-file-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-clock</title><path d=\"M6.75 10.5a6.75 6.75 0 106.75 6.75 6.758 6.758 0 00-6.75-6.75zm0 11.5a4.75 4.75 0 114.75-4.75A4.756 4.756 0 016.75 22z\"/><path d=\"M8.75 16.25h-.818a.176.176 0 01-.182-.2v-1.3a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2zM23.415 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.247.247 0 00.242.25c.366.006 1.037.034 1.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.244.244 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.229.331H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M19.5 10a.751.751 0 00-.4-.655l-5.846-3.236a.886.886 0 00-.834-.009.755.755 0 00-.42.665V11.6a.241.241 0 00.073.173 8.013 8.013 0 011.359 1.737.245.245 0 00.331.1l5.337-2.955a.751.751 0 00.4-.655z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});