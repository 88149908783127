define("ember-svg-jar/inlined/protocol-open-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protocol-open-id</title><path d=\"M14.75 7.24V1.5a.25.25 0 00-.38-.21l-4 2.5a.25.25 0 00-.12.21v15.21c-4.87-.74-6.56-2.86-6.51-4.62s1.64-3.47 4.51-4.29V7.45c-4.45 1-7.81 3.89-8 7.18-.1 1.79.75 6.19 10.75 8.12a.25.25 0 00.14 0l3.5-2.29a.25.25 0 00.11-.21V10.11zM23.62 8a.25.25 0 00-.26 0l-2.11 1.44a19 19 0 00-4.49-1.79v3c.58.18 1.16.39 1.73.64l-1.6 1a.25.25 0 00.13.46l6.48 2a.25.25 0 00.25-.25V8.24a.25.25 0 00-.13-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});