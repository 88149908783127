define("ember-svg-jar/inlined/truck-animal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-animal</title><path d=\"M2.625 4.5a.25.25 0 01.25.25v1a2.138 2.138 0 00.362 1.193L4.4 8.7a2.369 2.369 0 003.942 0l1.171-1.757a2.138 2.138 0 00.362-1.193v-1a.25.25 0 01.25-.25 2.5 2.5 0 002.5-2.5V1a1 1 0 00-2 0v1a.5.5 0 01-.5.5h-.586a.249.249 0 01-.186-.083 1.984 1.984 0 00-1.478-.667h-3a1.984 1.984 0 00-1.475.667.249.249 0 01-.186.083h-.589a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v1a2.5 2.5 0 002.5 2.5zm4.75-.25a.5.5 0 011 0v1a.5.5 0 01-1 0zm-3 0a.5.5 0 011 0v1a.5.5 0 01-1 0zM19.125 10.25h-2.5a.75.75 0 00-.75.75v5.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.525a.25.25 0 01.2-.245 1 1 0 000-1.96.25.25 0 01-.2-.245v-.8a.25.25 0 01.2-.245 1 1 0 00-.2-1.98h-.02a.251.251 0 01-.2-.1.96.96 0 00-1.56 0 .249.249 0 01-.2.1h-8.29a.251.251 0 01-.2-.1.96.96 0 00-1.56 0 .249.249 0 01-.2.1h-.02a1 1 0 00-.2 1.98.25.25 0 01.2.245v.8a.25.25 0 01-.2.245 1 1 0 000 1.96.25.25 0 01.2.245v.525a.25.25 0 01-.25.25h-.5a.75.75 0 00-.75.75v1.6A1.647 1.647 0 001.771 21H1.8a.218.218 0 00.21-.158 3.24 3.24 0 016.22-.021.25.25 0 00.241.179h6.058a.25.25 0 00.239-.179 3.24 3.24 0 016.214 0 .25.25 0 00.239.179h1.008a1.647 1.647 0 001.646-1.646V15a4.756 4.756 0 00-4.75-4.75zm-7.25 6.5a.25.25 0 01-.25.25h-7.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h7.75a.25.25 0 01.25.25zm0-3a.25.25 0 01-.25.25h-7.75a.25.25 0 01-.25-.25V13a.25.25 0 01.25-.25h7.75a.25.25 0 01.25.25zm10 1.75h-2.5a.5.5 0 01-.5-.5v-2.5a.5.5 0 01.5-.5 3 3 0 013 3 .5.5 0 01-.5.5z\"/><circle cx=\"5.125\" cy=\"21.75\" r=\"2.25\"/><circle cx=\"17.875\" cy=\"21.75\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});