define("ember-svg-jar/inlined/conversation-chat-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-2-alternate</title><path d=\"M9.5 12.5H9a1 1 0 00-.753.342l-1.747 2V13.5a1 1 0 00-1-1H5a3 3 0 01-3-3v-4a3 3 0 013-3h9.5a3 3 0 013 3V9a1 1 0 102 0V5.5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v4a5.008 5.008 0 004.5 4.976V17.5a1 1 0 001.753.659l3.2-3.659H9.5a1 1 0 000-2z\"/><path d=\"M21 11.5h-6.5a3 3 0 00-3 3v3a3 3 0 003 3h1.586l2.707 2.708A1 1 0 0020.5 22.5v-2h.5a3 3 0 003-3v-3a3 3 0 00-3-3zm1 6a1 1 0 01-1 1h-1.5a1 1 0 00-1 1v.586l-1.293-1.293a1 1 0 00-.707-.293h-2a1 1 0 01-1-1v-3a1 1 0 011-1H21a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});