define("ember-svg-jar/inlined/clean-car-leaf-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-leaf-2</title><path d=\"M5.712 6.192c2.33-1.5 5.949-3.128 8.644-2.538a.6.6 0 01-.256 1.18 8.468 8.468 0 00-4.593.749.5.5 0 00-.293.606c.492 1.652 2.286 2.189 4 1.96 2.09-.279 5.334-2.971 6-5.6a.606.606 0 00-.567-.753A7.842 7.842 0 0115.425.9a8.441 8.441 0 00-2.8-.875 3.308 3.308 0 00-2.95 1.013 2.647 2.647 0 00-.562 1.743.5.5 0 01-.319.471 20.676 20.676 0 00-3.76 1.939.6.6 0 00.678 1zM20.857 13.985a1 1 0 00-1.372-.342l-.509.3a.5.5 0 01-.754-.37l-.122-1.057a2 2 0 00-1.986-1.766H7.889A2 2 0 005.9 12.517l-.125 1.061a.5.5 0 01-.754.37l-.509-.3a1 1 0 00-1.03 1.715l.818.49a.5.5 0 01.151.717A2.486 2.486 0 004 18v1a1.5 1.5 0 001.5 1.5h13A1.5 1.5 0 0020 19v-1a2.486 2.486 0 00-.454-1.435.505.505 0 01-.081-.392.5.5 0 01.233-.325l.817-.49a1 1 0 00.342-1.373zm-4.611 1.347a.5.5 0 01-.374.168H8.128a.5.5 0 01-.5-.558l.206-1.75a.5.5 0 01.5-.442h7.332a.5.5 0 01.5.442l.206 1.75a.5.5 0 01-.126.39zM7.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5zM18.5 21.5h-2a.5.5 0 00-.5.5v.5a1.5 1.5 0 003 0V22a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});