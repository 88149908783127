define("ember-svg-jar/inlined/composition-window-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-woman-1</title><path d=\"M20.75 2.75A2.752 2.752 0 0018 0H6a2.752 2.752 0 00-2.75 2.75v18.5A2.752 2.752 0 006 24h12a2.752 2.752 0 002.75-2.75zM8.911 11.9a3.137 3.137 0 016.1 0l1.274 3.951a.5.5 0 01-.476.653h-1.588a.25.25 0 00-.248.219l-.418 3.343a.5.5 0 01-.5.438h-2.114a.5.5 0 01-.5-.438l-.418-3.343a.25.25 0 00-.248-.219h-1.6a.5.5 0 01-.478-.646zM9.5 6A2.5 2.5 0 1112 8.5 2.5 2.5 0 019.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});