define("ember-svg-jar/inlined/diving-oxygen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-oxygen</title><rect x=\"16.875\" y=\"14\" width=\"7\" height=\"7\" rx=\".25\" ry=\".25\"/><path d=\"M18.875 24h3a2 2 0 001.98-1.714.251.251 0 00-.059-.2.248.248 0 00-.189-.086h-6.464a.249.249 0 00-.247.286A2 2 0 0018.875 24zM3.375 6h1.5c1.252 0 2-.935 2-2.5v-1a.5.5 0 011 0v1c0 1.565.747 2.5 2 2.5h1.5a2 2 0 002-2V3a3 3 0 00-3-3h-6a3 3 0 00-3 3v1a2 2 0 002 2z\"/><path d=\"M19.376 7.125v.159a.254.254 0 01-.071.175 1.481 1.481 0 00-.429 1.041v1.7a.252.252 0 01-.132.221 3.506 3.506 0 00-1.769 2.31.22.22 0 00.043.185.217.217 0 00.171.082h6.334a.251.251 0 00.2-.1.254.254 0 00.047-.213 3.512 3.512 0 00-1.758-2.269.25.25 0 01-.132-.22V8.5a1.481 1.481 0 00-.429-1.041.25.25 0 01-.071-.175v-.159a3.875 3.875 0 10-7.75 0V16a5.8 5.8 0 01-5.751 6 5.8 5.8 0 01-5.75-6v-2a3.741 3.741 0 013.1-3.675.248.248 0 01.255.114 2.248 2.248 0 10-.062-2.276.254.254 0 01-.191.127A5.755 5.755 0 00.125 14v2a7.8 7.8 0 007.75 8 7.8 7.8 0 007.751-8V7.125a1.875 1.875 0 113.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});