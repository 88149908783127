define("ember-svg-jar/inlined/content-browser-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-browser-edit</title><path d=\"M22 19.118a1 1 0 01-1 1H3a1 1 0 01-1-1v-11a.249.249 0 01.25-.25h9.5a.249.249 0 00.177-.073l5.25-5.25A.25.25 0 0017 2.118H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V8.625a.25.25 0 00-.427-.177l-1.5 1.5a.251.251 0 00-.073.177zm-18.5-14a.75.75 0 11.75.75.75.75 0 01-.75-.75zm3 0a.75.75 0 11.75.75.75.75 0 01-.75-.75zm3 0a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/><path d=\"M5.25 11.368H8a.75.75 0 000-1.5H5.25a.75.75 0 000 1.5zM5.25 14.868H8a.75.75 0 000-1.5H5.25a.75.75 0 000 1.5zM5.25 18.368h13.5a.75.75 0 000-1.5H5.25a.75.75 0 000 1.5zM12.74 10.915a.5.5 0 00-.835.216l-.885 3.1a.5.5 0 00.618.618l3.1-.885a.5.5 0 00.346-.356.494.494 0 00-.13-.478zM21.772 7.8a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0l-5.011 5.015a.5.5 0 000 .707l3 3a.5.5 0 00.707 0zM19.478 2.684a.5.5 0 000 .706l3 3a.457.457 0 00.328.126.619.619 0 00.425-.172 2.621 2.621 0 000-3.707 2.653 2.653 0 00-3.753.047z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});