define("ember-svg-jar/inlined/smiley-evil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-evil</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5.132 7A1 1 0 016.5 6.632l3.5 2a1 1 0 01-.5 1.868.988.988 0 01-.5-.132l-3.5-2A1 1 0 015.132 7zm1.1 9.355l1.617-1.938a2 2 0 013.232.22l.708 1.133a.251.251 0 00.424 0l.708-1.133a2 2 0 013.232-.22l1.617 1.938a1 1 0 01-1.538 1.282l-1.4-1.675a.25.25 0 00-.4.028l-.737 1.18a2 2 0 01-3.39 0l-.737-1.18a.25.25 0 00-.4-.028l-1.399 1.679a1 1 0 01-1.538-1.282zM18.5 8.368l-3.5 2A1 1 0 0113.632 10 1 1 0 0114 8.632l3.5-2a1 1 0 111 1.736z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});