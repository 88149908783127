define("ember-svg-jar/inlined/road-sign-no-buses-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-buses-alternate</title><path d=\"M19.468 7.673a.244.244 0 00-.211.069l-8 8a.245.245 0 00.173.418h1.61a.245.245 0 00.241-.2 2.434 2.434 0 014.789-.012.244.244 0 00.272.2 1.713 1.713 0 001.5-1.7V8.571a1.663 1.663 0 00-.189-.769.246.246 0 00-.185-.129zm-1.835 4.083a.98.98 0 11.979-.98.98.98 0 01-.979.98z\"/><path d=\"M15.673 14.94a1.47 1.47 0 00-1.218 2.291 1.5 1.5 0 00.359.365 1.462 1.462 0 001.9-.148 1.5 1.5 0 00.179-.217 1.47 1.47 0 00-1.219-2.291z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM4.439 18.177a.25.25 0 01-.189.071.245.245 0 01-.178-.094A10.035 10.035 0 0118.154 4.072a.246.246 0 01.023.367l-2.346 2.346a.247.247 0 01-.174.072h-7.33a1.716 1.716 0 00-1.715 1.714v2.939a.245.245 0 01-.245.245h-.979a1.717 1.717 0 00-1.715 1.714v.98a1.713 1.713 0 001.5 1.7.246.246 0 00.273-.2 2.441 2.441 0 012.391-1.989 1.973 1.973 0 01.244.015.244.244 0 01.143.416l-.509.51a.255.255 0 01-.131.068A1.457 1.457 0 006.4 16.156a.251.251 0 01-.068.131l-.826.825zM12.3 10.32l-1 1a.249.249 0 01-.2.07.244.244 0 01-.18-.115.93.93 0 01-.142-.5.979.979 0 01.979-.979.928.928 0 01.5.142.244.244 0 01.115.18.249.249 0 01-.072.202zm-2.5-.545v1.469a.49.49 0 01-.49.49h-.731a.49.49 0 01-.49-.49V9.285a.491.491 0 01.49-.49h.245a.98.98 0 01.976.98zM12 22.041a9.98 9.98 0 01-5.433-1.609 12.224 12.224 0 01-.718-.5.244.244 0 01-.024-.368L19.561 5.825a.246.246 0 01.368.024c.178.232.344.472.5.718A10.026 10.026 0 0112 22.041z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});