define("ember-svg-jar/inlined/road-sign-water-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-water-ahead-alternate</title><path d=\"M23.707 12.707a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0zm-7.164 4.336l-4.366 4.366a.25.25 0 01-.354 0l-4.366-4.366a.062.062 0 01-.013-.066.061.061 0 01.056-.038 2.718 2.718 0 001.374-.374.25.25 0 01.252 0 2.794 2.794 0 002.749 0 .248.248 0 01.25 0 2.794 2.794 0 002.749 0 .25.25 0 01.252 0 2.718 2.718 0 001.374.374.061.061 0 01.056.038.062.062 0 01-.013.066zm4.866-5.22a.25.25 0 010 .354l-4.055 4.055a.059.059 0 01-.066.013.06.06 0 01-.038-.056.75.75 0 00-.75-.75 1.234 1.234 0 01-.936-.433.776.776 0 00-1.128 0 1.256 1.256 0 01-1.872 0 .776.776 0 00-1.128 0 1.256 1.256 0 01-1.872 0 .776.776 0 00-1.128 0 1.234 1.234 0 01-.936.433.75.75 0 00-.75.75.06.06 0 01-.038.056.059.059 0 01-.066-.013l-4.055-4.055a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0z\"/><path d=\"M9.793 8.793l-2.134 2.134a.249.249 0 01-.177.073H4.5a1 1 0 000 2h2.25a.25.25 0 01.25.25v.25a1 1 0 002 0v-.982a.249.249 0 01.073-.177l2.134-2.134a1 1 0 00-1.414-1.414zM20.5 12a1 1 0 00-1-1h-2.982a.249.249 0 01-.177-.073l-2.134-2.134a1 1 0 00-1.414 1.414l2.134 2.134a.249.249 0 01.073.177v.982a1 1 0 002 0v-.25a.25.25 0 01.25-.25h2.25a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});