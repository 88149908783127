define("ember-svg-jar/inlined/monitor-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-camera</title><path d=\"M19 23.751a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3V2.84A2.548 2.548 0 0021.5.251h-19A2.548 2.548 0 000 2.84v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2zM2 2.84a.551.551 0 01.5-.589h19a.551.551 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M15.5 6.251a1 1 0 00-1-1h-6a1 1 0 00-1 1v5a1 1 0 001 1h6a1 1 0 001-1v-.32a.25.25 0 01.1-.2.248.248 0 01.225-.034l1.342.447a.248.248 0 00.225-.034.252.252 0 00.1-.2V6.584a.25.25 0 00-.32-.24l-1.36.4a.25.25 0 01-.32-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});