define("ember-svg-jar/inlined/science-fiction-robot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-fiction-robot</title><path d=\"M15.75 5.75A.25.25 0 0016 5.5V4a4 4 0 00-8 0v1.5a.249.249 0 00.25.25zM7.25 17.25a.249.249 0 00-.25.25V22H6a1 1 0 000 2h4.5a1 1 0 00.5-1.866V17.5a.25.25 0 00-.25-.25zM13.25 17.25a.249.249 0 00-.25.25v4.634A1 1 0 0013.5 24H18a1 1 0 000-2h-1v-4.5a.25.25 0 00-.25-.25zM22.5 13.769V11.6a4.485 4.485 0 00-3.561-4.429.249.249 0 00-.241.07.252.252 0 00-.059.245 14.331 14.331 0 01.409 1.714.249.249 0 00.129.179A2.416 2.416 0 0120.5 11.6v2.169a2 2 0 102 0zM1.5 11.6v2.169a2 2 0 102 0V11.6a2.418 2.418 0 011.322-2.219.253.253 0 00.13-.179 14.371 14.371 0 01.408-1.716.25.25 0 00-.3-.315A4.485 4.485 0 001.5 11.6zM17.042 7.419a.251.251 0 00-.237-.169H7.194a.251.251 0 00-.237.169 12.222 12.222 0 000 8.163.251.251 0 00.236.168h9.613a.251.251 0 00.236-.168 12.222 12.222 0 000-8.163zM14.5 11.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});