define("ember-svg-jar/inlined/crypto-currency-bitcoin-dollar-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-dollar-exchange</title><path d=\"M21.6 16.605a.5.5 0 00-.707 0L18.9 18.613a.5.5 0 00.354.853h.56a.252.252 0 01.209.111.248.248 0 01.022.236 4.04 4.04 0 01-3.785 2.165 1 1 0 000 2c2.97-.01 5.433-1.882 5.9-4.317a.25.25 0 01.245-.2h.844a.5.5 0 00.351-.861zM1.905 7.358a.5.5 0 00.353.146.493.493 0 00.354-.148l1.994-2a.5.5 0 00-.354-.856h-.56a.25.25 0 01-.209-.112.246.246 0 01-.022-.235 4.04 4.04 0 013.783-2.165 1 1 0 000-2c-2.97.01-5.432 1.88-5.9 4.317a.249.249 0 01-.244.2H.255a.5.5 0 00-.355.86zM10 14.988a.752.752 0 00-.75-.75h-2.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2.5a.752.752 0 00.75-.75zM9.248 17.238h-2.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h2.5a.75.75 0 000-1.5z\"/><path d=\"M8.045 9.012a7.5 7.5 0 107.5 7.5 7.508 7.508 0 00-7.5-7.5zM6 20.993v-.5a.25.25 0 00-.25-.25.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0v.719a.251.251 0 00.15.229 2.244 2.244 0 011.35 2.052 2.219 2.219 0 01-.46 1.349.251.251 0 000 .3 2.219 2.219 0 01.46 1.348 2.244 2.244 0 01-1.35 2.052.251.251 0 00-.15.229v.719a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0zM18.748 10.993a5.5 5.5 0 10-5.5-5.5 5.507 5.507 0 005.5 5.5zM20 2.243a.75.75 0 010 1.5h-1.7a.368.368 0 00-.137.71l1.719.688a1.863 1.863 0 01-.2 3.531.25.25 0 00-.184.241v.122a.75.75 0 01-1.5 0v-.042a.25.25 0 00-.25-.25H17.5a.75.75 0 010-1.5h1.694a.368.368 0 00.137-.71l-1.721-.687a1.865 1.865 0 01.2-3.532.249.249 0 00.19-.241v-.122a.75.75 0 011.5 0v.042a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});