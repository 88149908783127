define("ember-svg-jar/inlined/style-three-pin-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cup</title><path d=\"M16.168 8.5H15.5a.5.5 0 00-.5.5v.885a.5.5 0 00.647.478l.766-.236a.832.832 0 00-.245-1.627z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.5 9.332a2.321 2.321 0 01-1.646 2.229l-1.645.506a.5.5 0 00-.335.347A3.5 3.5 0 0111.5 15h-2A3.5 3.5 0 016 11.5v-4A1.5 1.5 0 017.5 6h6a1.493 1.493 0 011.292.75.5.5 0 00.433.25h.943A2.334 2.334 0 0118.5 9.332z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});