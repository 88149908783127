define("ember-svg-jar/inlined/analytics-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-graph</title><path d=\"M18.876 6.451A2.979 2.979 0 0118.5 5a.153.153 0 00-.06-.121.157.157 0 00-.133-.025l-5 1.4a.253.253 0 00-.162.143.247.247 0 00.008.214A2.976 2.976 0 0113.5 8a.208.208 0 00.263.2l4.961-1.386a.25.25 0 00.152-.363zM18.818 15.946a.248.248 0 00-.17-.136l-4.842-1.127a.248.248 0 00-.307.249V15a2.971 2.971 0 01-.29 1.285.251.251 0 00.17.352l4.813 1.121a.255.255 0 00.213-.049.249.249 0 00.094-.2V17.5a2.979 2.979 0 01.315-1.335.255.255 0 00.004-.219z\"/><path d=\"M1 0a1 1 0 00-1 1v21.522A1.485 1.485 0 001.489 24H23a1 1 0 000-2H2.25a.249.249 0 01-.25-.25v-.05a.249.249 0 01.119-.213l6.314-3.881a.25.25 0 00.039-.4 3 3 0 01-.778-1.147.25.25 0 00-.365-.124l-4.948 3.043A.251.251 0 012 18.765v-2.649a.248.248 0 01.1-.2l6.636-5.1a.249.249 0 000-.4 3.022 3.022 0 01-.9-1.056.25.25 0 00-.375-.084L2.4 13.162a.25.25 0 01-.4-.2V1a1 1 0 00-1-1z\"/><circle cx=\"10.5\" cy=\"8\" r=\"2\"/><circle cx=\"21.5\" cy=\"5\" r=\"2\"/><circle cx=\"21.5\" cy=\"17.5\" r=\"2\"/><circle cx=\"10.5\" cy=\"15\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});