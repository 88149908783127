define("ember-svg-jar/inlined/email-action-heart-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-heart_1</title><path d=\"M13.722 11.065a5.05 5.05 0 013.778.911 5.027 5.027 0 013.237-.948.25.25 0 00.185-.069.247.247 0 00.078-.181V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h8.02a.25.25 0 00.23-.157 5.034 5.034 0 013.722-2.778z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761a.437.437 0 00.106-.447C19.758.027 19.49 0 19.249 0h-17.5c-.24 0-.51.027-.605.314a.441.441 0 00.106.447zM21.039 12.546a3.483 3.483 0 00-3.058.987l-.481.482-.481-.482a3.527 3.527 0 00-5.647.916 3.521 3.521 0 00.653 4.063l5.114 5.334a.5.5 0 00.722 0l5.106-5.327a3.524 3.524 0 00.66-4.07 3.485 3.485 0 00-2.588-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});