define("ember-svg-jar/inlined/touch-password-approved-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-password-approved-1</title><path d=\"M22 0H2a2 2 0 00-2 2v9a2 2 0 002 2h1a1 1 0 000-2h-.5a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v8a.5.5 0 01-.5.5H13a1 1 0 000 2h9a2 2 0 002-2V2a2 2 0 00-2-2z\"/><path d=\"M16 9.5h.033a1 1 0 00.72-.341l3.5-4a1 1 0 00-1.506-1.317l-2.8 3.2-1.24-1.248a1 1 0 10-1.414 1.414l2 2A1 1 0 0016 9.5zM13.389 17H10.5v-6.5a2.5 2.5 0 10-5 0v8.835l-1.089-1.527a2.136 2.136 0 00-2.8-.87 2.1 2.1 0 00-.87 2.8l2.1 3.993a.5.5 0 00.441.269H17.5a.5.5 0 00.5-.5v-1.652A4.8 4.8 0 0013.389 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});