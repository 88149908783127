define("ember-svg-jar/inlined/data-transfer-ftp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-ftp</title><path d=\"M11.5 5.5a1 1 0 001-1v-.75a.25.25 0 01.25-.25H14a.5.5 0 00.354-.854l-2.5-2.5a.5.5 0 00-.708 0l-2.5 2.5A.5.5 0 009 3.5h1.25a.25.25 0 01.25.25v.75a1 1 0 001 1zM17.25 10.25a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.5a.75.75 0 000-1.5z\"/><path d=\"M2 8v8a1.5 1.5 0 001.5 1.5h17A1.5 1.5 0 0022 16V8a1.5 1.5 0 00-1.5-1.5h-17A1.5 1.5 0 002 8zm13.5 1.5a.75.75 0 01.75-.75h1.5a2.25 2.25 0 010 4.5h-.5a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0zm-4.75.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.5a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-4a.25.25 0 00-.25-.25zm-3.5 1a.75.75 0 010 1.5h-1A.25.25 0 006 13v1.5a.75.75 0 01-1.5 0v-4a1.752 1.752 0 011.75-1.75h2a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25zM11.5 18.5a1 1 0 00-1 1v.75a.25.25 0 01-.25.25H9a.5.5 0 00-.354.853l2.5 2.5a.5.5 0 00.708 0l2.5-2.5A.5.5 0 0014 20.5h-1.25a.25.25 0 01-.25-.25v-.75a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});