define("ember-svg-jar/inlined/phone-actions-question-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-question-1</title><path d=\"M17.4.01a6.57 6.57 0 00-5.863 9.551l-1.415 2.973a1 1 0 00.2 1.137 1.015 1.015 0 001.137.2l2.972-1.415A6.576 6.576 0 1017.4.01zm0 11.157a4.558 4.558 0 01-2.407-.691 1 1 0 00-.528-.151.983.983 0 00-.43.1l-.895.426.426-.9A1 1 0 0013.513 9a4.574 4.574 0 113.887 2.167z\"/><path d=\"M17.5 3.177a2.252 2.252 0 00-2.25 2.25.75.75 0 001.5 0 .75.75 0 11.75.75.75.75 0 000 1.5 2.25 2.25 0 000-4.5z\"/><circle cx=\"17.503\" cy=\"9.177\" r=\"1\"/><path d=\"M15.515 15.773a3.114 3.114 0 00-4.4 0l-.828.828a4.252 4.252 0 00-1.346 4.5A11.342 11.342 0 012.91 15.07a4.247 4.247 0 004.5-1.346l.83-.829a3.117 3.117 0 000-4.4L4.655 4.913a2.234 2.234 0 00-3.649.739 13.314 13.314 0 0017.358 17.355 2.233 2.233 0 00.736-3.649z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});