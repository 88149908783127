define("ember-svg-jar/inlined/professions-man-nurse-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-nurse-2</title><path d=\"M20.608 18.815l-2.893-.971a.747.747 0 00-.474 1.416l2.868.963a3.25 3.25 0 012.1 3.03.747.747 0 001.494 0 4.759 4.759 0 00-3.095-4.438zM6.336 17.718l-2.969 1A4.752 4.752 0 00.3 23.145a.747.747 0 001.494 0 3.244 3.244 0 012.075-3.022l2.945-.988a.747.747 0 10-.475-1.417zM10.527 14.08a2.715 2.715 0 00.5.213 3.828 3.828 0 001 .149 3.347 3.347 0 00.992-.147 2.749 2.749 0 00.5-.215.747.747 0 00-.731-1.3 1.194 1.194 0 01-.221.094 1.834 1.834 0 01-.542.078 1.833 1.833 0 01-.546-.079 1.216 1.216 0 01-.223-.094.747.747 0 10-.728 1.3zM11.131 10.494a.746.746 0 00-.038-1.056 2.012 2.012 0 00-2.59 0 .748.748 0 001.018 1.095.558.558 0 01.555 0 .746.746 0 001.055-.039zM14.479 10.533A.748.748 0 0015.5 9.438a2.012 2.012 0 00-2.59 0 .747.747 0 101.017 1.095.558.558 0 01.552 0z\"/><path d=\"M17.644 12.959a.251.251 0 01.143-.188 2.719 2.719 0 001.434-2.424 1.876 1.876 0 00-.932-1.8.747.747 0 00-.667 1.336.613.613 0 01.105.437 1.275 1.275 0 01-.556 1.092 1.744 1.744 0 00-1 1.3c-.377 2.239-3.163 3.723-4.191 3.723s-3.815-1.485-4.191-3.723a1.743 1.743 0 00-1-1.3 1.27 1.27 0 01-.556-1.091.616.616 0 01.105-.438.747.747 0 10-.669-1.336 1.879 1.879 0 00-.93 1.8 2.717 2.717 0 001.434 2.423.249.249 0 01.143.187c.508 3.035 4.005 4.971 5.664 4.971s5.155-1.935 5.664-4.969zM6.146 7.46a35.554 35.554 0 0111.708 0 .709.709 0 00.122.01.747.747 0 00.747-.747V2.241a.752.752 0 00-.3-.6A11.171 11.171 0 0012 0h-.006a11.172 11.172 0 00-6.419 1.643.752.752 0 00-.3.6v4.48a.747.747 0 00.869.737zm.625-4.655a.25.25 0 01.129-.221 12.456 12.456 0 0110.192 0 .249.249 0 01.133.22v2.762a.25.25 0 01-.282.247 36.912 36.912 0 00-9.894 0 .253.253 0 01-.2-.06.248.248 0 01-.085-.187zM17.852 21.788a.622.622 0 00-.623-.622h-.622a.249.249 0 01-.249-.249v-.623a.623.623 0 00-1.245 0v.623a.25.25 0 01-.249.249h-.623a.623.623 0 000 1.245h.623a.249.249 0 01.249.249v.622a.623.623 0 101.245 0v-.622a.248.248 0 01.249-.249h.622a.623.623 0 00.623-.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});