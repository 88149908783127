define("ember-svg-jar/inlined/christmas-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-star</title><path d=\"M23.573 17.615l-10.163-7.09a.5.5 0 00-.365-.083l-1.885.3a.5.5 0 00-.414.414l-.3 1.886a.5.5 0 00.084.365l7.09 10.162a1 1 0 00.82.428 1.119 1.119 0 00.182-.016 1.006 1.006 0 00.789-.745l.612-2.488a1 1 0 01.732-.732l2.489-.613a1 1 0 00.333-1.791z\"/><path d=\"M14.246 8.032a1 1 0 00-.533-1.046L10.45 5.314a.5.5 0 01-.266-.518l.538-3.65a1 1 0 00-1.7-.853l-2.566 2.57a.5.5 0 01-.581.092L2.662 1.318a1 1 0 00-1.344 1.343l1.638 3.214a.5.5 0 01-.092.581L.294 9.025a1 1 0 00-.187 1.158.99.99 0 001.04.538l3.65-.538a.5.5 0 01.518.267l1.67 3.261a1 1 0 00.89.547 1.061 1.061 0 00.157-.013 1 1 0 00.832-.829l.573-3.565a.5.5 0 01.414-.414l3.567-.574a1 1 0 00.828-.831z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});