define("ember-svg-jar/inlined/messages-bubble-square-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-information</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.553A1.459 1.459 0 002 18h3v4a.5.5 0 00.784.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM9.747 14a.75.75 0 01.75-.75h1.25V9a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1A1.752 1.752 0 0113.247 9v4.25H14.5a.75.75 0 010 1.5h-4a.75.75 0 01-.753-.75zM11.5 5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});