define("ember-svg-jar/inlined/soft-drinks-juice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-juice</title><path d=\"M14.854 9.146A2.185 2.185 0 0114 7V5.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V7a2.185 2.185 0 01-.854 2.146A6.087 6.087 0 007 13.5v9A1.5 1.5 0 008.5 24h7a1.5 1.5 0 001.5-1.5v-9a6.087 6.087 0 00-2.146-4.354zM10.5 3.5h3A.5.5 0 0014 3V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});