define("ember-svg-jar/inlined/audio-file-mp3-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-mp3-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M9 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H9a.875.875 0 000-1.75z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM6.125 17.5a.625.625 0 01-1.25 0v-3.012c0-.141-.116-.108-.172 0l-.144.289a.65.65 0 01-1.118 0l-.153-.3c-.047-.091-.163-.1-.163 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279l.8 1.59a.152.152 0 00.289.005l.8-1.6a.625.625 0 011.184.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H9a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zm6.821-.479A2.3 2.3 0 0116.125 16a2.015 2.015 0 01-1.865 2.123h-.757a1.736 1.736 0 01-1.628-1.823.625.625 0 011.25 0c0 .339.2.575.375.575h.754c.337 0 .621-.4.621-.873s-.285-.873-.623-.873a.625.625 0 010-1.25c.338 0 .623-.4.623-.873s-.284-.871-.621-.873h-.757c-.175 0-.372.236-.372.575a.625.625 0 01-1.25 0 1.735 1.735 0 011.625-1.833h.76A2.015 2.015 0 0116.125 13a2.3 2.3 0 01-.429 1.352.24.24 0 00.004.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});