define("ember-svg-jar/inlined/wild-bird", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird</title><path d=\"M23.65 18.112c-.018-.038-1.8-3.634-2.1-3.924-.27.429-2.191 3.453-6.561 2.168-4.438-1.305-4.489-7.505-4.489-7.769a.75.75 0 01.749-.75.751.751 0 01.75.749c0 .054.052 5.343 3.408 6.331 3.638 1.079 4.93-1.566 5.118-2.01C16.874 6.576 12.185 0 8.46 0 5.82 0 1.987 1.15.407 3.211a.75.75 0 101.186.918 5.5 5.5 0 01.822-.787C3 6.589 4.763 15.656 6.853 18.4a8.127 8.127 0 002.227 1.99l-.78 2.283a1 1 0 101.894.642l.712-2.088a8.122 8.122 0 003.784.3l-.39 1.144a1 1 0 101.894.642c.894-2.631.842-2.454.861-2.57a8.5 8.5 0 002.045-1.486 15.113 15.113 0 001.8-2.138c.6 1.137.942 1.852.947 1.862a1 1 0 101.8-.869zM7.75 2.392a.981.981 0 11-1 .981.991.991 0 011-.981z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});