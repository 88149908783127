define("ember-svg-jar/inlined/video-game-logo-streamplay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-streamplay</title><path d=\"M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm2.173 5.529l-2.817 1.409a.592.592 0 01-.856-.529v-2.818a.591.591 0 01.856-.528l2.817 1.408a.591.591 0 010 1.058z\"/><path d=\"M7.4 7.4a1 1 0 00-1.41-1.411 8.511 8.511 0 000 12.022A1 1 0 107.4 16.6a6.51 6.51 0 010-9.2zM18.01 5.989A1 1 0 0016.6 7.4a6.51 6.51 0 010 9.194 1 1 0 101.414 1.414 8.511 8.511 0 00-.004-12.019z\"/><path d=\"M4.777 5.282a1 1 0 00-1.414-1.414 11.513 11.513 0 000 16.264 1 1 0 101.414-1.414 9.512 9.512 0 010-13.436zM20.637 3.868a1 1 0 00-1.414 1.414 9.512 9.512 0 010 13.436 1 1 0 101.414 1.414 11.513 11.513 0 000-16.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});