define("ember-svg-jar/inlined/database-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-bug</title><path d=\"M10.109 21.608a.25.25 0 00.182-.071.157.157 0 00-.064-.263 2.244 2.244 0 01-1.605-2.149 2.162 2.162 0 01.148-.782.25.25 0 00-.207-.343 13.431 13.431 0 01-6.777-2.289.25.25 0 00-.408.194v1.716c0 2.105 3.846 3.821 8.731 3.987zM1.378 13.125c0 2.062 3.7 3.758 8.461 3.976a.237.237 0 00.114-.022 2.223 2.223 0 01.919-.2h.433a.1.1 0 00.1-.09c0-.031.006-.063.009-.089a.1.1 0 00-.037-.09l-.905-.72a2.241 2.241 0 01-.828-2.033.251.251 0 00-.232-.28c-3.022-.2-5.959-1-7.626-2.358a.25.25 0 00-.408.194zM10.879 9.125c-3.5 0-7.156-.833-9.093-2.411a.25.25 0 00-.408.194v1.717c0 2.1 3.847 3.818 8.734 3.983a.253.253 0 00.182-.07 2.231 2.231 0 013.041-.122.25.25 0 00.181.048c3.961-.481 6.862-2.015 6.862-3.839V6.91a.25.25 0 00-.408-.194c-1.937 1.577-5.587 2.409-9.091 2.409z\"/><ellipse cx=\"10.879\" cy=\"4.125\" rx=\"9.5\" ry=\"4\"/><path d=\"M20.405 13.538l-.583.462a.75.75 0 00-.169.984.75.75 0 001.1.19l.583-.464a.75.75 0 00-.934-1.174zM13.092 14.986a.751.751 0 00-.17-.984l-.583-.464a.75.75 0 00-.934 1.174l.583.464a.751.751 0 001.104-.19zM19.653 22.264a.75.75 0 00.169.984l.583.464a.75.75 0 10.934-1.174l-.583-.464a.75.75 0 00-1.103.19zM13.472 22.066a.7.7 0 00-1.036-.178l-.548.436a.7.7 0 10.877 1.1l.548-.435a.705.705 0 00.159-.923zM21.872 18.375h-.75a.75.75 0 00-.75.75.75.75 0 00.75.75h.75a.75.75 0 000-1.5zM12.372 19.125a.75.75 0 00-.75-.75h-.75a.75.75 0 000 1.5h.75a.75.75 0 00.75-.75zM19.277 17.239a.1.1 0 00.067-.029.091.091 0 00.026-.068 2.818 2.818 0 00-5.621-.178.252.252 0 00.249.275zM13.986 18.178a.25.25 0 00-.25.25v1.629a2.818 2.818 0 005.636 0v-1.629a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});