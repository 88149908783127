define("ember-svg-jar/inlined/modern-weapon-tank-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-tank-1</title><rect x=\"11.25\" y=\"14.625\" width=\"5\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"11.25\" y=\"11.625\" width=\"5\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"11.25\" y=\"17.625\" width=\"5\" height=\"2\" rx=\".5\" ry=\".5\"/><path d=\"M9.25 9.125a1 1 0 001 1h7a1 1 0 001-1v-3a.5.5 0 01.5-.5h1a1 1 0 011 1v1a1 1 0 002 0v-1a3 3 0 00-3-3h-1.4a.5.5 0 01-.374-.168.962.962 0 00-.723-.332h-7a1 1 0 00-.943.667.5.5 0 01-.472.333H2v-1.5a1 1 0 00-2 0v5.25a.75.75 0 00.75.75h8a.5.5 0 01.5.5zM8.482 16.638l-4.5 3.5a1.25 1.25 0 001.535 1.974l4.5-3.5a1.25 1.25 0 10-1.535-1.974zM23.517 20.138l-4.5-3.5a1.25 1.25 0 00-1.535 1.974l4.5 3.5a1.25 1.25 0 101.535-1.974z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});