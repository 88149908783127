define("ember-svg-jar/inlined/tools-gasket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-gasket</title><path d=\"M22 2a.5.5 0 00-.243-.429l-2.5-1.5A.628.628 0 0019 0H8.5A6.508 6.508 0 002 6.5v17a.5.5 0 00.5.5h1.75a.5.5 0 00.5-.5v-16A4.756 4.756 0 019.5 2.75h4a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-4A3.254 3.254 0 006.25 7.5v16a.5.5 0 00.5.5H8.5a.5.5 0 00.5-.5v-15A1.5 1.5 0 0110.5 7H19a.6.6 0 00.257-.071l2.5-1.5A.5.5 0 0022 5zm-1.5 2.345a.251.251 0 01-.138.224l-1.75.875a.252.252 0 01-.224 0l-1.75-.875a.251.251 0 01-.138-.224v-1.69a.251.251 0 01.138-.224l1.75-.875a.252.252 0 01.224 0l1.75.875a.251.251 0 01.138.224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});