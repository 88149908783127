define("ember-svg-jar/inlined/single-neutral-static-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-static-3</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M13.891 12.583a1 1 0 001.292-.574l.423-1.1a.25.25 0 01.233-.16h2.322a.25.25 0 01.233.16l.423 1.1a1.012 1.012 0 001.292.574 1 1 0 00.574-1.292l-2.75-7.15a1 1 0 00-1.866 0l-2.75 7.15a1 1 0 00.574 1.292zm3.734-3.673a.249.249 0 01-.233.34h-.784a.249.249 0 01-.233-.34l.392-1.018a.25.25 0 01.466 0z\"/><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M13.891 12.583a1 1 0 001.292-.574l.423-1.1a.25.25 0 01.233-.16h2.322a.25.25 0 01.233.16l.423 1.1a1.012 1.012 0 001.292.574 1 1 0 00.574-1.292l-2.75-7.15a1 1 0 00-1.866 0l-2.75 7.15a1 1 0 00.574 1.292zm3.734-3.673a.249.249 0 01-.233.34h-.784a.249.249 0 01-.233-.34l.392-1.018a.25.25 0 01.466 0z\"/><path d=\"M24 2a2 2 0 00-2-2H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});