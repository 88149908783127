define("ember-svg-jar/inlined/monitor-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-edit</title><path d=\"M11.247 17.92l1.371-1.5a.25.25 0 00-.184-.42H10.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h4.068a.251.251 0 00.177-.073l3.182-3.182a.249.249 0 00.073-.176V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h6.061a.248.248 0 00.186-.08zM2.5 11.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5V11a.5.5 0 01-.5.5zM12.74 20.046a.5.5 0 00-.835.216l-.885 3.1a.5.5 0 00.618.619l3.1-.886a.5.5 0 00.217-.834zM18.772 13.229a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM23.233 11.769a2.658 2.658 0 00-3.75.042.5.5 0 00-.151.354.507.507 0 00.147.357l3 3a.54.54 0 00.377.123.576.576 0 00.377-.169 2.626 2.626 0 000-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});