define("ember-svg-jar/inlined/user-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 8.445c1.19-.693 2-1.968 2-3.445V4a4 4 0 00-8 0v1c0 1.477.81 2.752 2 3.445v.719C2.551 9.72 0 11.671 0 14v1a1 1 0 001 1h14a1 1 0 001-1v-1c0-2.329-2.551-4.28-6-4.836v-.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
});