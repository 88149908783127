define("ember-svg-jar/inlined/famous-people-man-hitler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-hitler</title><path d=\"M16.974 16.785l-2.969 3.143a.25.25 0 01-.41-.07L12.457 17.3a.522.522 0 00-.915 0l-1.068 2.4a.25.25 0 01-.4.075L7.08 16.787a.5.5 0 01-.146-.371v-.056a.248.248 0 00-.089-.206.25.25 0 00-.22-.051 13.88 13.88 0 00-5.161 2.314A2.466 2.466 0 00.5 20.394V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.106a2.464 2.464 0 00-.967-1.977 13.9 13.9 0 00-5.249-2.336.163.163 0 00-.159.048.165.165 0 00-.037.163.5.5 0 01-.114.493zM13.6 11.379a.5.5 0 00-.49-.379h-2.22a.5.5 0 00-.485.379l-.25 1a.5.5 0 00.485.621h2.72a.5.5 0 00.485-.621z\"/><path d=\"M12 16a7.013 7.013 0 007-7V7A7 7 0 005 7v2a7.023 7.023 0 007 7zM7 9V7.185a.25.25 0 01.16-.233 5.262 5.262 0 002.476-1.958.25.25 0 01.392-.035A7.627 7.627 0 0016 7.25h.75a.25.25 0 01.25.25V9A5 5 0 017 9z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});