define("ember-svg-jar/inlined/card-game-symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-symbols</title><path d=\"M4.69 10.587a1 1 0 001.619 0l3.267-4.5a1 1 0 000-1.174L6.309.412a1.04 1.04 0 00-1.618 0l-3.267 4.5a1 1 0 000 1.175zM23.18 18.548a2.846 2.846 0 00.82-2.14 2.814 2.814 0 00-1.035-2.048 2.9 2.9 0 00-3.857.279l-.428.428a.252.252 0 01-.354 0l-.428-.428a2.9 2.9 0 00-3.857-.279A2.814 2.814 0 0013 16.408a2.848 2.848 0 00.819 2.142l3.971 3.971a1 1 0 001.414 0zM6.181 13.031a.969.969 0 00-1.363 0l-4 4A2.845 2.845 0 000 19.17a2.813 2.813 0 001.035 2.049 2.713 2.713 0 001.732.607 2.99 2.99 0 001.366-.333.25.25 0 01.364.223V23a1 1 0 002 0v-1.285a.25.25 0 01.364-.222 2.845 2.845 0 003.1-.274A2.813 2.813 0 0011 19.17a2.847 2.847 0 00-.819-2.141zM21.4 3a.156.156 0 01-.148-.161 2.75 2.75 0 10-5.5 0A.156.156 0 0115.6 3a2.75 2.75 0 00.146 5.5h1.5a.25.25 0 01.25.25v1.5a1 1 0 002 0v-1.5a.25.25 0 01.25-.25h1.5A2.75 2.75 0 0021.4 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});