define("ember-svg-jar/inlined/composition-window-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-man</title><path d=\"M21 2.5H3a3 3 0 00-3 3v13a3 3 0 003 3h18a3 3 0 003-3v-13a3 3 0 00-3-3zm1 16a1 1 0 01-1 1H3a1 1 0 01-1-1v-13a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M12 14a4 4 0 10-4-4 4 4 0 004 4zm0-2a2 2 0 01-1.858-2.739.251.251 0 01.346-.13 5.986 5.986 0 002.726.655c.177 0 .353-.008.526-.024A.24.24 0 0114 10a2 2 0 01-2 2zM12 15a6.593 6.593 0 00-4.876 2.164.5.5 0 00.37.836h9.012a.5.5 0 00.37-.836A6.593 6.593 0 0012 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});