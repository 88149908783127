define("ember-svg-jar/inlined/real-estate-insurance-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-insurance-building-1</title><path d=\"M13 19.5H5.5a.5.5 0 010-1h3A.5.5 0 009 18a2 2 0 00-2-2H1.75a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25H15.5a.5.5 0 00.5-.5 3 3 0 00-3-3zM15.724 6.053l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 009 6.5V14a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V6.5a.5.5 0 00-.276-.447zM14.5 10a.5.5 0 01-.5.5h-3a.5.5 0 010-1h3a.5.5 0 01.5.5zM14 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zM23.692 2.538l-6-2.5A.5.5 0 0017 .5V14a.5.5 0 00.5.5H19a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462zM22 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM22 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm.5-3a.5.5 0 01-.5.5h-3a.5.5 0 010-1h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});