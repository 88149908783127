define("ember-svg-jar/inlined/animal-print-paw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-paw</title><path d=\"M2.545 8.505a.25.25 0 00.246-.221.994.994 0 01.1-.338A1 1 0 001.1 7.055a3.15 3.15 0 00-.325 1.579.252.252 0 00.121.2.249.249 0 00.234.012 3.487 3.487 0 011.415-.341zM8.5 2.01a.249.249 0 00.264-.221 1.013 1.013 0 01.1-.343A1 1 0 107.068.555a3.106 3.106 0 00-.323 1.46.249.249 0 00.34.228A3.415 3.415 0 018.5 2.01zM23.29 7.055a1 1 0 10-1.791.891 1.025 1.025 0 01.1.378.25.25 0 00.218.228 3.7 3.7 0 011.4.475.25.25 0 00.374-.188 3.153 3.153 0 00-.301-1.784zM17.318.554a1 1 0 10-1.79.893.994.994 0 01.1.345.249.249 0 00.23.223 3.652 3.652 0 011.413.408.247.247 0 00.236 0 .252.252 0 00.128-.2 3.135 3.135 0 00-.317-1.669zM17.938 17.079c-.556-.329-1.036-.613-1.151-.96-.727-2.191-1.552-4.674-4.787-4.674s-4.06 2.483-4.787 4.675c-.115.346-.595.63-1.151.959-1.041.617-2.468 1.462-2.468 3.532A3.144 3.144 0 006.969 24a4.5 4.5 0 002.816-1.185A3.556 3.556 0 0112 21.833a3.556 3.556 0 012.215.982A4.5 4.5 0 0017.031 24a3.144 3.144 0 003.375-3.389c0-2.07-1.427-2.911-2.468-3.532z\"/><ellipse cx=\"8.406\" cy=\"6.889\" rx=\"2.656\" ry=\"3.389\"/><ellipse cx=\"2.656\" cy=\"13.389\" rx=\"2.656\" ry=\"3.389\"/><ellipse cx=\"15.594\" cy=\"6.889\" rx=\"2.656\" ry=\"3.389\"/><ellipse cx=\"21.344\" cy=\"13.389\" rx=\"2.656\" ry=\"3.389\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});