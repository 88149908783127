define("ember-svg-jar/inlined/wild-bird-eggs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-eggs</title><path d=\"M15.329 14.71a5.2 5.2 0 01-.251 1.524 3.522 3.522 0 003.422-3.518c0-1.949-1.58-6.471-3.529-6.471-.764 0-1.469.7-2.046 1.658a12.788 12.788 0 012.404 6.807zM10.039 18.5a3.79 3.79 0 003.79-3.79c0-2.093-1.7-6.947-3.79-6.947S6.25 12.617 6.25 14.71a3.789 3.789 0 003.789 3.79zM9.817 2.2a10.193 10.193 0 014.367 0 .98.98 0 00.424-1.912 12.144 12.144 0 00-5.214 0A.98.98 0 109.817 2.2zM2.176 6.915a.98.98 0 001.352-.3 10.136 10.136 0 013.088-3.09.979.979 0 10-1.053-1.651 12.082 12.082 0 00-3.688 3.688.981.981 0 00.301 1.353zM2.2 14.183a10.129 10.129 0 010-4.367.979.979 0 10-1.911-.425 12.081 12.081 0 000 5.215.98.98 0 001.911-.423zM6.614 20.471a10.122 10.122 0 01-3.087-3.088.98.98 0 00-1.652 1.054 12.1 12.1 0 003.688 3.687.979.979 0 101.051-1.653zM14.183 21.8a10.172 10.172 0 01-4.366 0 .98.98 0 10-.425 1.913 12.1 12.1 0 005.215 0 .98.98 0 00-.424-1.913zM21.825 17.085a.981.981 0 00-1.353.3 10.133 10.133 0 01-3.088 3.088.98.98 0 00-.3 1.352 1.014 1.014 0 001.353.3 12.071 12.071 0 003.687-3.687.979.979 0 00-.299-1.353zM23.712 9.393a.98.98 0 10-1.913.423 10.13 10.13 0 010 4.367.979.979 0 00.744 1.169.989.989 0 001.168-.744 12.081 12.081 0 00.001-5.215zM17.385 3.526a10.138 10.138 0 013.088 3.089.979.979 0 101.652-1.052 12.072 12.072 0 00-3.687-3.688.979.979 0 10-1.053 1.651z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});