define("ember-svg-jar/inlined/navigation-smartphone-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-smartphone-hand</title><path d=\"M20.485 0h-10a3 3 0 00-3 3v5.6a.25.25 0 01-.13.219c-1.71.94-4.855 2.932-4.855 4.856v4.646a.25.25 0 01-.046.145L.7 20.917a1 1 0 00.233 1.395 1 1 0 001.394-.233L4.313 19.3a1 1 0 00.186-.581v-5.025a.242.242 0 010-.047c.087-.453 1.2-1.42 2.6-2.334a.251.251 0 01.387.209v2.149a.253.253 0 01-.073.177l-.683.683a.75.75 0 000 1.061.783.783 0 001.06 0l2.195-2.2a.518.518 0 01.715 0 .5.5 0 01.149.358.552.552 0 01-.186.4l-.01.013L7.4 18.007a.754.754 0 00-.177.485v1.823a3.6 3.6 0 01-.852 1.593 9.931 9.931 0 01-.708.816.75.75 0 001.067 1.053 11.021 11.021 0 00.914-1.059.25.25 0 01.4.007A2.977 2.977 0 0010.486 24h10a3 3 0 003-3V3a3 3 0 00-3.001-3zm-4.75 22.606a1 1 0 111-1 1 1 0 01-1 1zM21.485 18a1 1 0 01-1 1h-10a1 1 0 01-1-1v-.043a.25.25 0 01.059-.161l2.209-2.614.014-.014a2.005 2.005 0 00-.007-2.83 2.038 2.038 0 00-1.96-.491.25.25 0 01-.315-.241V3.5a1 1 0 011-1h10a1 1 0 011 1z\"/><circle cx=\"12.999\" cy=\"6\" r=\"1\"/><circle cx=\"12.999\" cy=\"10.999\" r=\"1\"/><circle cx=\"12.999\" cy=\"15.999\" r=\"1\"/><path d=\"M19 5.25h-3.265a.75.75 0 100 1.5H19a.75.75 0 100-1.5zM19 10.249h-3.265a.75.75 0 000 1.5H19a.75.75 0 000-1.5zM19 15.249h-3.265a.75.75 0 100 1.5H19a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});