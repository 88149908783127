define("ember-svg-jar/inlined/multiple-actions-share-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-share-1</title><path d=\"M17.989 8.6a5.95 5.95 0 00-2.465.534.5.5 0 00-.182.771A7.414 7.414 0 0117 14.1v.025a.5.5 0 00.5.473h6.127A.376.376 0 0024 14.2a6.014 6.014 0 00-6.011-5.6z\"/><ellipse cx=\"17.513\" cy=\"4.097\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M15.5 14.2a6.025 6.025 0 00-12-.27.6.6 0 00.6.667h11.023a.376.376 0 00.376-.4z\"/><ellipse cx=\"9.013\" cy=\"4.097\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M2 22.4v-5.61a1 1 0 00-2 0v5.61a1 1 0 002 0zM12.5 19.6h-7a.355.355 0 01-.323-.388.371.371 0 01.371-.37H7.5a.887.887 0 00.819-1.242 1.905 1.905 0 00-1.814-1l-3-.033a.557.557 0 00-.357.145.5.5 0 00-.148.352V22.1a.5.5 0 00.5.5h8a2.041 2.041 0 002-1.925v-.2a.97.97 0 00-1-.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});