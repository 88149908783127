define("ember-svg-jar/inlined/easter-egg-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>easter-egg-ribbon</title><path d=\"M23.65 17.612a1.827 1.827 0 00.07-2.034l-2.414-3.907a.5.5 0 01.149-.679 3.613 3.613 0 001.487-3.946l-.79-2.933a3.609 3.609 0 00-5.839-1.8.5.5 0 01-.662-.008A8.4 8.4 0 009.995 0C4.162 0 .225 6.185.005 12.577a.5.5 0 00.7.476l4.876-2.107a.5.5 0 01.681.329l.067.248a3.6 3.6 0 00.227.616.5.5 0 01-.254.674L.53 15.307a.5.5 0 00-.293.551A9.412 9.412 0 009.995 24a9.349 9.349 0 008.311-4.318.5.5 0 01.677-.167 1.833 1.833 0 002.719-1.2 1.853 1.853 0 001.948-.703zM4.245 9.25a1 1 0 111-1 1 1 0 01-1 1zM5.5 20a1 1 0 111-1 1 1 0 01-1 1zM17.059 4.125a1.857 1.857 0 013.4.443l.79 2.933A1.857 1.857 0 0118.533 9.6l-.771-.444a.5.5 0 01-.246-.5 3.183 3.183 0 00-.082-1.256 3.176 3.176 0 00-.928-1.524.5.5 0 01-.1-.618zM8.883 12.2a1.851 1.851 0 01-.867-1.127l-.791-2.937a1.858 1.858 0 012.721-2.094l1.13.651a.5.5 0 01.227.583 3.194 3.194 0 00.521 2.91.5.5 0 01.038.556l-.443.77a1.873 1.873 0 01-1.126.867 1.85 1.85 0 01-1.41-.179zm5.051 7.246a.25.25 0 01-.432.164l-.351-.371a.873.873 0 00-1.238-.033l-.37.351a.25.25 0 01-.422-.188l.139-5.179a.5.5 0 01.262-.427 3.628 3.628 0 001.415-1.374l.432-.75a.5.5 0 01.508-.245.313.313 0 01.266.318zm1.811-11.592a1.444 1.444 0 11-1.771-1.019 1.445 1.445 0 011.771 1.019zm6.275 8.3a.251.251 0 01-.01.278.248.248 0 01-.26.1l-.5-.117a.87.87 0 00-1.052.651l-.118.5a.25.25 0 01-.456.074L15.9 11.612a.5.5 0 01.157-.685 3.089 3.089 0 00.286-.205.5.5 0 01.562-.043l.752.432a3.6 3.6 0 001.306.449.5.5 0 01.357.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});