define("ember-svg-jar/inlined/playlist-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-settings</title><path d=\"M10.882 16.611a3.283 3.283 0 013.139-5.434l.228.053c.128.03.3-.039.3-.171a2.606 2.606 0 011.771-2.426.25.25 0 00.18-.24V2.5A2.5 2.5 0 0014 0H2.5A2.5 2.5 0 000 2.5v12A2.5 2.5 0 002.5 17h8.671a.033.033 0 00.023-.01.032.032 0 000-.043zM11.5 8a1 1 0 01-1-1 1.77 1.77 0 00-1.151-1.729A.25.25 0 009 5.5v5.552A2.5 2.5 0 116.5 8.5c.077 0 .152 0 .227.01A.249.249 0 007 8.261V4a1 1 0 011-1c1.557 0 4.5.836 4.5 4a1 1 0 01-1 1z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.41.327a.452.452 0 01-.534-.311l-.424-1.392a1.774 1.774 0 00-3.4 0l-.424 1.391a.456.456 0 01-.533.312l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.41-.327a.451.451 0 01.534.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.464.464 0 010-.626zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});