define("ember-svg-jar/inlined/currency-pound-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-pound-increase</title><path d=\"M7.486 0a7 7 0 107 7 7.008 7.008 0 00-7-7zm1.452 6.216a.75.75 0 010 1.5H7.76a.25.25 0 00-.25.25v1.178a3.35 3.35 0 01-.21 1.094.25.25 0 00.235.334h2.128a.75.75 0 010 1.5H5.308a.75.75 0 01-.043-1.5.24.24 0 00.142-.054 2.21 2.21 0 00.6-1.375V7.966a.25.25 0 00-.25-.25H4.581a.75.75 0 010-1.5H5.76a.25.25 0 00.25-.25v-1.9a2.2 2.2 0 114.4 0 .75.75 0 11-1.5 0 .7.7 0 10-1.4 0v1.9a.25.25 0 00.25.25zM.96 21.815a1.25 1.25 0 001.66 1.87l7.155-6.36a.25.25 0 01.322-.009L13 19.64a2.163 2.163 0 002.8-.1l5.047-4.653a.249.249 0 01.346.007l1.462 1.462a.5.5 0 00.353.146.513.513 0 00.192-.038.5.5 0 00.314-.464v-5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.353.854l1.415 1.414a.25.25 0 01-.008.361l-4.582 4.225a.25.25 0 01-.326.012l-2.9-2.317a2.152 2.152 0 00-2.771.071z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});