define("ember-svg-jar/inlined/instrument-contrabass-sheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-contrabass-sheet</title><path d=\"M23.979 9.748A1.5 1.5 0 0022.5 8h-9.639a1.5 1.5 0 00-1.48 1.747l.666 4A1.5 1.5 0 0013.527 15h1.9a.25.25 0 00.25-.25V12.5a2 2 0 014 0v2.25a.25.25 0 00.25.25h1.9a1.5 1.5 0 001.479-1.253z\"/><path d=\"M18.68 12.5a1 1 0 00-2 0v6.483a.247.247 0 01-.073.176l-3.134 3.134a1 1 0 101.414 1.414l1.367-1.366a.25.25 0 01.426.177V23a1 1 0 002 0v-.482a.25.25 0 01.427-.177l1.366 1.366a1 1 0 001.414-1.414l-3.133-3.134a.247.247 0 01-.074-.176zM11 17.226a1 1 0 00-.7-.963 1.932 1.932 0 01-1.277-1.59 1.9 1.9 0 01.737-1.895.5.5 0 00.206-.349 4.381 4.381 0 00-1.081-3.418 4.51 4.51 0 00-2.2-1.354.251.251 0 01-.185-.242V4.25A.25.25 0 016.75 4H7a1 1 0 000-2h-.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H4a1 1 0 000 2h.25a.25.25 0 01.25.25v3.165a.251.251 0 01-.185.242 4.51 4.51 0 00-2.2 1.355 4.379 4.379 0 00-1.082 3.417.5.5 0 00.206.349 1.9 1.9 0 01.736 1.894A1.934 1.934 0 01.7 16.263a1 1 0 00-.7.963 5.334 5.334 0 003.145 4.757.25.25 0 00.355-.226V21a2 2 0 014 0v.757a.25.25 0 00.355.226A5.342 5.342 0 0011 17.226z\"/><path d=\"M6.5 21a1 1 0 00-2 0v2a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});