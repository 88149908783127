define("ember-svg-jar/inlined/paragraph-justified-align", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-justified-align</title><path d=\"M14.863 23.271H1.5a1.25 1.25 0 010-2.5h13.363a1.25 1.25 0 010 2.5zM22.5 3.225h-21a1.25 1.25 0 010-2.5h21a1.25 1.25 0 010 2.5zM22.5 9.907h-21a1.25 1.25 0 010-2.5h21a1.25 1.25 0 010 2.5zM22.5 16.588h-21a1.25 1.25 0 010-2.5h21a1.25 1.25 0 110 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});