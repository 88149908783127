define("ember-svg-jar/inlined/concert-rock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-rock-1</title><path d=\"M19.021 2.5a1.5 1.5 0 00-1.5 1.5v3a1 1 0 01-1 1h-4.5a1 1 0 01-1-1V1.5a1.5 1.5 0 00-3 0v7.264a.5.5 0 01-.86.347L6.145 8.06a1.551 1.551 0 00-2.374 1.983l5.45 7.068a1 1 0 00.791.389h8.053a1 1 0 00.784-.378 7.619 7.619 0 001.672-4.622V4a1.5 1.5 0 00-1.5-1.5zM18.021 19h-9a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-2a1.5 1.5 0 00-1.5-1.5zm-7.75 3.25a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.25 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.25 0a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});