define("ember-svg-jar/inlined/single-man-actions-laptop.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-laptop</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.959 6.959 0 008.5 5a6.36 6.36 0 00.929-.07.5.5 0 01.571.564A3 3 0 014.044 5a2.841 2.841 0 01.1-.743zM10.457 14a2.962 2.962 0 01.6-1.783.5.5 0 00-.153-.735A7.383 7.383 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h9.413a.5.5 0 00.5-.5zM23.833 22.6l-1.786-2.868a.49.49 0 00-.09-.086V14a1.5 1.5 0 00-1.5-1.5h-7a1.5 1.5 0 00-1.5 1.5v5.649a.5.5 0 00-.091.086l-1.8 2.9a.921.921 0 00.044.928.957.957 0 00.809.436H23a.959.959 0 00.81-.436.925.925 0 00.044-.928.203.203 0 01-.021-.035zm-9.876-7.85a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25zm1.515 7.63a.243.243 0 010-.238l.272-.476a.232.232 0 01.2-.119H17.8a.232.232 0 01.2.119l.272.476a.243.243 0 010 .238.231.231 0 01-.2.12h-2.4a.232.232 0 01-.2-.12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});