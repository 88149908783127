define("ember-svg-jar/inlined/e-commerce-shopping-bag-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-shopping-bag-laptop</title><path d=\"M13.83 2.575a.25.25 0 00.245.3h2.05a.751.751 0 01.75.75v6.369a.249.249 0 00.309.243 4 4 0 01.941-.112.249.249 0 00.25-.25v-6.25a2.253 2.253 0 00-2.25-2.25h-2.05a.25.25 0 00-.245.3 2.272 2.272 0 010 .9zM12 15.809a3.149 3.149 0 012.091-2.509.249.249 0 00.161-.174 4 4 0 01.481-1.113.25.25 0 00-.212-.383H3.875a.5.5 0 01-.5-.5v-7.5a.751.751 0 01.75-.75h1.718a.249.249 0 00.244-.195 3.559 3.559 0 01.353-.935.249.249 0 00-.219-.37h-2.1a2.253 2.253 0 00-2.25 2.25v8.53a.255.255 0 01-.054.17L.478 14.009a1.61 1.61 0 001.257 2.616h9.921a.249.249 0 00.247-.212zm-2.875-1.684a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM23.875 21.731a2.223 2.223 0 00-.025-.325L23.019 16a1.907 1.907 0 00-1.894-1.624.25.25 0 01-.25-.25 2.75 2.75 0 00-5.5 0 .25.25 0 01-.25.25A1.907 1.907 0 0013.231 16l-.831 5.406a2.223 2.223 0 00-.025.325 2.147 2.147 0 002.144 2.144h7.212a2.147 2.147 0 002.144-2.144zm-5.75-8.856a1.252 1.252 0 011.25 1.25.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25 1.252 1.252 0 011.25-1.25z\"/><path d=\"M7.5 3.467a2.084 2.084 0 001.315 1.942l2.063.825a.592.592 0 01-.1 1.129.579.579 0 01-.119.012H8.625a.75.75 0 100 1.5h.5a.25.25 0 01.25.25v.25a.75.75 0 101.5 0v-.3a.25.25 0 01.2-.245 2.092 2.092 0 00.36-3.992l-2.063-.822A.588.588 0 019 3.467a.594.594 0 01.31-.521l.052-.025a.651.651 0 01.111-.034.579.579 0 01.119-.012h2.033a.75.75 0 100-1.5h-.5a.25.25 0 01-.25-.25v-.25a.75.75 0 10-1.5 0v.3a.25.25 0 01-.2.245A2.1 2.1 0 007.5 3.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});