define("ember-svg-jar/inlined/modern-tv-3d-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-3d-glasses</title><path d=\"M19.626 16.552a.251.251 0 00-.376.216v3.464a.251.251 0 00.376.216 2.25 2.25 0 000-3.9z\"/><path d=\"M24 14.5a1.5 1.5 0 00-1.5-1.5h-11a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h11a1.5 1.5 0 001.5-1.5zm-5.5 7.75a.75.75 0 01-.75-.75v-6a.75.75 0 01.75-.75 3.75 3.75 0 010 7.5zm-3.5-1.5a.75.75 0 000-1.5.75.75 0 010-1.5.75.75 0 000-1.5h-2.5a.75.75 0 010-1.5H15a2.249 2.249 0 011.8 3.6.251.251 0 000 .3 2.249 2.249 0 01-1.8 3.6h-2.5a.75.75 0 010-1.5z\"/><path d=\"M9.639 12.479l.171-.456.276-.735a.461.461 0 01.826 0l.026.067a.249.249 0 00.259.16 3 3 0 01.3-.015h8.651a.25.25 0 00.248-.214c.61-4.274.6-4.165.6-4.3a.992.992 0 00-.214-.606L16.9 1.2A3.016 3.016 0 0014.5 0H13a1 1 0 000 2h1.5a1 1 0 01.8.4l2.4 3.2a.25.25 0 01-.2.4h-14a.25.25 0 01-.2-.4l2.4-3.2a1 1 0 01.8-.4H8a1 1 0 000-2H6.5a3.01 3.01 0 00-2.4 1.2L.214 6.382A1 1 0 000 6.988c0 .124-.038-.185.727 5.173A3.323 3.323 0 004 15h2a3.9 3.9 0 003.639-2.521z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});