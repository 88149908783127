define("ember-svg-jar/inlined/medical-app-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-laptop</title><path d=\"M16 6.5a.5.5 0 00-.5-.5H14a.5.5 0 01-.5-.5V4a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5H8.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H10a.5.5 0 01.5.5V11a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V9.5A.5.5 0 0114 9h1.5a.5.5 0 00.5-.5z\"/><path d=\"M23.294 20.932l-2.1-6.374a1.349 1.349 0 00-.2-.393.249.249 0 010-.292 2.626 2.626 0 00.505-1.54V2.667A2.67 2.67 0 0018.831 0H5.164A2.669 2.669 0 002.5 2.667v9.666a2.624 2.624 0 00.5 1.539.249.249 0 010 .292 1.31 1.31 0 00-.2.385L.729 20.864A2.166 2.166 0 002.667 24h18.662a2.153 2.153 0 001.843-1.027 2.106 2.106 0 00.122-2.041zm-7.789.465a.5.5 0 01-.406.208H8.9a.5.5 0 01-.474-.658l.6-1.8A.5.5 0 019.5 18.8h5a.5.5 0 01.474.342l.6 1.8a.5.5 0 01-.069.458zm3.995-9.064a.667.667 0 01-.667.667H5.164a.667.667 0 01-.666-.667V2.667A.667.667 0 015.164 2h13.667a.667.667 0 01.667.667z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});