define("ember-svg-jar/inlined/agriculture-machine-harraw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-harraw</title><path d=\"M3.5 14h-3a.5.5 0 00-.5.5v3.626a4.486 4.486 0 001.619 3.457l1.561 1.3A.5.5 0 003.5 23a.511.511 0 00.212-.047A.5.5 0 004 22.5v-8a.5.5 0 00-.5-.5zM10 14H7a.5.5 0 00-.5.5v3.626a4.486 4.486 0 001.619 3.457l1.561 1.3A.5.5 0 0010 23a.511.511 0 00.212-.047.5.5 0 00.288-.453v-8a.5.5 0 00-.5-.5zM23.77 23.08l-6.541-4.2a.5.5 0 01-.229-.426V14.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v3.626a4.486 4.486 0 001.619 3.457l1.555 1.3A5.678 5.678 0 0020 24h3.5a.5.5 0 00.27-.92zM22.5 9.5H20a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5v-1a1.5 1.5 0 00-1.5-1.5zM.5 12.5h17a.5.5 0 00.5-.5v-2a1 1 0 00-.232-.637l-5.152-6.186a.5.5 0 01-.116-.32V1a1 1 0 00-1-1h-1a1 1 0 00-1 1v1.875a.5.5 0 01-.2.4L.84 9.62A1.62 1.62 0 000 11v1a.5.5 0 00.5.5zm12-5.648a.25.25 0 01.442-.16l2 2.4a.25.25 0 01-.192.41H13a.5.5 0 01-.5-.5zM4.933 9.05L9.1 5.925a.25.25 0 01.4.2V9a.5.5 0 01-.5.5H5.083a.25.25 0 01-.15-.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});