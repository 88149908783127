define("ember-svg-jar/inlined/messages-bubble-square-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-quote</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.553A1.459 1.459 0 002 18h3v4a.5.5 0 00.784.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zm-8 4.75a.75.75 0 010-1.5c1.822 0 3.25 1.867 3.25 4.25s-1.428 4.25-3.25 4.25a.75.75 0 010-1.5c.948 0 1.75-1.259 1.75-2.75S14.948 6.25 14 6.25zm-4.5 6a1.75 1.75 0 111.75-1.75v2a1.752 1.752 0 01-1.75 1.75.75.75 0 010-1.5.253.253 0 00.25-.25v-.276a1.581 1.581 0 01-.25.026zM11.25 7a.75.75 0 01-1.5 0V4.5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});