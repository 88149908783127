define("ember-svg-jar/inlined/road-sign-66-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-66-1</title><circle cx=\"8.25\" cy=\"15\" r=\"1.75\"/><circle cx=\"16\" cy=\"15\" r=\"1.75\"/><path d=\"M22.25 7.25H1.75a.25.25 0 00-.25.25v3.911a12.862 12.862 0 008.147 11.935l1.068.414a3.56 3.56 0 002.57 0l1.068-.414A12.862 12.862 0 0022.5 11.411V7.5a.25.25 0 00-.25-.25zm-14 11A3.254 3.254 0 015 15v-2.5a3.754 3.754 0 013.75-3.75h.5a.75.75 0 010 1.5h-.5a2.239 2.239 0 00-2.088 1.44.25.25 0 00.332.319 3.188 3.188 0 011.256-.259 3.25 3.25 0 010 6.5zm7.75 0A3.254 3.254 0 0112.75 15v-2.5a3.754 3.754 0 013.75-3.75h.5a.75.75 0 010 1.5h-.5a2.239 2.239 0 00-2.088 1.44.25.25 0 00.332.319A3.188 3.188 0 0116 11.75a3.25 3.25 0 010 6.5zM21.771 1.753a1.506 1.506 0 00-1.478-.038l-2.064 1.1a.5.5 0 01-.467 0L12.7.17a1.5 1.5 0 00-1.39 0L6.236 2.82a.5.5 0 01-.466 0l-2.063-1.1A1.5 1.5 0 001.5 3.039V5.5a.25.25 0 00.25.25h20.5a.25.25 0 00.25-.25V3.039a1.505 1.505 0 00-.729-1.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});