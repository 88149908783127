define("ember-svg-jar/inlined/baby-girl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-girl</title><path d=\"M7.869 6.869a2.585 2.585 0 001.918-.893.5.5 0 00.08-.6 2.448 2.448 0 01.552-3.081.5.5 0 00.032-.736L9.619.726a2.474 2.474 0 00-4.225 1.75v1.917a2.478 2.478 0 002.475 2.476zM14.452 4.167a2.426 2.426 0 01-.32 1.208.5.5 0 00.081.6 2.582 2.582 0 001.918.893 2.478 2.478 0 002.475-2.476V2.476a2.474 2.474 0 00-4.225-1.75l-.832.832a.5.5 0 00.031.736 2.444 2.444 0 01.872 1.873zM12 5.619a1.453 1.453 0 10-1.452-1.452A1.454 1.454 0 0012 5.619z\"/><path d=\"M22.566 10.926a1 1 0 01-.38-.571A11.517 11.517 0 0020.8 7.008a1 1 0 10-1.7 1.052 9.483 9.483 0 011.293 3.5 1 1 0 00.637.783 1.5 1.5 0 01-.228 2.876 1 1 0 00-.755.672A8.46 8.46 0 0112 22a8.46 8.46 0 01-8.042-6.107 1 1 0 00-.755-.672 1.5 1.5 0 01-.228-2.876 1 1 0 00.637-.783 9.483 9.483 0 011.293-3.5A1 1 0 103.2 7.008a11.49 11.49 0 00-1.39 3.347 1 1 0 01-.38.571 3.5 3.5 0 00.473 5.941 1 1 0 01.467.506A10.5 10.5 0 0012 24a10.5 10.5 0 009.626-6.627 1 1 0 01.467-.506 3.5 3.5 0 00.473-5.941z\"/><path d=\"M10.555 12.737a1.022 1.022 0 00-.993-1.032.974.974 0 00-.966.733.527.527 0 01-1.017.019 1 1 0 00-1.96.28 2.281 2.281 0 002.468 2.1 2.281 2.281 0 002.468-2.1zM18.381 12.737a1 1 0 00-1.96-.28.527.527 0 01-1.017-.019.975.975 0 00-.966-.733 1.022 1.022 0 00-.993 1.032 2.281 2.281 0 002.468 2.1 2.281 2.281 0 002.468-2.1zM12 20.1a3.6 3.6 0 003.156-1.468 1 1 0 00-1.72-1.02A1.852 1.852 0 0112 18.1a1.852 1.852 0 01-1.436-.488 1 1 0 10-1.72 1.02A3.6 3.6 0 0012 20.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});