define("ember-svg-jar/inlined/layout-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-content</title><rect x=\".5\" y=\".997\" width=\"10\" height=\"10\" rx=\"2\" ry=\"2\"/><rect x=\".5\" y=\"12.997\" width=\"10\" height=\"10\" rx=\"2\" ry=\"2\"/><path d=\"M13.747 3h9a.75.75 0 000-1.5h-9a.75.75 0 000 1.5zM22.75 4.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM22.75 7.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM22.75 14.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM22.75 17.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5zM22.75 20.5h-9a.75.75 0 000 1.5h9a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});