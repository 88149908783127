define("ember-svg-jar/inlined/plane-take-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-take-off</title><path d=\"M6.088 6.323l3.9 1.614a.5.5 0 00.391 0l4.152-1.807a.25.25 0 000-.46L9.243 3.485a1.006 1.006 0 00-.766 0l-2.388.991a1 1 0 000 1.847z\"/><path d=\"M23.587 6.052A2.5 2.5 0 0020.321 4.7h-.008L7.074 10.462a.5.5 0 01-.423-.012L4.275 9.262a1.012 1.012 0 00-.841-.024L.828 10.355a1 1 0 00-.315 1.627l3.745 3.745a1 1 0 001.078.221l5.617-2.248a.249.249 0 01.327.319l-1.956 5.219a1 1 0 001.383 1.246l2.71-1.355a1 1 0 00.45-.448l3.749-7.5a.5.5 0 01.273-.245l4.345-1.619a2.5 2.5 0 001.353-3.266z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});