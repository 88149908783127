define("ember-svg-jar/inlined/multiple-actions-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-block</title><circle cx=\"14.501\" cy=\"3.5\" r=\"3\"/><path d=\"M11.934 11.261a.248.248 0 00.172.146.254.254 0 00.22-.053 7.987 7.987 0 015.385-1.909.25.25 0 00.189-.415 4.552 4.552 0 00-7 .23.25.25 0 00.005.311 7.015 7.015 0 011.029 1.69zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.691 18h.579a.253.253 0 00.18-.076.249.249 0 00.07-.183 8 8 0 011.32-4.706.252.252 0 00.036-.191A5.5 5.5 0 000 14z\"/><circle cx=\"5.501\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.17 19a6.508 6.508 0 0010.93 3.1 6.536 6.536 0 001.734-6.1A6.421 6.421 0 0019 11.171 6.512 6.512 0 0011.17 19zm8.443-5.474a.246.246 0 01.129.183.249.249 0 01-.071.213l-5.752 5.752a.25.25 0 01-.4-.058 4.507 4.507 0 016.091-6.09zm-4.222 7.96a.255.255 0 01-.129-.184.252.252 0 01.071-.213l5.752-5.752a.252.252 0 01.213-.071.246.246 0 01.184.129 4.507 4.507 0 01-6.091 6.091z\"/><circle cx=\"14.501\" cy=\"3.5\" r=\"3\"/><path d=\"M11.934 11.261a.248.248 0 00.172.146.254.254 0 00.22-.053 7.987 7.987 0 015.385-1.909.25.25 0 00.189-.415 4.552 4.552 0 00-7 .23.25.25 0 00.005.311 7.015 7.015 0 011.029 1.69zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.691 18h.579a.253.253 0 00.18-.076.249.249 0 00.07-.183 8 8 0 011.32-4.706.252.252 0 00.036-.191A5.5 5.5 0 000 14z\"/><circle cx=\"5.501\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.17 19a6.508 6.508 0 0010.93 3.1 6.536 6.536 0 001.734-6.1A6.421 6.421 0 0019 11.171 6.512 6.512 0 0011.17 19zm8.443-5.474a.246.246 0 01.129.183.249.249 0 01-.071.213l-5.752 5.752a.25.25 0 01-.4-.058 4.507 4.507 0 016.091-6.09zm-4.222 7.96a.255.255 0 01-.129-.184.252.252 0 01.071-.213l5.752-5.752a.252.252 0 01.213-.071.246.246 0 01.184.129 4.507 4.507 0 01-6.091 6.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});