define("ember-svg-jar/inlined/smiley-mad-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-mad-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8.447 7.9l-3 1.5A.989.989 0 015 9.5a1 1 0 01-.448-1.895l3-1.5A1 1 0 118.447 7.9zM9 11.5A1.5 1.5 0 117.5 10 1.5 1.5 0 019 11.5zm1 6a2 2 0 014 0v1a2 2 0 01-4 0zm5-6a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm3.553-2.1l-3-1.5a1 1 0 11.894-1.79l3 1.5A1 1 0 0119 9.5a.989.989 0 01-.447-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});