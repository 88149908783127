define("ember-svg-jar/inlined/ui-webpage-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-webpage-check</title><path d=\"M22.75 2a2 2 0 00-2-2H3.25a2 2 0 00-2 2v17.475a2.015 2.015 0 002 2.025h2a1 1 0 000-2l-1.5-.019a.5.5 0 01-.494-.5V2.5a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5V19a.5.5 0 01-.5.5h-1a1 1 0 000 2h1.5a2.015 2.015 0 002-2.025z\"/><path d=\"M18.25 7a1 1 0 000-2H6.073a1 1 0 000 2zM6.25 11.5h7.5a1 1 0 000-2h-7.5a1 1 0 000 2zM12.25 15a4.5 4.5 0 104.5 4.5 4.505 4.505 0 00-4.5-4.5zm2.232 2.737a.75.75 0 01.15 1.05L12.7 21.369a1.249 1.249 0 01-.908.5h-.087a1.246 1.246 0 01-.89-.369l-1-1a.75.75 0 011.06-1.06l.594.593a.251.251 0 00.377-.027l1.591-2.121a.746.746 0 011.045-.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});