define("ember-svg-jar/inlined/seasoning-pepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seasoning-pepper</title><path d=\"M21 15h-6a2.151 2.151 0 00-2 2v6a1 1 0 001 1h8a1 1 0 001-1v-6a2.151 2.151 0 00-2-2zM15.5 13.5h5a.5.5 0 00.5-.5V9.9a.5.5 0 01.315-.464A1.09 1.09 0 0022 8.5V8a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v.5a1 1 0 00.667.943.5.5 0 01.333.472V13a.5.5 0 00.5.5zM5.5 13C.835 13 .112 20.214 0 22.425A1.5 1.5 0 001.505 24h7.989A1.5 1.5 0 0011 22.426C10.888 20.214 10.165 13 5.5 13zm-1.25 4a.75.75 0 11.75-.75.75.75 0 01-.75.75zm2.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zM22.5 3h-3a.5.5 0 01-.5-.5.626.626 0 01.385-.577A1 1 0 0019 0h-2a1 1 0 00-.385 1.923A.627.627 0 0117 2.5a.5.5 0 01-.5.5h-3a1.5 1.5 0 000 3h9a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});