define("ember-svg-jar/inlined/people-man-cap-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-cap-3</title><path d=\"M12.955 12.56a1.238 1.238 0 01-.834.285h-.283a1.238 1.238 0 01-.838-.286.75.75 0 10-1.047 1.073 2.7 2.7 0 001.882.713h.283A2.693 2.693 0 0014 13.631a.75.75 0 10-1.049-1.071zM10.355 10.228A.75.75 0 0011 10.6a.739.739 0 00.382-.105.751.751 0 00.262-1.028A1.821 1.821 0 0010 8.6a1.821 1.821 0 00-1.645.867.75.75 0 001.29.766c.022-.037.079-.133.355-.133s.333.091.355.128zM14 8.6a1.821 1.821 0 00-1.645.867.75.75 0 101.29.766c.022-.037.079-.133.355-.133s.333.1.355.133A.75.75 0 0015 10.6a.739.739 0 00.382-.105.751.751 0 00.262-1.028A1.821 1.821 0 0014 8.6z\"/><path d=\"M6.189 12.447a.253.253 0 01.145.187c.511 3.048 4.021 4.991 5.687 4.991s5.175-1.943 5.687-4.992a.247.247 0 01.143-.186 2.731 2.731 0 001.439-2.433 2.113 2.113 0 00-.464-1.444c.048-.338.078-.664.1-.982a.249.249 0 01.249-.233H23a.75.75 0 000-1.5h-3.849a.249.249 0 01-.248-.223 6.2 6.2 0 00-1.353-3.416C16.374.828 14.514.125 12.021.125S7.666.829 6.49 2.216C5.256 3.674 4.827 5.811 5.215 8.57a2.116 2.116 0 00-.465 1.443 2.732 2.732 0 001.439 2.434zm11.042-1.366a1.744 1.744 0 00-1 1.3c-.378 2.249-3.176 3.739-4.208 3.739s-3.831-1.49-4.209-3.74a1.747 1.747 0 00-1-1.3 1.281 1.281 0 01-.559-1.1.615.615 0 01.105-.438.753.753 0 00.406-.794 12.433 12.433 0 01-.141-1.103.252.252 0 01.249-.27h10.292a.252.252 0 01.25.27c-.029.354-.071.721-.136 1.107a.754.754 0 00.4.794.618.618 0 01.105.439 1.275 1.275 0 01-.554 1.096z\"/><path d=\"M21.08 18.662l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.662A4.519 4.519 0 000 22.875v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.5a4.519 4.519 0 00-2.92-4.213z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});