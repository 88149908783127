define("ember-svg-jar/inlined/road-straight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-straight</title><path d=\"M23.716 23.027l-7-22.5A.749.749 0 0016 0h-2.75a.25.25 0 00-.25.25V1.5a1 1 0 01-2 0V.25a.25.25 0 00-.25-.25H8a.749.749 0 00-.716.527l-7 22.5A.75.75 0 001 24h9.75a.25.25 0 00.25-.25V20a1 1 0 012 0v3.75a.25.25 0 00.25.25H23a.75.75 0 00.716-.973zM12 4.25a1 1 0 011 1V7.5a1 1 0 01-2 0V5.25a1 1 0 011-1zm0 12.25a1 1 0 01-1-1V12a1 1 0 012 0v3.5a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});