define("ember-svg-jar/inlined/road-sign-no-parking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-parking</title><path d=\"M12.177 14.3a.252.252 0 00-.354 0l-6.967 6.965a.251.251 0 00.029.378 11.917 11.917 0 0014.23 0 .25.25 0 00.1-.182.254.254 0 00-.072-.2zM9.7 11.823L2.735 4.856a.251.251 0 00-.378.029 11.919 11.919 0 000 14.231.251.251 0 00.378.028L9.7 12.177a.25.25 0 000-.354zM11.823 9.7a.25.25 0 00.354 0l6.967-6.967a.251.251 0 00-.029-.378 11.919 11.919 0 00-14.231 0 .251.251 0 00-.1.183.245.245 0 00.072.195zM21.643 4.884a.251.251 0 00-.378-.028L14.3 11.823a.25.25 0 000 .354l6.967 6.967a.249.249 0 00.2.073.256.256 0 00.183-.1 11.92 11.92 0 000-14.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});