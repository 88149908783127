define("ember-svg-jar/inlined/real-estate-message-buy-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-buy-building</title><path d=\"M6.724 15.553l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 000 16v7.5a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1A.5.5 0 013 22h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V16a.5.5 0 00-.276-.447zM5 20H2a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.266 14.016l-1.323.7a2.708 2.708 0 01-3.66-3.658l.3-.559a.5.5 0 00-.25-.7l-.637-.265A.5.5 0 008 10v13.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-9.042a.5.5 0 00-.734-.442zM13 20h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/><path d=\"M17.5 0a6.506 6.506 0 00-6.082 8.8.5.5 0 01-.025.411l-1.226 2.314a1.711 1.711 0 001.507 2.508 1.721 1.721 0 00.8-.2l2.313-1.225a.5.5 0 01.412-.025A6.44 6.44 0 0017.5 13a6.5 6.5 0 000-13zm0 11.5a4.95 4.95 0 01-2.2-.509.755.755 0 00-.682.01l-2.843 1.506a.208.208 0 01-.281-.281L13 9.383a.75.75 0 00.01-.681A5 5 0 1117.5 11.5z\"/><path d=\"M16.654 5h2.6a.75.75 0 000-1.5H18.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v.15a.1.1 0 01-.1.1A1.655 1.655 0 0016 6.675l2.405 1.03a.154.154 0 01-.059.295h-2.6a.75.75 0 000 1.5h.75a.25.25 0 01.25.25.75.75 0 001.5 0V9.6a.1.1 0 01.1-.1A1.654 1.654 0 0019 6.326L16.593 5.3a.156.156 0 01-.093-.142.154.154 0 01.154-.158z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});