define("ember-svg-jar/inlined/jellyfish-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>jellyfish-group</title><path d=\"M9.31 1.426C7.1-.3 4.419-.43 3.215 1.118a2.787 2.787 0 00-.533 1.9.5.5 0 00.192.366l.708.551a4.2 4.2 0 00-1.135 1.019 4.1 4.1 0 00-.8 1.706.75.75 0 001.47.295A2.737 2.737 0 014.9 4.966l.437.34L3.5 7.673a.75.75 0 001.181.927l1.843-2.373.434.337a2.6 2.6 0 01-.566 1.461 2.617 2.617 0 01-.921.759.75.75 0 10.646 1.353 4.127 4.127 0 001.458-1.19 4.264 4.264 0 00.71-1.35l.706.55a.5.5 0 00.307.105 2.8 2.8 0 001.809-.993 3.084 3.084 0 00.342-2.948A6.283 6.283 0 009.31 1.426zm-4.6.218a1 1 0 11.175 1.4 1 1 0 01-.179-1.4zM21.032 9.838a6.294 6.294 0 00-3.32-1.365 3.081 3.081 0 00-2.774 1.056 2.787 2.787 0 00-.533 1.9.5.5 0 00.192.366l.707.551a4.217 4.217 0 00-1.135 1.017 4.056 4.056 0 00-.8 1.705.75.75 0 001.471.295 2.572 2.572 0 01.51-1.079 2.625 2.625 0 011.273-.91l.437.34-1.84 2.37a.75.75 0 101.184.922l1.843-2.367.436.339a2.638 2.638 0 01-.569 1.459 2.608 2.608 0 01-.921.759.75.75 0 10.646 1.353 4.109 4.109 0 001.459-1.19 4.24 4.24 0 00.707-1.352l.709.552a.5.5 0 00.307.106 2.8 2.8 0 001.808-.995c1.205-1.547.416-4.11-1.797-5.832zm-4.6.218a1 1 0 11.175 1.4 1 1 0 01-.178-1.4zM8.282 15.176c-2.21-1.722-4.887-1.859-6.094-.309a2.787 2.787 0 00-.533 1.9.5.5 0 00.192.366l.708.551A4.221 4.221 0 001.419 18.7a4.116 4.116 0 00-.8 1.706.75.75 0 001.471.295 2.613 2.613 0 01.51-1.08 2.632 2.632 0 011.274-.909l.436.34-1.84 2.371a.75.75 0 101.184.922L5.5 19.977l.434.337a2.605 2.605 0 01-.567 1.461 2.608 2.608 0 01-.921.759.749.749 0 00.324 1.426 3.223 3.223 0 001.778-1.26 4.261 4.261 0 00.709-1.35l.707.55a.5.5 0 00.307.1 2.794 2.794 0 001.808-.993c1.205-1.547.421-4.107-1.797-5.831zm-4.6.218a1 1 0 11.175 1.4 1 1 0 01-.178-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});