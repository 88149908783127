define("ember-svg-jar/inlined/love-it-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-star</title><path d=\"M11.436 19.392a.249.249 0 00-.045-.269l-8.06-8.731a4.422 4.422 0 01-.849-5.16 4.492 4.492 0 013.328-2.42A4.578 4.578 0 019.8 4.1l1.531 1.4a1 1 0 001.347 0l1.561-1.424a4.547 4.547 0 013.965-1.256 4.5 4.5 0 013.328 2.419 4.372 4.372 0 01-1.023 5.218.247.247 0 00-.054.276l.628 1.457a.25.25 0 00.395.088l.287-.254a6.392 6.392 0 001.555-7.696 6.586 6.586 0 00-10.453-1.71l-.859.782-.828-.755A6.528 6.528 0 005.489.838 6.476 6.476 0 00.7 4.328a6.4 6.4 0 001.2 7.454l8.479 9.187a.25.25 0 00.418-.082c.009-.042.639-1.495.639-1.495z\"/><path d=\"M22.738 14.249h-2.665l-1.409-3.267-.013-.028a1.293 1.293 0 00-2.317.028l-1.41 3.267h-2.666a1.23 1.23 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.329 2.076-1.295 2.98a1.255 1.255 0 00.315 1.451 1.284 1.284 0 001.505.166l3.235-1.825 3.235 1.821a1.275 1.275 0 001.506-.166 1.254 1.254 0 00.315-1.45l-1.3-2.981 2.348-2.092a1.262 1.262 0 00.3-1.378 1.231 1.231 0 00-1.166-.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});