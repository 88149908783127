define("ember-svg-jar/inlined/landmark-sydney-opera-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-sydney-opera-house-1</title><path d=\"M16.85 6.833h3.733a3.417 3.417 0 10-3.111-4.817.25.25 0 01-.266.145 2.35 2.35 0 10-.356 4.672zM23.5 16.435h-.9a.251.251 0 01-.232-.343l1.56-3.892a1 1 0 00-.637-1.329 5.746 5.746 0 00-5.4.761.25.25 0 01-.391-.122c-.657-2.063-2.812-6.892-8.558-6.555a1 1 0 00-.852 1.41l4.417 9.716a.251.251 0 01-.228.354H8.653a.251.251 0 01-.177-.427l1.87-1.87a.25.25 0 00.051-.28L7.79 8.122A.248.248 0 007.661 8a7.627 7.627 0 00-3.75-.563A1 1 0 003.1 8.855l3.4 7.223a.251.251 0 01-.227.357h-1.62a.251.251 0 01-.177-.427l.474-.474A.25.25 0 005 15.25l-2.039-4.331a.247.247 0 00-.171-.137 4.2 4.2 0 00-2.049.042 1 1 0 00-.6 1.48l2.251 3.752a.251.251 0 01-.215.379H.5a.5.5 0 00-.5.5v3.333a.25.25 0 00.32.24l1.014-.079c.605.085 1.538-.773 1.8-1.111a2.5 2.5 0 014.119.226 2.005 2.005 0 001.4.856 2.669 2.669 0 001.792-1 2.5 2.5 0 014.307.377l.012.022a1.19 1.19 0 00.965.6 1.764 1.764 0 001.384-.821l.007-.012a2.417 2.417 0 012.149-1.223 2.508 2.508 0 012.137 1.2l.006.009c.114.17.712.9 1.249.837l1.022.08a.25.25 0 00.32-.24v-3.294a.5.5 0 00-.503-.5zm-3.237 0h-5.61a.251.251 0 01-.177-.427l2.8-2.8a.25.25 0 01.354 0l2.805 2.805a.25.25 0 01-.172.422z\"/><path d=\"M22.865 21.94c-1.616.225-2.729-1.536-2.738-1.55a1.048 1.048 0 00-1.726.022 3.271 3.271 0 01-2.672 1.553 2.727 2.727 0 01-2.329-1.477 1.043 1.043 0 00-1.727-.155 4.173 4.173 0 01-3.017 1.632 3.525 3.525 0 01-2.68-1.575 1.041 1.041 0 00-1.65-.091c-.015.02-1.528 1.909-3.188 1.682a1 1 0 10-.27 1.981 4 4 0 00.549.038 5.539 5.539 0 003.478-1.448.25.25 0 01.331.006 5.039 5.039 0 003.427 1.407 5.622 5.622 0 003.553-1.343.249.249 0 01.334.015 4.434 4.434 0 003.189 1.328 4.976 4.976 0 003.378-1.327.251.251 0 01.337 0 4.5 4.5 0 003.691 1.281 1 1 0 10-.27-1.981z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});