define("ember-svg-jar/inlined/cursor-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-alternate</title><path d=\"M16.2 20.084l-4.447-4.447-1.739 2.563a1.5 1.5 0 01-2.676-.414l-3.81-12.7a1.25 1.25 0 011.557-1.56l12.7 3.811a1.5 1.5 0 01.424 2.67l-.01.006-2.562 1.735 4.448 4.452a1.5 1.5 0 010 2.121l-1.768 1.768a1.5 1.5 0 01-2.117-.005zm-4.6-7.011a1 1 0 01.707.293l4.773 4.773a.249.249 0 00.353 0l.708-.707a.25.25 0 000-.353l-4.773-4.773a1 1 0 01.146-1.536l2.253-1.525A.25.25 0 0015.7 8.8L6.288 5.976a.247.247 0 00-.248.062.252.252 0 00-.063.249L8.8 15.7a.25.25 0 00.447.069l1.525-2.253a1 1 0 01.828-.443z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});