define("ember-svg-jar/inlined/wireless-payment-smartphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wireless-payment-smartphone-1</title><path d=\"M12.863 3.01a5.629 5.629 0 016.155 0 .979.979 0 101.088-1.628 7.624 7.624 0 00-8.331 0 .979.979 0 001.088 1.628z\"/><path d=\"M12.889 4.322a.979.979 0 101.088 1.628 3.593 3.593 0 013.928 0 .979.979 0 001.088-1.628 5.586 5.586 0 00-6.104 0zM21.055 21.527A2.633 2.633 0 0118.2 19.55l-.129-.1H12.03a.25.25 0 01-.25-.25v-6.99a.25.25 0 01.22-.245l1.066-.153a.97.97 0 00.769-.628c.013-.035.033-.066.043-.1l.08-.323a.978.978 0 00-.757-1.2l-3.384-.676a.978.978 0 00-.879.262l-.894.88-2.838 2.792-.016.018a.979.979 0 01-.751.35H.748a.25.25 0 00-.25.25v7.331a.249.249 0 00.25.25s5.2 0 5.628.008a.36.36 0 01.056.008c.174.046 1.116.292 1.613.42a.247.247 0 00.309-.243v-5.675a.734.734 0 011.468 0v6.719a1.737 1.737 0 001.713 1.6h8.81a1.713 1.713 0 001.713-1.713v-.367a.25.25 0 00-.25-.25zm-4.136 0a.979.979 0 11-.979-.979.978.978 0 01.979.979zM22.058 8.207a.979.979 0 00-1.958 0v1.324a.25.25 0 00.25.25h1.458a.25.25 0 00.25-.25z\"/><path d=\"M22.523 11.249h-2.447c-.979 0-1.469.49-1.469.979a56.223 56.223 0 00.979 6.852c.31.928.757.979 1.469.979h1.468a.979.979 0 00.979-.979v-6.852a.979.979 0 00-.979-.979z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});