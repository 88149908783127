define("ember-svg-jar/inlined/medical-specialty-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-back</title><path d=\"M17.563 10.192l-.38-1.14A3 3 0 0014.337 7H9.662a3 3 0 00-2.846 2.052l-.38 1.14a3 3 0 00.273 2.492s.527.848.592.916a1.283 1.283 0 002.041-1.526L8.5 10.5a.5.5 0 01.881-.473l.831 1.552a2.173 2.173 0 01.3 1.194 1.97 1.97 0 012.715.727h.423a2.271 2.271 0 01.165-1.962l.808-1.511a.5.5 0 01.881.473l-.82 1.531A1.288 1.288 0 0015.771 14c.857 0 1.125-.661 1.519-1.317a3 3 0 00.273-2.491z\"/><circle cx=\"20.5\" cy=\"17.001\" r=\"2.5\"/><circle cx=\"12\" cy=\"3.001\" r=\"2.5\"/><path d=\"M7.5 20.751H1a1 1 0 000 2h6.5a1 1 0 000-2zM23 20.751h-5a1 1 0 000 2h5a1 1 0 000-2z\"/><path d=\"M14.955 16.5H12.5a.25.25 0 01-.25-.25V14.5a.75.75 0 10-1.5 0v1.75a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25V14.5a.75.75 0 10-1.5 0v1.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V14.5a.75.75 0 10-1.5 0v2.024A1.5 1.5 0 002 19.5h9.842a.25.25 0 01.212.382l-.826 1.323a1.5 1.5 0 102.544 1.59l2.5-4a1.5 1.5 0 00-1.317-2.295z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});