define("ember-svg-jar/inlined/breakfast-croissant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-croissant</title><path d=\"M18.546 3.331a.247.247 0 00-.21-.056.25.25 0 00-.175.128c-.055.1-3.36 5.734-3.3 5.633a.249.249 0 00-.015.222.253.253 0 00.167.147 3.946 3.946 0 011.265.583A1 1 0 0017.4 10l3.145-2.1a1 1 0 00.378-1.193 8.6 8.6 0 00-2.377-3.376zM17.821 11.523a.249.249 0 00-.094.3 3.989 3.989 0 01.16 2.391 1 1 0 00.531 1.135 1 1 0 001.222-.272 8.392 8.392 0 001.86-5.3v-.243a.249.249 0 00-.388-.2zM5.454 3.331a.247.247 0 01.21-.056.25.25 0 01.175.128c.055.1 3.36 5.734 3.3 5.633a.249.249 0 01.015.222.253.253 0 01-.167.147 3.946 3.946 0 00-1.265.583A1 1 0 016.6 10L3.452 7.9a1 1 0 01-.378-1.19 8.6 8.6 0 012.38-3.379zM6.179 11.523a.249.249 0 01.094.3 3.989 3.989 0 00-.16 2.391 1 1 0 01-.531 1.135 1 1 0 01-1.222-.272 8.392 8.392 0 01-1.86-5.3v-.243a.249.249 0 01.388-.2zM16.8 2.765a1.5 1.5 0 00-.871-2.2 13.932 13.932 0 00-7.853 0 1.5 1.5 0 00-.872 2.2l3.937 6.709a1 1 0 001.723 0zM21 18a2.96 2.96 0 00-.353.021.249.249 0 00-.19.366 4.475 4.475 0 01.533 2.121.5.5 0 01-1 0A3.506 3.506 0 0016.5 17a3.422 3.422 0 00-1 .151.249.249 0 00-.167.171.253.253 0 00.052.233 4.474 4.474 0 011.108 2.953.5.5 0 01-1 0 3.486 3.486 0 00-4.474-3.346.251.251 0 00-.119.4 4.474 4.474 0 011.1 2.94.5.5 0 01-1 0A3.515 3.515 0 007.5 17a3.474 3.474 0 00-1.88.557.251.251 0 00-.027.4A3.991 3.991 0 017 21a.5.5 0 01-1 0 3 3 0 10-3 3h18a3 3 0 000-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});