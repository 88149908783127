define("ember-svg-jar/inlined/time-clock-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-fire</title><path d=\"M22.534 10.1A8.047 8.047 0 0020.1 4.456a1 1 0 00-1.662.649 1.345 1.345 0 01-1.456 1.2 4.381 4.381 0 01-.878-4.816A1 1 0 0015.234 0c-4.316 0-7.558 2.406-8.506 6.21a1.505 1.505 0 01-1-1.147 1 1 0 00-1.637-.625 7.419 7.419 0 00-2.558 6.578A6.381 6.381 0 004.685 15.7a.25.25 0 00.218.017.253.253 0 00.149-.162A7.655 7.655 0 015.6 14.2a.25.25 0 00-.087-.324 4.337 4.337 0 01-2-3.124 5.425 5.425 0 01.952-3.77A3.646 3.646 0 007.631 8.3a1 1 0 00.893-.855 5.975 5.975 0 015.289-5.333 6.336 6.336 0 002.256 6.018 1 1 0 00.5.172 3.553 3.553 0 003.1-1.2 5.8 5.8 0 01.866 3.008 3.937 3.937 0 01-1.465 3.171.25.25 0 00-.052.327 7.651 7.651 0 01.665 1.319.251.251 0 00.382.11 5.916 5.916 0 002.469-4.937z\"/><path d=\"M18.726 17.751A6.25 6.25 0 1012.476 24a6.257 6.257 0 006.25-6.249zm-2.442 0a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3.5a.75.75 0 011.5 0v2.5a.25.25 0 00.25.25h2a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});