define("ember-svg-jar/inlined/award-badge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-badge-1</title><path d=\"M10.953 12.6a1.751 1.751 0 012.133 0l5.608 4.327a.5.5 0 00.8-.4V2.006a2 2 0 00-2-2H6.5a2 2 0 00-2 2v14.531a.5.5 0 00.8.4z\"/><path d=\"M12.17 13.794a.248.248 0 00-.3 0l-7.17 5.5a.5.5 0 00-.2.4v2.8a1.511 1.511 0 00.839 1.347 1.489 1.489 0 001.574-.158l5.1-3.914 5.068 3.911a1.5 1.5 0 002.414-1.19v-2.796a.5.5 0 00-.194-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});