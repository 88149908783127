define("ember-svg-jar/inlined/trekking-goal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trekking-goal</title><circle cx=\"8.249\" cy=\"5.206\" r=\"2.25\"/><path d=\"M1.065 14.729a1.506 1.506 0 001.872-1l1.239-4.087a1.5 1.5 0 00-1-1.871 1.5 1.5 0 00-1.87 1L.065 12.858a1.5 1.5 0 001 1.871zM11.545 15.483a1.223 1.223 0 00.2.016 1.25 1.25 0 00.212-2.482 5.133 5.133 0 01-3.629-2.528l-.492-.859a1.249 1.249 0 00-2.276.239l-4 12.5a1.25 1.25 0 00.81 1.571 1.227 1.227 0 00.381.06 1.25 1.25 0 001.19-.869l1.216-3.8a.25.25 0 01.472-.012l1.451 3.869A1.251 1.251 0 008.25 24a1.229 1.229 0 00.439-.08 1.249 1.249 0 00.732-1.608l-2.785-7.427a.249.249 0 010-.164l.388-1.221a.249.249 0 01.413-.1 7.634 7.634 0 004.108 2.083zM24 6.5V1a1 1 0 00-1-1h-7.5a1 1 0 00-1 1v13.5a1 1 0 002 0V7.25a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v7.25a1 1 0 002 0zm-3.25-1.25h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});