define("ember-svg-jar/inlined/arrow-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-right-2</title><path d=\"M1.667 3h14.049a1.5 1.5 0 011.125.508l6.617 7.5a1.5 1.5 0 010 1.985l-6.618 7.5a1.5 1.5 0 01-1.124.507H1.667a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});