define("ember-svg-jar/inlined/wedding-car-heart-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-car-heart-balloon</title><path d=\"M23.428 1.4A2.594 2.594 0 0019.31.741l-.359.354a.5.5 0 01-.7 0L17.89.741a2.594 2.594 0 00-4.118.658 2.5 2.5 0 00.481 2.92l3.887 3.988a.645.645 0 00.92 0l3.887-3.988a2.5 2.5 0 00.481-2.919zM19.13 11.281a1 1 0 00-1.957-.414 7.39 7.39 0 00.6 4.086c.5 1.4.863 2.446.156 3.534a.25.25 0 01-.452-.077A7.06 7.06 0 0010.609 13c-2.26 0-5.285.876-7.039 4.986a.5.5 0 01-.4.3C1.452 18.492.3 19.55.3 21v1a1 1 0 001 1 .367.367 0 00.267-.114.465.465 0 00.1-.386 2.5 2.5 0 015 0 .5.5 0 00.5.5H11.3a.5.5 0 00.5-.5 2.5 2.5 0 015 0 .5.5 0 00.5.5h.367a.5.5 0 00.493-.582l-.145-.874a.5.5 0 01.183-.475c3.014-2.388 2.155-4.819 1.457-6.786a5.716 5.716 0 01-.525-3.002zm-4.471 6.61a.252.252 0 01-.207.109H7.038a.5.5 0 01-.442-.734A4.435 4.435 0 0110.55 15a4.385 4.385 0 014.135 2.659.252.252 0 01-.026.232z\"/><circle cx=\"4.167\" cy=\"22.5\" r=\"1.5\"/><circle cx=\"14.3\" cy=\"22.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});