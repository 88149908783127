define("ember-svg-jar/inlined/charging-battery-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-battery-medium</title><path d=\"M22.5 9H21V7.5A1.5 1.5 0 0019.5 6h-18A1.5 1.5 0 000 7.5v9A1.5 1.5 0 001.5 18h18a1.5 1.5 0 001.5-1.5V15h1.5a1.5 1.5 0 001.5-1.5v-3A1.5 1.5 0 0022.5 9zm-9 .5v5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5H13a.5.5 0 01.5.5zm-4 0v5a.5.5 0 01-.5.5H7.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5H9a.5.5 0 01.5.5zm-4 0v5a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5H5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});