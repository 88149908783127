define("ember-svg-jar/inlined/wifi-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-monitor</title><path d=\"M12.03 11.5a1.5 1.5 0 101.5 1.5 1.5 1.5 0 00-1.5-1.5zM12.03 7.5a5.96 5.96 0 00-4.243 1.758A1 1 0 109.2 10.672a4.1 4.1 0 015.658 0 1 1 0 001.414-1.414A5.96 5.96 0 0012.03 7.5z\"/><path d=\"M6.373 7.843a8.01 8.01 0 0111.314 0A1 1 0 0019.1 6.429a10.011 10.011 0 00-14.142 0 1 1 0 001.415 1.414zM14.348 20.18a.5.5 0 00-.385-.18h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.409.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M22.5.5h-21A1.5 1.5 0 000 2v15.5A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5V2A1.5 1.5 0 0022.5.5zM22 15a.5.5 0 01-.5.5h-19A.5.5 0 012 15V3a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});