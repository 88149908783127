define("ember-svg-jar/inlined/antique-sling-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-sling-1</title><circle cx=\"14.75\" cy=\"20.5\" r=\"2.75\"/><circle cx=\"18\" cy=\"15\" r=\"2.75\"/><circle cx=\"21.25\" cy=\"20.5\" r=\"2.75\"/><path d=\"M20.936 7.09a.5.5 0 01.108-.241 2 2 0 00-.13-2.685l-2.828-2.828a2 2 0 00-2.614-.186.5.5 0 01-.3.1H7.9A7.912 7.912 0 00.162 7.562a.249.249 0 00.325.287 3.166 3.166 0 011.462-.134.25.25 0 00.276-.179A5.909 5.909 0 017.9 3.25h6.65a.25.25 0 01.236.167 1.986 1.986 0 00.471.747l2.829 2.829a2 2 0 00.5.362.249.249 0 01.113.324A3.5 3.5 0 0115.5 9.75h-1.623a.25.25 0 00-.245.3 3.054 3.054 0 01-.042 1.385.25.25 0 00.241.315H15.5a5.51 5.51 0 005.436-4.66z\"/><path d=\"M11.753 11.714a1.5 1.5 0 00-2.121-2.121l-3.359 3.359a.251.251 0 01-.354 0L2.561 9.593a1.5 1.5 0 00-2.122 2.121l4.077 4.077a.25.25 0 01.072.194l-.357 5.27a1.869 1.869 0 103.73 0l-.361-5.27a.247.247 0 01.073-.194z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});