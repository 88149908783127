define("ember-svg-jar/inlined/concert-rock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-rock</title><path d=\"M14 18.658a2.334 2.334 0 01.333-2.481l2.48-3.039a1 1 0 00.226-.632V4.5a1.5 1.5 0 00-3 0v3a1 1 0 01-1 1h-4.5a1 1 0 01-1-1V2a1.5 1.5 0 00-3 0v7.263a.5.5 0 01-.86.348L2.666 8.56a1.551 1.551 0 00-2.374 1.983l5.45 7.068a1.007 1.007 0 00.456.331.5.5 0 01.333.474L6.5 23a.5.5 0 00.5.5h7a.5.5 0 00.5-.492l.053-3.382a.5.5 0 00-.138-.352 2.309 2.309 0 01-.415-.616z\"/><path d=\"M23.924 15.977a.823.823 0 00-.745-.477h-1.036a.5.5 0 01-.5-.5v-4a.5.5 0 00-.887-.316l-5.275 6.463a.821.821 0 00.626 1.353h1.036a.5.5 0 01.5.5v4a.5.5 0 00.332.471.491.491 0 00.168.029.5.5 0 00.387-.183l5.275-6.464a.824.824 0 00.119-.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});