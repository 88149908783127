define("ember-svg-jar/inlined/vinyl-record-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record-player</title><path d=\"M10 10.75a2.75 2.75 0 102.75 2.75A2.752 2.752 0 0010 10.75zm0 4a1.25 1.25 0 111.25-1.25A1.252 1.252 0 0110 14.75zM22.5 3a2.5 2.5 0 00-5 0v2a1.5 1.5 0 001.286 1.485.249.249 0 01.214.247v5.288a.253.253 0 01-.064.168c-4.5 5-4.2 4.667-4.244 4.722a1 1 0 001.551 1.259l4.5-5A1 1 0 0021 12.5V6.732a.249.249 0 01.214-.247A1.5 1.5 0 0022.5 5z\"/><path d=\"M23.724 6.5a.224.224 0 00-.19.1 3.02 3.02 0 01-.919.923.25.25 0 00-.115.211V12.5a2.5 2.5 0 01-.642 1.673l-4.5 5a2.5 2.5 0 01-2.97.568.251.251 0 00-.25.015 7.5 7.5 0 112.874-8.916.252.252 0 00.487-.091V7.738a.25.25 0 00-.115-.211A3 3 0 0116 5V3.75a.25.25 0 00-.25-.25H1.5A1.5 1.5 0 000 5v17a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 22V6.776a.277.277 0 00-.276-.276z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});