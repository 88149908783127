define("ember-svg-jar/inlined/smiley-look-one-eye-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-look-one-eye-alternate</title><path d=\"M9.692 19h5a1 1 0 000-2h-5a1 1 0 000 2zM6.888 9.832a3.153 3.153 0 001.8.668 2.805 2.805 0 002.395-1.553.993.993 0 00-.438-1.327 1.006 1.006 0 00-1.345.421s-.257.459-.612.459A1.429 1.429 0 018 8.168 3.159 3.159 0 006.2 7.5a2.8 2.8 0 00-2.4 1.553.993.993 0 00.437 1.327 1.006 1.006 0 001.343-.421s.258-.459.612-.459a1.431 1.431 0 01.696.332zM6.692 14h2.5a1 1 0 000-2h-2.5a1 1 0 000 2zM13.6 5.052a1 1 0 001.4-.2 2.751 2.751 0 014.4 0A1 1 0 1021 3.658a4.75 4.75 0 00-7.6 0 1 1 0 00.2 1.394z\"/><path d=\"M23.07 13.931a1 1 0 00-1.227.7A10 10 0 1112.192 2a1 1 0 000-2 12 12 0 1011.58 15.158 1 1 0 00-.702-1.227z\"/><path d=\"M21.692 11a4.5 4.5 0 10-4.5 4.5 4.5 4.5 0 004.5-4.5zm-7 0a2.5 2.5 0 112.5 2.5 2.5 2.5 0 01-2.5-2.5z\"/><circle cx=\"17.192\" cy=\"11\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});