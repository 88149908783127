define("ember-svg-jar/inlined/hotel-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-bed</title><circle cx=\"5\" cy=\"11.5\" r=\"2\"/><path d=\"M9 13.5h10.667a.833.833 0 00.623-1.386C18.233 9.8 14.1 8.372 9.309 8.51a.833.833 0 00-.809.832V13a.5.5 0 00.5.5z\"/><path d=\"M23 7.75a1 1 0 00-1 1v5.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-8.5a1 1 0 00-2 0v12.5a1 1 0 002 0v-1a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v1a1 1 0 002 0v-9.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});