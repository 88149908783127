define("ember-svg-jar/inlined/love-it-ribbon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-ribbon-1</title><path d=\"M23.366 19.266l-2.4-4.138a.25.25 0 00-.424-.013 10.283 10.283 0 01-5.075 3.985.249.249 0 00-.151.15.253.253 0 00.02.213l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405a1 1 0 001.031-1.488zM3.471 15.09a.247.247 0 00-.216-.112.25.25 0 00-.209.124L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00.02-.212.248.248 0 00-.15-.15 10.286 10.286 0 01-5.069-4.002zM20.766 9.456a8.75 8.75 0 10-8.75 8.75 8.76 8.76 0 008.75-8.75zm-8.136 4.175a1 1 0 01-1.258 0c-1.237-1-3.871-3.354-3.872-5.209 0-2.587 3.151-3.538 4.271-.972a.25.25 0 00.458 0c1.12-2.566 4.271-1.615 4.271.972 0 1.856-2.632 4.206-3.87 5.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});