define("ember-svg-jar/inlined/content-pen-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-double</title><path d=\"M10.731 6.843a.25.25 0 000-.354L8.657 4.415a.5.5 0 00-.707 0L4.414 7.951a.5.5 0 000 .707l2.075 2.074a.249.249 0 00.353 0zM18.579 16.458a1.5 1.5 0 000-2.121l-1.067-1.067a.25.25 0 00-.354 0l-3.889 3.888a.252.252 0 000 .354l1.068 1.067a1.5 1.5 0 002.121 0zM22.115 18.226a2.75 2.75 0 00-3.889 3.888c.9.9 4.4 1.66 5.5 1.881a.229.229 0 00.27-.269c-.221-1.103-.979-4.599-1.881-5.5zM20.78 20.78a.75.75 0 110-1.06.749.749 0 010 1.06zM3.707 7.243l3.536-3.536a.5.5 0 00.041-.66C7.271 3.031 5.122.879 5.122.879A3 3 0 00.879 5.122s2.152 2.15 2.169 2.162a.5.5 0 00.659-.041zM20.87 7.438a.507.507 0 000-.717l-3.588-3.589a.507.507 0 00-.718 0L2.3 17.4a.507.507 0 000 .717L5.887 21.7a.506.506 0 00.717 0zM18 2.414L21.587 6a.508.508 0 00.652.055c.023-.017.869-.859.869-.859A3.044 3.044 0 0018.8.893s-.843.846-.859.869a.509.509 0 00.059.652zM4.917 22.121l-3.038-3.038a.507.507 0 00-.851.235L.016 23.369a.507.507 0 00.615.615l4.051-1.012a.507.507 0 00.235-.851z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});