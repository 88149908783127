define("ember-svg-jar/inlined/phone-actions-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-split</title><path d=\"M17.167 16.161a2.5 2.5 0 00-3.536 0l-.384.385a46.76 46.76 0 01-5.789-5.789l.385-.385a2.5 2.5 0 000-3.535L5.721 4.715a2.5 2.5 0 00-3.536 0L1.022 5.879a3.507 3.507 0 00-.441 4.4 46.906 46.906 0 0013.141 13.145 3.535 3.535 0 004.4-.442l1.164-1.163a2.5 2.5 0 000-3.536zM10.679 4.882a1.105 1.105 0 001.2-.236l.565-.565 3.3 3v4.42a1.25 1.25 0 102.5 0V6.532a1.247 1.247 0 00-.409-.924l-3.626-3.3.425-.425a1.1 1.1 0 00-.775-1.878H11.1a1.1 1.1 0 00-1.1 1.1v2.761a1.119 1.119 0 00.679 1.016zM22.9.005h-2.763a1.1 1.1 0 00-.779 1.881l.36.36-.994.9a1.248 1.248 0 00-.086 1.765A1.267 1.267 0 0020.4 5l1.085-.983.629.63a1.117 1.117 0 001.2.238A1.1 1.1 0 0024 3.866V1.107A1.1 1.1 0 0022.9.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});