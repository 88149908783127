define("ember-svg-jar/inlined/smiley-in-trouble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-in-trouble</title><path d=\"M24 6c0-1.488-1.576-3.992-2.516-5.353a1.556 1.556 0 00-2.468 0c-.185.269-.395.581-.613.922a.25.25 0 01-.337.081 11.986 11.986 0 105.324 6.571.25.25 0 01.026-.213A3.726 3.726 0 0024 6zm-3.958-4.19a.248.248 0 01.208-.11.252.252 0 01.209.112C21.016 2.653 22.5 4.991 22.5 6A2.25 2.25 0 0118 6c0-1.009 1.484-3.347 2.042-4.19zM18 9.5a2 2 0 11-2-2 2 2 0 012 2zm-10-2a2 2 0 11-2 2 2 2 0 012-2zm-.838 9.623a5.773 5.773 0 019.676 0 1 1 0 01-1.676 1.093 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.093z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});