define("ember-svg-jar/inlined/data-file-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-add</title><path d=\"M5.992 2v7.275a.248.248 0 00.243.25c.365.007 1.037.034 1.484.082a.245.245 0 00.273-.248V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.875 7.875 0 01-.364 1.458.244.244 0 00.229.331h7.734a2 2 0 002-2V4.414A2 2 0 0023.406 3L20.992.586A2 2 0 0019.578 0H7.992a2 2 0 00-2 2z\"/><path d=\"M14.26 9.7l1.508.905a1 1 0 001.22-.151.977.977 0 00.106-.123l2.687-3.734a1 1 0 10-1.623-1.168l-2.016 2.8a.252.252 0 01-.332.068l-1.51-.91a1 1 0 00-1.331.28l-2.042 2.884a.244.244 0 00.065.344 8.06 8.06 0 011.128.932.243.243 0 00.374-.029l1.438-2.03a.25.25 0 01.328-.068zM6.508 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.5-7.25h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25h-1.5a.75.75 0 110-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});