define("ember-svg-jar/inlined/rating-booklet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-booklet</title><path d=\"M6.25 4h12a.5.5 0 00.5-.5V1a1 1 0 00-.407-.8.987.987 0 00-.869-.157L6.123 3.018A.5.5 0 006.25 4zM18.5 5h-13a1.251 1.251 0 00-1.249 1.25v16.5A1.251 1.251 0 005.5 24h13a1.251 1.251 0 001.249-1.25V6.251A1.251 1.251 0 0018.5 5zm-2.656 9.355l-1.4.976a.5.5 0 00-.173.61l.8 1.833a.524.524 0 01-.746.655l-2.08-1.17a.5.5 0 00-.49 0L9.676 18.43a.524.524 0 01-.746-.655l.8-1.833a.5.5 0 00-.172-.61l-1.4-.976A.489.489 0 018.5 13.5h1.654a.5.5 0 00.468-.323l.907-2.39a.531.531 0 01.943 0l.905 2.39a.5.5 0 00.467.323H15.5a.489.489 0 01.344.856z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});