define("ember-svg-jar/inlined/real-estate-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-building</title><path d=\"M23 3a1 1 0 00-1 1v17a1 1 0 01-1 1H4a1 1 0 000 2h17a3 3 0 003-3V4a1 1 0 00-1-1zM8 10.25H5a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM8 12.75H5a.75.75 0 000 1.5h3a.75.75 0 000-1.5z\"/><path d=\"M19 20.5a1.5 1.5 0 001.5-1.5V1.5A1.5 1.5 0 0019 0H1.5A1.5 1.5 0 000 1.5V19a1.5 1.5 0 001.5 1.5zm-9-3a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-1A.5.5 0 007 16H6a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5V10a.5.5 0 01.276-.447l3-1.5a.5.5 0 01.448 0l3 1.5A.5.5 0 0110 10zm8 0a.5.5 0 01-.5.5H12a.5.5 0 01-.5-.5V4a.5.5 0 01.707-.455l5.5 2.5A.5.5 0 0118 6.5z\"/><path d=\"M16 10.25h-2.5a.75.75 0 000 1.5H16a.75.75 0 000-1.5zM16 7.75h-2.5a.75.75 0 000 1.5H16a.75.75 0 000-1.5zM16 12.75h-2.5a.75.75 0 000 1.5H16a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});