define("ember-svg-jar/inlined/shop-sale-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-sale-1</title><path d=\"M11 19.875a.875.875 0 00.873-.875v-4.5a2.375 2.375 0 00-4.75 0V19a.875.875 0 001.75 0v-1.875a.25.25 0 01.25-.25h.75a.25.25 0 01.25.25V19a.875.875 0 00.877.875zm-1.875-4.75a.25.25 0 01-.25-.25V14.5a.625.625 0 011.25 0v.375a.25.25 0 01-.25.25zM20.873 14a.126.126 0 01.127-.125h2a.875.875 0 100-1.75h-2A1.877 1.877 0 0019.123 14v4A1.877 1.877 0 0021 19.875h2a.875.875 0 100-1.75h-2a.126.126 0 01-.127-.125v-.875a.25.25 0 01.25-.25H22a.875.875 0 100-1.75h-.875a.25.25 0 01-.25-.25zM14.5 12.125a.875.875 0 00-.875.875v5a1.877 1.877 0 001.875 1.875h2a.875.875 0 100-1.75h-2a.126.126 0 01-.127-.125v-5a.875.875 0 00-.873-.875zM5.373 17.7a2.173 2.173 0 00-.97-1.812l-1.839-1.229a.428.428 0 01.237-.784H4.5a.875.875 0 100-1.75H2.8a2.178 2.178 0 00-1.208 3.99l1.84 1.226a.428.428 0 01-.238.784H1.5a.875.875 0 000 1.75h1.7A2.18 2.18 0 005.373 17.7zM21.5 23a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1zM17.748 5.483a.244.244 0 00-.009-.066c-.109-.4-1.188-4.329-1.434-5.233A.249.249 0 0016.064 0H13a.25.25 0 00-.25.25v9.362a.249.249 0 00.14.224 4.729 4.729 0 002.473.46 4.869 4.869 0 002.379-.843.018.018 0 00.008-.015zM23.956 5.073L22.235.967A1.5 1.5 0 0020.881 0h-2.747a.249.249 0 00-.241.316l1.329 4.832a.8.8 0 01.026.2V8.92a.251.251 0 00.159.233A3.349 3.349 0 0024 6.183v-.91a.532.532 0 00-.044-.2zM11.248.25A.25.25 0 0011 0H7.932a.25.25 0 00-.241.184L6.257 5.417a.244.244 0 00-.009.066V9.3a.251.251 0 00.113.209 4.943 4.943 0 002.289.791c.133.011.266.015.4.015a4.68 4.68 0 002.061-.473.249.249 0 00.14-.224zM4.748 5.348a.8.8 0 01.026-.2L6.1.316A.249.249 0 005.862 0H3.119a1.5 1.5 0 00-1.354.967L.044 5.073a.532.532 0 00-.042.2v.91A3.247 3.247 0 001.99 9.1a3.34 3.34 0 002.6.05.25.25 0 00.159-.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});