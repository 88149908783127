define("ember-svg-jar/inlined/barcode-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>barcode-tag</title><path d=\"M23.187 12.8L11.208.814A2.771 2.771 0 009.24 0H2.782A2.779 2.779 0 000 2.783l.007 6.461a2.758 2.758 0 00.814 1.965L12.8 23.188a2.785 2.785 0 003.933 0l6.454-6.453a2.785 2.785 0 000-3.935zm-8.839 6.88a.75.75 0 01-1.061-1.061l.4-.4a.75.75 0 111.061 1.06zm-.388-5.56a.75.75 0 01-1.06-1.06l2.656-2.66a.75.75 0 011.06 1.06zm-5.4-.225a.75.75 0 01-1.06-1.061l5.243-5.243A.75.75 0 1113.8 8.652zm3.216 2.405l-.4.4a.75.75 0 01-1.06-1.061l.4-.4a.75.75 0 011.06 1.061zM6.3 3.484A2.712 2.712 0 113.584 6.2 2.714 2.714 0 016.3 3.484zm9.578 12.55l2.656-2.656a.75.75 0 011.061 1.061l-2.66 2.661a.75.75 0 11-1.061-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});