define("ember-svg-jar/inlined/people-woman-cap-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-4</title><path d=\"M22.533 18.917a14.264 14.264 0 00-5.908-2.481.5.5 0 00-.552.279A4.557 4.557 0 0112 19.5a4.557 4.557 0 01-4.073-2.785.5.5 0 00-.552-.279 14.264 14.264 0 00-5.908 2.481A2.464 2.464 0 00.5 20.894V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.606a2.464 2.464 0 00-.967-1.977zM13.077 11.362h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/><path d=\"M23 6.5a1 1 0 00-1-1h-2.968a.248.248 0 01-.242-.19 6.993 6.993 0 00-13.67.443.253.253 0 01-.107.163 1.739 1.739 0 00-.779 1.447v1.171a2.718 2.718 0 00.643 1.752.253.253 0 01.056.194c-.187 1.361-.709 2.6-2.086 3.044a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.116 5.351 5.351 0 001.317-.161 3.487 3.487 0 001.029-.437.252.252 0 01.274 0 6.972 6.972 0 007.641.032.252.252 0 01.275 0 3.484 3.484 0 001.073.461 5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.119.5.5 0 00-.231-.8c-1.417-.455-1.927-1.78-2.1-3.131a.25.25 0 01.054-.19 2.715 2.715 0 00.626-1.729V7.75a.25.25 0 01.25-.25H22a1 1 0 001-1zm-4.766 2.034a1.242 1.242 0 01-.366.884l-.482.482a1.252 1.252 0 01-1.348.277l-3.4-1.362a1.74 1.74 0 00-1.3 0l-3.4 1.362A1.25 1.25 0 016.582 9.9L6.1 9.418a1.242 1.242 0 01-.366-.884V7.363a.25.25 0 01.25-.25h12a.25.25 0 01.25.25zM12 14a4.985 4.985 0 01-3.944-1.94.25.25 0 01.136-.4 2.3 2.3 0 00.295-.095l3.4-1.361a.249.249 0 01.186 0l3.4 1.361a2.4 2.4 0 00.321.1.25.25 0 01.136.4A4.986 4.986 0 0112 14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});