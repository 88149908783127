define("ember-svg-jar/inlined/real-estate-action-house-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-key</title><path d=\"M11.126 15.258a5.958 5.958 0 015.019-1.7.5.5 0 00.41-.132l3.572-3.374A1.989 1.989 0 0121.5 9.5a.455.455 0 00.312-.785L13.03.41a1.5 1.5 0 00-2.058 0L.156 10.637A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h5.625a.5.5 0 00.443-.732 5.987 5.987 0 011.058-7.01z\"/><path d=\"M23.5 11h-2a.5.5 0 00-.343.136l-4.134 3.9a.5.5 0 01-.476.119 4.5 4.5 0 103.166 3.18.5.5 0 01.129-.485l4.011-4A.5.5 0 0024 13.5v-2a.5.5 0 00-.5-.5zm-6.741 9.584a1.75 1.75 0 110-2.475 1.746 1.746 0 010 2.476z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});