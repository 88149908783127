define("ember-svg-jar/inlined/arrow-double-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-up-1</title><path d=\"M17.7 5.305l-5-4.92a1 1 0 00-1.4 0l-5 4.921a.993.993 0 00-.3.712v4.009a1 1 0 00.614.922 1.009 1.009 0 00.386.078 1 1 0 00.7-.287l4.124-4.056a.249.249 0 01.35 0L16.3 10.74a1 1 0 001.7-.713V6.018a.994.994 0 00-.3-.713z\"/><path d=\"M12.7 9.856a1 1 0 00-1.4 0l-5 4.921a.991.991 0 00-.3.712v1.526a1 1 0 001.7.713l4.124-4.058a.249.249 0 01.35 0l4.126 4.058a1 1 0 001.7-.713v-1.526a.992.992 0 00-.3-.713z\"/><path d=\"M13.05 17.615a1.509 1.509 0 00-2.1 0L6.3 22.184a1 1 0 001.4 1.426l4.123-4.052a.251.251 0 01.35 0L16.3 23.61a1 1 0 101.4-1.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});