define("ember-svg-jar/inlined/video-game-sonic-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-sonic-1</title><path d=\"M23.957 12.6c-1.024-3.281-2.912-5.344-5.623-6.158a.5.5 0 01-.146-.886 7.991 7.991 0 01.94-.572.992.992 0 00.541-.911 1.008 1.008 0 00-.59-.9 9.866 9.866 0 00-8.938-.056 12.459 12.459 0 00-6.133 8.62.55.55 0 00-.008.095v2.589a.5.5 0 01-.5.5H2a.286.286 0 01-.225-.11.972.972 0 00-.775-.39A1 1 0 00.779 16.4a.283.283 0 01.221.273v.248a5.006 5.006 0 005 5h5a3.457 3.457 0 002.409-.984 2 2 0 01.591-.393 7.507 7.507 0 002.9-2.239.5.5 0 01.475-.184 4.3 4.3 0 012.76 1.715.99.99 0 00.971.343 1.027 1.027 0 00.782-.745 9.776 9.776 0 00.059-5.273.357.357 0 01.534-.4.991.991 0 001.119-.061 1.007 1.007 0 00.357-1.1zM5 12.421a2 2 0 014 0v2a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zm6 7.5H6a3.005 3.005 0 01-2.94-2.4.5.5 0 01.491-.6H11a1.5 1.5 0 010 3zm1.64-10.495a.5.5 0 01-.431.245H10a.5.5 0 01-.5-.5V6.643a.5.5 0 01.811-.391 9.355 9.355 0 012.335 2.678.5.5 0 01-.006.496z\"/><path d=\"M7 14.171a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});