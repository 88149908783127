define("ember-svg-jar/inlined/monitor-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-warning</title><path d=\"M11.659 17.733l.525-1a.5.5 0 00-.443-.733H10.5a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h2.551a.5.5 0 00.442-.268l1.242-2.366a.25.25 0 00-.222-.366H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.149a.5.5 0 00.645.479A2.919 2.919 0 0117.5 10a.5.5 0 00.5-.5V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 100 2h6.214a.5.5 0 00.445-.267zM23.837 21.906l-5.057-9.634a1.45 1.45 0 00-2.562 0l-5.055 9.634a1.429 1.429 0 00.048 1.408 1.456 1.456 0 001.233.686h10.111a1.456 1.456 0 001.233-.687 1.431 1.431 0 00.049-1.407zm-6.338.345a1 1 0 111-1 1 1 0 01-.999 1zm1.005-3.5a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});