define("ember-svg-jar/inlined/ticket-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-hold</title><path d=\"M6.575 9.11c-1.4 1.007-2.717 1.958-2.717 3.564v4.646a.5.5 0 01-.093.291l-1.75 2.451a.75.75 0 101.22.871l1.984-2.777a.747.747 0 00.139-.436v-5.046c0-1 1.552-1.95 2.37-2.547a.247.247 0 01.26-.021.251.251 0 01.137.223v2.013a.5.5 0 01-.146.354l-.834.834a.737.737 0 000 1.06.752.752 0 001.06 0L10.4 12.4a.516.516 0 01.72 0 .54.54 0 01-.04.76l-3.26 3.85a.7.7 0 00-.18.48v1.82a3.663 3.663 0 01-.85 1.6 9.387 9.387 0 01-.71.809.753.753 0 001.07 1.061c.112-.112.375-.392.676-.76a.249.249 0 01.424.063A1.487 1.487 0 009.625 23h11a1.5 1.5 0 001.5-1.5v-19a1.5 1.5 0 00-1.5-1.5h-3a1 1 0 00-.98.8 1.5 1.5 0 01-2.94 0 1 1 0 00-.98-.8h-3.1a1.5 1.5 0 00-1.5 1.5v5.3a.249.249 0 01-.1.2zm6.05 11.39a1 1 0 111-1 1 1 0 01-1 1zm7-.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V9.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});