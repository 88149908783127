define("ember-svg-jar/inlined/toys-teddy-bear-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-teddy-bear-1</title><path d=\"M19.1 15.406c1.131-.272 3.022-.727 2.612-2.911a2.023 2.023 0 00-.873-1.328 6.083 6.083 0 00-3.905-.46 1 1 0 00-.578.275 6.239 6.239 0 01-8.7 0 1 1 0 00-.577-.276 6.091 6.091 0 00-3.9.461 2.023 2.023 0 00-.886 1.333c-.41 2.184 1.481 2.639 2.612 2.911 1.429.344 1.845.563 1.845 1.344v1a.5.5 0 01-.5.5 2.75 2.75 0 102.323 4.222.5.5 0 01.51-.224A20.184 20.184 0 0012 22.5a20.508 20.508 0 002.919-.251.5.5 0 01.509.224 2.75 2.75 0 102.322-4.223.5.5 0 01-.5-.5v-1c0-.781.416-1 1.85-1.344z\"/><path d=\"M6.508 5.454a.5.5 0 01.27.5c-.018.18-.028.362-.028.548a5.25 5.25 0 0010.5 0c0-.186-.01-.369-.028-.549a.5.5 0 01.27-.5 2.75 2.75 0 10-3.411-4.143.5.5 0 01-.541.17 5.293 5.293 0 00-3.081 0 .5.5 0 01-.541-.171 2.75 2.75 0 10-3.41 4.144zm7.742-.7a.75.75 0 11-.75.75.75.75 0 01.75-.754zm-3.712 2.805A.5.5 0 0111 7.25h2a.5.5 0 01.354.854l-1 1a.5.5 0 01-.708 0l-1-1a.5.5 0 01-.108-.545zM9.75 4.75A.75.75 0 119 5.5a.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});