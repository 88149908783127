define("ember-svg-jar/inlined/walking-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking-1</title><circle cx=\"14.25\" cy=\"3.522\" r=\"3.5\"/><path d=\"M13.951 14.153a3.007 3.007 0 01-.013-2.07l.514-1.427A2 2 0 1010.688 9.3l-4.32 12a2 2 0 001.205 2.56 1.979 1.979 0 00.677.118 2 2 0 001.882-1.322l1.11-3.083a.25.25 0 01.469 0l1.167 3.111a2 2 0 103.745-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});