define("ember-svg-jar/inlined/moving-walkway-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>moving-walkway-people</title><path d=\"M17.5 10.25a1.5 1.5 0 00-3 0V12a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25z\"/><circle cx=\"16\" cy=\"4.75\" r=\"2.5\"/><path d=\"M9.5 10.25a1.5 1.5 0 00-3 0V12a.25.25 0 00.25.25h2.5A.25.25 0 009.5 12z\"/><circle cx=\"8\" cy=\"4.75\" r=\"2.5\"/><path d=\"M23.5 13.75H4a4 4 0 000 8h19.5a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});