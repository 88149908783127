define("ember-svg-jar/inlined/people-man-4.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-4</title><path d=\"M6.149 12.572a.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.734 2.734 0 001.439-2.435 2.082 2.082 0 00-.521-1.5c-.032-3.564-1.3-5.325-1.9-5.7a.249.249 0 01-.092-.1c-.605-1.217-2.172-1.365-3.3-1.456-.574-.046-1.359-.109-1.495-.315a.561.561 0 01.037-.4.5.5 0 00-.07-.45A.5.5 0 0011.5 0C8.525 0 7.431 1.391 7.112 2.882a.247.247 0 01-.08.135C6.707 3.3 5.321 4.7 5.234 8.629a2.082 2.082 0 00-.524 1.51 2.729 2.729 0 001.439 2.433zm.165-2.9a.748.748 0 00.415-.666 10.419 10.419 0 01.481-3.46.25.25 0 01.417-.085A3.835 3.835 0 0010.5 6.5h3a3.848 3.848 0 002.86-1.026.249.249 0 01.415.087 9.995 9.995 0 01.454 3.434.753.753 0 00.416.676.615.615 0 01.105.438 1.279 1.279 0 01-.559 1.1 1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.277 1.277 0 01-.559-1.1.626.626 0 01.098-.433z\"/><path d=\"M21.08 18.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213zM10.168 13a.75.75 0 00.305 1.016 2.916 2.916 0 00.51.211 3.579 3.579 0 001.017.151h.006a3.509 3.509 0 001.013-.147 2.783 2.783 0 00.512-.213.75.75 0 10-.715-1.318 1.24 1.24 0 01-.236.1 1.961 1.961 0 01-.574.082 2.05 2.05 0 01-.579-.082 1.283 1.283 0 01-.24-.1.752.752 0 00-1.019.3zM9.383 10.453a.63.63 0 01.626 0 .75.75 0 001-1.115 2.1 2.1 0 00-2.636 0 .75.75 0 101.006 1.113zM13.948 10.453a.63.63 0 01.626 0 .75.75 0 101-1.115 2.1 2.1 0 00-2.636 0 .75.75 0 001.006 1.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});