define("ember-svg-jar/inlined/beach-palm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beach-palm</title><path d=\"M5.16 3.15l2.781 1.105a.505.505 0 00.184.035 13.491 13.491 0 013.791.526.5.5 0 00.288 0 13.676 13.676 0 014.811-.485.5.5 0 00.223-.033L20.22 3.11a.756.756 0 00.08-1.369 5.327 5.327 0 00-5.4.089 5.1 5.1 0 00-2.034 2.176.25.25 0 01-.453 0A5.1 5.1 0 0010.43 1.86a5.34 5.34 0 00-5.34-.18.808.808 0 00.07 1.47zM11.52 11.45h.841a.5.5 0 00.5-.478l.036-.835a.5.5 0 00-.252-.456 3.832 3.832 0 01-.3-.189.5.5 0 00-.559 0 6.4 6.4 0 01-1.169.613.5.5 0 00-.249.222l-3.021 5.484a.5.5 0 00.484.739c.883-.083 1.641-.1 2.139-.1.7 0 1.4.033 2.107.1a.5.5 0 00.375-.122.5.5 0 00.17-.355l.026-.6a.5.5 0 00-.5-.522H11.02a.5.5 0 010-1h1.223a.5.5 0 00.5-.476l.023-.5a.5.5 0 00-.5-.524h-.746a.5.5 0 010-1zM9.971 17.45c-4.157 0-8.807 1.135-9.395 4.319a1 1 0 00.984 1.181h17.106a.749.749 0 00.747-.81c-.276-3.457-5.059-4.69-9.442-4.69z\"/><path d=\"M23.347 9.078c-2.259-4.362-8.244-4.242-11.2-3.187a.243.243 0 01-.168 0C9.017 4.834 3.031 4.714.772 9.079a.806.806 0 00.677 1.178c.417.02.885.033 1.39.033 2.5 0 7.361-.328 9.036-2.172a.252.252 0 01.37 0c1.673 1.843 6.53 2.172 9.036 2.172.5 0 .973-.013 1.389-.033a.806.806 0 00.677-1.179z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});