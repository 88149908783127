define("ember-svg-jar/inlined/airplane-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>airplane-mode</title><path d=\"M9.5 19.33a.253.253 0 01-.076.18l-1.463 1.411A1.5 1.5 0 009 23.5a7.321 7.321 0 002.855-1.394.246.246 0 01.29 0A7.307 7.307 0 0015 23.5a1.5 1.5 0 001.039-2.579l-1.463-1.411a.253.253 0 01-.076-.18v-6.295l-4.927 4.927a.249.249 0 00-.073.177zM14.5 3a2.5 2.5 0 10-5 0v3.656L6.487 9.315 1.177 14a2 2 0 001.784 3.45.259.259 0 00.121-.067L14.427 6.038a.251.251 0 00.073-.177zM20 16.907a2.49 2.49 0 001.5.593 2 2 0 001.323-3.5l-4.758-4.2a.25.25 0 00-.342.011l-3.2 3.2c1.418 1.004 4.853 3.48 5.477 3.896zM23.707.293a1 1 0 00-1.414 0l-22 22a1 1 0 101.414 1.414l22-22a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});