define("ember-svg-jar/inlined/seo-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-label</title><path d=\"M16.235 13.409a.748.748 0 00-.531.219l-2.121 2.122a.748.748 0 000 1.06.767.767 0 001.059 0l2.122-2.121a.749.749 0 00-.53-1.28z\"/><path d=\"M9.845.647a1.511 1.511 0 00-1.06-.439H1.708a1.5 1.5 0 00-1.5 1.5l.006 7.08a1.493 1.493 0 00.44 1.06l13.507 13.506a1.5 1.5 0 002.121 0l7.071-7.07a1.5 1.5 0 000-2.121zm-4.016 9.6a2.046 2.046 0 01-1.452-.6l-1.2-1.2A.75.75 0 014.238 7.38l1.2 1.2a.553.553 0 00.933-.5l-.434-2.167A2.054 2.054 0 019.4 4.058l1.2 1.2a.75.75 0 01-1.06 1.06l-1.2-1.2a.553.553 0 00-.933.5l.433 2.168a2.051 2.051 0 01-2.012 2.455zm5.5 1.8a.746.746 0 01-.531-.22l-.707-.707a.25.25 0 00-.353 0l-.707.707a.249.249 0 00-.073.177.244.244 0 00.073.176l1.414 1.415a.75.75 0 11-1.061 1.061l-1.416-1.425a1.75 1.75 0 010-2.474L10.8 7.928a1.755 1.755 0 012.475 0l1.414 1.415a.75.75 0 11-1.06 1.06L12.21 8.989a.25.25 0 00-.353 0l-.707.711a.25.25 0 000 .354l.707.707a.75.75 0 01-.53 1.28zm6.5 3.712L15.7 17.871a2.25 2.25 0 01-3.182-3.183l2.121-2.121a2.251 2.251 0 013.184 3.182z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});