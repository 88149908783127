define("ember-svg-jar/inlined/messages-bubble-forward-all-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-forward-all-alternate</title><path d=\"M7.94 17.374l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.276-1.089A7.043 7.043 0 012 9.912c0-4.358 4.486-7.9 10-7.9s10 3.546 10 7.9a6.222 6.222 0 01-.226 1.639.25.25 0 00.1.271l1.3.9a.25.25 0 00.379-.124A8.264 8.264 0 0024 9.912c0-5.461-5.383-9.9-12-9.9S0 4.451 0 9.912a8.877 8.877 0 002.708 6.26l-1.6 4.4a1 1 0 001.325 1.264l5.076-2.116a.249.249 0 00.149-.181 7.929 7.929 0 01.61-1.829.25.25 0 00-.319-.339z\"/><path d=\"M11 21.086a4.8 4.8 0 014-4.62v1.321a.5.5 0 00.785.412l3.5-2.421a.5.5 0 000-.823l-3.5-2.42a.5.5 0 00-.784.411v1.5a6.821 6.821 0 00-6 6.643H9v1.906a1 1 0 102 0z\"/><path d=\"M20.177 12.328a.75.75 0 10-.854 1.234l2.609 1.8-2.609 1.808a.75.75 0 10.854 1.233l3.5-2.421a.75.75 0 000-1.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});