define("ember-svg-jar/inlined/insurance-expensive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insurance-expensive</title><path d=\"M23.184 21.576l-21.684-8a1 1 0 10-.684 1.879l10.319 3.81a.251.251 0 01.109.392L8.409 23.17a.5.5 0 00.389.814h6.4a.5.5 0 00.389-.814l-2.045-2.535a.25.25 0 01.28-.392l8.678 3.212a1 1 0 10.684-1.879zM24 11.516a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5zM6.5 13.016a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-.95-10.2a.249.249 0 00.2-.245v-.305a.75.75 0 011.5 0v.25a.25.25 0 00.25.25H8a.75.75 0 010 1.5H5.967a.591.591 0 00-.221 1.14l2.065.826a2.085 2.085 0 01-.361 3.981.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0v-.25a.25.25 0 00-.25-.25H5a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141L5.189 6.8a2.085 2.085 0 01.361-3.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});