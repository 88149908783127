define("ember-svg-jar/inlined/amphibian-chameleon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-chameleon</title><path d=\"M16.544 4.132a17 17 0 00-7.578 1.2L7.042 4.64A12.767 12.767 0 017 2.4a.5.5 0 00-.5-.526c-.069 0-1.731.025-3.854 2.147C.278 6.385.011 10.912 0 11.1a.5.5 0 00.124.356 5.87 5.87 0 003.292 1.663 8.174 8.174 0 004.613-.755L6.649 15a.251.251 0 01-.222.134H1a1 1 0 000 2h11.75a1 1 0 000-2H10.9a.251.251 0 01-.226-.359l2.145-4.452a.75.75 0 011.368.617l-.83 1.839a.254.254 0 00.018.239.25.25 0 00.21.114h1.605c2.81 0 6.06 1.306 6.06 4.5a2.753 2.753 0 01-2.75 2.75 2.253 2.253 0 01-1.677-.75.75.75 0 111.117-1 .754.754 0 00.56.25 1.252 1.252 0 001.25-1.25 1.76 1.76 0 00-.383-1.051C17.917 14.649 15 16.442 15 18.63s1.5 3.5 4 3.5c3.282 0 5-4.527 5-9 0-5.364-2.647-8.56-7.456-8.998zm-9.508 4.5A2.262 2.262 0 114.774 6.37a2.265 2.265 0 012.262 2.262z\"/><circle cx=\"4.786\" cy=\"8.62\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});