define("ember-svg-jar/inlined/e-commerce-open-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-open-basket</title><path d=\"M23.073 18.9l-3.786-1.1a.25.25 0 01-.18-.24V13.5a1 1 0 00-2 0v6.561a.25.25 0 01-.456.142c-1.482-2.138-3.808-1.465-2.714.205l2.985 3.5a.25.25 0 00.191.088h5.807a.249.249 0 00.243-.192l.719-3.017.085-.367a1.284 1.284 0 00-.894-1.52z\"/><path d=\"M14.749 16.75a1 1 0 00-1-1H8.84a.992.992 0 01-.971-.75l-.929-4.449a.249.249 0 01.244-.3H19.75a1 1 0 000-2h-1.964a.249.249 0 01-.229-.15l-1.848-4.25a1 1 0 00-1.834.8L15.289 7.9a.25.25 0 01-.229.35H9.438a.25.25 0 01-.229-.35l1.413-3.252a1 1 0 10-1.834-.8L6.941 8.1a.249.249 0 01-.229.15H4.25a1 1 0 000 2h.381a.251.251 0 01.245.2l1.04 4.976A2.989 2.989 0 008.84 17.75h4.909a1 1 0 001-1z\"/><path d=\"M9.5 11.75v2.5a.75.75 0 001.5 0v-2.5a.75.75 0 00-1.5 0zM13.5 11.75v2.5a.75.75 0 001.5 0v-2.5a.75.75 0 00-1.5 0zM4 6.25A2.253 2.253 0 016.25 4a.75.75 0 000-1.5A3.755 3.755 0 002.5 6.25a.75.75 0 001.5 0z\"/><path d=\"M6.25 1.5a.75.75 0 000-1.5A6.257 6.257 0 000 6.25a.75.75 0 001.5 0A4.756 4.756 0 016.25 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});