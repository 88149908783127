define("ember-svg-jar/inlined/astrology-capricorn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-capricorn</title><path d=\"M20.5 17h-1a.5.5 0 01-.5-.5V1a1 1 0 00-.562-.9 1.011 1.011 0 00-1.055.112 23.265 23.265 0 00-7.651 9.8.25.25 0 01-.463 0 23.259 23.259 0 00-7.652-9.8A1 1 0 00.383 1.787 21.133 21.133 0 018.1 12.67a1.464 1.464 0 002.808 0 20.428 20.428 0 015.67-9.13.249.249 0 01.422.181V16.5a.5.5 0 01-.5.5H1a1 1 0 000 2h15.5a.5.5 0 01.5.5v1a3.5 3.5 0 103.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});