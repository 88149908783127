define("ember-svg-jar/inlined/resize-shrink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-shrink</title><path d=\"M24 2a2 2 0 00-2-2H6a2 2 0 00-2 2v8.5a1 1 0 002 0v-8a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-8a1 1 0 000 2H22a2 2 0 002-2zM2.25 22.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5zM10.75 21a.75.75 0 00-.75.75v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h1.5a.75.75 0 00.75-.75v-1.5a.75.75 0 00-.75-.75zM2.25 12.5H.75a.75.75 0 00-.75.75v1.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/><path d=\"M10.75 12.5h-1.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM.75 19.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM10.75 17a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM6.25 22.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM6.25 12.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM18.5 11.75a.75.75 0 00.53-1.28L17.164 8.6a.25.25 0 010-.354l2.543-2.543a1 1 0 10-1.414-1.414L15.75 6.836a.25.25 0 01-.354 0L13.53 4.97a.75.75 0 00-1.28.53V11a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});