define("ember-svg-jar/inlined/saving-safe-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-safe-1</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5v18A1.5 1.5 0 001.5 22h.75a.25.25 0 01.25.25v.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-.25a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v.25a.5.5 0 00.5.5H21a.5.5 0 00.5-.5v-.25a.25.25 0 01.25-.25h.75a1.5 1.5 0 001.5-1.5zm-1.75 3v12a1.752 1.752 0 01-1.75 1.75h-16a1.752 1.752 0 01-1.75-1.75v-12A1.752 1.752 0 014.5 3.75h16a1.752 1.752 0 011.75 1.75z\"/><path d=\"M20.5 5.25h-16a.253.253 0 00-.25.25v12a.253.253 0 00.25.25h16a.253.253 0 00.25-.25v-12a.253.253 0 00-.25-.25zM15 16a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1zm-1.5-3.25a3 3 0 112.967-3.007A2.982 2.982 0 0116.5 12.75z\"/><path d=\"M16.5 8.25a1.473 1.473 0 00-1.45 1.493A1.459 1.459 0 1016.5 8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});