define("ember-svg-jar/inlined/social-deviant-art-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-deviant-art-1</title><path d=\"M20.5 3V.5A.5.5 0 0020 0h-5.5a.5.5 0 00-.42.23L11.72 4H4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h4.19l-4.64 9.28a.5.5 0 00-.05.22v3a.5.5 0 00.5.5h5.5a.5.5 0 00.45-.28L11.81 20H20a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-4.67l5.12-9.77A.5.5 0 0020.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});