define("ember-svg-jar/inlined/single-man-actions-chat.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-chat</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.363 6.363 0 00.93-.07.5.5 0 01.57.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM9.456 12a3.156 3.156 0 01.072-.657.5.5 0 00-.359-.587A7.34 7.34 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.412a.5.5 0 00.5-.5zM23.956 12a2 2 0 00-2-2h-9a2 2 0 00-2 2v7a2 2 0 002 2h.75a.25.25 0 01.25.25V23a1 1 0 001.625.781l3.407-2.726a.25.25 0 01.157-.055h2.811a2 2 0 002-2zm-5.874 7.219l-1.72 1.381a.25.25 0 01-.406-.2V20a1 1 0 00-1-1h-1.75a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v6.5a.255.255 0 01-.073.177.251.251 0 01-.177.073h-3a1 1 0 00-.624.219z\"/><path d=\"M14.956 15h5a.75.75 0 100-1.5h-5a.75.75 0 000 1.5zM18.706 16.75a.75.75 0 00-.75-.75h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});