define("ember-svg-jar/inlined/file-code-lock-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-lock-1</title><path d=\"M14.679 12.2l.018-.017a.751.751 0 00.018-1.043l-2.029-2.174a.249.249 0 010-.341l2.028-2.174a.75.75 0 00-1.078-1.043l-.018.019-2.666 2.857a.749.749 0 000 1.023l2.666 2.857a.751.751 0 001.061.036zM16.286 11.14a.75.75 0 001.078 1.043l.017-.019 2.667-2.857a.749.749 0 000-1.023l-2.666-2.857a.751.751 0 00-1.061-.036.731.731 0 00-.036 1.06l2.029 2.174a.249.249 0 010 .341z\"/><path d=\"M23.414 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.684a.244.244 0 00.184.235 4.438 4.438 0 011.429.687A.247.247 0 008 10.4V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.355V18a.5.5 0 01-.5.5h-9.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M8.611 15.513a.123.123 0 01-.111-.12v-1.142a3.5 3.5 0 10-7 0v1.134a.114.114 0 01-.113.128A1.5 1.5 0 000 17v5.5A1.5 1.5 0 001.5 24h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.389-1.487zM5 21.251a1 1 0 111-1 1 1 0 01-1 1zm1.5-5.866a.12.12 0 01-.106.116H3.612a.122.122 0 01-.112-.116v-1.134a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});