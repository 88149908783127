define("ember-svg-jar/inlined/cash-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-shield</title><path d=\"M9.647 23.347l1.068.413a3.546 3.546 0 002.57 0l1.068-.413A12.863 12.863 0 0022.5 11.412V3.774a2.047 2.047 0 00-1.181-1.859A22.612 22.612 0 0012 0a22.612 22.612 0 00-9.319 1.915A2.047 2.047 0 001.5 3.774v7.638a12.863 12.863 0 008.147 11.935zm3.013-10.619l-2.437-1.219a3.568 3.568 0 01.363-6.54.249.249 0 00.164-.235v-.609a1.25 1.25 0 012.5 0V4.5a.25.25 0 00.25.25h1a1.25 1.25 0 010 2.5h-2.683a1.067 1.067 0 00-.477 2.022l2.437 1.219a3.568 3.568 0 01-.363 6.54.249.249 0 00-.164.235v.609a1.25 1.25 0 01-2.5 0V17.5a.25.25 0 00-.25-.25h-1a1.25 1.25 0 010-2.5h2.683a1.067 1.067 0 00.477-2.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});