define("ember-svg-jar/inlined/multiple-actions-player.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-player</title><path d=\"M14.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.259 6.259 0 01-.922 3.624.254.254 0 00-.029.216.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537.054.107.1.216.147.326a.25.25 0 00.231.156h2.928a.25.25 0 00.171-.433A11.5 11.5 0 0014.471 8.4z\"/><path d=\"M10.5 12a1.5 1.5 0 011.5-1.5h.807a.25.25 0 00.17-.433A11.488 11.488 0 009.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.174 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.75a.25.25 0 00.25-.25zM24 22.125v-8.25A1.877 1.877 0 0022.125 12h-8.25A1.876 1.876 0 0012 13.875v8.25A1.876 1.876 0 0013.875 24h8.25A1.877 1.877 0 0024 22.125zm-8.5-5.945a.931.931 0 011.408-.8l3.034 1.82a.929.929 0 010 1.594l-3.035 1.82a.923.923 0 01-.478.133.94.94 0 01-.459-.121.931.931 0 01-.471-.809z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});