define("ember-svg-jar/inlined/love-it-angel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-angel</title><path d=\"M20.087 10a.25.25 0 00-.243.31 5.038 5.038 0 01.133 1.46.219.219 0 00.223.23h.221A2.265 2.265 0 0122 14.463V21.5a.5.5 0 01-1 0v-2.537a1 1 0 00-2 0 .5.5 0 01-1 0v-2.714a.25.25 0 00-.43-.173l-1.5 1.565a.247.247 0 00-.07.173v1.149a2.5 2.5 0 003 2.45v.087a2.5 2.5 0 005 0v-7.037C24 11.575 22.242 10 20.087 10zM6 18.963a.5.5 0 01-1 0 1 1 0 00-2 0V21.5a.5.5 0 01-1 0v-7.037A2.266 2.266 0 013.584 12h.178a.25.25 0 00.25-.261 5.047 5.047 0 01.135-1.429.25.25 0 00-.242-.31C1.458 10 0 11.978 0 14.463V21.5a2.5 2.5 0 005 0v-.087a2.5 2.5 0 003-2.45v-1.142a.25.25 0 00-.07-.173l-1.5-1.564a.25.25 0 00-.43.173zM12 5.5c1.239 0 7.417-.133 7.417-2.75S13.239 0 12 0 4.583.133 4.583 2.75 10.761 5.5 12 5.5zM12 2a16.6 16.6 0 014.388.51.25.25 0 010 .481A16.646 16.646 0 0112 3.5a16.646 16.646 0 01-4.388-.509.25.25 0 010-.481A16.6 16.6 0 0112 2zM18.124 9.95a3.525 3.525 0 00-5.646-.916L12 9.515l-.482-.482a3.526 3.526 0 00-4.993 4.979l5.114 5.334a.5.5 0 00.721 0l5.106-5.326a3.524 3.524 0 00.658-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});