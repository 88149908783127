define("ember-svg-jar/inlined/non-gmo-organic-flask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-organic-flask</title><path d=\"M20.993 20.734a1 1 0 00-1.726.939.25.25 0 01-.238.327H5.194A.25.25 0 015 21.592l.622-.76a1 1 0 10-1.548-1.267l-.956 1.169A2 2 0 004.666 24h14.779a2 2 0 001.548-3.266zM7.993 2.886a.5.5 0 01.313.464v2.334a1 1 0 002 0V3.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v6a1 1 0 002 0V3.35a.5.5 0 01.312-.464A1.5 1.5 0 0015.556 0h-7a1.5 1.5 0 00-.563 2.886z\"/><path d=\"M7.21 17.076a2.377 2.377 0 00.317-.022 2.327 2.327 0 001-.395.5.5 0 00.162-.636 24.209 24.209 0 00-2.63-4.134.5.5 0 11.785-.62c1.942 2.46 4.02 6.312 4.468 9.1A.75.75 0 0012 21a.487.487 0 00.054 0 .75.75 0 00.717-.53c.642-2.073 2.183-4.988 4.169-5.98a.5.5 0 01.448.895 6.756 6.756 0 00-2.467 2.689.5.5 0 00.233.71 2.105 2.105 0 00.435.135 2.18 2.18 0 00.4.037 3.44 3.44 0 002.391-1.194 7.305 7.305 0 00.955-6.72.5.5 0 00-.756-.19 6.485 6.485 0 01-2.542 1.091 6.976 6.976 0 00-2.258.892 2.74 2.74 0 00-1.37 2.186 2.113 2.113 0 00.376 1.189.5.5 0 01.017.527c-.12.207-.232.412-.336.613a.25.25 0 01-.466-.029 20.408 20.408 0 00-1.1-2.443.5.5 0 01.127-.624 2.89 2.89 0 001.069-1.59A3.8 3.8 0 0010.953 9.4a6.677 6.677 0 00-3.486-1.864 8.854 8.854 0 01-3.389-1.592.507.507 0 00-.418-.109.5.5 0 00-.347.258c-1.378 2.606-.73 7.352.778 9.13a4.406 4.406 0 003.119 1.853z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});