define("ember-svg-jar/inlined/profile-cash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>profile-cash-message</title><path d=\"M23.75 1.5a1.5 1.5 0 00-1.5-1.5h-10a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v2.25a.5.5 0 00.825.38l3.29-2.819a.244.244 0 01.162-.061h5.223a1.5 1.5 0 001.5-1.5zm-7.667 3.375h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25h-1.458a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083zM3.926 21.775L.574 23.032A.5.5 0 00.75 24h11a.5.5 0 00.176-.968l-3.352-1.257a.5.5 0 01-.324-.467v-.931a.479.479 0 01.109-.311A5.8 5.8 0 009.75 16.5c0-2.445-1.324-3.979-3.455-4h-.029a3.387 3.387 0 00-2.878 1.69c-1.566 2.708.183 5.186.757 5.881a.471.471 0 01.105.306v.931a.5.5 0 01-.324.467z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});