define("ember-svg-jar/inlined/bird-cage-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-cage-1</title><path d=\"M19.327 8.3a.5.5 0 00-.458-.3H5.255a.5.5 0 00-.454.291C.029 18.654.005 19.892 1.859 22.126a4.489 4.489 0 003.463 1.626h13.36a4.489 4.489 0 003.463-1.626c2.139-2.579 1.3-4.426-2.818-13.826zM6.309 14.782a.75.75 0 011.06-1.061l.667.668a2.959 2.959 0 015.8.863h3.47a.508.508 0 01.5.576 4.989 4.989 0 01-9.857.374l-.58.58a.75.75 0 01-1.06-1.061l.469-.469zM18.785 6.5a.5.5 0 00.469-.327L21.1 1.191a.652.652 0 00-.145-.821.638.638 0 00-.81.046L15.77 3.239 12.777.6A.966.966 0 0011.4.563L8.652 3.236 4 .418A.648.648 0 003.188.37a.646.646 0 00-.151.811l1.852 4.994a.5.5 0 00.469.327z\"/><circle cx=\"10.339\" cy=\"14.752\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});