define("ember-svg-jar/inlined/single-woman-search.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-search</title><path d=\"M23.414 23.414a2 2 0 000-2.828l-4.069-4.069a.251.251 0 01-.033-.313 10.508 10.508 0 10-3.112 3.108.249.249 0 01.313.033l4.069 4.069a2 2 0 002.832 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M5 14.756a.236.236 0 00.062.183.24.24 0 00.177.079H15.76a.242.242 0 00.24-.262 4.868 4.868 0 00-.465-1.684c-.338-.676-1.749-1.144-3.522-1.8a.481.481 0 01-.313-.45v-.2a.249.249 0 01.246-.25 3.75 3.75 0 002.22-.543.24.24 0 00.051-.365c-.3-.331-.51-.731-.6-2.045a3.118 3.118 0 10-6.234 0c-.089 1.314-.3 1.714-.6 2.045a.24.24 0 00.051.365 3.779 3.779 0 002.215.556.249.249 0 01.251.25v.188a.481.481 0 01-.313.45c-1.773.657-3.185 1.125-3.522 1.8A4.868 4.868 0 005 14.756z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});