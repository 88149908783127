define("ember-svg-jar/inlined/social-music-itunes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-itunes</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm5 14.49a2 2 0 11-2-2V8.77a.5.5 0 00-.7-.46l-4 1.69a.5.5 0 00-.3.46v6a2 2 0 11-2-2v-5a1 1 0 01.61-.92l7-3A1 1 0 0117 6.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});