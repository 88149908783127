define("ember-svg-jar/inlined/water-glass-half-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-glass-half-full</title><path d=\"M20.5 3c0-2.581-5.924-3-8.5-3S3.5.419 3.5 3c0 .089 1.991 19.025 2 19.113C5.506 22.72 5.694 24 12 24s6.494-1.28 6.5-1.887c.009-.087 2-19.025 2-19.113zm-2.944 8.948a.5.5 0 01-.344.424A18.225 18.225 0 0112 13a18.269 18.269 0 01-5.213-.628.5.5 0 01-.344-.424.249.249 0 01.165-.261A17.58 17.58 0 0112 11a17.58 17.58 0 015.392.687.25.25 0 01.164.261zm.3-2.829a.5.5 0 01-.212.358.5.5 0 01-.409.075A22.71 22.71 0 0012 9a22.71 22.71 0 00-5.232.552.5.5 0 01-.621-.433l-.374-3.564a.251.251 0 01.321-.266A23.233 23.233 0 0012 6a23.242 23.242 0 005.906-.711.251.251 0 01.321.266zM12 2a17.991 17.991 0 015.74.765.25.25 0 010 .47A17.991 17.991 0 0112 4a17.991 17.991 0 01-5.74-.765.249.249 0 010-.47A17.991 17.991 0 0112 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});