define("ember-svg-jar/inlined/antique-catapult-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-catapult-1</title><path d=\"M15 8.329a.25.25 0 01.467-.124l4.883 8.546a.253.253 0 00.135.112l1.438.5a.25.25 0 00.3-.36L15.151 4.628a.531.531 0 00-.081-.106.249.249 0 01-.07-.173V3.5a1 1 0 00-1-1h-1a1 1 0 00-1 1v11.358a.25.25 0 00.246.25 20.429 20.429 0 012.47.192.251.251 0 00.284-.248zM9.381 15.285a.25.25 0 00.115-.45L6.159 12.4a.25.25 0 00-.238-.031 4.418 4.418 0 01-1.593.307 4.465 4.465 0 01-2.219-.6.016.016 0 00-.02 0 .015.015 0 000 .02l2.988 4.034a.241.241 0 00.272.084 19.918 19.918 0 014.032-.929zM23.53 18.918l-5.423-1.886a18.586 18.586 0 00-12.214 0L.47 18.918a.516.516 0 00-.347.488v2.135a.517.517 0 00.517.517h22.72a.517.517 0 00.517-.517v-2.135a.516.516 0 00-.347-.488zM12 19.5a1 1 0 111-1 1 1 0 01-1 1zM.872 6.808a.25.25 0 00-.372.144 3.453 3.453 0 001.55 3.935l.486.293a3.459 3.459 0 004.2-.482.25.25 0 00-.046-.394z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});