define("ember-svg-jar/inlined/punishment-torture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>punishment-torture</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm7.2 6.3a1.031 1.031 0 01-.039 1.5l-.292.294a.5.5 0 01-.708 0l-.76-.759a.5.5 0 01.01-.717c.133-.126.245-.234.329-.318a1.031 1.031 0 011.46 0zM12 4a2 2 0 11-2 2 2 2 0 012-2zM4.8 6.3a1.031 1.031 0 011.46 0c.094.094.206.2.33.321a.5.5 0 01.007.715l-.755.764a.5.5 0 01-.708 0l-.292-.3A1.031 1.031 0 014.8 6.3zm3.356 14.145a1.276 1.276 0 01-1.282-1.939l.008-.014.052-.089a.5.5 0 01.723-.155l1.264.9a.5.5 0 01.141.66L9 19.917a1.274 1.274 0 01-.844.528zm3.025-4.238l-.847 1.44a.5.5 0 01-.722.154l-1.272-.909a.5.5 0 01-.141-.658L9.5 14v-1.5L6.894 9.871a.5.5 0 010-.706l.836-.835a.5.5 0 01.689-.018c.3.271.6.545.9.815a4 4 0 005.339 0c.3-.273.611-.549.909-.819a.5.5 0 01.689.018l.843.843a.5.5 0 010 .706L14.5 12.5V14l1.445 2.083a.5.5 0 01-.12.692l-1.307.933a.5.5 0 01-.7-.114l-1-1.387a1 1 0 00-1.637 0zm6.13 4a1.277 1.277 0 01-1.811-.289l-.214-.3a.5.5 0 01.115-.7l1.268-.906a.5.5 0 01.7.122l.252.363a1.278 1.278 0 01-.31 1.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});