define("ember-svg-jar/inlined/book-bookmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-bookmark</title><path d=\"M24 3.75a2 2 0 00-2-2h-.884a.5.5 0 01-.433-.25.5.5 0 00-.433-.25h-3a.5.5 0 00-.5.5v7.9a.25.25 0 00.427.177l1.4-1.4a.25.25 0 01.354 0l1.4 1.4a.25.25 0 00.427-.177v-5.4a.5.5 0 01.5-.5h.25a.5.5 0 01.5.5v13a.5.5 0 01-.5.5H15a5.036 5.036 0 00-1.365.178.5.5 0 01-.635-.481V6.25c0-2.056 1.089-2.424 1.8-2.488a.5.5 0 00.455-.5v-1a.5.5 0 00-.542-.5 3.775 3.775 0 00-2.372.941.5.5 0 01-.679 0A3.833 3.833 0 009 1.75H2a2 2 0 00-2 2v14a2 2 0 002 2h7c1.439 0 2 .561 2 2a1 1 0 002 0c0-1.439.561-2 2-2h7a2 2 0 002-2zm-15 14H2.5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5H9c1.74 0 2 1.566 2 2.5v11.2a.5.5 0 01-.635.481A5.036 5.036 0 009 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});