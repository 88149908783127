define("ember-svg-jar/inlined/composition-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-vertical</title><path d=\"M20.458 21.688A1.751 1.751 0 0118.8 24H5.2a1.751 1.751 0 01-1.663-2.293 29.017 29.017 0 000-19.42A1.752 1.752 0 015.2 0h13.6a1.754 1.754 0 011.661 2.3 27.851 27.851 0 00-.003 19.388zm-14.053-.825a.5.5 0 00.481.637h10.205a.5.5 0 00.48-.642 29.358 29.358 0 01.01-17.717.5.5 0 00-.481-.641H6.881a.5.5 0 00-.482.636 30.543 30.543 0 01.006 17.727z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});