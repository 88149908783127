define("ember-svg-jar/inlined/single-neutral-actions-add.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-add</title><path d=\"M11.229 12.241a.5.5 0 00-.13-.764 7.38 7.38 0 00-3.643-.977 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.14a.5.5 0 00.492-.411 7.979 7.979 0 011.847-3.848z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.25 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-2a.5.5 0 00-.5.5v2a1 1 0 01-2 0v-2a.5.5 0 00-.5-.5h-2a1 1 0 010-2h2a.5.5 0 00.5-.5v-2a1 1 0 012 0v2a.5.5 0 00.5.5h2a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});