define("ember-svg-jar/inlined/hand-drag-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-drag-alternate</title><path d=\"M18 4.748a2.492 2.492 0 00-.544.063A2.5 2.5 0 0015 2.748a2.469 2.469 0 00-.891.171 2.486 2.486 0 00-4.219 0A2.464 2.464 0 009 2.748a2.5 2.5 0 00-2.5 2.5v3.5H6a2.5 2.5 0 00-2.5 2.5v4c0 2.636 3.582 7 7.665 7H14.5a6.008 6.008 0 006-6v-9a2.5 2.5 0 00-2.5-2.5zm.5 11.5a4.005 4.005 0 01-4 4h-3.336c-3.153 0-5.665-3.591-5.665-5v-4a.5.5 0 01.5-.5h.5v3.5a1 1 0 002 0v-9a.5.5 0 111 0v5a1 1 0 002 0v-6a.5.5 0 111 0v6a1 1 0 002 0v-5a.5.5 0 111 0v6a1 1 0 002 0v-4a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});