define("ember-svg-jar/inlined/people-woman-cap-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-3</title><path d=\"M22.534 18.792a14.255 14.255 0 00-5.908-2.481.493.493 0 00-.479.173.455.455 0 00-.073.106 4.891 4.891 0 01-2.192 2.327l-.086.044a4.5 4.5 0 01-.433.174c-.107.037-.216.067-.326.1l-.1.025a.25.25 0 00-.189.243v4.126a.25.25 0 00.25.25H23a.5.5 0 00.5-.5v-2.61a2.466 2.466 0 00-.966-1.977zM11.25 19.5a.25.25 0 00-.189-.243l-.1-.025a3.085 3.085 0 01-.327-.1 4.377 4.377 0 01-.432-.174c-.03-.014-.058-.029-.087-.044a4.886 4.886 0 01-2.188-2.324.455.455 0 00-.073-.106.491.491 0 00-.479-.173 14.267 14.267 0 00-5.909 2.481A2.466 2.466 0 00.5 20.769v2.606a.5.5 0 00.5.5h10a.25.25 0 00.25-.25zM15.6 14.584a.248.248 0 01.293.02 4.272 4.272 0 002.574.77 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-3.348-1.075-1.825-6.3-2.854-8.988l-.008-.023a6.74 6.74 0 00-12.582 0l-.013.029c-.972 2.541.431 7.871-2.853 8.926a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 4.16 4.16 0 002.539-.739.251.251 0 01.29-.015 6.732 6.732 0 007.23.024zm-3.6-.459a5.256 5.256 0 01-5.25-5.25v-1.5a.25.25 0 01.25-.25h10a.25.25 0 01.25.25v1.5a5.256 5.256 0 01-5.25 5.25z\"/><circle cx=\"14\" cy=\"9.125\" r=\"1\"/><circle cx=\"10\" cy=\"9.125\" r=\"1\"/><path d=\"M12.943 12.62a2.375 2.375 0 00.441-.183.64.64 0 00-.307-1.2h-2a.64.64 0 00-.307 1.2 2.355 2.355 0 00.438.182 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});