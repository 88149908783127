define("ember-svg-jar/inlined/phone-action-remove-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-remove-user</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M13.6 13.5h1.413a.253.253 0 00.243-.329 3.992 3.992 0 00-2.625-2.488 2.25 2.25 0 10-2.264 0A3.993 3.993 0 007.5 14.5v1a.5.5 0 00.5.5h3.018a.245.245 0 00.249-.268 1.583 1.583 0 01.208-1.041A2.419 2.419 0 0113.6 13.5z\"/><path d=\"M15.75 14.75h-2.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});