define("ember-svg-jar/inlined/drone-controller-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-controller-1</title><path d=\"M10.125 14.875h-7a3 3 0 00-3 3v4.5a1.5 1.5 0 002.7.9l2.387-3.2a.5.5 0 01.4-.2h2.022a.5.5 0 01.4.2l2.39 3.2a1.5 1.5 0 002.7-.9v-4.5a3 3 0 00-2.999-3zm1.5 3.5a1 1 0 11-1-1 1 1 0 011 1zm-10 0a1 1 0 111 1 1 1 0 01-1-1zM4.786 12.831a.75.75 0 101 1.115 1.279 1.279 0 011.671 0 .75.75 0 101-1.115 2.818 2.818 0 00-3.671 0z\"/><path d=\"M4.452 11.959a3.33 3.33 0 014.347 0 .75.75 0 101-1.115 4.871 4.871 0 00-6.355 0 .75.75 0 101 1.115zM23.875.875a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h5a.75.75 0 00.75-.75zM4.375.875a.75.75 0 00.75.75h5a.75.75 0 000-1.5h-5a.75.75 0 00-.75.75zM23.625 5.375a1.5 1.5 0 00-1.5-1.5h-.5a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.249.249 0 01-.25.25h-1a2.563 2.563 0 01-1.9-.845 3.5 3.5 0 00-5.195 0 2.568 2.568 0 01-1.9.845h-1a.25.25 0 01-.25-.25v-.75a.75.75 0 00-1.5 0v.75a.249.249 0 01-.25.25h-.5a1.5 1.5 0 000 3h3.5a2.523 2.523 0 01.632.08.248.248 0 01.183.192.251.251 0 01-.094.249l-.059.045a.75.75 0 00.921 1.184 4.729 4.729 0 01.624-.411.249.249 0 01.266.015l.083.057a.249.249 0 01.069.346 2.25 2.25 0 103.749 0 .249.249 0 01.069-.346l.081-.056a.249.249 0 01.266-.015 4.753 4.753 0 012.459 4.16.75.75 0 001.5 0A6.243 6.243 0 0017.9 7.4a.25.25 0 01.089-.441 2.555 2.555 0 01.636-.081h3.5a1.5 1.5 0 001.5-1.503zm-9.5 5.25a.75.75 0 11.75-.75.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});