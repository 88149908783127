define("ember-svg-jar/inlined/educative-toys-counting-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>educative-toys-counting-1</title><path d=\"M22.25 20.5H1.75a1.75 1.75 0 000 3.5h20.5a1.75 1.75 0 000-3.5z\"/><rect x=\"2.5\" y=\"5.5\" width=\"8\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"2.5\" y=\"10.5\" width=\"8\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"2.5\" y=\"15.5\" width=\"8\" height=\"4\" rx=\"1\" ry=\"1\"/><rect x=\"13.5\" y=\"15.5\" width=\"8\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M6 4.5h1a.5.5 0 00.5-.5V1a1 1 0 00-2 0v3a.5.5 0 00.5.5zM17 14.5h1a.5.5 0 00.5-.5V1a1 1 0 00-2 0v13a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});