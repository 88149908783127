define("ember-svg-jar/inlined/sailing-boat-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat-2</title><path d=\"M1 17.4h.705a.25.25 0 01.25.25v.95a.221.221 0 01-.222.221 1 1 0 000 2h2.445a1 1 0 100-2 .222.222 0 01-.223-.222v-.95a.25.25 0 01.25-.25h3.3a.988.988 0 00.936-.689l.007-.021a.982.982 0 00-.936-1.29H1a1 1 0 000 2zM23 17.4a1 1 0 100-2h-6.655a.981.981 0 00-.936 1.289l.007.022a.988.988 0 00.936.689zM23 14.217a.75.75 0 00.75-.75V8.578a.75.75 0 00-.75-.75H10.206a.25.25 0 01-.2-.4l2.048-2.633a1 1 0 00-1.578-1.229l-3.24 4.166a.251.251 0 01-.2.1H3.478A1.239 1.239 0 002.25 9.233c.386 2.841 3.211 4.984 6.572 4.984zM11.93 20.556a2.689 2.689 0 10-2.689-2.689 2.689 2.689 0 002.689 2.689z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});