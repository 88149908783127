define("ember-svg-jar/inlined/transportation-ticket-boat-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-boat-alternate</title><path d=\"M17.5 11.76h-.855a.206.206 0 01-.14-.054l-3.141-2.854-.017-.017a1.752 1.752 0 00-1.292-.57H8.5a.75.75 0 000 1.5.25.25 0 01.25.25v1.495a.249.249 0 01-.25.25h-1a.967.967 0 00-1 1v1.506a.251.251 0 00.282.249A1.576 1.576 0 017 14.5a1.13 1.13 0 00.836-.414 2 2 0 012.828 0 1.051 1.051 0 001.672 0 2 2 0 012.828 0c.379.378.621.414 1.586.414a.171.171 0 00.15-.089l1.017-1.873.007-.013a.5.5 0 00-.424-.765zm-3.577-.16a.25.25 0 01-.233.159H10.5a.25.25 0 01-.25-.25v-1.494a.25.25 0 01.25-.25h1.555a.318.318 0 01.232.129l.013.012 1.558 1.42a.247.247 0 01.065.274zM16.75 15.75a2.968 2.968 0 01-2.47-.78.749.749 0 00-1.06 0 2.294 2.294 0 01-3.44 0 .749.749 0 00-1.06 0 2.4 2.4 0 01-1.72.78.75.75 0 000 1.5 3.722 3.722 0 002.11-.664.251.251 0 01.28 0 3.687 3.687 0 004.223 0 .249.249 0 01.278 0 4.78 4.78 0 002.859.667.75.75 0 000-1.5z\"/><path d=\"M22.687 9.52A1.5 1.5 0 0024 8.031V5a2 2 0 00-2-2H2a2 2 0 00-2 2v3.031A1.5 1.5 0 001.313 9.52a2.5 2.5 0 010 4.96A1.5 1.5 0 000 15.969V19a2 2 0 002 2h20a2 2 0 002-2v-3.031a1.5 1.5 0 00-1.313-1.489 2.5 2.5 0 010-4.96zm-.871 6.822a.25.25 0 01.184.241v2.167a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-2.167a.249.249 0 01.184-.241 4.5 4.5 0 000-8.684A.25.25 0 012 7.417V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25v2.167a.249.249 0 01-.184.241 4.5 4.5 0 000 8.684z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});