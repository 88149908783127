define("ember-svg-jar/inlined/messages-bubble-square-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-share</title><path d=\"M13.75 14a4.177 4.177 0 011.25.193.247.247 0 00.2-.024l1.752-1.051a.25.25 0 00.117-.166 4.23 4.23 0 014.172-3.436.249.249 0 00.178-.072.252.252 0 00.074-.178V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h2.766a.247.247 0 00.156-.055A4.2 4.2 0 0113.75 14z\"/><path d=\"M21.25 18.5a2.734 2.734 0 00-2.166 1.076l-2.612-1.045c.009-.094.028-.185.028-.281a2.741 2.741 0 00-.105-.712l2.979-1.788a2.858 2.858 0 10-.769-1.288l-2.979 1.788a2.752 2.752 0 10.29 3.674l2.612 1.045c-.009.094-.028.185-.028.281a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});