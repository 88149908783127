define("ember-svg-jar/inlined/social-photobucket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-photobucket</title><path d=\"M12 7.5a1 1 0 000 2 3 3 0 013 3 1 1 0 002 0 5 5 0 00-5-5z\"/><path d=\"M22 4.78h-6.06a8.58 8.58 0 00-7.87 0H6v-.91a1 1 0 00-1-1H3.13a1 1 0 00-1 1v.91H2a2 2 0 00-2 2v11.44a2 2 0 002 2h6.06a8.58 8.58 0 007.87 0H22a2 2 0 002-2V6.74a2 2 0 00-2-1.96zm-20 2h3.5a8.6 8.6 0 000 11.41l-3.5.03zm3.39 5.7A6.65 6.65 0 1112 19.13a6.66 6.66 0 01-6.65-6.65zm13.15 5.7a8.6 8.6 0 000-11.41h3.52v11.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});