define("ember-svg-jar/inlined/lucide-cloud-cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9\"/><circle cx=\"12\" cy=\"17\" r=\"3\"/><path d=\"M12 13v1M12 20v1M16 17h-1M9 17H8M15 14l-.88.88M9.88 19.12L9 20M15 20l-.88-.88M9.88 14.88L9 14\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});