define("ember-svg-jar/inlined/real-estate-action-building-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-disable</title><path d=\"M6.723 6.053l-3-1.5a.5.5 0 00-.447 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.277-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.706 10.716a.5.5 0 00.294-.455V3a.5.5 0 00-.307-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h2.013a.5.5 0 00.441-.264 8.041 8.041 0 013.752-3.52zM10 4.5h3a.5.5 0 110 1h-3a.5.5 0 010-1zM10 7h3a.5.5 0 110 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 110 1zM18 12a6 6 0 106 6 6.007 6.007 0 00-6-6zm-3.756 7.342A3.831 3.831 0 0114 18a4 4 0 014-4 3.854 3.854 0 011.342.243.5.5 0 01.179.823l-4.455 4.455a.5.5 0 01-.822-.179zM18 22a3.842 3.842 0 01-1.34-.243.5.5 0 01-.179-.822l4.454-4.455a.5.5 0 01.822.18A3.842 3.842 0 0122 18a4 4 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});