define("ember-svg-jar/inlined/skirt-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skirt-1</title><path d=\"M7.374 4.734a44.38 44.38 0 01-1.861-.259 1.95 1.95 0 01-.419-.115.5.5 0 00-.633.248 8.71 8.71 0 00-.723 3.831c0 3.749 0 7.625-1.634 10.943a1 1 0 00.268 1.218 16.937 16.937 0 003.911 2.214.5.5 0 00.684-.389 90.831 90.831 0 00.606-13A30.116 30.116 0 017.81 5.3a.5.5 0 00-.436-.566zM14.684 5.351a.5.5 0 00-.525-.424c-.78.049-1.554.074-2.328.074q-1 0-2-.055a.5.5 0 00-.521.425 28.056 28.056 0 00-.24 4.06 88.471 88.471 0 01-.681 13.619.5.5 0 00.379.566 13.793 13.793 0 006.464 0 .5.5 0 00.379-.566 88.584 88.584 0 01-.684-13.619 27.96 27.96 0 00-.243-4.08zM21.9 19.383c-1.635-3.319-1.635-7.195-1.635-10.944a8.523 8.523 0 00-.793-3.972.5.5 0 00-.684-.21 1.974 1.974 0 01-.639.218q-.774.125-1.529.219a.5.5 0 00-.433.567 29.893 29.893 0 01.243 4.17 90.693 90.693 0 00.606 13 .493.493 0 00.247.36.5.5 0 00.437.029 16.961 16.961 0 003.908-2.22 1 1 0 00.272-1.217zM5.751 2.994a38.065 38.065 0 006.08.506 38.019 38.019 0 006.077-.506.5.5 0 00.421-.493V.994a1 1 0 00-1.147-.982 35.313 35.313 0 01-10.7 0A1 1 0 005.33.994V2.5a.5.5 0 00.421.494z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});