define("ember-svg-jar/inlined/sd-card-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-subtract</title><path d=\"M15.8 9.687a.25.25 0 00.2-.244V4.915a1.511 1.511 0 00-.439-1.061L12.146.44a1.511 1.511 0 00-1.06-.44H1.5A1.5 1.5 0 000 1.5V9a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5V20a1.5 1.5 0 001.5 1.5h8.661a.251.251 0 00.222-.365A7.925 7.925 0 019.5 17.5a8.01 8.01 0 016.3-7.813zM10.25 3.5a.75.75 0 111.5 0v4a.75.75 0 11-1.5 0zm-5.5 4a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0zm3.5 0a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});