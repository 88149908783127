define("ember-svg-jar/inlined/science-electricity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-electricity</title><path d=\"M5.25 12.25h1a.75.75 0 000-1.5h-1a.75.75 0 000 1.5zM2.25 20.75H2a.5.5 0 01-.5-.5V20A.75.75 0 000 20v1.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5zM.75 17.75A.75.75 0 001.5 17v-1A.75.75 0 000 16v1a.75.75 0 00.75.75zM2.25 10.75H.75a.75.75 0 00-.75.75V13a.75.75 0 001.5 0v-.25a.5.5 0 01.5-.5h.25a.75.75 0 000-1.5zM16.5 11.5a.75.75 0 00.75.75h1a.75.75 0 000-1.5h-1a.75.75 0 00-.75.75zM23.25 19.25a.75.75 0 00-.75.75v.25a.5.5 0 01-.5.5h-.25a.75.75 0 000 1.5h1.5a.75.75 0 00.75-.75V20a.75.75 0 00-.75-.75zM23.25 15.25a.75.75 0 00-.75.75v1a.75.75 0 001.5 0v-1a.75.75 0 00-.75-.75zM23.25 10.75h-1.5a.75.75 0 000 1.5H22a.5.5 0 01.5.5V13a.75.75 0 001.5 0v-1.5a.75.75 0 00-.75-.75zM17.25 18.5h-8a.5.5 0 00-.5.5v.5a.5.5 0 01-.5.5h-1a.5.5 0 00-.5.5V22a.5.5 0 00.5.5h1a.5.5 0 01.5.5v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V19a.5.5 0 00-.5-.5zM7 17.25a.75.75 0 000-1.5h-.25a.5.5 0 01-.5-.5V15a.75.75 0 00-1.5 0v.25a.5.5 0 01-.5.5H4a.75.75 0 000 1.5h.25a.5.5 0 01.5.5V18a.75.75 0 001.5 0v-.25a.5.5 0 01.5-.5zM20 15.75h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM9.7 11.541a.5.5 0 01.307.437 1.924 1.924 0 00.986 1.671.5.5 0 01.258.437v.414a.75.75 0 001.5 0v-.414a.5.5 0 01.258-.437 1.924 1.924 0 00.986-1.671.5.5 0 01.307-.437 6 6 0 10-4.6 0zM12 2a4 4 0 011.429 7.736.5.5 0 01-.679-.467V8.018a.5.5 0 01.146-.354L14.03 6.53a.75.75 0 00-1.06-1.06l-.616.616a.5.5 0 01-.708 0l-.616-.616a.75.75 0 00-1.06 1.06l1.13 1.134a.5.5 0 01.146.354v1.251a.5.5 0 01-.679.467A4 4 0 0112 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});