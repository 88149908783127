define("ember-svg-jar/inlined/history-man-viking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-viking</title><path d=\"M19.38 4.454a.247.247 0 00.41.076 6.762 6.762 0 001.465-3.317A1 1 0 0020.786.14a.969.969 0 00-1.135.1 13.437 13.437 0 01-1.872 1.351.246.246 0 00-.121.182.25.25 0 00.071.206 7.541 7.541 0 011.651 2.475zM6.271 1.975a.247.247 0 00-.051-.388A13.432 13.432 0 014.349.236.969.969 0 003.214.14a1 1 0 00-.469 1.074A6.771 6.771 0 004.21 4.53a.247.247 0 00.41-.076 7.51 7.51 0 011.651-2.479zM.124 23.01a.99.99 0 101.979 0v-.847a.965.965 0 01.373-.776C3.74 20.423 6.657 18.8 12 18.8a21.751 21.751 0 014.442.433.247.247 0 01.044.471 27.24 27.24 0 01-6.121 1.564l-3.465.445a.99.99 0 10.25 1.964l3.468-.441c.256-.033 5.9-.774 8.854-2.9a.249.249 0 01.25-.023 10.109 10.109 0 011.8 1.077.965.965 0 01.373.776v.847a.99.99 0 101.979 0v-.847a2.926 2.926 0 00-1.151-2.349 14.834 14.834 0 00-7.078-2.726.247.247 0 01-.067-.469l.484-.223a4.5 4.5 0 002.607-4.346c0-.082.009-2.56.009-4.38a.247.247 0 00-.247-.247h-.99a.248.248 0 00-.247.247v1.434a.25.25 0 01-.069.172l-2.075 2.159-.018.021a1.137 1.137 0 01-1.264.387 5.625 5.625 0 00-3.54 0 1.181 1.181 0 01-1.323-.457l-.018-.02-2.014-2.091a.25.25 0 01-.073-.172V7.67a.248.248 0 00-.247-.247h-.99a.247.247 0 00-.247.247c0 1.513.018 4.22.011 4.324a4.526 4.526 0 002.6 4.4l.484.223a.247.247 0 01-.067.469 15.284 15.284 0 00-7.078 2.726 2.926 2.926 0 00-1.142 2.351zm10.243-9.049a.743.743 0 01.72-.56h1.826a.742.742 0 01.356 1.393 2.279 2.279 0 01-.428.179A2.875 2.875 0 0112 15.1a2.949 2.949 0 01-.839-.122 2.346 2.346 0 01-.427-.178.742.742 0 01-.367-.839z\"/><path d=\"M13.979 10a.99.99 0 00.99-.99.986.986 0 00-1.909-.35.978.978 0 00.919 1.34zM10.021 10a.99.99 0 00.989-.99.99.99 0 00-1.979 0 .99.99 0 00.99.99zM18.216 5.938a.248.248 0 00.2-.1.252.252 0 00.043-.214A6.611 6.611 0 0012 .846a6.611 6.611 0 00-6.455 4.782.247.247 0 00.239.31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});