define("ember-svg-jar/inlined/police-woman-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-3</title><path d=\"M22.248 19.936a7.1 7.1 0 00-3.056-1.157c-.236-.049-1.531-.366-2.1-.5a.5.5 0 00-.457.116A7.608 7.608 0 0112 20.5a7.608 7.608 0 01-4.639-2.109.5.5 0 00-.457-.116c-.565.138-1.859.455-2.095.5a7.107 7.107 0 00-3.057 1.157A4.529 4.529 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.529 4.529 0 00-1.752-3.564zm-4.357 3.158A2 2 0 0117 21.43v-.18a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v.18a2 2 0 01-.891 1.664l-.609.406zM1.837 7.622A46.906 46.906 0 0012 8.73a46.906 46.906 0 0010.163-1.108.75.75 0 00-.326-1.464 44.975 44.975 0 01-3.449.609l-.529-3.755A3.516 3.516 0 0014.394 0H9.606a3.516 3.516 0 00-3.465 3.012l-.529 3.755a46.3 46.3 0 01-3.449-.609.75.75 0 00-.326 1.464zM14 3.93a2.5 2.5 0 01-1.113 2.08L12 6.6l-.887-.591A2.5 2.5 0 0110 3.93V2.75a.25.25 0 01.25-.25h3.5a.25.25 0 01.25.25zM10.163 14.6a.751.751 0 00.3 1.018 3.429 3.429 0 003.063 0 .749.749 0 10-.716-1.318 1.432 1.432 0 01-.235.1 2.142 2.142 0 01-1.159 0 1.2 1.2 0 01-.234-.1.752.752 0 00-1.019.3z\"/><path d=\"M7.689 16.936A6.839 6.839 0 0012 19.25a6.839 6.839 0 004.311-2.314 2.15 2.15 0 001.539.562 5.057 5.057 0 003.54-1.705.5.5 0 00-.232-.788 2.679 2.679 0 01-1.345-1.1 5.88 5.88 0 01-.694-1.456 3.156 3.156 0 00.13-.807v-.306a2.045 2.045 0 00-2-2.083h-2.5a2 2 0 00-1.83 1.25 2.081 2.081 0 00-.08.25H11.16a2.081 2.081 0 00-.08-.25A2 2 0 009.25 9.25h-2.5a2.045 2.045 0 00-2 2.083v.306a3.156 3.156 0 00.13.807 5.88 5.88 0 01-.693 1.454A2.679 2.679 0 012.842 15a.5.5 0 00-.232.788A5.057 5.057 0 006.15 17.5a2.149 2.149 0 001.539-.564zm9.561-6.186a.548.548 0 01.5.583A1.841 1.841 0 0116 13.25a1.841 1.841 0 01-1.75-1.917.548.548 0 01.5-.583zm-1.282 4a5.525 5.525 0 01-3.968 3 5.53 5.53 0 01-3.968-3 3.277 3.277 0 003.084-2.5h1.768a3.277 3.277 0 003.084 2.497zm-9.218-4h2.5a.548.548 0 01.5.583A1.841 1.841 0 018 13.25a1.841 1.841 0 01-1.75-1.917.548.548 0 01.5-.583z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});