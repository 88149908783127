define("ember-svg-jar/inlined/arrow-thick-dot-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-dot-down</title><circle cx=\"12\" cy=\"22\" r=\"2\"/><circle cx=\"17\" cy=\"18\" r=\"2\"/><circle cx=\"21\" cy=\"14\" r=\"2\"/><circle cx=\"7\" cy=\"18\" r=\"2\"/><circle cx=\"3\" cy=\"14\" r=\"2\"/><circle cx=\"12\" cy=\"2\" r=\"2\"/><circle cx=\"12\" cy=\"7\" r=\"2\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"12\" cy=\"17\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});