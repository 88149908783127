define("ember-svg-jar/inlined/sd-card-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-disable</title><path d=\"M9.706 19.33a8.035 8.035 0 012.139-7.487A7.942 7.942 0 0115.8 9.7a.25.25 0 00.2-.244v-4.54a1.511 1.511 0 00-.439-1.061L12.148.441A1.513 1.513 0 0011.087 0H1.5A1.5 1.5 0 000 1.5V9a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5V20a1.5 1.5 0 001.5 1.5h8.637a.251.251 0 00.223-.363 7.721 7.721 0 01-.654-1.807zM8.251 3.5v4a.75.75 0 11-1.5 0v-4a.75.75 0 011.5 0zm-3.5 0v4a.75.75 0 11-1.5 0v-4a.75.75 0 011.5 0zm7 4a.75.75 0 01-1.5 0v-4a.75.75 0 011.5 0z\"/><path d=\"M19 11.17A6.513 6.513 0 0011.169 19 6.421 6.421 0 0016 23.833a6.76 6.76 0 001.49.167 6.5 6.5 0 006.341-8A6.422 6.422 0 0019 11.17zm-3.611 10.312a.25.25 0 01-.057-.4l5.753-5.753a.245.245 0 01.212-.07.25.25 0 01.184.127 4.508 4.508 0 01-6.092 6.093zm4.224-7.96a.249.249 0 01.057.4l-5.746 5.747a.25.25 0 01-.4-.066 4.441 4.441 0 01.8-5.28 4.608 4.608 0 015.289-.801z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});