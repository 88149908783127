define("ember-svg-jar/inlined/single-neutral-actions-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-shield</title><path d=\"M0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.619a.25.25 0 00.248-.282A7.006 7.006 0 019.5 16.6v-3.394a3.029 3.029 0 01.717-1.953.252.252 0 00.024-.288A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M16.19 23.4l.553.211a2.068 2.068 0 001.47 0l.554-.211C21.848 22.23 24 19.432 24 16.6v-3.394a1.541 1.541 0 00-.928-1.415 16.365 16.365 0 00-5.594-1.041 15.986 15.986 0 00-5.55 1.04A1.544 1.544 0 0011 13.206V16.6a7.716 7.716 0 005.19 6.8zm-1.69-7.65h1.75a.25.25 0 00.25-.25v-1.75a1 1 0 012 0v1.75a.25.25 0 00.25.25h1.75a1 1 0 010 2h-1.75a.25.25 0 00-.25.25v1.75a1 1 0 01-2 0V18a.25.25 0 00-.25-.25H14.5a1 1 0 010-2zM0 17.25a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.619a.25.25 0 00.248-.282A7.006 7.006 0 019.5 16.6v-3.394a3.029 3.029 0 01.717-1.953.252.252 0 00.024-.288A5.5 5.5 0 000 13.75z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M16.19 23.4l.553.211a2.068 2.068 0 001.47 0l.554-.211C21.848 22.23 24 19.432 24 16.6v-3.394a1.541 1.541 0 00-.928-1.415 16.365 16.365 0 00-5.594-1.041 15.986 15.986 0 00-5.55 1.04A1.544 1.544 0 0011 13.206V16.6a7.716 7.716 0 005.19 6.8zm-1.69-7.65h1.75a.25.25 0 00.25-.25v-1.75a1 1 0 012 0v1.75a.25.25 0 00.25.25h1.75a1 1 0 010 2h-1.75a.25.25 0 00-.25.25v1.75a1 1 0 01-2 0V18a.25.25 0 00-.25-.25H14.5a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});