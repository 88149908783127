define("ember-svg-jar/inlined/plane-trip-land-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-land-check</title><path d=\"M9.931 4.959a.248.248 0 00.143.144L14.3 6.745a.25.25 0 00.322-.327L12.653 1.58a1 1 0 00-.532-.544L9.892.082a1 1 0 00-1.329 1.273z\"/><path d=\"M17.5 9.5a.19.19 0 00.1-.351 2.826 2.826 0 00-.467-.233L5.509 4.4a.25.25 0 01-.146-.154L4.545 1.8a1 1 0 00-.554-.6L1.4.082A1 1 0 000 1v5.065a1.494 1.494 0 00.943 1.393l5.263 2.105a.25.25 0 01.013.459l-4.851 2.264a1 1 0 00.07 1.842l2.73 1.023a1 1 0 00.633.024l5.7-1.678a.246.246 0 00.143-.11A8 8 0 0117.5 9.5zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.274 5.2l-2.905 3.874a1.5 1.5 0 01-2.261.159l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.249.249 0 00.376-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});