define("ember-svg-jar/inlined/messages-bubble-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-forward</title><path d=\"M17.828 13.058a.248.248 0 00.172-.237v-.2A2 2 0 0121.171 11l.162.116a.251.251 0 00.386-.136A7.33 7.33 0 0022 9c0-4.962-4.935-9-11-9S0 4.038 0 9a8.08 8.08 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429A13.217 13.217 0 0011 18a13.1 13.1 0 001.48-.092.252.252 0 00.2-.149 8.539 8.539 0 015.148-4.701z\"/><path d=\"M20.291 12.217a.5.5 0 00-.791.407V14.2a6.942 6.942 0 00-6 6.828V23a1 1 0 002 0v-1.969a4.936 4.936 0 014-4.8v1.4a.5.5 0 00.5.5.5.5 0 00.291-.093l3.5-2.5a.5.5 0 000-.814z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});