define("ember-svg-jar/inlined/modern-weapon-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-gun</title><rect x=\"21.5\" y=\"5.5\" width=\"2.5\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M19 2.5a1 1 0 00-1 1 .5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5V3a1 1 0 00-1-1H4a1 1 0 00-1 1v.5a.5.5 0 01-.5.5A2.5 2.5 0 000 6.5V8a.5.5 0 00.5.5h19A.5.5 0 0020 8V3.5a1 1 0 00-1-1zM19.5 10H.5a.5.5 0 00-.5.5v.657a1 1 0 00.6.916 3.011 3.011 0 011.725 3.4l-1.178 5.311A1 1 0 002.123 22H7.5a.5.5 0 00.488-.392l1.036-4.662a.25.25 0 01.244-.2h2.791a2.732 2.732 0 002.594-1.841l.7-1.751A.249.249 0 0115.58 13H19a1 1 0 001-1v-1.5a.5.5 0 00-.5-.5zm-5.838 3.342l-.418 1.058a1.246 1.246 0 01-1.185.854H9.713a.25.25 0 01-.244-.3l.345-1.554a.5.5 0 01.486-.4h3.127a.252.252 0 01.207.109.254.254 0 01.028.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});