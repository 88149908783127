define("ember-svg-jar/inlined/human-resources-search-men", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-search-men</title><path d=\"M22.516 17.014l-2.868-1.273a.252.252 0 01-.148-.229v-1.143a.247.247 0 01.074-.177 3.308 3.308 0 00.779-2.23.253.253 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.251.251 0 01-.034-.265 2.531 2.531 0 00.156-1.727C20.43 5.935 18.557 5.4 17.037 5.52a5.552 5.552 0 00-1.985.2.249.249 0 00-.171.184c-.033.148-.069.29-.1.425a.247.247 0 00.016.173A3.431 3.431 0 0115.12 8a3.275 3.275 0 01-.658 2.03.247.247 0 00-.05.115 5.447 5.447 0 01-.7 1.973.251.251 0 00-.034.156 4.325 4.325 0 00.271 1.158.248.248 0 00.14.139l2.256.894a4.974 4.974 0 013.155 4.649V22a2.041 2.041 0 01-.012.223.249.249 0 00.249.277H23.5a.5.5 0 00.5-.5v-2.7a2.5 2.5 0 00-1.484-2.286z\"/><path d=\"M18 19.114a3.483 3.483 0 00-2.211-3.255l-4.131-1.637a.249.249 0 01-.158-.232v-1.55a.25.25 0 01.108-.206 3.467 3.467 0 001.373-2.744.251.251 0 01.1-.181A1.593 1.593 0 0013.62 8a1.7 1.7 0 00-.412-1.2.251.251 0 01-.059-.249 6.205 6.205 0 00.336-3.174C13.139 1.992 11.256 1.5 9.75 1.5c-1.292 0-2.86.362-3.5 1.34a.252.252 0 01-.206.114 1.413 1.413 0 00-1.069.433c-.713.769-.357 2.253-.1 3.161a.251.251 0 01-.065.244 1.2 1.2 0 00-.207.279A1.946 1.946 0 004.38 8a1.593 1.593 0 00.539 1.309.251.251 0 01.1.181 3.467 3.467 0 001.373 2.744.253.253 0 01.108.206v1.551a.249.249 0 01-.158.232L2.206 15.87A3.482 3.482 0 000 19.122V22a.5.5 0 00.5.5h17a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});