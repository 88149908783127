define("ember-svg-jar/inlined/seo-search-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seo-search-star</title><path d=\"M1.514 15.658l-.706.707a.5.5 0 00.353.853h2.828a.5.5 0 00.5-.5V13.89a.5.5 0 00-.853-.354l-.3.3a.251.251 0 01-.4-.059A7.921 7.921 0 012 10a8.009 8.009 0 018-8 7.928 7.928 0 013.517.812 1 1 0 00.879-1.8A9.907 9.907 0 0010 0 10.011 10.011 0 000 10a9.9 9.9 0 001.548 5.347.25.25 0 01-.034.311z\"/><path d=\"M14.927 9.07A.613.613 0 0014.5 8h-2.34a.247.247 0 01-.224-.141l-1.214-2.5a.662.662 0 00-1.177 0L8.325 7.86A.25.25 0 018.1 8H5.761a.612.612 0 00-.428 1.07l2.051 2.142a.252.252 0 01.048.273L6.3 14.094a.654.654 0 00.931.818l2.778-1.565a.249.249 0 01.245 0l2.778 1.565a.654.654 0 00.931-.818l-1.134-2.609a.251.251 0 01.049-.273z\"/><path d=\"M23.411 20.583l-3.8-3.8a1 1 0 00-1.259-.127.25.25 0 01-.314-.032l-.141-.141a.249.249 0 01-.02-.33 9.988 9.988 0 00.574-11.5.251.251 0 01.035-.311l.706-.706a.5.5 0 00-.353-.854h-2.828a.5.5 0 00-.5.5v2.829a.5.5 0 00.308.462.5.5 0 00.545-.108l.295-.295a.249.249 0 01.214-.07.246.246 0 01.183.129 8 8 0 01-10.632 10.93 1 1 0 10-.9 1.788 10 10 0 0010.625-1.069.25.25 0 01.331.02l.14.14a.25.25 0 01.032.315 1 1 0 00.13 1.261l3.8 3.8a2 2 0 002.828-2.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});