define("ember-svg-jar/inlined/single-woman-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-decrease</title><path d=\"M23.679 4.033a.5.5 0 00-.551.133l-1.256 1.4a.251.251 0 01-.187.082.246.246 0 01-.185-.088l-2.565-2.89a1.251 1.251 0 00-1.716-.146l-1.379 1.1a.25.25 0 01-.34-.024L12.941.677a1.25 1.25 0 00-1.882 1.646l3.5 4a1.25 1.25 0 001.722.153l1.387-1.11a.25.25 0 01.343.03l1.843 2.077a.249.249 0 010 .333l-1.224 1.36A.5.5 0 0019 10h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.321-.467zM11.983 17.853l-1.8-6.286a4.256 4.256 0 00-1.239-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.319a.25.25 0 01.246.2L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.246 2.246 0 01-2.159-1.64.25.25 0 01.159-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.16.3 2.248 2.248 0 01-2.159 1.643z\"/><path d=\"M23.679 4.033a.5.5 0 00-.551.133l-1.256 1.4a.251.251 0 01-.187.082.246.246 0 01-.185-.088l-2.565-2.89a1.251 1.251 0 00-1.716-.146l-1.379 1.1a.25.25 0 01-.34-.024L12.941.677a1.25 1.25 0 00-1.882 1.646l3.5 4a1.25 1.25 0 001.722.153l1.387-1.11a.25.25 0 01.343.03l1.843 2.077a.249.249 0 010 .333l-1.224 1.36A.5.5 0 0019 10h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.321-.467zM11.983 17.853l-1.8-6.286a4.256 4.256 0 00-1.239-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.319a.25.25 0 01.246.2L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.483-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.246 2.246 0 01-2.159-1.64.25.25 0 01.159-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.16.3 2.248 2.248 0 01-2.159 1.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});