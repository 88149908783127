define("ember-svg-jar/inlined/smart-watch-circle-cash-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-cash-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.247.247 0 01.109.148l.908 3.406A.5.5 0 008.5 24h7a.5.5 0 00.482-.369l.935-3.427a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M14.75 13.41a2.081 2.081 0 00-1.316-1.942l-2.062-.825a.592.592 0 01.219-1.143h2.034a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V7.5a.75.75 0 10-1.5 0v.3a.248.248 0 01-.2.244 2.092 2.092 0 00-.359 3.992l2.063.825A.592.592 0 0112.658 14h-2.033a.75.75 0 000 1.5h.5a.249.249 0 01.25.25V16a.75.75 0 001.5 0v-.3a.248.248 0 01.2-.244 2.1 2.1 0 001.675-2.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});