define("ember-svg-jar/inlined/rotate-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rotate-front</title><path d=\"M23.9 6.8a.5.5 0 00-.4-.8h-.25a.25.25 0 01-.25-.25V4a3 3 0 00-3-3h-1.75A.25.25 0 0118 .75V.5a.5.5 0 00-.8-.4l-2 1.5a.5.5 0 000 .8l2 1.5a.5.5 0 00.3.1.508.508 0 00.224-.053A.5.5 0 0018 3.5v-.25a.25.25 0 01.25-.25H20a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-.25a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0zM20 12a2 2 0 00-2-2h-4V6a2 2 0 00-2-2H2a2 2 0 00-2 2v10a2 2 0 002 2h4v4a2 2 0 002 2h10a2 2 0 002-2zM2 6.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5zm16 15a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-3.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1.25a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1h-1.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h3.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});