define("ember-svg-jar/inlined/phone-action-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-settings</title><path d=\"M15.438 11.614a.169.169 0 010-.228l.686-.74A1.392 1.392 0 0014.8 8.34l-.982.226a.161.161 0 01-.19-.112l-.295-.968a1.388 1.388 0 00-2.656 0l-.295.969a.16.16 0 01-.19.111l-.987-.226a1.393 1.393 0 00-1.33 2.306l.687.74a.169.169 0 010 .228l-.686.74a1.392 1.392 0 001.329 2.306l.982-.226a.162.162 0 01.19.112l.295.969a1.388 1.388 0 002.656 0l.295-.969a.163.163 0 01.19-.111l.982.226a1.393 1.393 0 001.33-2.306zM12 13a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 13z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});