define("ember-svg-jar/inlined/expand-horizontal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-horizontal-1</title><path d=\"M4.793 17.207a1 1 0 00.707.293.989.989 0 00.383-.077A1 1 0 006.5 16.5v-3a.25.25 0 01.25-.25h10.5a.25.25 0 01.25.25v3a1 1 0 00.617.923.989.989 0 00.383.077 1 1 0 00.707-.293l4.5-4.5a1 1 0 000-1.414l-4.5-4.5A1 1 0 0017.5 7.5v3a.25.25 0 01-.25.25H6.75a.25.25 0 01-.25-.25v-3a1 1 0 00-1.707-.707l-4.5 4.5a1 1 0 000 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});