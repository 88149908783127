define("ember-svg-jar/inlined/underwear-bikini-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-bikini-bottom</title><path d=\"M23.394 4.661c-.832-.847-2.557-1.02-4.1.522a5.869 5.869 0 00-1.255 2.97.5.5 0 01-.42.408A39.1 39.1 0 0112 8.979a39.1 39.1 0 01-5.621-.418.5.5 0 01-.42-.408A5.869 5.869 0 004.7 5.183C3.579 4.058 1.719 3.527.606 4.662c-.985 1-.742 2.81.554 4.11A4.818 4.818 0 003.327 9.85a.5.5 0 01.354.632c-.145.467-.507 1.341-1.516 4.152a1 1 0 00.594 1.284 1.016 1.016 0 00.345.061 1 1 0 00.939-.656L5.2 12.164a.25.25 0 01.437-.06 34.333 34.333 0 013.923 6.94 1.482 1.482 0 001.379.935h2.123a1.482 1.482 0 001.378-.934 34.34 34.34 0 013.923-6.945.25.25 0 01.437.061l1.158 3.158a1 1 0 00.939.656 1.015 1.015 0 00.344-.061 1 1 0 00.594-1.284c-1-2.8-1.369-3.675-1.516-4.153a.5.5 0 01.354-.631 4.808 4.808 0 002.168-1.079c1.295-1.295 1.538-3.1.553-4.106zm-21.36 1.4c.085-.089.682-.038 1.255.535a2.362 2.362 0 01.446.879.251.251 0 01-.061.254.247.247 0 01-.254.061 2.244 2.244 0 01-.845-.432c-.59-.59-.633-1.202-.541-1.296zm19.391 1.3a2.229 2.229 0 01-.845.433.25.25 0 01-.315-.315 2.362 2.362 0 01.446-.879c.481-.482 1.142-.649 1.254-.536.093.092.05.704-.54 1.294z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});