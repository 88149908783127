define("ember-svg-jar/inlined/plant-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-pot</title><path d=\"M7.5 13.5h.25a.75.75 0 01.75.75.75.75 0 00.75.75h1.5a.5.5 0 00.5-.5v-6a.75.75 0 011.5 0v6a.5.5 0 00.5.5h1.5a.75.75 0 00.75-.75.75.75 0 01.75-.75h.25a1.75 1.75 0 000-3.5h-1.25a.75.75 0 010-1.5h.25a2 2 0 000-4c-.9 0-1.458.917-2.048-.1a.943.943 0 01.255-1.187A1.726 1.726 0 0014.5 1.75 1.749 1.749 0 0012.75 0h-1.5A1.75 1.75 0 009.5 1.75a1.726 1.726 0 00.793 1.467.942.942 0 01.255 1.183c-.589 1.017-1.148.1-2.048.1a2 2 0 000 4h.25a.75.75 0 010 1.5H7.5a1.75 1.75 0 000 3.5zM18.128 16.906a1.006 1.006 0 00-.8-.406H6.677a1 1 0 00-.956 1.294l1.149 3.735A3.484 3.484 0 0010.216 24h3.568a3.484 3.484 0 003.346-2.471l1.148-3.729a1 1 0 00-.15-.894z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});