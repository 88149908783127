define("ember-svg-jar/inlined/road-sign-turn-left-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left-2-alternate</title><path d=\"M12.5 9h-2.25a.25.25 0 01-.25-.25V8a.5.5 0 00-.854-.354L7 9.793a1 1 0 000 1.414l2.146 2.147A.5.5 0 0010 13v-.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V16a1 1 0 001 1h1a1 1 0 001-1v-4.5A2.5 2.5 0 0012.5 9z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});