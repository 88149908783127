define("ember-svg-jar/inlined/real-estate-search-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-building</title><path d=\"M23.561 23.561a1.5 1.5 0 000-2.122L19.2 17.077a.5.5 0 01-.058-.638 10.54 10.54 0 10-2.7 2.7.5.5 0 01.638.058l4.362 4.363a1.5 1.5 0 002.122 0zM2 10.5a8.5 8.5 0 118.5 8.5A8.51 8.51 0 012 10.5z\"/><path d=\"M13.724 6.553l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 007 7v7.5a.5.5 0 00.5.5H9a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V7a.5.5 0 00-.276-.447zM12 11H9a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5H9a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});