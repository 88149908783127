define("ember-svg-jar/inlined/crime-man-thief-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-1</title><path d=\"M13.62 12.157a.64.64 0 00-.315-.719 2.423 2.423 0 00-.436-.182 3.137 3.137 0 00-1.737 0 2.408 2.408 0 00-.435.18.639.639 0 00.3 1.2h2a.641.641 0 00.623-.479z\"/><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.6a2.3 2.3 0 00-.981-1.871 15.687 15.687 0 00-6.8-2.435.249.249 0 01-.214-.247v-1.24a.1.1 0 01.046-.081A7 7 0 0019 9V7A7 7 0 005 7v2a6.994 6.994 0 003.38 5.981.253.253 0 01.12.214v1.156a.249.249 0 01-.214.247 15.687 15.687 0 00-6.8 2.435A2.3 2.3 0 00.5 20.9zM16.25 7.322a.75.75 0 01-.75.75h-1a.75.75 0 010-1.5h1a.75.75 0 01.75.75zm-6 0a.75.75 0 01-.75.75h-1a.75.75 0 110-1.5h1a.75.75 0 01.75.75zM9.105 2.95a.25.25 0 01.395.2v.35a.5.5 0 001 0V2.442a.25.25 0 01.184-.241 4.834 4.834 0 01.527-.113.245.245 0 01.2.057.248.248 0 01.088.19v.9a.5.5 0 001 0v-.9a.251.251 0 01.289-.247 4.834 4.834 0 01.527.113.249.249 0 01.184.241V3.5a.5.5 0 001 0v-.346a.248.248 0 01.135-.222.251.251 0 01.26.018 5 5 0 011.5 1.681.25.25 0 01-.22.369H7.825a.25.25 0 01-.22-.369 5 5 0 011.5-1.681zm-1.726 7.9a.25.25 0 01.221-.35 6.407 6.407 0 004.272-1.637.186.186 0 01.256 0A6.411 6.411 0 0016.4 10.5a.25.25 0 01.221.342 4.962 4.962 0 01-9.242 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});