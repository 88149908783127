define("ember-svg-jar/inlined/style-two-pin-tunnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-tunnel</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm0 5a1 1 0 111-1 1 1 0 01-1 1 5.5 5.5 0 015.5 4.95.5.5 0 01-.5.55H7a.5.5 0 01-.5-.55A5.5 5.5 0 0112 5zm6 8H6a.75.75 0 010-1.5h12a.75.75 0 010 1.5z\"/><path d=\"M9.7 7.728a.5.5 0 00.287-.092A3.479 3.479 0 0112 7a.5.5 0 000-1 4.472 4.472 0 00-2.589.819.5.5 0 00.289.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});