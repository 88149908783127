define("ember-svg-jar/inlined/reflect-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reflect-left</title><path d=\"M12 8a1 1 0 00-1 1v14a1 1 0 002 0V9a1 1 0 00-1-1zM21.939 8.646l-6.293 6.293a1.5 1.5 0 000 2.122l6.293 6.293A1.174 1.174 0 0024 22.5v-13a1.173 1.173 0 00-2.061-.854zM22 19.982a.25.25 0 01-.427.177l-3.982-3.982a.25.25 0 010-.354l3.982-3.982a.25.25 0 01.427.177zM2.061 8.646A1.173 1.173 0 000 9.5v13a1.175 1.175 0 002.061.854l6.293-6.293a1.5 1.5 0 000-2.122z\"/><path d=\"M10.008 3.914l-.761-.173a.243.243 0 01-.111-.415A4.948 4.948 0 0112.5 2a5.006 5.006 0 015 5 1 1 0 002 0A7 7 0 006.75 3.018a.245.245 0 01-.254.1L5.107 2.8a1 1 0 00-1.116 1.424l1.614 3.223a1.023 1.023 0 001.435.395l3.286-2.111a1 1 0 00-.318-1.817z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});