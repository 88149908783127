define("ember-svg-jar/inlined/livestock-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>livestock-ram</title><path d=\"M21.5 8.375s-6.25-7.5-10.25-7.5c-2.566 0-3.669 2.195-3.934 3.769A5.285 5.285 0 000 9.519a1 1 0 002 0A3.279 3.279 0 017.864 7.5l.037.044a2.753 2.753 0 002.349 1.331 4.012 4.012 0 001.982-.45 1.211 1.211 0 00.518-.95 1.344 1.344 0 00-1.5-1.5.5.5 0 010-1 2.324 2.324 0 012.5 2.5 2.385 2.385 0 01-1.5 2.1v2.8c0 1.726 1.191 2.26 2.751 2.426a3.6 3.6 0 01.353 2.994 2.258 2.258 0 01-2.777 1.033 1 1 0 00-1.334.667 2.213 2.213 0 01-4.343-.582 1 1 0 00-2 0 4.213 4.213 0 007.9 2.029 4.255 4.255 0 004.326-2.221 4.343 4.343 0 00.051-3.846H21c1.5 0 3 0 3-1.5s-.5-3-2.5-5zm-3.25 1.5a1 1 0 111-1 1 1 0 01-1 1.003z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});