define("ember-svg-jar/inlined/gesture-two-fingers-swipe-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-swipe-right</title><path d=\"M14.583 14.294a.249.249 0 01-.14-.293l1.247-4.658a.93.93 0 00-1.8-.454l-1.43 4.592a.249.249 0 01-.3.168l-.585-.143a.25.25 0 01-.19-.243V8.705a1 1 0 00-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.856-1.449-2.762.224L9.08 20.5a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.411c.2-1.1.664-3.684.775-4.166.253-1.091-.661-1.427-1.615-1.852z\"/><path d=\"M23.389 4.153a.751.751 0 00-1.411-.215l-.486.972a.25.25 0 01-.322.117 23.949 23.949 0 00-9.283-1.754A20.178 20.178 0 00.565 6.32a1 1 0 101.119 1.658 18.2 18.2 0 0110.2-2.7 22.012 22.012 0 018.357 1.535.254.254 0 01.138.144.251.251 0 01-.011.2l-.35.7a.751.751 0 00.951 1.031l2.436-.988a.75.75 0 00.461-.816z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});