define("ember-svg-jar/inlined/paginate-filter-android-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-android-alternate</title><path d=\"M19 12H9a.5.5 0 00-.5.5v3A1.5 1.5 0 0010 17h8a1.5 1.5 0 001.5-1.5v-3a.5.5 0 00-.5-.5zM19.23 5.181a1 1 0 10-1.459-1.367l-1.23 1.31a5.462 5.462 0 00-5.082 0l-1.229-1.31a1 1 0 10-1.459 1.367l1.11 1.184A5.469 5.469 0 008.5 10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5 5.469 5.469 0 00-1.381-3.632zm-6.98 3.566A.75.75 0 1113 8a.75.75 0 01-.75.747zm3.5 0A.75.75 0 1116.5 8a.75.75 0 01-.75.747z\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});