define("ember-svg-jar/inlined/picture-polaroid-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-polaroid-woman</title><path d=\"M14.334 14.664a6.577 6.577 0 00-9.753 0 .5.5 0 00.37.836h9.013a.5.5 0 00.37-.836z\"/><path d=\"M4.867 10.959a1 1 0 101.682 1.082 4.964 4.964 0 00.394-.747.251.251 0 01.364-.114 4.044 4.044 0 004.3 0 .25.25 0 01.363.113 5 5 0 00.4.749 1 1 0 001.68-1.086 3.852 3.852 0 01-.539-2.119V7.749a4.051 4.051 0 00-8.1 0v1.089a3.86 3.86 0 01-.544 2.121zM9.458 9.8a2.053 2.053 0 01-2.022-1.711.251.251 0 01.152-.273 5.009 5.009 0 001.7-1.149.252.252 0 01.359 0 5 5 0 001.683 1.146.25.25 0 01.151.272A2.053 2.053 0 019.458 9.8z\"/><path d=\"M17 19a2 2 0 002-2V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2zM2 2.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v14a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5z\"/><path d=\"M23.992 9.235a2 2 0 00-1.812-2.172l-1-.091A1 1 0 1021 8.964l.5.044a.5.5 0 01.453.544L20.863 21.5a.5.5 0 01-.543.452L6.877 20.728a1 1 0 10-.177 1.991l13.941 1.274a2 2 0 002.174-1.811z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});