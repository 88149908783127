define("ember-svg-jar/inlined/real-estate-sign-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-rent</title><path d=\"M3.875 10.5a.125.125 0 00.125.125.625.625 0 000-1.25.125.125 0 00-.125.125z\"/><path d=\"M22 0H1.5a1 1 0 000 2H3a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5H1.5A1.5 1.5 0 000 6.25v10a1.5 1.5 0 001.5 1.5H19a1.5 1.5 0 001.5-1.5v-10a1.5 1.5 0 00-1.5-1.5h-1.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V23a1 1 0 002 0V2a2 2 0 00-2-2zM5.786 13.429a.625.625 0 11-1.072.642l-.375-.624a.25.25 0 00-.464.129v.174a.625.625 0 01-1.25 0v-5a.625.625 0 01.625-.625H4a1.872 1.872 0 01.976 3.475.251.251 0 00-.084.342zm3.464-2.3a.625.625 0 010 1.25H8.125a.25.25 0 00-.25.25v.125a.375.375 0 00.375.375h1a.625.625 0 010 1.25h-1a1.627 1.627 0 01-1.625-1.629v-3A1.627 1.627 0 018.25 8.125h1a.625.625 0 010 1.25h-1a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zm4.625 2.625a.625.625 0 01-1.2.232l-.313-.782a.25.25 0 00-.482.093v.457a.625.625 0 01-1.25 0v-5a.625.625 0 011.205-.232l.313.782a.25.25 0 00.482-.093V8.75a.625.625 0 011.25 0zM6 4.75a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h8.5a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5zm11.25 3.375a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.125a.625.625 0 01-1.25 0V9.625a.25.25 0 00-.25-.25h-.125a.625.625 0 010-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});