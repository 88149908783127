define("ember-svg-jar/inlined/car-dashboard-lights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-lights</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm2.5 16.751a1.5 1.5 0 01-1.5-1.5v-7a1.5 1.5 0 011.5-1.5 5 5 0 010 10zM10.531 12.5H5.5a.75.75 0 110-1.5h5.031a.75.75 0 010 1.5zm.75 2.75a.75.75 0 01-.75.75H5.5a.75.75 0 010-1.5h5.031a.75.75 0 01.75.751zM10.531 9H5.5a.75.75 0 010-1.5h5.031a.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});