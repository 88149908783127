define("ember-svg-jar/inlined/lighthouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lighthouse</title><path d=\"M8.751 7.25A.251.251 0 019 7.5v1.75a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V7.5a.251.251 0 01.25-.25.75.75 0 000-1.5h-6.5a.75.75 0 000 1.5zM9.55 4.25h4.9a.5.5 0 00.49-.6 3.005 3.005 0 00-2.083-2.273A.5.5 0 0112.5.9V.5a.5.5 0 00-1 0v.4a.5.5 0 01-.357.479 3 3 0 00-2.082 2.272.5.5 0 00.489.6zM.783 5.226l4.5 1a1 1 0 10.434-1.952l-4.5-1a1 1 0 00-.434 1.952zM6.463 8.733a1 1 0 00-1.231-.7l-4.5 1.25a1 1 0 00.536 1.927l4.5-1.25a1 1 0 00.695-1.227zM18.768 6.464l4.5-1.25a1 1 0 00-.536-1.927l-4.5 1.25a1 1 0 00.536 1.927zM23.268 9.287l-4.5-1.25a1 1 0 00-.536 1.927l4.5 1.25a1 1 0 00.536-1.927zM18.251 22h-.857a.558.558 0 01-.544-.5l-.75-8.589a.146.146 0 01.148-.161.75.75 0 000-1.5h-8.5a.75.75 0 000 1.5.146.146 0 01.147.161L7.151 21.5a.558.558 0 01-.544.5h-.856a1 1 0 000 2H9.5a.5.5 0 00.5-.5v-2.25a2 2 0 014 0v2.25a.5.5 0 00.5.5h3.75a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});