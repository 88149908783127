define("ember-svg-jar/inlined/real-estate-market-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-building</title><path d=\"M20.494 13.493h-2a.5.5 0 00-.5.469 7.5 7.5 0 11-7.955-7.955.5.5 0 00.469-.5v-2a.5.5 0 00-.525-.5 10.5 10.5 0 1011.01 11.012.5.5 0 00-.499-.526zM14.031.007a.5.5 0 00-.525.5v2a.5.5 0 00.469.5 7.513 7.513 0 017.017 7.017.5.5 0 00.5.468h2a.5.5 0 00.5-.525 10.515 10.515 0 00-9.961-9.96z\"/><path d=\"M12.006 17.994h1.5a.5.5 0 00.5-.5v-7.5a.5.5 0 00-.276-.447l-3-1.5a.5.5 0 00-.447 0l-3 1.5a.5.5 0 00-.277.447v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5zm-3-7.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});