define("ember-svg-jar/inlined/laptop-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-settings</title><path d=\"M9.277 15.132a2.244 2.244 0 01-1.218-1.25.492.492 0 00-.47-.36H.509a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.431 5.431 0 005 3.31h3.962a.251.251 0 00.25-.254v-.246a8.909 8.909 0 01.151-1.622.25.25 0 00-.135-.268zM3.509 12.032a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v5.294a.249.249 0 00.222.248 8.216 8.216 0 011.455.3.25.25 0 00.323-.239v-5.6a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 .997z\"/><circle cx=\"17.74\" cy=\"17.001\" r=\"1.5\"/><path d=\"M22.521 17.314a.462.462 0 010-.626l.987-1.064a1.782 1.782 0 00-1.7-2.953L20.4 13a.457.457 0 01-.534-.312L19.44 11.3a1.775 1.775 0 00-3.4 0l-.425 1.391a.452.452 0 01-.533.312l-1.411-.327a1.783 1.783 0 00-1.7 2.953l.988 1.064a.462.462 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.453.453 0 01.532.31l.425 1.393a1.745 1.745 0 001.7 1.261 1.747 1.747 0 001.7-1.261l.423-1.393a.449.449 0 01.534-.31l1.411.327a1.783 1.783 0 001.7-2.953zM17.74 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});