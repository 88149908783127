define("ember-mobiledoc-dom-renderer/components/render-mobiledoc/component", ["exports", "@ember/array", "@glimmer/component", "@ember/runloop", "ember", "ember-mobiledoc-dom-renderer", "ember-mobiledoc-dom-renderer/utils/document", "ember-mobiledoc-dom-renderer/utils/polyfilled-assign"], function (_exports, _array, _component, _runloop, _ember, _emberMobiledocDomRenderer, _document, _polyfilledAssign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CARD_ELEMENT_CLASS = _exports.ATOM_ELEMENT_CLASS = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const {
    uuid
  } = _ember.default;
  const ADD_CARD_HOOK = 'addComponentCard';
  const REMOVE_CARD_HOOK = 'removeComponentCard';
  const ADD_ATOM_HOOK = 'addComponentAtom';
  const REMOVE_ATOM_HOOK = 'removeComponentAtom';
  const CARD_TAG_NAME = 'div';
  const ATOM_TAG_NAME = 'span';
  const UUID_PREFIX = '__rendered-mobiledoc-entity-';
  const CARD_ELEMENT_CLASS = _exports.CARD_ELEMENT_CLASS = '__rendered-mobiledoc-card';
  const ATOM_ELEMENT_CLASS = _exports.ATOM_ELEMENT_CLASS = '__rendered-mobiledoc-atom';
  const CARD_HOOKS = {
    ADD: ADD_CARD_HOOK,
    REMOVE: REMOVE_CARD_HOOK
  };
  const ATOM_HOOKS = {
    ADD: ADD_ATOM_HOOK,
    REMOVE: REMOVE_ATOM_HOOK
  };
  function rendererFor(type) {
    let hookNames;
    if (type === 'card') {
      hookNames = CARD_HOOKS;
    } else if (type === 'atom') {
      hookNames = ATOM_HOOKS;
    }
    return function ({
      env,
      options
    }) {
      let {
        onTeardown
      } = env;
      let addHook = options[hookNames.ADD];
      let removeHook = options[hookNames.REMOVE];
      let {
        entity,
        element
      } = addHook(...arguments);
      onTeardown(() => removeHook(entity));
      return element;
    };
  }
  function createComponentCard(name) {
    return {
      name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('card')
    };
  }
  function createComponentAtom(name) {
    return {
      name,
      type: _emberMobiledocDomRenderer.RENDER_TYPE,
      render: rendererFor('atom')
    };
  }
  class _default extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_teardownRender", void 0);
      // @private
      _defineProperty(this, "_componentCards", (0, _array.A)());
      _defineProperty(this, "_componentAtoms", (0, _array.A)());
    }
    // pass in an array of card names that the mobiledoc may have. These
    // map to component names using `cardNameToComponentName`
    get cardNames() {
      return this.args.cardNames || [];
    }

    // pass in an array of atom names that the mobiledoc may have. These
    // map to component names using `atomNameToComponentName`
    get atomNames() {
      return this.args.atomNames || [];
    }
    get mdcCards() {
      return this.cardNames.map(createComponentCard);
    }
    get mdcAtoms() {
      return this.atomNames.map(createComponentAtom);
    }
    get renderedMobiledoc() {
      if (this._teardownRender) {
        this._teardownRender();
        // eslint-disable-next-line ember/no-side-effects
        this._teardownRender = null;
      }
      let dom = (0, _document.getDocument)(this);
      let {
        mobiledoc
      } = this.args;
      let renderer = new _emberMobiledocDomRenderer.default(this._buildRendererOptions(dom));
      let {
        result,
        teardown
      } = renderer.render(mobiledoc);

      // result is a document fragment, and glimmer2 errors when cleaning it up.
      // We must append the document fragment to a static wrapper.
      // Related: https://github.com/tildeio/glimmer/pull/331 and
      //          https://github.com/yapplabs/ember-wormhole/issues/66#issuecomment-246207622
      let wrapper = this._createElement(dom, 'div');
      wrapper.appendChild(result);

      // eslint-disable-next-line ember/no-side-effects
      this._teardownRender = teardown;
      return wrapper;
    }
    _buildRendererOptions(dom) {
      let options = {
        dom,
        cards: this.mdcCards,
        atoms: this.mdcAtoms
      };
      ['mobiledoc', 'sectionElementRenderer', 'markupElementRenderer', 'unknownCardHandler', 'unknownAtomHandler'].forEach(option => {
        let value = this.args[option];
        if (value) {
          options[option] = value;
        }
      });
      let passedOptions = this.args.cardOptions;
      let cardOptions = this._cardOptions;
      cardOptions = passedOptions ? (0, _polyfilledAssign.default)(passedOptions, cardOptions) : cardOptions;
      options.cardOptions = cardOptions;
      return options;
    }
    get _cardOptions() {
      return {
        [ADD_CARD_HOOK]: ({
          env,
          options,
          payload
        }) => {
          let {
            name: cardName,
            dom
          } = env;
          let classNames = [CARD_ELEMENT_CLASS, `${CARD_ELEMENT_CLASS}-${cardName}`];
          let element = this._createElement(dom, CARD_TAG_NAME, classNames);
          let componentName = this.cardNameToComponentName(cardName);
          let card = {
            componentName,
            destinationElement: element,
            payload,
            options
          };
          this.addCard(card);
          return {
            entity: card,
            element
          };
        },
        [ADD_ATOM_HOOK]: ({
          env,
          options,
          value,
          payload
        }) => {
          let {
            name: atomName,
            dom
          } = env;
          let classNames = [ATOM_ELEMENT_CLASS, `${ATOM_ELEMENT_CLASS}-${atomName}`];
          let element = this._createElement(dom, ATOM_TAG_NAME, classNames);
          let componentName = this.atomNameToComponentName(atomName);
          let atom = {
            componentName,
            destinationElement: element,
            payload,
            value,
            options
          };
          this.addAtom(atom);
          return {
            entity: atom,
            element
          };
        },
        [REMOVE_CARD_HOOK]: card => this.removeCard(card),
        [REMOVE_ATOM_HOOK]: atom => this.removeAtom(atom)
      };
    }
    willDestroy() {
      if (this._teardownRender) {
        this._teardownRender();
      }
      return super.willDestroy(...arguments);
    }

    // pass the argument to change the mapping of card name -> component name
    cardNameToComponentName(name) {
      if (this.args.cardNameToComponentName) {
        return this.args.cardNameToComponentName(name);
      }
      return name;
    }

    // pass the argument to change the mapping of atom name -> component name
    atomNameToComponentName(name) {
      if (this.args.atomNameToComponentName) {
        return this.args.atomNameToComponentName(name);
      }
      return name;
    }
    addCard(card) {
      this._componentCards.pushObject(card);
    }
    removeCard(card) {
      (0, _runloop.join)(() => {
        this._componentCards.removeObject(card);
      });
    }
    addAtom(atom) {
      this._componentAtoms.pushObject(atom);
    }
    removeAtom(atom) {
      (0, _runloop.join)(() => {
        this._componentAtoms.removeObject(atom);
      });
    }
    generateUuid() {
      return `${UUID_PREFIX}${uuid()}`;
    }
    _createElement(dom, tagName, classNames = []) {
      let el = dom.createElement(tagName);
      el.setAttribute('id', this.generateUuid());
      el.setAttribute('class', classNames.join(' '));
      return el;
    }
  }
  _exports.default = _default;
});