define("ember-svg-jar/inlined/smiley-spoilt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-spoilt</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 7a2 2 0 11-2 2 2 2 0 012-2zm-1.269 9.359l1.617-1.939a2 2 0 013.232.221l.708 1.133a.251.251 0 00.424 0l.708-1.133a2 2 0 013.232-.22l1.617 1.938a1 1 0 01-1.538 1.282l-1.4-1.675a.25.25 0 00-.4.028l-.731 1.179a2 2 0 01-3.39 0l-.737-1.179a.25.25 0 00-.4-.028l-1.404 1.675a1 1 0 01-1.538-1.282zM16.5 11a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});