define("ember-svg-jar/inlined/arrow-thick-circle-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-up-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.563 12.705l-.3.3a1.055 1.055 0 01-1.443.031l-2.585-2.376c-.4-.372-.737-.226-.737.324v6.485a1 1 0 01-1 1h-1a1 1 0 01-1-1v-6.5c0-.55-.336-.7-.746-.334l-2.573 2.3a1.073 1.073 0 01-1.446-.035l-.3-.3a.973.973 0 01.019-1.4l5.822-5.515a1.06 1.06 0 011.446.006l5.825 5.61a.982.982 0 01.018 1.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});