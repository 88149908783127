define("ember-svg-jar/inlined/virtual-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>virtual-home</title><path d=\"M5.132 10.342A1.659 1.659 0 006.789 12h10.422a1.659 1.659 0 001.657-1.658V4.553a.5.5 0 00-.231-.422L12.269.078a.5.5 0 00-.538 0L5.363 4.131a.5.5 0 00-.231.422zm5.379-6.223l4.052 2.315a.5.5 0 010 .869l-4.052 2.315a.5.5 0 01-.748-.434V4.553a.5.5 0 01.748-.434zM17.5 16.5h-1.917a.249.249 0 01-.241-.184 4.5 4.5 0 00-8.707.089.125.125 0 01-.121.095H6.5A2.5 2.5 0 004 19v2a1.5 1.5 0 00.857 1.355.25.25 0 01.143.226V23a1 1 0 002 0v-.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.419a.25.25 0 01.143-.226A1.5 1.5 0 0020 21v-2a2.5 2.5 0 00-2.5-2.5zM11 15a2.5 2.5 0 11-2.5 2.5A2.5 2.5 0 0111 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});