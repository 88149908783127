define("ember-svg-jar/inlined/shark-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-fish</title><path d=\"M23.917 10.943a12.877 12.877 0 01-.363-3.089 12.865 12.865 0 01.363-3.089 1 1 0 00-1.565-1.046 6.459 6.459 0 00-1.905 2.663 16.362 16.362 0 00-6.254-2.395l.278-2.765a1 1 0 00-1.426-1 7.458 7.458 0 00-3.7 3.737C5.392 4.26 2.246 5.3.234 6.969a.5.5 0 00-.068.7 10.762 10.762 0 002.827 2.538.478.478 0 00.307.067l3.033-.31-.475.647a.5.5 0 00.3.785 25.065 25.065 0 004.639.439 6.033 6.033 0 003.336 2.335 1 1 0 001.248-1.155c-.087-.446-.158-.92-.213-1.416a15.618 15.618 0 005.281-2.272 6.457 6.457 0 001.9 2.659 1 1 0 001.564-1.045zM12.383 7.189a.75.75 0 111.341-.67 3.178 3.178 0 010 2.67.75.75 0 11-1.341-.67 1.682 1.682 0 000-1.33zm-3 0a.75.75 0 111.341-.67 3.178 3.178 0 010 2.67.75.75 0 11-1.341-.67 1.682 1.682 0 000-1.33zm-3.829-.335a1 1 0 111 1 1 1 0 01-1-1zM21.323 18.624a4.4 4.4 0 00.412-.688.75.75 0 00-1.361-.629 3.606 3.606 0 01-.272.439 3.174 3.174 0 00-1.858-.623c-2.21 0-2.649 1.292-2.667 1.347a.512.512 0 000 .3c.018.056.457 1.348 2.667 1.348A3.178 3.178 0 0020.1 19.5a3.453 3.453 0 01.272.439.75.75 0 001.362-.628 4.365 4.365 0 00-.411-.687zM10.94 21.624a7.17 7.17 0 00.783-1.161.75.75 0 00-1.337-.68 6.071 6.071 0 01-.691.99 5.023 5.023 0 00-3.141-1.149c-3.323 0-3.95 1.766-3.975 1.841a.505.505 0 000 .317c.025.075.652 1.842 3.975 1.842a5.024 5.024 0 003.146-1.15 6.207 6.207 0 01.691.989.75.75 0 001.337-.678 7.073 7.073 0 00-.788-1.161z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});