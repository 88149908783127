define("ember-svg-jar/inlined/paragraph-image-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-image-left</title><path d=\"M16 22H1a1 1 0 000 2h15a1 1 0 000-2zM23 17H1a1 1 0 000 2h22a1 1 0 000-2zM23 12H1a1 1 0 000 2h22a1 1 0 000-2zM23 7H13a1 1 0 000 2h10a1 1 0 000-2zM13 4h10a1 1 0 000-2H13a1 1 0 100 2z\"/><rect y=\"-.002\" width=\"10\" height=\"10\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});