define("ember-svg-jar/inlined/car-retro-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-retro-2</title><path d=\"M23 14.68a.25.25 0 01-.25-.25v-2.25a2 2 0 00-2-2h-.477a.251.251 0 01-.224-.138l-1.625-3.259a1.992 1.992 0 00-1.794-1.1H8.62a1.993 1.993 0 00-1.795 1.1L5.2 10.042a.251.251 0 01-.224.138H2a2 2 0 00-2 2v2.5a1.5 1.5 0 001.244 1.477.25.25 0 00.291-.215 2.988 2.988 0 015.933.018.249.249 0 00.248.22h7.318a.248.248 0 00.248-.22 2.995 2.995 0 015.968.36.91.91 0 010 .091.25.25 0 00.25.269H23a1 1 0 000-2zM11.75 9.43a.25.25 0 01-.25.25H8.024a.25.25 0 01-.224-.362l.751-1.5a.249.249 0 01.223-.138H11.5a.249.249 0 01.25.25zm5.692.132a.25.25 0 01-.212.118H14a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.48a.249.249 0 01.224.138l.75 1.5a.252.252 0 01-.012.244z\"/><circle cx=\"4.5\" cy=\"16.32\" r=\"2\"/><circle cx=\"18.25\" cy=\"16.32\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});