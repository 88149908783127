define("ember-svg-jar/inlined/currency-yuan-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-yuan-bill</title><path d=\"M21.143 7.488A.251.251 0 0121 7.262V6a1 1 0 00-1-1H4a1 1 0 00-1 1v1.262a.25.25 0 01-.143.226 4.993 4.993 0 000 9.026.25.25 0 01.143.226V18a1 1 0 001 1h16a1 1 0 001-1v-1.26a.25.25 0 01.143-.226 4.993 4.993 0 000-9.026zm-7.429 4.655a1 1 0 010 2h-.464a.25.25 0 00-.25.25V16a1 1 0 01-2 0v-1.607a.25.25 0 00-.25-.25h-.464a1 1 0 010-2h.464a.25.25 0 00.25-.25v-.086a.257.257 0 00-.042-.139L8.882 8.555a1 1 0 011.664-1.11l1.246 1.869a.25.25 0 00.416 0l1.246-1.869a1 1 0 011.664 1.11l-2.076 3.113a.257.257 0 00-.042.139v.086a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});