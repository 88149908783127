define("ember-svg-jar/inlined/history-man-king.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-man-king</title><path d=\"M5.369 9.9a.249.249 0 01-.073.2 2.064 2.064 0 00-.545 1.532 2.728 2.728 0 001.435 2.432.251.251 0 01.142.188c.509 3.048 4.011 4.994 5.672 4.994s5.163-1.944 5.672-4.992a.251.251 0 01.142-.187 2.728 2.728 0 001.435-2.432A2.064 2.064 0 0018.7 10.1a.25.25 0 01-.073-.2l.873-8.352a.5.5 0 00-.762-.476L15.282 3.23a.251.251 0 01-.322-.049L12.38.171a.517.517 0 00-.76 0l-2.58 3.01a.251.251 0 01-.322.049L5.265 1.072a.5.5 0 00-.762.48zm11.824 2.8a1.75 1.75 0 00-1 1.3c-.375 2.249-3.162 3.739-4.191 3.739s-3.816-1.49-4.191-3.738a1.75 1.75 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.625.625 0 01.105-.44.745.745 0 00.377-.441l.306-.953a.249.249 0 01.158-.16 14.9 14.9 0 019.6-.001.249.249 0 01.158.161l.309.961a.745.745 0 00.377.441.625.625 0 01.105.44 1.281 1.281 0 01-.556 1.097z\"/><path d=\"M12.741 14.324a1.342 1.342 0 01-.236.1 1.986 1.986 0 01-.574.081 2.034 2.034 0 01-.581-.082 1.408 1.408 0 01-.239-.1.75.75 0 00-.711 1.321 2.913 2.913 0 00.511.211 3.357 3.357 0 001.02.145 3.5 3.5 0 001.013-.147 2.754 2.754 0 00.512-.213.75.75 0 10-.715-1.318zM10.991 12.023a.75.75 0 00-.054-1.059 2.093 2.093 0 00-2.636 0 .75.75 0 001.006 1.113.63.63 0 01.626 0 .749.749 0 001.058-.054zM12.866 10.964a.75.75 0 00.5 1.306.744.744 0 00.5-.193.63.63 0 01.626 0 .749.749 0 101-1.113 2.093 2.093 0 00-2.626 0zM23.659 23.308a4.5 4.5 0 00-2.579-2.526.481.481 0 00-.054-.016l-5-1.262a.5.5 0 00-.427.088L12 22.365l-3.61-2.777a.5.5 0 00-.428-.088L2.92 20.782a4.5 4.5 0 00-2.579 2.526A.5.5 0 00.8 24h22.4a.5.5 0 00.463-.688z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});