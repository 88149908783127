define("ember-svg-jar/inlined/space-ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-ship</title><path d=\"M22.285 20.29a.5.5 0 01-.285-.452v-.924a1.986 1.986 0 00-.586-1.414L18.5 14.588a2 2 0 00-.484-.356.97.97 0 01-.518-.858 1.127 1.127 0 01.228-.68A7.727 7.727 0 0019.5 8.15C19.5 4.481 16.039 0 12 0S4.5 4.481 4.5 8.15a7.727 7.727 0 001.772 4.544 1.127 1.127 0 01.228.68.97.97 0 01-.518.858 2 2 0 00-.484.356L2.586 17.5A1.986 1.986 0 002 18.914v.924a.5.5 0 01-.285.452A3 3 0 000 23a1 1 0 001 1h4a1 1 0 001-1 3 3 0 00-1.715-2.71.5.5 0 01-.285-.452v-.717a.5.5 0 01.146-.353l2.475-2.475A1 1 0 017.328 16H10.5a.5.5 0 01.5.5v3.338a.5.5 0 01-.285.452A3 3 0 009 23a1 1 0 001 1h4a1 1 0 001-1 3 3 0 00-1.715-2.71.5.5 0 01-.285-.452V16.5a.5.5 0 01.5-.5h3.172a1 1 0 01.707.293l2.475 2.475a.5.5 0 01.146.353v.717a.5.5 0 01-.285.452A3 3 0 0018 23a1 1 0 001 1h4a1 1 0 001-1 3 3 0 00-1.715-2.71zM11 13.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5zm-.25-2.5a.5.5 0 01-.5-.5V7a1 1 0 011-1h1.5a1 1 0 011 1v3.5a.5.5 0 01-.5.5zm4.75 2.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V13a.5.5 0 01.5-.5H15a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});