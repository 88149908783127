define("ember-svg-jar/inlined/database-clock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-clock-alternate</title><path d=\"M.723 4.907v12.478c0 2.557 3.4 4.169 7.323 4.71a.96.96 0 001-1.359L9.03 20.7a.982.982 0 00-.766-.588c-3.448-.475-5.541-1.774-5.541-2.729v-.729a.251.251 0 01.372-.218A13.581 13.581 0 007.188 17.8a.984.984 0 001.159-.908v-.024a.974.974 0 00-.8-1.021c-3.023-.545-4.828-1.731-4.828-2.619V12.5a.247.247 0 01.124-.215.249.249 0 01.248 0 15 15 0 005.363 1.545.974.974 0 00.952-.517l.036-.064a.957.957 0 00-.762-1.4c-3.7-.423-5.957-1.788-5.957-2.781v-.73a.251.251 0 01.377-.22 17.268 17.268 0 007.927 1.7c.4 0 4.908-.024 7.927-1.7a.25.25 0 01.371.217v.5a.967.967 0 00.587.9l.022.009a.986.986 0 001.387-.9V4.907c0-3.192-5.306-4.915-10.3-4.915S.737 1.711.725 4.9zm10.3-2.915c5.062 0 8.293 1.724 8.3 2.912v.009c-.01 1.188-3.24 2.909-8.3 2.909s-8.3-1.727-8.3-2.915 3.234-2.915 8.299-2.915z\"/><path d=\"M16.649 10.757a6.628 6.628 0 106.628 6.628 6.636 6.636 0 00-6.628-6.628zm-4.628 6.628a4.628 4.628 0 114.628 4.628 4.634 4.634 0 01-4.628-4.628z\"/><path d=\"M19.606 17.385a1 1 0 00-1-1H17.9a.25.25 0 01-.25-.25v-1.2a1 1 0 00-2 0v2.446a1 1 0 001 1h1.957a1 1 0 00.999-.996z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});