define("ember-svg-jar/inlined/baby-stork", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-stork</title><path d=\"M24 5.615a1.579 1.579 0 00-1.1-1.524 33.492 33.492 0 00-3.291-.824.25.25 0 01-.136-.416 2.813 2.813 0 00.669-1.806.5.5 0 00-.517-.531 2.893 2.893 0 00-1.885.673 3.669 3.669 0 00-.819 1.561.257.257 0 01-.251.185.251.251 0 01-.238-.181 3.688 3.688 0 00-.821-1.565 2.889 2.889 0 00-1.88-.673.5.5 0 00-.517.531 2.967 2.967 0 00.576 1.7.249.249 0 01-.154.4 12.512 12.512 0 00-1.274.273.5.5 0 01-.59-.288 4.907 4.907 0 00-1.234-1.716A5.892 5.892 0 002.03 2.3C.573 4.471.634 8.009 2.216 13.115a3.05 3.05 0 01.092 1.7c-.365 1.319-.883 1.2-1.169 1.156a1 1 0 00-.278 1.981c1.782.252 2.86-.747 3.374-2.6a4.963 4.963 0 00-.109-2.827c-1.71-5.517-1.18-8-.435-9.111A3.908 3.908 0 019.2 2.905a2.761 2.761 0 01.948 2.04 2.981 2.981 0 01-4.393 2.679 1 1 0 00-1.418 1.2c.488 1.481 1.476 4.768 1.847 6.006l.129.427a10.38 10.38 0 01-.437 7.323 1 1 0 101.812.846 12.4 12.4 0 00.538-8.753l-.126-.422c-.224-.746-.67-2.23-1.1-3.625a.5.5 0 01.45-.646 5 5 0 003.741-2.051.5.5 0 01.568-.177 11.3 11.3 0 002.013.484.5.5 0 01.378.705l-2.465 5.323a5.774 5.774 0 001.372 7.23 6.192 6.192 0 007.787.121l.064-.051a5.777 5.777 0 001.44-7.491L19.7 8.689a.5.5 0 01.338-.709 32.373 32.373 0 002.9-.81A1.586 1.586 0 0024 5.615zm-3.879-.2a.25.25 0 010 .487 15.6 15.6 0 01-3.571.529 13.315 13.315 0 01-3.977-.514.25.25 0 010-.476 13.835 13.835 0 013.975-.51 16.8 16.8 0 013.573.485z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});