define("ember-svg-jar/inlined/video-edit-cc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-cc</title><path d=\"M10.5 17.75a.25.25 0 00-.25-.25H8a2 2 0 01-2-2V14a.5.5 0 01.5-.5h3.75a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25H6.5a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2.25a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25V4a4 4 0 00-4-4H1a1 1 0 000 2 2 2 0 012 2v11.5a4 4 0 004 4h3.25a.25.25 0 00.25-.25zM14 5.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H12a2 2 0 012 2z\"/><path d=\"M22.5 12h-9a1.5 1.5 0 00-1.5 1.5v9a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5v-9a1.5 1.5 0 00-1.5-1.5zM14 18.5v-1a2.752 2.752 0 012.75-2.75.75.75 0 010 1.5 1.252 1.252 0 00-1.25 1.25v1a1.252 1.252 0 001.25 1.25.75.75 0 010 1.5A2.752 2.752 0 0114 18.5zm7.25 1.25a.75.75 0 010 1.5 2.752 2.752 0 01-2.75-2.75v-1a2.752 2.752 0 012.75-2.75.75.75 0 010 1.5A1.252 1.252 0 0020 17.5v1a1.252 1.252 0 001.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});