define("ember-svg-jar/inlined/glasses-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>glasses-sun</title><path d=\"M20.353 8.615h-4.021a.5.5 0 01-.433-.251 4.5 4.5 0 00-7.8 0 .5.5 0 01-.433.251H3.647A3.627 3.627 0 000 12.212c0 3.287 2.2 5.673 5.235 5.673A5.726 5.726 0 0011 12.212v-1.039a2.53 2.53 0 00-.682-1.727.5.5 0 01.038-.717 2.508 2.508 0 013.288 0 .5.5 0 01.038.717A2.53 2.53 0 0013 11.173v1.039a5.726 5.726 0 005.765 5.673C21.8 17.885 24 15.5 24 12.212a3.627 3.627 0 00-3.647-3.597zM9 12.212a3.724 3.724 0 01-3.765 3.673C3 15.885 2 14.04 2 12.212a1.624 1.624 0 011.647-1.6h4.765a.574.574 0 01.588.561zm9.765 3.673A3.724 3.724 0 0115 12.212v-1.039a.574.574 0 01.588-.558h4.765A1.624 1.624 0 0122 12.212c0 1.828-1 3.673-3.235 3.673z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});