define("ember-svg-jar/inlined/atomic-bomb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>atomic-bomb</title><path d=\"M15.978 13h-1.419a3.575 3.575 0 00-4.868 0H8.272a2.6 2.6 0 00-2.634 2.24A2.5 2.5 0 008.125 18h2.625a.25.25 0 01.25.25V23a1 1 0 002 0v-4.75a.25.25 0 01.25-.25h2.875a2.5 2.5 0 002.487-2.76A2.6 2.6 0 0015.978 13z\"/><path d=\"M8.554 19a1 1 0 00-1.075.92 2.957 2.957 0 01-1.071 2.261 1 1 0 101.153 1.632 4.889 4.889 0 001.912-3.739A1 1 0 008.554 19zM16.521 19.923a1 1 0 00-1.994.154 4.892 4.892 0 001.912 3.739 1 1 0 101.154-1.632 2.955 2.955 0 01-1.072-2.261zM21.669 4.539a3.569 3.569 0 00-5.28-2.58 5.893 5.893 0 00-8.775 0 3.571 3.571 0 00-5.283 2.58A2.747 2.747 0 002.8 10h4.45a.5.5 0 00.5-.5V8a.75.75 0 011.5 0v2.25a1 1 0 002 0v-3.5a.75.75 0 011.5 0v3.5a1 1 0 002 0V8a.75.75 0 011.5 0v1.5a.5.5 0 00.5.5h4.45a2.747 2.747 0 00.469-5.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});