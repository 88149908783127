define("ember-svg-jar/inlined/science-black-hole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-black-hole</title><path d=\"M11.25 12c0-2.364-.657-4.75-2.125-4.75S7 9.636 7 12s.657 4.75 2.125 4.75S11.25 14.364 11.25 12zm-2.36 2.656A8.009 8.009 0 018.5 12a8.014 8.014 0 01.39-2.656.25.25 0 01.47 0A8.014 8.014 0 019.75 12a8.014 8.014 0 01-.39 2.656.25.25 0 01-.47 0z\"/><path d=\"M15 3a8.96 8.96 0 00-3.446.683 9.089 9.089 0 00-1.933 1.1 1 1 0 001.2 1.6 7 7 0 0110.909 3.668.25.25 0 01-.352.293l-4.987-2.487a5.779 5.779 0 00-2.572-.607h-1.393a.75.75 0 000 1.5h1.393a4.272 4.272 0 011.9.449l3.155 1.577a.25.25 0 01-.112.474H13a.75.75 0 000 1.5h5.764a.25.25 0 01.112.474L15.721 14.8a4.272 4.272 0 01-1.9.449h-1.395a.75.75 0 000 1.5h1.393a5.779 5.779 0 002.572-.607l4.982-2.491a.25.25 0 01.352.293 7 7 0 01-11.007 3.593 1 1 0 10-1.225 1.581A9 9 0 1015 3zM5.25 7.25H3.768a.5.5 0 01-.354-.15L2.28 5.97A.75.75 0 001 6.5V7a.25.25 0 01-.25.25.75.75 0 000 1.5A.25.25 0 011 9v.5a.75.75 0 001.28.53L3.414 8.9a.5.5 0 01.354-.146H5.25a.75.75 0 000-1.5zM5.25 15.25H3.768a.5.5 0 01-.354-.146L2.28 13.97A.75.75 0 001 14.5v.5a.25.25 0 01-.25.25.75.75 0 000 1.5A.25.25 0 011 17v.5a.75.75 0 001.28.53l1.134-1.13a.5.5 0 01.354-.146H5.25a.75.75 0 000-1.5zM4.28 9.97A.75.75 0 003 10.5v.245a.5.5 0 01-.5.5H.75a.75.75 0 000 1.5H2.5a.5.5 0 01.5.5v.255a.75.75 0 001.28.53l1.5-1.5a.749.749 0 000-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});