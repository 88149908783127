define("ember-svg-jar/inlined/keyboard-shift-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-shift-1</title><path d=\"M16.5 23.306a1 1 0 001-1v-10.75a.249.249 0 01.25-.25h3.258a1 1 0 00.692-1.723L12.691.967a1 1 0 00-1.382 0L2.3 9.583a1 1 0 00.691 1.723H6.25a.249.249 0 01.25.25v10.75a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});