define("ember-svg-jar/inlined/history-egyptian-mythology-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-egyptian-mythology-1</title><path d=\"M0 22.875a1 1 0 001 1h22a1 1 0 001-1v-1.6a3 3 0 00-2.43-2.94l-3.97-.775a4.765 4.765 0 00-.756-.442.5.5 0 010-.906.947.947 0 01.4-.087H19c1 0 3.94-2.33 4.87-6a2.057 2.057 0 00.13-.69 1.81 1.81 0 00-1.81-1.81h-4.44a4.706 4.706 0 01-2.9-1.3 6.517 6.517 0 00-4.1-1.7A4.746 4.746 0 009 4.959a.5.5 0 01-.29.023l-1.12-.247c-1.97-.44-2.12-1.39-2.86-3.12l-.16-.35A1.654 1.654 0 003 .125a1.5 1.5 0 00-1.5 1.5v4a7.262 7.262 0 00.7 3.194.5.5 0 01-.124.606A5.875 5.875 0 000 14.125zM3.5 4.692a.25.25 0 01.461-.134 4.583 4.583 0 003.2 2.127l1.59.35a1.039 1.039 0 00.67-.08 2.907 2.907 0 011.33-.33 4.706 4.706 0 012.9 1.3 6.517 6.517 0 004.1 1.7h3.507a.5.5 0 01.471.667 8.046 8.046 0 01-2.762 3.75.5.5 0 01-.276.083H17.25a3.009 3.009 0 00-3 3v1a.988.988 0 00.333.741.5.5 0 01.167.373v.49a1.908 1.908 0 01-.1.614l-.4 1.19a.5.5 0 01-.475.342h-2.06a.5.5 0 01-.475-.342 7.147 7.147 0 01-.461-1.84.5.5 0 01.4-.539l.256-.049a1.032 1.032 0 00.808-.98v-3a1.018 1.018 0 00-1-1h-.5a3.777 3.777 0 01-3.11-1.08 3.222 3.222 0 01-.13-2.88 1 1 0 00-.96-1.29H5.5c-1.263 0-2-1.981-2-3.25zM22 21.275v.6h-3.565a.1.1 0 01-.082-.043.1.1 0 01-.01-.093 5.462 5.462 0 00.348-1.383.5.5 0 01.592-.431l1.907.37a1 1 0 01.81.98zm-15.693-1.19a.5.5 0 01.585.4 5.923 5.923 0 00.193.749.489.489 0 01-.464.644H2.75a.5.5 0 01-.5-.5v-.006a.5.5 0 01.406-.491z\"/><circle cx=\"11.5\" cy=\"10.125\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});