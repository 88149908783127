define("ember-svg-jar/inlined/single-woman-actions-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-upload</title><path d=\"M10.679 13.472a.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.155-4.096zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM17.443 11.134a6.433 6.433 0 106.433 6.433 6.441 6.441 0 00-6.433-6.433zm-.989 9.4v-2.472a.248.248 0 00-.248-.248h-1.138a.494.494 0 01-.386-.8l2.375-2.969a.512.512 0 01.773 0l2.37 2.965a.494.494 0 01-.386.8H18.68a.248.248 0 00-.247.248v2.474a.99.99 0 11-1.979 0z\"/><path d=\"M10.679 13.472a.249.249 0 00.028-.194c-.526-1.935-.655-2.941-1.731-3.948a.5.5 0 00-.757.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h.132a.249.249 0 00.184-.082.245.245 0 00.063-.19 7.085 7.085 0 01-.038-.718 7.857 7.857 0 011.155-4.096zM6.041 0a4.206 4.206 0 104.206 4.206A4.212 4.212 0 006.041 0zm0 6.433A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM17.443 11.134a6.433 6.433 0 106.433 6.433 6.441 6.441 0 00-6.433-6.433zm-.989 9.4v-2.472a.248.248 0 00-.248-.248h-1.138a.494.494 0 01-.386-.8l2.375-2.969a.512.512 0 01.773 0l2.37 2.965a.494.494 0 01-.386.8H18.68a.248.248 0 00-.247.248v2.474a.99.99 0 11-1.979 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});