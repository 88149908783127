define("ember-svg-jar/inlined/kitchenware-ladle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-ladle-1</title><path d=\"M14.755 13.5a13.1 13.1 0 00-4.766.817.249.249 0 01-.344-.232V6.5a6.508 6.508 0 00-6.5-6.5 1 1 0 100 2 4.505 4.505 0 014.5 4.5v10.4a7.105 7.105 0 0014.21 0c0-2.23-3.57-3.4-7.1-3.4zm4.6 4.179a7.973 7.973 0 01-1.927.47 1.255 1.255 0 00-.844.49l-1.22 2.661a.451.451 0 01-.769-.03l-.918-2.46a1.266 1.266 0 00-.936-.6 9.745 9.745 0 01-1.028-.16 4.091 4.091 0 01-2.095-.86c.26-.43 2.206-1.19 5.255-1.19 3 0 4.949.741 5.245 1.18a1.968 1.968 0 01-.76.499z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});