define("ember-svg-jar/inlined/athletics-running", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-running</title><path d=\"M13 7h.5a.5.5 0 00.5-.5V4.75a.5.5 0 00-.5-.5h-1.214a7.75 7.75 0 000 15.5H13.5a.5.5 0 00.5-.5V17.5a.5.5 0 00-.5-.5H13a5 5 0 010-10zM15.5 4.75V6.5a.5.5 0 00.5.5h7.5a.5.5 0 00.5-.5V4.25h-8a.5.5 0 00-.5.5zM23.5 0h-3.75a.5.5 0 00-.5.5v1.75a.5.5 0 00.5.5H24V.5a.5.5 0 00-.5-.5z\"/><path d=\"M23.5 17H18a.5.5 0 01-.5-.5v-1.026a.5.5 0 01.3-.457l3.609-1.6a1 1 0 000-1.828l-4.5-2a1 1 0 00-1.409.911v10.25a.5.5 0 01-.5.5h-2.714a9.25 9.25 0 010-18.5h4.964a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H12a12 12 0 000 24h11.5a.5.5 0 00.5-.5v-1.75a.5.5 0 00-.5-.5H18a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5.5a.5.5 0 00.5-.5V17.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});