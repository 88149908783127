define("ember-svg-jar/inlined/lab-protection-glasses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-protection-glasses</title><path d=\"M23.029 10.447l-7.014-4.208a1 1 0 00-1.03 1.715l3.63 2.177a.251.251 0 01-.129.465H5.473a.25.25 0 01-.129-.464l10.561-6.4a.919.919 0 011.164.183l1.15 1.36a1 1 0 101.527-1.295l-1.154-1.366a2.9 2.9 0 00-3.728-.594L12.252 3.6a.5.5 0 01-.518 0L9.121 2.021a2.9 2.9 0 00-3.721.6L4.238 3.973a1 1 0 001.52 1.3l1.161-1.355a.908.908 0 011.165-.187l1.627.986a.25.25 0 010 .428l-8.748 5.3A2.01 2.01 0 000 12.159v5.867A2.892 2.892 0 001.219 20.4c2.329 1.634 4.32 2.261 6.09 1.913 2.226-.436 3.438-2.319 4.24-3.566.139-.215.306-.477.451-.676.145.2.312.461.451.676.8 1.247 2.014 3.13 4.24 3.566a4.98 4.98 0 00.965.093A9.194 9.194 0 0022.78 20.4 2.894 2.894 0 0024 18.026v-5.863a2.012 2.012 0 00-.971-1.716zM22 18.026a.909.909 0 01-.367.739c-1.835 1.288-3.366 1.817-4.557 1.588-1.39-.273-2.251-1.611-2.943-2.686-.627-.975-1.169-1.817-2.133-1.817s-1.506.842-2.133 1.817c-.692 1.075-1.553 2.413-2.943 2.686-1.192.229-2.722-.3-4.558-1.589A.908.908 0 012 18.026v-3.58a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});