define("ember-svg-jar/inlined/pencil-write-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pencil-write-1</title><path d=\"M13.616 15.186a1.989 1.989 0 01-.969.53c-.07.038-3.236 1.325-3.238 1.326a2.046 2.046 0 01-2.157-.442 2 2 0 01-.443-2.157s1.291-3.168 1.327-3.238a1.978 1.978 0 01.53-.968l6.259-6.261a.25.25 0 00-.176-.427H3.7A3.118 3.118 0 00.579 6.667v13.765A3.117 3.117 0 003.7 23.549h13.761a3.118 3.118 0 003.118-3.118V8.826a.25.25 0 00-.427-.176zM21.431.451a1.974 1.974 0 00-1.415.557l-1.061 1.061a.5.5 0 000 .707L21.076 4.9a.5.5 0 00.707 0l1.06-1.061a2.02 2.02 0 000-2.828 2 2 0 00-1.412-.56z\"/><path d=\"M17.858 3.871a.516.516 0 00-.707 0l-6.363 6.365a.5.5 0 000 .707l2.121 2.121a.5.5 0 00.707 0L19.98 6.7a.5.5 0 000-.707zM11.882 14.438a.5.5 0 00.168-.819L10.232 11.8a.5.5 0 00-.454-.137.5.5 0 00-.364.3L8.2 15a.5.5 0 00.65.65z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});