define("ember-svg-jar/inlined/network-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-star</title><path d=\"M10.8 21.056a.252.252 0 00-.049-.276 10.344 10.344 0 01-2.1-3.034.25.25 0 01.149-.346 9.05 9.05 0 00.62-.244.871.871 0 00.528-.782.874.874 0 00-.873-.895h-1a.249.249 0 01-.244-.2 15.551 15.551 0 01-.1-6.069.248.248 0 01.245-.2c10.786 0 13.485.006 14.223-.019a.942.942 0 00.891-1.375A11.868 11.868 0 0012.022.043C7.421.016.438 3.574.019 11.342A11.972 11.972 0 0010.2 23.824a.25.25 0 00.277-.317 3.569 3.569 0 01.323-2.451zm-5.026-5.869a.249.249 0 01-.246.3H2.817a.248.248 0 01-.235-.166A9.844 9.844 0 012.4 9.194a.25.25 0 01.24-.179h2.792a.25.25 0 01.248.285 17.846 17.846 0 00-.2 2.7 17.2 17.2 0 00.294 3.187zm6.039-12.874a.251.251 0 01.291 0 10.132 10.132 0 013.34 4.356.251.251 0 01-.23.346H8.7a.251.251 0 01-.231-.346 10.011 10.011 0 013.344-4.356zm8.541 4.317a.253.253 0 01.009.255.25.25 0 01-.219.13H17.88a.25.25 0 01-.237-.172 13.071 13.071 0 00-1.759-3.491.25.25 0 01.309-.372 9.855 9.855 0 014.161 3.65zM7.694 3.015a.25.25 0 01.312.37 13.076 13.076 0 00-1.729 3.458.25.25 0 01-.237.172H3.8a.251.251 0 01-.21-.386 10.007 10.007 0 014.104-3.614zM3.933 17.881a.251.251 0 01.2-.4H6.2a.251.251 0 01.235.163A12.778 12.778 0 008 20.613a.251.251 0 01-.312.371 10 10 0 01-3.755-3.103z\"/><path d=\"M20.747 23.785a1.272 1.272 0 001.5-.166 1.249 1.249 0 00.314-1.444l-1.219-2.8a.249.249 0 01.063-.286l2.2-1.964a1.259 1.259 0 00.3-1.373 1.227 1.227 0 00-1.164-.759h-2.488a.248.248 0 01-.229-.151l-1.339-3.1-.013-.027a1.289 1.289 0 00-2.309.027l-1.34 3.1a.251.251 0 01-.23.15H12.3a1.224 1.224 0 00-1.161.757 1.267 1.267 0 00.318 1.392l2.185 1.947a.249.249 0 01.063.286l-1.218 2.8a1.249 1.249 0 00.313 1.445 1.274 1.274 0 001.5.166l3.1-1.746a.251.251 0 01.246 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});