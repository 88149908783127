define("ember-svg-jar/inlined/alarm-bell-timer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-timer-1</title><path d=\"M9.5 21.5h-3a.5.5 0 00-.5.5 2 2 0 004 0 .5.5 0 00-.5-.5zM11.419 7.275A5.566 5.566 0 009 6.127V4.5a1 1 0 10-2 0v1.627C4.168 6.684 2 9.55 2 13v4.5a1 1 0 01-1 1 1 1 0 000 2h14a1 1 0 100-2 1 1 0 01-1-1V13a8.019 8.019 0 00-.218-1.866 7.006 7.006 0 01-2.363-3.859zM18.251 0A5.75 5.75 0 1024 5.751 5.756 5.756 0 0018.251 0zm0 9.5A3.75 3.75 0 1122 5.751 3.755 3.755 0 0118.251 9.5z\"/><path d=\"M19.751 5H19V3.751a.75.75 0 00-1.5 0v2a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});