define("ember-svg-jar/inlined/upload-dash-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-dash-arrow</title><path d=\"M4.583 15.809a1 1 0 00.184 1.08l6.16 6.634a1.462 1.462 0 002.146 0l6.16-6.634a1 1 0 00-.733-1.681h-3.25a.25.25 0 01-.25-.25v-4.951a1 1 0 00-1-1h-4a1 1 0 00-1 1v4.951a.25.25 0 01-.25.25H5.5a1 1 0 00-.917.601z\"/><rect x=\"9\" y=\"4.507\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\"9\" y=\".007\" width=\"6\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});