define("ember-svg-jar/inlined/martial-arts-sword-fencing-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>martial-arts-sword-fencing-1</title><path d=\"M23.707 1.707A1 1 0 0022.293.293L8.632 13.954a.5.5 0 01-.6.082 5.017 5.017 0 00-5.526.4 1 1 0 00-.094 1.5l1.77 1.764a.5.5 0 010 .707L.293 22.293a1 1 0 001.414 1.414l.854-.854a.5.5 0 01.6-.083 5.009 5.009 0 005.973-.831l.353-.353a4.9 4.9 0 00.48-5.619.5.5 0 01.082-.6zM7.184 20.839a.251.251 0 01-.126.184 3.01 3.01 0 01-2.386.234.249.249 0 01-.1-.415L5.6 19.818a.5.5 0 01.707 0l.81.81a.249.249 0 01.067.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});