define("ember-svg-jar/inlined/bus-double-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-double-1</title><path d=\"M22.705 0h-16.5a2.5 2.5 0 00-2.5 2.5v12.25a.25.25 0 01-.25.25H2.128a1 1 0 00-.986.836l-.833 5A1 1 0 001.3 22h.57a.25.25 0 00.25-.25 3.25 3.25 0 016.5 0 .249.249 0 00.25.25h6.5a.25.25 0 00.25-.25 3.25 3.25 0 116.5 0 .249.249 0 00.25.25h.34a1 1 0 001-1V1a1 1 0 00-1.005-1zm-13 14a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 011-1h2a1 1 0 011 1zm0-8a1 1 0 01-1 1h-2a1 1 0 01-1-1V3a1 1 0 011-1h2a1 1 0 011 1zm6 8a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 011-1h2a1 1 0 011 1zm0-8a1 1 0 01-1 1h-2a1 1 0 01-1-1V3a1 1 0 011-1h2a1 1 0 011 1zm6 8a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 011-1h2a1 1 0 011 1zm0-8a1 1 0 01-1 1h-2a1 1 0 01-1-1V3a1 1 0 011-1h2a1 1 0 011 1z\"/><circle cx=\"5.365\" cy=\"21.75\" r=\"2.25\"/><circle cx=\"18.865\" cy=\"21.75\" r=\"2.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});