define("ember-svg-jar/inlined/dressing-hanger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-hanger-1</title><path d=\"M22.251 6.913l-4.1-1.043a.25.25 0 01-.189-.242V5A2.6 2.6 0 1015.12.762a1 1 0 101.414 1.414.6.6 0 11.424 1.024 1 1 0 00-1 1v1.429a.249.249 0 01-.188.242L11.6 6.934a2.394 2.394 0 00.758 4.666h9.211a2.394 2.394 0 00.687-4.687zM21.564 9.6h-9.211a.4.4 0 01-.2-.748l4.739-1.2a.256.256 0 01.123 0l4.673 1.178a.4.4 0 01-.124.77z\"/><path d=\"M1.042 3h11.916a1 1 0 000-2H1.042a1 1 0 000 2zM20.958 3h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/><rect x=\"9.542\" y=\"20\" width=\"7\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M17.042 13h-16a1 1 0 000 2 .5.5 0 01.5.5v3a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-3a.5.5 0 011 0v3a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-3a.5.5 0 01.5-.5 1 1 0 000-2zm-11.5 4.25h-1a.75.75 0 010-1.5h1a.75.75 0 110 1.5zm8 0h-1a.75.75 0 010-1.5h1a.75.75 0 110 1.5z\"/><rect x=\"1.542\" y=\"20\" width=\"7\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});