define("ember-svg-jar/inlined/hat-lady-cowboy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-lady-cowboy</title><path d=\"M23 11.724a1 1 0 00-1 1 3.505 3.505 0 01-1.945 3.136.5.5 0 01-.694-.279 28.285 28.285 0 01-1.213-5.327c-.329-2.114-.566-3.642-1.57-4.241a2.179 2.179 0 00-1.915-.04l-.6.216A5.783 5.783 0 0112 6.725a5.783 5.783 0 01-2.059-.536l-.6-.216a2.18 2.18 0 00-1.915.04c-1 .6-1.241 2.127-1.57 4.241a28.285 28.285 0 01-1.213 5.327.5.5 0 01-.694.279A3.505 3.505 0 012 12.724a1 1 0 00-2 0 5.507 5.507 0 005.5 5.5h13a5.507 5.507 0 005.5-5.5 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});