define("ember-svg-jar/inlined/make-up-mascara-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-mascara-small</title><path d=\"M19.625 2h-2a2.5 2.5 0 00-2.5 2.5v3.25a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V4.5a2.5 2.5 0 00-2.5-2.5zM21.625 9.75h-6a.5.5 0 00-.5.5V12a1 1 0 001 1h5a1 1 0 001-1v-1.75a.5.5 0 00-.5-.5zM7.375 6.833h1.5a1 1 0 000-2h-1.5a.5.5 0 01-.5-.5V4a.5.5 0 01.5-.5 1 1 0 000-2 .5.5 0 01-.5-.5 1 1 0 00-2 0 .5.5 0 01-.5.5 1 1 0 000 2 .5.5 0 01.5.5v.333a.5.5 0 01-.5.5h-1.5a1 1 0 000 2h1.5a.5.5 0 01.5.5v.334a.5.5 0 01-.5.5h-1.5a1 1 0 000 2h1.5a.5.5 0 01.5.5V11a.5.5 0 01-.5.5 1 1 0 000 2 .5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-1.25a1 1 0 00-1 1v5.5a2.5 2.5 0 002.5 2.5h2.5a2.5 2.5 0 002.5-2.5V16a1 1 0 00-1-1h-1.25a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5 1 1 0 000-2 .5.5 0 01-.5-.5v-.333a.5.5 0 01.5-.5h1.5a1 1 0 000-2h-1.5a.5.5 0 01-.5-.5v-.334a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});