define("ember-svg-jar/inlined/asian-interior-eating-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-eating-table</title><path d=\"M23.5 12.5A2.5 2.5 0 0021 15v4.5a.5.5 0 01-.5.5h-1a1.5 1.5 0 00-1.5 1.5v.5a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0024 22v-9a.5.5 0 00-.5-.5z\"/><circle cx=\"21\" cy=\"6\" r=\"1\"/><circle cx=\"22\" cy=\"1.5\" r=\"1\"/><circle cx=\"16.5\" cy=\"1.5\" r=\"1\"/><circle cx=\"9\" cy=\"1.5\" r=\"1\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"1\"/><path d=\"M18 17.5a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5h-3.853l-.279-2.6a1.87 1.87 0 00-.653-1.2.5.5 0 01-.175-.429C13.346 6.01 15.691 6 16.5 6a3.817 3.817 0 004-3.5 1 1 0 00-2 0 1.835 1.835 0 01-2 1.5 5.24 5.24 0 00-4.01 1.594.5.5 0 01-.541.131A4.16 4.16 0 0010.5 5.5a.906.906 0 01-.412-.061A2.838 2.838 0 0110 4.5a1 1 0 00-2 0c0 .9 0 3 2.5 3q.139 0 .249.006a.5.5 0 01.374.195.506.506 0 01.091.412 8.107 8.107 0 00-.165 1.169.5.5 0 01-.194.36 1.893 1.893 0 00-.725 1.27L9.853 13.5H6A1.5 1.5 0 004.5 15v1A1.5 1.5 0 006 17.5h.5a.5.5 0 01.5.5v4.5a1 1 0 002 0V18a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v4.5a1 1 0 002 0V18a.5.5 0 01.5-.5zM6 21.5A1.5 1.5 0 004.5 20h-1a.5.5 0 01-.5-.5V15a2.5 2.5 0 00-2.5-2.5.5.5 0 00-.5.5v9a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 006 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});