define("ember-svg-jar/inlined/sports-car-convertible-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-convertible-1</title><path d=\"M23 14.5V13a2.5 2.5 0 00-2.5-2.5H20a6.666 6.666 0 00-2.107.342 9.712 9.712 0 01-.69.2.249.249 0 01-.3-.311l.558-1.955a1 1 0 10-1.922-.549l-.851 2.973a.251.251 0 01-.242.181 10.763 10.763 0 01-3.341-.544 6.432 6.432 0 00-1.385-.285.251.251 0 01-.2-.128.247.247 0 010-.232l1.88-3.745a1 1 0 00-1.79-.9L7.2 10.361a.249.249 0 01-.224.138H3.5A2.5 2.5 0 001 13v1.5a.5.5 0 00.5.5h1.67a.249.249 0 00.18-.076 2.963 2.963 0 014.3 0 .249.249 0 00.18.076h8.34a.249.249 0 00.18-.076 2.963 2.963 0 014.3 0 .249.249 0 00.18.076h1.67a.5.5 0 00.5-.5zM5.2 12.124a2.18 2.18 0 01-.358.466 2.262 2.262 0 01-1.59.659h-.5A.25.25 0 012.5 13a1.252 1.252 0 011.25-1.25h1.231a.251.251 0 01.217.375zM23 16h-1.346a.252.252 0 00-.243.312 2.708 2.708 0 010 1.377.249.249 0 00.243.311H23a1 1 0 100-2zM15.544 16.1a.253.253 0 00-.2-.1h-6.69a.252.252 0 00-.243.312 2.708 2.708 0 010 1.377.249.249 0 00.243.311h6.692a.251.251 0 00.243-.311 2.708 2.708 0 010-1.377.251.251 0 00-.045-.212zM2.544 16.1a.253.253 0 00-.2-.1H1a1 1 0 100 2h1.346a.251.251 0 00.243-.311 2.708 2.708 0 010-1.377.251.251 0 00-.045-.212z\"/><circle cx=\"5.5\" cy=\"16.999\" r=\"2\"/><circle cx=\"18.5\" cy=\"16.999\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});