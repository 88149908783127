define("ember-svg-jar/inlined/common-file-text-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-settings</title><path d=\"M19 8.5a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v6.356a.246.246 0 00.214.248 7.766 7.766 0 011.45.317A.247.247 0 008 8.689V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.375a.249.249 0 00-.236.32 3.245 3.245 0 01.048 1.377.248.248 0 00.24.3H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M17.5 12a1 1 0 00-1-1h-4a.955.955 0 00-.5.142c-.086.052-.15.187.019.308a7.281 7.281 0 011.57 1.461.236.236 0 00.19.089H16.5a1 1 0 001-1zM12.02 15.654a1.782 1.782 0 00-1.7-2.953l-1.411.326a.452.452 0 01-.533-.31l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.452.452 0 01-.533.31l-1.41-.327a1.782 1.782 0 00-1.7 2.952l.988 1.066a.463.463 0 010 .625l-.989 1.065a1.782 1.782 0 001.7 2.953l1.416-.326a.453.453 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.326a1.782 1.782 0 001.7-2.952l-.988-1.065a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/><circle cx=\"6.252\" cy=\"17.031\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});