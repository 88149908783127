define("ember-svg-jar/inlined/style-three-pin-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-brush</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6 7.5a1 1 0 01-1 1H9a1 1 0 01-1-1 .25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25h5.5A1.752 1.752 0 0114 11.75v1.043a.249.249 0 00.167.235.5.5 0 01.333.472v4.25a1.25 1.25 0 01-2.5 0V13.5a.5.5 0 01.333-.472.249.249 0 00.167-.235V11.75a.25.25 0 00-.25-.25h-5.5A1.752 1.752 0 015 9.75V7.5a1.752 1.752 0 011.75-1.75h1A.25.25 0 008 5.5a1 1 0 011-1h8a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});