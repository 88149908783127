define("ember-svg-jar/inlined/receipt-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>receipt-dollar</title><path d=\"M20.5 1.707A1 1 0 0018.793 1l-.616.616a.252.252 0 01-.354 0L16.707.5a1 1 0 00-1.414 0l-1.116 1.116a.252.252 0 01-.354 0L12.707.5a1 1 0 00-1.414 0l-1.116 1.116a.252.252 0 01-.354 0L8.707.5a1 1 0 00-1.414 0L6.177 1.616a.252.252 0 01-.354 0L5.207 1a1 1 0 00-1.707.707v20.586A1 1 0 005.207 23l.616-.617a.252.252 0 01.354 0L7.293 23.5a1 1 0 001.414 0l1.116-1.117a.252.252 0 01.354 0l1.116 1.117a1 1 0 001.414 0l1.116-1.117a.252.252 0 01.354 0l1.116 1.117a1 1 0 001.414 0l1.116-1.116a.249.249 0 01.354 0l.615.616a1 1 0 001.708-.707zm-7.069 14.77a.749.749 0 111.263-.807 1.25 1.25 0 101.056-1.92 2.75 2.75 0 01-.3-5.483.249.249 0 00.222-.248v-.352a.75.75 0 011.5 0V8.5a.253.253 0 00.111.208 2.759 2.759 0 01.81.841.75.75 0 11-1.276.79 1.25 1.25 0 10-1.067 1.911 2.749 2.749 0 01.3 5.482.249.249 0 00-.222.249v.353a.75.75 0 01-1.5 0V17.5a.25.25 0 00-.111-.208 2.742 2.742 0 01-.786-.815zM10.5 13.75H7a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm.25 3.25a.75.75 0 01-.75.75H7a.75.75 0 010-1.5h3a.75.75 0 01.75.75zm-.25-7.25H7a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});