define("ember-svg-jar/inlined/c-plus-plus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>c-plus-plus-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-15 18.876a.625.625 0 010 1.25 4.625 4.625 0 010-9.25.625.625 0 010 1.25 3.375 3.375 0 000 6.75zm6-2.75h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-1.124a.25.25 0 00-.25-.25H9.5a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25V13.5a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H13.5a.625.625 0 010 1.25zm6 0h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 11-1.25 0v-1.124a.25.25 0 00-.25-.25H15.5a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25V13.5a.625.625 0 111.25 0v1.125a.25.25 0 00.25.25H19.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});