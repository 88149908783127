define("ember-svg-jar/inlined/database-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-share</title><path d=\"M0 13.991v5.5a1.752 1.752 0 001.75 1.75h2.77a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1a.75.75 0 100 1.5h4a.75.75 0 000-1.5h-1a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25h2.73a1.752 1.752 0 001.75-1.75v-5.5a1.752 1.752 0 00-1.75-1.75h-7.5A1.752 1.752 0 000 13.991zm9.5 0v4.5a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25h7.5a.251.251 0 01.25.25zM22.25 12.241h-7.5a1.752 1.752 0 00-1.75 1.75v5.5a1.752 1.752 0 001.75 1.75h2.77a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1a.75.75 0 100 1.5h4a.75.75 0 000-1.5h-1a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25h2.73a1.752 1.752 0 001.75-1.75v-5.5a1.752 1.752 0 00-1.75-1.75zm-7.5 1.5h7.5a.251.251 0 01.25.25v4.5a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4.5a.25.25 0 01.25-.25zM11.99 11.241c2.289 0 3.884-1.045 3.884-1.984V8.046a.251.251 0 00-.384-.212 6.631 6.631 0 01-3.5.9 6.631 6.631 0 01-3.5-.9.25.25 0 00-.383.212v1.211c0 .943 1.593 1.984 3.883 1.984zM8.107 5.751c0 .938 1.6 1.983 3.883 1.983s3.884-1.045 3.884-1.983V4.539a.25.25 0 00-.384-.211 6.64 6.64 0 01-3.5.9 6.64 6.64 0 01-3.5-.9.249.249 0 00-.254-.008.251.251 0 00-.129.219zM8.107 2.244c0 .938 1.6 1.983 3.883 1.983s3.884-1.045 3.884-1.983S14.279.259 11.99.259 8.107 1.305 8.107 2.244zM21.5 10.991a.75.75 0 00.75-.75v-4a1.752 1.752 0 00-1.75-1.75h-2a.75.75 0 000 1.5h2a.25.25 0 01.25.25v4a.75.75 0 00.75.75zM3.25 10.241v-4a.25.25 0 01.25-.25h2a.75.75 0 000-1.5h-2a1.752 1.752 0 00-1.75 1.75v4a.75.75 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});