define("ember-svg-jar/inlined/phone-selfie-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-selfie-woman</title><path d=\"M12 21a6.593 6.593 0 00-4.876 2.164.5.5 0 00.37.836h9.012a.5.5 0 00.37-.836A6.593 6.593 0 0012 21z\"/><path d=\"M19 3a1 1 0 000 2 1 1 0 011 1v2.25a.25.25 0 01-.25.25H4.25A.25.25 0 014 8.25V6a1 1 0 011-1 1 1 0 000-2 3 3 0 00-3 3v17a1 1 0 002 0V10.25a.25.25 0 01.25-.25h15.5a.25.25 0 01.25.25V23a1 1 0 002 0V6a3 3 0 00-3-3z\"/><circle cx=\"12\" cy=\"5.5\" r=\"1.5\"/><path d=\"M12 3a.75.75 0 00.75-.75V.75a.75.75 0 00-1.5 0v1.5A.75.75 0 0012 3zM9.172 3.732a.75.75 0 001.06-1.06l-1.06-1.061a.75.75 0 00-1.061 1.061zM7.25 6.25h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM14.828 3.732l1.061-1.06a.75.75 0 00-1.061-1.061l-1.06 1.061a.75.75 0 001.06 1.06zM14.5 5.5a.75.75 0 00.75.75h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 00-.75.75zM16.5 16.749V15.5a4.5 4.5 0 00-9 0v1.249a4.568 4.568 0 01-.642 2.512 1 1 0 001.68 1.085 5.6 5.6 0 00.522-1.034.249.249 0 01.376-.116 4.49 4.49 0 005.128 0 .249.249 0 01.376.116 5.6 5.6 0 00.522 1.034 1 1 0 001.68-1.085 4.568 4.568 0 01-.642-2.512zM12 18a2.5 2.5 0 01-2.465-2.086.249.249 0 01.159-.275 5.73 5.73 0 002.134-1.407.254.254 0 01.181-.077.251.251 0 01.181.078 5.73 5.73 0 002.117 1.4.25.25 0 01.158.275A2.5 2.5 0 0112 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});