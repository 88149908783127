define("ember-svg-jar/inlined/truck-cargo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck-cargo</title><path d=\"M11.5 4.25a.25.25 0 00-.25-.25H6a2.5 2.5 0 00-2.5 2.5V7a.977.977 0 00.5.852.247.247 0 01.1.318l-1.351 3.057a.254.254 0 01-.124.126l-1.781.822A1.427 1.427 0 000 13.455v3.122A1.465 1.465 0 001.5 18h1.76a.25.25 0 00.249-.231 3 3 0 015.982 0A.251.251 0 009.74 18h1.51a.25.25 0 00.25-.25zM9.25 8a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H5.424a.251.251 0 01-.229-.352l1.111-2.5A.252.252 0 016.535 8zM24 5.5A1.5 1.5 0 0022.5 4h-9.75a.25.25 0 00-.25.25v13.5a.25.25 0 00.25.25h3.51a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h.76a.5.5 0 00.5-.5v-12z\"/><circle cx=\"6.5\" cy=\"18\" r=\"2\"/><circle cx=\"19.5\" cy=\"18\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});