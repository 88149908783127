define("ember-svg-jar/inlined/arrow-circle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-circle-up</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.761 16.138a1.251 1.251 0 01-1.753-.232L12.2 9.635a.249.249 0 00-.4 0l-4.81 6.271a1.25 1.25 0 11-1.984-1.521l5.634-7.346a1.779 1.779 0 012.716 0l5.634 7.346a1.25 1.25 0 01-.229 1.753z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});