define("ember-svg-jar/inlined/video-edit-camera-measure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-camera-measure</title><circle cx=\"5.5\" cy=\"5\" r=\"2\"/><path d=\"M19 2a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h9.25a.25.25 0 00.25-.25V8.329a.252.252 0 00-.12-.214A.77.77 0 0011 8a.943.943 0 00-.868.664l-1.592 3.5a.25.25 0 01-.42.056l-1.096-1.312a1.163 1.163 0 00-1.967.153l-1.991 3.318a.251.251 0 01-.215.121h-.6A.25.25 0 012 14.25V2.5a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v4.75a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25z\"/><path d=\"M24 12a1 1 0 00-1-1h-5.75a.25.25 0 01-.25-.25v-.25a1 1 0 00-2 0v.25a.25.25 0 01-.25.25 1.75 1.75 0 000 3.5h2.75a.754.754 0 00.469-.164l1.6-1.281a.246.246 0 01.162-.055H23a1 1 0 001-1zM23 14.5h-3a1 1 0 000 2h3a1 1 0 000-2zM22.75 20.25A.25.25 0 0123 20a1 1 0 000-2h-3.274a.245.245 0 01-.156-.055l-1.6-1.281a.754.754 0 00-.47-.164h-2.75a1.75 1.75 0 000 3.5.25.25 0 01.25.25v.25a1 1 0 002 0v-.25a.25.25 0 01.25-.25h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.75a1 1 0 000 2h.775a.25.25 0 01.245.2 1 1 0 001.846.3 1 1 0 00.134-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});