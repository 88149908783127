define("ember-svg-jar/inlined/style-three-pin-education-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-education-1</title><rect x=\"12.75\" y=\"9.501\" width=\"1.75\" height=\"4.5\" rx=\".25\" ry=\".25\"/><rect x=\"9.5\" y=\"9.501\" width=\"1.75\" height=\"4.5\" rx=\".25\" ry=\".25\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM8.1 5.75a4.5 4.5 0 017.8 0 .5.5 0 01-.432.75H8.536a.5.5 0 01-.436-.75zM17 14a.75.75 0 010 1.5H7A.75.75 0 117 14h.75a.25.25 0 00.25-.25v-4a.25.25 0 00-.25-.25H7A.75.75 0 017 8h10a.75.75 0 010 1.5h-.75a.25.25 0 00-.25.25v4a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});