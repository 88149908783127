define("ember-svg-jar/inlined/astronomy-planet-ring-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-planet-ring-star</title><path d=\"M1.625 3.094v.812a.75.75 0 001.5 0v-.812h.75a.75.75 0 100-1.5h-.75v-.75a.75.75 0 00-1.5 0v.75h-.75a.75.75 0 000 1.5zM23.375 20.406a2 2 0 01-2-2 .5.5 0 00-1 0 2 2 0 01-2 2 .5.5 0 000 1 2 2 0 012 2 .5.5 0 001 0 2 2 0 012-2 .5.5 0 000-1zM19.168 10.28a.249.249 0 01.046-.206 31.472 31.472 0 001.962-2.859c1.734-2.89 2.061-4.811 1-5.871A2.56 2.56 0 0019.7.847c-1.854.392-4.177 1.874-6.17 3.382a.247.247 0 01-.212.043 8 8 0 00-9.664 9.908.02.02 0 010 .015l-.011.013C1.5 17.1-.694 20.9.962 22.557a2.158 2.158 0 001.594.6c1.788 0 4.316-1.441 6.776-3.287a.248.248 0 01.206-.043 8 8 0 009.63-9.542zM4.39 16.616a.249.249 0 01.412 0 8.082 8.082 0 001.259 1.411.251.251 0 00.309.017 46.427 46.427 0 006-5.125 46.706 46.706 0 005.03-5.848.251.251 0 00-.016-.31 8.031 8.031 0 00-1.514-1.342.248.248 0 01-.109-.2.251.251 0 01.108-.207A16.479 16.479 0 0119.722 2.9a3.2 3.2 0 01.816-.185.251.251 0 01.261.234c.077 1.2-2.162 5.468-7.462 10.768s-9.544 7.522-10.728 7.5a.25.25 0 01-.244-.25 2.707 2.707 0 01.147-.767 14.808 14.808 0 011.878-3.584z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});