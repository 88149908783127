define("ember-svg-jar/inlined/kitchenware-masher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-masher</title><path d=\"M16.008 11.582a1.036 1.036 0 01.218-1.145l7.223-7.224A1.882 1.882 0 0020.787.551l-7.224 7.223a1.036 1.036 0 01-1.145.218 6.869 6.869 0 00-7.6 1.431L.827 13.415c-1.636 1.636-.774 5.06 1.962 7.8s6.16 3.6 7.8 1.962l3.992-3.992a6.869 6.869 0 001.427-7.603zm-12.093 8.5c-2.212-2.212-2.809-4.7-1.962-5.544S5.285 14.291 7.5 16.5s2.809 4.7 1.962 5.544-3.335.256-5.547-1.959z\"/><circle cx=\"5.706\" cy=\"18.294\" r=\"1\"/><circle cx=\"3.585\" cy=\"16.173\" r=\"1\"/><circle cx=\"7.827\" cy=\"20.415\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});