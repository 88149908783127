define("ember-svg-jar/inlined/navigation-right-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-circle-1</title><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zm5 1.5A3.5 3.5 0 018.5 10H14a.25.25 0 00.25-.25V8a.75.75 0 011.28-.531l3 3a.75.75 0 010 1.061l-3 3a.75.75 0 01-1.28-.53v-1.75A.25.25 0 0014 12H8.5a1.5 1.5 0 000 3 1 1 0 010 2A3.5 3.5 0 015 13.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});