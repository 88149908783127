define("ember-svg-jar/inlined/professions-man-chef-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-chef-3</title><path d=\"M23.5 23.269a2.464 2.464 0 00-.967-1.977C21.105 20.2 17.838 18.375 12 18.375a24.869 24.869 0 00-3.031.181.25.25 0 00-.219.248v4.821a.25.25 0 00.25.25h14a.5.5 0 00.5-.5zm-11.75-1.644a1 1 0 11-1-1 1 1 0 011 1zM7.25 19.144a.25.25 0 00-.094-.195.247.247 0 00-.211-.049 14.034 14.034 0 00-5.479 2.392A2.466 2.466 0 00.5 23.269v.106a.5.5 0 00.5.5h6a.25.25 0 00.25-.25z\"/><path d=\"M19.25 5.006a3 3 0 00-1.45-2.537A3.472 3.472 0 0015.5 2a.252.252 0 01-.249-.117 3.889 3.889 0 00-6.5 0A.25.25 0 018.5 2a3.468 3.468 0 00-2.3.473 2.992 2.992 0 00-1.45 2.533 2.932 2.932 0 001.127 2.287.251.251 0 01.075.287A6.461 6.461 0 005.5 9.958v1.834a6.5 6.5 0 1013 0V9.958a6.461 6.461 0 00-.452-2.378.249.249 0 01.075-.286 2.934 2.934 0 001.127-2.288zm-2.75 6.786a4.5 4.5 0 01-9 0V9.958a.033.033 0 01.01-.023.031.031 0 01.024-.008c1.485.124 2.976.2 4.468.2s2.982-.073 4.464-.2a.031.031 0 01.024.008.033.033 0 01.01.023z\"/><circle cx=\"14\" cy=\"11.625\" r=\"1\"/><circle cx=\"10\" cy=\"11.625\" r=\"1\"/><path d=\"M13.077 13.737h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});