define("ember-svg-jar/inlined/caps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>caps</title><path d=\"M9.25 5h-7a2 2 0 00-2 2v1.5a1 1 0 002 0V7.248A.25.25 0 012.5 7h2a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25h-.75a1 1 0 000 2h4a1 1 0 000-2H7a.25.25 0 01-.25-.25v-9.5A.25.25 0 017 7h2a.25.25 0 01.25.25V8.5a1 1 0 002 0V7a2 2 0 00-2-2zM21.75 5h-7a2 2 0 00-2 2v1.5a1 1 0 002 0V7.248A.25.25 0 0115 7h2a.25.25 0 01.25.25v9.5A.25.25 0 0117 17h-.75a1 1 0 000 2h4a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-9.5A.25.25 0 0119.5 7h2a.25.25 0 01.25.25V8.5a1 1 0 002 0V7a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});