define("ember-svg-jar/inlined/voice-id-denied", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-id-denied</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.591 6.677l1.237 1.237a1 1 0 11-1.414 1.414l-1.237-1.237a.25.25 0 00-.354 0l-1.237 1.237a1 1 0 11-1.414-1.414l1.237-1.237a.25.25 0 000-.354l-1.237-1.237a1 1 0 011.414-1.414l1.237 1.237a.25.25 0 00.354 0l1.237-1.237a1 1 0 111.414 1.414l-1.237 1.237a.25.25 0 000 .354zM1 2.5a1 1 0 00-1 1v14a1 1 0 002 0v-14a1 1 0 00-1-1zM4.5 6.5a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zM8 5a1 1 0 00-1 1v9a1 1 0 002 0V6a1 1 0 00-1-1zM22 10.5a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM12.5 11V1a1 1 0 00-2 0v10a1 1 0 002 0zM18.5 9.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM15 10a1 1 0 001-1V6a1 1 0 00-2 0v3a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});