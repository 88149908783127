define("ember-svg-jar/inlined/color-picker-take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-take</title><path d=\"M17.5 8h-.75a.25.25 0 01-.25-.25V5A4.775 4.775 0 0012 0a4.775 4.775 0 00-4.5 5v2.75a.25.25 0 01-.25.25H6.5a1 1 0 000 2h.75a.25.25 0 01.25.25V17a5.121 5.121 0 001.415 3.96.251.251 0 01.085.188V22.5a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-1.352a.251.251 0 01.085-.188A5.121 5.121 0 0016.5 17v-6.75a.25.25 0 01.25-.25h.75a1 1 0 000-2zm-3 9c0 1.343-.35 2.3-.961 2.612A1 1 0 0013 20.5v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V20.5a1 1 0 00-.539-.888C9.85 19.3 9.5 18.343 9.5 17v-6.75a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25z\"/><path d=\"M12 18.5a1 1 0 001-1V13a1 1 0 00-2 0v4.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});