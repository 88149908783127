define("ember-svg-jar/inlined/renewable-energy-solar-power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-solar-power</title><path d=\"M23.919 15.507a.878.878 0 00-.794-.507H21.5a.5.5 0 01-.5-.5v-5a.5.5 0 00-.89-.313l-5.9 7.371A.875.875 0 0014.875 18H16.5a.5.5 0 01.5.5v5a.5.5 0 00.89.312l5.9-7.37a.879.879 0 00.129-.935z\"/><circle cx=\"6.5\" cy=\"6.5\" r=\"2.5\"/><path d=\"M6.5 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM2.611 4.025a1 1 0 101.414-1.414L3.318 1.9A1 1 0 001.9 3.318zM3 6.5a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1zM2.611 8.975l-.711.707A1 1 0 003.318 11.1l.707-.707a1 1 0 10-1.414-1.418zM5.5 11v1a1 1 0 002 0v-1a1 1 0 00-2 0zM8.975 8.975a1 1 0 000 1.414l.707.707A1 1 0 0011.1 9.682l-.707-.707a1 1 0 00-1.418 0zM10 6.5a1 1 0 001 1h1a1 1 0 000-2h-1a1 1 0 00-1 1zM10.389 4.025l.707-.707A1 1 0 009.682 1.9l-.707.707a1 1 0 101.414 1.414zM14.653 5.809a.5.5 0 00.854-.354.356.356 0 01.51-.322 7.01 7.01 0 012.672 2.233A1 1 0 1020.311 6.2a8.991 8.991 0 00-4.461-3.286.5.5 0 01-.343-.475v-.984a.5.5 0 00-.854-.353l-2 2a.5.5 0 000 .707zM11.36 17.1a.5.5 0 00-.853.353.358.358 0 01-.511.323 6.988 6.988 0 01-3.2-3.095 1 1 0 00-1.775.923A8.972 8.972 0 0010.164 20a.5.5 0 01.343.475v.984a.5.5 0 00.309.462.508.508 0 00.191.038.5.5 0 00.353-.146l2-2a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});