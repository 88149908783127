define("ember-svg-jar/inlined/crypto-currency-bitcoin-dollar-unequal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-dollar-unequal-1</title><path d=\"M11.969 18.188a.518.518 0 00-.781 0l-4 5a.5.5 0 00.391.812h8a.5.5 0 00.39-.812zM23.151 17.7L1.277 12.913a1 1 0 10-.428 1.953l21.874 4.789a1 1 0 001.191-.762 1 1 0 00-.763-1.193zM1.329 10a1 1 0 001 1H2.6a.25.25 0 01.245.2 1 1 0 001.961-.006.25.25 0 01.228-.2A3 3 0 007.829 8a2.962 2.962 0 00-.65-1.845.249.249 0 010-.31A2.962 2.962 0 007.829 4a3 3 0 00-2.791-2.993.25.25 0 01-.228-.2A1 1 0 002.849.8.25.25 0 012.6 1h-.271a1 1 0 00-1 1zm3.5-1h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 110 2zm1-5a1 1 0 01-1 1h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 011 1zM20.079 12h-2.146a1 1 0 000 2h.25a.25.25 0 01.25.25v.25a1 1 0 002 0v-.324a.248.248 0 01.2-.244 2.845 2.845 0 00.721-5.339l-1.949-.975A.854.854 0 0119.787 6h2.146a1 1 0 000-2h-.25a.25.25 0 01-.25-.25V3.5a1 1 0 00-2 0v.323a.249.249 0 01-.2.245 2.845 2.845 0 00-.722 5.339l1.949.975A.854.854 0 0120.079 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});