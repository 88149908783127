define("ember-svg-jar/inlined/monetization-user-monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-user-monitor-1</title><path d=\"M21.505.462h-9.98a1 1 0 000 2h9.98c.343 0 .5.375.5.724v9.53c0 .349-.156.724-.5.724h-7.984a1 1 0 100 2h.249a.25.25 0 01.25.25v1a.251.251 0 01-.25.25h-2.245a1 1 0 000 2h6.986a1 1 0 000-2h-2.245a.251.251 0 01-.25-.25v-1a.25.25 0 01.25-.25h5.239a2.557 2.557 0 002.5-2.72V3.182a2.557 2.557 0 00-2.5-2.72zM.5 16.432h1.455a.25.25 0 01.249.224l.643 6.435a.5.5 0 00.5.449h2.84a.5.5 0 00.5-.449l.644-6.435a.249.249 0 01.249-.224h1.45a.5.5 0 00.5-.5v-2.845A4.769 4.769 0 007.054 8.91a.249.249 0 01-.011-.431A4.073 4.073 0 102.678 8.4a.251.251 0 01-.027.433A4.752 4.752 0 000 13.087v2.846a.5.5 0 00.5.5zm5.014-4.153a.749.749 0 11-1.5 0v-1.687a.749.749 0 111.5 0zM2.938 4.431a.248.248 0 01.368-.139 5.056 5.056 0 002.632.735 5.125 5.125 0 00.942-.087.094.094 0 01.074.019.09.09 0 01.032.068 2.071 2.071 0 11-4.141 0 1.956 1.956 0 01.093-.596z\"/><path d=\"M14.894 2.708a.748.748 0 00-.749.748v.307a.251.251 0 01-.2.245 2.079 2.079 0 00-.358 3.972l2.058.824a.591.591 0 01-.219 1.139H13.4a.748.748 0 100 1.5h.5a.251.251 0 01.25.25v.249a.749.749 0 101.5 0v-.306a.249.249 0 01.2-.244 2.081 2.081 0 00.35-3.978l-2.058-.823a.591.591 0 01.219-1.139h2.03a.749.749 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.246a.748.748 0 00-.747-.748z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});