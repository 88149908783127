define("ember-svg-jar/inlined/video-game-hatchi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-hatchi</title><path d=\"M12 0C4.933 0 2 9.444 2 14.5 2 20.271 5.925 24 12 24s10-3.729 10-9.5C22 9.444 19.067 0 12 0zM8 20a1 1 0 111-1 1 1 0 01-1 1zm4 1.5a1 1 0 111-1 1 1 0 01-1 1zm4-1.5a1 1 0 111-1 1 1 0 01-1 1zm1.5-5a1 1 0 01-1 1h-9a1 1 0 01-1-1V8a1 1 0 011-1h9a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});