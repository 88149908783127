define("ember-svg-jar/inlined/add-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>add-bold</title><path d=\"M8.5 22a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-6.25a.25.25 0 01.25-.25H22a1.5 1.5 0 001.5-1.5v-4A1.5 1.5 0 0022 8.5h-6.25a.25.25 0 01-.25-.25V2A1.5 1.5 0 0014 .5h-4A1.5 1.5 0 008.5 2v6.25a.25.25 0 01-.25.25H2A1.5 1.5 0 00.5 10v4A1.5 1.5 0 002 15.5h6.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});