define("ember-svg-jar/inlined/agriculture-machine-seeder-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-seeder-1</title><path d=\"M4.808 13.856a.5.5 0 00-.429-.091l-3.621.9a1 1 0 00-.758.976v.609a1 1 0 001 1h3.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.192-.394zM13.5 12.75h-3a.5.5 0 00-.354.146.621.621 0 00-.172.534 1.984 1.984 0 01.026.32v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a1.984 1.984 0 01.026-.32.617.617 0 00-.172-.534.5.5 0 00-.354-.146zM1.487 18.288a.749.749 0 00-.948.475l-.5 1.5a.749.749 0 101.422.474l.5-1.5a.75.75 0 00-.474-.949zM4.461 18.763a.749.749 0 10-1.422.474l.5 1.5a.749.749 0 101.422-.474zM23.242 14.67l-3.621-.9a.5.5 0 00-.429.091.5.5 0 00-.192.389v2.5a.5.5 0 00.5.5H23a1 1 0 001-1v-.609a1 1 0 00-.758-.971zM23.461 18.763a.749.749 0 10-1.422.474l.5 1.5a.749.749 0 101.422-.474zM20.487 18.288a.75.75 0 00-.948.475l-.5 1.5a.749.749 0 001.422.474l.5-1.5a.75.75 0 00-.474-.949z\"/><rect x=\"6\" y=\"12.75\" width=\"3\" height=\"8.5\" rx=\"1\" ry=\"1\"/><path d=\"M16.238 11.584a.5.5 0 00.122-.363l-.341-4.834a.5.5 0 01.16-.4 1 1 0 00.321-.737v-1.5a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0v1.5a1 1 0 00.322.734.5.5 0 01.159.4l-.339 4.838a.5.5 0 00.458.534 2.1 2.1 0 011.1.395.249.249 0 00.4-.181l.29-3.762a.5.5 0 01.5-.461h3.222a.5.5 0 01.5.461l.289 3.758a.254.254 0 00.147.209.248.248 0 00.254-.029 2.018 2.018 0 011.1-.394.5.5 0 00.336-.168z\"/><rect x=\"15\" y=\"12.75\" width=\"3\" height=\"8.5\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});