define("ember-svg-jar/inlined/zoom-in-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>zoom-in-1</title><path d=\"M23.414 20.591L19.2 16.377a10.51 10.51 0 10-2.828 2.823l4.214 4.214a2.027 2.027 0 002.828 0 2 2 0 000-2.823zM10.5 2.005a8.5 8.5 0 11-8.5 8.5 8.51 8.51 0 018.5-8.5z\"/><path d=\"M7 11.505h2.5v2.5a1 1 0 102 0v-2.5H14a1 1 0 000-2h-2.5v-2.5a1 1 0 10-2 0v2.5H7a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});