define("ember-svg-jar/inlined/gmo-food-steak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gmo-food-steak</title><circle cx=\"6\" cy=\"18\" r=\"1\"/><path d=\"M23.707 4.293l-4-4a1 1 0 00-1.414 1.414l.564.564a.5.5 0 010 .707l-.682.682a.5.5 0 01-.354.147.5.5 0 01-.353-.147l-1.746-1.746a1 1 0 00-1.414 1.414l.759.759a.429.429 0 010 .6.427.427 0 000 .6l1.112 1.112a.5.5 0 01-.707.707l-1.061-1.06a.5.5 0 00-.707 0l-.859.859a.5.5 0 000 .707l1.009 1.009a.5.5 0 01-.708.707l-1.008-1.001a.5.5 0 00-.707 0l-.3.3a3 3 0 00-.586 3.414l-.772.772a.5.5 0 01-.616.071A6 6 0 106 24h6.5a4 4 0 000-8 1.273 1.273 0 01-.784-.268.5.5 0 00-.66.041l-1.433 1.433a.5.5 0 00-.144.408A3.525 3.525 0 019.5 18a.5.5 0 00.5.5h2.5a1.5 1.5 0 010 3H6a3.5 3.5 0 111.333-6.737.25.25 0 01.082.408l-.185.184a1 1 0 001.415 1.415l3.309-3.31a3 3 0 003.414-.585l3.89-3.89a.5.5 0 01.707 0l.707.707a1 1 0 001.414-1.414L20.34 6.532a.5.5 0 010-.707l.682-.682a.5.5 0 01.707 0l.564.564a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});