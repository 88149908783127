define("ember-svg-jar/inlined/credit-card-phone-call-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-phone-call-alternate</title><path d=\"M15.109 16.734l.02-.02A.994.994 0 0014.443 15H3a1 1 0 01-1-1V7.75a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25V8a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11a3 3 0 003 3h11.423a1.012 1.012 0 00.686-.266zM3 2h16a1 1 0 011 1v1.25a.25.25 0 01-.25.25H2.25A.25.25 0 012 4.25V3a1 1 0 011-1z\"/><path d=\"M8 11H5a1 1 0 000 2h3a1 1 0 000-2zM22.29 10.315A1.7 1.7 0 0021.1 9.8a1.379 1.379 0 00-.986.4l-1.554 1.549a1.548 1.548 0 00.115 2.181l.414.414a.251.251 0 01.018.335 31.357 31.357 0 01-4.428 4.428.251.251 0 01-.335-.017l-.415-.415a1.7 1.7 0 00-1.2-.51 1.378 1.378 0 00-.986.4L10.2 20.11a1.548 1.548 0 00.115 2.181l.944.945a2.553 2.553 0 001.788.764 2.108 2.108 0 001.136-.321 30.635 30.635 0 009.5-9.495 2.383 2.383 0 00-.445-2.926z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});