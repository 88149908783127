define("ember-svg-jar/inlined/content-paper-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-paper-edit</title><path d=\"M12.75 5h-6a.75.75 0 000 1.5h6a.75.75 0 000-1.5zM13.5 9.25a.75.75 0 00-.75-.75h-8a.75.75 0 000 1.5h8a.75.75 0 00.75-.75zM4.75 12a.75.75 0 000 1.5h5.5a.75.75 0 000-1.5z\"/><path d=\"M11.3 17.655a.252.252 0 00-.231-.155H2.5A.5.5 0 012 17V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v10.07a.25.25 0 00.427.176l1.28-1.28a1 1 0 00.293-.707V2a2 2 0 00-2-2H2a2 2 0 00-2 2v15.5a2 2 0 002 2h7.868a.5.5 0 00.478-.353 1.934 1.934 0 01.5-.819l.4-.4a.25.25 0 00.054-.273zM12.062 20.131a.251.251 0 00-.422.128l-.628 3.141a.5.5 0 00.49.6.466.466 0 00.1-.01l3.143-.629a.25.25 0 00.127-.422zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-.007-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});