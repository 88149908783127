define("ember-svg-jar/inlined/rat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rat</title><path d=\"M24 6.25a5.111 5.111 0 00-.639-2.456c-.012-.024-.024-.049-.039-.073a5.033 5.033 0 00-4.31-2.471 4.937 4.937 0 00-4.68 3.325 8.631 8.631 0 00-4.664 0A4.936 4.936 0 004.989 1.25 5.035 5.035 0 00.678 3.72a.773.773 0 00-.04.075A5.1 5.1 0 000 6.25a4.979 4.979 0 004.005 4.9 14.332 14.332 0 01.037 1.45 16.23 16.23 0 00.258 2.244 4.515 4.515 0 00-1.946 1.044 1 1 0 000 1.414 1.011 1.011 0 001.412 0 2.6 2.6 0 011.092-.538 11.041 11.041 0 00.738 1.557 4.941 4.941 0 00-1.241 1.9 1 1 0 00.633 1.264 1.015 1.015 0 001.263-.633 2.621 2.621 0 01.53-.87A7.566 7.566 0 0012 22.75a7.583 7.583 0 005.254-2.809 2.675 2.675 0 01.562.906 1.017 1.017 0 001.263.633 1 1 0 00.633-1.264 4.965 4.965 0 00-1.281-1.937 11.028 11.028 0 00.722-1.534 2.664 2.664 0 011.15.556 1 1 0 101.412-1.414 4.593 4.593 0 00-2.006-1.061c.14.038.263-3.673.287-3.678A4.986 4.986 0 0024 6.25zM8.79 14a1 1 0 111-1 1 1 0 01-1 1zm3.242 4.25c.826 0 1.5.31 1.5 1a1.6 1.6 0 01-1.5 1.5 1.6 1.6 0 01-1.5-1.5c.004-.69.674-1 1.5-1zM16.271 13a1 1 0 11-1-1 1 1 0 011 1zM2.026 6.518a2.65 2.65 0 01.339-1.295c.007-.013.013-.019.013-.026 2.061-.863 3.313.392 3.528 1.608.109.614-1.175 2.4-1.747 2.282a2.61 2.61 0 01-2.133-2.569zm17.816 2.569c-.555.111-1.857-1.662-1.748-2.282.215-1.216 1.467-2.471 3.528-1.608 0 .007.006.013.013.033a2.614 2.614 0 01-1.793 3.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});