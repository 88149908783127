define("ember-svg-jar/inlined/messages-people-person-bubble-oval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-person-bubble-oval</title><circle cx=\"13.116\" cy=\"5.5\" r=\"1\"/><circle cx=\"16.366\" cy=\"5.5\" r=\"1\"/><circle cx=\"19.616\" cy=\"5.5\" r=\"1\"/><path d=\"M18.5 0h-6a5.525 5.525 0 00-5.363 4.31 1 1 0 001.953.43A3.512 3.512 0 0112.5 2h6a3.5 3.5 0 010 7h-1a1 1 0 00-.625.219l-.878.7A1 1 0 0015 9h-1a1 1 0 000 2v1a1 1 0 001.625.781L17.851 11h.649a5.5 5.5 0 000-11z\"/><path d=\"M11.5 17.329l-.63-.229a2.236 2.236 0 01-.08-1.608 5.621 5.621 0 001.466-4.315C12.256 8.755 10.677 7 8.5 7s-3.756 1.755-3.756 4.174A5.6 5.6 0 006.2 15.47a2.2 2.2 0 01-.067 1.63l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.17 9.17 0 000 23.5a.5.5 0 00.5.5h16a.5.5 0 00.5-.5 9.17 9.17 0 00-.748-3.545c-.539-1.077-2.307-1.727-4.752-2.626z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});