define("ember-svg-jar/inlined/video-game-gamasutra-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-gamasutra-2</title><path d=\"M13 13v-2a1 1 0 00-2 0v2a1 1 0 002 0zM7 10V7.5a3.5 3.5 0 00-7 0V10a1 1 0 001 1h5a1 1 0 001-1zM2.25 8.75A.75.75 0 113 8a.75.75 0 01-.75.75zm2.5 0A.75.75 0 115.5 8a.75.75 0 01-.75.75zM15.5 3.5a3.5 3.5 0 00-7 0V6a1 1 0 001 1h5a1 1 0 001-1zm-4.75 1.25A.75.75 0 1111.5 4a.75.75 0 01-.75.75zm2.5 0A.75.75 0 1114 4a.75.75 0 01-.75.75zM20.5 4A3.5 3.5 0 0017 7.5V10a1 1 0 001 1h5a1 1 0 001-1V7.5A3.5 3.5 0 0020.5 4zm-1.25 4.75A.75.75 0 1120 8a.75.75 0 01-.75.75zm2.5 0A.75.75 0 1122.5 8a.75.75 0 01-.75.75zM12 15a2.5 2.5 0 00-2.5 2.5v6a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-6A2.5 2.5 0 0012 15zM15.9 20.479a.25.25 0 00-.4.2V23.5a.5.5 0 00.5.5h1.534a1 1 0 00.61-1.792zM8.36 20.443a.25.25 0 00-.263.026l-2.247 1.74A1 1 0 006.462 24H8a.5.5 0 00.5-.5v-2.833a.248.248 0 00-.14-.224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});