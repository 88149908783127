define("ember-svg-jar/inlined/arrow-thick-left-bottom-corner-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-bottom-corner-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-4.707 8.489l-5.582 5.58a.25.25 0 00.177.427H16.3a.5.5 0 01.353.854l-2 2a.5.5 0 01-.353.146H7a.5.5 0 01-.5-.5V9.7a.5.5 0 01.146-.353l2-2A.5.5 0 019.5 7.7v4.039a.25.25 0 00.424.179l5.746-5.564a.5.5 0 01.7.006l1.42 1.419a.5.5 0 01.003.71z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});