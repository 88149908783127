define("ember-svg-jar/inlined/trends-torch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trends-torch</title><path d=\"M8.75 10.261h6.75a.5.5 0 00.421-.231 4.627 4.627 0 00.579-2.52 4.151 4.151 0 00-2.816-3.964.5.5 0 00-.684.408 1.3 1.3 0 01-.338.716A3.486 3.486 0 0113.8.915a.5.5 0 00-.408-.89A7.8 7.8 0 007.5 7.51a4.849 4.849 0 00.831 2.525.5.5 0 00.419.226zM19.962 12.07a.5.5 0 00-.462-.309h-15a.5.5 0 00-.354.854 12.086 12.086 0 004.81 2.674l1.828 7.723a1.247 1.247 0 002.434 0l1.828-7.723a12.085 12.085 0 004.808-2.674.5.5 0 00.108-.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});