define("ember-svg-jar/inlined/folder-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-disable</title><path d=\"M17.5 9.5a7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.563a.447.447 0 00.437-.5 8.009 8.009 0 018-8z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-3.967 8.6a4.448 4.448 0 01-.367-3.319 4.5 4.5 0 013.112-3.117 4.443 4.443 0 013.325.367.252.252 0 01.052.4l-5.724 5.722a.252.252 0 01-.398-.053zm8.3-.887a4.5 4.5 0 01-3.112 3.117 4.443 4.443 0 01-3.325-.367.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.4.051 4.448 4.448 0 01.366 3.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});