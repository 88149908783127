define("ember-svg-jar/inlined/style-three-pin-navigation-location-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-navigation-location-1</title><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm5.2 5.76l-1.793 6.12a1.5 1.5 0 01-1.018 1.02l-6.111 1.8a1.167 1.167 0 01-1.178-.29 1.185 1.185 0 01-.3-1.17l1.8-6.127A1.5 1.5 0 019.613 6.1l6.127-1.8a1.174 1.174 0 011.46 1.46z\"/><path d=\"M15.159 6.03L10.86 7.3l-.415.122a.25.25 0 00-.107.416l3.324 3.324a.251.251 0 00.417-.107l.121-.415 1.27-4.3a.25.25 0 00-.311-.311z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});