define("ember-svg-jar/inlined/famous-character-kermit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-kermit</title><path d=\"M22.534 14.812A5.508 5.508 0 0018.649 13a5.707 5.707 0 00-2.051.337 1 1 0 00-.638.784l-.281 1.734a.5.5 0 01-.216.336.5.5 0 01-.394.07l-.606-.146a1 1 0 00-1.178.636 5.427 5.427 0 001.516 5.77A5.54 5.54 0 0018.525 24c.1 0 .208 0 .311-.01a5.464 5.464 0 003.7-9.178zM16.751 21.5A1.25 1.25 0 1118 20.25a1.25 1.25 0 01-1.249 1.25zm3.5-2.5a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/><circle cx=\"12.751\" cy=\"4.25\" r=\"1\"/><circle cx=\"4.252\" cy=\"3.75\" r=\"1\"/><path d=\"M17 8.5a5.467 5.467 0 00-.271-1.687 4.692 4.692 0 01-.229-1.45V4a4 4 0 00-7.589-1.756.458.458 0 01-.822 0A4 4 0 00.5 4v1.364a4.692 4.692 0 01-.229 1.45A5.468 5.468 0 000 8.5a5.826 5.826 0 003.232 5.234c1.481.743 2.548 1.253 3.307 1.6a4.657 4.657 0 001.961.416 4.649 4.649 0 001.96-.411c.76-.351 1.827-.861 3.307-1.6A5.824 5.824 0 0017 8.5zM10.5 4a2 2 0 014 0v1.364a.637.637 0 01-.638.636h-2.727a.637.637 0 01-.636-.637zM3.138 6a.637.637 0 01-.638-.636V4a2 2 0 014 0v1.364A.637.637 0 015.865 6zM13 10.615l-2.789 1.323a4 4 0 01-3.424 0L4 10.615a1 1 0 01.5-1.865h8a1 1 0 01.5 1.865z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});