define("ember-svg-jar/inlined/single-woman-actions-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-share-1</title><path d=\"M1.113 24a.99.99 0 00.99-.99v-5.555a.99.99 0 00-1.979 0v5.555a.989.989 0 00.989.99zM3.24 17.375a.489.489 0 00-.147.351v4.981a.5.5 0 00.5.5h7.917a2.02 2.02 0 001.974-1.907v-.2a.96.96 0 00-.989-.862H5.567a.35.35 0 01-.319-.383.367.367 0 01.367-.367H7.55a.878.878 0 00.807-1.224 1.884 1.884 0 00-1.794-1l-2.946-.032a.222.222 0 00-.045 0 .612.612 0 00-.332.143zM12.017 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.483.388h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.32a.5.5 0 00.478-.626c-1.964-7.162-1.88-7.546-3.007-8.6a.495.495 0 00-.756.1l-1.734 2.729a.5.5 0 01-.837 0L15.8 9.411a.5.5 0 00-.755-.1c-1.134 1.045-1.152 1.783-2.329 6.078zM13.711 4.206A4.206 4.206 0 1017.917 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.224 2.224 0 0115.78 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.132 1.623z\"/><path d=\"M1.113 24a.99.99 0 00.99-.99v-5.555a.99.99 0 00-1.979 0v5.555a.989.989 0 00.989.99zM3.24 17.375a.489.489 0 00-.147.351v4.981a.5.5 0 00.5.5h7.917a2.02 2.02 0 001.974-1.907v-.2a.96.96 0 00-.989-.862H5.567a.35.35 0 01-.319-.383.367.367 0 01.367-.367H7.55a.878.878 0 00.807-1.224 1.884 1.884 0 00-1.794-1l-2.946-.032a.222.222 0 00-.045 0 .612.612 0 00-.332.143zM12.017 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.483.388h2.969a.5.5 0 00.483-.388v-.007l.921-4.847a.247.247 0 01.243-.2h2.32a.5.5 0 00.478-.626c-1.964-7.162-1.88-7.546-3.007-8.6a.495.495 0 00-.756.1l-1.734 2.729a.5.5 0 01-.837 0L15.8 9.411a.5.5 0 00-.755-.1c-1.134 1.045-1.152 1.783-2.329 6.078zM13.711 4.206A4.206 4.206 0 1017.917 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.224 2.224 0 0115.78 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.132 1.623z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});