define("ember-svg-jar/inlined/multiple-actions-key.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-key</title><path d=\"M16.33 13.655a.253.253 0 00.207-.066l2.077-1.962a.249.249 0 00.069-.252 4.855 4.855 0 00-.308-.8c-.454-.906-1.9-1.439-3.91-2.177l-.485-.173a1.8 1.8 0 01-.055-1.172 4.646 4.646 0 001.215-3.565A3.218 3.218 0 0012 0a2.991 2.991 0 00-1.342.314.25.25 0 00-.1.363 5 5 0 01.833 2.807 6.268 6.268 0 01-.921 3.623.248.248 0 00-.029.215.254.254 0 00.15.157c1.917.715 3.3 1.327 3.9 2.536a8.185 8.185 0 01.737 3.337.243.243 0 00.075.176.245.245 0 00.177.072 5.719 5.719 0 01.85.055z\"/><path d=\"M13.72 13.848A.359.359 0 0014 13.5a7.538 7.538 0 00-.618-2.922c-.454-.906-1.9-1.439-3.91-2.177l-.485-.173a1.8 1.8 0 01-.056-1.172 4.642 4.642 0 001.216-3.567A3.218 3.218 0 007 0a3.218 3.218 0 00-3.147 3.488 4.608 4.608 0 001.192 3.537 1.877 1.877 0 010 1.185l-.514.19c-2.006.738-3.456 1.271-3.91 2.177A7.538 7.538 0 000 13.5a.5.5 0 00.5.5h11.959a5.363 5.363 0 001.261-.152zM24 11.63a.5.5 0 00-.5-.5h-1.978a.5.5 0 00-.343.137l-4.187 3.955a.249.249 0 01-.244.058 4.454 4.454 0 10-1.792 8.687 4.355 4.355 0 00.509.029 4.452 4.452 0 004.259-5.729.252.252 0 01.063-.249l4.067-4.056a.505.505 0 00.146-.354zm-7.162 8.986a1.736 1.736 0 01-2.455-2.455 1.778 1.778 0 012.455 0 1.736 1.736 0 010 2.454z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});