define("ember-svg-jar/inlined/time-clock-six-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-six-1</title><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zm2 0a10 10 0 1110 10A10.011 10.011 0 012 12z\"/><path d=\"M12 4a.5.5 0 01.5.5v15a.5.5 0 01-.5.5 8 8 0 010-16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});