define("ember-svg-jar/inlined/road-sign-turn-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-right-alternate</title><path d=\"M23.25 6.75h-4.586a.25.25 0 00-.177.427l3.94 3.939a1.252 1.252 0 010 1.768l-3.939 3.939a.25.25 0 00.176.427h4.586a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75z\"/><path d=\"M21.086 12a.251.251 0 00-.073-.177l-5-5a.249.249 0 00-.177-.073h-5.172a.25.25 0 00-.177.427l3.94 3.939a1.252 1.252 0 010 1.768l-3.94 3.939a.25.25 0 00.177.427h5.172a.249.249 0 00.177-.073l5-5a.251.251 0 00.073-.177z\"/><path d=\"M13.013 11.823l-5-5a.251.251 0 00-.177-.073H2.664a.25.25 0 00-.177.427l3.94 3.939a1.252 1.252 0 010 1.768l-3.94 3.939a.25.25 0 00.177.427h5.172a.251.251 0 00.177-.073l5-5a.252.252 0 000-.354z\"/><path d=\"M5.086 12a.251.251 0 00-.073-.177L.434 7.245a.25.25 0 00-.423.132A.712.712 0 000 7.5v9a.712.712 0 00.011.123.25.25 0 00.423.133l4.579-4.579A.251.251 0 005.086 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});