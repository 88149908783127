define("ember-svg-jar/inlined/cat-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-water</title><path d=\"M17.107 18.5a.25.25 0 00.25-.25V17a1.5 1.5 0 00-1.432-1.493 2 2 0 013.815-.183 1 1 0 101.882-.676 3.979 3.979 0 00-6.765-1.266 3.98 3.98 0 00-6.769 1.277 1 1 0 101.883.67 2 2 0 013.817.178A1.5 1.5 0 0012.357 17v1.25a.249.249 0 00.25.25zM11.357 7.287v-7c0-.275-.183-.369-.407-.209L7.016 2.887a6.606 6.606 0 00-2.324 0L.764.078C.54-.082.357.012.357.287v7c0 3.5 2.462 4.5 5.5 4.5s5.5-1 5.5-4.5zm-4.433-.75a2 2 0 012.732-.73.5.5 0 01-.5.865.991.991 0 00-.758-.1 1 1 0 00-.608.466.5.5 0 11-.865-.5zm-2.3.62a.5.5 0 01-.686-.172 1 1 0 00-1.372-.342.5.5 0 01-.515-.858 2 2 0 012.749.687.5.5 0 01-.171.685zm.874 2.6l-.5-.5a.5.5 0 01.359-.857h1a.5.5 0 01.353.854l-.5.5a.5.5 0 01-.707 0zM23.25 20H5.75a.373.373 0 00-.379.485l.394 1.575A2.7 2.7 0 008.25 24h12.5a2.7 2.7 0 002.485-1.94l.394-1.575A.373.373 0 0023.25 20z\"/><circle cx=\"8.607\" cy=\"18\" r=\"1.25\"/><circle cx=\"21.107\" cy=\"18\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});