define("ember-svg-jar/inlined/wild-bird-woodpecker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-woodpecker</title><path d=\"M23 1a1 1 0 00-1-1h-6a1 1 0 00-1 1v12.3a.5.5 0 01-.5.5h-1.717a.5.5 0 01-.488-.392 8.555 8.555 0 00-2.761-4.576c.222-.26 3.192-3.888 3.808-4.468A.5.5 0 0013 3.5H9c-5.351 0-7.446 4.665-7.97 6.119a.5.5 0 00.694.618c2.039-1.022 2.686-.637 3.37-.232.142.084.283.166.429.238.5 1.481.477 8.079.437 9.008C7.4 18.955 8.5 17.3 8.5 15.3a2.987 2.987 0 00-.5-1.666.5.5 0 11.832-.555A3.982 3.982 0 019.5 15.3c0 2.59-1.584 4.727-3.605 4.975A9.992 9.992 0 015.24 23.3a.5.5 0 00.59.679 9 9 0 005.712-4.655.5.5 0 01.521-.27c.615.092 1.854.272 2.509.367a.5.5 0 01.428.5V23a1 1 0 001 1h6a1 1 0 001-1zm-4.167 4.5c0 1.381-.41 2.5-.916 2.5S17 6.881 17 5.5s.41-2.5.917-2.5.916 1.119.916 2.5zM7 7.3a1 1 0 111-1 1 1 0 01-1 1zm5.831 9.858a.5.5 0 01-.425-.567c.017-.114.031-.229.044-.345a.5.5 0 01.5-.446h1.55a.5.5 0 01.5.5v.591a.5.5 0 01-.571.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});