define("ember-svg-jar/inlined/time-clock-file-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-edit</title><path d=\"M23.413 3L21 .587A2 2 0 0019.585 0H8a2 2 0 00-2 2v9.582a.249.249 0 00.421.182 67.841 67.841 0 011.5-1.426.241.241 0 00.079-.182V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.354.147l2.121 2.121a.5.5 0 01.147.354V18a.5.5 0 01-.5.5H11.431a.25.25 0 00-.177.074l-1.5 1.5a.249.249 0 00.177.426H22a2 2 0 002-2V4.416A2 2 0 0023.413 3z\"/><path d=\"M13.99 11.675a4.09 4.09 0 01.437 1.3.357.357 0 00.149.18 4.6 4.6 0 00.923.1 4.75 4.75 0 10-4.75-4.75 4.616 4.616 0 00.091.887.439.439 0 00.226.189 4.059 4.059 0 011.284.444c.154.085.245-.051.165-.233a3.25 3.25 0 111.649 1.676c-.164-.079-.264.044-.174.207zM1.061 20.132a.25.25 0 00-.422.128L.011 23.4A.5.5 0 00.5 24a.55.55 0 00.1-.009l3.143-.629a.25.25 0 00.128-.422zM12.035 11.592a2.71 2.71 0 00-3.606.273l-6.58 6.58a.252.252 0 000 .354L5.2 22.152a.249.249 0 00.353 0l6.679-6.679a2.622 2.622 0 00-.197-3.881z\"/><path d=\"M15.5 5.984a.75.75 0 00-.75.75V8.5a.75.75 0 00.75.75h1.768a.75.75 0 100-1.5H16.5a.251.251 0 01-.252-.251v-.765a.75.75 0 00-.748-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});