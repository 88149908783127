define("ember-svg-jar/inlined/appliances-food-processor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-food-processor</title><path d=\"M19.249 16.7a.5.5 0 01-.249-.432V7.5A2.5 2.5 0 0016.5 5H16a.5.5 0 01-.5-.5v-2A.5.5 0 0116 2h.5a1 1 0 000-2h-9a1 1 0 000 2H8a.5.5 0 01.5.5v2A.5.5 0 018 5h-.5A2.5 2.5 0 005 7.5v8.768a.5.5 0 01-.249.433A1.5 1.5 0 004 18v5a1 1 0 001 1h14a1 1 0 001-1v-5a1.5 1.5 0 00-.751-1.3zM10.5 2.5A.5.5 0 0111 2h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm1.5 19a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm5-12.25a.5.5 0 01-.5.5H15a.75.75 0 000 1.5h1.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H15a.75.75 0 000 1.5h1.5a.5.5 0 01.5.5v.75a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-7a.5.5 0 01.5-.5h9a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});