define("ember-svg-jar/inlined/style-three-pin-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-question</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.749 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 17.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.557-6.609a1.009 1.009 0 00-.557.9v.468a1 1 0 01-2 0v-.468A3.023 3.023 0 0112.666 9.1 1.5 1.5 0 1010.5 7.75a1 1 0 01-2 0 3.5 3.5 0 115.057 3.136z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});