define("ember-svg-jar/inlined/style-three-pin-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-box</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5 14a1 1 0 01-1 1H8a1 1 0 01-1-1V7a1 1 0 011-1h1.5a.5.5 0 01.5.5v3.293a.5.5 0 00.854.353l.792-.792a.5.5 0 01.708 0l.792.792A.5.5 0 0014 9.793V6.5a.5.5 0 01.5-.5H16a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});