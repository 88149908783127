define("ember-svg-jar/inlined/login-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-key-1</title><path d=\"M23.113 5.954L21.849 4.69l.881-.88a1.914 1.914 0 10-2.707-2.71L9.04 12.085a6.014 6.014 0 102.707 2.707l3.739-3.737.762.762a1.75 1.75 0 102.475-2.475l-.763-.762 1.416-1.417 1.264 1.263a1.788 1.788 0 002.473 0 1.751 1.751 0 000-2.472zm-16.737 8.5a3 3 0 11-3 3 3 3 0 013-2.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});