define("ember-svg-jar/inlined/direction-button-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>direction-button-square-alternate</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M9.5 7.5h5a.5.5 0 00.384-.82l-2.5-3a.517.517 0 00-.768 0l-2.5 3a.5.5 0 00.384.82zM11.616 20.32a.5.5 0 00.768 0l2.5-3a.5.5 0 00-.384-.82h-5a.5.5 0 00-.384.82zM16.788 14.953a.5.5 0 00.532-.069l3-2.5a.5.5 0 000-.768l-3-2.5a.5.5 0 00-.82.384v5a.5.5 0 00.288.453zM6.68 14.884a.5.5 0 00.82-.384v-5a.5.5 0 00-.82-.384l-3 2.5a.5.5 0 000 .768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});