define("ember-svg-jar/inlined/t-shirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>t-shirt</title><path d=\"M23.568 6.293l-3.752-3.879A3.012 3.012 0 0017.66 1.5h-1.038a.5.5 0 01-.376-.17c-.029-.033-.441-.447-.441-.447a3.089 3.089 0 00-1.026-.671h-.006A3.021 3.021 0 0013.678 0h-3.356a3.021 3.021 0 00-1.095.209h-.006A3.089 3.089 0 008.2.883s-.412.414-.441.447a.5.5 0 01-.376.17H6.34a3.012 3.012 0 00-2.156.914L.432 6.293a1.516 1.516 0 00-.247 1.794l1.994 3.36a1.507 1.507 0 001.1.81 1.49 1.49 0 00.592-.023.5.5 0 01.618.486v9.78a1.5 1.5 0 001.5 1.5h12.019a1.5 1.5 0 001.5-1.5v-9.78a.5.5 0 01.618-.486 1.49 1.49 0 00.592.023 1.507 1.507 0 001.1-.81l1.994-3.36a1.516 1.516 0 00-.244-1.794zm-13.082.392a.578.578 0 01-.228.385l-.907.681a.452.452 0 01-.644-.115L7.128 5.105a.87.87 0 01.124-1.085s.351-.351.373-.371a.586.586 0 01.859.085l1.891 2.517a.585.585 0 01.111.434zm-.913-4.017a.429.429 0 01-.038-.437A.4.4 0 019.9 2h4.194a.4.4 0 01.361.231.43.43 0 01-.039.437L12.2 5.721a.251.251 0 01-.405 0zm7.283 2.437l-1.578 2.531a.434.434 0 01-.291.194.426.426 0 01-.353-.079l-.908-.681a.584.584 0 01-.116-.819l1.89-2.517a.585.585 0 01.858-.085c.022.02.374.371.374.371a.869.869 0 01.124 1.085z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});