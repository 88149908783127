define("ember-svg-jar/inlined/mask-bubble-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-bubble-alternate</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M5.261 14.662a.251.251 0 01.091.221 3.441 3.441 0 003.765 3.765.253.253 0 01.22.091 3.442 3.442 0 005.326 0 .251.251 0 01.22-.091 3.441 3.441 0 003.765-3.765.25.25 0 01.091-.221 3.44 3.44 0 000-5.324.253.253 0 01-.091-.221 3.441 3.441 0 00-3.765-3.765.251.251 0 01-.22-.091 3.442 3.442 0 00-5.326 0 .253.253 0 01-.22.091 3.441 3.441 0 00-3.765 3.765.25.25 0 01-.091.221 3.44 3.44 0 000 5.324zm1.644-4a1 1 0 00.549-1.322 1.435 1.435 0 011.887-1.886 1 1 0 001.324-.548 1.437 1.437 0 012.67 0 1 1 0 001.324.548 1.435 1.435 0 011.887 1.886 1 1 0 00.549 1.327 1.434 1.434 0 010 2.666 1 1 0 00-.549 1.327 1.435 1.435 0 01-1.887 1.886 1 1 0 00-1.324.548 1.437 1.437 0 01-2.67 0 1 1 0 00-1.324-.548 1.435 1.435 0 01-1.887-1.886 1 1 0 00-.549-1.327 1.434 1.434 0 010-2.666z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});