define("ember-svg-jar/inlined/alarm-bell-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>alarm-bell-off</title><path d=\"M14.241 21H9.768a.25.25 0 00-.248.222 2.547 2.547 0 00-.02.278 2.5 2.5 0 005 0 2.548 2.548 0 00-.015-.278.249.249 0 00-.244-.222zM9.126 18.5H21a.5.5 0 00.447-.725 16.366 16.366 0 01-1.747-7.331v-.768a8.064 8.064 0 00-.149-1.605zM1 23.993a.981.981 0 00.707-.285l22-22a1.022 1.022 0 000-1.414 1 1 0 00-1.415 0L17.68 4.911a16.182 16.182 0 00-1.841-1.357 6.377 6.377 0 00-2.339-.918V1.5a1.5 1.5 0 10-3 0v1.149a6.752 6.752 0 00-2.85 1.277C5.782 5.392 4.3 6.551 4.3 9.676v2.268c0 2.38-.528 3.424-1.581 5.506l-.165.326A.5.5 0 003 18.5h1.09L.3 22.294a.994.994 0 00.7 1.699z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});