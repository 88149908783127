define("ember-svg-jar/inlined/database-subtract-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-subtract-alternate</title><path d=\"M21.663 4.962c0-3.219-5.356-4.956-10.4-4.956S.884 1.739.873 4.952v12.62c0 2.648 3.626 4.291 7.718 4.791a.948.948 0 00.961-1.371l-.023-.049a.983.983 0 00-.769-.565c-3.66-.448-5.889-1.812-5.889-2.806v-.759a.25.25 0 01.372-.218 14.007 14.007 0 004.42 1.428.952.952 0 001.115-.876l.006-.084a.947.947 0 00-.782-1c-3.205-.531-5.131-1.772-5.131-2.695v-.758a.249.249 0 01.372-.218A15.444 15.444 0 009 14a.926.926 0 00.88-.472l.03-.049a.959.959 0 00-.725-1.465c-3.909-.387-6.314-1.814-6.314-2.849v-.759a.251.251 0 01.123-.216.254.254 0 01.249 0 17.417 17.417 0 008.024 1.73 17.419 17.419 0 008.026-1.731.25.25 0 01.372.215 5.331 5.331 0 01-.024.891 1.043 1.043 0 00.534 1.152l.049.024a1 1 0 001.438-.894zm-10.4 2.956c-5.125 0-8.395-1.751-8.395-2.956s3.27-2.956 8.4-2.956 8.391 1.748 8.4 2.953v.009c-.017 1.204-3.284 2.95-8.401 2.95z\"/><path d=\"M16.7 11.143a6.429 6.429 0 106.429 6.429 6.436 6.436 0 00-6.429-6.429zm-3.214 6.429a.742.742 0 01.742-.742h4.945a.742.742 0 010 1.484h-4.945a.742.742 0 01-.742-.742z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});