define("ember-svg-jar/inlined/dresser-drawers-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-5</title><path d=\"M13.25 9a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm5.5 3.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM10.75 9h-10a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zm-3 3.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM22.25 0h-9a.5.5 0 00-.5.5V7a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V1.5a1.5 1.5 0 00-1.5-1.5zm-3.5 5.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM13.25 16.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h9a1.5 1.5 0 001.5-1.5V17a.5.5 0 00-.5-.5zm5.5 2.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM10.75 0h-9a1.5 1.5 0 00-1.5 1.5V7a.5.5 0 00.5.5h10a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-3 5.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM10.75 16.5h-10a.5.5 0 00-.5.5v5.5a1.5 1.5 0 001.5 1.5h9a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5zm-3 2.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});