define("ember-svg-jar/inlined/easter-egg-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>easter-egg-basket</title><path d=\"M23.505 10.633a.5.5 0 01-.154-.476c.756-3.524-.605-6.8-3.316-7.691-2.9-.95-6.443 1.378-7.654 5.362a.25.25 0 01-.219.177.252.252 0 01-.245-.139c-1.5-3.052-4.442-4.787-7.037-3.959-2.218.709-3.6 3.083-3.562 5.952a.5.5 0 01-.329.477A1.5 1.5 0 000 11.746a10.108 10.108 0 00.745 3.714.25.25 0 00.406.088c.107-.1.216-.219.332-.344a3.316 3.316 0 012.6-1.33 3.318 3.318 0 012.6 1.33c.525.561.831.855 1.5.855s.973-.294 1.5-.855a3.195 3.195 0 015.188 0c.528.561.833.855 1.5.855s.977-.294 1.5-.856a3.325 3.325 0 012.6-1.329 3.233 3.233 0 012.533 1.265.25.25 0 00.416-.09 9.993 9.993 0 00.59-3.3 1.5 1.5 0 00-.505-1.116zm-13.1-.6a.5.5 0 01-.409.213H3.819a.5.5 0 01-.5-.5c0-1.923.849-3.511 2.17-3.933 2.085-.668 4.158 1.5 4.976 3.764a.5.5 0 01-.065.456zm10.964-.175a.5.5 0 01-.487.388h-6.357a.5.5 0 01-.5-.551 6.645 6.645 0 01.306-1.4c.9-2.735 3.221-4.534 5.078-3.925 1.724.561 2.542 2.952 1.959 5.488z\"/><path d=\"M21.964 16.229c-.527-.561-.834-.855-1.5-.855s-.977.294-1.5.856a3.2 3.2 0 01-5.191 0c-.528-.562-.833-.855-1.5-.855s-.973.293-1.5.855a3.317 3.317 0 01-2.594 1.33 3.313 3.313 0 01-2.593-1.33c-.527-.562-.833-.855-1.5-.855s-.974.293-1.5.855a6.583 6.583 0 01-.651.633.5.5 0 00-.1.663A8.777 8.777 0 009 21.746h6a8.876 8.876 0 007.38-4.546.5.5 0 00-.075-.619c-.122-.118-.234-.238-.341-.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});