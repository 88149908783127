define("ember-svg-jar/inlined/navigation-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-menu</title><rect x=\".5\" y=\"2.5\" width=\"23\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\".5\" y=\"10.5\" width=\"23\" height=\"3\" rx=\"1\" ry=\"1\"/><rect x=\".5\" y=\"18.5\" width=\"23\" height=\"3\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});