define("ember-svg-jar/inlined/file-pdf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-pdf-1</title><path d=\"M11.462 12.263c-.2-.076-.337.028-.337.17v6.012c0 .233.189.341.446.254a3.375 3.375 0 00-.109-6.436zM6.5 12.123H5.374a.249.249 0 00-.249.249v2.252a.249.249 0 00.249.249H6.5a1.375 1.375 0 000-2.75z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-16 16.123H5.374a.249.249 0 00-.249.249V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.625 2.625 0 010 5.25zm4 4a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm10-8H18a.877.877 0 00-.875.875v1.626a.249.249 0 00.249.249H19a.625.625 0 010 1.25h-1.626a.249.249 0 00-.249.249V19.5a.625.625 0 11-1.25 0V13A2.127 2.127 0 0118 10.873h2.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});