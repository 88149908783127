define("ember-svg-jar/inlined/climbing-head-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>climbing-head-light</title><path d=\"M10.5 5v.5a2.5 2.5 0 005 0V5zM16 4a.5.5 0 00.5-.5 3.5 3.5 0 00-7 0 .5.5 0 00.5.5zM3.246 11.575l3.936-3.843a1.5 1.5 0 00.026-2.121l-.7-.715a1.492 1.492 0 00-1.056-.453 1.526 1.526 0 00-1.066.427L.453 8.712a1.5 1.5 0 00-.027 2.122l.7.713a1.5 1.5 0 002.121.028zM16.378 15.091a1.5 1.5 0 00-.788-1.969l-2.4-1.028a1.491 1.491 0 01-.788-.787l-1.027-2.4a1.5 1.5 0 00-2.489-.418l-5 5.5A1.476 1.476 0 003.5 15v3.2a1.5 1.5 0 01-.2.744l-1.6 2.812a1.5 1.5 0 102.6 1.488l1.6-2.808a4.51 4.51 0 00.6-2.236v-.611l1.332.766a1.5 1.5 0 01.668 1.251V22.5a1.5 1.5 0 003 0v-2.894a4.526 4.526 0 00-2.088-3.8l-1.78-1.021a.249.249 0 01-.123-.178.253.253 0 01.062-.207l1.714-1.884a.249.249 0 01.409.057 4.362 4.362 0 002.317 2.279l2.4 1.027a1.5 1.5 0 001.967-.788zM19.693 3.6l3.721-1.691a1 1 0 10-.828-1.82l-3.721 1.693a1 1 0 00.828 1.82zM23.414 7.091l-3.76-1.709a1 1 0 10-.828 1.82l3.76 1.709a1 1 0 00.828-1.82z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});