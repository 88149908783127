define("ember-svg-jar/inlined/security-officer-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-camera</title><path d=\"M18.448 5.7a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .008v-.759a.25.25 0 00-.25-.25zM23.111 4.7a.5.5 0 00.475-.342l.329-.988a1 1 0 00-.707-1.286L18.176.825a.742.742 0 00-.735.218.753.753 0 00-.161.746l.3.91h-.842a1 1 0 000 2zM23.994 11.7a.5.5 0 00-.5-.5h-1.41a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31h-1.74a2 2 0 00-1.416.587l-4.594 4.6a1.245 1.245 0 001.76 1.76l3.9-3.892a.25.25 0 01.426.177V20.7a.5.5 0 01-.5.5H7.493a2.506 2.506 0 00-1.487-2.041v-2.21h1.375a2 2 0 002-2v-1a2 2 0 00-2-2h-4.75a2 2 0 00-2 2v1a2 2 0 002 2h1.375v2.209A2.505 2.505 0 002.518 21.2H1.006a1 1 0 000 2h22.488a.5.5 0 00.5-.5zM3.756 14.449a1.25 1.25 0 111.25 1.25 1.25 1.25 0 01-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});