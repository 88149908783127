define("ember-svg-jar/inlined/real-estate-favorite-heart-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-heart-building</title><path d=\"M22.151 11.759a6.158 6.158 0 001.181-7.187 6.338 6.338 0 00-10.068-1.631l-.927.845a.5.5 0 01-.673 0l-.913-.831a6.278 6.278 0 00-5.476-1.737A6.227 6.227 0 00.669 4.572a6.146 6.146 0 001.156 7.161l9.423 10.794a1 1 0 001.505 0zM15.5 15.632a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-7.5a.5.5 0 01.276-.447l3-1.5a.5.5 0 01.447 0l3 1.5a.5.5 0 01.277.447z\"/><path d=\"M14 9.132a.5.5 0 00-.5-.5h-3a.5.5 0 000 1h3a.5.5 0 00.5-.5zM13.5 11.132h-3a.5.5 0 000 1h3a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});