define("ember-svg-jar/inlined/laptop-launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-launch</title><path d=\"M12 14.87a1.668 1.668 0 00-1.719 1.73 5.705 5.705 0 001.53 2.939.249.249 0 00.189.086.253.253 0 00.189-.085 5.713 5.713 0 001.53-2.94A1.668 1.668 0 0012 14.87zM10.521 13.7h2.961l1.439.914a.5.5 0 00.768-.407l.041-1.4a2 2 0 00-1.283-1.925l-.114-.044a.25.25 0 01-.155-.287l1-4.514a3.09 3.09 0 00-1.057-2.644 31.789 31.789 0 00-1.011-.749.252.252 0 01-.1-.2V1.25a1 1 0 00-2 0v1.192a.25.25 0 01-.1.2c-.4.286-.8.58-1.011.749a3.093 3.093 0 00-1.07 2.647l1 4.512a.251.251 0 01-.154.288l-.118.045a2 2 0 00-1.282 1.925l.04 1.4a.5.5 0 00.768.407zM12 7.1a1.137 1.137 0 111.137-1.137A1.137 1.137 0 0112 7.1z\"/><path d=\"M5.249 7.048a2 2 0 00-2 2v9.5a.25.25 0 01-.057.159L.378 22.129a1.148 1.148 0 001.083 1.621h21.078a1.148 1.148 0 001.083-1.621l-2.814-3.42a.25.25 0 01-.057-.159v-9.5a2 2 0 00-2-2h-1.5a1 1 0 000 2H18.5a.25.25 0 01.25.25v8.8a.25.25 0 01-.25.25h-3.56a.249.249 0 00-.224.14 9.981 9.981 0 01-1.632 2.3 1.5 1.5 0 01-2.17 0 9.981 9.981 0 01-1.632-2.3.249.249 0 00-.224-.14H5.5a.25.25 0 01-.25-.25V9.3a.25.25 0 01.25-.25h1.25a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});