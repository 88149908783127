define("ember-svg-jar/inlined/single-man-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM9.535 13.98a.248.248 0 00-.045-.109 1.221 1.221 0 01-.188-.433 1.322 1.322 0 01.1-.937.252.252 0 00.021-.19 2.356 2.356 0 01.053-1.973 1.061 1.061 0 01.58-.392.253.253 0 00.121-.076 2.656 2.656 0 011.984-.6c.975.023 2.079.363 2.256 1.245a3.515 3.515 0 01-.306 1.816.248.248 0 00.01.2 1.336 1.336 0 01.08.905 1.221 1.221 0 01-.188.433.248.248 0 00-.045.109 2.13 2.13 0 01-.561 1.14.254.254 0 00-.067.171v.451a.249.249 0 00.163.234l.19.071c1.57.577 2.7.994 3.073 1.733a4.924 4.924 0 01.487 1.988.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5 4.924 4.924 0 01.487-1.988c.369-.739 1.5-1.156 3.073-1.733l.19-.071a.249.249 0 00.163-.234v-.451a.254.254 0 00-.067-.171 2.13 2.13 0 01-.564-1.138zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});