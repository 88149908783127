define("ember-svg-jar/inlined/common-file-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double</title><path d=\"M21.811 3.854L18.4.441A1.5 1.5 0 0017.336 0H6.75a1.5 1.5 0 00-1.5 1.5V19a1.5 1.5 0 001.5 1.5h14a1.5 1.5 0 001.5-1.5V4.914a1.492 1.492 0 00-.439-1.06z\"/><path d=\"M17.75 22h-13a1 1 0 01-1-1V3.5a1 1 0 00-2 0V21a3 3 0 003 3h13a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});