define("ember-svg-jar/inlined/kitchenware-garlic-mincer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-garlic-mincer</title><path d=\"M24 7.894l-.287-2.006a.553.553 0 00-.154-.308L18.42.446a.553.553 0 00-.308-.154L16.106.005a.544.544 0 00-.606.433l-.284 1.42-2.292.328a.543.543 0 00-.462.462l-.332 2.291-1.421.284a.545.545 0 00-.432.611l.287 2.006a.534.534 0 00.154.308l5.134 5.135a.546.546 0 00.308.154l2.006.286a.545.545 0 00.611-.432l.284-1.421 2.291-.327a.543.543 0 00.462-.462l.328-2.291 1.42-.285A.544.544 0 0024 7.894zm-8.374-.221a1 1 0 110-1.414 1 1 0 01-.005 1.414zm2.121 2.121a1 1 0 110-1.414 1 1 0 01-.005 1.414zm.44-4.682a1 1 0 110-1.414 1 1 0 01-.005 1.414zM20.3 7.233a1 1 0 110-1.414 1 1 0 010 1.414zM11.441 11.112a.5.5 0 00-.707 0L.446 21.4A1.523 1.523 0 002.6 23.554l10.288-10.288a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});