define("ember-svg-jar/inlined/messages-bubble-typing-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-typing-alternate_1</title><path d=\"M12 .874C5.406.874.041 5.333.041 10.815a8.978 8.978 0 003.045 6.634L.969 21.675a1 1 0 001.316 1.355l5.957-2.773a14.22 14.22 0 003.758.5c6.594 0 11.959-4.459 11.959-9.941S18.594.874 12 .874zm0 17.882a12.132 12.132 0 01-3.548-.521 1 1 0 00-.713.05L4.692 19.7a.25.25 0 01-.329-.339l.86-1.716a1 1 0 00-.27-1.229 7.182 7.182 0 01-2.912-5.6c0-4.379 4.468-7.941 9.959-7.941s9.959 3.562 9.959 7.941-4.468 7.94-9.959 7.94z\"/><circle cx=\"6.959\" cy=\"10.874\" r=\"1.5\"/><circle cx=\"12\" cy=\"10.874\" r=\"1.5\"/><circle cx=\"17\" cy=\"10.874\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});