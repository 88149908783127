define("ember-svg-jar/inlined/history-vase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-vase</title><path d=\"M9.25 2.5h5.5a1.25 1.25 0 000-2.5h-5.5a1.25 1.25 0 000 2.5zM21.388 16.5H20a1 1 0 01-1-1V12a.5.5 0 00-.5-.5H15a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1a1 1 0 011 1v1.5a1 1 0 01-1 1h-5.5a1 1 0 01-1-1V12a.5.5 0 00-.5-.5H5.5a.5.5 0 00-.5.5v.5a.5.5 0 00.5.5h1a1 1 0 011 1v1.5a1 1 0 01-1 1H2.612a.5.5 0 00-.452.714 8.357 8.357 0 00.607 1.064.5.5 0 00.416.222h17.634a.5.5 0 00.416-.222 8.48 8.48 0 00.607-1.064.5.5 0 00-.452-.714zM19.5 22h-.836a.5.5 0 01-.278-.916 10.853 10.853 0 00.936-.7A.5.5 0 0019 19.5H5a.5.5 0 00-.321.884 10.853 10.853 0 00.936.7.5.5 0 01-.28.916H4.5a1 1 0 000 2h15a1 1 0 000-2z\"/><path d=\"M17.46 6.929C15.872 6.16 14.5 5.5 14.5 4.5V4a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v.5c0 1-1.372 1.66-2.96 2.429C4.294 8.016 1.5 9.369 1.5 12.5v2a5.211 5.211 0 00.032.556.5.5 0 00.5.444H6a.5.5 0 00.5-.5v-.5A.5.5 0 006 14H5a1 1 0 01-1-1v-1.5a1 1 0 011-1h4.5a1 1 0 011 1V15a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-1a1 1 0 01-1-1v-1.5a1 1 0 011-1H19a1 1 0 011 1V15a.5.5 0 00.5.5h1.47a.5.5 0 00.5-.444 4.965 4.965 0 00.03-.556v-2c0-3.131-2.794-4.484-5.04-5.571z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});