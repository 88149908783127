define("ember-svg-jar/inlined/landmark-christ-the-reedemer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-christ-the reedemer</title><path d=\"M17.171 21.68a1 1 0 00-.948-.68H7.778a1 1 0 00-.949.684l-.553 1.662A.5.5 0 006.75 24h10.5a.5.5 0 00.474-.658zM13.281 6.2a.5.5 0 00-.281.45v5.85a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V5.216a.243.243 0 00-.437-.147 5.346 5.346 0 01-.553.617 2.85 2.85 0 01-.729.514zM9.33 9.616a18.1 18.1 0 001.89 1.45.5.5 0 00.78-.42V6.959a.5.5 0 00-.426-.494 2.894 2.894 0 01-1.584-.779 6.367 6.367 0 01-.59-.666.5.5 0 00-.5-.187 27.411 27.411 0 01-5.92.667.98.98 0 00-.88.55.33.33 0 01-.3.2h-.55a.75.75 0 000 1.5h1.19a.5.5 0 01.4.2c.842 1.108 1.312 1.55 2.4 1.55H9a.5.5 0 01.33.116zM15 14.5a.5.5 0 00-.5-.5h-1a1.5 1.5 0 01-1.441-1.108.5.5 0 00-.249-.312 15.906 15.906 0 01-2.011-1.3.5.5 0 00-.8.4v1.961a.5.5 0 00.244.429 23.947 23.947 0 005.12 2.341.5.5 0 00.637-.481zM9.74 15.5a.5.5 0 00-.74.44V19a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1.011a.5.5 0 00-.389-.487A20.551 20.551 0 019.74 15.5zM22.75 6.25h-.527a.36.36 0 01-.322-.2 1 1 0 00-.88-.558 27.7 27.7 0 01-4.435-.368.5.5 0 00-.586.493V9a.5.5 0 00.5.5h2.264c1.083 0 1.556-.439 2.4-1.552a.5.5 0 01.4-.2h1.186a.75.75 0 000-1.5zM12 5.5a1.9 1.9 0 001.319-.532A3.514 3.514 0 0014.5 2.5a2.5 2.5 0 00-5 0 3.511 3.511 0 001.182 2.468A1.891 1.891 0 0012 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});