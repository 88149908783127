define("ember-svg-jar/inlined/movie-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-star</title><path d=\"M23.383 10.315a1.937 1.937 0 00.474-2.139A1.911 1.911 0 0022.041 7h-4.793a1 1 0 01-.9-.569L13.8 1.108V1.1a2.024 2.024 0 00-3.6.01L7.649 6.432a1 1 0 01-.9.568H1.956A1.907 1.907 0 00.143 8.173a1.937 1.937 0 00.469 2.136l4.275 4.382a1 1 0 01.2 1.1L2.73 21.215a1.951 1.951 0 00.492 2.258A2.008 2.008 0 004.571 24a2.037 2.037 0 001-.268l5.934-3.342a1 1 0 01.982 0l5.934 3.342a2 2 0 002.353-.259 1.953 1.953 0 00.492-2.258l-2.359-5.427a1 1 0 01.2-1.1zM12 17.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5zm2.5-6.75h-5a.75.75 0 010-1.5h5a.75.75 0 110 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});