define("ember-svg-jar/inlined/drawer-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-file</title><path d=\"M8.5 5.75h2a.75.75 0 000-1.5h-2a.75.75 0 000 1.5zM7.753 8.5a.75.75 0 00.75.75h7a.75.75 0 000-1.5h-7a.75.75 0 00-.75.75zM8.5 12.75h7a.75.75 0 000-1.5h-7a.75.75 0 000 1.5z\"/><path d=\"M23.867 16L20.5 10.227V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v8.222L.14 16a1.4 1.4 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.249 1.249 0 00-.133-.5zm-5.62-14a.25.25 0 01.25.25L18.5 13a1 1 0 001 1 1 1 0 00.71-.3l1.34 2.3H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.453L3.8 13.7a.994.994 0 00.714.3 1 1 0 001-1L5.5 2.25A.249.249 0 015.753 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});