define("ember-svg-jar/inlined/coffee-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coffee-filter</title><path d=\"M16.75 11.25a1 1 0 000-2h-1.909a.25.25 0 01-.215-.378l1.556-2.621a.5.5 0 00-.428-.751H5.628a.5.5 0 00-.428.751l1.556 2.621a.25.25 0 01-.215.378H4.75a1 1 0 000 2zM7.836 3.121A1 1 0 109.25 4.535a2 2 0 000-2.828A1 1 0 007.836.293a2 2 0 000 2.827zM11.836 3.121a1 1 0 101.414 1.414 2 2 0 000-2.828A1 1 0 0011.836.293a2 2 0 000 2.827zM17.25 12.25H6.562a.248.248 0 00-.244.2l-1.757 7.9A3 3 0 007.49 24h6.52a2.992 2.992 0 001.318-.308c.116-.04 4.043-3.4 4.043-3.4a2.978 2.978 0 00.879-2.121V15.25a3 3 0 00-3-3zm1 5.922a1 1 0 01-.239.657s-.783.68-.9.778-.344-.036-.382-.208l-1.077-4.845a.25.25 0 01.244-.3h1.354a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});