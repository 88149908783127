define("ember-svg-jar/inlined/vending-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vending-machine</title><path d=\"M23 1.5A1.5 1.5 0 0021.5 0h-19A1.5 1.5 0 001 1.5V21a1.479 1.479 0 00.375.983.5.5 0 01.125.331V23a1 1 0 002 0 .5.5 0 01.5-.5h16a.5.5 0 01.5.5 1 1 0 002 0v-.686a.5.5 0 01.125-.331A1.479 1.479 0 0023 21zm-11 19H6.5a1 1 0 010-2H12a1 1 0 010 2zm3.5-4.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h11a.5.5 0 01.5.5zm4.75.5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm.25-5.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});