define("ember-svg-jar/inlined/crafts-calligraphy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crafts-calligraphy</title><path d=\"M9.353 11.847a1 1 0 00-1.319-.512l-3.8 1.676A3.122 3.122 0 002.5 15.818v4.457a1 1 0 01-.434.823L.449 22.165a1 1 0 001.1 1.67L6.4 20.53a2.989 2.989 0 001.35-2.505V13.81a.251.251 0 01.149-.229l.945-.416a1 1 0 00.509-1.318zm-3.606 6.178a1.007 1.007 0 01-.469.847l-.386.267a.25.25 0 01-.392-.2v-3.121a1.12 1.12 0 01.586-1l.31-.136a.249.249 0 01.351.228zM19.654 6.948L17.006 4.3a.926.926 0 00-.906-.236l-3.047.862a2.187 2.187 0 00-1.51 1.51l-1.43 5.021a.5.5 0 00.835.49l3.808-3.808A.75.75 0 0115.817 9.2l-3.808 3.807a.5.5 0 00.49.835l5.021-1.429a2.189 2.189 0 001.51-1.513l.862-3.044a.927.927 0 00-.238-.908z\"/><rect x=\"18.505\" y=\"1.035\" width=\"5.097\" height=\"3.823\" rx=\".5\" ry=\".5\" transform=\"rotate(-45 21.053 2.946)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});