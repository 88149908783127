define("ember-svg-jar/inlined/flash-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flash-off</title><path d=\"M23.431 23.153a1 1 0 000-1.414l-7.166-7.166 3.418-4.784A.5.5 0 0019.276 9H11.9L13.764.606a.5.5 0 00-.895-.4L8.3 6.606 1.983.291A1 1 0 00.569 1.705L22.083 23.2a.975.975 0 001.348-.044zM6.454 9.689a.25.25 0 00-.38.032l-3.2 4.487a.5.5 0 00.407.79h7.376L8.788 23.39a.5.5 0 00.284.565.505.505 0 00.2.043.5.5 0 00.407-.209l4.406-6.17a.249.249 0 00-.027-.322z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});