define("ember-svg-jar/inlined/desktop-monitor-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-lock</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.682a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.786h8.079a.5.5 0 00.41-.787z\"/><path d=\"M14.857 7.278a.251.251 0 01-.107-.2V6.5a2.75 2.75 0 10-5.5 0v.572a.251.251 0 01-.107.2A1.49 1.49 0 008.5 8.5v3A1.5 1.5 0 0010 13h4a1.5 1.5 0 001.5-1.5v-3a1.49 1.49 0 00-.643-1.222zM13 10.02a1 1 0 11-1-1 1 1 0 011 1zM10.75 6.5a1.25 1.25 0 012.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});