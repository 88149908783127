define("ember-svg-jar/inlined/organic-tree-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-tree-1</title><rect y=\"13.5\" width=\"7\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect x=\"8\" y=\"21.5\" width=\"8\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect y=\"21.5\" width=\"6.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect x=\"17.5\" y=\"21.5\" width=\"6.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/><rect x=\"5.5\" y=\"17.5\" width=\"5.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M18.3 19.1l-2-1.5a.5.5 0 00-.3-.1h-2.5a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5H18a.5.5 0 00.3-.9z\"/><rect y=\"17.5\" width=\"4\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M23.763 17.574a.5.5 0 00-.487-.021l-3 1.5A.5.5 0 0020.5 20h3a.5.5 0 00.5-.5V18a.5.5 0 00-.237-.426zM5.558 8.477a4.322 4.322 0 001.316.217 2.619 2.619 0 001.685-.538 2.008 2.008 0 00.324-.336.5.5 0 00-.12-.721A6.532 6.532 0 005.554 5.85a.5.5 0 01-.491-.51.47.47 0 01.51-.49A8.161 8.161 0 0110.6 7.36 2.407 2.407 0 0111.25 9a8.6 8.6 0 01-1.156 4.267.5.5 0 01-.423.233H9a.5.5 0 00-.5.5v1.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V14a.5.5 0 00-.5-.5h-.671a.5.5 0 01-.423-.233A8.6 8.6 0 0112.75 9a2.4 2.4 0 01.65-1.64 8.167 8.167 0 015.03-2.51.47.47 0 01.51.49.5.5 0 01-.491.51 6.532 6.532 0 00-3.212 1.25.5.5 0 00-.12.721 2.048 2.048 0 00.324.336 2.619 2.619 0 001.685.538 4.322 4.322 0 001.316-.217A7.305 7.305 0 0022.5 3.034a.5.5 0 00-.16-.4.49.49 0 00-.412-.127 6.518 6.518 0 01-2.754-.26 6.924 6.924 0 00-2.409-.3 2.737 2.737 0 00-2.25 1.263 2.11 2.11 0 00-.24 1.224.5.5 0 01-.229.465 9.132 9.132 0 00-1.683 1.369.5.5 0 01-.363.155.506.506 0 01-.362-.154A9.1 9.1 0 009.955 4.9a.5.5 0 01-.229-.465 2.11 2.11 0 00-.24-1.224 2.737 2.737 0 00-2.25-1.263 6.924 6.924 0 00-2.409.3 6.518 6.518 0 01-2.754.26.49.49 0 00-.412.127.5.5 0 00-.16.4 7.305 7.305 0 004.057 5.442z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});