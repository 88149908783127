define("ember-svg-jar/inlined/trailer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trailer</title><path d=\"M22.719 3.625h-11.5a4.756 4.756 0 00-4.75 4.75v7.5a.25.25 0 01-.25.25H1.031a1 1 0 100 2h13.19a.25.25 0 00.25-.25 3.5 3.5 0 017 0 .25.25 0 00.25.25h1a1.251 1.251 0 001.25-1.25v-12a1.252 1.252 0 00-1.252-1.25zm-7.5 7.25a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm5.562 0a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5z\"/><path d=\"M17.971 15.376a2.5 2.5 0 102.5 2.5 2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});