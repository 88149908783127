define("ember-svg-jar/inlined/grid-ruler-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-ruler-1</title><path d=\"M23.645 13.92L21.876 7.6a.252.252 0 00-.177-.175.255.255 0 00-.241.065l-3.926 3.927a.25.25 0 000 .353l3.889 3.888a1.253 1.253 0 001.768 0 1.917 1.917 0 00.456-1.738zM16.509 2.542a.25.25 0 00-.109-.417L10.081.356A1.908 1.908 0 008.34.811a1.252 1.252 0 000 1.768l3.889 3.89a.249.249 0 00.354 0zM21.245 6.291a.5.5 0 000-.707l-2.828-2.828a.5.5 0 00-.707 0L.739 19.726a1.5 1.5 0 000 2.122l1.414 1.413a1.5 1.5 0 002.121 0l3.472-3.472a.249.249 0 000-.354L6.2 17.893a.5.5 0 01.705-.709l1.554 1.545a.25.25 0 00.353 0l1.56-1.561a.251.251 0 000-.354l-1.556-1.542a.5.5 0 11.705-.709l1.554 1.545a.25.25 0 00.353 0l1.561-1.562a.25.25 0 00.074-.177.256.256 0 00-.074-.177l-1.552-1.541a.5.5 0 11.705-.709l1.558 1.544a.25.25 0 00.353 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});