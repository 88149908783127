define("ember-svg-jar/inlined/diagram-steady-rise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-steady-rise</title><path d=\"M23.577 9.659a1.439 1.439 0 000-2.038L20.7 4.742a1.44 1.44 0 00-2.46 1.018v1.19a.25.25 0 01-.25.25h-5.03a4.325 4.325 0 00-4.32 4.32v3.84A1.442 1.442 0 017.2 16.8H1.44a1.44 1.44 0 000 2.88H7.2a4.325 4.325 0 004.32-4.32v-3.84a1.442 1.442 0 011.44-1.44h5.03a.25.25 0 01.25.25v1.19a1.44 1.44 0 002.46 1.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});