define("ember-svg-jar/inlined/phone-hand-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-hand-hold</title><path d=\"M22.739 8.347l-3.1-6.275a3 3 0 00-3.591-1.52 2.47 2.47 0 00-3.657.619 2.465 2.465 0 00-3 1A2.485 2.485 0 006 4.5v.965l-3.379 1.67a3 3 0 00-1.36 4.018l3.1 6.275A3 3 0 007.055 19.1a2.892 2.892 0 00.38-.044 3 3 0 00.765.844c.412.308.8.522.8 1.1v2a1 1 0 002 0v-2a3.015 3.015 0 00-1.2-2.4l-.41-.311 2.054-1.015a1 1 0 00-.887-1.793L7.493 17a1 1 0 01-1.339-.453l-3.1-6.275a1 1 0 01.454-1.339L6 7.7v.8a2.5 2.5 0 004.61 1.329 2.465 2.465 0 003-1A2.484 2.484 0 0017 6.5V2.417a.923.923 0 01.271.037 1 1 0 01.576.5l3.1 6.275a1 1 0 01-.454 1.339l-.51.252A2.494 2.494 0 0015 11v2.258A4.529 4.529 0 0013 17a1 1 0 001 1 1.023 1.023 0 001-1 2.514 2.514 0 011.429-2.26 1 1 0 00.571-.9V11a.5.5 0 011 0v5a2 2 0 01-1.2 1.834 3.026 3.026 0 00-1.8 2.75V23a1 1 0 002 0v-2.416a1 1 0 01.6-.917A4 4 0 0020 16v-2.953l1.379-.682a3 3 0 001.36-4.018zM14.5 7a.5.5 0 01-.5-.5v-4a.5.5 0 011 0v4a.5.5 0 01-.5.5zm-2.5.5a.5.5 0 01-1 0v-4a.5.5 0 011 0zm-3 1a.5.5 0 01-1 0v-4a.5.5 0 011 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});