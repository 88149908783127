define("ember-svg-jar/inlined/social-media-iwiw-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-iwiw-1</title><path d=\"M6.84 8.69a.5.5 0 00-.55 0L4.26 10l-1.41-.67a.75.75 0 00-.67 1.34l1.39.69v1.87a.5.5 0 00.22.42l2.5 1.67a.5.5 0 00.55 0l2.5-1.67a.5.5 0 00.22-.42v-2.46a.5.5 0 00-.22-.42zM13.79 8.69a.5.5 0 00-.55 0l-2.5 1.67a.5.5 0 00-.22.42v2.46a.5.5 0 00.22.42l2 1.35v3a.75.75 0 001.5 0V15l2-1.35a.5.5 0 00.22-.42v-2.46a.5.5 0 00-.22-.42zM21.17 3.33l-1.84.92-2-1.36a.5.5 0 00-.55 0l-2.5 1.67A.5.5 0 0014 5v2.43a.5.5 0 00.22.42l2.5 1.67a.5.5 0 00.55 0l2.5-1.67a.5.5 0 00.23-.42V5.59l1.84-.92a.75.75 0 00-.67-1.34zM13.51 19.25a.75.75 0 10.75.75.75.75 0 00-.75-.75zM.76 8.5a.75.75 0 10.75.75.75.75 0 00-.75-.75zM23.26 2.5a.75.75 0 10.75.75.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});