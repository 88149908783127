define("ember-svg-jar/inlined/pen-write-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pen-write-1</title><path d=\"M11.669 6.65a.757.757 0 001.053-.131l2.949-3.787a.538.538 0 01.752-.094l.254.2-1.12 1.437a.321.321 0 00.056.451L18.15 6.7a.323.323 0 00.452-.056l1.58-2.029A1.93 1.93 0 0017.7 1.754a.67.67 0 00-.105-.1l-.255-.2a2.038 2.038 0 00-2.857.355L11.539 5.6a.751.751 0 00.13 1.05z\"/><path d=\"M10.3 12.593l1.522 1.185a.964.964 0 001.353-.168l4.436-5.7a.321.321 0 00-.056-.451L15.02 5.487a.323.323 0 00-.452.056l-4.434 5.7a.963.963 0 00.166 1.35zM8.062 17.188a.531.531 0 00.534.052l3.167-1.61a.5.5 0 00.081-.839l-2.383-1.857a.5.5 0 00-.795.284l-.784 3.465a.5.5 0 00.18.505z\"/><path d=\"M22 13.472h-4.238a1 1 0 000 2h3.988a.25.25 0 01.25.25v5a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25h2.94a1 1 0 000-2H2a2 2 0 00-2 2v5.5a2 2 0 002 2h20a2 2 0 002-2v-5.5a2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});