define("ember-svg-jar/inlined/compass-south", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-south</title><path d=\"M16.59 15.042l-.777.359a9.1 9.1 0 01-7.626 0l-.777-.359a.455.455 0 00-.582.645l4.78 8.09a.456.456 0 00.784 0l4.78-8.09a.455.455 0 00-.582-.645zM12 14.5a7.25 7.25 0 10-7.25-7.25A7.258 7.258 0 0012 14.5zm.657-5.911l-2.146-1.43A2.27 2.27 0 0111.77 3h1.98a.75.75 0 010 1.5h-1.98a.77.77 0 00-.427 1.411l2.146 1.43A2.27 2.27 0 0112.23 11.5h-1.98a.75.75 0 010-1.5h1.98a.77.77 0 00.427-1.411z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});