define("ember-svg-jar/inlined/laptop-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-1</title><path d=\"M20.5 12a1 1 0 001-1V3a3 3 0 00-3-3h-13a3 3 0 00-3 3v8a1 1 0 002 0V3a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 001 1zM11 21.75a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2h-9.75a.25.25 0 01-.25-.25v-.25a1 1 0 00-1-1 1 1 0 00-1 1zM17.26 19a7.076 7.076 0 006.714-4.842.5.5 0 00-.474-.658H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.474.658A7.076 7.076 0 006.74 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});