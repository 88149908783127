define("ember-svg-jar/inlined/arrow-rectangle-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-up-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-5 13.3v2.341a.5.5 0 01-.882.323l-4.427-5.233a.25.25 0 00-.382 0l-4.427 5.231a.5.5 0 01-.882-.323V13.3a.5.5 0 01.129-.335l5-5.547a.5.5 0 01.742 0l5 5.547a.5.5 0 01.129.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});