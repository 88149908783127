define("ember-svg-jar/inlined/monitor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-1</title><path d=\"M22 .251H2a2 2 0 00-2 2v13.5a2 2 0 002 2h7.65a.248.248 0 01.248.281l-.2 1.594a.251.251 0 00.287.279A13 13 0 0112 19.751a13.134 13.134 0 012.014.153.245.245 0 00.212-.067.252.252 0 00.075-.211l-.2-1.594a.25.25 0 01.248-.281H22a2 2 0 002-2v-13.5a2 2 0 00-2-2zm-19.5 15.5a.5.5 0 01-.5-.5v-12.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v12.5a.5.5 0 01-.5.5zM12 20.751c-2.667 0-5.5.876-5.5 2.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5c0-1.624-2.833-2.5-5.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});