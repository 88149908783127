define("ember-svg-jar/inlined/design-file-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-text-alternate</title><path d=\"M15.5 7h-7A1.5 1.5 0 007 8.5V10a1 1 0 002 0v-.75A.25.25 0 019.25 9h1.5a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H10a1 1 0 000 2h4a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V10a1 1 0 002 0V8.5A1.5 1.5 0 0015.5 7z\"/><path d=\"M21.707 5.707L16.293.293A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});