define("ember-svg-jar/inlined/mask-heart-double-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-heart-double-alternate</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 20H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M7.078 9.758a.251.251 0 00-.017-.327l-.573-.6a1.656 1.656 0 01-.309-1.912 1.659 1.659 0 012.657-.432l.733.734a1 1 0 001.414 0l.735-.735a1.659 1.659 0 012.829 1.261.25.25 0 00.318.253 5.123 5.123 0 011.435-.225.251.251 0 00.246-.26A3.659 3.659 0 0010.3 5.072a.038.038 0 01-.027.011.039.039 0 01-.027-.011 3.658 3.658 0 00-5.19 5.157l.865.9a.25.25 0 00.414-.082c.056-.144.12-.288.19-.429a5.145 5.145 0 01.553-.86z\"/><path d=\"M19.645 11.3a3.66 3.66 0 00-5.861-.95.036.036 0 01-.026.011.043.043 0 01-.028-.011 3.658 3.658 0 00-5.188 5.15l4.494 4.688a1 1 0 001.443 0l4.48-4.673a3.656 3.656 0 00.686-4.215zm-2.115 2.82l-3.592 3.747a.25.25 0 01-.361 0L9.971 14.1a1.658 1.658 0 112.345-2.345l.735.734a1 1 0 001.414 0l.733-.734a1.658 1.658 0 012.657.431 1.664 1.664 0 01-.325 1.934z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});