define("ember-svg-jar/inlined/diagram-arrow-bow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-bow</title><path d=\"M23.56 10.938l-3-3A1.5 1.5 0 0018 9v1.25a.25.25 0 01-.25.25h-3.908a.5.5 0 01-.493-.42A12.02 12.02 0 001.5-.016a1.5 1.5 0 000 3 9.016 9.016 0 018.752 6.9.5.5 0 01-.487.617H1.5a1.5 1.5 0 000 3h8.258a.5.5 0 01.486.619A9.015 9.015 0 011.5 20.984a1.5 1.5 0 000 3A12.019 12.019 0 0013.344 13.92a.5.5 0 01.493-.42h3.913a.25.25 0 01.25.25V15a1.5 1.5 0 002.561 1.061l3-3a1.5 1.5 0 000-2.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});