define("ember-svg-jar/inlined/q-a-quora", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>q&amp;amp;a-quora</title><path d=\"M17.37 19.68A10.39 10.39 0 0012 .5 10.29 10.29 0 001.78 10.86 10.29 10.29 0 0012 21.22a10.1 10.1 0 002.41-.29 4.5 4.5 0 005.16 2.49.5.5 0 00.43-.49V22a.5.5 0 00-.33-.47 3.9 3.9 0 01-2.3-1.85zm-.07-7.45a7.31 7.31 0 01-1.44 4.46 6.47 6.47 0 00-4.61-2.06.5.5 0 00-.51.5v1.13a.5.5 0 00.37.48 3.61 3.61 0 012.18 1.78 4.25 4.25 0 01-1.21.18c-2.88 0-5.22-2.89-5.22-6.46V9.56C6.86 6 9.19 3.1 12.08 3.1S17.3 6 17.3 9.56v2.67z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});