define("ember-svg-jar/inlined/crime-man-riot-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-riot-1</title><path d=\"M21.063 18.281l-2.118-.711a.5.5 0 01-.323-.6A35.707 35.707 0 0020 7.5C20 3.505 16.262 0 12 0S4 3.505 4 7.5a35.723 35.723 0 001.378 9.467.494.494 0 01-.323.6l-2.135.717A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5H12a.5.5 0 01-.265-.076c-.186-.116-4.568-2.932-4.568-9.632a4.449 4.449 0 01.535-2.18l-.435-3.478A3 3 0 019.4 5.379a9.08 9.08 0 015.192 0 3 3 0 012.137 3.255l-.429 3.478a4.449 4.449 0 01.535 2.18c0 6.7-4.382 9.516-4.568 9.632A.5.5 0 0112 24h11.5a.5.5 0 00.5-.5v-1a4.524 4.524 0 00-2.937-4.219z\"/><path d=\"M9.4 9.558a.631.631 0 01.628 0 .751.751 0 001-1.116 2.1 2.1 0 00-2.636 0 .751.751 0 001 1.116zM13.969 9.558a.631.631 0 01.628 0 .751.751 0 001-1.116 2.1 2.1 0 00-2.636 0 .751.751 0 101 1.116zM15.833 14.292C15.833 11.428 13.432 11 12 11s-3.833.428-3.833 3.292A10.653 10.653 0 0012 22.887a10.527 10.527 0 003.833-8.595z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});