define("ember-svg-jar/inlined/road-sign-side-road-angle-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-side-road-angle-right-1</title><path d=\"M23.78 11.47L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-7.414 4.212l-.707.707a.5.5 0 01-.707 0l-1.962-1.962a.25.25 0 00-.427.177v1.9a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V9.25a.25.25 0 00-.25-.25h-.646a.25.25 0 01-.177-.427l1.719-1.719a.5.5 0 01.708 0l1.719 1.719a.25.25 0 01-.177.427h-.646a.25.25 0 00-.25.25v1.818a.251.251 0 00.073.177l3.73 3.73a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});