define("ember-svg-jar/inlined/composition-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-human</title><path d=\"M16.237 15.553l-.91-.335c-.114-.042-.228-.228-.3-.5a3.7 3.7 0 01.108-2.166c1.037-1.141 2.05-2.549 2.05-6.055 0-3.8-2.6-5.5-5.18-5.5S6.82 2.7 6.82 6.5c0 3.506 1.013 4.914 2.047 6.052a3.681 3.681 0 01.111 2.169c-.077.269-.191.455-.3.5l-.91.335c-3.469 1.276-5.976 2.2-6.71 3.667A10.567 10.567 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 10.567 10.567 0 00-1.053-4.28c-.734-1.47-3.241-2.391-6.71-3.667zM1 5a1 1 0 001-1V2.5a.5.5 0 01.5-.5H4a1 1 0 000-2H2a2 2 0 00-2 2v2a1 1 0 001 1z\"/><path d=\"M2 15h2a1 1 0 000-2H2.5a.5.5 0 01-.5-.5V11a1 1 0 00-2 0v2a2 2 0 002 2zM22 0h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5V4a1 1 0 002 0V2a2 2 0 00-2-2zM23 10a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});