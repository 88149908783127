define("ember-svg-jar/inlined/crime-man-thief-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thief-2</title><path d=\"M22.23 21.308a14.643 14.643 0 00-7.013-2.608.249.249 0 01-.217-.249v-.715a.247.247 0 00-.108-.206.25.25 0 00-.231-.028 7.442 7.442 0 01-5.322 0 .25.25 0 00-.231.028.253.253 0 00-.108.206v.715a.249.249 0 01-.217.248 14.643 14.643 0 00-7.013 2.609A1.979 1.979 0 001 22.887v.857a.25.25 0 00.25.25h21.5a.25.25 0 00.25-.25v-.857a1.979 1.979 0 00-.77-1.579zM5.5 8.494v2a6.5 6.5 0 0013 0v-2a6.511 6.511 0 00-4.251-6.1.25.25 0 01-.133-.355 3 3 0 00.263-.644l.005-.016A1.125 1.125 0 0014.2.406a.981.981 0 00-.779-.4h-2.837a.981.981 0 00-.779.4 1.125 1.125 0 00-.19.973v.009a2.977 2.977 0 00.265.653.25.25 0 01-.133.355A6.51 6.51 0 005.5 8.494zm4 1.5a1.6 1.6 0 01-1.5-1.5 1.035 1.035 0 011-1c1 0 2 .447 2 1a1.56 1.56 0 01-1.5 1.5zm2.5 2a2.052 2.052 0 012 1.5c0 .552-.9 1-2 1s-2-.448-2-1a2.051 2.051 0 012-1.5zm1-3.5c0-.553 1-1 2-1a1.035 1.035 0 011 1 1.6 1.6 0 01-1.5 1.5 1.56 1.56 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});