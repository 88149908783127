define("ember-svg-jar/inlined/famous-people-witch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-witch</title><path d=\"M18.314 13.075a.249.249 0 00.2-.337l-.548-1.419c-.053-.137.031-.03-1.812-1.872a.252.252 0 01-.066-.238l.4-1.588a.5.5 0 00-.106-.446l-2.822-3.29a.248.248 0 01-.06-.162V.5a.5.5 0 00-.854-.354l-4.5 4.5a.5.5 0 00-.075.611l1.217 2.028a.249.249 0 01-.028.294c-3.924 4.414-3.694 4.127-3.739 4.278l-.26.873a.256.256 0 00.03.207.252.252 0 00.176.112 47.6 47.6 0 0012.847.026z\"/><path d=\"M.5 20.5a3.5 3.5 0 001.536 2.9.249.249 0 01.1.141c.01.035.019.07.03.1a.5.5 0 00.48.359h18.708a.5.5 0 00.479-.359c.011-.033.02-.067.029-.1a.256.256 0 01.1-.142 3.5 3.5 0 00-1.331-6.341.251.251 0 01-.2-.194 2.985 2.985 0 00-.278-.766.249.249 0 01.18-.363 41.41 41.41 0 001.427-.254 1 1 0 00.784-1.177 1.026 1.026 0 00-1.177-.784 47.545 47.545 0 01-18.738 0 1.024 1.024 0 00-1.177.784 1 1 0 00.784 1.177c.475.095.951.176 1.427.254a.249.249 0 01.18.363 3.023 3.023 0 00-.278.766.251.251 0 01-.2.194A3.506 3.506 0 00.5 20.5zm6.25-4.059a.251.251 0 01.276-.248 49.274 49.274 0 004.974.259 49.274 49.274 0 004.974-.259.251.251 0 01.193.063.248.248 0 01.083.185V17a5.25 5.25 0 01-10.5 0z\"/><path d=\"M10.77 20.56a2.291 2.291 0 00.438.183 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.125 2.312 2.312 0 00.441-.184.639.639 0 00-.307-1.2h-2a.639.639 0 00-.307 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});