define("ember-svg-jar/inlined/renewable-energy-wind-turbine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-wind-turbine</title><path d=\"M9.826 10.632a.5.5 0 00.62.054 3.534 3.534 0 013.1-.4.5.5 0 00.659-.433l.619-8.053a1.647 1.647 0 00-3.19-.689l-2.81 7.743a1 1 0 00.251 1.064zM10.838 18.365l.243-1.008a.5.5 0 00-.264-.563 3.532 3.532 0 01-1.891-2.482.5.5 0 00-.7-.355L.935 17.444a1.647 1.647 0 001 3.107l8.108-1.438a1 1 0 00.795-.748zM23.617 19.233l-5.3-6.3a1 1 0 00-1.046-.315l-.994.294a.5.5 0 00-.357.51 3.528 3.528 0 01-1.2 2.879.5.5 0 00-.171.405.5.5 0 00.216.383l6.662 4.566a1.647 1.647 0 002.192-2.418zM3.053 14.632a1 1 0 001-1 8.353 8.353 0 013.592-6.858A1 1 0 006.5 5.131a10.353 10.353 0 00-4.451 8.5 1 1 0 001.004 1.001zM16.334 20.986a8.371 8.371 0 01-7.545.169 1 1 0 00-.864 1.8 10.38 10.38 0 009.354-.212 1 1 0 10-.945-1.762zM17.077 6.728a8.326 8.326 0 013.606 5.956 1 1 0 001.988-.229A10.329 10.329 0 0018.2 5.071a1 1 0 00-1.12 1.657zM12.4 11.612a2.02 2.02 0 102.02 2.02 2.02 2.02 0 00-2.02-2.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});