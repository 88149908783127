define("ember-svg-jar/inlined/arrow-thick-left-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-left-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-8.855 16.642a.5.5 0 01-.353.854h-3.071a.5.5 0 01-.37-.163l-4.545-5a.5.5 0 010-.672l4.545-5a.5.5 0 01.37-.164h3.073a.5.5 0 01.353.853l-2.721 2.724a.25.25 0 00.177.427H19a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-7.9a.25.25 0 00-.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});