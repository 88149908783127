define("ember-svg-jar/inlined/pie-line-graph-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pie-line-graph-desktop</title><path d=\"M14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787zM22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M10.02 10.444a1.057 1.057 0 00.105-.124l2.687-3.734a1 1 0 10-1.623-1.168l-2.016 2.8a.249.249 0 01-.331.069L7.327 7.38A1 1 0 006 7.66l-2.316 3.263a1 1 0 001.632 1.158l1.643-2.322a.251.251 0 01.333-.069l1.507.9a1 1 0 001.221-.146z\"/><rect x=\"14.5\" y=\"4\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"6\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});