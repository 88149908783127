define("ember-svg-jar/inlined/messages-bubble-typing-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-typing-alternate</title><circle cx=\"5.085\" cy=\"19\" r=\"2\"/><path d=\"M15.852 0A7.64 7.64 0 009.41 3.474a6.234 6.234 0 00-2.776-.649A6.444 6.444 0 00.255 9.318 6.451 6.451 0 006.326 15.8a6.3 6.3 0 008.6 1.171 8.252 8.252 0 001.235.093c4.491 0 7.751-3.589 7.751-8.534A8.22 8.22 0 0015.852 0zm.312 15.068a6.3 6.3 0 01-1.291-.135.993.993 0 00-.851.214 4.3 4.3 0 01-6.368-.907 1.031 1.031 0 00-1.02-.428 4.443 4.443 0 01-4.379-4.494 4.443 4.443 0 014.379-4.493 4.28 4.28 0 012.52.823 1 1 0 001.461-.327A5.892 5.892 0 0115.852 2c3.457 0 6.063 2.809 6.063 6.534 0 3.786-2.415 6.534-5.751 6.534z\"/><circle cx=\"7.585\" cy=\"10\" r=\"1.5\"/><circle cx=\"12.585\" cy=\"10\" r=\"1.5\"/><circle cx=\"17.585\" cy=\"10\" r=\"1.5\"/><circle cx=\"1.585\" cy=\"22.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});