define("ember-svg-jar/inlined/artboard-shrink-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-shrink-alternate</title><path d=\"M23.707.293a1 1 0 00-1.414 0l-6.682 6.682a.251.251 0 01-.23.068A1.838 1.838 0 0015 7H7.25A.25.25 0 017 6.75V5.5A1.5 1.5 0 005.5 4h-1A1.5 1.5 0 003 5.5v1.25a.25.25 0 01-.25.25H1.5A1.5 1.5 0 000 8.5v1A1.5 1.5 0 001.5 11h1.25a.25.25 0 01.25.25V19a2 2 0 002 2h7.75a.25.25 0 01.25.25v1.25a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-1.25a.25.25 0 01.25-.25h1.25a1.5 1.5 0 001.5-1.5v-1a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 01-.25-.25V9a1.838 1.838 0 00-.043-.381.249.249 0 01.068-.229l6.682-6.683a1 1 0 000-1.414zM12.5 17H9.018a.25.25 0 01-.177-.427l3.732-3.732a.25.25 0 01.427.177V16.5a.5.5 0 01-.5.5zm-5.073-1.841A.25.25 0 017 14.982V11.5a.5.5 0 01.5-.5h3.482a.25.25 0 01.177.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});