define("ember-svg-jar/inlined/religion-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-shield</title><path d=\"M22.5 1.016a.5.5 0 00-.786-.41 8.136 8.136 0 01-4.727 1.784A5.873 5.873 0 0112.4.182a.535.535 0 00-.795 0A5.868 5.868 0 017.013 2.39 8.126 8.126 0 012.287.606a.5.5 0 00-.787.41v14.811a.5.5 0 00.018.133c.062.227 1.626 5.572 10.347 8.017a.515.515 0 00.27 0c8.721-2.445 10.285-7.79 10.347-8.017a.5.5 0 00.018-.133zm-3 13.461a.5.5 0 01-.651.476 21.206 21.206 0 00-4.688-.844.5.5 0 00-.537.536 19.931 19.931 0 00.851 4.676.5.5 0 01-.475.656h-4a.5.5 0 01-.474-.658 19.967 19.967 0 00.876-4.684.5.5 0 00-.536-.538 20.024 20.024 0 00-4.71.854.492.492 0 01-.449-.069.5.5 0 01-.207-.4v-4A.5.5 0 015.159 10a19.847 19.847 0 004.705.878.5.5 0 00.538-.537 20.087 20.087 0 00-.877-4.709.5.5 0 01.475-.655h4a.5.5 0 01.477.65 21.21 21.21 0 00-.846 4.711.5.5 0 00.538.537A20.082 20.082 0 0018.842 10a.5.5 0 01.658.475z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});