define("ember-svg-jar/inlined/shipment-truck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-truck-1</title><path d=\"M24 16.765a1.749 1.749 0 00-1.307-1.692l-3.464-.908a.642.642 0 01-.479-.62 5.251 5.251 0 00-2.48-4.476L14 7.652a.9.9 0 00-.259-.107.252.252 0 01-.164-.135A4.23 4.23 0 009.769 5H7.5a.5.5 0 00-.5.5v13.75a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V3.96A3.451 3.451 0 002.938.529L.609.012A.5.5 0 000 .5v18.774a.25.25 0 00.386.209A2.949 2.949 0 012 19a3 3 0 013 3 2.8 2.8 0 01-.089.689.252.252 0 00.045.215.248.248 0 00.2.1c7.9 0 9.694.017 10.208-.014a.251.251 0 00.227-.311A2.781 2.781 0 0115.5 22a2.994 2.994 0 115.988 0 .252.252 0 00.072.18.249.249 0 00.178.075h1.512A.75.75 0 0024 21.5zM11 10.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5zm5.743 2.986a.25.25 0 01-.25.264h-1.77a.25.25 0 01-.25-.25v-2.766a.249.249 0 01.374-.216 3.454 3.454 0 011.896 2.968z\"/><circle cx=\"18.5\" cy=\"22\" r=\"2\"/><circle cx=\"2\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});