define("ember-svg-jar/inlined/christmas-snow-globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-snow-globe</title><path d=\"M20.465 19.894a.5.5 0 01.084-.547A11.7 11.7 0 0023.5 11.5a11.5 11.5 0 00-23 0 11.489 11.489 0 002.948 7.848.5.5 0 01.086.549l-.949 2.046A1.5 1.5 0 003.977 24h16.046a1.5 1.5 0 001.377-2.082zM12 18a2.5 2.5 0 112.5-2.5A2.5 2.5 0 0112 18zm-1.5-8.5A1.5 1.5 0 1112 11a1.5 1.5 0 01-1.5-1.5zm8.891 8.129a1 1 0 01-.778.371h-2.431a.248.248 0 01-.214-.122.253.253 0 01-.006-.247 4.5 4.5 0 00.4-3.251.5.5 0 01.26-.571l.829-.415a1 1 0 10-.9-1.788l-1.081.541a.5.5 0 01-.549-.067.415.415 0 01-.069-.555 3.5 3.5 0 10-5.745-.056.5.5 0 01-.082.656.385.385 0 01-.429.056l-1.149-.575a1 1 0 10-.9 1.788l.829.415a.5.5 0 01.26.571 4.5 4.5 0 00.4 3.251.251.251 0 01-.22.369H5.38a1 1 0 01-.773-.365A9.579 9.579 0 012.5 11.5a9.5 9.5 0 0119 0 9.77 9.77 0 01-2.109 6.129z\"/><circle cx=\"9.5\" cy=\"4\" r=\"1\"/><circle cx=\"15\" cy=\"4\" r=\"1\"/><circle cx=\"18\" cy=\"6.473\" r=\"1\"/><circle cx=\"6.5\" cy=\"6.473\" r=\"1\"/><circle cx=\"4.5\" cy=\"9.973\" r=\"1\"/><circle cx=\"19.5\" cy=\"9.973\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});