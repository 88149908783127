define("ember-svg-jar/inlined/analytics-pie-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-pie-1</title><path d=\"M10.5 24A10.512 10.512 0 0021 13.5a.5.5 0 00-.5-.5h-9a.5.5 0 01-.5-.5v-9a.5.5 0 00-.5-.5 10.5 10.5 0 000 21z\"/><path d=\"M13.5 0a.5.5 0 00-.5.5V10a1 1 0 001 1h9.5a.5.5 0 00.5-.5A10.512 10.512 0 0013.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});