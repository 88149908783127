define("ember-svg-jar/inlined/science-apple-gravity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-apple-gravity</title><path d=\"M14.5 20h-1a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-1a1 1 0 00-.625 1.781l2.5 2a1 1 0 001.25 0l2.5-2A1 1 0 0014.5 20zM6 16.5H5a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.5.5H1a1 1 0 00-.625 1.781l2.5 2a1 1 0 001.25 0l2.5-2A1 1 0 006 16.5zM23.944 17.169A1 1 0 0023 16.5h-1a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-1a1 1 0 00-.625 1.781l2.5 2a1 1 0 001.25 0l2.5-2a1 1 0 00.319-1.112zM9.99 13.534c1.6 1.379 5.738-.373 7.576-2.506s1.883-4.881.284-6.259a6.774 6.774 0 00-1.15-.838.25.25 0 01-.078-.374l1.517-1.941a1 1 0 10-1.58-1.232l-1.382 1.769a.25.25 0 01-.411-.024A5.468 5.468 0 0013.621.843c-1.6-1.379-4.311-.928-6.149 1.205s-2.814 6.891-1.215 8.27 1.526.386 2.326 1.075-.193.763 1.407 2.141zM8.329 4.587a4.253 4.253 0 013.2-2.306.75.75 0 01.211 1.486 2.747 2.747 0 00-2.07 1.492.75.75 0 11-1.342-.672z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});