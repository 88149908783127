define("ember-svg-jar/inlined/business-deal-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-deal-cash</title><circle cx=\"4.88\" cy=\"16.75\" r=\"2.75\"/><path d=\"M.586 23.677a.25.25 0 00.24.323h8.108a.25.25 0 00.239-.323 4.49 4.49 0 00-8.587 0z\"/><circle cx=\"19.12\" cy=\"16.75\" r=\"2.75\"/><path d=\"M23.376 23.9a.249.249 0 00.038-.221 4.49 4.49 0 00-8.587 0 .25.25 0 00.239.323h8.109a.247.247 0 00.201-.102zM3.5 1.5v9A1.5 1.5 0 005 12h6.25a.25.25 0 01.25.25v2.25a.5.5 0 00.309.462A.493.493 0 0012 15a.5.5 0 00.354-.146l2.78-2.784a.249.249 0 01.177-.07H19a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0019 0H5a1.5 1.5 0 00-1.5 1.5zm9.667 5.125h-2.334a1.792 1.792 0 010-3.583h.292a.25.25 0 00.25-.25V2.5a.625.625 0 011.25 0v.292a.25.25 0 00.25.25h1.458a.625.625 0 010 1.25h-3.5a.542.542 0 000 1.083h2.334a1.792 1.792 0 010 3.584h-.292a.249.249 0 00-.25.25V9.5a.625.625 0 01-1.25 0v-.291a.249.249 0 00-.25-.25H9.667a.625.625 0 110-1.25h3.5a.542.542 0 000-1.084z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});