define("ember-svg-jar/inlined/envelope-pigeon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>envelope-pigeon</title><path d=\"M13.823 8.7a13.953 13.953 0 00-2.806-7.234.992.992 0 00-.908-.374 1 1 0 00-.79.582c-1.167 2.59-1.65 4.39-1.438 5.356 2.264-.517 4.494.707 5.942 1.67z\"/><path d=\"M21.1 20.643a.248.248 0 01-.025-.23.25.25 0 01.175-.152 4.674 4.674 0 002.5-1.313 1 1 0 00-.363-1.589 22.579 22.579 0 01-4.343-2.77 66.704 66.704 0 01-1.326-1.044C21.27 11.9 23.333 8.7 23.985 4.274a1 1 0 00-1.307-1.1c-4.014 1.345-6.065 2.547-6.407 3.573-.355 1.069-1.236 3.165-1.73 4.318A12.182 12.182 0 009.843 8.5a3.27 3.27 0 00-2.764.54 3.726 3.726 0 00-1.329 2.745 10.38 10.38 0 00-.78 1.022l-1.245-.293a1.067 1.067 0 00-.317-.02 1 1 0 00-.823.58l-2.5 5.5a1 1 0 00.681 1.388l8.5 2a1 1 0 001.14-.56l1.08-2.377a.25.25 0 01.358-.111l.149.088A11.807 11.807 0 0014.2 20a.245.245 0 01.131.1l1.579 2.368a1 1 0 001.664-1.11l-.288-.432a.249.249 0 01.219-.388c.461.02.8.02 1 .019a.25.25 0 01.21.111l1.2 1.8a1 1 0 001.664-1.11zM2.41 18.294l1.349-2.97 1.6 2.562a.754.754 0 00.873.314l4.044-1.348-1.351 2.975zM10 11.238a1 1 0 11-1-1 1 1 0 011 1zm-4.711 3.7l3.514.827-2.48.827z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});