define("ember-svg-jar/inlined/smiley-nauseous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-nauseous</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8 5.5a1 1 0 01-1 1c-1.153 0-3.142-.547-3.895-2.053a1 1 0 011.79-.894A2.656 2.656 0 0019 4.5a1 1 0 011 1zm-12 5A1.5 1.5 0 119.5 9 1.5 1.5 0 018 10.5zm1.25 8a1 1 0 01-2 0 3.493 3.493 0 00-1.893-3.11 1 1 0 01.92-1.776A5.484 5.484 0 019.25 18.5zm.914-4.77a7.579 7.579 0 013.672 0 1 1 0 01-.243 1.969.984.984 0 01-.245-.03 5.589 5.589 0 00-2.7 0 1 1 0 11-.488-1.939zm8.479 1.66a3.493 3.493 0 00-1.893 3.11 1 1 0 11-2 0 5.484 5.484 0 012.973-4.886 1 1 0 01.92 1.776zM15 9a1.5 1.5 0 111.5 1.5A1.5 1.5 0 0115 9zM5.5 4.5a2.656 2.656 0 002.105-.947 1 1 0 111.79.894C8.642 5.953 6.653 6.5 5.5 6.5a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});