define("ember-svg-jar/inlined/single-man-actions-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-1</title><path d=\"M16.076 13.1a.25.25 0 00.035-.309 2.166 2.166 0 00-1.022-.864l-3.936-1.687a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.944.492 10.242 0 8.889 0a3.751 3.751 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.105 1.171.176 1.732a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2A1.766 1.766 0 004.32 5a1.524 1.524 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.921 8.7.25.25 0 016 8.883v1.122a.252.252 0 01-.151.23l-3.936 1.687C.641 12.468.458 13.626.206 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h11.568a.251.251 0 00.177-.073zM15.085 22.74a.5.5 0 00-.131-.479l-2.214-2.215a.5.5 0 00-.834.216l-.886 3.1a.5.5 0 00.48.638.47.47 0 00.138-.02l3.1-.885a.5.5 0 00.347-.355zM21.772 16.229l-3-3a.5.5 0 00-.707 0l-5.011 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.01a.5.5 0 000-.707zM19.48 11.813a.5.5 0 000 .708l3 3a.535.535 0 00.377.124.587.587 0 00.377-.17 2.626 2.626 0 000-3.707 2.655 2.655 0 00-3.754.045z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});