define("ember-svg-jar/inlined/toys-hand-ghost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-hand-ghost</title><path d=\"M7.246 16.126a2 2 0 001.454.624h10.568a.5.5 0 01.5.5v3.25a1.6 1.6 0 01-1.243 1.53 1 1 0 00.242 1.97 1.017 1.017 0 00.243-.03 3.618 3.618 0 002.758-3.47V7.25a7.25 7.25 0 00-14.5 0v5.2a.317.317 0 01-.547.218 2.6 2.6 0 00-3.995 3.318l5.508 7.6a1 1 0 001.62-1.174l-5.509-7.6a.6.6 0 01.925-.769zM17.518 5.36a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zm-6 0a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zm7.376 8.162a.749.749 0 01-1.026-.268l-.642-1.1a.294.294 0 00-.262-.149.29.29 0 00-.26.149l-.638 1.092a1.8 1.8 0 01-3.113 0l-.633-1.087a.3.3 0 00-.521 0l-.636 1.089a.75.75 0 11-1.295-.756L10.5 11.4a1.782 1.782 0 011.555-.894 1.784 1.784 0 011.557.9l.633 1.088a.3.3 0 00.522 0l.637-1.092a1.782 1.782 0 011.554-.893 1.789 1.789 0 011.557.891l.642 1.1a.749.749 0 01-.263 1.022z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});