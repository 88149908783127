define("ember-svg-jar/inlined/database-question-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-question-alternate</title><path d=\"M2.753 17.753v-.789a.249.249 0 01.372-.217A14.149 14.149 0 007.6 18.2a.991.991 0 001.151-.939v-.02a.97.97 0 00-.807-1C4.7 15.7 2.753 14.44 2.753 13.5v-.789a.249.249 0 01.373-.217 15.407 15.407 0 005.685 1.62.96.96 0 00.941-.477l.014-.025a.991.991 0 00-.759-1.484c-3.877-.414-6.254-1.843-6.254-2.878v-.786a.25.25 0 01.373-.218A17.574 17.574 0 0011.254 10a17.588 17.588 0 008.129-1.757.25.25 0 01.373.214 7.553 7.553 0 01-.011.843.991.991 0 00.525 1.027l.075.037a.968.968 0 001.408-.864V5c0-3.248-5.41-5-10.5-5S.766 1.751.755 4.993v12.76c0 2.692 3.717 4.353 7.888 4.842a.983.983 0 001.012-1.4l-.012-.022a1.016 1.016 0 00-.784-.549c-3.792-.439-6.106-1.849-6.106-2.871zM11.254 2c5.185 0 8.495 1.773 8.5 3v.009C19.742 6.232 16.435 8 11.254 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M16.747 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1 10.5a1 1 0 111 1 1 1 0 01-1-1zM16 18.2a1.258 1.258 0 01.986-1.222 1.125 1.125 0 10-1.361-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.538 2.461.25.25 0 00-.163.234v.18a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});