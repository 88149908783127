define("ember-svg-jar/inlined/vide-document-mpg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vide-document-mpg</title><path d=\"M12 9.625h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H12a.875.875 0 000-1.75z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.977c0-.128-.144-.089-.188 0l-.128.256a.65.65 0 01-1.118 0S6.346 12.1 6.3 12s-.177-.118-.177 0v3a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279l.785 1.569a.175.175 0 00.314 0l.783-1.566A.625.625 0 019.125 9zm2.75-2.375h-.5a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 01.625-.625H12a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903zM19.125 11a.625.625 0 01-1.25 0v-.5a.875.875 0 00-1.75 0v3a.875.875 0 001.694.308c.022-.059.048-.183-.053-.183H17.5a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});