define("ember-svg-jar/inlined/common-file-text-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-check</title><path d=\"M12 8.5h7a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M23.414 3L21 .586A2 2 0 0019.585 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25 19.17 19.17 0 011.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M17.5 12a1 1 0 00-1-1h-4a.952.952 0 00-.632.243.223.223 0 00.006.343 8.066 8.066 0 011.164 1.314.24.24 0 00.2.1H16.5a1 1 0 001-1zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm3.273 5.2l-2.9 3.874a1.491 1.491 0 01-1.1.6h-.109a1.5 1.5 0 01-1.057-.442l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.25.25 0 00.377-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});