define("ember-svg-jar/inlined/messages-people-user-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-user-heart</title><path d=\"M15.143 10.85a.5.5 0 00.714 0l2.976-3.041a2.2 2.2 0 00.426-2.58A2.239 2.239 0 0017.6 4.03a2.269 2.269 0 00-1.966.619l-.138.135-.138-.135a2.284 2.284 0 00-3.621.58 2.194 2.194 0 00.422 2.571z\"/><path d=\"M22 0H9a2 2 0 00-2 2v11a2 2 0 002 2h.5v2.5a1 1 0 001.707.707L14.414 15H22a2 2 0 002-2V2a2 2 0 00-2-2zm0 12.75a.25.25 0 01-.25.25H14a1 1 0 00-.707.293L11.5 15.086V14a1 1 0 00-1-1H9.25a.25.25 0 01-.25-.25V2.25A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25zM3.505 13a2.75 2.75 0 10-.01 0A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.821a.249.249 0 01.249.225L2 23.55a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.433-4.325A.249.249 0 015.678 19H6.5a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.505 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});