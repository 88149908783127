define("ember-svg-jar/inlined/gesture-flip-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-flip-left</title><path d=\"M7.515 6.951a1 1 0 001.259-.643 4.928 4.928 0 011-1.88.25.25 0 01.428.121l.067.334a.75.75 0 001.358.271l1.5-2.237a.751.751 0 00-.206-1.041L10.68.376a.75.75 0 00-1.152.769l.139.7a.248.248 0 01-.13.27 5.724 5.724 0 00-2.666 3.576 1 1 0 00.644 1.26zM16.25 8.249a3 3 0 00-3 3v4.072a.428.428 0 00.429.428h5.142a.428.428 0 00.429-.428v-4.072a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM7.75 8.249a3 3 0 00-3 3v4.072a.428.428 0 00.429.428h5.142a.428.428 0 00.429-.428v-4.072a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM16.484 17.048a1 1 0 00-1.259.643 4.894 4.894 0 01-1 1.873.249.249 0 01-.428-.121l-.065-.327a.751.751 0 00-1.359-.271l-1.5 2.237a.752.752 0 00.206 1.041l2.245 1.5a.751.751 0 001.152-.771l-.142-.709a.252.252 0 01.128-.27 5.771 5.771 0 002.669-3.566 1 1 0 00-.647-1.259z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});