define("ember-svg-jar/inlined/single-man-actions-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-music</title><path d=\"M12.5 13.83a3.476 3.476 0 01.867-2.29.249.249 0 00-.09-.394l-2.124-.91a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.308 3.308 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.575 1.575 0 00-.317-1.014.253.253 0 01-.034-.265 2.53 2.53 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.755 3.755 0 00-3.163 1.324.249.249 0 01-.212.118 1.073 1.073 0 00-.9.376c-.372.492-.106 1.17.176 1.732a.25.25 0 01-.046.287 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.521 1.521 0 00.638 1.331.25.25 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.25.25 0 01-.151.23l-3.937 1.686C.64 12.468.457 13.626.205 15.23c-.055.359-.117.752-.2 1.179A.5.5 0 00.5 17h11.749a.25.25 0 00.25-.25z\"/><path d=\"M13.5 24a2.5 2.5 0 002.5-2.5v-7.463a.251.251 0 01.2-.246l5.5-1.031a.25.25 0 01.3.246v3.755a.251.251 0 01-.081.185.248.248 0 01-.191.064c-.076-.01-.152-.01-.228-.01a2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.368-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.251.251 0 01-.081.185.248.248 0 01-.191.064c-.076-.01-.152-.01-.228-.01a2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});