define("ember-svg-jar/inlined/footwear-heels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-heels</title><path d=\"M18.734 15H16.66a3.5 3.5 0 01-3.193-2.069L7.941.591A1 1 0 006.57.111 10.749 10.749 0 00.856 11.032a93.758 93.758 0 01.71 11.481A1.5 1.5 0 003.065 24h.5a1.5 1.5 0 001.5-1.5v-7.567A.935.935 0 016 14a.959.959 0 01.91.712l2.674 6.5A4.486 4.486 0 0013.75 24h4.984a4.5 4.5 0 000-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});