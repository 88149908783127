define("ember-svg-jar/inlined/modern-weapon-machine-gun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-machine-gun</title><path d=\"M23.707.293a1 1 0 00-1.066-.227l-4.935 1.9a1.49 1.49 0 00-.934 1.106l-.333 1.664a.493.493 0 01-.136.255l-.786.786a.5.5 0 000 .707l2 2a.5.5 0 00.707 0l.786-.784a.5.5 0 01.256-.137l1.662-.333a1.487 1.487 0 001.107-.93l1.9-4.936a1 1 0 00-.228-1.071zM10.879 8.293a.5.5 0 00.707 0l.707-.707a.5.5 0 000-.707l-.586-.586a1 1 0 00-1.414 1.414zM14.354 7.646a.5.5 0 00-.707 0l-10.94 10.94a.5.5 0 01-.707 0l-.293-.293a1 1 0 10-1.414 1.414l4 4a1 1 0 101.414-1.414L5.414 22a.5.5 0 010-.707L7 19.707a.5.5 0 01.707 0l1.44 1.439a.5.5 0 00.707 0l.793-.793a.5.5 0 000-.707l-1.44-1.439a.5.5 0 010-.707l2.293-2.293a.5.5 0 01.707 0l2.44 2.439a.5.5 0 00.707 0l.793-.793a.5.5 0 000-.707l-2.44-2.439a.5.5 0 010-.707l2.647-2.647a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});