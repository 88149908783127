define("ember-svg-jar/inlined/navigation-left-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-circle-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.5 17a1 1 0 110-2 1.5 1.5 0 000-3H10a.25.25 0 00-.25.25V14a.75.75 0 01-1.28.53l-3-3a.75.75 0 010-1.061l3-3A.75.75 0 019.75 8v1.75A.251.251 0 0010 10h5.5a3.5 3.5 0 010 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});