define("ember-svg-jar/inlined/diagram-lower-steady", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-lower-steady</title><path d=\"M23.577 8.581L20.7 5.7a1.44 1.44 0 00-2.46 1.02v1.19a.25.25 0 01-.25.25h-5.51a4.325 4.325 0 00-4.32 4.32v7.2a1.442 1.442 0 01-1.44 1.44h-2.4a1.442 1.442 0 01-1.44-1.44V1.44a1.44 1.44 0 00-2.88 0v18.24A4.325 4.325 0 004.32 24h2.4a4.325 4.325 0 004.32-4.32v-7.2a1.442 1.442 0 011.44-1.44h5.51a.25.25 0 01.25.25v1.19a1.44 1.44 0 002.46 1.02l2.879-2.879a1.439 1.439 0 000-2.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});