define("ember-svg-jar/inlined/spa-bamboo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-bamboo</title><path d=\"M22.066 12.378a.907.907 0 00-.6-.656 9.034 9.034 0 00-3.2-.469.5.5 0 00-.428.283 11.279 11.279 0 01-2.419 3.26.5.5 0 00.3.862c.205.016.417.026.634.026a7 7 0 001.609-.182 8.43 8.43 0 003.857-2.269.907.907 0 00.247-.855zM17.079 4.913a1 1 0 00-1.591-.6 10.057 10.057 0 00-3.264 4.221 10.3 10.3 0 00-.119 6.114.5.5 0 00.735.276 10.3 10.3 0 003.928-4.687 10.051 10.051 0 00.311-5.324zM8.494 4.564a.5.5 0 00.1-.42L7.863.787A1.007 1.007 0 006.886 0H2.909a1 1 0 00-.977 1.213l.683 3.143a.5.5 0 00.485.394h5a.5.5 0 00.394-.186zM11.429 18.064a.5.5 0 00.1-.42l-2.392-11a.5.5 0 00-.488-.394h-5a.5.5 0 00-.489.606l2.392 11a.5.5 0 00.488.394h5a.5.5 0 00.389-.186zM11.583 19.75h-5a.5.5 0 00-.489.606l.621 2.857a1.006 1.006 0 00.977.787h3.976a1 1 0 00.978-1.213l-.575-2.643a.5.5 0 00-.488-.394z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});