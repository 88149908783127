define("ember-svg-jar/inlined/task-list-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-edit</title><path d=\"M17 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h1.75a.25.25 0 01.25.25V3.5A1.5 1.5 0 006.5 5h6A1.5 1.5 0 0014 3.5V2.249A.25.25 0 0114.25 2H16a1 1 0 011 1v5.314a.25.25 0 00.427.177l1.5-1.5A.249.249 0 0019 6.814V3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h13a3 3 0 003-3v-5.236a.25.25 0 00-.427-.177l-1.5 1.5a.249.249 0 00-.073.177z\"/><path d=\"M8.469 6.663a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.181.094.251.251 0 01-.191-.073l-.71-.71A.75.75 0 103.97 8.78l1.5 1.5a.759.759 0 001.116-.061l2-2.5a.751.751 0 00-.117-1.056zM8.469 12.163a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.183.092.251.251 0 01-.191-.073l-.71-.71a.75.75 0 10-1.06 1.063l1.5 1.5a.759.759 0 001.116-.061l2-2.5a.751.751 0 00-.117-1.056zM14.25 8.5h-3.5a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5zM23.586 8.453a1.414 1.414 0 10-2-2L11.573 16.466a.244.244 0 00-.06.1l-.665 2a.5.5 0 00.632.632l2-.665a.244.244 0 00.1-.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});