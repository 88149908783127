define("ember-svg-jar/inlined/single-neutral-actions-graduate.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-graduate</title><path d=\"M10.462 15.663l2.876-1.234a.5.5 0 00.21-.75A7.517 7.517 0 007.456 10.5a7.544 7.544 0 00-7.185 5.358.5.5 0 00.479.642h8.514a.5.5 0 00.4-.2 1.94 1.94 0 01.798-.637z\"/><circle cx=\"7.25\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.416 18.046a.5.5 0 00.019-.935l-6-2.383a.5.5 0 00-.37 0l-6 2.383a.5.5 0 00-.315.473v3.381a.75.75 0 101.5 0v-2.153a.251.251 0 01.333-.236l4.5 1.589a.532.532 0 00.167.028.521.521 0 00.166-.028z\"/><path d=\"M20.845 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.667-.471l-3.179 1.123a.249.249 0 01-.166 0l-3.179-1.123a.5.5 0 00-.667.471v2.146a.5.5 0 00.334.471l2.572.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});