define("ember-svg-jar/inlined/hand-hold-3d-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-hold-3d-box</title><path d=\"M22.942 8.664L19.2 7.106a.25.25 0 01.1-.481h1.45a2.5 2.5 0 000-5h-4a.5.5 0 01-.5-.5 1 1 0 00-2 0 2.5 2.5 0 002.5 2.5h4a.5.5 0 010 1h-3a2 2 0 00-1.973 1.73.25.25 0 01-.151.2l-5.068 2.109a.5.5 0 00-.308.461v6.5a.5.5 0 00.308.461l6 2.5a.492.492 0 00.384 0l6-2.5a.5.5 0 00.308-.461v-6.5a.5.5 0 00-.308-.461zm-6.288-.957a.246.246 0 01.192 0L19.7 8.894a.25.25 0 010 .462l-2.85 1.187a.246.246 0 01-.192 0L13.8 9.356a.25.25 0 010-.462zM13.75 19.375h-7.5a.5.5 0 010-1h3a.5.5 0 00.5-.5 2 2 0 00-2-2H2.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0 .25.25 0 01.25-.25h13.75a.5.5 0 00.5-.5 3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});