define("ember-svg-jar/inlined/real-estate-couple-search-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-couple-search-house</title><path d=\"M9 4a.5.5 0 01.5.5V7a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V4.5A.5.5 0 0116 4a.5.5 0 00.325-.88l-3.5-3a.5.5 0 00-.65 0l-3.5 3A.5.5 0 009 4z\"/><circle cx=\"4.5\" cy=\"15\" r=\"3\"/><path d=\"M4.5 19A4.505 4.505 0 000 23.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5A4.505 4.505 0 004.5 19z\"/><circle cx=\"19.5\" cy=\"15\" r=\"3\"/><path d=\"M19.5 19a4.505 4.505 0 00-4.5 4.5.5.5 0 00.5.5h8a.5.5 0 00.5-.5 4.505 4.505 0 00-4.5-4.5zM19.25 11.25a2 2 0 002-2V1a1 1 0 00-2 0v7.75a.5.5 0 01-.5.5h-3a1 1 0 00-.707.293l-1.366 1.366a.25.25 0 01-.427-.177v-.482a1 1 0 00-1-1h-6a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v8.25a2 2 0 002 2h5a.5.5 0 01.5.5v.293a1.71 1.71 0 002.914 1.207l1.854-1.85a.5.5 0 01.353-.146z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});