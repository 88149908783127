define("ember-svg-jar/inlined/lab-flame-experiment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-flame-experiment</title><path d=\"M21.931 12.25h-1.5a.5.5 0 01-.5-.5v-8a1 1 0 00-2 0v8a.5.5 0 01-.5.5h-5.137a.5.5 0 01-.5-.5v-.55a.5.5 0 01.5-.5h.5a1 1 0 00.215-1.977.252.252 0 01-.174-.138.248.248 0 010-.222 4.191 4.191 0 00.473-1.936A6.992 6.992 0 009.069.1a1.236 1.236 0 00-1.455.364 1.234 1.234 0 00-.058 1.5 1.91 1.91 0 01.2.355.249.249 0 01-.231.348 1.255 1.255 0 00-.537.119A3.934 3.934 0 004.6 6.431 4.174 4.174 0 005.041 8.3a.276.276 0 01-.247.4 1 1 0 100 2h.5a.5.5 0 01.5.5v.546a.5.5 0 01-.5.5h-1.78a1.5 1.5 0 000 3h.8a.25.25 0 01.194.408l-2.142 2.622A3.5 3.5 0 005.079 24h7.428a3.5 3.5 0 002.713-5.724l-2.141-2.618a.249.249 0 01.193-.408h4.159a.5.5 0 01.5.5V23a1 1 0 002 0v-7.25a.5.5 0 01.5-.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5zM7.09 5.127a.218.218 0 01.32 0 4.741 4.741 0 00.5.452 1 1 0 001.45-.222 3.587 3.587 0 00.549-1.616.25.25 0 01.451-.121 4.834 4.834 0 01.948 2.813 2.359 2.359 0 01-4.714 0 1.871 1.871 0 01.496-1.306zm2.7 6.077v.546a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.55a.5.5 0 01.5-.5h1a.5.5 0 01.504.5zm2.781 7.249a.252.252 0 01-.254.124 7.21 7.21 0 00-1.03-.077c-2.5 0-4 1.5-6 1.5a6.3 6.3 0 01-1.182-.114.25.25 0 01-.188-.158.253.253 0 01.039-.243c.285-.356 1.04-1.259 3.317-4.051a.5.5 0 01.387-.184h2.263a.5.5 0 01.387.183l2.24 2.738a.251.251 0 01.025.282z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});