define("ember-svg-jar/inlined/wolf-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wolf-3</title><path d=\"M23.748 13.566l-2.364-1.35a.5.5 0 01-.105-.788l.574-.574a.5.5 0 00-.129-.8l-2.76-1.38a.5.5 0 01-.13-.8l.519-.519a.5.5 0 00-.076-.769s-1.5-1-3.076-2.006a6.029 6.029 0 00-1.469-.655c-.066-.3-.145-.634-.247-1.044A3.949 3.949 0 0010.48 0a.5.5 0 00-.455.659A10.431 10.431 0 0110.5 3.5a2.189 2.189 0 01-.057.505A5.562 5.562 0 005.054 8.79a2.588 2.588 0 01-1.154 1.6c-1.059.689-2.5 1.654-3.341 2.337A1.44 1.44 0 00.074 14.3c.6 1.724 3.453 3.585 3.7 3.671a4.954 4.954 0 003.084-.507 4.422 4.422 0 012.416-.493c.565.187.318 1.726.08 3.215A21.4 21.4 0 009 23.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V14a.5.5 0 00-.252-.434zM9 8.251A1.25 1.25 0 117.75 9.5 1.25 1.25 0 019 8.251z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});