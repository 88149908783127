define("ember-svg-jar/inlined/transform-shrink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transform-shrink</title><path d=\"M22.01 1.632a.25.25 0 010-.355l.071-.07A.5.5 0 0021.8.358L19.321.005a.5.5 0 00-.566.566l.353 2.475a.5.5 0 00.849.282l.285-.285a.25.25 0 01.348-.005A5.872 5.872 0 0122 7a1 1 0 002 0 7.84 7.84 0 00-1.99-5.368zM6.4 20.253a.5.5 0 00-.87.211l-.089.392a.25.25 0 01-.293.189 5.9 5.9 0 01-3.3-2.607A1 1 0 00.153 19.5a7.835 7.835 0 004.537 3.49.247.247 0 01.157.11.25.25 0 01.033.19l-.022.1a.5.5 0 00.69.569l2.285-1.015a.5.5 0 00.178-.78zM23 10a1 1 0 00-1-1h-1.827a.25.25 0 01-.248-.22 10 10 0 10-3.769 9.1.25.25 0 01.331.02l2.087 2.087a1 1 0 001.414-1.414l-7.147-7.147a.25.25 0 01.177-.426H22a1 1 0 001-1zM10 9a1 1 0 00-.707 1.707l5.41 5.409a.251.251 0 01-.031.38A8 8 0 1117.9 8.709a.251.251 0 01-.251.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});