define("ember-svg-jar/inlined/trousers-jeans", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trousers-jeans</title><path d=\"M21.177 11.757L2.81 12.24a.25.25 0 00-.221.353l1.738 3.823a2.025 2.025 0 00.8.882 1.972 1.972 0 001.263.276l14.825-1.8a.251.251 0 00.22-.249v-3.518a.25.25 0 00-.258-.25zM19.425 17l-5.772.7a.251.251 0 00-.22.249V23.5a.25.25 0 00.25.25h5.028a.252.252 0 00.249-.221l.744-6.251a.251.251 0 00-.279-.278z\"/><rect x=\"6.933\" y=\".25\" width=\"4\" height=\"2.5\" rx=\".25\" ry=\".25\"/><rect x=\"11.933\" y=\".25\" width=\"2\" height=\"2.5\" rx=\".25\" ry=\".25\"/><rect x=\"14.933\" y=\".25\" width=\"6.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M3.393 11.225l7.373-.2a.25.25 0 00.191-.1l2.029-2.609a.25.25 0 01.447.154v2.23a.25.25 0 00.257.25l6.572-.173a.251.251 0 00.242-.221l.777-6.531a.251.251 0 00-.248-.279H7.058a.248.248 0 00-.221.134l-3.671 6.978a.251.251 0 00.227.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});