define("ember-svg-jar/inlined/single-woman-actions-warning.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-warning</title><path d=\"M13.128 14.851a.5.5 0 00-.055-.547 7.528 7.528 0 00-5.822-2.8 7.542 7.542 0 00-7.185 5.353.5.5 0 00.479.643h10.89a.5.5 0 00.443-.267zM18.737 12.272a1.449 1.449 0 00-2.562 0l-5.055 9.634a1.429 1.429 0 00.048 1.408A1.454 1.454 0 0012.4 24h10.112a1.454 1.454 0 001.233-.686 1.431 1.431 0 00.049-1.408zm-1.28 2.978a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zm-1 6a1 1 0 111 1 1 1 0 01-1-1zM1.568 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.573 4.573 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});