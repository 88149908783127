define("ember-svg-jar/inlined/restaurant-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-menu</title><path d=\"M23.4 1.5A1.5 1.5 0 0021.9 0H4.4a1.5 1.5 0 00-1.5 1.5v.75a.5.5 0 00.5.5h.956a.75.75 0 010 1.5h-3a.75.75 0 000 1.5H2.4a.5.5 0 01.5.5v4a.5.5 0 00.5.5h.956a.75.75 0 010 1.5h-3a.75.75 0 000 1.5H2.4a.5.5 0 01.5.5v4a.5.5 0 00.5.5h.956a.75.75 0 110 1.5h-3a.75.75 0 000 1.5H2.4a.5.5 0 01.5.5v.25A1.5 1.5 0 004.4 24h17.5a1.5 1.5 0 001.5-1.5zm-12.015 8.72a.5.5 0 00-.288.453V17.5a.75.75 0 01-1.5 0v-6.825a.5.5 0 00-.288-.453A2.449 2.449 0 017.9 8V6.7a2.45 2.45 0 114.9 0V8a2.454 2.454 0 01-1.415 2.22zm9.262-2.47a3.5 3.5 0 01-2.176 3.3.5.5 0 00-.324.467V17.5a.75.75 0 01-1.5 0v-5.979a.5.5 0 00-.323-.467 3.5 3.5 0 01-2.177-3.3V5a.75.75 0 111.5 0v2.75a2.137 2.137 0 00.569 1.475.25.25 0 00.431-.173V5a.75.75 0 011.5 0v4.052a.25.25 0 00.431.173 2.132 2.132 0 00.569-1.475V5a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});