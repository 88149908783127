define("ember-svg-jar/inlined/email-action-send-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-send-1</title><path d=\"M13.073 13.25H17.6v-.078a2.135 2.135 0 011.369-2.043A2.074 2.074 0 0121 11.5V3.25a.158.158 0 00-.1-.147.161.161 0 00-.173.034L12.2 11.664a2.407 2.407 0 01-3.4 0L.273 3.137A.158.158 0 00.1 3.1a.16.16 0 00-.1.15v10.5a1.749 1.749 0 001.75 1.75h8.787a2.6 2.6 0 012.536-2.25z\"/><path d=\"M9.728 10.737a1.1 1.1 0 001.547 0l8.475-8.476a.437.437 0 00-.246-.742 1.751 1.751 0 00-.253-.019h-17.5a1.748 1.748 0 00-.252.019.437.437 0 00-.246.742zM20.182 12.748a.61.61 0 00-.69-.213.629.629 0 00-.391.637v1.578h-6.028A1.124 1.124 0 0012 15.916v3.168a1.124 1.124 0 001.074 1.166H19.1v1.578a.609.609 0 00.59.672.637.637 0 00.492-.248l3.611-4.189a.875.875 0 000-1.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});