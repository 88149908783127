define("ember-svg-jar/inlined/server-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-settings</title><path d=\"M2.509 6.015h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3A1.115 1.115 0 114.759 4.13a1.113 1.113 0 01-1.115-1.115zm3.865 0A1.115 1.115 0 118.623 4.13a1.113 1.113 0 01-1.114-1.115zM17.759 8.532a8.4 8.4 0 013.412.723.5.5 0 00.675-.622 2.538 2.538 0 00-2.337-1.618h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h7.478a.49.49 0 00.423-.248 8.5 8.5 0 017.349-4.235zm-8.021 1.483A1.115 1.115 0 118.623 8.9a1.115 1.115 0 011.115 1.115zm-3.865 0A1.115 1.115 0 114.759 8.9a1.115 1.115 0 011.114 1.115zM2.509 20.015h6.608a.5.5 0 00.484-.624 8.328 8.328 0 010-4.742.5.5 0 00-.48-.634H2.509a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5zm1.135-3a1.115 1.115 0 111.115 1.114 1.114 1.114 0 01-1.115-1.114z\"/><circle cx=\"17.739\" cy=\"17.015\" r=\"1.5\"/><path d=\"M13.671 12.684a1.783 1.783 0 00-1.7 2.953l.987 1.066a.461.461 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.456.456 0 01.533.311l.423 1.391a1.776 1.776 0 003.4 0l.423-1.391a.449.449 0 01.534-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.463.463 0 010-.625l.988-1.066a1.783 1.783 0 00-1.7-2.953l-1.412.327a.454.454 0 01-.532-.31l-.424-1.392a1.776 1.776 0 00-3.4 0l-.423 1.391a.456.456 0 01-.534.31zm7.068 4.331a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});