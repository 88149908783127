define("ember-svg-jar/inlined/single-neutral-actions-mobilephone.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-mobilephone</title><path d=\"M13.5 14.112a.507.507 0 00-.085-.28A7.533 7.533 0 007.206 10.5a7.542 7.542 0 00-7.185 5.352.5.5 0 00.479.642H13a.5.5 0 00.5-.5z\"/><circle cx=\"7\" cy=\"4.748\" r=\"4.749\"/><path d=\"M22.526 11.005h-6.052A1.493 1.493 0 0015 12.513v9.979A1.493 1.493 0 0016.474 24h6.052A1.493 1.493 0 0024 22.492v-9.979a1.493 1.493 0 00-1.474-1.508zM17 21.5v-8a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});