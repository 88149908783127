define("ember-svg-jar/inlined/champagne-cooler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>champagne-cooler</title><path d=\"M19.5 11.25h-15a3.5 3.5 0 00-1.255 6.769.987.987 0 00.357.066 1 1 0 00.915-1.407 1 1 0 00-.555-.526 1.5 1.5 0 01.538-2.9h.141a.5.5 0 01.485.379l2.4 9.614A1 1 0 008.5 24h7a1 1 0 00.97-.757l2.4-9.614a.5.5 0 01.485-.379h.145a1.5 1.5 0 01.539 2.9 1 1 0 00-.576 1.292 1 1 0 001.292.575A3.5 3.5 0 0019.5 11.25zM7.762 9.995L17.5 10a.5.5 0 00.5-.5V8.278a2.188 2.188 0 01.914-2.122l.884-.883a.5.5 0 000-.707l-1.432-1.432a.5.5 0 00-.707 0l-.9.9a2.186 2.186 0 01-2.122.914 6.084 6.084 0 00-4.6 1.561L7.409 9.142a.5.5 0 00.353.853zM20.858 3.505a.5.5 0 00.707 0L22.8 2.268a.5.5 0 00-.005-.711L21.35.144a.5.5 0 00-.7.005l-1.223 1.217a.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});