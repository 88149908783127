define("ember-svg-jar/inlined/bathroom-hanger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-hanger</title><path d=\"M18.489 20.984H5.511a.5.5 0 00-.5.5v1.5a1 1 0 001 1H17.99a1 1 0 001-1v-1.5a.5.5 0 00-.501-.5zM24 11.543A2.163 2.163 0 0023.356 10a19 19 0 00-10.134-4.92.249.249 0 01-.222-.25v-.941a1 1 0 00-1-1 .437.437 0 010-.874.432.432 0 01.319.139A1 1 0 1013.775.789a2.433 2.433 0 10-2.907 3.819.25.25 0 01.132.221v.085a.155.155 0 01-.141.155A18.955 18.955 0 00.644 10 2.163 2.163 0 000 11.543 1.956 1.956 0 001.955 13.5h2.808a.25.25 0 01.249.25v5.241a.5.5 0 00.5.5h12.977a.5.5 0 00.5-.5v-5.244a.25.25 0 01.249-.25h2.808A1.956 1.956 0 0024 11.543zm-5.085-.043a.219.219 0 01-.2-.12A2.5 2.5 0 0016.492 10H7.508a2.5 2.5 0 00-2.224 1.369.25.25 0 01-.22.137l-2.616.029a.25.25 0 01-.184-.421C3.432 9.884 8.187 7.009 12 7.009c3.729 0 8.535 2.837 9.754 4.067a.25.25 0 01-.177.425z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});