define("ember-svg-jar/inlined/smiley-smirk-glasses-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-smirk-glasses-1-alternate</title><path d=\"M17.7 15.277a1 1 0 00-1.4.156 5.5 5.5 0 01-6.41 1.643 1 1 0 00-.77 1.847 7.5 7.5 0 008.74-2.24 1 1 0 00-.16-1.406zM7.829 2.9a10.077 10.077 0 018.342 0 .986.986 0 00.417.1 1 1 0 00.419-1.908 12.078 12.078 0 00-10.014 0A1 1 0 007.829 2.9z\"/><path d=\"M22.73 6.425a2.784 2.784 0 00-2.762-2.447H4.032A2.784 2.784 0 001.27 6.425a12.333 12.333 0 00.574 11.969 12 12 0 0020.312 0 12.333 12.333 0 00.574-11.969zm-2.762-.447a.78.78 0 01.774.892l-.493 3.447a2.891 2.891 0 01-2.471 1.427 4.322 4.322 0 01-4.2-1.684 1.841 1.841 0 00-3.16 0 4.32 4.32 0 01-4.195 1.686 2.9 2.9 0 01-2.474-1.429L3.258 6.87a.78.78 0 01.774-.892zm.5 11.348a10 10 0 01-18.444-5.977 4.939 4.939 0 003.968 2.383 7.61 7.61 0 00.886.052 6.178 6.178 0 004.925-2.21.249.249 0 01.2-.1.253.253 0 01.2.1 6.359 6.359 0 005.813 2.157 4.934 4.934 0 003.965-2.382 9.914 9.914 0 01-1.516 5.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});