define("ember-svg-jar/inlined/paralympics-archery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-archery</title><path d=\"M6 21.75a3.492 3.492 0 01-.7-6.915.248.248 0 00.2-.244v-1.535a.249.249 0 00-.285-.247 5.5 5.5 0 105.966 7.275.251.251 0 00-.236-.334H9.3a.251.251 0 00-.221.133A3.493 3.493 0 016 21.75zM11 3.25a2.5 2.5 0 10-2.5 2.5 2.5 2.5 0 002.5-2.5zM20.7.677a1 1 0 00-1.641 1.144c3.256 4.662 3.256 9.193 0 13.856a1 1 0 001.641 1.144c3.739-5.356 3.739-10.788 0-16.144z\"/><path d=\"M16.877 19.955l-1.913-3.061A3.48 3.48 0 0012 15.25V10.5a.25.25 0 01.25-.25H18a1.5 1.5 0 000-3h-7.062a.25.25 0 00-.222.365A2.434 2.434 0 0111 8.749a2.5 2.5 0 01-2.5 2.5H7.246A.25.25 0 007 11.5v5.25a1.5 1.5 0 001.5 1.5H12a.505.505 0 01.424.234l1.913 3.061a1.5 1.5 0 002.544-1.59z\"/><path d=\"M4 10.249h4.5a1.5 1.5 0 000-3H4a1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});