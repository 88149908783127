define("ember-svg-jar/inlined/vr-play-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-play-device</title><path d=\"M.312 16.334a.25.25 0 00.271.022A2.987 2.987 0 012 16h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.28c-.268-1.087-.793-3.2-.793-3.2a.5.5 0 00-.485-.376H11a.5.5 0 00-.354.146 2.894 2.894 0 01-2.061.854H5.414a2.929 2.929 0 01-2.06-.854A.5.5 0 003 12.5H1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.252.252 0 00.09.255zM12 17H2a2 2 0 00-2 2v3a2 2 0 002 2h10a2 2 0 002-2v-3a2 2 0 00-2-2zm-7.5 3h-2a.5.5 0 010-1h2a.5.5 0 010 1zm3 0h-1a.5.5 0 010-1h1a.5.5 0 010 1zM22 0h-9a2 2 0 00-2 2v8.75a.25.25 0 00.25.25h1.25a2 2 0 011.94 1.515l.074.295a.25.25 0 00.243.19H22a2 2 0 002-2V2a2 2 0 00-2-2zm-2.559 7.3l-3.033 1.82A.931.931 0 0115 8.32V4.68a.93.93 0 011.408-.8l3.033 1.82a.928.928 0 010 1.595z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});