define("ember-svg-jar/inlined/single-woman-actions-view.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-view</title><path d=\"M9.456 16.5a6.927 6.927 0 011.026-3.632.5.5 0 00-.223-.719A7.4 7.4 0 007.25 11.5a7.544 7.544 0 00-7.185 5.36.5.5 0 00.479.642h8.433a.5.5 0 00.5-.539 5.122 5.122 0 01-.021-.463zM21.129 19.756a.25.25 0 01-.034-.311 5.507 5.507 0 10-1.695 1.692.248.248 0 01.311.033l2.534 2.535a1 1 0 001.414-1.414zM12.956 16.5a3.5 3.5 0 113.5 3.5 3.5 3.5 0 01-3.5-3.5zM1.566 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.297 1.382zM7.208 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});