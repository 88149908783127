define("ember-svg-jar/inlined/phone-actions-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-subtract</title><path d=\"M17.168 16.163a2.559 2.559 0 00-3.537 0l-.384.384a46.7 46.7 0 01-5.789-5.79l.385-.384a2.507 2.507 0 000-3.536L5.721 4.716a2.5 2.5 0 00-3.536 0L1.022 5.879a3.514 3.514 0 00-.442 4.405 46.915 46.915 0 0013.141 13.141 3.542 3.542 0 004.4-.442l1.164-1.165a2.5 2.5 0 000-3.534zM17.5.005a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.25 6.5a.75.75 0 01-.75.75h-5a.75.75 0 010-1.5h5a.75.75 0 01.748.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});