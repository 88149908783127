define("ember-svg-jar/inlined/christmas-tree-ornaments-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-tree-ornaments-1</title><path d=\"M10.091 18.9c-.354-.627-.539-.889-.779-.889s-.423.262-.776.888c-.392.7-.93 1.654-2.083 1.654s-1.691-.956-2.084-1.654c-.352-.626-.537-.888-.774-.888s-.424.262-.777.889a6.693 6.693 0 01-.482.77.5.5 0 00-.074.462 5 5 0 009.119.811.5.5 0 00-.171-.664 3.659 3.659 0 01-1.119-1.379z\"/><path d=\"M1.18 8.457l4.23-.772a.5.5 0 01.59.491v3.1a.5.5 0 01-.2.4 2 2 0 00-.8 1.6v.35a.5.5 0 01-.273.445 5.019 5.019 0 00-2.218 2.257.25.25 0 00.328.337 1.784 1.784 0 01.758-.16c1.153 0 1.69.956 2.082 1.653.352.627.538.889.776.889s.424-.262.776-.889c.392-.7.93-1.653 2.083-1.653s1.693.955 2.085 1.653l.129.225a.25.25 0 00.466-.141 5 5 0 00-2.719-4.171.5.5 0 01-.273-.444v-.35a2 2 0 00-.8-1.6.5.5 0 01-.2-.4V7.629a.5.5 0 01.41-.492l8-1.461a.5.5 0 01.59.492v3.11a.5.5 0 01-.2.4 2 2 0 00-.8 1.6 1.965 1.965 0 00.037.383.5.5 0 01-.218.514 4.01 4.01 0 00-1.532 1.863.5.5 0 00.464.686h6.5a.5.5 0 00.464-.686 4.016 4.016 0 00-1.532-1.863.5.5 0 01-.218-.514 2.046 2.046 0 00.035-.384 2 2 0 00-.8-1.6.5.5 0 01-.2-.4V5.62a.5.5 0 01.41-.492l.826-.151a.5.5 0 01.371.078l1.83 1.245a1 1 0 001.125-1.655l-1.1-.747a.5.5 0 01-.133-.694l.8-1.17A1 1 0 0021.472.911L20.187 2.8a.506.506 0 01-.324.211l-1.43.261a.25.25 0 01-.272-.35l.352-.771a1 1 0 00-1.821-.83l-1.02 2.239a.5.5 0 01-.365.285l-2.732.5a.251.251 0 01-.272-.351l.753-1.63a1 1 0 00-1.817-.838L9.806 4.633a.505.505 0 01-.365.283l-2.247.41a.251.251 0 01-.272-.351l.73-1.584a1 1 0 10-1.816-.837L4.425 5.616a.5.5 0 01-.365.284l-3.24.59a1 1 0 00.36 1.967z\"/><path d=\"M14.71 16.223a.5.5 0 00-.47.672 4 4 0 007.52 0 .5.5 0 00-.47-.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});