define("ember-svg-jar/inlined/baby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby</title><path d=\"M10.555 11.783a1.022 1.022 0 00-.993-1.032.974.974 0 00-.966.733.524.524 0 01-.509.4.529.529 0 01-.508-.381 1 1 0 00-1.96.281 2.282 2.282 0 002.468 2.1 2.282 2.282 0 002.468-2.101zM17.381 10.783a1 1 0 00-.96.719.529.529 0 01-.508.381.524.524 0 01-.509-.4.975.975 0 00-.966-.733 1.022 1.022 0 00-.993 1.032 2.282 2.282 0 002.468 2.1 2.282 2.282 0 002.468-2.1 1 1 0 00-1-.999zM14.806 16.3a1 1 0 00-1.37.35 1.852 1.852 0 01-1.436.491 1.852 1.852 0 01-1.436-.488 1 1 0 00-1.72 1.02A3.6 3.6 0 0012 19.141a3.6 3.6 0 003.156-1.468 1 1 0 00-.35-1.373z\"/><path d=\"M22.567 9.927a.99.99 0 01-.379-.574A10.571 10.571 0 0012 1 10.571 10.571 0 001.812 9.353a1 1 0 01-.379.574 3.5 3.5 0 00.474 5.94 1 1 0 01.467.506A10.5 10.5 0 0012 23a10.5 10.5 0 009.626-6.627 1 1 0 01.467-.506 3.5 3.5 0 00.474-5.94zM20.8 14.221a1 1 0 00-.755.673A8.46 8.46 0 0112 21a8.46 8.46 0 01-8.042-6.106 1 1 0 00-.755-.673 1.5 1.5 0 01-.228-2.876 1 1 0 00.637-.783A8.643 8.643 0 0111.991 3a1.493 1.493 0 011.449 1.345 1 1 0 01-.89 1.09.589.589 0 01-.649-.618 1 1 0 00-1.951-.442 2.539 2.539 0 00.9 2.477 2.8 2.8 0 003.92-.512 2.972 2.972 0 00.664-2.155.249.249 0 01.366-.241 8.97 8.97 0 014.588 6.618 1 1 0 00.637.783 1.5 1.5 0 01-.228 2.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});