define("ember-svg-jar/inlined/smiley-sad-crying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sad-crying</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 7a2 2 0 11-2 2 2 2 0 012-2zm-.838 10.453a5.774 5.774 0 019.676 0 1 1 0 01-1.676 1.094 3.771 3.771 0 00-6.324 0 1 1 0 01-1.676-1.094zM16 11a2 2 0 112-2 2 2 0 01-2 2zm4 5.5a2.5 2.5 0 01-2.5-2.5c0-1.136 1.691-3.786 2.292-4.691a.249.249 0 01.416 0c.6.905 2.292 3.555 2.292 4.691a2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});