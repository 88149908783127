define("ember-svg-jar/inlined/clean-car-leaf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clean-car-leaf-1</title><path d=\"M5.943 6.192c2.33-1.5 5.949-3.129 8.644-2.539a.6.6 0 01-.258 1.181 8.47 8.47 0 00-4.593.749.5.5 0 00-.293.606c.492 1.652 2.286 2.189 4 1.961 2.09-.28 5.334-2.972 6-5.6a.606.606 0 00-.567-.753A7.858 7.858 0 0115.656.9a8.4 8.4 0 00-2.8-.875 3.305 3.305 0 00-2.95 1.013 2.647 2.647 0 00-.562 1.743.5.5 0 01-.319.471 20.575 20.575 0 00-3.76 1.94.6.6 0 00.678 1zM18.854 16c-.308 0-.741-.41-.928-.879l-.743-1.856A3.781 3.781 0 0013.469 11H9.14a2.508 2.508 0 00-2.452 2.01l-.356 1.784A1.5 1.5 0 014.86 16H3.5A2.5 2.5 0 001 18.5v2a1.5 1.5 0 00.828 1.341.5.5 0 00.717-.361 3 3 0 015.924.092.5.5 0 00.5.428h6.072a.5.5 0 00.494-.428 3 3 0 015.925-.092.5.5 0 00.717.361A1.5 1.5 0 0023 20.5v-.355A4.15 4.15 0 0018.854 16zm-2.894-.11a.249.249 0 01-.207.11H13.5a1.5 1.5 0 01-1.5-1.5v-1a.5.5 0 01.5-.5h.969a1.876 1.876 0 011.857 1.007c.375.936.561 1.4.659 1.65a.248.248 0 01-.025.233z\"/><circle cx=\"5.5\" cy=\"22\" r=\"2\"/><circle cx=\"18.5\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});