define("ember-svg-jar/inlined/walking-forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walking-forbidden</title><circle cx=\"12\" cy=\"4.898\" r=\"2.204\"/><path d=\"M7.363 19.146a1.225 1.225 0 001.008 1.409 1.25 1.25 0 00.2.016 1.226 1.226 0 001.209-1.023l.313-1.89a2.709 2.709 0 01.589-1.282l.64-.769.115.773a5.143 5.143 0 001.075 2.469l1.2 1.5a1.226 1.226 0 001.722.189 1.161 1.161 0 00.377-.559.246.246 0 00-.06-.249l-5.838-5.839a.238.238 0 00-.184-.071.246.246 0 00-.177.087l-.75.9a5.134 5.134 0 00-1.124 2.448zM5.388 11.755a1.225 1.225 0 001.224 1.225H7.4a3.7 3.7 0 00.952-.132.245.245 0 00.111-.41l-1.819-1.816a.244.244 0 00-.208-.07 1.218 1.218 0 00-1.048 1.203z\"/><path d=\"M12 24a11.911 11.911 0 006.248-1.774 12.109 12.109 0 001.495-1.073c.138-.117.269-.242.4-.366a.861.861 0 00.092-.061c.016-.016.022-.039.037-.057A11.985 11.985 0 103.33 3.726c-.017.015-.04.021-.056.037a.861.861 0 00-.061.092c-.124.133-.249.264-.366.4a12.109 12.109 0 00-1.073 1.5A11.976 11.976 0 0012 24zm0-22.041a10.025 10.025 0 017.7 16.466.248.248 0 01-.177.087.245.245 0 01-.185-.071l-3.024-3.024-2.771-2.771a.246.246 0 01-.072-.173V11.6a.245.245 0 01.409-.182 3.973 3.973 0 011.33 2.043 1.223 1.223 0 001.2.984 1.239 1.239 0 00.241-.024 1.225 1.225 0 00.961-1.441 7.054 7.054 0 00-3.1-4.177 4.086 4.086 0 00-2.337-.725h-.448a3.326 3.326 0 00-1.877.586.245.245 0 01-.311-.029l-.546-.545-3.43-3.43a.242.242 0 01-.071-.185.246.246 0 01.083-.175A9.981 9.981 0 0112 1.959zM3.2 7.176a9.575 9.575 0 01.666-1.037.245.245 0 01.371-.029L17.89 19.765a.245.245 0 01-.029.371 9.575 9.575 0 01-1.037.666c-.178.1-.364.182-.549.27A10.029 10.029 0 013.2 7.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});