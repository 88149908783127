define("ember-svg-jar/inlined/basketball-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>basketball-ball</title><path d=\"M9.73 6.205a.5.5 0 00.129.691q.411.285.821.595a.5.5 0 00.6 0 21.334 21.334 0 018.728-3.882.25.25 0 00.119-.43A11.953 11.953 0 0012.038.038c-.1 0-.193 0-.288.007a.5.5 0 00-.476.524A9.192 9.192 0 019.73 6.205zM12.489 8.75a.5.5 0 00.155.4 21.807 21.807 0 011.525 1.591.5.5 0 00.616.111 8.761 8.761 0 018.8.163.251.251 0 00.374-.243 11.918 11.918 0 00-2.133-5.656.5.5 0 00-.488-.2A20.258 20.258 0 0012.7 8.378a.5.5 0 00-.211.372zM12.835 12.251a.5.5 0 00.037-.7 20.811 20.811 0 00-1.506-1.532.5.5 0 00-.678 0 24.692 24.692 0 00-6.521 10.665.5.5 0 00.156.528 11.922 11.922 0 006.448 2.745.25.25 0 00.243-.373 8.921 8.921 0 011.821-11.333z\"/><path d=\"M14.448 13.577a.5.5 0 00-.361-.216.506.506 0 00-.4.136 7.527 7.527 0 00-.8 10.262.5.5 0 00.457.2 11.923 11.923 0 004.116-1.213.5.5 0 00.269-.517 20.363 20.363 0 00-3.281-8.652zM1.131 8.737a7.648 7.648 0 005.314-1.346.5.5 0 00-.052-.852A26.619 26.619 0 002.8 4.968a.5.5 0 00-.573.185 11.911 11.911 0 00-1.51 2.921.5.5 0 00.414.663zM4.223 3.907a28.174 28.174 0 013.549 1.679.5.5 0 00.657-.152A7.71 7.71 0 009.758.871.5.5 0 009.137.4a11.949 11.949 0 00-5.063 2.668.5.5 0 00.149.839zM23.761 12.9a7.449 7.449 0 00-7.879-.93.5.5 0 00-.2.735 21.554 21.554 0 013.422 8.559.25.25 0 00.4.153 11.965 11.965 0 004.452-8.062.5.5 0 00-.195-.455zM9.374 9.116a.5.5 0 00.153-.4.5.5 0 00-.206-.372l-.57-.4a.5.5 0 00-.608.038 9.139 9.139 0 01-7.438 2.195.5.5 0 00-.376.1.507.507 0 00-.195.336 11.247 11.247 0 00-.1 1.43 11.943 11.943 0 002.546 7.372.251.251 0 00.434-.074 25.8 25.8 0 016.36-10.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});