define("ember-svg-jar/inlined/audio-file-equalizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-equalizer</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM8.5 20.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm4.5 9h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm4.5 3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});