define("ember-svg-jar/inlined/heavy-equipment-lift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-lift</title><path d=\"M9.959 6.544a2.656 2.656 0 01.556-.846.256.256 0 00.068-.209.25.25 0 00-.124-.182L3.552 1.36a1.491 1.491 0 00-1.71 2.426l.658.564v8.631a3.5 3.5 0 102 0V6.065l3.928 3.366a.249.249 0 00.394-.095zM22 18.835H11a2 2 0 000 4h11a2 2 0 000-4zm-10 2.75a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zM21 11.335h-2.5v-2.5a2.5 2.5 0 00-2.5-2.5h-3.5a1.3 1.3 0 00-1.151.774l-1.812 4.449a.509.509 0 00-.037.189v4.088a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5zm-8.982-.46l.665-2.337a.464.464 0 01.447-.338h2.9a.466.466 0 01.465.467V11a.466.466 0 01-.465.467h-3.57a.467.467 0 01-.442-.592z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});