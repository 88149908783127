define("ember-svg-jar/inlined/flip-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flip-right</title><path d=\"M24 2a2 2 0 00-2-2h-1a1 1 0 000 2h.5a.5.5 0 01.5.5V3a1 1 0 002 0zM15 1a1 1 0 001 1h1.5a1 1 0 000-2H16a1 1 0 00-1 1zM23 20a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H21a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1zM18.5 23a1 1 0 00-1-1H16a1 1 0 000 2h1.5a1 1 0 001-1zM23 10.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM23 5.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM23 15.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM12.731 8.031a.25.25 0 00.269-.25V1a1 1 0 00-2 0v6.779a.25.25 0 00.079.182.248.248 0 00.187.067 9.077 9.077 0 011.465.003zM11.234 13.024a.249.249 0 00-.234.249V23a1 1 0 002 0v-9.7a.25.25 0 00-.216-.248A5.822 5.822 0 0012 13q-.4 0-.766.024z\"/><path d=\"M19.75 10.5a.755.755 0 00-1.356-.454l-.938.938a.252.252 0 01-.311.034A9.541 9.541 0 0012 9.5a11.151 11.151 0 00-7.536 2.486A1 1 0 105.772 13.5a9.2 9.2 0 016.228-2 7.472 7.472 0 013.57.914.251.251 0 01.059.4l-.659.659a.751.751 0 00.53 1.28h3.243c.7 0 1.007-.17 1.007-1z\"/><path d=\"M9 23a1 1 0 00-1-1H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h6a1 1 0 000-2H2a2 2 0 00-2 2v20a2 2 0 002 2h6a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});