define("ember-svg-jar/inlined/office-desk-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-desk-2</title><path d=\"M23.5 21h-7a.5.5 0 00-.5.5v1a1.5 1.5 0 001.5 1.5h5a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5zM22.5 11.5H11.75a.249.249 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a2 2 0 002-2V2a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h4.25a.25.25 0 01.25.25v1a.249.249 0 01-.25.25H1.5A1.5 1.5 0 000 13v10a1 1 0 002 0v-7.5a.5.5 0 01.5-.5h21a.5.5 0 00.5-.5V13a1.5 1.5 0 00-1.5-1.5zM5 2h11v6H5z\"/><rect x=\"16\" y=\"16.499\" width=\"8\" height=\"3\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});