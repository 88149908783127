define("ember-svg-jar/inlined/modern-architecture-twin-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-twin-building</title><path d=\"M2.5 6.981h6a.5.5 0 00.5-.5v-1a.5.5 0 00-.061-.238L6.378.5a1.038 1.038 0 00-1.756 0L2.061 5.243A.5.5 0 002 5.481v1a.5.5 0 00.5.5zM15.5 6.981h6a.5.5 0 00.5-.5v-1a.5.5 0 00-.061-.238L19.378.5a1.038 1.038 0 00-1.756 0l-2.561 4.743a.5.5 0 00-.061.238v1a.5.5 0 00.5.5zM22 8.481h-7a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-1.5a1 1 0 00-1-1H2a1 1 0 00-1 1v14a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-8.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v8.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-14a1 1 0 00-1-1zm-14.75 12h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 110 1.5zm13 8h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5zm0-4h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});