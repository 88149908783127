define("ember-svg-jar/inlined/road-sign-speed-limit-15", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-speed-limit-15</title><path d=\"M20 3.75a.25.25 0 00-.25.25v1a.25.25 0 00.5 0V4a.25.25 0 00-.25-.25zM8 3.75a.25.25 0 10-.25-.25.25.25 0 00.25.25z\"/><path d=\"M23.25 0H.75A.75.75 0 000 .75v22.5a.75.75 0 00.75.75h22.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75zm-13 3A1.752 1.752 0 0112 1.25h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5h-1A1.752 1.752 0 0110.25 6zm-4-1A.75.75 0 017 1.25h.5a2.244 2.244 0 01.45 4.443.249.249 0 00-.2.245V7a.75.75 0 01-1.5 0zm3 7.5v5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zm-7-2.5A.75.75 0 013 6.25h1A.25.25 0 004.25 6c0-.343-.192-.519-.7-.9A2.553 2.553 0 012.25 3 1.752 1.752 0 014 1.25h1a.75.75 0 010 1.5H4a.25.25 0 00-.25.25c0 .343.192.519.7.9A2.553 2.553 0 015.75 6 1.752 1.752 0 014 7.75H3A.75.75 0 012.25 7zm4.25 8.25h-1a1.752 1.752 0 01-1.75-1.75v-4a.75.75 0 011.5 0v4a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm4.5 8H9a.75.75 0 010-1.5.25.25 0 00.25-.25V19a.25.25 0 00-.4-.2l-.4.3a.751.751 0 01-.9-1.2l1.2-.9a1.25 1.25 0 012 1v3.5a.25.25 0 00.25.25.75.75 0 010 1.5zm.857-10.864a.058.058 0 00-.107.029V14.5a.75.75 0 01-1.5 0v-5a.75.75 0 011.393-.386l.643 1.071a.25.25 0 00.428 0l.643-1.071a.75.75 0 011.393.386v5a.75.75 0 01-1.5 0v-2.085a.058.058 0 00-.107-.029.78.78 0 01-1.286 0zm2.712 6.868c.708.065 2.181.2 2.181 2a2 2 0 01-2 2H13a.75.75 0 010-1.5h1.75a.5.5 0 00.5-.5c0-.395 0-.427-.819-.5-.708-.065-2.181-.2-2.181-2V17.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-2a.25.25 0 00-.25.25v.25c0 .396 0 .428.819.504zM17.25 14.5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zM17 7.75h-1A1.752 1.752 0 0114.25 6V3A1.752 1.752 0 0116 1.25h1a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5h-1a.25.25 0 000 .5h1a.75.75 0 010 1.5zm3.5 2.5a.25.25 0 00-.25.25v4a.75.75 0 01-1.5 0v-4a.25.25 0 00-.25-.25.75.75 0 010-1.5h2a.75.75 0 010 1.5zM21.75 5A2.752 2.752 0 0119 7.75a.75.75 0 01-.75-.75V2a.75.75 0 01.75-.75A2.752 2.752 0 0121.75 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});