define("ember-svg-jar/inlined/outdoors-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-landscape</title><path d=\"M14.669 17.744a15.772 15.772 0 01-1.359-.379 1 1 0 00-.639 1.9c.434.146.906.278 1.533.427a1 1 0 10.465-1.944zM9.9 14.3a1 1 0 10-1.785.9A9.186 9.186 0 009 16.627a1 1 0 101.592-1.21A7.291 7.291 0 019.9 14.3zM20.212 19.792a6.116 6.116 0 00-1.524-.923 1 1 0 10-.8 1.832 4.173 4.173 0 011.034.619 1 1 0 101.291-1.528zM3.474 13.342a.52.52 0 00-.948 0l-2.5 7.5A.5.5 0 00.5 21.5h1a.5.5 0 01.5.5v1a1 1 0 002 0v-1a.5.5 0 01.5-.5h1a.5.5 0 00.474-.658zM23.974 13.842l-2.5-7.5a.52.52 0 00-.948 0l-2.5 7.5a.5.5 0 00.474.658h1a.5.5 0 01.5.5v1a1 1 0 002 0v-1a.5.5 0 01.5-.5h1a.5.5 0 00.474-.658zM13.284 5.142a1.152 1.152 0 00-1.037-.642 1.1 1.1 0 00-.973.526.249.249 0 01-.435-.026L8.991 1.1a1.906 1.906 0 00-3.48.075l-3.7 8.792A.749.749 0 002.5 11H15a.75.75 0 00.671-1.086zm-5-1.758a.251.251 0 01-.211.116H6.532a.25.25 0 01-.23-.347l.592-1.4a.423.423 0 01.741-.015L8.3 3.143a.252.252 0 01-.012.241z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});