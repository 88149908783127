define("ember-svg-jar/inlined/clamp-press-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clamp-press-1</title><path d=\"M19.625 3.25h-8.5a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h1a1 1 0 000-2h-6a1 1 0 000 2h1a.5.5 0 01.5.5v.25a.5.5 0 01-.5.5H4.656a1.25 1.25 0 000 2.5h14.211a.5.5 0 01.5.5c.014 1.831.01 8.168.008 10a.5.5 0 01-.5.5H4.656a1.25 1.25 0 100 2.5h2.469a.5.5 0 01.5.5V20a.5.5 0 01-.5.5h-1a1 1 0 000 2h6a1 1 0 000-2h-1a.5.5 0 01-.5-.5v-.25a.5.5 0 01.5-.5h7.75a.5.5 0 01.5.5v3a1.25 1.25 0 002.5 0V6c0-1.9-1.13-2.75-2.25-2.75z\"/><rect x=\"2.125\" y=\"12\" width=\"14\" height=\"3.5\" rx=\"1\" ry=\"1\"/><rect x=\"2.125\" y=\"7\" width=\"14\" height=\"3.5\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});