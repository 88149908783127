define("ember-svg-jar/inlined/cash-user-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-user-1</title><path d=\"M1.726 11.5h9.992a1 1 0 000-2H2.476a.249.249 0 01-.25-.25v-7a.25.25 0 01.25-.25h11.118a1 1 0 000-2H1.726a1.5 1.5 0 00-1.5 1.5V10a1.5 1.5 0 001.5 1.5z\"/><circle cx=\"8.726\" cy=\"5.75\" r=\"2.25\"/><path d=\"M16.909 8.833a.25.25 0 01-.027.432 4.76 4.76 0 00-2.656 4.262v2.851a.5.5 0 00.146.353.5.5 0 00.354.147h1.458a.251.251 0 01.249.225l.645 6.448a.5.5 0 00.5.45h2.85a.5.5 0 00.5-.45l.644-6.448a.251.251 0 01.249-.225h1.459a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.774 4.774 0 00-2.481-4.185.249.249 0 01-.129-.213.246.246 0 01.118-.219 4.081 4.081 0 10-4.373-.077zm2.841 3.885a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zm-2.581-7.865a.248.248 0 01.368-.139 5.07 5.07 0 002.639.737 5.012 5.012 0 00.749-.055.25.25 0 01.286.268 2.073 2.073 0 01-4.136-.213 1.97 1.97 0 01.094-.598z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});