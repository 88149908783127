define("ember-svg-jar/inlined/arrow-down-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-down-1</title><path d=\"M12 19.5a2.3 2.3 0 01-1.729-.78L.46 7.568a1.847 1.847 0 012.773-2.439l8.579 9.752a.25.25 0 00.376 0l8.579-9.752a1.847 1.847 0 112.773 2.439l-9.808 11.148A2.31 2.31 0 0112 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});