define("ember-svg-jar/inlined/real-estate-message-building-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-message-building-1</title><path d=\"M22 1H2a2 2 0 00-2 2v14a2 2 0 002 2h4a.5.5 0 01.5.5v1.793A1.707 1.707 0 009.414 22.5l3.354-3.354a.5.5 0 01.353-.146H22a2 2 0 002-2V3a2 2 0 00-2-2zm0 15.5a.5.5 0 01-.5.5h-9a1 1 0 00-.707.293l-2.439 2.439a.5.5 0 01-.854-.353V18a1 1 0 00-1-1h-5a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M15.474 6.3l-3-1.5a.5.5 0 00-.448 0l-3 1.5a.5.5 0 00-.276.447v7.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V6.75a.5.5 0 00-.276-.45zm-1.724 4.45h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});