define("ember-svg-jar/inlined/match-fire-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>match-fire-alternate</title><path d=\"M16.736 15.682a1 1 0 00-1.265.634A6.912 6.912 0 012 14.136a11.786 11.786 0 015.864-9.96.252.252 0 01.287.022.249.249 0 01.076.277 5.253 5.253 0 00-.316 1.749 6.457 6.457 0 002.029 4.7 1 1 0 001.336 0 6.408 6.408 0 001.54-2.3.253.253 0 01.17-.147.25.25 0 01.22.048 6.766 6.766 0 01.728.691 1 1 0 101.48-1.345 8.494 8.494 0 00-2.326-1.811 1.263 1.263 0 00-1.825.855 5.674 5.674 0 01-.445 1.285.25.25 0 01-.451 0 4.636 4.636 0 01-.456-1.971 4.405 4.405 0 011.561-3.073A1.264 1.264 0 0010.289 1 13.938 13.938 0 000 14.136a8.913 8.913 0 0017.37 2.811 1 1 0 00-.634-1.265z\"/><path d=\"M23.832 6.62a1 1 0 00-1.386-.278l-10.273 6.833a.253.253 0 01-.279 0 2.919 2.919 0 00-1.065-.452 2.973 2.973 0 00-2.253.443 3 3 0 104.6 1.932.249.249 0 01.107-.255l10.271-6.836a1 1 0 00.278-1.387z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});