define("ember-svg-jar/inlined/walkman-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walkman-play</title><path d=\"M18.5 14a7.008 7.008 0 01-6.276-3.9.25.25 0 00-.448 0 7 7 0 01-8.448 3.556.254.254 0 00-.225.036.249.249 0 00-.1.2V20a2.5 2.5 0 002.5 2.5h13A2.5 2.5 0 0021 20v-6.108a.249.249 0 00-.1-.2.254.254 0 00-.225-.036A6.967 6.967 0 0118.5 14zM17 15a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0117 15zm-4 3.75a.5.5 0 011 0v1.5a.5.5 0 01-1 0zm-3 0a.5.5 0 011 0v1.5a.5.5 0 01-1 0zM7 15a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 017 15z\"/><path d=\"M11 7a5.5 5.5 0 10-5.5 5.5A5.5 5.5 0 0011 7zM2.253 8.875a.75.75 0 01.274-1.024l2.1-1.211a.252.252 0 00.125-.217V4a.75.75 0 011.5 0v2.422a.252.252 0 00.125.217l2.1 1.212a.75.75 0 01-.75 1.3l-2.1-1.211a.253.253 0 00-.25 0l-2.1 1.211a.75.75 0 01-1.024-.276zM18.5 1.5A5.5 5.5 0 1024 7a5.5 5.5 0 00-5.5-5.5zm2.222 7.649l-2.1-1.211a.253.253 0 00-.25 0l-2.1 1.211a.75.75 0 01-.75-1.3l2.1-1.211a.252.252 0 00.125-.217V4a.75.75 0 011.5 0v2.422a.252.252 0 00.125.217l2.1 1.212a.75.75 0 11-.75 1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});