define("ember-svg-jar/inlined/focus-e-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-e-alternate</title><path d=\"M11 7.5h4a1.5 1.5 0 000-3h-4A3.5 3.5 0 007.5 8v8a3.5 3.5 0 003.5 3.5h4a1.5 1.5 0 000-3h-4a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a1.5 1.5 0 000-3h-2a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5z\"/><path d=\"M21 0H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});