define("ember-svg-jar/inlined/recycling-trash-bin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-trash-bin-1</title><path d=\"M22.816 3.223L21.113.668A1.5 1.5 0 0019.865 0H4.135a1.5 1.5 0 00-1.248.668l-1.7 2.555A.5.5 0 001.6 4h20.8a.5.5 0 00.416-.777zM22.3 5.5H1.705a.5.5 0 00-.5.554l1.8 16.6A1.5 1.5 0 004.5 24h15a1.5 1.5 0 001.492-1.342l1.8-16.6a.5.5 0 00-.5-.554zm-8.74 15.256a.25.25 0 01-.31-.243V17.5a.5.5 0 01.5-.5h1.389a1.861 1.861 0 100-3.721 1.605 1.605 0 00-.327.034.245.245 0 01-.191-.041.25.25 0 01-.105-.166 2.547 2.547 0 00-5.032 0 .253.253 0 01-.1.166.248.248 0 01-.192.041 1.608 1.608 0 00-.328-.034A1.861 1.861 0 008.86 17h1.39a.5.5 0 01.5.5v3.013a.25.25 0 01-.31.243 6.5 6.5 0 113.12 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});