define("ember-svg-jar/inlined/monitor-www", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-www</title><path d=\"M19 23.75a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3V2.839A2.548 2.548 0 0021.5.25h-19A2.548 2.548 0 000 2.839V16.75a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2zM2 2.839a.551.551 0 01.5-.589h19a.551.551 0 01.5.589V14.75a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M8 6a.75.75 0 00-.75.75V8.1a5.279 5.279 0 01-.05.74.125.125 0 01-.115.107.126.126 0 01-.129-.09l-.232-.813a.75.75 0 00-1.442 0l-.232.812a.125.125 0 01-.25-.016 5.279 5.279 0 01-.05-.74V6.75a.75.75 0 00-1.5 0V8.1a6.73 6.73 0 00.482 2.5l.568 1.428a.73.73 0 00.734.471.749.749 0 00.683-.543l.163-.556a.125.125 0 01.24 0l.159.556a.749.749 0 00.683.543H7a.747.747 0 00.7-.472l.568-1.427a6.73 6.73 0 00.482-2.5V6.75A.75.75 0 008 6zM14 6a.75.75 0 00-.75.75V8.1a5.279 5.279 0 01-.053.745.125.125 0 01-.115.107.126.126 0 01-.129-.09l-.232-.813a.75.75 0 00-1.442 0l-.232.812a.125.125 0 01-.247-.021 5.279 5.279 0 01-.05-.74V6.75a.75.75 0 00-1.5 0V8.1a6.73 6.73 0 00.482 2.5l.572 1.426a.729.729 0 00.734.471.749.749 0 00.683-.543l.159-.556a.125.125 0 01.24 0l.159.556a.749.749 0 00.683.543H13a.747.747 0 00.7-.472l.572-1.426A6.73 6.73 0 0014.75 8.1V6.75A.75.75 0 0014 6zM20 6a.75.75 0 00-.75.75V8.1a5.279 5.279 0 01-.053.745.125.125 0 01-.115.107.126.126 0 01-.129-.09l-.232-.813a.75.75 0 00-1.442 0l-.232.812a.125.125 0 01-.247-.021 5.279 5.279 0 01-.05-.74V6.75a.75.75 0 00-1.5 0V8.1a6.73 6.73 0 00.482 2.507l.572 1.426a.729.729 0 00.734.471.749.749 0 00.683-.543l.159-.556a.125.125 0 01.24 0l.159.556a.749.749 0 00.683.543H19a.747.747 0 00.7-.472l.572-1.426A6.73 6.73 0 0020.75 8.1V6.75A.75.75 0 0020 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});