define("ember-svg-jar/inlined/army-symbol-medical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-medical</title><path d=\"M24 5.5a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h20a2 2 0 002-2zm-2 5a.5.5 0 01-.5.5h-.75a.25.25 0 01-.25-.25V9.5a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25V6a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zm-11.5-5a.5.5 0 01.5.5v.75a.25.25 0 01-.25.25H9.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25V9.5a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5V6a.5.5 0 01.5-.5zm-8.5 8a.5.5 0 01.5-.5h.75a.25.25 0 01.25.25v1.25a1 1 0 002 0v-1.25a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H9.5a1 1 0 000 2h1.25a.25.25 0 01.25.25V18a.5.5 0 01-.5.5h-8A.5.5 0 012 18zm11.5 5a.5.5 0 01-.5-.5v-.75a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-1.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v1.25a1 1 0 002 0v-1.25a.25.25 0 01.25-.25h.75a.5.5 0 01.5.5V18a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});