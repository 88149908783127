define("ember-svg-jar/inlined/file-copyright-r", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-copyright-r</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3zM8 16a1 1 0 00-1-1h-.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H7a1 1 0 001-1z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm.342 7.135l1.512 1.511a.5.5 0 010 .708.509.509 0 01-.708 0l-1.34-1.341c-.153-.154-.306-.155-.306.028V20a.5.5 0 01-1 0v-5.5a.5.5 0 01.5-.5H7a2 2 0 010 4h-.147a.082.082 0 00-.011.135z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});