define("ember-svg-jar/inlined/tags-alert-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-alert-alternate</title><circle cx=\"17.164\" cy=\"6.009\" r=\"1.497\"/><path d=\"M18.813 12.27a1.446 1.446 0 00-2.557 0l-5.048 9.617a1.43 1.43 0 00.048 1.406 1.454 1.454 0 001.231.686h10.1a1.453 1.453 0 001.23-.686 1.427 1.427 0 00.048-1.406zm-1.279 2.474a.749.749 0 01.749.749v2.995a.749.749 0 11-1.5 0v-2.995a.749.749 0 01.751-.749zm0 5.491a1 1 0 11-1 1 1 1 0 011-1z\"/><path d=\"M9.841 21.191l.136-.259a.059.059 0 00-.094-.069 1.025 1.025 0 01-1.412 0L2.31 14.7a1 1 0 010-1.412L13.291 2.309A.993.993 0 0114 2.016h6.66a.5.5 0 01.5.5v6.66a.993.993 0 01-.293.707l-.983.984a.25.25 0 00-.025.325 2.95 2.95 0 01.242.383l.5.944a.25.25 0 00.4.061l1.285-1.286a2.973 2.973 0 00.877-2.118v-7.16a2 2 0 00-2-2H14A2.973 2.973 0 0011.88.9L.9 11.878a3 3 0 000 4.235l6.16 6.162a2.984 2.984 0 002.117.876h.11a.25.25 0 00.239-.277 2.92 2.92 0 01.315-1.683z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});