define("ember-svg-jar/inlined/hqx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hqx</title><path d=\"M12 9.626a.876.876 0 00-.875.875V13a.875.875 0 001.75 0v-2.5A.876.876 0 0012 9.626z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM9.125 15a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 011.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V9a.625.625 0 111.25 0zm5-2a2.112 2.112 0 01-.993 1.786c-.029.019-.044.088.029.147l.727.577a.634.634 0 01.1.879.627.627 0 01-.878.1l-1.93-1.53A2.126 2.126 0 019.875 13v-2.5a2.125 2.125 0 114.25 0zm4.934 1.721a.625.625 0 11-1.118.558s-.752-1.5-.814-1.629-.194-.12-.242-.023l-.826 1.652a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.3-2.609a.252.252 0 000-.224l-1.3-2.609a.625.625 0 111.118-.558s.778 1.564.833 1.667a.122.122 0 00.22-.008l.829-1.659a.625.625 0 011.118.558l-1.3 2.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});