define("ember-svg-jar/inlined/cursor-add-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-add-alternate</title><path d=\"M6 8.99l.6-2.4 2.4-.6a1 1 0 00.2-1.86L1.749.405A1 1 0 00.407 1.747L4.133 9.2A1 1 0 006 8.991zM17.7 13.7h-1.75a.25.25 0 01-.25-.25V11.7a1 1 0 10-2 0v1.75a.25.25 0 01-.25.25H11.7a1 1 0 000 2h1.75a.25.25 0 01.25.25v1.75a1 1 0 102 0v-1.75a.25.25 0 01.25-.25h1.75a1 1 0 000-2z\"/><path d=\"M14.7 5.7a9 9 0 109 9 9.01 9.01 0 00-9-9zm0 16a7 7 0 117-7 7.008 7.008 0 01-7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});