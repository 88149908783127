define("ember-svg-jar/inlined/travel-insurance-plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-insurance-plane-1</title><path d=\"M9.468 3.008l1.733.717a.249.249 0 00.2 0l3.585-1.559a.251.251 0 000-.461L11.943.451a1 1 0 00-.764 0l-1.711.709a1 1 0 000 1.848z\"/><path d=\"M22.812 1.481a2.153 2.153 0 00-2.329-.465l-.017.006L9.523 5.783 7.489 4.766a1 1 0 00-.84-.025l-1.967.844a1 1 0 00-.312 1.626l2.917 2.917a1 1 0 001.08.222l4.239-1.7a.25.25 0 01.327.319l-1.4 3.748a.991.991 0 00.266 1.093 1.257 1.257 0 001.117.153l1.98-.989a1 1 0 00.444-.445l3.06-6.12a.25.25 0 01.136-.122l3.57-1.327a2.145 2.145 0 00.706-3.479zM1.577 14.375a1 1 0 00-1 1v7.25a1 1 0 002 0v-7.25a1 1 0 00-1-1zM16.077 19.125H6.952a.625.625 0 110-1.25h2.526a1 1 0 00.92-1.393 2.236 2.236 0 00-2.057-1.357H4.077a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236v-.264a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});