define("ember-svg-jar/inlined/rat-curve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rat-curve</title><path d=\"M19.856 2.268a.99.99 0 00-1.269 1.52 9.247 9.247 0 012.968 10.166 8.857 8.857 0 01-2.133 3.492.5.5 0 01-.779-.616c.429-.695.855-1.366 1.222-1.856.81-1.087.549-1.949.08-2.358a1.5 1.5 0 00-2.024.118 22.749 22.749 0 00-1.513 1.766 3.286 3.286 0 00-3.121-1.506 10.8 10.8 0 01-2.89 1.3.75.75 0 01-.914-.516.742.742 0 01.517-.923 10.969 10.969 0 002.4-1.055A1.238 1.238 0 0011 9.755a.742.742 0 01-.824-1.235 2.721 2.721 0 012.941-.05.5.5 0 00.693-.168 7.291 7.291 0 00.571-4.849.492.492 0 00-.37-.41c-3.281-.816-6.6-.414-8.426.986a4.1 4.1 0 00-2.738-.193C1.384 4.225-.433 5.582.1 7.627a3.111 3.111 0 002.67 2.127c-.061-.008-.108 2.849-.081 3.174.231 2.763 1.714 9.034 10.357 9.034a11.189 11.189 0 0010.438-7.529 11.5 11.5 0 00-3.628-12.165zM1.684 6.279a4.4 4.4 0 012.038.021 5.1 5.1 0 00-.739 1.991A1.649 1.649 0 011.53 7.247a1.33 1.33 0 01.154-.968zm7.031.229a.99.99 0 11.99-.99.99.99 0 01-.99.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});