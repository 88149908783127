define("ember-svg-jar/inlined/photography-equipment-cable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photography-equipment-cable</title><path d=\"M24 15.5A7.508 7.508 0 0016.5 8a1.994 1.994 0 01-1.826-1.2.25.25 0 01.005-.212.254.254 0 01.166-.132A1.5 1.5 0 0016 5V3.5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5V5a1.5 1.5 0 001.355 1.492.251.251 0 01.226.205A3.989 3.989 0 0016.5 10a5.5 5.5 0 01.239 11 .25.25 0 00-.239.25V22a3.278 3.278 0 01-.119.845.123.123 0 00.119.155 7.508 7.508 0 007.5-7.5zM12.5 2h2a1 1 0 000-2h-2a1 1 0 000 2z\"/><path d=\"M15 16a2 2 0 00-2-2h-1.5a.913.913 0 01-.5-.207L9.707 12.5A1.872 1.872 0 008.5 12h-2a1.872 1.872 0 00-1.207.5L4 13.793a.913.913 0 01-.5.207H2a2 2 0 00-2 2v6a2 2 0 002 2h11a2 2 0 002-2zM5 19a2.5 2.5 0 112.5 2.5A2.5 2.5 0 015 19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});