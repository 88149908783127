define("ember-svg-jar/inlined/famous-character-star-wars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-star-wars</title><path d=\"M23.452 4.64L20.04.375A.994.994 0 0019.26 0h-1.205a1 1 0 00-.774 1.633l3.379 4.131a1.5 1.5 0 01.34.95v10.572a1.5 1.5 0 01-.339.95l-3.38 4.131A1 1 0 0018.055 24h1.205a1 1 0 00.781-.375l3.41-4.265A2.494 2.494 0 0024 17.8V6.2a2.5 2.5 0 00-.548-1.56zM3.34 18.236a1.5 1.5 0 01-.34-.95V6.714a1.5 1.5 0 01.34-.95l3.379-4.131A1 1 0 005.945 0H4.74a1 1 0 00-.781.375L.549 4.64A2.494 2.494 0 000 6.2v11.6a2.5 2.5 0 00.548 1.56l3.412 4.265a.994.994 0 00.78.375h1.205a1 1 0 00.774-1.633z\"/><path d=\"M17.235 15.055l2.074-.506a.25.25 0 00.191-.243V9.489a.25.25 0 00-.171-.237l-2.463-.818a.237.237 0 01-.112-.08 5.977 5.977 0 00-9.508 0 .237.237 0 01-.112.08l-2.463.818a.25.25 0 00-.171.237v4.817a.25.25 0 00.191.243l2.074.506a.237.237 0 01.145.106 5.982 5.982 0 0010.18 0 .237.237 0 01.145-.106zM15.4 12.8c-.027.1-.169.248-.306.145L14.1 12.2a1 1 0 10-1.2 1.6s.944.714 1.069.8.023.261-.061.317a3.416 3.416 0 01-3.78.023c-.1-.062-.218-.249-.058-.369l1.03-.771a1 1 0 00-1.2-1.6s-.824.606-.98.735-.3-.067-.328-.189A3.162 3.162 0 018.5 12a3.483 3.483 0 012.222-3.241c.091-.036.278-.056.278.214V10a1 1 0 002 0s-.012-.825 0-1.027a.217.217 0 01.284-.212A3.484 3.484 0 0115.5 12a3.28 3.28 0 01-.1.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});