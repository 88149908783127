define("ember-svg-jar/inlined/monitor-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-refresh</title><path d=\"M11.989 11.939a.25.25 0 00-.163-.439H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v7.308a.25.25 0 00.28.248 7.284 7.284 0 01.867-.056c.2 0 .391.009.583.023a.249.249 0 00.19-.065.252.252 0 00.08-.184V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.147a.125.125 0 00.125-.125 7.849 7.849 0 012.717-5.936z\"/><path d=\"M23.692 11.56a.5.5 0 00-.545.108l-1.3 1.3a.251.251 0 01-.348.005 6.315 6.315 0 00-4.354-1.721A6.375 6.375 0 1022.13 21.6a1 1 0 00-1.563-1.248 4.376 4.376 0 11-.5-5.982.25.25 0 01.009.362l-1.386 1.393a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-4.457a.5.5 0 00-.308-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});