define("ember-svg-jar/inlined/weather-cloud-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-thunder</title><path d=\"M22.513 9.443a.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 8.5a4.491 4.491 0 00-3.517-4.393.248.248 0 01-.171-.136 7 7 0 00-12.988.916.251.251 0 01-.205.173 6.5 6.5 0 00.294 12.914.25.25 0 00.268-.205 2.319 2.319 0 01.511-1.09l.218-.268A.249.249 0 006.72 16H6.5a4.5 4.5 0 11.081-9 1 1 0 00.986-.834 5 5 0 019.647-.831 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169A3 3 0 0119 16h-.958a.251.251 0 00-.244.3 2.338 2.338 0 01-.1 1.355.247.247 0 00.027.231.25.25 0 00.206.109H19a5 5 0 003.513-8.557z\"/><path d=\"M16.281 16.476a.826.826 0 00-.745-.476H14.25a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.888-.316l-5.273 6.461A.822.822 0 008.464 19H9.75a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.487.487 0 00.168.029.5.5 0 00.388-.184l5.273-6.461a.826.826 0 00.12-.879z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});