define("ember-svg-jar/inlined/vintage-car-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-7</title><path d=\"M24 13.48a4.131 4.131 0 00-3.886-4.088.251.251 0 01-.232-.211l-.765-4.839a2.142 2.142 0 00-1.973-1.859H6.852a2.147 2.147 0 00-1.977 1.858l-.764 4.842a.25.25 0 01-.232.21A4.123 4.123 0 000 13.484a4.488 4.488 0 002.839 4.16.251.251 0 01.161.232v2.641a1 1 0 001 1h2a1 1 0 001-1v-2.25a.25.25 0 01.25-.25h1.1a.25.25 0 01.215.122 2.271 2.271 0 001.956 1.127h2.956a2.273 2.273 0 001.956-1.127.25.25 0 01.215-.122h1.1a.25.25 0 01.25.25v2.25a1 1 0 001 1h2a1 1 0 001-1v-2.638a.25.25 0 01.157-.233A4.486 4.486 0 0024 13.484zm-20 1.5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 014 14.983zm8-7a10.116 10.116 0 00-5.491 1.515.25.25 0 01-.384-.248l.719-4.553a.25.25 0 01.247-.211H16.9a.25.25 0 01.247.211l.72 4.555a.25.25 0 01-.384.248A10.1 10.1 0 0012 7.984zm8 7a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.503z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});