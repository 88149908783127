define("ember-svg-jar/inlined/tag-dollar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-dollar-1</title><path d=\"M23.186 16.735a2.785 2.785 0 000-3.933L11.208.814A2.8 2.8 0 009.241 0H2.782A2.779 2.779 0 000 2.783l.007 6.462a2.763 2.763 0 00.814 1.963L12.8 23.188a2.786 2.786 0 003.933 0zM14.8 18.857a1 1 0 01-.707-1.707l1.7-1.7a.842.842 0 00-.761-1.421l-3.066.613a2.843 2.843 0 01-3.1-4.065.252.252 0 00-.046-.29l-.226-.226A1 1 0 1110 8.65l.116.116a.25.25 0 00.353 0l.617-.616A1 1 0 1112.5 9.564l-1.7 1.7a.842.842 0 00.761 1.422l3.067-.613a2.844 2.844 0 013.1 4.065.251.251 0 00.046.29l.226.222a1 1 0 11-1.414 1.414l-.116-.116a.252.252 0 00-.354 0l-.616.617a1 1 0 01-.7.292zM7.3 5.2a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});