define("ember-svg-jar/inlined/messages-bubble-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-double</title><path d=\"M16.3 8.512a9.12 9.12 0 011.37.105.251.251 0 00.285-.218A5.567 5.567 0 0018 7.751C18 3.754 14.075.5 9.25.5S.5 3.754.5 7.751A6.556 6.556 0 002.746 12.6l-1.7 2.978a.5.5 0 00.62.713L6.1 14.516a10.113 10.113 0 001.422.34.25.25 0 00.284-.187C8.655 11.2 12.2 8.512 16.3 8.512z\"/><path d=\"M16.3 10.012c-3.9 0-7.2 2.857-7.2 6.239a6.24 6.24 0 002.958 5.181 7.157 7.157 0 006.642.618l3.585 1.417a.557.557 0 00.676-.743l-.834-2.951a5.175 5.175 0 001.373-3.522c0-3.382-3.3-6.239-7.2-6.239z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});