define("ember-svg-jar/inlined/style-three-pin-gas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-gas</title><path d=\"M10.354 9.146a.5.5 0 00-.708 0l-1.5 1.5A.5.5 0 008 11v1.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V11a.5.5 0 00-.146-.354z\"/><rect x=\"8.5\" y=\"6\" width=\"3\" height=\"1.5\" rx=\".25\" ry=\".25\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6 15.25a.75.75 0 01-1.5 0v-8A1.252 1.252 0 0015.25 6h-2a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h.25A1.5 1.5 0 0115 9v4a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 015 13V9a1.5 1.5 0 011.5-1.5h.25A.25.25 0 007 7.25v-1A.25.25 0 006.75 6h-.5a.75.75 0 010-1.5h9A2.752 2.752 0 0118 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});