define("ember-svg-jar/inlined/notes-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-list</title><path d=\"M23 2a2 2 0 00-2-2H3a2 2 0 00-2 2v16.5a2 2 0 002 2h12.5a1 1 0 00.707-.293l6.5-6.5A1 1 0 0023 13zM3 2.5a.5.5 0 01.5-.5h17a.5.5 0 01.5.5V12a.5.5 0 01-.5.5H17a2 2 0 00-2 2V18a.5.5 0 01-.5.5h-11A.5.5 0 013 18z\"/><path d=\"M16.491 6.085a1.25 1.25 0 10-1.962-1.549L9.5 10.9a.25.25 0 01-.373.022l-1.65-1.649a1.25 1.25 0 00-1.768 1.768l2.05 2.051a2.27 2.27 0 003.357-.2zM21 22H3a1 1 0 000 2h18a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});