define("ember-svg-jar/inlined/history-silk-bug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-silk-bug</title><path d=\"M16.474 14.859A2.009 2.009 0 0016 14.75h-3a.5.5 0 00-.5.5v6a.5.5 0 00.5.5 3.394 3.394 0 001.963-.626.5.5 0 01.787.409V23a1 1 0 002 0v-2.528a3.014 3.014 0 00-.316-1.341l-.881-1.762a.5.5 0 01-.053-.223.1.1 0 01.135-.093 5.666 5.666 0 011.52.712 3.147 3.147 0 01.1 1.244v.491a1 1 0 002 0v-.478c.016-2.762-.563-2.976-3.781-4.163z\"/><circle cx=\"12\" cy=\"3.75\" r=\"3.75\"/><path d=\"M11.5 15.25a.5.5 0 00-.5-.5H8a2.03 2.03 0 00-.474.109c-3.218 1.187-3.8 1.4-3.778 4.163v.478a1 1 0 002 0v-.491a3.147 3.147 0 01.1-1.244 5.666 5.666 0 011.52-.712.1.1 0 01.135.093.5.5 0 01-.053.223l-.881 1.762a3.014 3.014 0 00-.316 1.341V23a1 1 0 002 0v-1.466a.5.5 0 01.787-.409 3.4 3.4 0 001.96.625.5.5 0 00.5-.5zM17.875 13.75a4.375 4.375 0 000-8.75h-.125a1 1 0 000 2h.125a2.375 2.375 0 010 4.75h-1.652a.5.5 0 01-.372-.166 2.494 2.494 0 00-1.129-.723.5.5 0 01-.333-.334 2.494 2.494 0 00-4.778 0 .5.5 0 01-.333.334 2.494 2.494 0 00-1.129.723.5.5 0 01-.372.166H6.125a2.375 2.375 0 010-4.75h.125a1 1 0 000-2h-.125a4.375 4.375 0 000 8.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});