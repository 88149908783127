define("ember-svg-jar/inlined/road-sign-bird-life", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bird-life</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zM19 12.75h-3.432a.248.248 0 00-.2.1A4 4 0 0112 14.25a4 4 0 01-3.37-1.4.248.248 0 00-.2-.1H5a.75.75 0 010-1.5h3.693a1.258 1.258 0 011.066.6c.167.27.72.9 2.241.9s2.074-.633 2.241-.9a1.259 1.259 0 011.066-.6H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});