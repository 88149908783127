define("ember-svg-jar/inlined/american-football-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>american-football-ball</title><ellipse cx=\"4.5\" cy=\"17\" rx=\"4.5\" ry=\"7\"/><path d=\"M23.667 13.756a.5.5 0 01-.167-.373V1a1 1 0 00-2 0v8a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v12.383a.5.5 0 01-.167.373A1 1 0 0011 14.5V19a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-4.5a1 1 0 00-.333-.744.5.5 0 01-.167-.373V12a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1.383a.5.5 0 01-.167.373A1 1 0 0021 14.5V19a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-4.5a1 1 0 00-.333-.744z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});