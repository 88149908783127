define("ember-svg-jar/inlined/cake-birthday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-birthday</title><path d=\"M5.5 5.5a2.257 2.257 0 002.25-2.259A6.131 6.131 0 006.478.451a1.286 1.286 0 00-1.956 0 6.122 6.122 0 00-1.272 2.79A2.257 2.257 0 005.5 5.5zM18.5 5.5a2.257 2.257 0 002.25-2.259 6.131 6.131 0 00-1.272-2.79 1.286 1.286 0 00-1.956 0 6.122 6.122 0 00-1.272 2.79A2.257 2.257 0 0018.5 5.5zM12 5.5a2.257 2.257 0 002.25-2.259 6.131 6.131 0 00-1.272-2.79 1.286 1.286 0 00-1.956 0 6.122 6.122 0 00-1.272 2.79A2.257 2.257 0 0012 5.5zM20.5 11a.5.5 0 01-.5-.5v-3a1 1 0 00-1-1h-1a1 1 0 00-1 1v3a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-3a1 1 0 00-1-1h-1a1 1 0 00-1 1v3a.5.5 0 01-.5.5H7.5a.5.5 0 01-.5-.5v-3a1 1 0 00-1-1H5a1 1 0 00-1 1v3a.5.5 0 01-.5.5A2.5 2.5 0 001 13.5v.359A1.951 1.951 0 002.757 15.8a2.267 2.267 0 002.113-.984 1.319 1.319 0 011.1-.591 1.4 1.4 0 011.179.619 2.253 2.253 0 003.7 0 1.437 1.437 0 012.3 0 2.253 2.253 0 003.7 0 1.383 1.383 0 011.179-.619 1.319 1.319 0 011.1.591 2.267 2.267 0 002.113.984A1.951 1.951 0 0023 13.859V13.5a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M22.317 17.069a3.453 3.453 0 01-.923.224 3.779 3.779 0 01-3.051-1.084.5.5 0 00-.714.007 3.67 3.67 0 01-2.635 1.1 3.746 3.746 0 01-2.641-1.093.5.5 0 00-.706 0 3.75 3.75 0 01-5.295 0 .5.5 0 00-.7 0 3.788 3.788 0 01-3.042 1.075 3.453 3.453 0 01-.923-.224.5.5 0 00-.687.46V21.5A2.5 2.5 0 003.5 24h17a2.5 2.5 0 002.5-2.5v-3.966a.5.5 0 00-.683-.465z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});