define("ember-svg-jar/inlined/style-three-pin-dentist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-dentist</title><path d=\"M12.5 0C6.71 0 2 4.262 2 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.7 21.784 23 15.246 23 9.5 23 4.262 18.29 0 12.5 0zm3.692 11.413a.5.5 0 00-.086.481 6.364 6.364 0 01.171 3.473.951.951 0 01-.947.716.934.934 0 01-.948-.795c-.154-1.263-.963-2.216-1.882-2.216s-1.725.948-1.881 2.206a.952.952 0 01-.965.8.935.935 0 01-.928-.7 6.319 6.319 0 01-.168-1.458 6.218 6.218 0 01.336-2.02.5.5 0 00-.086-.482 4.438 4.438 0 01-.939-2.907 2.838 2.838 0 012.737-2.923 2.937 2.937 0 011.618.475.5.5 0 00.551 0 2.945 2.945 0 011.618-.474 2.839 2.839 0 012.738 2.923 4.438 4.438 0 01-.939 2.901z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});