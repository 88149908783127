define("ember-svg-jar/inlined/museum-exhibition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>museum-exhibition</title><path d=\"M23.445 16.667a7.993 7.993 0 01-2.105-1.516.251.251 0 01-.025-.324 2.25 2.25 0 10-3.63 0 .249.249 0 01-.025.324 8 8 0 01-11.319 0 .251.251 0 01-.025-.325 2.25 2.25 0 10-3.632 0 .248.248 0 01-.025.324 7.965 7.965 0 01-2.1 1.516 1 1 0 10.89 1.791A9.989 9.989 0 003.1 17.425a.248.248 0 01.263-.027.252.252 0 01.139.225V23a1 1 0 002 0v-5.378a.25.25 0 01.4-.2 10 10 0 0012.194 0 .251.251 0 01.4.2V23a1 1 0 002 0v-5.377a.25.25 0 01.4-.2 9.939 9.939 0 001.652 1.033 1 1 0 10.89-1.791zM.762 1.973c.421.1.876.2 1.357.292a1 1 0 01.734.591l1.6 3.752a.9.9 0 00.776.549h.059a.9.9 0 00.78-.452l1.896-3.276a.839.839 0 01.77-.417.815.815 0 01.687.45L11.191 7a.9.9 0 001.618 0l1.77-3.54a.815.815 0 01.687-.45.839.839 0 01.77.417l1.894 3.28a.9.9 0 00.78.452h.059a.9.9 0 00.776-.549l1.6-3.752a1 1 0 01.734-.591c.481-.091.936-.188 1.357-.292A1 1 0 0022.762.03 50.538 50.538 0 0112 1.1 50.538 50.538 0 011.238.03a1 1 0 00-.476 1.943z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});