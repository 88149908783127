define("ember-svg-jar/inlined/underwear-boxers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-boxers</title><path d=\"M14.25 13.945V8.2a.25.25 0 00-.25-.25h-4a.25.25 0 00-.25.25v5.75a2.25 2.25 0 004.5 0zM22.166 6.445a.5.5 0 00.5-.555l-.166-1.5a.5.5 0 00-.5-.445H1.5a.5.5 0 00-.5.463l-.11 1.5a.5.5 0 00.5.537z\"/><path d=\"M8.25 13.945V8.2A.25.25 0 008 7.945H1.205a.5.5 0 00-.5.463l-.7 9.478a1 1 0 00.275.813.958.958 0 00.746.3l8.624 1.017a1 1 0 00.838-.147.991.991 0 00.422-.721l.111-1.171a.5.5 0 00-.324-.516 3.755 3.755 0 01-2.447-3.516zM23.993 17.89l-1.053-9.5a.5.5 0 00-.5-.445H16a.25.25 0 00-.25.25v5.75a3.755 3.755 0 01-2.45 3.516.5.5 0 00-.324.516l.11 1.17a1 1 0 00.423.722.948.948 0 00.767.16L22.972 19a.962.962 0 00.745-.3.992.992 0 00.276-.81z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});