define("ember-svg-jar/inlined/project-plan-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-plan-pen</title><path d=\"M7.324 12.118a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1a.5.5 0 01.5.5v4.5a.5.5 0 00.5.5H5a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V19a.5.5 0 01.5-.5h1a.5.5 0 00.324-.882zM6.251 10.25h5.5a1 1 0 000-2h-5.5a1 1 0 000 2zM23.708 1.586L22.415.293a1 1 0 00-1.415 0l-4.353 4.353a.5.5 0 000 .707l2 2a.5.5 0 00.707 0L23.708 3a1 1 0 000-1.414zM15.854 6.146a.492.492 0 00-.469-.133.5.5 0 00-.359.329l-1 3a.5.5 0 00.633.632l3-1a.5.5 0 00.2-.828zM1.751 10.25h1.5a1 1 0 100-2h-1.5a1 1 0 100 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});