define("ember-svg-jar/inlined/cash-payment-sign-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-sign-2</title><path d=\"M24 9.331a1.5 1.5 0 00-1.5-1.5h-6.568a.249.249 0 00-.177.073l-6.341 6.341a2 2 0 01-1.021.547 20.477 20.477 0 01-2.893.539 2 2 0 01-1.961-2.393l.5-2.5a2 2 0 01.547-1.021l1.159-1.159a.25.25 0 00-.177-.427H1.5a1.5 1.5 0 00-1.5 1.5v11a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5zm-11.5 7.75h8a.75.75 0 010 1.5h-8a.75.75 0 010-1.5z\"/><path d=\"M5.646 10.477a.492.492 0 00-.136.256l-.5 2.5a.5.5 0 00.49.6.473.473 0 00.1-.01l2.5-.5a.491.491 0 00.256-.137l7.75-7.75A1.914 1.914 0 0013.4 2.727z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});