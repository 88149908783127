define("ember-svg-jar/inlined/nuclear-energy-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nuclear-energy-truck</title><circle cx=\"5\" cy=\"19.25\" r=\"2\"/><path d=\"M22.5 2.75H11a1.5 1.5 0 00-1.5 1.5V5.5A.5.5 0 019 6H5.25a1 1 0 00-.886.536l-2.3 4.386a2.007 2.007 0 01-.455.577L.256 12.686A.746.746 0 000 13.25v5.25a.75.75 0 00.75.75h.785a.5.5 0 00.495-.429 3 3 0 015.94 0 .5.5 0 00.5.429h7.07a.5.5 0 00.5-.429 3 3 0 015.94 0 .5.5 0 00.5.429h1.02a.5.5 0 00.5-.5V4.25a1.5 1.5 0 00-1.5-1.5zm-13 7.5a.5.5 0 01-.5.5H5.241a.5.5 0 01-.443-.732l.917-1.75A.5.5 0 016.157 8H9a.5.5 0 01.5.5zm7.228-2.105a1.105 1.105 0 11-1.106 1.105 1.107 1.107 0 011.106-1.105zM12.255 9.25a.5.5 0 01-.5-.556 4.988 4.988 0 012.415-3.728.5.5 0 01.4-.049.506.506 0 01.307.271l.578 1.273a.5.5 0 01-.177.622 2.615 2.615 0 00-1.123 1.747.5.5 0 01-.492.42zm7.519 3.743a.5.5 0 01-.208.349 4.888 4.888 0 01-5.676 0 .5.5 0 01-.09-.738l.912-1.045a.5.5 0 01.641-.1 2.6 2.6 0 002.749 0 .5.5 0 01.641.1l.912 1.046a.494.494 0 01.119.388zm1.8-3.911a.5.5 0 01-.373.167h-1.409a.5.5 0 01-.492-.42 2.613 2.613 0 00-1.128-1.746.5.5 0 01-.178-.622l.579-1.274a.5.5 0 01.713-.222A4.989 4.989 0 0121.7 8.693a.5.5 0 01-.127.389z\"/><circle cx=\"19\" cy=\"19.25\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});