define("ember-svg-jar/inlined/color-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-palette</title><path d=\"M20.587 8.706a.22.22 0 01-.087-.184V8.5a8.5 8.5 0 00-17 0v.01a.248.248 0 01-.1.205 8.487 8.487 0 008.5 14.567.251.251 0 01.2 0 8.488 8.488 0 008.487-14.576zm-8.446 12.17a.254.254 0 01-.282 0A6.507 6.507 0 019.16 16.9a.251.251 0 01.319-.293 8.3 8.3 0 005.042 0 .251.251 0 01.319.293 6.507 6.507 0 01-2.699 3.976zm2.707-6.755a.251.251 0 01-.14.28 6.421 6.421 0 01-5.416 0 .25.25 0 01-.14-.28 6.516 6.516 0 012.707-4 .254.254 0 01.282 0 6.516 6.516 0 012.707 4zM7.5 12.649a.25.25 0 01-.425.079 6.5 6.5 0 01-1.423-2.849.251.251 0 01.14-.28 6.4 6.4 0 013.579-.539.25.25 0 01.142.425A8.5 8.5 0 007.5 12.649zm6.992-3.164a.251.251 0 01.142-.425 6.4 6.4 0 013.579.539.25.25 0 01.14.28 6.5 6.5 0 01-1.418 2.849.25.25 0 01-.425-.079 8.5 8.5 0 00-2.023-3.164zM12 2a6.505 6.505 0 016.34 5.1.251.251 0 01-.319.293 8.364 8.364 0 00-5.921.326.251.251 0 01-.2 0 8.361 8.361 0 00-5.92-.326.251.251 0 01-.32-.293A6.505 6.505 0 0112 2zM8.5 22a6.494 6.494 0 01-4.93-10.728.25.25 0 01.43.079 8.528 8.528 0 002.9 3.934.248.248 0 01.1.205v.01a8.456 8.456 0 002.513 6.015.251.251 0 01-.142.425A6.424 6.424 0 018.5 22zm7 0a6.424 6.424 0 01-.871-.06.25.25 0 01-.142-.425A8.456 8.456 0 0017 15.5v-.01a.248.248 0 01.1-.205 8.528 8.528 0 002.906-3.934.25.25 0 01.425-.079A6.494 6.494 0 0115.5 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});