define("ember-svg-jar/inlined/people-woman-glasses-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-glasses-4</title><path d=\"M18.575 5.348a.25.25 0 00-.244-.193H5.5a.017.017 0 00-.019.013l-.019-.021a3.535 3.535 0 00-3.267-1.28.529.529 0 00-.323.191.5.5 0 00-.109.333 3.482 3.482 0 001.405 2.65.251.251 0 010 .4 3.484 3.484 0 00-1.405 2.65.538.538 0 00.117.343.5.5 0 00.315.182 3.507 3.507 0 002.653-.681.25.25 0 01.4.2v5.9a.25.25 0 00.315.242 16.347 16.347 0 014.277-.551h4.316a16.347 16.347 0 014.277.551.25.25 0 00.315-.242v-9.13a6.7 6.7 0 00-.173-1.557zM14.75 7.405a1 1 0 11-1 1 1 1 0 011-1zm-3.5-.928a5.825 5.825 0 00.583-.437.25.25 0 01.325 0 5.915 5.915 0 00.583.438.249.249 0 01.058.363 2.52 2.52 0 00-.369.654.251.251 0 01-.233.16h-.4a.251.251 0 01-.233-.16 2.512 2.512 0 00-.37-.655.249.249 0 01.058-.363zm-2 .928a1 1 0 11-1 1 1 1 0 011-1zm2.75 6.75A5.256 5.256 0 016.748 8.9a.025.025 0 01.025-.021.024.024 0 01.027.021 2.491 2.491 0 004.766.409.251.251 0 01.233-.158h.4a.251.251 0 01.233.158A2.491 2.491 0 0017.2 8.9a.024.024 0 01.025-.02.025.025 0 01.025.021A5.256 5.256 0 0112 14.155zM22.534 19.322a12.229 12.229 0 00-2.634-1.481.251.251 0 00-.252.033L12.462 23.5a.751.751 0 01-.934-.008L4.515 17.81a.252.252 0 00-.252-.037 12.34 12.34 0 00-2.8 1.549A2.465 2.465 0 00.5 21.3v2.107a.5.5 0 00.5.5h22a.5.5 0 00.5-.5V21.3a2.465 2.465 0 00-.966-1.978z\"/><path d=\"M11.853 21.821a.251.251 0 00.312 0l5.515-4.316a.25.25 0 00-.091-.439 17.99 17.99 0 00-1.533-.333.5.5 0 00-.4.1l-3.5 2.8a.25.25 0 01-.312 0l-3.5-2.8a.5.5 0 00-.4-.1c-.49.084-.944.185-1.375.294a.25.25 0 00-.1.437zM10.38 12.019a.64.64 0 00.316.719 2.312 2.312 0 00.435.179 2.989 2.989 0 00.869.127 3.023 3.023 0 00.867-.125 2.274 2.274 0 00.44-.183.639.639 0 00-.307-1.2h-2a.64.64 0 00-.62.483zM17.511 3.655a.251.251 0 00.219-.129.249.249 0 00-.007-.254 6.738 6.738 0 00-11.44 0 .251.251 0 00.213.383z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});