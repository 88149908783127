define("ember-svg-jar/inlined/bird-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-house</title><path d=\"M12.345 5.215a.5.5 0 00-.69 0l-9.026 8.614a1 1 0 00-.29.919L3.7 21.589A3.011 3.011 0 006.64 24h10.72a3.013 3.013 0 002.94-2.414l1.36-6.838a1 1 0 00-.29-.919zM12 12.77A3.232 3.232 0 118.768 16 3.231 3.231 0 0112 12.77z\"/><path d=\"M2.52 11.6l9.135-8.462a.505.505 0 01.706.016L21.48 11.6a1.486 1.486 0 001.962.057 1.587 1.587 0 00.159-.138A1.5 1.5 0 0023.52 9.4L14.42.97A3.49 3.49 0 009.6.956L.48 9.4a1.5 1.5 0 00-.08 2.122 1.478 1.478 0 00.173.124A1.486 1.486 0 002.52 11.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});