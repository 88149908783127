define("ember-svg-jar/inlined/parrot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parrot-1</title><path d=\"M23.924 22.91c-1.534-7.085-5.084-12.282-9.783-14.36a6.193 6.193 0 00.392-2.178A6.471 6.471 0 007.982 0 6.139 6.139 0 003 2.522a3.193 3.193 0 00-.344 2.969A4.089 4.089 0 001.4 12.32c.661.614 2.343 1.62 3.166.8a1 1 0 00.284-.812s-.03-.2-.03-.244c0-.106.267-.243.556-.367.185 2.4.983 5.925 3.855 8.179l-1.068 2.771a.989.989 0 101.847.709l.936-2.426.121.057v2.024a.99.99 0 001.979 0v-1.348a14.178 14.178 0 002.663.364 13.848 13.848 0 01-2.241-6.716.742.742 0 111.479-.125 11.6 11.6 0 003.309 7.564 16.481 16.481 0 005.185.759.5.5 0 00.484-.6zM5.041 9.7a3.479 3.479 0 00-1.985 1.419 2.125 2.125 0 01-1.012-1.8 2.171 2.171 0 012.193-2.154c.77 0 1.584.223 1.783 1.875.023.181-.009.278-.979.66zm2.551.681c.864-1.129.209-3.28-.8-4.248a3.823 3.823 0 00-2.967-.924 4.226 4.226 0 013.662-2.241c2.279 0 3.751 1.36 3.751 3.463a3.964 3.964 0 01-3.646 3.946z\"/><path d=\"M7.806 3.673a.98.98 0 10.989.98.985.985 0 00-.989-.98zm0 .99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});