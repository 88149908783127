define("ember-svg-jar/inlined/chef-gear-biscuits-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-biscuits-cup</title><path d=\"M17.1 1.024a3 3 0 00-3.789 1.908l-.931 2.82a.25.25 0 01-.475 0L10.69 2.071a3 3 0 10-5.7 1.861l2.031 6.223a.5.5 0 00.475.345h9.274a.5.5 0 00.475-.343l1.765-5.344a3 3 0 00-1.91-3.789zM9.766 8.915a.749.749 0 01-.945-.481L6.97 2.758a.749.749 0 111.43-.465l1.852 5.676a.75.75 0 01-.486.946zm7.264-5.282l-1.615 4.891a.749.749 0 01-.712.515.741.741 0 01-.235-.039.749.749 0 01-.477-.947l1.615-4.891a.75.75 0 011.424.47zM18.44 12H5.734a.5.5 0 00-.483.626l2.78 9.914A2 2 0 009.956 24H14.4a2 2 0 001.934-1.492l2.593-9.882A.5.5 0 0018.44 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});