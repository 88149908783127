define("ember-svg-jar/inlined/famous-people-man-charlie-chaplin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-people-man-charlie-chaplin</title><path d=\"M6.5 16.868a.244.244 0 00-.267-.11 13.583 13.583 0 00-5.041 2.263 2.408 2.408 0 00-.947 1.936v2.063a.49.49 0 00.49.49h9.729a.245.245 0 00.2-.381c-3.193-4.794-3.972-5.947-4.164-6.261zM20.571 18.349a.244.244 0 00-.128-.215 15.051 15.051 0 00-3.656-1.376.245.245 0 00-.267.109c-.193.316-.974 1.473-4.167 6.262a.244.244 0 00.2.381h7.771a.244.244 0 00.244-.245z\"/><circle cx=\"13.469\" cy=\"8.816\" r=\".98\"/><circle cx=\"9.551\" cy=\"8.816\" r=\".98\"/><path d=\"M19.213 5.155c-.353.066-.545.094-.8.134a.246.246 0 01-.275-.179 6.85 6.85 0 00-13.25 0 .245.245 0 01-.274.179c-.261-.041-.449-.068-.8-.134a.711.711 0 00-.826.593.762.762 0 00.616.863c.327.061.656.109.983.161a.087.087 0 01.073.085v1.959a6.857 6.857 0 0013.714 0V6.857a.087.087 0 01.073-.085c.328-.052.656-.1.983-.161a.763.763 0 00.617-.863.712.712 0 00-.834-.593zm-2.8 3.661a4.9 4.9 0 01-9.8 0V7.33a.246.246 0 01.082-.183.25.25 0 01.191-.061 41.25 41.25 0 009.251 0 .246.246 0 01.272.243zM11.065 16.448l-1.446 3.133a.247.247 0 00.019.238l1.668 2.5a.245.245 0 00.408 0l1.668-2.5a.241.241 0 00.019-.238l-1.446-3.133a.511.511 0 00-.89 0z\"/><path d=\"M20.571 10.286a3.187 3.187 0 00-3.183 3.183v.731a.98.98 0 101.959 0v-.735a1.225 1.225 0 012.449 0v9.555a1.009 1.009 0 00.98.98.98.98 0 00.979-.98v-9.551a3.187 3.187 0 00-3.184-3.183zM13.072 11.146a.489.489 0 00-.475-.37h-2.174a.49.49 0 00-.475.37l-.245.98a.49.49 0 00.475.609h2.664a.49.49 0 00.475-.609z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});