define("@gavant/ember-shopify-draggable/mixins/base-group", ["exports", "@ember/object/mixin", "@ember/object/evented", "@ember/object", "@ember/runloop", "@ember/utils", "@ember/array"], function (_exports, _mixin, _evented, _object, _runloop, _utils, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create(_evented.default, {
    shopifyInstance: null,
    //plugins
    resizeMirror: false,
    snappable: false,
    swapAnimation: false,
    collidable: false,
    // Draggable options
    // https://github.com/Shopify/draggable/tree/master/src/Draggable#options
    delay: 100,
    handle: null,
    //Draggable Mirror options
    //https://github.com/Shopify/draggable/tree/master/src/Draggable/Plugins/Mirror#options
    mirrorOptions: Object.freeze({
      constrainDimensions: true
    }),
    //SwapAnimation options
    //https://github.com/Shopify/draggable/tree/master/src/Plugins/SwapAnimation#options
    swapAnimationOptions: Object.freeze({
      duration: 150,
      easingFunction: 'ease-in-out',
      horizontal: false
    }),
    //Collidable options
    //https://github.com/Shopify/draggable/tree/master/src/Plugins/Collidable#options
    collidables: null,
    dragEvents: (0, _array.A)(['start', 'move', 'over', 'out', 'stop', 'pressure']),
    snapEvents: (0, _array.A)(['in', 'out']),
    collidableEvents: (0, _array.A)(['in', 'out']),
    resizeMirrorDidChange: (0, _object.observer)('resizeMirror', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, 'togglePlugin', 'ResizeMirror', (0, _object.get)(this, 'resizeMirror'));
    }),
    snappableDidChange: (0, _object.observer)('snappable', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, 'togglePlugin', 'Snappable', (0, _object.get)(this, 'snappable'));
    }),
    collidableDidChange: (0, _object.observer)('collidable', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, 'togglePlugin', 'Collidable', (0, _object.get)(this, 'collidable'));
    }),
    swapAnimationDidChange: (0, _object.observer)('swapAnimation', function () {
      (0, _runloop.scheduleOnce)('afterRender', this, 'togglePlugin', 'SwapAnimation', (0, _object.get)(this, 'swapAnimation'));
    }),
    togglePlugin(plugin, enabled) {
      return (0, _object.get)(this, 'shopifyInstance')[`${enabled ? 'add' : 'remove'}Plugin`]((0, _object.get)(this, `plugins.${plugin}`));
    },
    initializePrivateEventListeners() {
      const shopifyInstance = (0, _object.get)(this, 'shopifyInstance');
      (0, _object.get)(this, '_events').forEach(eventName => {
        shopifyInstance.on(eventName, (0, _runloop.bind)(this, 'trigger', eventName));
      });
    },
    initializePublicEventListeners() {
      this.bindEventListenersType('drag');
      this.bindEventListenersType('snap');
      this.bindEventListenersType('collidable');
    },
    bindEventListenersType(namespace) {
      const shopifyInstance = (0, _object.get)(this, 'shopifyInstance');
      (0, _object.get)(this, `${namespace}Events`).forEach(eventName => {
        shopifyInstance.on(`${namespace}:${eventName}`, (0, _runloop.bind)(this, 'fireAction', namespace, eventName));
      });
    },
    fireAction(namespace, eventName, event) {
      const actions = (0, _object.get)(this, `${namespace}Actions`);
      (0, _utils.tryInvoke)(actions, eventName, [event]);
    },
    willDestroyElement() {
      if ((0, _object.get)(this, 'shopifyInstance')) {
        (0, _object.get)(this, 'shopifyInstance').destroy();
      }
      this._super(...arguments);
    }
  });
});