define("ember-svg-jar/inlined/vintage-woman-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-woman-3</title><path d=\"M2.979 15.005a.5.5 0 00-.231.789 5.055 5.055 0 003.54 1.7 2.128 2.128 0 001.981-1.109 6.6 6.6 0 003.865 1.861A6.607 6.607 0 0016 16.386a2.13 2.13 0 001.987 1.114 5.053 5.053 0 003.54-1.7.5.5 0 00-.231-.789c-1.23-.408-1.872-1.838-2.206-3.128a3.15 3.15 0 00.294-1.239 1.887 1.887 0 00-.933-1.808.75.75 0 10-.672 1.341.618.618 0 01.105.439 1.281 1.281 0 01-.557 1.1 1.754 1.754 0 00-1 1.3c-.375 2.249-3.162 3.738-4.191 3.738s-3.816-1.489-4.192-3.737a1.744 1.744 0 00-1-1.306 1.285 1.285 0 01-.556-1.1.632.632 0 01.1-.439.75.75 0 00-.672-1.342 1.886 1.886 0 00-.931 1.809 3.143 3.143 0 00.3 1.25c-.337 1.287-.98 2.711-2.206 3.116z\"/><path d=\"M11.247 13.329a.75.75 0 00-.721 1.316 3.431 3.431 0 003.064 0 .75.75 0 10-.715-1.318 1.967 1.967 0 01-1.628.002zM8.436 9.968a.75.75 0 101 1.114.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.627 0zM14.005 11.082a.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114zM23.847 23.375a4.491 4.491 0 00-2.757-3.084l-4.4-1.755a.5.5 0 00-.684.419l-.409 4.5a.5.5 0 00.5.545h7.271a.5.5 0 00.485-.625zM12.464 20.565a.52.52 0 00-.928 0l-1.1 2.749A.5.5 0 0010.9 24h2.2a.5.5 0 00.463-.686zM7.762 18.574a.5.5 0 00-.448-.038L2.92 20.287a4.5 4.5 0 00-2.767 3.088.5.5 0 00.485.625h7.271a.5.5 0 00.5-.545L8 18.955a.5.5 0 00-.238-.381zM12.138 8.791c1.1 0 10.75-.133 10.75-4.75a.75.75 0 10-1.5 0c0 1.174-1.346 1.944-3.053 2.439l-1.628-5.152A2 2 0 0013.831.265l-1.2.686a1.008 1.008 0 01-.992 0l-1.2-.686a2 2 0 00-2.884 1.081L5.933 6.478c-1.7-.5-3.045-1.265-3.045-2.437a.75.75 0 00-1.5 0c0 4.617 9.649 4.75 10.75 4.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});