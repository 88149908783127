define("ember-svg-jar/inlined/database-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-1</title><path d=\"M11.011 19.773v1.987a.25.25 0 01-.25.25H5.574a.989.989 0 100 1.977h12.852a.989.989 0 100-1.977h-5.187a.25.25 0 01-.25-.25v-1.986a.975.975 0 00-.958-.978h-.02a.982.982 0 00-1 .977zM20.9 23a.988.988 0 00.989.988h.989a.989.989 0 100-1.977h-.989A.989.989 0 0020.9 23zM1.125 23.987h.989a.989.989 0 100-1.977h-.989a.989.989 0 100 1.977zM21.639 11.669a.249.249 0 00-.407-.194c-1.917 1.557-5.523 2.379-8.985 2.379s-7.068-.822-8.984-2.379a.25.25 0 00-.408.194v2.185c0 2.185 4.206 3.955 9.392 3.955s9.392-1.77 9.392-3.955z\"/><path d=\"M12.248 8.911c-3.462 0-7.069-.822-8.985-2.38a.25.25 0 00-.408.194v2.186c0 2.185 4.206 3.955 9.392 3.955s9.392-1.77 9.392-3.955V6.726a.249.249 0 00-.407-.193c-1.917 1.556-5.523 2.378-8.984 2.378z\"/><ellipse cx=\"12.248\" cy=\"3.968\" rx=\"9.392\" ry=\"3.955\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});