define("ember-svg-jar/inlined/common-file-double-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-double-2</title><path d=\"M21.941 3.607L18.87.554A1.912 1.912 0 0017.527 0H8.9A1.9 1.9 0 007 1.9V5H3.4a1.9 1.9 0 00-1.9 1.9v15.21A1.9 1.9 0 003.4 24h11.7a1.9 1.9 0 001.9-1.895V19h3.6a1.9 1.9 0 001.9-1.895V4.949a1.882 1.882 0 00-.559-1.342zM14.5 22H4a.5.5 0 01-.5-.5v-14A.5.5 0 014 7h7.784a.5.5 0 01.35.143L14.85 9.8a.5.5 0 01.15.357V21.5a.5.5 0 01-.5.5zm5.5-5h-2.75a.25.25 0 01-.25-.25v-6.8a1.892 1.892 0 00-.558-1.341L13.37 5.554A1.9 1.9 0 0012.028 5H9.25A.25.25 0 019 4.75V2.5a.5.5 0 01.5-.5l7.756-.026a.5.5 0 01.351.143L20.35 4.8a.5.5 0 01.15.357V16.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});