define("ember-svg-jar/inlined/single-woman-statis-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-statis-2</title><path d=\"M22 .015H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2v-12a2 2 0 00-2-2z\"/><rect x=\"13\" y=\"7.515\" width=\"2.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"17\" y=\"4.015\" width=\"2.5\" height=\"8\" rx=\".5\" ry=\".5\"/><path d=\"M11.982 17.853l-1.8-6.286a4.263 4.263 0 00-1.238-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.319a.251.251 0 01.246.2L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.248 2.248 0 01-2.16-1.64.25.25 0 01.16-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.159.3 2.246 2.246 0 01-2.158 1.643zM22 .015H11.5a1 1 0 000 2h10a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-7.75a1 1 0 000 2H22a2 2 0 002-2v-12a2 2 0 00-2-2z\"/><rect x=\"13\" y=\"7.515\" width=\"2.5\" height=\"4.5\" rx=\".5\" ry=\".5\"/><rect x=\"17\" y=\"4.015\" width=\"2.5\" height=\"8\" rx=\".5\" ry=\".5\"/><path d=\"M11.982 17.853l-1.8-6.286a4.263 4.263 0 00-1.238-2.125.5.5 0 00-.764.1L6.425 12.3a.5.5 0 01-.846 0l-1.74-2.775a.5.5 0 00-.763-.1 4.226 4.226 0 00-1.254 2.13l-1.8 6.3a.5.5 0 00.482.632h2.319a.251.251 0 01.246.2L4 23.588a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.515a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.248 2.248 0 01-2.16-1.64.25.25 0 01.16-.3A4.68 4.68 0 005.8 3.432a.25.25 0 01.357 0 4.693 4.693 0 001.821 1.14.249.249 0 01.159.3 2.246 2.246 0 01-2.158 1.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});