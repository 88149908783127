define("ember-svg-jar/inlined/startup-launch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>startup-launch</title><path d=\"M1.5 23.5a.5.5 0 00.5.5h18.5a.5.5 0 00.5-.5 2.926 2.926 0 00-2.781-2.992.25.25 0 01-.231-.22A2.353 2.353 0 0015.5 18.5h-.8a.251.251 0 00-.238.173 10.083 10.083 0 01-2.13 3.362 1.5 1.5 0 01-2.17 0 10.083 10.083 0 01-2.13-3.362.251.251 0 00-.232-.173H7a2.362 2.362 0 00-2.479 1.8.251.251 0 01-.231.212A2.927 2.927 0 001.5 23.5z\"/><path d=\"M11.25 15.478a1.887 1.887 0 00-1.945 1.958c0 1.231 1.268 2.8 1.757 3.356a.25.25 0 00.376 0c.488-.557 1.757-2.124 1.757-3.356a1.887 1.887 0 00-1.945-1.958z\"/><path d=\"M13.646 2.492a8.071 8.071 0 00-1.272-.721.25.25 0 01-.136-.273.735.735 0 00.014-.142V1a1 1 0 00-2 0v.356a.735.735 0 00.014.142.25.25 0 01-.136.273 8.009 8.009 0 00-1.271.721 3.5 3.5 0 00-1.2 2.987v.01l1.128 5.13a.251.251 0 01-.155.287l-.156.06a2.263 2.263 0 00-1.446 2.179c.08 2.774.087 2.575 2.543 1.015h3.35l1.628 1.035a.566.566 0 00.869-.462l.046-1.587a2.264 2.264 0 00-1.451-2.179l-.156-.06a.25.25 0 01-.154-.287c.069-.314 1.051-4.827 1.135-5.14a3.5 3.5 0 00-1.194-2.988zM11.252 6.68a1.287 1.287 0 111.287-1.286 1.286 1.286 0 01-1.287 1.286zM21.3 12H23a1 1 0 00.184-1.982.253.253 0 01-.2-.2A1 1 0 0021 10v.018a.016.016 0 01-.013.015 2.3 2.3 0 00-.962 4.186l1.84 1.226A.3.3 0 0121.7 16H20a1 1 0 00-.183 1.983.252.252 0 01.2.2 1 1 0 001.97-.039.25.25 0 01.2-.207 2.3 2.3 0 00.793-4.157l-1.84-1.226A.3.3 0 0121.3 12zM2.7 7H1a1 1 0 00-.183 1.983.252.252 0 01.2.2 1 1 0 001.97-.039.25.25 0 01.195-.207 2.3 2.3 0 00.793-4.157l-1.84-1.225A.3.3 0 012.3 3H4a1 1 0 00.184-1.982.253.253 0 01-.2-.2A1 1 0 002 1v.018a.016.016 0 01-.013.015 2.3 2.3 0 00-.962 4.186l1.84 1.226A.3.3 0 012.7 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});