define("ember-svg-jar/inlined/truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>truck</title><path d=\"M22.65 2.5a3 3 0 00-3 3v2.951a.25.25 0 01-.084.187A.481.481 0 0019.4 9v3.75a.25.25 0 01-.25.25h-.4a.25.25 0 01-.25-.25V3.5A2.5 2.5 0 0016 1H8a2.5 2.5 0 00-2.5 2.5v9.25a.25.25 0 01-.25.25h-.4a.25.25 0 01-.25-.25V9a.481.481 0 00-.166-.362.252.252 0 01-.084-.187V5.5a3 3 0 00-3-3 1 1 0 000 2 1 1 0 011 1v2.951a.25.25 0 01-.084.187A.479.479 0 002.1 9v4.805a.248.248 0 01-.054.155A2.469 2.469 0 001.5 15.5v3A1.5 1.5 0 003 20h.233a.249.249 0 01.25.25V22a1 1 0 001 1H6.5a1 1 0 001-1v-1.75a.25.25 0 01.25-.25h8.5a.249.249 0 01.25.25V22a1 1 0 001 1h1.981a1 1 0 001-1v-1.75a.25.25 0 01.25-.25H21a1.5 1.5 0 001.5-1.5v-3a2.469 2.469 0 00-.546-1.54.25.25 0 01-.054-.155V9a.482.482 0 00-.166-.362.25.25 0 01-.084-.187V5.5a1 1 0 011-1 1 1 0 000-2zM7.5 8V5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zm8.733 9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h7.5a.5.5 0 01.5.5zm.267-9a.5.5 0 01-.5.5h-2.5A.5.5 0 0113 8V5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5z\"/><path d=\"M9.484 12.25a.75.75 0 00-.75.75v3a.75.75 0 001.5 0v-3a.75.75 0 00-.75-.75zM11.984 12.25a.75.75 0 00-.75.75v3a.75.75 0 001.5 0v-3a.75.75 0 00-.75-.75zM14.4 12.25a.75.75 0 00-.75.75v3a.75.75 0 001.5 0v-3a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});