define("ember-svg-jar/inlined/phone-action-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-home</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M16.4 11.067l-4.212-3.285a.293.293 0 00-.376 0L7.6 11.067a.284.284 0 00-.085.317.291.291 0 00.273.187h.995v3a.428.428 0 00.431.429H10.5a.428.428 0 00.429-.429V13.5a1.071 1.071 0 012.142 0v1.071A.428.428 0 0013.5 15h1.286a.43.43 0 00.428-.429v-3h.995a.291.291 0 00.273-.187.284.284 0 00-.082-.317z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});