define("ember-svg-jar/inlined/insect-mantis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-mantis</title><path d=\"M23.74 3.281C21.251.542 17.9-.643 15.239.452a4.981 4.981 0 00-2.979 4.714h-.523a5.012 5.012 0 00-3-4.689C6.077-.617 2.737.555.26 3.28a1 1 0 001.48 1.346c1.89-2.08 4.369-3.068 6.239-2.3a2.721 2.721 0 011.615 1.93A3.747 3.747 0 107 10.7a3.692 3.692 0 00.928-.13l.985.965a2.489 2.489 0 001.587 4.418 1 1 0 000-2 .5.5 0 010-1 .98.98 0 00.372-.075 2.445 2.445 0 001.128.327 2.549 2.549 0 001.127-.327.989.989 0 00.373.075.5.5 0 010 1 1 1 0 000 2 2.489 2.489 0 001.586-4.416l.984-.964a3.708 3.708 0 00.93.13 3.75 3.75 0 10-2.622-6.423A2.709 2.709 0 0116 2.3c1.6-.659 4.1-.049 6.26 2.324a1 1 0 101.48-1.344zM7 8.7a1.75 1.75 0 111.75-1.75A1.749 1.749 0 017 8.7zm10-3.5a1.75 1.75 0 11-1.75 1.75A1.75 1.75 0 0117 5.2zM4.161 13.62C2.4 13.62.579 14.629.579 18.46c0 2.72 2.167 5.493 2.767 5.493a.488.488 0 00.509-.455 14.528 14.528 0 01-.731-4.128c0-.718.042-1.823 1-1.823.665 0 2.746 2.051 3.888 3.48L9.6 23.015a.683.683 0 001.124-.769C8.77 18.948 6.5 13.62 4.161 13.62zM19.839 13.62c-2.334 0-4.609 5.328-6.565 8.626a.683.683 0 001.124.769l1.591-1.988c1.142-1.429 3.223-3.48 3.888-3.48.957 0 1 1.105 1 1.823a14.528 14.528 0 01-.731 4.128.488.488 0 00.509.455c.6 0 2.767-2.773 2.767-5.493-.001-3.831-1.822-4.84-3.583-4.84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});