define("ember-svg-jar/inlined/single-neutral-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-search</title><path d=\"M23.414 23.414a2 2 0 000-2.828l-4.069-4.069a.251.251 0 01-.033-.313 10.508 10.508 0 10-3.112 3.108.249.249 0 01.313.033l4.069 4.069a2 2 0 002.832 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M5 15.268h11a.5.5 0 00.385-.181.5.5 0 00.106-.41A5.282 5.282 0 0016 13.2c-.453-.876-1.591-1.415-3.652-2.151a.977.977 0 01-.027-.7 5 5 0 001.148-3.529A2.881 2.881 0 0010.5 3.768 2.881 2.881 0 007.531 6.82a4.979 4.979 0 001.13 3.511 1 1 0 01.007.71C6.592 11.785 5.454 12.324 5 13.2a5.282 5.282 0 00-.492 1.477.5.5 0 00.106.41.5.5 0 00.386.181z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});