define("ember-svg-jar/inlined/earth-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-block</title><path d=\"M10.7 21.651a.491.491 0 00-.334-.217 9.748 9.748 0 01-2.9-.934v-1.279a2.444 2.444 0 01.874-1.878 4.409 4.409 0 00-2.832-7.788H2.337a9.783 9.783 0 0115.068-5.88h-3.322a2.7 2.7 0 000 5.39 2.515 2.515 0 011.491.5.5.5 0 00.374.089 7.805 7.805 0 017.027 2.364.383.383 0 00.663-.262A11.76 11.76 0 107.312 22.6l.156.078v-.021a11.683 11.683 0 004.41.866.189.189 0 00.127-.329 7.928 7.928 0 01-1.305-1.543z\"/><path d=\"M17.267 10.77a6.615 6.615 0 106.615 6.615 6.623 6.623 0 00-6.615-6.615zm-3.876 8.809a.493.493 0 01-.359-.279 4.576 4.576 0 01-.42-1.915 4.66 4.66 0 014.655-4.655 4.581 4.581 0 011.916.42.49.49 0 01.142.792l-5.5 5.5a.492.492 0 01-.434.137zm3.876 2.461a4.58 4.58 0 01-1.915-.42.487.487 0 01-.278-.359.493.493 0 01.136-.433l5.5-5.5a.49.49 0 01.792.142 4.591 4.591 0 01.419 1.915 4.66 4.66 0 01-4.654 4.655z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});