define("ember-svg-jar/inlined/road-sign-no-entry-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-entry-1</title><path d=\"M22.016 5.409a.255.255 0 00-.184-.111.249.249 0 00-.2.073L5.371 21.63a.245.245 0 00-.072.2.251.251 0 00.11.184A11.986 11.986 0 0022.016 5.409zM18.7 2.169a.25.25 0 00-.111-.184A11.985 11.985 0 001.985 18.591a.249.249 0 00.385.039L18.63 2.37a.251.251 0 00.07-.201z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});