define("ember-svg-jar/inlined/single-neutral-actions-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-flash</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.179 15.75h-1.286a.25.25 0 01-.25-.25v-4.25a.5.5 0 00-.887-.316L15.483 17.4a.822.822 0 00.627 1.352h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.5.5 0 00.556-.155l5.266-6.463a.822.822 0 00-.627-1.352zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M23.179 15.75h-1.286a.25.25 0 01-.25-.25v-4.25a.5.5 0 00-.887-.316L15.483 17.4a.822.822 0 00.627 1.352h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.5.5 0 00.556-.155l5.266-6.463a.822.822 0 00-.627-1.352z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});