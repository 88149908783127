define("ember-svg-jar/inlined/style-three-pin-hammer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hammer</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.606 11.35l-.009.008-1.358 1.359a1.057 1.057 0 01-.78.31A4.365 4.365 0 0113.14 11.9a.5.5 0 00-.665.037l-3.768 3.77a1 1 0 11-1.414-1.414l3.769-3.769a.5.5 0 00.038-.664c-.8-1-1.574-2.342-.817-3.1l1.355-1.355.018-.017a1.214 1.214 0 011.228-.216 5.7 5.7 0 011.857 1.051.5.5 0 00.661-.04l.376-.376a1 1 0 111.414 1.414l-.374.374a.5.5 0 00-.038.663 5.689 5.689 0 011.053 1.851 1.22 1.22 0 01-.227 1.241z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});