define("ember-svg-jar/inlined/space-astronaut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-astronaut</title><path d=\"M23.5 19.157a1 1 0 00-1.412-.089 11.365 11.365 0 01-8.422 2.788 6.862 6.862 0 01-4.633-2.439L13.478 15a.5.5 0 01.707.707l-3.033 3.032a.25.25 0 00-.03.318 1.5 1.5 0 002.3.215l2.122-2.122a4.5 4.5 0 000-6.363l-.335-.335a.25.25 0 01.248-.417 4 4 0 10-2.7-2.7.25.25 0 01-.416.248l-.336-.335a4.5 4.5 0 00-6.364 0l-2.119 2.12a1.5 1.5 0 00.225 2.306.25.25 0 00.314-.029L7.1 8.7a.5.5 0 01.7.717L.7 16.443a1.5 1.5 0 002.121 2.121L5.277 16.1a1 1 0 011.414 1.415l-2.462 2.459A1.5 1.5 0 106.351 22.1l1.258-1.258a9.211 9.211 0 007.08 3.076 13.511 13.511 0 008.718-3.345 1 1 0 00.093-1.416zM18.32 4.47a2.44 2.44 0 11-3.451 0 2.439 2.439 0 013.451 0zm-8.434 5.605a.5.5 0 01.707 0l2.121 2.125a.5.5 0 010 .707l-2.121 2.121a.5.5 0 01-.707 0L7.765 12.9a.5.5 0 010-.707z\"/><path d=\"M10.98 4.826a.251.251 0 00.2-.01.247.247 0 00.129-.155 5.5 5.5 0 013.417-3.638.25.25 0 00.034-.456 4.094 4.094 0 00-4.824.715L7 4.221a.25.25 0 00.242.419 6.055 6.055 0 013.741.186zM20.483 10.084a5.464 5.464 0 01-2.355 1.395.253.253 0 00-.155.129.247.247 0 00-.009.2 6.055 6.055 0 01.186 3.741.25.25 0 00.418.243l2.94-2.94a4.1 4.1 0 00.717-4.82.251.251 0 00-.456.033 5.476 5.476 0 01-1.286 2.018z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});