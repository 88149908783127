define("ember-svg-jar/inlined/gesture-tap-swipe-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-up</title><path d=\"M12.126 3.5h1.25a.25.25 0 01.25.25V8.5a1 1 0 002 0V3.749a.25.25 0 01.25-.25h1.25a.75.75 0 00.468-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2a.75.75 0 00.469 1.336z\"/><path d=\"M13.126 9.5a7.235 7.235 0 00-3.86 1.112.75.75 0 10.8 1.269 5.75 5.75 0 11.012 9.746.75.75 0 10-.795 1.273 7.25 7.25 0 103.843-13.4z\"/><path d=\"M16.624 16.745a3.5 3.5 0 00-3.5-3.5h-9a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h9a3.5 3.5 0 003.5-3.5zm-5 1.125v-2.25a.375.375 0 01.376-.375h1.125a1.5 1.5 0 010 3H12a.375.375 0 01-.376-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});