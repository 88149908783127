define("ember-svg-jar/inlined/forbidden-phone-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>forbidden-phone-off</title><path d=\"M7.5 12.775V5a1 1 0 011-1h7a1.009 1.009 0 01.331.056.584.584 0 00.386.025 1.126 1.126 0 00.263-.181l1.295-1.3a.252.252 0 00.01-.351A2.49 2.49 0 0016 1.5H8A2.5 2.5 0 005.5 4v10.275a.25.25 0 00.427.177l1.5-1.5a.249.249 0 00.073-.177zM23.707.293a1 1 0 00-1.414 0l-22 22a1 1 0 00-.187 1.154.974.974 0 00.187.26 1 1 0 001.414 0l3.921-3.92A2.5 2.5 0 008 21.5h8a2.5 2.5 0 002.5-2.5V6.914l5.207-5.207a1 1 0 00.187-1.154.974.974 0 00-.187-.26zM15.5 17H9.018l-.177-.427 7.232-7.232a.25.25 0 01.427.177V16a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});