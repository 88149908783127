define("ember-svg-jar/inlined/road-sign-mudslide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-mudslide</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zm-7.28 3.22H7a.75.75 0 01-.75-.75V9.5a.747.747 0 01.22-.53l2.146-2.147a1.25 1.25 0 012.134.884v1.879a.248.248 0 00.073.176l1.024 1.025a1.739 1.739 0 01.445.757l.722 2.525a.251.251 0 00.24.181h3a.75.75 0 010 1.5zm-4-8.25a.75.75 0 11.75.75.75.75 0 01-.75-.75zm1.75 3a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});