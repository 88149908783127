define("ember-svg-jar/inlined/notes-quill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-quill</title><path d=\"M11.717 17.5H2.25a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v8.083a.249.249 0 00.318.239 5.577 5.577 0 011.423-.172.245.245 0 00.259-.248V4.5a2 2 0 00-2-2h-1.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h9.455a.252.252 0 00.249-.238 11.445 11.445 0 01.257-1.46.248.248 0 00-.244-.302z\"/><path d=\"M23.882 11.894a.5.5 0 00-.409-.177 13.48 13.48 0 00-4.251 1.122.5.5 0 00-.3.416c0 .026-.2 2.134-1.425 2.794a.189.189 0 01-.293-.155V14.5a.5.5 0 00-.169-.374.51.51 0 00-.389-.126c-.788.1-2.685 1.976-3.313 3.8a3.558 3.558 0 00.1 2.828.241.241 0 01-.041.281l-1.38 1.38a1 1 0 001.414 1.414s1.422-1.424 1.507-1.507a.3.3 0 01.273-.049 2.627 2.627 0 00.637.079 3.658 3.658 0 00.886-.114 8.068 8.068 0 003.552-2.26c2.555-2.556 3.663-7.326 3.709-7.529a.5.5 0 00-.108-.429z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});