define("ember-svg-jar/inlined/calendar-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-cash</title><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M12.2 14.745h-.4a.989.989 0 01-1.048-.914c0-.682.511-.914 1.05-.914l1.8.005a.75.75 0 000-1.5h-.852v-.586a.75.75 0 00-1.5 0v.643a2.58 2.58 0 00-1.232.626 2.37 2.37 0 00-.768 1.728 2.487 2.487 0 002.55 2.412h.4a.926.926 0 110 1.834h-1.8a.75.75 0 000 1.5h.848v.585a.75.75 0 001.5 0v-.648a2.453 2.453 0 002-2.357 2.491 2.491 0 00-2.548-2.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});