define("ember-svg-jar/inlined/ecology-leaf-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-message</title><path d=\"M17.684 6.072a.5.5 0 00-.431-.016 8.044 8.044 0 01-3.337.569 8.325 8.325 0 00-2.872.4A3.207 3.207 0 008.88 9.133a2.492 2.492 0 00.16 1.591.5.5 0 01-.1.543 20.8 20.8 0 00-2.7 3.506.748.748 0 00.26 1.027.739.739 0 00.38.105.75.75 0 00.647-.369c1.261-2.147 3.942-5.243 6.513-5.865a.5.5 0 11.235.972 8.277 8.277 0 00-3.674 2.52.5.5 0 00-.018.663 2.493 2.493 0 00.873.672 2.676 2.676 0 001.109.226 4.5 4.5 0 002.4-.787c1.728-1.119 3.484-4.966 2.982-7.518a.5.5 0 00-.263-.347z\"/><path d=\"M12 .75C5.383.75 0 5.348 0 11a9.308 9.308 0 002.783 6.57.5.5 0 01.076.6L1.2 21.012a1.488 1.488 0 001.873 2.117l5.267-2.257a.507.507 0 01.317-.025 13.962 13.962 0 003.343.4c6.617 0 12-4.6 12-10.25S18.617.75 12 .75zm0 18.5a11.87 11.87 0 01-3.3-.462 1 1 0 00-.672.042l-3.621 1.552a.249.249 0 01-.286-.064.252.252 0 01-.029-.292l1.1-1.891a1 1 0 00-.231-1.278A7.554 7.554 0 012 11c0-4.55 4.486-8.25 10-8.25S22 6.45 22 11s-4.486 8.25-10 8.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});