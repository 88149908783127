define("ember-svg-jar/inlined/chair-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-5</title><path d=\"M23.274 5.017a2.5 2.5 0 00-3.522-3.548A11.58 11.58 0 0112 4.249a11.568 11.568 0 01-7.734-2.767A2.5 2.5 0 00.731 5.017 16.7 16.7 0 0012 9.249a16.713 16.713 0 0011.274-4.232zM21.751 22.91a1 1 0 00-.089-1.411l-7.937-7a.249.249 0 010-.375l2.837-2.5a.5.5 0 00-.331-.875H14.72a.5.5 0 00-.331.125l-2.224 1.961a.25.25 0 01-.33 0l-2.224-1.961a.5.5 0 00-.331-.125H7.769a.5.5 0 00-.331.875l2.838 2.5a.251.251 0 010 .375l-7.938 7A1 1 0 103.66 23l8.175-7.207a.25.25 0 01.33 0L20.34 23a1 1 0 001.411-.089z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});