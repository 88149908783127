define("ember-svg-jar/inlined/hotel-single-bed-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-single-bed-1</title><path d=\"M10.364 5.318h3.272a3.266 3.266 0 013.176 2.517.273.273 0 00.265.21h5.014a.272.272 0 00.273-.272V5.045a2.181 2.181 0 00-2.182-2.181H3.818a2.181 2.181 0 00-2.182 2.181v2.728a.272.272 0 00.273.272h5.014a.271.271 0 00.265-.21 3.266 3.266 0 013.176-2.517zM1.091 9.682A1.09 1.09 0 000 10.773V16.5a1.091 1.091 0 00.873 1.069.273.273 0 01.218.267v2.209a1.091 1.091 0 002.182 0v-2.181a.272.272 0 01.272-.273h16.91a.272.272 0 01.272.273v2.181a1.091 1.091 0 102.182 0v-2.209a.273.273 0 01.218-.267A1.091 1.091 0 0024 16.5v-5.727a1.09 1.09 0 00-1.091-1.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});