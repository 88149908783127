define("ember-svg-jar/inlined/army-symbol-cavalry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-cavalry</title><path d=\"M24 4.5a2 2 0 00-2-2H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2zm-4.7 0a.25.25 0 01.153.447l-2.369 1.84a.248.248 0 01-.238.038A5.449 5.449 0 0015 6.5H9a5.449 5.449 0 00-1.844.324.248.248 0 01-.238-.038L4.55 4.947A.25.25 0 014.7 4.5zm-9.182 7.3a.249.249 0 010 .394l-3.113 2.42a.249.249 0 01-.319-.01 3.452 3.452 0 010-5.208.251.251 0 01.319-.01zM9.7 8.947a.25.25 0 01.153-.447h4.294a.25.25 0 01.153.447l-2.147 1.668a.249.249 0 01-.306 0zm2.147 4.438a.249.249 0 01.306 0l2.147 1.668a.25.25 0 01-.153.447H9.853a.25.25 0 01-.153-.447zm2.037-1.185a.249.249 0 010-.394L17 9.386a.249.249 0 01.319.01 3.452 3.452 0 010 5.208.251.251 0 01-.319.01zM2 6.009a.25.25 0 01.4-.2L5 7.83a.25.25 0 01.027.371 5.471 5.471 0 000 7.594.251.251 0 01-.027.371l-2.6 2.023a.25.25 0 01-.4-.2zM4.7 19.5a.249.249 0 01-.153-.447l2.367-1.839a.248.248 0 01.238-.038A5.449 5.449 0 009 17.5h6a5.449 5.449 0 001.844-.324.248.248 0 01.238.038l2.367 1.839a.249.249 0 01-.153.447zM22 17.991a.25.25 0 01-.4.2L19 16.168a.25.25 0 01-.027-.371 5.471 5.471 0 000-7.594.251.251 0 01.027-.371l2.6-2.021a.25.25 0 01.4.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});