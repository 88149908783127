define("ember-svg-jar/inlined/car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-1</title><path d=\"M22 10.375a1 1 0 001-1v-1a1 1 0 00-1-1h-2.1a.25.25 0 01-.237-.171l-1.1-3.3a1.5 1.5 0 00-1.423-1.029H6.86A1.5 1.5 0 005.437 3.9l-1.1 3.3a.25.25 0 01-.237.171H2a1 1 0 00-1 1v1a1 1 0 001 1h.689a.25.25 0 01.177.427l-1.573 1.577a1.008 1.008 0 00-.293.707v3.293a.979.979 0 00.6.9.25.25 0 01.15.229v2.374A1.251 1.251 0 003 21.125h2a1.251 1.251 0 001.25-1.25v-2.25a.25.25 0 01.25-.25h11a.25.25 0 01.25.25v2.25a1.251 1.251 0 001.25 1.25h2a1.251 1.251 0 001.25-1.25V17.5a.25.25 0 01.15-.229.979.979 0 00.6-.9v-3.289a1.008 1.008 0 00-.293-.707L21.134 10.8a.25.25 0 01.177-.427zm-5.4-5.5a.25.25 0 01.237.171L18 8.546a.251.251 0 01-.238.329H6.235A.251.251 0 016 8.546l1.167-3.5a.25.25 0 01.233-.171zm-10.6 10H3a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5zm11.5-1.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});