define("ember-svg-jar/inlined/professions-man-office-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-office-1</title><path d=\"M.5 20.769v2.606a.5.5 0 00.5.5h8.648a.251.251 0 00.241-.181l1.63-5.706a.52.52 0 01.962 0l1.63 5.706a.251.251 0 00.241.181H23a.5.5 0 00.5-.5v-2.606a2.462 2.462 0 00-.967-1.977 15.417 15.417 0 00-7.713-2.759.25.25 0 01-.071-.478A7 7 0 0019 9.125v-2a7 7 0 00-14 0v2a7 7 0 004.251 6.43.25.25 0 01-.071.478 15.417 15.417 0 00-7.713 2.759A2.462 2.462 0 00.5 20.769zM7 9.125v-.95a.251.251 0 01.243-.25 6.992 6.992 0 004.694-1.693.1.1 0 01.126 0 7 7 0 004.694 1.694.251.251 0 01.243.25v.949a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"9.375\" r=\"1\"/><circle cx=\"10\" cy=\"9.375\" r=\"1\"/><path d=\"M12.082 12.994a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});