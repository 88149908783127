define("ember-svg-jar/inlined/arrow-left-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-left-2-alternate</title><path d=\"M21.616 21.537H8.694a2.391 2.391 0 01-1.786-.8L.594 13.578a2.393 2.393 0 010-3.156l6.311-7.153a2.4 2.4 0 011.789-.806h12.922A2.386 2.386 0 0124 4.847v14.306a2.386 2.386 0 01-2.384 2.384zM8.833 18.592a.254.254 0 00.188.084h11.868a.25.25 0 00.25-.25V5.574a.25.25 0 00-.25-.25H9.021a.254.254 0 00-.188.084l-5.669 6.427a.248.248 0 000 .33z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});