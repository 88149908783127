define("ember-svg-jar/inlined/upload-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>upload-menu</title><path d=\"M16.423 16.383a1 1 0 00-.217-1.09l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5A1 1 0 008.5 17h1.75a.25.25 0 01.25.25v5.25a1.5 1.5 0 003 0v-5.25a.25.25 0 01.25-.25h1.75a1 1 0 00.923-.617zM5.5 2h13a1 1 0 000-2h-13a1 1 0 000 2zM5.5 5.5h13a1 1 0 000-2h-13a1 1 0 000 2zM5.5 9h13a1 1 0 000-2h-13a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});