define("ember-svg-jar/inlined/e-commerce-basket-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-basket-laptop</title><path d=\"M7.843 12.355a1.164 1.164 0 001.2.894h5.882a1.166 1.166 0 001.205-.9L17 8.87a.5.5 0 00-.485-.62h-.783a.25.25 0 01-.223-.138L14.391 5.9a.75.75 0 10-1.34.673l.663 1.319a.249.249 0 01-.223.362h-3.007a.249.249 0 01-.223-.362l.663-1.319a.75.75 0 10-1.34-.673L8.469 8.112a.25.25 0 01-.223.138h-.782a.5.5 0 00-.485.62z\"/><path d=\"M23.633 18.414l-2.114-2.205a.248.248 0 01-.061-.235.917.917 0 00.029-.224V4.639a2.308 2.308 0 00-2.211-2.389H4.725a2.309 2.309 0 00-2.213 2.389V15.75a.871.871 0 00.03.224.25.25 0 01-.062.235L.365 18.415a1.371 1.371 0 00-.347 1.057c.169 1.982 1.455 2.278 2.206 2.278h19.553c.751 0 2.035-.3 2.206-2.279a1.373 1.373 0 00-.35-1.057zm-8.4.336a.75.75 0 01-.75.75h-5a.75.75 0 010-1.5h5a.75.75 0 01.754.75zm-10.721-14a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v9.5a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});