define("ember-svg-jar/inlined/common-file-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-heart</title><path d=\"M23.414 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.681a.413.413 0 00.5.3c.154-.057.754-.464 1.167-.618A.5.5 0 008 10.894V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.4a.489.489 0 00-.395.194c-.268.357-.814.979-1.182 1.391a.249.249 0 00.189.414H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.629 14.449a3.527 3.527 0 00-5.647-.916l-.481.482-.482-.482a3.483 3.483 0 00-3.057-.987 3.526 3.526 0 00-1.936 5.966l5.113 5.334a.5.5 0 00.723 0l5.107-5.327a3.526 3.526 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});