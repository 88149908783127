define("ember-svg-jar/inlined/lioness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lioness</title><path d=\"M15.079 21.46A4.565 4.565 0 0112 20.261a4.565 4.565 0 01-3.079 1.2c-1 0-.889-.185-.989-.085A4.442 4.442 0 0012 24c2.705 0 4.214-2.477 4.067-2.626-.099-.1.014.086-.988.086z\"/><path d=\"M19 0a5.492 5.492 0 00-3.654 1.39 1.03 1.03 0 00-.186.221 9.029 9.029 0 00-6.32 0 1.03 1.03 0 00-.186-.221A5.492 5.492 0 005 0C3.76 0 0 .77 0 4.5a5.52 5.52 0 002.732 4.753 1.01 1.01 0 00.293.113C3.009 9.589 3 11.907 3 11.907 3 14.81 6 15 5.842 16.321v.56a3.08 3.08 0 005.958 1.107 2 2 0 01-1.681-1.323.5.5 0 01.466-.665h2.83a.5.5 0 01.472.667 2 2 0 01-1.682 1.323 3.08 3.08 0 005.953-1.107v-.56C18.158 15 21 14.812 21 11.907c0 0-.008-2.318-.025-2.541a.989.989 0 00.293-.113A5.52 5.52 0 0024 4.5C24-.029 19.116 0 19 0zM2.734 6.641a3.5 3.5 0 01-.709-2.559 6.443 6.443 0 011.769.549 10.518 10.518 0 00-1.06 2.01zM8 11.25A1.25 1.25 0 119.25 10 1.25 1.25 0 018 11.25zm8 0A1.25 1.25 0 1117.25 10 1.25 1.25 0 0116 11.25zm5.267-4.609a10.609 10.609 0 00-1.061-2.01 6.433 6.433 0 011.769-.549 3.508 3.508 0 01-.708 2.559z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});