define("ember-svg-jar/inlined/messages-bubble-quote-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-quote-alternate</title><path d=\"M12 .841a13.291 13.291 0 00-8.406 2.85A9.152 9.152 0 000 10.809a9.008 9.008 0 003.054 6.657L.93 21.708a1 1 0 001.316 1.355l5.98-2.782a14.157 14.157 0 003.771.5H12a13.285 13.285 0 008.4-2.85 9.153 9.153 0 003.6-7.114c0-5.5-5.382-9.975-12-9.976zm7.141 15.542a11.286 11.286 0 01-7.141 2.4 12.093 12.093 0 01-3.56-.523 1 1 0 00-.715.05l-3.071 1.427a.25.25 0 01-.329-.339l.869-1.733a1 1 0 00-.269-1.229A7.21 7.21 0 012 10.81a7.194 7.194 0 012.857-5.569A11.287 11.287 0 0112 2.841c5.512 0 10 3.579 9.995 7.975a7.191 7.191 0 01-2.852 5.567z\"/><path d=\"M10.5 5.124a1 1 0 00-1 1v2.5a1 1 0 102 0v-2.5a1 1 0 00-1-1zM9.75 10.374a1.746 1.746 0 00-.25 3.474v.276a1 1 0 000 2 2 2 0 002-2v-2a1.753 1.753 0 00-1.75-1.75zM14 6.124a1 1 0 100 2c.709 0 1.5 1.027 1.5 2.5s-.791 2.5-1.5 2.5a1 1 0 000 2c1.962 0 3.5-1.977 3.5-4.5s-1.539-4.5-3.5-4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});