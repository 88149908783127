define("ember-svg-jar/inlined/halloween-bat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-bat</title><path d=\"M20.423 6.821a.522.522 0 00-.826.2c-.492 1.233-1.3 2.379-3.742 3.112a.251.251 0 01-.314-.3 3.46 3.46 0 00.118-.893 3.568 3.568 0 00-.469-1.768.5.5 0 01-.023-.449l.726-1.648a.522.522 0 00-.568-.724 3.5 3.5 0 00-1.892.977.5.5 0 01-.483.121 3.5 3.5 0 00-.95-.14 3.415 3.415 0 00-.858.115.5.5 0 01-.469-.122A3.5 3.5 0 008.8 4.347a.521.521 0 00-.568.724L8.906 6.6a.5.5 0 01-.031.464 3.562 3.562 0 00-.534 1.875 3.46 3.46 0 00.118.893.251.251 0 01-.314.3C5.7 9.4 4.895 8.254 4.4 7.021a.521.521 0 00-.826-.2C.216 9.732.01 13.082 0 17.429a.522.522 0 00.95.3c2.77-3.976 5.876-2.148 6.709 0a.522.522 0 00.644.309 3.059 3.059 0 013.259 1.389.522.522 0 00.878 0 3.056 3.056 0 013.26-1.394.521.521 0 00.643-.309c.833-2.144 3.939-3.972 6.709 0a.522.522 0 00.95-.3c-.012-4.342-.218-7.692-3.579-10.603z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});