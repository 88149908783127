define("ember-svg-jar/inlined/project-home-shopping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-home-shopping</title><path d=\"M3.177 8.618A.5.5 0 003.5 9.5h1a.5.5 0 01.5.5v4.5a.5.5 0 00.5.5H8a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V10a.5.5 0 01.5-.5h1a.5.5 0 00.324-.882l-6.5-5.5a.5.5 0 00-.647 0z\"/><circle cx=\"16\" cy=\"21\" r=\"2\"/><circle cx=\"5\" cy=\"21\" r=\"2\"/><path d=\"M24 2a1 1 0 00-1-1h-1.5a1 1 0 00-.991.868l-1.827 13.7a.5.5 0 01-.5.434H1a1 1 0 000 2h18.5a1 1 0 00.991-.868l1.827-13.7a.5.5 0 01.5-.434H23a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});