define("ember-svg-jar/inlined/design-tool-fibonacci", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-fibonacci</title><path d=\"M18.731 14a.25.25 0 00.25-.263 12.594 12.594 0 00-5.23-9.747 15.287 15.287 0 00-8.376-2.973 11.375 11.375 0 00-1.14.013.249.249 0 00-.235.249V13.75a.25.25 0 00.25.25zM5 21.107A5.214 5.214 0 014 18v4.5A1.5 1.5 0 005.5 24h5.977a9.186 9.186 0 01-4.218-1A6.5 6.5 0 015 21.107zM5 15.265A.157.157 0 004.885 15H4.25a.25.25 0 00-.25.25V18a3.858 3.858 0 011-2.735z\"/><path d=\"M8.5 17c-.676 0-.915.581-.915 1.078a1.509 1.509 0 00.351.981A1.342 1.342 0 009 19.5c1.065 0 2-.7 2-1.5a2.934 2.934 0 00-3-3 2.892 2.892 0 00-3 3 4.221 4.221 0 001.291 3.082 7.2 7.2 0 004.437 1.885.252.252 0 00.191-.064.255.255 0 00.081-.185v-2.467a.219.219 0 00-.109-.189.215.215 0 00-.218 0A3.373 3.373 0 019 20.5a2.32 2.32 0 01-1.818-.784 2.523 2.523 0 01-.6-1.638A1.936 1.936 0 018.5 16a.5.5 0 010 1zM12 18v4.712a.251.251 0 00.275.249c3.113-.308 6.412-2.458 6.7-7.7a.256.256 0 00-.068-.186.251.251 0 00-.178-.075h-7.44a.252.252 0 00-.227.144.249.249 0 00.034.265A4.024 4.024 0 0112 18z\"/><path d=\"M19 19.361A7.935 7.935 0 0115.56 23a8.644 8.644 0 01-4.06 1h7a1.5 1.5 0 001.5-1.5v-4.388a.25.25 0 00-.489-.074A9.546 9.546 0 0119 19.361zM20 1.5A1.5 1.5 0 0018.5 0H8.756a.25.25 0 00-.064.492C9.229.633 9.764.8 10.293 1a16.51 16.51 0 014.055 2.188A13.826 13.826 0 0119 9.105a13.544 13.544 0 01.511 1.524.25.25 0 00.489-.065V1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});