define("ember-svg-jar/inlined/laptop-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-upload</title><path d=\"M5.51 4.5H5.5a3 3 0 00-3 3v8a1 1 0 002 0v-8a1 1 0 011-1h.01a.991.991 0 00.991-1v-.011A.992.992 0 005.51 4.5zM20.5 16.5a1 1 0 001-1v-8a3 3 0 00-3-3h-.013a.988.988 0 00-.99.978V5.5a.991.991 0 00.991 1h.012a1 1 0 011 1v8a1 1 0 001 1zM13.5 6.25a.25.25 0 01.25-.25h1.75a1 1 0 00.707-1.707l-3.5-3.5a1 1 0 00-1.414 0l-3.5 3.5A1 1 0 008.5 6h1.75a.25.25 0 01.25.25v5.25a1.5 1.5 0 003 0zM23.5 18H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5A.5.5 0 008 18H.5a.5.5 0 00-.474.658A7.076 7.076 0 006.74 23.5h10.52a7.076 7.076 0 006.714-4.842A.5.5 0 0023.5 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});