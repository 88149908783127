define("ember-svg-jar/inlined/single-man-actions-heart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-heart</title><path d=\"M.618 18.062h1.791a.247.247 0 01.247.227l.438 5.257a.5.5 0 00.493.454h3.959a.494.494 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h.849a.247.247 0 00.234-.329 4.894 4.894 0 01.94-4.878.247.247 0 00.052-.23A5.44 5.44 0 00.123 14.1v3.464a.5.5 0 00.495.498z\"/><path d=\"M1.36 4.206A4.207 4.207 0 105.567 0 4.211 4.211 0 001.36 4.206zm4.207 2.227A2.229 2.229 0 013.34 4.206a2.135 2.135 0 01.156-.8.249.249 0 01.36-.119 5.314 5.314 0 002.8.793 5.335 5.335 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM12.023 18.566l5.059 5.278a.5.5 0 00.714 0l5.052-5.271a3.489 3.489 0 00-1.911-5.91 3.449 3.449 0 00-3.025.977l-.3.3a.249.249 0 01-.35 0l-.3-.3a3.489 3.489 0 00-4.943 4.925z\"/><path d=\"M.618 18.062h1.791a.247.247 0 01.247.227l.438 5.257a.5.5 0 00.493.454h3.959a.494.494 0 00.493-.454l.438-5.257a.247.247 0 01.247-.227h.849a.247.247 0 00.234-.329 4.894 4.894 0 01.94-4.878.247.247 0 00.052-.23A5.44 5.44 0 00.123 14.1v3.464a.5.5 0 00.495.498z\"/><path d=\"M1.36 4.206A4.207 4.207 0 105.567 0 4.211 4.211 0 001.36 4.206zm4.207 2.227A2.229 2.229 0 013.34 4.206a2.135 2.135 0 01.156-.8.249.249 0 01.36-.119 5.314 5.314 0 002.8.793 5.335 5.335 0 00.9-.077.2.2 0 01.165.045.2.2 0 01.072.155 2.229 2.229 0 01-2.226 2.23zM12.023 18.566l5.059 5.278a.5.5 0 00.714 0l5.052-5.271a3.489 3.489 0 00-1.911-5.91 3.449 3.449 0 00-3.025.977l-.3.3a.249.249 0 01-.35 0l-.3-.3a3.489 3.489 0 00-4.943 4.925z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});