define("ember-svg-jar/inlined/escalator-descend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>escalator-descend</title><path d=\"M17.409 8.052a.5.5 0 010 .707l-1.263 1.262a.5.5 0 00.354.854h5a.5.5 0 00.5-.5v-5a.5.5 0 00-.309-.462.507.507 0 00-.545.108l-1.262 1.263a.5.5 0 01-.707 0l-4.793-4.793a1.25 1.25 0 10-1.767 1.768zM24 19.375a3.5 3.5 0 00-3.5-3.5h-3.372a.5.5 0 01-.364-.158L9.857 8.35a1.5 1.5 0 00-1.095-.475H3.5a3.5 3.5 0 000 7h2.872a.5.5 0 01.364.158l6.908 7.367a1.5 1.5 0 001.094.475H20.5a3.5 3.5 0 003.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});