define("ember-svg-jar/inlined/flag-plain-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flag-plain-2</title><path d=\"M21 2H6.5a.75.75 0 00-.75.75v12a.75.75 0 00.75.75H21a1.752 1.752 0 001.75-1.75v-10A1.752 1.752 0 0021 2zM2.75 0a1.5 1.5 0 00-1.5 1.5v21a1.5 1.5 0 003 0v-21A1.5 1.5 0 002.75 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});