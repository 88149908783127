define("ember-svg-jar/inlined/single-neutral-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-setting</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M22.529 7.281a.463.463 0 010-.625l.987-1.065a1.755 1.755 0 00.235-2.112 1.741 1.741 0 00-1.935-.84l-1.411.326a.452.452 0 01-.534-.311l-.423-1.392a1.776 1.776 0 00-3.4 0l-.424 1.392a.453.453 0 01-.533.311l-1.411-.326a1.741 1.741 0 00-1.936.84 1.758 1.758 0 00.235 2.112l.988 1.065a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.392a1.776 1.776 0 003.4 0l.423-1.392a.452.452 0 01.534-.311l1.411.327a1.782 1.782 0 001.7-2.952zm-4.781 1.625a1.938 1.938 0 111.937-1.937 1.94 1.94 0 01-1.937 1.937zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M22.529 7.281a.463.463 0 010-.625l.987-1.065a1.755 1.755 0 00.235-2.112 1.741 1.741 0 00-1.935-.84l-1.411.326a.452.452 0 01-.534-.311l-.423-1.392a1.776 1.776 0 00-3.4 0l-.424 1.392a.453.453 0 01-.533.311l-1.411-.326a1.741 1.741 0 00-1.936.84 1.758 1.758 0 00.235 2.112l.988 1.065a.463.463 0 010 .625l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.533.311l.424 1.392a1.776 1.776 0 003.4 0l.423-1.392a.452.452 0 01.534-.311l1.411.327a1.782 1.782 0 001.7-2.952zm-4.781 1.625a1.938 1.938 0 111.937-1.937 1.94 1.94 0 01-1.937 1.937z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});