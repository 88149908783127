define("ember-svg-jar/inlined/checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>checklist</title><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0021.5 0zm-3 9.5H14a1 1 0 010-2h4.5a1 1 0 010 2zm1 8a1 1 0 01-1 1H14a1 1 0 010-2h4.5a1 1 0 011 1zM11.3 5.6l-3 4a1 1 0 01-.729.4H7.5a1 1 0 01-.707-.293l-1.5-1.5a1 1 0 011.414-1.415l.685.685L9.7 4.4a1 1 0 011.6 1.2zm0 10l-3 4a1.018 1.018 0 01-1.507.107l-1.5-1.5a1 1 0 011.414-1.414l.685.685L9.7 14.4a1 1 0 011.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});