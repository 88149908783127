define("ember-svg-jar/inlined/real-estate-action-house-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-dollar</title><path d=\"M9.5 17.5a7.987 7.987 0 0113.137-6.118.5.5 0 00.321.118h.542a.5.5 0 00.344-.863L13.031.41a1.505 1.505 0 00-2.06 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.638a.5.5 0 00.4-.795A7.932 7.932 0 019.5 17.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.111 10.158a.5.5 0 00-.362.48v.112a.75.75 0 11-1.5 0 .5.5 0 00-.5-.5H16a.75.75 0 110-1.5h2.033a.592.592 0 00.22-1.141l-2.064-.826a2.084 2.084 0 01.2-3.941.5.5 0 00.362-.48v-.112a.75.75 0 111.5 0 .5.5 0 00.5.5H19a.75.75 0 010 1.5h-2.034a.591.591 0 00-.22 1.14l2.064.827a2.084 2.084 0 01-.2 3.941z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});