define("ember-svg-jar/inlined/renewable-energy-battery-charge-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>renewable-energy-battery-charge-1</title><path d=\"M14.5 6.048a.5.5 0 01-.5-.5 1.5 1.5 0 00-1.5-1.5h-1a1.5 1.5 0 00-1.5 1.5.5.5 0 01-.5.5 2.5 2.5 0 00-2.5 2.5v9a2.5 2.5 0 002.5 2.5h5a2.5 2.5 0 002.5-2.5v-9a2.5 2.5 0 00-2.5-2.5zm.576 6.832l-3.167 4.048a.445.445 0 01-.795-.275v-2.212a.5.5 0 00-.5-.5H9.335a.445.445 0 01-.35-.719l3.113-4a.445.445 0 01.8.274v2.169a.5.5 0 00.5.5h1.333a.444.444 0 01.35.718zM3.423 17.194a9.9 9.9 0 01-.78-1.609 1 1 0 00-1.872.7 11.853 11.853 0 00.938 1.934 1 1 0 101.714-1.03zM6.5 20.4a10.09 10.09 0 01-1.4-1.116 1 1 0 00-1.38 1.447 12.031 12.031 0 001.68 1.342A1 1 0 006.5 20.4zM10.664 21.96a9.987 9.987 0 01-1.746-.395 1 1 0 10-.616 1.9 11.9 11.9 0 002.1.474 1 1 0 00.265-1.982zM15.092 21.561a10 10 0 01-1.745.4 1 1 0 10.267 1.982 12.087 12.087 0 002.095-.477 1 1 0 10-.617-1.9zM22.649 14.991a1 1 0 00-1.288.583 10.085 10.085 0 01-.779 1.611 1 1 0 101.718 1.029 11.94 11.94 0 00.937-1.935 1 1 0 00-.588-1.288zM18.906 19.281a10.112 10.112 0 01-1.4 1.117 1 1 0 101.1 1.668 12.081 12.081 0 001.68-1.339 1 1 0 10-1.382-1.446z\"/><path d=\"M12 .048a12.013 12.013 0 00-12 12c0 .368.017.731.049 1.089a1 1 0 101.992-.179Q2 12.508 2 12.048a10 10 0 0120 0q0 .461-.041.91a1 1 0 101.992.179c.032-.358.049-.721.049-1.089a12.013 12.013 0 00-12-12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});