define("ember-svg-jar/inlined/module-hands-puzzle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>module-hands-puzzle</title><path d=\"M17.031 15.632a22.622 22.622 0 00-1.52 3.926.246.246 0 00-.011.072v4.12a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-.972a.5.5 0 01.148-.355 1931.7 1931.7 0 003.558-3.529 1 1 0 00.294-.708V11.04a.5.5 0 00-.665-.472c-.73.255-1.8 1.388-1.834 5.828a.5.5 0 01-.147.35l-2.208 2.209a.25.25 0 01-.421-.232l.494-2.153c.343-1.438-.971-2.052-1.688-.938zM1.165 10.568a.5.5 0 00-.665.472v7.1a1 1 0 00.329.742c1.035.936 3 2.978 3.532 3.539a.5.5 0 01.139.345v.98a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25V19.63a.246.246 0 00-.011-.072 22.622 22.622 0 00-1.52-3.926c-.717-1.114-2.031-.5-1.688.938l.493 2.153a.25.25 0 01-.42.232l-2.208-2.209A.5.5 0 013 16.4c-.033-4.444-1.1-5.577-1.835-5.832zM7.768 5.6a2.016 2.016 0 01-.255-.311.5.5 0 00-.771-.077L5.293 6.657a1 1 0 000 1.414l5.657 5.657a1 1 0 001.414 0l1.561-1.562a.5.5 0 00-.171-.819A2 2 0 1115.9 8.071a1.97 1.97 0 01.448.683.5.5 0 00.82.171l1.561-1.561a1 1 0 000-1.414L17.4 4.621a.247.247 0 01-.07-.219.251.251 0 01.139-.183 1.966 1.966 0 00.552-.391A2 2 0 1014.8 1.552a.25.25 0 01-.4.069L13.071.293a1 1 0 00-1.414 0l-1.45 1.449a.5.5 0 00.078.772 1.953 1.953 0 01.311.254A2 2 0 117.768 5.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});