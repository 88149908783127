define("ember-svg-jar/inlined/multiple-actions-graduate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-graduate</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v.454a.249.249 0 00.348.23l3.864-1.656a2 2 0 011.576 0l.363.156a.251.251 0 00.349-.23V11.9a4.456 4.456 0 00-4.5-4.4 4.528 4.528 0 00-3.6 1.761.248.248 0 00.005.31A6.96 6.96 0 0112.5 14zM2.31 18a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25v-.312a2 2 0 011.213-1.838l.135-.058a.25.25 0 00.152-.23V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.316 17.111a.5.5 0 00-.316.474v3.38a.75.75 0 101.5 0v-2.153a.251.251 0 01.106-.2.249.249 0 01.227-.032l4.5 1.589a.5.5 0 00.166.028.5.5 0 00.167-.028l6-2.118a.5.5 0 00.018-.936l-6-2.383a.5.5 0 00-.369 0z\"/><path d=\"M21.1 22.918a.5.5 0 00.334-.472V20.3a.5.5 0 00-.667-.472l-3.179 1.122a.249.249 0 01-.166 0l-3.179-1.122a.5.5 0 00-.666.472v2.145a.5.5 0 00.333.472l2.573.908a3.091 3.091 0 002.045 0z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.5 14v.454a.249.249 0 00.348.23l3.864-1.656a2 2 0 011.576 0l.363.156a.251.251 0 00.349-.23V11.9a4.456 4.456 0 00-4.5-4.4 4.528 4.528 0 00-3.6 1.761.248.248 0 00.005.31A6.96 6.96 0 0112.5 14zM2.31 18a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.56a.25.25 0 00.25-.25v-.312a2 2 0 011.213-1.838l.135-.058a.25.25 0 00.152-.23V14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11.316 17.111a.5.5 0 00-.316.474v3.38a.75.75 0 101.5 0v-2.153a.251.251 0 01.106-.2.249.249 0 01.227-.032l4.5 1.589a.5.5 0 00.166.028.5.5 0 00.167-.028l6-2.118a.5.5 0 00.018-.936l-6-2.383a.5.5 0 00-.369 0z\"/><path d=\"M21.1 22.918a.5.5 0 00.334-.472V20.3a.5.5 0 00-.667-.472l-3.179 1.122a.249.249 0 01-.166 0l-3.179-1.122a.5.5 0 00-.666.472v2.145a.5.5 0 00.333.472l2.573.908a3.091 3.091 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});