define("ember-svg-jar/inlined/gesture-tap-swipe-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-vertical</title><path d=\"M17.125 20.5h-5a.75.75 0 00-.468 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.469-1.335zM12.125 3.5h5a.75.75 0 00.469-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2a.75.75 0 00.468 1.335zM13.125 4.75a7.231 7.231 0 00-3.859 1.112.75.75 0 10.8 1.269 5.751 5.751 0 11.013 9.745.75.75 0 00-.795 1.273 7.25 7.25 0 103.842-13.4z\"/><path d=\"M16.625 12a3.5 3.5 0 00-3.5-3.5h-9a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h9a3.5 3.5 0 003.5-3.5zm-5 1.125V10.87A.374.374 0 0112 10.5h1.125a1.5 1.5 0 110 3H12a.374.374 0 01-.375-.38z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});