define("ember-svg-jar/inlined/video-edit-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-brightness</title><circle cx=\"17\" cy=\"12\" r=\"7\"/><path d=\"M10.063 17.789a7 7 0 010-11.578 1 1 0 10-1.126-1.652 9 9 0 000 14.882 1 1 0 101.126-1.652z\"/><path d=\"M5.063 17.789a7 7 0 010-11.578 1 1 0 00-1.126-1.652 9 9 0 000 14.882 1 1 0 101.126-1.652z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});