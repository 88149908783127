define("ember-svg-jar/inlined/single-neutral-vintage-tv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-vintage-tv</title><path d=\"M24 3.249a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-1.5 1.5v14.5a1.5 1.5 0 001.5 1.5h.8a.25.25 0 01.2.406l-.775.969a1 1 0 101.562 1.25l1.95-2.437a.5.5 0 01.39-.188h12.752a.5.5 0 01.39.188l1.95 2.437a1 1 0 001.562-1.249l-.775-.97a.25.25 0 01.2-.406h.8a1.5 1.5 0 001.5-1.5zM6.677 11.58a4.978 4.978 0 01-1.13-3.511 2.881 2.881 0 012.969-3.052 2.88 2.88 0 012.968 3.052 4.993 4.993 0 01-1.149 3.531.988.988 0 00.027.7c2.062.735 3.2 1.274 3.653 2.151a5.374 5.374 0 01.492 1.476.5.5 0 01-.491.591h-11a.5.5 0 01-.493-.59 5.325 5.325 0 01.494-1.478c.453-.876 1.59-1.415 3.667-2.159a1.009 1.009 0 00-.007-.711zM17.5 17.249a.5.5 0 01-.5-.5V4a.5.5 0 111 0v12.75a.5.5 0 01-.5.499zm2.25-2.5A.75.75 0 0120.5 14h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.751zM21.5 12.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM21 8.749a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});