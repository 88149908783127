define("ember-svg-jar/inlined/nuclear-energy-plant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nuclear-energy-plant</title><path d=\"M23.525.451a.5.5 0 00-.669 0C21 2.1 19.2 1.485 17.119.774 14.686-.058 11.928-1 9.288 2.521a.5.5 0 00.665.724c1.36-.85 2.756-.278 4.371.385a9.943 9.943 0 003.746.981c1.692 0 3.545-.8 5.522-3.494a.5.5 0 00-.067-.666zM19.966 7.931a1 1 0 00-1-.931H4.908a1 1 0 00-1 .931c-.151 2.206-.852 9.777-3.477 14.59A1 1 0 001.311 24h21.254a1 1 0 00.878-1.479c-2.625-4.813-3.326-12.384-3.477-14.59zm-8.028 6.319a1.25 1.25 0 11-1.25 1.25 1.251 1.251 0 011.25-1.25zm-5.846 1.087a.509.509 0 01-.129-.383 6.037 6.037 0 012.858-4.582.5.5 0 01.711.2l.9 1.8a.5.5 0 01-.168.638A3 3 0 008.94 15.5H6.461a.5.5 0 01-.369-.163zm9.25 4.907a.5.5 0 01-.226.332 5.919 5.919 0 01-6.355 0 .5.5 0 01-.135-.723l1.2-1.6a.5.5 0 01.645-.135 3 3 0 002.932 0 .5.5 0 01.644.135l1.2 1.6a.5.5 0 01.095.391zm2.443-4.908a.5.5 0 01-.369.163h-2.011a.5.5 0 01-.5-.429 3 3 0 00-1.3-2.061.5.5 0 01-.167-.639l.9-1.8a.5.5 0 01.711-.2 6.033 6.033 0 012.858 4.582.5.5 0 01-.122.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});