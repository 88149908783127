define("ember-svg-jar/inlined/wedding-groom-cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-groom-cake</title><path d=\"M22 13.5h-2.5a.5.5 0 010-1H22a1 1 0 001-1V9a2 2 0 00-.8-1.6.5.5 0 01-.2-.4V5.5a2 2 0 00-2-2h-.7a.25.25 0 01-.178-.425l1.01-1.025a1.159 1.159 0 00.235-1.386 1.294 1.294 0 00-2.019-.312.5.5 0 01-.7 0 1.294 1.294 0 00-2.019.312 1.161 1.161 0 00.236 1.386l1.01 1.025a.249.249 0 01-.175.425H16a2 2 0 00-2 2V7a.5.5 0 01-.2.4A2 2 0 0013 9v2.5a1 1 0 001 1h2.5a.5.5 0 010 1H14a2 2 0 00-2 2V23a1 1 0 002 0v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a1 1 0 002 0v-7.5a2 2 0 00-2-2zM16 6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zm-.5 4.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5zM7.306 8.355a.249.249 0 01-.019-.436 4.276 4.276 0 10-4.468 0 .25.25 0 01-.02.436A5.059 5.059 0 000 12.877v3.037a.5.5 0 00.5.5h1.371a.5.5 0 01.5.45l.669 6.687a.5.5 0 00.5.45h3.03a.5.5 0 00.5-.45l.669-6.687a.5.5 0 01.5-.45h1.37a.5.5 0 00.5-.5v-3.037a5.059 5.059 0 00-2.803-4.522zM5.8 12.016a.75.75 0 01-1.5 0v-1.8a.75.75 0 011.5 0zm-.75-5.465A2.275 2.275 0 012.886 3.58a.252.252 0 01.153-.159.247.247 0 01.218.024 5.415 5.415 0 002.887.83A5.5 5.5 0 007.03 4.2a.251.251 0 01.29.268 2.279 2.279 0 01-2.267 2.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});