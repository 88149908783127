define("ember-svg-jar/inlined/social-profile-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-network</title><path d=\"M22.25 10.917a2.5 2.5 0 00-2.5-2.5h-1.5a1 1 0 000 2h1.5a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-3a1 1 0 00-2 0v5.5a2.5 2.5 0 002.5 2.5h4.5a2.5 2.5 0 002.5-2.5z\"/><path d=\"M18 .333a3.5 3.5 0 00-3.5 3.5 3.39 3.39 0 00.287 1.352.5.5 0 01-.105.552l-.968.968a.5.5 0 01-.54.111 3.061 3.061 0 00-2.345 0 .5.5 0 01-.541-.11L6.874 3.294a.5.5 0 01-.143-.416 2.4 2.4 0 00.019-.295 2.5 2.5 0 10-2.5 2.5 2.412 2.412 0 00.947-.2.5.5 0 01.55.107l3.127 3.131a.5.5 0 01.11.541 3.057 3.057 0 00-.038 2.25.5.5 0 01-.222.606l-2.6 1.488a.5.5 0 01-.524-.016 2.449 2.449 0 00-1.347-.407 2.5 2.5 0 000 5 2.473 2.473 0 002.47-2.352.5.5 0 01.251-.408l3.417-1.953a.5.5 0 01.438-.028 3.082 3.082 0 001.171.241 3.254 3.254 0 003.25-3.25 3.137 3.137 0 00-.232-1.173.5.5 0 01.11-.541l.968-.968a.5.5 0 01.553-.1A3.368 3.368 0 0018 7.333a3.5 3.5 0 000-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});