define("ember-svg-jar/inlined/door-password-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>door-password-lock</title><path d=\"M.5 11.5h11a.5.5 0 00.5-.5V3.5A3.5 3.5 0 008.5 0h-5A3.5 3.5 0 000 3.5V11a.5.5 0 00.5.5zm9-8a1 1 0 11-1 1 1 1 0 011-1zm0 3.5a1 1 0 11-1 1 1 1 0 011-1zM6 3.5a1 1 0 11-1 1 1 1 0 011-1zM6 7a1 1 0 11-1 1 1 1 0 011-1zM2.5 3.5a1 1 0 11-1 1 1 1 0 011-1zm0 3.5a1 1 0 11-1 1 1 1 0 011-1zM11.482 21.5H6a3 3 0 010-6h5.75a.25.25 0 00.25-.25V13.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.5.5v7A3.5 3.5 0 003.5 24h5a3.486 3.486 0 003.213-2.154.25.25 0 00-.231-.346z\"/><path d=\"M21.019 17.862l-1.037-.3A14.74 14.74 0 0015.939 17H6a1.5 1.5 0 000 3h9.939a14.74 14.74 0 004.043-.566l1.037-.3a.663.663 0 000-1.275z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});