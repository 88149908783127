define("ember-svg-jar/inlined/medical-specialty-pregnancy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-pregnancy</title><path d=\"M17.562 2.219a3.748 3.748 0 00-2.839 1.346.424.424 0 01-.723-.3V.5a.5.5 0 00-.5-.5H10a.5.5 0 00-.5.5v2.82a4.616 4.616 0 001.387 3.222 3.576 3.576 0 01.477 4.438A8.718 8.718 0 014 15a4 4 0 00-4 4v4.5a.5.5 0 00.5.5H4a.5.5 0 00.5-.5v-3.232a1.011 1.011 0 011.052-.993c.323-.062.848.532 1.142.838A9.193 9.193 0 0017 22.386c3.386-1.374 7-5.073 7-11.335 0-5.2-2.647-8.832-6.438-8.832zM13.805 20.5a4.934 4.934 0 01-4.08-2.058 1.225 1.225 0 01-.152-1.126 1.249 1.249 0 01.841-.779 5.21 5.21 0 011.417-.2 1.2 1.2 0 001.064-.714 4.282 4.282 0 013.4-2.3 2.525 2.525 0 012.491 1.543 2.955 2.955 0 01.253 1.178c.003 2.378-2.439 4.456-5.234 4.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});