define("ember-svg-jar/inlined/notes-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-search</title><path d=\"M12.008 4.767v.25a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1a.25.25 0 01.25.25v4.56a.245.245 0 00.257.249c.4-.02 1.083-.02 1.487 0a.245.245 0 00.256-.249v-4.81a2 2 0 00-2-2h-1.25a.25.25 0 01-.25-.25v-1.25a1 1 0 10-2 0v1.25a.249.249 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.25a1 1 0 10-2 0v1.25a.249.249 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v1.25a.249.249 0 01-.25.25h-1.75a2 2 0 00-2 2v13a2 2 0 002 2h7.81a.244.244 0 00.223-.343 6.957 6.957 0 01-.42-1.453.242.242 0 00-.238-.2H2.258a.25.25 0 01-.25-.25V4.767a.25.25 0 01.25-.25h1.5a.249.249 0 01.25.25v.25a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.249.249 0 01.25.25v.25a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.249.249 0 01.25.25z\"/><path d=\"M23.7 22.276l-2.506-2.5a.242.242 0 01-.03-.3 5.515 5.515 0 10-1.688 1.688.242.242 0 01.3.031l2.506 2.505a1 1 0 001.418-1.424zm-7.174-2.259a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.501 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});