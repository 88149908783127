define("ember-svg-jar/inlined/image-document-eps-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-eps-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM7 14.875a.625.625 0 010 1.25H5.375a.25.25 0 00-.25.25V18a.875.875 0 00.875.875h2.5a.625.625 0 010 1.25H6A2.124 2.124 0 013.875 18v-5A2.124 2.124 0 016 10.875h2.5a.625.625 0 010 1.25H6a.875.875 0 00-.875.875v1.625a.25.25 0 00.25.25zm5.4 1.25h-1.025a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.629 2.629 0 012.574 3.149 2.7 2.7 0 01-2.674 2.101zm5.409-1.672l1.949.976a2.479 2.479 0 01-1.108 4.7H16.5a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.328l-1.949-.976a2.479 2.479 0 011.108-4.7H20.5a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328z\"/><path d=\"M12.5 12.125h-1.125a.25.25 0 00-.25.25v2.25a.25.25 0 00.25.25H12.5a1.375 1.375 0 000-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});