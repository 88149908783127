define("ember-svg-jar/inlined/crime-man-thug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-man-thug</title><circle cx=\"13.979\" cy=\"8.412\" r=\".99\"/><circle cx=\"10.021\" cy=\"8.412\" r=\".99\"/><path d=\"M18.928 8.907V6.928a6.928 6.928 0 00-13.856 0v1.979a6.928 6.928 0 0013.856 0zm-11.876 0V6.928a4.948 4.948 0 119.9 0v1.979a4.87 4.87 0 01-.844 2.728.248.248 0 01-.4.02 4.789 4.789 0 00-7.416 0 .248.248 0 01-.4-.02 4.862 4.862 0 01-.84-2.728zm3.711 3.464a.742.742 0 01.742-.742h.99a.742.742 0 010 1.484h-.99a.742.742 0 01-.742-.742z\"/><path d=\"M.124 23.01a.99.99 0 101.979 0v-1.837a.965.965 0 01.373-.776 10.875 10.875 0 012.756-1.48.248.248 0 01.335.231v4.357a.248.248 0 00.247.248h12.372a.248.248 0 00.247-.248v-4.357a.248.248 0 01.335-.231 10.9 10.9 0 012.755 1.483.966.966 0 01.374.777v1.833a.99.99 0 101.979 0v-1.837a2.931 2.931 0 00-1.151-2.35c-1.463-1.116-4.8-2.988-10.725-2.988s-9.262 1.872-10.726 2.989a2.93 2.93 0 00-1.15 2.349zm14.633-5.036a.248.248 0 01.161.405 3.715 3.715 0 01-5.836 0 .248.248 0 01.16-.4 23.842 23.842 0 015.515 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});