define("ember-svg-jar/inlined/railroad-locomotive-cargo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-locomotive-cargo</title><path d=\"M1.5 13.25h21a.5.5 0 00.5-.5v-8.5a1.5 1.5 0 00-1.5-1.5h-19A1.5 1.5 0 001 4.25v8.5a.5.5 0 00.5.5zm16.45-7.5a.8.8 0 011.6 0v4.5a.8.8 0 01-1.6 0zm-4.5 0a.8.8 0 011.6 0v4.5a.8.8 0 01-1.6 0zm-4.5 0a.8.8 0 111.6 0v4.5a.8.8 0 01-1.6 0zm-4.5 0a.8.8 0 011.6 0v4.5a.8.8 0 11-1.6 0z\"/><circle cx=\"2.667\" cy=\"19.25\" r=\"2\"/><circle cx=\"7.333\" cy=\"19.25\" r=\"2\"/><circle cx=\"21.333\" cy=\"19.25\" r=\"2\"/><circle cx=\"16.667\" cy=\"19.25\" r=\"2\"/><path d=\"M23 14.5H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});