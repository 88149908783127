define("ember-svg-jar/inlined/presentation-projector-screen-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-projector-screen-image</title><path d=\"M24 1.75a1.5 1.5 0 00-1.5-1.5h-21a1.5 1.5 0 00-.214 2.984.25.25 0 01.214.248v14.543a.251.251 0 01-.2.245 1 1 0 00.2 1.98H11a.249.249 0 01.25.25v.314a.249.249 0 01-.107.2 1.5 1.5 0 101.714 0 .249.249 0 01-.107-.2V20.5a.249.249 0 01.25-.25h9.5a1 1 0 00.2-1.98.249.249 0 01-.2-.245V3.482a.251.251 0 01.214-.248A1.5 1.5 0 0024 1.75zM20.5 18a.25.25 0 01-.25.25H3.75A.25.25 0 013.5 18V3.5a.249.249 0 01.25-.25h16.5a.249.249 0 01.25.25z\"/><path d=\"M15.512 9.751a1.054 1.054 0 00-.844-.613.992.992 0 00-.878.514l-.005.008-1.64 2.806a.25.25 0 01-.185.122.254.254 0 01-.209-.073L10.059 10.8a.194.194 0 00-.026-.023.91.91 0 00-1.366.3l-.006.01-2.441 4.17a.25.25 0 00.216.376h11.657a.249.249 0 00.222-.363z\"/><circle cx=\"8.011\" cy=\"7.75\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});