define("ember-svg-jar/inlined/asian-interior-door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-interior-door</title><path d=\"M10.5 5.25A1.752 1.752 0 008.75 3.5h-7A1.752 1.752 0 000 5.25V22.5A1.5 1.5 0 001.5 24H9a1.5 1.5 0 001.5-1.5zM6 5.5a.5.5 0 01.5-.5h2.25a.25.25 0 01.25.25V7.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zM6 10a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm2.5 4.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5zm-7-9.25A.25.25 0 011.75 5H4a.5.5 0 01.5.5v2A.5.5 0 014 8H2a.5.5 0 01-.5-.5zm0 4.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5zM4 14.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5zM23.5 5.25a1.752 1.752 0 00-1.75-1.75h-7A1.752 1.752 0 0013 5.25V22.5a1.5 1.5 0 001.5 1.5H22a1.5 1.5 0 001.5-1.5zM19 5.5a.5.5 0 01.5-.5h2.25a.25.25 0 01.25.25V7.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm2.5 4.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5zm-7-9.25a.25.25 0 01.25-.25H17a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm0 4.75a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5zm2.5 4.5a.5.5 0 01.5.5v2.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5zM23 0H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});