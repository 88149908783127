define("ember-svg-jar/inlined/tools-screwdriver-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-screwdriver-1</title><path d=\"M23.46.541a.748.748 0 00-.677-.2l-5.3 1.06a.749.749 0 00-.383.199l-1.772 1.769a.75.75 0 001.061 1.06l1.5-1.5a.5.5 0 01.255-.136l1.256-.251a.25.25 0 01.226.422l-7.829 7.829a.251.251 0 000 .354l1.06 1.061a.25.25 0 00.354 0l7.829-7.83a.252.252 0 01.295-.043.249.249 0 01.127.269l-.25 1.252a.506.506 0 01-.137.255l-1.5 1.5a.75.75 0 101.06 1.061L22.4 6.9a.737.737 0 00.2-.383l1.061-5.3a.75.75 0 00-.201-.676zM8.434 9.909a1 1 0 10-1.414 1.414l.051.052a.5.5 0 010 .707l-5.777 5.777a3.334 3.334 0 000 4.709l.138.138a3.335 3.335 0 004.709 0l5.777-5.777a.5.5 0 01.708 0l.051.051a1 1 0 001.414-1.414zm1.085 3.778l-6.5 6.5a.5.5 0 11-.707-.707l6.5-6.5a.5.5 0 01.707.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});