define("ember-svg-jar/inlined/gesture-tap-swipe-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-down</title><path d=\"M17.126 20.5h-1.25a.25.25 0 01-.25-.25V15.5a1 1 0 00-2 0v4.75a.25.25 0 01-.25.25h-1.25a.75.75 0 00-.469 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.468-1.335z\"/><path d=\"M13.126 0a7.246 7.246 0 00-3.843 1.1.75.75 0 10.8 1.272 5.751 5.751 0 11-.012 9.745.75.75 0 10-.8 1.27A7.25 7.25 0 1013.126 0z\"/><path d=\"M16.624 7.254a3.5 3.5 0 00-3.5-3.5h-9a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h9a3.5 3.5 0 003.5-3.5zm-5 1.125v-2.25A.375.375 0 0112 5.754h1.125a1.5 1.5 0 010 3H12a.375.375 0 01-.376-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});