define("ember-svg-jar/inlined/paginate-filter-plus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-plus-1</title><path d=\"M24 2a2 2 0 00-2-2H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2zm-11.5 8.75h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1A.25.25 0 009.75 9V8a.75.75 0 011.5 0v1a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm6.237-5.111l-1.5 8a.75.75 0 01-1.474-.278l1.128-6.013-.922.738a.751.751 0 01-.938-1.172l2.5-2a.75.75 0 011.206.725z\"/><path d=\"M20.5 23a1 1 0 00-1-1h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});