define("ember-svg-jar/inlined/car-dashboard-fuel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-fuel</title><path d=\"M22.405 13.031a.75.75 0 000-1.061l-1.491-1.491a.752.752 0 00-.7-.212l-4.172.835a.249.249 0 01-.281-.152l-.331-.826a2.173 2.173 0 00-2.025-1.374h-1.78a.249.249 0 01-.25-.25v-.75a.25.25 0 01.25-.25h.75a1 1 0 000-2h-4a1 1 0 000 2h.75a.25.25 0 01.25.25v.75a.249.249 0 01-.25.25H5.052a1.429 1.429 0 00-1.427 1.428v3.669a2.406 2.406 0 002.4 2.4h8.066A4.087 4.087 0 0016.83 15.2l3.341-3.009a.25.25 0 01.344.009l.83.83a.749.749 0 001.06.001zM3.582 5.793a1 1 0 00-1.414 0l-1.75 1.75a1 1 0 001.414 1.415l1.75-1.75a1 1 0 000-1.415zM22.739 14.658a.5.5 0 00-.364-.158.405.405 0 00-.371.165A4.288 4.288 0 0020.875 17a1.556 1.556 0 001.531 1.5 1.513 1.513 0 001.469-1.5 4.117 4.117 0 00-1.136-2.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});