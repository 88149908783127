define("ember-svg-jar/inlined/email-action-download-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-download_1</title><path d=\"M17.5 9.5a7.908 7.908 0 013.151.657.251.251 0 00.349-.23V1.75a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.164a2.407 2.407 0 01-3.4 0L.271 1.637A.159.159 0 000 1.75v10.5A1.749 1.749 0 001.75 14h8.432a.219.219 0 00.2-.119A8 8 0 0117.5 9.5z\"/><path d=\"M9.726 9.236a1.094 1.094 0 001.547 0L19.748.761A.437.437 0 0019.5.019 1.62 1.62 0 0019.249 0h-17.5A1.62 1.62 0 001.5.019a.437.437 0 00-.352.3.441.441 0 00.102.442zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.391 7.312l-2 2.5a.5.5 0 01-.782 0l-2-2.5a.5.5 0 01.391-.812h1a.25.25 0 00.25-.25V14.5a.75.75 0 011.5 0v2.75a.25.25 0 00.25.25h1a.5.5 0 01.391.812z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});