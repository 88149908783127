define("ember-svg-jar/inlined/paginate-filter-camera-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-camera-alternate</title><path d=\"M19.472 7.115a1 1 0 00-1.027.05l-1.164.777a.253.253 0 01-.225.027.25.25 0 01-.156-.169A2.5 2.5 0 0014.5 6H10a2.5 2.5 0 00-2.5 2.5v4A2.5 2.5 0 0010 15h4.5a2.5 2.5 0 002.4-1.806.25.25 0 01.379-.138l1.164.776A1 1 0 0020 13V8a1 1 0 00-.528-.885z\"/><path d=\"M22 0H5.5a2 2 0 00-2 2v16.5a2 2 0 002 2H22a2 2 0 002-2V2a2 2 0 00-2-2zm-.145 18.354a.5.5 0 01-.354.146H6a.5.5 0 01-.5-.5V2.5A.5.5 0 016 2h15.5a.5.5 0 01.5.5V18a.5.5 0 01-.145.351z\"/><path d=\"M19.5 22h-17a.5.5 0 01-.5-.5v-17a1 1 0 00-2 0V22a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});