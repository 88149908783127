define("ember-svg-jar/inlined/walkman-cassette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>walkman-cassette</title><path d=\"M24 6a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h.586a.251.251 0 00.177-.073l3.121-3.121A2.729 2.729 0 017.828 16h8.344a2.729 2.729 0 011.944.806l3.121 3.121a.251.251 0 00.177.073H22a2 2 0 002-2zm-5.5 7.5a2.495 2.495 0 112.5-2.495 2.5 2.5 0 01-2.5 2.495zM8 11a2.5 2.5 0 11-2.5-2.489A2.5 2.5 0 018 11zm2 0a4.474 4.474 0 00-.532-2.121.25.25 0 01.22-.368h4.624a.25.25 0 01.22.368 4.5 4.5 0 00.011 4.263.25.25 0 01-.22.369H9.677a.25.25 0 01-.22-.369A4.478 4.478 0 0010 11z\"/><path d=\"M17.056 17.866a1.258 1.258 0 00-.884-.366H7.828a1.258 1.258 0 00-.884.366l-1.707 1.707a.25.25 0 00.177.427h13.172a.25.25 0 00.177-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});