define("ember-svg-jar/inlined/sports-car-convertible", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car-convertible</title><path d=\"M7.25 11h4a.5.5 0 00.5-.5 2.5 2.5 0 00-5 0 .5.5 0 00.5.5z\"/><circle cx=\"6.75\" cy=\"17\" r=\"2\"/><circle cx=\"20.25\" cy=\"17\" r=\"2\"/><path d=\"M22.184 7.242l.882-.294a1 1 0 00-.632-1.9l-4.5 1.5A1 1 0 0018.25 8.5c.081 0 .781-.216 1.1-.315.094-.029.155.006.156.1 0 .438.009 1.727.009 1.883a.35.35 0 01-.387.335h-.611a.6.6 0 01-.391-.275c-.876-1.693-3.171-2.378-4.27-2.616a.5.5 0 00-.6.49v2.4a2 2 0 01-2 2H4.568A4 4 0 00.99 14.711l-.714 1.427A.25.25 0 00.5 16.5h3.1a.244.244 0 00.236-.193 2.989 2.989 0 015.82 0 .244.244 0 00.244.193h7.2a.244.244 0 00.236-.193 2.99 2.99 0 015.86.193h.05v-5a1 1 0 00-1-1 .2.2 0 01-.236-.218l-.011-2.754c.001-.141.185-.286.185-.286z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});