define("ember-svg-jar/inlined/professions-man-construction-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-man-construction-2</title><path d=\"M24 21.269a2.959 2.959 0 00-1.164-2.369 15.684 15.684 0 00-7.588-2.816.25.25 0 01-.081-.47A7 7 0 0019 9.375v-1a.25.25 0 01.25-.25h.25a.75.75 0 000-1.5h-.32a.25.25 0 01-.248-.215 7 7 0 00-3.14-4.91.251.251 0 00-.377.145L14.224 6.07a.75.75 0 01-.724.555.732.732 0 01-.2-.026.749.749 0 01-.529-.919L14.084.819a.242.242 0 00-.113-.282A4.554 4.554 0 0012 .125a4.563 4.563 0 00-1.972.412.243.243 0 00-.112.282l1.308 4.861a.75.75 0 11-1.448.39L8.585 1.646a.251.251 0 00-.377-.146 7 7 0 00-3.14 4.91.25.25 0 01-.248.215H4.5a.75.75 0 000 1.5h.25a.25.25 0 01.25.25v1a7 7 0 003.833 6.234.25.25 0 01-.081.47A15.694 15.694 0 001.163 18.9 2.961 2.961 0 000 21.269v1.606a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 10.609 10.609 0 012.276-1.292.254.254 0 01.236.022.25.25 0 01.112.209v4.2a.25.25 0 00.25.25H11a.25.25 0 00.25-.25v-2.951a.252.252 0 00-.067-.171l-1.934-2.067a.25.25 0 01.155-.419 24.139 24.139 0 015.192 0 .25.25 0 01.155.419L12.817 20.5a.252.252 0 00-.067.171v2.953a.25.25 0 00.25.25h5.75a.25.25 0 00.25-.25v-4.2a.248.248 0 01.112-.208.251.251 0 01.236-.022 10.572 10.572 0 012.275 1.292.978.978 0 01.377.785v1.606a1 1 0 002 0zM7 9.375v-1a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25v1a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"9.625\" r=\"1\"/><circle cx=\"10\" cy=\"9.625\" r=\"1\"/><path d=\"M12.082 13.244a2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2h-2a.639.639 0 00-.3 1.2 2.31 2.31 0 00.435.18 2.99 2.99 0 00.867.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});