define("ember-svg-jar/inlined/social-music-spotify-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-music-spotify-2</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm5 18.1a.9.9 0 01-1.27.08 8.37 8.37 0 00-4.27-1.78 8.4 8.4 0 00-4.57.52.9.9 0 11-.76-1.63 10.07 10.07 0 015.58-.67 10.05 10.05 0 015.2 2.21.9.9 0 01.09 1.27zm1.19-3.78a1 1 0 01-1.41.09 9.35 9.35 0 00-4.75-2 9.33 9.33 0 00-5.11.59 1 1 0 11-.85-1.81 11.21 11.21 0 016.25-.76 11.22 11.22 0 015.79 2.45 1 1 0 01.09 1.44zm1.58-3.62a1.15 1.15 0 01-1.62.08 10.62 10.62 0 00-5.55-2.35 10.61 10.61 0 00-6 .72 1.15 1.15 0 01-1-2.07 13 13 0 017.29-.93 13 13 0 016.81 2.92 1.15 1.15 0 01.08 1.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});