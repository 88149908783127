define("ember-svg-jar/inlined/style-two-pin-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-information</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm.5 3.75a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm3.25 12a1 1 0 01-1 1h-4a1 1 0 010-2h.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-.5a1 1 0 010-2h1a2 2 0 012 2v5h1a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});