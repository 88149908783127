define("ember-svg-jar/inlined/amusement-park-ferris-wheel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amusement-park-ferris-wheel</title><path d=\"M21.938 9.183a.5.5 0 01-.294-.331 9.928 9.928 0 00-.954-2.295.5.5 0 01-.026-.444 2.5 2.5 0 00-3.277-3.277.5.5 0 01-.443-.026 9.935 9.935 0 00-2.3-.954.5.5 0 01-.331-.294 2.5 2.5 0 00-4.634 0 .5.5 0 01-.331.294 9.928 9.928 0 00-2.295.954.5.5 0 01-.444.026 2.5 2.5 0 00-3.273 3.277.506.506 0 01-.026.444 9.882 9.882 0 00-.953 2.3.5.5 0 01-.3.331 2.5 2.5 0 000 4.632.5.5 0 01.293.332 9.932 9.932 0 002.731 4.575 1 1 0 101.384-1.444A7.94 7.94 0 014.395 14a.5.5 0 01.163-.546 2.5 2.5 0 000-3.9A.5.5 0 014.4 9a7.969 7.969 0 01.461-1.111.5.5 0 01.5-.27 2.5 2.5 0 002.759-2.76.5.5 0 01.271-.5A8.1 8.1 0 019.5 3.9a.5.5 0 01.546.163 2.5 2.5 0 003.9 0A.5.5 0 0114.5 3.9a8.1 8.1 0 011.111.462.5.5 0 01.271.5 2.5 2.5 0 002.759 2.76.5.5 0 01.5.27A8.1 8.1 0 0119.6 9a.5.5 0 01-.163.546 2.5 2.5 0 000 3.905.5.5 0 01.164.546 7.951 7.951 0 01-2.07 3.278 1 1 0 101.384 1.444 9.915 9.915 0 002.729-4.575.5.5 0 01.294-.332 2.5 2.5 0 00-.005-4.632z\"/><path d=\"M21 22h-3a.5.5 0 01-.461-.307L13.845 12.9a.249.249 0 01.042-.261 2.5 2.5 0 10-3.774 0 .249.249 0 01.042.261l-3.691 8.793A.5.5 0 016 22H3a1 1 0 000 2h18a1 1 0 000-2zm-5.672-.112a.251.251 0 01-.208.112H8.88a.25.25 0 01-.23-.347l3.242-7.724a.117.117 0 01.216 0l3.242 7.724a.249.249 0 01-.022.235z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});