define("ember-svg-jar/inlined/athletics-running-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-running-1</title><path d=\"M22.214 2.75H23.5a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H12a12 12 0 000 24h11.5a.5.5 0 00.5-.5v-1.75a.5.5 0 00-.5-.5h-1a.75.75 0 010-1.5h1a.5.5 0 00.5-.5V17.5a.5.5 0 00-.5-.5h-5.25a.5.5 0 01-.5-.5v-.96a.5.5 0 01.5-.5h4a1 1 0 001-1v-3a1 1 0 00-1-1h-4.085a.5.5 0 01-.472-.333 1 1 0 00-1.943.333v11.5a.75.75 0 01-1.5 0V17.5a.5.5 0 00-.5-.5H13a5 5 0 010-10h10.5a.5.5 0 00.5-.5V4.75a.5.5 0 00-.5-.5h-1.286a.75.75 0 010-1.5zm-4.464 17.5a.5.5 0 01.5-.5h.178a.75.75 0 010 1.5h-.178a.5.5 0 01-.5-.5zm-8.178-.157a.746.746 0 01.9-.555 7.783 7.783 0 001.81.212.765.765 0 01.767.75.737.737 0 01-.734.75 9.339 9.339 0 01-2.188-.25.751.751 0 01-.555-.907zM4.55 6.927a9.32 9.32 0 011.424-1.689A.75.75 0 117 6.334 7.774 7.774 0 005.8 7.75a.75.75 0 11-1.25-.823zm-.582 6.986a.751.751 0 01-.846-.641A9.187 9.187 0 013.036 12a9.371 9.371 0 01.047-.936.76.76 0 01.822-.67.75.75 0 01.67.822 7.933 7.933 0 00.033 1.852.75.75 0 01-.64.845zm3.315 5a.75.75 0 01-1.058.074 9.28 9.28 0 01-1.484-1.634.75.75 0 011.221-.871 7.846 7.846 0 001.246 1.372.752.752 0 01.075 1.06zm4.749-14.66a7.733 7.733 0 00-1.83.279.75.75 0 11-.4-1.445 9.2 9.2 0 012.184-.333.733.733 0 01.774.725.75.75 0 01-.728.775zm6.085 0h-2.036a.75.75 0 010-1.5h2.036a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});