define("ember-svg-jar/inlined/people-man-beanie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beanie</title><path d=\"M12.643 13.328a1.966 1.966 0 01-1.634 0 .75.75 0 00-.711 1.321 3.433 3.433 0 003.059 0 .749.749 0 00-.714-1.318zM10.893 11.027a.751.751 0 00-.054-1.059 2.094 2.094 0 00-2.637 0 .751.751 0 001.006 1.114.628.628 0 01.625 0 .752.752 0 001.06-.055zM15.458 11.027a.751.751 0 00-.058-1.059 2.093 2.093 0 00-2.636 0 .75.75 0 001 1.114.628.628 0 01.625 0 .752.752 0 001.069-.055z\"/><path d=\"M22.685 20.218A13.717 13.717 0 0018 18.006a20.873 20.873 0 00-.775-.194 2.86 2.86 0 00.111-.744 2.361 2.361 0 00-.628-1.747 5.068 5.068 0 00.865-2.063.252.252 0 01.143-.187 2.725 2.725 0 001.434-2.432 2.1 2.1 0 00-.454-1.41 1.341 1.341 0 001.054-1.354v-1.25A1.319 1.319 0 0018.5 5.25h-.2C17.807 2.6 16.684 0 12 0S6.193 2.6 5.7 5.25h-.2a1.319 1.319 0 00-1.25 1.375v1.25a1.363 1.363 0 00.888 1.31 2.093 2.093 0 00-.486 1.454 2.728 2.728 0 001.435 2.432.248.248 0 01.142.188 5.67 5.67 0 001.91 3.228c-.046 0-.093.01-.139.013a.989.989 0 00-.865.527A.97.97 0 007 17.5v.27a4.425 4.425 0 00-1 .236 13.717 13.717 0 00-4.685 2.212A2.715 2.715 0 00.25 22.394V23a.75.75 0 001.5 0v-.606a1.224 1.224 0 01.476-.983 12.254 12.254 0 014.263-1.982c.861-.233.816-.175 1.011-.3V23.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3.025c.167.008.329.025.5.025 2.262 0 3.65-.6 4.447-1.4.2.149.213.1 1.064.332a12.254 12.254 0 014.263 1.982 1.224 1.224 0 01.476.983V23a.75.75 0 001.5 0v-.606a2.715 2.715 0 00-1.065-2.176zM6 7.75a.25.25 0 01-.25-.25V7A.25.25 0 016 6.75h12a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25zm1.71 5.262a1.752 1.752 0 00-1-1.306 1.281 1.281 0 01-.557-1.1.625.625 0 01.105-.44.74.74 0 00.358-.92h10.573a.74.74 0 00.358.92.634.634 0 01.1.44 1.28 1.28 0 01-.556 1.1 1.747 1.747 0 00-1 1.306 4.189 4.189 0 01-1.452 2.37 33.97 33.97 0 01-4.24.868 4.956 4.956 0 01-2.689-3.238z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});