define("ember-svg-jar/inlined/book-library-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-library-1</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5zm-1 10.25a.75.75 0 01-.75.75H1.75a.75.75 0 010-1.5H4.5a.5.5 0 00.5-.5V3.25a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v7.25a.5.5 0 00.5.5h.75a.5.5 0 00.5-.5V4.25a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v6.25a.5.5 0 00.5.5h3.221a.25.25 0 00.237-.331l-2.554-7.44a.468.468 0 01.291-.6l1.773-.609a.468.468 0 01.595.291l2.864 8.346a.5.5 0 00.473.343h1.1a.75.75 0 01.75.75zm-2.5 3.75v6a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm-4.5 1v5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm-4.5.75v4.25a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5v-4.25a.5.5 0 01.5-.5H11a.5.5 0 01.5.5zm-4-1.75v6a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5v-6A.5.5 0 014 15h3a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});