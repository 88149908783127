define("ember-svg-jar/inlined/allowances-no-pushing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>allowances-no-pushing</title><circle cx=\"11.616\" cy=\"4.408\" r=\"2.204\"/><path d=\"M6.093 18.612a1.224 1.224 0 001.107-.7l1.25-2.654a.243.243 0 01.307-.125l1.758.66a.244.244 0 01.141.32l-.329.823a1.224 1.224 0 001.136 1.679 1.223 1.223 0 001.136-.769l.3-.754a.244.244 0 00-.055-.264l-5.084-5.09a.244.244 0 00-.394.069l-2.381 5.06a1.224 1.224 0 001.108 1.745z\"/><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zM12 1.959a9.957 9.957 0 015.979 1.994.243.243 0 01.086.273.245.245 0 01-.232.168h-1.425a.98.98 0 00-.979.979v1.642a.244.244 0 00.157.229 2.188 2.188 0 010 4.1.246.246 0 00-.157.229v2.373a.245.245 0 01-.418.173L12.392 11.5l-.98-.979H14.8a1.225 1.225 0 100-2.449H9.9a1.187 1.187 0 00-.555.14.244.244 0 01-.287-.044l-.551-.55-2.95-2.95a.242.242 0 01-.071-.185.246.246 0 01.089-.183A9.981 9.981 0 0112 1.959zm5.89 17.806a.245.245 0 01-.029.371 9.575 9.575 0 01-1.037.666 9.979 9.979 0 01-10.065-.253.245.245 0 01.128-.453h7.039a.734.734 0 00.735-.747.735.735 0 00-.735-.722H4.587a.243.243 0 01-.185-.085A9.993 9.993 0 013.2 7.176a9.575 9.575 0 01.666-1.037.245.245 0 01.371-.029zm1.494-1.277a.122.122 0 01-.037-.089V5.838a.245.245 0 01.435-.155 9.975 9.975 0 01-.171 12.842.132.132 0 01-.091.044.125.125 0 01-.093-.037z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});