define("ember-svg-jar/inlined/tablet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tablet</title><path d=\"M19 0H5a2.5 2.5 0 00-2.5 2.5v19A2.5 2.5 0 005 24h14a2.5 2.5 0 002.5-2.5v-19A2.5 2.5 0 0019 0zM5 4.5a1 1 0 011-1h12a1 1 0 011 1v14a1 1 0 01-1 1H6a1 1 0 01-1-1z\"/><rect x=\"6.75\" y=\"6.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"10.75\" y=\"6.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"14.75\" y=\"6.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"6.75\" y=\"10.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"10.75\" y=\"10.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"6.75\" y=\"14.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"10.75\" y=\"14.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/><rect x=\"14.75\" y=\"10.251\" width=\"2.5\" height=\"2.5\" rx=\".75\" ry=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});