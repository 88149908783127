define("ember-svg-jar/inlined/app-window-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-flag</title><path d=\"M13.8 23a1 1 0 002 0v-3.585a.25.25 0 01.17-.237l.082-.028a2.805 2.805 0 012.5.341 4.31 4.31 0 002.42.737 4.537 4.537 0 001.473-.248l.932-.429a.749.749 0 00.436-.681v-5.5a.75.75 0 00-1.065-.681l-.858.4a2.8 2.8 0 01-2.5-.341 4.348 4.348 0 00-3.819-.519l-1.268.43a.75.75 0 00-.51.71z\"/><path d=\"M11.464 19.222a.97.97 0 00-.951-.721H3a1 1 0 01-1-1V5.251A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V9a1 1 0 00.388.777l.022.018A.983.983 0 0024 9.018V3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h7.52a1 1 0 00.949-1.256zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5zm-3.562.091A1 1 0 013.5 1.5a.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});