define("ember-svg-jar/inlined/road-sign-obstruction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-obstruction</title><path d=\"M17.1 12.683a.251.251 0 00-.273.055l-10 10a.249.249 0 00-.073.177v.336A.75.75 0 007.5 24h1.585a.249.249 0 00.177-.073l7.915-7.914a.249.249 0 00.073-.177v-2.922a.251.251 0 00-.15-.231zM17.228.724a.027.027 0 00-.03.018.672.672 0 01-.168.288L6.823 11.237a.251.251 0 00-.073.177v2.922a.25.25 0 00.427.177l9.888-9.888a.5.5 0 01.073-.059.252.252 0 00.112-.209V.75a.027.027 0 00-.022-.026zM17.1 6.934a.249.249 0 00-.273.054l-10 10a.251.251 0 00-.073.177v2.922a.25.25 0 00.427.176l10-10a.249.249 0 00.073-.177V7.165a.249.249 0 00-.154-.231zM6.9 3.317a.249.249 0 00.273-.054l2.84-2.836A.25.25 0 009.836 0H7.5a.75.75 0 00-.75.75v2.336a.25.25 0 00.15.231zM17.1 18.433a.251.251 0 00-.273.055l-5.086 5.085a.25.25 0 00.177.427H16.5a.75.75 0 00.75-.75v-4.586a.251.251 0 00-.15-.231zM6.9 8.817a.251.251 0 00.273-.054l8.34-8.336A.25.25 0 0015.336 0h-2.672a.251.251 0 00-.177.073L6.823 5.738a.249.249 0 00-.073.177v2.671a.251.251 0 00.15.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});