define("ember-svg-jar/inlined/playlist-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-menu</title><path d=\"M13.132 14.5h3.161a.247.247 0 00.244-.25V2.5A2.47 2.47 0 0014.105 0H2.432A2.47 2.47 0 000 2.5v12A2.47 2.47 0 002.432 17h8.511a.247.247 0 00.244-.25v-.25a1.974 1.974 0 011.945-2zm-1.7-6.75A.74.74 0 0110.7 7a2.075 2.075 0 00-1.633-2.087.245.245 0 00-.312.24V11a2.47 2.47 0 01-2.432 2.5 2.5 2.5 0 010-5 2.346 2.346 0 01.663.1.24.24 0 00.214-.046.252.252 0 00.1-.2V4a.74.74 0 01.729-.75c1.43 0 4.134.784 4.134 3.75a.74.74 0 01-.733.75zM22.373 22h-8.755a1 1 0 000 2h8.755a1 1 0 000-2zM22.373 19h-8.755a1 1 0 000 2h8.755a1 1 0 000-2zM22.373 16h-8.755a1 1 0 000 2h8.755a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});