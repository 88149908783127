define("ember-svg-jar/inlined/social-media-google-plus-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-google-plus-2</title><path d=\"M12.6 12.69c-1.18-.8-1.46-1.06-1.24-1.76a5.24 5.24 0 00.75-.61 5.66 5.66 0 000-7.81h2.29a1 1 0 100-2h-7a6 6 0 00-3.51 1.58 5.68 5.68 0 00.21 8 6 6 0 004.2 1.76 5.61 5.61 0 001-.1 3.1 3.1 0 001.16 1.86A12.69 12.69 0 008 13.38c-4.49 0-8 2.22-8 5.06s3.51 5.06 8 5.06 8-2.22 8-5.06c0-3.44-2-4.84-3.4-5.75zm-7.09-4A3.68 3.68 0 015.3 3.5a3.35 3.35 0 012.4-1h.12a4 4 0 012.67 1.18 3.68 3.68 0 01.21 5.22 3.68 3.68 0 01-5.19-.21zM8 21.5c-3.54 0-6-1.61-6-3.06s2.47-3.06 6-3.06 6 1.61 6 3.06-2.46 3.06-6 3.06z\"/><path d=\"M23 7.5h-3V4a1 1 0 10-2 0v3.5h-3a1 1 0 000 2h3V13a1 1 0 102 0V9.5h3a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});