define("ember-svg-jar/inlined/expand-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-full</title><path d=\"M23.75 2.453a2.206 2.206 0 00-2.2-2.2h-5.732a1.25 1.25 0 000 2.5H21a.25.25 0 01.25.25v3.268a1.25 1.25 0 102.5 0zM.25 6.271a1.25 1.25 0 002.5 0V3A.25.25 0 013 2.749h5.182a1.25 1.25 0 000-2.5H2.455a2.206 2.206 0 00-2.2 2.2zM23.75 21.545v-3.819a1.25 1.25 0 00-2.5 0V21a.25.25 0 01-.25.25h-5.182a1.25 1.25 0 000 2.5h5.727a2.206 2.206 0 002.205-2.205zM.25 21.545a2.206 2.206 0 002.205 2.2h5.727a1.25 1.25 0 000-2.5H3A.25.25 0 012.75 21v-3.274a1.25 1.25 0 00-2.5 0z\"/><rect x=\"5.273\" y=\"8.136\" width=\"13.454\" height=\"7.727\" rx=\"1.954\" ry=\"1.954\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});