define("ember-svg-jar/inlined/agriculture-machine-seeder-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-seeder-2</title><path d=\"M5.554 11.75h12.892a2.483 2.483 0 011.863.833.5.5 0 00.373.167h.818a.5.5 0 00.5-.5V7.165a1.5 1.5 0 001-1.415v-1a1.5 1.5 0 00-1.5-1.5h-19A1.5 1.5 0 001 4.75v1a1.5 1.5 0 001 1.414v5.086a.5.5 0 00.5.5h.818a.5.5 0 00.373-.167 2.485 2.485 0 011.863-.833zM4.5 10.25a.5.5 0 01-.5-.5v-2a1.5 1.5 0 011.5-1.5.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm3.5 0a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm3.5 0a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm3.5 0a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5zm3.5 0a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5 1.5 1.5 0 011.5 1.5v2a.5.5 0 01-.5.5zM2.117 17.461a2.5 2.5 0 01.066-2.535l.514-.821a.232.232 0 00-.2-.355h-1A1.5 1.5 0 000 15.25v4a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-.11a.5.5 0 00-.366-.482 2.5 2.5 0 01-1.517-1.197zM22.5 13.75h-1a.231.231 0 00-.2.354l.512.821a2.5 2.5 0 01-1.451 3.732.5.5 0 00-.366.481v.111a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-4A1.5 1.5 0 0022.5 13.75z\"/><path d=\"M19.7 17.75a1.5 1.5 0 001.272-2.294l-1.25-2a1.5 1.5 0 00-1.272-.7H5.554a1.5 1.5 0 00-1.272.705l-1.25 2A1.5 1.5 0 004.3 17.75h2.34l.386 1.158a.5.5 0 00.948 0l.386-1.158h1.28l.386 1.158a.5.5 0 00.948 0l.386-1.158h1.28l.386 1.158a.5.5 0 00.948 0l.386-1.158h1.28l.386 1.158a.5.5 0 00.948 0l.386-1.158z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});