define("ember-svg-jar/inlined/video-game-character", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-character</title><circle cx=\"7.5\" cy=\"5\" r=\".75\"/><circle cx=\"10.5\" cy=\"5\" r=\".75\"/><path d=\"M2.155 12.873a10.481 10.481 0 001.59.127.5.5 0 01.474.342 7.774 7.774 0 004.316 4.221 1.131 1.131 0 00.93 0 7.774 7.774 0 004.316-4.221.5.5 0 01.474-.342 10.481 10.481 0 001.59-.127c.174-.027.321-.227.374-.508a1.1 1.1 0 00-.127-.784A14.385 14.385 0 0014.9 9.256a1 1 0 01-.127-.8 9.822 9.822 0 00.336-2.353 6.107 6.107 0 10-12.214 0 9.822 9.822 0 00.334 2.357 1 1 0 01-.127.8 14.385 14.385 0 00-1.194 2.325 1.1 1.1 0 00-.127.784c.053.277.2.477.374.504zm8.616-1.986A2.125 2.125 0 018.988 12a2.123 2.123 0 01-1.783-1.113.5.5 0 01.091-.645 2.3 2.3 0 013.383 0 .5.5 0 01.092.645zM9 2a4.112 4.112 0 014.107 4.107c0 .125-.008.3-.03.516a.5.5 0 01-.62.435A1.853 1.853 0 0012 7a3.385 3.385 0 00-2.694 1.084.472.472 0 01-.306.16.472.472 0 01-.306-.16A3.385 3.385 0 006 7a1.853 1.853 0 00-.457.058.5.5 0 01-.62-.435 5.214 5.214 0 01-.03-.516A4.112 4.112 0 019 2z\"/><path d=\"M20.75 4a3.254 3.254 0 00-3.25 3.25V8a1 1 0 002 0v-.75a1.25 1.25 0 012.5 0V8.8a1.494 1.494 0 01-.379 1l-.737.829A3.5 3.5 0 0020 12.95v4.621a.5.5 0 01-.807.395 402.253 402.253 0 01-3.9-3.061.5.5 0 00-.395-.1.5.5 0 00-.342.225 11.7 11.7 0 01-4.944 4.088 1.461 1.461 0 01-1.235 0 11.715 11.715 0 01-4.929-4.078.5.5 0 00-.744-.114c-.328.276-.735.6-1.133.9A4.007 4.007 0 000 19.006V23a1 1 0 001 1h20a1 1 0 001-1V12.95a1.494 1.494 0 01.379-1l.737-.829A3.5 3.5 0 0024 8.8V7.25A3.254 3.254 0 0020.75 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});