define("ember-svg-jar/inlined/arrow-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-up-2</title><path d=\"M3 22.333V8.284a1.5 1.5 0 01.508-1.125l7.5-6.617a1.5 1.5 0 011.985 0l7.5 6.618A1.5 1.5 0 0121 8.284v14.049a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});