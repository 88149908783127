define("ember-svg-jar/inlined/office-business-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-business-card</title><path d=\"M24 6.967a2 2 0 00-2-2H11.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h9.75a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h3.75a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-.5a.25.25 0 01-.25-.25v-.438a1.25 1.25 0 012.5 0v8.438a.688.688 0 01-1.375 0V8.779a1 1 0 00-2 0v3.938a2.688 2.688 0 005.375 0V4.279a3.25 3.25 0 00-6.5 0v.438a.25.25 0 01-.25.25H2a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2z\"/><path d=\"M13.25 14.217h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM7 17.717a.75.75 0 00.75.75h10.5a.75.75 0 000-1.5H7.75a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});