define("ember-svg-jar/inlined/baby-care-diaper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-care-diaper</title><path d=\"M23.382 5.259a.5.5 0 01-.132-.339V3.913a1.665 1.665 0 00-1.663-1.663H2.413A1.665 1.665 0 00.75 3.913V4.92a.5.5 0 01-.132.339A2.332 2.332 0 000 6.842v1.816a2.332 2.332 0 00.618 1.583.5.5 0 01.132.336 11.25 11.25 0 0022.5 0 .5.5 0 01.132-.336A2.332 2.332 0 0024 8.658V6.842a2.332 2.332 0 00-.618-1.583zM2 8.658V6.842a.342.342 0 01.342-.342h3.316A.342.342 0 016 6.842v1.816A.342.342 0 015.658 9H2.342A.342.342 0 012 8.658zm8.153 10.266a.5.5 0 01-.6.344 8.742 8.742 0 01-6.172-5.5.5.5 0 01.314-.647 5.152 5.152 0 011.616-.261 4.912 4.912 0 015.025 4.791 4.463 4.463 0 01-.183 1.273zM12 13.111a.679.679 0 01-.426-.151c-.927-.736-3.043-2.576-3.043-4.022a1.694 1.694 0 013.245-.844.249.249 0 00.448 0 1.694 1.694 0 013.245.844c0 1.446-2.116 3.286-3.043 4.022a.679.679 0 01-.426.151zm8.614.658a8.742 8.742 0 01-6.172 5.5.5.5 0 01-.6-.344 4.463 4.463 0 01-.188-1.272 4.912 4.912 0 015.025-4.791 5.152 5.152 0 011.616.261.5.5 0 01.314.647zM22 8.658a.342.342 0 01-.342.342h-3.316A.342.342 0 0118 8.658V6.842a.342.342 0 01.342-.342h3.316a.342.342 0 01.342.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});