define("ember-svg-jar/inlined/road-sign-crossroad-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-crossroad-alternate</title><path d=\"M23 23.247a1 1 0 00.881-1.473l-11-20.534a1.041 1.041 0 00-1.762 0l-11 20.534A1 1 0 001 23.247zm-19.915-2a.25.25 0 01-.214-.122.248.248 0 01-.006-.247L11.78 4.24a.249.249 0 01.44 0l8.914 16.638a.248.248 0 01-.006.247.25.25 0 01-.214.122z\"/><path d=\"M10.991 11.809v2.25a.25.25 0 01-.25.25H8.493a1 1 0 000 2h2.248a.25.25 0 01.25.25v2.25a1 1 0 102 0v-2.25a.25.25 0 01.25-.25h2.248a1 1 0 000-2h-2.25a.25.25 0 01-.25-.25v-2.25a1 1 0 10-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});