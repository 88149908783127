define("ember-svg-jar/inlined/primitive-symbols-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-group</title><path d=\"M23.813 11.647l-1.954-2.735a1.521 1.521 0 00-1.375-.612 1.427 1.427 0 00-1.115.744l-1.412 2.5a.25.25 0 01-.435 0l-1.413-2.5a1.474 1.474 0 00-1.209-.752 1.546 1.546 0 00-1.278.619l-1.955 2.736a.176.176 0 01-.286 0L9.425 8.912a1.511 1.511 0 00-1.282-.62 1.473 1.473 0 00-1.208.75L6 10.694a.251.251 0 01-.436 0l-.932-1.652a1.476 1.476 0 00-1.209-.75 1.525 1.525 0 00-1.281.619L.187 11.647a1 1 0 101.626 1.162l1.48-2.07 1.271 2.249a.246.246 0 010 .246l-3.478 6.154a1 1 0 00.378 1.362 1.07 1.07 0 001.362-.377l2.739-4.845a.25.25 0 01.435 0l2.6 4.6a1.478 1.478 0 001.287.751h4.224a1.476 1.476 0 001.286-.75l2.123-3.756a.25.25 0 01.436 0l2.26 4a1.059 1.059 0 001.362.377 1 1 0 00.378-1.362l-3-5.308a.251.251 0 010-.246l1.75-3.095 1.48 2.07a1 1 0 101.626-1.162zM13.662 18.88h-3.324a.248.248 0 01-.217-.127L7 13.234a.251.251 0 010-.246l1.271-2.249 1.48 2.07a1 1 0 001.394.233.707.707 0 01.751 0 1 1 0 001.395-.233l1.479-2.07 1.75 3.095a.251.251 0 010 .246l-2.642 4.673a.248.248 0 01-.216.127z\"/><circle cx=\"5.782\" cy=\"5.533\" r=\"2.413\"/><circle cx=\"17.739\" cy=\"5.533\" r=\"2.413\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});