define("ember-svg-jar/inlined/old-people-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>old-people-man-1</title><path d=\"M17.989 12.59a.252.252 0 01.2-.12A2.5 2.5 0 0020.5 9.982v-.018a.033.033 0 01.015-.027 4 4 0 00-1.025-7.331.25.25 0 01-.173-.157A3 3 0 0016.5.482a2.949 2.949 0 00-1.18.254.249.249 0 01-.21-.005 7.137 7.137 0 00-6.22 0 .245.245 0 01-.21.005A2.949 2.949 0 007.5.482a3 3 0 00-2.817 1.967.25.25 0 01-.173.157 4 4 0 00-1.041 7.322.063.063 0 01.031.054 2.5 2.5 0 002.315 2.488.252.252 0 01.2.12 6.983 6.983 0 0011.978 0zM16.25 7.982a1.195 1.195 0 11-1.194-1.194 1.2 1.2 0 011.194 1.194zm-2.385-5.64a.249.249 0 01.155.259 3.68 3.68 0 00-.02.381 3.47 3.47 0 00.628 1.993.25.25 0 01-.151.387 2.685 2.685 0 00-1.942 1.705.25.25 0 01-.235.165h-.485a.249.249 0 01-.235-.166A2.742 2.742 0 009.567 5.3a.25.25 0 01-.156-.384A3.475 3.475 0 0010 2.982a3.715 3.715 0 00-.02-.382.248.248 0 01.155-.258 5.09 5.09 0 013.73 0zM9 6.732a1.25 1.25 0 11-1.25 1.25A1.251 1.251 0 019 6.732zm3 7.25a5.006 5.006 0 01-4.7-3.3.25.25 0 01.358-.3 2.711 2.711 0 001.342.35A2.742 2.742 0 0011.58 8.9a.249.249 0 01.235-.166h.485a.25.25 0 01.235.165 2.686 2.686 0 002.521 1.779 2.644 2.644 0 001.3-.339.25.25 0 01.357.3A5 5 0 0112 13.982z\"/><path d=\"M23.5 20.876a2.462 2.462 0 00-.967-1.977C21.106 17.81 17.839 15.982 12 15.982S2.894 17.81 1.467 18.9A2.462 2.462 0 00.5 20.876v2.606a.5.5 0 00.5.5h8.648a.251.251 0 00.241-.181l1.63-5.706a.52.52 0 01.962 0l1.63 5.706a.251.251 0 00.241.181H23a.5.5 0 00.5-.5zM13 11.343h-2a.639.639 0 00-.3 1.2 2.354 2.354 0 00.434.179 2.964 2.964 0 00.869.127 3.024 3.024 0 00.87-.125 2.444 2.444 0 00.435-.182.639.639 0 00-.3-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});