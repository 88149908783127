define("ember-svg-jar/inlined/human-resources-hierarchy-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-hierarchy-man</title><path d=\"M14.505 5.25a.249.249 0 01.25-.25h.75a1 1 0 100-2h-1.5a1.5 1.5 0 00-1.5 1.5v6.25a.25.25 0 01-.25.25h-1.75a1 1 0 000 2h1.75a.249.249 0 01.25.25v6.25a1.5 1.5 0 001.5 1.5h1.5a1 1 0 000-2h-.75a.25.25 0 01-.25-.25z\"/><circle cx=\"20.505\" cy=\"17.944\" r=\"1.944\"/><path d=\"M20.505 20.5a3.5 3.5 0 00-3.49 3.231.253.253 0 00.066.189.25.25 0 00.183.08h6.481a.252.252 0 00.25-.27 3.5 3.5 0 00-3.49-3.23z\"/><circle cx=\"20.505\" cy=\"1.944\" r=\"1.944\"/><path d=\"M20.505 4.5a3.5 3.5 0 00-3.49 3.231.253.253 0 00.066.189.25.25 0 00.183.08h6.481A.252.252 0 0024 7.73a3.5 3.5 0 00-3.495-3.23zM7.01 12.67a.25.25 0 01-.024-.446 4 4 0 10-3.962 0A.25.25 0 013 12.67a5.006 5.006 0 00-2.995 4.58v.25a.25.25 0 00.25.25h9.5a.25.25 0 00.25-.25v-.25a5.007 5.007 0 00-2.995-4.58zM3.085 8.189a.247.247 0 01.148-.162.251.251 0 01.22.018 4.95 4.95 0 002.552.705 5.041 5.041 0 00.9-.082.082.082 0 01.1.082 2 2 0 11-3.92-.561z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});