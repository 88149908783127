define("ember-svg-jar/inlined/video-game-smartphone-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-smartphone-landscape</title><path d=\"M11.705 12.354a.5.5 0 010-.708l2.037-2.036a.573.573 0 00-.072-.87 4 4 0 100 6.52.571.571 0 00.072-.87z\"/><path d=\"M21 4H3a3 3 0 00-3 3v10a3 3 0 003 3h18a3 3 0 003-3V7a3 3 0 00-3-3zm-1.5 13.5a.5.5 0 01-.5.5H3.5a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5H19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});