define("ember-svg-jar/inlined/award-medal-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-medal-4</title><path d=\"M17.043 14.043l.349-1.746a.807.807 0 00-.98-.982l-1.747.35-1.418-1.418 2.144-1.838A3.448 3.448 0 0016.5 6V2h2a1 1 0 000-2h-13a1 1 0 000 2h2v4a3.448 3.448 0 001.109 2.409l2.144 1.839-1.418 1.417-1.747-.35a.807.807 0 00-.98.981l.349 1.747L5 16a1 1 0 000 1.414l1.957 1.957-.349 1.747a.807.807 0 00.98.981l1.748-.35 1.956 1.957a1 1 0 001.415 0l1.957-1.958 1.748.35a.807.807 0 00.98-.981l-.349-1.747L19 17.415A1 1 0 0019 16zM14.5 16.708a2.5 2.5 0 11-2.5-2.5 2.5 2.5 0 012.5 2.5zM9.911 6.891A1.531 1.531 0 019.5 6V2.25A.25.25 0 019.75 2h2a.25.25 0 01.25.25v5.888a.25.25 0 01-.413.189z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});