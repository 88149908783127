define("ember-svg-jar/inlined/weather-cloud-rain-drops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-rain-drops</title><path d=\"M4.443 15.579l.02-.027a.03.03 0 000-.026.032.032 0 00-.016-.02A4.5 4.5 0 016.581 7a1 1 0 00.986-.834 5 5 0 019.647-.831 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169 3 3 0 01-.156 5.027v.011q.524.759.937 1.446a.246.246 0 00.162.115.25.25 0 00.2-.039 5 5 0 00.66-7.661.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 8.5a4.491 4.491 0 00-3.517-4.393.248.248 0 01-.171-.136 7 7 0 00-12.988.916.251.251 0 01-.205.173 6.5 6.5 0 00-2.44 12.025.249.249 0 00.342-.085q.406-.675.922-1.421z\"/><path d=\"M15.5 17c0-1.278-1.522-3.621-2.177-4.568a1.038 1.038 0 00-1.646 0C11.022 13.379 9.5 15.722 9.5 17a3 3 0 006 0zm-4 0a6.062 6.062 0 01.782-1.773.252.252 0 01.436 0A6.068 6.068 0 0113.5 17a1 1 0 01-2 0zM19.323 16.432a1.038 1.038 0 00-1.646 0c-.655.947-2.177 3.29-2.177 4.568a3 3 0 006 0c0-1.278-1.522-3.621-2.177-4.568zM18.5 22a1 1 0 01-1-1 6.062 6.062 0 01.782-1.773.252.252 0 01.436 0A6.068 6.068 0 0119.5 21a1 1 0 01-1 1zM7.323 16.432a1.038 1.038 0 00-1.646 0C5.022 17.379 3.5 19.722 3.5 21a3 3 0 006 0c0-1.278-1.522-3.621-2.177-4.568zM6.5 22a1 1 0 01-1-1 6.062 6.062 0 01.782-1.773.252.252 0 01.436 0A6.068 6.068 0 017.5 21a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});