define("ember-svg-jar/inlined/conversation-text-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-text-alternate</title><path d=\"M6.612 17.938L4 19.148l1.19-2.369a1 1 0 00-.269-1.225A7.184 7.184 0 012 9.943C2 5.56 6.486 1.994 12 1.994c5.04 0 9.307 3 9.923 6.971a6.4 6.4 0 01.07 1.282 1 1 0 002 .092 8.448 8.448 0 00-.093-1.681C23.134 3.723 18.018 0 12 0 5.383 0 0 4.461 0 9.943a8.972 8.972 0 003.057 6.638L.931 20.812a.993.993 0 00.177 1.141 1.009 1.009 0 001.139.209l5.209-2.417a1 1 0 10-.844-1.807z\"/><path d=\"M17.749 6.8A.749.749 0 0017 6.056H6.5a.748.748 0 100 1.5H17a.748.748 0 00.749-.756zM6.5 10.542a.748.748 0 100 1.5H10a.748.748 0 100-1.5zM22.638 20A5.328 5.328 0 0024 16.49c0-3.28-3.179-5.948-7.087-5.948S9.824 13.21 9.824 16.49s3.176 5.949 7.088 5.949a8.209 8.209 0 001.8-.206l3.34 1.667a1 1 0 001.405-1.178zm-2.083.016l.321 1.066-1.594-.794a1 1 0 00-.71-.07c-3.5.947-6.748-1.126-6.748-3.725 0-2.18 2.282-3.954 5.088-3.954S22 14.31 22 16.49A3.459 3.459 0 0120.827 19a1 1 0 00-.272 1.013z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});