define("ember-svg-jar/inlined/medical-instrument-ambulance-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-instrument-ambulance-bed</title><path d=\"M24 11.75a2 2 0 00-2-2H7.119A1.507 1.507 0 015.68 8.674L3.942 2.762a2.057 2.057 0 00-2.179-1.5A2 2 0 00.081 3.813l1.972 6.708a4.526 4.526 0 004.318 3.229H22a2 2 0 002-2z\"/><path d=\"M6.059 5.674A1.5 1.5 0 007.5 6.751a1.48 1.48 0 00.424-.062 1.5 1.5 0 001.014-1.862l-1.029-3.5a1.5 1.5 0 00-2.879.847zM5.5 19.771a1.99 1.99 0 102 1.99 2 2 0 00-2-1.99zM18.5 19.771a1.99 1.99 0 102 1.99 2 2 0 00-2-1.99zM16.351 19.676a.5.5 0 00-.082-.764l-2.158-1.444A.25.25 0 0114 17.26a.253.253 0 01.111-.208l2.013-1.344a.25.25 0 00-.138-.458h-2.635a.5.5 0 00-.277.084l-.8.531a.5.5 0 01-.554 0l-.8-.531a.5.5 0 00-.277-.084H8.015a.25.25 0 00-.139.458l2.013 1.344a.253.253 0 01.111.208.248.248 0 01-.111.208l-2.158 1.444a.5.5 0 00-.082.764A2.988 2.988 0 018.5 21.76a.5.5 0 00.5.5h6a.5.5 0 00.5-.5 2.988 2.988 0 01.851-2.084zm-6.225.584a.25.25 0 01-.139-.458l1.736-1.157a.5.5 0 01.554 0l1.736 1.155a.25.25 0 01-.139.458z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});