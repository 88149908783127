define("ember-svg-jar/inlined/animal-print-paw-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>animal-print-paw-1</title><path d=\"M2.024 4.8a.25.25 0 00.191-.359L1.9 3.8a1 1 0 00-1.794.9l.26.522a.253.253 0 00.161.13.25.25 0 00.2-.036A2.942 2.942 0 012.024 4.8zM7.518 1.794a.25.25 0 00.192-.36L7.4.8a1 1 0 00-1.794.9l.25.5a.25.25 0 00.362.1 2.946 2.946 0 011.3-.506zM23.165 5.479a.25.25 0 00.38-.083l.349-.7a1 1 0 00-1.789-.896s-.2.4-.348.7a.249.249 0 00.167.355 3.125 3.125 0 011.241.624zM17.317 2.294a.251.251 0 00.362-.1l.25-.5A1 1 0 0016.14.8l-.315.631a.247.247 0 000 .229.252.252 0 00.189.131 2.951 2.951 0 011.303.503zM7.6 3.3c-1.232.27-1.916 1.769-1.558 3.411C6.44 8.519 7.8 9.4 8.883 9.158c1.1-.238 1.95-1.622 1.558-3.41C10.083 4.105 8.838 3.023 7.6 3.3zM2.152 6.3C.919 6.566.235 8.066.593 9.708c.4 1.811 1.758 2.694 2.838 2.45 1.1-.238 1.95-1.622 1.558-3.41C4.631 7.106 3.387 6.025 2.152 6.3zM17.489 6.708c.36-1.642-.325-3.141-1.558-3.411s-2.478.808-2.838 2.451c-.393 1.8.465 3.173 1.558 3.41 1.339.291 2.515-.97 2.838-2.45zM21.514 6.3c-1.235-.274-2.479.808-2.838 2.451-.39 1.786.454 3.17 1.558 3.41 1.318.286 2.509-.943 2.838-2.45.358-1.645-.326-3.145-1.558-3.411zM12 10.5c-6.124 0-9.5 4.3-9.5 7.823a6.88 6.88 0 001.936 4.7 2.2 2.2 0 002.976.275 8.281 8.281 0 015.147-1.621 8.559 8.559 0 014.657 1.277 2.2 2.2 0 002.922-.621A6.716 6.716 0 0021.5 18.32c0-4.559-4.577-7.82-9.5-7.82z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});