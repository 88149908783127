define("ember-svg-jar/inlined/medical-specialty-knee-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-knee-1</title><path d=\"M15.573 2.476L1.587.007a.505.505 0 00-.408.11A.5.5 0 001 .5v4.183a.5.5 0 00.391.488l14.283 3.2a1 1 0 00.681-.088A2.464 2.464 0 0117.5 8a2.5 2.5 0 11-2.177 3.709 1 1 0 00-.656-.492L1.609 8.3A.5.5 0 001 8.783V12.5a.5.5 0 00.433.5l9.27 1.264A1.506 1.506 0 0112 15.746V23.5a.5.5 0 00.5.5H16a.5.5 0 00.5-.5v-2.193a15.523 15.523 0 00-1.115-5.827.5.5 0 01.584-.665 6.333 6.333 0 003.035 0 .5.5 0 01.585.663 15.636 15.636 0 00-1.089 5.829V23.5a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5V12.468c0-5.068-2.835-8.9-7.427-9.992z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});