define("ember-svg-jar/inlined/laboratory-chromosome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laboratory-chromosome</title><path d=\"M9.913 10.425c.428-2.448-.107-4.587-1.2-4.777S6.4 7.287 5.972 9.735a8.783 8.783 0 00-.084 2.607h-.006a8.758 8.758 0 00-.871-2.456c-1.151-2.2-2.877-3.573-3.856-3.062s-.84 2.712.311 4.915c2.09 4 3.705 3.029 4.786 2.958 1.048-.261 2.883.172 3.661-4.272z\"/><path d=\"M6.249 14.7c-1.052.26-2.885-.174-3.663 4.271-.428 2.448.106 4.587 1.194 4.777S6.1 22.1 6.526 19.656a8.78 8.78 0 00.085-2.606h.006a8.773 8.773 0 00.871 2.455c1.151 2.2 2.878 3.574 3.857 3.062s.839-2.711-.311-4.914c-2.088-3.998-3.703-3.029-4.785-2.953zM17.837 9.787c1.083.056 2.711 1 4.746-3.024 1.12-2.218 1.229-4.42.243-4.918s-2.693.9-3.814 3.115a8.8 8.8 0 00-.838 2.471 8.788 8.788 0 00-.121-2.6c-.462-2.442-1.716-4.255-2.8-4.05s-1.59 2.352-1.128 4.794c.829 4.425 2.657 3.966 3.712 4.212z\"/><path d=\"M17.839 9.787c-.82-.116-1.736 1.563-2.045 3.751s.1 4.054.926 4.17 1.735-1.563 2.044-3.75-.105-4.058-.925-4.171zM8.683 3.692a1 1 0 001.266-.632l.5-1.5a1 1 0 00-1.9-.632l-.5 1.5a1 1 0 00.634 1.264zM1.911 4.569a1 1 0 101.414-1.414L2.207 2.037A1 1 0 00.793 3.451zM15.293 20.537l-1.5 1.5a1 1 0 001.414 1.414l1.5-1.5a1 1 0 00-1.414-1.414zM20.707 20.051a1 1 0 00-1.414 1.414l1.5 1.5a1 1 0 001.414-1.414zM22.816 13.8a1 1 0 00-1.265.632l-.5 1.5a1 1 0 001.9.632l.5-1.5a1 1 0 00-.635-1.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});