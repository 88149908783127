define("ember-svg-jar/inlined/job-seach-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-seach-profile</title><path d=\"M8.2 21.881a3 3 0 01.391-4.224 3 3 0 013.533-.221.249.249 0 00.384-.211V12a3 3 0 01.923-2.163.25.25 0 00.077-.18V4.5A1.5 1.5 0 0012 3H1.5A1.5 1.5 0 000 4.5V21a1.5 1.5 0 001.5 1.5h6.682a.251.251 0 00.228-.146.254.254 0 00-.038-.268zM8.742 8.54A2.122 2.122 0 116.62 6.417 2.122 2.122 0 018.742 8.54zM3.57 14.134a.25.25 0 01-.237-.329 3.465 3.465 0 016.575 0 .251.251 0 01-.035.225.247.247 0 01-.2.1z\"/><path d=\"M16.192 9.079a.25.25 0 00.308-.243V2a2 2 0 00-2-2H3a1 1 0 000 2h11a.5.5 0 01.5.5v6.336a.25.25 0 00.308.243 3 3 0 011.384 0zM24 22a4 4 0 00-4-4h-2.75a.25.25 0 01-.25-.25V12a1.5 1.5 0 10-3 0v9.135a.25.25 0 01-.442.16L11.654 19a1.5 1.5 0 00-2.309 1.915l2.577 2.991a.252.252 0 00.19.087H23.75a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});