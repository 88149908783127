define("ember-svg-jar/inlined/disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disable</title><path d=\"M16.481 5.751a.257.257 0 00-.034-.392 7.981 7.981 0 00-10.1.984 8.005 8.005 0 00-.984 10.1.258.258 0 00.393.036z\"/><path d=\"M20.485 3.515a12 12 0 100 16.971 12 12 0 000-16.971zm-1.414 15.557a10.121 10.121 0 01-14.142 0 10.012 10.012 0 010-14.143 10.1 10.1 0 0114.142 0 10.2 10.2 0 010 14.143z\"/><path d=\"M18.249 7.517L7.519 18.248a.257.257 0 00.036.392A7.989 7.989 0 0018.641 7.552a.258.258 0 00-.392-.035z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});