define("ember-svg-jar/inlined/weather-rain-drops", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-rain-drops</title><path d=\"M8.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM3.124 4.538a1 1 0 101.414-1.415l-.708-.707a1 1 0 10-1.414 1.415zM3 8.25a1 1 0 00-1-1H1a1 1 0 000 2h1a1 1 0 001-1zM14.085 2.417a1 1 0 00-1.415 0l-.708.707a1 1 0 00-.292.67.249.249 0 00.337.243 8.545 8.545 0 012.125-.492.252.252 0 00.219-.192 1 1 0 00-.266-.936z\"/><path d=\"M5.229 9.313a.249.249 0 00.124-.285 3 3 0 014-3.569.248.248 0 00.247-.039 8.443 8.443 0 011.375-.92.25.25 0 00.025-.427 5 5 0 00-7.407 5.984.25.25 0 00.4.1 7.069 7.069 0 011.236-.844zM22.094 12.073a.25.25 0 01-.1-.193A7 7 0 008.338 9.84a.249.249 0 01-.22.173 5.5 5.5 0 00-2.37 10.247.249.249 0 00.369-.158 7.951 7.951 0 01.5-1.4.249.249 0 00-.087-.309 3.5 3.5 0 012.42-6.361 1 1 0 001.116-.833A5 5 0 0120 12c0 .146-.007.29-.02.433a1 1 0 00.5.959 3 3 0 011.228 3.9.246.246 0 00-.011.19A4.712 4.712 0 0122 19c0 .155-.008.307-.023.458a.25.25 0 00.419.208 5 5 0 00-.3-7.593z\"/><path d=\"M9.5 21a1 1 0 002 0 6.068 6.068 0 00-.782-1.773.251.251 0 00-.436 0A6.062 6.062 0 009.5 21z\"/><path d=\"M13.5 21c0-1.278-1.522-3.621-2.177-4.568a1.038 1.038 0 00-1.646 0C9.022 17.379 7.5 19.722 7.5 21a3 3 0 006 0zm-3.218-1.772a.252.252 0 01.436 0A6.068 6.068 0 0111.5 21a1 1 0 01-2 0 6.062 6.062 0 01.782-1.772zM18.5 19a6.068 6.068 0 00-.782-1.773.251.251 0 00-.436 0A6.062 6.062 0 0016.5 19a1 1 0 002 0z\"/><path d=\"M18.323 14.432a1.038 1.038 0 00-1.646 0c-.655.947-2.177 3.29-2.177 4.568a3 3 0 006 0c0-1.278-1.522-3.621-2.177-4.568zM16.5 19a6.062 6.062 0 01.782-1.773.252.252 0 01.436 0A6.068 6.068 0 0118.5 19a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});