define("ember-svg-jar/inlined/video-game-mario-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-3</title><path d=\"M8.5 14.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM15.5 14.5a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75z\"/><path d=\"M22.1 10.39a.5.5 0 01-.181-.73A5.921 5.921 0 0023 6.25 6.257 6.257 0 0016.75 0h-9.5A6.257 6.257 0 001 6.25a5.84 5.84 0 001.07 3.411.505.505 0 01.076.407.5.5 0 01-.255.325 3.5 3.5 0 001.331 6.6 9 9 0 0017.556 0 3.5 3.5 0 001.326-6.6zM8.553 4.776l1.5-3a.5.5 0 01.4-.274.494.494 0 01.445.2l.9 1.2a.25.25 0 00.4 0l.9-1.2a.5.5 0 01.847.076l1.5 3a.5.5 0 11-.894.448L13.6 3.309a.25.25 0 00-.423-.038L12.4 4.3a.518.518 0 01-.8 0l-.772-1.029a.25.25 0 00-.423.038l-.958 1.915a.5.5 0 01-.894-.448zM3 14.5a.25.25 0 01-.4.2 1.5 1.5 0 010-2.4.25.25 0 01.4.2zm16 .5a7 7 0 01-14 0v-3.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5zm1.59-5h-.529a.5.5 0 01-.466-.318A2.253 2.253 0 0017.5 8.25h-11a2.253 2.253 0 00-2.095 1.432.5.5 0 01-.466.318H3.41a.5.5 0 01-.476-.654A3.754 3.754 0 016.5 6.75h11a3.754 3.754 0 013.566 2.6.5.5 0 01-.476.65zm.81 4.7a.25.25 0 01-.4-.2v-2a.25.25 0 01.4-.2 1.5 1.5 0 010 2.4z\"/><path d=\"M16.937 15.5h-1.979a.5.5 0 00-.493.417 2.5 2.5 0 01-4.93 0 .5.5 0 00-.493-.417H7.063a.5.5 0 00-.484.376A2.5 2.5 0 009 19a2.4 2.4 0 00.678-.1 2.486 2.486 0 004.644 0A2.4 2.4 0 0015 19a2.5 2.5 0 002.421-3.124.5.5 0 00-.484-.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});