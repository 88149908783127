define("ember-svg-jar/inlined/hair-skin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-skin-1</title><path d=\"M5.889 12.493A2.058 2.058 0 008 10.424V1a.988.988 0 00-.845-.986.977.977 0 00-1.1.675A68.905 68.905 0 004 10.505a1.893 1.893 0 001.889 1.988zM17.889 12.493A2.058 2.058 0 0020 10.424V1a.988.988 0 00-.845-.991.975.975 0 00-1.1.675A68.905 68.905 0 0016 10.505a1.893 1.893 0 001.889 1.988z\"/><path d=\"M23.5 10.5h-1.532a1.025 1.025 0 00-1 .9 3 3 0 01-5.946 0c-.029-.211-.206-.9-1.284-.9H9.963a1.022 1.022 0 00-.99.9 3 3 0 01-5.946 0 1.025 1.025 0 00-1-.9H.5a.5.5 0 00-.5.5v11.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V11a.5.5 0 00-.5-.5zm-21 7.486A.993.993 0 112.5 16a.993.993 0 110 1.986zM5.772 21.5a.993.993 0 110-1.986.993.993 0 110 1.986zm1.971-3.514a.993.993 0 110-1.986.993.993 0 110 1.986zm3.272 3.514a.993.993 0 110-1.986.993.993 0 110 1.986zm1.97-3.514a.993.993 0 110-1.986.993.993 0 110 1.986zm3.272 3.514a.993.993 0 110-1.986.993.993 0 110 1.986zm1.971-3.514a.993.993 0 110-1.986.993.993 0 110 1.986zM21.5 21.5a.993.993 0 110-1.986.993.993 0 110 1.986z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});