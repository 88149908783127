define("ember-svg-jar/inlined/crypto-currency-litecoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-litecoin</title><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zm5.5 16.75H9.11a1.251 1.251 0 01-1.226-1.5L8.4 13.7a.251.251 0 00-.346-.279l-1.754.767a.78.78 0 01-.3.062.75.75 0 01-.3-1.437l3.03-1.326a.251.251 0 00.145-.18l1.391-6.954a.75.75 0 111.47.294l-1.117 5.584a.25.25 0 00.089.244.247.247 0 00.257.034l2.735-1.2a.751.751 0 11.6 1.375l-4.017 1.757a.25.25 0 00-.145.18l-.665 3.326a.246.246 0 00.052.207.249.249 0 00.193.092H17.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});