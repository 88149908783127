define("ember-svg-jar/inlined/smiley-shout-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shout-alternate</title><circle cx=\"7\" cy=\"6\" r=\"1.5\"/><circle cx=\"17\" cy=\"6\" r=\"1.5\"/><path d=\"M9.5 15v2a2.5 2.5 0 005 0v-2a2.5 2.5 0 00-5 0zM3.9 3.553a1 1 0 001.4-.205 2.189 2.189 0 013.4 0 1 1 0 101.6-1.2 4.126 4.126 0 00-6.6 0 1 1 0 00.2 1.405z\"/><path d=\"M22.394 5.5a1 1 0 10-1.731 1 10 10 0 11-17.326 0 1 1 0 10-1.731-1 12 12 0 1020.788 0zM13.9 3.553a1 1 0 001.4-.205 2.189 2.189 0 013.4 0 1 1 0 101.6-1.2 4.126 4.126 0 00-6.6 0 1 1 0 00.2 1.405z\"/><path d=\"M8.5 9.5a1 1 0 001 1h1a1 1 0 000-2h-1a1 1 0 00-1 1zM13.5 10.5h1a1 1 0 000-2h-1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});