define("ember-svg-jar/inlined/file-txt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-txt</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM8 9.623h-.622a.251.251 0 00-.251.251V15a.625.625 0 01-1.25 0V9.874a.251.251 0 00-.251-.251H5a.625.625 0 010-1.25h3a.625.625 0 010 1.25zm5.559 5.1a.624.624 0 11-1.117.558s-.862-1.71-.881-1.761a.066.066 0 00-.122 0l-.88 1.759a.624.624 0 11-1.117-.558l1.3-2.61a.248.248 0 000-.222l-1.3-2.61a.624.624 0 011.117-.558l.875 1.75a.072.072 0 00.132 0l.876-1.751a.624.624 0 011.117.558l-1.3 2.61a.242.242 0 000 .222zM18 9.623h-.624a.251.251 0 00-.251.251V15a.625.625 0 11-1.25 0V9.874a.251.251 0 00-.251-.251H15a.625.625 0 010-1.25h3a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});