define("ember-svg-jar/inlined/farmer-s-market-vendor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farmer&amp;apos;s-market-vendor</title><path d=\"M22.672 3.768L12.658.111a2.016 2.016 0 00-1.33.005L1.343 3.763A2 2 0 000 5.652V23a1 1 0 002 0l.014-16.655a1 1 0 01.656-.938l8.972-3.287a1 1 0 01.687 0l9.014 3.292a1 1 0 01.657.939V23a1 1 0 002 0V5.652a2 2 0 00-1.328-1.884z\"/><rect x=\"4.5\" y=\"17.999\" width=\"15\" height=\"6\" rx=\"1\" ry=\"1\"/><path d=\"M9.9 13.863a4.87 4.87 0 00-2 1.883.5.5 0 00.431.753h7.373a.5.5 0 00.43-.754 4.875 4.875 0 00-2.023-1.89.249.249 0 01-.022-.435 3.926 3.926 0 10-4.166.008.25.25 0 01-.02.435zm.252-4.316a.249.249 0 01.364-.145 5.087 5.087 0 002.548.7 4.8 4.8 0 00.564-.034.25.25 0 01.277.28 1.925 1.925 0 11-3.753-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});