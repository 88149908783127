define("ember-svg-jar/inlined/beanie-winter-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beanie-winter-2</title><rect y=\"18\" width=\"24\" height=\"5\" rx=\"2\" ry=\"2\"/><path d=\"M2.5 16.5h19a.5.5 0 00.5-.5 11.2 11.2 0 00-7.643-10.164.248.248 0 01-.175-.189.251.251 0 01.085-.244l1.37-1.132a1 1 0 10-1.274-1.542l-.962.8a.25.25 0 01-.409-.194L13 2.006A1 1 0 0012.006 1H12a1 1 0 00-1 .994l-.008 1.338a.25.25 0 01-.411.19l-.937-.788a1 1 0 10-1.288 1.532L9.718 5.41a.249.249 0 01-.091.431A11.2 11.2 0 002 16a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});