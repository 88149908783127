define("ember-svg-jar/inlined/crypto-currency-bitcoin-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-laptop</title><path d=\"M13.809 7.448a.25.25 0 01-.149-.229V6.5a.75.75 0 00-1.5 0V7a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V7a.25.25 0 01-.25.25.75.75 0 00-.75.75v6a.75.75 0 00.75.75.25.25 0 01.25.25v.5a.75.75 0 001.5 0V15a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.719a.25.25 0 01.149-.229A2.244 2.244 0 0015.16 12.5a2.219 2.219 0 00-.46-1.349.248.248 0 010-.3 2.219 2.219 0 00.46-1.351 2.244 2.244 0 00-1.351-2.052zM10.16 9a.25.25 0 01.25-.25h2.5a.75.75 0 010 1.5h-2.5a.25.25 0 01-.25-.25zm2.75 4.25h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h2.5a.75.75 0 010 1.5z\"/><path d=\"M23.9 18.665a1 1 0 00-.99-1.165h-.25a.25.25 0 01-.25-.25V4.5a2 2 0 00-2-2h-17a2 2 0 00-2 2v12.75a.25.25 0 01-.25.25h-.07a1 1 0 00-.99 1.164l.124.747a2.492 2.492 0 002.47 2.089h18.611a2.492 2.492 0 002.467-2.089zM3.41 5.5a1 1 0 011-1h15a1 1 0 011 1v11a1 1 0 01-1 1h-15a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});