define("ember-svg-jar/inlined/align-middle-move-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-middle-move-horizontal</title><path d=\"M20.5 13.5h-7.25a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h4.25a1 1 0 001-1v-4a1 1 0 00-1-1h-4.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v3.25a.25.25 0 01-.25.25H6.5a1 1 0 00-1 1v4a1 1 0 001 1h4.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H3.5a1 1 0 00-1 1v4a1 1 0 001 1h7.25a.25.25 0 01.25.25V23a1 1 0 002 0v-3.25a.25.25 0 01.25-.25h7.25a1 1 0 001-1v-4a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});