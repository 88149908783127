define("ember-svg-jar/inlined/saving-money-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-money-tree</title><path d=\"M19.618 8.98a.249.249 0 01-.189-.282 7.447 7.447 0 00.093-1.175A7.518 7.518 0 004.6 6.217a.249.249 0 01-.161.192A5.84 5.84 0 00.75 12.182c0 2.825 1.6 6.125 6.125 6.125H10a.25.25 0 01.25.25v1.193A.25.25 0 0110 20H8.443a1 1 0 00-.943 1.316l.78 2.342a.5.5 0 00.47.342h6a.5.5 0 00.475-.342l.78-2.342A1 1 0 0015.057 20H13.5a.25.25 0 01-.25-.25v-1.193a.25.25 0 01.25-.25h5.022a4.728 4.728 0 001.1-9.327zM12 16a1 1 0 01-1-1v-.368a.25.25 0 00-.25-.25h-.691a1 1 0 010-2h2.631a.736.736 0 00.274-1.419l-2.67-1.069a2.726 2.726 0 01.5-5.214.25.25 0 00.2-.245V4a1 1 0 012 0v.368a.25.25 0 00.25.25h.691a1 1 0 010 2H11.31a.736.736 0 00-.274 1.419l2.67 1.069a2.726 2.726 0 01-.5 5.214.25.25 0 00-.2.245V15A1 1 0 0112 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});