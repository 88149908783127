define("ember-svg-jar/inlined/business-burn-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-burn-money</title><path d=\"M14.267 17.8a1 1 0 00.707-.293l8.233-8.233a2 2 0 000-2.828L17.55.793a2 2 0 00-2.828 0L7.988 7.525A1 1 0 009.4 8.939l6.557-6.555a.249.249 0 01.353 0l5.3 5.3a.25.25 0 010 .353L13.56 16.1a1 1 0 00.707 1.707z\"/><circle cx=\"13.707\" cy=\"10.209\" r=\"2\"/><path d=\"M5.461 23.793a5.246 5.246 0 005.246-5.246 4.957 4.957 0 00-1.816-4.041.5.5 0 00-.744.2 2.023 2.023 0 01-1.9 1.245.251.251 0 01-.193-.132 6.012 6.012 0 01-.2-5.176.5.5 0 00-.674-.659 8.536 8.536 0 00-4.964 8.563 5.319 5.319 0 005.245 5.246zm-.222-6a.382.382 0 01.547.264c.144.694.959 1.387.959 2.155a1.5 1.5 0 01-3 0 2.762 2.762 0 011.494-2.421z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});