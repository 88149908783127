define("ember-svg-jar/inlined/scooter-sport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>scooter-sport</title><path d=\"M20.25 13a3.722 3.722 0 00-2.821 1.306.249.249 0 01-.285.067l-1.725-.719a.251.251 0 01-.146-.167.248.248 0 01.046-.218 9.238 9.238 0 012.208-2.1 17.153 17.153 0 015.059-2.254 1.566 1.566 0 00-.594-3.068c-1.035.129-2.133.344-3.294.57q-.609.119-1.226.235a.251.251 0 00-.128.066l-.008.007L13.61 9.7a2.51 2.51 0 01-1.562.547h-.66a2.511 2.511 0 01-2.149-1.219L8.233 7.335a.245.245 0 01-.023-.2l.287-.9a1 1 0 01.964-.735H11a1 1 0 000-2H9.461A2.99 2.99 0 006.6 5.589l-.772 2.429a.25.25 0 01-.291.168C1.76 7.366.211 9.849.141 9.967a.992.992 0 00.337 1.354.977.977 0 00.522.148 1.015 1.015 0 00.851-.463c.1-.152.91-1.275 3.017-.917a.251.251 0 01.2.323l-.458 1.421a.251.251 0 01-.265.173A.746.746 0 004.25 12a4.291 4.291 0 102.361.735.251.251 0 01-.1-.284l.389-1.218a.25.25 0 01.417-.1 11.7 11.7 0 011.624 2.354c.7 1.213 1.3 2.261 2.308 2.261h.5a3.027 3.027 0 001.847-.56.25.25 0 01.242-.027l2.53 1.054a.249.249 0 01.152.262 2.2 2.2 0 00-.021.271A3.75 3.75 0 1020.25 13zm-16 5.5a2.245 2.245 0 01-.809-4.341.25.25 0 01.328.309L3.3 15.946a1 1 0 001.906.608l.473-1.484a.25.25 0 01.446-.063A2.229 2.229 0 016.5 16.25a2.253 2.253 0 01-2.25 2.25zm16 0a1.747 1.747 0 01-1.45-.774.25.25 0 01.3-.37l.761.317a1 1 0 00.77-1.846l-.765-.318a.25.25 0 01.048-.476A1.647 1.647 0 0120.25 15a1.75 1.75 0 010 3.5z\"/><path d=\"M9.524 7.288a.253.253 0 000 .259l.576.97a1.508 1.508 0 001.29.733h.66a1.506 1.506 0 00.938-.328l1.706-1.366a.25.25 0 00-.184-.444 20.094 20.094 0 01-2.258.138c-1 0-1.824-.032-2.495-.08a.252.252 0 00-.233.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});