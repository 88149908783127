define("ember-svg-jar/inlined/professions-woman-welder.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-welder</title><path d=\"M3.5 12.181a.25.25 0 00-.452-.148 4.09 4.09 0 01-2.181 1.59.494.494 0 00-.349.35.5.5 0 00.123.48 6.07 6.07 0 002.538 1.413.249.249 0 00.321-.24zM20.5 15.62a.252.252 0 00.1.2.249.249 0 00.221.039 6.11 6.11 0 002.536-1.406.5.5 0 00-.225-.832 4.044 4.044 0 01-2.18-1.575.25.25 0 00-.452.148z\"/><path d=\"M21.98 19.368a6.833 6.833 0 00-3.041-1.213c-.719-.156-1.3-.316-2.19-.551a13.606 13.606 0 002.154-.688A.99.99 0 0019.5 16V7.625a7.512 7.512 0 00-7.731-7.5 7.688 7.688 0 00-7.254 7.5C4.494 8.151 4.5 6.427 4.5 16a.99.99 0 00.6.916 13.573 13.573 0 002.154.688c-1.566.414-1.462.393-2.19.551a6.833 6.833 0 00-3.044 1.213 4.849 4.849 0 00-1.77 3.757.75.75 0 101.5 0 3.334 3.334 0 011.211-2.584 3.241 3.241 0 01.789-.448v3.037a.75.75 0 00.75.75h15a.75.75 0 00.75-.75v-3.042a3.241 3.241 0 01.789.448 3.334 3.334 0 011.211 2.584.75.75 0 101.5 0 4.846 4.846 0 00-1.77-3.752zM10.5 3.375h3a.75.75 0 010 1.5h-3a.75.75 0 010-1.5zM7 8.125a1.5 1.5 0 011.5-1.5h7a1.5 1.5 0 011.5 1.5v2a1.5 1.5 0 01-1.5 1.5h-7a1.5 1.5 0 01-1.5-1.5zm5 10a21.187 21.187 0 004.008-.364.746.746 0 00-.258.553v1.311a1.252 1.252 0 01-1.25 1.25h-5a1.252 1.252 0 01-1.25-1.25v-1.311a.746.746 0 00-.258-.553 21.187 21.187 0 004.008.364z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});