define("ember-svg-jar/inlined/christmas-gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-gift</title><rect x=\"5\" y=\"10.875\" width=\"9.5\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M23 20.875h-4.047a.5.5 0 01-.483-.629l.267-1a.5.5 0 01.483-.371H23a1 1 0 000-2H5a3 3 0 01-3-3 1 1 0 00-2 0 5.006 5.006 0 005 5h2.115a.5.5 0 01.485.625l-.267 1a.5.5 0 01-.483.371H1a1 1 0 000 2h22a1 1 0 000-2zM16.6 19.5l-.267 1a.5.5 0 01-.483.371h-5.9a.5.5 0 01-.483-.629l.267-1a.5.5 0 01.483-.371h5.895a.5.5 0 01.483.629zM8 9.875h6a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5h-1.666a.25.25 0 01-.15-.45l1.266-.95a.75.75 0 00-.9-1.2l-1.25.938a.5.5 0 01-.6 0l-1.25-.938a.75.75 0 00-.9 1.2l1.266.95a.25.25 0 01-.15.45H8a.5.5 0 00-.5.5v5a.5.5 0 00.5.5z\"/><path d=\"M16.5 15.875h6a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5h-1.666a.25.25 0 01-.15-.45l1.266-.95a.75.75 0 10-.9-1.2l-1.25.938a.5.5 0 01-.6 0l-1.25-.938a.75.75 0 00-.9 1.2l1.266.95a.25.25 0 01-.15.45H16.5a.5.5 0 00-.5.5v7a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});