define("ember-svg-jar/inlined/spider-web", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spider-web</title><path d=\"M23.1 18.344l-1-.682V6.315l1-.681a1 1 0 00-1.127-1.653l-.973.66-8-3.37V1a1 1 0 00-2 0v.271l-8 3.37-.967-.66A1 1 0 00.9 5.634l1 .681v11.347l-1 .682a1 1 0 00-.263 1.389A1.017 1.017 0 002.031 20L3 19.337l8 3.37V23a1 1 0 002 0v-.293l8-3.37.966.659a1 1 0 101.134-1.652zm-3-2.046l-1.351-.921V8.6l1.351-.921zm-8.1.832l-4.745-1.978V8.826L12 6.849l4.745 1.977v6.326zm7.034-11.148L17.55 7 13 5.1V3.442zM11 3.442V5.1L6.45 7 4.966 5.982zM3.9 7.679l1.355.921v6.777L3.9 16.3zM4.965 18l1.484-1.013L11 18.879v1.658zM13 20.537v-1.658l4.551-1.9L19.035 18z\"/><path d=\"M15.372 10.11a.751.751 0 00-1-.354l-.922.439a1.469 1.469 0 00-2.9 0l-.922-.439a.749.749 0 10-.645 1.353l1.913.912a1.969 1.969 0 00-.4.38l-1.362.453a.75.75 0 00.466 1.424l.555-.185a1.915 1.915 0 003.682 0l.555.185a.75.75 0 10.474-1.424l-1.358-.454a1.969 1.969 0 00-.4-.38l1.913-.912a.749.749 0 00.351-.998z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});