define("ember-svg-jar/inlined/road-sign-left-curve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve</title><path d=\"M23.78 11.47L12.53.22a.749.749 0 00-1.06 0L.22 11.47a.749.749 0 000 1.06l11.25 11.25a.748.748 0 001.06 0l11.25-11.25a.749.749 0 000-1.06zm-10.987.858a1 1 0 00-.293.707V16a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3.793a2 2 0 01.586-1.415l2.159-2.158a.252.252 0 000-.354l-.354-.354a.25.25 0 01.177-.426H15a.5.5 0 01.5.5v2.932a.25.25 0 01-.427.176l-.353-.353a.25.25 0 00-.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});