define("ember-svg-jar/inlined/design-tool-quill-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-quill-2</title><path d=\"M23.8 1.26a.75.75 0 00-.64-1.254A23.886 23.886 0 004.628 13.662l-2.635 5.826a.643.643 0 00-.045.132L.154 22.467a1 1 0 101.692 1.066l4.518-7.208a.5.5 0 01.846.533l-1.19 1.889a.25.25 0 00.306.364L9.764 17.7a12.368 12.368 0 005.844-4.835.25.25 0 00-.021-.3L13.31 9.913a.251.251 0 01.19-.413H17a.219.219 0 00.2-.135 10.824 10.824 0 012.049-3.195z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});