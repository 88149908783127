define("ember-svg-jar/inlined/database-cash-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-cash-alternate</title><path d=\"M2.751 17.753v-.789a.249.249 0 01.373-.217A14.1 14.1 0 007.6 18.2a1 1 0 001.153-.957v-.02a.967.967 0 00-.81-.982C4.7 15.7 2.751 14.44 2.751 13.5v-.789a.249.249 0 01.373-.217 15.424 15.424 0 005.687 1.62.959.959 0 00.943-.478l.012-.02a.993.993 0 00-.761-1.488c-3.877-.414-6.254-1.843-6.254-2.878v-.786a.25.25 0 01.373-.218A17.574 17.574 0 0011.252 10a17.588 17.588 0 008.129-1.757.25.25 0 01.373.214 7.567 7.567 0 01-.011.844.989.989 0 00.524 1.023l.081.04a.965.965 0 001.4-.86V5c0-3.248-5.41-5-10.5-5S.764 1.751.753 4.993v12.76c0 2.693 3.72 4.354 7.892 4.843a.983.983 0 001.01-1.406l-.012-.022a1.015 1.015 0 00-.784-.548c-3.792-.435-6.108-1.844-6.108-2.867zM11.252 2c5.185 0 8.495 1.773 8.5 3v.009C19.74 6.232 16.433 8 11.252 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M16.749 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM16 21.753V21.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141l-2.065-.826a2.085 2.085 0 01.361-3.981.249.249 0 00.2-.245v-.307a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.034A.591.591 0 0016 16.393l2.065.826A2.085 2.085 0 0117.7 21.2a.249.249 0 00-.2.245v.308a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});