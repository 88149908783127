define("ember-svg-jar/inlined/recycling-trash-bin-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>recycling-trash-bin-2</title><path d=\"M23.109.324A1 1 0 0022.372 0H1.628a1 1 0 00-1 1.086L2.506 22.63A1.491 1.491 0 004 24h16a1.491 1.491 0 001.494-1.37l1.874-21.544a1 1 0 00-.259-.762zM10.045 17.5H6.26a2 2 0 01-1.734-3l1.206-2.091a.5.5 0 00-.184-.682l-.433-.25a.5.5 0 01.12-.916l2.731-.734a.5.5 0 01.613.353l.734 2.732a.5.5 0 01-.178.525.494.494 0 01-.3.1.5.5 0 01-.249-.066l-.434-.25a.5.5 0 00-.683.183l-.989 1.715a.251.251 0 00.217.375h3.353a1 1 0 110 2zm1.782-11.658l-1.557 2.7a1 1 0 11-1.732-1l1.774-3.078a2.1 2.1 0 011.735-1 2 2 0 011.732 1l1.038 1.808a.5.5 0 00.682.185l.434-.248a.5.5 0 01.731.563l-.739 2.731a.5.5 0 01-.482.369.487.487 0 01-.131-.018l-2.731-.739a.5.5 0 01-.118-.916l.434-.249a.5.5 0 00.185-.683l-.821-1.43a.25.25 0 00-.434 0zM19.533 16.5a2.008 2.008 0 01-1.731 1h-2.257a.5.5 0 00-.5.5v.5a.5.5 0 01-.308.462.513.513 0 01-.192.038.5.5 0 01-.353-.146l-2-2a.5.5 0 010-.708l2-2a.5.5 0 01.853.354v.5a.5.5 0 00.5.5h1.826a.251.251 0 00.216-.125.247.247 0 000-.249l-1.612-2.811a1 1 0 111.734-.995l1.828 3.18a2.007 2.007 0 01-.004 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});