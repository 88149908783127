define("ember-svg-jar/inlined/construction-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-house</title><path d=\"M14.983 11.75a2.25 2.25 0 012.25 2.25.25.25 0 00.25.25H20.5a.5.5 0 00.5-.5V10a.5.5 0 01.5-.5h2a.5.5 0 00.3-.9L12.3.1a.5.5 0 00-.6 0L.2 8.6a.5.5 0 00.3.9h2a.5.5 0 01.5.5v13.5a.5.5 0 00.5.5h8.747a.5.5 0 00.5-.558 1.523 1.523 0 01-.012-.192V14a2.25 2.25 0 012.248-2.25zM11 9.5a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5z\"/><path d=\"M23.233 13.25a.75.75 0 00-.75.75v1.25a.5.5 0 01-.5.5h-5.75a.5.5 0 01-.5-.5V14a.75.75 0 00-1.5 0v9.25a.756.756 0 001.225.58l3.492-2.857a.251.251 0 01.317 0l3.491 2.857a.756.756 0 001.224-.581V14a.75.75 0 00-.749-.75zm-5.545 6.819l-1.546 1.265a.251.251 0 01-.409-.193V18.61a.251.251 0 01.409-.194l1.546 1.266a.249.249 0 01.092.193.252.252 0 01-.092.194zm1.262-1.292l-1.324-1.083a.25.25 0 01.158-.444h2.649a.25.25 0 01.158.444l-1.324 1.083a.249.249 0 01-.317 0zm3.533 2.364a.25.25 0 01-.408.193l-1.546-1.265a.249.249 0 01-.092-.194.246.246 0 01.092-.193l1.546-1.266a.25.25 0 01.408.194z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});