define("ember-svg-jar/inlined/folder-lock-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-lock-1-alternate</title><path d=\"M24 6.3a1.95 1.95 0 00-1.95-1.95H12a.5.5 0 01-.447-.277l-.747-1.494A1.949 1.949 0 009.061 1.5H1.95A1.95 1.95 0 000 3.45v17.1a1.95 1.95 0 001.95 1.95h20.1A1.95 1.95 0 0024 20.55zM22.048 20a.5.5 0 01-.5.5L2.5 20.549a.5.5 0 01-.5-.5L1.952 4a.5.5 0 01.5-.5l6.256-.025a.5.5 0 01.449.277l.761 1.523A1.941 1.941 0 0011.66 6.35l9.84-.05a.5.5 0 01.5.5z\"/><path d=\"M15.5 12.051a.251.251 0 01-.139-.224v-.66a3.195 3.195 0 10-6.389 0v.671a.252.252 0 01-.139.224A1.486 1.486 0 008 13.389V17.5A1.5 1.5 0 009.5 19h5.333a1.5 1.5 0 001.5-1.5v-4.111a1.5 1.5 0 00-.833-1.338zm-3.333 4.639a1.222 1.222 0 111.222-1.221 1.221 1.221 0 01-1.222 1.221zm1.694-5.3a.25.25 0 01-.25.25h-2.889a.25.25 0 01-.25-.25v-.222a1.695 1.695 0 013.389 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});