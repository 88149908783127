define("ember-svg-jar/inlined/hospital-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-house</title><path d=\"M12.345 4.844a.5.5 0 00-.69 0l-8 7.637a.5.5 0 00-.155.361v9.908a1 1 0 001 1h15a1 1 0 001-1v-9.908a.5.5 0 00-.155-.361zM17 16.861a.556.556 0 01-.556.556h-2.277a.5.5 0 00-.5.5V20.2a.555.555 0 01-.556.555h-2.222a.555.555 0 01-.556-.555v-2.283a.5.5 0 00-.5-.5H7.556A.556.556 0 017 16.861v-2.222a.555.555 0 01.556-.555h2.277a.5.5 0 00.5-.5v-2.278a.556.556 0 01.556-.556h2.222a.556.556 0 01.556.556v2.278a.5.5 0 00.5.5h2.277a.555.555 0 01.556.555z\"/><path d=\"M23.69 11.027l-11-10.5a1 1 0 00-1.38 0l-11 10.5a1 1 0 001.38 1.447l9.965-9.511a.5.5 0 01.69 0l9.965 9.511a1 1 0 001.38-1.447z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});