define("ember-svg-jar/inlined/deer-antlers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-antlers</title><path d=\"M8.9 14.564C6.661 12.38 4.505 9.937 4.505 2.5a1 1 0 10-2 0 30.563 30.563 0 00.235 3.939.838.838 0 01-.458-.273A1.642 1.642 0 012 5.1a1 1 0 00-1.986-.206 3.651 3.651 0 00.731 2.549 3.108 3.108 0 002.36 1.044 15.211 15.211 0 001.1 3.207 2.261 2.261 0 01-2.232-1.446 1 1 0 00-1.938.49C.312 11.859 1.6 13.7 4.561 13.7a7.728 7.728 0 00.825-.046A18.421 18.421 0 007.5 15.987c-4.013.636-4.739-1.16-4.783-1.284a1 1 0 00-1.917.571c.04.143.871 2.842 5.121 2.842a13.73 13.73 0 003.109-.4A3.3 3.3 0 019.5 19.5v2a1 1 0 102 0v-2c0-2.4-1.263-3.632-2.6-4.936zM23.245 9.543a1 1 0 00-1.219.7 2.279 2.279 0 01-2.291 1.45 15.746 15.746 0 00.993-3.241 2.93 2.93 0 002.527-1.012 3.651 3.651 0 00.731-2.549A1 1 0 1022 5.106a1.682 1.682 0 01-.277 1.054.965.965 0 01-.687.323 35.061 35.061 0 00.207-3.983 1 1 0 10-2 0c0 10.735-3.98 11.171-5.573 13.5a5.292 5.292 0 00-1.17 3.5v2a1 1 0 102 0v-2a3.462 3.462 0 01.419-1.792 13.86 13.86 0 003.164.408c4.25 0 5.082-2.7 5.122-2.842a1 1 0 10-1.921-.548c-.006.021-.681 1.913-4.784 1.26a13.989 13.989 0 002.137-2.326 7.838 7.838 0 00.8.043c2.963 0 4.249-1.844 4.524-2.965a.992.992 0 00-.716-1.195z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});