define("ember-svg-jar/inlined/shipment-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-open</title><path d=\"M15.071 11.538a.037.037 0 00-.041-.023.036.036 0 00-.03.036v7.033a.25.25 0 00.25.25h4.821A1.069 1.069 0 0021 17.666v-5.25a.25.25 0 00-.25-.25H16a1 1 0 01-.929-.628zM.08 9.955a.564.564 0 00.484.854H2.25a.25.25 0 00.25-.25V6.824a.251.251 0 00-.465-.124zM13.5 5.416a.25.25 0 00-.25-.25h-9a.25.25 0 00-.25.25v12.418a1 1 0 001 1h8.25a.25.25 0 00.25-.25zM23.959 9.912l-1.884-4.591a.249.249 0 00-.231-.155h-7.36a.253.253 0 00-.208.111.25.25 0 00-.023.234l1.974 4.809a.563.563 0 00.523.346h6.685a.551.551 0 00.524-.754z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});