define("ember-svg-jar/inlined/street-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>street-light</title><path d=\"M.5 3.5a2 2 0 002 2h4a2 2 0 002-2V2.367a.25.25 0 01.318-.241A3 3 0 0111 5v12a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V5a2.994 2.994 0 012.182-2.876.25.25 0 01.318.241V3.5a2 2 0 002 2h4a2 2 0 002-2V2a2 2 0 00-2-2h-5A5.16 5.16 0 0012 2a5.168 5.168 0 00-4.5-2h-5a2 2 0 00-2 2zm21-.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-.5A.5.5 0 0118 2h3a.5.5 0 01.5.5zm-19-.5A.5.5 0 013 2h3a.5.5 0 01.5.5V3a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5z\"/><path d=\"M2.168 6.947l-1 1.5a1 1 0 101.664 1.109l1-1.5a1 1 0 00-1.664-1.109zM5.945 6.67a1 1 0 00-.277 1.386l1 1.5a1 1 0 101.664-1.109l-1-1.5a1 1 0 00-1.387-.277zM21.832 6.947a1 1 0 00-1.664 1.109l1 1.5a1 1 0 101.664-1.109zM18.055 6.67a1 1 0 00-1.387.277l-1 1.5a1 1 0 101.664 1.109l1-1.5a1 1 0 00-.277-1.386zM23 22h-7.469a.5.5 0 01-.486-.379l-.371-1.485A1.5 1.5 0 0013.219 19h-2.438a1.5 1.5 0 00-1.455 1.136l-.371 1.485A.5.5 0 018.47 22H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});