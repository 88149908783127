define("ember-svg-jar/inlined/artboard-shapes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>artboard-shapes</title><path d=\"M6 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM2.5 5H1a1 1 0 000 2h1.5a1 1 0 000-2zM2.5 17H1a1 1 0 000 2h1.5a1 1 0 000-2zM18 0a1 1 0 00-1 1v1.5a1 1 0 002 0V1a1 1 0 00-1-1zM6 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM18 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM21.5 7H23a1 1 0 000-2h-1.5a1 1 0 000 2zM23 17h-1.5a1 1 0 000 2H23a1 1 0 000-2z\"/><rect x=\"12.25\" y=\"11.25\" width=\"3\" height=\"4\" rx=\".25\" ry=\".25\"/><path d=\"M11.5 9.75a.25.25 0 00.25-.25V9a.25.25 0 00-.25-.25H9a.25.25 0 00-.25.25v3.5a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-2a.75.75 0 01.75-.75z\"/><path d=\"M18 5H6a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zm-2 4.75a.75.75 0 01.75.75V16a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75v-1.5a.25.25 0 00-.25-.25H8a.75.75 0 01-.75-.75V8A.75.75 0 018 7.25h4.5a.75.75 0 01.75.75v1.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});