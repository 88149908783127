define("ember-svg-jar/inlined/cloud-server", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-server</title><path d=\"M6.634 11.5H16.84a1.066 1.066 0 00.126-.008A4.463 4.463 0 0021 7.105a4.308 4.308 0 00-1.337-3.122 4.488 4.488 0 00-2.59-1.231.252.252 0 01-.178-.11A5.988 5.988 0 0011.925 0a5.891 5.891 0 00-5.707 4.257.251.251 0 01-.21.182 3.663 3.663 0 00-1.86.8 3.5 3.5 0 00-1.3 2.714 3.361 3.361 0 00.959 2.458A4.272 4.272 0 006.634 11.5zM5.415 6.788a1.673 1.673 0 011.066-.379 1.784 1.784 0 01.347.035 1 1 0 001.195-.919A3.845 3.845 0 0111.925 2a3.946 3.946 0 013.541 2.16.954.954 0 00.934.558 2.587 2.587 0 011.874.705A2.322 2.322 0 0119 7.105 2.49 2.49 0 0116.766 9.5H6.645a2.448 2.448 0 01-1.431-.513 1.375 1.375 0 01-.363-1.033 1.485 1.485 0 01.564-1.166zM21 19H3a1 1 0 00-1 1v3a1 1 0 001 1h18a1 1 0 001-1v-3a1 1 0 00-1-1zM5.729 22.5a1 1 0 111-1 1 1 0 01-1 1.005zm4.021 0a1 1 0 111-1 1 1 0 01-1 1.005zM21 13H3a1 1 0 00-1 1v3a1 1 0 001 1h18a1 1 0 001-1v-3a1 1 0 00-1-1zM5.729 16.5a1 1 0 111-1 1 1 0 01-1 1.005zm4.021 0a1 1 0 111-1 1 1 0 01-1 1.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});