define("ember-svg-jar/inlined/family-outdoors-playhouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-outdoors-playhouse</title><path d=\"M24 9a1 1 0 00-1-1H10.089a.339.339 0 01-.339-.339.5.5 0 01.214-.41 1.246 1.246 0 00.262-1.8l-4-5a1.29 1.29 0 00-1.954 0l-4 5a1.246 1.246 0 00.262 1.8.5.5 0 01.215.411V23a1 1 0 002 0v-.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v.5a1 1 0 002 0V10.5a.5.5 0 01.5-.5H12a.5.5 0 01.5.5v5.11a1 1 0 002 0V10.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5v5.11a1 1 0 002 0V10.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V23a1 1 0 002 0zM7.25 7.478a.5.5 0 01.5.5V11a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V7.978a.5.5 0 01.5-.5zM2.75 14a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5zm.5 6a.5.5 0 01-.5-.5v-1.25a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5z\"/><path d=\"M18.856 17.473a7.922 7.922 0 01-6.213 0 1 1 0 00-.787 1.839 9.943 9.943 0 007.787 0 1 1 0 00-.787-1.839z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});