define("ember-svg-jar/inlined/rating-star-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-star-give</title><path d=\"M23.912 7.022a1.413 1.413 0 00-1.319-.922h-4.757a.5.5 0 01-.471-.333L15.65.912a1.407 1.407 0 00-2.635 0l-.005.019-1.71 4.84a.5.5 0 01-.472.333H6.072a1.407 1.407 0 00-.9 2.487l4.072 3.375a.5.5 0 01.155.543L9 13.7a.5.5 0 00.272.616 3.732 3.732 0 011.928 1.95 2.475 2.475 0 01.194.766.5.5 0 00.778.37l1.87-1.264a.5.5 0 01.575.011l4.2 3.076a1.407 1.407 0 002.17-1.577l-1.713-5.139a.5.5 0 01.155-.543l4.084-3.384a1.411 1.411 0 00.399-1.56z\"/><path d=\"M6.375 19.5a.625.625 0 010-1.25H8.9a1 1 0 00.919-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM2 15.75a1.02 1.02 0 00-1-1 1 1 0 00-1 1V23a1 1 0 001 1 1.044 1.044 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});