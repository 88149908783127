define("ember-svg-jar/inlined/shrink-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink-vertical</title><path d=\"M23.75 22.5a1.25 1.25 0 00-1.25-1.25h-9a.25.25 0 01-.25-.25v-3a.25.25 0 01.25-.25h1.364a.5.5 0 00.353-.854l-2.863-2.863a.5.5 0 00-.708 0L8.783 16.9a.5.5 0 00.353.854H10.5a.25.25 0 01.25.25v3a.25.25 0 01-.25.25h-9a1.25 1.25 0 000 2.5h21a1.25 1.25 0 001.25-1.254zM22.5.249h-21a1.25 1.25 0 000 2.5h9a.25.25 0 01.25.25v3.954a.25.25 0 01-.25.25H9.136a.5.5 0 00-.353.854l2.863 2.864a.5.5 0 00.708 0l2.863-2.864a.5.5 0 00-.353-.854H13.5a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25h9a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});