define("ember-svg-jar/inlined/style-two-pin-off-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-off-map</title><path d=\"M4.436 15.632a1 1 0 00.868-.279l2.04-2.041a1 1 0 00.145-1.232A4.891 4.891 0 016.75 9.5a5 5 0 015-5 4.891 4.891 0 012.58.739 1 1 0 001.232-.139l1.808-1.813a1 1 0 00-.127-1.522A9.483 9.483 0 002.25 9.5a12.63 12.63 0 001.45 5.591 1 1 0 00.736.541zM23.707.543a1 1 0 00-1.414 0l-22 21.75a1 1 0 101.414 1.414l4.631-4.578a32.989 32.989 0 004.454 4.525 1.5 1.5 0 001.917 0c2-1.666 8.541-7.609 8.541-14.153a9.43 9.43 0 00-.963-4.161l3.42-3.382a1 1 0 000-1.415zM16.75 9.5a5 5 0 01-5 5 .3.3 0 01-.211-.513L16.277 9.3a.278.278 0 01.473.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});