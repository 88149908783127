define("ember-svg-jar/inlined/coin-purse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>coin-purse</title><path d=\"M12.009 10A11.807 11.807 0 00.041 20.627a.791.791 0 00.224.613.867.867 0 00.623.26h22.03a1.066 1.066 0 00.772-.327.979.979 0 00.267-.768A11.819 11.819 0 0012.009 10z\"/><path d=\"M.708 12.67a1.25 1.25 0 001.767-.07 12.851 12.851 0 0119.066 0 1.251 1.251 0 101.84-1.694 15.428 15.428 0 00-7.837-4.624.25.25 0 01-.125-.414 2.015 2.015 0 10-3.243-2.358.25.25 0 01-.431 0A1.981 1.981 0 009.961 2.5a2 2 0 00-1.443 3.378.249.249 0 01-.122.416A15.432 15.432 0 00.635 10.9a1.251 1.251 0 00.073 1.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});