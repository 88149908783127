define("ember-svg-jar/inlined/kitchenware-spatula-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-spatula-1</title><path d=\"M3.622 18.684a.5.5 0 00-.707 0L.5 21.1a1.7 1.7 0 102.4 2.4l2.418-2.418a.5.5 0 000-.707zM11.128 14.567l-1.694-1.694a.5.5 0 00-.707 0l-4.045 4.044a.5.5 0 000 .707l1.694 1.694a.5.5 0 00.708 0l4.044-4.044a.5.5 0 000-.707zM23.414 8.717a2 2 0 000-2.828l-5.3-5.3a2 2 0 00-2.828 0L10.67 5.2a2 2 0 00-.57 1.655l.53 4.347a2.48 2.48 0 002.17 2.169l4.347.53a2 2 0 001.653-.571zM12.758 7.4a.75.75 0 01-.53-1.28l2.827-2.829a.75.75 0 011.061 1.061l-2.828 2.825a.744.744 0 01-.53.223zm1.287 3.308a.75.75 0 01-.53-1.28l4.949-4.951a.75.75 0 111.06 1.06l-4.949 4.951a.744.744 0 01-.53.217zm6.667-1.76l-2.829 2.827a.75.75 0 01-1.06-1.06l2.828-2.828a.75.75 0 011.061 1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});