define("ember-svg-jar/inlined/office-shelf-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-shelf-corner</title><path d=\"M23.109 10.791a1 1 0 10-1.218-1.586L18.1 12.123a.251.251 0 00-.1.2v1.892a.251.251 0 00.4.2zM21.886 16.209L18.1 19.161a.251.251 0 00-.1.2v1.9a.25.25 0 00.4.2l4.71-3.669a1 1 0 10-1.228-1.578z\"/><rect x=\".5\" y=\"10.748\" width=\"16\" height=\"5.5\" rx=\".25\" ry=\".25\"/><path d=\"M.75 17.748A.25.25 0 00.5 18v4.25a1.5 1.5 0 001.5 1.5h13a1.5 1.5 0 001.5-1.5V18a.25.25 0 00-.25-.25zM.5 7.268V9a.25.25 0 00.25.25h15.5A.25.25 0 0016.5 9v-.75a1.5 1.5 0 00-1.5-1.5H5.029a.25.25 0 01-.134-.461L6.54 5.248l4.994-3.154A1 1 0 1010.466.4L2.794 5.248.966 6.4a1 1 0 00-.466.868z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});