define("ember-svg-jar/inlined/style-three-pin-guitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-guitar</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.541 14.153a1.5 1.5 0 001.92 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm0 18.884c-2.087 0-4-.494-4-2.778A5.556 5.556 0 019.177 13.4a1.15 1.15 0 00.1-.962 2.064 2.064 0 011.056-2.511A2.1 2.1 0 0011 9.339V7.248A.25.25 0 0010.75 7H10a.5.5 0 010-1h.75a.25.25 0 00.25-.25v-.5a.25.25 0 00-.25-.25H10a.5.5 0 010-1h.75a.25.25 0 00.25-.25V3.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v.248a.25.25 0 00.25.25H14a.5.5 0 010 1h-.75a.25.25 0 00-.25.25v.5a.25.25 0 00.25.25H14a.5.5 0 010 1h-.75a.25.25 0 00-.25.25v2.09a2.077 2.077 0 00.663.587 2.064 2.064 0 011.056 2.511 1.153 1.153 0 00.1.962A5.568 5.568 0 0116 16.106c0 2.249-1.869 2.778-4 2.778z\"/><circle cx=\"12\" cy=\"13\" r=\"1.25\"/><path d=\"M13 16h-2a.5.5 0 000 1h2a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});