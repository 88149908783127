define("ember-svg-jar/inlined/diamond-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diamond-give</title><path d=\"M18.53 5.041a.027.027 0 000-.027.026.026 0 00-.018-.014H5.488a.026.026 0 00-.022.014.027.027 0 000 .027l5.7 6.582a1.093 1.093 0 001.652 0zM18.578 3.668L16.155.438A1.09 1.09 0 0015.281 0H8.72a1.091 1.091 0 00-.875.438l-2.423 3.23A.207.207 0 005.587 4h12.826a.207.207 0 00.165-.332z\"/><rect x=\"14.5\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M21 12.4a.248.248 0 01-.073.176l-2.281 2.281a.25.25 0 01-.421-.232l.493-2.152c.343-1.437-.971-2.051-1.688-.937a22.652 22.652 0 00-1.52 3.926.251.251 0 00-.011.072v4.216a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-1.177a.252.252 0 01.074-.177c.432-.428 2.641-2.616 3.632-3.6a1 1 0 00.294-.709V6.65a.25.25 0 00-.242-.25c-.623-.019-2.237.467-2.257 6z\"/><rect x=\"3.5\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M4.5 19.75a.25.25 0 00.25.25h3.5a.25.25 0 00.25-.25v-4.221a.251.251 0 00-.011-.072 22.652 22.652 0 00-1.52-3.926c-.717-1.114-2.031-.5-1.688.937l.493 2.152a.25.25 0 01-.42.232l-2.281-2.279A.248.248 0 013 12.4c-.02-5.53-1.634-6.016-2.258-6a.25.25 0 00-.242.25v7.393a1 1 0 00.329.742c1.086.983 3.194 3.185 3.6 3.612a.25.25 0 01.069.172z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});