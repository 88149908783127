define("ember-svg-jar/inlined/flying-insect-bee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bee</title><path d=\"M8.894 12a.5.5 0 00-.474.341A8.394 8.394 0 008 15a.5.5 0 00.5.5h7a.5.5 0 00.5-.5 8.394 8.394 0 00-.42-2.659.5.5 0 00-.474-.341zM16 0a3.5 3.5 0 00-3.5 3.5v.531a4.048 4.048 0 00-1 0V3.5A3.5 3.5 0 008 0a1 1 0 000 2 1.5 1.5 0 011.5 1.5v1.377a4 4 0 00-.782 5.409.5.5 0 00.41.214h5.744a.5.5 0 00.41-.214 4 4 0 00-.782-5.408V3.5A1.5 1.5 0 0116 2a1 1 0 000-2zM15.518 17.207a.5.5 0 00-.406-.207H8.888a.5.5 0 00-.475.657A4.864 4.864 0 0011 20.81V23a1 1 0 002 0v-2.19a4.864 4.864 0 002.587-3.153.5.5 0 00-.069-.45zM.75 19.234a3 3 0 106 0v-6.8a.5.5 0 00-.7-.456c-2.63 1.18-5.3 3.394-5.3 7.256zM17.954 11.983a.5.5 0 00-.7.456v6.8a3 3 0 106 0c-.004-3.867-2.674-6.081-5.3-7.256z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});