define("ember-svg-jar/inlined/delivery-truck-cargo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-cargo</title><path d=\"M12.545 17.25H9.456a1 1 0 00-.978 1.12v.023a.974.974 0 00.978.857h3.083a.973.973 0 00.977-.858v-.023a.994.994 0 00-.971-1.119z\"/><path d=\"M24 11.25a4 4 0 00-4-4h-3.25A.25.25 0 0116.5 7V4.75a2 2 0 00-2-2H2a2 2 0 00-2 2v13.5a1 1 0 001 1h.262a.25.25 0 00.185-.081.253.253 0 00.065-.191 3.355 3.355 0 01.453-1.942.258.258 0 00.035-.127V5.75a1 1 0 011-1h10.5a1 1 0 011 1v10.006a.25.25 0 00.4.2 3.469 3.469 0 012.1-.7 3.5 3.5 0 013.5 3.5 2.039 2.039 0 01-.013.223.251.251 0 00.249.277H22a2 2 0 002-2v-6zm-2 0v.732a.251.251 0 01-.254.25l-2.5-.038a.25.25 0 01-.246-.25V9.5a.25.25 0 01.25-.25H20a2 2 0 012 2z\"/><circle cx=\"17\" cy=\"18.75\" r=\"2.5\"/><circle cx=\"5\" cy=\"18.75\" r=\"2.5\"/><rect x=\"6\" y=\"6.25\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/><path d=\"M7.5 15.25v-3a.5.5 0 00-.5-.5H4a.5.5 0 00-.5.5v3a.524.524 0 00.5.5h3a.5.5 0 00.5-.5z\"/><rect x=\"9\" y=\"11.75\" width=\"4\" height=\"4\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});