define("ember-svg-jar/inlined/astrology-saggitarius", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-saggitarius</title><path d=\"M23.5 1.5a1 1 0 00-1-1h-8a1 1 0 000 2h5a.25.25 0 01.177.427L6.856 15.735a.5.5 0 01-.707 0l-2.942-2.942a1 1 0 00-1.414 1.414l2.941 2.941a.5.5 0 010 .708L.793 21.793a1 1 0 001.414 1.414l3.941-3.938a.5.5 0 01.707 0l2.938 2.938a1 1 0 001.414-1.414L8.27 17.856a.5.5 0 010-.707l12.8-12.792a.25.25 0 01.427.177V9.5a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});