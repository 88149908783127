define("ember-svg-jar/inlined/diagram-arrow-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-arrow-up-1</title><path d=\"M0 22.978a1 1 0 001 1h22a1 1 0 001-1v-3a1 1 0 00-1-1h-7.75a.25.25 0 01-.25-.25V9.013a.25.25 0 01.25-.25h3.25a1 1 0 00.733-1.68L13.074.451a1.505 1.505 0 00-2.147 0l-6.16 6.632a1 1 0 00.733 1.68h3.25a.25.25 0 01.25.25v9.715a.25.25 0 01-.25.25H1a1 1 0 00-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});