define("ember-svg-jar/inlined/gardening-digging-fork", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-digging-fork</title><path d=\"M23.46 13.111L16 9.254c-1.872-.968-3.459-1.012-4.9-.115a.5.5 0 01-.617-.072l-.823-.822a.5.5 0 01-.112-.536A2 2 0 009.1 5.564L4.268.731A2.5 2.5 0 00.733 4.266L5.565 9.1a2 2 0 002.145.447.5.5 0 01.536.112l.823.823a.5.5 0 01.071.617c-.9 1.444-.853 3.032.115 4.9l3.857 7.457a1 1 0 101.776-.919l-.942-1.822a.5.5 0 01.091-.584l.628-.628a.25.25 0 01.389.044l.8 1.278a1 1 0 101.7-1.058l-1-1.6a.5.5 0 01.071-.618l.932-.932a.5.5 0 01.618-.07l1.6 1a1 1 0 001.058-1.7l-1.278-.8a.25.25 0 01-.044-.389l.628-.628a.5.5 0 01.584-.091l1.822.943a1 1 0 00.919-1.777zm-9.94 4.712l-.464.464a.249.249 0 01-.4-.061l-1.626-3.143a3.63 3.63 0 01-.528-1.977.25.25 0 01.462-.106l2.626 4.2a.5.5 0 01-.07.623zm2.349-2.349l-.394.394a.25.25 0 01-.389-.044l-.655-1.05a.25.25 0 01.344-.344l1.05.655a.25.25 0 01.044.389zm2.419-2.419l-.464.464a.5.5 0 01-.618.07L13 10.964a.25.25 0 01-.111-.27.252.252 0 01.219-.192 3.624 3.624 0 011.977.529l3.142 1.625a.248.248 0 01.132.183.251.251 0 01-.071.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});