define("ember-svg-jar/inlined/exotic-food-samosa-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exotic-food-samosa-1</title><path d=\"M14.646 17.157c0 3.523 1.742 5.983 4.238 5.983h.878c2.5 0 4.238-2.46 4.238-5.983 0-3.274-9.354-3.274-9.354 0zm4.677 1c-2.088 0-3.181-.741-3.181-1s1.093-1 3.181-1 3.181.742 3.181 1-1.094.997-3.181.997zM20.866 7.44c.158.2.33.4.5.586a2.151 2.151 0 01.639 1.414 2.856 2.856 0 01-1.367 2.06 1 1 0 101.179 1.609A4.624 4.624 0 0024 9.44c0-2.811-3.49-4.71-5.063-5.17a.249.249 0 00-.277.379 25.181 25.181 0 002.206 2.791z\"/><path d=\"M15.7 9.4a.5.5 0 00.409.214h3.255a.948.948 0 00.838-.5.969.969 0 00-.102-1.036 28.11 28.11 0 01-4.528-6.8.954.954 0 00-1.622.074A22.694 22.694 0 0112.36 4.3a.5.5 0 00-.011.519A28.317 28.317 0 0015.7 9.4zM10.6 13.648C5.284 13.316 2 11.206 2 9.44c0-1.109 1.279-2.355 3.573-3.211a1 1 0 00.486-.387Q6.613 5 7.072 4.143a.249.249 0 00-.278-.361C2.675 4.774 0 6.914 0 9.44c0 3.213 4.405 5.819 10.475 6.2h.063a1 1 0 00.061-1.992z\"/><path d=\"M14.911 10.922a.97.97 0 00-.093-1.038 28.061 28.061 0 01-4.529-6.8.955.955 0 00-1.622.075 26.082 26.082 0 01-4.533 6.785.95.95 0 00.79 1.478h9.15a.949.949 0 00.837-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});