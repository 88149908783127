define("ember-svg-jar/inlined/asian-food-rice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>asian-food-rice</title><path d=\"M23.375 13.083a4.871 4.871 0 00-.643-2.461.931.931 0 00-1.3-.188.966.966 0 00-.224 1.322 1.75 1.75 0 01.16.49c.135.407-.357.363-.357.363H2.87a.241.241 0 01-.236-.3 2.845 2.845 0 012.734-2.077 2.415 2.415 0 011.083.212.95.95 0 001.317-.659A3.476 3.476 0 0110.294 7a3.383 3.383 0 012.115.2.949.949 0 10.77-1.735 5.185 5.185 0 00-2.116-.447 5.262 5.262 0 00-4.73 3.017.582.582 0 01-.522.322 5.74 5.74 0 00-.443-.017 4.752 4.752 0 00-4.747 4.743v.52c.19 3.749 2.479 6.477 6.239 7.729a.233.233 0 01.148.3l-.418 1.074A.949.949 0 007.474 24h9.053a.948.948 0 00.883-1.294l-.417-1.069a.232.232 0 01.147-.3c3.759-1.252 6.051-3.98 6.238-7.729.006-.108-.003-.449-.003-.525z\"/><path d=\"M15.216 10.919a.976.976 0 001.15-.691l2.262-9.048a.949.949 0 10-1.841-.46l-2.262 9.048a.949.949 0 00.691 1.151zM18.018 11.813a1.024 1.024 0 001.255-.476l4.017-8.924a.949.949 0 10-1.731-.779l-4.017 8.924a.949.949 0 00.476 1.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});