define("ember-svg-jar/inlined/award-star-head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-star-head</title><path d=\"M16.374 12.561a1.19 1.19 0 00.3-1.377l-1.113-2.559a.25.25 0 01.056-.28l2.011-1.933a1.2 1.2 0 00.285-1.312 1.172 1.172 0 00-1.113-.72h-2.259a.252.252 0 01-.228-.147L13.1 1.567l-.009-.018A1.217 1.217 0 0012 .88a1.223 1.223 0 00-1.1.687L9.686 4.233a.25.25 0 01-.228.147H7.2a1.171 1.171 0 00-1.113.72 1.2 1.2 0 00.289 1.314l2.007 1.931a.249.249 0 01.056.28l-1.111 2.558a1.189 1.189 0 00.3 1.377 1.213 1.213 0 001.429.159l2.822-1.59a.249.249 0 01.245 0l2.822 1.59a1.223 1.223 0 001.428-.158zM9.314 18.442a1 1 0 00-1.981.282l.267 1.859a.251.251 0 01-.212.283l-1.851.264a1 1 0 00.14 1.99c.1 0-.086.022 2.736-.38a1.5 1.5 0 001.272-1.7z\"/><path d=\"M6.217 19.659a1.493 1.493 0 00.563-1l.235-1.959a1 1 0 10-1.985-.239l-.146 1.211a.249.249 0 01-.278.218l-1.2-.146a1 1 0 00-.241 1.985l1.949.238a1.491 1.491 0 001.103-.308zM3.953 16.49a1.491 1.491 0 00.8-.82l.635-1.581a1 1 0 10-1.857-.745l-.354.882a.25.25 0 01-.325.139l-.876-.353a1 1 0 00-.749 1.854l1.576.636a1.486 1.486 0 001.15-.012zM2.7 13.177a1.484 1.484 0 00.989-.585l.9-1.2A1 1 0 003.3 9.928l-.223-1.912a1 1 0 10-1.987.228l.231 2.016a.987.987 0 00-1.121.332 1 1 0 00.2 1.4l1.192.9a1.487 1.487 0 001.108.285zM18.465 21.13l-1.851-.264a.251.251 0 01-.212-.283l.265-1.859a1 1 0 00-1.981-.282l-.369 2.6a1.5 1.5 0 001.271 1.7c2.8.4 2.635.381 2.737.381a1 1 0 00.14-1.99z\"/><path d=\"M20.6 17.746l-1.2.146a.249.249 0 01-.278-.218l-.146-1.211a1 1 0 00-1.985.239l.235 1.959a1.5 1.5 0 001.668 1.308l1.95-.238a1 1 0 00-.241-1.985zM22.021 14.012l-.876.353a.25.25 0 01-.325-.139l-.354-.882a1 1 0 00-1.857.745l.635 1.58a1.5 1.5 0 001.956.831l1.575-.636a1 1 0 00-.749-1.854z\"/><path d=\"M23.8 10.592a.987.987 0 00-1.119-.332l.231-2.016a1 1 0 10-1.987-.228L20.7 9.928a1 1 0 00-1.29 1.468l.9 1.2a1.5 1.5 0 002.1.3l1.192-.9a1 1 0 00.198-1.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});