define("ember-svg-jar/inlined/style-three-pin-plane-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-plane-1</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.384 12.384a1.279 1.279 0 01-1.768 0l-1.939-1.939a.25.25 0 00-.427.176v2.155a.5.5 0 00.146.353l1.488 1.487a1.25 1.25 0 01-1.768 1.768l-.762-.763a.5.5 0 00-.708 0l-.762.763a1.25 1.25 0 01-1.768-1.768l1.484-1.487a.5.5 0 00.146-.353v-2.155a.25.25 0 00-.427-.176l-1.935 1.939a1.279 1.279 0 01-1.768 0 1.251 1.251 0 010-1.768L10.6 6.629a.5.5 0 00.146-.353V4.5a1.25 1.25 0 012.5 0v1.776a.5.5 0 00.146.353l3.988 3.987a1.251 1.251 0 010 1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});