define("ember-svg-jar/inlined/toys-rocking-horse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-rocking-horse</title><path d=\"M18.03 16.473a6.565 6.565 0 00-5.544-3.5 6.713 6.713 0 00-5.6 3.478 5.537 5.537 0 00-.284.586 1 1 0 001.852.756 3.617 3.617 0 01.186-.382 4.772 4.772 0 013.841-2.438 4.567 4.567 0 013.781 2.442c.046.085.088.172.126.26a1 1 0 101.84-.783 4.249 4.249 0 00-.198-.419z\"/><circle cx=\"4.997\" cy=\"4.428\" r=\"1\"/><path d=\"M1.828 8.359a2.828 2.828 0 002.192.179l.285-.1a.5.5 0 01.643.343l.765 2.826a.5.5 0 01-.132.487 13.867 13.867 0 00-2.738 3.8 1 1 0 001.806.859 12 12 0 012.9-3.741 1 1 0 00.338-1.04l-1.42-5.25a1 1 0 00-1.287-.689l-1.793.608c-.577.188-1.153-.331-1.332-.9A1.348 1.348 0 012.9 4.163l3.368-1.509a1.025 1.025 0 011.153.559l1.67 3.68A1 1 0 0010 7.48c.259 0 .529 0 .807-.01a.5.5 0 01.5.378l.6 2.4a.484.484 0 00.48.37h2.72a.484.484 0 00.48-.37l.472-1.889a.5.5 0 01.726-.317 2.321 2.321 0 01.525.386A2.749 2.749 0 0118 10.48v2.63a1 1 0 00.458.84 6.72 6.72 0 011.767 2.506 1 1 0 001.822-.824 10.2 10.2 0 00-1.776-2.732 1 1 0 01-.271-.691v-1.662a.25.25 0 01.374-.218l2.137 1.212a.986.986 0 00.492.131 1 1 0 00.494-1.87l-4.3-2.439a2.422 2.422 0 01-.5-.377A7.062 7.062 0 0013.9 5.432a.5.5 0 01-.445-.309l-1.226-2.97A3.539 3.539 0 007.7.241L6.9.56a1 1 0 01-.378.071 2.65 2.65 0 00-1 .167L2.064 2.346a3.308 3.308 0 00-1.917 4 3.424 3.424 0 001.681 2.013zM21.019 18.257C20.988 18.274 17.8 20 12 20s-8.988-1.726-9.008-1.737a2 2 0 00-1.984 3.474C1.17 21.829 5.049 24 12 24s10.83-2.171 10.992-2.263a2 2 0 00-1.973-3.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});