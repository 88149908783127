define("ember-svg-jar/inlined/kitchen-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-storage</title><path d=\"M15.227 8.5H13a1 1 0 000 2h9.5a1 1 0 000-2h-1.477a.25.25 0 01-.213-.119.248.248 0 01-.011-.243l.1-.191a1 1 0 10-1.79-.894l-.654 1.309a.249.249 0 01-.224.138h-.2a.25.25 0 01-.213-.119.248.248 0 01-.011-.243l.1-.191a1 1 0 10-1.79-.894l-.654 1.309a.249.249 0 01-.236.138zM6.75 11V9A.75.75 0 006 8.25H3a.75.75 0 00-.75.75v2a.5.5 0 01-.5.5H1.5a1 1 0 000 2H11a1 1 0 000-2H7.25a.5.5 0 01-.5-.5z\"/><rect x=\"8\" width=\"8\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M.5 5h6a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-5A1.5 1.5 0 000 1.5v3a.5.5 0 00.5.5zM22.5 0h-5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-3A1.5 1.5 0 0022.5 0zM22.5 15h-21A1.5 1.5 0 000 16.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 1.5 1.5 0 00-1.5-1.5z\"/><rect x=\"8\" y=\"18\" width=\"8\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M6.5 18h-6a.5.5 0 00-.5.5v4c0 .725.525 1.5 2 1.5h4.5a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zM23.5 18h-6a.5.5 0 00-.5.5v5a.5.5 0 00.5.5H23c.458 0 1-.812 1-1.5v-4a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});