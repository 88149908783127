define("ember-svg-jar/inlined/read-email-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M18.375 10.542a.249.249 0 00-.254.007L13.2 13.627a2.261 2.261 0 01-2.385 0l-4.929-3.078a.249.249 0 00-.254-.007.251.251 0 00-.129.219V15.5A1.5 1.5 0 007 17h10a1.5 1.5 0 001.5-1.5v-4.739a.251.251 0 00-.125-.219z\"/><path d=\"M17 7H7a1.5 1.5 0 00-1.5 1.5.073.073 0 00.036.063l6.067 3.792a.754.754 0 00.8 0l6.068-3.792A.077.077 0 0018.5 8.5 1.5 1.5 0 0017 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});