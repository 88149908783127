define("ember-svg-jar/inlined/gardening-sprinkle-bottle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-sprinkle-bottle-1</title><path d=\"M23.726 12.562l-1.04-4.68a4 4 0 00-3.9-3.132h-3.027a.5.5 0 01-.5-.5V2.5a.5.5 0 01.5-.5h.5a1 1 0 000-2h-6.5a1 1 0 000 2h3a.5.5 0 01.5.5v1.75a.5.5 0 01-.5.5h-.5a3.92 3.92 0 00-.5.031L8.447 5.2a.5.5 0 00-.438.5v.618a.5.5 0 00.438.5l3.124.39a.5.5 0 01.438.5v.372a.5.5 0 01-.416.493A4.005 4.005 0 008.259 12.5V22a2 2 0 002 2h7.5a2 2 0 002-2v-9.5a4.005 4.005 0 00-3.334-3.944.5.5 0 01-.416-.493V7.25a.5.5 0 01.5-.5h2.322a2 2 0 011.952 1.566l1.037 4.669a.976.976 0 101.906-.423zm-5.967-.062v2.25a.5.5 0 01-.5.5h-6.5a.5.5 0 01-.5-.5V12.5a2 2 0 012-2h3.5a2 2 0 012 2z\"/><circle cx=\"4.25\" cy=\"4.5\" r=\"1\"/><circle cx=\"4.25\" cy=\"7.5\" r=\"1\"/><circle cx=\"1.25\" cy=\"3\" r=\"1\"/><circle cx=\"1.25\" cy=\"6\" r=\"1\"/><circle cx=\"1.25\" cy=\"9\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});