define("ember-svg-jar/inlined/ticket-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ticket-buy</title><path d=\"M23.871 2.519a1.02 1.02 0 00-.617-.493l-1.585-.457a1.03 1.03 0 00-1.224.562.934.934 0 01-1.777-.511 1.025 1.025 0 00-.74-1.128L16.365.041a1.031 1.031 0 00-1.276.7l-.762 2.643a.5.5 0 00.329.616l7.851 2.486a.5.5 0 00.631-.338l.821-2.848a1.024 1.024 0 00-.088-.781zM22.116 7.939l-7.85-2.486a.5.5 0 00-.632.338l-1.964 6.816a1.034 1.034 0 00.705 1.274l1.563.45a1.027 1.027 0 001.227-.566.934.934 0 011.776.509 1.033 1.033 0 00.735 1.134l1.589.459a1.048 1.048 0 00.287.041 1.034 1.034 0 00.989-.746l1.905-6.608a.5.5 0 00-.33-.615zM7.079 9.347a.25.25 0 01-.011-.431 4.075 4.075 0 10-4.414-.082.247.247 0 01.11.222.25.25 0 01-.137.208A4.778 4.778 0 000 13.527v2.851a.5.5 0 00.5.5h1.232a.5.5 0 01.5.45l.622 6.223a.5.5 0 00.5.45H6.2a.5.5 0 00.5-.45l.623-6.223a.5.5 0 01.5-.45h1.225a.5.5 0 00.354-.147.5.5 0 00.146-.353v-2.851a4.778 4.778 0 00-2.469-4.18zm-1.555 3.371a.75.75 0 01-1.5 0v-1.692a.75.75 0 011.5 0zm-.6-5.192a2.076 2.076 0 01-1.987-2.671.25.25 0 01.37-.142 5.076 5.076 0 002.643.738A5.194 5.194 0 006.7 5.4a.25.25 0 01.286.273 2.078 2.078 0 01-2.061 1.853z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});