define("ember-svg-jar/inlined/single-neutral-actions-remove.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-remove</title><path d=\"M11.335 12.419a.5.5 0 00-.117-.741 7.475 7.475 0 00-11.2 4.179.5.5 0 00.482.643h8.64a.5.5 0 00.492-.411 7.96 7.96 0 011.703-3.67z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-3.182 8.268l1.414-1.414a.5.5 0 000-.708l-1.414-1.414a1 1 0 111.414-1.414l1.414 1.415a.5.5 0 00.707 0l1.415-1.415a1 1 0 111.414 1.414l-1.414 1.414a.5.5 0 000 .708l1.414 1.414a1 1 0 11-1.414 1.414l-1.415-1.415a.5.5 0 00-.707 0l-1.414 1.415a1 1 0 11-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});