define("ember-svg-jar/inlined/car-dashboard-long-lights-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-long-lights-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M10.839 10.519l-5.031 1A1 1 0 006 13.5a1.016 1.016 0 00.2-.019l5.031-1a1 1 0 10-.39-1.962zM10.839 7.019l-5.031 1A1 1 0 006 10a1.016 1.016 0 00.2-.019l5.031-1a1 1 0 10-.39-1.962zM10.839 14.019l-5.031 1A1 1 0 006 17a1.016 1.016 0 00.2-.019l5.031-1a1 1 0 00-.39-1.962zM15 6.751a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5 5 5 0 000-10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});