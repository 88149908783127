define("ember-svg-jar/inlined/video-edit-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-edit-subtract</title><path d=\"M9.5 17.742a.25.25 0 00-.25-.242H8a2 2 0 01-2-2V14a.5.5 0 01.5-.5h3.931a.252.252 0 00.215-.121 7.972 7.972 0 01.687-.97.249.249 0 00-.192-.409H6.5a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v1.918a.25.25 0 00.349.229 7.922 7.922 0 012.424-.614.25.25 0 00.227-.249V4a4 4 0 00-4-4H1a1 1 0 000 2 2 2 0 012 2v11.5a4 4 0 004 4h2.437a.25.25 0 00.244-.3 7.919 7.919 0 01-.181-1.458zM14 5.5a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5H12a2 2 0 012 2z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zM15 18.25a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});