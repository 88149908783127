define("ember-svg-jar/inlined/social-media-retweet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-retweet</title><path d=\"M12.86 15.65a.5.5 0 00-.35-.15h-5A.5.5 0 017 15v-4.5h1.5a.5.5 0 00.37-.83l-4-4.5a.52.52 0 00-.75 0l-4 4.5a.5.5 0 00.37.83H2v6.2a3.1 3.1 0 003.12 2.8H15.5a.5.5 0 00.35-.85zM24 13.8a.5.5 0 00-.46-.3H22v-6a3.27 3.27 0 00-3.12-3H8.5a.5.5 0 00-.35.85l3 3a.5.5 0 00.35.15h5a.5.5 0 01.5.5v4.5h-1.5a.5.5 0 00-.37.83l4 4.5a.5.5 0 00.75 0l4-4.5a.5.5 0 00.12-.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});