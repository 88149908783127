define("ember-svg-jar/inlined/disability-walking-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-walking-aid</title><circle cx=\"15\" cy=\"2.5\" r=\"2.5\"/><path d=\"M17.5 6.64h-5a3.99 3.99 0 00-3.7 2.517.25.25 0 00.23.343h.47a1.973 1.973 0 011.333.52.249.249 0 00.337 0 .485.485 0 01.33-.129.5.5 0 01.5.5V22.75a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0V10.39a.5.5 0 011 0v3.86a1.25 1.25 0 002.5 0v-3.61a4.005 4.005 0 00-4-4z\"/><path d=\"M9.5 14.258V12.5a1 1 0 000-2h-6a1 1 0 000 2v1.758a4.917 4.917 0 002 3.656V22a1 1 0 000 2h2a1 1 0 000-2v-4.086a4.913 4.913 0 002-3.656zm-4-1.508a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.508a2.015 2.015 0 01-.586 1.414l-.237.237a.25.25 0 01-.354 0l-.237-.237a2.015 2.015 0 01-.586-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});