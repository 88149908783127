define("ember-svg-jar/inlined/desktop-computer-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-computer-3</title><path d=\"M7.5 18.5h1.737a.5.5 0 00.5-.5L9.749.5a.5.5 0 00-.5-.5H8.5A1.5 1.5 0 007 1.5V18a.5.5 0 00.5.5zM11.737 18.5H16.5a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5h-4.756a.5.5 0 00-.5.5l-.007 10a.5.5 0 00.5.5zm2.249-5a1 1 0 11-1 1 1 1 0 011-1zM17 6V1.5A1.5 1.5 0 0015.5 0h-3.751a.5.5 0 00-.5.5V6a.5.5 0 00.5.5H16.5A.5.5 0 0017 6zM17 20H7a2.5 2.5 0 00-2.5 2.5A1.5 1.5 0 006 24h12a1.5 1.5 0 001.5-1.5A2.5 2.5 0 0017 20z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});