define("ember-svg-jar/inlined/vectors-anchor-rectangle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-rectangle-alternate</title><rect x=\"20\" y=\"10\" width=\"2\" height=\"4\" rx=\".25\" ry=\".25\"/><path d=\"M7.3 3.723a.249.249 0 00.174.307l8.518 2.37a.249.249 0 00.307-.174l.4-1.445a.249.249 0 00-.174-.307L8.008 2.1a.249.249 0 00-.307.174z\"/><rect x=\"2\" y=\"7.5\" width=\"2\" height=\"9\" rx=\".25\" ry=\".25\"/><path d=\"M7.473 19.97a.249.249 0 00-.174.307l.4 1.445a.249.249 0 00.307.174l8.519-2.366a.249.249 0 00.174-.307l-.4-1.445a.249.249 0 00-.307-.174z\"/><circle cx=\"21\" cy=\"5.5\" r=\"3\"/><circle cx=\"3\" cy=\"3\" r=\"3\"/><circle cx=\"21\" cy=\"18.5\" r=\"3\"/><circle cx=\"3\" cy=\"21\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});