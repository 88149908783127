define("ember-svg-jar/inlined/server-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-1</title><path d=\"M22.5 2.25h-21A1.5 1.5 0 000 3.75v2.5a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-2.5a1.5 1.5 0 00-1.5-1.5zM3.25 5A1.25 1.25 0 114.5 6.25 1.251 1.251 0 013.25 5zM8.5 6.25A1.25 1.25 0 119.75 5 1.252 1.252 0 018.5 6.25zm-7.458-2.7l-.005.014zM22.5 9.25h-21a1.5 1.5 0 00-1.5 1.5v2.5a1.5 1.5 0 001.5 1.5h9.25A.25.25 0 0111 15v4.5a.25.25 0 01-.25.25H5.5a1 1 0 000 2h13a1 1 0 000-2h-5.25a.25.25 0 01-.25-.25V15a.25.25 0 01.25-.25h9.25a1.5 1.5 0 001.5-1.5v-2.5a1.5 1.5 0 00-1.5-1.5zM3.25 12a1.25 1.25 0 111.25 1.25A1.251 1.251 0 013.25 12zm5.25 1.25A1.25 1.25 0 119.75 12a1.252 1.252 0 01-1.25 1.25zM23 21.75a1 1 0 000-2h-1a1 1 0 000 2zM1 21.75h1a1 1 0 000-2H1a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});