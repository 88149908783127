define("ember-svg-jar/inlined/arrow-dot-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-dot-down</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-3 12.5A1.5 1.5 0 1118 11a1.5 1.5 0 011.5 1.5zM10 7a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0110 7zM8.5 4.5A1.5 1.5 0 1110 6a1.5 1.5 0 01-1.5-1.5zM10 11a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0110 11zm0 4a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0110 15zm2 4a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0112 19zm.5-2.5A1.5 1.5 0 1114 18a1.5 1.5 0 01-1.5-1.5zM14 14a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 14zm0-4a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 10zm0-4a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0114 6zm-6.5 6.5A1.5 1.5 0 116 11a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});