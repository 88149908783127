define("ember-svg-jar/inlined/weather-night-wind-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-night-wind-1</title><path d=\"M23.523 10.337a4.5 4.5 0 010-7.674 1 1 0 000-1.7A6.5 6.5 0 0014.35 3.52a.249.249 0 00.148.354 8.5 8.5 0 011.356.552.251.251 0 00.333-.1A4.5 4.5 0 0120.125 2a4.723 4.723 0 01.506.028.25.25 0 01.167.4A6.465 6.465 0 0019.375 6.5a6.6 6.6 0 00.211 1.657.262.262 0 00.019.049 8.49 8.49 0 01.54 1.364.286.286 0 00.02.048 6.387 6.387 0 00.633.948.25.25 0 01-.167.4.192.192 0 00-.17.211v.01a.248.248 0 00.075.154 6.516 6.516 0 011.034 1.309.25.25 0 00.281.112 6.508 6.508 0 001.672-.725 1 1 0 000-1.7z\"/><path d=\"M19.094 12.073a.25.25 0 01-.1-.193A7 7 0 005.338 9.84a.249.249 0 01-.22.173 5.514 5.514 0 00-4.978 4.25.251.251 0 00.067.233.248.248 0 00.233.067A2.516 2.516 0 011 14.5h.965a.249.249 0 00.235-.167 3.5 3.5 0 013.748-2.3.99.99 0 001.116-.833A5 5 0 0117 12c0 .146-.007.29-.02.433a1 1 0 00.5.959A3 3 0 0116 19h-2.5a.253.253 0 00-.117.029c-.094.05-.19.1-.288.14a.248.248 0 00-.117.353 3.981 3.981 0 01.462 1.272.248.248 0 00.246.206H16a5 5 0 003.094-8.927z\"/><path d=\"M0 17a1 1 0 001 1h10.5a2.5 2.5 0 000-5 1 1 0 000 2 .5.5 0 010 1H1a1 1 0 00-1 1zM9.5 19H1a1 1 0 000 2h8.5a.5.5 0 010 1 1 1 0 000 2 2.5 2.5 0 000-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});