define("ember-svg-jar/inlined/road-sign-pedestrian", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-pedestrian</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.769 10.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-8-7.5a2.5 2.5 0 11-2.5 2.5 2.5 2.5 0 012.5-2.5zm4.5 10.559a.751.751 0 01-1.059-.058l-.256-.285a.25.25 0 00-.435.189l.221 2.552a.5.5 0 01-.5.543.224.224 0 00-.224.224v2.526a.75.75 0 01-1.5 0v-2.5a.25.25 0 00-.25-.25h-1a.25.25 0 00-.25.25v2.5a.75.75 0 01-1.5 0V16.7a.2.2 0 00-.2-.2.5.5 0 01-.5-.545L6.269 11a2.5 2.5 0 012.5-2.5 2.624 2.624 0 012.438 1.635l2.12 2.364a.751.751 0 01-.057 1.06zm5.665 6.065a.75.75 0 01-1.04-.208l-.668-1a.25.25 0 00-.458.139v.7a.75.75 0 01-1.5 0v-2a.227.227 0 01.009-.059.256.256 0 000-.123.3.3 0 01-.008-.073v-.507a.252.252 0 00-.138-.224l-1.2-.6a.75.75 0 11.67-1.342l.668.335a.249.249 0 00.31-.073 1.5 1.5 0 012.686.909V17a.494.494 0 01-.013.11.247.247 0 00.035.2l.852 1.278a.749.749 0 01-.204 1.036z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});