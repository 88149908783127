define("ember-svg-jar/inlined/hair-dress-chair-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-chair-1</title><path d=\"M15.9 13.838a.5.5 0 00.6-.49V6.385a1.5 1.5 0 00-1.5-1.5H9a1.5 1.5 0 00-1.5 1.5v6.963a.5.5 0 00.6.49 20.153 20.153 0 013.9-.453 20.153 20.153 0 013.9.453z\"/><path d=\"M16 18.385a1.487 1.487 0 001.174-.577.5.5 0 01.348-.19 3 3 0 002.728-2.983v-1.354a.5.5 0 01.3-.459.75.75 0 00-.3-1.437h-2a.75.75 0 00-.3 1.437.5.5 0 01.3.459v1.354a.963.963 0 01-.35.744.5.5 0 01-.686-.044.954.954 0 00-.472-.28 21.576 21.576 0 00-4.734-.67.025.025 0 00-.015 0 21.577 21.577 0 00-4.735.67.954.954 0 00-.472.28.5.5 0 01-.686.044.963.963 0 01-.35-.744v-1.354a.5.5 0 01.3-.459.75.75 0 00-.3-1.437h-2a.75.75 0 00-.3 1.437.5.5 0 01.3.459v1.354a3 3 0 002.728 2.983.5.5 0 01.348.19A1.487 1.487 0 008 18.385h2a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5H8.5a1 1 0 000 2h7a1 1 0 000-2H14a.5.5 0 01-.5-.5v-2.615a.5.5 0 01.5-.5z\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h2a1 1 0 100-2H2.5a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v19a.5.5 0 01-.5.5H20a1 1 0 100 2h2a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});