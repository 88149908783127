define("ember-svg-jar/inlined/fruit-lemon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-lemon</title><path d=\"M14.8 2.369a10.5 10.5 0 100 19.262c5.55-1.088 9.078-4.682 9.078-9.631S20.347 3.457 14.8 2.369zM10.625 20.5a8.5 8.5 0 118.5-8.5 8.51 8.51 0 01-8.5 8.5z\"/><path d=\"M10.625 5.625a.875.875 0 00-.875.875v1.833a.875.875 0 001.75 0V6.5a.875.875 0 00-.875-.875zM7.355 7.492A.875.875 0 006.117 8.73l1.3 1.3a.969.969 0 001.238 0 .877.877 0 000-1.238zM7.833 12a.875.875 0 00-.875-.875H5.125a.875.875 0 000 1.75h1.833A.875.875 0 007.833 12zM7.413 13.974l-1.3 1.3a.877.877 0 000 1.238 1.039 1.039 0 001.238 0l1.3-1.3a.875.875 0 00-1.238-1.238zM10.625 14.792a.875.875 0 00-.875.875V17.5a.875.875 0 001.75 0v-1.833a.875.875 0 00-.875-.875zM13.837 13.974a.875.875 0 00-1.237 1.238l1.3 1.3a.969.969 0 001.238 0 .877.877 0 000-1.238zM14.292 11.125a.875.875 0 000 1.75h1.833a.875.875 0 000-1.75zM13.837 10.026l1.3-1.3A.875.875 0 0013.9 7.492l-1.3 1.3a.877.877 0 000 1.238.9.9 0 001.237-.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});