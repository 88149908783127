define("ember-svg-jar/inlined/smiley-shock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-shock</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.5 7a2 2 0 11-2 2 2 2 0 012-2zm2 8.5a2.5 2.5 0 015 0V17a2.5 2.5 0 01-5 0zm7-4.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});