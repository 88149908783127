define("ember-svg-jar/inlined/transportation-ticket-car-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-car-transfer</title><path d=\"M23.732 17.979a.791.791 0 00-.1-.154l-.7-.81a.247.247 0 01-.058-.132l-.328-2.645a1.745 1.745 0 00-1.726-1.463h-5.306a1.753 1.753 0 00-1.731 1.495l-.323 2.612a.248.248 0 01-.059.133l-.7.81a.753.753 0 00-.1.154 1.77 1.77 0 00-.184.783v.513a1.745 1.745 0 00.875 1.509.251.251 0 01.125.216v1.025a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v.75a.75.75 0 001.5 0V21a.249.249 0 01.125-.216 1.745 1.745 0 00.875-1.509v-.513a1.757 1.757 0 00-.185-.783zm-8.682-1.734l.217-1.761a.249.249 0 01.247-.209h5.306a.247.247 0 01.242.178l.221 1.792a.248.248 0 01-.248.28H15.3a.248.248 0 01-.187-.084.251.251 0 01-.063-.196zm-.025 3.448a.75.75 0 11.75-.75.75.75 0 01-.75.75zm6.25 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zM15.108 8.909a.248.248 0 01.078.188c0 .071-.367.357-.421.4a.737.737 0 00-.26.481.75.75 0 00.38.742l2.187.812a.744.744 0 00.364.1.8.8 0 00.206-.028.755.755 0 00.45-.358l1.031-1.856a.75.75 0 00-.861-1.09l-.67.191a.25.25 0 01-.271-.094 6.949 6.949 0 00-4.47-2.775 1 1 0 10-.337 1.978 5.005 5.005 0 012.594 1.309zM10.834 17.457A4.973 4.973 0 017.7 15.563a.25.25 0 01.111-.385l.2-.076a.751.751 0 00.045-1.386l-1.933-.874a.749.749 0 00-.993.375l-.869 1.933a.749.749 0 00.683 1.059.761.761 0 00.265-.048l.2-.075a.249.249 0 01.3.1A6.977 6.977 0 0010.5 19.43a1.183 1.183 0 00.166.013 1 1 0 00.165-1.986z\"/><path d=\"M8.017 10.825L9.2 12.407a.75.75 0 001.2-.9l-.795-1.06a.252.252 0 01.013-.317 1.724 1.724 0 00.446-1.155v-6a1.752 1.752 0 00-1.75-1.75h-6a1.752 1.752 0 00-1.75 1.75v6a1.728 1.728 0 00.445 1.155.25.25 0 01.013.317L.233 11.5a.75.75 0 001.2.9l1.185-1.579a.249.249 0 01.2-.1h5a.249.249 0 01.199.104zm-5.949-4.85v-3a.253.253 0 01.25-.25h6a.253.253 0 01.25.25v3a.25.25 0 01-.25.25h-6a.249.249 0 01-.25-.25zm1.25 3a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.25-.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});