define("ember-svg-jar/inlined/cat-lost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-lost</title><path d=\"M5.151 4.57a.25.25 0 00.471.073A6.038 6.038 0 018.07 2.067a.251.251 0 00.01-.43L5.689.143a.721.721 0 00-.817-.035.722.722 0 00-.29.764zM16.378 4.643a.25.25 0 00.471-.073l.569-3.7a.722.722 0 00-.29-.764.723.723 0 00-.817.035L13.92 1.637a.249.249 0 00.01.43 6.038 6.038 0 012.448 2.576zM6 7.3v5.25a.25.25 0 00.25.25h9.5a.25.25 0 00.25-.25V7.3a5 5 0 00-10 0zm2.5-1a1 1 0 11-1 1 1 1 0 011-1zm5 2a1 1 0 111-1 1 1 0 01-1 1zm-.788 1.183a.5.5 0 01-.108.545l-1.25 1.25a.5.5 0 01-.708 0L9.4 10.03a.5.5 0 01.354-.854h2.5a.5.5 0 01.458.309zM23 14h-4a1 1 0 000 2h1v7a1 1 0 002 0v-7h1a1 1 0 000-2zM5 22H2.5a.5.5 0 01-.5-.5V15a1 1 0 00-2 0v6.5A2.5 2.5 0 002.5 24H5a1 1 0 000-2z\"/><path d=\"M14.829 16h2.146a1 1 0 000-2h-2.146a2.857 2.857 0 00-2.847 2.671A3 3 0 006 17v4a3 3 0 006 0v-3.768a2.838 2.838 0 001.553 2.175l1.949.975A.854.854 0 0115.121 22h-2.146a1 1 0 000 2h2.146a2.854 2.854 0 001.279-5.407l-1.949-.975A.854.854 0 0114.829 16zM10 21a1 1 0 01-2 0v-4a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});