define("ember-svg-jar/inlined/chef-gear-hat-moustache", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-hat-moustache</title><path d=\"M20.288 3.773a3.413 3.413 0 00-2.053-2.057 3.527 3.527 0 00-3.073.314.239.239 0 01-.327-.065 3.476 3.476 0 00-5.67 0 .239.239 0 01-.327.065 3.489 3.489 0 00-5.281 2.334A3.476 3.476 0 005 7.858V11.5a.25.25 0 00.25.25h13.5a.25.25 0 00.25-.25V7.858a3.5 3.5 0 001.288-4.085zM18.75 13.25H5.25a.25.25 0 00-.25.25v2a1 1 0 001 1h12a1 1 0 001-1v-2a.25.25 0 00-.25-.25zM18.111 20.674a2.513 2.513 0 01-.72.107c-2.378 0-1.5-2-3.5-2a1.992 1.992 0 00-1.709.978.245.245 0 01-.427 0 1.989 1.989 0 00-1.708-.978c-2 0-1.123 2-3.5 2a2.527 2.527 0 01-.769-.122.2.2 0 00-.158.1 1.842 1.842 0 00-.073.521c0 3.054 4.949 2.681 6.209.542a.243.243 0 01.425 0c1.26 2.139 6.21 2.512 6.21-.542a1.843 1.843 0 00-.077-.531c-.014-.043-.149-.092-.203-.075z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});