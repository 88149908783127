define("ember-svg-jar/inlined/drawer-skull", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-skull</title><path d=\"M4.51 12.5a1 1 0 001-1L5.5 2.25a.251.251 0 01.073-.177.255.255 0 01.18-.073h12.494a.25.25 0 01.25.25l.003 9.25a1 1 0 001 1 1 1 0 001-1V2a2 2 0 00-2-2h-13a2 2 0 00-2 2l.006 9.5a1 1 0 001.004 1z\"/><path d=\"M12 3.5a4 4 0 00-2.995 6.65L9 12a.5.5 0 00.5.5H11a.25.25 0 00.25-.25V11a.75.75 0 011.5 0v1.25a.25.25 0 00.25.25h1.5a.5.5 0 00.5-.5v-1.669-.185A4 4 0 0012 3.5zM10.5 8a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});