define("ember-svg-jar/inlined/travel-luggage-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-luggage-1</title><path d=\"M8.393 17.891A4.5 4.5 0 005.5 9.944H2.584a.25.25 0 01-.241-.315 9.982 9.982 0 0114.546-6.153.25.25 0 01-.122.468H14.25a2.75 2.75 0 100 5.5 2.565 2.565 0 011.83.788.49.49 0 00.171.1l2.249-.022a4.007 4.007 0 013.884 3.046.249.249 0 00.165.178 4.154 4.154 0 01.957.458.251.251 0 00.383-.177A11.8 11.8 0 0024 12.194 12 12 0 108.941 23.79a.5.5 0 00.62-.565 2.924 2.924 0 01-.009-.886.5.5 0 00-.353-.55 10.147 10.147 0 01-1.7-.677v-1.3a2.492 2.492 0 01.894-1.921z\"/><path d=\"M24 17.557a2.754 2.754 0 00-2.75-2.751.25.25 0 01-.25-.25 2.753 2.753 0 00-2.75-2.75h-1.5a2.753 2.753 0 00-2.75 2.75.25.25 0 01-.25.25A2.754 2.754 0 0011 17.557v3.5a2.752 2.752 0 002.75 2.749h7.5A2.752 2.752 0 0024 21.057zm-8.5-3a1.252 1.252 0 011.25-1.25h1.5a1.252 1.252 0 011.25 1.25.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.251z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});