define("ember-svg-jar/inlined/check-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-2-alternate</title><path d=\"M18.741 6.243a1.25 1.25 0 00-1.748.265l-6.228 8.453-3.985-3.188a1.25 1.25 0 10-1.561 1.952l5 4a1.235 1.235 0 00.781.269 1.254 1.254 0 001.006-.5l7-9.5a1.249 1.249 0 00-.265-1.751z\"/><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 21.75a.25.25 0 01-.25.25H2.25a.249.249 0 01-.25-.25V2.249A.249.249 0 012.25 2h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});