define("ember-svg-jar/inlined/pollution-skull-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-skull-1</title><path d=\"M12 5a6.508 6.508 0 00-6.5 6.5V13a2.5 2.5 0 002 2.449V16.5a2.5 2.5 0 001.875 2.421.5.5 0 00.625-.485V16.5a.5.5 0 011 0v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 011 0v1.936a.5.5 0 00.625.485A2.5 2.5 0 0016.5 16.5v-1.051a2.5 2.5 0 002-2.449v-1.5A6.508 6.508 0 0012 5zm-2.5 7.5a1 1 0 111-1 1 1 0 01-1 1zm5 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});