define("ember-svg-jar/inlined/cash-molecule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-molecule</title><path d=\"M21.736 9.332A2.4 2.4 0 0020.64 4.8a2.358 2.358 0 00-1.324.411.252.252 0 01-.262.011l-4.529-2.517a.25.25 0 01-.128-.241V2.4a2.4 2.4 0 00-4.8 0v.064a.251.251 0 01-.127.241L4.946 5.222a.252.252 0 01-.262-.011A2.358 2.358 0 003.36 4.8a2.4 2.4 0 00-1.1 4.532.25.25 0 01.136.223v4.891a.247.247 0 01-.136.222 2.4 2.4 0 001.1 4.532 2.358 2.358 0 001.324-.41.25.25 0 01.262-.011l4.53 2.521a.25.25 0 01.127.241v.064a2.4 2.4 0 004.8 0v-.064a.249.249 0 01.128-.241l4.529-2.517a.25.25 0 01.262.011 2.358 2.358 0 001.324.41 2.4 2.4 0 001.1-4.532.249.249 0 01-.136-.222V9.555a.25.25 0 01.126-.223zm-2.056 5.114a.247.247 0 01-.136.222 2.4 2.4 0 00-1.3 2.132v.063a.248.248 0 01-.128.241l-4.53 2.517a.25.25 0 01-.261-.011 2.343 2.343 0 00-2.648 0 .25.25 0 01-.261.011L5.885 17.1a.248.248 0 01-.128-.241v-.063a2.4 2.4 0 00-1.3-2.132.249.249 0 01-.136-.222V9.555a.25.25 0 01.136-.223A2.4 2.4 0 005.76 7.2v-.062a.25.25 0 01.125-.238l4.53-2.516a.248.248 0 01.261.011 2.348 2.348 0 002.648 0 .248.248 0 01.261-.011l4.53 2.516a.25.25 0 01.128.242v.062a2.4 2.4 0 001.3 2.132.25.25 0 01.136.223z\"/><path d=\"M13.731 11.352l-2.33-.931a.32.32 0 01-.2-.294.316.316 0 01.315-.315h2.3a1.2 1.2 0 000-2.4h-.282a.212.212 0 01-.214-.212 1.2 1.2 0 00-2.4 0v.091a.251.251 0 01-.178.239 2.711 2.711 0 00-.233 5.118l2.33.932a.315.315 0 01-.116.608h-2.3a1.2 1.2 0 100 2.4h.282a.213.213 0 01.212.212 1.2 1.2 0 002.4 0v-.09a.25.25 0 01.179-.239 2.712 2.712 0 00.232-5.119z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});