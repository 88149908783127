define("ember-svg-jar/inlined/plane-trip-food-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-food-service</title><path d=\"M7.8 2.458l1.537.636a.249.249 0 00.195 0l3.225-1.4a.25.25 0 000-.46L10.016.1A.935.935 0 009.3.1L7.8.723a.939.939 0 000 1.735z\"/><path d=\"M5.865 8.7a.938.938 0 00.664.277.919.919 0 00.348-.077s3.358-1.351 3.634-1.453.392-.047.336.106l-.269.745a.5.5 0 00.471.67h2.617a.5.5 0 00.449-.281L15.7 5.452a.26.26 0 01.138-.125l3.1-1.155A1.933 1.933 0 1017.451.6L7.978 4.724a.247.247 0 01-.211-.006l-1.655-.827a.937.937 0 00-.79-.024L3.6 4.606a.939.939 0 00-.3 1.528zM22.5 10.471h-21a1 1 0 000 2h21a1 1 0 000-2zM12.5 13.521a1 1 0 00-1 1v2.4a1.982 1.982 0 01-.738 1.54c-.082.067-.262.223-.262.012v-4a1 1 0 00-2 0v3.994c0 .188-.186.059-.27-.011a1.979 1.979 0 01-.73-1.533v-2.4a1 1 0 00-2 0v2.4a4 4 0 002.824 3.808.244.244 0 01.176.232v2.01a1 1 0 102 0v-2.01a.244.244 0 01.176-.232 4 4 0 002.824-3.81v-2.4a1 1 0 00-1-1zM15.516 13.52a.749.749 0 00-1.016.7v8.75a1 1 0 102 0v-1a.248.248 0 01.248-.248h.991a1.274 1.274 0 00.913-.386 1.182 1.182 0 00.34-.868c-.18-5.638-3.343-6.898-3.476-6.948z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});