define("ember-svg-jar/inlined/modern-tv-8k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-8k</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M18.53 3.97a.749.749 0 00-1.06 0l-2.293 2.293a.25.25 0 01-.427-.177V4.5a.75.75 0 00-1.5 0v8a.75.75 0 001.5 0v-1.586a.25.25 0 01.427-.177l2.293 2.293a.75.75 0 001.06-1.06l-3.293-3.293a.252.252 0 010-.354L18.53 5.03a.749.749 0 000-1.06zM10.509 7.447a.25.25 0 01-.062-.322A2.228 2.228 0 0010.75 6a2.25 2.25 0 00-4.5 0 2.228 2.228 0 00.3 1.125.25.25 0 01-.062.322 3.25 3.25 0 104.018 0zM7.75 6a.75.75 0 11.75.75.751.751 0 01-.75-.75zm.75 5.75A1.75 1.75 0 1110.25 10a1.752 1.752 0 01-1.75 1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});