define("ember-svg-jar/inlined/laptop-lock.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-lock</title><path d=\"M23 17.751a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5V18a.25.25 0 01-.25.25h-3.5A.25.25 0 0110 18v-.25a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5v2a1.5 1.5 0 001.5 1.5h19a1.5 1.5 0 001.5-1.5zM12 6a2.753 2.753 0 00-2.75 2.75v.572a.251.251 0 01-.107.205 1.49 1.49 0 00-.643 1.224v3a1.5 1.5 0 001.5 1.5h4a1.5 1.5 0 001.5-1.5v-3a1.49 1.49 0 00-.643-1.223.251.251 0 01-.107-.205v-.572A2.752 2.752 0 0012 6zm1 6.269a1 1 0 11-1-1 1 1 0 011 1.001zm-2.25-3.518a1.25 1.25 0 012.5 0A.25.25 0 0113 9h-2a.25.25 0 01-.25-.249z\"/><path d=\"M3.5 15.751a1 1 0 001-1v-9a1 1 0 011-1h13a1 1 0 011 1v9a1 1 0 002 0v-9a3 3 0 00-3-3h-13a3 3 0 00-3 3v9a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});