define("ember-svg-jar/inlined/appliances-fridge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>appliances-fridge</title><path d=\"M3.5 10a.5.5 0 00-.5.5v11A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5v-11a.5.5 0 00-.5-.5zm3.75 7.5a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zM20.5 8.5A.5.5 0 0021 8V2.5A2.5 2.5 0 0018.5 0h-13A2.5 2.5 0 003 2.5V8a.5.5 0 00.5.5zM5.75 3a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});