define("ember-svg-jar/inlined/volume-control-full-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-full-1</title><path d=\"M11.443 2.532a9.731 9.731 0 00-7.924 4.85.249.249 0 01-.221.133H3a3 3 0 00-3 3v2a3 3 0 003 3h.3a.249.249 0 01.221.133 9.728 9.728 0 007.924 4.85h.06a1 1 0 001-1V3.53a1 1 0 00-1.062-.998zM17.59 4.968a1 1 0 00-1.414 1.414 7.944 7.944 0 010 11.239 1 1 0 001.414 1.414 9.943 9.943 0 000-14.067z\"/><path d=\"M20.041 2.436a1 1 0 10-1.414 1.414 11.541 11.541 0 010 16.3 1 1 0 001.414 1.414 13.542 13.542 0 000-19.128zM15.141 7.5a1 1 0 10-1.416 1.413 4.379 4.379 0 010 6.179A1 1 0 1015.14 16.5a6.382 6.382 0 00.001-9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});