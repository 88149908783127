define("ember-svg-jar/inlined/single-neutral-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zm-9.739 11.8a4.981 4.981 0 01-1.13-3.511 2.881 2.881 0 012.969-3.056 2.881 2.881 0 012.969 3.053 5 5 0 01-1.149 3.534.977.977 0 00.027.7c2.061.736 3.2 1.274 3.652 2.151a5.282 5.282 0 01.492 1.477.5.5 0 01-.106.41.5.5 0 01-.385.18h-11a.5.5 0 01-.385-.18.5.5 0 01-.106-.41 5.282 5.282 0 01.492-1.477c.453-.877 1.591-1.415 3.667-2.159a1 1 0 00-.007-.715zM5.25 2H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 010-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});