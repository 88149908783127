define("ember-svg-jar/inlined/common-file-module", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-module</title><path d=\"M8.521.792A1 1 0 007.816.5H3a1 1 0 00-1 1v8.51A1 1 0 003 11h6.51a1 1 0 00.995-.995V3.283a.984.984 0 00-.281-.693zM8.521 13.292A1 1 0 007.816 13H3a1 1 0 00-1 1v8.51a1 1 0 001 1h6.51a1 1 0 00.995-1v-6.727a.984.984 0 00-.281-.693zM21.719 2.59l-1.7-1.8a1 1 0 00-.703-.29H14.5a1 1 0 00-.995 1v8.51a1 1 0 00.995.99h6.51a1 1 0 001-.995V3.283a.984.984 0 00-.291-.693zM20.021 13.292a1 1 0 00-.705-.292H14.5a1 1 0 00-1 1v8.51a1 1 0 00.995 1h6.51a1 1 0 001-1v-6.727a.984.984 0 00-.281-.693z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});