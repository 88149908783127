define("ember-svg-jar/inlined/professions-woman-chef-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-chef-1</title><path d=\"M23.381 20.432a2.435 2.435 0 00-.956-1.957A11.934 11.934 0 0020 17.088a.247.247 0 00-.316.108l-3.512 6.438a.248.248 0 00.218.366h6.5a.494.494 0 00.494-.5zM.619 20.432v3.073a.494.494 0 00.494.5h7.251a.247.247 0 00.188-.086l3.034-3.524a.247.247 0 000-.326l-3.494-3.93a.492.492 0 00-.462-.158 14.376 14.376 0 00-6.055 2.494 2.434 2.434 0 00-.956 1.957zM17.087 16.277l-6.3 7.314a.248.248 0 00.188.409h3.158a.247.247 0 00.217-.129l3.883-7.118a.247.247 0 00-.149-.356 19.79 19.79 0 00-.751-.2.246.246 0 00-.246.08z\"/><circle cx=\"13.979\" cy=\"9.402\" r=\".99\"/><circle cx=\"10.021\" cy=\"9.402\" r=\".99\"/><path d=\"M13.066 11.264h-1.98a.632.632 0 00-.3 1.189 2.324 2.324 0 00.43.178 2.967 2.967 0 00.858.125h.006a2.961 2.961 0 00.855-.124 2.366 2.366 0 00.431-.179.633.633 0 00-.3-1.189z\"/><path d=\"M2.942 13.631a.493.493 0 00-.325.333.5.5 0 00.1.456A3.761 3.761 0 005.6 15.527a4.054 4.054 0 002.49-.71.246.246 0 01.28-.014 6.885 6.885 0 007.214.023.247.247 0 01.285.019 4.182 4.182 0 002.524.742 3.763 3.763 0 002.891-1.108.5.5 0 00.1-.456.5.5 0 00-.325-.333c-2.754-.884-2.13-4.592-2.13-10.721A2.973 2.973 0 0015.959 0H8.041a2.973 2.973 0 00-2.969 2.969c0 6.409.542 9.804-2.13 10.662zm8.128-9.14a.249.249 0 01.188.24v.73a.248.248 0 01-.239.247 17.851 17.851 0 00-3.66.454.251.251 0 01-.212-.045.248.248 0 01-.1-.2V3.8a.245.245 0 01.1-.195.248.248 0 01.212-.046zm5.571-.928a.248.248 0 01.212.046.245.245 0 01.095.195v2.118a.247.247 0 01-.307.24 17.851 17.851 0 00-3.66-.454.248.248 0 01-.239-.247v-.73a.249.249 0 01.188-.24zM7.052 8.907v-.928a.247.247 0 01.173-.236A16.208 16.208 0 0112 7.175a16.268 16.268 0 014.775.567.247.247 0 01.173.236v.929a4.948 4.948 0 11-9.9 0zm8.907-6.928a.111.111 0 01.027.218l-3.926.982a.244.244 0 01-.12 0L8.014 2.2a.111.111 0 01.027-.218z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});