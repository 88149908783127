define("ember-svg-jar/inlined/arrow-thick-right-bottom-corner-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-bottom-corner-2</title><path d=\"M22.762 8.107a2.475 2.475 0 00-2.533-2.516 2.428 2.428 0 00-2.464 2.517v5.349a.251.251 0 01-.426.178L5.284 1.773c-.56-.56-1.882-.955-3.192.358a2.293 2.293 0 00-.333 3.2l11.881 11.99a.25.25 0 01-.177.426H7.924a2.5 2.5 0 100 5h13.838a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});