define("ember-svg-jar/inlined/road-sign-slide-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-slide-left-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-11.53 10.116a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M10.47 6.47l-2 2A.75.75 0 009 9.75h.75A.25.25 0 0110 10v1a1 1 0 00.293.707l2.634 2.634a.249.249 0 01.073.177V17a1 1 0 002 0v-2.793a1.494 1.494 0 00-.439-1.061l-2.488-2.487a.249.249 0 01-.073-.177V10a.25.25 0 01.25-.25H13a.75.75 0 00.53-1.28l-2-2a.749.749 0 00-1.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});