define("ember-svg-jar/inlined/social-profile-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-profile-click</title><path d=\"M16.55 12a1.5 1.5 0 00-1.5 1.5v6.464a.25.25 0 01-.411.192l-.878-.736a1.25 1.25 0 10-1.922 1.6l2.329 2.8a.5.5 0 00.385.18h8a.5.5 0 00.5-.5v-2.168A3.334 3.334 0 0019.716 18H18.55a.5.5 0 01-.5-.5v-4a1.5 1.5 0 00-1.5-1.5zM9.845 9.031a2.033 2.033 0 10-2.033 2.033 2.033 2.033 0 002.033-2.033zM4.654 14.1a.218.218 0 00.208.286h5.858a.249.249 0 00.236-.33 3.318 3.318 0 00-6.3.044z\"/><path d=\"M9.5 19.5a1 1 0 00-1-1H3a1 1 0 01-1-1V5.25A.25.25 0 012.25 5h19.5a.25.25 0 01.25.25V16a1 1 0 001 1 1 1 0 001-1V3a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h5.5a1 1 0 001-1zM9.654 2a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0A.969.969 0 018 2.5a.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 2.5a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 1.5a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});