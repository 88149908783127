define("ember-svg-jar/inlined/arrow-thick-right-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right-4</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM5.676 13.5a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h7.9a.25.25 0 00.177-.427l-2.724-2.724a.5.5 0 01.353-.853h3.073a.5.5 0 01.37.164l4.545 5a.5.5 0 010 .672l-4.545 5a.5.5 0 01-.37.163h-3.071a.5.5 0 01-.353-.854l2.718-2.715a.25.25 0 00-.176-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});