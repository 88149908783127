define("ember-svg-jar/inlined/tea-bottle-herbal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-bottle-herbal</title><rect x=\"3.75\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M10.6 20.465a.5.5 0 00.146-.354V17.75a2.242 2.242 0 011.64-2.15.5.5 0 00.364-.481v-.459c0-2.786-.862-5.14-3.387-6.263A.989.989 0 018.75 7.5V5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2.5a.989.989 0 01-.613.894C1.612 9.517.75 11.871.75 14.657V22a2 2 0 002 2h5.512a.5.5 0 00.5-.55 1.9 1.9 0 01-.01-.2 2.235 2.235 0 01.659-1.591zM6.75 17a2.073 2.073 0 01-2.073-2.073c0-.81 1.037-2.2 1.644-2.949a.553.553 0 01.858 0c.608.746 1.644 2.139 1.644 2.949A2.072 2.072 0 016.75 17zM22.5 14.5h-1.086a.25.25 0 01-.177-.427l.293-.293a.75.75 0 00-1.06-1.06l-.293.293a.25.25 0 01-.427-.177V11.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V14.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V17.75a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354L10.47 22.72a.75.75 0 101.06 1.06l1.634-1.634a.5.5 0 01.354-.146H16.5a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146H19.5a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.354-.146H22.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});