define("ember-svg-jar/inlined/ray-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ray-1</title><path d=\"M23.712 22.3c-.614-.645-.6-.668-.325-1.27a6.016 6.016 0 00.488-1.385c.254-1.227-.183-1.8-.594-2.067-1.186-.765-2.9.4-5.826 2.527-.4.291-.792.577-1.16.834a6.057 6.057 0 01.6-.96c1.067-1.377 1.316-2.276 1.028-3.08 1.876-2.3 1.51-4.9 1.179-7.222-.325-2.276-.315-3.27 1.1-4.685a29.1 29.1 0 012.676-2.31A1.486 1.486 0 0023.372 1a1.466 1.466 0 00-1.45-.984A30.724 30.724 0 0010.99 2.15a10.429 10.429 0 01-1.368.461 2.226 2.226 0 01-1.833-.387L6.027.463a1 1 0 00-1.414 1.414A2.644 2.644 0 015.4 3.6a1.8 1.8 0 01-.44 1.359 2.3 2.3 0 01-3.083-.346A1 1 0 00.463 6.027l1.76 1.762a2.228 2.228 0 01.388 1.831 10.579 10.579 0 01-.462 1.371A30.756 30.756 0 00.013 21.922a1.489 1.489 0 002.668.958 28.966 28.966 0 012.309-2.672c1.416-1.416 2.41-1.426 4.689-1.1a10.808 10.808 0 005.516-.184c-.452.64-1.956 2.963-.851 4.121.976 1.023 2.567-.094 3.089-.461.38-.267 3.5-2.533 4.392-3a5.825 5.825 0 01-.253.605c-.471 1.018-.745 1.983.692 3.49a1 1 0 001.448-1.379zM7.993 11.7a11.548 11.548 0 00-1.364 3.191.75.75 0 11-1.455-.364 12.9 12.9 0 011.57-3.658.75.75 0 011.249.831zm6.9-5.068A11.5 11.5 0 0011.7 7.993a.75.75 0 01-.831-1.249 12.838 12.838 0 013.657-1.571.75.75 0 01.365 1.455z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});