define("ember-svg-jar/inlined/famous-character-batman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-batman</title><path d=\"M22.533 18.409a12.259 12.259 0 00-2.9-1.6.262.262 0 00-.187 0L13.2 19.085a3.5 3.5 0 01-2.4 0s-5.476-2.016-6.2-2.3a.228.228 0 00-.172 0 12.271 12.271 0 00-2.961 1.625A2.468 2.468 0 00.5 20.386v3.106a.5.5 0 00.5.5h4.779a.245.245 0 00.241-.225C6.161 22.542 7.25 21.5 9.125 21a.155.155 0 01.13.022.093.093 0 01.038.1l-.2.649a1.243 1.243 0 00.407 1.257 1.06 1.06 0 00.882.141.457.457 0 00.314-.42V21.3c0-.087.122-.136.208-.084l.835.506h.522l.835-.506c.086-.052.208 0 .208.084v1.45a.435.435 0 00.338.395 1.292 1.292 0 00.858-.115 1.238 1.238 0 00.407-1.257l-.2-.649a.092.092 0 01.038-.1.153.153 0 01.13-.024c1.875.5 2.964 1.544 3.105 2.769a.245.245 0 00.241.225H23a.5.5 0 00.5-.5v-3.108a2.468 2.468 0 00-.967-1.977z\"/><path d=\"M12 15.492a22.815 22.815 0 00-5.223.566.251.251 0 00-.03.48l4.395 1.608a2.494 2.494 0 001.716 0l4.395-1.608a.251.251 0 00-.03-.48A22.808 22.808 0 0012 15.492zM12 14.992A6.99 6.99 0 0018.99 8L19 1.474a1.25 1.25 0 00-2-1l-1.005.756a7.129 7.129 0 00-7.986 0L7 .472a1.25 1.25 0 00-2 1v6.52a7.007 7.007 0 007 7zm1.22-8.6a2.428 2.428 0 011.092-.861 1.785 1.785 0 012.421.619c.322.779-.238 1.724-1.273 2.153a2.572 2.572 0 01-.978.2 1.483 1.483 0 01-1.444-.821 1.367 1.367 0 01.182-1.289zm-.67 4.483l1.631-.466a.25.25 0 01.319.24v1.484a.261.261 0 01-.128.225 4.719 4.719 0 01-2.217.631 4.87 4.87 0 01-2.52-.6.26.26 0 01-.134-.228V10.65a.25.25 0 01.319-.24l1.635.466a2 2 0 001.095 0zM7.267 6.153a1.788 1.788 0 012.421-.621 2.428 2.428 0 011.092.861 1.365 1.365 0 01.182 1.292 1.483 1.483 0 01-1.444.821 2.576 2.576 0 01-.978-.2C7.505 7.875 6.945 6.93 7.267 6.153z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});