define("ember-svg-jar/inlined/kindle-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kindle-hold</title><path d=\"M12.5 12.251h-7a.75.75 0 100 1.5h7a.75.75 0 000-1.5zM12.5 8.751h-7a.75.75 0 000 1.5h7a.75.75 0 000-1.5zM5.5 5.251a.75.75 0 000 1.5H11a.75.75 0 000-1.5z\"/><path d=\"M23.911 22.891l-2.131-3.968a.244.244 0 01-.03-.118v-3.814c0-1.821-1.323-2.815-2.856-3.966a83.813 83.813 0 01-.8-.607.251.251 0 01-.1-.2V1.5A1.5 1.5 0 0016.5 0h-15A1.5 1.5 0 000 1.5V20a1.5 1.5 0 001.5 1.5h13.63a.25.25 0 01.215.122 15.242 15.242 0 001.594 2.126.75.75 0 001.121-1A8.389 8.389 0 0116.25 20v-1a.75.75 0 00-.178-.485l-1.753-2.071a.731.731 0 011.075-.99l2.576 2.576a.75.75 0 101.06-1.061l-.957-.957a.251.251 0 01-.073-.176v-3.105a.25.25 0 01.4-.2c1.246.951 1.848 1.528 1.848 2.458v4.065a.745.745 0 00.089.355l2.25 4.189a.757.757 0 001.015.306.749.749 0 00.309-1.013zm-10.4-8.68a2.249 2.249 0 00-.536 2.909.25.25 0 01-.214.381H3a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v10.65a.251.251 0 01-.338.234 2.23 2.23 0 00-2.154.327z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});