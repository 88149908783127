define("ember-svg-jar/inlined/hockey-puck-stick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hockey-puck-stick</title><path d=\"M6.849 12.2A2.653 2.653 0 104.2 14.849 2.656 2.656 0 006.849 12.2zM6.728 18.941a.5.5 0 00-.432-.247h-.485c-1.389 0-2.115 1.334-2.115 2.653S4.422 24 5.811 24h3.435a.25.25 0 00.216-.376zM16.679 7.581a.25.25 0 00-.46.028l-1.2 3.421a1 1 0 00.044.769l2.29 4.7a.251.251 0 00.463-.031L19 12.884a1 1 0 00-.054-.758zM14.444 13.953a.25.25 0 00-.461.027l-1.537 4.379a.5.5 0 01-.472.335H8.756a.25.25 0 00-.216.376l2.734 4.682a.5.5 0 00.432.248h2.178a2.341 2.341 0 002.022-1.732l.917-2.785a1 1 0 00-.051-.751zM20.95.079a2.185 2.185 0 00-2.625 1.531l-1.077 3.068a1 1 0 00.048.777l2.225 4.469a.25.25 0 00.461-.034l2.387-7.245A2.051 2.051 0 0020.95.079z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});