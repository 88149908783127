define("ember-svg-jar/inlined/face-id-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-3</title><path d=\"M13.041 14.906a1.863 1.863 0 01-2.082 0 .751.751 0 00-.918 1.188 3.253 3.253 0 003.918 0 .751.751 0 00-.918-1.188z\"/><circle cx=\"14\" cy=\"12.5\" r=\"1\"/><circle cx=\"10\" cy=\"12.5\" r=\"1\"/><path d=\"M6.5 22H2.25a.25.25 0 01-.25-.25V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 000-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.25 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2zM20.947 15.606A4.364 4.364 0 0119 12.883V10.5a7 7 0 00-14 0v2.383a4.364 4.364 0 01-1.947 2.723 1 1 0 00.894 1.794 5.108 5.108 0 001.717-1.421 7 7 0 0012.672 0 5.108 5.108 0 001.717 1.421 1 1 0 10.894-1.789zM12 18a5.006 5.006 0 01-5-5v-2a6.486 6.486 0 005-2.347A6.486 6.486 0 0017 11v2a5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});