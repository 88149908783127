define("ember-svg-jar/inlined/navigation-arrows-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-left</title><path d=\"M10.913 21.706v-1.4a.5.5 0 00-.13-.336L4.45 13.006a1.492 1.492 0 010-2.018l6.333-6.965a.5.5 0 00.13-.337v-1.4a1 1 0 00-1.741-.666L.359 11.313a.983.983 0 00-.272.679 1.017 1.017 0 00.265.681l8.814 9.692a.968.968 0 001.1.27.992.992 0 00.647-.929z\"/><path d=\"M6.859 11.313a.983.983 0 00-.272.679 1.017 1.017 0 00.265.681l8.814 9.692a.968.968 0 001.1.27.992.992 0 00.649-.929v-1.4a.5.5 0 00-.13-.336l-6.335-6.964a1.492 1.492 0 010-2.018l6.333-6.965a.5.5 0 00.13-.337v-1.4a1 1 0 00-1.741-.666z\"/><path d=\"M13.348 11.322a1 1 0 000 1.346l8.823 9.707a1 1 0 001.741-.673V2.289a1 1 0 00-1.74-.672z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});