define("ember-svg-jar/inlined/arrow-rectangle-left-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-left-2</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM11.656 12.17l5.069 5.487a.5.5 0 01-.367.839h-3.151a.5.5 0 01-.353-.146l-6-6a.5.5 0 010-.706l5.955-6a.5.5 0 01.355-.148h3.2a.5.5 0 01.368.839l-5.07 5.5a.252.252 0 00-.006.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});