define("ember-svg-jar/inlined/gesture-tap-horizontal-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-horizontal-expand</title><path d=\"M2.164 6.21l-2 2.5a.75.75 0 000 .937l2 2.5a.75.75 0 001.336-.468v-5a.751.751 0 00-1.336-.469zM23.836 8.71l-2-2.5a.75.75 0 00-1.335.469v5a.75.75 0 001.335.468l2-2.5a.75.75 0 000-.937zM17.894 6.3a6.727 6.727 0 00-11.78-.008 6.656 6.656 0 00.173 6.84.75.75 0 101.271-.795 5.237 5.237 0 119.023-5.313 5.171 5.171 0 01-.143 5.324.75.75 0 001.27.8 6.653 6.653 0 00.186-6.848z\"/><path d=\"M16.422 15.083l-3.741-1.117a.25.25 0 01-.179-.239V9.159a1 1 0 10-2 0v7.063a.25.25 0 01-.455.143c-1.49-2.138-3.855-1.449-2.762.224l2.915 4.367a.5.5 0 00.416.223h5.434a.5.5 0 00.492-.411c.2-1.1.664-3.683.775-4.166a1.281 1.281 0 00-.895-1.519z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});