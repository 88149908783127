define("ember-svg-jar/inlined/single-woman-actions-flash.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flash</title><path d=\"M12.655 16.672l1.72-2.108a.5.5 0 00-.022-.657 7.46 7.46 0 00-12.645 2.95.5.5 0 00.479.643h9.688a.5.5 0 00.46-.3 2.021 2.021 0 01.32-.528zM22.118 17.354A.822.822 0 0021.492 16h-1.036a.5.5 0 01-.5-.5v-4a.5.5 0 00-.887-.316L13.8 17.646A.821.821 0 0014.42 19h1.036a.5.5 0 01.5.5v4a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.387-.183z\"/><path d=\"M1.566 10.442a1 1 0 001.383-.3 5.093 5.093 0 00.454-.9.25.25 0 01.412-.082 4.727 4.727 0 006.786 0 .251.251 0 01.228-.071.255.255 0 01.184.153 5.093 5.093 0 00.454.9 1 1 0 001.68-1.085 4.569 4.569 0 01-.642-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.297 1.382zM7.208 8.6a2.7 2.7 0 01-2.741-2.352.5.5 0 01.324-.577 5.7 5.7 0 002.245-1.439.25.25 0 01.361 0 5.712 5.712 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});