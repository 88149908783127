define("ember-svg-jar/inlined/medical-nanobot-syringe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-syringe</title><path d=\"M21.056 7.974a.5.5 0 01.707 0l.53.53a1 1 0 001.414-1.414l-1.59-1.59-1.592-1.592a1 1 0 10-1.414 1.414l.53.531a.5.5 0 010 .707l-.6.605a.5.5 0 01-.707 0L15.8 4.634a1 1 0 00-1.414 1.414l.586.586a.5.5 0 010 .708l-8.941 8.94a1.5 1.5 0 000 2.122l3.182 3.182a1.5 1.5 0 002.122 0l8.941-8.941a.5.5 0 01.707 0l.4.4a1 1 0 101.417-1.411l-2.35-2.348a.5.5 0 010-.707zm-1.592 3.359l-.707.707a.5.5 0 01-.707 0l-2.475-2.475a.5.5 0 010-.707l.707-.707a.5.5 0 01.708 0l2.474 2.475a.5.5 0 010 .707z\"/><path d=\"M3.868 11L2.505 8.617a.5.5 0 01.122-.638l.841-.673a.5.5 0 01.666.037l.47.47a.5.5 0 01.146.353V11.5a1 1 0 002 0V8.166a.5.5 0 01.15-.353l.47-.47a.5.5 0 01.666-.037l.842.673a.5.5 0 01.121.638L7.632 11A1 1 0 008 12.368a.988.988 0 00.5.132 1 1 0 00.869-.5l2-3.5a1 1 0 00-.243-1.277L9.542 5.952a.5.5 0 01-.042-.744l.175-.175a1.108 1.108 0 000-1.566L6.534.324a1.109 1.109 0 00-1.567 0L1.825 3.467a1.106 1.106 0 000 1.566L2 5.208a.5.5 0 01.145.382.5.5 0 01-.187.362L.376 7.219A1 1 0 00.132 8.5l2 3.5a1 1 0 00.868.5.988.988 0 00.5-.132A1 1 0 003.868 11zM5.75 2.5A1.75 1.75 0 114 4.25 1.749 1.749 0 015.75 2.5zM6.913 20.349a.748.748 0 00-1.06 0l-1.945 1.944a1 1 0 101.414 1.414l1.946-1.944a.753.753 0 000-1.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});