define("ember-svg-jar/inlined/skiing-slide-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-slide-down</title><circle cx=\"21\" cy=\"5.878\" r=\"2.5\"/><path d=\"M22.783 18.867a1 1 0 00-1.3.561 1.5 1.5 0 01-1.947.845l-17.669-6.98a1 1 0 10-.734 1.859l17.67 6.98a3.5 3.5 0 004.542-1.967 1 1 0 00-.562-1.298zM4.891 12.051l2.9-.967a4.478 4.478 0 002.963-5.294l-.069-.3a.249.249 0 01.346-.284l1.634.739a1 1 0 01.575.746l.47 2.817a4.486 4.486 0 002.123 3.118l3.4 2.038a1.5 1.5 0 001.543-2.573l-3.394-2.037a1.493 1.493 0 01-.708-1.04l-.69-4.134a1.5 1.5 0 00-.861-1.12l-4.241-1.913a2.5 2.5 0 00-3.465 2.847l.417 1.779a1.5 1.5 0 01-.988 1.765l-2.9.968a1.5 1.5 0 00.949 2.845z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});