define("ember-svg-jar/inlined/messages-bubble-download-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-download-alternate</title><path d=\"M8.6 17.352a1 1 0 00-.662.038l-3.647 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.275-1.089A7.04 7.04 0 012 9.928c0-4.358 4.486-7.9 10-7.9s10 3.546 10 7.9a6.1 6.1 0 01-.048.743.249.249 0 00.1.236A8.031 8.031 0 0123.325 12a.25.25 0 00.425-.11A8.063 8.063 0 0024 9.928c0-5.461-5.383-9.9-12-9.9S0 4.467 0 9.928a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.342.09.688.163 1.036.224a.25.25 0 00.287-.3 7.859 7.859 0 01-.205-1.522.249.249 0 00-.2-.234c-.234-.059-.472-.118-.709-.186z\"/><path d=\"M17.5 23.976a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.45-6.217a.5.5 0 01.45-.283h1a.25.25 0 00.25-.25v-2.75a.75.75 0 111.5 0v2.75a.25.25 0 00.25.25h1a.5.5 0 01.391.813l-2 2.5a.519.519 0 01-.782 0l-2-2.5a.5.5 0 01-.059-.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});