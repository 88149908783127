define("ember-svg-jar/inlined/broken-tab-remove-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>broken-tab-remove-alternate</title><path d=\"M22 17.5a2 2 0 002-2v-7a2 2 0 00-2-2H2a2 2 0 00-2 2v7a2 2 0 002 2zm-19.5-2A.5.5 0 012 15V9a.5.5 0 01.5-.5h11.2a.5.5 0 01.456.705l-1 2.236a1.514 1.514 0 00.015 1.154l.99 2.2a.5.5 0 01-.456.705zm18.53-2.531a.75.75 0 11-1.061 1.061l-.793-.793a.249.249 0 00-.353 0l-.793.793a.75.75 0 01-1.061-1.061l.793-.792a.25.25 0 000-.354l-.793-.793a.75.75 0 011.061-1.061l.793.793a.249.249 0 00.353 0l.793-.793a.75.75 0 011.061 1.061l-.793.793a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});