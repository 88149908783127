define("ember-svg-jar/inlined/landmark-hagia-sophia", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-hagia-sophia</title><path d=\"M7.55 8h8.9a.5.5 0 00.5-.556 4.989 4.989 0 00-3.76-4.294.249.249 0 01-.19-.242V1.75a1 1 0 00-2 0v1.158a.248.248 0 01-.19.242 4.988 4.988 0 00-3.757 4.294A.5.5 0 007.55 8zM7.182 12.105a2.5 2.5 0 011.128.673.251.251 0 00.387-.032 4 4 0 016.606 0 .251.251 0 00.387.032 2.5 2.5 0 011.128-.673.25.25 0 00.182-.241V10a.5.5 0 00-.5-.5h-9a.5.5 0 00-.5.5v1.864a.25.25 0 00.182.241zM1.5 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1z\"/><path d=\"M23.993 7.168l-.418-2.507a1.09 1.09 0 00-2.15 0l-.418 2.507L21 7.25V19a1 1 0 01-2 0v-4a1.5 1.5 0 00-3 0v2.5a.5.5 0 01-1 0v-2a3 3 0 00-6 0v2a.5.5 0 01-1 0V15a1.5 1.5 0 00-3 0v4a1 1 0 01-2 0V7.25l-.007-.082-.418-2.507a1.09 1.09 0 00-2.15 0L.007 7.168 0 7.25V22.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V7.25zM18.5 22h-4a.5.5 0 01-.5-.5c0-.827-.9-1.5-2-1.5s-2 .673-2 1.5a.5.5 0 01-.5.5h-4a.5.5 0 010-1h3.372a.25.25 0 00.237-.171A2.976 2.976 0 0112 19a2.976 2.976 0 012.891 1.829.25.25 0 00.237.171H18.5a.5.5 0 010 1zM22.5 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});