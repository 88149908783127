define("ember-svg-jar/inlined/smart-light-wifi-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-light-wifi-1</title><path d=\"M20.855 7.357a9 9 0 10-14.9 8.28 3.421 3.421 0 002.308.862h7.468a3.426 3.426 0 002.309-.863 8.974 8.974 0 002.815-8.279zM12 13.6a1.25 1.25 0 111.25-1.25A1.25 1.25 0 0112 13.6zm3.5-3.747a.751.751 0 01-1.055.113 3.866 3.866 0 00-4.891 0 .75.75 0 11-.94-1.166 5.354 5.354 0 016.775 0 .749.749 0 01.111 1.052zm2.085-3.013a.749.749 0 01-1.055.112 7.161 7.161 0 00-9.058 0 .75.75 0 01-.942-1.167 8.65 8.65 0 0110.942 0 .75.75 0 01.113 1.055z\"/><rect x=\"8.501\" y=\"17.499\" width=\"7\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M14.222 21H9.781a.5.5 0 00-.373.833 3.466 3.466 0 001.441.952A.226.226 0 0111 23a1 1 0 002 0 .228.228 0 01.153-.215 3.46 3.46 0 001.441-.952.5.5 0 00-.372-.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});