define("ember-svg-jar/inlined/instrument-dholak", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-dholak</title><path d=\"M5 1.5A2.5 2.5 0 007.5 4h9A2.5 2.5 0 0019 1.5 1.5 1.5 0 0017.5 0h-11A1.5 1.5 0 005 1.5zM9.667 19a.5.5 0 00-.462.308l-1.089 2.614A1.5 1.5 0 009.5 24h5a1.5 1.5 0 001.385-2.077L14.8 19.308a.5.5 0 00-.467-.308zM7.585 5.334A.251.251 0 007.349 5H6.5a.5.5 0 00-.5.48c0 .134-.043 1.222.054 2.713a.25.25 0 00.485.068zM17.461 8.261a.25.25 0 00.485-.068c.1-1.491.059-2.579.054-2.713a.5.5 0 00-.5-.48h-.849a.251.251 0 00-.236.334zM15.7 7.778a.25.25 0 00-.468-.007l-2.528 6.5a.75.75 0 01-1.4 0l-2.528-6.5a.249.249 0 00-.468.006l-1.62 4.536a.252.252 0 00-.008.144 10.055 10.055 0 002.08 4.382.5.5 0 00.368.161h5.744a.5.5 0 00.368-.161 10.043 10.043 0 002.082-4.382.245.245 0 00-.007-.144z\"/><path d=\"M11.767 11.331a.25.25 0 00.466 0l2.33-5.99A.251.251 0 0014.33 5H9.67a.251.251 0 00-.233.341z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});