define("ember-svg-jar/inlined/project-blueprint-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>project-blueprint-idea</title><path d=\"M23 12.5a1 1 0 00-1 1V15a.25.25 0 01-.25.25H19.5a.75.75 0 000 1.5h2.25A.25.25 0 0122 17v1.5a.25.25 0 01-.25.25H20A1.251 1.251 0 0018.75 20v1.75a.25.25 0 01-.25.25h-6a.25.25 0 01-.25-.25V20.5a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V16a.25.25 0 01.25-.25h8.25a.25.25 0 01.25.25v1.5a.75.75 0 001.5 0v-2A1.252 1.252 0 0011 14.249H2.25A.25.25 0 012 14v-2a.249.249 0 01.25-.25H5a1.251 1.251 0 001.25-1.25v-2a.75.75 0 10-1.5 0v1.75h-2.5A.25.25 0 012 10V6.751a.25.25 0 01.25-.25H7a1 1 0 000-2H1.478A1.485 1.485 0 000 5.987v16.527A1.485 1.485 0 001.478 24h21.044A1.485 1.485 0 0024 22.514V13.5a1 1 0 00-1-1zm-2.75 7.75H22V22h-1.75z\"/><path d=\"M14.727 10.454v.591a1.182 1.182 0 00.773 1.109V13a1 1 0 102 0v-.845a1.183 1.183 0 00.772-1.109v-.591A3.478 3.478 0 0020.045 7.5 3.651 3.651 0 0016.5 3.954 3.651 3.651 0 0012.955 7.5a3.479 3.479 0 001.772 2.954zM16.5 3.182a1 1 0 001-1V1a1 1 0 00-2 0v1.182a1 1 0 001 1zM20.968 4.446l.832-.836a1 1 0 00-1.412-1.41l-.835.836a1 1 0 001.415 1.414zM20.818 6.909a1 1 0 001 1H23a1 1 0 000-2h-1.182a1 1 0 00-1 1zM12.032 4.446a1 1 0 001.415-1.414l-.836-.832A1 1 0 0011.2 3.61zM10 7.909h1.182a1 1 0 000-2H10a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});