define("ember-svg-jar/inlined/file-text-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-text</title><defs><path d=\"M56 67.232C56 68.76 54.865 70 53.464 70H2.539C1.136 70 0 68.76 0 67.232V2.766C0 1.237 1.136 0 2.539 0H42.79L56 14.64v52.592z\" id=\"a\"/><mask id=\"b\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"56\" height=\"70\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use stroke=\"#646B79\" mask=\"url(#b)\" stroke-width=\"2\" fill=\"#FFF\" xlink:href=\"#a\"/><path d=\"M42 0v12.375c0 .032 0 .064.002.095.053 1.67 1.463 2.981 3.15 2.928H56L42 0z\" fill=\"#D5D9DD\"/><g stroke=\"#646B79\" stroke-width=\"2\" stroke-linecap=\"round\"><path d=\"M13.3 22h16.8\"/><path d=\"M13.216 30H42.81M13.216 38H42.81M13.216 46H42.81\" stroke-linejoin=\"round\"/></g></g>",
    "attrs": {
      "width": "56",
      "height": "70",
      "viewBox": "0 0 56 70",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});