define("ember-svg-jar/inlined/gaming-trophy-10", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-trophy-10</title><path d=\"M16.5 20.053c-1.423-.141-2.3-.649-2.47-2.694a.5.5 0 00-.565-.455 11.2 11.2 0 01-2.927 0 .5.5 0 00-.565.455C9.8 19.407 8.922 19.912 7.5 20.053A1.979 1.979 0 007.7 24h8.6a1.979 1.979 0 00.194-3.947zM14.754 4.25A1.251 1.251 0 0013.5 5.5v2a1.25 1.25 0 002.5 0v-2a1.252 1.252 0 00-1.246-1.25z\"/><path d=\"M22 5.5h-.246a.5.5 0 01-.5-.565 29.845 29.845 0 00.246-3.413A1.5 1.5 0 0020 0H4a1.5 1.5 0 00-1.5 1.523 29.827 29.827 0 00.238 3.412.5.5 0 01-.5.565H2a2 2 0 00-2 2V10a5.019 5.019 0 003.266 4.691 1 1 0 10.693-1.877A3.01 3.01 0 012 10V8a.5.5 0 01.5-.5h.343a.5.5 0 01.482.366C4.688 12.786 7.7 15.5 12 15.5s7.311-2.714 8.675-7.634a.5.5 0 01.482-.366h.343a.5.5 0 01.5.5v2a3.01 3.01 0 01-1.959 2.814 1 1 0 10.693 1.877A5.019 5.019 0 0024 10V7.5a2 2 0 00-2-2zm-11.246 4.75h-3a.75.75 0 110-1.5h.257a.5.5 0 00.5-.5V5.414a.25.25 0 00-.427-.177l-.293.293A.75.75 0 016.73 4.47l1.324-1.324a1.345 1.345 0 01.957-.4 1 1 0 011 1v4.5a.5.5 0 00.5.5h.243a.75.75 0 010 1.5zM17.5 7.5a2.75 2.75 0 01-5.5 0v-2a2.75 2.75 0 015.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});