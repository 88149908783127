define("ember-svg-jar/inlined/database-hand-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-hand-alternate</title><path d=\"M9.652 14.181a.979.979 0 00.342.069.99.99 0 00.875-.542.977.977 0 00.125-.458v-.3a.251.251 0 01.123-.216.254.254 0 01.249 0 9.872 9.872 0 004.756 1.118 9.877 9.877 0 004.756-1.118.249.249 0 01.248 0 .247.247 0 01.124.215v1.329c0 .94-1.364 1.9-3.243 2.288a1.036 1.036 0 00-.779 1.18 1 1 0 00.978.8 1.072 1.072 0 00.2-.02c2.941-.6 4.842-2.27 4.842-4.247V4.269a.921.921 0 00-.026-.129C22.96 1.794 19.94 0 16.122 0S9.284 1.794 9.02 4.14a.921.921 0 00-.026.129v8.981a1 1 0 00.589.907c.024.007.046.017.069.024zm6.47-2.333c-2.935 0-5.128-1.306-5.128-2.473V8.046a.25.25 0 01.372-.219 9.872 9.872 0 004.756 1.118 9.877 9.877 0 004.756-1.118.251.251 0 01.372.219v1.329c0 1.167-2.193 2.473-5.128 2.473zm5.128-7.375c0 1.167-2.193 2.472-5.128 2.472s-5.128-1.305-5.128-2.472S13.187 2 16.122 2s5.128 1.306 5.128 2.473zM4.25 15.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.763a2.239 2.239 0 002.237-2.236V20.5a1 1 0 00-1-1H7.125a.625.625 0 010-1.25h2.526a1 1 0 00.918-1.393A2.233 2.233 0 008.513 15.5zM2.75 15.75a.987.987 0 00-.267-.668.987.987 0 00-.733-.332 1 1 0 00-1 1V23a1 1 0 001 1 .981.981 0 00.808-.443A.97.97 0 002.75 23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});