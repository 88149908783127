define("ember-svg-jar/inlined/house-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>house-2</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11A1 1 0 001 13h1.25a.25.25 0 01.25.25V23a1 1 0 001 1h5.75a.25.25 0 00.25-.25V19a2.5 2.5 0 015 0v4.75a.25.25 0 00.25.25h5.75a1 1 0 001-1v-9.75a.25.25 0 01.25-.25H23a1 1 0 00.707-1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});