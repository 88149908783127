define("ember-svg-jar/inlined/family-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-hold</title><path d=\"M21.28 7.926a.5.5 0 00-.641.2l-1 1.745a.25.25 0 01-.433 0l-1-1.745a.5.5 0 00-.641-.2 4.5 4.5 0 00-2.474 2.874.5.5 0 00.216.558 5.533 5.533 0 011.166.978.5.5 0 00.371.165h6.574a.5.5 0 00.5-.5 4.481 4.481 0 00-2.638-4.075zM4.02 7.622A5 5 0 001.418 12a.5.5 0 00.5.5h5.856a1 1 0 00.721-.308 5.57 5.57 0 011.325-1.024.247.247 0 00.125-.168.254.254 0 00-.051-.206 3.228 3.228 0 01-.726-2.044 2.876 2.876 0 01.048-.512.5.5 0 00-.228-.515 2.982 2.982 0 00-.173-.1.251.251 0 01-.028-.423 4 4 0 10-4.739 0 .251.251 0 01-.028.421zM4.5 3.44a.254.254 0 01.149-.163.251.251 0 01.22.019A4.956 4.956 0 007.418 4a4.94 4.94 0 00.7-.05.251.251 0 01.21.069.249.249 0 01.074.207A2 2 0 114.5 3.44z\"/><circle cx=\"12.418\" cy=\"8.75\" r=\"2.25\"/><path d=\"M9.111 13.786a.253.253 0 00-.026.224.247.247 0 00.164.154 3.74 3.74 0 011.224.627 1 1 0 00.612.209h4.48a.5.5 0 00.452-.714 3.966 3.966 0 00-6.906-.5zM15.835 7.5a1 1 0 00.7-.288 3 3 0 00.335-.393.25.25 0 01.351-.054 3.66 3.66 0 004.389 0 .25.25 0 01.351.054 3 3 0 00.335.393 1 1 0 001.4-1.424c-.132-.131-.534-.527-.534-2.042a3.75 3.75 0 00-7.5 0c0 1.515-.4 1.911-.535 2.042a1 1 0 00.7 1.712zm2.073-3.646a2.621 2.621 0 00.532-.188 2.189 2.189 0 00.779-.618.25.25 0 01.4 0 2.185 2.185 0 00.78.618 2.591 2.591 0 00.531.188.249.249 0 01.183.3 1.739 1.739 0 01-3.385 0 .25.25 0 01.18-.296zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.395A2.235 2.235 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5H14a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1zM1 14.5a1 1 0 00-1 1V23a1 1 0 002 0v-7.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});