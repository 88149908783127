define("ember-svg-jar/inlined/safety-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-board</title><path d=\"M22.5 12.5h-21A1.5 1.5 0 000 14v8.5A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5V14a1.5 1.5 0 00-1.5-1.5zm-17.25 5a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm3 0a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm9 0a.75.75 0 01-1.5 0v-2a.75.75 0 011.5 0zm1 4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm.5-4.5v-2a.75.75 0 011.5 0v2a.75.75 0 01-1.5 0zm2 4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zM17.31 9.856a.25.25 0 01.19.243v.4a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.4a.251.251 0 01.19-.243 5 5 0 10-2.38 0zm1.94-7.406a.25.25 0 01.109-.206.246.246 0 01.232-.026 2.988 2.988 0 011.691 1.69.25.25 0 01-.232.342H19.5a.25.25 0 01-.25-.25zm0 3.55a.25.25 0 01.25-.25h1.55a.251.251 0 01.233.341 2.993 2.993 0 01-1.691 1.691.25.25 0 01-.342-.232zm-3.533-2.091a2.991 2.991 0 011.692-1.691.25.25 0 01.341.232V4a.25.25 0 01-.25.25h-1.55a.251.251 0 01-.233-.341zm.027 1.95a.248.248 0 01.206-.109h1.55a.25.25 0 01.25.25v1.55a.25.25 0 01-.109.206.246.246 0 01-.232.026 2.991 2.991 0 01-1.691-1.69.248.248 0 01.026-.233zM4.31 9.856a.25.25 0 01.19.243v.4A.5.5 0 005 11h1a.5.5 0 00.5-.5v-.4a.251.251 0 01.19-.243 5 5 0 10-2.38 0zM6.25 2.45a.25.25 0 01.109-.206.246.246 0 01.232-.026 2.991 2.991 0 011.692 1.691.251.251 0 01-.233.341H6.5A.25.25 0 016.25 4zm0 3.55a.25.25 0 01.25-.25h1.55a.251.251 0 01.233.341 2.993 2.993 0 01-1.691 1.691.25.25 0 01-.342-.232zM2.717 3.909a2.991 2.991 0 011.692-1.691.25.25 0 01.341.232V4a.25.25 0 01-.25.25H2.95a.251.251 0 01-.233-.341zm.027 1.95a.248.248 0 01.206-.109H4.5a.25.25 0 01.25.25v1.55a.25.25 0 01-.109.206.246.246 0 01-.232.026 2.991 2.991 0 01-1.691-1.69.248.248 0 01.026-.233z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});