define("ember-svg-jar/inlined/style-three-pin-car-mechanic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-car-mechanic</title><path d=\"M13 4.5h-2a1.74 1.74 0 00-1.946 1.712.249.249 0 00.246.288h5.4a.249.249 0 00.247-.288A1.74 1.74 0 0013 4.5z\"/><path d=\"M22.5 9.5C22.5 4.262 17.79 0 12 0S1.5 4.262 1.5 9.5a10.726 10.726 0 001.564 5.1.5.5 0 00.936-.242V11a.75.75 0 011.5 0v.5a.5.5 0 00.5.5h2.25a.75.75 0 01-.75-.75v-.89a.25.25 0 00-.111-.208 2.014 2.014 0 01-.689-.793A.25.25 0 016.926 9H8.5a.5.5 0 000-1H6.928a.25.25 0 01-.228-.358 2.005 2.005 0 011.17-1.034.251.251 0 00.17-.214C8.215 4.505 9.225 3.5 11 3.5h2c1.775 0 2.785 1 2.96 2.894a.251.251 0 00.17.214 2.005 2.005 0 011.17 1.034.25.25 0 01-.228.358H15.5a.5.5 0 000 1h1.574a.25.25 0 01.225.359 2.014 2.014 0 01-.688.793.253.253 0 00-.111.208v.89a.75.75 0 01-.75.75H18a.5.5 0 00.5-.5V11a.75.75 0 011.5 0v3.357a.5.5 0 00.936.245A10.723 10.723 0 0022.5 9.5z\"/><path d=\"M15.75 12a.75.75 0 01-.75-.75v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5a.75.75 0 01-.75.75zM5.5 17.934a.5.5 0 00.115.319 43.793 43.793 0 005.425 5.4 1.5 1.5 0 001.921 0 43.944 43.944 0 005.424-5.4.5.5 0 00.115-.319V14a.5.5 0 00-.5-.5H6a.5.5 0 00-.5.5zm5.25-1.434a1.247 1.247 0 00-.976.469.75.75 0 01-1.17-.938 2.738 2.738 0 014.671.662.5.5 0 00.462.307h2.013a.75.75 0 010 1.5h-2.013a.5.5 0 00-.462.307 2.738 2.738 0 01-4.671.662.75.75 0 011.17-.938 1.25 1.25 0 10.976-2.031z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});