define("ember-svg-jar/inlined/advertising-technorati", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>advertising-technorati</title><path d=\"M19.69 3.33A14.07 14.07 0 0012 1.15a14.07 14.07 0 00-7.69 2.18A9 9 0 000 10.81 8.92 8.92 0 003.95 18l-1.62 4.17a.5.5 0 00.66.64l6.08-2.61a15.28 15.28 0 002.93.28 14.06 14.06 0 007.69-2.18A9 9 0 0024 10.81a9 9 0 00-4.31-7.48zM12 14.88c-3.53 0-6.4-1.82-6.4-4.06S8.47 6.75 12 6.75s6.4 1.82 6.4 4.06-2.87 4.07-6.4 4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});