define("ember-svg-jar/inlined/single-man-search.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-search</title><path d=\"M23.414 23.414a2 2 0 000-2.828l-4.069-4.069a.251.251 0 01-.033-.313 10.508 10.508 0 10-3.112 3.108.249.249 0 01.313.033l4.069 4.069a2 2 0 002.832 0zM10.5 2A8.5 8.5 0 112 10.5 8.51 8.51 0 0110.5 2z\"/><path d=\"M5 14.767a.5.5 0 00.5.5h10a.5.5 0 00.5-.5 4.931 4.931 0 00-.487-1.988c-.369-.738-1.5-1.155-3.074-1.732l-.189-.07a.251.251 0 01-.163-.235v-.451a.254.254 0 01.067-.171 2.13 2.13 0 00.562-1.147.222.222 0 01.04-.1 1.185 1.185 0 00.192-.438 1.333 1.333 0 00-.08-.9.248.248 0 01-.01-.2 3.515 3.515 0 00.306-1.816c-.177-.883-1.281-1.222-2.256-1.245a2.661 2.661 0 00-1.984.6.253.253 0 01-.121.076 1.061 1.061 0 00-.58.392 2.358 2.358 0 00-.053 1.969.252.252 0 01-.021.19 1.32 1.32 0 00-.1.936 1.185 1.185 0 00.192.438.222.222 0 01.04.1 2.13 2.13 0 00.562 1.147.254.254 0 01.067.171v.451a.251.251 0 01-.163.235l-.189.07c-1.57.577-2.7.994-3.074 1.732A4.931 4.931 0 005 14.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});