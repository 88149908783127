define("ember-svg-jar/inlined/messages-bubble-square-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-image</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM6.551 14.219a.5.5 0 01.054-.526l2.451-3.151a1.5 1.5 0 011.925-.328l1.428 1.086 1.359-1.749A1.53 1.53 0 0114.994 9a1.508 1.508 0 011.166.639l2.755 4.08a.5.5 0 01-.415.78H7a.5.5 0 01-.449-.28zM6.5 6.5a2 2 0 112 2 2 2 0 01-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});