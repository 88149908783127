define("ember-svg-jar/inlined/mask-heart-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mask-heart-alternate</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M11.265 19.408a1 1 0 001.47 0l5.952-6.452a4.776 4.776 0 00.841-5.362 4.549 4.549 0 00-3.362-2.538 4.4 4.4 0 00-3.912 1.325l-.074.077a.249.249 0 01-.36 0l-.074-.076a4.406 4.406 0 00-3.912-1.326 4.549 4.549 0 00-3.362 2.538A4.767 4.767 0 005.3 12.94zM6.274 8.461a2.513 2.513 0 011.876-1.43 2.433 2.433 0 012.155.738l.974 1.012a1.033 1.033 0 001.442 0l.979-1.012a2.424 2.424 0 012.155-.738 2.513 2.513 0 011.876 1.43 2.772 2.772 0 01-.5 3.123l-5.046 5.472a.252.252 0 01-.368 0l-5.062-5.487a2.766 2.766 0 01-.481-3.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});