define("ember-svg-jar/inlined/shape-pyramid-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-pyramid-alternate</title><path d=\"M12.253 17.67a.5.5 0 00-.515 0l-8.751 5.256a.25.25 0 00.129.464h17.759a.25.25 0 00.125-.464zM11 16.361a.5.5 0 00.243-.428V1.163a.25.25 0 00-.479-.1L1.727 21.4a.25.25 0 00.357.316zM13 16.361a.5.5 0 01-.243-.428V1.163a.25.25 0 01.479-.1L22.273 21.4a.25.25 0 01-.357.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});