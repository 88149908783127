define("ember-svg-jar/inlined/button-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-split</title><path d=\"M20.335 10.123a1 1 0 001.122-.4l1.889-2.834a1 1 0 000-1.11l-1.889-2.835a1 1 0 00-1.832.555v1.333a.25.25 0 01-.25.25h-1.584c-3.03 0-4.283 1.88-5.29 3.391-1 1.5-1.593 2.276-3.21 2.276H1.736a1.25 1.25 0 000 2.5h7.555c1.617 0 2.213.78 3.21 2.276 1.007 1.511 2.26 3.391 5.29 3.391h1.584a.25.25 0 01.25.25V20.5a1 1 0 001.832.555l1.889-2.833a1 1 0 000-1.11l-1.889-2.834a1 1 0 00-1.832.555v1.334a.25.25 0 01-.25.25h-1.584c-1.616 0-2.212-.78-3.21-2.276A10.1 10.1 0 0012.827 12a10.127 10.127 0 001.754-2.141c1-1.5 1.594-2.276 3.21-2.276h1.584a.25.25 0 01.25.25v1.333a1 1 0 00.71.957z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});