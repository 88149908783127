define("ember-svg-jar/inlined/fishing-hook-fish-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-hook-fish-1</title><path d=\"M11 15.375c-3.866 0-7 4-7 4s3.134 4 7 4c3.187 0 5.876-2.718 6.723-3.673a.487.487 0 000-.654c-.847-.955-3.536-3.673-6.723-3.673zm2.5 4.75a1 1 0 111-1 1 1 0 01-1 1zM.5 16.332v6.086a.5.5 0 00.9.307l2.6-3.35-2.6-3.35a.5.5 0 00-.9.307zM15 5.375v-2a.5.5 0 00-.5-.5H.25a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25H14.5a.5.5 0 00.5-.5zM22 2.875a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.75a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25zM19.25 5.82V1.625a1 1 0 00-2 0v4.2a2.74 2.74 0 000 5.11v1.942a.5.5 0 11-1 0v-.5a1 1 0 00-2 0v.5a2.5 2.5 0 105 0V10.93a2.74 2.74 0 000-5.11zm-1 3.305a.75.75 0 11.75-.75.752.752 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});