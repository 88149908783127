define("ember-svg-jar/inlined/landmark-volcano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-volcano</title><circle cx=\"4.751\" cy=\"4.75\" r=\"4.75\"/><path d=\"M23.321 21.23c-1.99-1.28-6.64-4.81-6.9-9.81a1.482 1.482 0 00-1.47-1.42h-5.9a1.482 1.482 0 00-1.47 1.42c-.26 5-4.91 8.53-6.9 9.81a1.523 1.523 0 00-.62 1.69A1.473 1.473 0 001.471 24h21.06a1.473 1.473 0 001.41-1.08 1.528 1.528 0 00-.62-1.69zm-6.849-3.545l-.551.615a.971.971 0 01-1.45-.01l-2.11-2.39a.486.486 0 00-.72 0l-2.11 2.38a.962.962 0 01-1.45 0l-.541-.606a.25.25 0 01-.023-.3 11.983 11.983 0 001.96-5.15.25.25 0 01.248-.224h4.552a.249.249 0 01.248.22 11.921 11.921 0 001.969 5.16.253.253 0 01-.022.305z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});