define("ember-svg-jar/inlined/single-man-profile-picture.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-profile-picture</title><path d=\"M22.5 1.5A1.5 1.5 0 0021 0H3a1.5 1.5 0 00-1.5 1.5v21A1.5 1.5 0 003 24h18a1.5 1.5 0 001.5-1.5zM19 20a.5.5 0 01-.5.5h-13A.5.5 0 015 20V4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M14.117 14.223l-.259-.1a.25.25 0 01-.163-.235v-.578a.25.25 0 01.07-.173 2.308 2.308 0 00.625-1.263.245.245 0 01.047-.114 1.27 1.27 0 00.208-.471 1.458 1.458 0 00-.1-.987.248.248 0 01-.012-.208 3.886 3.886 0 00.346-2c-.189-.943-1.381-1.305-2.435-1.329a2.891 2.891 0 00-2.155.657.263.263 0 01-.127.08 1.117 1.117 0 00-.63.418 2.589 2.589 0 00-.042 2.154.251.251 0 01-.023.195 1.43 1.43 0 00-.115 1.019 1.265 1.265 0 00.208.47.254.254 0 01.047.114 2.308 2.308 0 00.625 1.264.25.25 0 01.07.173v.578a.25.25 0 01-.163.235l-.259.1c-1.715.63-2.954 1.086-3.352 1.881A5.344 5.344 0 006 18.268a.5.5 0 00.5.5h11a.5.5 0 00.5-.5 5.344 5.344 0 00-.531-2.164c-.398-.795-1.637-1.251-3.352-1.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});