define("ember-svg-jar/inlined/smiley-angel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-angel</title><path d=\"M20.582 7.464A28.888 28.888 0 0112 8.5a28.888 28.888 0 01-8.582-1.036 1 1 0 00-1.2.493 11 11 0 1019.556 0 1 1 0 00-1.192-.493zM6.084 12a2.637 2.637 0 014.832 0 1 1 0 11-1.832.8.663.663 0 00-1.168 0 1 1 0 01-1.832-.8zm1.178 3.89a1 1 0 011.384.291 4 4 0 006.708 0 1 1 0 011.675 1.092 6 6 0 01-10.058 0 1 1 0 01.291-1.383zM17.4 13.316a1 1 0 01-1.317-.515.663.663 0 00-1.168 0 1 1 0 01-1.832-.8 2.637 2.637 0 014.832 0 1 1 0 01-.515 1.315zM12 7c1.126 0 11-.1 11-3.5S13.126 0 12 0 1 .1 1 3.5 10.874 7 12 7zm0-5c4.333 0 7.352.7 8.525 1.276a.253.253 0 01.14.224.249.249 0 01-.14.224C19.353 4.3 16.334 5 12 5s-7.353-.7-8.525-1.276a.249.249 0 010-.448C4.648 2.7 7.667 2 12 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});