define("ember-svg-jar/inlined/famous-character-megaman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-megaman-1</title><path d=\"M22.912 9.063l-1.529-.438a1 1 0 01-.645-.57 9.569 9.569 0 00-4.7-4.9.5.5 0 01-.288-.455V2a2 2 0 00-2-2h-3.5a2 2 0 00-2 2v.7a.5.5 0 01-.287.453 9.569 9.569 0 00-4.7 4.9 1 1 0 01-.645.57l-1.529.438A1.5 1.5 0 000 10.5V14a1.505 1.505 0 001.088 1.442l1.116.318a.5.5 0 01.354.39 9.6 9.6 0 0018.884 0 .5.5 0 01.354-.39l1.116-.318A1.505 1.505 0 0024 14v-3.5a1.5 1.5 0 00-1.088-1.437zM10.25 2.5a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5zm0 6a.5.5 0 01.5-.5h2.5a.5.5 0 01.5.5V10a1 1 0 01-1 1h-1.5a1 1 0 01-1-1zm9.287 6.934a7.609 7.609 0 01-15.074 0l-.248-1.786a1 1 0 01.63-1.071 2.824 2.824 0 011.4-.113 6.315 6.315 0 014.8 3.812 1 1 0 001.922 0 6.316 6.316 0 014.8-3.812 2.823 2.823 0 011.4.113 1 1 0 01.63 1.07z\"/><path d=\"M8 15.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM16 15.5a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});