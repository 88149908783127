define("ember-svg-jar/inlined/smart-watch-circle-graph-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-graph-line</title><path d=\"M15.94 12.886l-.519-.866a.25.25 0 00-.446.033l-1.216 2.956a1.249 1.249 0 01-2.289-.016l-1.864-5.38a.249.249 0 00-.452-.043l-1.922 3.307a.752.752 0 01-.649.373h-2.7a.249.249 0 00-.245.295 8.5 8.5 0 0016.718 0 .249.249 0 00-.053-.205.251.251 0 00-.193-.09h-3.53a.751.751 0 01-.64-.364z\"/><path d=\"M8.423 7.845a1.267 1.267 0 011.177-.59 1.25 1.25 0 011.035.753l1.788 5.161a.25.25 0 00.467.013l1.092-2.653A1.249 1.249 0 0116.2 10.4l.739 1.232a.25.25 0 00.215.122h3.085a.25.25 0 00.249-.265 8.5 8.5 0 00-16.968 0 .25.25 0 00.249.265h2.239a.251.251 0 00.216-.125zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129zM16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});