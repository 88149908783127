define("ember-svg-jar/inlined/diagram-curve-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curve-up-1</title><path d=\"M24 4.32a1.461 1.461 0 00-.424-1.02L20.7.422a1.44 1.44 0 00-2.46 1.018v1.19a.25.25 0 01-.25.25H14.4a5.286 5.286 0 00-5.28 5.28v10.56a2.4 2.4 0 01-2.4 2.4H1.44a1.44 1.44 0 000 2.88h5.28A5.286 5.286 0 0012 18.72V8.16a2.4 2.4 0 012.4-2.4h3.59a.25.25 0 01.25.25V7.2a1.44 1.44 0 002.46 1.018l2.876-2.878A1.459 1.459 0 0024 4.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});