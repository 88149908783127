define("ember-svg-jar/inlined/famous-character-pokemon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-pokemon</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 8a4 4 0 11-4 4 4 4 0 014-4zm0 14a10.016 10.016 0 01-9.875-8.421.5.5 0 01.494-.579h3.057a.5.5 0 01.486.385 6 6 0 0011.676 0 .5.5 0 01.486-.385h3.058a.5.5 0 01.493.579A10.016 10.016 0 0112 22z\"/><circle cx=\"12\" cy=\"12\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});