define("ember-svg-jar/inlined/chef-gear-cookie-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-cookie-bowl</title><path d=\"M3.009 12h1.285a.327.327 0 00.24-.105.331.331 0 00.087-.247q-.027-.36-.027-.726a9.674 9.674 0 017.386-9.387.25.25 0 00.073-.455 7.247 7.247 0 00-9.432 10.735.5.5 0 00.388.185zM7 1.75a.75.75 0 11-.75.75.75.75 0 01.75-.75zM3.75 4.5a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><path d=\"M6.094 10.922c0 .24.01.431.023.614a.5.5 0 00.5.464h15.265a.5.5 0 00.5-.468 7.63 7.63 0 00-.087-1.926 1.047 1.047 0 00-1.252-.849 1.15 1.15 0 01-1.37-1.122 1.047 1.047 0 00-1.145-1.049 1.982 1.982 0 01-2.136-1.953 1.82 1.82 0 01.058-.455 1.048 1.048 0 00-.833-1.291 7.951 7.951 0 00-1.366-.121 8.165 8.165 0 00-8.157 8.156zM15.75 8.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zM12.25 6a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-2 2.5a.75.75 0 11-.75.75.75.75 0 01.75-.75zM22.155 13.5H1.845a1.006 1.006 0 00-.974 1.259 10.039 10.039 0 005.707 6.605.993.993 0 01.493 1.308L6.8 23.3a.5.5 0 00.457.7h9.493a.5.5 0 00.458-.7l-.275-.627a.993.993 0 01.493-1.308 10.039 10.039 0 005.707-6.6 1.006 1.006 0 00-.978-1.265z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});