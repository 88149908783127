define("ember-svg-jar/inlined/clothes-design-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-button</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.25A9.25 9.25 0 1121.25 12 9.26 9.26 0 0112 21.25z\"/><path d=\"M12 4.25A7.75 7.75 0 1019.75 12 7.759 7.759 0 0012 4.25zM8 13.5A1.5 1.5 0 119.5 12 1.5 1.5 0 018 13.5zm4 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-8A1.5 1.5 0 1113.5 8 1.5 1.5 0 0112 9.5zm4 4a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});