define("ember-svg-jar/inlined/bookmarks-document-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bookmarks-document-alternate</title><path d=\"M24 3.428a1.96 1.96 0 00-1.96-1.96H11.485A1.468 1.468 0 0010.04.244H6.122a1.47 1.47 0 00-1.445 1.224 1.518 1.518 0 00-.024.245v9.306a.489.489 0 00.881.294l2.349-3.135a.246.246 0 01.392 0l2.353 3.135a.5.5 0 00.547.171.49.49 0 00.335-.465V3.678a.25.25 0 01.25-.25h9.78a.5.5 0 01.5.5V21.54a.5.5 0 01-.5.5H2.459a.5.5 0 01-.5-.5V3.672a.244.244 0 01.241-.244.98.98 0 000-1.96h-.241A1.96 1.96 0 000 3.428V22.04A1.959 1.959 0 001.959 24H22.04A1.959 1.959 0 0024 22.04z\"/><path d=\"M14.449 7.591h3.918a.98.98 0 100-1.959h-3.918a.98.98 0 100 1.959zM14.449 11.509h3.918a.98.98 0 100-1.959h-3.918a.98.98 0 100 1.959zM18.37 13.468H6.122a.98.98 0 000 1.96H18.37a.98.98 0 000-1.96zM17.387 18.366a.979.979 0 00-.979-.979H6.122a.98.98 0 000 1.959h10.286a.979.979 0 00.979-.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});