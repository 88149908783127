define("ember-svg-jar/inlined/messages-people-woman-bubble-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-circle</title><path d=\"M17.245 0A6.911 6.911 0 0011.7 2.708 1 1 0 0013.3 3.9 4.9 4.9 0 0117.245 2 4.555 4.555 0 0122 6.311a4.319 4.319 0 01-2.9 3.97 1 1 0 00-.32.2c-.166.154-1.593 1.4-1.78 1.564v-.476a1 1 0 00-.86-1 6.353 6.353 0 01-.768-.145 1 1 0 10-.547 1.924q.081.024.174.045v1.884a1 1 0 001.707.707c.25-.249 3.056-2.7 3.288-2.909a6.285 6.285 0 004-5.766A6.552 6.552 0 0017.245 0z\"/><circle cx=\"13.5\" cy=\"6.499\" r=\"1\"/><circle cx=\"16.75\" cy=\"6.499\" r=\"1\"/><circle cx=\"20\" cy=\"6.499\" r=\"1\"/><path d=\"M11.173 17.386a.255.255 0 01-.173-.224v-.678a5.251 5.251 0 003.015-.768.5.5 0 00.132-.744c-.421-.5-.713-1.153-.842-3.059C13.059 8.278 10.607 7 8.5 7s-4.557 1.278-4.8 4.913c-.129 1.9-.421 2.563-.842 3.059a.5.5 0 00.131.744A5.267 5.267 0 006 16.484v.679a.257.257 0 01-.174.224c-2.868 1.062-4.434 1.82-5.034 3.021A7.587 7.587 0 000 23.5a.5.5 0 00.5.5h6.9a.25.25 0 00.231-.346L6.14 20.049a.248.248 0 01.06-.279.251.251 0 01.284-.039 4.679 4.679 0 004.21.037.25.25 0 01.338.327l-1.591 3.553a.251.251 0 00.228.352H16.5a.5.5 0 00.5-.5 7.581 7.581 0 00-.792-3.091c-.601-1.209-2.167-1.963-5.035-3.023z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});