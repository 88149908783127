define("ember-svg-jar/inlined/style-two-pin-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-check</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm5.731 7.183L11.085 14.3a1.506 1.506 0 01-1.072.476h-.027a1.5 1.5 0 01-1.06-.44l-2.633-2.629a1 1 0 011.414-1.414l1.9 1.9a.5.5 0 00.719-.013l5.94-6.365a1 1 0 111.462 1.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});