define("ember-svg-jar/inlined/shop-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-pin</title><path d=\"M20.747 10.9a.25.25 0 00-.245.25v5.287a.563.563 0 01-.563.563H4.065a.563.563 0 01-.563-.563v-5.29a.25.25 0 00-.245-.25 4.8 4.8 0 01-1.426-.249.251.251 0 00-.329.238v8.669a1.75 1.75 0 002 1.726h5.414a.249.249 0 01.192.09l1.741 2.088a1.5 1.5 0 002.3 0l1.751-2.088a.249.249 0 01.192-.09H20.5a1.75 1.75 0 002-1.726v-8.667a.25.25 0 00-.33-.237 4.788 4.788 0 01-1.423.249zM0 5.273v.909A3.246 3.246 0 001.989 9.1a3.406 3.406 0 003.258-.28.251.251 0 01.313.036 4.9 4.9 0 003.487 1.456 4.618 4.618 0 002.8-.93.251.251 0 01.3 0 4.668 4.668 0 003.21.914 4.8 4.8 0 003.083-1.438.252.252 0 01.314-.038 3.392 3.392 0 003.252.287A3.294 3.294 0 0024 6.182v-.909a.52.52 0 00-.042-.2l-.754-1.8-.969-2.307A1.5 1.5 0 0020.881 0H3.119a1.5 1.5 0 00-1.354.966L.8 3.273l-.754 1.8a.52.52 0 00-.046.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});