define("ember-svg-jar/inlined/real-estate-search-house.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-search-house</title><path d=\"M17.5 8.5h-3a.5.5 0 00-.5.5v.2a.5.5 0 00.171.376l3 2.625A.5.5 0 0018 11.82V9a.5.5 0 00-.5-.5zM17.5 4a.75.75 0 00-.75.75.25.25 0 01-.25.25 1.752 1.752 0 00-1.75 1.75.75.75 0 001.5 0 .25.25 0 01.25-.25 1.752 1.752 0 001.75-1.75.75.75 0 00-.75-.75z\"/><path d=\"M12 0a12 12 0 1012 12A12.01 12.01 0 0012 0zm2.5 21.794a.5.5 0 01-.394.489 10.148 10.148 0 01-4.208 0 .5.5 0 01-.4-.49V19.5a1 1 0 011-1h3a1 1 0 011 1zM10.5 14a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zm7.75 6.43a.47.47 0 01-.75-.378V16.5a.5.5 0 01.5-.5h.669a.5.5 0 00.33-.876l-6.34-5.548a1 1 0 00-1.318 0L5 15.124a.5.5 0 00.33.876H6a.5.5 0 01.5.5v3.487a.5.5 0 01-.8.4 10.5 10.5 0 1112.548.041z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});