define("ember-svg-jar/inlined/credit-card-smartphone-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-smartphone-alternate</title><path d=\"M3 15a1 1 0 01-1-1V7.75a.25.25 0 01.25-.25h17.5a.25.25 0 01.25.25v1.262a.987.987 0 00.988.987h.029A.985.985 0 0022 9.012V3a3 3 0 00-3-3H3a3 3 0 00-3 3v11a3 3 0 003 3h9.513a.987.987 0 00.987-.987v-.026a.987.987 0 00-.987-.987zM2 3a1 1 0 011-1h16a1 1 0 011 1v1.25a.25.25 0 01-.25.25H2.25A.25.25 0 012 4.25z\"/><path d=\"M8 11H5a1 1 0 000 2h3a1 1 0 000-2zM22.526 12h-5.052A1.493 1.493 0 0016 13.509v8.982A1.493 1.493 0 0017.474 24h5.052A1.493 1.493 0 0024 22.491v-8.982A1.493 1.493 0 0022.526 12zM21.5 21h-3a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v6a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});