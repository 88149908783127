define("ember-svg-jar/inlined/messages-people-woman-bubble-oval-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-oval-1</title><circle cx=\"12.991\" cy=\"5.499\" r=\"1\"/><circle cx=\"16.241\" cy=\"5.499\" r=\"1\"/><circle cx=\"19.491\" cy=\"5.499\" r=\"1\"/><path d=\"M18.375 0h-6a5.465 5.465 0 00-4.916 3.043 1 1 0 101.79.892A3.474 3.474 0 0112.375 2h6a3.5 3.5 0 110 7h-.5a1 1 0 00-.625.22l-.878.7a1 1 0 00-1-.922h-1a1 1 0 000 2v1a1 1 0 001.625.78L18.226 11h.149a5.5 5.5 0 100-11z\"/><path d=\"M1.69 16.944a1 1 0 001.382-.3 5.649 5.649 0 00.594-1.283 4.733 4.733 0 007.33 0 5.649 5.649 0 00.594 1.283 1 1 0 101.681-1.083 4.561 4.561 0 01-.643-2.511V11.8a5.3 5.3 0 00-5.3-5.3 5.3 5.3 0 00-5.3 5.3v1.25a4.573 4.573 0 01-.642 2.512 1 1 0 00.304 1.382zM7.331 15.1c-2.63 0-2.793-2.7-2.814-2.8a5.713 5.713 0 002.823-1.763 5.717 5.717 0 002.806 1.763c-.021.1-.187 2.8-2.815 2.8zM7.331 18a7.543 7.543 0 00-7.185 5.358.5.5 0 00.479.642h13.413a.5.5 0 00.479-.642A7.545 7.545 0 007.331 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});