define("ember-svg-jar/inlined/design-file-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-select</title><path d=\"M14.934 12.349a.894.894 0 01-.286-.288.1.1 0 00-.095-.061H9.459a.12.12 0 00-.1.051.928.928 0 01-.289.3c-.023.014-.07.037-.07.091v4.621a.109.109 0 00.053.084.894.894 0 01.291.283c.016.024.032.074.1.074h5.126c.055 0 .07-.042.083-.062a.911.911 0 01.3-.293.088.088 0 00.047-.093v-4.609a.119.119 0 00-.066-.098z\"/><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415zM18 11.583a.918.918 0 01-.917.917.107.107 0 00-.083.118v4.258c0 .123.083.124.083.124a.918.918 0 01.917.917v1.666a.918.918 0 01-.917.917h-1.666a.918.918 0 01-.917-.917s.006-.083-.112-.083H9.606c-.122 0-.106.083-.106.083a.918.918 0 01-.917.917H6.917A.918.918 0 016 19.583v-1.666A.918.918 0 016.917 17S7 16.988 7 16.853v-4.218c0-.136-.083-.135-.083-.135A.918.918 0 016 11.583V9.917A.918.918 0 016.917 9h1.666a.918.918 0 01.917.917s-.012.083.088.083h4.818a.085.085 0 00.094-.083.918.918 0 01.917-.917h1.666a.918.918 0 01.917.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});