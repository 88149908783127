define("ember-svg-jar/inlined/heavy-equipment-wood", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-wood</title><path d=\"M12.25 7a1.25 1.25 0 000 2.5h1.5a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25z\"/><rect x=\"15\" y=\"7\" width=\"4.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M22.25 9.5a1.25 1.25 0 000-2.5h-1.5a.25.25 0 00-.25.25v2a.25.25 0 00.25.25zM22.25 6a1.25 1.25 0 000-2.5h-1.5a.25.25 0 00-.25.25v2a.25.25 0 00.25.25z\"/><rect x=\"15\" y=\"3.5\" width=\"4.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M12.25 3.5a1.25 1.25 0 000 2.5h1.5a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25zM12.25 10.5a1.25 1.25 0 000 2.5h1.5a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25z\"/><rect x=\"15\" y=\"10.5\" width=\"4.5\" height=\"2.5\" rx=\".25\" ry=\".25\"/><path d=\"M22.25 13a1.25 1.25 0 000-2.5h-1.5a.25.25 0 00-.25.25v2a.25.25 0 00.25.25zM22.5 14.5H10a.5.5 0 01-.5-.5V8a.5.5 0 00-.5-.5H5.754a1.506 1.506 0 00-1.442 1.088l-.732 2.563-2.25 1.125a1.491 1.491 0 00-.83 1.342V17.5a1 1 0 001 1h2.26a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h5.52a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231H23a.5.5 0 00.5-.5v-2.5a1 1 0 00-1-1z\"/><circle cx=\"7\" cy=\"18.5\" r=\"2\"/><circle cx=\"19\" cy=\"18.5\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});