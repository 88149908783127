define("ember-svg-jar/inlined/human-resources-employee-previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-employee-previous</title><path d=\"M23.992 11.609A10.708 10.708 0 0012.89 1.294a10.843 10.843 0 00-9.732 7.161.25.25 0 01-.254.163L.538 8.436a.515.515 0 00-.469.246.5.5 0 00.015.53l3.068 4.6a.5.5 0 00.384.221h.033a.5.5 0 00.375-.171l3.58-4.091a.5.5 0 00-.338-.828l-1.665-.124a.25.25 0 01-.208-.353 8.809 8.809 0 017.651-5.173 8.716 8.716 0 01.641 17.42 1 1 0 00.036 2h.038a10.707 10.707 0 0010.313-11.104z\"/><circle cx=\"13.38\" cy=\"9.611\" r=\"2.75\"/><path d=\"M9.087 16.538a.248.248 0 00.037.222.251.251 0 00.2.1h8.108a.25.25 0 00.239-.323 4.489 4.489 0 00-8.586 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});