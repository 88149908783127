define("ember-svg-jar/inlined/arrow-rectangle-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-rectangle-left</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-7.117 17.116a1.25 1.25 0 11-1.767 1.768l-5.647-5.647a1.752 1.752 0 010-2.474l5.647-5.647a1.25 1.25 0 011.767 1.768l-4.939 4.939a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});