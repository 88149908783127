define("ember-svg-jar/inlined/modern-architecture-buildings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-architecture-buildings</title><path d=\"M23 22h-2.771a.5.5 0 01-.5-.5V11.25a.5.5 0 00-.209-.407l-5.582-3.987a.5.5 0 01-.209-.406V4.5a1 1 0 00-2 0v7.25a1 1 0 001 1h1a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-1a1 1 0 100 2h.75a.755.755 0 01.53.22.75.75 0 01-.53 1.28h-1a.75.75 0 00-.75.75v.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-.25a1 1 0 00-1-1h-.75a.75.75 0 110-1.5h1.25a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5h-1.25a.75.75 0 110-1.5h1a.751.751 0 00.75-.75v-1.75a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1a1 1 0 001-1V1a1 1 0 10-2 0v1.241a.5.5 0 01-.252.434l-5.244 3a1 1 0 00-.5.869V21.5a.5.5 0 01-.5.5H1a1 1 0 000 2h22a1 1 0 000-2zM5.229 11a.75.75 0 01-1.5 0V7.5a.75.75 0 011.5 0zm12.5 5a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zM17.229 6.5h3.5a3.25 3.25 0 10-2.942-4.623.249.249 0 01-.26.143 2.25 2.25 0 10-.3 4.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});