define("ember-svg-jar/inlined/layers-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers-1</title><path d=\"M21.5 2h-12A2.5 2.5 0 007 4.5v8A2.5 2.5 0 009.5 15h12a2.5 2.5 0 002.5-2.5v-8A2.5 2.5 0 0021.5 2zm.5 10.5a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z\"/><path d=\"M19.5 15.5a1 1 0 00-.98.8.249.249 0 01-.245.2H5.75a.25.25 0 01-.25-.25V7.725a.25.25 0 01.2-.245 1 1 0 00-.2-1.98 2 2 0 00-2 2v9a2 2 0 002 2h13a2 2 0 002-2 1 1 0 00-1-1z\"/><path d=\"M16 19a1 1 0 00-.98.8.249.249 0 01-.245.2H2.25a.25.25 0 01-.25-.25v-8.525a.25.25 0 01.2-.245A1 1 0 002 9a2 2 0 00-2 2v9a2 2 0 002 2h13a2 2 0 002-2 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});