define("ember-svg-jar/inlined/ski-jumping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ski-jumping</title><circle cx=\"17.329\" cy=\"2.638\" r=\"2.5\"/><path d=\"M22.953 14.487a1 1 0 00-1.927.531 1.5 1.5 0 01-1.049 1.844L1.656 21.9a1 1 0 10.53 1.928l18.321-5.038a3.5 3.5 0 002.446-4.303zM3.2 19.5a1.5 1.5 0 001.992-.734l2.167-5.279a1.493 1.493 0 01.49-.591L13.7 8.358a1.5 1.5 0 10-1.744-2.441l-5.85 4.537a4.5 4.5 0 00-1.472 1.776l-2.167 5.279A1.5 1.5 0 003.2 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});