define("ember-svg-jar/inlined/professional-tool-basecamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professional-tool-basecamp</title><path d=\"M24 16.83a4.17 4.17 0 00-.11-1.19C22 6.23 16.52 1.94 12.26 1.94h-.71c-2.69 0-5.62 1.9-8 5.21A17.45 17.45 0 000 16.61c0 1.31 2.87 5.45 12.08 5.45 8.31 0 11.8-3.81 11.92-5.23zm-9.43 3.6a25.19 25.19 0 01-4.76 0A13.22 13.22 0 013 18.28c-.08-.12-.11-.19-.12-.19v-.1a13.42 13.42 0 013.29-5.27 1.21 1.21 0 01.23-.11.64.64 0 01.57.1 6 6 0 01.65.66 5.92 5.92 0 001.08 1 1.87 1.87 0 001.29.44 1.59 1.59 0 00.75-.29 11.94 11.94 0 002.7-2.88c.45-.58 1.09-1.4 1.41-1.73l.15-.05a.2.2 0 01.13 0 2.56 2.56 0 01.3.18 22.41 22.41 0 015.34 6.84 2.44 2.44 0 01.4 1.25 14.28 14.28 0 01-6.61 2.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});