define("ember-svg-jar/inlined/single-neutral-actions-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-chat</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-5.75a3.453 3.453 0 01.248-1.28.25.25 0 00-.041-.253A5.494 5.494 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 18.75a2 2 0 002 2h.75A.25.25 0 0114 21v1.75a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2h-9a2 2 0 00-2 2v7zm7.125.219l-1.719 1.376a.25.25 0 01-.406-.2v-.4a1 1 0 00-1-1h-1.75A.25.25 0 0113 18.5V12a.25.25 0 01.25-.25h8.5A.25.25 0 0122 12v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 14.75h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.75 16.5a.75.75 0 00-.75-.75h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.56a.25.25 0 00.25-.25v-5.75a3.453 3.453 0 01.248-1.28.25.25 0 00-.041-.253A5.494 5.494 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M11 18.75a2 2 0 002 2h.75A.25.25 0 0114 21v1.75a1 1 0 001.625.781l3.407-2.726a.247.247 0 01.156-.055H22a2 2 0 002-2v-7a2 2 0 00-2-2h-9a2 2 0 00-2 2v7zm7.125.219l-1.719 1.376a.25.25 0 01-.406-.2v-.4a1 1 0 00-1-1h-1.75A.25.25 0 0113 18.5V12a.25.25 0 01.25-.25h8.5A.25.25 0 0122 12v6.5a.249.249 0 01-.25.25h-3a1 1 0 00-.625.219z\"/><path d=\"M15 14.75h5a.75.75 0 000-1.5h-5a.75.75 0 000 1.5zM18.75 16.5a.75.75 0 00-.75-.75h-3a.75.75 0 000 1.5h3a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});