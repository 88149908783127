define("ember-svg-jar/inlined/taking-pictures-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taking-pictures-square-alternate</title><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3zm-2 18a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h18a1 1 0 011 1z\"/><path d=\"M19.5 10a2 2 0 00-2-2H16a.913.913 0 01-.5-.207L14.207 6.5A1.872 1.872 0 0013 6h-2a1.872 1.872 0 00-1.207.5L8.5 7.793A.913.913 0 018 8H6.5a2 2 0 00-2 2v5a2 2 0 002 2h11a2 2 0 002-2zm-5.25 2.5A2.25 2.25 0 1112 10.25a2.25 2.25 0 012.25 2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});