define("ember-svg-jar/inlined/golf-hole-aim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-hole-aim</title><circle cx=\"7.952\" cy=\"21.057\" r=\"1.717\"/><path d=\"M23.189 3.438a.982.982 0 00-1.216.667l-4.579 15.688-1.194-.43a2.452 2.452 0 00-3.135 3.272A2.234 2.234 0 0014.777 24h.009l1.977-.018a1.979 1.979 0 001.837-1.331l5.255-18a.981.981 0 00-.666-1.213zM13.838 14.19c0-1.84-2.754-3.3-6.359-3.425a.492.492 0 00-.508.49v1.954a.736.736 0 11-1.471 0V6.383l3.685-1.746a1.471 1.471 0 00-.034-2.671l-4.2-1.88a.98.98 0 00-1.381.9L3.545 11.2C1.475 11.788.1 12.894.1 14.19c0 1.925 3.017 3.433 6.867 3.433s6.871-1.508 6.871-3.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});