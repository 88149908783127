define("ember-svg-jar/inlined/read-email-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-email-hand</title><path d=\"M23.8 22.824l-2.489-3.775v-5.49c0-2.341-3.194-4.265-4.178-4.9a.251.251 0 00-.265-.005l-3.8 2.281a.758.758 0 01-.386.107h-9a.746.746 0 01-.364-.1L.438 9.347a.25.25 0 00-.371.219v6.164a1.473 1.473 0 001.479 1.47h12.277l.99 1.119V20.5a5.785 5.785 0 001.549 3.218.75.75 0 101.17-.939c-.2-.245-1.219-2.279-1.219-2.279v-2.47a.751.751 0 00-.188-.5l-3.642-4.11a.581.581 0 010-.822.611.611 0 01.884.055l3.727 2.987a.781.781 0 001.054-.116.75.75 0 00-.116-1.055L16.067 12.9V9.75l.618.418c1.427.968 3.128 2.211 3.128 3.391v5.721a.75.75 0 00.131.423l2.62 3.967a.75.75 0 001.238-.846zM3.267 14.3l2-1.5a.5.5 0 01.3-.1h5.275a2.068 2.068 0 00.087 1H5.734l-1.867 1.4a.5.5 0 01-.6-.8z\"/><path d=\"M3.709 9.361a.249.249 0 00.361-.224V2.25A.249.249 0 014.317 2h7.5a.25.25 0 01.25.25v6.9a.252.252 0 00.116.211.249.249 0 00.241.015l1.522-.714a.251.251 0 00.121-.215V1.794A1.735 1.735 0 0012.4 0H3.733a1.734 1.734 0 00-1.666 1.792v6.6a.251.251 0 00.129.219z\"/><path d=\"M5.782 5.042h1.3a.75.75 0 000-1.5h-1.3a.75.75 0 000 1.5zM5.035 7.292a.75.75 0 00.75.75h4.562a.75.75 0 000-1.5H5.785a.75.75 0 00-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});