define("ember-svg-jar/inlined/responsive-design-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>responsive-design-expand</title><path d=\"M13.668 10.418a1 1 0 101.414 1.414l1.793-1.793a.5.5 0 01.707 0l.439.44a.5.5 0 00.854-.354v-3a.5.5 0 00-.5-.5h-3a.5.5 0 00-.354.854l.44.439a.5.5 0 010 .707zM10.729 7.479a.5.5 0 00-.354-.854h-3a.5.5 0 00-.5.5v3a.5.5 0 00.309.462.5.5 0 00.545-.108l.439-.44a.5.5 0 01.707 0l1.793 1.793a1 1 0 001.414-1.414l-1.793-1.793a.5.5 0 010-.707z\"/><path d=\"M15.875 16.125a1 1 0 001 1h4a3 3 0 003-3v-11a3 3 0 00-3-3h-16a3 3 0 00-3 3v7.5a1 1 0 002 0v-5a1 1 0 011-1h16a1 1 0 011 1v8.5a1 1 0 01-1 1h-4a1 1 0 00-1 1zm-10.5-12.5a1 1 0 01-1-1 .979.979 0 01.092-.408 1 1 0 01.908-.592.985.985 0 01.846.5.969.969 0 01.154.5 1 1 0 01-1 1zm3.5 0a1 1 0 01-1-1 .969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5 1 1 0 01-1 1zm3.5 0a1 1 0 01-1-1 .969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5 1 1 0 01-1 1z\"/><path d=\"M2.875 12.875a2.753 2.753 0 00-2.75 2.75v5.5a2.752 2.752 0 002.75 2.75h8.5a2.752 2.752 0 002.75-2.75v-5.5a2.753 2.753 0 00-2.75-2.75zm-1.25 4.25a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v4a1.251 1.251 0 01-1.25 1.25h-8.5a1.251 1.251 0 01-1.25-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});