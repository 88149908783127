define("ember-svg-jar/inlined/shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop</title><path d=\"M19.4 10.913a.251.251 0 00-.321.041 5.222 5.222 0 01-3.449 1.734 4.938 4.938 0 01-3.476-1.083.25.25 0 00-.311 0 4.859 4.859 0 01-3.071 1.1c-.131 0-.264-.006-.4-.017a5.231 5.231 0 01-3.464-1.738.25.25 0 00-.319-.043 3.911 3.911 0 01-2.331.645A.251.251 0 002 11.8v9.572a1.5 1.5 0 001.5 1.5h17a1.5 1.5 0 001.5-1.5v-9.56a.251.251 0 00-.265-.25 3.841 3.841 0 01-2.335-.649zM15 15.236a.382.382 0 01.4-.361h3.2a.382.382 0 01.4.361v5.285a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zm-9.5-.028a.356.356 0 01.375-.333h5.246a.356.356 0 01.375.333v3.334a.356.356 0 01-.375.333H5.873a.356.356 0 01-.375-.333zM21.789 1.125h-3.153a.252.252 0 00-.2.1.248.248 0 00-.034.225l1.558 4.688a.768.768 0 01.04.237v2.991a.251.251 0 00.105.208 2.287 2.287 0 002.271.322A2.717 2.717 0 0024 7.44v-.946a.274.274 0 00-.016-.089L22.1 1.462c-.084-.219-.223-.337-.311-.337z\"/><path d=\"M18.452 9.313a.247.247 0 00.046-.144V6.537a.234.234 0 00-.013-.079L16.764 1.3a.25.25 0 00-.237-.171H13a.251.251 0 00-.177.073.255.255 0 00-.073.177v8.646a3.059 3.059 0 002.764 1.172 3.963 3.963 0 002.938-1.884zM11.25 10.021V1.375a.25.25 0 00-.25-.25H7.469a.25.25 0 00-.237.171L5.511 6.458a.234.234 0 00-.013.079v2.626a.253.253 0 00.045.144A3.954 3.954 0 008.5 11.194a3.029 3.029 0 002.75-1.173zM4 9.375v-3a.768.768 0 01.038-.237L5.6 1.454a.248.248 0 00-.034-.225.25.25 0 00-.2-.1H2.211c-.088 0-.227.118-.31.337L.018 6.405A.274.274 0 000 6.494v.946a2.709 2.709 0 001.623 2.449A1.989 1.989 0 004 9.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});