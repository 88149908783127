define("ember-svg-jar/inlined/vintage-tv-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv-4</title><path d=\"M23 7.5A1.5 1.5 0 0021.5 6h-6.982a.25.25 0 01-.177-.427l3.866-3.866A1 1 0 0016.793.293l-4.616 4.616a.25.25 0 01-.354 0L7.207.293a1 1 0 00-1.414 1.414l3.866 3.866A.25.25 0 019.482 6H2.5A1.5 1.5 0 001 7.5v13A1.5 1.5 0 002.5 22h.25a.25.25 0 01.25.25V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25V23a1 1 0 002 0v-.75a.25.25 0 01.25-.25h.25a1.5 1.5 0 001.5-1.5zM7 19a3.009 3.009 0 01-3-3v-4a3.009 3.009 0 013-3h10a3.009 3.009 0 013 3v4a3.009 3.009 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});