define("ember-svg-jar/inlined/dentistry-tooth-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-brush</title><path d=\"M13.166 9.5a3.136 3.136 0 00-1.977.7.5.5 0 01-.628 0 3.136 3.136 0 00-1.977-.7c-1.8 0-3.209 1.732-3.209 3.944a6.647 6.647 0 001.235 4.165.5.5 0 01.079.464 9.831 9.831 0 00-.48 3.074 10.142 10.142 0 00.2 2A1.054 1.054 0 007.431 24a1.074 1.074 0 001.064-.969c.2-1.886 1.224-3.309 2.38-3.309s2.179 1.423 2.38 3.308a1.072 1.072 0 001.064.97 1.054 1.054 0 001.026-.848 10.16 10.16 0 00.2-2.005 9.831 9.831 0 00-.48-3.074.5.5 0 01.079-.464 6.647 6.647 0 001.235-4.165c-.004-2.212-1.414-3.944-3.213-3.944zM6.041.1a1 1 0 10-.864 1.8.129.129 0 01.063.071.128.128 0 010 .1l-.861 1.775a2 2 0 00.939 2.668l4.29 2.056a2 2 0 002.668-.936l.8-1.667a.251.251 0 01.333-.117l9.032 4.33a1 1 0 10.864-1.8zm4.54 6.443a.251.251 0 01-.333.117l-3.84-1.84a.249.249 0 01-.117-.334l.693-1.44a.25.25 0 01.333-.117l3.84 1.841a.248.248 0 01.117.333zM21.875 12.75h-.5a.25.25 0 01-.25-.25V12a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5zM4.625 10a.75.75 0 00-.75-.75h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V9a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0V11a.25.25 0 01.25-.25h.5a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});