define("ember-svg-jar/inlined/diet-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diet-scale</title><path d=\"M23.441 5.085a1.493 1.493 0 00-.553-1.328A17.2 17.2 0 0012 0 17.2 17.2 0 001.113 3.757a1.493 1.493 0 00-.554 1.328l1.85 17.571A1.5 1.5 0 003.9 24h16.2a1.5 1.5 0 001.491-1.343zM20.5 8.5a.5.5 0 01-.5.5h-6.279a.5.5 0 01-.475-.342l-.772-2.316a.52.52 0 00-.948 0l-.772 2.316A.5.5 0 0110.28 9H4a.5.5 0 01-.5-.5V5.367a.5.5 0 01.226-.419A14.422 14.422 0 015.8 3.828a.5.5 0 01.674.291L7.211 6.2a.75.75 0 101.414-.5L7.85 3.511A.393.393 0 018.12 3a14.681 14.681 0 012.589-.441.5.5 0 01.541.5V4a.75.75 0 001.5 0v-.943a.5.5 0 01.542-.5A14.654 14.654 0 0115.88 3a.393.393 0 01.27.511L15.375 5.7a.75.75 0 101.414.5l.737-2.085a.5.5 0 01.674-.291 14.369 14.369 0 012.074 1.12.5.5 0 01.226.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});