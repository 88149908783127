define("ember-svg-jar/inlined/party-balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-balloon</title><path d=\"M19.929 7.929a7.929 7.929 0 00-15.858 0c0 3.7 2.468 8.048 5.842 9.378a.25.25 0 01.116.372l-.36.539A1.147 1.147 0 0010.623 20h.127a.25.25 0 01.25.25v1.25a2.32 2.32 0 002.5 2.5 1 1 0 000-2c-.449 0-.5-.051-.5-.5v-1.25a.25.25 0 01.25-.25h.127a1.147 1.147 0 00.954-1.782l-.36-.539a.25.25 0 01.116-.372c3.374-1.33 5.842-5.676 5.842-9.378zM12 3.75a4.446 4.446 0 00-3.956 2.4.75.75 0 11-1.33-.693A5.94 5.94 0 0112 2.25a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});