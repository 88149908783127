define("ember-svg-jar/inlined/material-tile-roof", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>material-tile-roof</title><path d=\"M12 7.748a3.246 3.246 0 00-2.6 1.3l-.181 3.624a.251.251 0 00.12.226.248.248 0 00.256 0 4.756 4.756 0 014.814 0 .25.25 0 00.376-.228L14.6 9.051A3.247 3.247 0 0012 7.748zM16.142 8.566a.251.251 0 00-.054.168l.218 4.362a.25.25 0 00.411.179 5 5 0 014.976-.854.251.251 0 00.336-.271l-.562-3.75a.357.357 0 01-.028-.027 3.57 3.57 0 00-5.297.193zM22.4 14.625l-.007-.007a3.467 3.467 0 00-2.462-1.018c-2.568 0-3.162 2.253-3.476 2.485l.26 5.2a.25.25 0 00.421.17 3.5 3.5 0 015.431.795.5.5 0 00.928-.325zM4.084 12.1a4.965 4.965 0 013.2 1.162.25.25 0 00.41-.179l.22-4.39a.169.169 0 00-.036-.112 3.559 3.559 0 00-5.3-.2.724.724 0 01-.048.048l-.559 3.728a.251.251 0 00.09.232.254.254 0 00.246.039 4.963 4.963 0 011.777-.328zM5.143 5.748a4.963 4.963 0 012.518.683.251.251 0 00.377-.2l.223-4.467a.251.251 0 00-.25-.263H4.86a1.491 1.491 0 00-1.483 1.274c-.1.7-.266 1.776-.461 3.076a.25.25 0 00.342.269 4.972 4.972 0 011.885-.372zM14.928 15.587a3.251 3.251 0 00-5.857 0l-.329 6.595a.121.121 0 00.226.066 3.5 3.5 0 016.063 0 .122.122 0 00.227-.067zM7.54 16.177C7.363 16 6.684 13.6 4.084 13.6a3.462 3.462 0 00-2.458 1.01.32.32 0 01-.027.025L.506 21.919a.5.5 0 00.457.576c.177.012.3.041.47-.248a3.5 3.5 0 015.431-.795.25.25 0 00.421-.169zM15.963 6.236a.249.249 0 00.376.2 4.976 4.976 0 014.4-.325.25.25 0 00.341-.269l-.46-3.07A1.491 1.491 0 0019.14 1.5h-3.151a.251.251 0 00-.25.263zM9.525 6.506a.25.25 0 00.361.237 4.757 4.757 0 014.228 0 .248.248 0 00.248-.015.251.251 0 00.113-.221l-.239-4.772a.251.251 0 00-.25-.238h-3.972a.25.25 0 00-.25.238z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});