define("ember-svg-jar/inlined/strategy-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>strategy-split</title><path d=\"M12 24a1.25 1.25 0 001.25-1.25v-.421a10.315 10.315 0 015.714-9.254.252.252 0 01.343.128l.251.606a1 1 0 00.8.61 1.066 1.066 0 00.126.008 1 1 0 00.8-.4l2.516-3.342a1 1 0 00-.66-1.592l-4.146-.583a1 1 0 00-1.062 1.373l.225.543a.25.25 0 01-.123.321 12.816 12.816 0 00-4.341 3.389.251.251 0 01-.443-.16V6.212a.249.249 0 01.25-.25h.807a1.042 1.042 0 00.9-1.572L12.9.475a1.087 1.087 0 00-1.8 0L8.794 4.39a1.043 1.043 0 00.9 1.572h.806a.249.249 0 01.25.25v7.764a.251.251 0 01-.443.16 12.816 12.816 0 00-4.341-3.389.25.25 0 01-.122-.321l.224-.543A1 1 0 005.006 8.51L.86 9.093a1 1 0 00-.66 1.593l2.52 3.343a1 1 0 00.8.4 1.066 1.066 0 00.126-.008 1 1 0 00.8-.609l.251-.607a.254.254 0 01.144-.138.251.251 0 01.2.011 10.314 10.314 0 015.714 9.253v.421A1.25 1.25 0 0012 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});