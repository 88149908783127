define("ember-svg-jar/inlined/content-pens", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pens</title><rect x=\"16.498\" y=\"8\" width=\"4.502\" height=\"11.5\" rx=\".5\" ry=\".5\"/><path d=\"M24 3a3 3 0 00-3-3h-4a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h3.5a.5.5 0 00.5-.5V2a1 1 0 011 1v7a1 1 0 001 1 1 1 0 001-1zM13.75 20h-3.5a.5.5 0 00-.475.658l1.75 3a.5.5 0 00.95 0l1.75-3A.5.5 0 0013.75 20zM12 0a2.505 2.505 0 00-2.5 2.5v16a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-16A2.506 2.506 0 0012 0zM5.3 17.119a2.345 2.345 0 00-2.35 2.345c0 1.087 1.651 3.651 2.182 4.448A.192.192 0 005.3 24a.194.194 0 00.162-.088c.531-.8 2.182-3.361 2.182-4.448A2.345 2.345 0 005.3 17.119zm0 2.905a.586.586 0 11.586-.586.586.586 0 01-.586.586zM3 15a1 1 0 001 1h2.5a1 1 0 001-1V2.25A2.25 2.25 0 003.379 1H3a3 3 0 00-3 3v6a1 1 0 002 0V4a1 1 0 011-1zM18.749 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});