define("ember-svg-jar/inlined/disability-blind-read-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-blind-read-book</title><path d=\"M18.923 9.758a8.338 8.338 0 01-1.29.1 9.172 9.172 0 01-7.293-3.916 2.984 2.984 0 010-3.527 10.4 10.4 0 011.887-1.97A.25.25 0 0012.07 0H2a2 2 0 00-2 2v19a3 3 0 003 3h13.5a2 2 0 002-2v-1.585a.5.5 0 01.333-.472A1 1 0 0019.5 19v-8.748a.5.5 0 00-.578-.494zM11.95 9.5a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm-5.4-4.63a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.501-1.5zm0 4.63a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.501-1.5zm0 4.5a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 016.551 14zm9.95 7.5a.5.5 0 01-.5.5H3a1 1 0 010-2h13a.5.5 0 01.5.5z\"/><path d=\"M23.712 3.3A7.687 7.687 0 0017.633 0a7.688 7.688 0 00-6.08 3.3 1.493 1.493 0 000 1.763 7.684 7.684 0 006.08 3.3 7.685 7.685 0 006.078-3.3 1.492 1.492 0 00.001-1.763zm-9.159 1.988a6.761 6.761 0 01-.855-.767.5.5 0 010-.683 5.324 5.324 0 014.069-1.832.252.252 0 01.224.157.249.249 0 01-.052.268l-2.728 2.805a.5.5 0 01-.658.052zm7.014-.767A5.317 5.317 0 0117.5 6.353a.25.25 0 01-.172-.424l2.727-2.8a.5.5 0 01.659-.052 6.664 6.664 0 01.854.767.5.5 0 01-.001.677z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});