define("ember-svg-jar/inlined/arrange-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrange-number</title><path d=\"M8.5 15.248H6.75A.25.25 0 016.5 15V3.748a1 1 0 00-2 0V15a.25.25 0 01-.25.25H2.5a.5.5 0 00-.354.853l3 3a.5.5 0 00.707 0l3-3a.5.5 0 00-.353-.853zM21 8.248h-1.25A.25.25 0 0119.5 8V1.748A1.5 1.5 0 0017.062.577l-1.687 1.35a1 1 0 001.25 1.561l.469-.375a.25.25 0 01.406.195V8a.25.25 0 01-.25.25H16a1 1 0 000 2h5a1 1 0 000-2zM18 13.748a3.5 3.5 0 10.747 6.919.25.25 0 01.24.411 1.985 1.985 0 01-1.487.67H17a1 1 0 000 2h.5a4 4 0 004-4v-2.5a3.5 3.5 0 00-3.5-3.5zm0 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});