define("ember-svg-jar/inlined/smart-watch-square-time-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-time-alternate</title><path d=\"M18.5 2.5h-1.626a.25.25 0 01-.237-.171L15.974.344A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.662 1.989a.251.251 0 01-.238.171H5.5a3 3 0 00-3 3v13a3 3 0 003 3h1.625a.251.251 0 01.238.171l.662 1.987A.5.5 0 008.5 24h7a.5.5 0 00.474-.342l.663-1.987a.25.25 0 01.237-.171H18.5a3 3 0 003-3v-13a3 3 0 00-3-3zm1 16a1 1 0 01-1 1h-13a1 1 0 01-1-1v-13a1 1 0 011-1h13a1 1 0 011 1z\"/><path d=\"M16.729 7.877c-1.647 0-1.647 1.905-1.647 2.623s0 2.627 1.647 2.627 1.646-1.908 1.646-2.627 0-2.623-1.646-2.623zm0 4c-.129 0-.4 0-.4-1.377s.268-1.373.4-1.373.4 0 .4 1.373-.282 1.377-.4 1.377zM7.153 11.877a.625.625 0 000 1.25 2.1 2.1 0 002.136-2.334V9.7A1.652 1.652 0 106 9.7a1.625 1.625 0 001.64 1.782.127.127 0 01.12.085.125.125 0 01-.039.141.86.86 0 01-.568.169zm.487-1.645c-.138 0-.395 0-.395-.531s.248-.533.395-.533.4 0 .4.533-.26.531-.4.531z\"/><circle cx=\"10.458\" cy=\"9.502\" r=\".5\"/><circle cx=\"10.458\" cy=\"11.502\" r=\".5\"/><path d=\"M16.914 15h-9.5a.75.75 0 100 1.5h9.5a.75.75 0 000-1.5zM12.164 11.617h.854a.25.25 0 01.25.25v.551a.625.625 0 001.25 0v-.754a.249.249 0 01.09-.192.625.625 0 000-.959.251.251 0 01-.09-.192V8.5a.625.625 0 00-.625-.625h-.729A1.626 1.626 0 0011.539 9.5v1.49a.625.625 0 00.625.627zm.625-2.117a.375.375 0 01.375-.375.1.1 0 01.1.1v.9a.24.24 0 01-.479 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});