define("ember-svg-jar/inlined/analytics-board-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-board-bars</title><path d=\"M1.5 21h21a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0022.5 3h-21A1.5 1.5 0 000 4.5v15A1.5 1.5 0 001.5 21zM18 12a1 1 0 012 0v4.5a1 1 0 01-2 0zm-5-4.5a1 1 0 012 0v9a1 1 0 01-2 0zm-4.5 6a1 1 0 012 0v3a1 1 0 01-2 0zm-4.5-2a1 1 0 012 0v5a1 1 0 01-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});