define("ember-svg-jar/inlined/style-three-pin-volcano", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-volcano</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4 4.25a.75.75 0 010 1.5A1.252 1.252 0 0014.75 7a.75.75 0 01-1.5 0A2.752 2.752 0 0116 4.25zm-4.75-.75a.75.75 0 011.5 0V5a.75.75 0 01-1.5 0zM8 4.25A2.752 2.752 0 0110.75 7a.75.75 0 01-1.5 0A1.252 1.252 0 008 5.75a.75.75 0 010-1.5zm9.511 10.1a1.006 1.006 0 01-.938.652H7.491a1 1 0 01-.636-1.771C8.561 11.818 9.5 10.493 9.5 9.5A.5.5 0 0110 9h.25a.25.25 0 01.25.25v2.25a.5.5 0 001 0V9.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.25a.5.5 0 001 0V9.25a.25.25 0 01.25-.25H14a.5.5 0 01.5.445c.113 1.022 1.083 2.371 2.731 3.8a1 1 0 01.28 1.103z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});