define("ember-svg-jar/inlined/weather-app-cloudy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-cloudy</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H7a1 1 0 01-1-1V3a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M14 19h-4a.75.75 0 000 1.5h4a.75.75 0 000-1.5zM14.1 13.761a1.407 1.407 0 00.918-2.467.253.253 0 01-.077-.253 1.051 1.051 0 00-1.01-1.315H13.9a.155.155 0 01-.147-.092 1.923 1.923 0 00-3.647.449.249.249 0 01-.2.2 1.756 1.756 0 00.341 3.477H14.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});