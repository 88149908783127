define("ember-svg-jar/inlined/common-file-text-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-info</title><path d=\"M12 8.5h7a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25 19.17 19.17 0 011.485.083A.246.246 0 008 9.36V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M17.5 12a1 1 0 00-1-1h-4a.958.958 0 00-.71.32.15.15 0 00.014.2 8.049 8.049 0 011.234 1.38.24.24 0 00.2.1H16.5a1 1 0 001-1zM6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zM6 13.249a1 1 0 11-1 1 1 1 0 011-1zm2 8.5H5a.75.75 0 010-1.5h.627a.123.123 0 00.123-.123v-2.251a.125.125 0 00-.125-.125H5a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.623a.127.127 0 00.126.127H8a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});