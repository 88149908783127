define("ember-svg-jar/inlined/dog-collar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dog-collar</title><path d=\"M13.444 17.188V14.8a18.564 18.564 0 01-2.888 0v2.4a17.756 17.756 0 002.888-.012z\"/><path d=\"M23.828 8.189c-1.034-2.565-6.563-3.939-11.8-3.939C6.773 4.25 1.216 5.623.174 8.186A1 1 0 000 8.75v6.072c0 3.236 6.053 4.928 12.032 4.928C17.979 19.75 24 18.058 24 14.822V8.75a.992.992 0 00-.172-.561zm-11.8 3.561a28.282 28.282 0 01-5.055-.422 18.018 18.018 0 015.056-.578 22.318 22.318 0 015.277.525 27.691 27.691 0 01-5.274.475zm-9 1.75a.75.75 0 11-.75.75.75.75 0 01.755-.75zm3 2.5a.75.75 0 11.75-.75.75.75 0 01-.745.75zm12-1.5a.75.75 0 11-.75.75.75.75 0 01.755-.75zm3 .5a.75.75 0 11.75-.75.75.75 0 01-.745.75zM22 9.067c0 .317-.425.723-1.222 1.122a.251.251 0 01-.192.013 21.653 21.653 0 00-8.553-1.452 18.622 18.622 0 00-8.257 1.6.247.247 0 01-.187-.007C2.534 9.871 2 9.385 2 9.067 2 8.1 5.907 6.25 12.032 6.25 18.118 6.25 22 8.1 22 9.067zm-12 9.08a.5.5 0 01-.445-.5v-3.4a.5.5 0 01.554-.5 17.175 17.175 0 003.779 0 .5.5 0 01.555.5v3.4a.5.5 0 01-.45.5 20.775 20.775 0 01-2.076.108A17.2 17.2 0 0110 18.147z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});