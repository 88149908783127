define("ember-svg-jar/inlined/style-one-pin-tunnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-tunnel</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 5a1 1 0 111-1 1 1 0 01-1 1 5.564 5.564 0 015.5 4.95.494.494 0 01-.126.385.5.5 0 01-.374.165H7a.5.5 0 01-.371-.165.494.494 0 01-.129-.385A5.564 5.564 0 0112 5zm6 8H6a.75.75 0 010-1.5h12a.75.75 0 010 1.5z\"/><path d=\"M9.7 7.728a.5.5 0 00.288-.092A3.476 3.476 0 0112 7a.5.5 0 000-1 4.467 4.467 0 00-2.589.819.5.5 0 00.288.909z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});