define("ember-svg-jar/inlined/designer-community-forrst", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-forrst</title><path d=\"M23 23.23L12.52.33a.55.55 0 00-1 0L1 23.23a.55.55 0 00.5.77h9.18v-4.95a.25.25 0 00-.14-.22l-2.42-1.22a.75.75 0 01.66-1.35l1.58.78a.25.25 0 00.36-.22v-2a.5.5 0 01.5-.5h1.59a.5.5 0 01.5.5v1.08a.25.25 0 00.37.22l.81-.44A.75.75 0 1115.2 17l-1.76 1a.25.25 0 00-.13.22v1a.25.25 0 00.36.22l2.71-1.38a.75.75 0 11.68 1.34l-3.62 1.85a.25.25 0 00-.14.22V24h9.15a.55.55 0 00.51-.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});