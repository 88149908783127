define("ember-svg-jar/inlined/gaming-100", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-100</title><path d=\"M12 11a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5zM16.5 11a.5.5 0 00-.5.5v2a.5.5 0 001 0v-2a.5.5 0 00-.5-.5z\"/><path d=\"M21.919 8.2a.5.5 0 01-.22-.532 4.505 4.505 0 00-5.37-5.368.5.5 0 01-.532-.22 4.5 4.5 0 00-7.594 0 .5.5 0 01-.531.221A4.5 4.5 0 002.3 7.67a.5.5 0 01-.22.532 4.506 4.506 0 000 7.6.5.5 0 01.22.532A4.505 4.505 0 007.671 21.7a.5.5 0 01.532.22 4.505 4.505 0 007.594 0 .5.5 0 01.532-.22 4.506 4.506 0 005.37-5.37.5.5 0 01.22-.532 4.506 4.506 0 000-7.6zM8.5 15h-2a.5.5 0 010-1 .5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5.5.5 0 010-1H7a1 1 0 011 1v2.5a.5.5 0 00.5.5.5.5 0 010 1zm5-1.5a1.5 1.5 0 01-3 0v-2a1.5 1.5 0 013 0zm4.5 0a1.5 1.5 0 01-3 0v-2a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});