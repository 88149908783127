define("ember-svg-jar/inlined/face-id-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>face-id-6</title><path d=\"M6.5 22H2.248A.25.25 0 012 21.75V17.5a1 1 0 00-2 0V22a2 2 0 002 2h4.5a1 1 0 100-2zM1 7.5a1 1 0 001-1V2.25A.25.25 0 012.248 2H6.5a1 1 0 000-2H2a2 2 0 00-2 2v4.5a1 1 0 001 1zM23 16.5a1 1 0 00-1 1v4.25a.25.25 0 01-.25.25H17.5a1 1 0 000 2H22a2 2 0 002-2v-4.5a1 1 0 00-1-1zM22 0h-4.5a1 1 0 000 2h4.25a.25.25 0 01.25.25V6.5a1 1 0 002 0V2a2 2 0 00-2-2zM6.608 13.442a1 1 0 001.383-.3 5.7 5.7 0 00.594-1.284 4.733 4.733 0 007.33 0 5.7 5.7 0 00.594 1.284 1 1 0 001.68-1.085 4.567 4.567 0 01-.642-2.511V8.3a5.3 5.3 0 10-10.594 0v1.248a4.57 4.57 0 01-.642 2.512 1 1 0 00.297 1.382zM12.25 11.6a2.7 2.7 0 01-2.741-2.35.5.5 0 01.324-.578 5.708 5.708 0 002.426-1.636 5.71 5.71 0 002.41 1.634.5.5 0 01.323.577A2.707 2.707 0 0112.25 11.6z\"/><path d=\"M18.956 20.5a.5.5 0 00.479-.642 7.5 7.5 0 00-14.37 0 .5.5 0 00.479.642z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});