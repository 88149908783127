define("ember-svg-jar/inlined/toys-clown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toys-clown</title><path d=\"M21.686 3.7a.5.5 0 01-.4-.27 2.814 2.814 0 00-2.518-1.53 2.857 2.857 0 00-1.352.337.5.5 0 01-.606-.1 6.49 6.49 0 00-9.62 0 .5.5 0 01-.606.1A2.86 2.86 0 005.232 1.9a2.813 2.813 0 00-2.515 1.526.5.5 0 01-.4.27 2.539 2.539 0 000 5.058.5.5 0 01.4.269 2.814 2.814 0 002.515 1.527 2.857 2.857 0 001.068-.206.5.5 0 01.579.156 6.493 6.493 0 0010.24 0 .5.5 0 01.579-.156 2.853 2.853 0 001.069.206 2.814 2.814 0 002.515-1.527.5.5 0 01.4-.269 2.539 2.539 0 000-5.058zM12 11a4.5 4.5 0 114.5-4.5A4.5 4.5 0 0112 11z\"/><circle cx=\"12\" cy=\"8\" r=\"1.5\"/><path d=\"M22.406 19.086l-4.5-2A1 1 0 0017.5 17h-11a1 1 0 00-.4.082l-4.5 1.946a1 1 0 10.8 1.836l2.755-1.191a.25.25 0 01.345.227v2.145A1.955 1.955 0 007.453 24h9.094a1.955 1.955 0 001.953-1.953v-2.124a.251.251 0 01.352-.228l2.742 1.219A.987.987 0 0022 21a1 1 0 00.407-1.914z\"/><circle cx=\"10\" cy=\"4.75\" r=\"1\"/><circle cx=\"14\" cy=\"4.75\" r=\"1\"/><path d=\"M15 16a1 1 0 000-2H9a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});