define("ember-svg-jar/inlined/make-up-nail-polisher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-nail-polisher</title><path d=\"M9.371 9.225a24.659 24.659 0 012.708-.157 23 23 0 012.55.15.5.5 0 00.557-.539l-.611-7.3A1.509 1.509 0 0013.08 0h-2.16a1.508 1.508 0 00-1.494 1.374l-.611 7.312a.5.5 0 00.556.539zM18.988 11.952c-.984-1.379-4.216-1.877-6.912-1.883h-.055c-2.742 0-6.007.5-7.009 1.883a2.6 2.6 0 00-.482 2.074l.006.024 2.137 7.975A2.511 2.511 0 009.117 24h5.766a2.507 2.507 0 002.439-1.951l2.148-8.024a2.609 2.609 0 00-.482-2.073zm-4.663 7.312a.861.861 0 01-.849.736h-2.952a.861.861 0 01-.848-.735l-.613-4.287A.858.858 0 019.912 14h4.177a.856.856 0 01.848.978z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});