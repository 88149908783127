define("ember-svg-jar/inlined/monetization-user-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-user-coins</title><path d=\"M3.331 7.5H1a1 1 0 000 2h.75a.25.25 0 01.25.25V10a1 1 0 002 0v-.26a.25.25 0 01.229-.249A1.834 1.834 0 006 7.5c0-1.186-1.187-1.984-2.441-2.83-.3-.2-.711-.479-1.038-.718a.251.251 0 01.148-.452H5a1 1 0 000-2h-.75A.249.249 0 014 1.25V1a1 1 0 00-2 0v.259a.249.249 0 01-.232.249A1.792 1.792 0 000 3.5c0 1.187 1.186 1.984 2.441 2.829.3.2.712.48 1.038.72a.25.25 0 01-.148.451zM7.25 24h9.5a.5.5 0 00.5-.5 5.251 5.251 0 00-2.785-4.629.249.249 0 01-.035-.418 4.091 4.091 0 10-4.93.04.251.251 0 01-.028.42A5.24 5.24 0 006.75 23.5a.5.5 0 00.5.5zm5.5-2.254a.75.75 0 01-1.5 0v-1.105a.75.75 0 011.5 0zm-2.808-7.119a.251.251 0 01.369-.139 5.084 5.084 0 002.653.742 5.235 5.235 0 00.952-.087.094.094 0 01.074.018.1.1 0 01.033.069 2.088 2.088 0 11-4.175 0 2 2 0 01.094-.603zM21.331 7.5H19a1 1 0 000 2h.75a.25.25 0 01.25.25V10a1 1 0 002 0v-.26a.25.25 0 01.229-.249A1.834 1.834 0 0024 7.5c0-1.186-1.187-1.984-2.441-2.83-.3-.2-.711-.479-1.038-.718a.251.251 0 01.148-.452H23a1 1 0 000-2h-.75a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v.259a.249.249 0 01-.232.249A1.792 1.792 0 0018 3.5c0 1.187 1.186 1.984 2.441 2.829.3.2.712.48 1.038.72a.25.25 0 01-.148.451zM8.5 6.5h7a1 1 0 000-2h-7a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});