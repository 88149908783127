define("ember-svg-jar/inlined/ranking-winner-ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-winner-ribbon</title><path d=\"M23.366 19.266l-2.4-4.138a.25.25 0 00-.424-.013 10.283 10.283 0 01-5.075 3.985.25.25 0 00-.131.363l1.975 3.343a1 1 0 001.8-.172l.832-2.287 2.392.405a1 1 0 001.031-1.488zM3.471 15.09a.25.25 0 00-.425.012L.634 19.267a1 1 0 001.032 1.487l2.391-.405.833 2.288a1 1 0 001.8.168l1.98-3.351a.251.251 0 00.02-.212.248.248 0 00-.15-.15 10.286 10.286 0 01-5.069-4.002zM20.766 9.456a8.75 8.75 0 10-8.75 8.75 8.76 8.76 0 008.75-8.75zM10.743 8.1a.75.75 0 11-.486-1.419l2-.686a.75.75 0 01.993.71v6a.75.75 0 01-1.5 0V7.756z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});