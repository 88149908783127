define("ember-svg-jar/inlined/railroad-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-train</title><path d=\"M23.015 22.143l-2.826-1.7a.25.25 0 01-.067-.37A1.726 1.726 0 0020.5 19v-6.021-.041L20 7a3 3 0 00-3-3h-1.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-9a1 1 0 000 2h1.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H7a2.983 2.983 0 00-3 2.917l-.5 6.021V19a1.72 1.72 0 00.378 1.077.25.25 0 01-.067.37l-2.826 1.7a1 1 0 101.03 1.714l5.118-3.071a.256.256 0 01.129-.036h9.476a.256.256 0 01.129.036l5.118 3.071a1 1 0 101.03-1.714zM11 2.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM7.5 18A1.5 1.5 0 119 16.5 1.5 1.5 0 017.5 18zm9 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm1.825-6.08a.25.25 0 01-.184.08H5.858a.249.249 0 01-.249-.27L6 7a1 1 0 011-1h10a1.027 1.027 0 011 1.083l.387 4.647a.248.248 0 01-.062.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});