define("ember-svg-jar/inlined/compass-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-1</title><circle cx=\"12\" cy=\"12\" r=\"1.75\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-.75 2.25a.75.75 0 011.5 0v1.5a.75.75 0 01-1.5 0zm-7.5 10.5h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm.825-7.114a.75.75 0 011.061-1.061L6.7 5.636A.75.75 0 115.636 6.7zM12.75 21.75a.75.75 0 01-1.5 0v-1.5a.75.75 0 011.5 0zm1.179-6.475L7.25 18.057A1 1 0 015.925 16.8L8.4 9.909A2.493 2.493 0 019.91 8.4l6.89-2.475a1 1 0 011.258 1.325l-2.783 6.679a2.484 2.484 0 01-1.346 1.346zm5.5 4.15a.75.75 0 01-1.061 0L17.3 18.364a.75.75 0 011.06-1.06l1.061 1.06a.75.75 0 01.004 1.061zm2.321-6.675h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});