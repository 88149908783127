define("ember-svg-jar/inlined/social-media-mr-wong", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-mr-wong</title><path d=\"M15.5 18.25h-2.25V24h4.5v-3.5a2.25 2.25 0 00-2.25-2.25zM7.25 20.5V24h4.5v-5.75H9.5a2.25 2.25 0 00-2.25 2.25z\"/><path d=\"M23 0H1a1 1 0 00-1 1v22a1 1 0 001 1h4.75v-3.5a3.75 3.75 0 013.75-3.75h2.25v-4.58a4.75 4.75 0 01-4-4.67v-.25H3a.75.75 0 010-1.5h4.75V2a.75.75 0 011.5 0v3.75h6.5V2a.75.75 0 011.5 0v3.75H22a.75.75 0 010 1.5h-4.75v.25a4.75 4.75 0 01-4 4.67v4.58h2.25a3.75 3.75 0 013.75 3.75V24H23a1 1 0 001-1V1a1 1 0 00-1-1zM3 15.47l3.3-3.3a.75.75 0 111.06 1.06L4 16.53a.75.75 0 11-1-1.06zm19 1.06a.75.75 0 01-1.06 0l-3.3-3.3a.75.75 0 011.06-1.06l3.3 3.3a.75.75 0 010 1.06z\"/><path d=\"M12.5 10.75a3.25 3.25 0 003.25-3.25v-.25h-6.5v.25a3.25 3.25 0 003.25 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});