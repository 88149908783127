define("ember-svg-jar/inlined/login-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>login-1</title><path d=\"M23 .5H10.5a1 1 0 00-1 1V9a.5.5 0 00.5.5h6.83A3.114 3.114 0 0120 12.338a3 3 0 01-3 3.162h-7a.5.5 0 00-.5.5v6.5a1 1 0 001 1H23a1 1 0 001-1v-21a1 1 0 00-1-1z\"/><path d=\"M17 14a1.5 1.5 0 000-3H7V8.5a1 1 0 00-1.625-.781l-5 4a1 1 0 000 1.562l5 4a1.054 1.054 0 001.059.12A1 1 0 007 16.5V14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});