define("ember-svg-jar/inlined/light-bulb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-bulb-1</title><rect x=\"8.501\" y=\"17.999\" width=\"7\" height=\"1.5\" rx=\".5\" ry=\".5\"/><path d=\"M14.222 21H9.781a.5.5 0 00-.373.833 3.482 3.482 0 001.593 1V23a1 1 0 002 0v-.165a3.476 3.476 0 001.594-1 .5.5 0 00.083-.537.5.5 0 00-.456-.298zM8.251 8.249a1 1 0 100 2H9a.249.249 0 00.25-.25v-.75a1 1 0 00-.999-1zM12.751 16.5a.5.5 0 00.5-.5v-4a.25.25 0 00-.25-.25H11a.25.25 0 00-.25.25v4a.5.5 0 00.5.5z\"/><path d=\"M20.855 7.357a9 9 0 10-14.9 8.28 3.421 3.421 0 002.308.862h.484a.5.5 0 00.5-.5V12a.25.25 0 00-.25-.25h-.75a2.5 2.5 0 112.5-2.5V10a.249.249 0 00.25.25h2a.249.249 0 00.25-.25v-.75a2.5 2.5 0 112.5 2.5H15a.25.25 0 00-.25.25v4a.5.5 0 00.5.5h.484a3.421 3.421 0 002.309-.863 8.974 8.974 0 002.812-8.28z\"/><path d=\"M15 10.249h.75a1 1 0 10-1-1V10a.249.249 0 00.25.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});