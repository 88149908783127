define("ember-svg-jar/inlined/calendar-favorite-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-favorite-1</title><path d=\"M2.5 16.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v2.07a7.327 7.327 0 012 0V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 00-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569a7.816 7.816 0 01-.042-2z\"/><path d=\"M22.74 15h-2.665l-1.409-3.267-.012-.028a1.294 1.294 0 00-2.318.028L14.925 15h-2.666a1.229 1.229 0 00-1.166.76 1.271 1.271 0 00.319 1.4l2.329 2.076-1.295 2.98a1.255 1.255 0 00.315 1.451 1.286 1.286 0 001.506.166l3.233-1.826 3.236 1.821a1.273 1.273 0 001.505-.166 1.254 1.254 0 00.316-1.45l-1.3-2.981 2.348-2.092a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0022.74 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});