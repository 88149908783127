define("ember-svg-jar/inlined/discount-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-bubble</title><path d=\"M24 12a4.357 4.357 0 00-2.272-3.86.25.25 0 01-.12-.289 4.416 4.416 0 00-5.459-5.46.25.25 0 01-.289-.12 4.416 4.416 0 00-7.72 0 .249.249 0 01-.289.12 4.416 4.416 0 00-5.459 5.46.249.249 0 01-.12.289 4.415 4.415 0 000 7.72.251.251 0 01.12.29 4.416 4.416 0 005.459 5.459.25.25 0 01.289.12 4.416 4.416 0 007.72 0 .249.249 0 01.289-.12 4.415 4.415 0 005.459-5.459.251.251 0 01.12-.29A4.354 4.354 0 0024 12zm-8.342 5.486a1.829 1.829 0 111.828-1.828 1.829 1.829 0 01-1.828 1.828zm-7.829.1a1 1 0 01-1.411-1.411l9.753-9.753a1 1 0 111.411 1.411zm.514-11.072a1.829 1.829 0 11-1.829 1.828 1.829 1.829 0 011.829-1.828z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});