define("ember-svg-jar/inlined/earth-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-setting</title><circle cx=\"17.692\" cy=\"17.1\" r=\"1.485\"/><path d=\"M22.425 17.41a.457.457 0 010-.619l.977-1.055a1.764 1.764 0 00-1.683-2.923l-1.4.323a.449.449 0 01-.528-.308l-.42-1.378a1.757 1.757 0 00-3.365 0l-.419 1.378a.448.448 0 01-.528.308l-1.4-.323a1.764 1.764 0 00-1.684 2.923l.978 1.055a.459.459 0 010 .619l-.978 1.054a1.765 1.765 0 001.684 2.924l1.4-.324a.446.446 0 01.529.308l.419 1.378a1.757 1.757 0 003.365 0l.42-1.378a.448.448 0 01.528-.308l1.4.324a1.765 1.765 0 001.68-2.924zm-4.733 2.661a2.971 2.971 0 112.97-2.971 2.974 2.974 0 01-2.97 2.971z\"/><path d=\"M14.589 23.182v-.01a.5.5 0 00-.589-.338 3.279 3.279 0 01-.751.087 3.175 3.175 0 01-2.4-1.089.494.494 0 00-.3-.162 9.9 9.9 0 01-3-.959v-1.292a2.47 2.47 0 01.883-1.9 4.455 4.455 0 00-2.868-7.865H2.36a9.884 9.884 0 0115.224-5.941h-3.357a2.723 2.723 0 000 5.446 2.562 2.562 0 011.319.376 3.233 3.233 0 015.248 1.483v.011a.5.5 0 00.585.337 3.324 3.324 0 01.749-.087 3.168 3.168 0 011.05.179.495.495 0 00.658-.505A11.88 11.88 0 107.387 22.831l.157.077v-.02a11.656 11.656 0 006.851.621.258.258 0 00.194-.327z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});