define("ember-svg-jar/inlined/real-estate-deal-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-key</title><path d=\"M23.721 11.8L12.722.3a1.035 1.035 0 00-1.445 0l-11 11.5A1 1 0 001 13.493h1.5a.5.5 0 01.5.5v2.527a.5.5 0 00.444.5 4 4 0 012.88 1.753.5.5 0 00.416.222H8.5a.5.5 0 00.5-.5v-3.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v8.5a.5.5 0 00.5.5h4a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.721-1.695z\"/><path d=\"M13 19.993H6.129a.471.471 0 01-.425-.27 3 3 0 10-.004 2.555.5.5 0 01.452-.285H7a.5.5 0 01.5.5 1 1 0 002 0 .5.5 0 111 0v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5 1 1 0 000-2zm-10 2a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});