define("ember-svg-jar/inlined/people-woman-13.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-13</title><path d=\"M22.534 21.167a14.255 14.255 0 00-5.908-2.481.491.491 0 00-.479.173c-.084.1-1.206 2.891-4.146 2.891a4.743 4.743 0 01-4.145-2.89.493.493 0 00-.48-.174 14.267 14.267 0 00-5.909 2.481A2.466 2.466 0 00.5 23.144v.106a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-.106a2.466 2.466 0 00-.966-1.977zM19 11c-.012-1.458.028-1.779-.035-2.406a.253.253 0 01.123-.241A2.026 2.026 0 0020 6.448v-2.9a2.108 2.108 0 00-.9-1.909 3.041 3.041 0 00-2.487-.139.249.249 0 01-.261-.088A2.971 2.971 0 0014 .25h-4a2.969 2.969 0 00-2.292 1.088.253.253 0 01-.243.085 3.578 3.578 0 00-2.411.177A2.091 2.091 0 004 3.553v2.894A2.1 2.1 0 004.928 8.3a.25.25 0 01.114.237C4.966 9.221 5.014 9.486 5 11c-.058 6.11 5.065 7.251 7 7.251.451-.001 7.059-.067 7-7.251zM6.019 3.544a.251.251 0 01.219-.256 7.15 7.15 0 012.813.77c.3.146.361.273.324.391a2.656 2.656 0 00-.1 1.19.252.252 0 01-.143.259 6.53 6.53 0 01-2.9.8.249.249 0 01-.206-.253l-.007-2.901zm11.692-.287a.25.25 0 01.277.238c.025.622-.02 2.316-.01 2.951a.25.25 0 01-.272.253 12.49 12.49 0 01-2.821-.961.25.25 0 01-.15-.258 2.3 2.3 0 00-.076-.89.25.25 0 01.141-.29 14.571 14.571 0 012.911-1.043zM10.75 5.25A1.25 1.25 0 1112 6.5a1.252 1.252 0 01-1.25-1.25zm-3.75 6v-1.009a.249.249 0 01.243-.249A7.016 7.016 0 0011.9 8.335a.156.156 0 01.208 0 7.019 7.019 0 004.653 1.658.249.249 0 01.243.25v1.007a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"11.75\" r=\"1\"/><circle cx=\"10\" cy=\"11.75\" r=\"1\"/><path d=\"M12.943 15.265a2.378 2.378 0 00.441-.184.639.639 0 00-.307-1.2h-2a.639.639 0 00-.307 1.2 2.308 2.308 0 00.438.183 3.017 3.017 0 00.869.127 2.977 2.977 0 00.866-.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});