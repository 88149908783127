define("ember-svg-jar/inlined/concert-couple-duet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-couple-duet</title><circle cx=\"3.5\" cy=\"10\" r=\"2.5\"/><path d=\"M3.5 13A3.5 3.5 0 000 16.5v2a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.4 4.1a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.41-4.1a.5.5 0 01.5-.45h.59a.5.5 0 00.5-.5v-2A3.5 3.5 0 003.5 13z\"/><circle cx=\"19.5\" cy=\"10\" r=\"2.5\"/><path d=\"M23.978 20.353l-1.708-5.5a3 3 0 00-5.539 0l-1.708 5.5A.5.5 0 0015.5 21h1.653a.5.5 0 01.493.418l.361 2.164A.5.5 0 0018.5 24h2a.5.5 0 00.493-.418l.361-2.164a.5.5 0 01.493-.418H23.5a.5.5 0 00.478-.648zM11.219 2.81a.749.749 0 00.023-1.06L9.938.387a1.25 1.25 0 00-2.124.591L7.43 2.7a.25.25 0 01-.3.189 1.75 1.75 0 101.326 2.09l.617-2.76A.25.25 0 019.5 2.1l.66.689a.751.751 0 001.059.021zM13.5 6.567a.251.251 0 01.312-.312l1.88.57a1 1 0 00.58-1.914l-2.753-.834a1.761 1.761 0 00-2.2 2.2l.791 2.615a.5.5 0 01-.333.624 2.5 2.5 0 103.117 1.668z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});