define("ember-svg-jar/inlined/ecology-human-mind-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-human-mind-flower</title><path d=\"M21.478 9.858c-.221-.388-.5-.883-.639-1.2a1 1 0 01-.082-.381C20.679 3.093 15.858 0 11.2 0A9.972 9.972 0 001.1 10.063c0 3.582 1.206 6.144 3.683 7.812a.5.5 0 01.217.416V23.5a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-2.488a.5.5 0 01.483-.5 4.445 4.445 0 003.186-.972c.734-.733.936-2.761.989-4.045a.5.5 0 01.5-.48c.19 0 .385 0 .563-.011 1.078-.047 1.139-.9 1.174-1.4.081-1.114-.715-2.513-1.417-3.746zM16.07 11.1a.5.5 0 00-.222.685 3.223 3.223 0 01.395 1.666 2.229 2.229 0 01-2.92 2.121 3.273 3.273 0 01-1.461-.885.5.5 0 00-.719 0 3.261 3.261 0 01-1.46.885 2.229 2.229 0 01-2.92-2.121 3.2 3.2 0 01.393-1.662.5.5 0 00-.221-.685 2.689 2.689 0 01-1.614-3.113A2.3 2.3 0 016.753 6.56a3.188 3.188 0 011.7-.14.5.5 0 00.586-.42A2.684 2.684 0 0111.5 3.5 2.684 2.684 0 0113.967 6a.5.5 0 00.583.424 3.2 3.2 0 011.7.14 2.3 2.3 0 011.433 1.431A2.669 2.669 0 0116.07 11.1z\"/><path d=\"M14.377 9.882a.672.672 0 01-.446-.481 2.479 2.479 0 00-.112-.345.671.671 0 01.078-.65 1 1 0 10-1.618-1.176.674.674 0 01-.594.277 2.47 2.47 0 00-.365 0 .676.676 0 01-.593-.276 1 1 0 00-.652-.4.988.988 0 00-.746.18 1 1 0 00-.22 1.4.673.673 0 01.078.65 2.479 2.479 0 00-.112.345.675.675 0 01-.447.481 1 1 0 00-.641 1.262 1.021 1.021 0 001.263.639.67.67 0 01.639.126 2.491 2.491 0 00.3.215.675.675 0 01.317.573 1 1 0 002 0 .675.675 0 01.319-.574 2.469 2.469 0 00.3-.215.671.671 0 01.638-.125 1.02 1.02 0 001.264-.64 1 1 0 00-.65-1.266zM11.5 11a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});