define("ember-svg-jar/inlined/box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.231 2.47L.809 4.828 11.5 9.958l4.886-2.011zM0 18.507c0 .199.118.379.301.458L11 23.836V10.828L0 5.558v12.949zm3-8.5a.5.5 0 01.707-.455l5 2.5a.5.5 0 01.293.455v5a.5.5 0 01-.707.455l-5-2.5A.498.498 0 013 15.007v-5zM12 10.828v13.008l11.699-4.871a.498.498 0 00.301-.458V5.558l-12 5.27zM23.191 4.828L12.199.049a.492.492 0 00-.398 0L7.398 1.962l10.127 5.462 5.666-2.596z\"/><g><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});