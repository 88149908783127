define("ember-svg-jar/inlined/make-up-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-brush</title><path d=\"M5.418 12.237a.5.5 0 00-.709 0l-3.252 3.252a4.984 4.984 0 107.043 7.05l3.212-3.215a.5.5 0 000-.7zM20.444 3.668C17.682.888 13.468-1.175 10.387.753a2.757 2.757 0 00-1.244 1.8L7.88 8.952a.5.5 0 01-.137.257L6.48 10.473a.5.5 0 000 .7l6.3 6.383a.5.5 0 00.71 0l1.3-1.3a.5.5 0 01.257-.137l6.394-1.264a2.758 2.758 0 001.791-1.227c1.968-3.115-.177-7.328-2.788-9.96zm1.519 9.16a1.251 1.251 0 01-.813.557l-6.174 1.221a.5.5 0 01-.451-.137l-4.993-5a.5.5 0 01-.137-.45l1.219-6.177a1.252 1.252 0 01.565-.818 3.574 3.574 0 011.995-.517.5.5 0 01.421.755l-1.363 2.3a.75.75 0 101.289.766l1.729-2.913a.5.5 0 01.643-.2q.4.188.789.415a.5.5 0 01.135.75l-2.4 2.931a.75.75 0 101.162.949l2.485-3.039a.5.5 0 01.72-.058c.2.182.4.369.592.56q.237.239.464.491a.5.5 0 01-.056.721l-3.038 2.49a.75.75 0 00.954 1.162l2.94-2.408a.5.5 0 01.748.135c.153.261.293.526.419.791a.5.5 0 01-.2.644l-2.929 1.738a.75.75 0 00.766 1.289l2.3-1.368a.5.5 0 01.755.427 3.619 3.619 0 01-.536 1.993z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});