define("ember-svg-jar/inlined/single-neutral-actions-setting.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-setting</title><path d=\"M10.448 12.789a3.141 3.141 0 01.433-.58.5.5 0 00-.124-.781 7.371 7.371 0 00-3.552-.928 7.542 7.542 0 00-7.184 5.357.5.5 0 00.479.643h9.348a.5.5 0 00.455-.709 3.253 3.253 0 01.145-3.002z\"/><circle cx=\"6.999\" cy=\"4.75\" r=\"4.75\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.453.453 0 01-.533-.311l-.424-1.393a1.776 1.776 0 00-3.4 0l-.424 1.393a.454.454 0 01-.533.311l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.065a.463.463 0 010 .625l-.988 1.064a1.783 1.783 0 001.7 2.953l1.411-.327a.454.454 0 01.533.312l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.452.452 0 01.533-.312l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.064a.463.463 0 010-.625zm-5.768 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});