define("ember-svg-jar/inlined/protection-sand-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-sand-bag</title><path d=\"M22.25 19.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM13.75 19.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM5.25 19.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM14.25 14.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5z\"/><path d=\"M4.169 13.189A3.178 3.178 0 015.25 13h3.5a3.224 3.224 0 012.382 1.053.5.5 0 00.737 0A3.22 3.22 0 0114.25 13h3.5A3.254 3.254 0 0121 16.25a3.174 3.174 0 01-.189 1.081.5.5 0 00.471.669h.968a3.229 3.229 0 01.928.136.25.25 0 00.322-.236V4.5A3.5 3.5 0 0020 1H8.5a5.006 5.006 0 00-5 5 1 1 0 001 1h10.882a.5.5 0 01.448.724l-.5 1a.5.5 0 01-.448.276H4.5a1 1 0 00-1 1v2.718a.5.5 0 00.669.471z\"/><path d=\"M5.25 18h3.5a1.75 1.75 0 000-3.5h-3.5a1.75 1.75 0 000 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});