define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock</title><path d=\"M8.652 21.5H2.5v-6.152a1.25 1.25 0 00-2.5 0v6.4A2.252 2.252 0 002.25 24h6.4a1.249 1.249 0 100-2.5zM1.25 9.9A1.25 1.25 0 002.5 8.652V2.5h6.152a1.25 1.25 0 100-2.5H2.25A2.252 2.252 0 000 2.249v6.4A1.25 1.25 0 001.25 9.9zM22.75 14.1a1.25 1.25 0 00-1.25 1.249V21.5h-6.152a1.249 1.249 0 100 2.5h6.4A2.252 2.252 0 0024 21.752v-6.4a1.25 1.25 0 00-1.25-1.252zM21.75 0h-6.4a1.25 1.25 0 100 2.5h6.15v6.152a1.25 1.25 0 002.5 0v-6.4A2.252 2.252 0 0021.75 0z\"/><path d=\"M12 3.763a4.482 4.482 0 00-4.5 4.453v.825h-.375A1.618 1.618 0 005.5 10.65v7.244A1.618 1.618 0 007.125 19.5h9.75a1.618 1.618 0 001.625-1.606V10.65a1.618 1.618 0 00-1.625-1.609H16.5v-.825A4.482 4.482 0 0012 3.763zM9.5 8.216a2.5 2.5 0 015 0v.825h-5zm2.5 6.79a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});