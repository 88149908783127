define("ember-svg-jar/inlined/taxi-sign-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-sign-alternate</title><path d=\"M23.4 17.1a.251.251 0 01-.15-.23V8a3 3 0 00-3-3H3.75a3 3 0 00-3 3v8.873a.251.251 0 01-.15.23A.976.976 0 000 18a1 1 0 001 1h22a1 1 0 001-1 .976.976 0 00-.6-.9zm-2.15-.353A.25.25 0 0121 17H3a.25.25 0 01-.25-.25V8a1 1 0 011-1h16.5a1 1 0 011 1z\"/><path d=\"M18.25 8.75a.75.75 0 00-.75.75v5a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75zM7.5 8.75h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 001.5 0v-4a.25.25 0 01.25-.25.75.75 0 000-1.5zM10.75 8.75A1.752 1.752 0 009 10.5v4a.75.75 0 001.5 0v-1a.25.25 0 01.5 0v1a.75.75 0 001.5 0v-4a1.752 1.752 0 00-1.75-1.75zM11 11.5a.25.25 0 01-.5 0v-1c0-.275.5-.275.5 0zM16.7 9.778a.749.749 0 00-1.4-.556l-.072.179a.25.25 0 01-.464 0l-.064-.179a.749.749 0 00-1.392.556l.851 2.129a.249.249 0 010 .186l-.859 2.129a.749.749 0 101.392.556l.072-.179a.25.25 0 01.464 0l.072.179a.749.749 0 001.392-.556l-.851-2.129a.249.249 0 010-.186z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});