define("ember-svg-jar/inlined/music-genre-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-star</title><path d=\"M15.249 17.365a3.243 3.243 0 01.742-2.065.248.248 0 00.043-.239l-.834-2.439a.25.25 0 01.074-.271l4.476-3.836A1.429 1.429 0 0018.825 6H13.56a.25.25 0 01-.234-.161L11.464.929A1.428 1.428 0 008.79.924L6.925 5.839A.248.248 0 016.692 6H1.428A1.428 1.428 0 00.5 8.513l4.478 3.838a.252.252 0 01.074.271l-1.876 5.5a1.428 1.428 0 002.184 1.612l4.622-3.265a.249.249 0 01.288 0l4.28 3.023a.248.248 0 00.274.01q.136-.082.282-.153a.249.249 0 00.143-.225zM21.631 14.239c-.041.008-3.57 1.14-3.57 1.14A2 2 0 0016.5 17.33v2.984a.25.25 0 01-.188.242A1.75 1.75 0 1018.5 22.25v-4.736a.251.251 0 01.175-.239c.553-.174 2.274-.725 3-.957a.25.25 0 01.326.238v1.758a.25.25 0 01-.188.242A1.75 1.75 0 1024 20.25V16.2a2 2 0 00-2.369-1.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});