define("ember-svg-jar/inlined/pets-hair-roller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-hair-roller</title><path d=\"M23.52 6.068l-.144-.144a2.5 2.5 0 00.17-.894 2.464 2.464 0 00-.726-1.753l-2.1-2.1a2.537 2.537 0 00-2.636-.545l-.156-.156a1.538 1.538 0 00-2.122 0L6.719 9.564a1.5 1.5 0 000 2.122l.461.461c-.163 1.953.167 2.393-6.41 7.57a2.478 2.478 0 103.546 3.462c5.011-6.371 5.435-6.166 7.535-6.36l.457.457a1.5 1.5 0 002.121 0l9.089-9.089a1.5 1.5 0 00.002-2.119zm-9.8 9.087a.5.5 0 01-.707 0l-4.174-4.176a.5.5 0 010-.707L16.513 2.6a.5.5 0 01.708 0L21.4 6.773a.5.5 0 010 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});