define("ember-svg-jar/inlined/study-brain-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>study-brain-1</title><path d=\"M18.108 13.262a1 1 0 001-1 3.445 3.445 0 00-6.89 0 1 1 0 002 0 1.445 1.445 0 012.89 0 1 1 0 001 1z\"/><path d=\"M21.725 9.19a.5.5 0 01-.278-.345 4.439 4.439 0 00-3.113-3.286.5.5 0 01-.349-.36 4.923 4.923 0 00-4.767-3.719h-2.436A4.923 4.923 0 006.015 5.2a.5.5 0 01-.349.36A4.441 4.441 0 002.6 8.664a.5.5 0 01-.317.341 3.455 3.455 0 001.107 6.7h3.743a.5.5 0 01.462.309 3.452 3.452 0 003.187 2.137h2.7a.5.5 0 01.488.392l.7 3.191a1 1 0 101.953-.428l-.957-4.369a1 1 0 00-.976-.786h-3.9a1.446 1.446 0 01-.479-2.81 1 1 0 00-.662-1.888A3.443 3.443 0 007.6 13.4a.5.5 0 01-.463.31H3.388a1.446 1.446 0 01.057-2.891 1 1 0 001-1 2.426 2.426 0 013.013-2.354.5.5 0 01.279.785 3.413 3.413 0 00-.655 1.611 1 1 0 00.864 1.12.941.941 0 00.129.008 1 1 0 00.991-.871A1.448 1.448 0 0110.5 8.86h.761a3.45 3.45 0 003.445-3.446 1 1 0 10-2 0 1.447 1.447 0 01-1.445 1.446H10.5a.8.8 0 01-.556-.225A4.418 4.418 0 008.485 5.7a.5.5 0 01-.255-.71 2.913 2.913 0 012.552-1.51h2.436a2.916 2.916 0 012.913 2.913 1 1 0 001 1 2.437 2.437 0 012.424 2.473 1 1 0 00.834.986 1.923 1.923 0 011.611 1.9v1.467a1.937 1.937 0 01-1.935 1.935 1 1 0 00-1 1 .945.945 0 01-.879.944 1 1 0 00-.894 1.221l.56 2.429a1 1 0 00.973.775 1.03 1.03 0 00.226-.025 1 1 0 00.75-1.2l-.269-1.166a.5.5 0 01.21-.528 3 3 0 001.093-1.32.5.5 0 01.322-.284A3.943 3.943 0 0024 14.219v-1.467a3.914 3.914 0 00-2.275-3.562z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});