define("ember-svg-jar/inlined/transportation-ticket-bus-transfer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-bus-transfer-1</title><path d=\"M12.883 18.251a4.981 4.981 0 01-4.616-3.077.126.126 0 01.116-.174.749.749 0 00.53-1.28l-1.5-1.5a.749.749 0 00-1.06 0l-1.5 1.5a.749.749 0 00.53 1.28h.531a.251.251 0 01.24.182 6.973 6.973 0 006.729 5.068 1 1 0 000-2zM12.883 8.251a5.013 5.013 0 013.635 1.58.25.25 0 01-.065.393l-.179.1a.75.75 0 00.13 1.38l2.028.624a.75.75 0 00.937-.5l.624-2.028a.75.75 0 00-1.068-.883l-.212.112a.25.25 0 01-.313-.069 7.023 7.023 0 00-5.516-2.709 1 1 0 000 2zM6 9.752V10a.75.75 0 001.5 0v-.52a.25.25 0 01.124-.217A1.744 1.744 0 008.5 7.752v-6A1.753 1.753 0 006.75 0h-5A1.753 1.753 0 000 1.752v6a1.745 1.745 0 00.875 1.509.25.25 0 01.125.216V10a.75.75 0 001.5 0v-.248a.249.249 0 01.25-.25h3a.25.25 0 01.25.25zm-4.5-8a.248.248 0 01.25-.25h5a.248.248 0 01.25.25V4.5a.249.249 0 01-.25.25h-5a.249.249 0 01-.25-.25zM2.25 7.5A.75.75 0 113 6.748a.75.75 0 01-.75.752zm3.25-.75a.75.75 0 11.75.75.749.749 0 01-.75-.752zM22.25 13.252h-5A1.753 1.753 0 0015.5 15v6a1.745 1.745 0 00.875 1.509.25.25 0 01.125.216v.521a.75.75 0 101.5 0V23a.249.249 0 01.25-.25h3a.25.25 0 01.25.25v.246a.75.75 0 101.5 0v-.52a.25.25 0 01.124-.217A1.744 1.744 0 0024 21v-6a1.753 1.753 0 00-1.75-1.748zm-4.5 7.5A.75.75 0 1118.5 20a.75.75 0 01-.75.748zm4 0A.75.75 0 1122.5 20a.75.75 0 01-.75.748zm.75-3a.249.249 0 01-.25.25h-5a.249.249 0 01-.25-.25V15a.248.248 0 01.25-.25h5a.248.248 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});