define("ember-svg-jar/inlined/desktop-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor</title><path d=\"M12.359 19.014a.169.169 0 01.141-.263 1 1 0 000-2h-10a.5.5 0 01-.5-.5v-11.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v1a1 1 0 102 0v-1.5a2 2 0 00-2-2H2a2 2 0 00-2 2v12.5a2 2 0 002 2h6.349a.25.25 0 01.208.388l-1.223 1.834a.5.5 0 00.416.778h5.5a.5.5 0 00.416-.778z\"/><path d=\"M23.5 7.751H22a.5.5 0 00-.354.146l-.78.781a.253.253 0 01-.177.073H18.31a.252.252 0 01-.176-.073l-.781-.778A.5.5 0 0017 7.751h-1.5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5H17a.5.5 0 00.353-.147l.781-.78a.248.248 0 01.176-.073h2.379a.249.249 0 01.177.073l.78.78a.5.5 0 00.354.147h1.5a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5zM17.5 15h4a.75.75 0 010 1.5h-4a.75.75 0 110-1.5zm-.75-2.75a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-4a.75.75 0 01-.75-.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});