define("ember-svg-jar/inlined/plant-cactus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-cactus</title><path d=\"M17.309 18h-11a.5.5 0 00-.493.582l.346 2.076A4 4 0 0010.107 24h3.4a4 4 0 003.945-3.342l.346-2.076a.5.5 0 00-.489-.582zM21.809 11h-.918a.125.125 0 01-.124-.107.248.248 0 01.071-.214l.825-.825a.5.5 0 00-.707-.708l-.441.441a.41.41 0 01-.482.073.419.419 0 01-.224-.372V8.5a.5.5 0 00-1 0v.779a.432.432 0 01-.231.382.4.4 0 01-.471-.07l-.444-.445a.5.5 0 00-.707.708l.808.808a.227.227 0 01.06.217c-.139.558-.192.621-.515.621h-1a.5.5 0 01-.5-.5V6.5a.5.5 0 01.5-.5h1a.5.5 0 000-1h-1.526a.5.5 0 01-.45-.282 4.629 4.629 0 00-.228-.417.5.5 0 01.025-.562L16.209 2.3a.5.5 0 10-.8-.6l-.9 1.2a.5.5 0 01-.658.128 3.581 3.581 0 00-1.142-.442.5.5 0 01-.4-.49V.5a.5.5 0 00-1 0v1.6a.5.5 0 01-.4.49 3.584 3.584 0 00-1.141.442.5.5 0 01-.657-.132l-.9-1.2a.5.5 0 00-.8.6l1.077 1.439a.5.5 0 01.025.562 4.768 4.768 0 00-.7 2.2v2a.5.5 0 01-.5.5h-.5a.5.5 0 01-.5-.5V7a2.184 2.184 0 00-.249-.991.5.5 0 01.072-.568l.548-.6a.5.5 0 10-.741-.672l-.776.857a.22.22 0 01-.223.064.182.182 0 01-.131-.174V4a.5.5 0 00-1 0v.932a.154.154 0 01-.113.148.186.186 0 01-.172-.041l-1-.908a.5.5 0 10-.672.738l.6.542a.5.5 0 01.106.6A2.181 2.181 0 002.309 7v1.5a4.5 4.5 0 004.5 4.5h.5a.5.5 0 01.5.5V16a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1.5a3 3 0 002.875-2.143.5.5 0 01.479-.357h.646a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});