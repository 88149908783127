define("ember-svg-jar/inlined/messages-bubble-share-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-share-1-alternate</title><path d=\"M10.861 15.162A.094.094 0 0010.8 15H10a1 1 0 00-.707.293L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.157 6.157 0 01-.06.831.25.25 0 00.2.278 4.207 4.207 0 011.35.545.249.249 0 00.378-.167A8.375 8.375 0 0024 8.5 8.51 8.51 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.768-2.768a.249.249 0 00.071-.149 4.216 4.216 0 011.315-2.628z\"/><path d=\"M21.25 18.5a2.736 2.736 0 00-2 .873l-2.753-1.1v-.021a2.765 2.765 0 00-.046-.457l3.125-1.874a2.724 2.724 0 001.671.581 2.8 2.8 0 10-2.7-2.294l-3.125 1.875a2.724 2.724 0 00-1.672-.583 2.75 2.75 0 102 4.627l2.753 1.1v.021a2.75 2.75 0 102.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});