define("ember-svg-jar/inlined/plane-trip-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-1</title><path d=\"M23.108 5.136a2.975 2.975 0 000-4.248 2.983 2.983 0 00-4.247 0L16.119 3.63a.5.5 0 01-.354.146c-1.5 0-2.87-.01-4.112-.018C4.989 3.714 1.944 3.7.673 4.96A2.462 2.462 0 000 6.805c0 2.349 2.1 2.433 5.28 2.56 1.068.043 2.313.093 3.722.224a.5.5 0 01.307.852l-4.318 4.318a.5.5 0 01-.353.147H2.523A2.525 2.525 0 000 17.429c0 1.887 1.387 2.279 2.856 2.7l.528.152a.5.5 0 01.337.338c.049.164.094.326.138.484.4 1.426.819 2.9 2.711 2.9a2.527 2.527 0 002.523-2.524v-2.118a.5.5 0 01.146-.354l4.318-4.319a.5.5 0 01.851.307c.133 1.417.183 2.667.225 3.738C14.761 21.9 14.845 24 17.19 24a2.729 2.729 0 002.076-.71c1.143-1.16 1.1-3.671 1.016-9.171a393.13 393.13 0 01-.062-5.887.5.5 0 01.147-.355z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});