define("ember-svg-jar/inlined/paginate-filter-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paginate-filter-mail</title><path d=\"M13.852 10.016a.251.251 0 00.272 0L23.74 3.6a.5.5 0 00.212-.519A2 2 0 0022 1.5H5.5a2 2 0 00-1.97 1.648.5.5 0 00.226.51z\"/><path d=\"M14.956 11.261a1.75 1.75 0 01-1.9.024L4.267 5.737a.5.5 0 00-.767.423V17a2 2 0 002 2H22a2 2 0 002-2V6.166a.5.5 0 00-.777-.416z\"/><path d=\"M19.5 20.5h-17A.5.5 0 012 20V6a1 1 0 00-2 0v14.5a2 2 0 002 2h17.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});