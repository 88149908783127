define("ember-svg-jar/inlined/notes-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-clock</title><path d=\"M12 4.75V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.25v4.7a.244.244 0 00.3.238 7.881 7.881 0 011.459-.187.244.244 0 00.24-.243V4.5a2 2 0 00-2-2H14.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.2a.244.244 0 00.238-.3 7.881 7.881 0 01-.187-1.459.244.244 0 00-.243-.24H2V4.5h1.75a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25z\"/><path d=\"M17.25 10.5A6.75 6.75 0 1024 17.25a6.758 6.758 0 00-6.75-6.75zm0 11.5A4.75 4.75 0 1122 17.25 4.756 4.756 0 0117.25 22z\"/><path d=\"M19.25 16.25h-.75a.25.25 0 01-.25-.25v-1.25a1 1 0 00-2 0v2.5a1 1 0 001 1h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});