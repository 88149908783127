define("ember-svg-jar/inlined/smiley-glasses-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-glasses-alternate</title><path d=\"M3 12.485h6a2 2 0 002-2v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v.25a2 2 0 002 2h6a2 2 0 002-2v-4a2 2 0 00-2-2h-6a2 2 0 00-2 2v1.25a.249.249 0 01-.25.25h-1.5a.249.249 0 01-.25-.25v-1.25a2 2 0 00-2-2H3a2 2 0 00-2 2v4a2 2 0 002 2zm12-5.75a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v3.5a.249.249 0 01-.25.25h-5.5a.249.249 0 01-.25-.25zm-12 0a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25v3.5a.249.249 0 01-.25.25h-5.5a.249.249 0 01-.25-.25zM22.941 13.511a1 1 0 00-1.2.748 10 10 0 01-19.482 0 1 1 0 10-1.947.453 12 12 0 0023.376 0 1 1 0 00-.747-1.201zM7.054 3.292a10.178 10.178 0 019.892 0 1 1 0 10.991-1.737 12.211 12.211 0 00-11.874 0 1 1 0 10.991 1.737z\"/><path d=\"M16.561 14.485H7.438a.432.432 0 00-.379.25.557.557 0 000 .5A5.619 5.619 0 0012 18.485a5.62 5.62 0 004.941-3.25.557.557 0 000-.5.431.431 0 00-.38-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});