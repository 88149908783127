define("ember-svg-jar/inlined/eraser-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>eraser-1</title><path d=\"M22.085 9.248a2.007 2.007 0 000-2.83l-5.171-5.17a2 2 0 00-2.829 0l-3.524 3.524a.5.5 0 000 .707l7.292 7.293a.5.5 0 00.708 0zM4.085 18.418A5.559 5.559 0 007.5 19.833h2a5.559 5.559 0 003.414-1.415l3.525-3.525a.5.5 0 000-.707L9.147 6.893a.5.5 0 00-.708 0l-6.525 6.525a2.007 2.007 0 000 2.83zM23 21.333H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});