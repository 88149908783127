define("ember-svg-jar/inlined/farming-wind-cock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-wind-cock</title><path d=\"M14.7 3.148a.5.5 0 00.723.162 4.394 4.394 0 014.984-.069.5.5 0 00.689-.134l.676-.987a.563.563 0 00-.567-.871l-1 .185L18.328.1a.561.561 0 00-.645 0l-1.927 1.334-1.073-.19a.563.563 0 00-.587.832z\"/><path d=\"M23.125 20.219l-2.5-2A1 1 0 0019 19v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-.653a.5.5 0 01.41-.491 8.816 8.816 0 007.033-7.417.5.5 0 01.5-.439H22.5a.5.5 0 00.459-.7l-1.821-4.213A3.461 3.461 0 0014.5 6.96c0 1.407-1.641 2.54-3 2.54-2.494 0-5.169-3.167-6.2-4.528a1 1 0 00-1.8.6V10a.5.5 0 00.5.5h2.751a.5.5 0 010 1H4.263a.5.5 0 00-.485.627 8.555 8.555 0 00.359 1.066.5.5 0 00.463.31H8a.5.5 0 010 1H5.775a.5.5 0 00-.389.814 8.509 8.509 0 005.2 3.054.5.5 0 01.416.493v.636a.5.5 0 01-.5.5H7.734a.5.5 0 01-.358-.151l-.862-.882a1 1 0 00-.846-.467H.75a.251.251 0 00-.224.362l1.8 1.969a.249.249 0 010 .338l-1.8 1.969a.251.251 0 00.224.362h4.918a1 1 0 00.846-.467l.862-.882A.5.5 0 017.734 22H10.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a1 1 0 001.625.781l2.5-2a1 1 0 000-1.562zM18 5.729a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});