define("ember-svg-jar/inlined/meeting-smartphone-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-smartphone-message</title><path d=\"M12.5 12.371a.25.25 0 00-.105-.2 4.486 4.486 0 01-.947-.914.25.25 0 00-.448.153v6.8a.25.25 0 01-.353.228c-.707-.32-1.944-.779-2.307-.912a.253.253 0 01-.158-.182 2.16 2.16 0 01-.013-.664.253.253 0 01.06-.124 4.059 4.059 0 001.009-3.028A2.8 2.8 0 006.5 10.5a2.8 2.8 0 00-2.738 3.026 4 4 0 00.987 3 .26.26 0 01.054.1 2.311 2.311 0 01.045.663.249.249 0 01-.161.216c-.4.151-1.633.616-2.333.935A.25.25 0 012 18.207V6.911a.415.415 0 01.417-.411h7.833a.249.249 0 00.25-.25v-2a.25.25 0 00-.25-.25H2.438A2.441 2.441 0 000 6.437v14.625A2.44 2.44 0 002.438 23.5h8.124A2.44 2.44 0 0013 21.062v-5.983a.252.252 0 00-.045-.143A2.494 2.494 0 0112.5 13.5zM21 .5h-6a3 3 0 00-3 3v5a3.005 3.005 0 001.846 2.77.251.251 0 01.154.23v2a1 1 0 001.832.555l1.629-2.443a.248.248 0 01.208-.112H21a3 3 0 003-3v-5a3 3 0 00-3-3zm1 8a1 1 0 01-1 1h-4a1 1 0 00-.832.446.2.2 0 01-.168.09.2.2 0 01-.168-.09A1 1 0 0015 9.5a1 1 0 01-1-1v-5a1 1 0 011-1h6a1 1 0 011 1z\"/><path d=\"M20 3.749h-4a.75.75 0 000 1.5h4a.75.75 0 100-1.5zM20 6.749h-4a.75.75 0 100 1.5h4a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});