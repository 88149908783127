define("ember-svg-jar/inlined/single-man-actions-video.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-video</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.363 6.363 0 00.93-.07.5.5 0 01.57.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM9.456 15.5a2.99 2.99 0 011.912-2.785.5.5 0 00.1-.877A7.414 7.414 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.412a.5.5 0 00.5-.5zM23.327 15.517l-2.806.748a.252.252 0 01-.217-.043.249.249 0 01-.1-.2V15.5a1.5 1.5 0 00-1.5-1.5h-6.25a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h6.25a1.5 1.5 0 001.5-1.5v-.524a.249.249 0 01.1-.2.252.252 0 01.217-.043l2.806.748a.5.5 0 00.629-.481v-6a.5.5 0 00-.629-.483z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});