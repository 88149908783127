define("ember-svg-jar/inlined/diagram-curve-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curve-up</title><path d=\"M23.576 3.3L20.7.422a1.44 1.44 0 00-2.46 1.018v1.195a.249.249 0 01-.24.249 6.726 6.726 0 00-6.413 5.829 1.409 1.409 0 00-.066.407v7.68a4.32 4.32 0 01-8.64 0V1.44A1.44 1.44 0 000 1.44V16.8a7.2 7.2 0 0014.4 0V9.6a3.845 3.845 0 013.573-3.831.251.251 0 01.267.25V7.2a1.44 1.44 0 002.46 1.018l2.876-2.878a1.439 1.439 0 000-2.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});