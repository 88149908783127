define("ember-svg-jar/inlined/navigation-left-circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-circle-2</title><path d=\"M0 12A12 12 0 1012 0 12.013 12.013 0 000 12zm8.4 3.6l-4-4a.5.5 0 010-.707l4-4a.5.5 0 01.854.354v1.5A.25.25 0 009.5 9h5.75a3.99 3.99 0 01.406 7.961.5.5 0 01-.151-.988 1.25 1.25 0 00-.255-2.473H9.5a.25.25 0 00-.25.25v1.5a.5.5 0 01-.85.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});