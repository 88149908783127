define("ember-svg-jar/inlined/chess-knight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chess-knight</title><path d=\"M5.772 12a1.334 1.334 0 001.28-.96c.187-2.157 1.657-2.122 2.4-2.98a.25.25 0 01.438.185 12.824 12.824 0 01-1.613 4.474 11.626 11.626 0 00-1.494 5.256.5.5 0 00.5.525h9.75a.5.5 0 00.474-.66 13.03 13.03 0 01-.736-4.076V10.5c0-4.372-.164-9.115-7.415-8.494a.5.5 0 01-.468-.235A3.287 3.287 0 006.272 0a.5.5 0 00-.5.5v1.757a1 1 0 01-.364.772A4.491 4.491 0 003.772 6.5V11a1 1 0 001 1zM17.228 20h-10a3 3 0 00-3 3 1 1 0 001 1h14a1 1 0 001-1 3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});