define("ember-svg-jar/inlined/time-clock-file-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-file-1</title><path d=\"M21.707 5.708L16.293.294A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.146l4.536 4.535a.5.5 0 01.146.355z\"/><path d=\"M12 6.5a6 6 0 106 6 6.006 6.006 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z\"/><path d=\"M13.251 11.5a.251.251 0 01-.251-.25v-.959a1 1 0 00-2 0V12.5a1 1 0 001 1h2.21a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});