define("ember-svg-jar/inlined/diagram-fall-rise-steady", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-fall-rise-steady</title><path d=\"M23.891 7.784l.007.022zM23.891 8.878l.007-.021zM1.44 12.789a1.44 1.44 0 001.44-1.44v-1.92a1.44 1.44 0 10-2.88 0v1.92a1.44 1.44 0 001.44 1.44zM15.84 6.891h-1.92a1.44 1.44 0 100 2.88h1.92a1.44 1.44 0 100-2.88zM4.312 19.851a1.44 1.44 0 00-.12 2.878h1.98a1.44 1.44 0 000-2.88zM1.44 19.509a1.44 1.44 0 001.44-1.44v-1.92a1.44 1.44 0 10-2.88 0v1.92a1.44 1.44 0 001.44 1.44zM9.6 13.405a1.44 1.44 0 001.44-1.44v-.754a1.437 1.437 0 01.115-.567A1.44 1.44 0 108.508 9.51a4.289 4.289 0 00-.348 1.7v.754a1.44 1.44 0 001.44 1.441zM8.16 18.411c0 .045 0 .089-.006.133a1.44 1.44 0 001.293 1.574 1.337 1.337 0 00.142.007 1.441 1.441 0 001.431-1.3 4.38 4.38 0 00.02-.414v-1.646a1.44 1.44 0 10-2.88 0zM1.44 6.069a1.44 1.44 0 001.44-1.44v-1.92a1.44 1.44 0 10-2.88 0v1.92a1.44 1.44 0 001.44 1.44zM23.577 7.312L20.7 4.433a1.44 1.44 0 00-2.46 1.018v5.76a1.44 1.44 0 00.889 1.33 1.415 1.415 0 00.551.11 1.435 1.435 0 001.018-.422l2.879-2.879a1.439 1.439 0 000-2.038z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});