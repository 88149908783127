define("ember-svg-jar/inlined/card-game-dice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-dice</title><path d=\"M15.954 2.976a1 1 0 101.307-.541 1 1 0 00-1.307.541zM9.849 16.465a1.043 1.043 0 00.395-.079 1.024 1.024 0 00.615-.751l.518-2.617a1.026 1.026 0 00-.436-1.055l-2.218-1.484a1.029 1.029 0 00-1.582.653l-.519 2.619a1.031 1.031 0 00.437 1.056l2.219 1.484a1.027 1.027 0 00.571.174z\"/><circle cx=\"9.889\" cy=\"7.25\" r=\"1\"/><circle cx=\"7.949\" cy=\"19.25\" r=\"1\"/><path d=\"M22.5 14h-7a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zm-6 8.5a1 1 0 111-1 1 1 0 01-1 1zM19 20a1 1 0 111-1 1 1 0 01-1 1zm2.5-2.5a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M12.155 19.854L7.3 21.865a.25.25 0 01-.327-.135L1.664 8.917a.255.255 0 010-.192.251.251 0 01.136-.135l8.9-3.69a.25.25 0 01.327.136l3.111 7.511a.25.25 0 00.318.138A2.972 2.972 0 0115.5 12.5a.162.162 0 00.15-.225l-3.415-8.247a1.278 1.278 0 00-1.671-.692l-1.577.653a.25.25 0 01-.346-.231V1.75a.255.255 0 01.073-.177.251.251 0 01.177-.073h9.637a.249.249 0 01.25.25v10.5a.249.249 0 00.25.25h1a.25.25 0 00.25-.25V1.281A1.28 1.28 0 0019 0H8.42a1.281 1.281 0 00-1.279 1.279v3.308a.251.251 0 01-.154.231L.792 7.384A1.278 1.278 0 00.1 9.056l5.666 13.683a1.282 1.282 0 001.67.693l4.91-2.032a.25.25 0 00.155-.231v-1.084a.251.251 0 00-.346-.231z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});