define("ember-svg-jar/inlined/vectors-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-pen</title><path d=\"M18.9 12.518a2.5 2.5 0 00-.173-2.558L16.3 6.442a.5.5 0 00-.411-.215H8.115a.5.5 0 00-.411.215L5.269 9.96a2.5 2.5 0 00-.169 2.558l5.567 10.935a1.534 1.534 0 00.137.22.25.25 0 00.45-.15v-9.131a.5.5 0 00-.25-.434 2 2 0 112 0 .5.5 0 00-.25.434v9.131a.25.25 0 00.45.15 1.534 1.534 0 00.137-.22zM15.5 5.227a.5.5 0 00.485-.379l1-4A.5.5 0 0016.5.227h-9a.5.5 0 00-.485.621l1 4a.5.5 0 00.485.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});