define("ember-svg-jar/inlined/outdoors-mining", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-mining</title><path d=\"M.573 17.947a1.938 1.938 0 001.382.573h.028a1.944 1.944 0 001.4-.614l9.105-9.657a.5.5 0 00-.029-.714c-.532-.482-1.1-.943-1.687-1.383a.5.5 0 00-.643.036L.615 15.139a1.957 1.957 0 00-.042 2.808z\"/><path d=\"M20.532 8.22C16.668 2.623 11.75-.065 5.513 0a.756.756 0 00-.72.56.755.755 0 00.347.84l.6.348c3.546 2.069 8.4 4.9 10.436 8.809l.36.7a1.489 1.489 0 001.115.791 1.652 1.652 0 00.221.015 1.482 1.482 0 001.068-.463l1.436-1.484a1.5 1.5 0 00.156-1.896zM14.466 22.348a1.006 1.006 0 00.5-.131L17.5 20.77a1 1 0 00.5-.87v-2.452a1 1 0 00-.5-.867l-2.54-1.451a1.012 1.012 0 00-.989 0l-2.532 1.45a1 1 0 00-.505.868V19.9a1 1 0 00.5.869l2.532 1.446a1 1 0 00.5.133zM24 14.438a1.008 1.008 0 00-.4-.8l-1.251-.938a1.007 1.007 0 00-1.2 0l-1.251.938a1.007 1.007 0 00-.4.8v.8a1 1 0 00.426.819l1.252.876a1 1 0 001.146 0l1.251-.876A1 1 0 0024 15.24zM24 21.324a1.007 1.007 0 00-.4-.8l-1.249-.936a1 1 0 00-1.2 0l-1.251.938a1.006 1.006 0 00-.4.8v.8a1 1 0 00.426.818l1.25.875a1 1 0 001.148 0l1.249-.874a1 1 0 00.427-.819z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});