define("ember-svg-jar/inlined/office-stamp-document-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-stamp-document-alternate</title><path d=\"M24 7a1 1 0 00-.293-.707l-6-6A1 1 0 0017 0H6a2 2 0 00-2 2v5.084a.251.251 0 00.342.233 4.458 4.458 0 011.421-.311A.249.249 0 006 6.757V2.5a.5.5 0 01.5-.5h9.75a.25.25 0 01.25.25V5.5a2 2 0 002 2h3.25a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5h-8.75a.25.25 0 00-.25.25V23a2.48 2.48 0 01-.1.682.25.25 0 00.241.318H22a2 2 0 002-2zM10 24a1 1 0 001-1v-.75a.25.25 0 00-.25-.25h-9.5a.25.25 0 00-.25.25V23a1 1 0 001 1z\"/><path d=\"M1 20.5h10a1 1 0 001-1 2 2 0 00-2-2H9a2 2 0 01-2-1.942 2.006 2.006 0 01.8-1.658 3 3 0 10-3.6 0 2.008 2.008 0 01.8 1.654A2 2 0 013 17.5H2a2 2 0 00-2 2 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});