define("ember-svg-jar/inlined/meeting-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-headphones</title><path d=\"M2 9.481a.25.25 0 00-.412-.19 4.491 4.491 0 000 6.853.25.25 0 00.412-.191zM24 12.717a4.489 4.489 0 00-1.587-3.425.25.25 0 00-.412.19v6.47a.25.25 0 00.412.191A4.491 4.491 0 0024 12.717zM21 8.675a.25.25 0 00-.177-.239 4.34 4.34 0 00-.626-.142.248.248 0 01-.211-.242 7.987 7.987 0 00-15.971 0 .251.251 0 01-.212.242 4.351 4.351 0 00-.627.141.25.25 0 00-.176.239v8.087a.25.25 0 00.174.239 4.344 4.344 0 001.326.217H7a.5.5 0 00.5-.5v-8a.5.5 0 00-.5-.5h-.731a.254.254 0 01-.181-.077.251.251 0 01-.069-.184 5.987 5.987 0 0111.964 0 .25.25 0 01-.249.261H17a.5.5 0 00-.5.5v8a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v1.315c0 1.474-1.291 2-2.5 2h-1.64a.253.253 0 01-.208-.111 2 2 0 100 2.221.252.252 0 01.208-.11h1.64c2.649 0 4.5-1.645 4.5-4v-1.4a.25.25 0 01.211-.247 4.293 4.293 0 00.613-.135.25.25 0 00.176-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});