define("ember-svg-jar/inlined/shipment-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-give</title><path d=\"M6.879 3.741a.25.25 0 00-.379.214v4.692a.5.5 0 00.243.429l3.878 2.324a.25.25 0 00.379-.211V6.355a.249.249 0 00-.121-.214zM16.538 2.853a.25.25 0 00.007-.443L12.239.061a.5.5 0 00-.478 0L7.392 2.444a.25.25 0 00-.009.434L11.4 5.29a.247.247 0 00.24.009zM12 11.276a.249.249 0 00.362.223l4.862-2.43a.5.5 0 00.276-.447V3.894a.25.25 0 00-.362-.223l-5 2.5a.251.251 0 00-.138.223z\"/><rect x=\"14.5\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M21 12.493a.258.258 0 01-.073.181l-2.281 2.281a.25.25 0 01-.421-.233l.493-2.151c.343-1.438-.971-2.051-1.688-.937a18.664 18.664 0 00-1.343 3.356 3.954 3.954 0 00-.187 1.21v3.651a.25.25 0 00.25.25h3.619a.244.244 0 00.2-.1c.423-.607 2.6-3.728 3.67-5.151a1.283 1.283 0 00.258-.778V6.745a.242.242 0 00-.239-.244c-.618-.021-2.237.456-2.258 5.992z\"/><rect x=\"3.5\" y=\"21\" width=\"6\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M8.25 20.1a.25.25 0 00.25-.25V16.2a3.954 3.954 0 00-.188-1.212 18.664 18.664 0 00-1.343-3.356c-.717-1.114-2.031-.5-1.688.937l.494 2.151a.253.253 0 01-.123.28.25.25 0 01-.3-.042l-2.279-2.284A.258.258 0 013 12.493C2.979 6.957 1.36 6.48.739 6.5a.247.247 0 00-.239.25v7.4a.893.893 0 00.174.527c.981 1.335 3.313 4.685 3.753 5.323a.246.246 0 00.2.105z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});