define("ember-svg-jar/inlined/astronomy-planet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-planet</title><path d=\"M18.5 13.25h-1.912a.5.5 0 01-.488-.393q-.065-.292-.155-.576a.5.5 0 01.227-.585l1.656-.955a1 1 0 10-1-1.732l-1.659.958a.5.5 0 01-.62-.1 6.207 6.207 0 00-.421-.422.5.5 0 01-.1-.619l.958-1.659a1 1 0 00-1.732-1L12.3 7.828a.5.5 0 01-.584.227q-.282-.09-.575-.155a.5.5 0 01-.393-.488V5.5a1 1 0 00-2 0v1.912a.5.5 0 01-.393.488q-.292.064-.575.155a.5.5 0 01-.58-.227l-.959-1.656a1 1 0 00-1.732 1l.958 1.659a.5.5 0 01-.095.619 6.207 6.207 0 00-.421.422.5.5 0 01-.62.1l-1.659-.963a1 1 0 10-1 1.732l1.656.955a.5.5 0 01.227.585q-.09.284-.155.576a.5.5 0 01-.488.393H1a1 1 0 000 2h1.912a.5.5 0 01.488.393q.065.292.155.576a.5.5 0 01-.227.585l-1.656.955a1 1 0 101 1.732l1.659-.958a.5.5 0 01.62.095c.133.147.274.288.421.422a.5.5 0 01.095.619l-.958 1.659a1 1 0 001.732 1l.959-1.656a.5.5 0 01.584-.227q.282.09.575.155a.5.5 0 01.393.488V23a1 1 0 002 0v-1.912a.5.5 0 01.393-.488q.292-.065.575-.155a.5.5 0 01.584.227l.957 1.656a1 1 0 001.732-1l-.958-1.659a.5.5 0 01.1-.619c.147-.134.288-.275.421-.422a.5.5 0 01.62-.095l1.659.958a.992.992 0 00.5.134 1 1 0 00.5-1.866l-1.663-.959a.5.5 0 01-.227-.585q.09-.284.155-.576a.5.5 0 01.488-.393H18.5a1 1 0 000-2zm-10.25 4.5a2 2 0 112-2 2 2 0 01-2 2zm3-5a1 1 0 111-1 1 1 0 01-1 1zm1.5 3a.5.5 0 11.5-.5.5.5 0 01-.5.5zM23.625 5.351l-.433-.25a.5.5 0 01-.246-.5 2.728 2.728 0 000-.707.5.5 0 01.246-.5l.433-.25a.75.75 0 00-.75-1.3l-.434.25a.5.5 0 01-.554-.036 2.787 2.787 0 00-.611-.353.5.5 0 01-.307-.462V.75a.75.75 0 00-1.5 0v.5a.5.5 0 01-.307.462 2.81 2.81 0 00-.611.353A.5.5 0 0118 2.1l-.435-.25a.75.75 0 00-.75 1.3l.433.251a.5.5 0 01.246.5 2.72 2.72 0 000 .706.5.5 0 01-.246.5l-.433.251a.75.75 0 00.375 1.4.739.739 0 00.375-.1L18 6.4a.5.5 0 01.553.036 2.81 2.81 0 00.611.353.5.5 0 01.307.462v.5a.75.75 0 001.5 0v-.5a.5.5 0 01.307-.462 2.787 2.787 0 00.611-.353.5.5 0 01.554-.036l.434.25a.738.738 0 00.374.1.75.75 0 00.376-1.4zm-3.406.149a1.25 1.25 0 111.25-1.25 1.252 1.252 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});