define("ember-svg-jar/inlined/app-window-search-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-search-text</title><path d=\"M21.139 18.7a5.514 5.514 0 10-1.691 1.69.25.25 0 01.311.035l2.534 2.534a1 1 0 001.414-1.414l-2.534-2.535a.25.25 0 01-.034-.31zm-1.362-1.757A3.467 3.467 0 1120 15.751a3.446 3.446 0 01-.223 1.191zM11 9a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h5A.75.75 0 0011 9zM5.25 11.751a.75.75 0 100 1.5h2.5a.75.75 0 000-1.5z\"/><path d=\"M10 20.251a1 1 0 00-1-1H3a1 1 0 01-1-1V6a.25.25 0 01.25-.25h19.5A.25.25 0 0122 6v2.75a1 1 0 001 1 1 1 0 001-1v-5a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h6a1 1 0 001-1zm-.346-17.5a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});