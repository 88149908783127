define("ember-svg-jar/inlined/photo-frame-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>photo-frame-landscape</title><path d=\"M23.972 22.207L19.817 2a2.508 2.508 0 00-2.448-2H1.5A1.5 1.5 0 00.028 1.793l2.433 11.834a.256.256 0 010 .12L.064 22.005a1 1 0 001.922.557l1.071-3.7a.25.25 0 01.485.019L4.183 22a2.508 2.508 0 002.448 2H22.5a1.5 1.5 0 001.472-1.793zM3.717 3.99a.392.392 0 01.4-.49H16.39a.643.643 0 01.6.49l3.292 16.02a.392.392 0 01-.4.49H7.61a.643.643 0 01-.6-.49z\"/><path d=\"M9 16.994L17 17a.5.5 0 00.455-.707l-2.5-5.5a.5.5 0 00-.89-.041l-1.6 2.8a.247.247 0 01-.16.12.252.252 0 01-.2-.035l-.832-.555a.5.5 0 00-.693.139l-2 2.993a.5.5 0 00.42.78zM10 10.5A1.5 1.5 0 108.5 9a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});