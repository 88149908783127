define("ember-svg-jar/inlined/hand-give-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hand-give-lock</title><path d=\"M15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.234 2.234 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20.5a1 1 0 00-1-1zM1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM22.245 5.835a.256.256 0 01-.256-.255V4.139a4.138 4.138 0 10-8.276 0V5.58a.255.255 0 01-.255.255A1.758 1.758 0 0011.7 7.591v6.9a1.757 1.757 0 001.756 1.755h8.787A1.757 1.757 0 0024 14.5V7.6a1.758 1.758 0 00-1.755-1.765zm-4.394 7.09a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm2.138-7.59a.5.5 0 01-.5.5h-3.276a.5.5 0 01-.5-.5v-1.2a2.138 2.138 0 114.276 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});