define("ember-svg-jar/inlined/pollution-co2-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-co2-message</title><path d=\"M20.773 18.108a2.179 2.179 0 00.477-1.358 1.75 1.75 0 00-3.5 0 .75.75 0 001.5 0c0-.276.5-.276.5 0a.679.679 0 01-.148.421l-1.687 2.11A.75.75 0 0018.5 20.5h2a.75.75 0 000-1.5.212.212 0 01-.165-.344zM18.25 8.75a3 3 0 00-6 0v4a3 3 0 006 0zm-2 4a1 1 0 01-2 0v-4a1 1 0 012 0zM10.25 7.75a1 1 0 000-2 5 5 0 000 10 1 1 0 000-2 3 3 0 010-6z\"/><path d=\"M12 .75C5.383.75 0 5.348 0 11a9.305 9.305 0 002.776 6.563.5.5 0 01.083.609L1.2 21.012a1.488 1.488 0 001.873 2.117l5.267-2.256a.5.5 0 01.32-.026 13.994 13.994 0 007.219-.147 1 1 0 00-.582-1.911 12.022 12.022 0 01-6.584 0 1.018 1.018 0 00-.684.038l-3.622 1.555a.249.249 0 01-.286-.064.252.252 0 01-.029-.292l1.1-1.891a.993.993 0 00-.22-1.269A7.559 7.559 0 012 11c0-4.549 4.486-8.25 10-8.25 6.4 0 11.136 4.9 9.766 10.027A.991.991 0 0022.484 14a1 1 0 001.214-.709A8.775 8.775 0 0024 11C24 5.348 18.617.75 12 .75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});