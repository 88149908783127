define("ember-svg-jar/inlined/equalizer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equalizer-1</title><path d=\"M24 2A1.5 1.5 0 0022.5.5h-21A1.5 1.5 0 000 2v20a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 22zm-6 16.5a1 1 0 010-2h.5a.25.25 0 00.25-.25V9a.75.75 0 011.5 0v7.25a.25.25 0 00.25.25h.5a1 1 0 010 2h-.5a.25.25 0 00-.25.25V21a.75.75 0 01-1.5 0v-2.25a.25.25 0 00-.25-.25zm-7.5-4a1 1 0 010-2h.5a.25.25 0 00.25-.25V9a.75.75 0 011.5 0v3.25a.25.25 0 00.25.25h.5a1 1 0 010 2H13a.25.25 0 00-.25.25V21a.75.75 0 01-1.5 0v-6.25a.25.25 0 00-.25-.25zM3.5 5a1 1 0 010-2h17a1 1 0 010 2zM3 18.5a1 1 0 010-2h.5a.25.25 0 00.25-.25V9a.75.75 0 011.5 0v7.25a.25.25 0 00.25.25H6a1 1 0 010 2h-.5a.25.25 0 00-.25.25V21a.75.75 0 01-1.5 0v-2.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});