define("ember-svg-jar/inlined/beard-style-mustache-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beard-style-mustache-1</title><path d=\"M20.186 4.626a6.308 6.308 0 00-5.1-1.358c-.136.011-.257.022-.36.028a4.428 4.428 0 00-2.422.916.5.5 0 01-.6 0A4.428 4.428 0 009.276 3.3c-.1-.006-.224-.017-.36-.028a6.321 6.321 0 00-5.1 1.358C1.792 6.419.683 9.916.521 15.022a1 1 0 00.6.952 1.031 1.031 0 00.4.082 1 1 0 00.708-.3A13.59 13.59 0 005.408 9.8a.5.5 0 01.234-.31.5.5 0 01.386-.05 9.369 9.369 0 005.8-.233.5.5 0 01.338 0 9.373 9.373 0 005.8.233.5.5 0 01.619.36 13.606 13.606 0 003.177 5.958 1 1 0 001.71-.737c-.155-5.105-1.263-8.602-3.286-10.395z\"/><path d=\"M14.532 11.806a3.524 3.524 0 01-1.467-.794.843.843 0 00-.565-.212h-1a.842.842 0 00-.563.215 3.625 3.625 0 01-1.472.794.855.855 0 00-.692.868C8.772 14.887 10.34 20.8 12 20.8s3.226-5.9 3.226-8.112a.861.861 0 00-.694-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});