define("ember-svg-jar/inlined/single-woman-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-monitor</title><path d=\"M24 2.5A1.5 1.5 0 0022.5 1h-21A1.5 1.5 0 000 2.5V17a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H4a1 1 0 000 2h16a1 1 0 000-2h-6.5a.5.5 0 01-.5-.5V19a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 17zM8.108 16a.5.5 0 01-.388-.815A5.689 5.689 0 0112.11 13a5.68 5.68 0 014.39 2.185.5.5 0 01-.388.815zm8.506-4.855a.633.633 0 01-1.064.686 4.087 4.087 0 01-.517-1.206.25.25 0 00-.416-.117 3.595 3.595 0 01-5.018 0 .249.249 0 00-.416.116 4.126 4.126 0 01-.517 1.208.633.633 0 01-1.066-.687 3.512 3.512 0 00.5-1.934v-1.7a4.013 4.013 0 118.025 0v1.7a3.521 3.521 0 00.489 1.934z\"/><path d=\"M15.306 7.9a.5.5 0 00-.4-.585 4.348 4.348 0 01-2.406-1.38s-.083-.18-.394-.193a.424.424 0 00-.393.193 4.345 4.345 0 01-2.407 1.381.5.5 0 00-.4.585.5.5 0 00.585.4A5.3 5.3 0 0011.94 7.09a.25.25 0 01.327 0A5.3 5.3 0 0014.72 8.3a.505.505 0 00.586-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});