define("ember-svg-jar/inlined/discount-30", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-30</title><path d=\"M11.97 10.285a.749.749 0 00-.748.748v2a.748.748 0 001.5 0v-2a.749.749 0 00-.752-.748z\"/><path d=\"M24 12a4.357 4.357 0 00-2.272-3.86.251.251 0 01-.12-.29 4.416 4.416 0 00-5.459-5.459.25.25 0 01-.289-.12 4.416 4.416 0 00-7.72 0 .248.248 0 01-.289.12A4.416 4.416 0 002.392 7.85a.251.251 0 01-.12.29 4.415 4.415 0 000 7.72.251.251 0 01.12.29 4.416 4.416 0 005.459 5.459.25.25 0 01.289.12 4.416 4.416 0 007.72 0 .248.248 0 01.289-.12 4.416 4.416 0 005.459-5.459.251.251 0 01.12-.29A4.357 4.357 0 0024 12zm-8.041 3.272a.749.749 0 01-.59-1.208l3.492-4.488a.748.748 0 111.18.919l-3.491 4.488a.745.745 0 01-.591.289zm0-5.611a.873.873 0 11-.873.873.873.873 0 01.874-.873zm3.491 3.491a.873.873 0 11-.873.873.873.873 0 01.874-.873zm-9.726-.124v-2a2.245 2.245 0 014.489 0v2a2.245 2.245 0 01-4.489 0zm-.5.247a2 2 0 01-1.985 1.994h-.755a1.748 1.748 0 01-1.749-1.746.748.748 0 011.5 0 .25.25 0 00.25.25h.752a.5.5 0 000-.994.748.748 0 110-1.5.5.5 0 000-.994h-.755a.249.249 0 00-.247.249.748.748 0 01-1.5 0 1.747 1.747 0 011.749-1.745h.758a1.992 1.992 0 011.649 3.1.251.251 0 000 .278 1.982 1.982 0 01.336 1.108z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});