define("ember-svg-jar/inlined/multiple-actions-alarm.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-alarm</title><path d=\"M17.753 9.505a.25.25 0 00.131-.456 5.995 5.995 0 00-5.861-.515.5.5 0 00-.182.771 7.442 7.442 0 011.089 1.818.5.5 0 00.817.158 5.968 5.968 0 014.006-1.776z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.623 14a.377.377 0 00.275-.119.372.372 0 00.1-.28A6.025 6.025 0 000 13.333.6.6 0 00.6 14z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.659 19.884a4.086 4.086 0 01-1.159-2.717V15.5a4.5 4.5 0 00-9 0v1.667a4.086 4.086 0 01-1.159 2.717l-.241.316a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.194a.5.5 0 00-.4-.194h-2.568a.5.5 0 00-.484.625 1.827 1.827 0 003.54 0 .5.5 0 00-.088-.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});