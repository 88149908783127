define("ember-svg-jar/inlined/style-one-pin-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-cart</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.5 2.5 0 011.218 1.222l1.873 4.076a1 1 0 001.817 0l1.876-4.08A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zM9 15.5a1 1 0 111-1 1 1 0 01-1 1zm4.5 0a1 1 0 111-1 1 1 0 01-1 1zm.979-3.356A.5.5 0 0114 12.5H8.5a.5.5 0 01-.472-.335l-1.75-5A.5.5 0 016.75 6.5h7.671a.25.25 0 00.24-.178l.8-2.666a.75.75 0 111.437.431z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});