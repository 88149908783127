define("ember-svg-jar/inlined/shipment-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-next</title><path d=\"M6.75 4.75A.25.25 0 007 4.5V1.25A.25.25 0 006.75 1H5.164a1.511 1.511 0 00-1.064.439L1.219 4.323a.25.25 0 00.181.427zM15.068 11.811a.248.248 0 00.182-.24V6.5a.25.25 0 00-.25-.25H.5a.249.249 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h7.726a.249.249 0 00.235-.167 8.01 8.01 0 015.357-5.022zM8.5 4.5a.249.249 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4 1.439A1.511 1.511 0 0010.335 1H8.75a.249.249 0 00-.25.25zM17.75 13a7.008 7.008 0 00-7 7v2a1 1 0 002 0v-2a5.006 5.006 0 015-5H18a.249.249 0 01.25.25v2.25a.5.5 0 00.807.395l4.408-3.429.092-.071a.5.5 0 000-.79l-4.5-3.5a.5.5 0 00-.807.395v2.25A.249.249 0 0118 13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});