define("ember-svg-jar/inlined/style-three-pin-bow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-bow</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm7.764 10.9l-1.857 1.393a.5.5 0 01-.3.1.5.5 0 01-.5-.5V11.5a.5.5 0 00-.5-.5H6.885a.25.25 0 00-.188.415l3.042 3.463a.5.5 0 00.482.159 4.648 4.648 0 003.33-2.849.465.465 0 01.433-.295h1.005a.464.464 0 01.442.607A6.507 6.507 0 019.25 17a.928.928 0 01-.929-.929.875.875 0 01.167-.514.5.5 0 00-.03-.622L5.39 11.442a1.458 1.458 0 010-1.883l3.068-3.494a.5.5 0 00.03-.622.875.875 0 01-.167-.514A.928.928 0 019.25 4a6.507 6.507 0 016.181 4.5.464.464 0 01-.442.607h-1.005a.465.465 0 01-.433-.295 4.648 4.648 0 00-3.33-2.849.5.5 0 00-.482.159L6.7 9.585a.25.25 0 00.185.415h9.722a.5.5 0 00.5-.5v-.393a.5.5 0 01.8-.4l1.857 1.393a.5.5 0 010 .8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});