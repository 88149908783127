define("ember-svg-jar/inlined/video-game-mario-flower-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-flower-2</title><path d=\"M24 7a7.008 7.008 0 00-7-7H7a7 7 0 000 14h4v9a1 1 0 002 0v-9h4a7.008 7.008 0 007-7zM7.5 10.5a3.5 3.5 0 010-7h9a3.5 3.5 0 010 7z\"/><path d=\"M8.309 19.382A2.485 2.485 0 006.073 18H3a1 1 0 00-.895 1.447l1.586 3.171A2.485 2.485 0 005.927 24H9a1 1 0 00.9-1.447zM21 18h-3.073a2.485 2.485 0 00-2.236 1.382l-1.586 3.171A1 1 0 0015 24h3.073a2.485 2.485 0 002.236-1.382l1.591-3.171A1 1 0 0021 18zM9.5 5a1 1 0 00-1 1v2a1 1 0 002 0V6a1 1 0 00-1-1zM14.5 5a1 1 0 00-1 1v2a1 1 0 002 0V6a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});