define("ember-svg-jar/inlined/real-estate-action-house-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-settings</title><path d=\"M14.018 11.239a.5.5 0 00.592-.341v-.011a3.275 3.275 0 016.268 0v.012a.5.5 0 00.591.34 3.255 3.255 0 012.125.229.479.479 0 00.24-.831L13.028.412a1.5 1.5 0 00-2.06 0L.157 10.637A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h5.984a.5.5 0 00.5-.515l-.032-1.089a.513.513 0 00-.059-.221 3.272 3.272 0 01.488-3.786l.016-.017a.5.5 0 000-.68l-.016-.017a3.282 3.282 0 013.138-5.434z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.529 17.344a.464.464 0 010-.626l.987-1.064a1.783 1.783 0 00-1.7-2.953l-1.411.327a.454.454 0 01-.534-.312l-.423-1.391a1.776 1.776 0 00-3.4 0l-.424 1.391a.448.448 0 01-.533.312L13.68 12.7a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.452.452 0 01.532.31l.424 1.393a1.776 1.776 0 003.4 0l.423-1.393a.45.45 0 01.534-.31l1.411.327a1.783 1.783 0 001.7-2.953zm-4.781 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});