define("ember-svg-jar/inlined/gesture-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-scroll</title><path d=\"M9.033 2.163A.751.751 0 009.5 3.5h1.25a.25.25 0 01.25.25v1.5a1 1 0 102 0v-1.5a.25.25 0 01.25-.25h1.25a.751.751 0 00.469-1.336l-2.5-2a.752.752 0 00-.938 0zM14.5 20.5h-1.25a.25.25 0 01-.25-.25v-1.5a1 1 0 00-2 0v1.5a.25.25 0 01-.25.25H9.5a.751.751 0 00-.469 1.335l2.5 2a.751.751 0 00.938 0l2.5-2A.75.75 0 0014.5 20.5zM11.5 15.574v-4.613a3 3 0 00-6 0v4.613a.429.429 0 00.429.426h5.142a.429.429 0 00.429-.426zM10 11.962a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.5 8a3 3 0 00-3 3v4.572a.429.429 0 00.429.428h5.142a.429.429 0 00.429-.428V11a3 3 0 00-3-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});