define("ember-svg-jar/inlined/social-media-about-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-about-me</title><path d=\"M8.89 6.53a3.63 3.63 0 00-2.6 1.1 3.58 3.58 0 00-4.19-.72 1 1 0 00-1-.9A1 1 0 000 7v10a1 1 0 102.08 0v-6.83a1.56 1.56 0 113.12 0V17a1 1 0 102.08 0v-6.83a1.56 1.56 0 113.12 0V17a1 1 0 102.08 0v-6.83a3.65 3.65 0 00-3.59-3.64zM23.7 12.42a1 1 0 00.25-.82c-.3-2.43-2.36-5-5.17-5a5.41 5.41 0 00-5.17 5V12.27A5.49 5.49 0 0018.77 18a5.13 5.13 0 004.69-3.26 1 1 0 10-1.91-.82 3.09 3.09 0 01-2.78 2 3.33 3.33 0 01-3.08-3.14h7.23a1 1 0 00.78-.36zM16 10.69a3.13 3.13 0 012.82-2 3.3 3.3 0 012.75 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});