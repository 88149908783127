define("ember-svg-jar/inlined/accounting-invoice-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>accounting-invoice-hand</title><path d=\"M8.3 16.292a1 1 0 001-1v-.328a.248.248 0 01.2-.244 2.6 2.6 0 00.413-4.962l-2.476-.99a.61.61 0 01.223-1.176h2.44a1 1 0 000-2h-.55a.25.25 0 01-.25-.25v-.25a1 1 0 00-2 0v.327a.249.249 0 01-.2.244 2.6 2.6 0 00-.413 4.962l2.477.99a.61.61 0 01-.228 1.177H6.5a1 1 0 000 2h.55a.25.25 0 01.25.25v.25a1 1 0 001 1z\"/><path d=\"M15.848 12.576a1 1 0 001.7-.712V2.292a2 2 0 00-2-2h-13a2 2 0 00-2 2v17.5a2 2 0 002 2h10.5a1 1 0 000-2H3.55a1 1 0 01-1-1v-15.5a1 1 0 011-1h11a1 1 0 011 1v8.572a1 1 0 00.3.712z\"/><path d=\"M13.028 15.638l.009.01 3.663 4.137a.249.249 0 01.063.166V22.5a2.626 2.626 0 00.306 1.071.251.251 0 00.226.142H23.2a.25.25 0 00.207-.391l-1.438-2.105a.255.255 0 01-.044-.141v-5.837c0-1.332-1.178-2.633-2.46-3.723a.252.252 0 00-.268-.036.249.249 0 00-.144.228l.023 3.954a.251.251 0 00.056.157.75.75 0 01-1.109 1.007l-3.4-3.354a.245.245 0 00-.076-.052 1.363 1.363 0 00-1.512.278 1.453 1.453 0 00-.007 1.94z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});