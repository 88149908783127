define("ember-svg-jar/inlined/security-officer-luggage-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>security-officer-luggage-check</title><path d=\"M24 16a4.005 4.005 0 00-4-4h-1.16a.5.5 0 00-.47.31l-.64 1.61a.262.262 0 01-.23.16.251.251 0 01-.23-.16l-.65-1.61a.489.489 0 00-.46-.31h-1.741a2 2 0 00-1.419.587L8.41 17.19a1.245 1.245 0 101.76 1.76l3.9-3.892a.25.25 0 01.427.177V23.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-7.75a.5.5 0 011 0v5.75a1.25 1.25 0 002.5 0z\"/><path d=\"M12 20.5H4a.5.5 0 01-.5-.5v-6.143a.25.25 0 00-.174-.238A8 8 0 01.445 11.98a.247.247 0 00-.245-.049.246.246 0 00-.164.193A2.209 2.209 0 000 12.5v6a2.5 2.5 0 002.01 2.451A.386.386 0 002 21v.5A2.5 2.5 0 004.5 24h6a2.5 2.5 0 002.5-2.5 1 1 0 00-1-1zM15.2 6.5a.25.25 0 00-.25.25v.75a2.5 2.5 0 005 .012V6.75a.25.25 0 00-.25-.25zM12.458 4.5a1 1 0 001 1h6.408a.5.5 0 00.475-.342l.33-.988a1 1 0 00-.707-1.286L14.93 1.625a.751.751 0 00-.894.965l.3.91s-.95.018-.987.021a.99.99 0 00-.891.979zM10.293 1.543L6 5.836 4.832 4.668a1 1 0 00-1.414 1.414l1.433 1.433a1.627 1.627 0 002.3 0l4.558-4.558a1 1 0 00-1.414-1.414z\"/><path d=\"M6 12a5.969 5.969 0 005.582-3.8 1 1 0 10-1.859-.734A4 4 0 116 2a1 1 0 000-2 6 6 0 000 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});