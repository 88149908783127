define("ember-svg-jar/inlined/body-care-toothbrush-paste", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>body-care-toothbrush-paste</title><path d=\"M9.5 21h-6a.5.5 0 00-.5.5v1A1.5 1.5 0 004.5 24h4a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5zM21.5 0a1.489 1.489 0 00-1.288.751.5.5 0 01-.433.249H16.5a.75.75 0 000 1.5h3a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-3a.75.75 0 000 1.5h3a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-3a.75.75 0 000 1.5h3a.5.5 0 01.5.5v13.5a1.5 1.5 0 003 0v-21A1.5 1.5 0 0021.5 0zM11 0H2a1 1 0 00-1 1.105l.106.95a.5.5 0 00.5.445h9.783a.5.5 0 00.5-.445L12 1.1A1 1 0 0011 0zM11.113 4H1.886a.5.5 0 00-.5.555L3 19.055a.5.5 0 00.5.445h6a.5.5 0 00.5-.445l1.613-14.5a.5.5 0 00-.5-.555z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});