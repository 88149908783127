define("ember-svg-jar/inlined/database-refresh-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-refresh-alternate</title><path d=\"M8.056 22.035a.949.949 0 001.024-1.3l-.018-.048a.981.981 0 00-.762-.627c-3.3-.499-5.289-1.76-5.289-2.685v-.725a.25.25 0 01.371-.218 13.639 13.639 0 004.106 1.362.982.982 0 001.152-.859v-.026a.975.975 0 00-.791-1.071c-3.03-.541-4.841-1.727-4.841-2.615V12.5a.25.25 0 01.371-.218 15.265 15.265 0 005.644 1.577 1 1 0 00.927-.459l.015-.02a.976.976 0 00-.725-1.5c-3.859-.38-6.229-1.792-6.229-2.808v-.725a.25.25 0 01.371-.218 17.254 17.254 0 007.909 1.7 17.248 17.248 0 007.912-1.7.249.249 0 01.371.213 2.561 2.561 0 01-.111 1.114 1.022 1.022 0 00.506 1.306l.029.014a1.073 1.073 0 001.571-.943V4.922c0-3.188-5.3-4.908-10.279-4.908S1.024 1.731 1.013 4.912v12.463c-.002 2.494 3.243 4.086 7.043 4.66zm3.235-20.021c5.05 0 8.273 1.719 8.278 2.905v.008c-.011 1.185-3.233 2.9-8.278 2.9S3.012 6.106 3.012 4.922s3.225-2.908 8.279-2.908z\"/><path d=\"M20.909 13.169a.25.25 0 01-.348.006 6.249 6.249 0 10.619 8.464 1 1 0 00-1.563-1.248 4.25 4.25 0 11-3.322-6.9 4.178 4.178 0 012.817 1.1.249.249 0 01.01.362l-1.32 1.32a.489.489 0 00.345.834H22.5a.489.489 0 00.488-.489v-4.35a.488.488 0 00-.834-.345l-.212.212z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});