define("ember-svg-jar/inlined/currency-dollar-decrease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-dollar-decrease</title><path d=\"M24 7a7 7 0 10-7 7 7.009 7.009 0 007-7zm-7.75 4.25A.25.25 0 0016 11h-.625a.75.75 0 010-1.5h2.2a.7.7 0 00.261-1.356l-2.235-.9a2.2 2.2 0 01.439-4.206.248.248 0 00.21-.238v-.4a.75.75 0 011.5 0v.35A.25.25 0 0018 3h.625a.75.75 0 010 1.5h-2.2a.7.7 0 00-.261 1.356l2.235.9a2.2 2.2 0 01-.439 4.206.248.248 0 00-.207.246v.4a.75.75 0 01-1.5 0zM22.975 23.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.354-.854l1.415-1.414a.252.252 0 00.073-.182.249.249 0 00-.08-.179l-4.583-4.225a.25.25 0 00-.326-.012l-2.9 2.317a2.156 2.156 0 01-2.772-.071l-.219-.194L.42 12.685a1.25 1.25 0 011.66-1.87l7.155 6.36a.25.25 0 00.322.009l2.9-2.324a2.163 2.163 0 012.8.1l5.047 4.653a.249.249 0 00.346-.007l1.462-1.462a.5.5 0 01.363-.144.512.512 0 01.191.038.5.5 0 01.309.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});