define("ember-svg-jar/inlined/electronics-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-switch</title><path d=\"M21.5 15a1.5 1.5 0 00-1.5-1.5h-1.25a.25.25 0 01-.25-.25V11.5A1.5 1.5 0 0017 10H7a1.5 1.5 0 00-1.5 1.5v1.75a.25.25 0 01-.25.25H4A1.5 1.5 0 002.5 15v2.5A1.5 1.5 0 004 19h1.75a.25.25 0 01.25.25V23a1 1 0 002 0v-3.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 102 0v-3.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V23a1 1 0 102 0v-3.75a.25.25 0 01.25-.25H20a1.5 1.5 0 001.5-1.5zM12.351 8a.251.251 0 00.246-.3l-1.121-5.8v-.015a2.5 2.5 0 10-4.845 1.247v.01L8.01 7.816A.25.25 0 008.25 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});