define("ember-svg-jar/inlined/music-basket-buy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-basket-buy</title><path d=\"M24 10.5A1.5 1.5 0 0022.5 9h-2.117a.249.249 0 01-.219-.129L15.593.643a1.25 1.25 0 10-2.186 1.214l3.762 6.772a.25.25 0 01-.218.371h-9.9a.25.25 0 01-.218-.371l3.762-6.772A1.25 1.25 0 108.407.643L3.836 8.871A.249.249 0 013.617 9H1.5a1.5 1.5 0 00-.563 2.89.25.25 0 01.153.191l1.639 9.83A2.493 2.493 0 005.194 24h13.613a2.493 2.493 0 002.465-2.089l1.638-9.83a.25.25 0 01.153-.191A1.5 1.5 0 0024 10.5zm-9.84 3.887a.245.245 0 00-.205-.054l-3.409 1.1c-.21.044-.3.148-.3.268v3.8A1.75 1.75 0 118.5 17.75h.086a.154.154 0 00.114-.04.159.159 0 00.05-.11v-1.9a1.753 1.753 0 011.428-1.72l3.409-1.1a1.78 1.78 0 011.531.35 1.742 1.742 0 01.632 1.347V18A1.75 1.75 0 1114 16.25a.249.249 0 00.25-.25v-1.421a.248.248 0 00-.09-.192z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});