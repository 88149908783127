define("ember-svg-jar/inlined/controls-movie-pause", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-pause</title><path d=\"M9.5 17.5a8 8 0 0112.072-6.884.25.25 0 00.377-.215V6a.5.5 0 00-.5-.5H.5A.5.5 0 000 6v10a2.5 2.5 0 002.5 2.5h6.784a.25.25 0 00.249-.273A7.939 7.939 0 019.5 17.5zM4.487 3.573A.25.25 0 004.664 4h3.175a.251.251 0 00.177-.073l3.5-3.5a.25.25 0 00-.18-.427H8.161a.251.251 0 00-.177.073zM16.487 3.573a.25.25 0 00.177.427h4.785a.5.5 0 00.5-.5v-1A2.5 2.5 0 0020.143.1a.251.251 0 00-.243.062zM.5 4h1.339a.251.251 0 00.177-.073l3.5-3.5A.25.25 0 005.336 0H2.5A2.5 2.5 0 000 2.5v1a.5.5 0 00.5.5zM10.487 3.573a.25.25 0 00.177.427h3.175a.251.251 0 00.177-.073l3.5-3.5a.25.25 0 00-.18-.427h-3.175a.251.251 0 00-.177.073z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.75 9.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75zM15 15.25a.75.75 0 011.5 0v4.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});