define("ember-svg-jar/inlined/charging-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charging-flash</title><path d=\"M7.5 18A5.773 5.773 0 012 12a5.773 5.773 0 015.5-6 1 1 0 000-2A7.771 7.771 0 000 12a7.771 7.771 0 007.5 8 1 1 0 000-2zM16.5 4a1 1 0 000 2 5.773 5.773 0 015.5 6 5.773 5.773 0 01-5.5 6 1 1 0 000 2 7.771 7.771 0 007.5-8 7.771 7.771 0 00-7.5-8z\"/><path d=\"M14 5a.5.5 0 00-.891-.312l-5.9 7.37a.875.875 0 00.666 1.442H9.75a.25.25 0 01.25.25V19a.5.5 0 00.891.312l5.9-7.37a.875.875 0 00-.667-1.442H14.25a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});