define("ember-svg-jar/inlined/brain-open-skill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brain-open-skill</title><path d=\"M2.66 8.971a.538.538 0 00.594-.543V8a1.777 1.777 0 011.7-1.735.7.7 0 00.117.092 3.252 3.252 0 001.476.352 3.3 3.3 0 001.823-.554.75.75 0 00-.832-1.248 1.746 1.746 0 01-1.611.177A2.577 2.577 0 018.4 3.25a2.629 2.629 0 01.577.068.741.741 0 00.8-.338A2.585 2.585 0 0114 2.713a5.16 5.16 0 01-3.236 3.075A.75.75 0 0011 7.25a.778.778 0 00.237-.038 6.786 6.786 0 002.12-1.152L14.4 7.45a.75.75 0 101.2-.9l-1.178-1.571a7.089 7.089 0 00.965-1.7c.068 0 .142-.024.208-.024a2.555 2.555 0 012.564 2.311.773.773 0 00.774.689A1.786 1.786 0 0120.75 8c0 .092 0 .3-.006.54a.437.437 0 00.483.442l.152-.015A.972.972 0 0022.25 8a3.27 3.27 0 00-2.7-3.188 4.065 4.065 0 00-4.358-3.041 4.114 4.114 0 00-6.388 0 4.057 4.057 0 00-4.355 3.036A3.273 3.273 0 001.75 8v.007a.971.971 0 00.871.96zM22.8 10.323a.5.5 0 00-.446-.323.4.4 0 00-.077.007A86.313 86.313 0 0112 10.621a86.313 86.313 0 01-10.277-.614.391.391 0 00-.076-.007.5.5 0 00-.45.323 3.8 3.8 0 001.7 4.487 1.115 1.115 0 01.638.831A8.218 8.218 0 006 20.083V23.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-3.417a8.231 8.231 0 002.468-4.442 1.115 1.115 0 01.638-.831 3.8 3.8 0 001.694-4.487z\"/><path d=\"M19.16 8.856a.748.748 0 00-.3-1.015 1.455 1.455 0 01-.624-1 .75.75 0 00-1.466.318 2.923 2.923 0 001.376 2 .73.73 0 00.316.081c.013 0 .027.01.041.01s.044-.016.068-.019a.738.738 0 00.589-.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});