define("ember-svg-jar/inlined/design-document-dxf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-document-dxf-1</title><path d=\"M5.125 12.5v6a.252.252 0 00.312.246 3.376 3.376 0 000-6.486.252.252 0 00-.312.24z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-18 20.125a.625.625 0 01-.625-.625v-8a.625.625 0 01.625-.625 4.625 4.625 0 010 9.25zm10-8H12a.875.875 0 00-.875.875v1.625a.25.25 0 00.25.25H13a.625.625 0 010 1.25h-1.625a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0V13A2.124 2.124 0 0112 10.875h2.5a.625.625 0 010 1.25zm6.559 7.1a.625.625 0 11-1.118.558l-1.217-2.435a.25.25 0 00-.448 0l-1.217 2.435a.625.625 0 11-1.118-.558l1.8-3.609a.252.252 0 000-.224l-1.8-3.609a.625.625 0 111.118-.558l1.217 2.435a.25.25 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});