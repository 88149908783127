define("ember-svg-jar/inlined/road-sign-width-4m-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-width-4m-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M5.53 10.47a.75.75 0 00-1.28.53v2a.75.75 0 001.28.53l1-1a.749.749 0 000-1.06zM19.287 10.307a.749.749 0 00-.817.163l-1 1a.749.749 0 000 1.06l1 1a.746.746 0 00.53.22.75.75 0 00.75-.75v-2a.75.75 0 00-.463-.693zM10.5 9.75a2.752 2.752 0 00-2.75 2.75v.5a.75.75 0 00.75.75h1a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-4a.75.75 0 00-.75-.75zm-1 2.5a.166.166 0 01-.154-.231q.024-.056.051-.108A.188.188 0 019.75 12a.25.25 0 01-.25.25zM16.2 9.777a.749.749 0 00-.843.337l-.643 1.071a.25.25 0 01-.428 0l-.643-1.071a.75.75 0 00-1.393.386v4a.75.75 0 001.5 0v-1.085a.058.058 0 01.107-.029.78.78 0 001.286 0 .058.058 0 01.107.029V14.5a.75.75 0 001.5 0v-4a.75.75 0 00-.55-.723z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});