define("ember-svg-jar/inlined/diamond-shine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diamond-shine</title><path d=\"M20 8.992a1 1 0 00.8-.4l1.5-2a1 1 0 10-1.6-1.2l-1.5 2a1 1 0 00.8 1.6zM20.8 15.392a1 1 0 00-1.6 1.2l1.5 2a1 1 0 001.6-1.2zM23 10.992h-2.5a1 1 0 000 2H23a1 1 0 000-2zM3.3 5.392a1 1 0 00-1.6 1.2l1.5 2a1 1 0 101.6-1.2zM3.2 15.392l-1.5 2a1 1 0 101.6 1.2l1.5-2a1 1 0 00-1.6-1.2zM4.5 11.992a1 1 0 00-1-1H1a1 1 0 000 2h2.5a1 1 0 001-1zM17.087 17.787l-4.752 2.376h-.021a.7.7 0 01-.628 0h-.021l-4.752-2.376a.285.285 0 00-.413.255 1 1 0 00.26.673l4.5 4.949a1 1 0 001.48 0l4.5-4.95a1 1 0 00.259-.672.285.285 0 00-.413-.255z\"/><path d=\"M6.776 16.042l3.75 1.875a.5.5 0 00.724-.448V8.737a.5.5 0 00-.262-.44L6.869 6.071a.25.25 0 00-.369.22v9.3a.5.5 0 00.276.451zM7.908 4.928l3.854 2.083a.5.5 0 00.476 0l3.854-2.083a.5.5 0 00.132-.776L12.741.32a1.035 1.035 0 00-1.481 0L7.776 4.152a.5.5 0 00.132.776zM17.131 6.071L13.012 8.3a.5.5 0 00-.262.44v8.732a.5.5 0 00.724.448l3.75-1.875a.5.5 0 00.276-.448v-9.3a.25.25 0 00-.369-.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});