define("ember-svg-jar/inlined/heavy-equipment-lift-hook-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heavy-equipment-lift-hook-box</title><path d=\"M11 18.835a2 2 0 000 4h11a2 2 0 000-4zm10 1.25a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-4.5 0a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-3.75.75a.75.75 0 11-.75-.75.75.75 0 01.75.75zM21.5 11.335h-2.25a.25.25 0 01-.25-.25v-2.25a2.5 2.5 0 00-2.5-2.5H13a1.3 1.3 0 00-1.151.775l-1.812 4.449a.5.5 0 00-.037.188v4.088a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5zm-8.982-.5l.762-2.161a.467.467 0 01.447-.339h2.808A.466.466 0 0117 8.8v2.161a.466.466 0 01-.465.467h-3.57a.467.467 0 01-.447-.593z\"/><path d=\"M7.534 13.335H5.25V6.279l3.678 3.153a.251.251 0 00.222.053.248.248 0 00.172-.149l1.137-2.792a2.656 2.656 0 01.556-.846.251.251 0 00.067-.209.248.248 0 00-.123-.182L4.052 1.361a1.49 1.49 0 00-1.71 2.425l.908.779v8.77H.966A.968.968 0 000 14.3v5.566a.968.968 0 00.966.967h6.568a.968.968 0 00.966-.967V14.3a.968.968 0 00-.966-.965z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});