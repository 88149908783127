define("ember-svg-jar/inlined/book-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-open</title><path d=\"M23.391 1.568a1.959 1.959 0 00-1.438-.56c-3.841.114-7.707.868-9.656 2.308a.5.5 0 01-.594 0c-1.949-1.44-5.815-2.194-9.656-2.308a1.951 1.951 0 00-1.438.56A2.021 2.021 0 000 3.009v14.5a2 2 0 001.956 2C7.923 19.678 11 21.327 11 21.993a1 1 0 002 0c0-.666 3.077-2.315 9.044-2.486a2 2 0 001.956-2V3.009a2.021 2.021 0 00-.609-1.441zM2.478 17.526a.5.5 0 01-.478-.5l-.012-13.5a.5.5 0 01.523-.5C8.113 3.281 11 4.849 11 5.493v13.085a.5.5 0 01-.7.458 22.937 22.937 0 00-7.822-1.51zm19.509-.5a.5.5 0 01-.479.5 22.909 22.909 0 00-7.808 1.51.5.5 0 01-.7-.458V5.493c0-.643 2.886-2.211 8.477-2.464a.5.5 0 01.523.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});