define("ember-svg-jar/inlined/breakfast-cereal-bowl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-cereal-bowl</title><path d=\"M12 3C6.183 3 0 4.928 0 8.5v1A11.771 11.771 0 0012 21 11.771 11.771 0 0024 9.5v-1C24 4.928 17.817 3 12 3zm0 2c6.2 0 10 2.039 10 3.5S18.2 12 12 12 2 9.961 2 8.5 5.8 5 12 5z\"/><path d=\"M10.142 10.52l-.672-.672a.75.75 0 111.06-1.06l.672.672a.75.75 0 11-1.06 1.06zM5.47 9.326a.75.75 0 010-1.061l.672-.672A.75.75 0 017.2 8.653l-.672.672a.75.75 0 01-1.058.001zM13.142 8.192l-.672-.672a.75.75 0 011.06-1.06l.672.672a.75.75 0 11-1.06 1.06zM16.8 10.021a.75.75 0 010-1.061l.672-.672a.75.75 0 111.06 1.06l-.672.672a.75.75 0 01-1.06.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});