define("ember-svg-jar/inlined/multiple-actions-mobilephone.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-mobilephone</title><path d=\"M14.489 8a5.974 5.974 0 00-2.466.534.5.5 0 00-.182.771 7.54 7.54 0 011.118 1.884.5.5 0 00.463.311h.029a.344.344 0 00.315-.206A2.964 2.964 0 0116.474 9.5h.31a.5.5 0 00.209-.955A6 6 0 0014.489 8z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.275-.119A.372.372 0 0012 13.6z\"/><ellipse cx=\"5.487\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M22.526 11.009h-6.052A1.493 1.493 0 0015 12.518v9.973A1.493 1.493 0 0016.474 24h6.052A1.493 1.493 0 0024 22.491v-9.973a1.493 1.493 0 00-1.474-1.509zM17 13.509a.5.5 0 01.5-.5h4a.5.5 0 01.5.5V21.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});