define("ember-svg-jar/inlined/transportation-ticket-bus-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-bus-transfer</title><path d=\"M6.221 9.774v.245a.75.75 0 101.5 0V9.5a.251.251 0 01.125-.216 1.745 1.745 0 00.876-1.509v-6A1.753 1.753 0 006.972.024h-5a1.752 1.752 0 00-1.75 1.75v6A1.743 1.743 0 001.1 9.282a.251.251 0 01.124.217v.52a.75.75 0 001.5 0v-.245a.25.25 0 01.25-.25h3a.25.25 0 01.247.25zm-4.5-8a.246.246 0 01.25-.25h5a.247.247 0 01.25.25v2.75a.25.25 0 01-.25.25h-5a.249.249 0 01-.25-.25zm.749 5.745a.75.75 0 11.75-.75.75.75 0 01-.749.75zm3.25-.75a.75.75 0 11.75.75.75.75 0 01-.749-.75zM11.221 18.019A5.493 5.493 0 016.733 15.7a.249.249 0 01.124-.381l.1-.033a.751.751 0 00.094-1.386l-1.9-.944a.75.75 0 00-1.006.337L3.2 15.2a.751.751 0 00.672 1.084.757.757 0 00.239-.039l.324-.109a.249.249 0 01.3.112 7.474 7.474 0 006.489 3.775 1 1 0 000-2zM13.476 7.875a.75.75 0 00.547 1.263h.024l2.121-.068a.751.751 0 00.725-.775l-.068-2.12a.75.75 0 00-1.3-.488l-.28.3a.252.252 0 01-.308.045 7.393 7.393 0 00-3.719-1.012 1 1 0 000 2 5.464 5.464 0 012.221.467.219.219 0 01.071.35zM23.628 22.776l-.8-1.06a.252.252 0 01.013-.317 1.724 1.724 0 00.445-1.155v-6a1.752 1.752 0 00-1.75-1.75h-6a1.753 1.753 0 00-1.75 1.75v6a1.724 1.724 0 00.446 1.155.252.252 0 01.013.317l-.793 1.057a.75.75 0 101.2.9l1.185-1.579a.249.249 0 01.2-.1h5a.249.249 0 01.2.1l1.187 1.582a.75.75 0 001.2-.9zm-7.086-2.532a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm1.249-3a.249.249 0 01-.25.25h-6a.25.25 0 01-.25-.25v-3a.253.253 0 01.25-.25h6a.253.253 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});