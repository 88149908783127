define("ember-svg-jar/inlined/chef-gear-cookies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-cookies</title><path d=\"M7.985 13.294a8.44 8.44 0 001.491.134 7.564 7.564 0 001.7-.189.25.25 0 00.072-.458A7.814 7.814 0 019.826 11.7a5.232 5.232 0 01-1.7-4.663 4.58 4.58 0 01.714-1.771.251.251 0 00-.187-.389 7.255 7.255 0 00-3.5.526A3.714 3.714 0 002.8 8.077c-.407 2.291 1.917 4.631 5.185 5.217z\"/><path d=\"M14.781 12.514a8.633 8.633 0 001.514.136 7 7 0 002.811-.554 3.716 3.716 0 002.346-2.674c.41-2.291-1.914-4.631-5.182-5.217S10.009 5.007 9.6 7.3a3.718 3.718 0 001.27 3.32 7.546 7.546 0 003.911 1.894zm1.712-6.061a.75.75 0 01.959.453l.576 1.6a.75.75 0 01-1.412.507l-.576-1.6a.75.75 0 01.453-.96zm-2.952.537a.749.749 0 01.959.452L15.059 9a.75.75 0 01-1.412.508l-.559-1.555a.751.751 0 01.453-.963z\"/><path d=\"M23.5 11.43a.5.5 0 00-.5.5c0 1.252-4.185 3-11 3s-11-1.748-11-3a.5.5 0 00-1 0v.5c0 4.135 5.383 7.5 12 7.5s12-3.365 12-7.5v-.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});