define("ember-svg-jar/inlined/medical-app-smartphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-smartphone-1</title><path d=\"M13.677 13.98a8 8 0 01-.942-.426A.5.5 0 0012 14v4.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.015a.5.5 0 00.5-.47 7.914 7.914 0 01.214-1.411A.5.5 0 008.242 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.052a.5.5 0 00-.323-.468z\"/><path d=\"M16.5 0A6.5 6.5 0 1023 6.5 6.508 6.508 0 0016.5 0zm4 7.5a.333.333 0 01-.333.333h-1.834a.5.5 0 00-.5.5v1.834a.333.333 0 01-.333.333h-2a.333.333 0 01-.333-.333V8.333a.5.5 0 00-.5-.5h-1.834A.333.333 0 0112.5 7.5v-2a.333.333 0 01.333-.333h1.834a.5.5 0 00.5-.5V2.833A.333.333 0 0115.5 2.5h2a.333.333 0 01.333.333v1.834a.5.5 0 00.5.5h1.834a.333.333 0 01.333.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});