define("ember-svg-jar/inlined/style-three-pin-toilet-unclog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-toilet-unclog</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6 15.25a.75.75 0 01-.75.75H6.75a.75.75 0 010-1.5h.374a.5.5 0 00.49-.4 4.486 4.486 0 013.261-3.444.5.5 0 00.375-.484V8.053a.5.5 0 00-.187-.39A1.487 1.487 0 0110.5 6.5V4a1.5 1.5 0 013 0v2.5a1.487 1.487 0 01-.563 1.163.5.5 0 00-.187.39v2.119a.5.5 0 00.375.484 4.486 4.486 0 013.261 3.444.5.5 0 00.49.4h.374a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});