define("ember-svg-jar/inlined/single-neutral-id-card-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-id-card-1</title><path d=\"M12 9.5a3 3 0 11-3 3 3 3 0 013-3zm4.933 10.271a.486.486 0 01-.415.229H7.48a.486.486 0 01-.415-.23.449.449 0 010-.461 5.743 5.743 0 019.875 0 .444.444 0 01-.007.462z\"/><circle cx=\"12\" cy=\"12.5\" r=\"3\"/><path d=\"M12 16.5a5.742 5.742 0 00-4.94 2.809.449.449 0 000 .461.486.486 0 00.42.23h9.038a.486.486 0 00.415-.229.444.444 0 000-.461A5.729 5.729 0 0012 16.5z\"/><path d=\"M19.5 3H17a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h2a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2a.5.5 0 00.5-.5v-1A.5.5 0 007 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2V5a2 2 0 00-2-2z\"/><path d=\"M9 6a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0015 6V3a3 3 0 00-6 0zm2-3.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});