define("ember-svg-jar/inlined/hospital-shower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-shower</title><circle cx=\"6.375\" cy=\"11.5\" r=\"2.25\"/><path d=\"M4.125 22.5a.25.25 0 01.25.25V23a1 1 0 001 1h2a1 1 0 001-1v-.25a.25.25 0 01.25-.25h.75a1 1 0 001-1v-3a3 3 0 00-3-3h-2a3 3 0 00-3 3v3a1 1 0 001 1zM8.871 2.5a2.5 2.5 0 00-5 0 .5.5 0 00.5.5h4a.5.5 0 00.5-.5zM4.978 7.313a.876.876 0 00.865-.75l.144-1a.875.875 0 10-1.732-.25l-.145 1a.877.877 0 00.742.992.888.888 0 00.126.008zM2.081 4.21l-.43.9a.875.875 0 001.581.752l.429-.9a.875.875 0 10-1.58-.752zM.625 9.319A.873.873 0 001.79 8.9l.371-.781a.874.874 0 10-1.58-.75l-.371.785a.874.874 0 00.415 1.165zM17.375 19h3a.5.5 0 00.5-.5v-2.25a.25.25 0 01.25-.25h2.25a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-2.25a.25.25 0 01-.25-.25V9.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v2.25a.25.25 0 01-.25.25h-2.25a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2.25a.25.25 0 01.25.25v2.25a.5.5 0 00.5.5zM8.495 5.314a.875.875 0 10-1.732.25l.144 1a.876.876 0 00.866.75.888.888 0 00.127-.009.877.877 0 00.741-.992zM9.5 3.8a.874.874 0 00-.414 1.166l.43.9a.875.875 0 001.58-.752l-.43-.9A.872.872 0 009.5 3.8zM12.125 9.319a.873.873 0 00.415-1.165l-.371-.781a.874.874 0 10-1.58.75l.371.781a.873.873 0 001.165.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});