define("ember-svg-jar/inlined/messages-bubble-square-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-heart</title><path d=\"M22 1.5H2A1.5 1.5 0 00.5 3v13.552A1.459 1.459 0 002 18h3v4a.5.5 0 00.785.411L12.156 18H22a1.5 1.5 0 001.5-1.5V3A1.5 1.5 0 0022 1.5zM8.23 9.789a2.517 2.517 0 013.57-3.552l.2.2.2-.2a2.517 2.517 0 014.031.654 2.515 2.515 0 01-.468 2.909l-3.4 3.549a.5.5 0 01-.722 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});