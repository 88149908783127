define("ember-svg-jar/inlined/keyboard-asterisk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-asterisk</title><path d=\"M0 12a1.224 1.224 0 001.224 1.225H8.44a.25.25 0 01.177.427l-5.1 5.1a1.224 1.224 0 101.732 1.731l5.1-5.1a.25.25 0 01.427.177v7.216a1.224 1.224 0 002.448 0v-7.219a.25.25 0 01.427-.177l5.1 5.1a1.224 1.224 0 101.732-1.731l-5.1-5.1a.25.25 0 01.177-.427h7.216a1.225 1.225 0 000-2.449h-7.217a.25.25 0 01-.177-.427l5.1-5.1a1.225 1.225 0 00-1.732-1.732l-5.1 5.1a.25.25 0 01-.427-.177V1.222a1.224 1.224 0 10-2.448 0v7.215a.25.25 0 01-.427.177l-5.1-5.1a1.225 1.225 0 00-1.733 1.73l5.1 5.1a.25.25 0 01-.177.427H1.224A1.224 1.224 0 000 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});