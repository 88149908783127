define("ember-svg-jar/inlined/earpods-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earpods-remove</title><path d=\"M4.1 0A5.06 5.06 0 00.335 1.631 1.307 1.307 0 000 2.505v4.033a1.306 1.306 0 00.336.875A5.059 5.059 0 004.1 9.043a5.237 5.237 0 00.82-.064.5.5 0 01.58.494V18.2a1.466 1.466 0 001.4 1.3h.8A1.3 1.3 0 009 18.2V4.521A4.727 4.727 0 004.1 0zM2.511 5.326a.8.8 0 11.8-.8.8.8 0 01-.8.8zM13.864 10.376a.252.252 0 00.136-.223v-.68a.5.5 0 01.58-.494 5.237 5.237 0 00.82.064 5.06 5.06 0 003.765-1.631 1.305 1.305 0 00.335-.874V2.505a1.308 1.308 0 00-.336-.875A5.059 5.059 0 0015.4 0a4.727 4.727 0 00-4.9 4.521v8.241a.25.25 0 00.454.144 8.06 8.06 0 012.91-2.53zm3.125-6.659a.8.8 0 11-.8.8.8.8 0 01.8-.8zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.651 8.091a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});