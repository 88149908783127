define("ember-svg-jar/inlined/smiley-disapointed-mad-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-disapointed-mad-alternate</title><path d=\"M9.7 8.222a1 1 0 00.8.4 1 1 0 00.8-1.6 4.127 4.127 0 00-6.606 0 1 1 0 001.606 1.2 2.186 2.186 0 013.4 0zM19.3 7.028a4.127 4.127 0 00-6.606 0A1 1 0 0014.3 8.222a2.186 2.186 0 013.394 0 1 1 0 00.8.4 1 1 0 00.8-1.6z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 14a5.624 5.624 0 00-4.87 3.008 1 1 0 101.74.984A3.656 3.656 0 0112 16a3.656 3.656 0 013.13 1.992 1 1 0 001.74-.984A5.624 5.624 0 0012 14z\"/><circle cx=\"8\" cy=\"10.5\" r=\"1.5\"/><circle cx=\"16\" cy=\"10.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});