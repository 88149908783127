define("ember-svg-jar/inlined/make-up-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-mirror</title><path d=\"M7.457 13.163A15.141 15.141 0 0112 12.5a15.141 15.141 0 014.543.663.5.5 0 00.486-.107 7.5 7.5 0 10-10.058 0 .5.5 0 00.486.107zM12 14c-4.767 0-8.5 2.2-8.5 5s3.733 5 8.5 5 8.5-2.2 8.5-5-3.733-5-8.5-5zm0 8c-3.706 0-6.5-1.29-6.5-3s2.794-3 6.5-3 6.5 1.29 6.5 3-2.794 3-6.5 3z\"/><path d=\"M13.3 17.062a.5.5 0 00-.415.156 6.94 6.94 0 00-1.7 3.165.5.5 0 00.476.613c3.249.076 5.835-1 5.835-2 .004-.762-1.659-1.687-4.196-1.934z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});