define("ember-svg-jar/inlined/skunk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skunk</title><path d=\"M19.5 11.412a3.131 3.131 0 00-2.767 1.363 4.3 4.3 0 00-.633 2.366 4.325 4.325 0 01-2.419-.261C13.76 14.807 17 13.35 17 8.5a8.5 8.5 0 10-15.541 4.761A1.383 1.383 0 004 12.5C4 9.851 5.645 8 8 8c1.231 0 1.543.4 1.645.537.817 1.061.035 4.02-.722 6.881C8.487 17.065 7.7 20.94 7.7 20.94A10.852 10.852 0 007.5 23a1 1 0 002 0c0-.215.006-.4.019-.572a.5.5 0 01.586-.456 18.685 18.685 0 003.152.268 14.587 14.587 0 002.167-.151.5.5 0 01.576.494V23a1 1 0 002 0v-1.737a4.8 4.8 0 002.416-2.974c1.7-.436 3.584-1.95 3.584-3.377 0-1.656-2.683-3.5-4.5-3.5zM8.5 3.75a9.5 9.5 0 00-5.842 1.9 6.5 6.5 0 0111.7.028A9.014 9.014 0 008.5 3.75zM20 15.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});