define("ember-svg-jar/inlined/smiley-wink-alternate-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-wink-alternate-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M19 9.1a3.5 3.5 0 00-6.415 0 1 1 0 101.832.8 1.5 1.5 0 012.751 0 1 1 0 00.917.6.973.973 0 00.4-.084A1 1 0 0019 9.1z\"/><circle cx=\"8\" cy=\"9\" r=\"2\"/><path d=\"M16.561 14.5H7.438a.432.432 0 00-.379.25.557.557 0 000 .5A5.62 5.62 0 0012 18.5a5.622 5.622 0 004.941-3.25.557.557 0 000-.5.431.431 0 00-.38-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});