define("ember-svg-jar/inlined/image-file-bmp-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-bmp-1</title><path d=\"M24 6.414a1 1 0 00-.293-.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM5.649 14.661a2.13 2.13 0 01.412 1.867 2.174 2.174 0 01-2.137 1.6H2.5a.625.625 0 01-.625-.625v-6a.625.625 0 01.625-.625h1.424a2.174 2.174 0 012.137 1.6 2.13 2.13 0 01-.412 1.867.248.248 0 000 .316zm5.476 2.839a.625.625 0 01-1.25 0v-3.064c0-.107-.108-.074-.144 0l-.172.343a.65.65 0 01-1.118 0s-.138-.26-.172-.343-.144-.094-.144 0V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.731 1.474.8 1.606.206.139.276 0l.8-1.606a.625.625 0 011.184.279zm2.75-2.375h-.5a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 01.625-.625H14a2.128 2.128 0 012.114 2.347 2.213 2.213 0 01-2.239 1.903z\"/><path d=\"M4 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H4a.875.875 0 000-1.75zM4 15.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H4a.875.875 0 000-1.75zM14 12.125h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H14a.875.875 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});