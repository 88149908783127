define("ember-svg-jar/inlined/currency-euro-international", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>currency-euro-international</title><path d=\"M22.75 5.958a1 1 0 000-2 8.006 8.006 0 00-7.35 4.869.251.251 0 01-.23.152h-1.42a1 1 0 000 2h.787a.25.25 0 01.249.274 7.46 7.46 0 00-.036.705 7.939 7.939 0 00.1 1.232.251.251 0 01-.247.289h-.726a1 1 0 000 2h1.548a.251.251 0 01.223.135 8 8 0 007.1 4.344 1 1 0 000-2 5.977 5.977 0 01-4.518-2.065.25.25 0 01.189-.414h2.37a1 1 0 000-2h-3.64a.249.249 0 01-.244-.194 5.862 5.862 0 01-.155-1.327 5.651 5.651 0 01.055-.763.248.248 0 01.247-.216h3.614a1 1 0 000-2H18a.25.25 0 01-.207-.391 5.993 5.993 0 014.957-2.63z\"/><path d=\"M12.216 2.057a.247.247 0 01.18-.078h.354a1 1 0 000-2C1.174-.021.25 10.527.25 12c0 1.448.909 12.021 12.5 12.021a1 1 0 000-2h-.35a.251.251 0 01-.183-.079 16.915 16.915 0 01-2.624-3.3.25.25 0 01.217-.373h1.541a1 1 0 100-2H8.757a.248.248 0 01-.24-.182 13.9 13.9 0 01-.474-2.821.25.25 0 01.249-.266h2.055a1 1 0 100-2H8.292a.248.248 0 01-.249-.267 13.9 13.9 0 01.474-2.822.251.251 0 01.24-.182h2.59a1 1 0 000-2H9.81a.25.25 0 01-.216-.375 17.686 17.686 0 012.622-3.297zM8.642 20.836a.251.251 0 01-.3.376 10.07 10.07 0 01-3.519-2.524.25.25 0 01.186-.417h2.013a.249.249 0 01.228.148 13.575 13.575 0 001.392 2.417zm-2.211-4.872a.251.251 0 01-.244.307H3.379a.25.25 0 01-.228-.147 9.927 9.927 0 01-.818-2.841.251.251 0 01.248-.283h3.21a.251.251 0 01.25.235 16.418 16.418 0 00.39 2.729zm-.39-5.2a.25.25 0 01-.25.234h-3.21a.25.25 0 01-.188-.085.253.253 0 01-.06-.2 9.949 9.949 0 01.818-2.843.251.251 0 01.228-.146h2.808a.251.251 0 01.244.307 16.43 16.43 0 00-.39 2.735zM5.008 5.729a.25.25 0 01-.186-.417A10.066 10.066 0 018.34 2.788a.25.25 0 01.3.376 13.573 13.573 0 00-1.389 2.417.25.25 0 01-.229.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});