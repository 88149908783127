define("ember-svg-jar/inlined/antique-shuriken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antique-shuriken</title><path d=\"M20.228 15.377a1 1 0 00-.761-.543 2.86 2.86 0 010-5.67 1 1 0 00.762-.544l3.68-7.358a.874.874 0 00-1.174-1.17L15.379 3.77a1 1 0 00-.545.763 2.859 2.859 0 01-5.668 0 1 1 0 00-.545-.762L1.264.092A.874.874 0 00.092 1.264l3.8 7.6a.5.5 0 00.419.275 2.86 2.86 0 01.219 5.691 1 1 0 00-.762.544L.092 22.736a.874.874 0 001.173 1.171l7.356-3.678a1 1 0 00.545-.763 2.859 2.859 0 015.668 0 1 1 0 00.544.762l7.357 3.678a.881.881 0 00.392.092.874.874 0 00.781-1.264zM14 12a2 2 0 11-2-2 2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});