define("ember-svg-jar/inlined/cloud-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-search</title><path d=\"M4.067 15.646a.96.96 0 001.067-.765l.013-.066a.985.985 0 00-.828-1.178 2.669 2.669 0 01-1.68-.728A2.371 2.371 0 012 11.124a2.588 2.588 0 012.585-2.587 2.645 2.645 0 01.536.056 1.009 1.009 0 00.811-.186 1 1 0 00.392-.779C6.4 1.891 14.617.257 17.141 5.583a1.027 1.027 0 00.979.569 3.694 3.694 0 012.987 1.333A3.8 3.8 0 0122 10.058a3.689 3.689 0 01-2.531 3.447.961.961 0 00-.595 1.124v.019a.972.972 0 001.279.712A5.6 5.6 0 0024 10.024a5.764 5.764 0 00-5.22-5.847.249.249 0 01-.19-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 8.8 4.471 4.471 0 000 11.244a4.287 4.287 0 001.235 3.09 4.921 4.921 0 002.832 1.312z\"/><path d=\"M17.558 16.208A5.541 5.541 0 1015 20.868a.25.25 0 01.311.034l2.484 2.484a1 1 0 101.414-1.414l-2.484-2.484a.25.25 0 01-.034-.311 5.492 5.492 0 00.867-2.969zm-9.058 0a3.529 3.529 0 113.529 3.53 3.533 3.533 0 01-3.529-3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});