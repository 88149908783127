define("ember-svg-jar/inlined/folder-cash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-cash-1</title><path d=\"M24 6a1.5 1.5 0 00-1.5-1.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21zM12.729 18a.12.12 0 00-.1.116v.633a.75.75 0 01-1.5 0v-.595a.126.126 0 00-.129-.125h-.89a.75.75 0 010-1.5h2.39a.828.828 0 00.307-1.6l-2.427-.971a2.318 2.318 0 01.65-4.458.119.119 0 00.1-.112V8.75a.75.75 0 011.5 0v.6a.123.123 0 00.123.123h.892a.75.75 0 010 1.5h-2.398a.828.828 0 00-.307 1.6l2.427.971A2.317 2.317 0 0112.729 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});