define("ember-svg-jar/inlined/check-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-square</title><path d=\"M12.816 18.04a3 3 0 01-4.457.24l-3.518-3.517a3.113 3.113 0 01-.231-4.244 3 3 0 014.354-.119l1.078 1.077a.249.249 0 00.371-.02l5.681-7.052A.25.25 0 0015.9 4H1.534a1.5 1.5 0 00-1.5 1.5v17a1.5 1.5 0 001.5 1.5h17a1.5 1.5 0 001.5-1.5V9.789a.25.25 0 00-.445-.157z\"/><path d=\"M23.407.331A1.5 1.5 0 0021.3.558L10.359 13.917 7.9 11.462a1.5 1.5 0 00-2.121 2.121l3.636 3.636a1.515 1.515 0 002.228-.12L23.634 2.44a1.5 1.5 0 00-.227-2.109z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});