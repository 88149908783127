define("ember-svg-jar/inlined/video-game-sonic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-sonic</title><path d=\"M21.467 12.379a.5.5 0 01-.227-.611c.593-1.6 1.442-1.825 1.816-1.842a.994.994 0 00.909-1.25 4.812 4.812 0 00-3.644-3.041.5.5 0 01-.347-.687 22.735 22.735 0 011.782-3.388A1 1 0 0021.8.526 1.065 1.065 0 0020.91 0C10.364.169 7.17 2.943 5.852 5.594a2 2 0 01-.776.834A4.376 4.376 0 002.5 10.5v4a.5.5 0 01-.5.5c-1.122 0-2 .659-2 1.5a1.414 1.414 0 00.842 1.222.5.5 0 01.26.357C1.705 21.533 5.144 24 9.5 24a12.649 12.649 0 006.84-1.764 3.04 3.04 0 01.871-.407 4.534 4.534 0 002.745-2.185 3 3 0 011.876-1.467 4.837 4.837 0 011.547-.183.492.492 0 00.531-.419 4.777 4.777 0 00-2.443-5.196zm-9.98-6.149a6.7 6.7 0 013.521-2.449.5.5 0 01.542.219 6.4 6.4 0 01.608 5.152.25.25 0 01-.451.074 5.712 5.712 0 00-4.057-2.6.25.25 0 01-.163-.394zM6 10.246c.932 0 1.75 1.515 1.75 2.43a.75.75 0 00.633.741.761.761 0 00.83-.51c.65-2 1.232-3.161 2.287-3.161 1.645 0 2.75 1.94 2.75 3.75a3.189 3.189 0 01-2.93 3.39 12.016 12.016 0 01-4.485-1.561 5.176 5.176 0 00-2.071-.625.5.5 0 01-.477-.45 7.511 7.511 0 01-.037-.75c0-1.826.768-3.254 1.75-3.254zM9.5 23c-3.846 0-6.66-2.023-7.339-4.722a.251.251 0 01.035-.2.248.248 0 01.172-.108 1.7 1.7 0 001.62-1.37.406.406 0 01.272-.353 2.851 2.851 0 011.949.439c1.35.676 3.391 1.7 5.111 1.7a4.364 4.364 0 003.821-2.446.5.5 0 01.644-.225A3.011 3.011 0 0117.5 18.5c0 3-4 4.5-8 4.5z\"/><path d=\"M6 14.246a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM11.5 15.246a.75.75 0 00.75-.75v-2a.75.75 0 00-1.5 0v2a.75.75 0 00.75.75zM12.7 18.97a2.721 2.721 0 01-2.641.81.75.75 0 00-.456 1.43 3.807 3.807 0 001.077.143 4.252 4.252 0 003.09-1.331.75.75 0 00-1.07-1.052z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});