define("ember-svg-jar/inlined/human-resources-hierarchy-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-hierarchy-1</title><circle cx=\"5.002\" cy=\"8.694\" r=\"2.778\"/><path d=\"M5 12.583A5 5 0 00.008 17.32a.251.251 0 00.069.185.249.249 0 00.181.078h9.487a.247.247 0 00.181-.078.25.25 0 00.069-.185A5 5 0 005 12.583zM14.5 5.25a.249.249 0 01.25-.25h.75a1 1 0 100-2H14a1.5 1.5 0 00-1.5 1.5v6.25a.25.25 0 01-.25.25H10.5a1 1 0 000 2h1.75a.249.249 0 01.25.25v6.25A1.5 1.5 0 0014 21h1.5a1 1 0 000-2h-.75a.249.249 0 01-.25-.25z\"/><circle cx=\"20.502\" cy=\"17.944\" r=\"1.944\"/><path d=\"M20.5 20.5a3.5 3.5 0 00-3.49 3.231.245.245 0 00.066.189.25.25 0 00.183.08h6.481a.249.249 0 00.183-.081.244.244 0 00.066-.189A3.5 3.5 0 0020.5 20.5z\"/><circle cx=\"20.502\" cy=\"1.944\" r=\"1.944\"/><path d=\"M20.5 4.5a3.5 3.5 0 00-3.49 3.231.245.245 0 00.066.189.25.25 0 00.183.08h6.481a.249.249 0 00.183-.081.244.244 0 00.066-.189A3.5 3.5 0 0020.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});