define("ember-svg-jar/inlined/shopping-basket-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-man</title><path d=\"M9.59 16H7.5a.25.25 0 01-.208-.389l1.038-1.556a1 1 0 00-1.664-1.11l-1.96 2.944A.25.25 0 014.5 16H1.41A1.412 1.412 0 000 17.41a1.445 1.445 0 00.027.277l.786 3.93A1.726 1.726 0 002.5 23h6a1.726 1.726 0 001.687-1.383l.786-3.931A1.427 1.427 0 0011 17.41 1.412 1.412 0 009.59 16zM8.5 20.5a.5.5 0 01-1 0v-2a.5.5 0 011 0zm-5-2v2a.5.5 0 01-1 0v-2a.5.5 0 011 0zm1.5 0a.5.5 0 011 0v2a.5.5 0 01-1 0zM18.5 8a4 4 0 003.981-3.608.249.249 0 00-.089-.216.252.252 0 00-.229-.049 8.676 8.676 0 01-2.382.36 6.179 6.179 0 01-4.6-2.02.253.253 0 00-.229-.072.251.251 0 00-.184.154A4 4 0 0018.5 8z\"/><path d=\"M22.342 3.025a.038.038 0 00.025-.048 4 4 0 00-6.692-1.806.249.249 0 00-.01.342c1.74 1.966 3.87 2.447 6.677 1.512zM18.5 8.5A5.506 5.506 0 0013 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});