define("ember-svg-jar/inlined/trousers-calves", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trousers-calves</title><path d=\"M18.5 2a.5.5 0 00.5-.5V1a1 1 0 00-1-1H6a1 1 0 00-1 1v.5a.5.5 0 00.5.5zM20 22.952l-.927-18.976a.5.5 0 00-.5-.476H5.427a.5.5 0 00-.5.476L4 22.953A1 1 0 005 24h4.5a1 1 0 00.994-.888l1.009-9.19a.5.5 0 01.985-.025l1.012 9.218a1 1 0 001 .885H19a1 1 0 001-1.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});