define("ember-svg-jar/inlined/touch-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id</title><path d=\"M3 12.625a.875.875 0 00-.875.875V23a.875.875 0 001.75 0v-9.5A.875.875 0 003 12.625zM12 1.875a8.1 8.1 0 014.492 1.353.875.875 0 10.968-1.458A9.877 9.877 0 002.125 10a.875.875 0 001.75 0A8.134 8.134 0 0112 1.875zM20.211 4.513a.875.875 0 10-1.454.974A8.081 8.081 0 0120.125 10v13a.875.875 0 001.75 0V10a9.828 9.828 0 00-1.664-5.487z\"/><path d=\"M12 3.125A6.883 6.883 0 005.125 10v13a.875.875 0 001.75 0V10a5.125 5.125 0 0110.25 0v6.5a.875.875 0 001.75 0V10A6.883 6.883 0 0012 3.125zM18 19.125a.875.875 0 00-.875.875v3a.875.875 0 001.75 0v-3a.875.875 0 00-.875-.875z\"/><path d=\"M9 17.625a.875.875 0 00-.875.875V23a.875.875 0 001.75 0v-4.5A.875.875 0 009 17.625zM12 6.125A3.879 3.879 0 008.125 10v5a.875.875 0 001.75 0v-5a2.125 2.125 0 014.25 0v13a.875.875 0 001.75 0V10A3.879 3.879 0 0012 6.125z\"/><path d=\"M12 10.125a.875.875 0 00-.875.875v7a.875.875 0 001.75 0v-7a.875.875 0 00-.875-.875zM12 20.625a.875.875 0 00-.875.875V23a.875.875 0 001.75 0v-1.5a.875.875 0 00-.875-.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});