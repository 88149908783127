define("ember-svg-jar/inlined/disability-wheelchair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-wheelchair</title><path d=\"M7.419 23.4a5.494 5.494 0 005.626-2.31 1.008 1.008 0 00-.82-1.586.956.956 0 00-.807.41 3.491 3.491 0 11-4.109-5.186A1.019 1.019 0 008 13.774a1.013 1.013 0 00-1.365-.945A5.5 5.5 0 007.419 23.4z\"/><circle cx=\"11\" cy=\"3\" r=\"2.5\"/><path d=\"M9.5 16.5A1.5 1.5 0 0011 18h4.5a.5.5 0 01.424.235l2.3 3.56a1.5 1.5 0 001.272.7 1.5 1.5 0 001.271-2.3l-2.3-3.56A3.48 3.48 0 0015.5 15H13a.5.5 0 01-.5-.5v-1.25a.25.25 0 01.25-.25H16a1.5 1.5 0 000-3h-3.25a.25.25 0 01-.25-.25V8.5a1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});