define("ember-svg-jar/inlined/light-mode-dark-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-dark-light</title><path d=\"M24 12a1 1 0 00-1-1h-3.906a.5.5 0 01-.49-.4 6.706 6.706 0 00-.948-2.284.5.5 0 01.065-.627l2.764-2.764a1 1 0 00-1.414-1.414l-2.764 2.768a.5.5 0 01-.627.065A6.706 6.706 0 0013.4 5.4a.5.5 0 01-.4-.49V1a1 1 0 00-2 0v3.906a.5.5 0 01-.4.49 6.706 6.706 0 00-2.284.948.5.5 0 01-.627-.065l-2.76-2.764a1 1 0 00-1.414 1.414l2.764 2.764a.5.5 0 01.065.627A6.706 6.706 0 005.4 10.6a.5.5 0 01-.49.4H1a1 1 0 000 2h3.907a.5.5 0 01.489.4 6.7 6.7 0 00.948 2.283.5.5 0 01-.065.627l-2.764 2.761a1 1 0 001.414 1.414l2.764-2.764a.5.5 0 01.627-.065 6.706 6.706 0 002.28.944.5.5 0 01.4.49V23a1 1 0 002 0v-3.906a.5.5 0 01.4-.49 6.706 6.706 0 002.284-.948.5.5 0 01.627.065l2.764 2.764a1 1 0 001.414-1.414l-2.764-2.764a.5.5 0 01-.065-.627 6.706 6.706 0 00.94-2.28.5.5 0 01.49-.4H23a1 1 0 001-1zm-8.738 2.5A5.762 5.762 0 019.5 8.738a5.67 5.67 0 01.155-1.312.5.5 0 01.322-.357 5.459 5.459 0 016.955 6.952.5.5 0 01-.354.321 5.69 5.69 0 01-1.316.158z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});