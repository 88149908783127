define("ember-svg-jar/inlined/shape-triangle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-triangle-alternate</title><path d=\"M4.147 17.4a.249.249 0 00.317.329A22.929 22.929 0 0112 16.6a22.831 22.831 0 017.57 1.148.25.25 0 00.318-.33L13.155 1.174a1.251 1.251 0 00-2.31 0zM12 18.1c-4.259 0-7.355.926-8.62 1.863a.249.249 0 00-.1.246C3.688 22.434 7.868 23.6 12 23.6s8.312-1.163 8.717-3.391a.249.249 0 00-.1-.246C19.355 19.023 16.259 18.1 12 18.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});