define("ember-svg-jar/inlined/laptop-announcement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-announcement</title><path d=\"M4 11.357v8a.359.359 0 00.009.081.251.251 0 01-.09.244l-2.032 1.626a.994.994 0 00-.369.891A1.775 1.775 0 003.5 23.857h17a1.775 1.775 0 001.982-1.657 1 1 0 00-.37-.891l-2.031-1.625a.251.251 0 01-.09-.244.359.359 0 00.009-.083v-8a2 2 0 00-2-2H6a2 2 0 00-2 2zm10 10.75h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm-7.751-10.75h11.5a.25.25 0 01.25.25v6.5a.249.249 0 01-.25.25H6.25a.249.249 0 01-.25-.25v-6.5a.248.248 0 01.073-.176.252.252 0 01.176-.074zM19.118 3.5l4.64-2.58a.016.016 0 00.008-.02.485.485 0 00-.133-.253A.348.348 0 0123.6.6a1.078 1.078 0 00-1.029-.457h-7.142A1.078 1.078 0 0014.405.6a.3.3 0 01-.038.048.489.489 0 00-.133.252.018.018 0 00.008.019L18.889 3.5a.222.222 0 00.229 0z\"/><path d=\"M14.214 5.592c0 .887.363 1.265 1.215 1.265h7.142c.852 0 1.215-.378 1.215-1.265v-3.12a.251.251 0 00-.371-.219L19.6 4.37a1.232 1.232 0 01-1.2 0l-3.815-2.116a.25.25 0 00-.371.218zM.214 5.592c0 .887.363 1.265 1.215 1.265h7.142c.852 0 1.215-.378 1.215-1.265v-3.12a.251.251 0 00-.371-.219L5.6 4.37a1.232 1.232 0 01-1.2 0L.585 2.254a.25.25 0 00-.371.218z\"/><path d=\"M5.118 3.5L9.758.92A.016.016 0 009.766.9a.485.485 0 00-.133-.252A.348.348 0 019.6.6 1.078 1.078 0 008.571.143H1.429A1.078 1.078 0 00.405.6a.3.3 0 01-.038.048A.489.489 0 00.234.9a.018.018 0 00.008.02L4.889 3.5a.222.222 0 00.229 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});