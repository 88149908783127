define("ember-svg-jar/inlined/shop-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-basket</title><path d=\"M6.257 5.415a.257.257 0 00-.009.067V9.3a.25.25 0 00.114.209 4.924 4.924 0 002.288.791c.133.011.266.016.4.016a4.664 4.664 0 002.061-.474.249.249 0 00.14-.224V.25A.25.25 0 0011 0H7.936A.249.249 0 007.7.185c-.1.367-.423 1.525-1.443 5.23zM12.748 9.611a.249.249 0 00.14.224 4.727 4.727 0 002.473.46 4.868 4.868 0 002.379-.842.018.018 0 00.008-.015V5.482a.252.252 0 00-.009-.067L16.3.185A.248.248 0 0016.06 0H13a.25.25 0 00-.25.25z\"/><path d=\"M24 5.262v-.007a.317.317 0 000-.053V5.2a.312.312 0 00-.011-.053v-.01a.441.441 0 00-.023-.064L22.235.966A1.5 1.5 0 0020.881 0h-2.747a.249.249 0 00-.241.316l1.329 4.832a.788.788 0 01.026.2V8.92a.25.25 0 00.158.232l.092.035v4.563a.25.25 0 01-.25.25h-14.5a.25.25 0 01-.25-.25V9.188l.091-.034a.251.251 0 00.159-.233V5.347a.788.788 0 01.026-.2L6.1.316A.249.249 0 005.862 0H3.119a1.5 1.5 0 00-1.354.966L.044 5.073a.441.441 0 00-.023.064v.005a.312.312 0 00-.012.058.317.317 0 000 .053v.927A3.245 3.245 0 001.99 9.1a3.328 3.328 0 00.335.127.251.251 0 01.173.238v4.321a.25.25 0 01-.2.245 1.5 1.5 0 00-1.177 1.632l.778 7A1.5 1.5 0 003.394 24H20.6a1.5 1.5 0 001.491-1.334l.778-7a1.5 1.5 0 00-1.169-1.632.25.25 0 01-.2-.245V9.474a.249.249 0 01.173-.238 3.254 3.254 0 00.335-.129A3.294 3.294 0 0024 6.182v-.92zM13.134 17.9a.25.25 0 00.218-.372l-.509-.913a.75.75 0 111.31-.73l1.107 1.982a.248.248 0 00.128.111 1.13 1.13 0 01.5.383 1.08 1.08 0 01.14 1.02l-1.4 2.472a1.1 1.1 0 01-1.028.647h-3.231a1.127 1.127 0 01-1.032-.66l-1.324-2.36a1.082 1.082 0 01.078-1.094 1.1 1.1 0 01.517-.408.247.247 0 00.128-.112l1.107-1.981a.75.75 0 011.31.73l-.509.913a.249.249 0 000 .248.247.247 0 00.215.124z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});