define("ember-svg-jar/inlined/paralympics-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-flag</title><path d=\"M6.5 22a3.491 3.491 0 01-1.749-6.517A.5.5 0 005 15.05v-1.127a.5.5 0 00-.7-.458 5.5 5.5 0 107.235 7.235.5.5 0 00-.458-.7H9.95a.5.5 0 00-.433.249A3.488 3.488 0 016.5 22zM8 7a2.5 2.5 0 10-2.5-2.5A2.5 2.5 0 008 7z\"/><path d=\"M14 5.4c-.327.3-.281.279-1.1 2.189a1.5 1.5 0 01-1.379.909H8A1.5 1.5 0 006.5 10v7A1.5 1.5 0 008 18.5h4.5a.505.505 0 01.424.234l1.913 3.066a1.5 1.5 0 002.544-1.59l-1.913-3.06A3.48 3.48 0 0012.5 15.5H10a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h1.522a4.494 4.494 0 004.136-2.728l.721-1.681A1.494 1.494 0 0016 5.39V1a1 1 0 00-2 0z\"/><rect x=\"16.974\" width=\"6.026\" height=\"4.506\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});