define("ember-svg-jar/inlined/single-man-actions-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-home</title><path d=\"M8.883 16.093l5.049-4.272a.25.25 0 00-.063-.421l-2.717-1.165a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.944.492 10.241 0 8.888 0a3.752 3.752 0 00-3.163 1.324.252.252 0 01-.213.118 1.074 1.074 0 00-.9.375c-.371.493-.1 1.171.176 1.732a.251.251 0 01-.045.288 1.3 1.3 0 00-.162.2A1.766 1.766 0 004.319 5a1.524 1.524 0 00.639 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h7.552a.251.251 0 00.223-.137 2.486 2.486 0 01.608-.77zM21.5 24a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.881l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.323.881h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});