define("ember-svg-jar/inlined/wifi-coffee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-coffee</title><path d=\"M17.711 12.25h-11.5a.5.5 0 00-.5.5v6a4.5 4.5 0 004.5 4.5h2a4.49 4.49 0 004.421-3.79.249.249 0 01.246-.21h.833a3.5 3.5 0 000-7zm0 5h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h.75a1.5 1.5 0 010 3zM11.213 2.75a9.43 9.43 0 016.713 2.782 1 1 0 001.414-1.414A11.415 11.415 0 0011.213.75a11.425 11.425 0 00-8.131 3.368A1 1 0 104.5 5.532a9.441 9.441 0 016.71-2.782z\"/><path d=\"M6.707 7.115a1 1 0 101.414 1.414 4.376 4.376 0 016.18 0 1 1 0 001.414-1.414 6.38 6.38 0 00-9.008 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});