define("ember-svg-jar/inlined/smiley-liar-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-liar-alternate</title><path d=\"M20.455 16.129a1 1 0 00-1.346.435A9.051 9.051 0 012.366 9.893a1 1 0 10-1.918-.564 11.051 11.051 0 0020.443 8.146 1 1 0 00-.436-1.346z\"/><circle cx=\"8.5\" cy=\"6\" r=\"1.5\"/><path d=\"M4.553 3.9A1 1 0 005.9 3.447 2.656 2.656 0 018 2.5a1 1 0 000-2c-1.153 0-3.142.547-3.895 2.053A1 1 0 004.553 3.9z\"/><circle cx=\"14.5\" cy=\"6\" r=\"1.5\"/><path d=\"M15 2.5a2.656 2.656 0 012.105.947 1 1 0 001.79-.894C18.142 1.047 16.153.5 15 .5a1 1 0 000 2zM21 9h-8.5a1 1 0 000 2H21a1 1 0 010 2h-4.5a1 1 0 000 2H21a3 3 0 000-6zM6.553 17.907a.4.4 0 00.347.593 18.979 18.979 0 018.21 0 .395.395 0 00.342-.592 5.136 5.136 0 00-8.894 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});