define("ember-svg-jar/inlined/image-document-gif-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-gif-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-16 18.875A1.377 1.377 0 007.875 17.5s.053-.375-.221-.375H7a.625.625 0 010-1.25h1.5a.625.625 0 01.625.625v1a2.625 2.625 0 01-5.25 0v-4a2.625 2.625 0 015.25 0 .625.625 0 01-1.25 0 1.375 1.375 0 00-2.75 0v4A1.377 1.377 0 006.5 18.875zm8 0a.625.625 0 010 1.25h-4a.625.625 0 010-1.25h1.125a.25.25 0 00.25-.25v-6.25a.25.25 0 00-.25-.25H10.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25zm6-6.75H18a.875.875 0 00-.875.875v1.625a.25.25 0 00.25.25H19a.625.625 0 010 1.25h-1.625a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0V13A2.124 2.124 0 0118 10.875h2.5a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});