define("ember-svg-jar/inlined/love-hat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-hat</title><path d=\"M15.765 9.3c.949-.063 3.639-.339 4.395-1.456a1.507 1.507 0 00-1.928-2.267.25.25 0 01-.367-.249 1.507 1.507 0 00-2.821-.95c-.756 1.116-.016 3.713.278 4.62a.431.431 0 00.443.302zM8.8 8.785a.523.523 0 00.621-.151c.85-1.047 3.515-4.538 3.012-6.611a2.356 2.356 0 00-4.68.128.248.248 0 01-.187.2.251.251 0 01-.266-.092 2.356 2.356 0 00-4.213 2.034C3.59 6.365 7.56 8.245 8.8 8.785zM21.5 12h-19a1 1 0 000 2H5a.5.5 0 01.5.5V16a.5.5 0 00.5.5h12a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h2.5a1 1 0 000-2zM18 18H6a.5.5 0 00-.5.5V21a3 3 0 003 3h7a3 3 0 003-3v-2.5a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});