define("ember-svg-jar/inlined/single-man-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zm-6.95 7.407a7.38 7.38 0 002-.275.238.238 0 01.219.048.233.233 0 01.081.208 3.513 3.513 0 01-7-.428 3.213 3.213 0 01.094-.752.233.233 0 01.147-.165.236.236 0 01.22.027 7.362 7.362 0 004.239 1.337zM9.64 8.845a.254.254 0 01-.1-.187.25.25 0 01.078-.2 3.5 3.5 0 015.524.938.25.25 0 01-.146.353 5.936 5.936 0 01-1.791.277A6.169 6.169 0 019.64 8.845zM12 15.75a6.3 6.3 0 015.689 3.95.751.751 0 01-.689 1.05H7a.751.751 0 01-.689-1.05A6.305 6.305 0 0112 15.75zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});