define("ember-svg-jar/inlined/sailing-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat</title><path d=\"M22.5 20h-4a.5.5 0 01-.5-.5v-1.085c0-9.259-2.546-15.38-3.64-17.581a1.5 1.5 0 00-2.685 0L3.606 16.967a1 1 0 00.894 1.448h11a.5.5 0 01.5.5v.585a.5.5 0 01-.5.5H4.167a1.006 1.006 0 00-.6.2L.9 22.2a1 1 0 00.6 1.8H21a2.5 2.5 0 002.5-2.5V21a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});