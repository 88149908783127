define("ember-svg-jar/inlined/single-woman-actions-flag.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-flag</title><path d=\"M12.635 12.314a.254.254 0 000-.2.249.249 0 00-.143-.133l-.522-.181a.778.778 0 01-.512-.73v-.47a.249.249 0 01.249-.25 6.378 6.378 0 003.787-.895.389.389 0 00.083-.593c-.494-.537-.835-1.185-.981-3.317C14.335 1.72 11.732.374 9.5.374S4.658 1.72 4.4 5.545c-.145 2.132-.487 2.78-.981 3.317a.388.388 0 00.084.593 6.35 6.35 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 00.5 17.45a.386.386 0 00.1.3.4.4 0 00.29.126h11.356a.25.25 0 00.25-.25V13a1.828 1.828 0 01.139-.686z\"/><path d=\"M14 22.626a1 1 0 002 0v-3.914a.25.25 0 01.191-.243 3.048 3.048 0 012.447.44 4.064 4.064 0 002.285.7 4.213 4.213 0 001.363-.227l.932-.43a.5.5 0 00.29-.454V13a.5.5 0 00-.71-.454l-.882.41a3.054 3.054 0 01-2.718-.37 4.046 4.046 0 00-3.6-.489l-1.269.429a.5.5 0 00-.34.474z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});