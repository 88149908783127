define("ember-svg-jar/inlined/check-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>check-badge</title><path d=\"M22.718 10.35L20.88 8.872a1.092 1.092 0 01-.4-.971l.255-2.345a2.091 2.091 0 00-2.307-2.308L16.08 3.5a1.093 1.093 0 01-.971-.4l-1.478-1.837a2.157 2.157 0 00-3.262 0L8.891 3.1a1.086 1.086 0 01-.971.4l-2.345-.252a2.091 2.091 0 00-2.308 2.307L3.522 7.9a1.087 1.087 0 01-.4.971l-1.84 1.479a2.092 2.092 0 000 3.262L3.12 15.09a1.088 1.088 0 01.4.971l-.255 2.345a2.091 2.091 0 002.307 2.308l2.346-.255a1.092 1.092 0 01.971.4l1.48 1.841a2.092 2.092 0 003.262 0l1.478-1.838a1.093 1.093 0 01.971-.4l2.345.255a2.091 2.091 0 002.308-2.307l-.255-2.346a1.086 1.086 0 01.4-.971l1.838-1.478a2.092 2.092 0 000-3.262zM12.4 15.048a1.785 1.785 0 01-2.663.19L7.293 12.8a1 1 0 111.414-1.414l2.251 2.251 3.909-5.211a1 1 0 111.6 1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});