define("ember-svg-jar/inlined/shopping-bag-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-fire</title><path d=\"M9.784 19a.25.25 0 00.249-.273A7.939 7.939 0 0110 18a11.037 11.037 0 015.988-9.695.251.251 0 00.132-.245l-.055-.6A1.573 1.573 0 0014.5 6h-1.75a.25.25 0 01-.25-.25V4a4 4 0 00-8 0v1.75a.25.25 0 01-.25.25H2.5A1.57 1.57 0 00.936 7.452l-.242 2.615-.577 8.666a.248.248 0 00.067.188.25.25 0 00.182.079zM6.5 4a2 2 0 014 0v1.75a.25.25 0 01-.25.25h-3.5a.25.25 0 01-.25-.25zM10.3 20.182a.248.248 0 00-.24-.182H.266a.25.25 0 00-.249.233l-.012.18v.151A2.439 2.439 0 002.436 23h8.823a.25.25 0 00.2-.394 7.983 7.983 0 01-1.159-2.424z\"/><path d=\"M21.308 12.87a1.5 1.5 0 00-1.857.151.25.25 0 01-.418-.219 2.345 2.345 0 01.3-.846 1.5 1.5 0 00-1.911-2.128A9.183 9.183 0 0012 18a6 6 0 0012 0 6.209 6.209 0 00-2.692-5.13zM18 22a4 4 0 01-4-4 7.02 7.02 0 012.6-5.322.25.25 0 01.409.207 4.158 4.158 0 001.391 3.361l.5.449a.251.251 0 00.334 0l.5-.451a4.021 4.021 0 00.794-1.02.253.253 0 01.191-.126.25.25 0 01.214.082A4.272 4.272 0 0122 18a4 4 0 01-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});