define("ember-svg-jar/inlined/robot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>robot</title><path d=\"M5.5 11h13a2 2 0 002-2V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v7a2 2 0 002 2zM16 4a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 0116 4zM8 4a1.5 1.5 0 11-1.5 1.5A1.5 1.5 0 018 4zM10.5 12.5a1 1 0 000 2h3a1 1 0 000-2z\"/><path d=\"M23.3 9.867a1 1 0 10-1.6 1.2 1.5 1.5 0 01-2.4 1.8 1 1 0 10-1.6 1.2 3.45 3.45 0 002.563 1.387.251.251 0 01.226.306 3.022 3.022 0 01-1.082 1.705l-.387.3a.315.315 0 01-.439-.051A4.613 4.613 0 0014.979 16H9.021a4.613 4.613 0 00-3.6 1.722.315.315 0 01-.439.051l-.387-.3a3.022 3.022 0 01-1.082-1.7.251.251 0 01.226-.306A3.449 3.449 0 006.3 14.073a1 1 0 00-1.6-1.2 1.5 1.5 0 01-2.4-1.8 1 1 0 10-1.6-1.2 3.5 3.5 0 00.415 4.664 1 1 0 01.318.7 5.041 5.041 0 001.927 3.816l.649.5a1 1 0 01.384.84 4.675 4.675 0 00.1 1.215c.009.038.019.076.032.114l.435 1.265A1.5 1.5 0 006.379 24h11.242a1.5 1.5 0 001.418-1.012l.435-1.265c.013-.038.023-.076.032-.114a4.675 4.675 0 00.1-1.215 1 1 0 01.384-.84l.649-.5a5.041 5.041 0 001.927-3.816 1 1 0 01.318-.7 3.5 3.5 0 00.416-4.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});