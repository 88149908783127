define("ember-svg-jar/inlined/style-three-pin-fish-trekking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fish-trekking</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.75 6.75a.75.75 0 01.75.75v.75a.75.75 0 01-1.5 0V7.5a.75.75 0 01.75-.75zM13 2.5a2 2 0 11-2 2 2 2 0 012-2zm-5.284 8.847a1.006 1.006 0 01-.371-.07l-1.457-.583a1 1 0 01-.461-1.484l1.428-2.14a1 1 0 011.225-.365l.925.4a1 1 0 01.539 1.278l-.9 2.328a1 1 0 01-.535.558.984.984 0 01-.393.078zM16.5 17a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zm.226-6.675a.992.992 0 01-.019 1.382A3.748 3.748 0 0114 12.5a3.293 3.293 0 01-1.158-.214.25.25 0 00-.319.328l1.406 3.515a1 1 0 01-1.858.742l-.914-2.286a.25.25 0 00-.469.014l-.739 2.217a1.022 1.022 0 01-1.265.633 1 1 0 01-.633-1.265l2.5-7.5a1 1 0 011.9.632.318.318 0 00.059.306A2.058 2.058 0 0014 10.5a2.506 2.506 0 001.353-.26 1 1 0 011.373.085z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});