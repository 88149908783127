define("ember-svg-jar/inlined/pets-hair-brush", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-hair-brush</title><path d=\"M23.174 4.526a3.181 3.181 0 00-2.309-2.034 3.059 3.059 0 00-.906-.077.25.25 0 00-.209.357c1.055 2.231.03 5.559-2.72 8.311-1.9 1.9-3.71 2.614-5.245 2.614a4.727 4.727 0 01-2.794-.9.249.249 0 00-.323.026l-7.388 7.37a2.146 2.146 0 103.034 3.035l6.732-6.728c2 .664 4.843-.437 7.693-3.008 2.749-2.481 5.309-6.343 4.435-8.966z\"/><path d=\"M10.357 10.355L8.515 8.514a.75.75 0 10-1.06 1.061L9.3 11.416c2.225 2.222 5.166.467 6.85-1.217 2.363-2.364 3.735-5.842 1.972-7.607L15.888.365a.75.75 0 10-1.06 1.06l2.229 2.228a1.662 1.662 0 01.343 1.382.251.251 0 01-.426.149l-3.218-3.215A.75.75 0 0012.7 3.03l3.068 3.07.795.8a.25.25 0 01.039.3 8.589 8.589 0 01-.411.634.25.25 0 01-.38.032l-.689-.689-4.11-4.111a.75.75 0 00-1.061 1.06l4.188 4.189.7.7a.251.251 0 01.074.181.253.253 0 01-.079.179 9.439 9.439 0 01-.559.484.25.25 0 01-.332-.02L13.2 9.1 9.91 5.81a.75.75 0 00-1.057 1.063l2.807 2.806.784.784a.25.25 0 01-.125.421 2.029 2.029 0 01-1.962-.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});