define("ember-svg-jar/inlined/single-neutral-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-home</title><path d=\"M22.875 7.747L12.831.76a1.46 1.46 0 00-1.662 0L1.124 7.747A1.458 1.458 0 00.5 8.943v13.1A1.457 1.457 0 001.956 23.5h20.088a1.457 1.457 0 001.456-1.457v-13.1a1.456 1.456 0 00-.625-1.196zM10.161 15.33a4.977 4.977 0 01-1.13-3.51A2.882 2.882 0 0112 8.767a2.882 2.882 0 012.969 3.053 5 5 0 01-1.149 3.534.977.977 0 00.027.7c2.061.735 3.2 1.274 3.652 2.151a5.275 5.275 0 01.492 1.476.5.5 0 01-.106.411.507.507 0 01-.385.18h-11a.507.507 0 01-.385-.18.5.5 0 01-.106-.411A5.293 5.293 0 016.5 18.2c.453-.876 1.591-1.415 3.667-2.159a1 1 0 00-.006-.711z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});