define("ember-svg-jar/inlined/engagement-ring-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>engagement-ring-1</title><path d=\"M9.785 4.25h4.43a.5.5 0 00.47-.671L13.5.329A.5.5 0 0013.034 0h-2.068a.5.5 0 00-.466.329l-1.185 3.25a.5.5 0 00.47.671zM3.3 4.25h3.82a.5.5 0 00.47-.329L8.776.671A.5.5 0 008.306 0H6.522a2.5 2.5 0 00-2.086 1.123L2.887 3.475a.5.5 0 00.413.775zM16.406 3.921a.5.5 0 00.47.329H20.7a.5.5 0 00.417-.775l-1.553-2.352A2.5 2.5 0 0017.478 0h-1.784a.5.5 0 00-.47.671zM15.527 13.541a.5.5 0 01-.229-.807l5.383-6.155a.5.5 0 00-.376-.829h-3.417a.5.5 0 00-.472.336l-2.206 6.341a.961.961 0 01-1 .642.616.616 0 01-.524-.817l2.03-5.838a.5.5 0 00-.472-.664H9.758a.5.5 0 00-.472.664l2.03 5.838a.616.616 0 01-.524.817.961.961 0 01-1-.642L7.584 6.086a.5.5 0 00-.472-.336H3.7a.5.5 0 00-.376.829L8.7 12.734a.5.5 0 01-.229.807 11.987 11.987 0 00-8.26 9.277A1 1 0 001.194 24h1.019a1 1 0 00.98-.8 8.987 8.987 0 0117.614 0 1 1 0 00.98.8h1.019a1 1 0 00.983-1.182 11.987 11.987 0 00-8.262-9.277z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});