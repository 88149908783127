define("ember-svg-jar/inlined/love-heart-hands-hold-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-hands-hold-1</title><rect x=\"20\" y=\"1\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M3.637 6.861h.016l2-.639A.5.5 0 006 5.733a2.25 2.25 0 012.25-2.29h5.25a.5.5 0 110 1H8.25a1.25 1.25 0 000 2.5l3.4-.007a1 1 0 01.577.182c1.329.935 2.5 1.825 4.271 1.825h2a.5.5 0 00.5-.5v-5.9a.5.5 0 00-.339-.474c-.706-.239-1.341-.46-1.917-.66C10.918-.613 10.81-.6 5.187 2.387c-1.281.681-2.876 1.529-4.91 2.547a.5.5 0 00-.13.8 3.232 3.232 0 003.49 1.127z\"/><rect y=\"14\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M20.363 17.139h-.016l-2 .639a.5.5 0 00-.348.485 2.25 2.25 0 01-2.249 2.29H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 100-2.5l-3.4.007a1 1 0 01-.577-.182c-1.329-.934-2.5-1.825-4.271-1.825h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.239 1.341.46 1.917.66 5.826 2.023 5.934 2.014 11.557-.977 1.281-.681 2.876-1.529 4.91-2.547a.5.5 0 00.13-.8 3.232 3.232 0 00-3.492-1.124zM12.438 10.187a.618.618 0 01-.875 0 1.585 1.585 0 10-2.242 2.241l2.318 2.418a.5.5 0 00.722 0l2.318-2.418a1.585 1.585 0 10-2.241-2.241z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});