define("ember-svg-jar/inlined/folder-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-home-1</title><path d=\"M24 6a1.5 1.5 0 00-1.5-1.5H11.736a.5.5 0 01-.447-.276l-.947-1.9A1.494 1.494 0 009 1.5H1.5A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21zm-7.521 7.281a.339.339 0 01-.318.219h-.911a.25.25 0 00-.25.25V17a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-3.25a.25.25 0 00-.25-.25h-.911a.339.339 0 01-.318-.219.329.329 0 01.1-.369l4.411-3.833a.344.344 0 01.438 0l4.411 3.833a.329.329 0 01.098.369z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});