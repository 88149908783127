define("ember-svg-jar/inlined/playlist-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>playlist-add</title><path d=\"M16.315 9.533a.247.247 0 00.222-.248V2.5A2.47 2.47 0 0014.105 0H2.432A2.47 2.47 0 000 2.5v12A2.47 2.47 0 002.432 17h6.6a.246.246 0 00.242-.227 7.906 7.906 0 017.041-7.24zM11.187 8a.987.987 0 01-.973-1A1.784 1.784 0 009 5.233a.183.183 0 00-.248.175V11.052A2.467 2.467 0 016.323 13.5a2.5 2.5 0 010-5c.074 0 .148 0 .221.01a.237.237 0 00.186-.064.253.253 0 00.079-.185V4a.987.987 0 01.973-1c1.514 0 4.377.836 4.377 4a.986.986 0 01-.972 1z\"/><path d=\"M17.5 10.636A6.685 6.685 0 0017.5 24a6.685 6.685 0 000-13.364zm.75 9.252a.75.75 0 11-1.5 0v-1.542a.254.254 0 00-.25-.257H15a.771.771 0 010-1.542h1.5a.254.254 0 00.25-.257v-1.542a.75.75 0 111.5 0v1.542a.254.254 0 00.25.257H20a.771.771 0 010 1.542h-1.5a.254.254 0 00-.25.257z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});