define("ember-svg-jar/inlined/data-transfer-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-vertical</title><path d=\"M6.481 5.124a1.5 1.5 0 00-1.5 1.5v10.109a.249.249 0 01-.25.25h-1.25a1 1 0 00-.857 1.517l3 5a1 1 0 001.715 0l3-5a1 1 0 00-.858-1.514h-1.25a.25.25 0 01-.25-.25V6.624a1.5 1.5 0 00-1.5-1.5zM17.519 18.84a1.5 1.5 0 001.5-1.5V7.233a.249.249 0 01.25-.25h1.25a1 1 0 00.857-1.515l-3-5a1.04 1.04 0 00-1.715 0l-3 5a1 1 0 00.858 1.515h1.25a.25.25 0 01.25.25V17.34a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});