define("ember-svg-jar/inlined/golf-hole-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-hole-1</title><path d=\"M17.5 10c-2.648 0-4.979.953-5.98 2.407a13.028 13.028 0 00-5.017-.282V8.759l4.371-2.087a1.5 1.5 0 00-.118-2.757l-4.9-1.85A1 1 0 004.505 3v14a1 1 0 002 0v-1.066A1.747 1.747 0 017.754 17.5a2.024 2.024 0 01-2.249 1.75 2.025 2.025 0 01-2.25-1.75v-3.66a.5.5 0 00-.748-.434A5.09 5.09 0 00.2 15.9c-.95 2.608 1.719 4.329 1.784 4.374A11.541 11.541 0 008.254 22c3.831 0 7.364-1.609 8.108-4.095C20.5 18.359 24 16.447 24 13.984 24 11.75 21.146 10 17.5 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});