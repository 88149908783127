define("ember-svg-jar/inlined/stove-pan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-pan</title><path d=\"M23 3.625H1a1 1 0 000 2h6.5a.5.5 0 01.5.5v2.5a2 2 0 002 2h12a2 2 0 002-2v-4a1 1 0 00-1-1zM19.429 13.627a.5.5 0 00-.377.227l-.818 1.266a.251.251 0 01-.407.019l-1.434-1.823a.518.518 0 00-.786 0l-1.434 1.823a.25.25 0 01-.406-.019l-.819-1.266a.5.5 0 00-.377-.227.481.481 0 00-.411.159c-.067.073-1.66 1.818-1.66 3.451a2.905 2.905 0 002.583 3.138h5.834a2.905 2.905 0 002.583-3.138c0-1.633-1.593-3.378-1.66-3.451a.485.485 0 00-.411-.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});