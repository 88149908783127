define("ember-svg-jar/inlined/outdoors-horse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-horse</title><path d=\"M23.621 8.7l-3.536-2.214V4.848a.378.378 0 00-.571-.324 16.977 16.977 0 00-4.3 3.848 2.222 2.222 0 01.256 1.039 2.257 2.257 0 01-2.25 2.232 6.5 6.5 0 01-3.076-.768V9.581a5.178 5.178 0 002.956 1.053 1.251 1.251 0 001.36-1.215v-.017a1.249 1.249 0 00-1.238-1.259 2.6 2.6 0 01-2.163-1.233l-1.1-1.818a1.25 1.25 0 00-2.319.648v5.135h-1.8a2.19 2.19 0 00-.565.081.994.994 0 00-.289-.226 3.013 3.013 0 00-4.173 1.637L.09 14.293a1 1 0 101.873.7l.723-1.927a1.022 1.022 0 01.578-.587 1.007 1.007 0 01.445-.061 2.225 2.225 0 00-.124.7v1.028a1.762 1.762 0 01-.186.783l-.577 1.156a2.254 2.254 0 00-.237 1.015v5.528a1.25 1.25 0 002.5 0v-4.175a1.733 1.733 0 01.515-1.237l.485-.485c0 .268-.007.5-.011.712-.029 1.755-.021 1.792.985 3.973.2.424.43.93.709 1.549a1.287 1.287 0 002.366-1.006l-.456-1.105c-1.111-2.678-1.111-2.678-.379-4.1l.123-.239a.249.249 0 01.223-.138H13.4a.25.25 0 01.248.217l.826 6.2a1.319 1.319 0 002.615-.165v-.739a24.391 24.391 0 012.879-11.49l2.3.423a1.248 1.248 0 001.409-.559l.251-.673a.753.753 0 00-.307-.891z\"/><circle cx=\"9.335\" cy=\"2.125\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});