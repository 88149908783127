define("ember-svg-jar/inlined/earth-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-move</title><path d=\"M12 4.992a7 7 0 000 14 7 7 0 000-14zm.193 11.964a.25.25 0 01-.386 0 8.091 8.091 0 01-1.255-2.121.248.248 0 01.025-.233.251.251 0 01.207-.11h2.432a.25.25 0 01.232.343 8.074 8.074 0 01-1.255 2.121zm-1.9-3.964a.249.249 0 01-.248-.222 6.986 6.986 0 01-.045-.778 7.206 7.206 0 01.044-.777.25.25 0 01.249-.223h3.414a.249.249 0 01.248.223 6.739 6.739 0 010 1.555.248.248 0 01-.248.222zm-3.793-1a5.38 5.38 0 01.06-.787.25.25 0 01.247-.213h1.477a.25.25 0 01.249.272c-.02.236-.033.478-.033.728s.013.493.033.729a.25.25 0 01-.249.271H6.807a.25.25 0 01-.247-.213 5.369 5.369 0 01-.06-.787zm5.307-4.963A.245.245 0 0112 6.938a.248.248 0 01.193.091 8.1 8.1 0 011.255 2.12.25.25 0 01-.232.343h-2.432a.25.25 0 01-.232-.343 8.083 8.083 0 011.255-2.12zm3.66 4.235a.25.25 0 01.249-.272h1.477a.25.25 0 01.247.213 5.191 5.191 0 010 1.574.249.249 0 01-.247.213h-1.477a.25.25 0 01-.249-.271c.02-.236.033-.478.033-.729s-.013-.492-.033-.728zm1.211-2.153a.25.25 0 01-.213.381h-1.127a.25.25 0 01-.239-.177 9.329 9.329 0 00-.746-1.771.251.251 0 01.343-.338 5.542 5.542 0 011.982 1.905zM9.3 7.206a.251.251 0 01.343.338A9.329 9.329 0 008.9 9.315a.25.25 0 01-.239.177H7.535a.249.249 0 01-.212-.381A5.531 5.531 0 019.3 7.206zm-1.978 7.668a.253.253 0 010-.254.25.25 0 01.218-.128h1.122a.25.25 0 01.239.178 9.318 9.318 0 00.746 1.77.25.25 0 01-.343.338 5.53 5.53 0 01-1.982-1.904zm7.374 1.9a.251.251 0 01-.343-.338 9.318 9.318 0 00.746-1.77.25.25 0 01.239-.178h1.127a.25.25 0 01.212.382 5.52 5.52 0 01-1.977 1.908zM9.13 2.655a.5.5 0 00.37.837h5a.5.5 0 00.37-.837L12.74.319a1.035 1.035 0 00-1.48 0zM23.673 11.253l-2.336-2.13a.5.5 0 00-.837.369v5a.5.5 0 00.3.458.512.512 0 00.2.042.5.5 0 00.337-.13l2.336-2.13a1 1 0 000-1.479zM14.5 20.492h-5a.5.5 0 00-.37.837l2.13 2.337a1 1 0 001.48 0l2.13-2.337a.5.5 0 00-.37-.837zM3.2 9.035a.5.5 0 00-.539.088l-2.334 2.13a1 1 0 000 1.479l2.336 2.13a.5.5 0 00.337.13.512.512 0 00.2-.042.5.5 0 00.3-.458v-5a.5.5 0 00-.3-.457z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});