define("ember-svg-jar/inlined/e-commerce-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-shopping-bag</title><path d=\"M19.941 16.824l-.9-8.342A1.677 1.677 0 0017.357 7H15.75a.25.25 0 01-.25-.25v-1A3.635 3.635 0 0012 2a3.635 3.635 0 00-3.5 3.75v1a.25.25 0 01-.25.25H6.643a1.677 1.677 0 00-1.681 1.482l-.9 8.342A10.234 10.234 0 004 17.917 2.617 2.617 0 006.643 20.5h10.714A2.617 2.617 0 0020 17.917a10.234 10.234 0 00-.059-1.093zm-9.441.593a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.142l-2.063-.825a2.092 2.092 0 01.36-3.992.249.249 0 00.2-.244v-.3a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.825a2.092 2.092 0 01-.36 3.992.249.249 0 00-.2.244v.3a.75.75 0 01-1.5 0v-.25a.249.249 0 00-.25-.25zm0-11.667A1.641 1.641 0 0112 4a1.641 1.641 0 011.5 1.75v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zM1 4a1 1 0 001-1v-.75A.25.25 0 012.25 2H3a1 1 0 000-2H2a2 2 0 00-2 2v1a1 1 0 001 1zM3 22h-.75a.25.25 0 01-.25-.25V21a1 1 0 00-2 0v1a2 2 0 002 2h1a1 1 0 000-2zM22 0h-1a1 1 0 000 2h.75a.25.25 0 01.25.25V3a1 1 0 002 0V2a2 2 0 00-2-2zM23 20a1 1 0 00-1 1v.75a.25.25 0 01-.25.25H21a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});