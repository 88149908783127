define("ember-svg-jar/inlined/single-man-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-home</title><path d=\"M22.875 7.747L12.832.76a1.464 1.464 0 00-1.664 0L1.125 7.747A1.455 1.455 0 00.5 8.943v13.1A1.458 1.458 0 001.957 23.5h20.086a1.458 1.458 0 001.457-1.458V8.943a1.455 1.455 0 00-.625-1.196zm-12.985.6a.251.251 0 01-.025-.385 3.5 3.5 0 015.524.938.251.251 0 01-.146.354 5.936 5.936 0 01-1.791.277A6.168 6.168 0 019.89 8.344zm-1.046 1.4a.238.238 0 01.367-.137 7.383 7.383 0 006.237 1.061.24.24 0 01.219.048.234.234 0 01.081.208 3.513 3.513 0 01-7-.428 3.227 3.227 0 01.096-.752zm9.032 10.164a.748.748 0 01-.626.338h-10a.751.751 0 01-.689-1.049 6.305 6.305 0 015.689-3.953 6.3 6.3 0 015.689 3.953.749.749 0 01-.063.711z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});