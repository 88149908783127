define("ember-svg-jar/inlined/real-estate-sign-building-sold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-building-sold</title><path d=\"M8.5 12.5a.5.5 0 01.5.5v1a.5.5 0 00.5.5H11a.5.5 0 00.5-.5V6.5a.5.5 0 00-.276-.448l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 004.5 6.5V14a.5.5 0 00.5.5h1.5A.5.5 0 007 14v-1a.5.5 0 01.5-.5zM6 7.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm.5 3a.5.5 0 010-1h3a.5.5 0 010 1zM12.5 14a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 0012.5.5zM14 5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3A.5.5 0 0114 5zm0 2.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm0 2.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM18 16.5a.75.75 0 00-.75.75v6A.75.75 0 0018 24a3.75 3.75 0 000-7.5zm.974 5.778a.157.157 0 01-.224-.141v-3.618a.25.25 0 01.375-.217 2.249 2.249 0 01-.151 3.976zM9.5 16.5a2.253 2.253 0 00-2.25 2.25v3a2.25 2.25 0 004.5 0v-3A2.253 2.253 0 009.5 16.5zm.75 5.25a.75.75 0 01-1.5 0v-3a.75.75 0 011.5 0zM16.75 23.25a.75.75 0 00-.75-.75h-2a.25.25 0 01-.25-.25v-5a.75.75 0 00-1.5 0v5A1.752 1.752 0 0014 24h2a.75.75 0 00.75-.75zM4.3 18H6a.75.75 0 000-1.5H4.3a2.053 2.053 0 00-1.139 3.761L5 21.487a.553.553 0 01-.3 1.013H3A.75.75 0 003 24h1.7a2.053 2.053 0 001.139-3.761L4 19.014A.553.553 0 014.3 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});