define("ember-svg-jar/inlined/organic-seedling-growth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-seedling-growth</title><path d=\"M1 12.5h.018c7.2-.126 11.135-3.026 12.33-9.092a.5.5 0 01.491-.4h.661a.5.5 0 00.354-.854l-2-2a.5.5 0 00-.707 0l-2 2A.5.5 0 0010.5 3h.269a.5.5 0 01.486.618C10.122 8.31 6.982 10.39.983 10.5a1 1 0 00.017 2z\"/><path d=\"M22.746 21.5H18a.5.5 0 01-.5-.5V9.282a2.289 2.289 0 01.284-1.1 7.231 7.231 0 013.06-2.334.5.5 0 11.311.95A6.42 6.42 0 0018.5 8.875a.25.25 0 00.059.372 2.1 2.1 0 001.145.319 3.655 3.655 0 002.2-.857A7.3 7.3 0 0023.827 2.2a.5.5 0 00-.72-.3 6.494 6.494 0 01-2.674.709c-1.779.19-3.771.747-4.038 2.515a2.053 2.053 0 00.112 1.025.5.5 0 01-.073.477 4.142 4.142 0 00-.934 2.656v4.108a.25.25 0 01-.412.19.453.453 0 01-.166-.316 1.4 1.4 0 00-.08-.346 2.35 2.35 0 00-1.715-1.318 4 4 0 00-2.355.214 4.842 4.842 0 01-2.015.3.492.492 0 00-.408.14.5.5 0 00-.147.406 5.857 5.857 0 003.391 4.259 4.008 4.008 0 001.1.171 1.673 1.673 0 001.329-.483.248.248 0 00.065-.209.252.252 0 00-.123-.179 8.052 8.052 0 00-2.542-.829.5.5 0 01.147-.989 5.7 5.7 0 013.669 1.663 1.3 1.3 0 01.258.777V21a.5.5 0 01-.5.5H1.25a1.25 1.25 0 000 2.5h21.5a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});