define("ember-svg-jar/inlined/decoration-crystals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-crystals</title><path d=\"M1.747 15.925a1 1 0 00-1.7.948l1.976 6.711a.5.5 0 00.48.359H4.6a.249.249 0 00.239-.321l-1.858-6.314zM11.14 18.99L8.5 9.379a.509.509 0 00-.158-.251L5.4 6.608a.5.5 0 00-.749.114l-2.072 3.319a.5.5 0 00-.057.4L6.4 23.584a.5.5 0 00.48.359h2.172a.5.5 0 00.469-.326l1.606-4.317a.5.5 0 00.013-.31zM23.455 8.292l-1.261-4.2a.5.5 0 00-.756-.272l-3.8 2.533a.5.5 0 00-.194.249l-6.318 17.004a.25.25 0 00.234.337h6.287a.5.5 0 00.471-.333L23.45 8.593a.5.5 0 00.005-.301zM23.49 15.82a1 1 0 00-1.03.042l-2.75 7.747a.25.25 0 00.236.334H22.5a.5.5 0 00.5-.426l.992-6.678a1 1 0 00-.502-1.019zM9.95 8.995l1.922 7a.25.25 0 00.475.021l3.622-9.747A.5.5 0 0016 6.091V4.786a1 1 0 00-.21-.614L13.29.444a1 1 0 00-1.579 0l-2.5 3.728A1 1 0 009 4.786v2.692a.5.5 0 00.182.386 2.152 2.152 0 01.768 1.131z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});