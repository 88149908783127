define("ember-svg-jar/inlined/social-media-mixx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-mixx</title><path d=\"M24 3a.5.5 0 00-.5-.5H.5A.5.5 0 000 3v18a.5.5 0 00.5.5H5a.5.5 0 00.5-.5V9a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v12a.5.5 0 00.5.5h4a.5.5 0 00.5-.5V9a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v12a.5.5 0 00.5.5h4.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});