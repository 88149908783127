define("ember-svg-jar/inlined/instrument-trumpet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-trumpet</title><path d=\"M22.365 5.751L18.5 8.919a.249.249 0 01-.158.057H13.75a.25.25 0 01-.25-.25v-.75a1 1 0 10-2 0v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.75a1 1 0 10-2 0v.75a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25v-.25a1 1 0 10-2 0v3a1 1 0 002 0v-.25a.25.25 0 01.25-.25h.877a.249.249 0 01.228.353A4 4 0 007 16.976h.25a.25.25 0 01.25.25v.25a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v.25a1 1 0 002 0v-.25a.25.25 0 01.25-.25H14a4 4 0 003.645-5.647.251.251 0 01.228-.353h.471a.255.255 0 01.159.056l3.864 3.157A1 1 0 0024 13.415v-6.89a1 1 0 00-1.635-.774zM7 14.976a2 2 0 110-4h.25a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25zm2.75 0a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25zm6.25-2a2 2 0 01-2 2h-.25a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25H14a2 2 0 012 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});