define("ember-svg-jar/inlined/ecology-leaf-book-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-leaf-book-open</title><path d=\"M23.392 3.09a2 2 0 00-1.455-.564q-.33.011-.655.027a1 1 0 10.193 1.993.5.5 0 01.525.5v12.977a.5.5 0 01-.479.5 23.124 23.124 0 00-7.82 1.5.5.5 0 01-.7-.458v-6.821a1 1 0 00-2 0v6.824a.5.5 0 01-.7.458 23.15 23.15 0 00-7.82-1.5.5.5 0 01-.479-.5V5.046a.5.5 0 01.524-.5 28.288 28.288 0 013.79.431 1 1 0 00.37-1.966 30.287 30.287 0 00-4.622-.485 2.047 2.047 0 00-1.454.563A2.01 2.01 0 000 4.525v13.98a1.99 1.99 0 001.941 2C7.918 20.68 11 22.326 11 22.99a1 1 0 002 0c0-.664 3.082-2.31 9.059-2.486a1.99 1.99 0 001.941-2V4.525a2.009 2.009 0 00-.608-1.435z\"/><path d=\"M9.123 5.782a20.449 20.449 0 00-3.358 3.4.75.75 0 001.212.884 24.294 24.294 0 017.033-5.807.75.75 0 01.725 1.313A23.375 23.375 0 0011.12 8.04a.5.5 0 00-.049.723 2.292 2.292 0 00.586.461 2.515 2.515 0 001.14.293 5.406 5.406 0 002.72-.8c2.037-1.131 4.315-5.346 3.943-8.271a.5.5 0 00-.66-.407 8.88 8.88 0 01-3.733.3 6.664 6.664 0 00-3.917.516A3.8 3.8 0 008.921 3.5a2.8 2.8 0 00.329 1.667.5.5 0 01-.127.615z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});