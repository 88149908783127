define("ember-svg-jar/inlined/user-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-idea</title><path d=\"M17.325 12.475h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM16.325 2.625a.75.75 0 00.75-.75v-1a.75.75 0 00-1.5 0v1a.75.75 0 00.75.75zM9.625 7.625h1.2a.75.75 0 000-1.5h-1.2a.75.75 0 000 1.5zM23.125 6.125h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM12.855 2.844l-1-1A.75.75 0 1010.8 2.905l1 1a.75.75 0 001.06-1.061zM21.855 1.844a.749.749 0 00-1.06 0l-1 1a.75.75 0 101.06 1.061l1-1a.75.75 0 000-1.061zM16.325 3.975a3.848 3.848 0 00-3.75 3.75 3.639 3.639 0 001.44 2.856.158.158 0 01.06.124v.02a1.252 1.252 0 001.25 1.25h2a1.251 1.251 0 001.25-1.25.19.19 0 01.072-.148 3.639 3.639 0 001.428-2.847 3.848 3.848 0 00-3.75-3.755zm1.125 5.6a.751.751 0 00-.375.65.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25.749.749 0 00-.375-.65 2.2 2.2 0 01-1.125-1.85 2.338 2.338 0 012.25-2.25 2.338 2.338 0 012.25 2.25 2.2 2.2 0 01-1.125 1.85zM8.7 17.961a.25.25 0 01-.036-.431 4.566 4.566 0 10-7.189-3.73A4.541 4.541 0 003.5 17.569a.249.249 0 01-.032.431 6 6 0 00-3.343 5.375.5.5 0 00.5.5h11a.5.5 0 00.5-.5A6 6 0 008.7 17.961zm-5.073-5.009A.25.25 0 014 12.828a5.86 5.86 0 004.463.84.135.135 0 01.163.131 2.575 2.575 0 11-5.15 0 2.455 2.455 0 01.151-.847z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});