define("ember-svg-jar/inlined/multiple-actions-address.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-address</title><path d=\"M15.91 9.153a.5.5 0 00.03-.977A6.031 6.031 0 0014.489 8a5.958 5.958 0 00-2.466.534.5.5 0 00-.182.77 7.507 7.507 0 01.553.779.5.5 0 00.687.163 8.292 8.292 0 012.829-1.093z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M5.989 8A6.014 6.014 0 000 13.332.6.6 0 00.6 14h8.847a.5.5 0 00.448-.277 8.521 8.521 0 011.34-1.959.5.5 0 00.072-.573A6.019 6.019 0 005.989 8z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M17.424 10.5a6.717 6.717 0 00-5.4 2.471 6.786 6.786 0 00-1.35 5.843 6.672 6.672 0 004.987 5 7.081 7.081 0 001.59.186 6.7 6.7 0 001.735-.227 1 1 0 00-.512-1.933 4.877 4.877 0 01-2.363.028 4.728 4.728 0 01-3.484-3.495 4.8 4.8 0 01.947-4.132 4.7 4.7 0 013.8-1.74A4.921 4.921 0 0122 17.516v.811a.8.8 0 01-1.594 0v-1.078a3.156 3.156 0 10-3.156 3.156 3.094 3.094 0 001.476-.377.25.25 0 01.312.063A2.793 2.793 0 0024 18.327v-.811a6.932 6.932 0 00-6.576-7.016zm-.174 7.9a1.157 1.157 0 111.156-1.157 1.157 1.157 0 01-1.156 1.163z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});