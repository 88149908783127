define("ember-svg-jar/inlined/audio-file-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-shield</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.266a.248.248 0 00.244.25c.368.005 1.045.021 1.5.047A.246.246 0 008 9.314V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.184 8.188 8.188 0 01-.53 1.46.245.245 0 00.218.355H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M12.673 10.665a3.047 3.047 0 011.827 2.791s-.006.073.086.048a2.248 2.248 0 001.647-2 .734.734 0 00.017-.084V6.277c0-.182.137-.235.263-.156l1.088.684a.75.75 0 10.8-1.269l-1.818-1.141A1.25 1.25 0 0014.75 5.5v3.564c0 .136-.1.128-.148.114a2.144 2.144 0 00-.6-.09 2.246 2.246 0 00-1.963 1.162.215.215 0 00.088.208c.209.072.398.142.546.207zM12.072 12.04A16.386 16.386 0 006.479 11a15.988 15.988 0 00-5.551 1.04A1.545 1.545 0 000 13.456v3.393a7.716 7.716 0 005.19 6.805A9 9 0 006.479 24a9.023 9.023 0 001.288-.346C10.848 22.48 13 19.682 13 16.849v-3.393a1.545 1.545 0 00-.928-1.416zM9.5 17.25h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0v2a.25.25 0 00.25.25h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});