define("ember-svg-jar/inlined/car-actions-settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-settings-1</title><path d=\"M14.02 11.24l.227.053a.251.251 0 00.3-.171l.071-.234a3.275 3.275 0 016.01-.618.041.041 0 00.047.02.04.04 0 00.031-.04V8a2.738 2.738 0 00-1-2.115.249.249 0 01-.09-.195.245.245 0 01.084-.19l.9-.716a1 1 0 00-1.249-1.562l-.725.58a.249.249 0 01-.4-.168l-.1-.88A3.018 3.018 0 0015.135 0H5.817A3.011 3.011 0 002.83 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563A1 1 0 10.376 4.781l.884.707a.251.251 0 010 .389A2.74 2.74 0 00.25 8v2.25A1.752 1.752 0 002 12h8.993a.25.25 0 00.163-.06 3.246 3.246 0 012.864-.7zM4 10.25a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM4.871 5a.249.249 0 01-.248-.277l.2-1.806a1.007 1.007 0 011-.917h9.318a1.013 1.013 0 011 .943l.2 1.78a.252.252 0 01-.063.194.249.249 0 01-.197.083zM6.5 13h-4a.25.25 0 00-.25.25v.25a2.25 2.25 0 104.5 0v-.25A.249.249 0 006.5 13z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M22.529 17.344a.464.464 0 010-.626l.987-1.064a1.783 1.783 0 00-1.7-2.953l-1.411.327a.452.452 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.453.453 0 01-.533.311l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.449.449 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.447.447 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953zm-4.781 2.687a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});