define("ember-svg-jar/inlined/navigation-down-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down-2</title><path d=\"M5.93 19.32l5.443 4.454a.99.99 0 001.254 0l5.443-4.454a.989.989 0 00-.627-1.755h-3.956a.25.25 0 01-.25-.25V7.175a.25.25 0 01.25-.25h3.461a1.237 1.237 0 100-2.474h-9.9a1.237 1.237 0 100 2.474h3.461a.25.25 0 01.25.25v10.14a.25.25 0 01-.25.25H6.557a.989.989 0 00-.627 1.755zM5.814 1.235a1.237 1.237 0 001.238 1.237h9.9a1.237 1.237 0 100-2.474h-9.9a1.237 1.237 0 00-1.238 1.237z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});