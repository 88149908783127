define("ember-svg-jar/inlined/screen-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screen-1-alternate</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5V18a1.5 1.5 0 001.5 1.5h9.25a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H7.5a1 1 0 000 2h9a1 1 0 000-2h-3.25a.25.25 0 01-.25-.25v-2a.25.25 0 01.25-.25h9.25A1.5 1.5 0 0024 18V1.5A1.5 1.5 0 0022.5 0zM22 15a.5.5 0 01-.5.5h-19A.5.5 0 012 15V2.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});