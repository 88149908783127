define("ember-svg-jar/inlined/gesture-tap-swipe-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-left</title><path d=\"M9.5 9.373a1 1 0 00-1-1H3.75a.249.249 0 01-.25-.25v-1.25a.751.751 0 00-1.336-.468l-2 2.5a.75.75 0 000 .937l2 2.5a.751.751 0 001.336-.469v-1.25a.25.25 0 01.25-.25H8.5a1 1 0 001-1z\"/><path d=\"M16.75 3.623a7.251 7.251 0 00-6.138 11.11.75.75 0 101.269-.8 5.751 5.751 0 119.745-.013.75.75 0 001.273.8A7.252 7.252 0 0016.75 3.623z\"/><path d=\"M16.745 7.374a3.5 3.5 0 00-3.5 3.5v9a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-9a3.5 3.5 0 00-3.5-3.5zm1.5 4.626a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375v-1.126a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});