define("ember-svg-jar/inlined/agriculture-machine-harvester-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-harvester-1</title><circle cx=\"9.5\" cy=\"20.25\" r=\"2.5\"/><circle cx=\"1.75\" cy=\"21\" r=\"1.75\"/><path d=\"M6 20.25a3.5 3.5 0 013.5-3.5.5.5 0 00.5-.5v-4.5a.5.5 0 01.5-.5h4.569a1.5 1.5 0 001.48-1.75l-.667-4a1.49 1.49 0 00-.218-.567 1.985 1.985 0 01-.29-.753l-.245-1.471A1.744 1.744 0 0013.4 1.25h-2.3a1.744 1.744 0 00-1.729 1.462l-.187 1.12a.5.5 0 01-.493.418 1.673 1.673 0 00-1.633 1.31L6.3 8.954a.749.749 0 01-.512.554A5.909 5.909 0 002.5 12.043l-1.122 1.826a.25.25 0 00.213.381h.659a.75.75 0 010 1.5H.573a.5.5 0 00-.48.358A2.276 2.276 0 000 16.75V18a.5.5 0 00.693.461 2.751 2.751 0 013.8 2.327.5.5 0 00.5.462h.541a.5.5 0 00.5-.562A3.528 3.528 0 016 20.25zm4.684-16.291l.132-.791a.5.5 0 01.493-.418h1.882a.5.5 0 01.493.418l.132.791a.25.25 0 01-.246.291h-2.64a.25.25 0 01-.246-.291zM18.562 11.875A5.438 5.438 0 1024 17.313a5.443 5.443 0 00-5.438-5.438zm0 9.375a3.938 3.938 0 112.785-6.719.25.25 0 01-.294.4 3.725 3.725 0 00-1.741-.429 3.153 3.153 0 00-3.149 3.15 2.672 2.672 0 002.669 2.67.75.75 0 100-1.5 1.171 1.171 0 01-1.169-1.17A1.651 1.651 0 0119.312 16a2.253 2.253 0 012.251 2.25 3 3 0 01-3.001 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});