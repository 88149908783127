define("ember-svg-jar/inlined/car-clouds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-clouds</title><path d=\"M2.206 7.5h6.357a.473.473 0 00.065 0 2.66 2.66 0 001.56-4.557 2.627 2.627 0 00-1.4-.7.5.5 0 01-.334-.214A3.474 3.474 0 005.553.5 3.655 3.655 0 002.1 2.973a.5.5 0 01-.383.337A2.114 2.114 0 000 5.387a1.99 1.99 0 00.57 1.478 2.471 2.471 0 001.636.635zM23.188 6.439a2.627 2.627 0 00-1.4-.7.5.5 0 01-.334-.214A3.474 3.474 0 0018.553 4 3.655 3.655 0 0015.1 6.473a.5.5 0 01-.383.337A2.114 2.114 0 0013 8.887a1.99 1.99 0 00.57 1.478 2.471 2.471 0 001.636.635h6.357a.473.473 0 00.065 0 2.66 2.66 0 001.56-4.557zM18.118 20.75a.483.483 0 01-.477-.4l-.363-2.183a7.045 7.045 0 00-6.969-5.913 7.42 7.42 0 00-6.825 4.5l-.206.482a.5.5 0 01-.408.3 3.206 3.206 0 00-2.161 1 3.467 3.467 0 00-.66 2.844 1 1 0 00.992.871h.3a.5.5 0 01.433.25 2 2 0 003.459 0 .5.5 0 01.433-.25h6.175a.5.5 0 01.433.25 2 2 0 003.577-.24.016.016 0 01.015-.01h2.255a.75.75 0 000-1.5zm-4.43-3.733a.5.5 0 01-.422.233H6.97a.5.5 0 01-.452-.714 3.977 3.977 0 017.2 0 .5.5 0 01-.03.481z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});