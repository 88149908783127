define("ember-svg-jar/inlined/road-sign-crossroad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-crossroad</title><path d=\"M23.251 23.235a.751.751 0 00.66-1.105l-11.25-21a.782.782 0 00-1.322 0l-11.25 21a.751.751 0 00.66 1.105zm-6.016-8.5a.749.749 0 01-.749.75h-3.5a.25.25 0 00-.25.25v3.5a.75.75 0 11-1.5 0v-3.5a.249.249 0 00-.249-.25h-3.5a.75.75 0 010-1.5h3.5a.249.249 0 00.249-.25v-3.5a.75.75 0 111.5 0v3.5a.25.25 0 00.25.25h3.5a.749.749 0 01.749.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});