define("ember-svg-jar/inlined/transportation-ticket-boat-bus-transfer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-boat-bus-transfer</title><path d=\"M14.221 10a2.561 2.561 0 01-2.154-.675.749.749 0 00-1.06 0 1.974 1.974 0 01-2.966 0 .75.75 0 00-1.061 0 1.974 1.974 0 01-2.966 0 .749.749 0 00-1.06 0A2.561 2.561 0 01.8 10a.75.75 0 000 1.5 4.389 4.389 0 002.547-.574.249.249 0 01.271 0 3.374 3.374 0 003.756 0 .246.246 0 01.273 0 3.374 3.374 0 003.756 0 .249.249 0 01.271 0 4.389 4.389 0 002.547.574.75.75 0 000-1.5zM15.887 10.393c.037.029.108.092.183.16a.253.253 0 01.082.2.25.25 0 01-.106.189.682.682 0 00-.306.6.778.778 0 00.463.693l1.96.812a.749.749 0 00.98-.4l.812-1.96a.75.75 0 00-.979-.981l-.528.219a.249.249 0 01-.288-.072 6.925 6.925 0 00-1.038-1.033 1 1 0 00-1.4.168 1.046 1.046 0 00.169 1.405zM13.127 19.316a4.985 4.985 0 01-4.936-3.078.123.123 0 01.011-.11.119.119 0 01.1-.052.75.75 0 00.53-1.28l-1.5-1.5a.75.75 0 00-1.061 0l-1.5 1.5a.75.75 0 00.531 1.28h.547a.249.249 0 01.24.182 6.948 6.948 0 006.711 5.068c.152 0 .3 0 .458-.014a1 1 0 10-.126-2zM22.2 13.252h-5A1.753 1.753 0 0015.45 15v6a1.743 1.743 0 00.875 1.508.252.252 0 01.125.217v.52a.75.75 0 001.5 0V23a.25.25 0 01.25-.25h3a.25.25 0 01.25.25v.245a.75.75 0 001.5 0v-.52a.251.251 0 01.125-.216A1.746 1.746 0 0023.95 21v-6a1.752 1.752 0 00-1.75-1.748zm-4.5 7.5a.75.75 0 11.75-.75.75.75 0 01-.75.746zm4 0a.75.75 0 11.75-.75.75.75 0 01-.75.746zm.75-3a.249.249 0 01-.25.25h-5a.25.25 0 01-.25-.25V15a.247.247 0 01.25-.25h5a.246.246 0 01.25.25zM1.709 8.305a.212.212 0 00.361.138 2 2 0 012.828 0 .817.817 0 00.6.308.815.815 0 00.6-.308 2 2 0 012.829 0 .736.736 0 001.2 0 2 2 0 012.828 0 .836.836 0 00.447.263.249.249 0 00.265-.11l.091-.143a1.2 1.2 0 00-1.008-1.844H8.8a.249.249 0 01-.25-.25V.752A.751.751 0 007.235.258l-3.5 4A.75.75 0 004.3 5.5h2.5a.25.25 0 01.25.25v.606a.25.25 0 01-.25.25H3.352a1.654 1.654 0 00-1.644 1.68V8.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});