define("ember-svg-jar/inlined/light-mode-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-flash</title><path d=\"M18.722 7h-3.605a.253.253 0 01-.218-.127.25.25 0 010-.252L18.412.805a.489.489 0 00-.43-.8h-4a1.174 1.174 0 00-.958.563L7.752 9.692a.518.518 0 00.48.808h2.673a.25.25 0 01.221.366L7.468 17.84a.25.25 0 01-.328.11l-1.635-.774a.5.5 0 00-.751.537l.671 6.064a.249.249 0 00.35.2l6.381-2.822a.479.479 0 00.025-.912l-2.2-.971a.248.248 0 01-.091-.388L19.113 7.8a.467.467 0 00-.391-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});