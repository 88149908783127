define("ember-svg-jar/inlined/dressing-hanging-rack-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dressing-hanging-rack-2</title><path d=\"M16.5 8.5a1.747 1.747 0 00-1.167 3.05.5.5 0 01.167.373V13a1 1 0 01-1 1h-1.25a.25.25 0 01-.25-.25v-6a.25.25 0 01.25-.25h1.25a3 3 0 003-3V3.423a.5.5 0 01.167-.373 1.75 1.75 0 10-2.334 0 .5.5 0 01.167.373V4.5a1 1 0 01-1 1h-1.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v6.25a.25.25 0 01-.25.25H9.5a1 1 0 01-1-1V5.423a.5.5 0 01.167-.373 1.75 1.75 0 10-2.334 0 .5.5 0 01.167.373V6.5a3 3 0 003 3h1.25a.25.25 0 01.25.25v6a.25.25 0 01-.25.25H9.5a1 1 0 01-1-1v-1.077a.5.5 0 01.167-.373 1.75 1.75 0 10-2.334 0 .5.5 0 01.167.373V15a3 3 0 003 3h1.25a.25.25 0 01.25.25V21a.5.5 0 01-.5.5H8.769a2.5 2.5 0 00-2.358 1.667A.625.625 0 007 24h10a.626.626 0 00.59-.833 2.5 2.5 0 00-2.358-1.667H13.5a.5.5 0 01-.5-.5v-4.75a.25.25 0 01.25-.25h1.25a3 3 0 003-3v-1.077a.5.5 0 01.167-.373A1.747 1.747 0 0016.5 8.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});