define("ember-svg-jar/inlined/single-neutral-actions-location.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-location</title><path d=\"M12.749 12.988a.5.5 0 00-.113-.609A7.467 7.467 0 00.521 15.857.5.5 0 001 16.5h10.5a.5.5 0 00.5-.5 6.438 6.438 0 01.749-3.012z\"/><circle cx=\"7.5\" cy=\"4.75\" r=\"4.75\"/><path d=\"M18.5 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0c.189-.19 4.646-4.683 4.646-7.854a5.006 5.006 0 00-5-5zm0 3.25A1.75 1.75 0 1116.75 16a1.752 1.752 0 011.75-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});