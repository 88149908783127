define("ember-svg-jar/inlined/tag-hot-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tag-hot-price</title><path d=\"M3 11a1 1 0 001-1V7.25A.25.25 0 014.25 7H6a.25.25 0 01.25.25V10a.75.75 0 001.5 0V2a.75.75 0 00-1.5 0v2.75A.25.25 0 016 5H4.25A.25.25 0 014 4.75V2a1 1 0 00-2 0v8a1 1 0 001 1zM12.25 11A3.821 3.821 0 0016 7.121a6.232 6.232 0 00-4.548-6.01 1 1 0 00-.845 1.764 1.464 1.464 0 01.378.437.249.249 0 01.015.208.253.253 0 01-.145.15l-.023.009A3.641 3.641 0 008.5 7.121 3.821 3.821 0 0012.25 11zm-1.58-4.656a.25.25 0 01.395-.072c.072.068.138.124.19.168a1 1 0 001.5-.225 3.044 3.044 0 00.364-.888.25.25 0 01.469-.047A4.344 4.344 0 0114 7.121 1.82 1.82 0 0112.25 9a1.82 1.82 0 01-1.75-1.879 1.727 1.727 0 01.17-.777zM16 3h1.25a.25.25 0 01.25.25V10a1 1 0 002 0V3.25a.25.25 0 01.25-.25H21a1 1 0 000-2h-5a1 1 0 000 2zM13.2 21.02a.249.249 0 01-.2-.245v-3.55a.25.25 0 01.2-.245A1 1 0 0013 15h-2a1 1 0 00-.2 1.98.249.249 0 01.2.245v3.55a.25.25 0 01-.2.245A1 1 0 0011 23h2a1 1 0 00.2-1.98z\"/><path d=\"M18 21a2 2 0 010-4 1 1 0 000-2 4 4 0 000 8 1 1 0 000-2z\"/><path d=\"M23 21h-1.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H22a1 1 0 000-2h-.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H23a1 1 0 000-2h-2a2 2 0 00-2 2v4a2 2 0 002 2h2a1 1 0 000-2zM2.5 15H1a1 1 0 00-1 1v6a1 1 0 002 0v-1.75a.25.25 0 01.25-.25h.25a2.5 2.5 0 000-5zm0 3h-.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h.25a.5.5 0 010 1z\"/><path d=\"M8.551 20.137a.251.251 0 01.065-.4A2.5 2.5 0 007.5 15H6a1 1 0 00-1 1v6a1 1 0 002 0 .243.243 0 01.414-.172l.879.879a1 1 0 101.414-1.414zM7 17.25a.25.25 0 01.25-.25h.25a.5.5 0 010 1h-.25a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});