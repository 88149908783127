define("ember-svg-jar/inlined/protection-sand-bag-soldier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-sand-bag-soldier</title><path d=\"M7.5 12h4.3a.5.5 0 00.479-.357 6.492 6.492 0 01.9-1.857.5.5 0 00-.415-.786H2v-.75a.75.75 0 00-1.5 0v1.5a.75.75 0 00.75.75h3.962A2.5 2.5 0 007.5 12zM6.75 19.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5zM15.25 19.5h-3.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5z\"/><path d=\"M18.5 8.5a5 5 0 00-5 5v.414a3.236 3.236 0 011 2.336 3.174 3.174 0 01-.189 1.081.5.5 0 00.471.669h.468a3.254 3.254 0 013.25 3.25 3.218 3.218 0 01-.988 2.32.25.25 0 00.174.43H22.5a1 1 0 001-1v-9.5a5 5 0 00-5-5zM7.75 14.5a1.75 1.75 0 000 3.5h3.5a1.75 1.75 0 000-3.5z\"/><path d=\"M16.05 5.5a.5.5 0 00-.49.6 3 3 0 005.88 0 .5.5 0 00-.491-.6zM15.036 4h6.928a.5.5 0 00.5-.571 4 4 0 00-7.919 0 .5.5 0 00.5.571z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});