define("ember-svg-jar/inlined/single-man-actions-check-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-check-2</title><path d=\"M11.844 11.842a7.128 7.128 0 01.7-.607.25.25 0 00-.054-.427l-1.335-.573a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.527 2.527 0 00.157-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.8a.25.25 0 00.249-.227 7.949 7.949 0 012.295-4.931z\"/><path d=\"M23.048 18.19a1 1 0 00-1.253.656A4.5 4.5 0 1117.5 13a1 1 0 000-2 6.5 6.5 0 106.2 8.443 1 1 0 00-.652-1.253z\"/><path d=\"M22.292 12.668l-4.616 4.615a.249.249 0 01-.353 0L16.144 16.1a1 1 0 00-1.414 1.42l1.577 1.58a1.69 1.69 0 002.386 0l5.013-5.014a1 1 0 10-1.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});