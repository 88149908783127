define("ember-svg-jar/inlined/wifi-subtract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-subtract</title><path d=\"M17.554 11.024a6.361 6.361 0 106.362 6.361 6.368 6.368 0 00-6.362-6.361zm3.193 6.361a.75.75 0 01-.75.75h-4.885a.75.75 0 110-1.5H20a.75.75 0 01.747.75zM15.212 8.663a1 1 0 001.414-1.414 9.75 9.75 0 00-13.775 0 1 1 0 001.414 1.414 7.751 7.751 0 0110.947 0z\"/><path d=\"M17.684 5.537A1 1 0 0019.1 4.123a13.255 13.255 0 00-18.721 0 1 1 0 001.413 1.414 11.253 11.253 0 0115.892 0zM13.5 11.02a.978.978 0 00.152-.447.989.989 0 00-.423-.941 6.252 6.252 0 00-7.9.767 1 1 0 101.414 1.414 4.251 4.251 0 015.371-.523.984.984 0 00.331.138 1 1 0 001.055-.408z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});