define("ember-svg-jar/inlined/phone-action-record-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-record-1</title><path d=\"M12 13.559V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.26a.245.245 0 00.24-.241 7.969 7.969 0 01.3-1.942A.245.245 0 008.569 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.228a.241.241 0 00-.163-.228 7.97 7.97 0 01-1.46-.691.244.244 0 00-.377.206z\"/><path d=\"M16.5 0A6.5 6.5 0 1023 6.5 6.508 6.508 0 0016.5 0zm0 11A4.5 4.5 0 1121 6.5a4.505 4.505 0 01-4.5 4.5z\"/><circle cx=\"16.5\" cy=\"6.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});