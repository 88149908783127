define("ember-svg-jar/inlined/construction-mortar-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-mortar-machine</title><path d=\"M15.761 6.05a.5.5 0 00.443-.733l-2.126-4.031A1.339 1.339 0 0012.953.6H3.7a1.344 1.344 0 00-1.125.683L.436 5.316a.5.5 0 00.441.734zM.567 7.55a.5.5 0 00-.494.581A7.974 7.974 0 007.866 14.9h.925a7.973 7.973 0 007.792-6.769.5.5 0 00-.493-.581z\"/><path d=\"M24 6.4a2.085 2.085 0 00-.436-1.2l-1.859-2.237A1.7 1.7 0 0020.5 2.4H19a1 1 0 00-1 1v12.5a.5.5 0 01-.5.5H1a1 1 0 00-1 1v2a1 1 0 001 1h.3a2.747 2.747 0 014.828-.125.252.252 0 00.217.125h11.308a.252.252 0 00.217-.125 2.747 2.747 0 014.83.125h.3a1 1 0 001-1z\"/><circle cx=\"20.25\" cy=\"21.65\" r=\"1.75\"/><circle cx=\"3.75\" cy=\"21.65\" r=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});