define("ember-svg-jar/inlined/remove-badge-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-badge-alternate</title><path d=\"M23.031 9.978L21.194 8.5a.594.594 0 01-.219-.528l.255-2.345a2.592 2.592 0 00-2.858-2.859l-2.346.255a.584.584 0 01-.527-.218L14.021.967a2.594 2.594 0 00-4.043 0L8.5 2.805a.589.589 0 01-.528.219l-2.344-.255a2.593 2.593 0 00-2.859 2.859l.256 2.344a.6.6 0 01-.218.528L.967 9.978a2.6 2.6 0 000 4.043L2.805 15.5a.6.6 0 01.22.529l-.256 2.341a2.593 2.593 0 002.858 2.86l2.346-.256a.579.579 0 01.527.219l1.478 1.837a2.593 2.593 0 004.043 0l1.479-1.836a.594.594 0 01.528-.22l2.345.256a2.593 2.593 0 002.859-2.859l-.255-2.346a.59.59 0 01.218-.527l1.839-1.478a2.593 2.593 0 000-4.042zm-1.253 2.483L19.94 13.94a2.575 2.575 0 00-.953 2.3l.255 2.346a.589.589 0 01-.17.483.6.6 0 01-.484.17l-2.346-.255a2.588 2.588 0 00-2.3.953l-1.478 1.838a.61.61 0 01-.924 0l-1.479-1.839a2.577 2.577 0 00-2.017-.969 2.772 2.772 0 00-.286.016l-2.346.255a.6.6 0 01-.482-.17.587.587 0 01-.171-.483l.256-2.347a2.586 2.586 0 00-.953-2.3l-1.841-1.476a.6.6 0 010-.925l1.839-1.478a2.583 2.583 0 00.954-2.3L4.757 5.41a.592.592 0 01.654-.653l2.346.255a2.584 2.584 0 002.3-.952l1.479-1.839a.611.611 0 01.923 0L13.94 4.06a2.579 2.579 0 002.3.952l2.346-.255a.592.592 0 01.653.654l-.255 2.346a2.584 2.584 0 00.953 2.3l1.838 1.478a.592.592 0 010 .924z\"/><path d=\"M16.383 7.616a1.249 1.249 0 00-1.767 0l-2.44 2.44a.25.25 0 01-.353 0l-2.44-2.44a1.25 1.25 0 00-1.767 1.768l2.439 2.439a.25.25 0 010 .354l-2.439 2.439a1.249 1.249 0 000 1.768 1.277 1.277 0 001.767 0l2.44-2.439a.249.249 0 01.353 0l2.44 2.439a1.266 1.266 0 001.767 0 1.249 1.249 0 000-1.768l-2.439-2.439a.25.25 0 010-.354l2.439-2.439a1.249 1.249 0 000-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});