define("ember-svg-jar/inlined/athletics-jumping-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>athletics-jumping-person</title><circle cx=\"14\" cy=\"2.5\" r=\"2.5\"/><path d=\"M22.561 5.939a1.5 1.5 0 00-2.122 0L18.73 7.648a1.5 1.5 0 01-1.515.369l-7.667-2.44a4.48 4.48 0 00-4.294.872L3.023 8.361a1.5 1.5 0 001.954 2.278l2.23-1.912a1.478 1.478 0 011.431-.29l1.721.547a.257.257 0 01.152.135.254.254 0 010 .2L8.086 14.85a.251.251 0 01-.229.15H2.5a1.5 1.5 0 000 3H9a1.5 1.5 0 001.374-.9l.724-1.65 3.5-.318a1.5 1.5 0 011.275.517l1.992 2.325a1.5 1.5 0 002.278-1.954l-1.997-2.32a4.492 4.492 0 00-3.824-1.554l-1.847.168.933-2.125a.251.251 0 01.3-.138l2.593.825a4.491 4.491 0 004.551-1.106l1.709-1.709a1.5 1.5 0 000-2.122z\"/><path d=\"M12.941 17.28a1 1 0 00-1 1V23a1 1 0 002 0v-4.72a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});