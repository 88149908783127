define("ember-svg-jar/inlined/single-neutral-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-question</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.75-4.109v.359a.75.75 0 01-1.5 0V7.2a1.257 1.257 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.375 2.513zM5.5 8.5A5.506 5.506 0 000 14v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5V14a5.506 5.506 0 00-5.5-5.5z\"/><circle cx=\"5.5\" cy=\"4\" r=\"3.5\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zm0 11.5a1 1 0 111-1 1 1 0 01-1 1zm.75-4.109v.359a.75.75 0 01-1.5 0V7.2a1.257 1.257 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 113.375 2.513z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});