define("ember-svg-jar/inlined/quill-circle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>quill-circle-1</title><path d=\"M23.853.149a.5.5 0 00-.431-.14 36.56 36.56 0 00-4.664 1.141.506.506 0 00-.331.333l-1.153 3.789a.248.248 0 01-.178.17.252.252 0 01-.238-.066l-2.3-2.3a.5.5 0 00-.564-.1 23.373 23.373 0 00-6.736 4.506c-2.575 2.576-3.811 5.018-3.673 7.273a5.578 5.578 0 001.186 3.062L.293 22.3a1 1 0 000 1.414 1.014 1.014 0 001.414 0l10.828-10.832a.75.75 0 111.061 1.06l-5.791 5.792a.249.249 0 00.1.413 6.578 6.578 0 005.408-.845 16.027 16.027 0 003.211-2.557C22.307 10.959 23.928 1 23.994.581a.5.5 0 00-.141-.432z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});