define("ember-svg-jar/inlined/outdoors-camp-fire-roasting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-camp-fire-roasting</title><path d=\"M11.669 11.529a.318.318 0 00-.4.458 5.242 5.242 0 01.267 4.219 1 1 0 01-1.566.447 2.122 2.122 0 01-.649-.877.3.3 0 00-.433-.168A4.38 4.38 0 007.1 19.537a4.675 4.675 0 009.345-.211 7.637 7.637 0 00-4.776-7.797zM5.25 3H3.6a.252.252 0 01-.249-.273L3.5 1.091a1 1 0 10-2-.181L0 17.41a1 1 0 00.91 1.09h.091a1 1 0 001-.91L3.12 5.228A.25.25 0 013.369 5H5.25a.25.25 0 00.25-.25v-1.5A.249.249 0 005.25 3zM22.5.91a1 1 0 10-1.992.181l.149 1.637A.252.252 0 0120.4 3h-1.65a.249.249 0 00-.25.25v1.5a.25.25 0 00.25.25h1.881a.25.25 0 01.249.227L22 17.591a1 1 0 001 .909h.091A1 1 0 0024 17.41z\"/><rect x=\"7\" y=\"1.001\" width=\"10\" height=\"6.5\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});