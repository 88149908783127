define("ember-svg-jar/inlined/composition-window-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-window-man-1</title><path d=\"M18 0H6a2.752 2.752 0 00-2.75 2.75v18.5A2.752 2.752 0 006 24h12a2.752 2.752 0 002.75-2.75V2.75A2.752 2.752 0 0018 0zm-6 9.5a4 4 0 014 4V15a1 1 0 01-1 1h-.776a.25.25 0 00-.249.222L13.6 19.61a1 1 0 01-.994.89H11.4a1 1 0 01-.994-.89l-.376-3.388A.25.25 0 009.776 16H9a1 1 0 01-1-1v-1.5a4 4 0 014-4zM9.5 6A2.5 2.5 0 1112 8.5 2.5 2.5 0 019.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});