define("ember-svg-jar/inlined/content-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-write</title><path d=\"M13.742 21.333a.956.956 0 00-.957-.957h-9.53a1 1 0 01-1-1V3.667a1 1 0 011-1h11.4a1 1 0 011 1v3.786a.957.957 0 001.914 0V2.667A1.914 1.914 0 0015.657.752H2.255A1.914 1.914 0 00.341 2.667v17.709a1.914 1.914 0 001.914 1.914h10.53a.956.956 0 00.957-.957z\"/><path d=\"M8 5.3a.718.718 0 000 1.435h4.786a.718.718 0 000-1.436zM4.888 5.06a.957.957 0 10.957.957.958.958 0 00-.957-.957zM7.515 16.172a.249.249 0 00-.425 0l-1.22 1.955a.251.251 0 00.234.382l2.3-.2a.249.249 0 00.192-.379zM10.3 10.589a2.146 2.146 0 00-.874 1.437l-.14.981a.25.25 0 00.378.249l5.153-3.156a.251.251 0 00.119-.221.249.249 0 00-.133-.213 57.205 57.205 0 00-1.008-.527 1.6 1.6 0 00-1.647.129zM10.475 17.248a.25.25 0 00.108-.289 2.128 2.128 0 01-.1-.652 2.156 2.156 0 012.154-2.153h2.818a.254.254 0 00.131-.037l3.063-1.877a1.436 1.436 0 10-1.5-2.449l-8.766 5.371a.249.249 0 00-.083.343l1.239 2.023a.249.249 0 00.344.082z\"/><path d=\"M14.623 17.5a.251.251 0 01.231.154l2.264 5.436a.251.251 0 00.231.154h6.06a.253.253 0 00.222-.134.25.25 0 00-.015-.257l-5.815-8.5a.25.25 0 00-.345-.066l-1.168.778a.252.252 0 01-.138.042h-3.514a1.2 1.2 0 100 2.393z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});