define("ember-svg-jar/inlined/social-bookmark-stumble-upon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-bookmark-stumble-upon-1</title><path d=\"M12.5 9.5a.5.5 0 00.2.4l2 1.5a.5.5 0 00.6 0l2-1.5a.5.5 0 00.2-.4v-1a5.5 5.5 0 00-11 0v7a.5.5 0 01-1 0v-3A.5.5 0 005 12H1a.5.5 0 00-.5.5v3a5.5 5.5 0 0011 0v-7a.5.5 0 011 0z\"/><path d=\"M23 12h-4a.5.5 0 00-.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 00-.8-.4L15 13.38l-1.7-1.28a.5.5 0 00-.8.4v3a5.5 5.5 0 0011 0v-3a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});