define("ember-svg-jar/inlined/messages-bubble-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-edit-1</title><path d=\"M18.626 10.113A4.039 4.039 0 0121.5 8.921a3.968 3.968 0 011.575.334.249.249 0 00.347-.194A7.582 7.582 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.993 7.993 0 00-3 15.4v4.1a.547.547 0 00.519.519.48.48 0 00.334-.165L9.708 16h2.933a.247.247 0 00.176-.073z\"/><path d=\"M23.227 11.751a2.619 2.619 0 00-3.7 0l-6.678 6.679a.252.252 0 000 .354l3.351 3.353a.249.249 0 00.353 0l6.679-6.679a2.621 2.621 0 00-.005-3.707zM11.6 23.976l3.143-.629a.25.25 0 00.127-.422l-2.808-2.808a.251.251 0 00-.422.128l-.628 3.143a.5.5 0 00.137.451c.243.244.44.139.451.137z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});