define("ember-svg-jar/inlined/cursor-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-information</title><path d=\"M9.71 5.288a9 9 0 109 9 9.01 9.01 0 00-9-9zm2.074 13.792h-4a.75.75 0 110-1.5h1a.25.25 0 00.25-.25v-4a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1a1.752 1.752 0 011.75 1.75v4a.25.25 0 00.25.25h1a.75.75 0 010 1.5zm-2-11.25a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zM23.144.854a.5.5 0 00-.457-.135l-8.811 1.86a.5.5 0 00-.072.957 11.48 11.48 0 016.658 6.658.5.5 0 00.957-.072l1.86-8.811a.5.5 0 00-.135-.457z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});