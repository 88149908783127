define("ember-svg-jar/inlined/earth-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-sync</title><path d=\"M10.343 21.573a.491.491 0 00-.326-.235A9.8 9.8 0 017.59 20.5v-1.279a2.445 2.445 0 01.875-1.878 4.4 4.4 0 001.077-1.373 7.722 7.722 0 01.418-1.27 4.349 4.349 0 00.08-.731 4.415 4.415 0 00-4.41-4.41H2.46a9.783 9.783 0 0115.068-5.88h-3.323a2.7 2.7 0 000 5.39 2.52 2.52 0 011.854.835.489.489 0 00.4.158q.29-.023.584-.023a7.652 7.652 0 014.022 1.127.489.489 0 00.6-.071l.216-.216a1.947 1.947 0 011.389-.579.339.339 0 00.335-.393A11.756 11.756 0 107.435 22.6l.155.078v-.021a11.751 11.751 0 003.058.785.49.49 0 00.434-.8 7.589 7.589 0 01-.739-1.069z\"/><path d=\"M23.457 11.808a.49.49 0 00-.534.107l-1.107 1.106a.489.489 0 01-.669.022 6.247 6.247 0 10.779 8.607.98.98 0 10-1.526-1.224 4.287 4.287 0 11-.709-6.05.491.491 0 01.044.732l-1.175 1.174a.49.49 0 00.347.837h4.363a.49.49 0 00.49-.49v-4.368a.491.491 0 00-.303-.453z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});