define("ember-svg-jar/inlined/couple-frame-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-frame-image</title><path d=\"M22 5h-4.356a.5.5 0 01-.363-.156L13.147.493a1.581 1.581 0 00-2.294 0L6.719 4.844A.5.5 0 016.356 5H2a2 2 0 00-2 2v15a2 2 0 002 2h20a2 2 0 002-2V7a2 2 0 00-2-2zM9.73 4.578l2.089-2.2a.251.251 0 01.362 0l2.089 2.2a.25.25 0 01-.181.422H9.911a.25.25 0 01-.181-.422zM22 21.438a.562.562 0 01-.562.562H2.562A.562.562 0 012 21.438V7.562A.562.562 0 012.562 7h18.876a.562.562 0 01.562.562z\"/><path d=\"M12.352 12.65l2.205-2.191A1.389 1.389 0 0014.84 8.8a1.553 1.553 0 00-2.422-.375.6.6 0 01-.835 0A1.554 1.554 0 009.16 8.8a1.389 1.389 0 00.283 1.662l2.205 2.191a.5.5 0 00.704-.003zM7 12a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 01-1.92-2.56.248.248 0 01.149-.162.245.245 0 01.219.018 4.978 4.978 0 003.252.654.249.249 0 01.209.069.247.247 0 01.074.207A2 2 0 017 18zM20.5 16a4 4 0 00-8 0c0 1.656-.376 2.2-.492 2.33a1 1 0 101.484 1.34 2.8 2.8 0 00.285-.377.251.251 0 01.36-.068 3.99 3.99 0 004.726 0 .251.251 0 01.36.068 2.8 2.8 0 00.285.377 1 1 0 101.484-1.34c-.116-.13-.492-.674-.492-2.33zm-4 2a2 2 0 01-1.954-1.576.25.25 0 01.192-.3 2.967 2.967 0 00.709-.235 2.337 2.337 0 00.852-.683.251.251 0 01.4 0 2.337 2.337 0 00.852.683 2.967 2.967 0 00.709.235.25.25 0 01.192.3A2 2 0 0116.5 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});