define("ember-svg-jar/inlined/table-lamp-retro-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-lamp-retro-1</title><path d=\"M16 23a1 1 0 00-1-1h-1.5a.5.5 0 01-.5-.5v-6.2a.5.5 0 00-.6-.49 1.986 1.986 0 01-.8 0 .5.5 0 00-.6.49v6.2a.5.5 0 01-.5.5H9a1 1 0 000 2h6a1 1 0 001-1z\"/><path d=\"M13.5 2a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5C6.082 2 2 5.581 2 10v1.75a.5.5 0 00.2.4l1.5 1.125a.5.5 0 00.6 0l1.4-1.05a.5.5 0 01.6 0l1.4 1.05a.5.5 0 00.6 0l1.4-1.05a.5.5 0 01.6 0l1.4 1.05a.5.5 0 00.6 0l1.4-1.05a.5.5 0 01.6 0l.5.375a.5.5 0 01.2.4v3.271a1 1 0 002 0V13a.5.5 0 01.2-.4l.5-.375a.5.5 0 01.6 0l1.4 1.05a.5.5 0 00.6 0l1.5-1.125a.5.5 0 00.2-.4V10c0-4.419-4.082-8-8.5-8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});