define("ember-svg-jar/inlined/car-repair-tow-truck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-tow-truck-2</title><path d=\"M22.3 14.2l-1.43-.572a.246.246 0 01-.13-.12l-1.511-3.022a1.989 1.989 0 00-1.789-1.105h-2.883a2 2 0 00-2 2v4.75a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-4.75a.5.5 0 00-.147-.354l-3-3a.5.5 0 00-.353-.146h-.111a.25.25 0 01-.069-.491l13.491-3.823a.25.25 0 01.318.241v1.02a1 1 0 001 1 .757.757 0 11-.537 1.292 1 1 0 00-1.412 1.416 2.757 2.757 0 103.1-4.459.25.25 0 01-.146-.227V3.1a.251.251 0 01.182-.241l1.188-.336a.5.5 0 00.168-.878L22.138.427a1.608 1.608 0 00-1.319-.254L2.773 5.291a1.5 1.5 0 00-1.041 1.848L2 8.1a2.087 2.087 0 01.056.274v3.25a.25.25 0 01-.25.25h-.75a1 1 0 00-1 1v6.5a1.985 1.985 0 00.8 1.592.25.25 0 00.385-.115 2.99 2.99 0 015.118-.891.251.251 0 00.385 0 2.992 2.992 0 015.219 1.221.249.249 0 00.243.193H14.4a.249.249 0 00.243-.193 2.99 2.99 0 015.822 0 .249.249 0 00.243.193h.846a2 2 0 002-2v-3.322A1.99 1.99 0 0022.3 14.2zM6.557 11.876h-2a.5.5 0 010-1h2a.5.5 0 010 1zm11.69 1.38a.249.249 0 01-.213.118h-3.227a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h2.477a.249.249 0 01.224.138l.75 1.5a.253.253 0 01-.011.244z\"/><circle cx=\"4.057\" cy=\"21.876\" r=\"2\"/><circle cx=\"9.057\" cy=\"21.876\" r=\"2\"/><circle cx=\"17.557\" cy=\"21.876\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});