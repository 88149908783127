define("ember-svg-jar/inlined/arrow-left-12", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.5 24c-.228 0-.458-.078-.646-.236l-13-11a1.001 1.001 0 010-1.528l13-11a1 1 0 011.292 1.527L7.048 12l12.098 10.236A1 1 0 0118.5 24z\"/><path fill=\"none\" d=\"M0 0h24v24H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});