define("ember-svg-jar/inlined/single-woman-actions-cart.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-cart</title><path d=\"M10.922 15.126a2 2 0 01.273-1.8l.012-.017a.5.5 0 00-.145-.726A7.438 7.438 0 007.224 11.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h10.505a.5.5 0 00.474-.659zM15.586 23.989a1.423 1.423 0 10-1.422-1.423 1.425 1.425 0 001.422 1.423zM20.712 22.577A1.423 1.423 0 1019.289 24a1.424 1.424 0 001.423-1.423zM23.469 10.789a.749.749 0 00-.949.474l-.795 2.385a.5.5 0 01-.474.342h-8.434a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.475.34h6.23a.5.5 0 00.482-.365l2.567-8.386a.75.75 0 00-.474-.949zM1.568 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.561 4.561 0 01-.643-2.511V5.3a5.3 5.3 0 10-10.594 0v1.248A4.573 4.573 0 011.27 9.06a1 1 0 00.298 1.382zM7.21 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.21 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});