define("ember-svg-jar/inlined/auto-pilot-car-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-smartphone</title><path d=\"M8 9.25v-7.5A1.752 1.752 0 006.25 0h-4.5A1.752 1.752 0 000 1.75v7.5A1.752 1.752 0 001.75 11h4.5A1.752 1.752 0 008 9.25zm-6.5 0v-7.5a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25z\"/><circle cx=\"4\" cy=\"7.5\" r=\"1\"/><path d=\"M23.984 20.5a9.933 9.933 0 00-6.563-8.5.25.25 0 01-.171-.237v-.591a3 3 0 00-6 0v.6a.25.25 0 01-.178.239c-4.063 1.232-7.4 4.613-8.045 8.587a2.081 2.081 0 00.464 1.679 2.057 2.057 0 001.109.677 2.7 2.7 0 01-.1-.694 2.75 2.75 0 015.5 0 2.7 2.7 0 01-.117.75h7.234a2.7 2.7 0 01-.117-.76 2.75 2.75 0 015.5 0 2.7 2.7 0 01-.085.646 2.2 2.2 0 001.029-.648 2.235 2.235 0 00.54-1.748zm-12.8-5.825l-1.249 2.497A1.494 1.494 0 018.594 18h-1.6a.5.5 0 01-.414-.78 9.914 9.914 0 013.933-3.212.5.5 0 01.673.67zm2.066-3.511a1 1 0 012 0v.374a9.842 9.842 0 00-.88-.041 10.981 10.981 0 00-1.12.062zm5.026 6.121A1.493 1.493 0 0117 18h-3.9a.8.8 0 01-.678-.378.79.79 0 01-.035-.775l1.008-2.018A1.491 1.491 0 0114.736 14H16.5a1.491 1.491 0 011.342.829l.5 1a1.491 1.491 0 01-.066 1.459z\"/><circle cx=\"7.25\" cy=\"22.25\" r=\"1.75\"/><circle cx=\"19.75\" cy=\"22.25\" r=\"1.75\"/><path d=\"M10.5 2.675h.5a1 1 0 100-2h-.5a1 1 0 000 2zM14 2.675h.5a1 1 0 100-2H14a1 1 0 000 2zM17.5 2.675h.5a1 1 0 000-2h-.5a1 1 0 000 2zM21 2.675h.5v.5a1 1 0 102 0v-.5a2 2 0 00-2-2H21a1 1 0 000 2zM23.5 6.175a1 1 0 00-2 0v.5a1 1 0 102 0zM22.5 8.675a1 1 0 00-1 1v.5a1 1 0 002 0v-.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});