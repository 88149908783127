define("ember-svg-jar/inlined/common-file-text-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-disable</title><path d=\"M12 8.282h7a1 1 0 000-2h-7a1 1 0 000 2z\"/><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.244.244 0 00.255.243 8.258 8.258 0 011.46.085.245.245 0 00.285-.24V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.147.353V18a.5.5 0 01-.5.5h-6.865a.242.242 0 00-.24.21 8.066 8.066 0 01-.363 1.459.244.244 0 00.229.331H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M17.5 11.782a1 1 0 00-1-1h-4a.944.944 0 00-.581.2.281.281 0 000 .41 7.938 7.938 0 011.127 1.286.242.242 0 00.2.1H16.5a1 1 0 001-.996zM6.5 11a6.5 6.5 0 106.5 6.5A6.5 6.5 0 006.5 11zm-3.967 8.6a4.45 4.45 0 01-.367-3.32 4.5 4.5 0 013.113-3.116 4.444 4.444 0 013.325.367.252.252 0 01.051.4l-5.724 5.723a.252.252 0 01-.398-.054zm8.3-.887a4.492 4.492 0 01-3.112 3.116 4.444 4.444 0 01-3.321-.363.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.4.051 4.443 4.443 0 01.362 3.321z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});