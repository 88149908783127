define("ember-svg-jar/inlined/user-cash-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-cash-scale</title><path d=\"M1 19.25h8.539a.25.25 0 01.2.406l-1.981 2.471a1 1 0 00.782 1.623h5.92a1 1 0 00.781-1.625l-1.975-2.469a.248.248 0 01-.03-.264.251.251 0 01.225-.142H23a1 1 0 000-2H6.376a.247.247 0 01-.185-.082.252.252 0 01-.064-.192l.351-3.5a.25.25 0 01.248-.226H7.75A.249.249 0 008 13v-2.75a3.5 3.5 0 00-7 0V13a.249.249 0 00.25.25h1.024a.25.25 0 01.248.226l.351 3.5a.25.25 0 01-.249.274H1a1 1 0 000 2z\"/><circle cx=\"4.5\" cy=\"2.75\" r=\"2.5\"/><path d=\"M18.341 16.365a1.25 1.25 0 001.25-1.25v-.7a.249.249 0 01.152-.229 3.6 3.6 0 002.187-3.3c0-2.763-2.146-3.278-3.3-3.556-1.266-.3-1.382-.4-1.382-1.125a1.091 1.091 0 012.1-.416 1.249 1.249 0 102.308-.958A3.578 3.578 0 0019.743 2.9a.252.252 0 01-.152-.231v-.71a1.25 1.25 0 10-2.5 0v.712a.249.249 0 01-.152.229 3.6 3.6 0 00-2.189 3.3c0 2.764 2.146 3.279 3.3 3.557 1.305.313 1.382.421 1.382 1.124a1.091 1.091 0 01-2.1.416 1.25 1.25 0 10-2.309.959 3.584 3.584 0 001.915 1.93.249.249 0 01.153.23v.7a1.249 1.249 0 001.25 1.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});