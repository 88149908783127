define("ember-svg-jar/inlined/crime-cut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-cut</title><path d=\"M14.646 18.349a.5.5 0 00-.146.354v2.672a1.25 1.25 0 002.5 0v-3a.5.5 0 011 0 1.25 1.25 0 002.5 0V13.7a.5.5 0 00-.854-.354zM21.5 9.375a1 1 0 000-1.414l-6.293-6.293a1 1 0 00-1.414 0L2.5 12.961a1 1 0 000 1.414l6.293 6.293a1 1 0 001.414 0zM11.03 16.7l-1 1a.75.75 0 01-1.06-1.061l1-1a.75.75 0 011.06 1.061zm2.43-4.07a2 2 0 110-2.829 2 2 0 010 2.827zm1.57-6.93l-1 1a.75.75 0 11-1.06-1.061l1-1A.75.75 0 0115.03 5.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});