define("ember-svg-jar/inlined/single-man-actions-monitor.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-monitor</title><path d=\"M7.044 10a5 5 0 10-5-5 5.006 5.006 0 005 5zm-2.9-5.743a.5.5 0 01.741-.3A6.958 6.958 0 008.5 5a6.363 6.363 0 00.93-.07.5.5 0 01.57.564A3 3 0 014.044 5a2.8 2.8 0 01.1-.743zM9.456 13a3.475 3.475 0 01.318-1.452.5.5 0 00-.3-.685A7.191 7.191 0 007.25 10.5a7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.412a.5.5 0 00.5-.5zM19.956 22h-1.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h3.25a2 2 0 002-2v-6a2 2 0 00-2-2h-9a2 2 0 00-2 2v6a2 2 0 002 2h3.25a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1.25a.985.985 0 00-.846.5.969.969 0 00-.154.5 1 1 0 001 1h5a1 1 0 001-1 .968.968 0 00-.153-.5.986.986 0 00-.847-.5zm-7-8.75a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v5.5a.252.252 0 01-.074.177.247.247 0 01-.176.073h-8.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});