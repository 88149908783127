define("ember-svg-jar/inlined/design-file-dwg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-dwg</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.427A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6zM5.956 13.319a.249.249 0 00-.331.24v3.882a.25.25 0 00.331.241A2.239 2.239 0 007.375 15.5a2.238 2.238 0 00-1.419-2.181z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.25a.25.25 0 00-.25-.25H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.25a.25.25 0 00-.25-.25zM5 19.125a.625.625 0 01-.625-.625v-6A.625.625 0 015 11.875a3.625 3.625 0 010 7.25zm9.625-4.861a10.155 10.155 0 01-1.065 4.515.627.627 0 01-.56.346.527.527 0 01-.068 0 .624.624 0 01-.538-.47s-.281-1.137-.317-1.265c-.018-.1-.134-.081-.155 0l-.316 1.262a.624.624 0 01-.538.47.609.609 0 01-.628-.342 10.155 10.155 0 01-1.065-4.515V12.5a.625.625 0 011.25 0v1.764a8.847 8.847 0 00.183 1.784c.024.113.145.065.16.006l.426-1.7a.624.624 0 011.212 0s.393 1.622.414 1.693.157.12.183-.011a9.2 9.2 0 00.172-1.767V12.5a.625.625 0 011.25 0zm5 .236a.625.625 0 01-1.25 0V14a.875.875 0 00-1.75 0v3a.875.875 0 001.722.221.076.076 0 00-.069-.1H18a.625.625 0 010-1.25h1a.625.625 0 01.625.625V17a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});