define("ember-svg-jar/inlined/single-woman-profile-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-profile-picture</title><path d=\"M22.5 1.5A1.5 1.5 0 0021 0H3a1.5 1.5 0 00-1.5 1.5v21A1.5 1.5 0 003 24h18a1.5 1.5 0 001.5-1.5zM19 20a.5.5 0 01-.5.5h-13A.5.5 0 015 20V4a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M16.36 11.274V10.3a4.37 4.37 0 00-8.74 0v.971a3.31 3.31 0 01-.468 1.829 1 1 0 001.676 1.1q.041-.064.078-.132a.251.251 0 01.378-.072 4.278 4.278 0 005.412 0 .25.25 0 01.378.072c.024.045.05.089.078.131a1 1 0 001.676-1.099 3.3 3.3 0 01-.468-1.826zm-4.37 1.683a2.235 2.235 0 01-2.21-1.835.249.249 0 01.17-.282 5.35 5.35 0 001.877-1.04.25.25 0 01.326 0 5.363 5.363 0 001.876 1.037.248.248 0 01.171.281 2.233 2.233 0 01-2.21 1.839zM11.992 15.457A5.689 5.689 0 007.6 17.642a.5.5 0 00.388.815h8a.5.5 0 00.388-.815 5.681 5.681 0 00-4.384-2.185z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});