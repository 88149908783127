define("ember-svg-jar/inlined/amphibian-frog-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>amphibian-frog-body</title><path d=\"M3.987 6.727L4.5 5.175a1 1 0 00-1.9-.633l-.225.675-.827-.552A1 1 0 00.445 6.329l1.669 1.113c.375.825 1.493 2.889 3.287 2.889a2.7 2.7 0 00.376-.026A25.75 25.75 0 005.248 8.3a3.479 3.479 0 01-1.261-1.573zM18.6 10.331c1.794 0 2.912-2.064 3.287-2.889l1.669-1.113a1 1 0 00-1.11-1.664l-.827.552-.225-.675a1 1 0 10-1.9.633l.518 1.553A3.51 3.51 0 0118.75 8.3q-.33 1.091-.526 2.006a2.671 2.671 0 00.376.025z\"/><path d=\"M23.228 16.271a.5.5 0 00-.368-.161h-.843a.5.5 0 00-.492.413 15.6 15.6 0 01-.825 2.827.518.518 0 01-.652.273.5.5 0 01-.274-.652 14.084 14.084 0 00.826-3.035c.162-1.593-.231-2.6-1.175-2.989-1.317-.537-2.365.557-2.946 1.587-.331-1.724.055-4.388 1.122-7.578a3.079 3.079 0 00-1.019-3.393L13.088.877a1.76 1.76 0 00-2.173 0l-3.5 2.691A3.079 3.079 0 006.4 6.958a21.587 21.587 0 011.24 6.231 7 7 0 01-.119 1.35c-.578-1.029-1.629-2.13-2.953-1.591-.942.392-1.334 1.4-1.171 2.994a14.148 14.148 0 00.826 3.029.5.5 0 01-.274.652.509.509 0 01-.649-.273 15.6 15.6 0 01-.822-2.827.5.5 0 00-.492-.413H1.14a.5.5 0 00-.5.541c.023.28.6 6.849 4.638 6.849a3.4 3.4 0 003.514-2.9 7.845 7.845 0 003.208.98 7.845 7.845 0 003.206-.978 3.4 3.4 0 003.514 2.9c4.034 0 4.615-6.569 4.638-6.849a.5.5 0 00-.13-.382zM13.236 5.428a1 1 0 111 1 1 1 0 01-1-1zm-2.472 0a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});