define("ember-svg-jar/inlined/astrology-broom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astrology-broom</title><path d=\"M23.56.439a1.5 1.5 0 00-2.121 0L20.31 1.567a.929.929 0 01-.832.252 3.918 3.918 0 00-4.574 2.9l-.262 1.074c-.349 1.461-.455 1.9-.982 2.429l-2.474 2.475-.04-.04a1.5 1.5 0 00-1.329-.416 1.514 1.514 0 00-1.112.889l-.682 1.856a.251.251 0 00.058.263l2.674 2.674a.251.251 0 00.263.058l1.89-.7a1.5 1.5 0 00.439-2.427l-.04-.04 2.475-2.475c1.128-1.129 1.382-2.192 1.8-3.955.069-.291.144-.6.228-.942a.916.916 0 011.076-.68 3.906 3.906 0 003.542-1.073L23.56 2.56a1.5 1.5 0 000-2.121zM7.354 13.931a.25.25 0 00-.248-.063L.917 15.725a1 1 0 00-.663 1.265 13.582 13.582 0 00.754 1.863.247.247 0 00.159.13.251.251 0 00.2-.032l2.146-1.414a.5.5 0 11.55.836l-2.206 1.455a.251.251 0 00-.109.162.256.256 0 00.041.19A8.132 8.132 0 003.81 22.2a.247.247 0 00.19.041.255.255 0 00.163-.108l1.455-2.21a.5.5 0 11.835.551l-1.414 2.151a.255.255 0 00-.033.2.251.251 0 00.131.159 13.67 13.67 0 001.869.756 1 1 0 001.268-.663l1.856-6.187a.247.247 0 00-.062-.248zM17 17.5a3.5 3.5 0 00-3.5 3.5 1 1 0 002 0 1.5 1.5 0 113 0v.25a.752.752 0 01-.75.75.245.245 0 01-.2-.095 1 1 0 10-1.561 1.252A2.243 2.243 0 0017.75 24a2.753 2.753 0 002.75-2.75V21a3.5 3.5 0 00-3.5-3.5zM3.5 12.5A3.5 3.5 0 007 9a1 1 0 10-2 0 1.5 1.5 0 01-3 0v-.25A.751.751 0 012.75 8a.247.247 0 01.191.089A1 1 0 104.467 6.8 2.245 2.245 0 002.75 6 2.753 2.753 0 000 8.749V9a3.5 3.5 0 003.5 3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});