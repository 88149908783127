define("ember-svg-jar/inlined/user-cash-scale-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-cash-scale-1</title><path d=\"M7.478 22.524a1 1 0 00.873 1.488h7.3a1 1 0 00.874-1.487l-.4-.717a.252.252 0 01.15-.363l.578-.165a3.656 3.656 0 002.6-3 .251.251 0 01.2-.209 5.438 5.438 0 002.738-1.48l.969-.969a.5.5 0 00-.36-.852h-9a.5.5 0 00-.354.853l.969.969a5.466 5.466 0 002.574 1.45.25.25 0 01.174.334 1.619 1.619 0 01-1.06.981l-1.138.326a.249.249 0 01-.287-.119l-.3-.537a1 1 0 00-.874-.514h-3.41a1 1 0 00-.874.514l-.3.536a.249.249 0 01-.287.12L7.7 19.357a1.619 1.619 0 01-1.06-.981.25.25 0 01.174-.334 5.466 5.466 0 002.574-1.45l.969-.969A.5.5 0 0010 14.77H1a.5.5 0 00-.354.853l.969.969a5.438 5.438 0 002.738 1.48.251.251 0 01.2.209 3.656 3.656 0 002.6 3l.578.165a.252.252 0 01.15.363z\"/><circle cx=\"5.5\" cy=\"2.519\" r=\"2.5\"/><path d=\"M2 9.434a.251.251 0 00.25.25h1.284a.25.25 0 01.247.214l.519 3.657a.249.249 0 00.247.215h1.9a.249.249 0 00.247-.215L7.219 9.9a.25.25 0 01.248-.214h1.282a.248.248 0 00.177-.074A.244.244 0 009 9.434c-.006-1.911-.148-4.415-3.5-4.415S2.007 7.523 2 9.434zM18.5 13.821a1.25 1.25 0 001.25-1.25V12.3a.248.248 0 01.19-.242 3.035 3.035 0 00.4-5.766l-2.751-1.1a.539.539 0 01.2-1.04H20.5a1.25 1.25 0 000-2.5H20a.249.249 0 01-.25-.25v-.164a1.25 1.25 0 10-2.5 0v.27a.249.249 0 01-.19.242 3.035 3.035 0 00-.4 5.766l2.751 1.1a.539.539 0 01-.2 1.039H16.5a1.25 1.25 0 000 2.5h.5a.251.251 0 01.25.25v.167a1.25 1.25 0 001.25 1.249z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});