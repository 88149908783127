define("ember-svg-jar/inlined/synchronize-arrow-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrow-2</title><path d=\"M12.567 1.257A10.821 10.821 0 002.818 8.4a.25.25 0 01-.271.162L.858 8.308a.516.516 0 00-.485.213.5.5 0 00-.021.53l2.679 4.7A.5.5 0 003.4 14h.067a.5.5 0 00.352-.145l3.77-3.746a.5.5 0 00-.279-.85l-1.717-.253a.246.246 0 01-.183-.127.25.25 0 01-.01-.223 8.259 8.259 0 117.866 11.591 1.25 1.25 0 00.045 2.5h.047a10.751 10.751 0 00-.792-21.488z\"/><circle cx=\"12.965\" cy=\"12.002\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});