define("ember-svg-jar/inlined/video-game-nintendo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-nintendo</title><path d=\"M21 2.5A2.5 2.5 0 0018.5 0h-13A2.5 2.5 0 003 2.5v19A2.5 2.5 0 005.5 24h10a5.506 5.506 0 005.5-5.5zM7 4a.507.507 0 01.5-.5h9a.5.5 0 01.5.5v6.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5zm4.5 12.75a.75.75 0 01-.75.75H9.5a.5.5 0 00-.5.5v1.25a.75.75 0 01-1.5 0V18a.5.5 0 00-.5-.5H5.75a.75.75 0 010-1.5H7a.5.5 0 00.5-.5v-1.25a.75.75 0 011.5 0v1.25a.5.5 0 00.5.5h1.25a.75.75 0 01.75.75zm3 2.75a1 1 0 111-1 1 1 0 01-1 1zm2.5-3a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});