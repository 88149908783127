define("ember-svg-jar/inlined/medical-hospital-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-hospital-2</title><path d=\"M18.289 11.488a1 1 0 00-.446 1.342 1.487 1.487 0 01.157.67c0 1.245-2.261 1.858-4.561 2.345a.252.252 0 01-.216-.056.249.249 0 01-.085-.207l.156-2.076C13.679 8.364 14 2.442 14 2a2 2 0 00-4 0c0 .442.321 6.364.706 11.506l.156 2.076a.251.251 0 01-.3.263C8.26 15.357 6 14.745 6 13.5a1.487 1.487 0 01.157-.67 1 1 0 10-1.788-.9A3.515 3.515 0 004 13.5c0 1.932 1.515 2.949 3.472 3.608a.25.25 0 01.05.451A2.752 2.752 0 006 20a3 3 0 003 3 1 1 0 000-2 1 1 0 01-1-1c0-.688 1.282-1.164 2.791-1.539a.249.249 0 01.309.222c.141 1.739.28 3.232.4 4.857a.5.5 0 00.991 0c.125-1.625.263-3.118.4-4.857a.249.249 0 01.31-.222C14.718 18.836 16 19.312 16 20a1 1 0 01-1 1 1 1 0 000 2 3 3 0 003-3 2.752 2.752 0 00-1.522-2.441.25.25 0 01.05-.451C18.485 16.449 20 15.432 20 13.5a3.515 3.515 0 00-.369-1.566 1 1 0 00-1.342-.446z\"/><path d=\"M6.5 6.5A.5.5 0 016 7H4.415a.5.5 0 00-.379.826 10.534 10.534 0 004.638 3.128.251.251 0 00.232-.04A.248.248 0 009 10.7c-.284-4.244-.5-8.288-.5-8.7 0-.026.007-.049.008-.075A2.305 2.305 0 006.377.5H2a.5.5 0 00-.5.5 10.262 10.262 0 00.123 1.577.5.5 0 00.494.423H4.5a.5.5 0 010 1H2.636a.5.5 0 00-.469.674 10.455 10.455 0 00.457 1.051.5.5 0 00.447.275H6a.5.5 0 01.5.5zM19 3.5a.5.5 0 01.5-.5h2.383a.5.5 0 00.494-.423A10.262 10.262 0 0022.5 1a.5.5 0 00-.5-.5h-4.377a2.307 2.307 0 00-2.131 1.425c0 .026.008.049.008.075 0 .414-.217 4.457-.5 8.7a.248.248 0 00.1.214.251.251 0 00.232.04 10.531 10.531 0 004.639-3.128.5.5 0 00-.386-.826H18a.5.5 0 010-1h2.929a.5.5 0 00.447-.275 10.455 10.455 0 00.457-1.051.5.5 0 00-.469-.674H19.5a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});