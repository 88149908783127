define("ember-svg-jar/inlined/real-estate-action-house-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-minus</title><path d=\"M9.5 17.5a7.987 7.987 0 0113.137-6.118.5.5 0 00.322.118h.541a.5.5 0 00.344-.864L13.029.408a1.5 1.5 0 00-2.059 0L.156 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.638a.5.5 0 00.4-.8A7.932 7.932 0 019.5 17.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});