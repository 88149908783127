define("ember-svg-jar/inlined/people-woman-11.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-11</title><path d=\"M17.146 15.338a5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.511 12.734 19 9.711 19 7A7 7 0 005.123 5.718a.25.25 0 01-.4.151 3.227 3.227 0 00-2.49-.66.286.286 0 00-.162.1.249.249 0 00-.058.171 3.247 3.247 0 001.574 2.642.25.25 0 010 .428 3.246 3.246 0 00-1.574 2.65.284.284 0 00.062.176.251.251 0 00.158.091A3.207 3.207 0 004.44 11a.25.25 0 01.365.3 3.508 3.508 0 01-1.958 2.214.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161 3.48 3.48 0 001.028-.436.25.25 0 01.274 0 6.97 6.97 0 007.643.032.252.252 0 01.275 0 3.478 3.478 0 001.068.473zM7 8.805a.25.25 0 01.18-.245A16.961 16.961 0 0112 8a16.95 16.95 0 014.82.56.249.249 0 01.18.245 5 5 0 11-10 0z\"/><circle cx=\"14\" cy=\"9.5\" r=\"1\"/><circle cx=\"10\" cy=\"9.5\" r=\"1\"/><path d=\"M13.077 11.631h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 3.024 3.024 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2zM0 23a1 1 0 002 0v-1.606a.98.98 0 01.376-.785 11.026 11.026 0 012.786-1.5.25.25 0 01.338.234v4.4a.25.25 0 00.25.25h12.5a.25.25 0 00.25-.25v-4.4a.25.25 0 01.338-.234 11 11 0 012.785 1.5.978.978 0 01.377.785V23a1 1 0 002 0v-1.606a2.959 2.959 0 00-1.164-2.374C21.358 17.892 17.985 16 12 16s-9.358 1.892-10.837 3.02A2.961 2.961 0 000 21.394zm14.786-4.838a.249.249 0 01.162.408 3.755 3.755 0 01-5.9 0 .251.251 0 01.163-.409 24.111 24.111 0 015.572 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});