define("ember-svg-jar/inlined/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard</title><path d=\"M21.5 13H4.75a.25.25 0 01-.25-.25v-.25a2 2 0 012-2h13a4.5 4.5 0 100-9H13a.5.5 0 01-.5-.5 1 1 0 00-2 0A2.5 2.5 0 0013 3.5h6.5a2.5 2.5 0 110 5h-13a4 4 0 00-4 4v.261a.25.25 0 01-.227.249A2.5 2.5 0 000 15.5v6A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-6a2.5 2.5 0 00-2.5-2.5zm-.252 7.5h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zm0-3h-.5a.5.5 0 010-1h.5a.5.5 0 010 1zM6.5 19.5h11a.5.5 0 010 1h-11a.5.5 0 010-1zm-.747-2a.5.5 0 010-1h.5a.5.5 0 010 1zm2.5-.5a.5.5 0 01.5-.5h.5a.5.5 0 010 1h-.5a.5.5 0 01-.5-.5zm3 0a.5.5 0 01.5-.5h.5a.5.5 0 010 1h-.5a.5.5 0 01-.5-.5zm3 0a.5.5 0 01.5-.5h.5a.5.5 0 110 1h-.5a.5.5 0 01-.5-.5zm3.5.5a.5.5 0 010-1h.5a.5.5 0 010 1zm-14.5-1a.5.5 0 110 1h-.5a.5.5 0 010-1zm-.5 3h.5a.5.5 0 010 1h-.5a.5.5 0 010-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});