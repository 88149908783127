define("ember-svg-jar/inlined/gaming-trophy-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-trophy-5</title><path d=\"M16.5 20.053c-1.423-.141-2.3-.649-2.47-2.694a.5.5 0 00-.565-.455 11.2 11.2 0 01-2.927 0 .5.5 0 00-.565.455C9.8 19.407 8.922 19.912 7.5 20.053A1.979 1.979 0 007.7 24h8.6a1.979 1.979 0 00.194-3.947zM22 5.5h-.246a.5.5 0 01-.5-.565 29.845 29.845 0 00.246-3.413A1.5 1.5 0 0020 0H4a1.5 1.5 0 00-1.5 1.523 29.827 29.827 0 00.238 3.412.5.5 0 01-.5.565H2a2 2 0 00-2 2V10a5.019 5.019 0 003.266 4.691 1 1 0 10.693-1.877A3.01 3.01 0 012 10V8a.5.5 0 01.5-.5h.343a.5.5 0 01.482.366C4.688 12.786 7.7 15.5 12 15.5s7.311-2.714 8.675-7.634a.5.5 0 01.482-.366h.343a.5.5 0 01.5.5v2a3.01 3.01 0 01-1.959 2.814 1 1 0 10.693 1.877A5.019 5.019 0 0024 10V7.5a2 2 0 00-2-2zm-10.993.25h1.5a2.25 2.25 0 010 4.5h-2.5a.75.75 0 110-1.5h2.5a.75.75 0 000-1.5h-1.5a1.752 1.752 0 01-1.75-1.75v-2a.75.75 0 01.75-.75h4a.75.75 0 010 1.5h-3a.25.25 0 00-.25.25v1a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});