define("ember-svg-jar/inlined/multiple-actions-up-down.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-up-down</title><path d=\"M14.75 11.25a2.5 2.5 0 012.5 2.5v.188a.25.25 0 00.436.167c1.3-1.443 1.877-2.089 2.137-2.377a.5.5 0 00.056-.6 6.019 6.019 0 00-5.14-2.882 5.95 5.95 0 00-2.465.534.5.5 0 00-.182.771 7.458 7.458 0 011.017 1.649.5.5 0 00.655.253 2.478 2.478 0 01.986-.203zM14.263 7.25a3.5 3.5 0 10-3.513-3.5 3.507 3.507 0 003.513 3.5zM12.249 13.851a6.025 6.025 0 00-11.995-.268.6.6 0 00.6.667h11.019a.377.377 0 00.275-.119.372.372 0 00.101-.28zM9.277 3.75a3.514 3.514 0 10-3.514 3.5 3.548 3.548 0 003.514-3.5zM21.157 12.959a.517.517 0 00-.813 0l-2.5 3.5a.5.5 0 00.406.791h1a.5.5 0 01.5.5v5a1 1 0 002 0v-5a.5.5 0 01.5-.5h1a.5.5 0 00.407-.791z\"/><path d=\"M15.75 13.75a1 1 0 00-2 0v5a.5.5 0 01-.5.5h-1a.5.5 0 00-.406.791l2.5 3.5a.5.5 0 00.813 0l2.5-3.5a.5.5 0 00-.407-.791h-1a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});