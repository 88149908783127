define("ember-svg-jar/inlined/app-window-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-eye</title><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M12.05 18.418c4.3 0 7.646-4.2 7.787-4.383a1 1 0 000-1.235c-.141-.178-3.484-4.382-7.787-4.382S4.4 12.622 4.263 12.8a1 1 0 000 1.235c.137.178 3.483 4.383 7.787 4.383zm0-8c2.328 0 4.431 1.758 5.49 2.823a.25.25 0 010 .353c-1.059 1.065-3.162 2.824-5.49 2.824s-4.431-1.759-5.49-2.824a.25.25 0 010-.353c1.059-1.065 3.162-2.823 5.49-2.823z\"/><circle cx=\"12.05\" cy=\"13.418\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});