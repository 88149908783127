define("ember-svg-jar/inlined/ui-page-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-page-slider</title><path d=\"M5.738 3.75H4.93a.249.249 0 00-.246.207c-.13.736-.256 1.579-.365 2.514a.251.251 0 00.248.279h14.866a.251.251 0 00.248-.279 43.434 43.434 0 00-.365-2.514.25.25 0 00-.246-.207H5.738zM6.05 21.75h-.712a.251.251 0 00-.244.307c.078.332.149.612.208.832A1.5 1.5 0 006.751 24h10.5a1.5 1.5 0 001.449-1.11c.059-.221.13-.5.208-.833a.251.251 0 00-.244-.307H6.05zM5.148 8.25h-.771a.25.25 0 00-.249.232 48.523 48.523 0 00.041 7.539.25.25 0 00.249.229h15.164a.249.249 0 00.249-.229 47.862 47.862 0 00.041-7.539.249.249 0 00-.249-.232H5.148zM13 14.75h-2a.75.75 0 010-1.5.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25.75.75 0 010-1.5h.5a1.252 1.252 0 011.25 1.25V13a.25.25 0 00.25.25.75.75 0 010 1.5zM5.353 17.75h-.724a.25.25 0 00-.248.282c.093.736.2 1.41.3 2.011a.249.249 0 00.246.207H19.07a.249.249 0 00.246-.207c.106-.6.21-1.275.3-2.011a.253.253 0 00-.06-.2.25.25 0 00-.188-.085H5.353zM5.094 1.943a.25.25 0 00.244.307h13.324a.25.25 0 00.244-.307 27.64 27.64 0 00-.208-.831A1.5 1.5 0 0017.249 0H6.751A1.5 1.5 0 005.3 1.111c-.057.22-.128.5-.206.832z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});