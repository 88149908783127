define("ember-svg-jar/inlined/bathroom-shower-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-shower-2</title><circle cx=\"3.5\" cy=\"21.25\" r=\"2.5\"/><circle cx=\"11.5\" cy=\"21.25\" r=\"2.5\"/><path d=\"M16 13.75v-1a1 1 0 00-2 0v1a1 1 0 002 0zM18.5 11.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM15.5 15.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM21.5 15.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM18.5 15.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1zM22 11.75a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1z\"/><path d=\"M8.5 6.25a4 4 0 014-4h1a3.99 3.99 0 013.936 3.409.25.25 0 01-.178.277 4.483 4.483 0 00-3.253 4.093.689.689 0 00.687.721h7.273a1 1 0 00.99-1.143 4.513 4.513 0 00-3.3-3.693.25.25 0 01-.184-.218A6 6 0 0013.5.25h-1a6.006 6.006 0 00-6 6v12.5a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});