define("ember-svg-jar/inlined/sd-card-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sd-card-sync</title><path d=\"M9.5 17.751a8.227 8.227 0 016.3-8.006.25.25 0 00.2-.245V4.915a1.511 1.511 0 00-.439-1.061L12.146.44a1.49 1.49 0 00-1.06-.44H1.5A1.5 1.5 0 000 1.5V9a.5.5 0 00.5.5h.75a.25.25 0 01.25.25v2a.25.25 0 01-.25.25H.5a.5.5 0 00-.5.5V20a1.5 1.5 0 001.5 1.5h7.75a.25.25 0 00.25-.25zM8.25 3.5v4a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0zm-3.5 0v4a.75.75 0 01-1.5 0v-4a.75.75 0 111.5 0zm7 4a.75.75 0 11-1.5 0v-4a.75.75 0 111.5 0z\"/><path d=\"M12.823 22.064a.251.251 0 01.359 0 6.085 6.085 0 0010.21-2.2 1 1 0 00-1.893-.649 4.09 4.09 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.721a.5.5 0 00-.354-.854H11.5a.5.5 0 00-.5.5v4.465a.5.5 0 00.309.462.539.539 0 00.545-.108zM17.347 11a6.108 6.108 0 00-5.78 4.129 1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.66 1.659a.5.5 0 00.354.854H23.5a.5.5 0 00.5-.5v-4.456a.5.5 0 00-.854-.353l-1.025 1.025a.251.251 0 01-.358 0A6.118 6.118 0 0017.347 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});