define("ember-svg-jar/inlined/history-chinese-urn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-chinese-urn</title><path d=\"M22.25 5.75a2.992 2.992 0 00-5.918-.629.25.25 0 01-.151.18.253.253 0 01-.234-.025A2.154 2.154 0 0115 3.5a.5.5 0 01.5-.5h1a1.5 1.5 0 000-3h-9a1.5 1.5 0 000 3h1a.5.5 0 01.5.5 2.152 2.152 0 01-.947 1.776.25.25 0 01-.385-.154 2.991 2.991 0 00-5.918.628V12a11.474 11.474 0 006.037 9.337.086.086 0 01-.037.163 1.25 1.25 0 000 2.5h8.5a1.25 1.25 0 000-2.5.086.086 0 01-.037-.163A11.474 11.474 0 0022.25 12zM3.75 6.988V5.75a1 1 0 012 0v.3a.249.249 0 01-.14.224 8.271 8.271 0 00-1.456.907.25.25 0 01-.4-.2zm13.5 11.157a.252.252 0 01-.179.076H6.928a.252.252 0 01-.179-.076A9.287 9.287 0 014 12.1a4.3 4.3 0 01.861-2.732.249.249 0 01.193-.092h13.892a.249.249 0 01.193.092A4.3 4.3 0 0120 12.1a9.287 9.287 0 01-2.749 6.045zm3-11.157a.25.25 0 01-.4.2 8.271 8.271 0 00-1.456-.907.249.249 0 01-.14-.224v-.3a1 1 0 012 0z\"/><circle cx=\"9\" cy=\"11.25\" r=\"1.25\"/><path d=\"M11.728 14.182a.751.751 0 00-.546-.91l-2-.5a1.922 1.922 0 00-.364 0l-2 .5A.75.75 0 007 14.75a.763.763 0 00.183-.022l1.256-.315a.034.034 0 01.032.057l-1.5 1.5a.75.75 0 001.06 1.06l.793-.793a.252.252 0 01.354 0l.793.793a.75.75 0 001.06-1.06l-1.5-1.5a.034.034 0 01.032-.057l1.256.315a.763.763 0 00.181.022.751.751 0 00.728-.568z\"/><circle cx=\"15\" cy=\"11.25\" r=\"1.25\"/><path d=\"M17.728 14.182a.751.751 0 00-.546-.91l-2-.5a1.843 1.843 0 00-.364 0l-2 .5A.75.75 0 0013 14.75a.763.763 0 00.183-.022l1.256-.315a.034.034 0 01.032.057l-1.5 1.5a.75.75 0 001.06 1.06l.793-.793a.252.252 0 01.354 0l.793.793a.75.75 0 001.06-1.06l-1.5-1.5a.034.034 0 01.032-.057l1.256.315a.763.763 0 00.181.022.751.751 0 00.728-.568z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});