define("ember-svg-jar/inlined/e-commerce-shop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-shop</title><path d=\"M22.216 17.962a.25.25 0 01-.216-.248v-4.435a1 1 0 00-2 0V17.7a.25.25 0 01-.25.25H15.5a.5.5 0 00-.5.5c0 .71-1.232 1.5-3 1.5s-3-.79-3-1.5a.5.5 0 00-.5-.5H4.252A.25.25 0 014 17.7v-4.25a1 1 0 00-2 0v4.268a.251.251 0 01-.214.248 1.5 1.5 0 00-1.054 2.279 5.749 5.749 0 004.893 2.7h12.754a5.746 5.746 0 004.892-2.7 1.5 1.5 0 00-1.055-2.283zM24 6.315v-.006a.307.307 0 000-.053v-.007a.323.323 0 00-.02-.049.488.488 0 00-.023-.064L22.235 2.02a1.5 1.5 0 00-1.354-.966h-2.747a.249.249 0 00-.241.316l1.329 4.83a.788.788 0 01.026.2v3.573a.25.25 0 00.159.233A3.348 3.348 0 0024 7.235v-.92zM17.748 6.536a.252.252 0 00-.009-.067L16.3 1.238a.249.249 0 00-.241-.184H13a.25.25 0 00-.25.25v9.361a.249.249 0 00.14.224 4.729 4.729 0 002.473.46 4.868 4.868 0 002.379-.842.018.018 0 00.008-.015zM4.748 6.4a.788.788 0 01.026-.2L6.1 1.37a.249.249 0 00-.241-.316h-2.74a1.5 1.5 0 00-1.354.966L.044 6.126a.488.488 0 00-.023.064.323.323 0 00-.011.054v.007a.307.307 0 000 .053v.926a3.243 3.243 0 001.98 2.926 3.331 3.331 0 002.6.051.25.25 0 00.159-.233zM11.248 1.3a.25.25 0 00-.25-.25H7.936a.249.249 0 00-.241.184c-.1.368-.419 1.526-1.438 5.231a.252.252 0 00-.009.067v3.821a.25.25 0 00.114.209 4.924 4.924 0 002.288.784c.133.011.266.016.4.016a4.664 4.664 0 002.061-.474.249.249 0 00.14-.224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});