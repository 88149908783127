define("ember-svg-jar/inlined/cd-box-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cd-box-1</title><path d=\"M10 12a2 2 0 102-2 2 2 0 00-2 2zm3 0a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M12 20.5A8.5 8.5 0 103.5 12a8.51 8.51 0 008.5 8.5zm5-9.055a.5.5 0 01-.5-.433A3.714 3.714 0 0014 7.974a.5.5 0 01.32-.947 4.712 4.712 0 013.18 3.852.5.5 0 01-.429.562.385.385 0 01-.071.005zM15 12a3 3 0 11-3-3 3 3 0 013 3zm-7.836 1.016a.5.5 0 01.607.362 3.711 3.711 0 002.834 2.733.5.5 0 01-.105.989.632.632 0 01-.105-.01A4.709 4.709 0 016.8 13.624a.5.5 0 01.364-.608z\"/><path d=\"M24 1.957A1.959 1.959 0 0022.043 0H1.957A1.959 1.959 0 000 1.957v20.088A1.959 1.959 0 001.957 24h20.086A1.959 1.959 0 0024 22.045zM22 21.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});