define("ember-svg-jar/inlined/exit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>exit-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM5 11.75a.75.75 0 010 1.5H3.75v1.25a.25.25 0 00.25.25h2a.75.75 0 010 1.5H4a1.752 1.752 0 01-1.75-1.75v-4A1.752 1.752 0 014 8.75h2a.75.75 0 010 1.5H4a.25.25 0 00-.25.25v1.25zm2.829-1.914a.75.75 0 111.342-.671L10 10.823l.829-1.658a.75.75 0 111.342.671L10.839 12.5l1.332 2.665a.75.75 0 01-.336 1.006.741.741 0 01-.334.079.752.752 0 01-.672-.414L10 14.177l-.829 1.659a.752.752 0 01-.672.414.741.741 0 01-.334-.079.75.75 0 01-.336-1.006L9.161 12.5zM14 10.25a.75.75 0 010-1.5h2a.75.75 0 010 1.5h-.25v4.5H16a.75.75 0 010 1.5h-2a.75.75 0 010-1.5h.25v-4.5zm5.25 5.25v-5.25h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5h-.75v5.25a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});