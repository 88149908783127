define("ember-svg-jar/inlined/multiple-actions-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-download</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.25.25 0 00.249-.265c0-.1-.01-.2-.01-.3a7.948 7.948 0 011.336-4.418.247.247 0 00.036-.192A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm6.5-4a1 1 0 011 1V17a.25.25 0 00.25.25h1.15a.5.5 0 01.391.813l-2.4 3a.5.5 0 01-.781 0l-2.4-3a.5.5 0 01.39-.813h1.15a.25.25 0 00.25-.25v-2.5a1 1 0 011-1z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.933 11.261a.25.25 0 00.392.092A7.948 7.948 0 0117.5 9.438h.2a.25.25 0 00.2-.416 4.552 4.552 0 00-7 .235.25.25 0 00.005.311 7.015 7.015 0 011.028 1.693zM0 17.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h.571a.25.25 0 00.249-.265c0-.1-.01-.2-.01-.3a7.948 7.948 0 011.336-4.418.247.247 0 00.036-.192A5.5 5.5 0 000 14z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm6.5-4a1 1 0 011 1V17a.25.25 0 00.25.25h1.15a.5.5 0 01.391.813l-2.4 3a.5.5 0 01-.781 0l-2.4-3a.5.5 0 01.39-.813h1.15a.25.25 0 00.25-.25v-2.5a1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});