define("ember-svg-jar/inlined/legal-verdict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>legal-verdict</title><path d=\"M18.5 12.747a2 2 0 002-2v-8a2 2 0 00-2-2H17a1 1 0 000 2h1.25A.25.25 0 0118.5 3v7.5a.25.25 0 01-.25.25H14.5a1 1 0 00-.708.293l-.877.879a.243.243 0 01-.415-.172 1 1 0 00-1-1H4.75a.25.25 0 01-.25-.25V3a.25.25 0 01.25-.25H6.5a1 1 0 000-2h-2a2 2 0 00-2 2v8a2 2 0 002 2h5.75a.25.25 0 01.25.25v1.75a1 1 0 00.618.924.979.979 0 00.382.076 1 1 0 00.708-.293l2.633-2.637a.251.251 0 01.177-.073z\"/><path d=\"M8.5 9.649a.991.991 0 00.688-.274c.543-.515 1.545-1.457 2.229-2.115a.5.5 0 01.7.007l.676.675a1 1 0 001.414 0l1.531-1.53a1 1 0 000-1.414l-3.455-3.455a1 1 0 00-1.414 0l-1.531 1.53a1 1 0 000 1.415l.657.656a.5.5 0 01-.009.717L7.812 7.924A1 1 0 008.5 9.649zM17.052 17.6a2.85 2.85 0 102.85-2.85 2.853 2.853 0 00-2.85 2.85z\"/><path d=\"M23.889 22.437a5.158 5.158 0 00-4.036-1.931 5.155 5.155 0 00-4.033 1.931.5.5 0 00.389.813H23.5a.5.5 0 00.389-.813zM4.194 20.45a2.85 2.85 0 10-2.85-2.85 2.853 2.853 0 002.85 2.85zM4.145 20.506a5.155 5.155 0 00-4.034 1.931.5.5 0 00.389.813h7.292a.5.5 0 00.389-.813 5.158 5.158 0 00-4.036-1.931z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});