define("ember-svg-jar/inlined/space-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-base</title><path d=\"M10.664 4.053a1.042 1.042 0 00-1.04 1.04v.874a.5.5 0 00.413.492 8.335 8.335 0 016.775 6.98.5.5 0 00.494.427H18.4a1.04 1.04 0 001.033-1.161c-.609-5.176-5.252-8.652-8.769-8.652zM15.341 16.2H1.829A1.039 1.039 0 00.79 17.244v6.236a.52.52 0 00.52.52h5.2a.52.52 0 00.52-.52v-2.858a1.559 1.559 0 113.118 0v2.858a.52.52 0 00.52.52h5.2a.52.52 0 00.52-.52v-6.236a1.039 1.039 0 00-1.047-1.044zM3.388 20.882a.78.78 0 11.78-.779.779.779 0 01-.78.779zm10.394 0a.78.78 0 11.779-.779.78.78 0 01-.779.779z\"/><path d=\"M1.829 15.166h13.512a.519.519 0 00.519-.52 7.275 7.275 0 00-14.55 0 .519.519 0 00.519.52zM22.905 3.244L19.965.3a1.039 1.039 0 10-1.47 1.47.519.519 0 010 .736l-2.081 2.086a.5.5 0 00.04.744 10.821 10.821 0 011.457 1.406.5.5 0 00.733.028L20.7 4.714a.52.52 0 01.735 0 1.039 1.039 0 001.469-1.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});