define("ember-svg-jar/inlined/download-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.748 14.664l-4 4.5a1 1 0 01-1.5 0l-4-4.5A1 1 0 018 13h2.25a.25.25 0 00.25-.25V5.5a1.5 1.5 0 013 0v7.25a.25.25 0 00.25.25H16a1 1 0 01.748 1.664z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});