define("ember-svg-jar/inlined/plant-pot-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-pot-1</title><path d=\"M20.168 5.216a.5.5 0 00-.332-.276.506.506 0 00-.423.085c-1.668 1.26-3.19.911-4.8 1.984a2.737 2.737 0 00-1.37 2.191 2.115 2.115 0 00.38 1.2.5.5 0 01.019.526 16.01 16.01 0 00-.532 1.02.25.25 0 01-.46-.024 13.416 13.416 0 00-1.278-2.549.5.5 0 01.149-.679 3.089 3.089 0 001.419-1.856 3.789 3.789 0 00-1.148-3.266A6.674 6.674 0 008.307 1.71 8.865 8.865 0 014.917.118a.5.5 0 00-.764.149c-1.378 2.606-.73 7.352.778 9.13.862 1.015 2.116 2.015 3.436 1.831a2.228 2.228 0 00.508-.135.5.5 0 00.256-.706 24.1 24.1 0 00-2.955-4.238.5.5 0 11.754-.656c3.691 4.236 4.85 7.8 5.142 10.961a.5.5 0 01-.5.546h-1.8a1.5 1.5 0 00-1.422 1.975l1.105 3.316A2.5 2.5 0 0011.832 24h2.118a2.5 2.5 0 002.371-1.709l1.1-3.316A1.5 1.5 0 0016 17h-1.925a.5.5 0 01-.5-.455 26.873 26.873 0 00-.121-1.081 1 1 0 01.04-.441c.759-2.336 2.14-5.272 4.231-6.352a.5.5 0 01.459.889 6.733 6.733 0 00-2.418 2.7.5.5 0 00.24.706 2.033 2.033 0 00.418.128 3.23 3.23 0 002.788-1.157 7.3 7.3 0 00.956-6.721z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});