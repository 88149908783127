define("ember-svg-jar/inlined/road-sign-bump-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-bump-ahead-alternate</title><path d=\"M23.53 11.47l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06zm-1.767.707l-9.586 9.586a.252.252 0 01-.354 0l-9.586-9.586a.252.252 0 010-.354l9.586-9.586a.252.252 0 01.354 0l9.586 9.586a.252.252 0 010 .354z\"/><path d=\"M19 12.25h-.83a.247.247 0 01-.222-.136A3.252 3.252 0 0015 10.25a3.2 3.2 0 00-2.788 1.582.251.251 0 01-.424 0A3.2 3.2 0 009 10.25a3.252 3.252 0 00-2.948 1.864.25.25 0 01-.222.136H5a.75.75 0 000 1.5h14a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});