define("ember-svg-jar/inlined/hoodie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hoodie</title><path d=\"M3.5 22.25a.25.25 0 01-.25.25H1.5a1 1 0 01-1-1v-1.25A.25.25 0 01.75 20h2.5a.25.25 0 01.25.25zM20.5 22.25a.25.25 0 00.25.25h1.75a1 1 0 001-1v-1.25a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25z\"/><path d=\"M23.364 11.526c-.156-.9-.319-1.838-.461-2.838a4.552 4.552 0 00-3.662-3.8.5.5 0 00-.561.285 5.414 5.414 0 01-3.216 2.791.759.759 0 01-.214.036.75.75 0 01-.214-1.469A3.61 3.61 0 0017.482 4c.012-.563.018-1.442.018-1.564 0-3.242-11-3.241-11 0 0 .122.006 1 .018 1.564a3.61 3.61 0 002.446 2.531A.75.75 0 018.75 8a.759.759 0 01-.214-.031 5.406 5.406 0 01-3.225-2.812.469.469 0 00-.527-.269 4.575 4.575 0 00-3.684 3.8c-.142 1-.3 1.937-.46 2.834A31.817 31.817 0 000 17.014 1.5 1.5 0 001.5 18.5H3a.5.5 0 00.5-.5v-5.75a.75.75 0 011.5 0v6.25l.006 4.138A1.493 1.493 0 006.5 24h11a1.5 1.5 0 001.5-1.409V12.25a.75.75 0 011.5 0V18a.5.5 0 00.5.5h1.5a1.5 1.5 0 001.5-1.487 31.955 31.955 0 00-.636-5.487zM9.378 4.658L9.1 3.974a1.264 1.264 0 01.255-1.351A2.031 2.031 0 0110.86 2h2.28a2.031 2.031 0 011.509.623 1.266 1.266 0 01.256 1.351l-.283.684a.625.625 0 01-.588.342H9.966a.625.625 0 01-.588-.342zM11.5 8.633l-.5 4.5a.751.751 0 01-.745.667.8.8 0 01-.084-.005.749.749 0 01-.662-.828l.5-4.5a.75.75 0 111.491.166zm2.333 5.167a.789.789 0 01-.084.005.75.75 0 01-.744-.667l-.5-4.5A.75.75 0 1114 8.467l.5 4.5a.75.75 0 01-.667.833z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});