define("ember-svg-jar/inlined/space-ship-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-ship-1</title><path d=\"M20.031 3.97a7.732 7.732 0 00-9.731-.963l-3.4 2.27a1.008 1.008 0 01-.555.168H4.364A4.973 4.973 0 00.826 6.91l-.241.242a2 2 0 000 2.828l.958.958a1 1 0 01.291.645 2.972 2.972 0 00.872 1.933l7.779 7.778a2.979 2.979 0 001.93.871 1 1 0 01.645.291l.96.959a2 2 0 002.827 0l.244-.244a4.972 4.972 0 001.465-3.536v-1.98a1 1 0 01.168-.555l2.269-3.4a7.729 7.729 0 00-.962-9.73zm-4.243 13.435l-2.475 2.475a1 1 0 01-1.414 0L4.12 12.1a1 1 0 010-1.413L6.6 8.212a.5.5 0 01.707 0l8.481 8.488a.5.5 0 010 .707zm1.412-7.071a2.5 2.5 0 110-3.536 2.5 2.5 0 010 3.536zM2.75 2.5a.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25.75.75 0 000-1.5.25.25 0 01-.25-.25.75.75 0 00-1.5 0 .25.25 0 01-.25.25.75.75 0 000 1.5zM5.75 21.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 .25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25.75.75 0 000-1.5zM23.25 16.5a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 .25.25 0 01-.25.25.75.75 0 000 1.5.25.25 0 01.25.25.75.75 0 001.5 0 .25.25 0 01.25-.25.75.75 0 000-1.5z\"/><circle cx=\"3.25\" cy=\"17.25\" r=\".75\"/><circle cx=\"9.25\" cy=\"1.75\" r=\".75\"/><circle cx=\"22.25\" cy=\"21.75\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});