define("ember-svg-jar/inlined/multiple-actions-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-cart</title><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.289 12.3a.249.249 0 00.3.182 1.753 1.753 0 01.411-.055h5.75a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.527 4.527 0 00-3.6 1.76.25.25 0 000 .311 6.978 6.978 0 011.389 2.729zM11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.615 21.154a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.154a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.319a1 1 0 10-1.9-.632l-.882 2.645a.25.25 0 01-.237.171z\"/><circle cx=\"14.5\" cy=\"3.5\" r=\"3\"/><path d=\"M12.289 12.3a.249.249 0 00.3.182 1.753 1.753 0 01.411-.055h5.75a.25.25 0 00.25-.25V11.9a4.456 4.456 0 00-4.5-4.4 4.527 4.527 0 00-3.6 1.76.25.25 0 000 .311 6.978 6.978 0 011.389 2.729zM11 14a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229L3 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 018.69 18h1.81a.5.5 0 00.5-.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M15.615 21.154a1.423 1.423 0 101.423 1.423 1.435 1.435 0 00-1.423-1.423zM19.308 21.154a1.423 1.423 0 101.423 1.423 1.429 1.429 0 00-1.423-1.423zM12.846 14a.5.5 0 00-.474.659l1.846 5.5a.5.5 0 00.473.341h6.232a.5.5 0 00.481-.365c1.69-6.042.852-3.242 2.544-8.319a1 1 0 10-1.9-.632l-.882 2.645a.25.25 0 01-.237.171z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});