define("ember-svg-jar/inlined/satellite-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>satellite-signal</title><path d=\"M11.473 10.7a1.251 1.251 0 101.768-.006 1.281 1.281 0 00-1.768.006z\"/><path d=\"M23.7 17.888l-3.9-3.87a1.494 1.494 0 00-2.121.009l-.7.711-.425-.422a5 5 0 00-6.966-6.893L9.158 7l.7-.709a1.5 1.5 0 00-.01-2.121L5.946.3a.991.991 0 00-1.414 0L1.013 3.853a1 1 0 00.006 1.413l3.907 3.87a1.507 1.507 0 002.122-.009l.7-.71.428.425a5 5 0 006.96 6.9l.425.421-.7.71a1.5 1.5 0 00.009 2.12l3.906 3.87a1 1 0 001.415-.006l3.518-3.55a1 1 0 00-.009-1.419zm-13.452-4.174a3 3 0 114.252-.031l-.007.005-.007.01a3 3 0 01-4.238.016zM9 18a3 3 0 01-3-3 1 1 0 00-2 0 5 5 0 005 5 1 1 0 100-2z\"/><path d=\"M9 22a7.007 7.007 0 01-7-7 1 1 0 00-2 0 9.008 9.008 0 009 9 1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});