define("ember-svg-jar/inlined/modern-music-drums", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-music-drums</title><path d=\"M4.073 19.532A.251.251 0 014 19.355V6.381a.25.25 0 01.057-.159l.684-.834a.249.249 0 01.327-.053l2.959 1.881A1 1 0 109.1 5.529L6.4 3.811a.251.251 0 01-.06-.37l.436-.532a1 1 0 00-1.549-1.268l-.627.766a.249.249 0 01-.327.052l-1.859-1.18a1 1 0 10-1.072 1.687l1.6 1.018A.249.249 0 013 4.353L.227 7.738a1 1 0 101.546 1.269.128.128 0 01.227.081v10.267a.251.251 0 01-.073.177L.293 21.166a1 1 0 001.414 1.414l1.116-1.117a.252.252 0 01.354 0l1.116 1.117a1 1 0 001.414-1.414z\"/><path d=\"M16.611 20.987a.251.251 0 01.015-.3 5.988 5.988 0 00.706-6.565.251.251 0 01.167-.359l.391-.088a1.5 1.5 0 001.134-1.793l-.438-1.951A1.5 1.5 0 0016.793 8.8l-2.928.659a1.5 1.5 0 00-1.15 1.223.25.25 0 01-.265.209 5.622 5.622 0 00-.9 0 .25.25 0 01-.265-.209 1.5 1.5 0 00-1.151-1.223L7.208 8.8a1.5 1.5 0 00-1.794 1.133l-.437 1.951a1.5 1.5 0 001.133 1.793l.391.088a.247.247 0 01.171.139.25.25 0 010 .22 5.988 5.988 0 00.706 6.565.251.251 0 01.015.3l-.221.331a1 1 0 101.664 1.109l.094-.14a.249.249 0 01.323-.083 5.991 5.991 0 005.5 0 .25.25 0 01.323.083l.094.14a1 1 0 101.664-1.109zM12 20.873a4 4 0 114-4 4 4 0 01-4 4z\"/><path d=\"M22.073 19.532a.251.251 0 01-.073-.177V9.088a.128.128 0 01.227-.081 1 1 0 101.546-1.269L21 4.353a.249.249 0 01.059-.369l1.6-1.018a1 1 0 10-1.072-1.687l-1.857 1.18a.249.249 0 01-.327-.052l-.629-.766a1 1 0 10-1.546 1.268l.436.532a.251.251 0 01-.06.37L14.9 5.529a1 1 0 001.073 1.687l2.959-1.881a.249.249 0 01.327.053l.684.834a.25.25 0 01.057.159v12.974a.251.251 0 01-.073.177l-1.634 1.634a1 1 0 001.414 1.414l1.116-1.117a.252.252 0 01.354 0l1.116 1.117a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});