define("ember-svg-jar/inlined/navigation-left-circle-1-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-circle-1-alternate_1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2A10 10 0 112 12 10.011 10.011 0 0112 2z\"/><path d=\"M11.882 8.383l-1.939 1.94a.25.25 0 00.177.427H18a1.25 1.25 0 010 2.5h-7.88a.25.25 0 00-.177.426l1.939 1.94a1.249 1.249 0 01-1.767 1.767l-4.5-4.5a1.247 1.247 0 010-1.767l4.5-4.5a1.249 1.249 0 011.767 1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});