define("ember-svg-jar/inlined/vectors-anchor-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-triangle</title><circle cx=\"12\" cy=\"3\" r=\"3\"/><circle cx=\"3\" cy=\"21\" r=\"3\"/><circle cx=\"21\" cy=\"21\" r=\"3\"/><rect x=\"2.219\" y=\"11\" width=\"10.062\" height=\"2\" rx=\".25\" ry=\".25\" transform=\"rotate(-63.435 7.25 12)\"/><rect x=\"15.75\" y=\"6.969\" width=\"2\" height=\"10.062\" rx=\".25\" ry=\".25\" transform=\"rotate(-26.565 16.75 12)\"/><rect x=\"7.5\" y=\"20\" width=\"9\" height=\"2\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});