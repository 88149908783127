define("ember-svg-jar/inlined/gardening-scissors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-scissors</title><path d=\"M14.723 6.244a6.824 6.824 0 012.075 1.1.5.5 0 00.508.062 6.072 6.072 0 002.7-2.61A7.329 7.329 0 0021.1.34a.451.451 0 00-.4-.34.5.5 0 00-.394.144L14.623 5.83a.25.25 0 00.1.414z\"/><path d=\"M22.312 3.019a.25.25 0 00-.484-.01 10.33 10.33 0 01-.956 2.291 7.187 7.187 0 01-2.8 2.844.25.25 0 00-.127.165.253.253 0 00.041.2 3.259 3.259 0 01-3.794 4.959.252.252 0 00-.325.156 4.346 4.346 0 01-.234.567.5.5 0 01-.8.135l-1.734-1.734a.5.5 0 01.135-.8c.963-.469 1.747-.529 2.341.065v.005l.188.183a2.263 2.263 0 003.177-3.222l-.168-.168a5.894 5.894 0 00-4.34-1.744A6.364 6.364 0 008.12 8.817l-6.551 6.551a.5.5 0 000 .707 2.1 2.1 0 001.54.66c1.474 0 3.208-1.373 4.9-2.714.2-.161.4-.318.6-.47a.5.5 0 01.66.042l2.558 2.558a.5.5 0 01.042.66l-.466.593c-1.847 2.334-3.756 4.747-2.054 6.449a.5.5 0 00.707 0l6.549-6.553a6.362 6.362 0 001.909-4.312.9.9 0 01.434-.741A7.409 7.409 0 0022.3 7.6a9.764 9.764 0 00.012-4.581zM15.173 9.5a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});