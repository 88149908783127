define("ember-svg-jar/inlined/light-mode-brightness", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-brightness</title><path d=\"M22.783 12.884a1.25 1.25 0 000-1.768l-2.46-2.46a.249.249 0 01-.074-.176V5A1.251 1.251 0 0019 3.75h-3.48a.251.251 0 01-.177-.073l-2.46-2.46a1.251 1.251 0 00-1.767 0l-2.46 2.46a.251.251 0 01-.177.073H5A1.252 1.252 0 003.749 5v3.48a.248.248 0 01-.073.176l-2.46 2.46a1.252 1.252 0 000 1.768l2.46 2.46a.249.249 0 01.073.177V19A1.251 1.251 0 005 20.25h3.48a.251.251 0 01.177.073l2.46 2.46a1.25 1.25 0 001.767 0l2.46-2.46a.251.251 0 01.177-.073H19A1.25 1.25 0 0020.249 19v-3.479a.25.25 0 01.074-.177zM7 12a5 5 0 115 5 5 5 0 01-5-5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});