define("ember-svg-jar/inlined/color-picker-5-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-picker-5-alternate</title><path d=\"M17 8a1 1 0 000-2h-.75a.25.25 0 01-.25-.25V4.5A4.277 4.277 0 0012 0a4.277 4.277 0 00-4 4.5v1.25a.25.25 0 01-.25.25H7a1 1 0 000 2zM8.25 9.5a.25.25 0 00-.25.25V14a5.189 5.189 0 001.9 4.042.25.25 0 01.1.2V19a1 1 0 001 1h2a1 1 0 001-1v-.757a.25.25 0 01.1-.2A5.189 5.189 0 0016 14V9.75a.25.25 0 00-.25-.25zm2.25 3.25a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25V15a1.5 1.5 0 01-3 0zM12 21a1 1 0 00-1 1v1a1 1 0 002 0v-1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});