define("ember-svg-jar/inlined/car-actions-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-actions-disable</title><path d=\"M5.443 12.621H1.484a.247.247 0 00-.248.247v.495A1.239 1.239 0 002.474 14.6h1.979a1.239 1.239 0 001.238-1.237v-.495a.247.247 0 00-.248-.247zM12.548 11.631a.248.248 0 00.152-.053 7.966 7.966 0 016.682-1.478 7.634 7.634 0 011.092.338.046.046 0 00.044-.005.049.049 0 00.021-.04V8.124a1.243 1.243 0 00-.363-.875L19.1 6.175a.5.5 0 01-.11-.166L16.963.935A1.478 1.478 0 0015.584 0H5.365a1.478 1.478 0 00-1.378.933l-1.921 4.8a.5.5 0 01-.11.166L.464 7.394a.742.742 0 00-.217.525v2.474a1.238 1.238 0 001.237 1.238zM4.649 4.61l.989-2.475a.248.248 0 01.23-.155h9.213a.248.248 0 01.23.155L16.3 4.61a.25.25 0 01-.025.231.247.247 0 01-.2.108H4.878a.246.246 0 01-.229-.339zM23.6 16.39a6.113 6.113 0 00-4.6-4.6 6.2 6.2 0 00-7.451 7.448 6.113 6.113 0 004.6 4.6 6.4 6.4 0 001.409.162 6.19 6.19 0 006.042-7.61zM14 19.85a.251.251 0 01-.182-.132 3.869 3.869 0 01-.336-.914A4.2 4.2 0 0114.6 14.84a4.144 4.144 0 012.964-1.232 4.589 4.589 0 011 .112 3.845 3.845 0 01.913.336.247.247 0 01.131.182.244.244 0 01-.069.213l-5.334 5.329a.25.25 0 01-.214.07zm6.553.94a4.191 4.191 0 01-3.967 1.12 3.86 3.86 0 01-.911-.335.248.248 0 01-.062-.395l5.329-5.329a.249.249 0 01.214-.07.253.253 0 01.182.132 3.9 3.9 0 01.335.912 4.2 4.2 0 01-1.129 3.965z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});