define("ember-svg-jar/inlined/vintage-tv-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-tv-2</title><path d=\"M22.5 5h-7.482a.25.25 0 01-.177-.427l2.866-2.866A1 1 0 0016.293.293l-3.616 3.616a.25.25 0 01-.354 0L8.707.293a1 1 0 00-1.414 1.414l2.866 2.866A.25.25 0 019.982 5H1.5A1.5 1.5 0 000 6.5v14A1.5 1.5 0 001.5 22h.689a.249.249 0 01.177.073l1.488 1.488a1.511 1.511 0 001.06.439h14.172a1.511 1.511 0 001.06-.439l1.488-1.488a.249.249 0 01.177-.073h.689a1.5 1.5 0 001.5-1.5v-14A1.5 1.5 0 0022.5 5zM16 7.5a1 1 0 011 1v10a1 1 0 01-1 1H3.5a1 1 0 01-1-1v-10a1 1 0 011-1zm2.75 10.25a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-2a.75.75 0 01-.75-.75zm2.75-2.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm-2.5-6a1.5 1.5 0 111.5 1.5A1.5 1.5 0 0119 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});