define("ember-svg-jar/inlined/police-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-1</title><path d=\"M.5 23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-2.106a2.459 2.459 0 00-.967-1.976C21.106 18.328 17.839 16.5 12 16.5s-9.106 1.828-10.533 2.917A2.462 2.462 0 00.5 21.394zm16.361-.54l-.609-.407a2.247 2.247 0 01-1-1.872v-.929a.249.249 0 01.25-.25h3a.249.249 0 01.25.25v.929a2.246 2.246 0 01-1 1.872l-.609.407a.251.251 0 01-.278 0z\"/><path d=\"M17.146 15.839a5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8c-2.548-.818-2.162-4.023-2.153-8.147a.248.248 0 01.112-.208 1.462 1.462 0 00.591-.755l.771-2.316a.5.5 0 00-.556-.651c-2.324.388-4.059-.375-6.257-1.339-.473-.203-.961-.417-1.472-.627a.5.5 0 00-.378 0c-.511.21-1 .424-1.472.631-2.2.964-3.935 1.725-6.257 1.339a.5.5 0 00-.556.651L4.3 4.974a1.457 1.457 0 00.591.755.248.248 0 01.109.208c.01 4.009.425 7.259-2.153 8.087a.5.5 0 00-.231.8 3.8 3.8 0 002.921 1.12 5.351 5.351 0 001.317-.162 3.435 3.435 0 001.029-.437.252.252 0 01.274 0 6.972 6.972 0 007.641.032.252.252 0 01.275 0 3.46 3.46 0 001.073.462zm-5.132-9.5a.251.251 0 01-.278 0l-.609-.407a2.247 2.247 0 01-1-1.872v-.933a.249.249 0 01.25-.25h3a.249.249 0 01.25.25v.929a2.246 2.246 0 01-1 1.872zM7 9.5V7.792a.249.249 0 01.369-.219A9.691 9.691 0 0012 8.75a9.683 9.683 0 004.631-1.178.25.25 0 01.369.22V9.5a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"10.001\" r=\"1\"/><circle cx=\"10\" cy=\"10.001\" r=\"1\"/><path d=\"M13.077 12.132h-2a.639.639 0 00-.3 1.2 2.3 2.3 0 00.437.181 2.967 2.967 0 00.868.126 3.016 3.016 0 00.868-.125 2.365 2.365 0 00.439-.182.64.64 0 00-.307-1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});