define("ember-svg-jar/inlined/modern-tv-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-apple</title><path d=\"M19.5 19.5h-15a6 6 0 01-4.08-1.6.25.25 0 00-.42.183v.417A4.505 4.505 0 004.5 23h15a4.505 4.505 0 004.5-4.5v-.418a.25.25 0 00-.42-.183A6 6 0 0119.5 19.5z\"/><path d=\"M23.957 12.887L22.562 4.74A4.49 4.49 0 0018.127 1H5.873a4.492 4.492 0 00-4.436 3.74l-1.4 8.165A4.5 4.5 0 004.5 18h15a4.5 4.5 0 004.458-5.113zM6.5 7.25a.75.75 0 010-1.5h4a.75.75 0 010 1.5H9.25v5.25a.75.75 0 01-1.5 0V7.25zm5.712-1.442a.751.751 0 01.98.4L14.769 10a.25.25 0 00.462 0l1.577-3.785a.75.75 0 011.384.578l-2.5 6a.75.75 0 01-1.384 0l-2.5-6a.749.749 0 01.404-.985z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});