define("ember-svg-jar/inlined/vectors-line-path-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-line-path-alternate</title><rect y=\"18\" width=\"6\" height=\"6\" rx=\"2\" ry=\"2\"/><rect x=\"18\" width=\"6\" height=\"6\" rx=\"2\" ry=\"2\"/><rect x=\"4.222\" y=\"11\" width=\"15.556\" height=\"2\" rx=\".25\" ry=\".25\" transform=\"rotate(-45 12 12)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});