define("ember-svg-jar/inlined/design-tool-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-pen</title><path d=\"M21.5 19a1 1 0 00-1 1 2 2 0 01-4 0V5a5.006 5.006 0 00-5-5h-2a5.006 5.006 0 00-5 5v.5a1 1 0 002 0V5a3 3 0 013-3h2a3 3 0 013 3v15a4 4 0 008 0 1 1 0 00-1-1z\"/><path d=\"M19 15a2.5 2.5 0 005 0V5.75a.25.25 0 00-.25-.25h-4.5a.25.25 0 00-.25.25zM23.947 4.276l-2-4a.521.521 0 00-.894 0l-2 4a.154.154 0 00.138.224h4.618a.154.154 0 00.138-.224zM10.75 13a.25.25 0 00.25-.25V10.5A2.5 2.5 0 008.5 8h-2a.25.25 0 00-.25.25v4.5a.25.25 0 00.25.25zM4.75 8.25A.25.25 0 004.5 8h-2A2.5 2.5 0 000 10.5v2.25a.25.25 0 00.25.25H4.5a.25.25 0 00.25-.25zM0 18.5a5.5 5.5 0 0011 0v-3.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});