define("ember-svg-jar/inlined/medical-app-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-app-smartphone</title><path d=\"M15.5 10H14a.5.5 0 01-.5-.5V8a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.5a.5.5 0 01-.5.5H8.5a.5.5 0 00-.5.5v2a.5.5 0 00.5.5H10a.5.5 0 01.5.5V15a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h1.5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});