define("ember-svg-jar/inlined/spa-candle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>spa-candle</title><path d=\"M7.18 7.255a2.88 2.88 0 002.879-2.879A5.309 5.309 0 007.786.089a.5.5 0 00-.763.561 3.155 3.155 0 01.086 1.523.464.464 0 01-.86.14.5.5 0 00-.7-.18A2.553 2.553 0 004.3 4.376a2.88 2.88 0 002.88 2.879zM10.727 22.328a4.092 4.092 0 01.18-1.392.5.5 0 00-.178-.546 3.529 3.529 0 01-1.4-3.879 3.331 3.331 0 012.032-2.059 3.75 3.75 0 01.937-.211.5.5 0 00.449-.5V9.5a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v13a1.5 1.5 0 001.5 1.5h7.206a.5.5 0 00.471-.67 3.4 3.4 0 01-.197-1.002zM8.249 13v1.5a1.25 1.25 0 01-2.5 0V13a1 1 0 00-1-1h-.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h6a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-1a1 1 0 00-1 1z\"/><path d=\"M20.412 19.649a2.292 2.292 0 001.759-2.674 2.292 2.292 0 00-3-1.13 3.061 3.061 0 00-.593.264.124.124 0 01-.136-.007.126.126 0 01-.049-.128 3.08 3.08 0 00.068-.645 2.293 2.293 0 00-2-2.5 2.292 2.292 0 00-2 2.5 3.08 3.08 0 00.067.645.124.124 0 01-.184.135 3.07 3.07 0 00-.594-.264 2.292 2.292 0 00-3 1.13 2.293 2.293 0 001.76 2.674 3.084 3.084 0 00.705.142.094.094 0 01.082.064.1.1 0 01-.028.1 3.085 3.085 0 00-.488.528 2.294 2.294 0 00.149 3.2 2.293 2.293 0 003.088-.847 3.14 3.14 0 00.325-.563.125.125 0 01.228 0 3.081 3.081 0 00.325.563 2.293 2.293 0 003.088.847 2.294 2.294 0 00.148-3.2 3.147 3.147 0 00-.426-.483.125.125 0 01.07-.218 3.049 3.049 0 00.636-.133zm-3.947.679a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});