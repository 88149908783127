define("ember-svg-jar/inlined/yoga-arm-stretch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>yoga-arm-stretch</title><circle cx=\"2.5\" cy=\"11.997\" r=\"2.5\"/><path d=\"M23.841 21.326c-.386-.772-3.926-8.27-5.3-11.181A2 2 0 0016.731 9H9.5V2a1.5 1.5 0 00-3 0v20a1.5 1.5 0 003 0v-7.5a.5.5 0 01.5-.5h3.948a.5.5 0 01.449.72l-3.239 6.609a1.5 1.5 0 102.683 1.342l3.212-6.422a.5.5 0 01.894 0l3.211 6.422a1.5 1.5 0 102.683-1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});