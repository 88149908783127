define("ember-svg-jar/inlined/lab-tube-experiment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-tube-experiment</title><path d=\"M17.5.62A1.5 1.5 0 0016.285 0H7.714A1.5 1.5 0 006.5.62 1.52 1.52 0 006.3 2 12.844 12.844 0 017 6.19V19a5 5 0 0010 0V6.19a12.853 12.853 0 01.708-4.2A1.518 1.518 0 0017.5.62zM9.664 14.745A1.256 1.256 0 1110.919 16a1.245 1.245 0 01-1.255-1.255zM12.919 21a1.25 1.25 0 111.256-1.255A1.255 1.255 0 0112.919 21zm.506-9a1.24 1.24 0 01-1.25-1.239A1.25 1.25 0 1113.425 12zm2.006-9.379A14.8 14.8 0 0015 6.19V7.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5V6.19a14.9 14.9 0 00-.429-3.57.5.5 0 01.481-.62h5.893a.5.5 0 01.486.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});