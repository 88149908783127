define("ember-svg-jar/inlined/single-neutral-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-up-down</title><path d=\"M3.854.146a.5.5 0 00-.708 0l-3 3A.5.5 0 00.5 4H2a.25.25 0 01.25.25V11a1.25 1.25 0 002.5 0V4.25A.25.25 0 015 4h1.5a.5.5 0 00.354-.854zM23.854 20.854A.5.5 0 0023.5 20H22a.25.25 0 01-.25-.25V13a1.25 1.25 0 00-2.5 0v6.75A.25.25 0 0119 20h-1.5a.5.5 0 00-.354.854l3 3a.5.5 0 00.708 0z\"/><circle cx=\"12\" cy=\"6.5\" r=\"2.5\"/><path d=\"M12 10a4.055 4.055 0 00-4 4.1v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.337a.5.5 0 00.5.442h2.334a.5.5 0 00.5-.442l.506-4.337a.251.251 0 01.241-.221H15.5a.5.5 0 00.5-.5v-2.4a4.055 4.055 0 00-4-4.1zM3.854.146a.5.5 0 00-.708 0l-3 3A.5.5 0 00.5 4H2a.25.25 0 01.25.25V11a1.25 1.25 0 002.5 0V4.25A.25.25 0 015 4h1.5a.5.5 0 00.354-.854zM23.854 20.854A.5.5 0 0023.5 20H22a.25.25 0 01-.25-.25V13a1.25 1.25 0 00-2.5 0v6.75A.25.25 0 0119 20h-1.5a.5.5 0 00-.354.854l3 3a.5.5 0 00.708 0z\"/><circle cx=\"12\" cy=\"6.5\" r=\"2.5\"/><path d=\"M12 10a4.055 4.055 0 00-4 4.1v2.4a.5.5 0 00.5.5h1.082a.251.251 0 01.249.221l.506 4.337a.5.5 0 00.5.442h2.334a.5.5 0 00.5-.442l.506-4.337a.251.251 0 01.241-.221H15.5a.5.5 0 00.5-.5v-2.4a4.055 4.055 0 00-4-4.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});