define("ember-svg-jar/inlined/style-three-pin-toilet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-toilet</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.629 3.175A.5.5 0 018 3.01h8a.5.5 0 01.371.165.494.494 0 01.126.385L16 8.55a.5.5 0 01-.5.45h-7a.5.5 0 01-.5-.45l-.5-4.99a.494.494 0 01.129-.385zm8.1 11.158a4.994 4.994 0 01-1.393 1.083.249.249 0 00-.118.3l.515 1.442a1 1 0 01-.943 1.342h-3.58a1 1 0 01-.941-1.336l.515-1.443a.249.249 0 00-.118-.3 5.009 5.009 0 01-1.393-1.083.5.5 0 01.373-.833h6.708a.5.5 0 01.373.833zM17.5 12a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-.5A1.5 1.5 0 018 10h8a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});