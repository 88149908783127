define("ember-svg-jar/inlined/rain-umbrella-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella-sun</title><path d=\"M23 7.75h-1a1 1 0 000 2h1a1 1 0 000-2zM20.023 4.977a1 1 0 00.707-.293l.708-.707a1 1 0 00-1.415-1.414l-.707.707a1 1 0 00.707 1.707zM15.25 3a1 1 0 001-1V1a1 1 0 00-2 0v1a1 1 0 001 1zM9.77 4.684a1 1 0 101.414-1.414l-.707-.707a1 1 0 10-1.414 1.414zM20.73 12.816a1 1 0 10-1.414 1.414l.707.707a1 1 0 101.415-1.414z\"/><path d=\"M11.484 8.2a.251.251 0 00.342-.178 3.5 3.5 0 114.374 4.096.249.249 0 00-.155.353 8.894 8.894 0 01.555 1.359.251.251 0 00.315.164 5.5 5.5 0 10-7-6.551.251.251 0 00.185.3 8.995 8.995 0 011.384.457zM7 21.5a2.5 2.5 0 005 0 1 1 0 00-2 0 .5.5 0 01-1 0v-3.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M7 8.345a.25.25 0 01-.212.247A8.013 8.013 0 000 16.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.449V16.5a8.013 8.013 0 00-6.788-7.908A.25.25 0 019 8.345V8a1 1 0 10-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});