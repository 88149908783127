define("ember-svg-jar/inlined/tags-lock-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-lock-alternate</title><circle cx=\"17.17\" cy=\"5.999\" r=\"1.5\"/><path d=\"M22.714 15.514a.249.249 0 01-.214-.247v-1.018a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.215.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.486zM20 20.249a1 1 0 11-1-1 1 1 0 011 1zm-2.5-6a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25z\"/><path d=\"M12.5 18.859a.25.25 0 00-.427-.177l-2.2 2.2a1.025 1.025 0 01-1.414 0l-6.168-6.176a1 1 0 010-1.414l11-11A1 1 0 0114 2h6.67a.5.5 0 01.5.5v6.67a.984.984 0 01-.049.312.251.251 0 00.125.3 5.013 5.013 0 011.192.84.251.251 0 00.394-.066 2.986 2.986 0 00.338-1.386V2a2 2 0 00-2-2H14a2.982 2.982 0 00-2.122.879l-11 11a3 3 0 000 4.242l6.172 6.172a3 3 0 004.242 0l1.135-1.135a.252.252 0 00.074-.177z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});