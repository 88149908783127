define("ember-svg-jar/inlined/video-game-logo-twitch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-twitch</title><path d=\"M22.75 0H4.033A1.223 1.223 0 003 .554L.25 4.365a1.739 1.739 0 00-.25.9v12.486A1.252 1.252 0 001.25 19H4.5a.5.5 0 01.5.5V23a1 1 0 001 1h2.107a1 1 0 00.866-.5l2.447-4.246a.5.5 0 01.433-.25h6.482a1.736 1.736 0 001.237-.513l3.914-3.914A1.763 1.763 0 0024 13.336V1.251A1.251 1.251 0 0022.75 0zM21 12.586a1 1 0 01-.293.707l-2.414 2.415a1 1 0 01-.707.293H10.76a.5.5 0 00-.409.213L8.9 18.29a.493.493 0 01-.9-.283V16.5a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h15a.5.5 0 01.5.5z\"/><path d=\"M11 6.251a.75.75 0 00-.75.75v4a.75.75 0 001.5 0V7a.75.75 0 00-.75-.749zM16 6.251a.75.75 0 00-.75.75v4a.75.75 0 001.5 0V7a.75.75 0 00-.75-.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});