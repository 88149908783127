define("ember-svg-jar/inlined/platypus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>platypus</title><path d=\"M22.8 4.663a3.207 3.207 0 10-5.273 3.63 1.045 1.045 0 01-.069 1.507c-2.633-.622-5.694.587-6.626 1.007a3.987 3.987 0 00-6.988 1.927 7.908 7.908 0 01-3.067.4.75.75 0 00-.75.75c0 .762.126 3.25 1.75 3.25a15.8 15.8 0 003.376-.764 4.141 4.141 0 002.625 1.015h1.047a.246.246 0 01.218.126.249.249 0 010 .253 1.354 1.354 0 01-1.263.624 1 1 0 000 2 3.326 3.326 0 003.428-2.826.25.25 0 01.243-.2l1.665-.024a.251.251 0 00.177-.077.254.254 0 00.069-.181 4.558 4.558 0 01.092-1.1 2.638 2.638 0 012.485-2.38.5.5 0 11-.042 1c-.106.028-1.155.025-1.466 1.577a1.959 1.959 0 00.394 2.01l-1.809.794a1 1 0 00.8 1.832l3.7-1.622a3.8 3.8 0 002.17-1.515 6.2 6.2 0 001.038-3.408 5.531 5.531 0 003.147-4.275 7.817 7.817 0 00-1.071-5.33zm-.9 5a3.643 3.643 0 01-1.3 2.345.25.25 0 01-.381-.085 3.905 3.905 0 00-.787-1.09.25.25 0 01-.043-.306 2.989 2.989 0 00-.277-3.452 1.207 1.207 0 111.969-1.388A5.769 5.769 0 0121.9 9.66zm-15.373 3.47a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});