define("ember-svg-jar/inlined/data-file-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-disable</title><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.275a.248.248 0 00.242.25c.366.007 1.038.034 1.485.082A.245.245 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.858a.245.245 0 00-.241.21 7.956 7.956 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M12.5 11.8l1.438-2.03a.25.25 0 01.332-.071l1.507.905A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.252.252 0 01-.332.068L14.3 7.387a1 1 0 00-1.33.28l-2.042 2.884A.243.243 0 0011 10.9a8.06 8.06 0 011.128.932.243.243 0 00.372-.032zM6.5 24A6.5 6.5 0 100 17.5 6.5 6.5 0 006.5 24zm3.967-8.6A4.484 4.484 0 014.4 21.466a.252.252 0 01-.052-.4l5.724-5.722a.252.252 0 01.395.056zm-8.3.886a4.492 4.492 0 013.112-3.115 4.439 4.439 0 013.325.366.252.252 0 01.052.4l-5.725 5.717a.252.252 0 01-.4-.051 4.45 4.45 0 01-.365-3.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});