define("ember-svg-jar/inlined/diagram-merge-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-merge-down</title><path d=\"M24 1.44A1.44 1.44 0 0022.56 0h-4.62a7.359 7.359 0 00-5.74 2.766.251.251 0 01-.39 0A7.359 7.359 0 006.06 0H1.44a1.44 1.44 0 000 2.88h4.62a4.505 4.505 0 014.5 4.5v10.61a.25.25 0 01-.25.25H9.12A1.44 1.44 0 008.1 20.7l2.878 2.878a1.431 1.431 0 00.473.315l.021.007a1.407 1.407 0 001.052 0l.021-.007a1.431 1.431 0 00.473-.315L15.9 20.7a1.44 1.44 0 00-1.02-2.46h-1.19a.25.25 0 01-.25-.25V7.38a4.505 4.505 0 014.5-4.5h4.62A1.44 1.44 0 0024 1.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});