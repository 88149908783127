define("ember-svg-jar/inlined/navigation-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-next</title><path d=\"M12.751 11.5a.25.25 0 00-.25.25v1.75a2.5 2.5 0 01-5 0v-2a5.006 5.006 0 015-5h4.25a.25.25 0 00.25-.25V5.5a2 2 0 00-2-2H2a2 2 0 00-2 2v13a2 2 0 002 2h13a2 2 0 002-2v-6.75a.25.25 0 00-.25-.25z\"/><path d=\"M23.708 8.291l-3.5-3.5A1 1 0 0018.5 5.5v2.25a.25.25 0 01-.25.25H12.5A3.5 3.5 0 009 11.5v2a1 1 0 002 0v-2a1.5 1.5 0 011.5-1.5h5.75a.25.25 0 01.25.25v2.25a1 1 0 001.707.707l3.5-3.5a1 1 0 00.001-1.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});