define("ember-svg-jar/inlined/paragraph-center-align", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paragraph-center-align</title><path d=\"M22 23.748H2a1.25 1.25 0 010-2.5h20a1.25 1.25 0 010 2.5zM22 2.748H2a1.25 1.25 0 010-2.5h20a1.25 1.25 0 010 2.5zM20 9.748H4a1.25 1.25 0 110-2.5h16a1.25 1.25 0 110 2.5zM17.5 16.748h-11a1.25 1.25 0 110-2.5h11a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});