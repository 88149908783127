define("ember-svg-jar/inlined/single-neutral-actions-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-share-1</title><path d=\"M6.5 13.75v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H17a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"12\" cy=\"3.75\" r=\"3.5\"/><path d=\"M6.5 13.75v3.5a.5.5 0 00.5.5h1.81a.25.25 0 01.249.229l.441 5.313a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H17a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0z\"/><circle cx=\"12\" cy=\"3.75\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});