define("ember-svg-jar/inlined/style-three-pin-snow-slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snow-slide</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.578 6.477a1.5 1.5 0 012.846-.95l.776 2.318a.5.5 0 00.209.266L13.8 10.23a1.5 1.5 0 11-1.59 2.543l-4-2.5a1.494 1.494 0 01-.628-.8zm9.528 10.048a5.008 5.008 0 01-4.228-.511l-8.239-5.148a.75.75 0 11.8-1.273l8.239 5.148a3.516 3.516 0 002.965.359.75.75 0 01.468 1.425zM17 12a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});