define("ember-svg-jar/inlined/fruit-scale-apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-scale-apple</title><path d=\"M21.807 20H20.25a.25.25 0 01-.25-.25v-8.145a.249.249 0 01.14-.224 6 6 0 10-5.28 0 .249.249 0 01.14.224v8.145a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h3.75a1 1 0 000-2h-10a1 1 0 000 2h3.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H2.193a1 1 0 00-.948 1.316l.667 2A1 1 0 002.86 24h18.28a1 1 0 00.948-.683l.666-2A1 1 0 0021.807 20zM17.5 2a4 4 0 014 4 3.677 3.677 0 01-.095.8.248.248 0 01-.244.2h-2.777a.033.033 0 01-.027-.015l-1.5-2.5a1 1 0 10-1.714 1.03l.664 1.106a.25.25 0 01-.215.379h-1.753a.249.249 0 01-.244-.2 3.677 3.677 0 01-.1-.8A4 4 0 0117.5 2z\"/><path d=\"M6.306 15.364a.249.249 0 01.281 0 2.4 2.4 0 003.429-1.139 7.081 7.081 0 00.931-3.013c0-1.8-1.177-3.316-2.59-3.316a3.485 3.485 0 00-.579.044.25.25 0 01-.291-.27c.046-.477.236-.787 1-.94a1 1 0 10-.392-1.96 2.942 2.942 0 00-2.608 2.944.252.252 0 01-.095.181.25.25 0 01-.2.049 3.389 3.389 0 00-.6-.048C3.177 7.9 2 9.407 2 11.212a7.516 7.516 0 00.946 3.038 2.33 2.33 0 003.36 1.114z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});