define("ember-svg-jar/inlined/plane-trip-take-off-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-take-off-cancel</title><path d=\"M6.339 2.762l3.87 1.6a.249.249 0 00.2 0l4.25-1.85a.249.249 0 000-.46L10.449.318a3.477 3.477 0 00-2.676 0L6.339.914a1 1 0 000 1.848z\"/><path d=\"M23.588 2.49a2.5 2.5 0 00-3.274-1.349L6.968 6.947a.253.253 0 01-.212-.006L4.279 5.7a1.006 1.006 0 00-.843-.026L.83 6.794a1 1 0 00-.315 1.627l3.045 3.046a2.5 2.5 0 002.7.553l4.7-1.879a.25.25 0 01.327.319l-.6 1.588a.231.231 0 00.38.244A7.965 7.965 0 0116.3 9.965a.25.25 0 00.211-.137l1.147-2.295a.249.249 0 01.142-.123l4.437-1.653a2.5 2.5 0 001.351-3.267zM18.1 12.106a6.01 6.01 0 00-7.226 7.222 5.941 5.941 0 005.834 4.621 6 6 0 005.855-7.381 5.927 5.927 0 00-4.463-4.462zm-5 7.576a4.013 4.013 0 015.348-5.347.25.25 0 01.068.4l-5.008 5.015a.25.25 0 01-.402-.068zm6.444 1.1a4.009 4.009 0 01-4.56.786.25.25 0 01-.068-.4l5.014-5.013a.25.25 0 01.4.068 4.01 4.01 0 01-.78 4.557z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});