define("ember-svg-jar/inlined/shipment-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-question</title><path d=\"M8.5 3.75a.25.25 0 00.25.25h5.35a.25.25 0 00.177-.427L11.4.689A1.511 1.511 0 0010.336.25H8.75A.25.25 0 008.5.5zM15.069 9.562a.248.248 0 00.182-.24V5.75A.25.25 0 0015 5.5H.5a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5H9.1a.25.25 0 00.247-.212 8.01 8.01 0 015.722-6.476zM6.75 4A.249.249 0 007 3.75V.5a.249.249 0 00-.25-.25H5.165A1.511 1.511 0 004.1.689L1.22 3.573A.25.25 0 001.4 4z\"/><path d=\"M17.25 10.75a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.625 4.875a2.64 2.64 0 01-1.731 2.468.219.219 0 00-.144.207v.2a.75.75 0 01-1.5 0v-.553a1.257 1.257 0 01.988-1.222 1.125 1.125 0 10-1.363-1.1.75.75 0 01-1.5 0 2.625 2.625 0 015.25 0zM17.25 20.25a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});