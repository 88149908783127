define("ember-svg-jar/inlined/monitor-antenna", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-antenna</title><path d=\"M.312 11.691a.72.72 0 00.7.393h5.6a.694.694 0 00.56-1.184l-.833-1.014a.25.25 0 01.222-.407 4.323 4.323 0 00.51.031 5.477 5.477 0 00.765-.054 5.3 5.3 0 003.511-2.09.49.49 0 00-.1-.688l-3-2.242a.253.253 0 01-.1-.18.249.249 0 01.068-.193l2.2-2.3A.983.983 0 109 .405L6.593 2.92a.249.249 0 01-.33.027L3.493.874a.492.492 0 00-.689.1 5.314 5.314 0 00.138 6.568.249.249 0 010 .316L.451 10.9a.72.72 0 00-.139.791zM18.219 10.566a.983.983 0 00.983-.984V6.676a3.937 3.937 0 00-3.933-3.933h-2.217a.983.983 0 000 1.966h2.217a1.969 1.969 0 011.967 1.967v2.906a.983.983 0 00.983.984zM3.47 14.253a.983.983 0 00-.983.983v2.256a3.937 3.937 0 003.933 3.933h2.945a.984.984 0 000-1.967H6.42a1.969 1.969 0 01-1.967-1.966v-2.256a.983.983 0 00-.983-.983zM11.951 18.489v.682a1.466 1.466 0 00.477 1.079 1.955 1.955 0 001.49.7h2.7a.249.249 0 01.25.25v.483a.25.25 0 01-.25.25h-1.226a.983.983 0 000 1.966h4.917a.983.983 0 000-1.966h-1.225a.25.25 0 01-.25-.25V21.2a.249.249 0 01.25-.25h2.7a1.954 1.954 0 001.489-.7 1.466 1.466 0 00.477-1.079v-5.6a1.969 1.969 0 00-1.966-1.967h-7.866a1.969 1.969 0 00-1.967 1.967zm9.583-4.916a.25.25 0 01.25.25v3.925a.25.25 0 01-.25.25h-7.366a.249.249 0 01-.25-.25v-3.925a.249.249 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});