define("ember-svg-jar/inlined/screen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>screen-1</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v13.25a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V1.5A1.5 1.5 0 0022.5 0zM24 16.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25V18a1.5 1.5 0 001.5 1.5h9.249a.247.247 0 01.176.073.252.252 0 01.075.177v2a.25.25 0 01-.25.25H7.5a1 1 0 100 2h9a1 1 0 000-2h-3.253a.252.252 0 01-.177-.074.247.247 0 01-.07-.175v-2a.25.25 0 01.25-.25h9.25A1.5 1.5 0 0024 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});