define("ember-svg-jar/inlined/camera-settings-focus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>camera-settings-focus</title><path d=\"M17 8h-1a.913.913 0 01-.5-.207L14.207 6.5A1.872 1.872 0 0013 6h-2a1.872 1.872 0 00-1.207.5L8.5 7.793A.913.913 0 018 8H7a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2zm-7.5 4.5A2.5 2.5 0 1112 15a2.5 2.5 0 01-2.5-2.5zM.75 7.5a.75.75 0 00.75-.75A2.253 2.253 0 013.75 4.5a.75.75 0 000-1.5A3.755 3.755 0 000 6.75a.75.75 0 00.75.75z\"/><path d=\"M2.25 7a.75.75 0 001.5 0A.25.25 0 014 6.75a.75.75 0 000-1.5A1.752 1.752 0 002.25 7zM3.75 19.5a2.253 2.253 0 01-2.25-2.25.75.75 0 00-1.5 0A3.755 3.755 0 003.75 21a.75.75 0 000-1.5z\"/><path d=\"M4 17.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0A1.752 1.752 0 004 18.75a.75.75 0 000-1.5zM20.25 4.5a2.253 2.253 0 012.25 2.25.75.75 0 001.5 0A3.755 3.755 0 0020.25 3a.75.75 0 000 1.5z\"/><path d=\"M20 6.75a.25.25 0 01.25.25.75.75 0 001.5 0A1.752 1.752 0 0020 5.25a.75.75 0 000 1.5zM23.25 16.5a.75.75 0 00-.75.75 2.253 2.253 0 01-2.25 2.25.75.75 0 000 1.5A3.755 3.755 0 0024 17.25a.75.75 0 00-.75-.75z\"/><path d=\"M21.75 17a.75.75 0 00-1.5 0 .25.25 0 01-.25.25.75.75 0 000 1.5A1.752 1.752 0 0021.75 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});