define("ember-svg-jar/inlined/social-media-vk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-vk</title><path d=\"M23.71 16.87a13.19 13.19 0 00-2.41-2.78c-1.29-1.2-1.29-1.2 0-2.84l.79-1c2-2.62 2.05-3.49 1.87-4-.29-.8-1.5-.7-1.68-.68h-3.39a1.26 1.26 0 00-.77.14 1.45 1.45 0 00-.52.62c-.62 1.65-2.32 4.71-3 5A5.35 5.35 0 0114.46 9c.11-1.64.23-3.5-1.16-3.84a6.36 6.36 0 00-1.73-.17 7.51 7.51 0 00-3.1.35c-.39.19-.91.67-.84 1.13a.62.62 0 00.48.46 1.06 1.06 0 01.68.31 8.28 8.28 0 010 4.16c-.66-.2-2.29-3.11-3-4.92a1.5 1.5 0 00-.45-.6 1.86 1.86 0 00-.83-.31h-3.3A1.5 1.5 0 00.19 6 1.17 1.17 0 00.1 7.06c.11.24 2.64 5.93 5.58 9a7.64 7.64 0 006.07 2.55 11.63 11.63 0 002.37-.23 2.09 2.09 0 00.4-1.38c0-.72.1-1 .41-1.05s.91.77 1.22 1.12a8.16 8.16 0 001.42 1.41 3 3 0 001.65.58h3.27a1.78 1.78 0 001.4-.75 1.36 1.36 0 00-.18-1.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});