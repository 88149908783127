define("ember-svg-jar/inlined/earth-model-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>earth-model-1</title><path d=\"M19.2.4a1.245 1.245 0 00-1.837 1.682A9.713 9.713 0 013.668 15.83a1.245 1.245 0 10-1.676 1.841A12.1 12.1 0 008.327 20.7a.249.249 0 01.04.483 12.3 12.3 0 00-1.7.7A1.121 1.121 0 007.176 24h10.533a1.121 1.121 0 00.5-2.123 12.621 12.621 0 00-3.983-1.22.25.25 0 01-.047-.483A12.2 12.2 0 0019.2.4z\"/><path d=\"M10.2 16.611a7.97 7.97 0 10-7.97-7.969 7.979 7.979 0 007.97 7.969zm.5-13.7a.747.747 0 010 1.494 4.738 4.738 0 00-4.733 4.733.748.748 0 01-1.495 0A6.234 6.234 0 0110.7 2.912z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});