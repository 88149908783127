define("ember-svg-jar/inlined/folder-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-download</title><path d=\"M17.5 9.5a7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.563a.447.447 0 00.437-.5 8.009 8.009 0 018-8z\"/><path d=\"M17.5 24a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm-2.391-5.688a.5.5 0 01.391-.812h.75a.25.25 0 00.25-.25V14.5a1 1 0 012 0v2.75a.25.25 0 00.25.25h.75a.5.5 0 01.391.813l-2 2.5a.518.518 0 01-.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});