define("ember-svg-jar/inlined/car-truck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-truck</title><path d=\"M23 15.18a.25.25 0 01-.25-.25v-3.25a1 1 0 00-1-1h-7a.25.25 0 01-.25-.25V6.68a1.5 1.5 0 00-1.5-1.5H9.24a2.985 2.985 0 00-2.727 1.761L5.18 10.517a.251.251 0 01-.234.163H2a2 2 0 00-2 2v3a.99.99 0 001 1h.644a.249.249 0 00.248-.22 2.988 2.988 0 015.935 0 .25.25 0 00.248.22h5.569a.249.249 0 00.248-.22 2.995 2.995 0 015.968.36v.091a.25.25 0 00.25.269H23a1 1 0 000-2zM12.5 9.93a.25.25 0 01-.25.25H7.8a.249.249 0 01-.234-.337l.8-2.145a1 1 0 01.874-.518h3.01a.25.25 0 01.25.25z\"/><circle cx=\"4.86\" cy=\"16.82\" r=\"2\"/><circle cx=\"16.86\" cy=\"16.82\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});