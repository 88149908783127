define("ember-svg-jar/inlined/drugs-cannabis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drugs-cannabis</title><path d=\"M22.791 16.6a12.4 12.4 0 00-4.972-1.125 10.378 10.378 0 00-1.108.059.25.25 0 01-.175-.45 17.564 17.564 0 002-1.71 19.263 19.263 0 003.627-5.184.78.78 0 00-1.039-1.042 19.252 19.252 0 00-5.186 3.628 17.364 17.364 0 00-1.561 1.8.25.25 0 01-.444-.2 19.876 19.876 0 00.433-4.053 20.344 20.344 0 00-1.645-7.84.78.78 0 00-1.442 0 20.344 20.344 0 00-1.645 7.833 19.868 19.868 0 00.433 4.053.25.25 0 01-.444.2 17.268 17.268 0 00-1.561-1.8 19.282 19.282 0 00-5.186-3.623.78.78 0 00-1.039 1.042 19.263 19.263 0 003.627 5.184 17.478 17.478 0 002 1.71.25.25 0 01-.175.45 10.392 10.392 0 00-1.108-.059A12.382 12.382 0 001.209 16.6a.78.78 0 000 1.422 12.411 12.411 0 004.971 1.125 10.781 10.781 0 001.713-.14.219.219 0 01.189.371 9.658 9.658 0 00-1.83 2.642.78.78 0 00.152.885c.634.635 2.906-.418 4.477-1.27a.25.25 0 01.369.219v1.4a.75.75 0 001.5 0v-1.4a.25.25 0 01.369-.22 10.718 10.718 0 003.867 1.466.835.835 0 00.609-.2.778.778 0 00.153-.885 9.658 9.658 0 00-1.831-2.643.219.219 0 01.189-.371 10.781 10.781 0 001.713.14 12.4 12.4 0 004.972-1.126.779.779 0 000-1.421z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});