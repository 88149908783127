define("ember-svg-jar/inlined/cash-payment-bills-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bills-1</title><path d=\"M7.658 1.881l1.414 5.373a1 1 0 00.966.746.983.983 0 00.256-.033 1 1 0 00.711-1.221L9.84 2.314A.25.25 0 0110.082 2h5.957a1 1 0 000-2H9.12a1.475 1.475 0 00-1.162.568 1.588 1.588 0 00-.325 1.194.968.968 0 00.025.119z\"/><path d=\"M21.867 3.561A1.478 1.478 0 0020.708 3h-7.419a1.485 1.485 0 00-1.136.532 1.559 1.559 0 00-.353 1.154.981.981 0 00.022.13l1.5 6.412a1 1 0 101.947-.456l-1.283-5.465A.251.251 0 0114.23 5h5.619a.249.249 0 01.247.285l-.8 5.573a1 1 0 001.981.284l.914-6.389a1.564 1.564 0 00-.324-1.192z\"/><path d=\"M20.629 16.519a1 1 0 01.062-1l.309-.464a1 1 0 000-1.11l-.556-.834a.25.25 0 00-.209-.111H12.5a.25.25 0 01-.246-.206l-.634-3.539a.249.249 0 00-.384-.164l-.818.545a2.988 2.988 0 00-.891.924l-3.3 5.356a2 2 0 01-1 .825l-3.2 1.2a.25.25 0 00-.162.231l-.075 5.576a.249.249 0 00.25.253h6.781a.248.248 0 00.093-.018l1.919-.767A2.992 2.992 0 0111.947 23h7.319a.247.247 0 00.176-.073l.99-.99a1 1 0 00.242-1.023l-.174-.521a1 1 0 01.054-.764l.592-1.182a1 1 0 000-.894z\"/><circle cx=\"17.119\" cy=\"8.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});