define("ember-svg-jar/inlined/saving-piggy-coins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>saving-piggy-coins</title><path d=\"M22.368 8.5h-.456a.248.248 0 01-.243-.192 8.336 8.336 0 00-3.56-4.939.25.25 0 01-.1-.283 3 3 0 01.8-1.261A1 1 0 0018.373.13a3.994 3.994 0 00-4.2 1.466.249.249 0 01-.244.095 11.17 11.17 0 00-8.911 2.115 8.159 8.159 0 00-2.945 4.518.249.249 0 00.272.3A24.059 24.059 0 015.132 8.5c1.778 0 6.5 0 6.5 2.5v7.244a.25.25 0 00.249.25 11.058 11.058 0 003.248-.476.25.25 0 01.191.02.253.253 0 01.12.15l.487 1.684a1.5 1.5 0 001.44 1.083 1.477 1.477 0 00.418-.06 1.5 1.5 0 001.023-1.857L18.16 16.8a.25.25 0 01.095-.274 8.82 8.82 0 002.653-2.9.251.251 0 01.219-.129h1.241a1.5 1.5 0 001.5-1.5V10a1.5 1.5 0 00-1.5-1.5zm-5.5 0a1 1 0 111 1 1 1 0 01-1-1zm-9.05-1.9a.751.751 0 01-1.05-.149.75.75 0 01.149-1.051 8.212 8.212 0 015.5-1.631.75.75 0 01.7.8.766.766 0 01-.8.7A6.767 6.767 0 007.818 6.6z\"/><path d=\"M10 15.694a.251.251 0 00.133-.221V13.1a.25.25 0 00-.333-.236 15.637 15.637 0 01-4.667.634 15.635 15.635 0 01-4.666-.634.251.251 0 00-.334.236v2.374a.253.253 0 00.134.222 11.816 11.816 0 004.866.8A11.725 11.725 0 0010 15.694zM5.132 21.5a15.635 15.635 0 01-4.666-.634.251.251 0 00-.334.236V23c0 .554 2.239 1 5 1s5-.446 5-1v-1.9a.25.25 0 00-.333-.236 15.637 15.637 0 01-4.667.636zM.132 11v.476a.253.253 0 00.134.222 11.816 11.816 0 004.866.8A11.725 11.725 0 0010 11.694a.251.251 0 00.133-.221V11c0-.551-2.238-1-5-1s-5.001.449-5.001 1zM10.132 17.1a.25.25 0 00-.333-.236 15.637 15.637 0 01-4.667.634 15.635 15.635 0 01-4.666-.634.251.251 0 00-.334.236v2.374a.253.253 0 00.134.222 11.816 11.816 0 004.866.8A11.725 11.725 0 0010 19.694a.251.251 0 00.133-.221z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});