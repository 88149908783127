define("ember-svg-jar/inlined/road-sign-left-curve-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-curve-1-alternate</title><path d=\"M12.073 11.366a.249.249 0 01-.073-.177V9.75a.25.25 0 01.25-.25H13a.5.5 0 00.354-.854L11.207 6.5a1 1 0 00-1.414 0L7.646 8.646A.5.5 0 008 9.5h.75a.25.25 0 01.25.25v2.543a.991.991 0 00.293.707l2.634 2.634a.249.249 0 01.073.177V17.5a1 1 0 001 1h1a1 1 0 001-1v-2.793a.991.991 0 00-.293-.707z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});