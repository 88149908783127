define("ember-svg-jar/inlined/common-file-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-stack</title><path d=\"M18.167 18.5H8a.5.5 0 01-.5-.5V4.333a1 1 0 00-2 0v14.334A1.833 1.833 0 007.333 20.5h10.834a1 1 0 000-2z\"/><path d=\"M14.667 22H4.5a.5.5 0 01-.5-.5V7.833a1 1 0 00-2 0v14.334A1.833 1.833 0 003.833 24h10.834a1 1 0 100-2z\"/><path d=\"M21.482 3.569L18.618.537A1.819 1.819 0 0017.321 0H11a2 2 0 00-2 2v13a2 2 0 002 2h9.167A1.835 1.835 0 0022 15.167V4.845a1.8 1.8 0 00-.518-1.276z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});