define("ember-svg-jar/inlined/feed-sina-weibo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>feed-sina-weibo</title><path d=\"M17 1.35a7.22 7.22 0 00-2.26.27.75.75 0 00.41 1.44A6 6 0 0117 2.85a5.76 5.76 0 015.53 6 5.85 5.85 0 01-.11.92.75.75 0 00.59.88h.15a.75.75 0 00.73-.6A7.36 7.36 0 0024 8.87a7.26 7.26 0 00-7-7.52z\"/><path d=\"M19.73 9.95a.75.75 0 001-.44 3.75 3.75 0 00-4.65-4.89.75.75 0 00.43 1.38 2.17 2.17 0 01.77-.1 2.25 2.25 0 012 3 .75.75 0 00.45 1.05zM19.41 13a5 5 0 00-1.33-.66 4.32 4.32 0 00.3-1.05 2.16 2.16 0 00-.46-1.87 3.25 3.25 0 00-2.64-.83 8.74 8.74 0 00-2.08.39l-.41.14-.18.07c.37-1.4.12-2.51-.71-3.07A3.64 3.64 0 009 5.85c-3.67 1.1-10 6.24-8.88 11a5.38 5.38 0 001.34 2.46 11.41 11.41 0 006.27 3.09 16.25 16.25 0 002.82.25 15.3 15.3 0 002.19-.16 11.33 11.33 0 007.17-3.67c1.22-1.46 1.79-3.75.14-5.32a5.11 5.11 0 00-.64-.5zm-3.75 4.7c-.93 1.79-3 2.88-5.65 3h-.4c-2.59 0-4.94-1-5.82-2.38a3.07 3.07 0 01-.26-2.8 5.62 5.62 0 013.82-3 8.51 8.51 0 017 .86 3.38 3.38 0 011.31 4.32z\"/><path d=\"M8.36 15.36a2 2 0 00-1.82 1.4 1.46 1.46 0 00.26 1.3 1.61 1.61 0 001.25.58h.07A1.91 1.91 0 0010 17.23 1.45 1.45 0 009.7 16a1.55 1.55 0 00-1.34-.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});