define("ember-svg-jar/inlined/phone-action-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-code</title><path d=\"M11.215 8.732A1 1 0 009.8 8.718l-2.042 2a1 1 0 000 1.428l2.042 2a1.022 1.022 0 001.415-.014 1 1 0 00-.015-1.414l-1.313-1.286 1.313-1.286a1 1 0 00.015-1.414zM14.2 8.718a1 1 0 10-1.4 1.428l1.313 1.286-1.313 1.286a1 1 0 00-.015 1.414 1.018 1.018 0 001.415.014l2.042-2a1 1 0 000-1.428z\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});