define("ember-svg-jar/inlined/social-media-google-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-google-plus</title><path d=\"M13.71 1.17h-6.1a6.66 6.66 0 00-2.17.37 5.74 5.74 0 00-4.12 5.08A5.37 5.37 0 006.23 12a2.87 2.87 0 00.09.92C1.88 13.25 0 16.29 0 18.27c0 2.73 3.21 4.56 7.23 4.56 4.2 0 7-2.46 7-5.38a5.22 5.22 0 00-2.55-4.76 4.85 4.85 0 01-1.19-1.17c0-.21.08-.34.8-.9a5 5 0 002.1-4A5.57 5.57 0 0012.74 4l.18-.1 1.36-1a1 1 0 00-.57-1.77zM5.93 4.39A1.11 1.11 0 016.82 4C7.91 4 9 5.36 9.21 6.91a2.81 2.81 0 01-.48 2.15 1.1 1.1 0 01-.87.4c-1.07 0-2.19-1.42-2.39-3a2.66 2.66 0 01.46-2.07zm1.68 11a4.18 4.18 0 011.1.16l.35.24c.9.61 1.44 1 1.57 1.48a1.78 1.78 0 010 .4c0 .54 0 2.19-3.46 2.19-1.71 0-3.05-1-3.05-2.28s1.66-2.16 3.49-2.16zM23.5 9.17h-2a.5.5 0 01-.5-.5v-2a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});