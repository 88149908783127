define("ember-svg-jar/inlined/technology-prosthetic-leg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>technology-prosthetic-leg</title><path d=\"M18.21 21.929a1.38 1.38 0 00-1.3-.929 5.67 5.67 0 01-2.114-.407 1.857 1.857 0 01-1.082-1.093.5.5 0 00-.5-.5H8.1a.5.5 0 00-.479.355l-.893 2.979A1.5 1.5 0 008.214 24h8a2.48 2.48 0 001.547-.536 1.38 1.38 0 00.449-1.535zM10.714 6.5a4.479 4.479 0 014.065 2.621.253.253 0 00.226.146.25.25 0 00.227-.143A4.939 4.939 0 0015.714 7V3a3 3 0 00-3-3h-1a2 2 0 00-2 2 1 1 0 01-1 1h-1.5a1.5 1.5 0 00-1.5 1.5V7A4.953 4.953 0 006.2 9.124a.25.25 0 00.453 0A4.481 4.481 0 0110.714 6.5z\"/><path d=\"M9.714 17.5h2a.5.5 0 00.5-.5v-1.5a.5.5 0 01.5-.5h.5a.5.5 0 00.5-.5V11a3 3 0 00-6 0v3.5a.5.5 0 00.5.5h.5a.5.5 0 01.5.5V17a.5.5 0 00.5.5zm1-7a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});