define("ember-svg-jar/inlined/people-woman-5.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-5</title><path d=\"M4.83 11.175A.248.248 0 004.577 11H4.5a3.475 3.475 0 01-2.863-1.516.25.25 0 00-.436.043A2.469 2.469 0 001 10.5a20.012 20.012 0 01-.974 4.842A.5.5 0 00.5 16a.419.419 0 00.05 0c.044 0 3.554-.395 4.915-3.109a.246.246 0 000-.234 7.453 7.453 0 01-.635-1.482zM23 10.5a2.469 2.469 0 00-.2-.973.25.25 0 00-.436-.043A3.475 3.475 0 0119.5 11h-.077a.248.248 0 00-.253.177 7.453 7.453 0 01-.63 1.479.246.246 0 000 .234c1.36 2.71 4.866 3.103 4.91 3.11a.419.419 0 00.05 0 .5.5 0 00.474-.658A20 20 0 0123 10.5zM4.5 9V7a7.356 7.356 0 01.2-1.676.249.249 0 00-.24-.306A2.491 2.491 0 004.5 10a.064.064 0 00.049-.022.065.065 0 00.016-.052A7.26 7.26 0 014.5 9zM19.5 7v2a7.26 7.26 0 01-.065.926.065.065 0 00.016.052.064.064 0 00.049.022 2.491 2.491 0 00.044-4.982.249.249 0 00-.241.306A7.356 7.356 0 0119.5 7z\"/><path d=\"M19 9V7A7 7 0 005 7v2a7 7 0 0014 0zm-7 5a5.006 5.006 0 01-5-5v-.951a.25.25 0 01.242-.25 6.83 6.83 0 004.593-1.6.25.25 0 01.331 0 6.837 6.837 0 004.591 1.6.251.251 0 01.243.25V9a5.006 5.006 0 01-5 5z\"/><circle cx=\"14\" cy=\"9.25\" r=\"1\"/><circle cx=\"10\" cy=\"9.25\" r=\"1\"/><path d=\"M10.457 11.844a.638.638 0 00.317.719 2.488 2.488 0 00.436.181 2.712 2.712 0 00.872.125h.01a2.916 2.916 0 00.856-.126 2.408 2.408 0 00.431-.179.639.639 0 00-.3-1.2h-2a.64.64 0 00-.622.48zM22.534 18.491a14.244 14.244 0 00-5.908-2.481.5.5 0 00-.552.279A4.558 4.558 0 0112 19.074a4.558 4.558 0 01-4.073-2.785.5.5 0 00-.552-.279 14.256 14.256 0 00-5.909 2.481A2.467 2.467 0 00.5 20.468V23.5a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-3.032a2.467 2.467 0 00-.966-1.977z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});