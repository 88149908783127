define("ember-svg-jar/inlined/multiple-actions-flash.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flash</title><path d=\"M18.484 9.78a.25.25 0 00-.018-.442c-.64-.307-1.435-.6-2.352-.94l-.485-.172a1.791 1.791 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 0013.643 0 2.994 2.994 0 0012.3.314a.25.25 0 00-.1.363 5 5 0 01.834 2.808 6.266 6.266 0 01-.922 3.624.25.25 0 00.121.372c1.918.716 3.3 1.328 3.905 2.537a6.4 6.4 0 01.455 1.246.25.25 0 00.435.1l.917-1.124a1.974 1.974 0 01.539-.46z\"/><path d=\"M15.025 10.577c-.454-.907-1.9-1.44-3.911-2.179l-.485-.172a1.791 1.791 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.22 3.22 0 008.643 0 3.219 3.219 0 005.5 3.485a4.609 4.609 0 001.19 3.538 1.877 1.877 0 010 1.185l-.515.19c-2.006.739-3.457 1.272-3.911 2.179a7.542 7.542 0 00-.621 2.923.5.5 0 00.5.5H14.76a.249.249 0 00.193-.092l.609-.746a.249.249 0 00.056-.178 6.873 6.873 0 00-.593-2.407zM22.159 17.355A.821.821 0 0021.536 16H20.25a.25.25 0 01-.25-.25V11.5a.5.5 0 00-.888-.316l-5.274 6.462A.822.822 0 0014.464 19h1.286a.25.25 0 01.25.25v4.25a.5.5 0 00.332.471.507.507 0 00.168.029.5.5 0 00.387-.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});