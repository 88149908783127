define("ember-svg-jar/inlined/shop-sign-open-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-sign-open-1</title><path d=\"M9.875 13.75A.877.877 0 009 12.875h-.625a.25.25 0 00-.25.25v1.25a.25.25 0 00.25.25H9a.877.877 0 00.875-.875zM4 17.625a.877.877 0 00.875-.875v-3a.875.875 0 00-1.75 0v3a.877.877 0 00.875.875z\"/><path d=\"M21.5 7.75h-3.99a.249.249 0 01-.216-.124l-2.927-5.017a2.74 2.74 0 00-4.734 0L6.706 7.626a.249.249 0 01-.216.124H2.5a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h19a2.5 2.5 0 002.5-2.5v-10a2.5 2.5 0 00-2.5-2.5zm-7 6.875a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25v1.125a.375.375 0 00.375.375h2a.625.625 0 010 1.25h-2a1.627 1.627 0 01-1.625-1.625v-4a1.627 1.627 0 011.625-1.625h2a.625.625 0 010 1.25h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25zM1.875 13.75a2.125 2.125 0 014.25 0v3a2.125 2.125 0 01-4.25 0zm5-1.5a.625.625 0 01.625-.625H9a2.125 2.125 0 010 4.25h-.625a.25.25 0 00-.25.25v2.125a.625.625 0 01-1.25 0zm10 0a.625.625 0 011.184-.279l1.341 2.685a.25.25 0 00.474-.112V12.25a.625.625 0 011.25 0v6a.624.624 0 01-.481.608.59.59 0 01-.144.017.625.625 0 01-.559-.346l-1.34-2.685a.25.25 0 00-.474.112v2.294a.625.625 0 01-1.25 0zm-5.516-8.632a.774.774 0 011.282 0l2.191 3.756a.25.25 0 01-.216.376H9.384a.25.25 0 01-.216-.376z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});