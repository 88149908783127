define("ember-svg-jar/inlined/dentistry-tooth-mirror", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-mirror</title><path d=\"M10.594 12.237a1 1 0 000 1.414l2.121 2.122a1 1 0 001.414 0l1.492-1.492a.5.5 0 000-.707l-2.829-2.828a.5.5 0 00-.707 0zM23.145 3.221a2.56 2.56 0 00-3.536 0l-5.756 5.757a.5.5 0 000 .707l2.828 2.829a.5.5 0 00.707 0l5.757-5.757a2.5 2.5 0 000-3.536zM10.759 15.374a.743.743 0 00-.53-.22.753.753 0 00-.53.22l-1.75 1.75a.251.251 0 01-.325.024 5.554 5.554 0 00-3.25-.91c-2.423 0-4.25 1.129-4.25 2.625s1.827 2.625 4.25 2.625c2.117 0 3.772-.863 4.158-2.078a.25.25 0 01.061-.1l2.52-2.521a.75.75 0 000-1.061zm-6.385 4.114a4.467 4.467 0 01-1.921-.388.249.249 0 01-.014-.444 4.04 4.04 0 011.935-.419 4.461 4.461 0 011.922.387.25.25 0 01.142.218.247.247 0 01-.129.226 4.037 4.037 0 01-1.935.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});