define("ember-svg-jar/inlined/mouse-smart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-smart</title><path d=\"M13.5.5h-3a6.018 6.018 0 00-6 6v11a6.018 6.018 0 006 6h3a6.018 6.018 0 006-6v-11a6.017 6.017 0 00-6-6zm0 7.5a1.5 1.5 0 01-3 0V5.5a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});