define("ember-svg-jar/inlined/phone-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-circle</title><path d=\"M12 .008a11.992 11.992 0 000 23.984c4.476 0 6.137-1.743 6.742-3.206a4.016 4.016 0 00-.18-3.6 1.911 1.911 0 00-.017-2.576l-1.431-1.43a1.932 1.932 0 00-2.722.1l-.211.21A28.16 28.16 0 0110.5 9.815l.211-.21a1.933 1.933 0 00.106-2.723L9.387 5.45a1.93 1.93 0 00-2.722.107l-.873.872a2.715 2.715 0 00-.48 3.324 28.771 28.771 0 008.931 8.931 2.445 2.445 0 001.312.371 2.815 2.815 0 001.472-.434 2.443 2.443 0 01-.279 1.691c-.659 1.1-2.3 1.68-4.748 1.68a9.99 9.99 0 118.245-4.346 1 1 0 101.649 1.133A11.994 11.994 0 0012 .008z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});