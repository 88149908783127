define("ember-svg-jar/inlined/tools-wheel-unscrew", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-wheel-unscrew</title><path d=\"M9.732 21.983a.248.248 0 00-.117-.163.767.767 0 01-.145-.12l-2.43-2.44a3.1 3.1 0 00-3.7-.424.249.249 0 01-.3-.037l-.558-.559a1.019 1.019 0 00-.83-.28 1 1 0 00-.73.481A3 3 0 003.5 22.973a3.051 3.051 0 001.53-.419 1.028 1.028 0 00.48-.741.985.985 0 00-.29-.829.25.25 0 01.336-.368.86.86 0 01.064.058l2.44 2.44a2.976 2.976 0 002.12.879c.1 0 .192 0 .288-.013a.25.25 0 00.146-.433 2.957 2.957 0 01-.882-1.564zM23.207 1.709a.994.994 0 000-1.41 1.007 1.007 0 00-1.419 0L20.2 1.858a.058.058 0 01-.076.006.977.977 0 00-1.41 0l-2.73 2.73A1 1 0 0015.98 6a.058.058 0 01-.006.076l-.3.305a3.006 3.006 0 000 4.25l2.44 2.44a.841.841 0 01.122.151.257.257 0 00.158.111 3 3 0 011.48.8c.03.027.058.056.086.085a.25.25 0 00.43-.149c.009-.1.014-.2.014-.3a2.969 2.969 0 00-.88-2.12L17.08 9.213a1 1 0 010-1.409l.207-.207a.248.248 0 01.309-.035.946.946 0 00.5.151.988.988 0 00.71-.3l2.73-2.73a.983.983 0 00.29-.7 1 1 0 00-.147-.513.25.25 0 01.039-.307zM16.338 14.836l-5.1 5.1a2 2 0 002.829 2.828l5.1-5.1a2 2 0 000-2.829 2.047 2.047 0 00-2.829.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});