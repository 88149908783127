define("ember-svg-jar/inlined/real-estate-action-house-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-information</title><path d=\"M9.5 17.5a7.987 7.987 0 0113.137-6.117.5.5 0 00.321.117h.542a.5.5 0 00.344-.863L13.029.408a1.5 1.5 0 00-2.059 0L.157 10.636A.5.5 0 00.5 11.5h2a.5.5 0 01.5.5v10a1 1 0 001 1h6.638a.5.5 0 00.4-.795A7.939 7.939 0 019.5 17.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-.75 2.249a1 1 0 11-1 1 1 1 0 011-1.001zm2.25 8.5h-3a.75.75 0 010-1.5h.25a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.5-.5H16a.75.75 0 010-1.5h1a1.252 1.252 0 011.25 1.25v2.25a.5.5 0 00.5.5H19a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});