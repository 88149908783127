define("ember-svg-jar/inlined/e-commerce-basket-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-basket-monitor</title><path d=\"M24 3.125a2 2 0 00-2-2H2a2 2 0 00-2 2v14a2 2 0 002 2h7.534a.25.25 0 01.239.323 8.425 8.425 0 01-.83 1.8.251.251 0 01-.216.123H7a.75.75 0 100 1.5h10a.75.75 0 000-1.5h-1.74a.249.249 0 01-.215-.123 8.4 8.4 0 01-.826-1.8.25.25 0 01.24-.323h7.513a2.007 2.007 0 002-1.972c.039-2.86.028.361.028-14.028zm-21 0h18a1 1 0 011 1v9a1 1 0 01-1 1H3a1 1 0 01-1-1v-9a1 1 0 011-1z\"/><path d=\"M16.5 7.625h-.758a.249.249 0 01-.223-.138L14.4 5.271a.749.749 0 00-1.339.673l.663 1.319a.25.25 0 01-.223.362H10.5a.25.25 0 01-.213-.119.248.248 0 01-.011-.243l.664-1.319a.75.75 0 00-1.34-.673L8.481 7.487a.25.25 0 01-.224.138H7.5a.5.5 0 00-.485.62l.859 3.484a1.161 1.161 0 001.2.895h5.85a1.161 1.161 0 001.2-.9l.86-3.482a.5.5 0 00-.486-.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});