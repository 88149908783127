define("ember-svg-jar/inlined/baby-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baby-1</title><circle cx=\"8.25\" cy=\"12\" r=\"1.25\"/><circle cx=\"15.75\" cy=\"12\" r=\"1.25\"/><path d=\"M21.5 10.472v-.137A9.725 9.725 0 0012.651.522 9.51 9.51 0 002.5 10v.308a.524.524 0 01-.266.429 3.5 3.5 0 00.789 6.73.235.235 0 01.188.144 9.5 9.5 0 0017.578 0 .235.235 0 01.188-.144 3.5 3.5 0 00.689-6.767.244.244 0 01-.166-.228zm-1 5.028h-.375a1 1 0 00-.983.814 5.169 5.169 0 01-1.812 3.3c-5.121 3.828-11.018 1.414-12.5-3.4a1 1 0 00-.956-.706H3.5a1.5 1.5 0 11.021-3 .987.987 0 00.979-1V10a7.5 7.5 0 017.336-7.492.142.142 0 00.021.01 1.978 1.978 0 011.258.871 1.688 1.688 0 01-.335 2.387 1.273 1.273 0 01-1.688-.306 1 1 0 00-1.8.873A3.373 3.373 0 0012.029 8a3.481 3.481 0 003.316-2.685 3.89 3.89 0 00.014-1.557.251.251 0 01.368-.256A7.494 7.494 0 0119.5 10v1.5a.987.987 0 00.979 1 1.5 1.5 0 11.021 3z\"/><path d=\"M13.841 15.843a3.016 3.016 0 01-3.682 0 1 1 0 00-1.318 1.5 4.9 4.9 0 006.318 0 1 1 0 10-1.318-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});