define("ember-svg-jar/inlined/christmas-mistletoe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>christmas-mistletoe</title><path d=\"M6.156 13.015l-1.189-1.309A.75.75 0 116.076 10.7l1.584 1.741a.5.5 0 00.478.152 4.036 4.036 0 011.1-.086.25.25 0 00.265-.236 3.98 3.98 0 01.815-2.2.5.5 0 00.054-.52 1.489 1.489 0 00-1.2-.838L7.055 8.5a.5.5 0 01-.375-.234l-.961-1.554A1.508 1.508 0 004.442 6H1.5A1.5 1.5 0 000 7.5v2.294a1.508 1.508 0 00.727 1.286l1.717 1.033a.5.5 0 01.243.426L2.7 14.6a1.493 1.493 0 001.063 1.43l.655.2a.5.5 0 00.638-.4 3.985 3.985 0 011.087-2.13.5.5 0 00.013-.685zM22.313 2.5h-3.491a1.676 1.676 0 00-1.434.8l-1.141 1.844a.686.686 0 01-.515.322l-2.515.254a1.679 1.679 0 00-1.486 1.347l-.309 1.549a.25.25 0 00.353.274 4.008 4.008 0 013.773.173.5.5 0 00.626-.093l1.416-1.559A.75.75 0 1118.7 8.42l-1.554 1.71a.5.5 0 00-.076.563 4 4 0 01-.146 3.878.25.25 0 00.286.369l2.393-.721a1.678 1.678 0 001.2-1.607l.012-2.447a.687.687 0 01.333-.585l2.037-1.226A1.7 1.7 0 0024 6.909V4.187A1.689 1.689 0 0022.313 2.5z\"/><path d=\"M11.835 15.518a3 3 0 10-1.294 3.556 4 4 0 011.294-3.556zM12.683 14.936A3.977 3.977 0 0114.5 14.5a4.048 4.048 0 011.091.15A3 3 0 1010.5 12.5a2.924 2.924 0 00.015.3 4.016 4.016 0 012.168 2.136z\"/><circle cx=\"14.5\" cy=\"18.5\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});