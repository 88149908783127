define("ember-svg-jar/inlined/smartphone-pay-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smartphone-pay-dollar</title><path d=\"M21.937 5.541H7.031a1.989 1.989 0 00-1.987 1.987v7.95a1.99 1.99 0 001.987 1.988h14.906a1.99 1.99 0 001.988-1.988v-7.95a1.99 1.99 0 00-1.988-1.987zm0 9.687a.25.25 0 01-.25.25H7.281a.25.25 0 01-.25-.25v-7.45a.25.25 0 01.25-.25h14.406a.25.25 0 01.25.25z\"/><path d=\"M12.745 18.708H2.313a.25.25 0 01-.25-.25V4.549a.249.249 0 01.25-.25h10.432a.746.746 0 00.746-.746v-.5A2.735 2.735 0 0010.758.324h-7.95A2.735 2.735 0 00.075 3.056v17.888a2.735 2.735 0 002.733 2.732h7.95a2.735 2.735 0 002.733-2.732v-1.491a.746.746 0 00-.746-.745zm-4.968 2.236a.994.994 0 11-.994-.994.993.993 0 01.994.994z\"/><circle cx=\"14.484\" cy=\"11.503\" r=\"2.236\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});