define("ember-svg-jar/inlined/safety-danger-cliff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-danger-cliff</title><path d=\"M15.751 3.211A2.5 2.5 0 0018.238 5.5c.071 0 .143 0 .214-.009a2.5 2.5 0 001.4-4.4 2.5 2.5 0 00-3.523.3 2.479 2.479 0 00-.578 1.82zM6.8 12.891a1.36 1.36 0 101.22 2.431l2.369-1.186a2.362 2.362 0 01.873-.246.156.156 0 01.132.256l-2.21 2.617a1.36 1.36 0 102.077 1.755l4.178-4.945a5.087 5.087 0 00.845-1.408l.832-2.082a.25.25 0 01.332-.137c.3.13.595.269.877.426a2.4 2.4 0 011.112 1.328l.365 1.07a1.36 1.36 0 002.574-.88l-.365-1.069A5.111 5.111 0 0019.64 7.99a14.364 14.364 0 00-1.606-.759L16.5 6.616a9.494 9.494 0 01-3.7-3.5 1.384 1.384 0 00-1.235-.771 1.365 1.365 0 00-1.087 2.187 12.575 12.575 0 004.068 4.115.251.251 0 01.1.3L13.9 10.8a.252.252 0 01-.212.157l-2.655.223a5.091 5.091 0 00-1.863.52zM1.551 18.042a1 1 0 001 1H5.3a.25.25 0 01.25.25V22.5a1 1 0 102 0v-3.958a1.5 1.5 0 00-1.5-1.5h-3.5a1 1 0 00-.999 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});