define("ember-svg-jar/inlined/pill-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pill-laptop</title><path d=\"M11.223 15.511a4.331 4.331 0 01-1.947-.477c-.29-.157-.776-.494-.775-.981a.5.5 0 00-.5-.489H0a.5.5 0 00-.395.193.5.5 0 00-.09.43 5.607 5.607 0 005.438 4.377h4.453a.243.243 0 00.229-.159 17.114 17.114 0 011.719-2.663c.105-.142-.036-.228-.131-.231zM3 12.064a1 1 0 001-1V2.556c0-.309.08-.489.5-.492h14.008c.323.012.491.081.493.5v6.263a.247.247 0 00.306.238 5.516 5.516 0 011.432-.125A.246.246 0 0021 8.7V2.556A2.389 2.389 0 0018.523.064H4.492A2.391 2.391 0 002 2.564v8.5a1 1 0 001 1zM23.329 11.578a4.008 4.008 0 00-5.657 0l-2.527 2.528-3.13 3.129a4.008 4.008 0 000 5.657 4.354 4.354 0 005.657 0l3.13-3.129 2.527-2.527a4.006 4.006 0 000-5.658zm-1.5 4.334l-2.259 2.259a.25.25 0 01-.354 0l-2.48-2.471a.249.249 0 010-.353l2.35-2.351a2.163 2.163 0 012.936.117 2.088 2.088 0 01-.198 2.799z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});