define("ember-svg-jar/inlined/fishing-fail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-fail</title><path d=\"M15.445.592a1 1 0 00-1.414 0L11.2 3.421a.25.25 0 01-.354 0L8.02.592a1 1 0 00-1.414 1.414l2.829 2.829a.249.249 0 010 .353L6.606 8.017A1 1 0 108.02 9.431L10.849 6.6a.25.25 0 01.354 0l2.828 2.828a1 1 0 001.414-1.414l-2.828-2.826a.249.249 0 010-.353l2.828-2.829a1 1 0 000-1.414zM23.434 16.75a.525.525 0 00-.458-.775.531.531 0 00-.259.07l-2.869 1.625a.249.249 0 01-.273-.017c-.249-.186-.53-.383-.827-.583a.251.251 0 01-.108-.243l.241-1.685a.524.524 0 00-.521-.6.514.514 0 00-.337.128l-1.239 1.062a.248.248 0 01-.273.034 15.54 15.54 0 00-2.056-.837.249.249 0 01-.17-.2l-.257-1.8a.521.521 0 00-.522-.449.511.511 0 00-.414.21l-1.173 1.56a.253.253 0 01-.225.1c-.324-.032-.649-.059-.982-.059s-.673.02-1 .053a.251.251 0 01-.184-.057.247.247 0 01-.089-.171l-.174-2.067a.521.521 0 00-.525-.48.512.512 0 00-.421.22L5.8 15.314a.237.237 0 01-.1.084A19.441 19.441 0 00.679 18.6a.518.518 0 00-.005.779c.278.246.688.592 1.207.981a.256.256 0 01.093.141l.7 2.906a.261.261 0 00.237.2h.018a.261.261 0 00.243-.167l.582-1.5a.25.25 0 01.355-.128 13.754 13.754 0 006.6 1.892 15.406 15.406 0 008.863-3.363.249.249 0 01.273-.018l2.869 1.625a.52.52 0 00.259.071.525.525 0 00.458-.775l-.678-1.247a2.093 2.093 0 010-2zM5 19.8a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});