define("ember-svg-jar/inlined/email-action-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>email-action-settings</title><path d=\"M14.02 11.208l.455.106.139-.458a3.275 3.275 0 016.268 0 .061.061 0 00.066.042.06.06 0 00.05-.059V1.781a.158.158 0 00-.1-.147.16.16 0 00-.173.034L12.2 10.2a2.407 2.407 0 01-3.4 0L.271 1.668A.159.159 0 00.1 1.634a.16.16 0 00-.1.147v10.5a1.749 1.749 0 001.75 1.75h8.067a.25.25 0 00.246-.206 3.258 3.258 0 013.957-2.617z\"/><path d=\"M9.725 9.268a1.1 1.1 0 001.547 0L19.746.792A.437.437 0 0019.5.05a1.751 1.751 0 00-.253-.019H1.749A1.751 1.751 0 001.5.05a.437.437 0 00-.352.3.441.441 0 00.102.442z\"/><circle cx=\"17.748\" cy=\"17\" r=\"1.5\"/><path d=\"M23.516 15.623a1.783 1.783 0 00-1.7-2.954L20.405 13a.453.453 0 01-.533-.311l-.424-1.393a1.775 1.775 0 00-3.4 0l-.424 1.393a.456.456 0 01-.533.311l-1.411-.328a1.784 1.784 0 00-1.7 2.954l.988 1.064a.464.464 0 010 .626l-.988 1.064a1.784 1.784 0 001.7 2.954L15.092 21a.453.453 0 01.533.311l.424 1.393a1.775 1.775 0 003.4 0l.424-1.393a.45.45 0 01.532-.311l1.411.328a1.783 1.783 0 001.7-2.954l-.987-1.064a.464.464 0 010-.626zM17.748 20a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});