define("ember-svg-jar/inlined/diagram-curve-rise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-curve-rise</title><path d=\"M1.5 13.5A1.5 1.5 0 003 12V9a2.5 2.5 0 012.5-2.5h1A2.5 2.5 0 019 9v6a5.506 5.506 0 005.5 5.5h1A5.506 5.506 0 0021 15v-1.25a.25.25 0 01.25-.25h1.25a1.5 1.5 0 001.06-2.561l-3-3a1.5 1.5 0 00-2.124 0l-3 3A1.5 1.5 0 0016.5 13.5h1.25a.25.25 0 01.25.25V15a2.5 2.5 0 01-2.5 2.5h-1A2.5 2.5 0 0112 15V9a5.506 5.506 0 00-5.5-5.5h-1A5.506 5.506 0 000 9v3a1.5 1.5 0 001.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});