define("ember-svg-jar/inlined/video-game-controller-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-controller-person</title><path d=\"M7.542 11.406a.976.976 0 01-1.084 0 .75.75 0 10-.916 1.188 2.418 2.418 0 002.916 0 .75.75 0 10-.916-1.188z\"/><path d=\"M14 9V7A7 7 0 000 7v2a7 7 0 0014 0zm-7 5a5.006 5.006 0 01-5-5V7.5a.5.5 0 01.5-.5H3c1.248 0 3.739.3 5.646-1.612a.5.5 0 01.708 0 5.255 5.255 0 002.284 1.346.5.5 0 01.36.479C12 7.838 12 9 12 9a5.006 5.006 0 01-5 5zM23.883 17.255a3.519 3.519 0 00-4.3-2.508l-8.851 2.328a3.52 3.52 0 001.791 6.808 3.4 3.4 0 002.054-1.524.5.5 0 01.3-.223l3.587-.944a.5.5 0 01.371.047 3.4 3.4 0 002.539.316 3.521 3.521 0 002.509-4.3zm-11.225 3.471l-.449.118a.249.249 0 00-.178.3l.118.45a.5.5 0 01-.356.61.469.469 0 01-.128.017.5.5 0 01-.483-.373l-.118-.449a.251.251 0 00-.116-.153.245.245 0 00-.189-.025l-.449.118a.516.516 0 01-.128.016.5.5 0 01-.127-.983l.449-.118a.249.249 0 00.178-.306l-.118-.45a.5.5 0 01.967-.254l.118.45a.25.25 0 00.305.178l.449-.118a.5.5 0 01.255.967zm6.523-1.458a.75.75 0 11.534-.916.75.75 0 01-.534.916zm.238-2.512a.75.75 0 11.916.534.749.749 0 01-.916-.534zm1.74 3.666a.75.75 0 11.534-.916.751.751 0 01-.534.916zm1.154-1.978a.75.75 0 11.534-.916.751.751 0 01-.534.916z\"/><path d=\"M6.108 9.649a.751.751 0 00.274-1.025 1.751 1.751 0 00-3.031 0 .75.75 0 001.3.752.258.258 0 01.434 0 .75.75 0 00.65.374.742.742 0 00.373-.101zM9.134 7.75a1.757 1.757 0 00-1.516.874.75.75 0 001.3.752.258.258 0 01.434 0A.749.749 0 0010 9.75a.739.739 0 00.375-.1.75.75 0 00.274-1.025 1.757 1.757 0 00-1.515-.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});