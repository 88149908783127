define("ember-svg-jar/inlined/browser-com", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>browser-com</title><circle cx=\"4.55\" cy=\"15.5\" r=\"1\"/><path d=\"M9.55 16.75a.75.75 0 000-1.5A1.25 1.25 0 018.3 14v-1a1.251 1.251 0 011.25-1.25.75.75 0 000-1.5A2.753 2.753 0 006.8 13v1a2.752 2.752 0 002.75 2.75zM13.05 16.75A1.752 1.752 0 0014.8 15v-3a1.75 1.75 0 00-3.5 0v3a1.752 1.752 0 001.75 1.75zM12.8 12a.25.25 0 01.5 0v3c0 .275-.5.275-.5 0zM16.55 16.75a.75.75 0 00.75-.75v-2.085a.058.058 0 01.042-.055.057.057 0 01.064.026.781.781 0 001.287 0 .058.058 0 01.065-.026.058.058 0 01.042.055V16a.75.75 0 001.5 0v-5a.751.751 0 00-1.394-.386l-.642 1.071a.249.249 0 01-.214.121.252.252 0 01-.215-.121l-.642-1.071A.75.75 0 0015.8 11v5a.75.75 0 00.75.75z\"/><path d=\"M24 4.75a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0A.969.969 0 016 4.25a.969.969 0 01.154-.5zm-3.562.092A1 1 0 013.5 3.25a.985.985 0 01.846.5.969.969 0 01.154.5.969.969 0 01-.154.5.966.966 0 01-1.692 0 .969.969 0 01-.154-.5.979.979 0 01.092-.408zM22 19.25a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});