define("ember-svg-jar/inlined/gesture-double-tap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-double-tap</title><path d=\"M12 4.866a6.6 6.6 0 00-5.59 10.087.75.75 0 101.271-.795A5.09 5.09 0 1116.45 9a5.023 5.023 0 01-.139 5.172.75.75 0 101.269.8A6.6 6.6 0 0012 4.866z\"/><path d=\"M21.9 7.323A10.692 10.692 0 0012 .866a10.685 10.685 0 00-9.9 6.47 10.715 10.715 0 001.913 11.706.751.751 0 001.106-1.015A9.231 9.231 0 013.47 7.939 9.2 9.2 0 0112 2.366a9.21 9.21 0 018.527 5.562A9.23 9.23 0 0118.906 18a.749.749 0 101.107 1.01A10.706 10.706 0 0021.9 7.323z\"/><path d=\"M16.416 17.036l-3.742-1.116a.25.25 0 01-.178-.24v-4.568a1 1 0 00-2 0v7.064a.25.25 0 01-.455.143c-1.49-2.139-3.856-1.45-2.762.223l2.912 4.367a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.411c.2-1.1.664-3.683.775-4.165a1.282 1.282 0 00-.893-1.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});