define("ember-svg-jar/inlined/human-resources-offer-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>human-resources-offer-man-1</title><path d=\"M1 14.75a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-1zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.394A2.233 2.233 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.239 2.239 0 002.236-2.237V20.5a1 1 0 00-1-1zM20.4 9.166a.25.25 0 01-.013-.458 4.576 4.576 0 10-4.062-.072.25.25 0 01-.03.457A5.76 5.76 0 0012.5 14.5a.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5 5.759 5.759 0 00-3.6-5.334zm-2.9 1.963a.75.75 0 111.5 0v1.222a.75.75 0 01-1.5 0zm-1.65-6.554a2.57 2.57 0 01.142-.845.251.251 0 01.374-.126 5.9 5.9 0 003.251.971 5.971 5.971 0 001.226-.128.13.13 0 01.109.027.127.127 0 01.048.1 2.575 2.575 0 01-5.15 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});