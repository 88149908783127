define("ember-svg-jar/inlined/modern-weapon-cannon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-weapon-cannon</title><path d=\"M23.665 4.3L21.442.972a.5.5 0 00-.777-.067l-3.184 3.338a.5.5 0 00-.056.62l1.1 1.675a.5.5 0 00.417.225.511.511 0 00.183-.034l4.305-1.688a.5.5 0 00.232-.744zM16.167 5.8a.5.5 0 00-.689-.145l-3.732 2.418a.5.5 0 01-.687-.142l-.559-.843a.5.5 0 00-.318-.212.488.488 0 00-.382.074l-1.366.913a.5.5 0 01-.693-.138L6.662 6.108a1 1 0 00-.637-.425l-2.08-.413a1 1 0 10-.39 1.961l1.471.293a.5.5 0 01.318.212l.736 1.1a.5.5 0 01-.137.693l-1.961 1.312a3.5 3.5 0 001.953 6.41h.162a.5.5 0 00.452-.345 5.979 5.979 0 016.771-4.054.5.5 0 00.368-.076.5.5 0 00.138-.694l-.547-.818a.493.493 0 01-.074-.379.5.5 0 01.218-.319l3.7-2.39a.5.5 0 00.146-.694z\"/><path d=\"M12.25 14.25a4.505 4.505 0 00-4.5 4.5 1.185 1.185 0 00.006.119.5.5 0 01-.234.469l-2.937 1.836a.5.5 0 01-.265.076H1.25a1 1 0 000 2h3.5a1 1 0 00.53-.152l2.759-1.725a.5.5 0 01.661.119 4.494 4.494 0 103.55-7.242zm0 6a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});