define("ember-svg-jar/inlined/medical-nanobot-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-nanobot-pill</title><path d=\"M22.379 7.238a5.564 5.564 0 00-7.858 0l-1.7 1.7a.5.5 0 00-.116.182 2.312 2.312 0 01-.171.373l-2 3.5a2.5 2.5 0 01-2.171 1.26 2.335 2.335 0 01-.559-.073.326.326 0 00-.311.087 5.556 5.556 0 107.853 7.863l3.517-3.517 3.516-3.513a5.572 5.572 0 000-7.858zM22 11.172a3.524 3.524 0 01-1.035 2.51l-2.452 2.452a.5.5 0 01-.707 0l-2.162-2.161-2.161-2.162a.5.5 0 010-.707l2.452-2.452A3.551 3.551 0 0122 11.172zM11.125 7.219L9.542 5.952a.5.5 0 01-.187-.362.5.5 0 01.145-.382l.175-.175a1.106 1.106 0 000-1.566L6.533.325a1.108 1.108 0 00-1.567 0L1.824 3.467a1.108 1.108 0 000 1.566L2 5.209a.5.5 0 01-.041.743L.376 7.219A1 1 0 00.132 8.5l2 3.5a1 1 0 00.868.5.988.988 0 00.5-.132A1 1 0 003.868 11L2.505 8.617a.5.5 0 01.122-.638l.841-.673a.5.5 0 01.666.037l.47.47a.5.5 0 01.146.353V11.5a1 1 0 002 0V8.166a.5.5 0 01.15-.353l.469-.47a.5.5 0 01.666-.037l.842.673a.5.5 0 01.121.638L7.632 11A1 1 0 008 12.368a.988.988 0 00.5.132 1 1 0 00.869-.5l2-3.5a1 1 0 00-.244-1.281zM5.75 6A1.75 1.75 0 117.5 4.25 1.749 1.749 0 015.75 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});