define("ember-svg-jar/inlined/volume-control-warning-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volume-control-warning-1</title><path d=\"M14 1a1 1 0 00-1.061-1 9.722 9.722 0 00-7.932 4.869.22.22 0 01-.194.117H3a3 3 0 00-3 3v2a3 3 0 003 3h1.794a.249.249 0 01.221.133 9.574 9.574 0 006.423 4.643.252.252 0 00.274-.128l2.259-4.306a.243.243 0 00.029-.116zM18.781 12.271a1.448 1.448 0 00-2.56 0l-5.056 9.634a1.426 1.426 0 00.048 1.408 1.453 1.453 0 001.232.687h10.112a1.454 1.454 0 001.232-.686 1.428 1.428 0 00.048-1.409zM16.751 16a.75.75 0 011.5 0v3a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});