define("ember-svg-jar/inlined/dresser-drawers-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-6</title><path d=\"M22.25 0H1.75a1.5 1.5 0 000 3h20.5a1.5 1.5 0 000-3z\"/><rect x=\"1.25\" y=\"11\" width=\"10\" height=\"3\" rx=\".5\" ry=\".5\"/><rect x=\"12.75\" y=\"11\" width=\"10\" height=\"3\" rx=\".5\" ry=\".5\"/><path d=\"M22.25 15.5H1.75a.5.5 0 00-.5.5v7a1 1 0 002 0v-2a.5.5 0 01.5-.5h16.5a.5.5 0 01.5.5v2a1 1 0 002 0v-7a.5.5 0 00-.5-.5z\"/><rect x=\"1.25\" y=\"4.5\" width=\"21.5\" height=\"5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});