define("ember-svg-jar/inlined/file-rar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-rar-1</title><path d=\"M19.875 13.5a1.377 1.377 0 00-1.375-1.377h-1.126a.249.249 0 00-.249.249v2.249a.252.252 0 00.252.252H18.5a1.377 1.377 0 001.375-1.373z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM6.275 16.389l2.667 2.668a.625.625 0 01-.884.883l-2.8-2.8s-.134-.056-.134.091V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 01.625-.625h2a2.625 2.625 0 010 5.25h-.187c-.077-.002-.159.142-.037.264zm8.85 3.109a.625.625 0 11-1.25 0v-3.125a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-5.9a2.7 2.7 0 012.1-2.679 2.629 2.629 0 013.15 2.579zm3.15-3.109l2.667 2.668a.625.625 0 01-.884.883l-2.8-2.8s-.134-.056-.134.091V19.5a.625.625 0 11-1.25 0v-8a.625.625 0 01.625-.625h2a2.625 2.625 0 010 5.25h-.188c-.076-.002-.158.142-.036.264z\"/><path d=\"M7.875 13.5A1.377 1.377 0 006.5 12.123H5.374a.249.249 0 00-.249.249v2.249a.252.252 0 00.252.252H6.5A1.377 1.377 0 007.875 13.5zM12.5 12.123a1.377 1.377 0 00-1.375 1.377v1.124a.251.251 0 00.251.251h2.246a.253.253 0 00.253-.253V13.5a1.377 1.377 0 00-1.375-1.377z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});