define("ember-svg-jar/inlined/gesture-tap-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-clock</title><path d=\"M9.9 7.5a4.255 4.255 0 00-4.25 4.25v11.5a.75.75 0 00.75.75h7a.75.75 0 00.75-.75v-11.5A4.255 4.255 0 009.9 7.5zm-2 6V12a2 2 0 114 0v1.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5z\"/><path d=\"M11.989 3.756a.25.25 0 00-.191-.284 8.371 8.371 0 00-1.9-.223 8.556 8.556 0 00-7.255 13.09.75.75 0 101.271-.8 7.051 7.051 0 017.712-10.573.226.226 0 00.193-.04.223.223 0 00.087-.177 6.021 6.021 0 01.083-.993zM17.18 10.7a.249.249 0 00-.28.279 6.6 6.6 0 01.054.824 7.037 7.037 0 01-1.081 3.755.749.749 0 00.235 1.034.739.739 0 00.4.116.75.75 0 00.635-.35 8.527 8.527 0 001.311-4.558 8.011 8.011 0 00-.047-.839.249.249 0 00-.263-.223c-.079 0-.158.007-.238.007a5.8 5.8 0 01-.726-.045zM17.906 0a4.75 4.75 0 104.75 4.75A4.756 4.756 0 0017.906 0zm0 8a3.25 3.25 0 113.25-3.25A3.254 3.254 0 0117.906 8z\"/><path d=\"M19.674 4h-.768a.25.25 0 01-.25-.25v-.769a.75.75 0 00-1.5 0v1.768a.75.75 0 00.75.75h1.768a.75.75 0 100-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});