define("ember-svg-jar/inlined/landmark-sydney-opera-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-sydney-opera-house</title><path d=\"M1.809 12.66a.253.253 0 01.006.255.5.5 0 00-.066.249v3.5a.5.5 0 00.5.5h19.576a.5.5 0 00.5-.5v-1.5a.5.5 0 00-.15-.358.249.249 0 01-.026-.33l1.551-2.071a1.514 1.514 0 00-.991-2.389 8.79 8.79 0 00-3.632.19.25.25 0 01-.306-.176 9.053 9.053 0 00-1.418-2.944 11.747 11.747 0 00-6.27-4.19 1.493 1.493 0 00-1.89 1.7l.259 1.469a.251.251 0 01-.362.266A10.536 10.536 0 005.369 5.2a1.5 1.5 0 00-1.59 1.9l.115.4a.142.142 0 01-.155.18 4.714 4.714 0 00-2.73.3 1.5 1.5 0 00-.751 2.2zm2.163 0a.1.1 0 01-.073-.03L2.247 10a.249.249 0 01.182-.381 3.133 3.133 0 012.33.666l1.491 1.9a.28.28 0 01-.175.477zm4.771.067a.551.551 0 00-.244-.067h-.87a.251.251 0 01-.241-.183L6.034 7.71a.25.25 0 01.3-.31c2.289.593 3.428 1.739 3.785 2.631l1.44 3.8a.25.25 0 01-.358.3zM11.385 5.5a.25.25 0 01.342-.274 8.193 8.193 0 015.22 5.729.248.248 0 01-.106.258 6.59 6.59 0 00-2.641 3.322.187.187 0 01-.178.129h-.832a.25.25 0 01-.247-.207zm8.183 9.074a.191.191 0 01-.161.089h-2.654a.25.25 0 01-.207-.389 5.8 5.8 0 014.765-2.365.25.25 0 01.206.4z\"/><rect y=\"18.668\" width=\"24\" height=\"2.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});