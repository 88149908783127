define("ember-svg-jar/inlined/designer-community-coroflot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-coroflot</title><path d=\"M17.13 10a4.66 4.66 0 00-.64.05l-.21-.46c-1.34-3-5.1-8.13-10-6.84a4.5 4.5 0 00.17-2A.75.75 0 105 .87a2.26 2.26 0 01-.5 2 1.87 1.87 0 01-1.5.47.75.75 0 00-.28 1.47 2.62 2.62 0 00.48 0 3.46 3.46 0 002.07-.72l.33.14C9 6 9 10.56 8.94 11.87a6.23 6.23 0 101.51-.37c0-1.67-.23-5.25-2.62-7.49 4.26 0 7.06 6.09 7.09 6.16l.13.3a.48.48 0 000 .84 7.49 7.49 0 013.62 6.41v.7a.5.5 0 00.73.49A4.73 4.73 0 0017.13 10zm-8.21 6.51a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});