define("ember-svg-jar/inlined/seafood-squid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>seafood-squid</title><path d=\"M10.5 18.5a1 1 0 00-1 1A2.5 2.5 0 017 22a2.316 2.316 0 01-2.5-2.25 1 1 0 00-2 0A4.284 4.284 0 007 24a4.505 4.505 0 004.5-4.5 1 1 0 00-1-1zM20.5 18.75a1 1 0 00-1 1A2.316 2.316 0 0117 22a2.5 2.5 0 01-2.5-2.5 1 1 0 00-2 0A4.505 4.505 0 0017 24a4.284 4.284 0 004.5-4.25 1 1 0 00-1-1z\"/><path d=\"M20.5 11a2.5 2.5 0 00-2.5 2.5V16a1 1 0 01-2 0V9.489a.5.5 0 01.5-.5h1.89a1 1 0 00.917-.587 1.018 1.018 0 00-.168-1.081l-6.4-7a1.02 1.02 0 00-1.5.005c-.571.653-5.585 6.087-6.392 7A1.012 1.012 0 004.693 8.4a1 1 0 00.917.587H7.5a.5.5 0 01.5.5V16a1 1 0 01-2 0v-2.5a2.5 2.5 0 00-5 0v2a1 1 0 002 0v-2a.5.5 0 011 0V16a3 3 0 006 0 .5.5 0 01.5-.5h3a.5.5 0 01.5.5 3 3 0 006 0v-2.5a.5.5 0 011 0v2a1 1 0 002 0v-2a2.5 2.5 0 00-2.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});