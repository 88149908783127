define("ember-svg-jar/inlined/layout-module-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layout-module-1</title><rect y=\"-.003\" width=\"11\" height=\"11\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"13\" y=\"-.003\" width=\"11\" height=\"11\" rx=\"1.5\" ry=\"1.5\"/><rect y=\"12.997\" width=\"11\" height=\"11\" rx=\"1.5\" ry=\"1.5\"/><rect x=\"13\" y=\"12.997\" width=\"11\" height=\"11\" rx=\"1.5\" ry=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});