define("ember-svg-jar/inlined/shopping-basket-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-check</title><path d=\"M17.317 11.075a.251.251 0 00.207-.184l.344-1.309a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.7a.249.249 0 00.243-.194 7.508 7.508 0 016.242-5.731zm-6.817.539V14a.5.5 0 01-1 0v-2.386a.5.5 0 011 0zm-3.872 3.031a.488.488 0 01-.085.007.5.5 0 01-.492-.417l-.408-2.385a.5.5 0 01.409-.576.5.5 0 01.577.408l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.251.251 0 01-.222.366H5.4a.249.249 0 01-.213-.121.246.246 0 01-.008-.245l1.51-2.9A1 1 0 104.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439zM18.5 13a5.5 5.5 0 105.5 5.5 5.506 5.506 0 00-5.5-5.5zm2.828 4.5l-2.421 3.228a1.368 1.368 0 01-1 .547h-.1a1.362 1.362 0 01-.97-.407l-1.249-1.249a.75.75 0 111.061-1.06l.945.946a.251.251 0 00.377-.027l2.158-2.878a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});