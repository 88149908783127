define("ember-svg-jar/inlined/day-sunset-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-sunset-1</title><path d=\"M7.865 14.478a.25.25 0 00.049-.341 5 5 0 118.168-.011.249.249 0 00.051.341 2.289 2.289 0 01.792 1.183.25.25 0 00.432.093 7 7 0 10-10.707.022.25.25 0 00.432-.1 2.266 2.266 0 01.783-1.191zM1.744 12.25h1.5a1 1 0 000-2h-1.5a1 1 0 000 2z\"/><path d=\"M5.1 5.77a1 1 0 101.414-1.415L5.453 3.3a1 1 0 00-1.414 1.409zM11.994 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM4.039 19.2a1 1 0 001.414 0l1.061-1.06A1 1 0 105.1 16.73l-1.061 1.061a1 1 0 000 1.409zM19.744 11.25a1 1 0 001 1h1.5a1 1 0 000-2h-1.5a1 1 0 00-1 1zM18.182 6.063a1 1 0 00.707-.293l1.06-1.061A1 1 0 1018.535 3.3l-1.06 1.06a1 1 0 00.707 1.708zM18.889 16.73a1 1 0 00-1.414 1.415l1.06 1.06a1 1 0 001.414-1.414zM11.408 20.369a.75.75 0 001.2 0l2.75-3.667a.749.749 0 00-.6-1.2h-1.5a.25.25 0 01-.25-.25V13a1 1 0 00-2 0v2.25a.25.25 0 01-.25.25h-1.5a.75.75 0 00-.6 1.2zM22.994 22H1.006a1 1 0 000 2h21.988a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});