define("ember-svg-jar/inlined/gymnastics-acrobatic-hanging-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gymnastics-acrobatic-hanging-1</title><path d=\"M22.625 15.009a.5.5 0 01-.125-.33V1a1 1 0 00-1.943-.333.5.5 0 01-.472.333H3.915a.5.5 0 01-.472-.333A1 1 0 001.5 1v13.679a.5.5 0 01-.125.33A1.5 1.5 0 001 16v7a1 1 0 001 1h1a1 1 0 001-1v-7a1.5 1.5 0 00-.375-.991.5.5 0 01-.125-.33V3.5A.5.5 0 014 3h2.5a.5.5 0 01.5.5v5.063a.5.5 0 01-.3.459 3.25 3.25 0 102.6 0 .5.5 0 01-.3-.459V3.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5.063a.5.5 0 01-.3.459 3.25 3.25 0 102.6 0 .5.5 0 01-.3-.459V3.5a.5.5 0 01.5-.5H20a.5.5 0 01.5.5v11.179a.5.5 0 01-.125.33A1.5 1.5 0 0020 16v7a1 1 0 001 1h1a1 1 0 001-1v-7a1.5 1.5 0 00-.375-.991zM9.25 12A1.25 1.25 0 118 10.75 1.252 1.252 0 019.25 12zm8 0A1.25 1.25 0 1116 10.75 1.252 1.252 0 0117.25 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});