define("ember-svg-jar/inlined/folder-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-hold</title><path d=\"M17 22h-3.75a.25.25 0 01-.25-.25v-3.5a.25.25 0 01.25-.25H21a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0021 3h-9.882a.5.5 0 01-.447-.276L9.724.83A1.5 1.5 0 008.38 0H3a1.5 1.5 0 00-1.5 1.5v15A1.5 1.5 0 003 18h7.75a.25.25 0 01.25.25v3.5a.25.25 0 01-.25.25H7a1 1 0 000 2h10a1 1 0 000-2zM4 22H2a1 1 0 000 2h2a1 1 0 000-2zM22 22h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});