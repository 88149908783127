define("ember-svg-jar/inlined/style-one-pin-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-dollar</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M14.875 11.158A2.33 2.33 0 0013.4 8.984l-2.06-.825a.342.342 0 01.127-.659H13.5a1 1 0 000-2h-.25a.25.25 0 01-.25-.25 1 1 0 00-2 0v.1a.251.251 0 01-.181.241 2.341 2.341 0 00-.221 4.424l2.062.825a.342.342 0 01-.127.659H10.5a1 1 0 000 2h.25a.25.25 0 01.25.25 1 1 0 002 0v-.1a.251.251 0 01.181-.241 2.345 2.345 0 001.694-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});