define("ember-svg-jar/inlined/concert-sing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>concert-sing</title><path d=\"M4.973.387A1.249 1.249 0 002.85.978L2.466 2.7a.251.251 0 01-.3.189 1.75 1.75 0 101.327 2.09l.616-2.76a.25.25 0 01.426-.119l.659.689A.75.75 0 006.277 1.75zM14.752 12.032a6.191 6.191 0 01-4.875-1.2.251.251 0 00-.245-.035.248.248 0 00-.155.203l-1.329 8.2a2.215 2.215 0 00.723 2.006.5.5 0 01.154.493l-.335 1.372a.75.75 0 00.549.908.8.8 0 00.18.021.751.751 0 00.728-.571l.335-1.368a.5.5 0 01.365-.366 2.217 2.217 0 001.569-1.445l2.617-7.894a.249.249 0 00-.281-.324zM9.952 4.252l7.78 1.907a.5.5 0 00.618-.516 4.7 4.7 0 00-8.708-2.135.5.5 0 00.31.744zM17.717 7.91a.5.5 0 00-.343-.294L9.6 5.709a.5.5 0 00-.618.517 4.7 4.7 0 008.706 2.135.5.5 0 00.029-.451zM23.254 13.505l-2.754-.834a1.762 1.762 0 00-2.2 2.2l.792 2.615a.5.5 0 01-.334.623 2.5 2.5 0 103.117 1.669l-1.4-4.613a.25.25 0 01.311-.312l1.881.569a1 1 0 10.579-1.914z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});