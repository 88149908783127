define("ember-svg-jar/inlined/bandage-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-finger</title><path d=\"M16.5 13.362h-3a.5.5 0 010-1h2a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5H9.923a.25.25 0 01-.145-.454l2.342-1.666a1.986 1.986 0 00-.8-3.57A1.934 1.934 0 009.82 3l-8.4 5.95a1.014 1.014 0 00-.42.82v8.71a1 1 0 00.72.96l5.3 1.52a10.534 10.534 0 002.88.4h2.6a2.5 2.5 0 002.308-1.525.5.5 0 00-.461-.7H12.5a.5.5 0 01-.5-.5.5.5 0 01.5-.5h2.77a1.487 1.487 0 00.73-1.28v-.22a.5.5 0 00-.5-.5h-2a.5.5 0 01-.5-.5.5.5 0 01.5-.5h2.77a1.487 1.487 0 00.73-1.28.5.5 0 00-.5-.493zM21 8.362h-1a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h1a2 2 0 000-4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});