define("ember-svg-jar/inlined/read-human", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>read-human</title><path d=\"M9.768 8.275a7.305 7.305 0 00-1.757 1.182.25.25 0 00.1.426l3.621 1.006a.989.989 0 00.536 0l3.619-1a.25.25 0 00.1-.427 7.317 7.317 0 00-1.756-1.181.25.25 0 01-.032-.435 4 4 0 10-4.4 0 .25.25 0 01-.031.435z\"/><path d=\"M21.375 12.605a.5.5 0 01-.375-.483v-1.3a1 1 0 00-1.268-.964l-7.2 2a2 2 0 01-1.07 0l-7.2-2A1 1 0 003 10.817v1.3a.5.5 0 01-.375.484 3.5 3.5 0 000 6.778.5.5 0 01.375.488v.45a1 1 0 00.732.963l7.733 2.148a2 2 0 001.07 0l7.733-2.148a1 1 0 00.732-.963v-.45a.5.5 0 01.375-.484 3.5 3.5 0 000-6.778zM5.5 17.328a.667.667 0 01-.667.666H3.5a2 2 0 010-4h1.333a.667.667 0 01.667.667zm15 .666h-1.333a.667.667 0 01-.667-.666v-2.667a.667.667 0 01.667-.667H20.5a2 2 0 010 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});