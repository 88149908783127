define("ember-svg-jar/inlined/cursor-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-question</title><path d=\"M9.71 5.288a9 9 0 109 9 9.01 9.01 0 00-9-9zm-.086 14.288a1 1 0 111-1 1 1 0 01-1 1zm1.335-4.643a.973.973 0 00-.585.892.75.75 0 01-1.5 0 2.474 2.474 0 011.484-2.267 1.836 1.836 0 10-2.57-1.684.75.75 0 01-1.5 0 3.336 3.336 0 114.671 3.059zM23.144.854a.5.5 0 00-.457-.135l-8.811 1.86a.5.5 0 00-.072.957 11.48 11.48 0 016.658 6.658.5.5 0 00.957-.072l1.86-8.811a.5.5 0 00-.135-.457z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});