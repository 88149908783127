define("ember-svg-jar/inlined/conversation-chat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-1</title><path d=\"M10.25 12.056a2.5 2.5 0 012.5-2.5H20a.25.25 0 00.25-.25v-6.25a1.5 1.5 0 00-1.5-1.5h-17a1.5 1.5 0 00-1.5 1.5v10a1.5 1.5 0 001.5 1.5h1.5v3.5a.5.5 0 00.854.354l3.853-3.854H10a.25.25 0 00.25-.25z\"/><path d=\"M22.75 11.056h-10a1 1 0 00-1 1v6a1 1 0 001 1h4.5l3.668 3.26a.5.5 0 00.832-.373v-2.887h1a1 1 0 001-1v-6a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});