define("ember-svg-jar/inlined/gesture-swipe-horizontal-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-left-1</title><path d=\"M1.887 8.418l.4-1.195a.251.251 0 00-.115-.3.715.715 0 00-.415-.089.749.749 0 00-.658.511l-.946 2.837a.75.75 0 00.475.948l2.834.945a.753.753 0 00.8-.22.738.738 0 00.168-.6.246.246 0 00-.168-.2L2.99 10.63a1.75 1.75 0 01-1.103-2.212zM23.616 9.686a10.515 10.515 0 00-5.641-3.023 1 1 0 10-.444 1.95 8.5 8.5 0 014.624 2.439 1 1 0 101.461-1.366z\"/><path d=\"M8.3 7.418a1 1 0 00-1.2-.754c-.119.027-.225.062-.341.091-.268.065-.532.133-.789.206a.251.251 0 01-.291-.129L5.163 5.8a.727.727 0 00-.725-.413.753.753 0 00-.658.513l-.944 2.834a.751.751 0 00.474.949l2.834.944a.751.751 0 00.909-1.047l-.234-.466a.251.251 0 01.156-.353c.184-.052.37-.1.567-.147a1.052 1.052 0 00.119-.053.989.989 0 00.639-1.143zM12.615 5a3.5 3.5 0 00-3.5 3.5v10a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-10a3.5 3.5 0 00-3.5-3.5zm1.5 4.625a.375.375 0 01-.375.375h-2.25a.375.375 0 01-.375-.375V8.5a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});