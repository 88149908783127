define("ember-svg-jar/inlined/cursor-information-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-information-alternate</title><path d=\"M9.959 4.538a9.5 9.5 0 109.5 9.5 9.511 9.511 0 00-9.5-9.5zm0 17a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5zM23.393.6a.5.5 0 00-.457-.136l-8.81 1.861a.5.5 0 00-.073.957 11.48 11.48 0 016.658 6.658.5.5 0 00.957-.072l1.861-8.811A.5.5 0 0023.393.6z\"/><path d=\"M12.209 17.788h-.75a.25.25 0 01-.25-.25v-3.75a2 2 0 00-2-2h-1a1 1 0 000 2h.75a.249.249 0 01.25.25v3.5a.249.249 0 01-.25.25h-.75a1 1 0 000 2h4a1 1 0 000-2z\"/><circle cx=\"10.209\" cy=\"9.538\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});