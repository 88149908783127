define("ember-svg-jar/inlined/desktop-monitor-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-clock</title><path d=\"M16.289 14.405a.245.245 0 00-.2.057.248.248 0 00-.088.19v2.1a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25v-8.5A.25.25 0 012.25 8h7.1a.251.251 0 00.25-.288A7.8 7.8 0 019.5 6.5c0-.079 0-.158.008-.236A.25.25 0 009.258 6H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.75a.25.25 0 01.25.25v1a.25.25 0 01-.25.25H5a1 1 0 000 2h8a1 1 0 100-2h-2.75a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H16a2 2 0 002-2v-3.757a.25.25 0 00-.078-.182.254.254 0 00-.187-.068c-.078 0-.156.008-.235.008a7.926 7.926 0 01-1.211-.096z\"/><path d=\"M17.5 0A6.5 6.5 0 1024 6.5 6.508 6.508 0 0017.5 0zM13 6.5a4.5 4.5 0 114.5 4.5A4.5 4.5 0 0113 6.5z\"/><path d=\"M16.75 4v2.5a.75.75 0 00.75.75h2a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});