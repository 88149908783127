define("ember-svg-jar/inlined/light-mode-exposure-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-exposure-1</title><path d=\"M24 3a3 3 0 00-3-3H3a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3zm-3.5 13a1 1 0 010 2h-2.25a.25.25 0 00-.25.25v2.25a1 1 0 01-2 0v-2.25a.25.25 0 00-.25-.25H13.5a1 1 0 010-2h2.25a.25.25 0 00.25-.25V13.5a1 1 0 012 0v2.25a.25.25 0 00.25.25zM2 3a1 1 0 011-1h18a.988.988 0 01.431.1.249.249 0 01.069.4l-19 19a.253.253 0 01-.219.07.249.249 0 01-.183-.139A.988.988 0 012 21z\"/><path d=\"M5.5 7.5H11a1 1 0 000-2H5.5a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});