define("ember-svg-jar/inlined/transfer-pictures-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfer-pictures-smartphone</title><path d=\"M10.01 2.01a6.018 6.018 0 00-5.851 4.8.249.249 0 01-.244.2H3.01a.5.5 0 00-.353.854l2 2a.5.5 0 00.707 0l2-2a.5.5 0 00-.354-.854h-.527a.249.249 0 01-.235-.334A4.021 4.021 0 0110.01 4.01a1 1 0 000-2zM23.51 3.01a1 1 0 00-1-1h-.191a.5.5 0 01-.447-.276L21.148.287A.5.5 0 0020.7.01h-3.381a.5.5 0 00-.447.277l-.723 1.447a.5.5 0 01-.447.276H13.51a1 1 0 00-1 1v5a1 1 0 001 1h9a1 1 0 001-1zm-4.5 4a2 2 0 112-2 2 2 0 01-2 2zM7.01 11.01H2.49a2 2 0 00-2 2v8.98a2 2 0 002 2h4.52a2 2 0 002-2v-8.98a2 2 0 00-2-2zm-.5 2a.5.5 0 01.5.5v6a.5.5 0 01-.5.5H2.99a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});