define("ember-svg-jar/inlined/cloud-storage-drive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-storage-drive</title><path d=\"M21.078 7.211a3.877 3.877 0 01.89 2.761 3.508 3.508 0 01-.667 1.913 1.007 1.007 0 00.16 1.406.986.986 0 001.381-.141 5.446 5.446 0 001.133-3.278A5.765 5.765 0 0018.633 3.9 7.684 7.684 0 004.471 6.085a.251.251 0 01-.226.2 4.59 4.59 0 00-4.267 4.574 4.62 4.62 0 00.69 2.54 1.006 1.006 0 001.507.206.975.975 0 00.188-1.267 2.56 2.56 0 01-.385-1.479 2.588 2.588 0 012.586-2.588 2.7 2.7 0 01.535.054 1 1 0 001.2-.962 4.512 4.512 0 01.934-2.98c3.358-3.839 8.251-2.512 9.884.935a.977.977 0 00.941.57 3.723 3.723 0 013.02 1.323z\"/><path d=\"M2.123 16a.25.25 0 00.15.45h19.5a.25.25 0 00.15-.45l-3.133-2.35a1.006 1.006 0 00-.6-.2H5.857a1 1 0 00-.6.2zM22.523 17.946h-21a1.5 1.5 0 00-1.5 1.5v3a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-3a1.5 1.5 0 00-1.5-1.5zm-2.5 4.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});