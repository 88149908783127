define("ember-svg-jar/inlined/cursor-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-add</title><path d=\"M9.865 7.23a1.5 1.5 0 00.307-2.8L2.723.708A1.5 1.5 0 00.71 2.721l3.726 7.45a1.5 1.5 0 002.8-.309l.525-2.1zM15.448 7.446a8 8 0 108 8 8 8 0 00-8-8zm3 9H16.7a.25.25 0 00-.25.25v1.75a1 1 0 11-2 0V16.7a.25.25 0 00-.25-.25h-1.75a1 1 0 010-2h1.75a.25.25 0 00.25-.25v-1.75a1 1 0 112 0v1.75a.25.25 0 00.25.25h1.75a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});