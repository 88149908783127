define("ember-svg-jar/inlined/halloween-lips-vampire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-lips-vampire</title><path d=\"M20.8 10.923a.5.5 0 00-.657.386 6.5 6.5 0 01-1.425 2.91.555.555 0 01-.979-.257 6.489 6.489 0 00-1.126-2.987.5.5 0 00-.567-.161c-.26.1-.525.2-.8.309a8.94 8.94 0 01-3.246.859 8.969 8.969 0 01-3.247-.858c-.271-.11-.536-.213-.8-.309a.5.5 0 00-.567.16 6.478 6.478 0 00-1.125 2.987.555.555 0 01-.979.257 6.48 6.48 0 01-1.422-2.911.5.5 0 00-.658-.386 9 9 0 00-2.174 1.051.5.5 0 00-.1.724c1.714 2.126 5.519 5.86 11.077 5.86 5.582 0 9.394-3.766 11.1-5.887a.468.468 0 00-.1-.678 9.447 9.447 0 00-2.205-1.069z\"/><path d=\"M23.7 10.145c-1.327-1.413-4.726-4.7-7.587-4.7a4.832 4.832 0 00-3.708 1.3.6.6 0 01-.8 0 4.832 4.832 0 00-3.708-1.3c-2.861 0-6.26 3.289-7.587 4.7a1.2 1.2 0 00-.293.544.364.364 0 00.562.382A10.654 10.654 0 016.162 9.4a8.365 8.365 0 012.966.8 8.223 8.223 0 002.872.782 8.223 8.223 0 002.872-.786 8.365 8.365 0 012.966-.8 10.654 10.654 0 015.59 1.676.364.364 0 00.562-.382 1.2 1.2 0 00-.29-.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});