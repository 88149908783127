define("ember-svg-jar/inlined/monitor-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-remove</title><path d=\"M11.78 11.925a.249.249 0 00-.18-.425H2.5A.5.5 0 012 11V2.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v6.85a.251.251 0 00.289.247A7.8 7.8 0 0117.5 9.5c.079 0 .157 0 .235.007a.248.248 0 00.187-.067.251.251 0 00.078-.181V2a2 2 0 00-2-2H2a2 2 0 00-2 2v10.5a2 2 0 002 2h5.5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H5a1 1 0 000 2h4.258a.251.251 0 00.25-.265c0-.078-.008-.156-.008-.235a7.958 7.958 0 012.28-5.575z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.513 6.513 0 00-6.5-6.5zm1.6 6.677l1.233 1.233a1.027 1.027 0 01.13 1.27 1.738 1.738 0 01-.13.15 1.024 1.024 0 01-.71.29 1.042 1.042 0 01-.71-.29l-1.236-1.23a.251.251 0 00-.177-.073.255.255 0 00-.177.073l-1.233 1.231a1.014 1.014 0 01-1.42 0 1.738 1.738 0 01-.13-.15 1.027 1.027 0 01.13-1.27l1.23-1.234a.249.249 0 000-.353l-1.23-1.233a1.027 1.027 0 01-.13-1.27 1.393 1.393 0 01.28-.28 1.027 1.027 0 011.27.13l1.233 1.229a.251.251 0 00.354 0l1.233-1.233a1.03 1.03 0 011.27-.13 1.393 1.393 0 01.28.28 1.027 1.027 0 01-.13 1.27l-1.23 1.237a.249.249 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});