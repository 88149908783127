define("ember-svg-jar/inlined/server-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-share-1</title><path d=\"M2.7 20.75a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM2.451 5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 000 5zm.5-2.5A1.25 1.25 0 114.2 3.75 1.249 1.249 0 012.951 2.5zm3.75 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 016.7 2.5zM-.049 8.5a2.5 2.5 0 002.5 2.5h13a2.5 2.5 0 000-5h-13a2.5 2.5 0 00-2.5 2.5zm3 0A1.25 1.25 0 114.2 9.75 1.249 1.249 0 012.951 8.5zm3.75 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 016.7 8.5zM2.6 17h5.351a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-3a.75.75 0 000 1.5h5a.5.5 0 00.4-.784A4.256 4.256 0 0113.8 14a4.209 4.209 0 011.986.5.511.511 0 00.685-.237c.185-.365.43-.855.583-1.2a.481.481 0 00-.137-.585A2.476 2.476 0 0015.451 12h-13a2.5 2.5 0 00-2.487 2.76A2.6 2.6 0 002.6 17zm.353-2.5A1.25 1.25 0 114.2 15.75a1.249 1.249 0 01-1.249-1.25zm5 1.25A1.25 1.25 0 119.2 14.5a1.25 1.25 0 01-1.249 1.25z\"/><path d=\"M18.612 14.289a.249.249 0 01-.116.267l-2.665 1.6a.248.248 0 01-.288-.022 2.752 2.752 0 10.311 3.926.247.247 0 01.28-.067l2.272.909a.249.249 0 01.157.246A2.744 2.744 0 1021.3 18.5a2.725 2.725 0 00-2.055.941.252.252 0 01-.281.067L16.7 18.6a.249.249 0 01-.156-.253 2.1 2.1 0 00-.057-.638.25.25 0 01.116-.268l2.665-1.6a.249.249 0 01.288.022 2.726 2.726 0 001.744.637 2.75 2.75 0 10-2.687-2.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});