define("ember-svg-jar/inlined/plant-sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-sad</title><path d=\"M20.75 10.982H3.25a.75.75 0 000 1.5h.372a.5.5 0 01.5.458l.729 8.749a2.514 2.514 0 002.489 2.293h9.32a2.514 2.514 0 002.491-2.293l.729-8.749a.5.5 0 01.5-.458h.372a.75.75 0 000-1.5zm-11.5 3A1.25 1.25 0 118 15.232a1.25 1.25 0 011.25-1.25zm6.212 7.671a.748.748 0 01-1-.337 2.7 2.7 0 00-4.914 0 .75.75 0 01-1.348-.669 4.2 4.2 0 017.6 0 .748.748 0 01-.338 1.006zm-.712-5.171a1.25 1.25 0 111.25-1.25 1.25 1.25 0 01-1.25 1.25zM11.781 7.512a.25.25 0 00.438 0l2.143-3.895a.5.5 0 000-.474A54.802 54.802 0 0012.853.469a1.043 1.043 0 00-1.715 0A55.117 55.117 0 009.63 3.144a.5.5 0 000 .474zM7.324 9.723A1 1 0 008 9.982h2.568A.5.5 0 0011 9.723l.294-.535a.5.5 0 000-.482L7.4 1.628a.968.968 0 00-.9-.508.979.979 0 00-.857.578 6.94 6.94 0 00.5 6.641 7.033 7.033 0 001.181 1.384zM12.21 9.859a.249.249 0 00.215.123H16a1 1 0 00.672-.259 7.033 7.033 0 001.178-1.384 6.938 6.938 0 00.5-6.641.982.982 0 00-1.75-.071l-4.394 7.985a.248.248 0 00.004.247z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});