define("ember-svg-jar/inlined/non-gmo-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>non-gmo-fire</title><path d=\"M18.958 10.8a.5.5 0 00-.483.129L7.427 21.978a.5.5 0 00.089.778A8.366 8.366 0 0011.971 24a7.292 7.292 0 006.237-2.98c1.711-2.4 2.105-6.094 1.1-9.865a.5.5 0 00-.35-.355zM22.371 2.709a1 1 0 00-1.414 0l-3.248 3.249a.5.5 0 01-.772-.08A17.153 17.153 0 0010.7.119a1.015 1.015 0 00-1.378 1.36 10.609 10.609 0 01.008 9.785.5.5 0 01-.869-.011 6.047 6.047 0 01-.458-1.061 1 1 0 00-.694-.668 1.017 1.017 0 00-.94.212 7.942 7.942 0 00-2.38 8.286 8.8 8.8 0 00.315.9.5.5 0 01-.109.544l-2.57 2.57a1 1 0 101.414 1.414L22.371 4.123a1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});