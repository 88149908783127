define("ember-svg-jar/inlined/style-three-pin-scarecrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-scarecrow</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.153 6.5a.25.25 0 00-.243.192 2.989 2.989 0 01-5.82 0 .251.251 0 00-.243-.192H7a.5.5 0 010-1h1.46a.25.25 0 00.24-.171l.715-2.145a1 1 0 01.945-.684h3.28a1 1 0 01.948.684l.712 2.145a.25.25 0 00.237.171H17a.5.5 0 010 1zM12 19.5a.75.75 0 01-.75-.75v-1.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1.5a.75.75 0 01-.75.75zm6.25-10a.75.75 0 010 1.5h-3.5a.25.25 0 00-.25.25v4.25a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4.25a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});