define("ember-svg-jar/inlined/crypto-currency-bitcoin-mining", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-mining</title><path d=\"M.621 14.826a1.267 1.267 0 001.792 1.792l6.546-6.545a.25.25 0 000-.354L7.52 8.281a.247.247 0 00-.353 0zM16.267 9.578a.251.251 0 00.2-.356 12.666 12.666 0 00-2.288-3.31.25.25 0 010-.351l.238-.238a.5.5 0 000-.707l-1.794-1.792a.514.514 0 00-.707 0l-.366.366a.249.249 0 01-.35.01A11.881 11.881 0 003.31-.01a.5.5 0 00-.354.853l5.376 5.376a.135.135 0 010 .19.5.5 0 000 .707l1.792 1.792a.5.5 0 00.707 0l.04-.04a.25.25 0 01.354 0l1.7 1.7a.248.248 0 00.3.039 7.918 7.918 0 013.042-1.029zM17.25 11.01a6.5 6.5 0 106.5 6.5 6.5 6.5 0 00-6.5-6.5zm-2.75 3.5a.75.75 0 01.75-.75.25.25 0 00.25-.25.75.75 0 111.5 0 .25.25 0 00.25.25h.5A2.253 2.253 0 0120 16.01a2.219 2.219 0 01-.46 1.349.251.251 0 000 .3A2.219 2.219 0 0120 19.01a2.253 2.253 0 01-2.25 2.25h-.5a.25.25 0 00-.25.25.75.75 0 11-1.5 0 .25.25 0 00-.25-.25.75.75 0 01-.75-.75z\"/><path d=\"M16.25 15.26a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5zM17.75 18.26h-1.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});