define("ember-svg-jar/inlined/landmark-wat-phra-kaew", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-wat-phra-kaew</title><path d=\"M12.982.789A1.006 1.006 0 0012.005 0a1 1 0 00-.977.792c-.266 1.251-.549 2.18-.842 3.135a.25.25 0 00.239.323H13.6a.25.25 0 00.239-.324c-.299-.956-.587-1.885-.857-3.137zM15.17 8.181a.25.25 0 00.279-.38 11.255 11.255 0 01-1.123-2.386.25.25 0 00-.235-.165H9.935a.25.25 0 00-.236.166A11.185 11.185 0 018.585 7.8a.25.25 0 00.28.379A10.839 10.839 0 0112 7.75a10.985 10.985 0 013.17.431zM2.5 20.75h19a.5.5 0 00.5-.5v-.5a.5.5 0 00-.188-.391 7.159 7.159 0 01-1.576-3.1C19.209 13.3 17.8 9.25 12 9.25c-5.739 0-7.17 4.047-8.215 7a7.252 7.252 0 01-1.6 3.108.5.5 0 00-.185.392v.5a.5.5 0 00.5.5zM23 22H1a1 1 0 000 2h22a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});