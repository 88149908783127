define("ember-svg-jar/inlined/video-game-flower-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-flower-3</title><path d=\"M8.75 11.5v1a.75.75 0 001.5 0v-1a.75.75 0 00-1.5 0zM13.75 11.5v1a.75.75 0 001.5 0v-1a.75.75 0 00-1.5 0zM14.962 15.256a.5.5 0 00-.461-.307h-5a.5.5 0 00-.357.85 4 4 0 005.715 0 .5.5 0 00.103-.543zM8.693 5.633a.5.5 0 00.6.082 5.455 5.455 0 011.563-.589.5.5 0 00.4-.49V.588a.5.5 0 00-.561-.5 10.727 10.727 0 00-4.85 1.856.5.5 0 00-.068.764zM1.723 10.75H6a.5.5 0 00.5-.48 5.4 5.4 0 011.075-3.03.5.5 0 00-.047-.652L4.613 3.673a.5.5 0 00-.73.025 10.732 10.732 0 00-2.659 6.525.5.5 0 00.5.527zM7.39 16.989A5.451 5.451 0 016.5 14v-1.25a.5.5 0 00-.5-.5H1.707a.5.5 0 00-.5.5v.457A10.733 10.733 0 003.883 20.3a.5.5 0 00.73.025l2.711-2.712a.5.5 0 00.066-.624zM22.293 12.25H18a.5.5 0 00-.5.5V14a5.451 5.451 0 01-.89 2.989.5.5 0 00.066.626l2.711 2.712a.5.5 0 00.73-.025 10.733 10.733 0 002.676-7.095v-.457a.5.5 0 00-.5-.5zM16.425 7.239a5.434 5.434 0 011.07 3.032.5.5 0 00.5.479h4.282a.5.5 0 00.5-.527 10.732 10.732 0 00-2.66-6.523.5.5 0 00-.73-.025l-2.914 2.913a.5.5 0 00-.048.651z\"/><path d=\"M10.5 7.25a.75.75 0 00-.75.75 2.25 2.25 0 004.5 0V5.993a.311.311 0 01.461-.273.5.5 0 00.593-.085l2.924-2.924a.5.5 0 00-.067-.764 10.715 10.715 0 00-4.85-1.855.5.5 0 00-.561.5V8a.75.75 0 01-1.5 0 .75.75 0 00-.75-.75zM10.853 19.373a5.467 5.467 0 01-1.843-.763.5.5 0 00-.625.066l-2.614 2.613a.5.5 0 00.068.764 10.71 10.71 0 004.85 1.855.5.5 0 00.561-.5v-3.55a.5.5 0 00-.397-.485zM15.615 18.676a.5.5 0 00-.625-.066 5.459 5.459 0 01-1.845.764.5.5 0 00-.4.489v3.549a.5.5 0 00.561.5 10.727 10.727 0 004.85-1.856.5.5 0 00.068-.764z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});