define("ember-svg-jar/inlined/protection-mask", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-mask</title><path d=\"M11.417.02A8.7 8.7 0 003.5 8.8V11a8.647 8.647 0 002.471 5.62.246.246 0 00.321 0A9.068 9.068 0 0112 14.75a9.068 9.068 0 015.708 1.871.246.246 0 00.321 0A8.647 8.647 0 0020.5 11V8.5A8.51 8.51 0 0011.417.02zM8.5 11a2 2 0 112-2 2 2 0 01-2 2zm7 0a2 2 0 112-2 2 2 0 01-2 2z\"/><path d=\"M12 16.25a7.917 7.917 0 00-4.39 1.25.258.258 0 00-.11.213v1.409a.246.246 0 00.367.215A9.055 9.055 0 0112 18.5a9.055 9.055 0 014.133.839.246.246 0 00.367-.215v-1.409a.258.258 0 00-.11-.213A7.917 7.917 0 0012 16.25z\"/><path d=\"M7.5 22c0 1.314 2.264 2 4.5 2s4.5-.686 4.5-2c0-2.627-9-2.627-9 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});