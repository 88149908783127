define("ember-svg-jar/inlined/navigation-smartphone-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-smartphone-finger</title><path d=\"M12 13a.5.5 0 00.354-.146C12.543 12.664 17 8.171 17 5A5 5 0 007 5c0 3.171 4.457 7.664 4.646 7.854A.5.5 0 0012 13zm-1.75-8A1.75 1.75 0 1112 6.75 1.752 1.752 0 0110.25 5zM19 19h-1.25a.25.25 0 01-.25-.25v-4a1.75 1.75 0 00-3.5 0v4.906a.25.25 0 01-.454.146l-.115-.162a.226.226 0 01-.016-.026 1.55 1.55 0 00-2.736 1.458l1.4 2.66a.5.5 0 00.442.268H22a.5.5 0 00.5-.5v-1A3.5 3.5 0 0019 19zM8.25 17h-4a.25.25 0 01-.25-.25V5.25A.25.25 0 014.25 5h.5a1 1 0 000-2H2.5A2.5 2.5 0 000 5.5v11A2.5 2.5 0 002.5 19h5.75a1 1 0 000-2z\"/><path d=\"M21.5 3h-2.25a1 1 0 000 2h.5a.25.25 0 01.25.25v11.957a.5.5 0 00.364.481 4.961 4.961 0 011.8.941.5.5 0 00.562.049A2.506 2.506 0 0024 16.5v-11A2.5 2.5 0 0021.5 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});