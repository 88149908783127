define("ember-svg-jar/inlined/room-service-do-not-disturb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>room-service-do-not-disturb</title><path d=\"M8.839 17.286a.248.248 0 00-.218.069l-3 3a.251.251 0 00.069.4A2.47 2.47 0 006.769 21a2.5 2.5 0 002.5-2.5 2.474 2.474 0 00-.246-1.076.249.249 0 00-.184-.138zM7.983 16.43a.248.248 0 00-.137-.184 2.5 2.5 0 00-3.331 3.332.246.246 0 00.183.137.249.249 0 00.219-.069l3-3a.248.248 0 00.066-.216zM2.894 5.518c.024.016.047.031.072.045a.5.5 0 00.623-.107 4.371 4.371 0 01.39-.392A4.247 4.247 0 016.792 4a4.416 4.416 0 01.525.032l4.128.516a.5.5 0 00.538-.65A5.543 5.543 0 007.222.02a5.462 5.462 0 00-4.9 2.249 2.286 2.286 0 00.575 3.249z\"/><path d=\"M11.706 11.919l-4.388.548a4.039 4.039 0 01-.526.034 4.234 4.234 0 01-1.892-.448.5.5 0 00-.507.035l-.743.511a5.5 5.5 0 00-2.382 4.53V22.5a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5V12.415a.5.5 0 00-.562-.5zM6.769 22a3.5 3.5 0 113.5-3.5 3.51 3.51 0 01-3.5 3.5zM21.958 7.376L7.132 5.522a2.75 2.75 0 100 5.458l14.826-1.854a.882.882 0 000-1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});