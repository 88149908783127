define("ember-svg-jar/inlined/video-file-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-heart</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.549a.266.266 0 00.132.2c.078.043.156.085.232.132a.471.471 0 00.291-.017 5 5 0 011.178-.564.243.243 0 00.167-.231V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.354.146l2.121 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.53a.254.254 0 00-.208.111c-.237.334-.844 1.027-1.243 1.474a.249.249 0 00.189.414H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M13.725 13.361a.243.243 0 00.327.088l5.048-2.794a.735.735 0 000-1.31l-5.845-3.236a.888.888 0 00-.835-.009.755.755 0 00-.42.665v4.786a.238.238 0 00.116.2 5.027 5.027 0 011.609 1.61zM12.628 14.449A3.506 3.506 0 009.581 12.5a3.665 3.665 0 00-2.693 1.127l-.211.211a.249.249 0 01-.353 0l-.211-.211A3.667 3.667 0 003.419 12.5a3.527 3.527 0 00-2.393 6.012l5.113 5.334a.5.5 0 00.723 0l5.106-5.327a3.524 3.524 0 00.66-4.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});