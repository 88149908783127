define("ember-svg-jar/inlined/real-estate-sign-building-sale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-building-sale</title><path d=\"M11.224 6.052l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 004.5 6.5V14a.5.5 0 00.5.5h1.5A.5.5 0 007 14v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5H11a.5.5 0 00.5-.5V6.5a.5.5 0 00-.276-.448zM9.5 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM19.192 2.538l-6-2.5A.5.5 0 0012.5.5V14a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462zM17.5 10.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM9.5 16.5a2.253 2.253 0 00-2.25 2.25v4.5a.75.75 0 001.5 0v-2A.25.25 0 019 21h1a.25.25 0 01.25.25v2a.75.75 0 001.5 0v-4.5A2.253 2.253 0 009.5 16.5zm-.5 3a.25.25 0 01-.25-.25v-.5a.75.75 0 011.5 0v.5a.25.25 0 01-.25.25zM21 22.5h-2a.25.25 0 01-.25-.25v-1A.25.25 0 0119 21h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-1A.25.25 0 0119 18h2a.75.75 0 000-1.5h-2a1.752 1.752 0 00-1.75 1.75v4A1.752 1.752 0 0019 24h2a.75.75 0 000-1.5zM16 22.5h-2a.25.25 0 01-.25-.25v-5a.75.75 0 00-1.5 0v5A1.752 1.752 0 0014 24h2a.75.75 0 000-1.5zM4.3 18H6a.75.75 0 000-1.5H4.3a2.053 2.053 0 00-1.139 3.761L5 21.487a.553.553 0 01-.3 1.013H3A.75.75 0 003 24h1.7a2.053 2.053 0 001.139-3.761L4 19.014A.553.553 0 014.3 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});