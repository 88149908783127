define("ember-svg-jar/inlined/warehouse-package-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-package-box</title><path d=\"M9.08 21.857a.242.242 0 00.108.2.245.245 0 00.229.022l4.419-1.842a.972.972 0 00.6-.9v-5.276a.24.24 0 00-.109-.2.243.243 0 00-.228-.022l-4.87 2.032a.245.245 0 00-.149.225zM3.087 13.843a.243.243 0 00-.228.022.24.24 0 00-.109.2v5.276a.972.972 0 00.6.9l4.419 1.842a.244.244 0 00.337-.225V16.1a.244.244 0 00-.15-.225zM13.263 13.136a.243.243 0 000-.449l-4.3-1.79a.973.973 0 00-.749 0l-4.3 1.79a.244.244 0 000 .449L8.406 15a.485.485 0 00.374 0z\"/><path d=\"M11.514 10.254a.974.974 0 101.947 0v-.122a.246.246 0 01.109-.2.243.243 0 01.228-.022l3.408 1.42a.244.244 0 01.15.225V19.6a.972.972 0 01-.6.9l-3.666 1.511a.974.974 0 10.742 1.8l3.662-1.507A2.914 2.914 0 0019.3 19.6V6.214a.244.244 0 01.072-.172l1.59-1.591a.973.973 0 10-1.376-1.377l-1.369 1.372a.243.243 0 01-.265.052l-3.583-1.492a.245.245 0 01-.145-.178.24.24 0 01.067-.219l.832-.833A.974.974 0 1013.746.4l-1.662 1.661a1.934 1.934 0 00-.57 1.377zm2.1-2.534a.243.243 0 01-.15-.225V5.1a.244.244 0 01.337-.224L17.206 6.3a.243.243 0 01.15.225v2.39a.243.243 0 01-.337.225z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});