define("ember-svg-jar/inlined/common-file-horizontal-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-horizontal-image</title><path d=\"M24 8.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0017.586 2H2a2 2 0 00-2 2v16a2 2 0 002 2h20a2 2 0 002-2zM22 19.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-15a.5.5 0 01.5-.5h14.465a.5.5 0 01.353.146l4.536 4.536a.5.5 0 01.146.353z\"/><circle cx=\"8.299\" cy=\"8\" r=\"2\"/><path d=\"M13.723 10.678a.5.5 0 00-.848 0l-2.426 3.882a.251.251 0 01-.424 0l-.864-1.382a.5.5 0 00-.848 0l-2.775 4.439A.25.25 0 005.75 18h12.1a.25.25 0 00.212-.383z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});