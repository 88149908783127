define("ember-svg-jar/inlined/shipment-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-add</title><path d=\"M8.75 3.75A.25.25 0 009 4h5.353a.25.25 0 00.177-.427L11.646.689A1.494 1.494 0 0010.585.25H9a.25.25 0 00-.25.25zM7 4a.25.25 0 00.25-.25V.5A.25.25 0 007 .25H5.414a1.49 1.49 0 00-1.06.439L1.469 3.573A.25.25 0 001.646 4zM15.3 9.436a.249.249 0 00.2-.244V5.75a.25.25 0 00-.25-.25H.75a.25.25 0 00-.25.25v9a1.5 1.5 0 001.5 1.5h6.852a.25.25 0 00.247-.212A8.014 8.014 0 0115.3 9.436z\"/><path d=\"M17 10.75a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm.75 9a.75.75 0 01-1.5 0v-1.5A.25.25 0 0016 18h-1.5a.75.75 0 010-1.5H16a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5H18a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});