define("ember-svg-jar/inlined/wedding-fireworks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-fireworks</title><path d=\"M6.332 15.929a.523.523 0 00.746-.654l-.8-1.834a.5.5 0 01.172-.61l1.4-.976A.49.49 0 007.506 11H5.852a.5.5 0 01-.468-.323l-.905-2.39a.531.531 0 00-.943 0l-.907 2.39a.5.5 0 01-.468.323H.506a.489.489 0 00-.343.855l1.4.976a.5.5 0 01.172.61l-.8 1.834a.523.523 0 00.746.654l2.081-1.169a.5.5 0 01.49 0zM22.437 12.831l1.4-.976a.489.489 0 00-.343-.855h-1.655a.5.5 0 01-.468-.323l-.907-2.39a.531.531 0 00-.943 0l-.905 2.39a.5.5 0 01-.468.323h-1.654a.49.49 0 00-.346.855l1.4.976a.5.5 0 01.172.61l-.8 1.834a.523.523 0 00.746.654l2.079-1.169a.5.5 0 01.491 0l2.079 1.169a.523.523 0 00.746-.654l-.8-1.834a.5.5 0 01.176-.61zM15.852 9.093l-1.039-2.385a.5.5 0 01.173-.61l1.834-1.279a.612.612 0 00-.432-1.069h-2.155a.5.5 0 01-.468-.323L12.6.358a.664.664 0 00-1.178 0L10.26 3.427a.5.5 0 01-.467.323H7.638a.612.612 0 00-.43 1.069L9.042 6.1a.5.5 0 01.173.61L8.176 9.093a.655.655 0 00.932.819l2.661-1.5a.5.5 0 01.49 0l2.66 1.5a.655.655 0 00.933-.819zM12.006 10.5a1 1 0 00-1 1V23a1 1 0 002 0V11.5a1 1 0 00-1-1zM5.527 16.65a1 1 0 00-1.054 1.7c1.815 1.125 1.985 1.987 2.546 4.842A1 1 0 008 24a1 1 0 00.982-1.192c-.582-2.95-.899-4.575-3.455-6.158zM18.473 16.65c-2.556 1.583-2.874 3.208-3.454 6.158a1 1 0 101.962.384c.561-2.855.731-3.717 2.546-4.842a1 1 0 00-1.054-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});