define("ember-svg-jar/inlined/auto-pilot-car-sound-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>auto-pilot-car-sound-warning</title><circle cx=\"14.375\" cy=\"17.375\" r=\"2\"/><path d=\"M22.875 4.625h-2.386a2.975 2.975 0 00-2.544 1.41l-2.912 4.551a.02.02 0 01-.012.008l-4.048.674a1.506 1.506 0 00-1.223 1.186l-.626 3.127a1.5 1.5 0 001.476 1.794h.562a.25.25 0 00.247-.214 4.584 4.584 0 01.259-1.069 3 3 0 015.427.006 4.58 4.58 0 01.256 1.063.251.251 0 00.248.214h5.781a.5.5 0 00.5-.5V5.625a1 1 0 00-1.005-1zm-1 5.5a.25.25 0 01-.25.25L18 10.38a.249.249 0 01-.215-.38l1.854-2.9a.993.993 0 01.85-.474h1.136a.25.25 0 01.25.25zM2.125 12.236a7.463 7.463 0 012.092-5.2 1 1 0 00-1.442-1.382 9.5 9.5 0 000 13.164 1 1 0 001.442-1.385 7.469 7.469 0 01-2.092-5.197z\"/><path d=\"M7.053 7.739a1 1 0 00-1.413.043 6.5 6.5 0 000 8.907 1 1 0 001.46-1.371 4.5 4.5 0 010-6.164 1 1 0 00-.047-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});