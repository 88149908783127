define("ember-svg-jar/inlined/location-off-target", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>location-off-target</title><path d=\"M14.126 18.26a6.606 6.606 0 01-8.385-8.383 1.225 1.225 0 00-2.317-.794 8.628 8.628 0 00-.331 1.29.49.49 0 01-.482.4H1.224a1.224 1.224 0 000 2.448h1.39a.489.489 0 01.481.4 9.069 9.069 0 007.278 7.279.491.491 0 01.4.482v1.39a1.224 1.224 0 002.448 0V21.39a.489.489 0 01.4-.482 9.026 9.026 0 001.289-.33 1.224 1.224 0 10-.79-2.318zM19.508 17.776a.488.488 0 01-.057-.624 8.989 8.989 0 001.454-3.525.49.49 0 01.482-.4h1.389a1.224 1.224 0 000-2.448h-1.39a.489.489 0 01-.481-.4A9.069 9.069 0 0013.627 3.1a.491.491 0 01-.4-.482V1.224a1.224 1.224 0 00-2.448 0v1.389a.489.489 0 01-.4.481 8.972 8.972 0 00-3.527 1.458.489.489 0 01-.625-.052L2.09.359A1.224 1.224 0 00.359 2.09L21.91 23.641a1.224 1.224 0 101.731-1.731zM12 5.388a6.6 6.6 0 015.793 9.788.489.489 0 01-.775.11L8.712 6.98a.489.489 0 01.11-.775A6.558 6.558 0 0112 5.388z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});