define("ember-svg-jar/inlined/app-window-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-two</title><path d=\"M18.5 4.251a3 3 0 00-3-3H3a3 3 0 00-3 3v9.5a3 3 0 003 3h12.5a3 3 0 003-3zm-8.846-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM16.5 13.751a1 1 0 01-1 1H3a1 1 0 01-1-1V6.5a.25.25 0 01.25-.25h14a.25.25 0 01.25.25z\"/><path d=\"M24 10.251a3 3 0 00-3-3h-.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h1.25a.25.25 0 01.25.25v7.25a1 1 0 01-1 1H8.5a1 1 0 01-1-1v-.5a1 1 0 00-1-1 1 1 0 00-1 1v.5a3 3 0 003 3H21a3 3 0 003-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});