define("ember-svg-jar/inlined/couple-man-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-man-woman</title><path d=\"M17.5 3.232A3.1 3.1 0 0014.854.019a2.833 2.833 0 00-2.654 1.22.25.25 0 01-.406 0A2.829 2.829 0 009.146.019 3.1 3.1 0 006.5 3.232c0 2.427 3.423 5.311 4.9 6.439a.981.981 0 001.208 0C14.076 8.544 17.5 5.659 17.5 3.232zM8.366 18.38a.251.251 0 01-.033-.425 4.4 4.4 0 10-5.166 0 .251.251 0 01-.033.425A5.755 5.755 0 000 23.5a.5.5 0 00.5.5H11a.5.5 0 00.5-.5 5.755 5.755 0 00-3.134-5.12zM5.75 16.8a2.4 2.4 0 01-2.276-3.155.255.255 0 01.154-.157.251.251 0 01.218.026 5.616 5.616 0 003.037.888 5.7 5.7 0 00.966-.083.247.247 0 01.211.061.25.25 0 01.081.2A2.4 2.4 0 015.75 16.8zM20.8 18.669a.5.5 0 00-.629.21l-1.208 2.1a.251.251 0 01-.434 0l-1.208-2.1a.5.5 0 00-.629-.21A5.258 5.258 0 0013.5 23.5a.5.5 0 00.5.5h9.5a.5.5 0 00.5-.5 5.258 5.258 0 00-3.2-4.831z\"/><path d=\"M14.5 14.233a3.068 3.068 0 01-.7 2.352 1 1 0 101.4 1.422 3.959 3.959 0 00.418-.488.256.256 0 01.173-.1.252.252 0 01.193.059 4.24 4.24 0 005.526 0 .247.247 0 01.193-.058.251.251 0 01.173.1 3.959 3.959 0 00.418.488 1 1 0 101.406-1.423 3.068 3.068 0 01-.7-2.352 4.25 4.25 0 00-8.5 0zm2.223.027a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.212-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});