define("ember-svg-jar/inlined/landmark-berlin-cathedral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-berlin-cathedral</title><path d=\"M1 8h5a.5.5 0 00.384-.82L4.616 5.058a.5.5 0 01-.116-.32V4.5A.5.5 0 015 4h.25a1 1 0 000-2H5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5A.5.5 0 012 2h-.25a1 1 0 000 2H2a.5.5 0 01.5.5v.238a.5.5 0 01-.116.32L.616 7.18A.5.5 0 001 8zM18 8h5a.5.5 0 00.384-.82l-1.768-2.122a.5.5 0 01-.116-.32V4.5A.5.5 0 0122 4h.25a1 1 0 000-2H22a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5h-.25a1 1 0 000 2H19a.5.5 0 01.5.5v.238a.5.5 0 01-.116.32L17.616 7.18A.5.5 0 0018 8zM12.819 6.3a1.039 1.039 0 00-1.638 0l-2.91 4.157a1.492 1.492 0 00-.271.86V23.5a.5.5 0 00.5.5H10a.5.5 0 00.5-.5V21a1.5 1.5 0 013 0v2.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V11.315a1.492 1.492 0 00-.271-.86zM12 14.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6 9.5H1a.5.5 0 00-.5.5v12.5A1.5 1.5 0 002 24h4a.5.5 0 00.5-.5V10a.5.5 0 00-.5-.5zM4.5 15a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-1.5a1 1 0 012 0zM23 9.5h-5a.5.5 0 00-.5.5v13.5a.5.5 0 00.5.5h4a1.5 1.5 0 001.5-1.5V10a.5.5 0 00-.5-.5zM21.5 15a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1.5a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});