define("ember-svg-jar/inlined/drone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-1</title><path d=\"M6 3.25a1 1 0 000-2H1a1 1 0 000 2zM3.5 3.75a1 1 0 00-1 1v.065a1.75 1.75 0 00-.75 1.435.75.75 0 00.75.75h2a.75.75 0 00.75-.75 1.75 1.75 0 00-.75-1.435V4.75a1 1 0 00-1-1zM24 2.25a1 1 0 00-1-1h-5a1 1 0 000 2h5a1 1 0 001-1zM20.5 3.75a1 1 0 00-1 1v.065a1.75 1.75 0 00-.75 1.435.75.75 0 00.75.75h2a.75.75 0 00.75-.75 1.75 1.75 0 00-.75-1.435V4.75a1 1 0 00-1-1zM21.565 8.25h-3.348c-2.511 0-3.407-2-6.217-2s-3.707 2-6.218 2H2.435a1.5 1.5 0 000 3s2.478-.024 3.565-.014a.5.5 0 01.5.5v1.379a.248.248 0 01-.095.2A7.866 7.866 0 003.5 19.75a2 2 0 002 2h.775a.25.25 0 01.245.2 1 1 0 001.98-.2v-2a1 1 0 00-1.98-.2.25.25 0 01-.245.2h-.293a.471.471 0 01-.468-.5 5.913 5.913 0 011.414-3.7.25.25 0 01.369-.007 2.056 2.056 0 001.453.7h6.45a2.183 2.183 0 001.489-.721.249.249 0 01.184-.075.254.254 0 01.181.083 5.9 5.9 0 011.432 3.717.47.47 0 01-.468.5h-.293a.25.25 0 01-.245-.2 1 1 0 00-1.98.2v2a1 1 0 001.98.2.25.25 0 01.245-.2h.775a2 2 0 002-2 7.87 7.87 0 00-2.9-6.437.248.248 0 01-.095-.2v-1.374a.5.5 0 01.5-.5c1.091-.01 3.569.014 3.569.014a1.5 1.5 0 000-3zm-6.065 5.5a.5.5 0 01-.5.5H9a.5.5 0 01-.5-.5v-1.23a.25.25 0 01.372-.218A5.968 5.968 0 0012 13.25a5.971 5.971 0 003.128-.948.249.249 0 01.248 0 .247.247 0 01.124.215z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});