define("ember-svg-jar/inlined/stripe-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stripe</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#666EE8\" fill-rule=\"nonzero\" width=\"750\" height=\"471\" rx=\"40\"/><path d=\"M636 238.23c0-37.12-17.98-66.41-52.345-66.41-34.51 0-55.39 29.29-55.39 66.12 0 43.645 24.65 65.685 60.03 65.685 17.255 0 30.305-3.915 40.165-9.425v-29c-9.86 4.93-21.17 7.975-35.525 7.975-14.065 0-26.535-4.93-28.13-22.04h70.905c0-1.885.29-9.425.29-12.905zm-71.63-13.775c0-16.385 10.005-23.2 19.14-23.2 8.845 0 18.27 6.815 18.27 23.2h-37.41zM472.295 171.82c-14.21 0-23.345 6.67-28.42 11.31l-1.885-8.99h-31.9v169.07l36.25-7.685.145-41.035c5.22 3.77 12.905 9.135 25.665 9.135 25.955 0 49.59-20.88 49.59-66.845-.145-42.05-24.07-64.96-49.445-64.96zm-8.7 99.905c-8.555 0-13.63-3.045-17.11-6.815l-.145-53.795c3.77-4.205 8.99-7.105 17.255-7.105 13.195 0 22.33 14.79 22.33 33.785 0 19.43-8.99 33.93-22.33 33.93zM360.21 163.265l36.395-7.83V126l-36.395 7.685zM360.21 174.285h36.395V301.16H360.21zM321.205 185.015l-2.32-10.73h-31.32V301.16h36.25v-85.985c8.555-11.165 23.055-9.135 27.55-7.54v-33.35c-4.64-1.74-21.605-4.93-30.16 10.73zM248.705 142.82l-35.38 7.54-.145 116.145c0 21.46 16.095 37.265 37.555 37.265 11.89 0 20.59-2.175 25.375-4.785V269.55c-4.64 1.885-27.55 8.555-27.55-12.905V205.17h27.55v-30.885h-27.55l.145-31.465zM150.685 211.115c0-5.655 4.64-7.83 12.325-7.83 11.02 0 24.94 3.335 35.96 9.28V178.49c-12.035-4.785-23.925-6.67-35.96-6.67-29.435 0-49.01 15.37-49.01 41.035 0 40.02 55.1 33.64 55.1 50.895 0 6.67-5.8 8.845-13.92 8.845-12.035 0-27.405-4.93-39.585-11.6v34.51c13.485 5.8 27.115 8.265 39.585 8.265 30.16 0 50.895-14.935 50.895-40.89-.145-43.21-55.39-35.525-55.39-51.765z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "750",
      "height": "471",
      "viewBox": "0 0 750 471",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});