define("ember-svg-jar/inlined/gaming-second-place", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-second-place</title><path d=\"M15.052 11.329a.25.25 0 00-.336.234v1.329a.251.251 0 00.336.234.927.927 0 000-1.8z\"/><path d=\"M23.511 10.254a1.566 1.566 0 00.369-1.74A1.632 1.632 0 0022.357 7.5h-4.881a1 1 0 01-.89-.543L13.464.885a1.653 1.653 0 00-2.928 0L7.414 6.957a1 1 0 01-.89.543H1.643A1.633 1.633 0 00.12 8.514a1.564 1.564 0 00.369 1.739L4.7 14.367a1 1 0 01.247 1.04l-2.21 6.468a1.566 1.566 0 00.471 1.725 1.656 1.656 0 001.846.217l6.488-3.347a.994.994 0 01.916 0l6.488 3.346a1.653 1.653 0 001.846-.216 1.566 1.566 0 00.471-1.722l-2.213-6.471a1 1 0 01.248-1.04zm-13.572 4.8a.249.249 0 00.227.145H11a.75.75 0 010 1.5H8a.75.75 0 01-.571-1.236l2.613-3.063a.628.628 0 00.208-.447.7.7 0 00-1.37-.223.749.749 0 11-1.422-.474 2.2 2.2 0 014.292.7 2.084 2.084 0 01-.607 1.465l-1.167 1.37a.248.248 0 00-.037.264zm5.185-.408a.5.5 0 00-.408.491v.812a.75.75 0 01-1.5 0V10.5a.75.75 0 01.75-.75h.634a2.571 2.571 0 012.65 2.478 2.522 2.522 0 01-2.126 2.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});