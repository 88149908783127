define("ember-svg-jar/inlined/shipment-smartphone-arrive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-smartphone-arrive</title><path d=\"M2.9 7.3a1 1 0 001.2-1.6l-2-1.5A1 1 0 10.9 5.8zM2.9 12.7l-2 1.5a1 1 0 101.2 1.6l2-1.5a1 1 0 00-1.2-1.6zM20.5 7.5a.994.994 0 00.6-.2l2-1.5a1 1 0 00-1.2-1.6l-2 1.5a1 1 0 00.6 1.8zM21.1 12.7a1 1 0 00-1.2 1.6l2 1.5a1 1 0 101.2-1.6zM3.5 11a1 1 0 000-2H1a1 1 0 000 2zM23 9h-2.5a1 1 0 000 2H23a1 1 0 000-2zM16.8 6.46a.251.251 0 000-.46l-4.415-1.84a1.007 1.007 0 00-.77 0L7.2 6a.25.25 0 000 .462l4.6 1.918a.492.492 0 00.384 0zM6.346 7.186A.25.25 0 006 7.415V13.3a1 1 0 00.611.921l4.542 1.921a.25.25 0 00.347-.231V9.5a.251.251 0 00-.154-.231zM12.5 15.917a.25.25 0 00.346.231l4.539-1.892a1 1 0 00.615-.923V7.415a.249.249 0 00-.346-.229l-5 2.083a.251.251 0 00-.154.231zM5.5 4a1 1 0 001-1 1 1 0 011-1h9a1 1 0 011 1 1 1 0 002 0 3 3 0 00-3-3h-9a3 3 0 00-3 3 1 1 0 001 1zM18.5 16.5a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-.5a1 1 0 00-2 0V21a3 3 0 003 3h9a3 3 0 003-3v-3.5a1 1 0 00-1-1zM11 21a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});