define("ember-svg-jar/inlined/app-window-pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-pie-chart</title><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/><path d=\"M14.042 9.174a.249.249 0 00.033-.212.246.246 0 00-.143-.16 4.879 4.879 0 00-1.882-.384 4.983 4.983 0 00-4.869 3.947.246.246 0 00.05.209.249.249 0 00.194.094h4.1a.252.252 0 00.207-.109zM7.425 14.168a.248.248 0 00-.194.093.246.246 0 00-.05.209 4.984 4.984 0 004.869 3.948 4.92 4.92 0 002.317-.587.25.25 0 00.074-.382l-2.666-3.192a.25.25 0 00-.192-.089zM15.589 16.486a.25.25 0 00.389-.006 4.932 4.932 0 00-.309-6.493.249.249 0 00-.2-.077.253.253 0 00-.187.108l-2.185 3.2a.251.251 0 00.014.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});