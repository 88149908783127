define("ember-svg-jar/inlined/bookmarks-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bookmarks-2</title><path d=\"M20 6.5a1 1 0 001-1V4a4 4 0 00-4-4H7a4 4 0 00-4 4v18.484a1.407 1.407 0 00.825 1.421 1.407 1.407 0 001.589-.421l3.232-3.231a.5.5 0 01.708 0l3.232 3.231a1.549 1.549 0 001.107.516 1.274 1.274 0 00.482-.095A1.407 1.407 0 0015 22.484V4.11a2.079 2.079 0 011.662-2.084A2 2 0 0119 4v1.5a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});