define("ember-svg-jar/inlined/delivery-person-motorcycle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-person-motorcycle</title><path d=\"M13.125 7a3.5 3.5 0 003.5-3.5 2.039 2.039 0 00-.013-.223.251.251 0 01.249-.277h.764a1 1 0 000-2h-1.958A.253.253 0 0115.5.934 3.5 3.5 0 1013.125 7zm0-2a1.5 1.5 0 01-1.5-1.5 1.391 1.391 0 01.035-.3.25.25 0 01.24-.2h2.442a.25.25 0 01.244.195 1.391 1.391 0 01.035.305A1.5 1.5 0 0113.125 5z\"/><rect x=\".125\" y=\"7.5\" width=\"8\" height=\"7\" rx=\"1\" ry=\"1\"/><path d=\"M13.625 7.25a3.254 3.254 0 00-3.25 3.25v3a1.25 1.25 0 001.25 1.25h2.418a.251.251 0 01.238.171l1.158 3.479a1.251 1.251 0 001.186.854 1.271 1.271 0 00.4-.064 1.251 1.251 0 00.79-1.582l-1.5-4.5a1.25 1.25 0 00-1.186-.854h-2a.25.25 0 01-.25-.25V10.5a.75.75 0 01.361-.641.25.25 0 01.252-.005l4.03 2.239a1.25 1.25 0 001.214-2.186l-4.5-2.5a1.251 1.251 0 00-.611-.157z\"/><circle cx=\"7.625\" cy=\"21.5\" r=\"2.5\"/><path d=\"M19.625 24a2.25 2.25 0 002.415-1.438.25.25 0 00-.241-.315h-4.348a.25.25 0 00-.241.315A2.25 2.25 0 0019.625 24zM5.875 15.5a3.755 3.755 0 00-3.75 3.75v1.25a1 1 0 002 0v-1.25a1.752 1.752 0 011.75-1.75h3.75a1.5 1.5 0 011.5 1.5v.5a1.993 1.993 0 00.984 1.712.251.251 0 00.128.035h10.9a.749.749 0 00.742-.748 3.11 3.11 0 00-2.559-3.1.251.251 0 01-.191-.243V15.9a.25.25 0 01.362-.223l.914.457a.5.5 0 00.724-.447v-1.878a.5.5 0 00-.724-.447l-.914.457a.25.25 0 01-.362-.223V13a1 1 0 00-2 0v6.25a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25V19a3.385 3.385 0 00-.252-1.271.25.25 0 01.137-.324.976.976 0 00.615-.905 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});