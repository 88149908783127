define("ember-svg-jar/inlined/analytics-pie-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-pie-2</title><path d=\"M23 10.5a.5.5 0 00.5-.5 10.012 10.012 0 00-10-10 .5.5 0 00-.5.5v9.025a.977.977 0 00.975.976zM23.5 12H14a.677.677 0 00-.5 1.207l6.718 6.718a.5.5 0 00.707 0A10.433 10.433 0 0024 12.5a.5.5 0 00-.5-.5z\"/><path d=\"M10.588 24a10.322 10.322 0 007.337-3.084.5.5 0 000-.707l-6.718-6.718a.91.91 0 01-.207-.5v-9.5a.5.5 0 00-.5-.5A10.5 10.5 0 1010.588 24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});