define("ember-svg-jar/inlined/image-file-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-file-landscape</title><path d=\"M21.414 4.914L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.328a2 2 0 00-.586-1.414zM9 7.5a2 2 0 11-2 2 2 2 0 012-2zm9.767 11.871a.249.249 0 01-.219.129H6.45a.25.25 0 01-.212-.383l2.775-4.439a.5.5 0 01.848 0l.864 1.382a.251.251 0 00.424 0l2.427-3.882a.5.5 0 01.848 0l4.336 6.939a.249.249 0 01.007.254z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});