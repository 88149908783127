define("ember-svg-jar/inlined/hospital-bedroom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-bedroom</title><circle cx=\"6\" cy=\"15.25\" r=\"2.5\"/><path d=\"M23 14.75a1 1 0 00-1 1V19a.25.25 0 01-.25.25H2.25A.25.25 0 012 19v-5.75a1 1 0 00-2 0V23a1 1 0 002 0v-.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v.5a1 1 0 002 0v-7.25a1 1 0 00-1-1z\"/><path d=\"M10 14.75v2.5a.5.5 0 00.5.5H20a.5.5 0 00.5-.5v-1a2.5 2.5 0 00-2.5-2.5h-7a1 1 0 00-1 1zM7.75 6.75h2.5a.25.25 0 01.25.25v2.5a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5V7a.25.25 0 01.25-.25h2.5a.5.5 0 00.5-.5v-2.5a.5.5 0 00-.5-.5h-2.5A.25.25 0 0114 3V.5a.5.5 0 00-.5-.5H11a.5.5 0 00-.5.5V3a.25.25 0 01-.25.25h-2.5a.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});