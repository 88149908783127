define("ember-svg-jar/inlined/shipment-fragile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-fragile</title><path d=\"M23.5 1.5a1 1 0 00-1-1h-6.75a.25.25 0 00-.25.25v7.794a.5.5 0 01-.743.437l-2.514-1.4a.5.5 0 00-.486 0l-2.514 1.4a.5.5 0 01-.743-.437V.75A.25.25 0 008.25.5H1.5a1 1 0 00-1 1v21a1 1 0 001 1h21a1 1 0 001-1zm-9.27 18.25a.75.75 0 110 1.5h-2.256a.75.75 0 010-1.5h.064a.25.25 0 00.25-.25v-1.263a.248.248 0 00-.155-.231 2.252 2.252 0 01-1.4-2.121 10.176 10.176 0 01.449-2.469.576.576 0 01.552-.416h2.609a.577.577 0 01.554.416 10.176 10.176 0 01.449 2.469 2.253 2.253 0 01-1.4 2.121.249.249 0 00-.155.231V19.5a.25.25 0 00.25.25zm7.962-4.25a.5.5 0 01-.462.309H21a.25.25 0 00-.25.25V20.5a.75.75 0 01-1.5 0v-4.442a.25.25 0 00-.25-.25h-.73a.5.5 0 01-.354-.854l1.73-1.73a.5.5 0 01.708 0l1.73 1.73a.5.5 0 01.108.546z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});