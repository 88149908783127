define("ember-svg-jar/inlined/task-list-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-multiple</title><path d=\"M23.5 9a3 3 0 00-3-3h-.26a.249.249 0 01-.249-.231A3 3 0 0017 3h-.26a.249.249 0 01-.249-.231A3 3 0 0013.5 0h-10a3 3 0 00-3 3v12a3 3 0 003 3h.26a.25.25 0 01.249.231A3 3 0 007 21h.26a.251.251 0 01.249.231A3 3 0 0010.5 24h10a3 3 0 003-3zm-20 7a1 1 0 01-1-1V3a1 1 0 011-1h10a1 1 0 01.943.667.25.25 0 01-.236.333H7a3 3 0 00-3 3v9.75a.249.249 0 01-.25.25zM7 19a1 1 0 01-1-1V6a1 1 0 011-1h10a1 1 0 01.943.667.25.25 0 01-.236.333H10.5a3 3 0 00-3 3v9.75a.249.249 0 01-.25.25zm14.5 2a1 1 0 01-1 1h-10a1 1 0 01-1-1V9a1 1 0 011-1h10a1 1 0 011 1z\"/><path d=\"M15.219 9.663a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.181.094.251.251 0 01-.191-.073l-.21-.21a.75.75 0 10-1.06 1.061l1 1a.759.759 0 001.116-.061l2-2.5a.751.751 0 00-.119-1.056zM19.25 11.5h-2a.75.75 0 000 1.5h2a.75.75 0 000-1.5zM15.219 15.163a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.181.094.251.251 0 01-.191-.073l-.21-.21a.75.75 0 10-1.06 1.061l1 1a.759.759 0 001.116-.061l2-2.5a.751.751 0 00-.119-1.056zM20 17.749a.75.75 0 00-.75-.75h-2a.75.75 0 000 1.5h2a.75.75 0 00.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});