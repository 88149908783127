define("ember-svg-jar/inlined/laptop-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-edit</title><path d=\"M11.745 17.427l3.51-3.51a.25.25 0 00-.177-.426h-.646a.5.5 0 00-.48.36c-.28.94-1.62 1.65-2.95 1.65a3.082 3.082 0 01-2.95-1.65.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31l6.526.01a.252.252 0 00.179-.074zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v7.126a.25.25 0 00.4.2 4.106 4.106 0 011.413-.682.25.25 0 00.187-.244V3a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM12.74 20.047a.5.5 0 00-.834.215l-.886 3.1a.5.5 0 00.48.638.459.459 0 00.137-.02l3.1-.886a.5.5 0 00.216-.834zM18.772 13.229a.5.5 0 00-.707 0l-5.011 5.011a.5.5 0 000 .707l3 3a.5.5 0 00.708 0l5.01-5.011a.5.5 0 000-.707zM23.233 11.768a2.643 2.643 0 00-3.754.047.5.5 0 000 .707l3 3a.457.457 0 00.328.125.612.612 0 00.426-.172 2.624 2.624 0 000-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});