define("ember-svg-jar/inlined/charger-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>charger-1</title><path d=\"M20 6h-2.25a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v4.25a.25.25 0 01-.25.25h-4.5a.25.25 0 01-.25-.25V1.5a1.5 1.5 0 00-3 0v4.25a.25.25 0 01-.25.25H4a1.5 1.5 0 000 3h16a1.5 1.5 0 100-3zM18 10.5H6a.5.5 0 00-.5.5v2.5a4.488 4.488 0 003.789 4.42.25.25 0 01.211.247V19.5a1.5 1.5 0 001.286 1.484.25.25 0 01.214.248V23a1 1 0 102 0v-1.767a.251.251 0 01.214-.248A1.5 1.5 0 0014.5 19.5v-1.332a.25.25 0 01.211-.247A4.488 4.488 0 0018.5 13.5V11a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});