define("ember-svg-jar/inlined/blood-drop-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>blood-drop-search</title><path d=\"M14.61 12.234a6.979 6.979 0 01-4.552-9.459.5.5 0 00-.026-.445A20.433 20.433 0 008.819.393a1.093 1.093 0 00-1.638 0C5.705 2.328 0 13.3 0 16.5 0 20.425 3.813 24 8 24s8-3.575 8-7.5a13.434 13.434 0 00-1.057-3.963.5.5 0 00-.333-.303zm-6.879 8.821a.749.749 0 01-.9.565A5.233 5.233 0 013.3 18.834a.75.75 0 111.343-.668 3.736 3.736 0 002.528 1.991.75.75 0 01.56.898z\"/><path d=\"M23.632 11.12l-2.388-2.4a.5.5 0 01-.086-.591 5.454 5.454 0 00.654-2.6 5.53 5.53 0 10-5.53 5.53 5.458 5.458 0 002.6-.654.5.5 0 01.59.086l2.4 2.388a1.23 1.23 0 001.759 0 1.236 1.236 0 00.001-1.759zm-7.35-2.06a3.53 3.53 0 113.53-3.53 3.537 3.537 0 01-3.53 3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});