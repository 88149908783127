define("ember-svg-jar/inlined/road-sign-light-guide-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-light-guide-alternate</title><path d=\"M16.5 0h-9a.75.75 0 00-.75.75v22.5a.754.754 0 00.75.75h9a.754.754 0 00.75-.75V.75A.75.75 0 0016.5 0zM8.25 1.75a.25.25 0 01.25-.25h1.586a.25.25 0 01.177.427L8.677 3.513a.25.25 0 01-.427-.177zm6.067 20.6a.251.251 0 01-.231.154H9.914a.25.25 0 01-.177-.427l2.086-2.086a.252.252 0 01.354 0l2.086 2.086a.253.253 0 01.054.269zm1.433-1.51a.25.25 0 01-.427.177l-2.439-2.44a1.251 1.251 0 00-1.768 0l-2.439 2.44a.25.25 0 01-.427-.177v-3.176a.251.251 0 01.073-.177l3.5-3.5a.252.252 0 01.354 0l3.5 3.5a.251.251 0 01.073.177zm0-6a.25.25 0 01-.427.177l-2.439-2.44a1.251 1.251 0 00-1.768 0l-2.439 2.44a.25.25 0 01-.427-.177v-2.923a.248.248 0 01.074-.177l3.5-3.464a.249.249 0 01.352 0l3.5 3.464a.248.248 0 01.074.177zm0-5.732a.25.25 0 01-.426.177l-2.44-2.415a1.251 1.251 0 00-1.765 0L8.676 9.281A.25.25 0 018.25 9.1V6.164a.251.251 0 01.073-.177l3.5-3.5a.252.252 0 01.354 0l3.5 3.5a.251.251 0 01.073.177zm0-5.768a.25.25 0 01-.427.177l-1.586-1.59a.25.25 0 01.177-.427H15.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});