define("ember-svg-jar/inlined/arrow-thick-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-right</title><path d=\"M23.606 11.278l-8.8-8.771a1 1 0 00-1.706.708V8.24a.25.25 0 01-.25.25H1.1a1 1 0 00-1 1v5a1 1 0 001 1h11.75a.25.25 0 01.25.25v5.043a1 1 0 001.707.707l8.8-8.8a1 1 0 000-1.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});