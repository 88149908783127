define("ember-svg-jar/inlined/pollution-co2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-co2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M15.25 8.125a2.375 2.375 0 00-4.75 0v3.25a2.375 2.375 0 004.75 0zm-1.5 3.25a.875.875 0 01-1.75 0v-3.25a.875.875 0 011.75 0zM8.75 7.25a.75.75 0 000-1.5 4 4 0 000 8 .75.75 0 000-1.5 2.5 2.5 0 010-5zM18.421 15.44a1.792 1.792 0 00.53-1.278 1.912 1.912 0 00-3.726-.6.75.75 0 001.423.474.412.412 0 01.8.13.309.309 0 01-.09.217l-2.09 2.091a.75.75 0 00.53 1.28h2.4a.75.75 0 000-1.5.245.245 0 01-.173-.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});