define("ember-svg-jar/inlined/credit-card-scan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-scan</title><path d=\"M23.264 7.481a.25.25 0 00.447-.152A9.967 9.967 0 0021.453.454a1.492 1.492 0 00-2.1-.039 1.508 1.508 0 00-.067 2.113 5.529 5.529 0 011.027 1.949.238.238 0 00.08.117 18.234 18.234 0 012.871 2.887zM13.827 2.442a1.5 1.5 0 00-1.9.934 1.551 1.551 0 00.935 1.9c2.256.993 5.871 3.148 6.059 6.806.153 3-1.65 5.8-3.917 5.911h-.049a16.978 16.978 0 01-4.614-1.583 1.5 1.5 0 10-1.291 2.708A55.081 55.081 0 0114.5 22a2.627 2.627 0 01.976 1.787.251.251 0 00.248.213h8.026a.25.25 0 00.25-.25V13c-.265-5.174-7.269-9.566-10.173-10.558z\"/><path d=\"M13 7.5a1 1 0 00-1-1H1a1 1 0 00-1 1V8a.25.25 0 00.25.25h12.5A.25.25 0 0013 8zM12 15.5a1 1 0 001-1V10a.25.25 0 00-.25-.25H.25A.25.25 0 000 10v4.5a1 1 0 001 1zm-7-2H2.5a.5.5 0 010-1H5a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});