define("ember-svg-jar/inlined/style-three-pin-charging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-charging</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.585 14.469l-4 5a.75.75 0 01-.585.281.76.76 0 01-.248-.042.751.751 0 01-.5-.708v-2.75a.5.5 0 00-.5-.5H9a.75.75 0 01-.585-1.219l4-5A.75.75 0 0113.75 10v2.75a.5.5 0 00.5.5H15a.75.75 0 01.585 1.219zM15.75 6h-3.013a.5.5 0 00-.462.307 2.738 2.738 0 01-4.675.662.75.75 0 011.171-.938 1.25 1.25 0 100-1.562.75.75 0 01-1.171-.938 2.738 2.738 0 014.671.662.5.5 0 00.462.307h3.017a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});