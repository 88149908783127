define("ember-svg-jar/inlined/phone-action-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-music</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M14.132 6.034c-.041.008-3.572 1.14-3.572 1.14A2 2 0 009 9.125v3.2a1.747 1.747 0 10.546 3.45A1.819 1.819 0 0011 13.947V9.309a.244.244 0 01.17-.237c.549-.173 2.284-.728 3.007-.96a.247.247 0 01.322.239v1.97a1.747 1.747 0 10.546 3.45 1.819 1.819 0 001.455-1.824V8a2 2 0 00-2.368-1.966z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});