define("ember-svg-jar/inlined/safety-extinguish-fire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>safety-extinguish-fire</title><path d=\"M6 2h12a1 1 0 000-2H6a1 1 0 000 2zM12.174 12.06a.527.527 0 00-.712.71 4.826 4.826 0 01.279 3.645.528.528 0 01-.879.2 2.392 2.392 0 01-.5-.74.495.495 0 00-.681-.252 4.111 4.111 0 00-2.4 3.781 4.721 4.721 0 009.438 0 8.451 8.451 0 00-4.545-7.344z\"/><circle cx=\"12\" cy=\"4.5\" r=\"1.5\"/><circle cx=\"12\" cy=\"8.5\" r=\"1.5\"/><path d=\"M17.031 3.1a1.5 1.5 0 10.836.786 1.494 1.494 0 00-.836-.786zM19.513 7.529a1.5 1.5 0 10-1.365 2.118 1.484 1.484 0 00.616-.133 1.5 1.5 0 00.749-1.985zM21.2 12.322a1.5 1.5 0 10-.786.837 1.491 1.491 0 00.786-.837zM22.805 14.82a1.5 1.5 0 10-.75 1.985 1.5 1.5 0 00.75-1.985zM8.117 3.133a1.5 1.5 0 00-2.02 1.9A1.505 1.505 0 007.5 6a1.507 1.507 0 00.531-.1 1.5 1.5 0 00.087-2.77zM6.471 6.779a1.5 1.5 0 00-1.984.749 1.5 1.5 0 00.75 1.984 1.479 1.479 0 00.615.133 1.5 1.5 0 00.619-2.867zM4.825 10.425a1.5 1.5 0 10.786.836 1.5 1.5 0 00-.786-.836zM3.18 14.07a1.5 1.5 0 00-1.985.75 1.5 1.5 0 101.985-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});