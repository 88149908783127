define("ember-svg-jar/inlined/skating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skating</title><path d=\"M17.441 16.956a5.466 5.466 0 00-2.123-4.341l-1.161-.9L16.641 8.4a1.5 1.5 0 00-1.2-2.4h-9a1.5 1.5 0 100 3h5.5a.25.25 0 01.2.4l-4.473 5.965a.25.25 0 00.047.348L9.7 17.238a.249.249 0 00.352-.048l2.308-3.077 1.119.87a2.486 2.486 0 01.965 1.973V18a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25zM5.774 17.889a.25.25 0 00.048.348L7.8 19.763a.252.252 0 00.353-.048l.993-1.325a.25.25 0 00-.047-.348L7.12 16.517a.249.249 0 00-.352.048z\"/><rect x=\"14.441\" y=\"19.25\" width=\"3\" height=\"1.75\" rx=\".25\" ry=\".25\"/><circle cx=\"18.441\" cy=\"2.5\" r=\"2.5\"/><path d=\"M20.191 20a1 1 0 000 2h-7.75a1 1 0 000 2h7.75a2 2 0 100-4zM3.428 17.528A1 1 0 102.189 19.1l5.385 4.247a2 2 0 102.455-3.158A1 1 0 008.8 21.761z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});