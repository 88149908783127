define("ember-svg-jar/inlined/presentation-audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>presentation-audience</title><path d=\"M9.236 18.349a2.85 2.85 0 102.85-2.849 2.853 2.853 0 00-2.85 2.849zM4.694 21.2a2.85 2.85 0 10-2.85-2.851 2.853 2.853 0 002.85 2.851zM16.464 18.349a2.85 2.85 0 102.85-2.849 2.853 2.853 0 00-2.85 2.849z\"/><path d=\"M23.389 23.186a5.155 5.155 0 00-4.033-1.931 5.158 5.158 0 00-3.487 1.345.249.249 0 01-.333 0 5.321 5.321 0 00-7.071 0 .25.25 0 01-.334 0 5.157 5.157 0 00-3.486-1.346 5.155 5.155 0 00-4.034 1.932A.5.5 0 001 24h22a.5.5 0 00.389-.814zM8.644 9.5h6.723a.5.5 0 00.48-.637 3.913 3.913 0 00-2.53-2.649 3.248 3.248 0 10-2.62.009 4.025 4.025 0 00-2.533 2.64.5.5 0 00.479.641zM5 12a1 1 0 001 1h.478a.249.249 0 01.245.2l.3 1.495A1 1 0 108.98 14.3l-.2-1a.248.248 0 01.245-.3h5.95a.248.248 0 01.245.3l-.2 1a1 1 0 101.96.393l.3-1.495a.249.249 0 01.245-.2H18a1 1 0 000-2H6A1 1 0 005 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});