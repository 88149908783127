define("ember-svg-jar/inlined/fitness-bicycle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-bicycle-1</title><circle cx=\"7.5\" cy=\"17\" r=\"1.25\"/><path d=\"M18.329 8.071A1.774 1.774 0 0119.161 8H20a4 4 0 000-8h-4.5a1 1 0 000 2H20a2 2 0 010 4h-.8a2.729 2.729 0 00-2.5.9 1.079 1.079 0 00-.084.134l-4.026 7.612A.313.313 0 0112 14.5v-2a.5.5 0 00-.5-.5H9.841a1 1 0 01-.744-.331L6.171 8.417A.25.25 0 016.356 8h.894a1.251 1.251 0 00.356-2.448C2.415 4 2.1 4 2 4a2 2 0 000 4h.659a1 1 0 01.744.331l2.83 3.145A.314.314 0 016 12a5 5 0 00-2.572 9.28.5.5 0 01.1.783l-.23.23a1 1 0 001.414 1.414l1.414-1.414A1 1 0 016.828 22h9.922a2.049 2.049 0 00.453-.053.5.5 0 01.465.135l1.625 1.625a1 1 0 001.414-1.414l-1.626-1.626a.5.5 0 01-.134-.465A2.033 2.033 0 0019 19.75a2.227 2.227 0 00-1.856-2.21l-2.712-.83a.5.5 0 01-.3-.711zM11.03 20.53a.749.749 0 01-1.06 0l-.887-.886a.5.5 0 00-.548-.108 2.646 2.646 0 01-1.035.214A2.75 2.75 0 1110.25 17a2.646 2.646 0 01-.214 1.035.5.5 0 00.108.548l.886.887a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});