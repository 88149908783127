define("ember-svg-jar/inlined/bandage-leg-hanging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-leg-hanging</title><path d=\"M22.76 14a1.506 1.506 0 00-1.45 1.11l-1.08 3.96a.5.5 0 01-.52.37l-4.78-.36-1.71.37a4.093 4.093 0 01-.85.09 3.982 3.982 0 01-2.31-.748.5.5 0 00-.254-.093l-2.324-.172a.476.476 0 00-.18.02L1.07 20.42a2.239 2.239 0 01-.57.08.5.5 0 00-.5.5v2.5a.5.5 0 00.5.5h21a2.509 2.509 0 002.5-2.5v-6.26A1.24 1.24 0 0022.76 14zM.5 19a.784.784 0 00.14-.02l7.443-2.236a.5.5 0 00.349-.56 4.026 4.026 0 01-.052-.644V9.573a.5.5 0 00-.623-.485L.37 10.96a.5.5 0 00-.37.49v7.05a.479.479 0 00.2.4.453.453 0 00.3.1zM17.161 4.967l.015.005 5.141 1.58a.5.5 0 00.629-.612l-.665-2.39a2.75 2.75 0 00-5.374 1.133.349.349 0 00.254.284zM17.4 6.623a.181.181 0 00-.138.215L18.605 13a.5.5 0 00.633.372l2.962-.892a2.512 2.512 0 001.71-3.07l-.167-.6a.5.5 0 00-.334-.343l-6-1.848z\"/><path d=\"M12.375 18.041a2.568 2.568 0 00.528-.056l2.616-.561a2.5 2.5 0 001.918-2.979L14.367.394A.5.5 0 0013.878 0h-3.5a.5.5 0 00-.5.5v15.04a2.5 2.5 0 002.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});