define("ember-svg-jar/inlined/vectors-anchor-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vectors-anchor-square</title><path d=\"M22 7a2 2 0 002-2V2a2 2 0 00-2-2h-3a2 2 0 00-2 2v.25a.25.25 0 01-.25.25h-9.5A.25.25 0 017 2.25V2a2 2 0 00-2-2H2a2 2 0 00-2 2v3a2 2 0 002 2h.25a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25H2a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V22a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2h-.25a.25.25 0 01-.25-.25v-9.5a.25.25 0 01.25-.25zm-3-4.75a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25v2.5a.249.249 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm-17 0A.25.25 0 012.25 2h2.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-2.5A.25.25 0 012 4.75zm3 19.5a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.249.249 0 01.25-.25h2.5a.25.25 0 01.25.25zm17 0a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zm-2.5-5a.25.25 0 01-.25.25H19a2 2 0 00-2 2v.25a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25V19a2 2 0 00-2-2h-.25a.25.25 0 01-.25-.25v-9.5A.25.25 0 014.75 7H5a2 2 0 002-2v-.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25V5a2 2 0 002 2h.25a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});