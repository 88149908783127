define("ember-svg-jar/inlined/wild-bird-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-2</title><path d=\"M21.476 10.662a1.43 1.43 0 00-2.092-.014 22.6 22.6 0 01-2.251 2.037.761.761 0 01-1.055-.118.75.75 0 01.118-1.054 20.379 20.379 0 002.2-2 1.624 1.624 0 000-2.2c-.126-.137-.295-.345-.509-.311a.5.5 0 00-.369.167l-1.307 1.464a4.252 4.252 0 00-3.6-2.133 4 4 0 00-2.994 1.336v-.222C9.65 4.145 9.67 1.853 8.54.712A2.571 2.571 0 006.608 0 5.744 5.744 0 002.7 1.663a1 1 0 00.187 1.582 96.498 96.498 0 002.909 1.646 1.5 1.5 0 01.788 1.314l.026 5.3a4.99 4.99 0 001.726 3.776l2.264 4.026a1.993 1.993 0 01.257.979V22a1 1 0 000 2h1a1 1 0 001-1v-2.714a3.994 3.994 0 00-.515-1.961l-1.032-1.834a9.84 9.84 0 002.7-.276l.848 1.8V22a1 1 0 000 2h1a1 1 0 001-1v-5.85l.224-2.131a15.03 15.03 0 004.418-3.706.5.5 0 00-.024-.651z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});