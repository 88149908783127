define("ember-svg-jar/inlined/lock-hierarchy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-hierarchy</title><path d=\"M8.75 3.25v1H8.5a1 1 0 00-1 1v5.25a1 1 0 001 1h7a1 1 0 001-1V5.25a1 1 0 00-1-1h-.25v-1a3.25 3.25 0 00-6.5 0zm3.25 6a1 1 0 111-1 1 1 0 01-1 1zm0-7.75a1.752 1.752 0 011.75 1.75V4a.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25v-.75A1.752 1.752 0 0112 1.5zM21.5 19.745v-4.5a1.5 1.5 0 00-1.5-1.5h-7v-.5a1 1 0 00-2 0v.5H4a1.5 1.5 0 00-1.5 1.5v4.5a2.25 2.25 0 102 0v-3.5a.5.5 0 01.5-.5h5.5a.5.5 0 01.5.5v3.5a2.25 2.25 0 102 0v-3.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v3.5a2.25 2.25 0 102 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});