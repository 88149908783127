define("ember-svg-jar/inlined/tags-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tags-add</title><path d=\"M5.422 19.5a1.972 1.972 0 001.406.582h.012a1.975 1.975 0 001.41-.6l1.217-1.241a.493.493 0 00.136-.367 7.976 7.976 0 018.2-8.363.486.486 0 00.363-.143l.939-.958A3.984 3.984 0 0020.086 6V2a2 2 0 00-2-2h-4.26a3.758 3.758 0 00-2.413 1L.586 11.834a2 2 0 000 2.828zm7.164-14a2 2 0 112 2 2 2 0 01-2-2z\"/><path d=\"M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm9-.75a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25H15a.75.75 0 110-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});