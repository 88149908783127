define("ember-svg-jar/inlined/family-grandmother", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-grandmother</title><path d=\"M21.75 14.473a2.253 2.253 0 00-2.25 2.25v6.25a1 1 0 002 0v-6.25c0-.275.5-.275.5 0v.25a1 1 0 002 0v-.25a2.253 2.253 0 00-2.25-2.25zM14.888 9.005a.5.5 0 00-.7.111L12.7 11.188a.249.249 0 01-.406 0l-1.48-2.072a.5.5 0 00-.7-.111 4 4 0 00-1.524 2.346l-1.578 7.013a.5.5 0 00.488.609h1.6a.5.5 0 01.5.45l.41 4.1a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.411-4.1a.5.5 0 01.5-.45h1.6a.5.5 0 00.488-.609l-1.599-7.013a4 4 0 00-1.522-2.346z\"/><path d=\"M9.02 8.611a5.781 5.781 0 00.6-.879.249.249 0 01.367-.081 4.249 4.249 0 005.024 0 .252.252 0 01.367.081 5.854 5.854 0 00.6.879 1 1 0 001.542-1.276 3.149 3.149 0 01-.77-1.98V4.223a4.25 4.25 0 00-8.5 0v1.132a3.144 3.144 0 01-.771 1.98A1 1 0 009.02 8.611zm1.453-4.378a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.916-.74.251.251 0 01.4 0 2.475 2.475 0 00.915.74 3.427 3.427 0 00.91.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.212-.278zM2.9 13.527A2.027 2.027 0 10.876 11.5 2.026 2.026 0 002.9 13.527zM2.9 13.973a2.9 2.9 0 00-2.9 2.9v1.759a.5.5 0 00.5.5H1a.5.5 0 01.5.45l.394 3.938a.5.5 0 00.5.45h1.025a.5.5 0 00.5-.45l.394-3.938a.5.5 0 01.5-.45h.5a.5.5 0 00.5-.5v-1.756A2.9 2.9 0 002.9 13.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});