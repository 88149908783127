define("ember-svg-jar/inlined/light-mode-night-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-night-woman</title><path d=\"M19.564.037a1 1 0 00-1.117 1.482 4.941 4.941 0 01.234 4.611 4.96 4.96 0 01-3.646 2.859.027.027 0 00-.02.035 8.123 8.123 0 01.525 2.136.25.25 0 00.153.2A6 6 0 1019.564.037zM5.975 19.825a.3.3 0 01.412-.4A5.652 5.652 0 008.995 20h.012a5.656 5.656 0 002.609-.571.3.3 0 01.411.4l-1.686 3.824a.25.25 0 00.229.347H17a1 1 0 00.912-1.41 6.929 6.929 0 00-3.741-3.58c-.8-.321-1.716-.651-2.7-1.016a.784.784 0 01-.511-.734v-.467a.249.249 0 01.254-.25 6.34 6.34 0 003.653-.834.5.5 0 00.125-.755c-.452-.527-.764-1.22-.9-3.251C13.827 7.854 11.232 6.5 9 6.5s-4.825 1.354-5.086 5.2c-.137 2.031-.45 2.724-.9 3.251a.5.5 0 00.124.755 6.343 6.343 0 003.654.834.251.251 0 01.254.25v.467a.784.784 0 01-.511.734c-.988.365-1.9.7-2.7 1.016A6.929 6.929 0 00.089 22.59 1 1 0 001 24h6.432a.249.249 0 00.229-.351z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});