define("ember-svg-jar/inlined/swimming-cap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-cap</title><path d=\"M13.95 18.1a.751.751 0 00-1.058.077 1.253 1.253 0 01-.928.341 1.282 1.282 0 01-.937-.341.75.75 0 10-1.134.981 2.744 2.744 0 002.071.86 2.7 2.7 0 002.063-.86.75.75 0 00-.077-1.058zM10.632 14.646a.752.752 0 00.264-1.028 1.822 1.822 0 00-1.646-.868 1.822 1.822 0 00-1.646.868.75.75 0 001.292.764c.021-.036.078-.132.354-.132s.333.1.354.132a.752.752 0 001.028.264zM14.75 12.75a1.822 1.822 0 00-1.646.868.75.75 0 001.292.764c.021-.036.078-.132.354-.132s.333.1.354.132a.75.75 0 001.292-.764 1.822 1.822 0 00-1.646-.868z\"/><path d=\"M19.961 6.277a.5.5 0 01.086-.617 2.964 2.964 0 00.99-2.16c0-1.962-2.093-3.5-4.765-3.5a5.474 5.474 0 00-3.8 1.361.5.5 0 01-.356.14h-.24a.5.5 0 01-.356-.14A5.474 5.474 0 007.728 0C5.056 0 2.963 1.538 2.963 3.5a2.964 2.964 0 00.99 2.16.5.5 0 01.086.617A11.238 11.238 0 002.5 12a11.1 11.1 0 002.406 6.976 4.013 4.013 0 01.446.686A7.377 7.377 0 0012 24a7.377 7.377 0 006.648-4.338 4.074 4.074 0 01.446-.686A11.1 11.1 0 0021.5 12a11.238 11.238 0 00-1.539-5.723zM12 22c-3.533 0-6-3.5-6-8.5a6 6 0 0112 0c0 5.005-2.467 8.5-6 8.5zm4.272-17a3.233 3.233 0 01-2.6-1.035.5.5 0 00-.195-.17 4.032 4.032 0 00-2.885-.059.5.5 0 00-.235.2A3.184 3.184 0 017.728 5c-1.583 0-2.765-.792-2.765-1.5S6.145 2 7.728 2a3.763 3.763 0 012.234.656.5.5 0 00.461.077 5.164 5.164 0 013.124.016.5.5 0 00.473-.078A3.736 3.736 0 0116.272 2c1.583 0 2.765.792 2.765 1.5S17.855 5 16.272 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});