define("ember-svg-jar/inlined/chess-figures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chess-figures</title><path d=\"M5.193 9.9a.25.25 0 01.171.433 14.939 14.939 0 00-1.438 1.476c-.793 1.1-1.651 1.838-1.729 5.216a.5.5 0 00.5.512h7.214a.5.5 0 00.5-.5c.046-12.264 1.784-11.6-2.03-13.5A164.423 164.423 0 015.5 2.039a.637.637 0 00-.934.563V3.7a1 1 0 01-.368.775L.656 7.353a1.286 1.286 0 00-.637 1.272C.285 9.439.437 9.9 1.292 9.9zM11.078 19.535c-.149-.409-.624-.5-1.1-.5H2.312c-.472 0-.947.091-1.1.5L.8 21.436a.468.468 0 00.114.415.569.569 0 00.424.184h9.621a.569.569 0 00.423-.184.465.465 0 00.114-.415zM23.989 21.436l-.416-1.9c-.15-.409-.625-.5-1.1-.5h-7.667c-.471 0-.946.091-1.095.5l-.417 1.9a.468.468 0 00.113.415.571.571 0 00.424.184h9.621a.571.571 0 00.424-.184.465.465 0 00.113-.415zM15.2 17.535h6.65a.5.5 0 00.5-.52 15.413 15.413 0 00-1.845-6.58 1.1 1.1 0 00-.962-.571H17.5a1.1 1.1 0 00-.962.571 15.427 15.427 0 00-1.845 6.58.5.5 0 00.5.52z\"/><circle cx=\"18.497\" cy=\"5.862\" r=\"2.906\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});