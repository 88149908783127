define("ember-svg-jar/inlined/settings-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-off</title><path d=\"M18 6.5H6a5.5 5.5 0 000 11h12a5.5 5.5 0 000-11zm-9.5 7a1 1 0 01-2 0v-3a1 1 0 112 0zm4 0a1 1 0 01-2 0v-3a1 1 0 112 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});