define("ember-svg-jar/inlined/html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>html</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.125 15a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9a.625.625 0 011.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V9a.625.625 0 011.25 0zM11.5 9.626h-1.125a.25.25 0 00-.25.25V15a.625.625 0 01-1.25 0V9.876a.25.25 0 00-.25-.25H7.5a.625.625 0 110-1.25h4a.625.625 0 010 1.25zM17.125 15a.625.625 0 01-1.25 0v-3.046c0-.142-.12-.067-.16.013l-.156.313a.65.65 0 01-1.118 0s-.1-.211-.15-.3-.166-.086-.166 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.72 1.436.783 1.566a.183.183 0 00.32-.009l.779-1.557A.625.625 0 0117.125 9zm4.375.625h-2A1.627 1.627 0 0117.875 14V9a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});