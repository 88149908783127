define("ember-svg-jar/inlined/search-square-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-square-alternate</title><circle cx=\"10.75\" cy=\"10.753\" r=\"4.5\"/><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-4.543 16.046a1 1 0 010 1.414 1.015 1.015 0 01-1.414 0l-3.317-3.316a.25.25 0 00-.318-.029 6.526 6.526 0 111.7-1.7.25.25 0 00.029.318z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});