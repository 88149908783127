define("ember-svg-jar/inlined/rain-umbrella-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella-closed</title><path d=\"M11 21.25a2.75 2.75 0 005.5 0 1 1 0 00-2 0 .75.75 0 01-1.5 0v-2.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM13.448.684A1 1 0 0012.5 0h-1a1 1 0 00-.948.684L5.771 15.026A1.5 1.5 0 007.194 17h9.613a1.5 1.5 0 001.422-1.975z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});