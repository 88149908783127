define("ember-svg-jar/inlined/pasta-noodles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pasta-noodles</title><path d=\"M8 8.5a1 1 0 001-1V1a1 1 0 00-2 0v6.5a1 1 0 001 1zM11 9.5a1 1 0 001-1V2a1 1 0 00-2 0v6.5a1 1 0 001 1zM13 3.5a1 1 0 001 1h9a1 1 0 000-2h-9a1 1 0 00-1 1zM1 4.5h1a1 1 0 000-2H1a1 1 0 000 2z\"/><path d=\"M14.783 11.367a.949.949 0 00-.747-.367H6.5a.5.5 0 01-.5-.5V2a1 1 0 00-2 0v8.5a.5.5 0 01-.5.5H1.964a.948.948 0 00-.748.369 1.032 1.032 0 00-.2.79l1.7 11a.974.974 0 00.951.841h8.667a.974.974 0 00.952-.841l1.7-11a1.035 1.035 0 00-.203-.792zM23.993 8.676a1 1 0 00-.876-1.111l-9-1.058a1 1 0 00-.234 1.986l9 1.059a.945.945 0 00.117.007 1 1 0 00.993-.883z\"/><path d=\"M2.022 7.625a1 1 0 00.121-1.993l-1.021-.125a1 1 0 00-.244 1.986l1.022.125a1.026 1.026 0 00.122.007z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});