define("ember-svg-jar/inlined/ui-phone-slider-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-phone-slider-vertical</title><path d=\"M6 20v2.5a1 1 0 001 1h10a1 1 0 001-1V20a1 1 0 00-1-1H7a1 1 0 00-1 1zm4.538.809A.5.5 0 0111 20.5h2a.5.5 0 01.354.854l-1 1a.5.5 0 01-.708 0l-1-1a.5.5 0 01-.108-.545zM18 4V1.5a1 1 0 00-1-1H7a1 1 0 00-1 1V4a1 1 0 001 1h10a1 1 0 001-1zm-4.538-.808A.5.5 0 0113 3.5h-2a.5.5 0 01-.354-.853l1-1a.5.5 0 01.708 0l1 1a.5.5 0 01.108.545zM17 6.5H7a1 1 0 00-1 1v9a1 1 0 001 1h10a1 1 0 001-1v-9a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});