define("ember-svg-jar/inlined/landmark-netherlands-windmill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-netherlands-windmill</title><path d=\"M20.5 21.979h-17a1 1 0 00-1 1v.5a.5.5 0 00.5.5h18a.5.5 0 00.5-.5v-.5a1 1 0 00-1-1zM4.613 4.934l.014.01L6.809 6.7a.248.248 0 01.09.231l-.335 2.3a.247.247 0 01-.09.159L4.7 10.819l-.022.017a1.242 1.242 0 00-.2 1.923l1.283 1.281a.252.252 0 01.071.213L5 19.909a.5.5 0 00.5.57h4.75a.25.25 0 00.25-.25v-2.75a1.5 1.5 0 013 0v2.75a.25.25 0 00.25.25h4.75a.5.5 0 00.5-.57l-.832-5.656a.252.252 0 01.071-.213l1.281-1.281a1.248 1.248 0 00-.131-1.884l-.019-.014-1.843-1.481a.244.244 0 01-.091-.159L17.1 6.92a.248.248 0 01.09-.231l2.11-1.7.018-.014a1.24 1.24 0 00.2-1.923L16.86.389a1.26 1.26 0 00-1.894.132l-.013.015L13.917 1.7a.25.25 0 01-.275.067 4.849 4.849 0 00-3.284 0 .25.25 0 01-.275-.067L9.047.536 9.034.521A1.26 1.26 0 007.14.389l-2.66 2.66a1.25 1.25 0 00.133 1.885zm1.324-1.222l1.886-1.886a.249.249 0 01.363.01l3.253 3.642a.774.774 0 001.118 0l3.253-3.642a.249.249 0 01.363-.01l1.886 1.886a.249.249 0 01-.02.371l-4.007 3.234a.752.752 0 000 1.168l4.011 3.233a.25.25 0 01.019.372l-1.877 1.869a.251.251 0 01-.37-.018L12.582 10a.787.787 0 00-1.156 0l-3.241 3.955a.25.25 0 01-.37.018L5.937 12.1a.249.249 0 01.02-.371L9.968 8.49a.752.752 0 000-1.168L5.957 4.083a.249.249 0 01-.02-.371z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});