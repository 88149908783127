define("ember-svg-jar/inlined/mouse-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mouse-1</title><circle cx=\"12.001\" cy=\"19.788\" r=\"1.75\"/><path d=\"M19.559 10.256a5 5 0 10-4.415-8.15.5.5 0 01-.386.182H9.243a.5.5 0 01-.386-.182 5 5 0 10-4.412 8.152.5.5 0 01.383.257l4.266 7.822a3.249 3.249 0 015.814 0l4.266-7.822a.5.5 0 01.385-.259zm-10.308.282a1 1 0 111-1 1 1 0 01-1 1zm5.5 0a1 1 0 111-1 1 1 0 01-1 1zM6.327 19.435l-2.79 2.538a1 1 0 001.345 1.48l2.791-2.537a1 1 0 00-1.346-1.481zM6.514 16.49a1 1 0 00-1.253-.656L.7 17.259a1 1 0 10.6 1.908l4.558-1.425a1 1 0 00.656-1.252zM20.463 21.973l-2.79-2.538a1 1 0 00-1.346 1.481l2.791 2.537a1 1 0 001.345-1.48zM23.3 17.259l-4.559-1.425a1 1 0 10-.6 1.908c4.97 1.554 4.644 1.471 4.857 1.471a1 1 0 00.3-1.954z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});