define("ember-svg-jar/inlined/pet-tracking-dog-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pet-tracking-dog-smartphone</title><path d=\"M21.957 3.512a1.037 1.037 0 01-.933-.577 2.556 2.556 0 00-2.249-1.42L17.924.239a.482.482 0 00-.446-.221.5.5 0 00-.416.275L15.053 4.31a3.42 3.42 0 01-.476.828.5.5 0 00.042.658l3.737 3.736a.5.5 0 00.747-.046 2.341 2.341 0 011.946-.98A2.954 2.954 0 0024 5.554a2.044 2.044 0 00-2.043-2.042zm-2.7 1.5a.749.749 0 11.749-.749.749.749 0 01-.75.747zM16 10.5a.5.5 0 00.353-.853l-3.141-3.139a.5.5 0 00-.487-.128 3.4 3.4 0 01-.917.128H5.025a3.2 3.2 0 00-.743.1.5.5 0 01-.425-.091C1.661 4.8 2.019 1.161 2.023 1.126A1 1 0 00.038.905c-.021.186-.425 4.158 1.988 6.641a.5.5 0 01.066.613A3.4 3.4 0 001.559 10l-.026 1.769a.492.492 0 01-.053.216l-1.4 2.792A.507.507 0 00.032 15v2.746a1.748 1.748 0 103.5 0V16.1a1.49 1.49 0 01.252-.831l1.068-1.6A1.5 1.5 0 016.094 13h6.538a.5.5 0 00.52-.356 2.965 2.965 0 012.832-2.144zM21.03 12h-5.046a1.492 1.492 0 00-1.472 1.506v8.971a1.492 1.492 0 001.472 1.507h5.046a1.491 1.491 0 001.47-1.506v-8.971A1.491 1.491 0 0021.03 12zM21 20.49a.5.5 0 01-.5.5h-3.99a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5h3.995a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});