define("ember-svg-jar/inlined/organic-plant-grow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-plant-grow</title><path d=\"M9.342 16.1a.5.5 0 00.648-.466 11.055 11.055 0 011.027-4.134.5.5 0 00-.036-.5A9 9 0 003.5 7a.5.5 0 00-.5.5 9.016 9.016 0 006.342 8.6zM9.005 7.057a10.554 10.554 0 012.8 2.517.251.251 0 00.4 0A10.581 10.581 0 0115 7.054a.5.5 0 00.237-.452A9.818 9.818 0 0012.354.141a.515.515 0 00-.708 0 9.85 9.85 0 00-2.877 6.464.5.5 0 00.236.452z\"/><path d=\"M19.5 19a4.493 4.493 0 00-2.8.979.5.5 0 01-.75-.151 4.478 4.478 0 00-2.6-2.123.5.5 0 01-.35-.477v-.347a.5.5 0 01.421-.494A9.013 9.013 0 0021 7.5a.5.5 0 00-.5-.5 9.01 9.01 0 00-9 9v1.1a.5.5 0 01-.4.49 4.482 4.482 0 00-3.046 2.238.5.5 0 01-.75.151A4.5 4.5 0 000 23.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5 4.505 4.505 0 00-4.5-4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});