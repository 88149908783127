define("ember-svg-jar/inlined/messages-bubble-empty-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-empty-alternate</title><path d=\"M15.826 1.75H8.174a8.174 8.174 0 000 16.348H8.5v3.152a1 1 0 001.726.687l3.637-3.837h1.963a8.174 8.174 0 000-16.348zm0 14.348h-2.393a1 1 0 00-.726.312L10.5 18.74V17.1a1 1 0 00-1-1H8.174a6.174 6.174 0 010-12.348h7.652a6.174 6.174 0 010 12.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});