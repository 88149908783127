define("ember-svg-jar/inlined/database-monitor-sync-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-monitor-sync-alternate</title><path d=\"M12.66 3.875a.5.5 0 000-.8l-2-1.5a.5.5 0 00-.8.4v.5h-2a3 3 0 00-3 3v1.75h-.5a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.5V5.474a1 1 0 011-1h2v.5a.5.5 0 00.8.4zM10.2 21.031a1.987 1.987 0 001.514-.709 1.49 1.49 0 00.486-1.1v-5.691a2 2 0 00-2-2h-8a2 2 0 00-2 2v5.693a1.493 1.493 0 00.485 1.1 1.99 1.99 0 001.515.709h2.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25H3.7a1 1 0 100 2h5a1 1 0 000-2H7.45a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25zm0-7.25v4a.25.25 0 01-.25.25h-7.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h7.5a.25.25 0 01.25.25zM19.3-.026c-2.294 0-4.119 1.048-4.435 2.449a.759.759 0 00-.065.3v6.126c0 1.613 1.977 2.875 4.5 2.875s4.5-1.262 4.5-2.875V2.724a.743.743 0 00-.065-.3c-.316-1.402-2.141-2.45-4.435-2.45zm0 10.25c-1.831 0-3-.814-3-1.375V8.4a.251.251 0 01.116-.211.248.248 0 01.239-.016 6.668 6.668 0 005.29 0 .248.248 0 01.239.016.251.251 0 01.116.211v.445c0 .565-1.168 1.379-3 1.379zm3-7.375c0 .561-1.168 1.375-3 1.375s-3-.814-3-1.375 1.169-1.375 3-1.375 3 .815 3 1.375zm-3 4.375c-1.831 0-3-.814-3-1.375V5.4a.251.251 0 01.116-.211.248.248 0 01.239-.016 6.321 6.321 0 002.645.547 6.317 6.317 0 002.645-.547.248.248 0 01.239.016.251.251 0 01.116.211v.445c0 .565-1.168 1.379-3 1.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});