define("ember-svg-jar/inlined/cash-briefcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-briefcase</title><path d=\"M3.5 22.75h17a3.5 3.5 0 003.5-3.5v-10a3.5 3.5 0 00-3.5-3.5h-3.75a.249.249 0 01-.25-.25V4.25a3 3 0 00-3-3h-3a3 3 0 00-3 3V5.5a.249.249 0 01-.25.25H3.5A3.5 3.5 0 000 9.25v10a3.5 3.5 0 003.5 3.5zm6-18.5a1 1 0 011-1h3a1 1 0 011 1V5.5a.249.249 0 01-.25.25h-4.5a.249.249 0 01-.25-.25zm3.6 11.468l-2.963-1.1a2.378 2.378 0 01-1.359-2.3A2.338 2.338 0 0110.8 9.977a.25.25 0 00.2-.246V9.25a1 1 0 012 0v.417a.25.25 0 00.25.25h.973a1 1 0 010 2h-2.779c-.667 0-.667.293-.667.4a.761.761 0 00.124.462l2.963 1.1a2.379 2.379 0 011.359 2.3 2.337 2.337 0 01-2.02 2.343.251.251 0 00-.2.246v.481a1 1 0 01-2 0v-.416a.249.249 0 00-.25-.25h-.976a1 1 0 110-2h2.779c.667 0 .667-.294.667-.4a.761.761 0 00-.123-.465z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});