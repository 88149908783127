define("ember-svg-jar/inlined/school-locker-closed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-locker-closed</title><path d=\"M6.5 0h-5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h5a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5zm-2 21.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM2 11a.75.75 0 11.75.75A.755.755 0 012 11zm2.5-4.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM15 0H9a.5.5 0 00-.5.5v23a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V.5A.5.5 0 0015 0zm-2 21.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM10.5 11a.75.75 0 11.75.75.755.755 0 01-.75-.75zM13 6.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM22.5 0h-5a.5.5 0 00-.5.5v23a.5.5 0 00.5.5h5a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-1 21.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM19 11a.75.75 0 11.75.75A.755.755 0 0119 11zm2.5-4.75h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm0-3h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});