define("ember-svg-jar/inlined/radio-antenna-handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>radio-antenna-handle</title><path d=\"M2 4a2 2 0 012-2h16a2 2 0 012 2v2.317a.249.249 0 00.206.245 3.976 3.976 0 011.411.546A.251.251 0 0024 6.9V4a4 4 0 00-4-4H4a4 4 0 00-4 4v2.9a.251.251 0 00.383.212 3.964 3.964 0 011.411-.546.248.248 0 00.206-.25zM0 21.5A2.5 2.5 0 002.5 24h19a2.5 2.5 0 002.5-2.5v-7.75a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25zM10.25 16h10.5a.75.75 0 010 1.5h-10.5a.75.75 0 010-1.5zm0 3.5h10.5a.75.75 0 010 1.5h-10.5a.75.75 0 010-1.5zM5 15.5a3 3 0 11-3 3 3 3 0 013-3z\"/><path d=\"M18 6a1 1 0 00-1 1v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V7a1 1 0 00-2 0v.75a.25.25 0 01-.25.25H2.5A2.5 2.5 0 000 10.5v1.25a.25.25 0 00.25.25h23.5a.25.25 0 00.25-.25V10.5A2.5 2.5 0 0021.5 8h-2.25a.25.25 0 01-.25-.25V7a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});