define("ember-svg-jar/inlined/organic-plant-root", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>organic-plant-root</title><path d=\"M23 11.786h-8.671a.5.5 0 01-.423-.233 8.611 8.611 0 01-1.156-4.267 2.4 2.4 0 01.647-1.64 8.174 8.174 0 015.03-2.511.471.471 0 01.51.491.5.5 0 01-.49.509 6.551 6.551 0 00-3.21 1.249.5.5 0 00-.12.722 2.048 2.048 0 00.324.336 2.619 2.619 0 001.685.538 4.3 4.3 0 001.317-.218A7.3 7.3 0 0022.5 1.32a.5.5 0 00-.572-.528 6.522 6.522 0 01-2.754-.26 6.9 6.9 0 00-2.409-.3A2.738 2.738 0 0014.514 1.5a2.116 2.116 0 00-.24 1.223.5.5 0 01-.229.466 9.132 9.132 0 00-1.683 1.369.5.5 0 01-.723 0 9.139 9.139 0 00-1.684-1.373.5.5 0 01-.229-.466A2.109 2.109 0 009.487 1.5 2.739 2.739 0 007.237.233a6.906 6.906 0 00-2.41.3 6.518 6.518 0 01-2.754.26.491.491 0 00-.412.127.5.5 0 00-.16.4 7.308 7.308 0 004.057 5.442 4.294 4.294 0 001.316.218 2.619 2.619 0 001.685-.538 1.969 1.969 0 00.324-.336.5.5 0 00.1-.387.505.505 0 00-.217-.335 6.551 6.551 0 00-3.212-1.249.5.5 0 01-.49-.509.47.47 0 01.509-.491A8.171 8.171 0 0110.6 5.646a2.411 2.411 0 01.646 1.64 8.6 8.6 0 01-1.156 4.267.5.5 0 01-.423.233H1a1 1 0 000 2h9.59a.318.318 0 01.188.573l-2.591 1.9a1 1 0 01-.592.194H6.579a2.978 2.978 0 00-2.122.878l-1.914 1.915a1 1 0 101.414 1.414l1.914-1.915a.993.993 0 01.708-.292.671.671 0 01.671.671V20.2a1 1 0 002 0v-1.734a1 1 0 01.409-.806l.943-.692a.25.25 0 01.4.2v5.617a1 1 0 002 0v-5.616a.25.25 0 01.4-.2l.943.692a1 1 0 01.409.806V20.2a1 1 0 102 0v-1.076a.671.671 0 01.671-.671.993.993 0 01.708.292l1.914 1.915a1 1 0 001.414-1.414l-1.914-1.915a2.978 2.978 0 00-2.122-.878h-1.02a1 1 0 01-.592-.194l-2.482-1.82a.361.361 0 01.213-.653H23a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});