define("ember-svg-jar/inlined/shopping-cart-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-empty</title><circle cx=\"14.531\" cy=\"21.125\" r=\"2.25\"/><circle cx=\"4.531\" cy=\"21.125\" r=\"2.25\"/><path d=\"M22.469.625h-3.062a1.756 1.756 0 00-1.735 1.519L15.9 15.408a.251.251 0 01-.248.217H1.531a1.25 1.25 0 100 2.5h15a1.758 1.758 0 001.736-1.519l1.768-13.264a.249.249 0 01.247-.217h2.187a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});