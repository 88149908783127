define("ember-svg-jar/inlined/warehouse-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-package</title><path d=\"M9.5 9h5a.5.5 0 01.5.5v2.75a.25.25 0 00.25.25h3.25a.483.483 0 00.5-.464V6.25a.25.25 0 00-.25-.25H5.25a.25.25 0 00-.25.25v5.786a.483.483 0 00.5.464h3.25a.25.25 0 00.25-.25V9.5a.5.5 0 01.5-.5zM12 0C6 0 5.144 3.668 5.021 4.721A.249.249 0 005.269 5H18.7a.249.249 0 00.2-.093.252.252 0 00.05-.21C18.716 3.615 17.527 0 12 0z\"/><rect x=\"2\" y=\"19\" width=\"6\" height=\"5\" rx=\".5\" ry=\".5\"/><rect x=\"16\" y=\"19\" width=\"6\" height=\"5\" rx=\".5\" ry=\".5\"/><path d=\"M18 17a1 1 0 002 0v-1a1.5 1.5 0 00-1.5-1.5h-5.25a.25.25 0 01-.25-.25V14a1 1 0 00-2 0v.25a.25.25 0 01-.25.25H5.5A1.5 1.5 0 004 16v1a1 1 0 002 0v-.25a.25.25 0 01.25-.25h11.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});