define("ember-svg-jar/inlined/weather-app-cloud-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-app-cloud-location</title><path d=\"M12.506 16.238a.25.25 0 00-.25-.238H6.5a4.5 4.5 0 11.081-9 1 1 0 00.986-.835 5 5 0 019.647-.83 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365.232.232 0 00.164.309 6.483 6.483 0 012.476 1.192.25.25 0 00.371-.32 4.966 4.966 0 00-.844-1.106.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 8.5a4.489 4.489 0 00-3.517-4.392.251.251 0 01-.171-.136 7 7 0 00-12.988.915.251.251 0 01-.205.173A6.5 6.5 0 006.5 18h6.021a.25.25 0 00.24-.32 6.3 6.3 0 01-.255-1.442z\"/><path d=\"M19 11a5.006 5.006 0 00-5 5c0 3.171 4.457 7.664 4.646 7.854a.5.5 0 00.708 0C19.543 23.664 24 19.171 24 16a5.006 5.006 0 00-5-5zm0 6.75A1.75 1.75 0 1120.75 16 1.752 1.752 0 0119 17.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});