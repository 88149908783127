define("ember-svg-jar/inlined/kitchenware-honey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-honey</title><path d=\"M8.686 13.9a.5.5 0 00-.707 0l-7.54 7.54a1.5 1.5 0 002.121 2.12l7.54-7.539a.5.5 0 000-.707zM7.639 10.022a1 1 0 00.661 1.705 1 1 0 01.492.158 11.719 11.719 0 011.807 1.521 11.671 11.671 0 011.521 1.807.991.991 0 01.158.492 1 1 0 001.706.657c1.086-1.086-.719-3.119-1.971-4.37-3.174-3.169-4.046-2.298-4.374-1.97zM21.125 2.875C17.236-1.014 16.171.05 15.822.4a1 1 0 00.8 1.7 1 1 0 01.6.131 13.244 13.244 0 012.484 2.055 13.23 13.23 0 012.055 2.485.993.993 0 01.131.6 1 1 0 001.7.8c.358-.342 1.422-1.407-2.467-5.296zM8.407 5.694a1 1 0 00.838 1.7 1 1 0 01.6.108 17.543 17.543 0 013.691 2.966 17.619 17.619 0 012.964 3.69 1 1 0 01.108.6 1 1 0 001.7.838c1.471-1.47-1.892-5.075-3.359-6.541-2.26-2.255-5.226-4.673-6.542-3.361zM14.564 3.148c-.6-.392-2.182-1.429-3.122-.488a1 1 0 00.838 1.7 1 1 0 01.6.108 17.586 17.586 0 013.69 2.967 17.621 17.621 0 012.967 3.69.992.992 0 01.107.6 1 1 0 001.7.838c1.47-1.469-1.889-5.071-3.359-6.54a22.814 22.814 0 00-3.421-2.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});