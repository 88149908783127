define("ember-svg-jar/inlined/insect-cricket-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-cricket-body</title><path d=\"M23.981 13.638a.5.5 0 00-.053-.4.5.5 0 00-.326-.23l-2.4-.5-.705-5.638A1 1 0 0019.5 6c-.316 0-1.551.16-4.125 2.219a8.309 8.309 0 00-2.3 2.613s-2.793-.588-3.962-.838a.5.5 0 00-.6.4 19.877 19.877 0 00-.294 3.339 6.412 6.412 0 00-1.951 1.027 4.01 4.01 0 00-1.155 1.74l-.086.258A1.079 1.079 0 014 17.5a1 1 0 100 2 3.077 3.077 0 002.924-2.1l.086-.258a2 2 0 011.155-1.225l1.191-.476c.219.139.457.274.713.4l-.439.9a1.209 1.209 0 01-1.13.759 1 1 0 000 2 3.168 3.168 0 002.954-1.952l.471-.961c-.009.019 2.676.636 2.917.673a20.2 20.2 0 002.846.242.5.5 0 00.5-.558l-.556-4.817a.5.5 0 00-.4-.433l-2.091-.432a8.017 8.017 0 011.478-1.481 14.514 14.514 0 012.039-1.405L19.932 18.5H19.5a.75.75 0 100 1.5h3a.75.75 0 000-1.5h-.555l-.249-2a5.69 5.69 0 002.285-2.862z\"/><path d=\"M7.068 9.958a.5.5 0 00-.363-.574 9.577 9.577 0 01-4.047-1.809A1.632 1.632 0 012 6.5a1.314 1.314 0 01.229-.815A1 1 0 00.793 4.293 3.179 3.179 0 000 6.5c0 1.288.928 2.507 2.6 3.465a3.272 3.272 0 001.051 2.889 3.508 3.508 0 002.578.974.5.5 0 00.5-.489 21.905 21.905 0 01.339-3.381z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});