define("ember-svg-jar/inlined/drawer-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-music</title><path d=\"M4.51 12.5a1 1 0 001-1L5.5 2.25a.247.247 0 01.073-.176.252.252 0 01.18-.074h12.494a.25.25 0 01.25.25l.003 9.25a1 1 0 001 1 1 1 0 001-1V2a2 2 0 00-2-2h-13a2 2 0 00-2 2l.007 9.5a1 1 0 001.003 1z\"/><path d=\"M14.5 7.25A1.75 1.75 0 1016.25 9V4.955a1.743 1.743 0 00-.632-1.347 1.776 1.776 0 00-1.532-.35l-3.409 1.1A1.751 1.751 0 009.25 6.08v3.2A1.581 1.581 0 009 9.25 1.75 1.75 0 1010.75 11V6.08c0-.12.086-.224.295-.27l3.409-1.1a.247.247 0 01.206.053.25.25 0 01.09.193v2.32a1.581 1.581 0 00-.25-.026z\"/><path d=\"M23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});