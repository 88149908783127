define("ember-svg-jar/inlined/shopping-basket-handle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-handle-1</title><path d=\"M21.5 14.625h-19a.5.5 0 00-.49.6l1.281 6.4a2.355 2.355 0 002.475 1.5h12.468a2.355 2.355 0 002.475-1.5l1.281-6.4a.5.5 0 00-.49-.6zm-13.75 5.5v-2.5a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm8.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zM22.222 9.138a.25.25 0 01-.216-.191l-.656-2.771c-.81-3.418-2.953-5.3-6.035-5.3h-6.63C5.6.875 3.46 2.758 2.65 6.176l-.656 2.771a.25.25 0 01-.216.191A2 2 0 002 13.125h20a2 2 0 00.222-3.987zM5.084 6.752c.716-3.027 2.4-3.377 3.6-3.377h6.63c1.2 0 2.885.35 3.6 3.377l.43 1.815a.25.25 0 01-.243.308H4.9a.25.25 0 01-.243-.308z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});