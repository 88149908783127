define("ember-svg-jar/inlined/multiple-actions-refresh.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-refresh</title><path d=\"M22.769 18.6a1 1 0 00-1.27.622 4.091 4.091 0 01-6.918 1.42.25.25 0 01.008-.345l1.722-1.722a.5.5 0 00-.354-.853H11.5a.5.5 0 00-.5.5v4.458a.5.5 0 00.853.353l.97-.969a.249.249 0 01.359 0 6.085 6.085 0 0010.209-2.2 1 1 0 00-.622-1.264zM24 12.234a.5.5 0 00-.854-.353l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.092 4.092 0 016.9-1.445.249.249 0 01-.007.346l-1.66 1.66a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5zM12.023 8.534a.5.5 0 00-.182.771 7.473 7.473 0 01.787 1.187.5.5 0 00.7.19A7.93 7.93 0 0117.5 9.5a.32.32 0 00.169-.593 6 6 0 00-5.646-.373z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.563 11.733A6.026 6.026 0 000 13.333.6.6 0 00.6 14h9.411a.5.5 0 00.441-.264 7.962 7.962 0 011.024-1.484.5.5 0 00.087-.519z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});