define("ember-svg-jar/inlined/pregnancy-ultrasound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pregnancy-ultrasound</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M15.119 4.619a.457.457 0 00-.608-.033 3.658 3.658 0 01-4.564 0 .455.455 0 00-.608.034L4.8 9.155a.458.458 0 00.014.659 10.967 10.967 0 0014.822 0 .457.457 0 00.015-.659zm-.554 5.552a4.985 4.985 0 01-4.671 0 .75.75 0 01.67-1.342 3.516 3.516 0 003.33 0 .75.75 0 01.671 1.342z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});