define("ember-svg-jar/inlined/shelf-books-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shelf-books-1</title><path d=\"M23 19.165a.5.5 0 01-.5-.5v-7.5a.5.5 0 01.5-.5 1 1 0 000-2h-3.25a.5.5 0 01-.5-.5v-4.5a.75.75 0 00-.75-.75H17a.75.75 0 00-.75.75v4.5a.5.5 0 01-1 0v-4.5a.75.75 0 00-.75-.75H13a.75.75 0 00-.75.75v4.5a.5.5 0 01-.5.5h-1.077a.253.253 0 01-.211-.115.25.25 0 01-.016-.24l1.736-3.787a.751.751 0 00-.369-.994L10.449 2.9a.748.748 0 00-.994.369l-2.338 5.1a.5.5 0 01-.455.292H1a1 1 0 000 2 .5.5 0 01.5.5v7.5a.5.5 0 01-.5.5 1 1 0 000 2h22a1 1 0 000-2zm-19.5-8a.5.5 0 01.5-.5h16a.5.5 0 01.5.5v7.5a.5.5 0 01-.5.5h-2.162a.5.5 0 01-.455-.292l-2.338-5.1a.75.75 0 00-.994-.369l-1.363.625a.75.75 0 00-.37.994l1.736 3.787a.25.25 0 01-.016.24.253.253 0 01-.211.115H12.75a.5.5 0 01-.5-.5v-4.5a.75.75 0 00-.75-.75H10a.75.75 0 00-.75.75v4.5a.5.5 0 01-1 0v-4.5a.75.75 0 00-.75-.75H6a.75.75 0 00-.75.75v4.5a.5.5 0 01-.5.5H4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});