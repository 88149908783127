define("ember-svg-jar/inlined/single-man-actions-time.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-time</title><path d=\"M.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.245.245 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm0-10.887a4.454 4.454 0 11-4.453 4.454 4.459 4.459 0 014.453-4.454z\"/><path d=\"M17.443 18.557h1.98a.99.99 0 000-1.98h-.743a.247.247 0 01-.247-.247v-.99a.99.99 0 00-1.979 0v2.227a.989.989 0 00.989.99zM.619 18.062H2.41a.246.246 0 01.246.227l.444 5.257a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.245.245 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1v3.464a.494.494 0 00.495.498z\"/><path d=\"M1.361 4.206A4.206 4.206 0 105.567 0a4.211 4.211 0 00-4.206 4.206zm4.206 2.227A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm0-10.887a4.454 4.454 0 11-4.453 4.454 4.459 4.459 0 014.453-4.454z\"/><path d=\"M17.443 18.557h1.98a.99.99 0 000-1.98h-.743a.247.247 0 01-.247-.247v-.99a.99.99 0 00-1.979 0v2.227a.989.989 0 00.989.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});