define("ember-svg-jar/inlined/headphones-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>headphones-1</title><path d=\"M6.69 13.694a11.797 11.797 0 01-.89-.6.5.5 0 00-.8.4v10a.5.5 0 00.854.354c.428-.428.821-.76 1.16-1.047C7.845 22.1 8.5 21.547 8.5 20.5V16c0-1.166-.92-1.746-1.81-2.306z\"/><path d=\"M22.182 13.093a.249.249 0 01-.182-.24V10a10 10 0 00-20 0v2.855a.248.248 0 01-.182.24A2.5 2.5 0 000 15.5v6A2.5 2.5 0 002.5 24h1a.5.5 0 00.5-.5V10a8 8 0 0116 0v13.5a.5.5 0 00.5.5h1a2.5 2.5 0 002.5-2.5v-6a2.5 2.5 0 00-1.818-2.407z\"/><path d=\"M18.2 13.1c-.3.223-.6.415-.89.6-.89.56-1.81 1.14-1.81 2.3v4.5c0 1.049.655 1.6 1.486 2.307.339.287.732.619 1.16 1.047A.5.5 0 0019 23.5v-10a.5.5 0 00-.8-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});