define("ember-svg-jar/inlined/network-arrow-sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-arrow-sync</title><path d=\"M4.952 12A7.048 7.048 0 1012 4.952 7.056 7.056 0 004.952 12zM12 17.168a5.169 5.169 0 010-10.337c.128 0 .254 0 .38.015a.25.25 0 01.222.18 2.832 2.832 0 01.089.51.253.253 0 01-.064.191.249.249 0 01-.185.082c-.192 0-.4-.014-.522-.022-.3-.018-.338-.016-.5-.016a2.467 2.467 0 00-.334 4.911.25.25 0 01.216.248v.85c0 1.306.706 2.22 1.706 2.22 2 0 2.225-1.738 2.225-2.738 0-.877.012-1.409.011-1.762a4.259 4.259 0 00-.02-.5 4.5 4.5 0 00-.3-.988.25.25 0 01.076-.285l.868-.734a.251.251 0 01.366.048A5.159 5.159 0 0112 17.168z\"/><path d=\"M3.236 19.438a.25.25 0 010 .323l-.449.533a.7.7 0 00.439 1.152l2.87.409a.915.915 0 00.342.074c.032 0 .063-.008.1-.011l.205.029a.855.855 0 00.1.007.7.7 0 00.67-.921L6.42 17.657a.7.7 0 00-1.21-.238l-.413.49A.255.255 0 014.6 18a.252.252 0 01-.193-.1 9.618 9.618 0 017.6-15.534 9.8 9.8 0 012.037.215.939.939 0 10.391-1.836A11.522 11.522 0 00.493 12a11.425 11.425 0 002.743 7.438zM20.764 4.561a.25.25 0 010-.323l.449-.533a.7.7 0 00-.439-1.151l-2.915-.416a.917.917 0 00-.346-.05l-.251-.035a.7.7 0 00-.77.914l1.088 3.376a.707.707 0 00.535.475.7.7 0 00.136.013.705.705 0 00.539-.25l.413-.49a.249.249 0 01.388.008A9.616 9.616 0 0112 21.632a9.732 9.732 0 01-2.037-.215.94.94 0 10-.393 1.838A11.522 11.522 0 0023.507 12a11.426 11.426 0 00-2.743-7.439z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});