define("ember-svg-jar/inlined/circus-tent-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circus-tent-1</title><path d=\"M20.131 15.177a.249.249 0 00-.282-.232A6.546 6.546 0 0119 15a5.761 5.761 0 01-3.354-1.01.251.251 0 00-.291 0A5.761 5.761 0 0112 15a5.761 5.761 0 01-3.354-1.01.251.251 0 00-.291 0A5.761 5.761 0 015 15a6.546 6.546 0 01-.849-.055.249.249 0 00-.282.232 37.111 37.111 0 01-1.149 7.549A1 1 0 003.682 24h16.637a1 1 0 00.962-1.273 37.16 37.16 0 01-1.15-7.55zM14 22a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-2.5a2 2 0 014 0zM22.5 10.987a2.59 2.59 0 00-1.284-2.237l-8.1-4.732a.25.25 0 01.059-.457l2.707-.748a1 1 0 00-.007-1.929l-3.112-.85A1 1 0 0011.5 1v2.515a.249.249 0 01-.124.216L2.784 8.75A2.59 2.59 0 001.5 10.987V11c0 1.381 1.567 2.5 3.5 2.5a3.659 3.659 0 003.277-1.621.252.252 0 01.222-.133.249.249 0 01.221.135A3.723 3.723 0 0012 13.5a3.659 3.659 0 003.277-1.62.25.25 0 01.443 0A3.727 3.727 0 0019 13.5c1.933 0 3.5-1.119 3.5-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});