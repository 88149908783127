define("ember-svg-jar/inlined/arrow-thick-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-up-1</title><path d=\"M22.925 10.613L12.708.4A.986.986 0 0012 .1a1 1 0 00-.707.3L1.076 10.613a1 1 0 000 1.414l2.752 2.753a.994.994 0 001.393.02l3.241-3.055a.247.247 0 01.27-.048.25.25 0 01.151.23V22.9a1 1 0 001 1h4a1 1 0 001-1V11.857a.25.25 0 01.418-.185l3.492 3.151a1 1 0 001.376-.036l2.762-2.761a1 1 0 00-.006-1.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});