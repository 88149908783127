define("ember-svg-jar/inlined/stamps-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stamps-mail</title><circle cx=\"12\" cy=\"12\" r=\"2.75\"/><path d=\"M22 6a1 1 0 011-1h.5a.5.5 0 00.5-.5v-3A1.5 1.5 0 0022.5 0h-3a.5.5 0 00-.5.5V1a1 1 0 01-2 0V.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V1a1 1 0 01-2 0V.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V1a1 1 0 01-2 0V.5a.5.5 0 00-.5-.5h-3A1.5 1.5 0 000 1.5v3a.5.5 0 00.5.5H1a1 1 0 010 2H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5H1a1 1 0 010 2H.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5H1a1 1 0 010 2H.5a.5.5 0 00-.5.5v3A1.5 1.5 0 001.5 24h3a.5.5 0 00.5-.5V23a1 1 0 012 0v.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V23a1 1 0 012 0v.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V23a1 1 0 012 0v.5a.5.5 0 00.5.5h3a1.5 1.5 0 001.5-1.5v-3a.5.5 0 00-.5-.5H23a1 1 0 010-2h.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5H23a1 1 0 010-2h.5a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5H23a1 1 0 01-1-1zm-5.735 8.445a.25.25 0 00.187.242 1.84 1.84 0 00.45.063c1.248 0 1.922-1.59 1.922-3.086a5.725 5.725 0 00-1.57-3.937 7.333 7.333 0 00-5.448-1.977 6.25 6.25 0 10.039 12.5 6.439 6.439 0 003.176-.835.749.749 0 11.751 1.3 7.951 7.951 0 01-3.927 1.038 7.75 7.75 0 11-.039-15.5 8.655 8.655 0 016.529 2.438 7.236 7.236 0 011.989 4.976c0 2.657-1.439 4.586-3.422 4.586a1.962 1.962 0 01-1.863-.88.249.249 0 00-.376-.072A4.21 4.21 0 0112 16.25a4.25 4.25 0 010-8.5 4.19 4.19 0 012.556.877.25.25 0 00.359-.057.723.723 0 01.6-.32.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});