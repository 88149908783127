define("ember-svg-jar/inlined/video-game-pacman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-pacman</title><path d=\"M22.425 17.915L14.6 12.409a.5.5 0 010-.818l7.825-5.506a1 1 0 00.214-1.431A12 12 0 1013.151 24a11.924 11.924 0 009.487-4.653 1 1 0 00-.213-1.432zM10.651 8.5a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});