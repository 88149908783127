define("ember-svg-jar/inlined/crime-woman-thief.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-thief</title><path d=\"M21.063 18.28s-3.482-1.164-4.494-1.519a.5.5 0 00-.473.078 7.313 7.313 0 01-4.117 1.661 7.311 7.311 0 01-4.1-1.652.5.5 0 00-.47-.078 804.751 804.751 0 01-4.487 1.514A4.522 4.522 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.524 4.524 0 00-2.937-4.22zM9.751 4.491L11.863 5.8a.252.252 0 00.262 0l2.123-1.306a4.235 4.235 0 013.507-.431.5.5 0 00.611-.672 5.046 5.046 0 00-.8-1.31C16.4.7 14.521 0 11.979 0s-4.42.7-5.587 2.077a5.111 5.111 0 00-.809 1.331.5.5 0 00.618.668 4.232 4.232 0 013.55.415z\"/><path d=\"M8.316 15.585a6.486 6.486 0 003.663 1.666 6.507 6.507 0 003.693-1.689 2.21 2.21 0 002.143 1.34 5.117 5.117 0 003.569-1.673.5.5 0 00-.231-.8 3.25 3.25 0 01-1.842-1.916 8.163 8.163 0 01-.467-1.421.155.155 0 00-.215-.208 4.138 4.138 0 01-1.947.594.5.5 0 00-.453.354 1.453 1.453 0 00-.041.176c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.383 1.383 0 00-.042-.18.5.5 0 00-.447-.353 4.172 4.172 0 01-1.9-.579.176.176 0 00-.241.242 8.078 8.078 0 01-.47 1.4 3.232 3.232 0 01-1.828 1.888.5.5 0 00-.231.8A5.117 5.117 0 006.185 16.9a2.186 2.186 0 002.131-1.315z\"/><path d=\"M12.005 10.979H12a2.356 2.356 0 00-2.177 1.2.75.75 0 001.358.639.928.928 0 01.819-.339.909.909 0 01.819.345.75.75 0 101.352-.65 2.35 2.35 0 00-2.166-1.195zM4.5 7.678a2.71 2.71 0 00.9 2.009 3.21 3.21 0 003.819.329l2.647-1.664a.25.25 0 01.266 0l2.648 1.664a3.207 3.207 0 003.82-.329 2.69 2.69 0 000-4.019 3.207 3.207 0 00-3.819-.33L12.133 7a.25.25 0 01-.266 0L9.219 5.338a3.2 3.2 0 00-3.817.33 2.708 2.708 0 00-.902 2.01zm11-.953h.5a.75.75 0 010 1.5h-.5a.75.75 0 010-1.5zm-6.25.75a.75.75 0 01-.75.75H8a.75.75 0 010-1.5h.5a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});