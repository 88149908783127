define("ember-svg-jar/inlined/shopping-cart-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-woman</title><path d=\"M3.27 19.779A1.423 1.423 0 104.693 21.2a1.435 1.435 0 00-1.423-1.421zM6.962 19.779A1.423 1.423 0 108.385 21.2a1.43 1.43 0 00-1.423-1.421zM.5 12.625a.5.5 0 00-.406.208.5.5 0 00-.067.451l1.845 5.5a.5.5 0 00.474.341h6.231a.5.5 0 00.482-.365c1.689-6.042.851-3.242 2.544-8.319a1 1 0 10-1.9-.632l-.882 2.645a.25.25 0 01-.237.171zM23.977 17.474l-1.67-5.28v-.017a3.947 3.947 0 00-1.941-2.55.251.251 0 00-.338.095l-1.345 2.364a.249.249 0 01-.434 0L16.9 9.718a.25.25 0 00-.337-.1 3.9 3.9 0 00-1.939 2.527v.015l-1.6 5.317a.5.5 0 00.478.644h1.822a.25.25 0 01.249.225L16 22.675a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.433-4.325a.25.25 0 01.249-.225H23.5a.5.5 0 00.477-.651zM18.439 9.375a4.2 4.2 0 002.115-.586 4.251 4.251 0 10-4.161.038 4.188 4.188 0 002.046.548zm-.178-5.011a.249.249 0 01.356 0 4.333 4.333 0 001.806 1.1.25.25 0 01.165.311 2.243 2.243 0 01-4.3 0 .25.25 0 01.166-.311 4.339 4.339 0 001.807-1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});