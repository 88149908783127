define("ember-svg-jar/inlined/modern-tv-flat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-flat</title><path d=\"M21.75 2H2.25A2.253 2.253 0 000 4.25v11a2.253 2.253 0 002.25 2.25H8.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H7.25a1.25 1.25 0 000 2.5h9.5a1.25 1.25 0 000-2.5H15.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h6.25A2.253 2.253 0 0024 15.25v-11A2.253 2.253 0 0021.75 2zm-.25 12.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h18a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});