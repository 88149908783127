define("ember-svg-jar/inlined/waiting-room-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>waiting-room-lamp</title><circle cx=\"7.5\" cy=\"7.5\" r=\"2.5\"/><path d=\"M13.239 17h-2.887a.5.5 0 01-.5-.462c0-.053-.377-3.311-.377-3.311a1.5 1.5 0 00-2.952.544l.347 3.087A3.516 3.516 0 0010.352 20h2.887a1.509 1.509 0 011.495 1.375l.22 2.174a.5.5 0 00.5.45h2.007a.5.5 0 00.5-.55l-.232-2.323A4.528 4.528 0 0013.239 17z\"/><path d=\"M12.5 21.5H8.745a3.511 3.511 0 01-3.421-2.761l-.688-3.993A1.5 1.5 0 003.157 13.5H1.5a.5.5 0 00-.5.5v9.5a.5.5 0 00.5.5h11.188a.5.5 0 00.5-.562l-.188-1.5a.5.5 0 00-.5-.438zM20 3.142V1a1 1 0 00-2 0v2.142A4 4 0 0015 7v.5a1 1 0 001 1h6a1 1 0 001-1V7a4 4 0 00-3-3.858z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});