define("ember-svg-jar/inlined/hierarchy-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hierarchy-4</title><path d=\"M23.5 19a1.5 1.5 0 00-1.5-1.5h-.3a.249.249 0 01-.248-.224A7 7 0 0014.5 11h-1.25a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h.25A1.5 1.5 0 0015 5V2A1.5 1.5 0 0013.5.5h-3A1.5 1.5 0 009 2v3a1.5 1.5 0 001.5 1.5h.25a.25.25 0 01.25.25v4a.25.25 0 01-.25.25H9.5a7 7 0 00-6.955 6.276.249.249 0 01-.245.224H2A1.5 1.5 0 00.5 19v3A1.5 1.5 0 002 23.5h3A1.5 1.5 0 006.5 22v-3A1.5 1.5 0 005 17.5h-.165a.248.248 0 01-.19-.088.252.252 0 01-.057-.2A4.987 4.987 0 019.5 13h1.25a.25.25 0 01.25.25v4a.25.25 0 01-.25.25h-.25A1.5 1.5 0 009 19v3a1.5 1.5 0 001.5 1.5h3A1.5 1.5 0 0015 22v-3a1.5 1.5 0 00-1.5-1.5h-.25a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h1.25a4.987 4.987 0 014.912 4.21.255.255 0 01-.057.2.248.248 0 01-.19.088H19A1.5 1.5 0 0017.5 19v3a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});