define("ember-svg-jar/inlined/messaging-pingchat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messaging-pingchat</title><path d=\"M14.33 16H8v-6a.5.5 0 00-.5-.5A7.37 7.37 0 000 16.77 7.33 7.33 0 007.42 24a7.56 7.56 0 007.42-7.5.5.5 0 00-.51-.5z\"/><path d=\"M17 0c-6.71 0-7 6.89-7 7v6.5a.5.5 0 00.5.5h6.19A7.08 7.08 0 0024 7a6.86 6.86 0 00-7-7zm-1.25 3a.75.75 0 111.5 0v4a.75.75 0 01-1.5 0zm.75 8.72a1.22 1.22 0 111.25-1.22 1.24 1.24 0 01-1.25 1.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});