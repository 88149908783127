define("ember-svg-jar/inlined/nautic-sports-surfing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>nautic-sports-surfing</title><circle cx=\"6.998\" cy=\"3.479\" r=\"2.5\"/><path d=\"M12.716 9.156a.253.253 0 00-.052-.191A4.028 4.028 0 009.5 7.479h-5a3.913 3.913 0 00-4 3.89v3.61a1.25 1.25 0 002.5 0v-3.86a.5.5 0 011 0v11.61a1.25 1.25 0 002.5 0v-6.25a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0v-11.61a.5.5 0 011 0 4.522 4.522 0 001.117 3.071.253.253 0 00.285.065.25.25 0 00.152-.249 22.632 22.632 0 01.162-4.85zM23.135 16.226A21.079 21.079 0 0019.994.652a1.568 1.568 0 00-2.514 0 21.086 21.086 0 00-3.136 15.593l1.257 6.1a2 2 0 001.966 1.633h2.34a2 2 0 001.964-1.62zm-6.72.767a.25.25 0 01-.423-.135l-.351-2.058a.249.249 0 01.069-.219l5.6-5.6a.249.249 0 01.423.136 20.519 20.519 0 01.24 2.2.253.253 0 01-.073.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});