define("ember-svg-jar/inlined/outdoors-rope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-rope</title><path d=\"M3.928 7.863a.5.5 0 00.487.387h1.226a.5.5 0 00.493-.583L5.72 5.21a2.53 2.53 0 01.58-2.06 2.563 2.563 0 013.9 0 2.53 2.53 0 01.58 2.06l-.39 2.462a.5.5 0 00.494.578h1.224a.5.5 0 00.488-.393l.384-1.747a4.838 4.838 0 10-9.46 0zM12.816 17.143a.5.5 0 00-.488-.393h-1.285a.5.5 0 00-.494.578l.231 1.462a2.53 2.53 0 01-.58 2.06 2.563 2.563 0 01-3.9 0 2.53 2.53 0 01-.58-2.06l.246-1.457a.5.5 0 00-.494-.583h-1.29a.5.5 0 00-.487.387l-.175.753a4.84 4.84 0 109.46 0z\"/><path d=\"M19.75 17.25a1 1 0 00-1 1 .5.5 0 01-1 0v-2a3 3 0 00-3-3H4.25a1 1 0 000 2h10.5a1 1 0 011 1v2a2.5 2.5 0 005 0 1 1 0 00-1-1zM4.25 11.75h8a1 1 0 000-2h-8a1 1 0 000 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});