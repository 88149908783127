define("ember-svg-jar/inlined/famous-character-flapjack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-flapjack</title><circle cx=\"9.5\" cy=\"8.5\" r=\"1\"/><circle cx=\"14.5\" cy=\"8.5\" r=\"1\"/><path d=\"M13.237 10.737a1.789 1.789 0 01-2.474 0A.75.75 0 009.7 11.8a3.254 3.254 0 004.6 0 .75.75 0 00-1.061-1.061z\"/><path d=\"M19 7A7 7 0 005 7v2a7 7 0 005 6.7c.164.049.168.328.014.347-.647.078-1.247.158-1.476.191h-.004a20.584 20.584 0 00-7.067 2.678A2.467 2.467 0 00.5 20.894V23.5a.5.5 0 00.5.5h6.845a.25.25 0 00.224-.362l-.238-.477a.248.248 0 01.046-.288l.8-.8A.249.249 0 018.854 22h6.292a.249.249 0 01.177.073l.8.8a.248.248 0 01.046.288l-.237.478a.248.248 0 00.011.243.252.252 0 00.213.118H23a.5.5 0 00.5-.5v-2.606a2.467 2.467 0 00-.967-1.977 21.376 21.376 0 00-7.067-2.678c-.23-.033-.827-.112-1.472-.19a.179.179 0 01-.034-.335A7 7 0 0019 9zm-3.271 10.632a.257.257 0 01.3-.3 16.478 16.478 0 013.26.914.25.25 0 01.08.4L17.7 20.4a1 1 0 01-1.551-.571zM12 14a5 5 0 01-5-5V7a4.972 4.972 0 01.062-.786A.284.284 0 017.379 6h.292A1.51 1.51 0 009.15 4.8l.073-.352a.251.251 0 01.293-.195c.827.149 2.2.252 2.234.252a.918.918 0 01.917.917.584.584 0 01-.584.578.316.316 0 01-.316-.316.75.75 0 00-1.5 0 1.834 1.834 0 002.727 1.591 2.112 2.112 0 001.169-1.722A2.319 2.319 0 0014 4.544c-.066-.12.005-.215.146-.246.121-.018.241-.037.359-.058.182-.052.272.17.272.17l.078.386A1.5 1.5 0 0016.32 6h.344a.317.317 0 01.283.275A4.959 4.959 0 0117 7v2a5 5 0 01-5 5zm-7.286 4.251a16.478 16.478 0 013.26-.914.257.257 0 01.3.3l-.415 2.193a1 1 0 01-1.551.571l-1.671-1.74a.25.25 0 01.077-.41z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});