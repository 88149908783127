define("ember-svg-jar/inlined/cash-payment-coin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-coin</title><path d=\"M17.188 9.415H14.75a.75.75 0 000 1.5h.625a.25.25 0 01.25.25v.219a.75.75 0 001.5 0v-.406a.063.063 0 01.063-.063 1.449 1.449 0 001.562-1.562c0-.931-.95-1.571-1.956-2.247a9.312 9.312 0 01-1.239-.926.21.21 0 01-.054-.139H18a.75.75 0 000-1.5h-.625a.25.25 0 01-.25-.25v-.219a.75.75 0 10-1.5 0v.406a.063.063 0 01-.063.062A1.416 1.416 0 0014 6.1c0 .93.95 1.57 1.956 2.247a9.021 9.021 0 011.249.936.185.185 0 01.043.127z\"/><path d=\"M16.426 15.353a7.575 7.575 0 10-7.574-7.574 7.582 7.582 0 007.574 7.574zm0-13.149a5.575 5.575 0 11-5.574 5.575A5.581 5.581 0 0116.426 2.2zM20.005 17.851h-.01l-3.521 1.168a.255.255 0 00-.153.142 2 2 0 01-1.821 1.192h-4a.5.5 0 010-1h4a1 1 0 000-2H11a7.12 7.12 0 00-4-1.5H4.944a3.991 3.991 0 00-1.788.422L.138 17.784a.251.251 0 00-.138.223v5.4a.248.248 0 00.129.218.249.249 0 00.253-.006L3.6 21.6a1.018 1.018 0 01.857-.1c9.966 3.358 6.628 3.373 17.866-2.312a.518.518 0 00.085-.867 2.48 2.48 0 00-2.403-.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});