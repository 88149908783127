define("ember-svg-jar/inlined/airport-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>airport-signal</title><path d=\"M5.1 9.324a1 1 0 00.588-1.284 7.047 7.047 0 01-.008-4.815 1 1 0 00-1.88-.684 9.035 9.035 0 00.012 6.189 1 1 0 001.288.594zM2.874 9.607a9.526 9.526 0 01.037-8.051A1 1 0 101.1.7 11.4 11.4 0 000 5.622a11.383 11.383 0 001.06 4.827 1 1 0 101.814-.842zM18.905 9.324a1 1 0 001.283-.594 9.035 9.035 0 00.012-6.189 1 1 0 00-1.879.684 7.047 7.047 0 01-.008 4.815 1 1 0 00.592 1.284zM22.9.7a1 1 0 10-1.807.857 9.526 9.526 0 01.037 8.051 1 1 0 101.814.842A11.383 11.383 0 0024 5.622 11.4 11.4 0 0022.9.7zM19.438 16.96A1.505 1.505 0 0018 15.872H6a1.505 1.505 0 00-1.438 1.088L2.951 22.6a1 1 0 00.962 1.275h16.174a1 1 0 00.962-1.275zM8.75 9.882h1.5a.5.5 0 01.5.5v.49a.5.5 0 01-.5.5h-1a1.5 1.5 0 00-1.5 1.5v1.5a.5.5 0 00.5.5h7.5a.5.5 0 00.5-.5v-1.5a1.5 1.5 0 00-1.5-1.5h-1a.5.5 0 01-.5-.5v-.49a.5.5 0 01.5-.5h1.5a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5h-6.5a1.5 1.5 0 00-1.5 1.5v5a1.5 1.5 0 001.5 1.5zm4.5-6.51a.5.5 0 011 0v4a.5.5 0 01-1 0zm-3.5 0a.5.5 0 011 0v4a.5.5 0 01-1 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});