define("ember-svg-jar/inlined/graph-stats-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graph-stats-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 2a10.011 10.011 0 0110 10h-2.646c-.849 0-1.018-.276-1.6-1.448-.09-.182-.184-.371-.285-.565a1.519 1.519 0 00-1.443-.773 1.479 1.479 0 00-1.348.99l-1.6 5.1-2.626-9.756A1.476 1.476 0 009 4.5a1.479 1.479 0 00-1.455 1.04 144.047 144.047 0 00-.755 2.9C5.886 12 5.809 12 4.874 12H2A10.011 10.011 0 0112 2zm0 20a10.017 10.017 0 01-9.8-8h2.674c2.568 0 2.944-1.485 3.852-5.074L9 7.851l2.583 9.6a1.477 1.477 0 001.434 1.049h.022a1.472 1.472 0 001.44-.992l1.746-5.552A3.244 3.244 0 0019.354 14H21.8a10.017 10.017 0 01-9.8 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});