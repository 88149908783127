define("ember-svg-jar/inlined/people-woman-graduate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-graduate</title><path d=\"M.5 13.985h3a.5.5 0 00.5-.5 10.666 10.666 0 00-1.25-4.679v-3.6a.75.75 0 00-1.5 0v3.6A10.666 10.666 0 000 13.485a.5.5 0 00.5.5zM21.186 20.271l-.017-.005-4.969-1.648a.5.5 0 00-.5.114l-3.358 3.232a.5.5 0 01-.69 0l-3.41-3.216a.5.5 0 00-.5-.111l-4.928 1.634a4.517 4.517 0 00-2.8 3.087.5.5 0 00.483.627h23a.5.5 0 00.483-.626 4.514 4.514 0 00-2.794-3.088zM.414 2.978l11.157 1.94a2.506 2.506 0 00.858 0l11.157-1.94a.5.5 0 000-.985L12.429.052a2.473 2.473 0 00-.858 0L.414 1.993a.5.5 0 000 .985zM10.291 14.6a.751.751 0 00.3 1.018 3.275 3.275 0 001.532.362h.007a3.264 3.264 0 001.524-.36.749.749 0 10-.714-1.318 1.758 1.758 0 01-.812.178 1.6 1.6 0 01-.816-.178.751.751 0 00-1.021.298zM9.5 12.059a.637.637 0 01.627 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.635 0 .75.75 0 101 1.114zM14.066 12.06a.636.636 0 01.628 0 .75.75 0 101-1.114 2.1 2.1 0 00-2.634 0 .75.75 0 001 1.115z\"/><path d=\"M2.807 15.546a.5.5 0 00-.231.8c.8.962 2.918 2.2 4.466 1.744a1.939 1.939 0 001.036-.736 6.646 6.646 0 003.9 1.885 6.725 6.725 0 004.05-2.023 2.079 2.079 0 001.033.694 4.753 4.753 0 004.361-1.564.5.5 0 00-.231-.8 3.907 3.907 0 01-2.172-2.827 3.135 3.135 0 00.229-1.094 2.089 2.089 0 00-.5-1.473V5.639a.25.25 0 00-.293-.246l-6.112 1.063a2.043 2.043 0 01-.686 0L5.543 5.393a.25.25 0 00-.293.246V10.1a2.073 2.073 0 00-.54 1.523 3.159 3.159 0 00.253 1.146 3.867 3.867 0 01-2.156 2.777zm3.505-4.383a.748.748 0 00.438-.678V8.548a.253.253 0 01.1-.2.256.256 0 01.217-.044A19.469 19.469 0 0012 8.952a19.476 19.476 0 004.937-.646.25.25 0 01.313.242v1.822a.74.74 0 00.395.786.619.619 0 01.105.44 1.279 1.279 0 01-.559 1.1 1.75 1.75 0 00-1 1.306 3.576 3.576 0 01-.6 1.4.494.494 0 00-.087.142 5.6 5.6 0 01-3.525 2.193c-1.033 0-3.831-1.49-4.208-3.74a1.753 1.753 0 00-1-1.306A1.276 1.276 0 016.21 11.6a.632.632 0 01.102-.437z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});