define("ember-svg-jar/inlined/wifi-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-add</title><path d=\"M17.633 11.037a6.236 6.236 0 106.372 6.235 6.313 6.313 0 00-6.372-6.235zm2.447 6.985h-1.447a.25.25 0 00-.25.25v1.388a.75.75 0 01-1.5 0v-1.388a.25.25 0 00-.25-.25h-1.447a.75.75 0 010-1.5h1.447a.25.25 0 00.25-.25v-1.39a.75.75 0 011.5 0v1.39a.25.25 0 00.25.25h1.447a.75.75 0 010 1.5zM15.159 8.764a1 1 0 101.4-1.432 9.924 9.924 0 00-13.78 0 1 1 0 101.4 1.432 7.91 7.91 0 0110.98 0z\"/><path d=\"M17.635 5.706a1 1 0 001.4-1.431 13.5 13.5 0 00-18.735 0 1 1 0 101.4 1.431 11.479 11.479 0 0115.935 0zM13.433 11.048a1.015 1.015 0 00-.286-1.386 6.367 6.367 0 00-7.9.751 1 1 0 001.4 1.432 4.347 4.347 0 015.4-.511 1.006 1.006 0 00.383.149 1 1 0 001.003-.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});