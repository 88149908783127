define("ember-svg-jar/inlined/shopping-bag-hang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-hang</title><path d=\"M3.753 20a.25.25 0 00-.25.244v.256A3.5 3.5 0 007 24h11a3.5 3.5 0 003.5-3.5v-.256a.25.25 0 00-.25-.244zM21.133 19a.25.25 0 00.249-.274l-.789-8.278A1.6 1.6 0 0019 9h-2.118a.251.251 0 01-.233-.159l-1.855-4.772a2.461 2.461 0 00-4.588 0L8.351 8.841A.251.251 0 018.118 9H6a1.6 1.6 0 00-1.593 1.448l-.789 8.278a.25.25 0 00.249.274zM12.069 4.8a.48.48 0 01.862 0l1.5 3.865A.251.251 0 0114.2 9h-3.4a.251.251 0 01-.233-.341z\"/><path d=\"M24 3.5A3.5 3.5 0 0020.5 0h-17a3.5 3.5 0 000 7h4a1 1 0 000-2h-4a1.5 1.5 0 010-3h17a1.5 1.5 0 010 3h-3a1 1 0 000 2h3A3.5 3.5 0 0024 3.5z\"/><circle cx=\"5.5\" cy=\"3.5\" r=\"1\"/><circle cx=\"19.5\" cy=\"3.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});