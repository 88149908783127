define("ember-svg-jar/inlined/shipment-deliver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-deliver</title><path d=\"M20.188 12.531h2.625a.437.437 0 01.437.437v6.125a.438.438 0 01-.437.438h-2.625a.438.438 0 01-.437-.437v-6.125a.437.437 0 01.437-.438zM6.231 15.647a.252.252 0 00.292-.113 2 2 0 011.728-1h3.654a.25.25 0 00.184-.08 5.8 5.8 0 014.1-1.919.242.242 0 01.112.025l.088.043a.25.25 0 00.36-.225V3.531a1 1 0 00-1-1H12.5a.25.25 0 00-.25.25v4.638a.517.517 0 01-.238.452.363.363 0 01-.443-.065L9.926 6.19a.249.249 0 00-.35 0L7.932 7.806a.363.363 0 01-.443.065.517.517 0 01-.238-.452V2.781A.25.25 0 007 2.531H3.751a1 1 0 00-1 1v10.838a.25.25 0 00.192.244c.157.037.521.157 3.288 1.034z\"/><path d=\"M16.251 13.531a4.938 4.938 0 00-3.6 1.8l-.075.1a.25.25 0 01-.2.1H8.251a1 1 0 00-1 1c0 .012.006.021.006.033a1 1 0 00.994.967h4a.5.5 0 010 1h-4a1.993 1.993 0 01-1.963-1.674.25.25 0 00-.172-.2L2.754 15.6h-.007a2.962 2.962 0 00-.816-.125 2.294 2.294 0 00-.414.038 1 1 0 00-.217 1.865c5.55 2.845 7.254 4.091 9.291 4.091 1.67 0 3.564-.838 7.989-2.318a.25.25 0 00.171-.237v-5.133a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});