define("ember-svg-jar/inlined/bookmarks-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bookmarks-1</title><path d=\"M1.516 17.488h4.5a.5.5 0 01.5.5v4.5a1.407 1.407 0 00.825 1.421 1.264 1.264 0 00.477.091 1.549 1.549 0 001.107-.516L23.417 8.992a2 2 0 000-2.825L17.834.585A1.982 1.982 0 0016.421 0a1.978 1.978 0 00-1.412.585L.516 15.076a1.408 1.408 0 00-.422 1.587 1.41 1.41 0 001.422.825z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});