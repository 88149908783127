define("ember-svg-jar/inlined/laptop-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-disable</title><path d=\"M9.719 15.363a2.428 2.428 0 01-1.543-1.387.492.492 0 00-.47-.36H.626a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31H9.38a.249.249 0 00.249-.242 7.839 7.839 0 01.245-1.714.25.25 0 00-.155-.297zM3.626 12.126a1 1 0 001-1v-8a1 1 0 011-1h12a1 1 0 011 1v6.343a.249.249 0 00.211.247 7.828 7.828 0 011.454.371.251.251 0 00.335-.236V3.126a3.009 3.009 0 00-3-3h-12a3.009 3.009 0 00-3 3v8a1 1 0 001 1zM17.626 11.376a6.25 6.25 0 106.25 6.25 6.256 6.256 0 00-6.25-6.25zm-3.6 8.289a.247.247 0 01-.184-.133 4.16 4.16 0 01-.467-1.906 4.254 4.254 0 014.25-4.25 4.167 4.167 0 011.907.467.251.251 0 01.063.4L14.242 19.6a.245.245 0 01-.215.065zm3.6 2.211a4.167 4.167 0 01-1.907-.467.251.251 0 01-.063-.4l5.353-5.352a.248.248 0 01.216-.07.251.251 0 01.184.133 4.16 4.16 0 01.467 1.906 4.255 4.255 0 01-4.25 4.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});