define("ember-svg-jar/inlined/phone-action-power-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-power-switch</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12 12.031a1 1 0 001-1v-3a1 1 0 10-2 0v3a1 1 0 001 1z\"/><path d=\"M14.354 9.193a1 1 0 00-.275 1.387 2.5 2.5 0 11-4.158 0 1 1 0 10-1.662-1.112 4.5 4.5 0 107.482 0 1 1 0 00-1.387-.275z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});