define("ember-svg-jar/inlined/fruit-grapes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fruit-grapes</title><circle cx=\"12\" cy=\"21.5\" r=\"2.5\"/><circle cx=\"12\" cy=\"10.5\" r=\"2.5\"/><circle cx=\"6\" cy=\"11\" r=\"2.5\"/><circle cx=\"18\" cy=\"11\" r=\"2.5\"/><circle cx=\"15\" cy=\"16.5\" r=\"2.5\"/><circle cx=\"9\" cy=\"16.5\" r=\"2.5\"/><path d=\"M12 7.5a1 1 0 001-1V1a1 1 0 00-2 0v5.5a1 1 0 001 1zM14.655 6.6a6.4 6.4 0 007.326-4.57.5.5 0 00-.4-.628 6.394 6.394 0 00-7.326 4.57.5.5 0 00.4.628zM9.345 6.6a6.4 6.4 0 01-7.326-4.57.5.5 0 01.4-.628 6.4 6.4 0 017.326 4.57.5.5 0 01-.4.628z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});