define("ember-svg-jar/inlined/tool-helmet-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tool-helmet-lock</title><path d=\"M14.506 14.014a4.484 4.484 0 018.116-2.4A.209.209 0 0023 11.5 11.5 11.5 0 106.874 22.02a1.521 1.521 0 00.627.139 1.182 1.182 0 00.411-.069l4.408-1.29a.25.25 0 00.18-.24V17a3 3 0 011.852-2.771.251.251 0 00.154-.215zm-.2-4.7c-1.056 3.574-6.185 5.845-10.9 6.79a.251.251 0 01-.269-.126 9.416 9.416 0 01-.842-6.8.251.251 0 01.24-.178h11.533a.251.251 0 01.24.321z\"/><path d=\"M22.714 15.519a.25.25 0 01-.214-.248v-1.017a3.5 3.5 0 00-7 0v1.017a.25.25 0 01-.214.248A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.481zM19 21.251a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.249.249 0 01-.25.249h-2.5a.249.249 0 01-.25-.249v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});