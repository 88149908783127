define("ember-svg-jar/inlined/style-three-pin-shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shopping-bag</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.846 12.822a2.5 2.5 0 01-2.494 2.678h-4.7a2.5 2.5 0 01-2.494-2.678L7.5 7.964A.5.5 0 018 7.5h.5A.5.5 0 009 7V6a3 3 0 016 0v1a.5.5 0 00.5.5h.5a.5.5 0 01.5.464z\"/><path d=\"M13.5 6a1.5 1.5 0 00-3 0v1a.5.5 0 00.5.5h2a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});