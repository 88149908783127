define("ember-svg-jar/inlined/koala-bamboo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>koala-bamboo</title><path d=\"M5 5.988a3.225 3.225 0 00.835-.111c-.1 3.111 3.969 4.872 5.413 4.872s5.513-1.763 5.414-4.878a3.216 3.216 0 00.86.119 2.995 2.995 0 100-5.989 3.032 3.032 0 00-2.778 1.881 2.107 2.107 0 00-1.492-.633h-4.01a2.106 2.106 0 00-1.471.614A3.034 3.034 0 005 0a2.994 2.994 0 100 5.988zm8.748-1.735A.75.75 0 1113 5a.749.749 0 01.749-.747zm-2.5 1.5c.644 0 1.165.782 1.165 1.748s0 1.748-1.165 1.748-1.164-.782-1.164-1.749.522-1.747 1.165-1.747zm-2.5-1.5A.75.75 0 118 5a.749.749 0 01.749-.747z\"/><path d=\"M22.749 7.5h-.722c.29-.4.578-.788.85-1.151a1 1 0 00-1.6-1.2c-.518.691-2.162 2.906-3.057 4.35h-1.457c-.07.08-.143.159-.218.237a8.534 8.534 0 01-5.294 2.513A8.53 8.53 0 015.962 9.74a6.145 6.145 0 01-1.046-1.43C2.956 10.321.5 13.491.5 16.749.5 21.757 3.892 24 7.251 24h8a2.25 2.25 0 100-4.5h-.967a1.248 1.248 0 01-1.148-1.743l1.285-3A1.249 1.249 0 0115.57 14h.359a41.325 41.325 0 00-1.448 3.9 1 1 0 001.909.6 38.837 38.837 0 011.727-4.5h.134a2.248 2.248 0 001.78-3.623c.254-.4.574-.874.924-1.377h1.794a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});