define("ember-svg-jar/inlined/mailing-service-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>mailing-service-email</title><path d=\"M23 1.91H1a1 1 0 00-1 1v2.87a1 1 0 00.54.89l11 5.74a1 1 0 00.92 0l11-5.74a1 1 0 00.54-.89V2.91a1 1 0 00-1-1zm-1 3.27l-10 5.21L2 5.18V3.91h20z\"/><path d=\"M23.52 8.75a1 1 0 00-1 0L12 14.22 1.46 8.72A1 1 0 000 9.61v11.48a1 1 0 001 1h22a1 1 0 001-1V9.61a1 1 0 00-.48-.86zM22 16.94l-5.09-3L22 11.26zm-14.91-3l-5.09 3v-5.68zM2 20.09v-.82L9.17 15l2.37 1.24a1 1 0 00.92 0L14.83 15 22 19.26v.82z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});