define("ember-svg-jar/inlined/disability-sit-cane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-sit-cane</title><circle cx=\"6.936\" cy=\"2.756\" r=\"2.5\"/><path d=\"M10.436 18.494h-6.5a1 1 0 000 2h6.5a1 1 0 000-2z\"/><path d=\"M15.6 17.439a3.484 3.484 0 00-3.485-3.184H9.687a.506.506 0 01-.5-.452l-.518-3.548h4.143a1.5 1.5 0 000-3H6.936a1.5 1.5 0 00-1.484 1.717l.766 5.247a3.512 3.512 0 003.469 3.036h2.423a.494.494 0 01.5.44l.335 4.669a1.5 1.5 0 102.99-.24zM18.064 5.744a3 3 0 00-3 3v.747a1 1 0 002 0v-.747a1 1 0 012 0v14a1 1 0 002 0v-14a3 3 0 00-3-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});