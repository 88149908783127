define("ember-svg-jar/inlined/office-file-xls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-xls</title><path d=\"M17.5 6h3.9a.25.25 0 00.177-.427L16.427.425A.25.25 0 0016 .6v3.9A1.5 1.5 0 0017.5 6z\"/><path d=\"M21.75 7H17.5A2.5 2.5 0 0115 4.5V.248A.25.25 0 0014.75 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V7.248A.25.25 0 0021.75 7zM9.057 18.191a.625.625 0 11-1.117.559s-.862-1.709-.881-1.762a.066.066 0 00-.121 0l-.881 1.76a.624.624 0 01-.557.352.616.616 0 01-.279-.067.624.624 0 01-.28-.838l1.3-2.608a.252.252 0 000-.224L4.94 12.75a.625.625 0 011.117-.559l.876 1.751a.071.071 0 00.132 0l.875-1.751a.625.625 0 111.117.559l-1.3 2.609a.252.252 0 000 .224zm4.443.909h-2a1.627 1.627 0 01-1.625-1.625v-5a.625.625 0 111.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zm2.927-4.758l1.84 1.226A1.928 1.928 0 0117.2 19.1h-1.7a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227a1.927 1.927 0 011.07-3.531h1.7a.625.625 0 010 1.25H16.8a.678.678 0 00-.376 1.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});