define("ember-svg-jar/inlined/retouch-landscape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-landscape</title><path d=\"M10.907 14.113L9.523 16.99a.249.249 0 01-.407.063l-.887-.945a1.091 1.091 0 00-1.838.138L4.4 19.488a.5.5 0 00.426.762h9.346a.5.5 0 00.466-.683L12.52 14.16a.83.83 0 00-1.613-.047z\"/><path d=\"M18 12.5a1 1 0 00-1 1v8a.5.5 0 01-.5.5h-14a.5.5 0 01-.5-.5v-14a.5.5 0 01.5-.5h4a1 1 0 000-2H2a2 2 0 00-2 2v15a2 2 0 002 2h15a2 2 0 002-2v-8.5a1 1 0 00-1-1z\"/><circle cx=\"6.75\" cy=\"11.75\" r=\"1.75\"/><path d=\"M23.707 14.949a1 1 0 000-1.414L14.774 4.6a1 1 0 00-1.414 1.417l8.933 8.932a1 1 0 001.414 0zM11.623 3.925a.75.75 0 001.061-1.06L11.27 1.451a.75.75 0 10-1.061 1.06zM14.263 3.447a.755.755 0 00.882-.59L15.534.9a.75.75 0 00-1.471-.3l-.389 1.962a.749.749 0 00.589.885zM15.944 3.514a.75.75 0 10.575 1.386l1.847-.765a.75.75 0 00-.574-1.386zM9.654 6.777l1.962-.391a.75.75 0 10-.293-1.471l-1.962.391a.749.749 0 00-.589.881.758.758 0 00.882.59zM13.252 6.78a.752.752 0 00-.981.406l-.764 1.847a.75.75 0 101.386.574l.764-1.847a.749.749 0 00-.405-.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});