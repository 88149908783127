define("ember-svg-jar/inlined/lucide-cloud-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2 2l20 20M5.782 5.782A7 7 0 009 19h8.5a4.5 4.5 0 001.307-.193M21.532 16.5A4.5 4.5 0 0017.5 10h-1.79A7.008 7.008 0 0010 5.07\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});