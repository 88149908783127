define("ember-svg-jar/inlined/decoration-flower-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>decoration-flower-pot</title><path d=\"M22 12.75a1 1 0 00-1-1h-5.89a.5.5 0 01-.492-.589 7.009 7.009 0 014.254-5.478 1 1 0 10-.744-1.857 9.062 9.062 0 00-5.554 7.488.5.5 0 01-.495.436h-.158a.5.5 0 01-.495-.436 9.062 9.062 0 00-5.554-7.488 1 1 0 10-.744 1.857 7.009 7.009 0 014.254 5.478.5.5 0 01-.492.589H3a1 1 0 00-1 1v.5a1 1 0 001 1h18a1 1 0 001-1zM20.5 15.75h-17a.5.5 0 00-.5.5v3a4.505 4.505 0 004.5 4.5h9a4.505 4.505 0 004.5-4.5v-3a.5.5 0 00-.5-.5zM16.228 3.231c.5.069 1.189-1.3 1.265-1.844a1 1 0 00-1.981-.274c-.076.547.221 2.05.716 2.118z\"/><path d=\"M19.4 9.526a1 1 0 00-.689-1.234c-.531-.151-2.061-.065-2.2.416s1.12 1.357 1.652 1.508a1 1 0 001.237-.69zM21.939 5.619c-.551-.043-2.033.344-2.071.843s1.366 1.108 1.916 1.151a1 1 0 10.155-1.994zM23.289 1.042a1.576 1.576 0 00-2.153-.577C20.382.9 18.8 2.724 19.194 3.406s2.764.224 3.518-.211a1.576 1.576 0 00.577-2.153zM7.771 3.231c.5-.068.793-1.571.717-2.118a1 1 0 00-1.981.274c.076.547.769 1.913 1.264 1.844zM12 5.757c.5 0 1-1.447 1-2a1 1 0 10-2 0c0 .551.5 1.999 1 2zM5.838 10.216c.532-.151 1.788-1.027 1.652-1.508s-1.666-.567-2.2-.416a1 1 0 10.545 1.924zM4.132 6.462c-.038-.5-1.52-.886-2.071-.843a1 1 0 10.155 1.994c.55-.043 1.955-.653 1.916-1.151zM4.806 3.406C5.2 2.724 3.618.9 2.864.465A1.576 1.576 0 001.288 3.2c.754.43 3.124.888 3.518.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});