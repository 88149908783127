define("ember-svg-jar/inlined/file-code-alternative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-code-alternative</title><path d=\"M21.707 5.708L16.293.294A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.535 4.535a.5.5 0 01.147.354z\"/><path d=\"M8.527 13.315a.252.252 0 010-.342l2.537-2.718A1 1 0 009.6 8.89l-3.33 3.571a1 1 0 000 1.365L9.6 17.4a1 1 0 001.414.049l.025-.024a1 1 0 00.023-1.389zM14.4 8.89a1 1 0 10-1.462 1.364l2.537 2.719a.249.249 0 010 .341l-2.537 2.718a1 1 0 001.437 1.391l.025-.023 3.333-3.571a1 1 0 000-1.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});