define("ember-svg-jar/inlined/button-fast-forward-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>button-fast-forward-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.344 12.774l-4.211 3.445a1 1 0 01-1.633-.774V14.9a.25.25 0 00-.389-.208L9.555 16.4A1 1 0 018 15.564V8.433A1 1 0 019.555 7.6l2.556 1.7a.25.25 0 00.389-.2v-.546a1 1 0 011.633-.774l4.211 3.445a1 1 0 010 1.548z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});