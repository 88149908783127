define("ember-svg-jar/inlined/tiger-footprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tiger-footprint</title><path d=\"M19.344 19.375c-.438-1.312-.875-2.625-2.625-2.625s-2.188 1.313-2.625 2.625c-.277.831-2.188.875-2.188 2.625a1.6 1.6 0 001.75 1.75c1.313 0 1.75-1.312 3.063-1.312s1.75 1.312 3.062 1.312a1.6 1.6 0 001.75-1.75c0-1.75-1.911-1.794-2.187-2.625z\"/><ellipse cx=\"14.531\" cy=\"13.688\" rx=\"1.313\" ry=\"1.75\"/><ellipse cx=\"11.031\" cy=\"17.625\" rx=\"1.313\" ry=\"1.75\"/><ellipse cx=\"18.906\" cy=\"13.688\" rx=\"1.313\" ry=\"1.75\"/><ellipse cx=\"22.406\" cy=\"17.625\" rx=\"1.313\" ry=\"1.75\"/><path d=\"M9.881 11.254c.341-1.147 1.93-2.04 2.53-2.293a.5.5 0 00.307-.461 7.14 7.14 0 00-.924-3.491 2.5 2.5 0 10-3.467-2.99A4.945 4.945 0 005.166 2a2.5 2.5 0 00-4.885.75 2.432 2.432 0 001.384 2.219A7.131 7.131 0 00.718 8.5a.5.5 0 00.307.461c.6.253 2.19 1.146 2.53 2.293a3.107 3.107 0 00.451 2.419 3.206 3.206 0 002.712 1.077 3.208 3.208 0 002.713-1.077 3.11 3.11 0 00.45-2.419zM4.469 8.5a1 1 0 111-1 1 1 0 01-1 1zm1.711 4.231v-.5h-.5a.5.5 0 010-1h2a.5.5 0 110 1h-.5v.5a.5.5 0 11-1 0zM7.969 7.5a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});