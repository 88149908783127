define("ember-svg-jar/inlined/remove-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>remove-badge</title><path d=\"M23.19 10.293l-1.921-1.545a1.161 1.161 0 01-.429-1.035l.267-2.452a2.167 2.167 0 00-2.386-2.388l-2.453.268a1.168 1.168 0 01-1.036-.429L13.688.79a2.234 2.234 0 00-3.376 0L8.768 2.711a1.163 1.163 0 01-1.037.43L5.28 2.873a2.167 2.167 0 00-2.387 2.388l.267 2.451a1.162 1.162 0 01-.429 1.036L.81 10.293a2.164 2.164 0 000 3.375l1.921 1.545a1.162 1.162 0 01.429 1.035L2.893 18.7a2.167 2.167 0 002.386 2.388l2.453-.268a1.169 1.169 0 011.036.429l1.544 1.922a2.167 2.167 0 003.376 0l1.544-1.922a1.174 1.174 0 011.037-.429l2.451.268a2.167 2.167 0 002.387-2.388l-.267-2.452a1.161 1.161 0 01.429-1.035l1.921-1.545a2.164 2.164 0 000-3.375zm-6.483 4.981a1 1 0 010 1.414 1.014 1.014 0 01-1.414 0l-3.116-3.116a.252.252 0 00-.354 0l-3.116 3.116a1.009 1.009 0 01-1.414 0 1 1 0 010-1.414l3.116-3.116a.249.249 0 000-.353L7.293 8.688a1 1 0 111.414-1.414l3.116 3.117a.252.252 0 00.354 0l3.116-3.117a1 1 0 111.414 1.414l-3.116 3.117a.249.249 0 000 .353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});