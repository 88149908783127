define("ember-svg-jar/inlined/family-child-teeter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-teeter</title><path d=\"M23.976 12.982a1 1 0 00-1.192-.76l-2.968.656a.252.252 0 00-.194.209 5.316 5.316 0 01-.292 1.539.25.25 0 00.282.346l3.6-.8a1 1 0 00.764-1.19zM4.4 16.825a.249.249 0 00-.243-.2l-1.241-.008-2.132.472a1 1 0 00.432 1.953l3.3-.729a.252.252 0 00.172-.352 4.34 4.34 0 01-.288-1.136zM15.523 14.1a.25.25 0 00-.3-.208l-6.778 1.5a.25.25 0 00-.191.293 6.892 6.892 0 01.244 1.43.251.251 0 00.3.246l1.9-.419a.254.254 0 01.211.049.25.25 0 01.093.2v2.687H10a1 1 0 000 2h4a1 1 0 000-2h-1v-3.24a.251.251 0 01.2-.244l2.88-.637a.249.249 0 00.111-.432 2 2 0 01-.668-1.225z\"/><circle cx=\"3\" cy=\"8.125\" r=\"2\"/><path d=\"M6.7 18.105a1 1 0 00.784-1.176l-.339-1.7a2.006 2.006 0 00-1.96-1.606H4v-1a1 1 0 00-2 0v2a1 1 0 001 1h1.976a.25.25 0 01.245.2l.3 1.5a1.011 1.011 0 001.179.782z\"/><circle cx=\"21\" cy=\"4.125\" r=\"2\"/><path d=\"M17.3 14.8a1.026 1.026 0 00.2.019 1 1 0 00.98-.8l.308-2.178a.25.25 0 01.248-.215H21a1 1 0 001-1v-2a1 1 0 00-2 0v1h-1.181a2.006 2.006 0 00-1.96 1.606l-.339 2.394a1 1 0 00.78 1.174z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});