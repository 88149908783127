define("ember-svg-jar/inlined/messages-bubble-heart-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-heart-2-alternate</title><path d=\"M9.547 15.417a.151.151 0 00-.083-.153.149.149 0 00-.171.029L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7a6.494 6.494 0 016.062 8.836.25.25 0 00.17.333 5.021 5.021 0 011.318.552.248.248 0 00.361-.123A8.493 8.493 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.952-2.953a.249.249 0 00.063-.25 5 5 0 01-.175-2.087z\"/><path d=\"M21.038 12.546a3.5 3.5 0 00-3.057.987l-.482.482-.481-.482a3.525 3.525 0 00-4.993 4.978l5.114 5.335a.5.5 0 00.721 0l5.107-5.327a3.526 3.526 0 00-1.929-5.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});