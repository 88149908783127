define("ember-svg-jar/inlined/image-document-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>image-document-svg</title><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.427 10.867l1.84 1.227A1.927 1.927 0 017.2 15.625H5.5a.625.625 0 010-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.227A1.927 1.927 0 016.8 8.375h1.7a.625.625 0 010 1.25H6.8a.678.678 0 00-.376 1.242zm7.7-.367a8.176 8.176 0 01-1.627 4.875.625.625 0 01-1 0A8.176 8.176 0 019.875 10.5V9a.625.625 0 011.25 0v1.5a6.93 6.93 0 00.674 2.966.252.252 0 00.4.008 6.922 6.922 0 00.678-2.974V9a.625.625 0 011.25 0zm5 .5a.625.625 0 01-1.25 0v-.5a.875.875 0 00-1.75 0v3a.875.875 0 001.671.365c.034-.075.082-.24-.1-.24h-.2a.625.625 0 010-1.25h1a.625.625 0 01.625.625v.5a2.125 2.125 0 01-4.25 0v-3a2.125 2.125 0 014.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});