define("ember-svg-jar/inlined/weather-cloud-wind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-wind</title><path d=\"M22.513 10.943a.252.252 0 01-.071-.218A4.6 4.6 0 0022.5 10a4.489 4.489 0 00-3.517-4.392.251.251 0 01-.171-.136 7 7 0 00-12.988.915.251.251 0 01-.205.173A6.509 6.509 0 000 13a.143.143 0 00.189.135A2.5 2.5 0 011 13h.756a.25.25 0 00.25-.237A4.526 4.526 0 016.581 8.5a1 1 0 00.986-.835 5 5 0 019.647-.83 1 1 0 00.9.667 2.5 2.5 0 012.232 3.365 1 1 0 00.366 1.169A3 3 0 0119 17.5h-2.854a.25.25 0 00-.24.318 2.521 2.521 0 010 1.364.246.246 0 00.041.219.251.251 0 00.2.1H19a5 5 0 003.513-8.557z\"/><path d=\"M10.5 16.5a1 1 0 000-2H1a1 1 0 000 2zM14.5 18.5a1 1 0 00-1-1H4a1 1 0 000 2h9.5a1 1 0 001-1zM10.5 20.5H1a1 1 0 000 2h9.5a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});