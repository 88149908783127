define("ember-svg-jar/inlined/board-game", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>board-game</title><rect x=\"17\" y=\"8.5\" width=\"7\" height=\"7\" rx=\".25\" ry=\".25\"/><rect x=\"8.5\" y=\"17\" width=\"7\" height=\"7\" rx=\".25\" ry=\".25\"/><rect x=\"8.5\" width=\"7\" height=\"7\" rx=\".25\" ry=\".25\"/><rect y=\"8.5\" width=\"7\" height=\"7\" rx=\".25\" ry=\".25\"/><path d=\"M8.75 8.5a.25.25 0 00-.25.25v6.5a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25v-6.5a.25.25 0 00-.25-.25zm3.25 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM17 6.75a.25.25 0 00.25.25h6.5a.25.25 0 00.25-.25V2.5A2.5 2.5 0 0021.5 0h-4.25a.25.25 0 00-.25.25zM20.5 2A1.5 1.5 0 1119 3.5 1.5 1.5 0 0120.5 2zM7 17.25a.25.25 0 00-.25-.25H.25a.25.25 0 00-.25.25v4.25A2.5 2.5 0 002.5 24h4.25a.25.25 0 00.25-.25zM3.5 22A1.5 1.5 0 115 20.5 1.5 1.5 0 013.5 22zM17.25 17a.25.25 0 00-.25.25v6.5a.25.25 0 00.25.25h4.25a2.5 2.5 0 002.5-2.5v-4.25a.25.25 0 00-.25-.25zm3.25 5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM6.75 7A.25.25 0 007 6.75V.25A.25.25 0 006.75 0H2.5A2.5 2.5 0 000 2.5v4.25A.25.25 0 00.25 7zM3.5 2A1.5 1.5 0 112 3.5 1.5 1.5 0 013.5 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});