define("ember-svg-jar/inlined/navigation-right-circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-right-circle-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.059 15.71a.5.5 0 01-.309-.462v-1.5a.25.25 0 00-.25-.25H8.75a1.25 1.25 0 00-.255 2.473.5.5 0 01-.151.988A3.99 3.99 0 018.75 9h5.75a.25.25 0 00.25-.25v-1.5a.5.5 0 01.854-.354l4 4a.5.5 0 010 .707l-4 4a.5.5 0 01-.545.107z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});