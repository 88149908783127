define("ember-svg-jar/inlined/transfusion-bag-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transfusion-bag-1</title><path d=\"M4.487 9.985a3.5 3.5 0 003.5-3.5c0-1.5-1.952-4.958-2.663-6.047a1.038 1.038 0 00-1.675 0C2.938 1.529.987 4.986.987 6.485a3.5 3.5 0 003.5 3.5zM8.915 21.834a9.866 9.866 0 01-1.31-.358 1 1 0 10-.667 1.884 11.528 11.528 0 001.582.44.965.965 0 00.2.019 1 1 0 00.2-1.98zM3.7 18.588A1 1 0 002.107 19.8a11.389 11.389 0 001.087 1.23A1 1 0 104.6 19.6a9.3 9.3 0 01-.9-1.012zM2.207 15.384a10.642 10.642 0 01-.2-1.357 1 1 0 10-1.994.151 12.7 12.7 0 00.236 1.612 1 1 0 00.978.8 1.024 1.024 0 00.2-.021 1 1 0 00.78-1.185zM10.156 4.993a8.806 8.806 0 011.338.049.914.914 0 00.116.007 1 1 0 00.113-1.993A10.194 10.194 0 0010.067 3a1 1 0 10.089 2zM14.376 5.927a8.477 8.477 0 011.139.708A1 1 0 0016.7 5.023a10.666 10.666 0 00-1.406-.874 1 1 0 00-.918 1.778zM22.453 10.88l-1.613-.68a.244.244 0 01-.135-.136.248.248 0 010-.191l.337-.8A1 1 0 0019.2 8.3l-.338.8a.245.245 0 01-.136.134.248.248 0 01-.191 0L16.92 8.56a2.5 2.5 0 00-3.272 1.34l-3.481 8.3a2.5 2.5 0 001.338 3.272l5.533 2.321a2.5 2.5 0 003.272-1.338l3.481-8.3a2.5 2.5 0 00-1.338-3.275zm-1.769 4l-2.031 4.842a1.166 1.166 0 01-1.527.624L13.9 18.994a1.166 1.166 0 01-.624-1.527l2.031-4.842A1.167 1.167 0 0116.832 12l3.228 1.354a1.167 1.167 0 01.624 1.528z\"/><path d=\"M18.481 15.179l-1.844-.774a.5.5 0 00-.387.922l1.85.773a.5.5 0 00.654-.268.5.5 0 00-.273-.653zM17.708 17.022l-1.844-.773a.5.5 0 10-.387.922l1.844.773a.491.491 0 00.194.039.5.5 0 00.193-.961z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});