define("ember-svg-jar/inlined/stove-induction-pot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>stove-induction-pot</title><path d=\"M19.75 6.25H18.5a.5.5 0 01-.5-.5V4.5a.5.5 0 01.5-.5h.75a.75.75 0 000-1.5h-4.088a.5.5 0 01-.487-.385 2.749 2.749 0 00-5.35 0 .5.5 0 01-.486.385H4.75a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v1.25a.5.5 0 01-.5.5H4.25a.75.75 0 000 1.5H5.5a.5.5 0 01.5.5v4.25C6 15.081 7.292 16 8.5 16h7c1.208 0 2.5-.919 2.5-3.5V8.25a.5.5 0 01.5-.5h1.25a.75.75 0 000-1.5zm-6.668-3.875a.25.25 0 01-.216.125h-1.732a.25.25 0 01-.216-.375 1.249 1.249 0 012.164 0 .248.248 0 010 .25zM5.077 18.739A6.487 6.487 0 003.75 21.75 2.176 2.176 0 006 24a2.176 2.176 0 002.25-2.25 6.479 6.479 0 00-1.326-3.01 1.134 1.134 0 00-1.847-.001zM11.025 18.035a7.512 7.512 0 00-1.532 3.458A2.425 2.425 0 0012 24a2.425 2.425 0 002.507-2.507 7.493 7.493 0 00-1.532-3.457 1.2 1.2 0 00-1.95-.001zM17.077 18.739a6.487 6.487 0 00-1.327 3.011 2.25 2.25 0 004.5 0 6.479 6.479 0 00-1.326-3.01 1.134 1.134 0 00-1.847-.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});