define("ember-svg-jar/inlined/underwear-briefs-male", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-briefs-male</title><path d=\"M23.5 6.5A.5.5 0 0024 6v-.5A1.5 1.5 0 0022.5 4h-21A1.5 1.5 0 000 5.5V6a.5.5 0 00.5.5zM6.857 8.327A.249.249 0 006.619 8H.5a.5.5 0 00-.5.5v4.571a1.5 1.5 0 001.31 1.487 15.955 15.955 0 015.333 1.862.249.249 0 00.362-.286 13.889 13.889 0 01-.148-7.807zM17.381 8a.249.249 0 00-.238.327A13.889 13.889 0 0117 16.134a.249.249 0 00.362.286 15.952 15.952 0 015.334-1.862A1.5 1.5 0 0024 13.071V8.5a.5.5 0 00-.5-.5zM15.675 8.676A1 1 0 0014.729 8H9.271a1 1 0 00-.946.676c-1.115 3.256-.069 7.9 1.758 10.859a1.494 1.494 0 001.085.465h1.664a1.494 1.494 0 001.085-.465c1.827-2.956 2.873-7.603 1.758-10.859z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});