define("ember-svg-jar/inlined/content-notebook-pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-notebook-pencil</title><path d=\"M19.75 6h3.5a.5.5 0 00.475-.658l-1.75-5a.5.5 0 00-.95 0l-1.75 5A.5.5 0 0019.75 6zM16.5 2H4a.5.5 0 00-.5.5v19a.5.5 0 00.5.5h12.5a.5.5 0 00.5-.5v-19a.5.5 0 00-.5-.5zm-9 8.5v-3A.5.5 0 018 7h5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5zM2 2h-.5A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22H2a.5.5 0 00.5-.5v-19A.5.5 0 002 2z\"/><rect x=\"19\" y=\"7\" width=\"5\" height=\"12.5\" rx=\".5\" ry=\".5\"/><path d=\"M23.5 20.5h-4a.5.5 0 00-.5.5v.5a2.5 2.5 0 105 0V21a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});