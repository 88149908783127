define("ember-svg-jar/inlined/fitness-biceps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-biceps</title><path d=\"M18.849 8.115a5.7 5.7 0 00-5.774 3.291.423.423 0 01-.539.218 5.853 5.853 0 00-5.39.637.523.523 0 01-.813-.359l-.818-5.939a.262.262 0 00-.258-.224H4.6a.783.783 0 010-1.565h.661a1.835 1.835 0 011.804 1.568L7.259 7.1a.523.523 0 00.82.352 3.063 3.063 0 001.3-2.176 3.131 3.131 0 00-3.116-3.454H4.352A3.636 3.636 0 00.745 4.894 69.551 69.551 0 000 15.393c0 3.614 5.61 6.785 12 6.785 7.751 0 11.672-2.511 11.984-7.674a5.969 5.969 0 00-5.135-6.389z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});