define("ember-svg-jar/inlined/pen-write", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pen-write</title><path d=\"M19 13a1 1 0 00-1 1v7a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1h7a1 1 0 000-2H3a3 3 0 00-3 3v18a3 3 0 003 3h14a3 3 0 003-3v-7a1 1 0 00-1-1z\"/><path d=\"M8.73 12.521a.5.5 0 00-.353.361l-.557 2.237-1.252 1.252a.75.75 0 000 1.061.76.76 0 001.06 0l1.264-1.262 2.207-.555a.5.5 0 00.36-.35.5.5 0 00-.127-.487L9.217 12.65a.5.5 0 00-.487-.129zM23.707.293a1 1 0 00-1.414 0l-.9.9a2.011 2.011 0 00-1.533.208l-.052-.051a3 3 0 00-4.235.006l-3.612 3.605a1 1 0 000 1.414 1.013 1.013 0 001.415 0L17 2.761a1 1 0 011.407.007l-.707.707a.5.5 0 000 .707L19.818 6.3a.5.5 0 00.707 0l1.768-1.768a2 2 0 00.517-1.93l.9-.9a1 1 0 00-.003-1.409z\"/><path d=\"M12.4 14.22a.5.5 0 00.353-.147l6.356-6.355a.5.5 0 000-.707L16.99 4.889a.514.514 0 00-.707 0l-6.356 6.356a.5.5 0 000 .707l2.121 2.121a.5.5 0 00.352.147z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});