define("ember-svg-jar/inlined/task-list-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-approve</title><path d=\"M20.5 2h-5a.241.241 0 01-.208-.121 3.827 3.827 0 00-6.588 0A.241.241 0 018.5 2h-5A1.5 1.5 0 002 3.5v19A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0020.5 2zM19 16.26a2.5 2.5 0 01-.6 1.626l-1.919 2.24a2.5 2.5 0 01-1.9.873H5.5a.5.5 0 01-.5-.5V5.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5z\"/><path d=\"M14.749 8.663l-3.885 5.179a.249.249 0 01-.376.027l-1.816-1.815A1.25 1.25 0 106.9 13.821l2.161 2.162a2.332 2.332 0 001.645.68c.055 0 .109 0 .164-.006a2.322 2.322 0 001.7-.926l4.177-5.568a1.25 1.25 0 10-2-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});