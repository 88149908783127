define("ember-svg-jar/inlined/family-umbrella-protect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-umbrella-protect</title><path d=\"M1.677 8.6l.5-.258a5 5 0 015.058.288l.314.209a.5.5 0 00.5.031l1.5-.752a5 5 0 014.473 0l1.5.752a.5.5 0 00.5-.031l.314-.209a5 5 0 015.058-.288l.5.258a.5.5 0 00.725-.5C22.083 3.549 17.44 0 11.792 0S1.5 3.549.952 8.1a.5.5 0 00.725.5zM23.366 18.7a1 1 0 00-.009-1.414c-.148-.145-.6-.591-.6-2.255a3.969 3.969 0 10-1.35 2.98 3.441 3.441 0 00.544.7 1 1 0 001.414-.01zM18.792 17a1.968 1.968 0 01-1.932-1.6.25.25 0 01.19-.29 2.535 2.535 0 001.542-.913.251.251 0 01.4 0 2.537 2.537 0 001.541.913.251.251 0 01.191.29 1.969 1.969 0 01-1.932 1.6zM4.292 19a3.948 3.948 0 10-3.947-3.947A3.952 3.952 0 004.292 19zM2.42 14.519a.251.251 0 01.368-.146 4.859 4.859 0 002.487.68 4.768 4.768 0 00.669-.046.251.251 0 01.282.278 1.947 1.947 0 01-3.881-.232 1.924 1.924 0 01.075-.534zM18.792 19.634a4.538 4.538 0 00-2.845 1 .5.5 0 00-.089.689 5.119 5.119 0 01.293.437.5.5 0 00.429.24h5.251a.5.5 0 00.374-.832 4.552 4.552 0 00-3.413-1.534zM4.292 19.6a4.523 4.523 0 00-3.431 1.57.5.5 0 00.378.83h5.766a.5.5 0 00.428-.242c.041-.068.083-.134.127-.2a.5.5 0 00-.064-.63A4.52 4.52 0 004.292 19.6zM13.165 20.516a.25.25 0 01-.063-.438 2.251 2.251 0 10-2.619 0 .25.25 0 01-.062.439 4.229 4.229 0 00-2.608 2.839.5.5 0 00.479.644h7a.5.5 0 00.479-.644 4.228 4.228 0 00-2.606-2.84zM11.542 9a.752.752 0 00-.75.75v4a1 1 0 002 0v-4a.751.751 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});