define("ember-svg-jar/inlined/social-media-brightkite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-brightkite</title><path d=\"M22.73 0H7.65A2.58 2.58 0 005 1.76L.78 23.4a.5.5 0 00.49.6h.16c.78-.26 19-6.49 20.52-7.18A2.08 2.08 0 0023.23 15V.5a.5.5 0 00-.5-.5zm-5.32 12.51a3.58 3.58 0 01-3.1 2.69 6.17 6.17 0 01-4.57-1.11V3.3l3-.43v4.27a2.43 2.43 0 012.17-1 2.26 2.26 0 012.29 1.59 7.89 7.89 0 01.21 4.78z\"/><path d=\"M14.15 8.46c-1.11-.13-1.38.38-1.38.38V13s1.47.49 1.94-.33.68-4.07-.56-4.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});