define("ember-svg-jar/inlined/road-sign-left-arrow-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-arrow-alternate</title><path d=\"M20 0H4a1 1 0 00-1 1v22a1 1 0 001 1h16a1 1 0 001-1V1a1 1 0 00-1-1zM5 2.25A.25.25 0 015.25 2h8.35a.25.25 0 01.18.423l-8.168 8.54a1.5 1.5 0 000 2.074l8.168 8.54a.25.25 0 01-.18.423H5.25a.25.25 0 01-.25-.25zm14 19.5a.25.25 0 01-.25.25h-1.694a.252.252 0 01-.181-.077l-9.326-9.75a.251.251 0 010-.346l9.326-9.75A.252.252 0 0117.056 2h1.694a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});