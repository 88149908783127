define("ember-svg-jar/inlined/road-sign-lane-narrowing-right-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-lane-narrowing-right-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M15.6 11.687l-2.044-2.508a.25.25 0 01-.056-.158V6.5a1 1 0 00-2 0v2.793a1.51 1.51 0 00.39 1.009l.009.01 2.045 2.509a.25.25 0 01.056.158V16a1 1 0 002 0v-3.293a1.492 1.492 0 00-.39-1.01zM10 5.5a1 1 0 00-1 1V16a1 1 0 002 0V6.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});