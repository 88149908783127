define("ember-svg-jar/inlined/taxi-wave", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>taxi-wave</title><path d=\"M24 5.635a1 1 0 00-1-1h-5.41a3.02 3.02 0 00-2.99 2.776L14.285 13a.251.251 0 01-.185.227 2.5 2.5 0 00-1.85 2.412v3a1.5 1.5 0 001.5 1.5H14a.25.25 0 01.25.25v.75a2 2 0 104 0v-.75a.25.25 0 01.25-.25h4.75a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-1.128a.249.249 0 01-.225-.143 2.377 2.377 0 00-4.294 0 .249.249 0 01-.225.143h-.834a.25.25 0 01-.25-.264l.3-5.32a1 1 0 011-.916H23a1 1 0 001-1.004zm-6.75 11a1.5 1.5 0 11-1.5-1.5 1.5 1.5 0 011.5 1.499z\"/><circle cx=\"19.75\" cy=\"9.135\" r=\"1.75\"/><circle cx=\"6.5\" cy=\"3.365\" r=\"2.5\"/><path d=\"M1.25 15.613a1.25 1.25 0 001.25-1.25v-3.856a.5.5 0 011 0v11.377a1.25 1.25 0 002.5 0v-6.021a.5.5 0 011 0v6.021a1.25 1.25 0 002.5 0v-11.11a.253.253 0 01.05-.15l5.43-7.24a1.25 1.25 0 10-2-1.5L9.4 6.657a.252.252 0 01-.2.1H4a4 4 0 00-4 4v3.606a1.25 1.25 0 001.25 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});