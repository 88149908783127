define("ember-svg-jar/inlined/database-upload-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-upload-alternate</title><path d=\"M9.542 20.977l-.01-.022a1.023 1.023 0 00-.8-.585c-3.649-.449-5.872-1.811-5.872-2.8v-.758a.25.25 0 01.371-.219 13.949 13.949 0 004.392 1.423.983.983 0 001.15-.9v-.023a.98.98 0 00-.808-1.031c-3.191-.533-5.107-1.77-5.107-2.692v-.758a.25.25 0 01.372-.218A15.4 15.4 0 009 14a.925.925 0 00.88-.471l.03-.049a.959.959 0 00-.726-1.465c-3.914-.388-6.32-1.819-6.32-2.852V8.4a.249.249 0 01.372-.218 17.426 17.426 0 008.024 1.731 17.422 17.422 0 008.026-1.73.25.25 0 01.372.215 5.486 5.486 0 01-.024.883 1.049 1.049 0 00.538 1.155l.037.018a1 1 0 001.446-.9v-4.6C21.656 1.738 16.3 0 11.26 0S.877 1.735.866 4.947v12.62c0 2.631 3.579 4.27 7.638 4.78a.985.985 0 001.038-1.37zM11.26 2c5.121 0 8.39 1.748 8.4 2.953v.009c-.011 1.205-3.278 2.95-8.4 2.95s-8.395-1.75-8.395-2.955S6.135 2 11.26 2z\"/><path d=\"M16.7 11.13a6.437 6.437 0 106.437 6.437A6.444 6.444 0 0016.7 11.13zm-1 9.4v-2.708a.249.249 0 00-.25-.25h-.728a.5.5 0 01-.39-.812l1.978-2.473a.519.519 0 01.781 0l1.978 2.473a.5.5 0 01-.391.812h-.728a.25.25 0 00-.25.25v2.711a1 1 0 11-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});