define("ember-svg-jar/inlined/bread-wheat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-wheat</title><path d=\"M10.5 13.866a.5.5 0 00-.68-.466 6 6 0 00-2.4 1.7.25.25 0 01-.44-.163v-1.611a.5.5 0 01.384-.487 5.992 5.992 0 003.005-1.748.5.5 0 00.134-.341V7.866A.5.5 0 009.82 7.4a6 6 0 00-2.4 1.7.25.25 0 01-.44-.163V7.326a.5.5 0 01.384-.487 5.992 5.992 0 003.005-1.748.5.5 0 00.131-.341V1.866a.5.5 0 00-.68-.466 6 6 0 00-2.4 1.7.25.25 0 01-.44-.163V1a1 1 0 00-2 0v1.938a.251.251 0 01-.164.234.247.247 0 01-.279-.072A5.989 5.989 0 00.53 1.025a.494.494 0 00-.53.528 6 6 0 004.591 5.286.5.5 0 01.386.486v1.613a.251.251 0 01-.164.234.247.247 0 01-.276-.072A5.989 5.989 0 00.53 7.025a.494.494 0 00-.53.528 6 6 0 004.589 5.286.5.5 0 01.386.486v1.613a.251.251 0 01-.164.234.247.247 0 01-.276-.072A5.989 5.989 0 00.53 13.025a.494.494 0 00-.53.528 6 6 0 004.589 5.286.5.5 0 01.386.486V23a1 1 0 002 0v-3.674a.5.5 0 01.384-.487 5.992 5.992 0 003.005-1.748.5.5 0 00.134-.341zM24 7.553a.494.494 0 00-.528-.528A5.989 5.989 0 0019.463 9.1a.25.25 0 01-.44-.162V7.325a.5.5 0 01.386-.486A6 6 0 0024 1.553a.494.494 0 00-.528-.528A5.989 5.989 0 0019.463 3.1a.25.25 0 01-.44-.162V1a1 1 0 00-2 0v1.938a.25.25 0 01-.44.163 5.983 5.983 0 00-4.007-2.076.494.494 0 00-.528.528 6 6 0 004.59 5.286.5.5 0 01.385.486v1.613a.25.25 0 01-.44.163 5.983 5.983 0 00-4.007-2.076.494.494 0 00-.528.528 6 6 0 004.59 5.286.5.5 0 01.385.486v1.613a.25.25 0 01-.44.163 5.983 5.983 0 00-4.007-2.076.494.494 0 00-.528.528 6 6 0 004.59 5.286.5.5 0 01.385.486V23a1 1 0 002 0v-3.675a.5.5 0 01.386-.486A6 6 0 0024 13.553a.494.494 0 00-.528-.528 5.989 5.989 0 00-4.009 2.075.25.25 0 01-.44-.162v-1.613a.5.5 0 01.386-.486A6 6 0 0024 7.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});