define("ember-svg-jar/inlined/conversation-chat-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-chat-bubble</title><path d=\"M17.25 6.875v-6a.75.75 0 00-.75-.75h-9a.75.75 0 00-.75.75v6a.75.75 0 00.75.75h1.75v1.75a.75.75 0 001.28.53l2.281-2.28H16.5a.75.75 0 00.75-.75zM4.505 12.875a2.75 2.75 0 10-.01 0A3.5 3.5 0 001 16.374v2a.5.5 0 00.5.5h.821a.249.249 0 01.249.225L3 23.425a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.43-4.325a.249.249 0 01.248-.225H7.5a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.495-3.5zM19.505 12.875a2.75 2.75 0 10-.01 0 3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.821a.249.249 0 01.249.225L18 23.425a.5.5 0 00.5.45h2a.5.5 0 00.5-.45l.43-4.325a.249.249 0 01.248-.225h.822a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.495-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});