define("ember-svg-jar/inlined/shopping-basket-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-woman</title><path d=\"M9.59 16H7.5a.25.25 0 01-.208-.389l1.038-1.556a1 1 0 00-1.664-1.11l-1.96 2.944A.253.253 0 014.5 16H1.41A1.412 1.412 0 000 17.41a1.445 1.445 0 00.027.277l.786 3.93A1.726 1.726 0 002.5 23h6a1.724 1.724 0 001.686-1.383l.787-3.931A1.427 1.427 0 0011 17.41 1.412 1.412 0 009.59 16zM8.5 20.5a.5.5 0 01-1 0v-2a.5.5 0 011 0zm-3.5-2a.5.5 0 011 0v2a.5.5 0 01-1 0zm-1.5 0v2a.5.5 0 01-1 0v-2a.5.5 0 011 0zM17.562 2.7a.5.5 0 01.414-.22.5.5 0 01.424.22c.034.047.892 1.24 3.4 1.3a.19.19 0 00.139-.057.187.187 0 00.053-.14A4 4 0 0014 3.765a.22.22 0 00.061.165.223.223 0 00.159.07c2.474-.073 3.306-1.252 3.342-1.3z\"/><path d=\"M18.138 3.841a.248.248 0 00-.313 0 5.708 5.708 0 01-3.388 1.147.252.252 0 00-.224.333 4 4 0 007.552 0 .25.25 0 00-.223-.333 5.777 5.777 0 01-3.404-1.147z\"/><path d=\"M23.98 18.361s-1.01-3.5-2.01-7a4.146 4.146 0 00-7.961 0c-1 3.5-1.991 7-1.991 7A.5.5 0 0012.5 19h2.374a.25.25 0 01.245.2L16 23.6a.5.5 0 00.49.4h3a.5.5 0 00.49-.4l.88-4.4a.25.25 0 01.24-.2h2.4a.5.5 0 00.48-.639z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});