define("ember-svg-jar/inlined/armchair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>armchair</title><path d=\"M3.538 8.419c.1-.006.2-.009.3-.009a5.269 5.269 0 015.226 4.546.5.5 0 00.562.428A17.987 17.987 0 0112 13.21a17.987 17.987 0 012.372.174.5.5 0 00.562-.428A5.269 5.269 0 0120.16 8.41c.1 0 .2 0 .3.009a.5.5 0 00.524-.582 9.115 9.115 0 00-17.972 0 .5.5 0 00.524.582z\"/><path d=\"M20.16 9.85a3.839 3.839 0 00-3.755 4.642.5.5 0 01-.587.595A19.932 19.932 0 0012 14.65a19.932 19.932 0 00-3.818.437.5.5 0 01-.587-.595A3.84 3.84 0 100 13.69a3.8 3.8 0 002.229 3.473.5.5 0 01.27.6 2.312 2.312 0 00-.1.67v1.377a1.442 1.442 0 001.44 1.44H4a.5.5 0 01.5.5v.75a1.25 1.25 0 002.5 0v-.75a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v.75a1.25 1.25 0 002.5 0v-.75a.5.5 0 01.5-.5h.16a1.442 1.442 0 001.44-1.44v-1.376a2.312 2.312 0 00-.1-.67.5.5 0 01.271-.6A3.8 3.8 0 0024 13.69a3.844 3.844 0 00-3.84-3.84z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});