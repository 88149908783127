define("ember-svg-jar/inlined/text-format-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>text-format-1</title><rect x=\"20.25\" y=\"6.998\" width=\"1.5\" height=\"10\" rx=\".25\" ry=\".25\"/><rect x=\"7\" y=\"2.248\" width=\"10\" height=\"1.5\" rx=\".25\" ry=\".25\"/><rect x=\"2.25\" y=\"6.998\" width=\"1.5\" height=\"10\" rx=\".25\" ry=\".25\"/><rect x=\"7\" y=\"20.248\" width=\"10\" height=\"1.5\" rx=\".25\" ry=\".25\"/><rect x=\".5\" y=\".498\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\"18.5\" y=\".498\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\".5\" y=\"18.498\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><rect x=\"18.5\" y=\"18.498\" width=\"5\" height=\"5\" rx=\"1\" ry=\"1\"/><path d=\"M17.5 7.5a1 1 0 00-1-1h-9a1 1 0 00-1 1v2a1 1 0 002 0v-.75a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v7.5a.25.25 0 01-.25.25h-.25a1 1 0 000 2h3a1 1 0 000-2h-.25a.25.25 0 01-.25-.25v-7.5a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v.75a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});