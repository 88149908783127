define("ember-svg-jar/inlined/smiley-sick-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-sick-alternate</title><path d=\"M10 14.955a.1.1 0 00-.043-.082.1.1 0 00-.091-.013 6.517 6.517 0 00-3.5 2.89.5.5 0 00.433.75h3.51a.251.251 0 00.236-.333A9.63 9.63 0 0110 14.955zM9.084 8.1a1.5 1.5 0 01-2.751 0 1 1 0 00-1.833.8 3.5 3.5 0 006.415 0 1 1 0 00-1.832-.8zM17.667 8.1a1.5 1.5 0 01-2.751 0 1 1 0 10-1.832.8 3.5 3.5 0 006.415 0 1 1 0 10-1.832-.8zM19.659 14.432a4.3 4.3 0 00-2.9 1.121.809.809 0 01-1.352-.6 1.955 1.955 0 10-3.909 0 8.168 8.168 0 008.159 8.158 4.341 4.341 0 100-8.681zM22 18.772a2.343 2.343 0 01-2.341 2.341 6.3 6.3 0 01-6.237-5.891 2.808 2.808 0 004.678 1.811 2.315 2.315 0 011.555-.6A2.343 2.343 0 0122 18.772z\"/><path d=\"M12 22a10 10 0 1110-10 1 1 0 002 0 12 12 0 10-12 12 1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});