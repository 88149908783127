define("ember-svg-jar/inlined/chair-bar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-bar-1</title><path d=\"M18.883.75H5.117A1.69 1.69 0 003.5 2.5a1.69 1.69 0 001.617 1.75h13.766A1.69 1.69 0 0020.5 2.5 1.69 1.69 0 0018.883.75zM18.1 5.25H5.9a.25.25 0 00-.176.427l.841.841a2.5 2.5 0 001.767.732h2.418a.25.25 0 01.25.25v2.267a.2.2 0 01-.1.167l-.843.421a1 1 0 00.859 1.806c.049-.019.088.006.088.085v1.019a.182.182 0 01-.091.162l-.856.428a1 1 0 00.862 1.8c.011-.005.085-.047.085.055v.871a.272.272 0 01-.191.279A6.5 6.5 0 005.8 21.286a1.507 1.507 0 001.435 1.964 1.462 1.462 0 001.413-1.01 3.5 3.5 0 016.7 0 1.462 1.462 0 001.413 1.01 1.507 1.507 0 001.439-1.964 6.5 6.5 0 00-5-4.419.239.239 0 01-.2-.234v-1.816c0-.123.03-.214.109-.253l.838-.419a1 1 0 00-.867-1.8.06.06 0 01-.08-.061v-1.049a.188.188 0 01.078-.155l.869-.435a1 1 0 00-.834-1.817c-.069.028-.113 0-.113-.046V7.5a.25.25 0 01.25-.25h2.422a2.5 2.5 0 001.767-.732l.841-.841a.25.25 0 00-.18-.427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});