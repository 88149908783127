define("ember-svg-jar/inlined/shopping-basket-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-remove</title><path d=\"M17.348 10.968a.252.252 0 00.2-.184l.316-1.2a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.79a.249.249 0 00.244-.2 7.507 7.507 0 016.182-5.832zm-6.848.647V14a.5.5 0 01-1 0v-2.385a.5.5 0 011 0zm-3.872 3.03a.488.488 0 01-.085.007.5.5 0 01-.492-.416l-.408-2.386a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.253.253 0 01-.008.246.251.251 0 01-.214.12H5.4a.25.25 0 01-.221-.366l1.51-2.9A1 1 0 004.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M18.439 12.879A5.561 5.561 0 1024 18.44a5.566 5.566 0 00-5.561-5.561zm2.32 6.82a.75.75 0 11-1.061 1.06l-1.082-1.082a.249.249 0 00-.353 0l-1.082 1.082A.75.75 0 0116.12 19.7l1.08-1.083a.25.25 0 000-.354l-1.08-1.082a.75.75 0 011.061-1.061l1.082 1.08a.249.249 0 00.353 0l1.084-1.08a.75.75 0 011.061 1.061l-1.082 1.082a.25.25 0 000 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});