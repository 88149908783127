define("ember-svg-jar/inlined/arduino-plus-minus-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arduino-plus-minus-1</title><path d=\"M24 10a1.509 1.509 0 00-.6-1.2L22 7.751V3.5A1.5 1.5 0 0020.5 2h-19A1.5 1.5 0 000 3.5v17A1.5 1.5 0 001.5 22h19a1.5 1.5 0 001.5-1.5v-2.25l1.4-1.05A1.508 1.508 0 0024 16zm-4.875 1.94a3.684 3.684 0 01-2.728 3.6c-2.289.577-3.961-1.1-4.819-2.276a.25.25 0 00-.406 0c-.858 1.174-2.53 2.853-4.819 2.276a3.684 3.684 0 01-2.728-3.6 3.53 3.53 0 013.652-3.592 5.134 5.134 0 013.9 2.394.25.25 0 00.406 0 5.134 5.134 0 013.9-2.394 3.53 3.53 0 013.642 3.592z\"/><path d=\"M6 12a.5.5 0 00.5.5h2a.5.5 0 000-1h-2a.5.5 0 00-.5.5zM15.5 10.5a.5.5 0 00-.5.5v.25a.25.25 0 01-.25.25h-.25a.5.5 0 000 1h.25a.25.25 0 01.25.25V13a.5.5 0 001 0v-.25a.25.25 0 01.25-.25h.25a.5.5 0 000-1h-.25a.25.25 0 01-.25-.25V11a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});