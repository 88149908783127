define("ember-svg-jar/inlined/shrink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shrink</title><path d=\"M23.384.615a1.251 1.251 0 00-1.768 0L17.851 4.38a.25.25 0 01-.354 0l-1.325-1.325a.5.5 0 00-.854.353v4.773a.5.5 0 00.5.5h4.773a.5.5 0 00.353-.854L19.618 6.5a.253.253 0 01-.073-.177.249.249 0 01.074-.176l3.765-3.765a1.251 1.251 0 000-1.767zM.616 23.383a1.251 1.251 0 001.768 0l3.765-3.765a.249.249 0 01.353 0l1.326 1.325a.5.5 0 00.354.147.512.512 0 00.191-.038.5.5 0 00.309-.462v-4.773a.5.5 0 00-.5-.5H3.409a.5.5 0 00-.353.854L4.381 17.5a.25.25 0 010 .354L.616 21.615a1.251 1.251 0 000 1.768zM15.627 21.052a.512.512 0 00.191.038.5.5 0 00.354-.147l1.328-1.325a.249.249 0 01.353 0l3.765 3.765a1.25 1.25 0 001.768-1.768l-3.767-3.765a.25.25 0 010-.354l1.325-1.325a.5.5 0 00-.353-.854h-4.773a.5.5 0 00-.5.5v4.773a.5.5 0 00.309.462zM8.682 3.408a.5.5 0 00-.854-.353L6.5 4.38a.247.247 0 01-.353 0L2.384.615A1.25 1.25 0 00.616 2.383l3.765 3.765a.25.25 0 010 .354L3.056 7.827a.5.5 0 00.353.854h4.773a.5.5 0 00.5-.5z\"/><circle cx=\"12\" cy=\"11.999\" r=\"2.909\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});