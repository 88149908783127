define("ember-svg-jar/inlined/information-desk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>information-desk</title><circle cx=\"7.706\" cy=\"9.595\" r=\"3.368\"/><path d=\"M13.434 17.722a2.868 2.868 0 00-2.868-2.868h-.678a.479.479 0 00-.416.24l-1.359 2.382a.478.478 0 01-.83 0l-1.36-2.382a.476.476 0 00-.415-.24H4.83a2.868 2.868 0 00-2.868 2.867v2.369a.5.5 0 00.5.5h10.472a.5.5 0 00.5-.5zM19.649 15.541a.75.75 0 00-.75.75v.861a.252.252 0 01-.17.237 2.883 2.883 0 00-1.971 2.726.5.5 0 00.5.5h4.781a.5.5 0 00.5-.5 2.882 2.882 0 00-1.97-2.726.251.251 0 01-.17-.237v-.861a.75.75 0 00-.75-.75zM18.454.494A5.042 5.042 0 1023.5 5.536 5.047 5.047 0 0018.454.494zm-.329 1.217a.957.957 0 11-.956.956.957.957 0 01.956-.956zm1.7 7.224h-2.268a.75.75 0 010-1.5h.136a.25.25 0 00.25-.25V6.157a.25.25 0 00-.25-.25h-.136a.75.75 0 010-1.5h.757a1.13 1.13 0 011.129 1.128v1.65a.25.25 0 00.25.25h.135a.75.75 0 010 1.5z\"/><rect x=\".245\" y=\"21.506\" width=\"23.511\" height=\"2\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});