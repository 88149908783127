define("ember-svg-jar/inlined/dresser-drawers-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dresser-drawers-4</title><path d=\"M20.75 3.5H3.25a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h17.5a.5.5 0 00.5-.5V4a.5.5 0 00-.5-.5zM12 7a1 1 0 111-1 1 1 0 01-1 1zM20.75 10H3.25a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h17.5a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5zM12 13.5a1 1 0 111-1 1 1 0 01-1 1zM22.75 0H1.25a1 1 0 000 2h21.5a1 1 0 000-2zM10.75 16.5h-7.5a.5.5 0 00-.5.5v4.09a.961.961 0 001 .91h.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h3.5a.5.5 0 00.5-.5V17a.5.5 0 00-.5-.5zm-4 3.5a1 1 0 111-1 1 1 0 01-1 1zM20.75 16.5h-7.5a.5.5 0 00-.5.5v4.5a.5.5 0 00.5.5h3.5a.5.5 0 01.5.5v.5a1 1 0 002 0v-.5a.5.5 0 01.5-.5h.5a.961.961 0 001-.91V17a.5.5 0 00-.5-.5zm-3.5 3.5a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});