define("ember-svg-jar/inlined/bomb-rocket-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-rocket-1</title><path d=\"M4.14.231A1 1 0 002.5 1v4.563a2.483 2.483 0 00.883 1.907l1.781 1.617A.5.5 0 006 8.717V2.384a.987.987 0 00-.341-.752zM20.925.1a1.013 1.013 0 00-1.084.153l-1.482 1.364a1 1 0 00-.359.767v6.333a.5.5 0 00.836.37l1.764-1.6a2.493 2.493 0 00.9-1.921V1a1 1 0 00-.575-.9zM13.212 3.593A.249.249 0 0113 3.346V1a1 1 0 00-2 0v2.346a.25.25 0 01-.212.247 4.455 4.455 0 00-3.252 1.843.25.25 0 00-.036.13v12.906a.5.5 0 00.625.485 15.575 15.575 0 017.75 0 .5.5 0 00.625-.485V5.566a.26.26 0 00-.036-.13 4.455 4.455 0 00-3.252-1.843zM15.394 20.38a14.094 14.094 0 00-6.788 0 .5.5 0 00-.351.322.5.5 0 00.077.469 12.932 12.932 0 003.134 2.675 1 1 0 001.068 0 12.884 12.884 0 003.134-2.674.5.5 0 00-.274-.791z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});