define("ember-svg-jar/inlined/3d-print-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-monitor</title><path d=\"M5.845 11.155a.748.748 0 001.06 0l2-2a.75.75 0 00-.53-1.28h-.75a.25.25 0 01-.25-.25v-.5a2 2 0 012-2 1 1 0 000-2 4 4 0 00-4 4v.5a.25.25 0 01-.25.25h-.75a.75.75 0 00-.53 1.28zM12.281 13.5l-2.75-1.771a.749.749 0 10-.812 1.26l1.343.866a.248.248 0 01.115.218.252.252 0 01-.129.211L6.5 16.243a.253.253 0 01-.242 0L2.7 14.279a.252.252 0 01-.129-.211.248.248 0 01.115-.218l1.343-.866a.749.749 0 10-.812-1.26L.469 13.5a.752.752 0 00-.344.63v5.791a.75.75 0 00.372.648L6 23.773a.751.751 0 00.756 0l5.5-3.209a.75.75 0 00.372-.648v-5.791a.751.751 0 00-.347-.625zM22.125.125h-9a1.752 1.752 0 00-1.75 1.75v6.5a1.752 1.752 0 001.75 1.75h3.52a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-1a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-1a.249.249 0 01-.25-.25v-.5a.249.249 0 01.25-.25h3.48a1.752 1.752 0 001.75-1.75v-6.5a1.752 1.752 0 00-1.75-1.75zm-9 1.5h9a.25.25 0 01.25.25v5a.25.25 0 01-.25.25h-9a.25.25 0 01-.25-.25v-5a.25.25 0 01.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});