define("ember-svg-jar/inlined/rotate-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rotate-vertical</title><path d=\"M1.6 8.8a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8h-.25A.249.249 0 013 5.75V4a1 1 0 011-1h1.75a.249.249 0 01.25.25v.25a.5.5 0 00.8.4l2-1.5a.5.5 0 000-.8L6.8.1a.5.5 0 00-.8.4v.25a.249.249 0 01-.25.25H4a3 3 0 00-3 3v1.75A.249.249 0 01.75 6H.5a.5.5 0 00-.4.8z\"/><rect y=\"12\" width=\"18\" height=\"12\" rx=\"2\" ry=\"2\"/><path d=\"M23 14a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H21a1 1 0 000 2h1a2 2 0 002-2v-1a1 1 0 00-1-1zM22 0h-1a1 1 0 000 2h.5a.5.5 0 01.5.5V3a1 1 0 002 0V2a2 2 0 00-2-2zM13 4a1 1 0 001-1v-.5a.5.5 0 01.5-.5h.5a1 1 0 000-2h-1a2 2 0 00-2 2v1a1 1 0 001 1zM23 7a1 1 0 00-1 1v2a1 1 0 002 0V8a1 1 0 00-1-1zM13 11a1 1 0 001-1V8a1 1 0 00-2 0v2a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});