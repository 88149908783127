define("ember-svg-jar/inlined/chair-director", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair-director</title><path d=\"M23.5 8.5a1 1 0 00-1-1h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v6.25a.25.25 0 01-.25.25H1.5a1 1 0 000 2h2.75a.25.25 0 01.25.25v.485a.24.24 0 01-.022.1l-1.892 4.259A1 1 0 003.5 16h17a1 1 0 00.914-1.406l-1.892-4.257a.24.24 0 01-.022-.1V9.75a.25.25 0 01.25-.25h2.75a1 1 0 001-1zm-6.5-2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5zM19.36 23.51a1 1 0 00-.35-1.37l-4.686-2.777a.25.25 0 010-.43l.849-.5a.5.5 0 00-.255-.93h-1.961a.5.5 0 00-.255.07l-.575.341a.251.251 0 01-.254 0l-.573-.344a.5.5 0 00-.255-.07H9.082a.5.5 0 00-.255.93l.849.5a.25.25 0 010 .43L4.99 22.14a1 1 0 101.02 1.72l5.863-3.474a.251.251 0 01.254 0l5.863 3.474a1 1 0 001.37-.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});