define("ember-svg-jar/inlined/peacock-feathers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>peacock-feathers</title><path d=\"M5.344 9.986l.723 1.875a1.017 1.017 0 001.293.573 1 1 0 00.573-1.292L7.2 9.229a3.486 3.486 0 001.041-4.06C7.566 3.539 3.457.836 2.991.534a.5.5 0 00-.762.312c-.118.544-1.136 5.354-.465 6.987a3.594 3.594 0 003.58 2.153zm.036-2.561A1 1 0 115 5.5a1 1 0 01.38 1.925zM16.8 9.229l-.737 1.913a1 1 0 00.573 1.292 1.015 1.015 0 001.293-.573l.723-1.875a3.533 3.533 0 003.58-2.153c.671-1.63-.347-6.443-.465-6.987a.5.5 0 00-.762-.312c-.466.3-4.575 3.005-5.246 4.635a3.54 3.54 0 001.041 4.06zm1.817-1.808a1.028 1.028 0 01-.545-1.3 1.008 1.008 0 011.3-.542 1 1 0 11-.759 1.842z\"/><path d=\"M22.993 13.839a.5.5 0 00-.43-.177c-.7.063-10.08.379-7.781 5.717L13 20.864v-5.008a3.506 3.506 0 002.5-3.356c0-1.762-2.772-5.826-3.088-6.284a.521.521 0 00-.823 0C11.273 6.675 8.5 10.739 8.5 12.5a3.505 3.505 0 002.5 3.355v5.009l-1.782-1.485a3.594 3.594 0 00-.977-4.067c-1.354-1.129-6.25-1.6-6.8-1.65a.537.537 0 00-.43.177.5.5 0 00-.1.454c.149.536 1.5 5.267 2.85 6.4a3.546 3.546 0 004.177.224l3.412 2.843a1 1 0 001.3 0l3.413-2.845a3.507 3.507 0 004.177-.225c1.354-1.13 2.7-5.861 2.85-6.4a.5.5 0 00-.097-.451zm-5.76 4.8A1.013 1.013 0 0118 17a.994.994 0 01.768.362A1.012 1.012 0 0118 19a1 1 0 01-.767-.357zM12 11.5a1 1 0 11-1 1 1 1 0 011-1zm-6.758 5.87A.948.948 0 016 17a1.01 1.01 0 01.766 1.643.993.993 0 11-1.524-1.273zM11 2.759V4.5a1 1 0 102 0V2.759A2.642 2.642 0 0014.5.5.5.5 0 0014 0h-4a.5.5 0 00-.5.5A2.642 2.642 0 0011 2.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});