define("ember-svg-jar/inlined/single-woman-actions-time.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-time</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm0 11a4.5 4.5 0 114.5-4.5 4.5 4.5 0 01-4.5 4.5z\"/><path d=\"M20.5 17.5a1 1 0 00-1-1h-.75a.25.25 0 01-.25-.25v-1a1 1 0 00-2 0v2.25a1 1 0 001 1h2a1 1 0 001-1zM10.836 13.1a.5.5 0 00-.182-.72 7.368 7.368 0 00-3.448-.88 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.515a.5.5 0 00.5-.47 7.93 7.93 0 011.321-3.93zM1.566 10.442a1 1 0 001.383-.3 5.233 5.233 0 00.454-.9.249.249 0 01.411-.082 4.728 4.728 0 006.787 0 .249.249 0 01.411.082 5.233 5.233 0 00.454.9 1 1 0 001.681-1.082 4.569 4.569 0 01-.647-2.511V5.3a5.3 5.3 0 10-10.59 0v1.248a4.573 4.573 0 01-.642 2.512 1 1 0 00.298 1.382zM7.208 8.6a2.706 2.706 0 01-2.742-2.352.5.5 0 01.324-.577 5.708 5.708 0 002.246-1.439.249.249 0 01.36 0 5.719 5.719 0 002.23 1.437.5.5 0 01.323.576A2.707 2.707 0 017.208 8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});