define("ember-svg-jar/inlined/drone-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drone-off</title><path d=\"M21 6.25h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM3 7.75h5a.75.75 0 000-1.5H3a.75.75 0 000 1.5zM12 8.5a3.494 3.494 0 00-2.6 1.155 2.565 2.565 0 01-1.9.845h-1a.25.25 0 01-.25-.25V9a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25H4a1.5 1.5 0 000 3h3.618a.251.251 0 00.177-.073l4.507-4.5a.25.25 0 00-.167-.427z\"/><path d=\"M23.53.47a.75.75 0 00-1.061 0l-22 22a.75.75 0 001.061 1.06l8.511-8.511a.09.09 0 01.151.046.185.185 0 01-.03.139 2.25 2.25 0 103.675 0 .18.18 0 01-.03-.139.176.176 0 01.08-.117q.087-.057.171-.117a.18.18 0 01.192-.013A4.754 4.754 0 0116.749 19a.75.75 0 001.5 0 6.245 6.245 0 00-2.584-5.059.179.179 0 01-.072-.174.182.182 0 01.123-.144 2.57 2.57 0 01.784-.123H20a1.5 1.5 0 000-3h-.5a.25.25 0 01-.25-.25V9a.75.75 0 00-1.5 0v1.25a.25.25 0 01-.25.25h-1a2.557 2.557 0 01-1.276-.341.181.181 0 01-.038-.284L23.53 1.53a.756.756 0 000-1.06zM12 17.25a.75.75 0 11.75-.75.752.752 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});