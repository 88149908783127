define("ember-svg-jar/inlined/ee-icon-question-inline-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>picture-landscape</title><g fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#5E7AF9\" width=\"54\" height=\"54\" rx=\"5\"/><path fill=\"#FFF\" d=\"M7 10h24v4H7zM34 10h13v4H34zM7 17h40v4H7zM7 31h20v4H7zM7 38h10v4H7zM20 38h18v4H20zM30 31h17v4H30z\"/><path fill=\"#273C9B\" d=\"M16 24h5v4h-5z\"/><path fill=\"#FFF\" d=\"M7 24h6v4H7zM41 24h6v4h-6z\"/><path fill=\"#273C9B\" d=\"M24 24h5v4h-5zM32 24h5v4h-5z\"/></g>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});