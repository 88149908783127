define("ember-svg-jar/inlined/flying-insect-honey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-honey</title><path d=\"M10.431 14.308L6 11.774a1.044 1.044 0 00-1.035 0L.526 14.308a1.045 1.045 0 00-.526.906v5.05a1.045 1.045 0 00.526.906l4.435 2.53A1.044 1.044 0 006 23.7l4.435-2.534a1.045 1.045 0 00.526-.906v-5.05a1.045 1.045 0 00-.53-.902zM23.474 14.308l-4.434-2.534a1.046 1.046 0 00-1.036 0l-4.435 2.534a1.045 1.045 0 00-.526.906v5.05a1.045 1.045 0 00.526.906L18 23.7a1.046 1.046 0 001.036 0l4.434-2.534a1.045 1.045 0 00.53-.902v-5.05a1.045 1.045 0 00-.526-.906zM17.478 8.786v-5.05a1.044 1.044 0 00-.525-.906L12.518.3a1.046 1.046 0 00-1.036 0L7.048 2.83a1.043 1.043 0 00-.526.906v5.05a1.043 1.043 0 00.526.906l4.434 2.534a1.046 1.046 0 001.036 0l4.435-2.534a1.044 1.044 0 00.525-.906z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});