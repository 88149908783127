define("ember-svg-jar/inlined/table-candelabra", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>table-candelabra</title><path d=\"M19.46 15.87a2.48 2.48 0 00.979-.6l1.291-1.291a1.5 1.5 0 001.263-1.625 1.575 1.575 0 00-1.6-1.354H17.75a.25.25 0 01-.25-.25V9.041a.245.245 0 01.176-.233A3.993 3.993 0 0020.5 5v-.5a1 1 0 00-2 0V5a1.976 1.976 0 01-.691 1.5c-.1.083-.309 0-.309 0v-2a1 1 0 00-2 0v2s-.216.081-.313 0A1.979 1.979 0 0114.5 5v-.5a1 1 0 00-2 0V5a3.993 3.993 0 002.824 3.808.244.244 0 01.176.233v1.709a.25.25 0 01-.25.25H2.607a1.575 1.575 0 00-1.6 1.352 1.5 1.5 0 001.263 1.625l1.291 1.291a2.494 2.494 0 00.979.6.249.249 0 01.166.323L2.28 22.659a.993.993 0 001.8.835l4.145-7.367A.25.25 0 018.439 16h7.123a.25.25 0 01.217.127l4.143 7.366a.993.993 0 001.8-.835l-2.424-6.464a.25.25 0 01.162-.324zM16.5 2.5a1 1 0 001-1V1a1 1 0 00-2 0v.5a1 1 0 001 1zM13.5 2.5a1 1 0 001-1V1a1 1 0 00-2 0v.5a1 1 0 001 1zM19.5 2.5a1 1 0 001-1V1a1 1 0 00-2 0v.5a1 1 0 001 1z\"/><path d=\"M3.751 9.777a.5.5 0 00.416.223h4.666a.5.5 0 00.416-.223L10.4 8.055A1 1 0 009.565 6.5H3.434A1 1 0 002.6 8.055z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});