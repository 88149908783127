define("ember-svg-jar/inlined/fireworks-rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fireworks-rocket</title><path d=\"M5.25 10.5a.75.75 0 01-.75-.75A3.755 3.755 0 00.75 6a.75.75 0 010-1.5A3.755 3.755 0 004.5.75a.75.75 0 011.5 0A3.755 3.755 0 009.75 4.5a.75.75 0 010 1.5A3.755 3.755 0 006 9.75a.75.75 0 01-.75.75zM3.765 5.046a.249.249 0 000 .408 5.3 5.3 0 011.281 1.281.249.249 0 00.408 0 5.3 5.3 0 011.281-1.281.249.249 0 000-.408 5.3 5.3 0 01-1.281-1.281.249.249 0 00-.408 0 5.3 5.3 0 01-1.281 1.281zM24 .836A.837.837 0 0023.165 0H18.14a.836.836 0 00-.591 1.427l5.025 5.024a.834.834 0 00.59.245.847.847 0 00.321-.064A.833.833 0 0024 5.86zM20.893 7.611a.5.5 0 000-.707l-3.8-3.8a.5.5 0 00-.707 0L11.01 8.485a1.5 1.5 0 000 2.122l.308.308a.251.251 0 010 .354L.293 22.293a1 1 0 101.414 1.414l11.025-11.024a.249.249 0 01.353 0l.308.307a1.5 1.5 0 002.121 0zM19.571 19.005l-.265-.038a1.507 1.507 0 01-1.273-1.274L18 17.429a.5.5 0 00-.99 0l-.038.265a1.507 1.507 0 01-1.273 1.273l-.265.038a.5.5 0 000 .99l.265.038a1.507 1.507 0 011.273 1.274l.038.264a.5.5 0 00.99 0l.038-.265a1.507 1.507 0 011.273-1.273l.26-.033a.5.5 0 000-.99z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});