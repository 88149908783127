define("ember-svg-jar/inlined/multiple-actions-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-shield</title><path d=\"M14.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.265 6.265 0 01-.922 3.625.249.249 0 00.122.371c1.721.644 3 1.209 3.686 2.193a.251.251 0 00.249.1 17.905 17.905 0 011.646-.215.25.25 0 00.076-.48 50.51 50.51 0 00-1.786-.679z\"/><path d=\"M9.5 13.456a3.044 3.044 0 011.827-2.79 10.539 10.539 0 011.225-.43.25.25 0 00.071-.446A15.492 15.492 0 009.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.174 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h8.75a.25.25 0 00.25-.25zM23.073 12.04A16.41 16.41 0 0017.478 11a16 16 0 00-5.551 1.04A1.546 1.546 0 0011 13.456v3.393a7.715 7.715 0 005.19 6.805l.554.21a2.04 2.04 0 00.734.136 2.07 2.07 0 00.735-.135l.553-.211C21.847 22.48 24 19.682 24 16.849v-3.393a1.547 1.547 0 00-.927-1.416zM18.25 15.5a.249.249 0 00.25.25h2a.75.75 0 010 1.5h-2a.249.249 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.25.25 0 00.25-.25v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});