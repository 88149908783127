define("ember-svg-jar/inlined/school-test-art", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-test-art</title><path d=\"M8.265 15.509a1.924 1.924 0 01-1.077-.071C4.036 14.337 2 11.81 2 9c0-3.86 3.813-7 8.5-7a8.937 8.937 0 017.453 3.637 6.051 6.051 0 01.535 5.838 1 1 0 101.848.765 7.992 7.992 0 00-.721-7.714A10.949 10.949 0 0010.5 0C4.71 0 0 4.038 0 9c0 3.672 2.562 6.94 6.528 8.326a4.059 4.059 0 001.331.229 3.769 3.769 0 00.867-.1 1 1 0 00-.461-1.946z\"/><circle cx=\"5.5\" cy=\"10.459\" r=\"1.5\"/><circle cx=\"7.5\" cy=\"6\" r=\"1.5\"/><circle cx=\"11.75\" cy=\"4.25\" r=\"1.25\"/><circle cx=\"16.25\" cy=\"7.25\" r=\"1.25\"/><path d=\"M23.685 16.61l-6-2.382a.5.5 0 00-.37 0l-6 2.382a.5.5 0 00-.315.475v3.13a.5.5 0 001 0v-2.08a.251.251 0 01.106-.2.249.249 0 01.227-.032l5 1.765a.49.49 0 00.332 0l6-2.117a.5.5 0 00.019-.937z\"/><path d=\"M20.763 19.829l-2.93 1.034a1 1 0 01-.666 0l-2.93-1.034a.5.5 0 00-.666.472v2.145a.5.5 0 00.334.471l2.573.908a3.069 3.069 0 002.043 0l2.574-.908a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.472zM11.625 14.358a2.005 2.005 0 011.175-1.272 1.914 1.914 0 011.666.118 1 1 0 00.98-1.743 3.926 3.926 0 00-3.382-.235 4 4 0 00-2.35 2.531 1 1 0 00.653 1.254.989.989 0 00.3.047 1 1 0 00.958-.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});