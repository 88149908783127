define("ember-svg-jar/inlined/hardware-hammer-nail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hardware-hammer-nail</title><path d=\"M22.993 17H19a1 1 0 100 2h.454a.249.249 0 01.247.209l.716 4.3a.589.589 0 001.163 0l.716-4.3a.249.249 0 01.243-.209h.454a1 1 0 100-2zM5.278 9.119a.5.5 0 00-.707 0L.154 13.536a.5.5 0 00-.147.353v5.653A.5.5 0 00.316 20a.5.5 0 00.544-.1l7.24-7.248a.5.5 0 000-.706zM19.056 10.178a1.5 1.5 0 000-2.119L18 7a1.021 1.021 0 00-1.412 0 1.025 1.025 0 01-1.418 0 1 1 0 01-.224-1.074 1.5 1.5 0 00-.334-1.606c-1.433-1.438-2.007-2.014-2.291-2.244l.006-.006A7.738 7.738 0 002.3 1.623a1 1 0 00.638 1.8A5.013 5.013 0 016.689 4.88a3.385 3.385 0 01.721.938.435.435 0 01-.093.555l-.982.98a.5.5 0 000 .707l2.827 2.826a.5.5 0 00.706 0l1.061-1.06a1.022 1.022 0 011.413 0 1 1 0 010 1.413 1 1 0 000 1.413l1.06 1.06a1.487 1.487 0 001.06.44 1.491 1.491 0 001.059-.44z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});