define("ember-svg-jar/inlined/car-repair-fluid-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-fluid-1</title><path d=\"M16.663 11.893c.372-.531.713-1 .98-1.349a2 2 0 011.6-.794 1.979 1.979 0 011.357.55.07.07 0 00.118-.051V8a2.739 2.739 0 00-1-2.115.252.252 0 01-.09-.195.245.245 0 01.093-.193l.895-.716a1 1 0 00-1.249-1.562l-.725.58a.249.249 0 01-.4-.168l-.1-.88A3.017 3.017 0 0015.148 0H5.829a3.011 3.011 0 00-2.987 2.725l-.1.889a.25.25 0 01-.405.168l-.7-.563a1 1 0 00-1.25 1.562l.884.707a.25.25 0 010 .389A2.737 2.737 0 00.262 8v2.25A1.752 1.752 0 002.012 12h14.446a.249.249 0 00.205-.107zM4.635 4.723l.2-1.806a1.007 1.007 0 011-.917h9.319a1.013 1.013 0 011 .943l.2 1.78a.249.249 0 01-.26.277H4.883a.249.249 0 01-.248-.277zm-.623 5.527a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm11.5-1.5a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5zM6.512 13h-4a.249.249 0 00-.25.25v.25a2.25 2.25 0 004.5 0v-.25a.251.251 0 00-.25-.25zM14.744 14.675a.249.249 0 00.22-.126c.229-.4.466-.788.705-1.165a.25.25 0 00-.211-.384h-.946a.249.249 0 00-.25.25v.25a2.19 2.19 0 00.265 1.044.251.251 0 00.217.131zM19.837 11.3a.777.777 0 00-1.2 0c-.425.562-4.151 5.56-4.151 7.952a4.75 4.75 0 009.5 0c.003-2.394-3.724-7.392-4.149-7.952z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});