define("ember-svg-jar/inlined/gift-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gift-box</title><path d=\"M13 12a.5.5 0 00.5.5H22a1.753 1.753 0 001.75-1.75v-2A1.752 1.752 0 0022 7h-8.5a.5.5 0 00-.5.5zM3.75 12.5H11a.5.5 0 00.5-.5V7.5A.5.5 0 0011 7H2A1.752 1.752 0 00.25 8.75v2A1.753 1.753 0 002 12.5zM11.5 14.5a.5.5 0 00-.5-.5H2.75a.5.5 0 00-.5.5v7.75A1.753 1.753 0 004 24h7a.5.5 0 00.5-.5zM13.5 14a.5.5 0 00-.5.5v9a.5.5 0 00.5.5H20a1.753 1.753 0 001.75-1.75V14.5a.5.5 0 00-.5-.5z\"/><path d=\"M13.043 6.707a1 1 0 00.944.263c1.183-.289 4.03-1.054 4.9-1.924A2.957 2.957 0 0014.7.865c-.757.757-1.479 3.077-1.924 4.9a1.041 1.041 0 00.267.942zm3.074-4.428a.957.957 0 111.353 1.353 8.987 8.987 0 01-2.284.932 8.921 8.921 0 01.931-2.285zM11.721 5.761C11.277 3.944 10.555 1.623 9.8.865a2.957 2.957 0 00-4.185 4.181c.868.87 3.715 1.635 4.9 1.924a1 1 0 00.944-.263 1.041 1.041 0 00.262-.946zM7.03 3.632a.956.956 0 111.352-1.353 8.883 8.883 0 01.931 2.285 8.894 8.894 0 01-2.283-.932z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});