define("ember-svg-jar/inlined/road-sign-right-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-arrow</title><path d=\"M20 0H9.107a.25.25 0 00-.181.423L19.67 11.654a.5.5 0 010 .692L8.926 23.577a.25.25 0 00.181.423H20a.75.75 0 00.75-.75V.75A.75.75 0 0020 0z\"/><path d=\"M15.205 11.654L4.1.041A.136.136 0 004 0a.75.75 0 00-.75.75v22.5A.75.75 0 004 24a.134.134 0 00.1-.041l11.105-11.613a.5.5 0 000-.692z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});