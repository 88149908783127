define("ember-svg-jar/inlined/bird-cage-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bird-cage-empty</title><path d=\"M23.5 21.875a1 1 0 00-1-1H22v-7A10.016 10.016 0 0013.557 4a2.25 2.25 0 10-3.114 0A10.016 10.016 0 002 13.875v7h-.5a1 1 0 00-1 1v1a1 1 0 001 1h21a1 1 0 001-1zM8 7.1a.249.249 0 01.129-.219 7.926 7.926 0 012.581-.9.251.251 0 01.29.247v6.149a1 1 0 002 0V6.226a.249.249 0 01.29-.247 7.926 7.926 0 012.581.9A.249.249 0 0116 7.1v13.03a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.75h1.25a1 1 0 000-2h-4.5a1 1 0 000 2H11v2.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25zm12 6.78v6.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V9.3a.251.251 0 01.452-.149A7.964 7.964 0 0120 13.875zm-16 0a7.964 7.964 0 011.548-4.73A.251.251 0 016 9.3v10.825a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});