define("ember-svg-jar/inlined/office-chair-table-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-chair-table-2</title><path d=\"M8.5 7a1 1 0 001-1v-.5a2.5 2.5 0 015 0V6a1 1 0 002 0v-.5a4.5 4.5 0 00-9 0V6a1 1 0 001 1zM16.875 14.547a.5.5 0 01-.375-.484V13a1 1 0 00-2 0v1a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-1a1 1 0 00-2 0v1.064a.5.5 0 01-.375.484A1.5 1.5 0 007.5 17.5h9a1.5 1.5 0 00.375-2.952z\"/><path d=\"M23 8.5H1a1 1 0 000 2h.5a.5.5 0 01.5.5v11a1 1 0 002 0V11a.5.5 0 01.5-.5h15a.5.5 0 01.5.5v11a1 1 0 002 0V11a.5.5 0 01.5-.5h.5a1 1 0 000-2z\"/><path d=\"M7.005 21.9A1 1 0 007.9 23H8a1 1 0 00.994-.9l.305-3.05a.5.5 0 00-.5-.55H7.8a.5.5 0 00-.5.451zM15.2 18.5a.5.5 0 00-.5.55l.305 3.05A1 1 0 0016 23h.1a1 1 0 00.9-1.1l-.3-2.95a.5.5 0 00-.5-.451z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});