define("ember-svg-jar/inlined/shopping-bag-sad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-bag-sad</title><path d=\"M21.965 20.467l-.9-13A1.574 1.574 0 0019.5 6h-1.75a.25.25 0 01-.25-.25V5.5a5.5 5.5 0 00-11 0v.25a.25.25 0 01-.25.25H4.5a1.574 1.574 0 00-1.567 1.463l-.9 13Q2 20.983 2 21.5A2.5 2.5 0 004.5 24h15a2.5 2.5 0 002.5-2.5q0-.517-.035-1.033zM8 12a1 1 0 11-1 1 1 1 0 011-1zm-.366 7.082a5.251 5.251 0 018.731 0 .75.75 0 11-1.246.834 3.752 3.752 0 00-6.239 0 .75.75 0 01-1.246-.835zM16 14a1 1 0 111-1 1 1 0 01-1 1zM8.5 5.5a3.5 3.5 0 017 0v.25a.25.25 0 01-.25.25h-6.5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});