define("ember-svg-jar/inlined/navigation-left-circle-1-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-left-circle-1_1</title><path d=\"M24 12a12 12 0 10-12 12 12.013 12.013 0 0012-12zm-13.707 5.2l-4.5-4.5a1 1 0 010-1.414l4.5-4.5A1 1 0 1111.707 8.2l-2.366 2.371a.25.25 0 00.177.429H18a1 1 0 010 2H9.518a.25.25 0 00-.177.426l2.366 2.367a1 1 0 01-1.414 1.407z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});