define("ember-svg-jar/inlined/multiple-actions-coding.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-coding</title><path d=\"M14.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.272 6.272 0 01-.921 3.624.25.25 0 00.122.372c1.472.542 3.109 1.167 3.83 2.41a.25.25 0 00.3.11 7.872 7.872 0 011.557-.4.25.25 0 00.061-.477 40.098 40.098 0 00-1.878-.724z\"/><path d=\"M13.224 10.77a.249.249 0 00.066-.346c-.653-.964-2.639-1.588-4.3-2.2a1.8 1.8 0 01-.06-1.17 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.663a.251.251 0 00.222-.136 8 8 0 012.839-3.094zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-2.281 3.97a.75.75 0 111.061 1.06l-1.293 1.293a.25.25 0 000 .354l1.293 1.293a.75.75 0 11-1.061 1.06l-2-2a.749.749 0 010-1.06zm4.561 5.06a.75.75 0 01-1.061-1.06l1.293-1.293a.25.25 0 000-.354l-1.293-1.293a.75.75 0 111.061-1.06l2 2a.749.749 0 010 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});