define("ember-svg-jar/inlined/keyboard-arrow-return", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-arrow-return</title><path d=\"M16.147 1.952h-3.909a1.5 1.5 0 000 3h3.909a4.852 4.852 0 110 9.7h-9.01a.25.25 0 01-.25-.25V11.27a1 1 0 00-1.707-.707L.293 15.449a1 1 0 000 1.414L5.18 21.75a1 1 0 001.707-.707v-3.137a.25.25 0 01.25-.25h9.01a7.852 7.852 0 100-15.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});