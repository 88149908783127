define("ember-svg-jar/inlined/video-game-control-directions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-control-directions</title><path d=\"M23.579 11.215A2.345 2.345 0 0024 9.875 2.377 2.377 0 0021.625 7.5H20a.75.75 0 00-.75.75v6.5a.75.75 0 00.75.75h1.625A2.377 2.377 0 0024 13.125a2.345 2.345 0 00-.421-1.34.5.5 0 010-.57zM21.625 14h-.375a.5.5 0 01-.5-.5v-.75a.5.5 0 01.5-.5h.375a.875.875 0 010 1.75zm0-3.25h-.375a.5.5 0 01-.5-.5V9.5a.5.5 0 01.5-.5h.375a.875.875 0 010 1.75zM14.667 15.833a2.419 2.419 0 00-2.417 2.417v5a.75.75 0 001.5 0V22a.5.5 0 01.5-.5h.833a.5.5 0 01.5.5v1.25a.75.75 0 001.5 0v-5a2.419 2.419 0 00-2.416-2.417zm.916 3.667a.5.5 0 01-.5.5h-.833a.5.5 0 01-.5-.5v-1.25a.917.917 0 111.833 0zM16 9.73V6.27a1.656 1.656 0 00-1.654-1.655h-2.461a.5.5 0 01-.5-.5V1.654A1.656 1.656 0 009.731 0H6.269a1.656 1.656 0 00-1.654 1.654v2.461a.5.5 0 01-.5.5H1.654A1.656 1.656 0 000 6.27v3.46a1.656 1.656 0 001.654 1.655h2.461a.5.5 0 01.5.5v2.461A1.656 1.656 0 006.269 16h3.462a1.656 1.656 0 001.654-1.654v-2.461a.5.5 0 01.5-.5h2.461A1.656 1.656 0 0016 9.73zM4.25 9a.5.5 0 01-.854.354l-1.5-1a.5.5 0 010-.708l1.5-1A.5.5 0 014.25 7zm5.1 3.6l-1 1.5a.5.5 0 01-.708 0l-1-1.5A.5.5 0 017 11.75h2a.5.5 0 01.354.854zm.108-8.663A.5.5 0 019 4.25H7a.5.5 0 01-.354-.85l1-1.5a.5.5 0 01.708 0l1 1.5a.5.5 0 01.108.541zM14.1 8.354l-1.5 1A.5.5 0 0111.75 9V7a.5.5 0 01.854-.354l1.5 1a.5.5 0 01-.004.708z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});