define("ember-svg-jar/inlined/design-file-ttf-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-file-ttf-1</title><path d=\"M23.707 5.708L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.706z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM5.5 12.125H4.375a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5.125a.25.25 0 00-.25-.25H1.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm6 0h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5.125a.25.25 0 00-.25-.25H7.5a.625.625 0 010-1.25h4a.625.625 0 010 1.25zm5 0h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H15.5a.625.625 0 010 1.25h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5a1.626 1.626 0 011.625-1.625h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});