define("ember-svg-jar/inlined/programming-language-monitor-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-language-monitor-html</title><path d=\"M1 5.916a1 1 0 001-1V3.005a.551.551 0 01.5-.589h19a.551.551 0 01.5.589v1.911a1 1 0 002 0V3.005A2.548 2.548 0 0021.5.416h-19A2.548 2.548 0 000 3.005v1.911a1 1 0 001 1zM19 23.584a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25v-1.168a.25.25 0 01.25-.25H21a3 3 0 003-3v-3a1 1 0 10-2 0v1a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-1.5a1 1 0 00-2 0v3.5a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.168a.25.25 0 01-.25.25H5a1 1 0 000 2z\"/><path d=\"M9.5 12.166a.75.75 0 00.75-.75v-4a.25.25 0 01.25-.25.75.75 0 000-1.5h-2a.75.75 0 000 1.5.25.25 0 01.25.25v4a.75.75 0 00.75.75zM6.25 6.416a.75.75 0 00-1.5 0v2a.25.25 0 01-.25.25H4a.25.25 0 01-.25-.25v-2a.75.75 0 00-1.5 0v5a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1a.75.75 0 001.5 0zM19.5 5.666a.75.75 0 00-.75.75v4a1.752 1.752 0 001.75 1.75h1a.75.75 0 000-1.5h-1a.25.25 0 01-.25-.25v-4a.75.75 0 00-.75-.75zM16.5 12.166a.75.75 0 00.75-.75v-5a.751.751 0 00-1.394-.385L15.214 7.1a.25.25 0 01-.428 0l-.642-1.07a.751.751 0 00-1.394.385v5a.75.75 0 001.5 0V9.331a.058.058 0 01.042-.055.057.057 0 01.064.026.782.782 0 001.288 0 .057.057 0 01.064-.026.058.058 0 01.042.055v2.085a.75.75 0 00.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});