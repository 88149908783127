define("ember-svg-jar/inlined/smiley-kiss-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss-alternate</title><path d=\"M14.5 12.5a2.5 2.5 0 00-2.226 1.364.252.252 0 01-.223.136H11.5a1 1 0 000 2H13a1 1 0 001-1 .5.5 0 011 0v3a.5.5 0 01-1 0 1 1 0 00-1-1h-1.5a1 1 0 000 2h.551a.252.252 0 01.223.136A2.5 2.5 0 0017 18v-3a2.5 2.5 0 00-2.5-2.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M10.4 7.584a1 1 0 00-1.316.516 1.5 1.5 0 01-2.751 0 1 1 0 00-1.833.8 3.5 3.5 0 006.415 0 1 1 0 00-.515-1.316zM18.983 7.584a1 1 0 00-1.316.516 1.5 1.5 0 01-2.751 0 1 1 0 10-1.832.8 3.5 3.5 0 006.415 0 1 1 0 00-.516-1.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});