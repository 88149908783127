define("ember-svg-jar/inlined/road-sign-right-reverse-turn-ahead-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-right-reverse-turn-ahead-1-alternate</title><path d=\"M10.25 11.192H12a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.354-.853l2.147-2.147a1 1 0 011.414 0L16.6 8.839a.5.5 0 01-.354.853H15.5a.25.25 0 00-.25.25v3.25a1 1 0 01-1 1H12.5a.25.25 0 00-.25.25v2.75a1 1 0 01-1 1h-1a1 1 0 01-1-1v-5a1 1 0 011-1z\"/><path d=\"M11.293 23.707a1 1 0 001.414 0l11-11a1 1 0 000-1.414l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414zm.53-21.116a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354l-9.232 9.232a.25.25 0 01-.354 0l-9.232-9.232a.25.25 0 010-.354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});