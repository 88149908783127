define("ember-svg-jar/inlined/phone-action-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-edit</title><path d=\"M16.5 8.416a1.916 1.916 0 00-3.267-1.356l-.247.247 2.707 2.708.247-.248a1.9 1.9 0 00.56-1.351zM8.288 12.994a.25.25 0 00-.422.128L7.51 14.9a.5.5 0 00.49.6.473.473 0 00.1-.01l1.78-.355a.25.25 0 00.127-.422z\"/><rect x=\"9.071\" y=\"9.343\" width=\"5.344\" height=\"3.829\" rx=\".25\" ry=\".25\" transform=\"rotate(-45.003 11.743 11.258)\"/><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});