define("ember-svg-jar/inlined/programming-user-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>programming-user-chat</title><path d=\"M24 1.75A1.752 1.752 0 0022.25 0h-10a1.752 1.752 0 00-1.75 1.75v8a1.752 1.752 0 001.75 1.75.25.25 0 01.25.25v2a.749.749 0 00.75.75.742.742 0 00.488-.181l3.219-2.759a.249.249 0 01.163-.06h5.13A1.752 1.752 0 0024 9.75zm-7.22 4.03l-2 2a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354L13.72 3.78a.75.75 0 111.06-1.06l2 2a.749.749 0 010 1.06zM20.75 8h-2.5a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zM5.08 9.77A5.086 5.086 0 000 14.85v3.05a.5.5 0 00.5.5h1.635a.25.25 0 01.248.216l.676 4.948a.5.5 0 00.495.436h3.052a.5.5 0 00.5-.433l.675-4.948a.25.25 0 01.248-.216H9.66a.5.5 0 00.5-.5V14.85a5.086 5.086 0 00-5.08-5.08zM5.242 8.941a3.3 3.3 0 10-3.295-3.3 3.295 3.295 0 003.295 3.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});