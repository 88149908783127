define("ember-svg-jar/inlined/day-afternoon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>day-afternoon</title><path d=\"M23 18.5h-.984a10.019 10.019 0 00-5.186-8.878 1 1 0 00-.969 1.75A8.035 8.035 0 0120.02 18.5H3.88a8.064 8.064 0 01.451-2.749 1 1 0 00-1.885-.667 10.036 10.036 0 00-.564 3.416H1a1 1 0 000 2h22a1 1 0 000-2z\"/><path d=\"M13 17.5v-2a1 1 0 00-2 0v2a1 1 0 002 0zM12 13.5a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM12 7.5a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM1.962 10.278h1.157a2.566 2.566 0 00.195.471l-.818.818a.75.75 0 101.061 1.061l.818-.818a2.566 2.566 0 00.471.2v1.157a.75.75 0 101.5 0v-1.162a2.586 2.586 0 00.47-.2l.819.818A.75.75 0 008.7 11.567l-.818-.818a2.566 2.566 0 00.195-.471h1.152a.75.75 0 000-1.5H8.072a2.625 2.625 0 00-.195-.471l.823-.818a.75.75 0 00-1.06-1.06l-.819.818a2.586 2.586 0 00-.47-.2V5.9a.75.75 0 00-1.5 0v1.152a2.566 2.566 0 00-.471.2l-.818-.818A.75.75 0 102.5 7.489l.818.818a2.625 2.625 0 00-.195.471H1.962a.75.75 0 000 1.5zm4.738-.75a1.1 1.1 0 11-1.1-1.1 1.1 1.1 0 011.1 1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});