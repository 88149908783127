define("ember-svg-jar/inlined/road-sign-tunnel-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-tunnel-ahead</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM18 13.75h-2.5a.786.786 0 01-.739-.627 2.792 2.792 0 00-5.52 0 .8.8 0 01-.74.627H6a.75.75 0 010-1.5h1.751a.251.251 0 00.234-.162A4.167 4.167 0 0112 9.25a4.167 4.167 0 014.015 2.838.251.251 0 00.234.162H18a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});