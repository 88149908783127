define("ember-svg-jar/inlined/card-game-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-choose</title><path d=\"M23.707 22.293l-3.064-3.064a.5.5 0 01.156-.813l.555-.238a1 1 0 00-.014-1.843l-6.29-2.591a1 1 0 00-1.305 1.306l2.589 6.29a1 1 0 00.918.619h.007a1 1 0 00.919-.606l.238-.554a.5.5 0 01.813-.156l3.064 3.064a1 1 0 001.414-1.414zM11.781 5.555a.352.352 0 00-.562 0L9.553 7.777a.353.353 0 000 .421l1.666 2.221a.352.352 0 00.562 0L13.447 8.2a.353.353 0 000-.421z\"/><path d=\"M11.5 14H8a.5.5 0 01-.5-.5v-11A.5.5 0 018 2h7a.5.5 0 01.5.5v9.513a1 1 0 102 0V2a2 2 0 00-2-2h-8a2 2 0 00-2 2v1.45l-3.96.942A2 2 0 00.055 6.8L2.83 18.475a2 2 0 001.941 1.539 2.014 2.014 0 00.467-.056l3.893-.926a1 1 0 10-.462-1.945l-3.406.81a.5.5 0 01-.6-.371L2.117 6.824a.5.5 0 01.371-.6l2.4-.57a.5.5 0 01.616.487V14a2 2 0 002 2h4a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});