define("ember-svg-jar/inlined/love-it-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-settings</title><path d=\"M9.685 17.635a.253.253 0 00-.066-.186l-6.285-6.807a4.422 4.422 0 01-.849-5.16 4.5 4.5 0 013.329-2.42 4.578 4.578 0 013.994 1.284l1.531 1.4a1 1 0 001.347 0l1.562-1.423a4.548 4.548 0 013.965-1.257 4.5 4.5 0 013.329 2.42 4.171 4.171 0 01-.144 4.07.248.248 0 00.006.26.251.251 0 00.235.114 3.38 3.38 0 011.478.17.25.25 0 00.31-.134 6.163 6.163 0 00-.1-5.384 6.59 6.59 0 00-10.455-1.71l-.859.783-.828-.755a6.528 6.528 0 00-5.693-1.811A6.472 6.472 0 00.7 4.578a6.4 6.4 0 001.2 7.454l7.215 7.816a.249.249 0 00.433-.152z\"/><circle cx=\"17.506\" cy=\"16.033\" r=\"1.5\"/><path d=\"M23.275 14.655a1.783 1.783 0 00-1.7-2.953l-1.411.327a.454.454 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.455.455 0 01-.533.311l-1.412-.329a1.783 1.783 0 00-1.7 2.953l.987 1.065a.461.461 0 010 .625l-.987 1.065a1.783 1.783 0 001.7 2.953l1.412-.327a.45.45 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.449.449 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.065a.461.461 0 010-.625zm-2.769 1.378a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});