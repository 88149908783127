define("ember-svg-jar/inlined/plant-bonsai-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plant-bonsai-1</title><path d=\"M13.25 13.5a4 4 0 003.891-3.065.65.65 0 01.524-.49 4 4 0 00-1.23-7.906.648.648 0 01-.587-.228 5 5 0 00-7.7 0 .651.651 0 01-.59.228A4 4 0 107 10h3.25a.5.5 0 01.5.5V15a.5.5 0 01-.5.5h-4a1 1 0 000 2h11.5a1 1 0 000-2h-4.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5zm-.5-3.144a.5.5 0 01.387-.487 4.938 4.938 0 001.189-.444.646.646 0 01.633.016.5.5 0 01.23.552A2 2 0 0113.25 11.5h-.5zM16.136 18.5H7.864a.5.5 0 00-.5.572l.536 3.614A1.5 1.5 0 009.383 24h5.234a1.5 1.5 0 001.488-1.309l.526-3.619a.5.5 0 00-.5-.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});