define("ember-svg-jar/inlined/meeting-headphone-wireless", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-headphone-wireless</title><path d=\"M19.137 14.223a1 1 0 01-.507-.687A6.965 6.965 0 0012 8a6.965 6.965 0 00-6.63 5.537 1 1 0 01-.507.687 4 4 0 001.887 7.525.5.5 0 00.5-.5v-6.5A4.938 4.938 0 0112 10a4.938 4.938 0 014.75 4.75v5.863a.5.5 0 01-.241.428 3.178 3.178 0 01-1.148.345.5.5 0 00-.415.6 2.5 2.5 0 01.033.841.5.5 0 00.568.559A3.845 3.845 0 0018.5 21.79a.99.99 0 01.449-.421 4 4 0 00.184-7.146z\"/><path d=\"M12.5 21h-1a1.5 1.5 0 100 3h1a1.5 1.5 0 000-3zM5.8 4.569a8.768 8.768 0 0112.4 0 1 1 0 001.414-1.414 10.767 10.767 0 00-15.232 0A1 1 0 005.8 4.569zM6.787 6.159A1 1 0 008.2 7.573a5.38 5.38 0 017.6 0 1 1 0 001.414-1.414 7.38 7.38 0 00-10.427 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});