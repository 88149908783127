define("ember-svg-jar/inlined/car-repair-fire-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-fire-1</title><path d=\"M22.474 6.43a.5.5 0 00-.932.044 1.829 1.829 0 01-.389.622.25.25 0 01-.434-.141c-.493-4.563-4.642-6.78-4.836-6.88a.5.5 0 00-.66.7 1.968 1.968 0 01-.523 2.609 14.574 14.574 0 00-1.688 1.448.253.253 0 01-.182.074.249.249 0 01-.179-.08 6.5 6.5 0 00-2.392-1.437l-.066-.026a.53.53 0 00-.8.316.454.454 0 00.186.481c.309.386.006 1.233-.15 1.553a7.315 7.315 0 00-.507 3.813.25.25 0 00.246.206h4.05A3.005 3.005 0 0116.2 12.36l.21 1.682a.25.25 0 00.127.188 4.01 4.01 0 011.721 1.9.249.249 0 00.325.13 7.222 7.222 0 003.857-3.324 8.261 8.261 0 00.034-6.506z\"/><path d=\"M15.672 15.209a.248.248 0 01-.151-.2l-.316-2.526a2 2 0 00-1.984-1.752H4.986A2 2 0 003 12.484l-.314 2.526a.247.247 0 01-.151.2 2.744 2.744 0 00-1.681 2.522v1.25a1.752 1.752 0 001.75 1.75.25.25 0 01.25.25v1.25a1.75 1.75 0 003.5 0v-1.25a.249.249 0 01.25-.25h5.5a.25.25 0 01.25.25v1.25a1.75 1.75 0 003.5 0v-1.321a.25.25 0 01.187-.242 1.745 1.745 0 001.313-1.687v-1.25a2.745 2.745 0 00-1.682-2.523zm-2.266-.309a.247.247 0 01-.187.084H4.988A.248.248 0 014.8 14.9a.252.252 0 01-.061-.2l.219-1.75a.25.25 0 01.248-.219H13a.25.25 0 01.248.219l.219 1.75a.248.248 0 01-.061.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});