define("ember-svg-jar/inlined/style-three-pin-hair-dryer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hair-dryer</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm3.536 13.588A5.028 5.028 0 0111 16.5a1.5 1.5 0 010-3 1.989 1.989 0 001.372-.548.5.5 0 01.479-.119A4.232 4.232 0 0014 13a4.314 4.314 0 00.971-.111.5.5 0 01.565.7zM14 12a72.207 72.207 0 01-7.164-.937A1 1 0 016 10.078V6.922a1 1 0 01.836-.985A71.939 71.939 0 0114 5a3.5 3.5 0 010 7z\"/><circle cx=\"14\" cy=\"8.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});