define("ember-svg-jar/inlined/single-neutral-actions-edit-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-edit-2</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M16.026 21.7a.5.5 0 00.707 0l6.5-6.5a2.623 2.623 0 00-.2-3.882 2.71 2.71 0 00-3.606.274l-6.4 6.4a.5.5 0 000 .707zM11.6 23.713l2.685-.537a.5.5 0 00.256-.844l-2.148-2.147a.5.5 0 00-.844.255l-.537 2.685a.5.5 0 00.49.6.466.466 0 00.098-.012zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 10-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.501\" cy=\"3.75\" r=\"3.5\"/><path d=\"M16.026 21.7a.5.5 0 00.707 0l6.5-6.5a2.623 2.623 0 00-.2-3.882 2.71 2.71 0 00-3.606.274l-6.4 6.4a.5.5 0 000 .707zM11.6 23.713l2.685-.537a.5.5 0 00.256-.844l-2.148-2.147a.5.5 0 00-.844.255l-.537 2.685a.5.5 0 00.49.6.466.466 0 00.098-.012z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});