define("ember-svg-jar/inlined/database", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database</title><path d=\"M23 20.5h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5zM2 20.5H1A.75.75 0 001 22h1a.75.75 0 000-1.5zM11.25 18.435a.249.249 0 01-.166.235 2.748 2.748 0 00-1.664 1.664.249.249 0 01-.235.166H5.5a.75.75 0 000 1.5h3.685a.249.249 0 01.235.166 2.732 2.732 0 005.16 0 .249.249 0 01.235-.166H18.5a.75.75 0 000-1.5h-3.685a.248.248 0 01-.235-.166 2.746 2.746 0 00-1.664-1.664.249.249 0 01-.166-.235v-1.2a.25.25 0 01.242-.25c4.9-.16 8.758-1.879 8.758-3.983v-1.718a.25.25 0 00-.408-.194c-1.937 1.578-5.588 2.41-9.092 2.41s-7.15-.832-9.092-2.41a.25.25 0 00-.408.194V13c0 2.033 3.6 3.707 8.264 3.963a.249.249 0 01.236.249zm2 2.815A1.25 1.25 0 1112 20a1.251 1.251 0 011.25 1.25z\"/><path d=\"M12.25 12.5c5.246 0 9.5-1.79 9.5-4V6.784a.25.25 0 00-.408-.193C19.405 8.168 15.755 9 12.251 9S5.1 8.167 3.158 6.589a.25.25 0 00-.408.194V8.5c0 2.21 4.25 4 9.5 4z\"/><ellipse cx=\"12.251\" cy=\"4\" rx=\"9.5\" ry=\"4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});