define("ember-svg-jar/inlined/swimming-pool-stairs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-pool-stairs</title><path d=\"M8 19.5a1 1 0 001-1v-1a.5.5 0 01.5-.5H15a.5.5 0 01.5.5v1a1 1 0 002 0v-15a1.5 1.5 0 013 0 1 1 0 002 0 3.5 3.5 0 00-7 0v2a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0 1 1 0 002 0 3.5 3.5 0 00-7 0v15a1 1 0 001 1zm1-11a.5.5 0 01.5-.5H15a.5.5 0 01.5.5V10a.5.5 0 01-.5.5H9.5A.5.5 0 019 10zM9 13a.5.5 0 01.5-.5H15a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H9.5a.5.5 0 01-.5-.5zM22.868 21.941c-1.629.214-2.739-1.5-2.746-1.513a1.044 1.044 0 00-1.719.028 3.265 3.265 0 01-2.674 1.51 2.725 2.725 0 01-2.336-1.44 1.046 1.046 0 00-1.713-.16 4.2 4.2 0 01-3.027 1.6 3.465 3.465 0 01-2.685-1.538 1.041 1.041 0 00-1.638-.091c-.015.018-1.522 1.854-3.2 1.644a1 1 0 00-.264 1.983 4.287 4.287 0 00.551.036 5.478 5.478 0 003.324-1.3.5.5 0 01.633.011 5.042 5.042 0 003.279 1.252 5.624 5.624 0 003.4-1.2.5.5 0 01.636.027 4.455 4.455 0 003.044 1.17 5 5 0 003.226-1.173.5.5 0 01.64 0 4.459 4.459 0 003.537 1.127 1 1 0 00.859-1.123 1.01 1.01 0 00-1.127-.85z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});