define("ember-svg-jar/inlined/real-estate-action-house-wrench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-house-wrench</title><path d=\"M23.722 11.8L12.724.3a1.038 1.038 0 00-1.446 0L.28 11.8A1 1 0 001 13.492h1.5a.5.5 0 01.5.5v8.5a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-8.5a.5.5 0 01.5-.5H23a1 1 0 00.722-1.692zm-9.972 3.724a.5.5 0 00-.25.433v4.535a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-4.535a.5.5 0 00-.25-.433 3.5 3.5 0 012.786-6.376.5.5 0 01.2.833l-.866.859a1.25 1.25 0 001.76 1.776l.514-.51a.5.5 0 01.852.386 3.5 3.5 0 01-1.746 3.032z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});