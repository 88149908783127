define("ember-svg-jar/inlined/people-man-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-2</title><path d=\"M10.148 12.366a.751.751 0 00.29 1.02 2.661 2.661 0 00.5.214 3.575 3.575 0 001.005.15 3.351 3.351 0 00.993-.148 2.669 2.669 0 00.5-.217.75.75 0 10-.735-1.307 1.192 1.192 0 01-.219.093 1.839 1.839 0 01-.544.079 1.876 1.876 0 01-.551-.08 1.3 1.3 0 01-.222-.094.748.748 0 00-1.017.29zM9.427 9.825a.559.559 0 01.558 0 .75.75 0 101.021-1.1 2.017 2.017 0 00-2.6 0 .75.75 0 001.021 1.1zM13.848 9.825a.559.559 0 01.558 0 .75.75 0 101.021-1.1 2.017 2.017 0 00-2.6 0 .75.75 0 001.021 1.1zM21.08 18.287l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.287A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213z\"/><path d=\"M6.106 12.047a.253.253 0 01.144.188c.511 3.047 4.022 4.991 5.687 4.991s5.176-1.944 5.687-4.992a.25.25 0 01.143-.187 2.724 2.724 0 001.439-2.433 2.115 2.115 0 00-.46-1.439.251.251 0 01-.06-.159 7.929 7.929 0 00-1.758-5.5.672.672 0 00-.146-.116.252.252 0 01-.111-.143 3.477 3.477 0 00-.935-1.642.5.5 0 00-.6-.031l-1.056.706a.25.25 0 01-.288-.008L12.216.1a.5.5 0 00-.6 0l-1.575 1.182a.25.25 0 01-.288.008L8.694.584a.5.5 0 00-.6.031 3.51 3.51 0 00-.951 1.7.22.22 0 01-.093.125.288.288 0 00-.05.038c-1.458 1.5-1.755 3.686-1.8 5.534a.244.244 0 01-.061.157 2.123 2.123 0 00-.467 1.444 2.731 2.731 0 001.434 2.434zm.166-2.9a.75.75 0 00.415-.666 10.638 10.638 0 01.449-3.366.229.229 0 01.392-.072A3.778 3.778 0 0010.416 6.5h3a3.78 3.78 0 002.889-1.457l.018-.022a.251.251 0 01.43.075 10.172 10.172 0 01.434 3.375.751.751 0 00.415.676.621.621 0 01.1.439 1.27 1.27 0 01-.558 1.095 1.747 1.747 0 00-1 1.306c-.378 2.248-3.176 3.739-4.208 3.739s-3.83-1.491-4.208-3.741a1.753 1.753 0 00-1-1.3 1.276 1.276 0 01-.559-1.1c-.007-.285.078-.426.103-.438z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});