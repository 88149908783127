define("ember-svg-jar/inlined/car-sports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-sports</title><path d=\"M19.75 9.25c-.293 0-.584-.005-.867-.01-.249 0-.491-.008-.727-.009a.249.249 0 01-.223-.361l.462-.923a1 1 0 10-1.79-.894L15.2 9.867c-.05.1-.027.383-.639.383H10.98a.249.249 0 01-.214-.121A1.673 1.673 0 0010.6 9.9a.252.252 0 01.012-.342l1.85-1.85a1 1 0 10-1.414-1.414L8.159 9.177a.249.249 0 01-.177.073H4.75A4.756 4.756 0 000 14v1.5a1.252 1.252 0 001.25 1.25h1.3a.25.25 0 00.247-.293A2.651 2.651 0 012.75 16a3 3 0 016 0 2.651 2.651 0 01-.042.457.249.249 0 00.055.2.253.253 0 00.192.089h6.59a.25.25 0 00.247-.293A2.651 2.651 0 0115.75 16a3 3 0 016 0 2.651 2.651 0 01-.042.457.249.249 0 00.055.2.253.253 0 00.192.089h.8A1.252 1.252 0 0024 15.5V14c0-3.926-2.311-4.75-4.25-4.75z\"/><path d=\"M5.75 14a2 2 0 102 2 2 2 0 00-2-2zM18.75 14a2 2 0 102 2 2 2 0 00-2-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});