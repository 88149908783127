define("ember-svg-jar/inlined/iris-scan-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>iris-scan-lock</title><circle cx=\"11.999\" cy=\"7\" r=\"1.5\"/><path d=\"M18.5 9.5h1a4.407 4.407 0 011.989.486.252.252 0 00.272-.031 20.676 20.676 0 001.662-1.538 2.034 2.034 0 000-2.837C20.806 2.869 16.486 0 12.146 0h-.293C7.512 0 3.192 2.87.578 5.58a2.035 2.035 0 000 2.836C3.19 11.129 7.512 14 11.839 14h.307a10.456 10.456 0 001.673-.142.25.25 0 00.209-.227A4.49 4.49 0 0118.5 9.5zM12 11a4 4 0 114-4 4 4 0 01-4 4z\"/><path d=\"M22.713 15.515a.249.249 0 01-.214-.247V14.25a3.5 3.5 0 00-7 0v1.018a.249.249 0 01-.214.247A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.287-1.485zM19 21.25a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-1a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});