define("ember-svg-jar/inlined/smiley-one-eye-smile-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-one-eye-smile-alternate</title><path d=\"M23.066 12a1 1 0 00-1.085.907A10 10 0 115.617 4.319a10.072 10.072 0 018.283-2.15A1 1 0 0014.257.2 12 12 0 1012.022 24a11.933 11.933 0 0011.952-10.916A1 1 0 0023.066 12z\"/><path d=\"M12.022 8a5 5 0 105-5 5.006 5.006 0 00-5 5zm5-3a3 3 0 11-3 3 3 3 0 013-3z\"/><circle cx=\"17.022\" cy=\"7.998\" r=\"1.5\"/><path d=\"M12.023 19.5a6.5 6.5 0 005.63-3.25.5.5 0 00-.433-.75H6.826a.5.5 0 00-.432.751 6.5 6.5 0 005.629 3.249z\"/><circle cx=\"8.022\" cy=\"8.998\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});