define("ember-svg-jar/inlined/layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>layers</title><path d=\"M22.07 9.144l-9.64 4.6a.99.99 0 01-.86 0l-9.639-4.6a1 1 0 10-.861 1.8l9.638 4.6a3 3 0 002.584 0l9.639-4.6a1 1 0 00-.861-1.8z\"/><path d=\"M22.07 13.186l-9.64 4.6a1 1 0 01-.86 0l-9.639-4.6a1 1 0 00-.861 1.8l9.638 4.6a3 3 0 002.584 0l9.639-4.6a1 1 0 00-.861-1.8z\"/><path d=\"M22.07 17.051l-9.64 4.6a.99.99 0 01-.86 0l-9.639-4.6a1 1 0 00-.861 1.806l9.638 4.6a2.986 2.986 0 002.584 0l9.639-4.6a1 1 0 00-.861-1.806zM1.156 7.444l9.644 4.195a3.008 3.008 0 002.392 0l9.645-4.193a1.5 1.5 0 000-2.752L13.2.5a3.005 3.005 0 00-2.4 0L1.158 4.693a1.5 1.5 0 000 2.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});