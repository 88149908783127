define("ember-svg-jar/inlined/cursor-hand-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-hand-2</title><path d=\"M21.141 6.323a1.779 1.779 0 00-1.608-.243.251.251 0 00-.174.238v4.92a.5.5 0 01-1 0v-6.5a1.757 1.757 0 00-.718-1.415 1.78 1.78 0 00-1.608-.243.251.251 0 00-.174.238v6.42a.5.5 0 01-1 0v-7a1.752 1.752 0 00-1.75-1.75 1.622 1.622 0 00-.588.107.249.249 0 00-.162.233v8.41a.5.5 0 01-1 0v-5a1.75 1.75 0 10-3.5 0v10a.253.253 0 01-.183.243.246.246 0 01-.273-.1l-2.12-2.971a1.735 1.735 0 00-2.828 2.011l5.47 7.719a3.255 3.255 0 002.654 1.366h5.036a6.258 6.258 0 006.244-6.25V7.738a1.757 1.757 0 00-.718-1.415z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});