define("ember-svg-jar/inlined/road-sign-left-reverse-turn-ahead", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-left-reverse-turn-ahead</title><path d=\"M.47 12.53l11 11a.748.748 0 001.06 0l11-11a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06zm8-3.06l2-2a.749.749 0 011.06 0l2 2a.75.75 0 01-.53 1.28h-1a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.5a1.252 1.252 0 011.25 1.25V17a.75.75 0 01-1.5 0v-3a.25.25 0 00-.25-.25h-1.5a1.252 1.252 0 01-1.25-1.25V11a.25.25 0 00-.25-.25H9a.75.75 0 01-.53-1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});