define("ember-svg-jar/inlined/brightness-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>brightness-1</title><circle cx=\"12\" cy=\"12.001\" r=\"2.5\"/><path d=\"M7.808 12a1 1 0 00-1-1H4.5a1 1 0 100 2h2.308a1 1 0 001-1zM7.621 14.966L5.989 16.6a1 1 0 000 1.415 1.015 1.015 0 001.414 0l1.632-1.63a1 1 0 00-1.414-1.415zM12 16.193a1 1 0 00-1 1V19.5a1 1 0 002 0v-2.307a1 1 0 00-1-1zM16.378 14.965a1 1 0 10-1.414 1.414l1.636 1.632a1 1 0 101.41-1.411zM19.5 11h-2.308a1 1 0 000 2H19.5a1 1 0 000-2zM15.671 9.32a1 1 0 00.707-.283l1.632-1.632a1 1 0 00-1.41-1.414l-1.636 1.631a.991.991 0 00.707 1.7zM12 7.808a1 1 0 001-1V4.5a1 1 0 10-2 0v2.308a1 1 0 001 1zM7.641 9.011a.951.951 0 001.394.026 1 1 0 000-1.414L7.4 5.991a1 1 0 00-1.411 1.414c.356.356 1.439 1.442 1.652 1.606z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});