define("ember-svg-jar/inlined/phone-action-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-email</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M16.4 10.5a.19.19 0 00-.2.006l-3.289 2.383a1.736 1.736 0 01-1.834 0l-3.283-2.383a.191.191 0 00-.294.164v3.169A1.159 1.159 0 008.654 15h6.692a1.159 1.159 0 001.154-1.161V10.67a.2.2 0 00-.1-.17z\"/><path d=\"M15.346 8H8.654A1.127 1.127 0 007.5 9.1a.057.057 0 00.027.047l4.167 2.767a.609.609 0 00.612 0l4.167-2.768A.054.054 0 0016.5 9.1 1.127 1.127 0 0015.346 8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});