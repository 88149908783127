define("ember-svg-jar/inlined/smiley-tongue-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-tongue-3</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM9.5 9a2 2 0 11-2-2 2 2 0 012 2zm7-2a2 2 0 11-2 2 2 2 0 012-2zM8 19v-2.75a.25.25 0 00-.25-.25H6.5a1 1 0 010-2h11a1 1 0 010 2h-1.25a.25.25 0 00-.25.25V19a4 4 0 01-8 0z\"/><path d=\"M11 16.25v2.25a1 1 0 002 0v-2.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});