define("ember-svg-jar/inlined/wild-bird-owl-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wild-bird-owl-body</title><path d=\"M10.5 13.611a.749.749 0 011.482-.222 9.057 9.057 0 007.856 7.525A52.32 52.32 0 0018.1 13.19c-1.026-3.31-2.592-5.282-3.634-5.908a9.08 9.08 0 00.159-1.682 4.16 4.16 0 00-.643-2.261 8.773 8.773 0 00-3.754 2.99.749.749 0 01-1.206 0 8.822 8.822 0 00-3.755-2.993A4.168 4.168 0 004.625 5.6a8.273 8.273 0 00.646 3.357 11.9 11.9 0 002.375 9.132 13.529 13.529 0 001.281 1.47L7.948 22h-.823a1 1 0 000 2h3a1 1 0 000-2H10.1l.43-1.072a14.159 14.159 0 009.092 2.828.5.5 0 00.5-.547c0-.012-.028-.287-.085-.76a10.576 10.576 0 01-9.537-8.838zm1.88-8.117a1 1 0 11-1 1 1 1 0 01.995-1zM8.875 8.5a.75.75 0 011.5 0V9a.75.75 0 01-1.5 0zm-1-2.006a1 1 0 11-1-1 1 1 0 011 1z\"/><path d=\"M9.022 4.577a.749.749 0 001.206 0 8.859 8.859 0 014.4-3.082.747.747 0 100-1.494 5.749 5.749 0 00-3.4 1.428 6.43 6.43 0 00-3.2 0A5.749 5.749 0 004.625 0a.747.747 0 100 1.494 9.046 9.046 0 014.397 3.083z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});