define("ember-svg-jar/inlined/road-sign-parking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-parking</title><path d=\"M12.458 9.251h-1a.249.249 0 00-.25.25v2a.249.249 0 00.25.25h1a1.25 1.25 0 000-2.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm.458 13.251h-1a.249.249 0 00-.25.25v3a.75.75 0 01-1.5 0v-8a.75.75 0 01.75-.75h2a2.75 2.75 0 110 5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});