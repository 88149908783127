define("ember-svg-jar/inlined/head-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>head-idea</title><path d=\"M13.5 14.5h-3a.75.75 0 000 1.5h3a.75.75 0 000-1.5zM20 5.75a1 1 0 000 2h1a1 1 0 000-2zM19 3.75a1 1 0 00.707-.293l1-1a1 1 0 10-1.414-1.415l-1 1A1 1 0 0019 3.75zM3 7.75h1a1 1 0 000-2H3a1 1 0 000 2zM4.293 3.457a1 1 0 001.414-1.415l-1-1a1 1 0 00-1.414 1.415zM19.293 12.457a1 1 0 101.414-1.415l-1-1a1 1 0 00-1.414 1.415zM4.293 10.042l-1 1a1 1 0 101.414 1.415l1-1a1 1 0 00-1.414-1.415zM17.5 6.75a5.5 5.5 0 10-8.13 4.81.252.252 0 01.13.22v.47a1 1 0 001 1h3a1 1 0 001-1v-.47a.252.252 0 01.13-.22 5.5 5.5 0 002.87-4.81zM8 17.2a.5.5 0 00-.684-.419l-4.4 1.752A4.521 4.521 0 000 22.75a.5.5 0 00.5.5H8a.5.5 0 00.5-.546zM21.087 18.539l-4.4-1.754A.5.5 0 0016 17.2l-.5 5.5a.5.5 0 00.5.546h7.5a.5.5 0 00.5-.5 4.531 4.531 0 00-2.913-4.207zM12.464 18.814a.52.52 0 00-.928 0l-1.5 3.75a.5.5 0 00.464.686h3a.5.5 0 00.464-.686z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});