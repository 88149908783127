define("ember-svg-jar/inlined/messages-people-woman-bubble-square-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-people-woman-bubble-square-1</title><path d=\"M22 0H9a2 2 0 00-2 2v2a1 1 0 102 0V2.249A.25.25 0 019.25 2h12.5a.25.25 0 01.25.25v6.5a.25.25 0 01-.25.25H19.5a1 1 0 00-.665.252L16.416 11.4a.25.25 0 01-.416-.187V10a1 1 0 00-1-1h-1a1 1 0 100 2v3a1 1 0 001.665.748L19.88 11H22a2 2 0 002-2V2a2 2 0 00-2-2z\"/><circle cx=\"12.502\" cy=\"5.748\" r=\"1\"/><circle cx=\"15.752\" cy=\"5.748\" r=\"1\"/><circle cx=\"19.003\" cy=\"5.748\" r=\"1\"/><path d=\"M1.564 16.943a1 1 0 001.383-.3 5.669 5.669 0 00.594-1.283 4.733 4.733 0 007.33 0 5.669 5.669 0 00.594 1.283 1 1 0 001.68-1.085 4.56 4.56 0 01-.642-2.509V11.8a5.3 5.3 0 10-10.594 0v1.249a4.563 4.563 0 01-.642 2.51 1 1 0 00.297 1.384zM7.206 15.1c-2.629 0-2.793-2.7-2.814-2.805a5.711 5.711 0 002.823-1.758 5.709 5.709 0 002.806 1.763c-.021.1-.187 2.8-2.815 2.8zM7.207 18a7.544 7.544 0 00-7.186 5.358A.5.5 0 00.5 24h13.413a.5.5 0 00.479-.642A7.542 7.542 0 007.207 18z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});