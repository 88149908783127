define("ember-svg-jar/inlined/arrow-thick-circle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-circle-down</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.639 12.854l-6.287 6.286a.5.5 0 01-.707 0l-6.286-6.286A.5.5 0 015.713 12H9.5a.25.25 0 00.25-.25V5.5a.5.5 0 01.5-.5h3.492a.5.5 0 01.5.5v6.25a.25.25 0 00.25.25h3.789a.5.5 0 01.358.854z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});