define("ember-svg-jar/inlined/bathroom-shower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bathroom-shower</title><path d=\"M21.639 6.5a6.5 6.5 0 00-12.952-.724.5.5 0 01-.351.424 4.5 4.5 0 00-3.2 4.3v.5a1 1 0 001 1h7a1 1 0 001-1v-.5a4.5 4.5 0 00-3.07-4.255.5.5 0 01-.333-.564 4.488 4.488 0 018.9.819V23a1 1 0 002 0zM9.228 20.216A5.89 5.89 0 008.139 22.5a1.5 1.5 0 003 0 5.89 5.89 0 00-1.089-2.284.518.518 0 00-.822 0z\"/><path d=\"M9.228 14.216A5.89 5.89 0 008.139 16.5a1.5 1.5 0 003 0 5.89 5.89 0 00-1.089-2.284.518.518 0 00-.822 0zM4.417 19.863a.484.484 0 00-.449.119A5.872 5.872 0 002.4 21.967a1.5 1.5 0 001.129 1.8 1.532 1.532 0 00.336.038 1.5 1.5 0 001.46-1.167 5.89 5.89 0 00-.554-2.469.5.5 0 00-.354-.306zM5.75 14.013a.492.492 0 00-.449.119 5.866 5.866 0 00-1.569 1.986 1.5 1.5 0 001.129 1.794 1.5 1.5 0 001.8-1.129 5.887 5.887 0 00-.561-2.469.5.5 0 00-.35-.301zM15.31 19.982a.494.494 0 00-.449-.119.5.5 0 00-.353.3 5.874 5.874 0 00-.553 2.469 1.5 1.5 0 001.459 1.168 1.532 1.532 0 00.336-.038 1.5 1.5 0 001.129-1.8 5.872 5.872 0 00-1.569-1.98zM14.416 17.912a1.5 1.5 0 001.13-1.795 5.872 5.872 0 00-1.569-1.985.492.492 0 00-.449-.119.5.5 0 00-.353.3 5.87 5.87 0 00-.553 2.469 1.5 1.5 0 001.794 1.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});