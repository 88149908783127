define("ember-svg-jar/inlined/disability-walk-blind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-walk-blind</title><path d=\"M14.484 14.55a.951.951 0 00-.078 1.351l7.117 7.536a1 1 0 101.454-1.374l-7.118-7.536a.945.945 0 00-1.358.007zM2.25 15.25a1.5 1.5 0 001.5-1.5v-1.3a1.5 1.5 0 01.728-1.286l.893-.536a.25.25 0 01.379.215v3.245a.993.993 0 01-.132.5l-3.67 6.424a1.5 1.5 0 102.6 1.488l2.481-4.341a.25.25 0 01.434 0L9.944 22.5a1.5 1.5 0 102.6-1.488l-3.67-6.423a.994.994 0 01-.132-.5v-2.38a.25.25 0 01.418-.185l2.573 2.339a1.5 1.5 0 002.018-2.22L8.81 7.141a2.482 2.482 0 00-2.969-.294L2.935 8.59A4.5 4.5 0 00.75 12.448v1.3a1.5 1.5 0 001.5 1.502z\"/><circle cx=\"7.25\" cy=\"2.75\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});