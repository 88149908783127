define("ember-svg-jar/inlined/chair", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chair</title><path d=\"M18.521 10.775a.255.255 0 01-.021-.1V1a1 1 0 00-2 0v.5a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v9.673a.255.255 0 01-.021.1C5.24 11.311 3.5 15.228 3.5 15.5v1a.5.5 0 00.5.5h16a.5.5 0 00.5-.5v-1c0-.272-1.74-4.189-1.979-4.725zM16.5 10a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zM5.5 19a.5.5 0 00-.5-.5H4a.5.5 0 00-.5.5v4a1 1 0 002 0zM20 18.5h-1a.5.5 0 00-.5.5v4a1 1 0 002 0v-4a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});