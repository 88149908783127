define("ember-svg-jar/inlined/shopping-basket-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-add</title><path d=\"M17.382 10.777a.248.248 0 00.22-.186l.266-1.009a.5.5 0 00-.483-.627H2.379a.5.5 0 00-.489.6l1.276 5.994A1.956 1.956 0 005.132 17h5.473a.249.249 0 00.246-.208 7.265 7.265 0 016.531-6.015zm-6.882.838V14a.5.5 0 01-1 0v-2.385a.5.5 0 011 0zm-3.872 3.03a.488.488 0 01-.085.007.5.5 0 01-.492-.416l-.408-2.386a.5.5 0 01.986-.168l.408 2.386a.5.5 0 01-.409.577zM19.763 6.167a1.441 1.441 0 00-1.439-1.439h-1.142a.25.25 0 01-.222-.135L14.848.538a1 1 0 00-1.774.924l1.511 2.9a.253.253 0 01-.008.246.251.251 0 01-.214.12H5.4a.25.25 0 01-.221-.366l1.51-2.9A1 1 0 004.916.538L2.8 4.593a.249.249 0 01-.221.135h-1.14a1.439 1.439 0 000 2.878h16.885a1.441 1.441 0 001.439-1.439z\"/><path d=\"M18.25 12.5A5.75 5.75 0 1024 18.25a5.756 5.756 0 00-5.75-5.75zm2.5 6.5h-1.5a.25.25 0 00-.25.25v1.5a.75.75 0 01-1.5 0v-1.5a.25.25 0 00-.25-.25h-1.5a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25v-1.5a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25h1.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});