define("ember-svg-jar/inlined/folder-fiile-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-fiile-edit</title><path d=\"M13 15H6a1 1 0 01-.8-.4l-.9-1.2a1 1 0 00-.8-.4H1a1 1 0 00-1 1v9a1 1 0 001 1h12a1 1 0 001-1v-7a1 1 0 00-1-1zM6.25 13.5h1.5a.25.25 0 00.25-.25V2.5a.5.5 0 01.5-.5h8.193a.249.249 0 00.177-.073L18.316.48a.146.146 0 00-.023-.187A1 1 0 0017.586 0H8a2 2 0 00-2 2v11.166c0 .146.065.334.25.334zM23.707 5.707a.146.146 0 00-.219.008L22.073 7.13a.249.249 0 00-.073.177V19.5a.5.5 0 01-.5.5h-5.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25H22a2 2 0 002-2V6.414a1 1 0 00-.293-.707zM17.322 9.76l6.041-6.041a2.18 2.18 0 00-.007-3.082 2.225 2.225 0 00-3.075 0L14.24 6.678a.492.492 0 00-.136.256L13.51 9.9a.5.5 0 00.49.6.473.473 0 00.1-.01l2.966-.59a.489.489 0 00.256-.14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});