define("ember-svg-jar/inlined/color-tube-painting-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>color-tube-painting-alternate</title><path d=\"M23.232 3.421L19.947 1.71a1.425 1.425 0 00-1.923.608l-.936 1.8a.259.259 0 00-.017.191.256.256 0 00.123.147l5.371 2.8a.25.25 0 00.337-.106l.936-1.8a1.424 1.424 0 00-.606-1.929zM12.885 12.731a.247.247 0 00-.19-.017.249.249 0 00-.147.123l-.936 1.8a1.426 1.426 0 00.606 1.923l3.285 1.71a1.427 1.427 0 001.923-.607l.936-1.8a.251.251 0 00-.106-.338zM16.733 5.34a.25.25 0 00-.338.107l-3.154 6.06a.249.249 0 00.106.337l5.371 2.8a.25.25 0 00.337-.107l3.155-6.06a.253.253 0 00.016-.191.247.247 0 00-.123-.146zM14.981 19.33L11.7 17.62a.5.5 0 00-.722.346l-.5 2.5a1.634 1.634 0 00.819 1.877 1.617 1.617 0 001.987-.385l1.816-1.831a.5.5 0 00-.119-.797zM11.177 11.8a2.94 2.94 0 01-.964-2.023 1.4 1.4 0 01.487-1.041 2.017 2.017 0 00.618-1.7 3.041 3.041 0 00-1.583-2.25c-1.12-.673-3.028-1.8-4.835-2.863A3.253 3.253 0 000 4.722a3 3 0 001.41 2.719 43.036 43.036 0 014.867 3.394 1.283 1.283 0 01.153 1.647c-.4.58-1.171.66-2.051.211a2.339 2.339 0 00-3.15.9c-.542.958-.38 2.367 1.2 3.355a22.916 22.916 0 006.417 2.816.25.25 0 00.288-.238 22.777 22.777 0 012.094-7.455.25.25 0 00-.051-.271z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});