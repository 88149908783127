define("ember-svg-jar/inlined/cloud-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-question</title><path d=\"M3.566 15.457a1 1 0 00-.5-.844 2.248 2.248 0 01-.431-.329A2.371 2.371 0 012 12.5a2.588 2.588 0 012.585-2.588 2.645 2.645 0 01.536.056A1 1 0 006.324 9c.081-5.754 8.3-7.363 10.818-2.045a.97.97 0 00.941.571 3.7 3.7 0 013 1.3 3.874 3.874 0 01.908 2.811 3.428 3.428 0 01-1.1 2.375 1.316 1.316 0 00-.42 1.089.912.912 0 001.476.628A5.408 5.408 0 0024 11.418a5.763 5.763 0 00-5.22-5.866.25.25 0 01-.19-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2 4.642 4.642 0 00-3.643 2.24A4.471 4.471 0 000 12.619a4.287 4.287 0 001.235 3.09 4.177 4.177 0 00.852.645 1 1 0 001.478-.9z\"/><path d=\"M18.5 15.8a6.5 6.5 0 10-6.5 6.5 6.508 6.508 0 006.5-6.5zm-7.25 1.25v-.55a1.257 1.257 0 01.986-1.221 1.125 1.125 0 10-1.361-1.1.75.75 0 11-1.5 0 2.625 2.625 0 113.538 2.461.25.25 0 00-.163.234v.18a.75.75 0 01-1.5 0zM12 18.8a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});