define("ember-svg-jar/inlined/audio-file-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-file-share</title><path d=\"M23.415 3L21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.418a.25.25 0 00.475.107 3.024 3.024 0 011.378-1.388.245.245 0 00.147-.22V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.147l2.121 2.122a.5.5 0 01.147.352V18a.5.5 0 01-.5.5h-7.46a.247.247 0 00-.215.374 4.233 4.233 0 01.581 1.425.245.245 0 00.239.2H22a2 2 0 002-2V4.414A2 2 0 0023.415 3z\"/><path d=\"M14.779 13.442a2.251 2.251 0 001.454-1.942.833.833 0 00.017-.084V6.234c0-.135.166-.175.274-.108l1.077.679a.75.75 0 10.8-1.27l-1.815-1.14A1.25 1.25 0 0014.75 5.5v3.635c0 .091-.089.06-.134.046A2.161 2.161 0 0014 9.087a2.222 2.222 0 00-1.549.632c-.072.07-.133.252.107.407a4.211 4.211 0 011.906 3.1.243.243 0 00.315.216zM8.442 16a.245.245 0 01.266.012 2.74 2.74 0 004.167-1.425A2.758 2.758 0 0010.25 11a2.808 2.808 0 00-2.713 3.059.245.245 0 01-.115.221L4.558 16a.245.245 0 01-.266-.012 2.71 2.71 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.774A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.244.244 0 01.259-.053l2.464 1a.239.239 0 01.14.214 2.753 2.753 0 105.361-1.025 2.683 2.683 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});