define("ember-svg-jar/inlined/hqx-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hqx-1</title><path d=\"M11.5 12.126a1.377 1.377 0 00-1.375 1.374V17a1.375 1.375 0 002.75 0v-3.5a1.377 1.377 0 00-1.375-1.374z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM8.125 19.5a.625.625 0 01-1.25 0v-3.124a.25.25 0 00-.25-.25h-2.25a.25.25 0 00-.25.25V19.5a.625.625 0 01-1.25 0v-8a.625.625 0 011.25 0v3.125a.25.25 0 00.25.25h2.25a.25.25 0 00.25-.25V11.5a.625.625 0 111.25 0zm6-2.5a2.61 2.61 0 01-1.25 2.221c-.052.032-.081.138.037.207l.9.534a.625.625 0 11-.635 1.076s-3.063-1.812-3.082-1.826A2.621 2.621 0 018.875 17v-3.5a2.625 2.625 0 115.25 0zm5.934 2.221a.625.625 0 11-1.118.558l-1.217-2.435a.25.25 0 00-.448 0l-1.217 2.436a.625.625 0 01-.559.346.625.625 0 01-.559-.9l1.8-3.609a.252.252 0 000-.224l-1.8-3.609a.625.625 0 111.118-.558l1.217 2.435a.251.251 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});