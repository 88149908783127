define("ember-svg-jar/inlined/breakfast-bread-toast-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>breakfast-bread-toast-1</title><path d=\"M21 21H.5a.5.5 0 00-.5.5A2.5 2.5 0 002.5 24H19a2.5 2.5 0 002.5-2.5.5.5 0 00-.5-.5zM24 17a1 1 0 00-1-1h-1a.5.5 0 01-.5-.5V12A2.5 2.5 0 0019 9.5H2.5A2.5 2.5 0 000 12v7a.5.5 0 00.5.5H21a.5.5 0 00.5-.5v-.5a.5.5 0 01.5-.5h1a1 1 0 001-1zm-5 .25a.75.75 0 01-1.5 0v-5a.75.75 0 011.5 0zM15 8.25a.751.751 0 01-.75-.75V4.687a1.75 1.75 0 011.312-1.7.25.25 0 00-.062-.487h-11a.25.25 0 00-.063.492 1.751 1.751 0 011.313 1.7V7.5a.751.751 0 01-.75.75H4a.751.751 0 01-.75-.75V5.2A2.75 2.75 0 014.5 0h11a2.75 2.75 0 011.25 5.2v2.3a.751.751 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});