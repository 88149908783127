define("ember-svg-jar/inlined/messages-bubble-square-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-settings</title><path d=\"M14.248 11.229a.251.251 0 00.294-.17 3.289 3.289 0 016.414 0 .25.25 0 00.294.169l.054-.013a.249.249 0 00.194-.243V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.189 15h1.665a.25.25 0 00.243-.308c-.389-1.625 1.113-4.144 4.151-3.463z\"/><circle cx=\"17.748\" cy=\"17.031\" r=\"1.5\"/><path d=\"M23.516 15.654a1.783 1.783 0 00-1.7-2.953l-1.411.327a.453.453 0 01-.533-.311l-.424-1.392a1.775 1.775 0 00-3.4 0l-.424 1.392a.454.454 0 01-.533.311l-1.41-.328a1.783 1.783 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.451.451 0 01.533.311l.424 1.392a1.775 1.775 0 003.4 0l.424-1.392a.45.45 0 01.533-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.987-1.065a.464.464 0 010-.626zm-2.768 1.377a3 3 0 11-3-3 3 3 0 013 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});