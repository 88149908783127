define("ember-svg-jar/inlined/primitive-symbols-woman", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>primitive-symbols-woman</title><path d=\"M6.992 8.1a3.952 3.952 0 001.486-.784.272.272 0 01.256.006 4.2 4.2 0 006.548-.022.213.213 0 01.209-.014 3.977 3.977 0 001.52.811.992.992 0 001.2-.719 1.006 1.006 0 00-.71-1.219A1.764 1.764 0 0116.247 4.6a4.249 4.249 0 00-8.5.028A1.645 1.645 0 016.5 6.157a1 1 0 00-.719 1.21 1.08 1.08 0 001.211.733z\"/><path d=\"M19.323 22.26L16.1 12.576a4.317 4.317 0 00-8.19 0L4.677 22.26a1.038 1.038 0 00.984 1.365h12.678a1.038 1.038 0 00.984-1.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});