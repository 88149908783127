define("ember-svg-jar/inlined/watch-time-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>watch-time-1-alternate</title><path d=\"M11.469 7.533a5 5 0 105 5 5.006 5.006 0 00-5-5zM14 15.063a.758.758 0 01-1.061 0l-2-2a.754.754 0 01-.219-.53v-2a.75.75 0 011.5 0v1.689L14 14a.75.75 0 010 1.063z\"/><path d=\"M23.707 5.708a1 1 0 000-1.414l-4-4a1 1 0 00-1.414 0S13.74 4.847 13.73 4.859a8 8 0 00-9.93 9.935l-3.5 3.5A1 1 0 000 19v4a1 1 0 001 1h4a1 1 0 00.707-.293l3.5-3.5a8 8 0 009.936-9.935zm-4.53-3.116l2.232 2.232a.25.25 0 010 .354l-.711.711a.25.25 0 01-.354 0l-2.232-2.232a.25.25 0 010-.354l.711-.711a.25.25 0 01.354 0zm-7.708 15.941a6 6 0 116-6 6.007 6.007 0 01-6 6zM4.482 22H2.25a.25.25 0 01-.25-.25v-2.231a.251.251 0 01.073-.177l2.385-2.385a.243.243 0 01.376.04A8.059 8.059 0 007 19.167a.242.242 0 01.04.376l-2.381 2.385a.249.249 0 01-.177.072zM15.894 5.522l.8-.8a.249.249 0 01.353 0l2.233 2.231a.25.25 0 010 .354l-.8.805a.244.244 0 01-.376-.04 8.059 8.059 0 00-2.17-2.17.243.243 0 01-.04-.38z\"/><circle cx=\"3.969\" cy=\"20.033\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});