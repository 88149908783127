define("ember-svg-jar/inlined/tiger-bengal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tiger-bengal</title><path d=\"M16.446 15.892a1.015 1.015 0 00-1.348.383 1.238 1.238 0 01-1.121.476c-.52 0-1-.121-1-1v-.134a3.012 3.012 0 001.894-1.417.983.983 0 00-.425-1.307 1.014 1.014 0 00-1.348.383 1.238 1.238 0 01-1.121.476 1.246 1.246 0 01-1.119-.472 1 1 0 00-1.776.92 3.018 3.018 0 001.895 1.418v.134c0 .879-.481 1-1 1a1.246 1.246 0 01-1.119-.472 1 1 0 00-1.776.92 3.188 3.188 0 002.9 1.552 3.064 3.064 0 002-.669 3.06 3.06 0 002 .669 3.185 3.185 0 002.889-1.552.983.983 0 00-.425-1.308z\"/><circle cx=\"7.368\" cy=\"10.491\" r=\"1.25\"/><circle cx=\"16.368\" cy=\"10.491\" r=\"1.25\"/><path d=\"M22.448 7.264A3.678 3.678 0 0017.6 1.73 10.5 10.5 0 0011.989.294 10.5 10.5 0 006.375 1.73 3.68 3.68 0 00.284 4.506a3.7 3.7 0 001.238 2.753c-.775 3.375-3.29 8.2.68 12.924a9.856 9.856 0 007.561 3.523h4.453a9.856 9.856 0 007.559-3.526c3.996-4.752 1.497-9.355.673-12.916zm-8.232 14.442H9.763a7.847 7.847 0 01-5.774-2.538 1.249 1.249 0 00.391.073 1 1 0 00.226-1.974 5.679 5.679 0 01-2.323-1.049A7.063 7.063 0 012 13.894a6.634 6.634 0 002.987 1.357 1 1 0 00.226-1.973 6.271 6.271 0 01-2.8-1.649C3.029 9.271 3.466 8 3.612 6.969a1 1 0 00-.485-1 1.7 1.7 0 01-.843-1.461A1.678 1.678 0 013.96 2.83a1.655 1.655 0 011.412.77 1 1 0 001.483.24 7.435 7.435 0 014.133-1.48v2.165a4.711 4.711 0 00-2.641.7 1.038 1.038 0 00-.164 1.436.963.963 0 001.371.154 4.131 4.131 0 011.434-.283V8a1 1 0 002 0V6.531a7.956 7.956 0 011.364.231 1 1 0 001.276-1.54 4.726 4.726 0 00-2.64-.7V2.359a7.431 7.431 0 014.135 1.484 1 1 0 001.483-.243 1.656 1.656 0 011.412-.774 1.676 1.676 0 01.831 3.132 1 1 0 00-.493 1.007c.142 1 .562 2.215 1.165 4.5a5.32 5.32 0 01-2.76 1.813 1 1 0 00.226 1.973 6.116 6.116 0 002.989-1.433 7.028 7.028 0 01-.216 2.162 5.509 5.509 0 01-2.608 1.286 1 1 0 00.226 1.974 2.569 2.569 0 00.686-.155 7.848 7.848 0 01-5.848 2.621z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});