define("ember-svg-jar/inlined/synchronize-arrows-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>synchronize-arrows-1</title><path d=\"M10.319 4.936a7.239 7.239 0 017.1 2.252 1.25 1.25 0 101.872-1.657A9.737 9.737 0 009.743 2.5a10.269 10.269 0 00-7.365 7.11.249.249 0 01-.271.178l-1.033-.13a.491.491 0 00-.474.219.5.5 0 00-.019.526l2.476 4.342a.5.5 0 00.373.248.43.43 0 00.062 0 .5.5 0 00.359-.152l3.477-3.593a.5.5 0 00-.3-.844l-1.878-.232a.25.25 0 01-.2-.333 7.7 7.7 0 015.369-4.903zM23.406 14.1a.5.5 0 00.015-.526l-2.5-4.329A.5.5 0 0020.546 9a.489.489 0 00-.421.151l-3.456 3.614a.5.5 0 00.3.842l1.848.221a.249.249 0 01.183.117.253.253 0 01.023.216 7.688 7.688 0 01-5.369 4.9 7.243 7.243 0 01-7.1-2.253 1.25 1.25 0 10-1.872 1.656 9.74 9.74 0 009.549 3.03 10.261 10.261 0 007.369-7.12.251.251 0 01.27-.179l1.058.127a.422.422 0 00.06 0 .5.5 0 00.418-.222z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});