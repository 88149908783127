define("ember-svg-jar/inlined/multiple-actions-flash.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-flash</title><path d=\"M14.489 8a5.95 5.95 0 00-2.465.534.5.5 0 00-.182.771A7.42 7.42 0 0113.5 13.5v.025A.5.5 0 0014 14h2.288a.5.5 0 00.387-.184l2.455-3.009a.5.5 0 000-.636A6.017 6.017 0 0014.489 8z\"/><ellipse cx=\"14.014\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.275-.119A.368.368 0 0012 13.6z\"/><ellipse cx=\"5.514\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M23.179 16h-1.036a.5.5 0 01-.5-.5v-4a.5.5 0 00-.887-.316l-5.275 6.462A.822.822 0 0016.107 19h1.036a.5.5 0 01.5.5v4a.5.5 0 00.332.471.516.516 0 00.168.029.5.5 0 00.387-.184l5.275-6.462A.822.822 0 0023.179 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});