define("ember-svg-jar/inlined/water-bottle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-bottle-1</title><path d=\"M9.5 3h5a.5.5 0 00.5-.5v-1A1.5 1.5 0 0013.5 0h-3A1.5 1.5 0 009 1.5v1a.5.5 0 00.5.5zM15.75 11.5h-7.5a2.75 2.75 0 000 5.5h7.5a2.75 2.75 0 000-5.5zM16.5 18.5h-9a2 2 0 00-2 2v.75A2.75 2.75 0 008.25 24h7.5a2.75 2.75 0 002.75-2.75v-.75a2 2 0 00-2-2zM15.75 4.5h-7.5A2.75 2.75 0 005.5 7.25V8a2 2 0 002 2h9a2 2 0 002-2v-.75a2.75 2.75 0 00-2.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});