define("ember-svg-jar/inlined/single-man-idea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-idea</title><path d=\"M21.751 10.506a.5.5 0 00.341-.134 6 6 0 10-8.18 0 .5.5 0 00.342.135zM15.5 11.506a.5.5 0 00-.5.5 2 2 0 001.778 1.988.249.249 0 01.222.249v.763a1 1 0 002 0v-.763a.25.25 0 01.222-.249A2 2 0 0021 12.006a.5.5 0 00-.5-.5zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144z\"/><path d=\"M21.751 10.506a.5.5 0 00.341-.134 6 6 0 10-8.18 0 .5.5 0 00.342.135zM15.5 11.506a.5.5 0 00-.5.5 2 2 0 001.778 1.988.249.249 0 01.222.249v.763a1 1 0 002 0v-.763a.25.25 0 01.222-.249A2 2 0 0021 12.006a.5.5 0 00-.5-.5zM5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});