define("ember-svg-jar/inlined/office-file-text-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-file-text-graph</title><path d=\"M11 12.5v-4a.5.5 0 00-.5-.5A4.505 4.505 0 006 12.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5z\"/><path d=\"M12.973 10.5a.494.494 0 00-.473.5v3.25a.25.25 0 01-.25.25H9a.494.494 0 00-.5.473 4.5 4.5 0 104.473-4.473z\"/><path d=\"M21.707 5.705L16.293.291A1 1 0 0015.586 0H4a2 2 0 00-2 2v20a2 2 0 002 2h16a2 2 0 002-2V6.412a1 1 0 00-.293-.707zM20 21.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h10.465a.5.5 0 01.353.147l4.536 4.533a.5.5 0 01.146.353z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});