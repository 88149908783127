define("ember-svg-jar/inlined/drawer-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-heart</title><path d=\"M4.507 12.5a1 1 0 001-1L5.5 2.25a.251.251 0 01.073-.177A.255.255 0 015.75 2h12.493a.25.25 0 01.25.25l.007 9.25a1 1 0 001 1 1 1 0 001-1L20.493 2a2 2 0 00-2-2H5.5a2 2 0 00-2 2l.007 9.5a1 1 0 001 1z\"/><path d=\"M14.386 4.533a2.5 2.5 0 00-2.183.705l-.2.2-.2-.2A2.517 2.517 0 008.229 8.79l3.41 3.556a.5.5 0 00.722 0l3.4-3.549a2.515 2.515 0 00.471-2.9 2.489 2.489 0 00-1.846-1.364z\"/><path d=\"M23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});