define("ember-svg-jar/inlined/messages-bubble-typing-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-typing_1</title><path d=\"M12 1.375C5.682 1.375.541 5.611.541 10.817A8.567 8.567 0 003.7 17.333L1.416 21.9a.5.5 0 00.447.725.51.51 0 00.211-.047L8.2 19.727a13.691 13.691 0 003.8.531c6.318 0 11.459-4.236 11.459-9.441S18.318 1.375 12 1.375zm-5.041 11a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm5.041-3a1.5 1.5 0 11-1.5 1.5 1.5 1.5 0 011.5-1.5zm5 3a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});