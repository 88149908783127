define("ember-svg-jar/inlined/app-window-text-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-text-1</title><path d=\"M0 19.251a3 3 0 003 3h18a3 3 0 003-3v-14.5a3 3 0 00-3-3H3a3 3 0 00-3 3zm14.5-15a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.983.983 0 01.846-.5 1 1 0 01.908.591.987.987 0 01.092.409zM2 7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7v12.25a1 1 0 01-1 1H3a1 1 0 01-1-1z\"/><path d=\"M10.25 10.751h8.5a.75.75 0 000-1.5h-8.5a.75.75 0 000 1.5zM5.75 10.751h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5zM10.25 14.251h8.5a.75.75 0 000-1.5h-8.5a.75.75 0 000 1.5zM5.75 14.251h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 100 1.5zM10.25 17.751h8.5a.75.75 0 000-1.5h-8.5a.75.75 0 000 1.5zM5.75 17.751h1.5a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});