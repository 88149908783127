define("ember-svg-jar/inlined/single-woman-actions-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-music</title><path d=\"M8.976 9.33a.5.5 0 00-.756.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.389v-.006l.921-4.847a.247.247 0 01.243-.2H10.8a.246.246 0 00.159-.059 4.066 4.066 0 01.82-.543.247.247 0 00.13-.287c-1.885-6.894-1.816-7.294-2.933-8.339zM6.041 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM13.484 24a2.478 2.478 0 002.475-2.475V14.14a.247.247 0 01.2-.243l5.441-1.021a.248.248 0 01.293.243v3.716a.248.248 0 01-.27.247 2.389 2.389 0 00-.225-.01 2.474 2.474 0 102.474 2.474v-6.725a1.98 1.98 0 00-2.344-1.946l-5.938 1.114a1.981 1.981 0 00-1.615 1.945v4.881a.247.247 0 01-.27.246 2.78 2.78 0 00-.225-.01 2.475 2.475 0 000 4.949z\"/><path d=\"M8.976 9.33a.5.5 0 00-.756.1l-1.737 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.015 10.373 2.1 10.78.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.484-.389v-.006l.921-4.847a.247.247 0 01.243-.2H10.8a.246.246 0 00.159-.059 4.066 4.066 0 01.82-.543.247.247 0 00.13-.287c-1.885-6.894-1.816-7.294-2.933-8.339zM6.041 8.412a4.206 4.206 0 10-4.206-4.206 4.211 4.211 0 004.206 4.206zm0-1.979A2.224 2.224 0 013.9 4.81a.248.248 0 01.158-.3 4.623 4.623 0 001.8-1.128.247.247 0 01.353 0 4.615 4.615 0 001.8 1.128.249.249 0 01.158.3 2.224 2.224 0 01-2.128 1.623zM13.484 24a2.478 2.478 0 002.475-2.475V14.14a.247.247 0 01.2-.243l5.441-1.021a.248.248 0 01.293.243v3.716a.248.248 0 01-.27.247 2.389 2.389 0 00-.225-.01 2.474 2.474 0 102.474 2.474v-6.725a1.98 1.98 0 00-2.344-1.946l-5.938 1.114a1.981 1.981 0 00-1.615 1.945v4.881a.247.247 0 01-.27.246 2.78 2.78 0 00-.225-.01 2.475 2.475 0 000 4.949z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});