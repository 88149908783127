define("ember-svg-jar/inlined/folder-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-alternate</title><path d=\"M19.714 20H4.286A2.287 2.287 0 012 17.714V6.286A2.285 2.285 0 014.286 4h3.43a2 2 0 011.441.612l1.192 1.235a.5.5 0 00.359.153H20a2 2 0 012 2v9.714A2.285 2.285 0 0119.714 20zM4.286 6A.286.286 0 004 6.286v11.428a.286.286 0 00.286.286h15.428a.286.286 0 00.286-.286V8.5a.5.5 0 00-.5-.5h-9a2 2 0 01-1.441-.612L7.865 6.153A.5.5 0 007.505 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});