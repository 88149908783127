define("ember-svg-jar/inlined/single-man-actions-add.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-add</title><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.247.247 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm-3.464-7.423h2.227a.247.247 0 00.248-.247V14.1a.99.99 0 111.979 0v2.23a.247.247 0 00.247.247h2.227a.99.99 0 110 1.98H18.68a.247.247 0 00-.247.247v2.227a.99.99 0 11-1.979 0V18.8a.247.247 0 00-.248-.247h-2.227a.99.99 0 010-1.98z\"/><path d=\"M.124 17.567a.494.494 0 00.5.5H2.41a.246.246 0 01.246.227l.444 5.252a.494.494 0 00.488.454h3.958a.494.494 0 00.493-.454l.439-5.257a.246.246 0 01.246-.227h.562a.247.247 0 00.18-.078.25.25 0 00.068-.184 3.602 3.602 0 01-.008-.233 7.866 7.866 0 011.33-4.384.25.25 0 00.037-.183A5.441 5.441 0 00.124 14.1z\"/><path d=\"M5.567 0a4.206 4.206 0 104.206 4.206A4.211 4.211 0 005.567 0zm0 6.433A2.229 2.229 0 013.34 4.206a2.136 2.136 0 01.157-.8.247.247 0 01.359-.119 5.317 5.317 0 002.8.793 5.353 5.353 0 00.9-.077.2.2 0 01.237.2 2.229 2.229 0 01-2.226 2.23zM17.443 24a6.433 6.433 0 10-6.433-6.433A6.441 6.441 0 0017.443 24zm-3.464-7.423h2.227a.247.247 0 00.248-.247V14.1a.99.99 0 111.979 0v2.23a.247.247 0 00.247.247h2.227a.99.99 0 110 1.98H18.68a.247.247 0 00-.247.247v2.227a.99.99 0 11-1.979 0V18.8a.247.247 0 00-.248-.247h-2.227a.99.99 0 010-1.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});