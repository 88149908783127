define("ember-svg-jar/inlined/kitchenware-grater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-grater</title><path d=\"M20.5 21.443L19.77 4.83A4.83 4.83 0 0014.95 0h-5.9a4.815 4.815 0 00-4.818 4.771L3.5 21.439A2.437 2.437 0 005.932 24h12.136a2.436 2.436 0 002.432-2.557zM7.5 21a1 1 0 111-1 1 1 0 01-1 1zm-1-9a1 1 0 111 1 1 1 0 01-1-1zm3 5a1 1 0 111-1 1 1 0 01-1 1zm0-8a1 1 0 111-1 1 1 0 01-1 1zM12 21a1 1 0 111-1 1 1 0 01-1 1zm0-8a1 1 0 111-1 1 1 0 01-1 1zm2.5 4a1 1 0 111-1 1 1 0 01-1 1zm-1-9a1 1 0 111 1 1 1 0 01-1-1zm3 13a1 1 0 111-1 1 1 0 01-1 1zm0-8a1 1 0 111-1 1 1 0 01-1 1zM6.882 4.5a.5.5 0 01-.467-.678A2.824 2.824 0 019.05 2h5.9a2.81 2.81 0 012.625 1.821.5.5 0 01-.467.679z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});