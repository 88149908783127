define("ember-svg-jar/inlined/gauge-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gauge-dashboard</title><path d=\"M12 2A12.013 12.013 0 000 14v2.5A2.5 2.5 0 002.5 19h19a2.5 2.5 0 002.5-2.5V14A12.013 12.013 0 0012 2zm9.5 13.25h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm-2.754-3.816a.751.751 0 01-.376-1.4l1.732-1a.75.75 0 11.75 1.3l-1.732 1a.739.739 0 01-.374.1zM14.75 14a2.746 2.746 0 11-1.937-2.628l3.741-5.758a.75.75 0 111.258.818l-3.743 5.76A2.734 2.734 0 0114.75 14zM8.192 8.4a.757.757 0 01-1.025-.27l-1-1.732a.75.75 0 111.3-.75l1 1.732a.749.749 0 01-.275 1.02zM4.88 11.332l-1.732-1a.75.75 0 01.75-1.3l1.732 1a.749.749 0 01.27 1.026.76.76 0 01-1.02.274zm.37 3.168a.75.75 0 01-.75.75h-2a.75.75 0 010-1.5h2a.75.75 0 01.75.75zM12 3.75a.75.75 0 01.75.75v2a.75.75 0 01-1.5 0v-2a.75.75 0 01.75-.75z\"/><circle cx=\"12\" cy=\"14\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});