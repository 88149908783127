define("ember-svg-jar/inlined/lion-tail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lion-tail</title><path d=\"M21.934 5.874a.707.707 0 00-.622-.374h-.034a.706.706 0 00-.617.433c-.682 1.635-2.286 3.369-3.89 5.507a3.9 3.9 0 002.821 6.348 4.065 4.065 0 004.231-4.232 16.4 16.4 0 00-1.889-7.682z\"/><path d=\"M19.592 19.289a4.932 4.932 0 01-1.912-.389.25.25 0 00-.314.107A3.9 3.9 0 0113.672 21c-4.331 0-4.5-4.494-4.5-5V8c0-6.328-4.907-8-7.5-8a1.5 1.5 0 00-.01 3c.753.01 4.51.3 4.51 5v8c0 2.767 1.567 8 7.5 8a6.911 6.911 0 006.728-4.391.251.251 0 00-.253-.346 5.997 5.997 0 01-.555.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});