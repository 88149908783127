define("ember-svg-jar/inlined/death-coffin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>death-coffin</title><path d=\"M20.813 7.021L16.1.419A1 1 0 0015.284 0H8.716A1 1 0 007.9.419l-4.716 6.6a.994.994 0 00-.157.824l3.849 15.4a1 1 0 00.97.758h8.3a1 1 0 00.971-.758l3.849-15.4a1 1 0 00-.153-.822zM16.5 10a.5.5 0 01-.5.5h-2a.5.5 0 00-.5.5v7a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-7a.5.5 0 00-.5-.5H8a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h2a.5.5 0 00.5-.5V5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});