define("ember-svg-jar/inlined/ceiling-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ceiling-lamp</title><path d=\"M12 20.5a1 1 0 00-1 1V23a1 1 0 002 0v-1.5a1 1 0 00-1-1zM17.866 19.66a1 1 0 00-1.733 1l.75 1.3a1 1 0 101.733-1zM7.5 19.294a1 1 0 00-1.367.366l-.75 1.3a1 1 0 00.367 1.367.991.991 0 00.5.133 1 1 0 00.867-.5l.75-1.3a1 1 0 00-.367-1.366zM15.625 7.25a1 1 0 01-.6-.917V5.5a2.986 2.986 0 00-1.733-2.7.5.5 0 01-.292-.454V1a1 1 0 00-2 0v1.346a.5.5 0 01-.288.453A2.986 2.986 0 008.979 5.5v.833a1 1 0 01-.6.917 8.284 8.284 0 00-5.367 6.626.992.992 0 00.245.786 1.01 1.01 0 00.751.338h15.985a1.01 1.01 0 00.755-.338 1 1 0 00.244-.787 8.286 8.286 0 00-5.367-6.625zM12 19.25a7.028 7.028 0 004.87-1.958.75.75 0 00-.517-1.292h-.769a.753.753 0 00-.462.16 5.016 5.016 0 01-6.244 0 .753.753 0 00-.462-.16h-.769a.749.749 0 00-.517 1.292A7.026 7.026 0 0012 19.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});