define("ember-svg-jar/inlined/composition-layout-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>composition-layout-2</title><path d=\"M24 2a2 2 0 00-2-2H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2zm-2 4.733a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25V2.25a.25.25 0 01.25-.25h8.25a.5.5 0 01.5.5zm-19.75 8.25a.25.25 0 01-.25-.25v-5.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v5.5a.25.25 0 01-.25.25zM10.75 2a.25.25 0 01.25.25v4.483a.25.25 0 01-.25.25h-8.5a.25.25 0 01-.25-.25V2.5a.5.5 0 01.5-.5zM2 17.233a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25v4.517a.25.25 0 01-.25.25H2.5a.5.5 0 01-.5-.5zM13.25 22a.25.25 0 01-.25-.25v-4.517a.25.25 0 01.25-.25h8.5a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});