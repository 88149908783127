define("ember-svg-jar/inlined/bomb-explosive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bomb-explosive</title><path d=\"M12 3.053a1 1 0 001-1V1a1 1 0 00-2 0v1.053a1 1 0 001 1zM14.5 4.053a1 1 0 00.52-.147l.872-.533a1 1 0 00-1.042-1.706l-.871.533a1 1 0 00.521 1.853zM14.014 5.646a1 1 0 00.823 1.15l1.465.243a1 1 0 10.327-1.973l-1.465-.242a1 1 0 00-1.15.822zM8.058 3.394l.955.532a1 1 0 10.974-1.747l-.955-.533a1 1 0 10-.974 1.748zM7.606 7.041L9.155 6.8a1 1 0 00-.31-1.976L7.3 5.065a1 1 0 00.31 1.976z\"/><path d=\"M19 8h-1.5A1.5 1.5 0 0016 9.5v1.511a.251.251 0 01-.4.2 5.923 5.923 0 00-.83-.529.5.5 0 01-.269-.443V9.5a1.5 1.5 0 00-1.125-1.453.5.5 0 01-.376-.484V5.45a1 1 0 10-2 0v2.113a.5.5 0 01-.375.484A1.5 1.5 0 009.5 9.5v.738a.5.5 0 01-.269.443 5.923 5.923 0 00-.83.529.25.25 0 01-.4-.2V9.5A1.5 1.5 0 006.5 8H5a1.5 1.5 0 00-1.5 1.5v13A1.5 1.5 0 005 24h1.5A1.5 1.5 0 008 22.5v-1.511a.251.251 0 01.4-.2 5.923 5.923 0 00.83.529.5.5 0 01.269.443v.739A1.5 1.5 0 0011 24h2a1.5 1.5 0 001.5-1.5v-.738a.5.5 0 01.269-.443 5.923 5.923 0 00.83-.529.25.25 0 01.4.2v1.51A1.5 1.5 0 0017.5 24H19a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0019 8zm-7 12a4 4 0 114-4 4 4 0 01-4 4z\"/><path d=\"M13.979 15.75H13a.25.25 0 01-.25-.25V14a.75.75 0 00-1.5 0v2.5a.75.75 0 00.75.75h1.979a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});