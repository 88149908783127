define("ember-svg-jar/inlined/delivery-man-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-man-give</title><path d=\"M17.491 20.993l.014-3a1.488 1.488 0 01.443-1.058 1.46 1.46 0 01.415-.286.249.249 0 00.144-.226v-.452a1 1 0 00-1-.994h-11a1 1 0 00-1 1v.443a.25.25 0 00.144.228 1.443 1.443 0 01.423.294 1.494 1.494 0 01.434 1.063l-.014 3a1.49 1.49 0 01-.443 1.058 1.445 1.445 0 01-.419.288.253.253 0 00-.146.227v.364a1 1 0 001 1.005l11 .051a1 1 0 001-1v-.429a.251.251 0 00-.144-.228 1.441 1.441 0 01-.418-.292 1.494 1.494 0 01-.433-1.056zm-3.5-1.386a.4.4 0 01-.652.314l-1.2-.952a.251.251 0 00-.309 0l-1.208.938a.4.4 0 01-.649-.319l.016-3.349a.25.25 0 01.251-.249l3.523.017a.249.249 0 01.176.074.245.245 0 01.072.177z\"/><path d=\"M5.364 17.648a.489.489 0 00-.352-.148h-.007a1.5 1.5 0 00-1.5 1.493v1A1.5 1.5 0 004.967 21.5a.285.285 0 00.051 0 .656.656 0 00.33-.141A.5.5 0 005.5 21l.014-3a.5.5 0 00-.15-.352zM19.007 17.5a.5.5 0 00-.5.5l-.014 3a.5.5 0 00.145.354.586.586 0 00.343.146.151.151 0 00.03 0 1.5 1.5 0 001.489-1.493v-1a1.5 1.5 0 00-1.493-1.507zM12 12.581a3.133 3.133 0 001.882-.63.625.625 0 00-.764-.99 1.973 1.973 0 01-2.236 0 .625.625 0 00-.764.99 3.133 3.133 0 001.882.63z\"/><circle cx=\"14\" cy=\"8.956\" r=\".875\"/><circle cx=\"10\" cy=\"8.956\" r=\".875\"/><path d=\"M4.8 7.92a.25.25 0 01.2.245v.291a7.023 7.023 0 002.913 5.684 1 1 0 101.168-1.623A5.019 5.019 0 017 8.456v-.762a.25.25 0 01.2-.245 23.947 23.947 0 019.6 0 .25.25 0 01.2.245v.762a5.017 5.017 0 01-2.084 4.062 1 1 0 001.168 1.624A7.022 7.022 0 0019 8.456v-.5A1 1 0 0019.274 6c-.283-.081-.567-.146-.851-.214a.251.251 0 01-.187-.194l-.628-3.14a1.494 1.494 0 00-1.04-1.152L12.431.063a1.506 1.506 0 00-.862 0L7.432 1.3a1.494 1.494 0 00-1.04 1.143l-.628 3.14a.251.251 0 01-.187.194c-.284.068-.568.133-.851.214a1 1 0 00-.688 1.236.98.98 0 00.762.693zm5.7-4.026a1.5 1.5 0 111.5 1.5 1.5 1.5 0 01-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});