define("ember-svg-jar/inlined/pin-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pin-remove</title><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM6.5 0A6.508 6.508 0 000 6.5c0 4 4.372 9.9 5.712 11.616a1 1 0 001.576 0C8.628 16.4 13 10.506 13 6.5A6.508 6.508 0 006.5 0zm0 9A2.5 2.5 0 119 6.5 2.5 2.5 0 016.5 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});