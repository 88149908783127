define("ember-svg-jar/inlined/tennis-forehand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tennis-forehand</title><circle cx=\"12.182\" cy=\"2.745\" r=\"2.5\"/><circle cx=\"21.183\" cy=\"6.245\" r=\"2.5\"/><path d=\"M17.317 10.385l-7.5-3.5a1.5 1.5 0 00-1.917.588l-3 5a1.516 1.516 0 00-.214.777v4.133a1.5 1.5 0 01-.464 1.085L.951 21.02a1.5 1.5 0 00-.359 2.091 1.517 1.517 0 002.091.358s3.423-2.665 3.462-2.7a4.5 4.5 0 001.537-3.387v-1.327a.25.25 0 01.118-.212.246.246 0 01.242-.012l3.846 1.89a1.5 1.5 0 01.794 1.324v3.2a1.5 1.5 0 003 0v-3.2a4.51 4.51 0 00-2.426-3.993l-4.7-2.314a.25.25 0 01-.13-.153.254.254 0 01.026-.2l1.21-2.016a.25.25 0 01.32-.1l6.07 2.833a1.5 1.5 0 101.27-2.719z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});