define("ember-svg-jar/inlined/print-picture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>print-picture</title><path d=\"M5.75 6a1 1 0 001-1V2h8.836l2.164 2.163V5a1 1 0 002 0V3.749a1 1 0 00-.293-.707L17 .587A2.01 2.01 0 0015.586 0H6.75a2 2 0 00-2 2v3a1 1 0 001 1zM21.5 7h-19A2.5 2.5 0 000 9.5V14a2.5 2.5 0 002.5 2.5H5v6.25A1.251 1.251 0 006.25 24h12a1.251 1.251 0 001.25-1.25V16.5h2A2.5 2.5 0 0024 14V9.5A2.5 2.5 0 0021.5 7zm-18 4.5a1 1 0 111-1 1 1 0 01-1 1zm14 10a.5.5 0 01-.5.5H7.5a.5.5 0 01-.5-.5v-9.043a.5.5 0 01.5-.5H17a.5.5 0 01.5.5z\"/><path d=\"M14.686 15.894a.5.5 0 00-.413-.307.51.51 0 00-.465.219l-1.66 2.477-1.648-1.35a.5.5 0 00-.762.156L8.1 20.27a.5.5 0 00.442.73h7.5a.5.5 0 00.462-.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});