define("ember-svg-jar/inlined/real-estate-action-building-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-action-building-lock</title><path d=\"M6.723 6.053l-3-1.5a.5.5 0 00-.447 0l-3 1.5A.5.5 0 000 6.5V14a.5.5 0 00.5.5H2a.5.5 0 00.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1a.5.5 0 00.5.5h1.5A.5.5 0 007 14V6.5a.5.5 0 00-.277-.447zM5 10.5H2a.5.5 0 010-1h3a.5.5 0 010 1zM5 8H2a.5.5 0 010-1h3a.5.5 0 010 1zM14.251 14.251a4.7 4.7 0 01.677-2.422.5.5 0 00.072-.257V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 008 .5V14a.5.5 0 00.5.5h5.2a.5.5 0 00.258-.072 3.07 3.07 0 01.271-.146.034.034 0 00.022-.031zM13 10.5h-3a.5.5 0 010-1h3a.5.5 0 110 1zM13 8h-3a.5.5 0 010-1h3a.5.5 0 110 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 110 1zM22.875 15.548a.5.5 0 01-.375-.484v-.813a3.5 3.5 0 10-7 0v.813a.5.5 0 01-.375.484A1.5 1.5 0 0014 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.125-1.452zM19 21.251a1 1 0 111-1 1 1 0 01-1 1zM20.5 15a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-.749a1.5 1.5 0 113 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});