define("ember-svg-jar/inlined/single-neutral-phone-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-phone-book</title><path d=\"M24 4a3 3 0 00-3-3h-.774a.5.5 0 01-.433-.25A1.491 1.491 0 0018.5 0H3.75a.25.25 0 00-.25.25v23.5a.25.25 0 00.25.25H18.5a1.491 1.491 0 001.293-.75.5.5 0 01.433-.25H21a3 3 0 003-3zm-6.321 14.489a.5.5 0 01-.384.18H6.3a.5.5 0 01-.385-.181.493.493 0 01-.106-.41A5.291 5.291 0 016.3 16.6c.453-.877 1.591-1.416 3.667-2.161a1 1 0 00-.006-.71 4.977 4.977 0 01-1.13-3.51 2.882 2.882 0 012.969-3.05 2.882 2.882 0 012.969 3.053 5 5 0 01-1.149 3.534.982.982 0 00.027.7c2.063.737 3.2 1.275 3.652 2.152a5.32 5.32 0 01.493 1.476.506.506 0 01-.113.405zM22 10.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zm-2 2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25zM21 3a1 1 0 011 1v1.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25zm1 17a1 1 0 01-1 1h-.75a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25zM2.25 0H1.5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h.75a.25.25 0 00.25-.25V.25A.25.25 0 002.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});