define("ember-svg-jar/inlined/real-estate-market-calculator-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-calculator-building</title><path d=\"M23.692 2.538l-6-2.5A.5.5 0 0017 .5V14a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462zM22 8h-3a.5.5 0 010-1h3a.5.5 0 010 1zm0-2.5h-3a.5.5 0 010-1h3a.5.5 0 010 1zM15.276 2.053l-5 2.5A.5.5 0 0010 5v2.5a.5.5 0 00.5.5H13a3.924 3.924 0 012.219.688.5.5 0 00.781-.413V2.5a.5.5 0 00-.724-.447zM14 6.5h-2a.5.5 0 010-1h2a.5.5 0 010 1zM13 9H3a3 3 0 00-3 3v9a3 3 0 003 3h10a3 3 0 003-3v-9a3 3 0 00-3-3zM5.487 19.927l.354.353a.75.75 0 11-1.061 1.061l-.353-.354a.252.252 0 00-.354 0l-.353.354a.75.75 0 11-1.061-1.061l.354-.353a.252.252 0 000-.354l-.354-.353a.75.75 0 011.061-1.061l.353.354a.252.252 0 00.354 0l.353-.354a.75.75 0 011.061 1.061l-.354.353a.252.252 0 000 .354zM5.75 14h-.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-.5a.75.75 0 010-1.5h.5a.25.25 0 00.25-.25v-.5a.75.75 0 011.5 0v.5a.25.25 0 00.25.25h.5a.75.75 0 010 1.5zm7.5 7.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-2.5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-5h-3a.75.75 0 010-1.5h3a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});