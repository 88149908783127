define("ember-svg-jar/inlined/people-woman-cap-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-cap-1</title><path d=\"M6.189 12.447a.253.253 0 01.145.187c.511 3.048 4.021 4.991 5.687 4.991a7.04 7.04 0 005.079-3.337h.01a6.138 6.138 0 001.742-7.613.454.454 0 00-.057-.079C18.5 3.422 16.577.125 11.979.125c-4.569 0-6.5 3.258-6.806 6.41a.736.736 0 00-.094.354 13.71 13.71 0 00.136 1.681 2.114 2.114 0 00-.465 1.443 2.731 2.731 0 001.439 2.434zm5.832 3.678c-1.033 0-3.831-1.49-4.209-3.74a1.747 1.747 0 00-1-1.3 1.279 1.279 0 01-.559-1.1.616.616 0 01.105-.439.752.752 0 00.406-.795 12.469 12.469 0 01-.113-.851.249.249 0 01.249-.275h4.8a.25.25 0 01.244.2 2.909 2.909 0 002.882 2.554c.8 0 1.193 1.111 1.371 2.133a5.354 5.354 0 01-4.176 3.613zm-.042-14.5a4.888 4.888 0 015.19 4.2.25.25 0 01-.245.3H7.036a.25.25 0 01-.245-.3 4.888 4.888 0 015.188-4.2z\"/><path d=\"M21.08 18.662l-.017-.006-4.476-1.5a.5.5 0 00-.562.178 4.985 4.985 0 01-8.05 0 .5.5 0 00-.562-.178L2.92 18.662A4.519 4.519 0 000 22.875v.5a.5.5 0 00.5.5h23a.5.5 0 00.5-.5v-.5a4.519 4.519 0 00-2.92-4.213zM12.792 12.953a1.566 1.566 0 01-.764.172 1.653 1.653 0 01-.772-.174.75.75 0 10-.731 1.31 3.211 3.211 0 001.507.364 3.146 3.146 0 001.5-.365.75.75 0 10-.734-1.307zM11.129 10.661A.751.751 0 0011.09 9.6a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .751.751 0 001.06-.039zM12.5 3.625H11a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});