define("ember-svg-jar/inlined/folder-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-upload</title><path d=\"M9.5 17.5a8.009 8.009 0 018-8 7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.224.83A1.5 1.5 0 006.88 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.563a.447.447 0 00.437-.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.391 5.688a.5.5 0 01-.391.812h-.75a.25.25 0 00-.25.25v2.75a1 1 0 01-2 0v-2.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.391-.813l2-2.5a.518.518 0 01.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});