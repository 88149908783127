define("ember-svg-jar/inlined/single-man-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-megaphone</title><path d=\"M5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144zM23 0a1 1 0 00-1 1v.547a.251.251 0 01-.192.243L12.77 3.936a.25.25 0 01-.3-.182A1 1 0 0010.5 4v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.247a.25.25 0 01.193.243V9.5a2.992 2.992 0 005.937.537.248.248 0 01.109-.165.251.251 0 01.194-.034l1.568.372a.251.251 0 01.192.243V11a1 1 0 002 0V1a1 1 0 00-1-1zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.308-.243l1.5.357A.245.245 0 0118 9.5z\"/><path d=\"M5.5 8.994a5.506 5.506 0 00-5.5 5.5v3a.5.5 0 00.5.5h1.81a.249.249 0 01.249.229L3 23.535a.5.5 0 00.5.459h4a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3a5.506 5.506 0 00-5.5-5.5zm.75 5.948a.75.75 0 01-1.5 0v-3.948a.75.75 0 011.5 0zM5.5 8.144a4.069 4.069 0 10-4.065-4.069A4.074 4.074 0 005.5 8.144zm0-2a2.07 2.07 0 01-2.065-2.069 1.989 1.989 0 01.136-.718.249.249 0 01.362-.124 5.061 5.061 0 002.609.725 4.961 4.961 0 00.736-.058.249.249 0 01.287.247A2.028 2.028 0 015.5 6.144zM23 0a1 1 0 00-1 1v.547a.251.251 0 01-.192.243L12.77 3.936a.25.25 0 01-.3-.182A1 1 0 0010.5 4v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.247a.25.25 0 01.193.243V9.5a2.992 2.992 0 005.937.537.248.248 0 01.109-.165.251.251 0 01.194-.034l1.568.372a.251.251 0 01.192.243V11a1 1 0 002 0V1a1 1 0 00-1-1zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.308-.243l1.5.357A.245.245 0 0118 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});