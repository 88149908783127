define("ember-svg-jar/inlined/plane-trip-take-off-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>plane-trip-take-off-check</title><path d=\"M6.339 2.711l3.87 1.6a.249.249 0 00.2 0l4.25-1.849a.249.249 0 000-.46L10.449.266a3.486 3.486 0 00-2.676 0l-1.434.6a1 1 0 000 1.848z\"/><path d=\"M11.222 10.142a.251.251 0 01.06.266l-.34.907a.25.25 0 00.4.272A7.964 7.964 0 0116.5 9.508a.252.252 0 00.217-.139l.944-1.888a.249.249 0 01.136-.123l4.433-1.651h.009a2.5 2.5 0 10-1.924-4.615L6.968 6.9a.251.251 0 01-.211 0L4.279 5.652a1 1 0 00-.843-.027L.83 6.742a1 1 0 00-.315 1.627l3.045 3.046a2.5 2.5 0 002.7.553l4.7-1.88a.249.249 0 01.262.054zM16.722 11a6.5 6.5 0 106.5 6.5 6.507 6.507 0 00-6.5-6.5zM20 16.206l-2.9 3.873a1.5 1.5 0 01-2.263.159l-1.5-1.5a.75.75 0 011.061-1.061l1.3 1.3a.25.25 0 00.377-.027l2.725-3.645a.751.751 0 011.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});