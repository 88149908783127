define("ember-svg-jar/inlined/love-heart-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-hold</title><path d=\"M23.853 21.808l-1.78-2.908a.5.5 0 01-.073-.259v-4.136c0-1.773-1.708-3.069-3.517-4.441a.508.508 0 01-.058-.759 4.953 4.953 0 001.069-5.88 5.084 5.084 0 00-8.087-1.295l-1.057.963a.5.5 0 01-.674 0L8.62 2.13a5.084 5.084 0 00-8.086 1.3 4.934 4.934 0 00.945 5.753l7.759 7.988a1.08 1.08 0 001.55 0 .64.64 0 00.146-.656 3.624 3.624 0 01-.2-1.385 3.747 3.747 0 014.916-3.366.5.5 0 00.516-.127l.15-.153a.5.5 0 01.662-.049l.3.227c1.209.917 2.722 2.063 2.722 2.843v4.559a1 1 0 00.147.522l2 3.267a1 1 0 001.706-1.045z\"/><path d=\"M16.1 13.576a2.378 2.378 0 00-3.371 3.349l1.62 1.775a.5.5 0 01.131.337v1.027c0 1.3 1.516 2.589 1.98 2.952a.875.875 0 001.081-1.377 3.871 3.871 0 01-1.311-1.575v-1.558a.878.878 0 00-.23-.591l-1.985-2.171a.628.628 0 01.89-.884l2.015 1.864a.875.875 0 001.188-1.285z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});