define("ember-svg-jar/inlined/restaurant-fork-knife", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>restaurant-fork-knife</title><path d=\"M15.5 0a.75.75 0 00-.75.75V23a1 1 0 002 0v-8a.5.5 0 01.5-.5H19a1.243 1.243 0 001.25-1.25v-.007C20.186 6.055 17.832 0 15.5 0zM10.75 0a1 1 0 00-1 1v5a1.993 1.993 0 01-.571 1.4.251.251 0 01-.429-.175V1a1 1 0 00-2 0v6.223a.251.251 0 01-.429.175A1.993 1.993 0 015.75 6V1a1 1 0 00-2 0v5a4.009 4.009 0 002.667 3.772.5.5 0 01.333.471V23a1 1 0 002 0V10.243a.5.5 0 01.333-.471A4.009 4.009 0 0011.75 6V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});