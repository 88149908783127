define("ember-svg-jar/inlined/data-file-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-star</title><path d=\"M12.608 17.139a1.262 1.262 0 00.3-1.378A1.232 1.232 0 0011.739 15h-2.5a.252.252 0 01-.23-.151l-1.344-3.118-.013-.031a1.293 1.293 0 00-2.317.027l-1.346 3.121a.252.252 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.269 1.269 0 00.319 1.4l2.194 1.954a.251.251 0 01.063.287l-1.224 2.811a1.253 1.253 0 00.315 1.451 1.36 1.36 0 001.506.166l3.111-1.753a.251.251 0 01.246 0l3.112 1.753a1.277 1.277 0 001.506-.166 1.256 1.256 0 00.316-1.45L10.333 19.4a.251.251 0 01.063-.287zM12.291 12.089l1.643-2.321a.251.251 0 01.333-.07l1.507.9A1 1 0 0017 10.451a.9.9 0 00.105-.123l2.688-3.734a1 1 0 10-1.624-1.168l-2.016 2.8a.251.251 0 01-.331.068L14.3 7.388a1 1 0 00-1.331.279l-2.312 3.265a1 1 0 001.632 1.157z\"/><path d=\"M12.944 20.388c.029.066.188.112.282.112H22a2 2 0 002-2V4.414A2 2 0 0023.413 3L21 .586A2 2 0 0019.584 0H8a2 2 0 00-2 2v6.792a.252.252 0 00.232.249 8.422 8.422 0 011.451.211A.249.249 0 008 9.013V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.353.146l2.121 2.122a.5.5 0 01.148.353V18a.5.5 0 01-.5.5h-7.337a.234.234 0 00-.162.06l-1.165 1.051a.279.279 0 00-.1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});