define("ember-svg-jar/inlined/cloud-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-cash</title><path d=\"M2.617 16.745A1 1 0 004 15.816v-.027a.948.948 0 00-.576-.876 2.4 2.4 0 01-.787-.5A2.371 2.371 0 012 12.624a2.588 2.588 0 012.586-2.587 2.635 2.635 0 01.535.056 1.009 1.009 0 00.811-.186 1 1 0 00.392-.779c.081-5.737 8.293-7.371 10.817-2.045a1.024 1.024 0 00.979.569 3.692 3.692 0 012.994 1.341 3.849 3.849 0 01.878 2.721 3.485 3.485 0 01-.842 2.155.982.982 0 00-.217.78v.026a.983.983 0 001.686.541A5.432 5.432 0 0024 11.621a5.766 5.766 0 00-5.217-5.944.25.25 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 10.3 4.479 4.479 0 000 12.744a4.287 4.287 0 001.235 3.09 4.44 4.44 0 001.382.911z\"/><path d=\"M19 15.679a6.5 6.5 0 10-6.5 6.5 6.508 6.508 0 006.5-6.5zm-8 3.75a.75.75 0 010-1.5h2.033a.592.592 0 00.221-1.141l-2.064-.826a2.084 2.084 0 01.36-3.98.249.249 0 00.2-.245v-.308a.75.75 0 011.5 0v.25a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.033a.592.592 0 00-.221 1.141l2.065.826a2.084 2.084 0 01-.361 3.98.249.249 0 00-.2.245v.308a.75.75 0 11-1.5 0v-.25a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});