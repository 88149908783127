define("ember-svg-jar/inlined/protection-shield-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-shield-5</title><path d=\"M10 8.57v-8a.5.5 0 00-.5-.5H1.958A1.958 1.958 0 00.012 2.028L0 8.569a.5.5 0 00.5.5h9a.5.5 0 00.5-.499zM14.5 13.57a.5.5 0 00-.5.5v9.611a.25.25 0 00.339.233 15.215 15.215 0 008.981-9.7.5.5 0 00-.485-.643zM14.5 9.07h8.989a.5.5 0 00.5-.5L24 2.018A1.96 1.96 0 0022.044.069H14.5a.5.5 0 00-.5.5v8a.5.5 0 00.5.501zM9.5 13.57H1.164a.5.5 0 00-.484.644 15.224 15.224 0 008.98 9.678.25.25 0 00.34-.234V14.07a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});