define("ember-svg-jar/inlined/database-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-hand</title><path d=\"M6.835 18.375a.626.626 0 01.625-.625h2.526a1 1 0 00.919-1.393A2.233 2.233 0 008.849 15H4.585a.5.5 0 00-.5.5V22a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.236V20a1 1 0 00-1-1H7.46a.626.626 0 01-.625-.625zM3.085 15.25a1.019 1.019 0 00-1-1 1 1 0 00-1 1v7.25a1 1 0 001 1 .983.983 0 00.809-.443.976.976 0 00.191-.557zM22.915 11.169a.251.251 0 00-.4-.2 8.827 8.827 0 01-5.31 1.54 8.824 8.824 0 01-5.309-1.54.251.251 0 00-.4.2v2.292c0 1.638 2.615 3.021 5.712 3.021s5.713-1.383 5.713-3.021z\"/><path d=\"M11.49 8.49c0 1.638 2.615 3.021 5.712 3.021s5.713-1.383 5.713-3.021V6.2a.251.251 0 00-.14-.224.248.248 0 00-.263.024A8.834 8.834 0 0117.2 7.541 8.83 8.83 0 0111.893 6a.25.25 0 00-.4.2z\"/><ellipse cx=\"17.202\" cy=\"3.521\" rx=\"5.712\" ry=\"3.021\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});