define("ember-svg-jar/inlined/single-woman-actions-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-home</title><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.705-3.714a.245.245 0 00-.059-.212 2.362 2.362 0 01-.475-.766 2.483 2.483 0 01.723-2.739l1.98-1.674a.247.247 0 00.079-.254c-.872-3.2-.94-3.928-2-4.916a.5.5 0 00-.757.1l-1.733 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM1.834 4.206A4.207 4.207 0 106.041 0a4.212 4.212 0 00-4.207 4.206zm4.207 2.227A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM10.2 17.684a.5.5 0 00.32.873h1.237A.247.247 0 0112 18.8v4.7a.494.494 0 00.495.5H21.4a.5.5 0 00.495-.5v-4.7a.246.246 0 01.247-.247h1.237a.5.5 0 00.32-.873l-6.433-5.443a.494.494 0 00-.64 0z\"/><path d=\"M.141 17.931a.5.5 0 00.478.626h2.3a.247.247 0 01.243.2l.921 4.847v.007a.5.5 0 00.484.389h2.969a.5.5 0 00.483-.388v-.007l.705-3.714a.245.245 0 00-.059-.212 2.362 2.362 0 01-.475-.766 2.483 2.483 0 01.723-2.739l1.98-1.674a.247.247 0 00.079-.254c-.872-3.2-.94-3.928-2-4.916a.5.5 0 00-.757.1l-1.733 2.73a.5.5 0 01-.837 0L3.923 9.411a.5.5 0 00-.755-.1C2.014 10.373 2.1 10.78.141 17.931zM1.834 4.206A4.207 4.207 0 106.041 0a4.212 4.212 0 00-4.207 4.206zm4.207 2.227A2.223 2.223 0 013.9 4.81a.247.247 0 01.158-.3 4.63 4.63 0 001.8-1.128.247.247 0 01.353 0 4.627 4.627 0 001.8 1.128.247.247 0 01.157.3 2.222 2.222 0 01-2.127 1.623zM10.2 17.684a.5.5 0 00.32.873h1.237A.247.247 0 0112 18.8v4.7a.494.494 0 00.495.5H21.4a.5.5 0 00.495-.5v-4.7a.246.246 0 01.247-.247h1.237a.5.5 0 00.32-.873l-6.433-5.443a.494.494 0 00-.64 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});