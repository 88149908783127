define("ember-svg-jar/inlined/tools-kitchen-scale-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-kitchen-scale-1</title><path d=\"M19.567 3.017A10.771 10.771 0 0012 0C6.7 0 1 3.755 1 12c0 3.713 1.942 8.969 2.778 11.057A1.492 1.492 0 005.17 24h13.659a1.5 1.5 0 001.394-.943C21.058 20.969 23 15.713 23 12a12.171 12.171 0 00-3.433-8.983zM12 3a1 1 0 11-1 1 1 1 0 011-1zM6.343 5.343a1 1 0 110 1.415 1 1 0 010-1.415zM5 12a1 1 0 111-1 1 1 0 01-1 1zm2.757 4.657a1 1 0 110-1.414 1 1 0 010 1.414zM12 19a1 1 0 111-1 1 1 0 01-1 1zm0-4a4 4 0 01-1.715-7.615.5.5 0 01.715.451V9.5a1 1 0 002 0V7.837a.5.5 0 01.715-.452A4 4 0 0112 15zm5.657 1.657a1 1 0 110-1.414 1 1 0 010 1.414zm0-9.9a1 1 0 110-1.415 1 1 0 010 1.416zM19 12a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});