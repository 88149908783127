define("ember-svg-jar/inlined/love-it-circle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-circle-alternate</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M15.54 7.046a3.494 3.494 0 00-3.058.988L12 8.515l-.481-.482a3.525 3.525 0 00-4.993 4.978l5.114 5.335a.5.5 0 00.721 0l5.106-5.326a3.522 3.522 0 00.66-4.071 3.486 3.486 0 00-2.587-1.903z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});