define("ember-svg-jar/inlined/vr-globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-globe</title><path d=\"M22.5 14h-.313a.249.249 0 00-.226.144 10.983 10.983 0 01-19.922 0A.249.249 0 001.813 14H1.5A1.5 1.5 0 000 15.5v6A2.5 2.5 0 002.5 24h5.26a2.5 2.5 0 001.627-.6l.986-.845a2.487 2.487 0 013.254 0l.986.845a2.5 2.5 0 001.627.6h5.26a2.5 2.5 0 002.5-2.5v-6a1.5 1.5 0 00-1.5-1.5z\"/><path d=\"M12 19zm4.039-3.19a.25.25 0 01-.357-.325 10.724 10.724 0 00.766-1.97.252.252 0 01.241-.182h1.294a.251.251 0 01.206.392 7.545 7.545 0 01-2.155 2.085zM9.7 13.67a.252.252 0 01.235-.337h4.122a.252.252 0 01.235.337 6.627 6.627 0 01-2.145 3.068.249.249 0 01-.3 0A6.623 6.623 0 019.7 13.67zM4.5 9.5a7.379 7.379 0 01.189-1.639.25.25 0 01.244-.195h1.932a.248.248 0 01.185.083.254.254 0 01.064.192 15.043 15.043 0 000 3.118.254.254 0 01-.064.192.249.249 0 01-.186.082H4.933a.25.25 0 01-.244-.194A7.379 7.379 0 014.5 9.5zm4.533 0a14.1 14.1 0 01.091-1.612.25.25 0 01.248-.222h5.256a.25.25 0 01.248.222 14.1 14.1 0 01.091 1.612 14.333 14.333 0 01-.091 1.612.249.249 0 01-.248.221H9.372a.249.249 0 01-.248-.221A14.1 14.1 0 019.033 9.5zm2.817-7.237a.249.249 0 01.3 0A6.619 6.619 0 0114.3 5.33a.25.25 0 01-.235.336H9.939a.249.249 0 01-.234-.336 6.623 6.623 0 012.145-3.067zM19.5 9.5a7.379 7.379 0 01-.189 1.639.249.249 0 01-.244.194h-1.931a.249.249 0 01-.186-.082.254.254 0 01-.064-.192 15.043 15.043 0 000-3.118.258.258 0 01.064-.193.253.253 0 01.186-.082h1.931a.251.251 0 01.244.195A7.379 7.379 0 0119.5 9.5zm-2.816-3.834a.251.251 0 01-.241-.181 10.724 10.724 0 00-.766-1.97.25.25 0 01.357-.325 7.545 7.545 0 012.15 2.085.25.25 0 01-.206.391zM7.966 3.19a.25.25 0 01.357.325 10.724 10.724 0 00-.766 1.97.251.251 0 01-.241.181H6.022a.249.249 0 01-.222-.133.252.252 0 01.015-.258A7.545 7.545 0 017.966 3.19zm-.65 10.143a.252.252 0 01.241.182 10.724 10.724 0 00.766 1.97.25.25 0 01-.357.325 7.545 7.545 0 01-2.15-2.085.251.251 0 01.206-.392z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});