define("ember-svg-jar/inlined/location-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>location-user</title><path d=\"M16.1 14.25a.253.253 0 00-.189.051.246.246 0 00-.1.171L15.58 16.7a.249.249 0 00.207.272c2.309.383 3.867 1.105 3.867 1.933 0 1.229-3.427 2.225-7.654 2.225s-7.654-1-7.654-2.225c0-.828 1.558-1.55 3.867-1.933a.249.249 0 00.207-.272l-.231-2.226a.246.246 0 00-.1-.171.253.253 0 00-.189-.051c-4.292.615-7.9 2.2-7.9 4.653 0 3.211 6.178 4.944 11.991 4.944s12-1.735 12-4.949c0-2.45-3.604-4.035-7.891-4.65z\"/><circle cx=\"12\" cy=\"2.903\" r=\"2.75\"/><path d=\"M9 13.153h.77a.249.249 0 01.249.229l.443 5.312a.5.5 0 00.5.459h2.08a.5.5 0 00.5-.459l.443-5.312a.249.249 0 01.249-.229H15a.5.5 0 00.5-.5v-2.5a3.5 3.5 0 00-7 0v2.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});