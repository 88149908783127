define("ember-svg-jar/inlined/style-three-pin-skating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-skating</title><path d=\"M12 0C6.21 0 1.5 4.26 1.5 9.5c0 5.75 7.3 12.29 9.54 14.15a1.492 1.492 0 001.92 0c2.24-1.87 9.54-8.4 9.54-14.15C22.5 4.26 17.79 0 12 0zm4.5 15.27a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm-4 0a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zM8 5.12a.5.5 0 01.32-.46l3-1.13A.508.508 0 0112 4v1.78a2.006 2.006 0 002 2 3 3 0 013 3 .973.973 0 01-.057.334.249.249 0 01-.257.164 2.275 2.275 0 00-.186-.008 2.481 2.481 0 00-1.447.463.244.244 0 01-.145.047h-.816a.244.244 0 01-.145-.047 2.493 2.493 0 00-2.894 0 .244.244 0 01-.145.047h-.913a2.387 2.387 0 00-1.236-.492.25.25 0 01-.15-.068A2 2 0 018 9.78z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});