define("ember-svg-jar/inlined/vintage-car-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-4</title><path d=\"M23.5 12.625H22a.5.5 0 010-1h1.26a.249.249 0 00.24-.181l.344-1.2A.856.856 0 0023 9.125h-2a1.532 1.532 0 00-1.193.639l-.1.155a.25.25 0 01-.408.011c-1.6-2.147-3.347-3.555-6.346-3.555H9.579a2.983 2.983 0 00-2.122.879l-3.3 3.3a.249.249 0 01-.177.073H1.5a1.485 1.485 0 00-1.193.6.25.25 0 00.2.4H2a.5.5 0 010 1H.25a.25.25 0 00-.25.25v2.25a1.5 1.5 0 001.5 1.5h.846a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.689 3 3 0 016 0 2.8 2.8 0 01-.089.689.251.251 0 00.243.311h5.192a.251.251 0 00.243-.311 2.8 2.8 0 01-.089-.691 3 3 0 015.991-.231.25.25 0 00.249.231h2.26a1.5 1.5 0 001.5-1.5v-1a.5.5 0 00-.5-.5zm-12.25-2.25a.25.25 0 01-.25.25H7.518a.25.25 0 01-.177-.427l1.53-1.53a1 1 0 01.708-.293H11a.25.25 0 01.25.25z\"/><circle cx=\"5.5\" cy=\"15.625\" r=\"2\"/><circle cx=\"17\" cy=\"15.625\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});