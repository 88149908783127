define("ember-svg-jar/inlined/3d-print-triangle-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-triangle-1</title><path d=\"M12.123 16.671a.251.251 0 00-.25-.251h-9.5a.249.249 0 01-.25-.25V2.375a.25.25 0 01.25-.25H8.4a.25.25 0 01.25.25v.818a.4.4 0 00.082.245l.818 1.091a.409.409 0 00.654 0l.818-1.091a.4.4 0 00.082-.245v-.818a.25.25 0 01.25-.25h6.023a.25.25 0 01.25.25v12a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-12.5a1.752 1.752 0 00-1.75-1.75h-16a1.752 1.752 0 00-1.75 1.75v16a1.752 1.752 0 001.75 1.75h10a.249.249 0 00.25-.251c-.004-.598-.006-2.103-.004-2.703z\"/><path d=\"M7.951 6.405a.749.749 0 000-1.06L6.928 4.322a.75.75 0 00-1.061 1.06l1.024 1.023a.749.749 0 001.06 0zM12.822 4.322L11.8 5.345a.75.75 0 001.06 1.06l1.024-1.023a.75.75 0 00-1.061-1.06zM10.307 7.623a.519.519 0 00-.864 0l-3.5 6a.5.5 0 00.432.752h7a.5.5 0 00.432-.752zM20.125 16.375a.75.75 0 00-.75.75v6a.75.75 0 00.75.75 3.75 3.75 0 000-7.5zm.881 5.821a.094.094 0 01-.131-.087v-3.716a.25.25 0 01.375-.216 2.25 2.25 0 01-.244 4.019zM18.375 18.625a2.253 2.253 0 00-2.25-2.25h-1.5a.75.75 0 000 1.5h1.5a.75.75 0 010 1.5h-.5a.75.75 0 000 1.5h.5a.75.75 0 010 1.5h-1.5a.75.75 0 000 1.5h1.5a2.249 2.249 0 001.676-3.75 2.244 2.244 0 00.574-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});