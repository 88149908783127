define("ember-svg-jar/inlined/voice-id-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>voice-id-user</title><path d=\"M1.028 2.5a1 1 0 00-1 1v14a1 1 0 002 0v-14a1 1 0 00-1-1zM4.528 6.5a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1zM8.028 5a1 1 0 00-1 1v9a1 1 0 102 0V6a1 1 0 00-1-1zM12.528 1a1 1 0 00-2 0v19a1 1 0 002 0zM22.028 10.5a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM18.528 9.5a1 1 0 001-1v-1a1 1 0 00-2 0v1a1 1 0 001 1zM15.028 10.5a1 1 0 001-1V6a1 1 0 10-2 0v3.5a1 1 0 001 1zM23.922 22.983a6.126 6.126 0 00-3.829-3.677.25.25 0 01-.029-.465 4.253 4.253 0 10-3.618-.024.25.25 0 01-.034.464 6.121 6.121 0 00-3.891 3.7.749.749 0 00.7 1.017h10a.75.75 0 00.7-1.017zM16.028 15a2.145 2.145 0 01.126-.72.252.252 0 01.366-.131 5.648 5.648 0 002.944.851 5.215 5.215 0 00.764-.056.25.25 0 01.286.266 2.248 2.248 0 01-4.486-.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});