define("ember-svg-jar/inlined/monitor-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-download</title><path d=\"M20.3 20.541a1.952 1.952 0 001.952-1.952V6.875A1.952 1.952 0 0020.3 4.922h-3.42a.976.976 0 00-.98.978.976.976 0 00.976.976h3.168a.25.25 0 01.25.25v9.75a.25.25 0 01-.25.25H3.952a.25.25 0 01-.25-.25V7.125a.25.25 0 01.25-.25h3.167A.976.976 0 008.1 5.9a.976.976 0 00-.976-.977H3.7a1.952 1.952 0 00-1.95 1.952v11.714a1.952 1.952 0 001.95 1.952h7.072a.25.25 0 01.25.25v.965a.25.25 0 01-.25.25H5.9a.976.976 0 000 1.952h12.2a.976.976 0 100-1.952h-4.874a.25.25 0 01-.25-.25v-.965a.25.25 0 01.25-.25z\"/><path d=\"M10.535 8.577a.25.25 0 01-.25.25h-1.7a.976.976 0 00-.69 1.667l3.416 3.416a.977.977 0 001.381 0l3.416-3.416a.976.976 0 00-.69-1.667h-1.7a.25.25 0 01-.25-.25V1.506a1.465 1.465 0 10-2.929 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});