define("ember-svg-jar/inlined/smart-watch-circle-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-star</title><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm4.272 8.211l-1.3.912a.252.252 0 00-.086.305l.718 1.646a1 1 0 01-.251 1.158 1.028 1.028 0 01-1.2.133l-1.957-1.1a.254.254 0 00-.245 0L10 15.865a1.02 1.02 0 01-1.2-.133 1 1 0 01-.251-1.158l.716-1.645a.249.249 0 00-.086-.3l-1.232-.859a1.029 1.029 0 01-.3-1.154.986.986 0 01.929-.616h1.482a.25.25 0 00.234-.162l.845-2.229a1.053 1.053 0 011.856-.049.54.54 0 01.023.049l.844 2.229a.251.251 0 00.234.162h1.482a.985.985 0 01.934.612 1 1 0 01-.238 1.099zM7.379 3.051a.125.125 0 00.135.014 9.98 9.98 0 018.972 0 .125.125 0 00.177-.144l-.68-2.55A.5.5 0 0015.5 0h-7a.5.5 0 00-.483.371l-.68 2.551a.125.125 0 00.042.129z\"/><path d=\"M16.621 20.949a.125.125 0 00-.135-.015 9.972 9.972 0 01-8.972 0 .125.125 0 00-.177.144l.68 2.551A.5.5 0 008.5 24h7a.5.5 0 00.483-.371l.68-2.551a.125.125 0 00-.042-.129z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});