define("ember-svg-jar/inlined/usb-cable-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>usb-cable-1</title><path d=\"M18.167 10.029A.25.25 0 0118 9.793V2a2 2 0 00-2-2H8a2 2 0 00-2 2v7.793a.25.25 0 01-.167.236.5.5 0 00-.333.471v8A2.5 2.5 0 008 21h2.25a.25.25 0 01.25.25v1.25a1.5 1.5 0 103 0v-1.25a.25.25 0 01.25-.25H16a2.5 2.5 0 002.5-2.5v-8a.5.5 0 00-.333-.471zM8 2.251A.25.25 0 018.25 2h7.5a.25.25 0 01.25.25v7a.25.25 0 01-.25.25h-7.5A.25.25 0 018 9.251z\"/><rect x=\"9\" y=\"3.501\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\"13\" y=\"3.501\" width=\"2\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});