define("ember-svg-jar/inlined/settings-sound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>settings-sound</title><path d=\"M18.5 3a3 3 0 00-3-3h-7a3 3 0 00-3 3v18a3 3 0 003 3h7a3 3 0 003-3zM8 7a.5.5 0 01-.5-.5V3a1 1 0 011-1h7a1 1 0 011 1v3.5a.5.5 0 01-.5.5zm6.5 13.5a.5.5 0 01-.7.459L11.094 19.8a.493.493 0 00-.2-.041H10a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h.9a.51.51 0 00.2-.04l2.7-1.161a.5.5 0 01.7.459z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});