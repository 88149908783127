define("ember-svg-jar/inlined/server-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-add</title><path d=\"M0 3.5A2.5 2.5 0 002.5 6h17A2.5 2.5 0 0022 3.5v-1A2.5 2.5 0 0019.5 0h-17A2.5 2.5 0 000 2.5zM3.635 3A1.115 1.115 0 114.75 4.115 1.115 1.115 0 013.635 3zM7.5 3a1.115 1.115 0 111.115 1.115A1.114 1.114 0 017.5 3zM17.5 9.5a7.957 7.957 0 013.777.949.493.493 0 00.723-.439v-.508A2.5 2.5 0 0019.5 7h-17A2.5 2.5 0 000 9.5v1A2.5 2.5 0 002.5 13h8.139a.491.491 0 00.4-.206A7.992 7.992 0 0117.5 9.5zm-7.771.5a1.115 1.115 0 11-1.114-1.115A1.114 1.114 0 019.729 10zm-3.864 0A1.115 1.115 0 114.75 8.885 1.115 1.115 0 015.865 10zM9.56 14H2.5A2.5 2.5 0 000 16.5v1A2.5 2.5 0 002.5 20h6.742a.5.5 0 00.481-.638 7.866 7.866 0 01.3-4.687A.5.5 0 009.56 14zm-5.925 3a1.115 1.115 0 111.115 1.114A1.115 1.115 0 013.635 17z\"/><path d=\"M11 17.5a6.5 6.5 0 106.5-6.5 6.508 6.508 0 00-6.5 6.5zm5.75 1a.25.25 0 00-.25-.25H15a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V15a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H20a.75.75 0 010 1.5h-1.5a.25.25 0 00-.25.25V20a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});