define("ember-svg-jar/inlined/design-tool-quill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>design-tool-quill</title><path d=\"M23.366.019c-.166.045-1.858.521-4.152 1.416a.252.252 0 00-.147.154l-1.33 3.99a.248.248 0 01-.2.169.252.252 0 01-.241-.109l-1.62-2.423a.249.249 0 00-.319-.085A27.212 27.212 0 008.3 7.925a15.606 15.606 0 00-4.39 6.856 5.342 5.342 0 00.183 3.484.249.249 0 01-.05.281l-3.75 3.747a1 1 0 001.414 1.414L13.3 12.115a.5.5 0 01.707.707l-7.022 7.025a.25.25 0 00.154.426 6.368 6.368 0 002.08-.18 15.606 15.606 0 006.856-4.39c5.112-5.111 7.8-14.666 7.907-15.069a.5.5 0 00-.616-.615z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});