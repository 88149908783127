define("ember-svg-jar/inlined/rating-half-star-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rating-half-star-alternate</title><path d=\"M17.324.249a1.512 1.512 0 00-1.411.993l-2.3 6.507H7.174a1.5 1.5 0 00-.963 2.651l5.447 4.514-2.285 6.859a1.5 1.5 0 002.309 1.685l6.233-4.57a1 1 0 00.408-.807V1.249a1 1 0 00-.999-1zm-1 17.2a.252.252 0 01-.1.2l-3.822 2.8a.25.25 0 01-.385-.281l1.755-5.268a1 1 0 00-.31-1.086L9.1 10.192a.25.25 0 01.159-.443h5.07a1 1 0 00.943-.668l1.057-2.994z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});