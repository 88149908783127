define("ember-svg-jar/inlined/video-game-mario-flame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-mario-flame</title><path d=\"M3.25 5.75v-.5A.25.25 0 013.5 5H4a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H1A.75.75 0 001 5h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0zM23 1.5h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25H20A.75.75 0 0020 3h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5A.25.25 0 0122.5 3h.5a.75.75 0 000-1.5zM21.491 10.8a.5.5 0 00-.465.1 8.7 8.7 0 01-1.6 1.078.5.5 0 01-.729-.4A13.16 13.16 0 008.008.006a.5.5 0 00-.491.213.5.5 0 00-.017.535A9.612 9.612 0 018.75 5.5a9.065 9.065 0 01-.444 2.806.5.5 0 01-.906.094 8.725 8.725 0 01-.77-1.822.5.5 0 00-.8-.242A10 10 0 1022.25 14a9.856 9.856 0 00-.422-2.865.5.5 0 00-.337-.335zM12.25 21.5a5 5 0 115-5 5 5 0 01-5 5z\"/><path d=\"M10.75 14.75a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zM13.75 14.75a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});