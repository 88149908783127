define("ember-svg-jar/inlined/audio-document-mid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-document-mid</title><path d=\"M16.455 9.819a.25.25 0 00-.33.24v3.882a.25.25 0 00.33.24A2.237 2.237 0 0017.875 12a2.237 2.237 0 00-1.42-2.181z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM10.125 15a.625.625 0 01-1.25 0v-3.032c0-.1-.121-.078-.159 0l-.157.314a.65.65 0 01-1.118 0s-.1-.2-.148-.3-.168-.085-.168 0V15a.625.625 0 01-1.25 0V9a.625.625 0 011.184-.279s.718 1.428.779 1.558a.178.178 0 00.324 0l.779-1.557A.625.625 0 0110.125 9zm3.25-.625h.125a.625.625 0 010 1.25h-2a.625.625 0 010-1.25h.125a.25.25 0 00.25-.25v-4.25a.25.25 0 00-.25-.25H11.5a.625.625 0 010-1.25h2a.625.625 0 010 1.25h-.125a.25.25 0 00-.25.25v4.25a.25.25 0 00.25.25zm2.125 1.25a.625.625 0 01-.625-.625V9a.625.625 0 01.625-.625 3.625 3.625 0 010 7.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});