define("ember-svg-jar/inlined/music-genre-team-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>music-genre-team-alternate</title><path d=\"M11.321 3.308a.252.252 0 01.179-.238l3-.957a.25.25 0 01.326.238V4.11a.251.251 0 01-.188.242 1.75 1.75 0 102.188 1.694V2A2 2 0 0014.451.035c-.041.008-3.57 1.14-3.57 1.14a2 2 0 00-1.56 1.951V6.11a.251.251 0 01-.188.242 1.75 1.75 0 102.188 1.694z\"/><circle cx=\"3.535\" cy=\"16\" r=\"2.5\"/><path d=\"M2.143 19.75A4.01 4.01 0 00.335 21.1a.25.25 0 00.2.4h6a.25.25 0 00.2-.4 4 4 0 00-4.592-1.35z\"/><circle cx=\"20.465\" cy=\"16\" r=\"2.5\"/><path d=\"M21.857 19.75a4 4 0 00-4.592 1.35.25.25 0 00.2.4h6a.25.25 0 00.2-.4 4.01 4.01 0 00-1.808-1.35zM12 10.5a5.006 5.006 0 00-5 5c0 4.32 4.179 5 5 5 .837 0 5-.718 5-5a5 5 0 00-5-5zm0 8a3 3 0 01-3-3 2.959 2.959 0 01.157-.954.249.249 0 01.355-.14 9.084 9.084 0 004.321 1.088c.371 0 .677-.023.886-.043a.25.25 0 01.274.266A3.005 3.005 0 0112 18.5zM17.06 23.09a8.845 8.845 0 00-10.119 0 .5.5 0 00.287.91h9.545a.5.5 0 00.287-.91z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});