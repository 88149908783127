define("ember-svg-jar/inlined/smart-watch-square-cash-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-cash-alternate</title><path d=\"M18 3h-1.078a.249.249 0 01-.24-.182L15.98.364A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.362l-.7 2.456A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3V6a3 3 0 00-3-3zm1 15a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M14.591 13.91a2.08 2.08 0 00-1.315-1.942l-2.064-.825A.592.592 0 0111.433 10h2.033a.75.75 0 000-1.5h-.5a.25.25 0 01-.25-.25V8a.75.75 0 10-1.5 0v.3a.248.248 0 01-.2.244 2.092 2.092 0 00-.36 3.992l2.063.825A.592.592 0 0112.5 14.5h-2.034a.75.75 0 100 1.5h.5a.249.249 0 01.25.25v.25a.75.75 0 001.5 0v-.3a.248.248 0 01.2-.244 2.1 2.1 0 001.675-2.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});