define("ember-svg-jar/inlined/single-neutral-actions-alarm.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-alarm</title><path d=\"M12 15.5a5.975 5.975 0 011.556-4.024.249.249 0 00-.09-.4 61.615 61.615 0 00-1.967-.748l-.629-.228a.589.589 0 01-.138-.31 2.632 2.632 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.5 2.5 0 01.072 1.317.609.609 0 01-.134.308l-.632.233c-2.446.9-4.214 1.549-4.753 2.626A9.167 9.167 0 000 16.5a.5.5 0 00.5.5h11.25a.25.25 0 00.25-.25zM23.658 19.883a4.085 4.085 0 01-1.158-2.716V15.5a4.5 4.5 0 00-9 0v1.667a4.08 4.08 0 01-1.158 2.715l-.242.318a.5.5 0 00.4.8h11a.5.5 0 00.4-.8zM19.682 22.193a.5.5 0 00-.4-.193h-2.568a.5.5 0 00-.4.193.507.507 0 00-.089.432 1.827 1.827 0 003.541 0 .507.507 0 00-.084-.432z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});