define("ember-svg-jar/inlined/monitor-transfer-folder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-transfer-folder</title><path d=\"M9.625 7.375h-.7a3.994 3.994 0 011.628-1.9.65.65 0 00.325-.55 1.119 1.119 0 00-1.794-.885 6.021 6.021 0 00-2.262 3.335h-1.2a1 1 0 00-.707 1.707l2 2a1 1 0 001.416 0l2-2a1 1 0 00-.707-1.707zM22.875 1.625h-4.25a.25.25 0 01-.25-.25v-.25a1 1 0 00-1-1h-3.5a1 1 0 00-1 1v7a1 1 0 001 1h9a1 1 0 001-1v-5.5a1 1 0 00-1-1z\"/><path d=\"M16.481 11.125a.986.986 0 00-.356.75v5.25a.25.25 0 01-.25.25h-13.5a.25.25 0 01-.25-.25v-6.25a.25.25 0 01.25-.25h.25a.987.987 0 00.968-.841.953.953 0 00.032-.159.983.983 0 00-.455-.817.976.976 0 00-.545-.183h-.5a2 2 0 00-2 2v8.25a2 2 0 002 2h5.75a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-2.75a1 1 0 000 2h8a1 1 0 000-2h-2.75a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25h5.75a2 2 0 002-2v-7a.987.987 0 00-1.644-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});