define("ember-svg-jar/inlined/single-woman-actions-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-email</title><path d=\"M10.25 17.5a.25.25 0 00.25-.25V15.5A2.5 2.5 0 0113 13h1.3a.25.25 0 00.094-.482c-.852-.345-1.843-.7-2.922-1.1a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.374 6.374 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.145 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.249.249 0 01.253.25v.462a.778.778 0 01-.512.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126zM24 18.261a.25.25 0 00-.383-.212l-4.425 3.078a2.26 2.26 0 01-2.384 0l-4.426-3.078a.247.247 0 00-.253-.007.249.249 0 00-.129.219V22.5a1.5 1.5 0 001.5 1.5h9a1.5 1.5 0 001.5-1.5z\"/><path d=\"M24 16a1.5 1.5 0 00-1.5-1.5h-9A1.5 1.5 0 0012 16a.073.073 0 00.035.063l5.565 3.792a.752.752 0 00.794 0l5.568-3.792A.075.075 0 0024 16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});