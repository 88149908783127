define("ember-svg-jar/inlined/shopping-cart-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-woman-1</title><path d=\"M13.5 8.5a1 1 0 00-1-1h-1a2.236 2.236 0 00-2.184 1.822l-.235 1.468a.249.249 0 01-.246.21H1.372A1.374 1.374 0 000 12.372a1.352 1.352 0 00.058.394l1.319 4.4A1.18 1.18 0 002.5 18h5.134a.251.251 0 01.247.29l-.164 1.025a.219.219 0 01-.217.185h-5a1 1 0 000 2h5a2.234 2.234 0 002.183-1.822l1.6-9.993A.219.219 0 0111.5 9.5h1a1 1 0 001-1z\"/><circle cx=\"2.5\" cy=\"23\" r=\"1\"/><circle cx=\"8\" cy=\"23\" r=\"1\"/><path d=\"M17.562 2.7a.5.5 0 01.414-.22.5.5 0 01.424.22c.034.047.892 1.24 3.4 1.3a.19.19 0 00.139-.057.187.187 0 00.053-.14A4 4 0 0014 3.765a.22.22 0 00.061.165.223.223 0 00.159.07c2.474-.073 3.306-1.251 3.342-1.3z\"/><path d=\"M18.138 3.841a.25.25 0 00-.313 0 5.708 5.708 0 01-3.388 1.147.252.252 0 00-.224.333 4 4 0 007.552 0 .25.25 0 00-.223-.333 5.777 5.777 0 01-3.404-1.147z\"/><path d=\"M23.98 18.361s-1.01-3.5-2.01-7a4.145 4.145 0 00-7.961 0c-1 3.5-1.991 7-1.991 7A.5.5 0 0012.5 19h2.374a.25.25 0 01.245.2L16 23.6a.5.5 0 00.49.4h3a.5.5 0 00.49-.4l.88-4.4a.25.25 0 01.24-.2h2.4a.5.5 0 00.48-.639z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});