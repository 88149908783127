define("ember-svg-jar/inlined/igloo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>igloo</title><path d=\"M12 4A12.013 12.013 0 000 16v2.522A1.48 1.48 0 001.478 20h21.044A1.48 1.48 0 0024 18.522V16A12.013 12.013 0 0012 4zm8.355 6.75a.249.249 0 01.214.121 9.935 9.935 0 011.088 2.565.25.25 0 01-.241.314H17a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25zm-7.6-4.439a.251.251 0 01.276-.249 9.957 9.957 0 015.907 2.758.25.25 0 01-.174.43H13a.25.25 0 01-.25-.25zm-1.776-.249a.252.252 0 01.276.249V9a.25.25 0 01-.25.25H5.236a.25.25 0 01-.174-.43 9.957 9.957 0 015.912-2.758zm-7.548 4.809a.25.25 0 01.214-.121H7a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H2.584a.25.25 0 01-.241-.314 9.935 9.935 0 011.088-2.565zm.819 6.879A.25.25 0 014 18H2.25a.25.25 0 01-.25-.25V16c0-.174.009-.346.021-.517a.249.249 0 01.249-.233H4a.25.25 0 01.25.25zm4-1.25v1.25A.25.25 0 018 18H6a.25.25 0 01-.25-.25V15.5a.25.25 0 01.25-.25h2.139a.249.249 0 01.241.316 3.552 3.552 0 00-.13.934zM10 18a.25.25 0 01-.25-.25V16.5a2.25 2.25 0 014.5 0v1.25A.25.25 0 0114 18zm5.25-4.5a.25.25 0 01-.25.25h-.373a.248.248 0 01-.164-.062 3.7 3.7 0 00-4.926 0 .248.248 0 01-.164.062H9a.25.25 0 01-.25-.25V11a.25.25 0 01.25-.25h6a.25.25 0 01.25.25zm3 4.25A.25.25 0 0118 18h-2a.25.25 0 01-.25-.25V16.5a3.552 3.552 0 00-.13-.934.249.249 0 01.241-.316H18a.25.25 0 01.25.25zm3.75 0a.25.25 0 01-.25.25H20a.25.25 0 01-.25-.25V15.5a.25.25 0 01.25-.25h1.73a.25.25 0 01.249.233c.012.171.021.343.021.517z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});