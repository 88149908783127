define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>filter</title><path d=\"M20.59 9.328a6.057 6.057 0 00.91-2.919v-2.25c0-1.246-2.515-4.066-10-4.066s-10 2.817-10 4.066v2.25a6.024 6.024 0 001.562 3.767l6.438 6.44v5.793a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-2.516a6.481 6.481 0 007.09-10.565zM11.5 2.093c4.963 0 8 1.559 8 2.407s-3.037 2.409-8 2.409-8-1.559-8-2.409 3.037-2.407 8-2.407zM16 18.409a4.53 4.53 0 01-2.374-.677.251.251 0 01-.126-.217v-.8a.251.251 0 01.073-.177l5.5-5.5a.251.251 0 01.369.016 4.45 4.45 0 011.058 2.855 4.505 4.505 0 01-4.5 4.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});