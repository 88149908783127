define("ember-svg-jar/inlined/road-sign-divided-highway-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-ahead-alternate</title><path d=\"M13 6.423h-2.5a.25.25 0 00-.25.25v2.75a1.5 1.5 0 103 0v-2.75a.25.25 0 00-.25-.25zM8.567 12.1a.249.249 0 01-.067-.17v-.789a.25.25 0 01.188-.242.749.749 0 00.342-1.256l-1-1a.749.749 0 00-1.06 0l-1 1a.749.749 0 00.342 1.257.25.25 0 01.188.242v1.282a.991.991 0 00.27.682l3.413 3.65a.25.25 0 01.067.171V18.5a1 1 0 002 0v-1.87a1.467 1.467 0 00-.416-1.036zM18.179 13.2a.253.253 0 01-.189-.218 1.369 1.369 0 00-.376-.85L16.31 10.6a.248.248 0 01-.06-.162V8.423a1 1 0 00-2 0v2.293a1.437 1.437 0 00.386 1l1.025 1.2a.249.249 0 01-.059.376.729.729 0 00-.295.343.749.749 0 00.163.817l1 1a.748.748 0 001.06 0l1-1a.749.749 0 00-.351-1.258z\"/><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});