define("ember-svg-jar/inlined/card-game-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>card-game-cards</title><circle cx=\"5.261\" cy=\"3.609\" r=\"1\"/><path d=\"M22.272 9.373a1.529 1.529 0 00-.827-2L15.4 4.872a.249.249 0 01-.155-.231V1.529A1.531 1.531 0 0013.719 0H3.14a1.531 1.531 0 00-1.529 1.531v14.812a1.531 1.531 0 001.53 1.529h.522a.251.251 0 01.246.294 1.528 1.528 0 00.922 1.671l9.774 4.047a1.528 1.528 0 002-.827zM3.611 2.252A.25.25 0 013.861 2H13a.249.249 0 01.25.25v1.356a.25.25 0 01-.345.231l-1.232-.51a1.53 1.53 0 00-2 .827L4.884 15.717a.251.251 0 01-.231.154h-.79a.251.251 0 01-.25-.25zm11.422 19.372a.25.25 0 01-.327.135l-8.442-3.5a.25.25 0 01-.135-.327l5.116-12.347a.249.249 0 01.326-.135l8.442 3.5a.25.25 0 01.135.136.244.244 0 010 .191z\"/><path d=\"M15.517 14L15 11.383a1.029 1.029 0 00-1.582-.655L11.2 12.212a1.026 1.026 0 00-.436 1.055l.518 2.618a1.028 1.028 0 00.615.751 1.027 1.027 0 00.967-.095l2.218-1.485A1.027 1.027 0 0015.517 14z\"/><circle cx=\"12.25\" cy=\"7.5\" r=\"1\"/><circle cx=\"14.19\" cy=\"19.5\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});