define("ember-svg-jar/inlined/crime-seal-forfeit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-seal-forfeit</title><path d=\"M21.968 22.2L20.9 16.686a1.508 1.508 0 00-1.472-1.186H4.571A1.512 1.512 0 003.1 16.7l-1.067 5.486A1.5 1.5 0 003.5 24h17a1.5 1.5 0 001.468-1.8zM7 22.25H5.5a.75.75 0 010-1.5H7a.75.75 0 010 1.5zm5-.75c-1.657 0-3-.784-3-1.75S10.343 18 12 18s3 .784 3 1.75-1.344 1.75-3 1.75zm6.5-2.75H17a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zM5.767 11a.25.25 0 00-.247.286A2 2 0 007.5 13h9a2 2 0 001.979-1.714.25.25 0 00-.058-.2.254.254 0 00-.189-.086zM4.879 9.869H19.12a.374.374 0 00.373-.367c0-.2.007-1.383.007-1.672a1.119 1.119 0 00-1.119-1.119h-3.77a1.12 1.12 0 01-1.12-1.121 1.929 1.929 0 01.477-1.272 2.61 2.61 0 10-3.937 0 1.929 1.929 0 01.477 1.272 1.12 1.12 0 01-1.12 1.121h-3.77A1.118 1.118 0 004.5 7.83c0 .289 0 1.474.006 1.672a.374.374 0 00.373.367z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});