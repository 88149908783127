define("ember-svg-jar/inlined/real-estate-sign-building-rent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-sign-building-rent</title><path d=\"M8.5 12.5a.5.5 0 01.5.5v1a.5.5 0 00.5.5H11a.5.5 0 00.5-.5V6.5a.5.5 0 00-.276-.448l-3-1.5a.5.5 0 00-.448 0l-3 1.5A.5.5 0 004.5 6.5V14a.5.5 0 00.5.5h1.5A.5.5 0 007 14v-1a.5.5 0 01.5-.5zM6.5 7h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 3.5a.5.5 0 010-1h3a.5.5 0 010 1zM13 14.5h6a.5.5 0 00.5-.5V3a.5.5 0 00-.308-.462l-6-2.5A.5.5 0 0012.5.5V14a.5.5 0 00.5.5zm1.5-10h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 2.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zm0 2.5h3a.5.5 0 010 1h-3a.5.5 0 010-1zM16 16.5a.75.75 0 00-.75.75v1.764a.25.25 0 01-.474.112l-1.105-2.211a.75.75 0 00-1.421.335v6a.75.75 0 001.5 0v-1.764a.25.25 0 01.474-.112l1.105 2.211A.75.75 0 0016 24a.723.723 0 00.172-.021.748.748 0 00.578-.729v-6a.75.75 0 00-.75-.75zM9 18h2a.75.75 0 000-1.5H9a1.752 1.752 0 00-1.75 1.75v4A1.752 1.752 0 009 24h2a.75.75 0 000-1.5H9a.25.25 0 01-.25-.25v-1A.25.25 0 019 21h1a.75.75 0 000-1.5H9a.25.25 0 01-.25-.25v-1A.25.25 0 019 18zM2.25 23.25a.75.75 0 001.5 0v-.586a.25.25 0 01.427-.177L5.47 23.78a.75.75 0 001.06-1.06L5.1 21.291a.25.25 0 01.1-.414 2.244 2.244 0 00-.7-4.377H3a.75.75 0 00-.75.75zM4.5 19.5H4a.25.25 0 01-.25-.25v-1A.25.25 0 014 18h.5a.75.75 0 010 1.5zM21 16.5h-3a.75.75 0 000 1.5h.5a.25.25 0 01.25.25v5a.75.75 0 001.5 0v-5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});