define("ember-svg-jar/inlined/file-dat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-dat-1</title><path d=\"M9.5 12.126a.876.876 0 00-.875.874v.625a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V13a.876.876 0 00-.875-.874zM3.956 12.32a.249.249 0 00-.331.24v3.882a.249.249 0 00.331.24A2.238 2.238 0 005.375 14.5a2.237 2.237 0 00-1.419-2.18z\"/><path d=\"M20 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h18a1 1 0 001-1zm-17-.874a.625.625 0 01-.625-.625v-6A.625.625 0 013 10.876a3.625 3.625 0 010 7.25zm8.625-.625a.625.625 0 01-1.25 0v-2.125a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-4.374a2.212 2.212 0 011.9-2.239A2.128 2.128 0 0111.625 13zM17 12.126h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 11-1.25 0v-5.124a.25.25 0 00-.25-.25H13a.625.625 0 010-1.25h4a.625.625 0 010 1.25z\"/><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});