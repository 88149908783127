define("ember-svg-jar/inlined/business-paper-boat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-paper-boat</title><path d=\"M7.693 9.239a.252.252 0 00.187.152l4.03.733a.49.49 0 00.179 0l4.031-.733A.251.251 0 0016.28 9l-3.417-4.839a1.11 1.11 0 00-1.731.008L7.72 9a.25.25 0 00-.027.239zM18.738 20.1a.25.25 0 00-.042-.269l-6.51-7.253A.246.246 0 0012 12.5a.249.249 0 00-.186.083L5.3 19.833a.25.25 0 00.186.417H18.51a.252.252 0 00.228-.15zM20.245 19.143a.251.251 0 00.189-.143l3.477-7.822a1.04 1.04 0 00-1.136-1.448l-9.129 1.66a.25.25 0 00-.142.413l6.516 7.259a.253.253 0 00.225.081zM.09 11.175l3.474 7.817a.25.25 0 00.415.066L10.5 11.8a.25.25 0 00-.141-.413L1.226 9.729A1.041 1.041 0 00.09 11.175z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});