define("ember-svg-jar/inlined/smiley-wink-alternate-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-wink-alternate-1</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 7a2 2 0 11-2 2 2 2 0 012-2zm-.941 8.709a.438.438 0 01.379-.22h9.123a.439.439 0 01.38.658 5.706 5.706 0 01-9.882 0 .436.436 0 010-.438zm11.587-5.274a1 1 0 01-1.29-.58A1.207 1.207 0 0016.291 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0 1 1 0 01-.58 1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});