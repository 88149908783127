define("ember-svg-jar/inlined/navigation-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-down</title><path d=\"M11.223 23.6l-9.988-7.99A1.25 1.25 0 012 13.371h1.5a.25.25 0 00.25-.25V1.371A1.251 1.251 0 015 .121h8.752a.25.25 0 01.25.25v4a2.5 2.5 0 002.5 2.5H20a.25.25 0 01.25.25v6a.25.25 0 00.25.25H22a1.255 1.255 0 011.248 1.256 1.231 1.231 0 01-.474.973l-9.983 7.986a1.227 1.227 0 01-1.568.014z\"/><path d=\"M16.5 5.873a1.5 1.5 0 01-1.5-1.5V.971A.251.251 0 0115.438.8l4.237 4.649a.251.251 0 01-.184.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});