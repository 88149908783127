define("ember-svg-jar/inlined/single-man-actions-refresh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-refresh</title><path d=\"M12.542 11.233a.25.25 0 00-.056-.426l-1.335-.572a.25.25 0 01-.151-.23V8.87a.251.251 0 01.073-.177 3.3 3.3 0 00.779-2.23.248.248 0 01.089-.177 1.876 1.876 0 00.537-1.4 1.58 1.58 0 00-.316-1.015.248.248 0 01-.034-.265 2.528 2.528 0 00.156-1.726C11.943.492 10.241 0 8.887 0a3.75 3.75 0 00-3.162 1.324.253.253 0 01-.213.118 1.073 1.073 0 00-.9.375c-.372.493-.1 1.171.176 1.732a.251.251 0 01-.045.288 1.2 1.2 0 00-.162.2A1.766 1.766 0 004.319 5a1.524 1.524 0 00.639 1.331.252.252 0 01.117.194A3.158 3.158 0 005.92 8.7a.25.25 0 01.08.183v1.122a.251.251 0 01-.152.23l-3.936 1.687C.639 12.468.457 13.626.205 15.23c-.056.359-.117.751-.2 1.179a.5.5 0 00.106.411A.5.5 0 00.5 17h8.793a.248.248 0 00.248-.227 7.99 7.99 0 013.001-5.54zM23.391 19.871a1 1 0 10-1.892-.648 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.721-1.721a.5.5 0 00-.353-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.969a.249.249 0 01.359 0 6.085 6.085 0 0010.209-2.2zM23.691 11.772a.5.5 0 00-.545.109l-1.025 1.025a.249.249 0 01-.358 0 6.1 6.1 0 00-10.2 2.227 1 1 0 001.893.648 4.09 4.09 0 016.894-1.445.25.25 0 01-.006.347l-1.66 1.659a.5.5 0 00.354.853H23.5a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});