define("ember-svg-jar/inlined/transportation-ticket-car-transfer-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-car-transfer-bus</title><path d=\"M16.4 9.791a.253.253 0 01.023.2 6.187 6.187 0 01-.9.152.734.734 0 00-.379.39.752.752 0 00.162.818l2.264 1.5a.751.751 0 001.06 0l1.5-1.5a.75.75 0 00-.53-1.281h-.72a.248.248 0 01-.234-.164A6.979 6.979 0 0012.1 5.323a1 1 0 000 2 4.986 4.986 0 014.3 2.468zM11.094 17.223a5 5 0 01-3.61-2.977.125.125 0 01.115-.173.75.75 0 00.53-1.28l-1.5-1.5a.749.749 0 00-1.06 0l-1.5 1.5a.75.75 0 00.53 1.28h.545a.251.251 0 01.24.181 6.983 6.983 0 005.31 4.928 1.07 1.07 0 00.2.02 1 1 0 00.2-1.979zM23.815 19.2a.722.722 0 00-.1-.154l-.7-.81a.254.254 0 01-.058-.133l-.328-2.644A1.744 1.744 0 0020.9 14h-5.3a1.751 1.751 0 00-1.73 1.494l-.324 2.613a.247.247 0 01-.058.132l-.7.811a.722.722 0 00-.1.154 1.753 1.753 0 00-.185.782v.514a1.746 1.746 0 00.875 1.509.25.25 0 01.125.216v1.025a.75.75 0 001.5 0v-.75a.25.25 0 01.25-.25h6a.25.25 0 01.25.25v.75a.75.75 0 001.5 0v-1.025a.25.25 0 01.125-.216A1.746 1.746 0 0024 20.5v-.514a1.753 1.753 0 00-.185-.786zm-8.682-1.735l.216-1.75a.25.25 0 01.245-.219H20.9a.249.249 0 01.247.219l.217 1.75a.257.257 0 01-.061.2.253.253 0 01-.187.084h-5.735a.253.253 0 01-.187-.084.257.257 0 01-.061-.196zm-.025 3.448a.75.75 0 11.75-.75.75.75 0 01-.75.754zm6.25 0a.75.75 0 11.75-.75.75.75 0 01-.75.754zM6 9.75v.245a.75.75 0 001.5 0v-.52a.25.25 0 01.124-.216A1.745 1.745 0 008.5 7.75v-6A1.752 1.752 0 006.75 0h-5A1.752 1.752 0 000 1.75v6a1.745 1.745 0 00.875 1.508.252.252 0 01.125.217v.52a.75.75 0 001.5 0V9.75a.249.249 0 01.25-.25h3a.25.25 0 01.25.25zm-4.5-8a.247.247 0 01.25-.25h5a.247.247 0 01.25.25V4.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25zm.75 5.75A.75.75 0 113 6.745a.75.75 0 01-.75.755zm3.25-.75a.75.75 0 11.75.75.75.75 0 01-.75-.755z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});