define("ember-svg-jar/inlined/style-two-pin-church", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-church</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm5.781 10.587a.5.5 0 01-.281.913h-1a.5.5 0 00-.5.5v3.5a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2a1.5 1.5 0 00-3 0v2a.5.5 0 01-.5.5H8.5a.5.5 0 01-.5-.5V12a.5.5 0 00-.5-.5h-1a.5.5 0 01-.281-.913l4.813-3.282a.5.5 0 00.218-.413V6.5a.5.5 0 00-.5-.5h-.5a.75.75 0 010-1.5h.5a.5.5 0 00.5-.5v-.5a.75.75 0 011.5 0V4a.5.5 0 00.5.5h.5a.75.75 0 010 1.5h-.5a.5.5 0 00-.5.5v.392a.5.5 0 00.218.413z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});