define("ember-svg-jar/inlined/single-woman-megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-megaphone</title><path d=\"M23-.006a1 1 0 00-1 1v.547a.252.252 0 01-.192.243L12.77 3.929a.251.251 0 01-.3-.181 1 1 0 00-1.969.246v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.246a.251.251 0 01.192.244v.946a2.992 2.992 0 005.937.536.25.25 0 01.3-.2l1.568.372a.251.251 0 01.192.243v.547a1 1 0 002 0v-10A1 1 0 0023-.006zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.095-.2.247.247 0 01.213-.047l1.5.356a.247.247 0 01.192.244zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/><path d=\"M23-.006a1 1 0 00-1 1v.547a.252.252 0 01-.192.243L12.77 3.929a.251.251 0 01-.3-.181 1 1 0 00-1.969.246v4a1 1 0 001.969.246.25.25 0 01.3-.182l1.038.246a.251.251 0 01.192.244v.946a2.992 2.992 0 005.937.536.25.25 0 01.3-.2l1.568.372a.251.251 0 01.192.243v.547a1 1 0 002 0v-10A1 1 0 0023-.006zm-5 9.5a1 1 0 01-2 0v-.353a.25.25 0 01.095-.2.247.247 0 01.213-.047l1.5.356a.247.247 0 01.192.244zM11.982 17.868l-1.8-6.285a4.255 4.255 0 00-1.238-2.125.5.5 0 00-.764.1l-1.755 2.76a.5.5 0 01-.846 0L3.839 9.54a.5.5 0 00-.358-.23.5.5 0 00-.4.128 4.219 4.219 0 00-1.254 2.129l-1.8 6.3A.5.5 0 00.5 18.5h2.323a.251.251 0 01.246.2L4 23.6a.5.5 0 00.489.4h3a.5.5 0 00.49-.4l.931-4.9a.249.249 0 01.245-.2H11.5a.5.5 0 00.482-.632zM5.979 8.531a4.25 4.25 0 10-4.25-4.25 4.255 4.255 0 004.25 4.25zm0-2a2.249 2.249 0 01-2.16-1.64.249.249 0 01.16-.3A4.67 4.67 0 005.8 3.447a.25.25 0 01.357 0 4.67 4.67 0 001.821 1.14.249.249 0 01.159.3 2.247 2.247 0 01-2.158 1.644z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});