define("ember-svg-jar/inlined/road-sign-cattle-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-cattle-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M14.954 11.324a.25.25 0 01-.071-.209l.02-.158a.249.249 0 01.183-.21 2.251 2.251 0 001.664-2.17v-.5a.75.75 0 10-1.5 0v.5a.751.751 0 01-.75.75h-.164a.251.251 0 01-.128-.035 1.5 1.5 0 00-.765-.215h-2.886a1.5 1.5 0 00-.765.215.251.251 0 01-.128.035H9.5a.751.751 0 01-.75-.75v-.5a.75.75 0 10-1.5 0v.5a2.252 2.252 0 001.663 2.17.249.249 0 01.183.21l.02.16a.246.246 0 01-.071.208l-1.9 1.9a.5.5 0 00-.089.585 1.532 1.532 0 001.411.691 3.118 3.118 0 00.78-.112.25.25 0 01.311.21l.39 3.062a1.5 1.5 0 001.5 1.417h1.108a1.5 1.5 0 001.5-1.4l.39-3.081a.252.252 0 01.312-.211 3.1 3.1 0 00.779.112 1.529 1.529 0 001.411-.691.5.5 0 00-.089-.585z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});