define("ember-svg-jar/inlined/police-woman-spy-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>police-woman-spy-1</title><path d=\"M2.842 15.505a.5.5 0 00-.231.789A5.053 5.053 0 006.151 18a2.135 2.135 0 001.8-.842 6.741 6.741 0 004.11 2.094 6.7 6.7 0 004.039-2.021 2.156 2.156 0 001.75.769 5.055 5.055 0 003.54-1.7.5.5 0 00-.231-.789 3.844 3.844 0 01-2.092-2.733 3.143 3.143 0 00.246-1.133v-.3a2.046 2.046 0 00-2-2.084h-2.5a2.01 2.01 0 00-1.91 1.5h-1.68a2.01 2.01 0 00-1.91-1.5h-2.5a2.046 2.046 0 00-2 2.084v.3a3.117 3.117 0 00.175.948c-.348 1.226-.983 2.526-2.146 2.912zm6.971-4.171a1.841 1.841 0 01-1.75 1.916 1.841 1.841 0 01-1.75-1.916.547.547 0 01.5-.584h2.5a.548.548 0 01.5.584zm2.25 6.416a5.531 5.531 0 01-3.968-3 3.276 3.276 0 003.084-2.5h1.768a3.276 3.276 0 003.085 2.5 5.53 5.53 0 01-3.969 3zm5.25-7a.548.548 0 01.5.584 1.841 1.841 0 01-1.75 1.916 1.841 1.841 0 01-1.75-1.916.547.547 0 01.5-.584zM1.9 7.372a46.851 46.851 0 0010.163 1.109 46.838 46.838 0 0010.163-1.109.75.75 0 00-.326-1.464c-1.194.266-2.4.465-3.616.632l-1.648-5.213A2 2 0 0013.761.264L12.56.95a1.007 1.007 0 01-.993 0l-1.2-.686a2 2 0 00-2.882 1.081L5.842 6.54a44.43 44.43 0 01-3.616-.632.75.75 0 10-.326 1.464zM23.984 23.374A4.507 4.507 0 0021.2 20.29l-4.45-1.755a.5.5 0 00-.681.419l-.413 4.5a.5.5 0 00.5.546H23.5a.5.5 0 00.484-.626zM12.439 20.511a.52.52 0 00-.877 0l-1.5 2.75A.5.5 0 0010.5 24h3a.5.5 0 00.439-.739z\"/><path d=\"M12.8 14.329a1.964 1.964 0 01-1.625 0 .751.751 0 00-.721 1.317 3.433 3.433 0 003.067 0 .751.751 0 00-.721-1.317zM7.815 18.575a.5.5 0 00-.444-.041l-4.515 1.751a4.536 4.536 0 00-2.839 3.087A.5.5 0 00.5 24h7.472a.5.5 0 00.5-.546l-.42-4.5a.5.5 0 00-.237-.379z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});