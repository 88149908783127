define("ember-svg-jar/inlined/rain-umbrella-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rain-umbrella-boots</title><path d=\"M15 14.5a2.5 2.5 0 005 0 1 1 0 00-2 0 .5.5 0 01-1 0v-3.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25zM15 1.345a.25.25 0 01-.212.247A8.013 8.013 0 008 9.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5 8.013 8.013 0 00-6.788-7.908.25.25 0 01-.212-.247V1a1 1 0 10-2 0zM12.052 18.634a4.747 4.747 0 00-4.617-.73.25.25 0 01-.335-.252l.137-1.884a.25.25 0 00-.25-.268H5a.5.5 0 010-1h2.1a.251.251 0 00.25-.232l.12-1.651A1.5 1.5 0 005.971 11H1.5A1.5 1.5 0 000 12.5v10A1.5 1.5 0 001.5 24H12a.5.5 0 00.432-.248c1.168-2.005 1.027-3.918-.38-5.118z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});