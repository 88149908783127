define("ember-svg-jar/inlined/waiting-room-clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>waiting-room-clock</title><path d=\"M18.749 0A5.25 5.25 0 1024 5.249 5.255 5.255 0 0018.749 0zm0 9a3.75 3.75 0 113.75-3.75A3.754 3.754 0 0118.749 9z\"/><path d=\"M19.5 4.939V3.767a.75.75 0 10-1.5 0v1.482a.749.749 0 00.22.531l.844.843a.75.75 0 001.06-1.06z\"/><circle cx=\"5.501\" cy=\"4.535\" r=\"2.5\"/><path d=\"M15.853 18.4a4.521 4.521 0 00-4.453-3.866H9.843A1.5 1.5 0 018.387 13.4l-.931-3.729a1.5 1.5 0 10-2.91.726l.931 3.728a4.493 4.493 0 004.366 3.408H11.4a1.507 1.507 0 011.484 1.288l.631 3.888a1.5 1.5 0 002.971-.424z\"/><path d=\"M10.639 20.884a1 1 0 00-.948-.86 6.962 6.962 0 01-6.651-5.292c-.383-1.52-.864-3.476-.864-3.476a1 1 0 00-.97-.757H.5A.5.5 0 000 11v12a1 1 0 001 1h9a1 1 0 00.991-1.143c-.022-.146-.225-1.057-.352-1.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});