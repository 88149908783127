define("ember-svg-jar/inlined/dial-pad-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dial-pad-alternate</title><path d=\"M3.316 0a3.316 3.316 0 103.315 3.315A3.319 3.319 0 003.316 0zm0 4.632a1.316 1.316 0 111.315-1.317 1.318 1.318 0 01-1.315 1.317zM11.964 0a3.316 3.316 0 103.316 3.315A3.319 3.319 0 0011.964 0zm0 4.632a1.316 1.316 0 111.316-1.317 1.319 1.319 0 01-1.316 1.317zM20.684 6.632a3.316 3.316 0 10-3.315-3.317 3.32 3.32 0 003.315 3.317zm0-4.632a1.316 1.316 0 11-1.315 1.315A1.317 1.317 0 0120.684 2zM3.316 8.685A3.315 3.315 0 106.631 12a3.319 3.319 0 00-3.315-3.315zm0 4.63A1.315 1.315 0 114.631 12a1.316 1.316 0 01-1.315 1.315zM11.964 8.685A3.315 3.315 0 1015.28 12a3.319 3.319 0 00-3.316-3.315zm0 4.63A1.315 1.315 0 1113.28 12a1.317 1.317 0 01-1.316 1.315zM20.684 8.685A3.315 3.315 0 1024 12a3.319 3.319 0 00-3.316-3.315zm0 4.63A1.315 1.315 0 1122 12a1.316 1.316 0 01-1.316 1.315zM11.964 17.368a3.316 3.316 0 103.316 3.317 3.321 3.321 0 00-3.316-3.317zm0 4.632a1.316 1.316 0 111.316-1.315A1.317 1.317 0 0111.964 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});