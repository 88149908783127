define("ember-svg-jar/inlined/app-window-graph-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-graph-1</title><path d=\"M3.55 13.751a1 1 0 001 1h2a1 1 0 00.874-.515l1.438-2.588a.248.248 0 01.215-.129.252.252 0 01.219.123l3.891 6.616a1 1 0 00.849.493h.014a1 1 0 00.847-.47l2.061-3.295a.5.5 0 01.424-.235h2.168a1 1 0 000-2h-3a1 1 0 00-.848.47L14.3 15.473a.251.251 0 01-.428-.006l-3.96-6.723a1 1 0 00-.862-.493h-.013a1 1 0 00-.861.514L6.1 12.494a.5.5 0 01-.437.257H4.55a1 1 0 00-1 1z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-9.5-.5a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.966.966 0 011.692 0 .969.969 0 01.154.5zm3.5 0a.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5.983.983 0 01.846-.5 1 1 0 01.908.591.987.987 0 01.092.409zM2 7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7v12.25a1 1 0 01-1 1H3a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});