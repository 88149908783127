define("ember-svg-jar/inlined/messages-bubble-subtract-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-subtract-1-alternate</title><path d=\"M9.76 15.49a.25.25 0 00-.4-.258.78.78 0 00-.068.061L7 17.586V15.56a1 1 0 00-.664-.942A6.493 6.493 0 018.5 2h7A6.508 6.508 0 0122 8.5a6.419 6.419 0 01-.314 1.974.249.249 0 00.1.287A8.171 8.171 0 0123 11.7a.248.248 0 00.225.062.252.252 0 00.179-.152A8.417 8.417 0 0024 8.5 8.51 8.51 0 0015.5 0h-7A8.492 8.492 0 005 16.232V20a1 1 0 001.707.707l2.733-2.733a.251.251 0 00.073-.194c-.006-.093-.013-.186-.013-.28a7.927 7.927 0 01.26-2.01z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 5.75a.75.75 0 010 1.5h-5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});