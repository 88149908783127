define("ember-svg-jar/inlined/chicken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chicken</title><path d=\"M12.974 3.548A4 4 0 009 0a1 1 0 000 2 2 2 0 011.95 1.555A10.014 10.014 0 002 13.5v10a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-10a10.013 10.013 0 00-9.026-9.952zM15.5 9.25a1.25 1.25 0 11-1.25 1.25 1.25 1.25 0 011.25-1.25zm-7 0a1.25 1.25 0 11-1.25 1.25A1.25 1.25 0 018.5 9.25zM12.007 13c1.074 0 3.441 1.348 4.672 2.785a.5.5 0 01-.328.822c-1.308.133-2.792.2-4.344.2-1.533 0-3.028-.073-4.341-.2a.5.5 0 01-.329-.823C8.569 14.347 10.934 13 12.007 13zm0 7.718A6.531 6.531 0 017.2 18.969a.5.5 0 01.423-.828c1.331.13 2.825.2 4.385.2s3.053-.069 4.386-.2a.5.5 0 01.424.828 6.534 6.534 0 01-4.811 1.749z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});