define("ember-svg-jar/inlined/style-three-pin-drink-food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-drink-food</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0 42.885 42.885 0 005.955-6.064 2.16 2.16 0 00.156-.2C20.955 14.942 22.5 12.14 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.933 10.75a1.906 1.906 0 010 1 .5.5 0 01-.433.25h-7a.5.5 0 01-.433-.25 1.906 1.906 0 010-1 1.5 1.5 0 011.3-.75h5.267a1.5 1.5 0 011.299.75zm-9.393.6a.5.5 0 01-.2.524 2 2 0 000 3.26.5.5 0 01.2.524.449.449 0 01-.44.342H6a.5.5 0 01-.5-.438l-1-8A.5.5 0 015 7h2.267a.5.5 0 00.487-.386l.4-1.686A2.49 2.49 0 0110.584 3h1.166a.5.5 0 010 1h-1.166a1.5 1.5 0 00-1.461 1.157l-.289 1.229A.5.5 0 009.321 7H11a.5.5 0 01.5.562l-.067.538a.5.5 0 01-.44.435A2.48 2.48 0 008.5 11a1.517 1.517 0 00.04.346zm9.393 4.9a1.5 1.5 0 01-1.3.75h-5.266a1.5 1.5 0 01-1.3-.75 1.906 1.906 0 010-1A.5.5 0 0110.5 15h7a.5.5 0 01.433.25 1.906 1.906 0 010 1zM18.5 14h-9a.5.5 0 010-1h9a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});