define("ember-svg-jar/inlined/subscript", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>subscript</title><path d=\"M14.707.421a1 1 0 00-1.414 0L7.677 6.037a.252.252 0 01-.354 0L1.707.421A1 1 0 00.293 1.835l5.616 5.616a.249.249 0 010 .353L.293 13.421a1 1 0 101.414 1.414l5.616-5.617a.252.252 0 01.354 0l5.616 5.617a1 1 0 001.414-1.414L9.091 7.8a.249.249 0 010-.353l5.616-5.616a1 1 0 000-1.41zM22.968 21.869h-2.549a.25.25 0 01-.205-.394L23.351 17a3.472 3.472 0 10-6.3-2.155 1 1 0 002 .084 1.477 1.477 0 112.658.938L17.2 22.3a1 1 0 00.82 1.573h4.95a1 1 0 100-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});