define("ember-svg-jar/inlined/road-sign-school-bus-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-school-bus-alternate</title><path d=\"M17.936 7.172h-10a1.752 1.752 0 00-1.75 1.75v3a.25.25 0 01-.25.25h-1a1.752 1.752 0 00-1.75 1.75v1a1.747 1.747 0 001.529 1.734.25.25 0 00.278-.2 2.485 2.485 0 014.889.013.25.25 0 00.245.2h2.618a.251.251 0 00.246-.2 2.484 2.484 0 014.888-.013.25.25 0 00.278.2 1.748 1.748 0 001.529-1.734v-6a1.752 1.752 0 00-1.75-1.75zM9.444 11.65a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h.25a1 1 0 011 1zm1.992.523a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M7.436 15.423a1.5 1.5 0 00-1.244 2.338 1.534 1.534 0 00.367.374 1.49 1.49 0 001.941-.152 1.45 1.45 0 00.183-.222 1.5 1.5 0 00-1.244-2.338zM15.436 15.423a1.5 1.5 0 00-1.244 2.338 1.534 1.534 0 00.367.374 1.49 1.49 0 001.938-.152 1.45 1.45 0 00.183-.222 1.5 1.5 0 00-1.244-2.338z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});