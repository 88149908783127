define("ember-svg-jar/inlined/award-ribbon-star-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-ribbon-star-3</title><path d=\"M12 18.5a9.917 9.917 0 01-4.146-.911.249.249 0 00-.354.227V23a1 1 0 001.708.707L12 20.913l2.792 2.792A1 1 0 0016.5 23v-5.185a.249.249 0 00-.354-.227A9.917 9.917 0 0112 18.5z\"/><path d=\"M12 0a8.5 8.5 0 108.5 8.5A8.509 8.509 0 0012 0zm0 3.5a5 5 0 11-5 5 5.005 5.005 0 015-5z\"/><path d=\"M10.127 9.105l-.653 1.5a.628.628 0 00.158.725.64.64 0 00.754.084l1.62-.912 1.621.912a.647.647 0 00.754-.084.627.627 0 00.157-.725l-.652-1.5 1.179-1.16a.632.632 0 00.151-.69.616.616 0 00-.584-.38h-1.34l-.709-1.523A.64.64 0 0012.007 5a.643.643 0 00-.577.351v.008l-.7 1.516H9.382a.617.617 0 00-.434 1.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});