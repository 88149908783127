define("ember-svg-jar/inlined/night-moon-gibbous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>night-moon-gibbous</title><path d=\"M17.124.42a1 1 0 00-.457-.212A11.717 11.717 0 006.9 2.713 12.261 12.261 0 002.5 12a12.01 12.01 0 004.351 9.234A11.7 11.7 0 0014.425 24a12.529 12.529 0 002.242-.2 1 1 0 00.457-.212C17.3 23.438 21.5 19.887 21.5 12S17.3.567 17.124.42zm-1.033 21.4a.25.25 0 01-.14.07 9.785 9.785 0 01-7.819-2.19A10.019 10.019 0 014.5 12a10.237 10.237 0 013.677-7.751A9.606 9.606 0 0114.4 2a10.591 10.591 0 011.549.116.245.245 0 01.14.071c.822.825 3.41 3.893 3.41 9.815S16.908 21 16.091 21.818z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});