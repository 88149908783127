define("ember-svg-jar/inlined/volleyball-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>volleyball-ball</title><path d=\"M16.4 15.112a.5.5 0 00-.172-.644 11.719 11.719 0 00-3.64-1.584.5.5 0 00-.481.141 13.373 13.373 0 01-10.464 4.12.248.248 0 00-.225.113.251.251 0 00-.013.251 12.037 12.037 0 003.385 4.028 1 1 0 00.725.2c4.507-.545 8.935-2.768 10.885-6.625z\"/><path d=\"M18.283 16.159a.5.5 0 00-.8.1c-1.947 3.468-5.55 5.616-9.444 6.554a.25.25 0 00-.025.478 11.952 11.952 0 0012.581-2.9.5.5 0 00.106-.541 11.727 11.727 0 00-2.418-3.691zM23.749 12.783c-2.725-3.635-6.864-6.358-11.178-6.115a.5.5 0 00-.471.471 11.7 11.7 0 00.448 3.944.5.5 0 00.363.346 13.371 13.371 0 018.8 7 .25.25 0 00.435.024 12.034 12.034 0 001.8-4.945 1 1 0 00-.197-.725z\"/><path d=\"M12.619 4.951a.5.5 0 00.4.2c3.978-.047 7.639 2 10.4 4.9a.25.25 0 00.427-.217 11.953 11.953 0 00-8.8-9.445.5.5 0 00-.521.179 11.707 11.707 0 00-1.99 3.942.5.5 0 00.084.441zM7.814 14.375a11.715 11.715 0 003.192-2.36.5.5 0 00.118-.487A13.372 13.372 0 0112.789.406.252.252 0 0012.8.154a.248.248 0 00-.211-.136 12.013 12.013 0 00-5.18.917 1 1 0 00-.534.53C5.093 5.642 4.8 10.588 7.171 14.2a.5.5 0 00.643.175z\"/><path d=\"M5.32 15.312a.5.5 0 00.309-.74C3.6 11.151 3.54 6.958 4.675 3.116a.25.25 0 00-.4-.261A11.95 11.95 0 00.5 15.2a.5.5 0 00.415.362 11.729 11.729 0 004.405-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});