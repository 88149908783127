define("ember-svg-jar/inlined/skating-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skating-1</title><circle cx=\"19\" cy=\"2.625\" r=\"2.5\"/><path d=\"M20.75 19.875a1 1 0 000 2H13a1 1 0 000 2h7.75a2 2 0 000-4z\"/><rect x=\"15\" y=\"19.125\" width=\"3\" height=\"1.75\" rx=\".25\" ry=\".25\"/><rect x=\"4.25\" y=\"14.142\" width=\"1.75\" height=\"3\" rx=\".25\" ry=\".25\"/><path d=\"M18 16.14a4.523 4.523 0 00-1.8-3.6l-1.281-.962L17.2 8.542a1.5 1.5 0 00-1.2-2.4H7.5a1.5 1.5 0 000 3h5a.25.25 0 01.2.4l-3 4a1.51 1.51 0 01-1.2.6H7.25a.25.25 0 00-.25.25v2.5a.25.25 0 00.25.25H8.5a4.527 4.527 0 003.6-1.8l1.021-1.362 1.278.96a1.509 1.509 0 01.6 1.2v1.735a.25.25 0 00.25.25h2.5a.25.25 0 00.25-.25zM3.25 12.142a1 1 0 00-2 0v7.75a2 2 0 004 0 1 1 0 00-2 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});