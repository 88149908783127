define("ember-svg-jar/inlined/multiple-actions-edit-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-2</title><path d=\"M18.7 10.485a.5.5 0 00.028-.736 6 6 0 00-6.708-1.214.5.5 0 00-.182.771A7.414 7.414 0 0113.5 13.5v.024A.5.5 0 0014 14h.968a.5.5 0 00.353-.147l3.15-3.149c.074-.076.153-.149.229-.219z\"/><ellipse cx=\"14.014\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-11.995-.266A.6.6 0 00.6 14h11.024a.378.378 0 00.376-.4z\"/><ellipse cx=\"5.514\" cy=\"3.501\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12.392 20.461a.5.5 0 00-.844.256l-.537 2.683a.5.5 0 00.588.588l2.685-.537a.5.5 0 00.255-.844zM19.528 11.765l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-3.707-3.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});