define("ember-svg-jar/inlined/shape-circle-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-circle-add</title><path d=\"M24 19a1 1 0 00-1-1h-2.75a.25.25 0 01-.25-.25V15a1 1 0 00-2 0v2.75a.25.25 0 01-.25.25H15a1 1 0 000 2h2.75a.25.25 0 01.25.25V23a1 1 0 002 0v-2.75a.25.25 0 01.25-.25H23a1 1 0 001-1zM10.5 19a8.5 8.5 0 118.5-8.5 1 1 0 002 0A10.5 10.5 0 1010.5 21a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});