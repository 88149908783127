define("ember-svg-jar/inlined/arrow-button-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-down</title><path d=\"M.249 7.207l10.984 12.471a1.066 1.066 0 001.539 0L23.751 7.207a.987.987 0 00-.107-1.414l-1.85-1.557a1.028 1.028 0 00-1.438.111L12.191 13.8a.25.25 0 01-.379 0L3.644 4.346A1.021 1.021 0 002.948 4a1 1 0 00-.741.238L.356 5.793A.988.988 0 000 6.478a.978.978 0 00.249.729z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});