define("ember-svg-jar/inlined/bandage-shoulder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bandage-shoulder</title><circle cx=\"10.853\" cy=\"2.5\" r=\"2.5\"/><path d=\"M8.912 6.875A.25.25 0 008.7 6.5h-.347a4 4 0 00-4 4 2.513 2.513 0 00.95 2.064.251.251 0 00.371-.073c.544-.946 2.569-4.456 3.238-5.616zM18.763 12.27a2 2 0 01-1.41-1.91.34.34 0 00-.01-.1 4 4 0 00-3.99-3.76h-2.935a.25.25 0 00-.239.177L9.373 9.3a.242.242 0 00.23.312 2.25 2.25 0 110 4.5H8.1a.25.25 0 00-.25.25v8.39a1.25 1.25 0 002.5 0V16.5a.5.5 0 011 0v6.25a1.25 1.25 0 002.5 0v-12.5a.5.5 0 011 0v.11a4.464 4.464 0 002.47 4.01 3.046 3.046 0 001.07.35 1.253 1.253 0 00.37-2.45z\"/><path d=\"M8.3 12.786a.251.251 0 00.239.324H9.6a1.25 1.25 0 100-2.5h-.445a.249.249 0 00-.239.176z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});