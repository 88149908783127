define("ember-svg-jar/inlined/laptop-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-star</title><path d=\"M9.6 15.485a.25.25 0 00-.144-.3 2.368 2.368 0 01-1.407-1.337.492.492 0 00-.47-.36H.5a.478.478 0 00-.41.22.467.467 0 00-.05.47 5.43 5.43 0 005 3.31h4.397a.249.249 0 00.205-.107.251.251 0 00.03-.23 2.727 2.727 0 01-.072-1.666zM3.5 12a1 1 0 001-1V3a1 1 0 011-1h12a1 1 0 011 1v6.543a.221.221 0 00.131.2 2.726 2.726 0 011.409 1.4.239.239 0 00.458-.081V3a3.009 3.009 0 00-3-3H5.5a3.009 3.009 0 00-3 3v8a1 1 0 001 1z\"/><path d=\"M23.609 17.139a1.264 1.264 0 00.3-1.378A1.233 1.233 0 0022.74 15h-2.5a.249.249 0 01-.229-.151l-1.344-3.116-.013-.028a1.294 1.294 0 00-2.318.028l-1.346 3.115a.25.25 0 01-.23.151h-2.5a1.228 1.228 0 00-1.166.76 1.268 1.268 0 00.319 1.4l2.194 1.955a.252.252 0 01.063.287l-1.223 2.814a1.253 1.253 0 00.314 1.451 1.278 1.278 0 001.506.166l3.112-1.752a.249.249 0 01.245 0l3.113 1.752a1.273 1.273 0 001.506-.166 1.254 1.254 0 00.315-1.45L21.334 19.4a.251.251 0 01.063-.287z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});