define("ember-svg-jar/inlined/hospital-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hospital-building</title><path d=\"M7.5 9A4.5 4.5 0 103 4.5 4.505 4.505 0 007.5 9zm-2-5.25h.75a.5.5 0 00.5-.5V2.5a.75.75 0 011.5 0v.75a.5.5 0 00.5.5h.75a.75.75 0 010 1.5h-.75a.5.5 0 00-.5.5v.75a.75.75 0 01-1.5 0v-.75a.5.5 0 00-.5-.5H5.5a.75.75 0 010-1.5z\"/><path d=\"M11.5 13h3a.5.5 0 00.5-.5V7a1.466 1.466 0 00-1.048-1.422.5.5 0 00-.633.359 5.992 5.992 0 01-11.638 0 .5.5 0 00-.633-.359A1.466 1.466 0 000 7v16a1 1 0 001 1h7.042a.5.5 0 00.493-.585A2.464 2.464 0 018.5 23v-7a3 3 0 013-3zM6 20.75H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm0-3.5H3a.75.75 0 010-1.5h3a.75.75 0 010 1.5zm.5-3.5H3a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5z\"/><path d=\"M22.5 14.5h-11A1.5 1.5 0 0010 16v7a1 1 0 001 1h3.5a.5.5 0 00.5-.5v-1a2 2 0 014 0v1a.5.5 0 00.5.5H23a1 1 0 001-1v-7a1.5 1.5 0 00-1.5-1.5zm-7 3.75H13a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm5.5 0h-2.5a.75.75 0 010-1.5H21a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});