define("ember-svg-jar/inlined/sailing-boat-water", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat-water</title><path d=\"M3.841 17.02a.5.5 0 00.561.194 2.418 2.418 0 01.713-.11 2.5 2.5 0 012.126 1.178 2.016 2.016 0 001.411.832 2.682 2.682 0 001.83-1 2.583 2.583 0 012-1.005 2.506 2.506 0 012.252 1.405.491.491 0 00.028.05 1.168 1.168 0 00.963.554 1.787 1.787 0 001.373-.758 2.5 2.5 0 012.171-1.26 2.261 2.261 0 01.6.087.5.5 0 00.549-.2 6.267 6.267 0 00.423-.716A1.5 1.5 0 0019.5 14.1h-6a.5.5 0 01-.5-.5v-1.25a.5.5 0 01.5-.5h5.012a1.488 1.488 0 001.158-2.422L13.537 1.88a1.427 1.427 0 00-2.534.9V13.6a.5.5 0 01-.5.5H4.739a1.508 1.508 0 00-1.275.71 1.488 1.488 0 00-.067 1.46 6.436 6.436 0 00.444.75z\"/><path d=\"M1.417 22.646a4.113 4.113 0 01-.549-.036 1 1 0 01.264-1.982c1.692.231 3.186-1.628 3.2-1.647a1.043 1.043 0 011.636.094 3.461 3.461 0 002.684 1.538 4.2 4.2 0 003.027-1.6 1.046 1.046 0 011.715.16 2.719 2.719 0 002.335 1.439A3.265 3.265 0 0018.4 19.1a1.044 1.044 0 011.72-.029c.009.013 1.115 1.718 2.745 1.513a1 1 0 01.266 1.982 4.485 4.485 0 01-3.531-1.122.5.5 0 00-.64 0 5 5 0 01-3.226 1.173 4.447 4.447 0 01-3.044-1.17.5.5 0 00-.636-.027 5.623 5.623 0 01-3.4 1.2 5.037 5.037 0 01-3.279-1.252.5.5 0 00-.632-.01 5.486 5.486 0 01-3.326 1.288z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});