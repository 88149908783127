define("ember-svg-jar/inlined/single-neutral-actions-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-remove</title><path d=\"M.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm3.182-9.682a1 1 0 010 1.414l-1.591 1.591a.25.25 0 000 .354l1.591 1.591a1 1 0 11-1.414 1.414l-1.591-1.591a.25.25 0 00-.354 0l-1.591 1.591a1 1 0 11-1.414-1.414l1.591-1.591a.25.25 0 000-.354l-1.591-1.591a1 1 0 111.414-1.414l1.591 1.591a.25.25 0 00.354 0l1.591-1.591a1 1 0 011.414 0zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229h.568a.25.25 0 00.182-.078.254.254 0 00.068-.187c0-.078-.008-.156-.008-.235a7.948 7.948 0 011.345-4.43.25.25 0 00.036-.19A5.5 5.5 0 000 13.75v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M17.5 23.75a6.5 6.5 0 10-6.5-6.5 6.508 6.508 0 006.5 6.5zm3.182-9.682a1 1 0 010 1.414l-1.591 1.591a.25.25 0 000 .354l1.591 1.591a1 1 0 11-1.414 1.414l-1.591-1.591a.25.25 0 00-.354 0l-1.591 1.591a1 1 0 11-1.414-1.414l1.591-1.591a.25.25 0 000-.354l-1.591-1.591a1 1 0 111.414-1.414l1.591 1.591a.25.25 0 00.354 0l1.591-1.591a1 1 0 011.414 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});