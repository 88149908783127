define("ember-svg-jar/inlined/car-voyage-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-voyage-1</title><rect x=\"5\" y=\".5\" width=\"5\" height=\"7\" rx=\"1.5\" ry=\"1.5\"/><path d=\"M12.5 7.5h1.25a.5.5 0 00.5-.5V3a.5.5 0 00-.5-.5H12.5A1.5 1.5 0 0011 4v2a1.5 1.5 0 001.5 1.5zM16.25 7.5h1.25A1.5 1.5 0 0019 6V4a1.5 1.5 0 00-1.5-1.5h-1.25a.5.5 0 00-.5.5v4a.5.5 0 00.5.5zM21.4 14.8l-1.212-.909-.009-.012-1.749-4.371a1.99 1.99 0 00-1.857-1.258H7.427A1.99 1.99 0 005.57 9.508l-1.749 4.371-.009.012L2.6 14.8A1.506 1.506 0 002 16v3a1 1 0 00.667.943.5.5 0 01.333.471V22.5a1 1 0 001 1h2a1 1 0 001-1v-2a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v2a1 1 0 001 1h2a1 1 0 001-1v-2.086a.5.5 0 01.333-.471A1 1 0 0022 19v-3a1.506 1.506 0 00-.6-1.2zm-3.951-1.77a.5.5 0 01-.415.22H6.966a.5.5 0 01-.465-.686l.8-2a.5.5 0 01.464-.314h8.469a.5.5 0 01.464.314l.8 2a.5.5 0 01-.049.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});