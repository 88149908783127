define("ember-svg-jar/inlined/toilet-sign-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>toilet-sign-1</title><path d=\"M23.4 18.613l-1.555-5.462a3.875 3.875 0 00-.875-1.611.5.5 0 00-.787.058L18.5 14.126a.5.5 0 01-.832 0L15.98 11.6a.5.5 0 00-.38-.221.5.5 0 00-.407.163 3.852 3.852 0 00-.875 1.611l-1.555 5.462a.5.5 0 00.48.637h1.928a.5.5 0 01.49.4L16.5 23.6a.5.5 0 00.49.4h2.18a.5.5 0 00.49-.4l.84-3.946a.5.5 0 01.49-.4h1.928a.5.5 0 00.481-.637zM5.581 10.25a5 5 0 00-5 5v3a.5.5 0 00.5.5h1.546a.5.5 0 01.5.452l.418 4.34a.5.5 0 00.5.458h3.08a.5.5 0 00.5-.458l.419-4.34a.5.5 0 01.5-.452h1.546a.5.5 0 00.5-.5v-3a5 5 0 00-5.009-5zm.947 4.724l-.5 1a.5.5 0 01-.894 0l-.5-1a.5.5 0 01-.046-.306l.5-3a.5.5 0 01.986 0l.5 3a.5.5 0 01-.046.306zM5.581 9.5a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zM2.84 4.541a.5.5 0 01.75-.39A6.241 6.241 0 006.748 5a5.993 5.993 0 00.9-.069.5.5 0 01.552.645 2.748 2.748 0 01-5.37-.826c.001-.07.004-.14.01-.209zM18.081 9.5a4.75 4.75 0 10-4.75-4.75 4.756 4.756 0 004.75 4.75zm-2.749-4.762a.061.061 0 01.052-.056 4.6 4.6 0 002.362-1.092.5.5 0 01.669 0 4.59 4.59 0 002.363 1.092.065.065 0 01.053.056v.012a2.75 2.75 0 01-5.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});