define("ember-svg-jar/inlined/video-game-controller-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-controller-monitor</title><path d=\"M20.484 16.96l-9.152-.009a3.52 3.52 0 00-.007 7.04 3.4 3.4 0 002.257-.842 1 1 0 01.659-.247h3.321a1 1 0 01.658.248 3.4 3.4 0 002.257.85 3.52 3.52 0 00.007-7.04zm-8.223 4.012H11.8a.25.25 0 00-.25.25v.465a.5.5 0 01-.5.5.5.5 0 01-.5-.5v-.467a.249.249 0 00-.25-.25h-.468a.5.5 0 010-1h.468a.25.25 0 00.25-.25v-.465a.5.5 0 01.5-.5.5.5 0 01.5.5v.464a.249.249 0 00.25.25h.464a.5.5 0 010 1zm6.679.257a.75.75 0 010-1.5.75.75 0 010 1.5zm1.618 1.62a.75.75 0 010-1.5.75.75 0 110 1.5zm0-3.238a.75.75 0 010-1.5.75.75 0 010 1.5zm1.618 1.621a.75.75 0 110-1.5.75.75 0 010 1.5zM12.258 15a1 1 0 00-1-1H5.75a1 1 0 000 2h5.508a1 1 0 001-1zM9.577 6.908a.5.5 0 010-.816l1.63-1.152a.5.5 0 00.061-.767 3.25 3.25 0 100 4.654.5.5 0 00-.061-.766z\"/><path d=\"M17 11v-1a.5.5 0 01.5-.5h.5a1 1 0 011 1V15a1 1 0 002 0v-4.5a3 3 0 00-3-3h-.5A.5.5 0 0117 7V2a2 2 0 00-2-2H2a2 2 0 00-2 2v9a2 2 0 002 2h13a2 2 0 002-2zm-2.5 0h-12a.5.5 0 01-.5-.5v-8a.5.5 0 01.5-.5h12a.5.5 0 01.5.5v8a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});