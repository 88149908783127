define("ember-svg-jar/inlined/compass-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>compass-2</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.329 14.658a.5.5 0 01-.671.671l-2.434-1.217a.5.5 0 00-.448 0l-2.434 1.217a.5.5 0 01-.671-.671l1.217-2.434a.5.5 0 000-.448L8.671 9.342a.5.5 0 01.671-.671l2.434 1.217a.5.5 0 00.448 0l2.434-1.217a.5.5 0 01.671.671l-1.217 2.434a.5.5 0 000 .448zM12 1a.5.5 0 01.488.392l1.186 5.336a.5.5 0 01-.264.556l-1.186.593a.5.5 0 01-.448 0l-1.186-.593a.5.5 0 01-.264-.556l1.186-5.336A.5.5 0 0112 1zM1.392 12.488a.5.5 0 010-.976l5.336-1.186a.5.5 0 01.556.264l.593 1.186a.5.5 0 010 .448l-.593 1.186a.5.5 0 01-.556.264zM12 23a.5.5 0 01-.488-.392l-1.186-5.336a.5.5 0 01.264-.556l1.186-.593a.5.5 0 01.448 0l1.186.593a.5.5 0 01.264.556l-1.186 5.336A.5.5 0 0112 23zm10.608-11.488a.5.5 0 010 .976l-5.336 1.186a.5.5 0 01-.556-.264l-.593-1.186a.5.5 0 010-.448l.593-1.186a.5.5 0 01.556-.264z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});