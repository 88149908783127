define("ember-svg-jar/inlined/halloween-cauldron-mix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-cauldron-mix</title><circle cx=\"10.75\" cy=\"9.25\" r=\".75\"/><circle cx=\"14.25\" cy=\"8.25\" r=\".75\"/><path d=\"M21.75 4.5a2.25 2.25 0 10-2.25-2.25 2.253 2.253 0 002.25 2.25zm0-3a.75.75 0 11-.75.75.751.751 0 01.75-.75z\"/><path d=\"M20.069 5a.5.5 0 01-.356-.407 2.736 2.736 0 00-5.169-.808.5.5 0 01-.479.279A37.406 37.406 0 0012 4a35.824 35.824 0 00-3.912.215.5.5 0 00-.384.737l1.211 2.2a.5.5 0 00.5.254A19.862 19.862 0 0112 7.25c4.831 0 6.887 1.415 7.354 2.354a.253.253 0 010 .215.25.25 0 01-.166.138A29.88 29.88 0 0112 10.75a29.88 29.88 0 01-7.191-.793.25.25 0 01-.166-.138.253.253 0 010-.215 3.033 3.033 0 011.269-1.16.25.25 0 01.341.1l.359.653a1 1 0 101.752-.963L4.126.519a1 1 0 00-1.752.962l1.732 3.15a.251.251 0 01-.151.361C1.61 5.666 0 6.676 0 8a2.4 2.4 0 001.354 1.919.5.5 0 01.2.589A9.6 9.6 0 001 13.717C1 19.387 5.935 24 12 24s11-4.613 11-10.283a9.624 9.624 0 00-.556-3.209.5.5 0 01.2-.589A2.4 2.4 0 0024 8c0-1.318-1.6-2.322-3.931-3zM17 6.25A1.25 1.25 0 1118.25 5 1.252 1.252 0 0117 6.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});