define("ember-svg-jar/inlined/landmark-one-world-trade-center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-one-world-trade-center</title><path d=\"M19.99 18.4a.5.5 0 00-.49-.4h-15a.5.5 0 00-.49.4l-1 5a.5.5 0 00.49.6h17a.5.5 0 00.49-.6zM8 3.5h8a.5.5 0 00.28-.914L12.842.261a1.49 1.49 0 00-1.684 0L7.72 2.588A.5.5 0 008 3.5z\"/><rect x=\"7.5\" y=\"5.001\" width=\"9\" height=\"2.5\" rx=\".5\" ry=\".5\"/><path d=\"M6 16.5h12a.5.5 0 00.494-.576l-1-6.5A.5.5 0 0017 9H7a.5.5 0 00-.494.424l-1 6.5A.5.5 0 006 16.5zm8.25-4.5a.75.75 0 111.5 0v1.5a.75.75 0 01-1.5 0zm-3 0a.75.75 0 111.5 0v1.5a.75.75 0 01-1.5 0zm-3 0a.75.75 0 111.5 0v1.5a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});