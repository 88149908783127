define("ember-svg-jar/inlined/single-neutral-actions-sync.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-sync</title><path d=\"M12.944 11.3a.249.249 0 00.113-.235.251.251 0 00-.158-.208c-.436-.168-.9-.34-1.4-.524l-.628-.23a.6.6 0 01-.138-.311 2.573 2.573 0 01.058-1.3 5.618 5.618 0 001.466-4.314C12.256 1.756 10.677 0 8.5 0S4.744 1.756 4.744 4.174A5.605 5.605 0 006.2 8.471a2.453 2.453 0 01.073 1.316.615.615 0 01-.135.309l-.631.232c-2.447.9-4.215 1.55-4.754 2.626A9.173 9.173 0 000 16.5a.5.5 0 00.5.5h8.8a.249.249 0 00.248-.225 7.437 7.437 0 013.396-5.475zM21.972 20.2a1 1 0 00-1.406.158 4.376 4.376 0 11-.495-5.982.25.25 0 01.083.179.254.254 0 01-.073.183l-1.391 1.387a.5.5 0 00.353.854H23.5a.5.5 0 00.5-.5v-4.458a.5.5 0 00-.854-.353l-1.3 1.3a.249.249 0 01-.348.005 6.374 6.374 0 10.628 8.631 1 1 0 00-.154-1.404z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});