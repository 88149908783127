define("ember-svg-jar/inlined/skiing-snow-scooter-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>skiing-snow-scooter-person</title><circle cx=\"6.749\" cy=\"2.375\" r=\"2\"/><path d=\"M23 19.125a1 1 0 00-1 1 1.5 1.5 0 01-1.5 1.5H1a1 1 0 000 2h19.5a3.5 3.5 0 003.5-3.5 1 1 0 00-1-1z\"/><path d=\"M2.141 20.125h15.692a3.015 3.015 0 001.8-.6l2.483-1.861a2.981 2.981 0 00.985-3.53 5.181 5.181 0 00-2.336-2.568s-4.723-2.459-4.952-2.489l-.861-2.584a1 1 0 10-1.9.633l.914 2.743c-.428.594-.373 1.308-1.426 2.242a3.948 3.948 0 01-2.64 1.014H8a.25.25 0 01-.25-.25v-1.368a.251.251 0 01.356-.226 6.627 6.627 0 002.644.595 1.5 1.5 0 000-3 3.164 3.164 0 01-2.639-1.412l-.611-.92a1.5 1.5 0 00-2.748.832v5.749H3.5a2 2 0 00-1.995 1.856l-.819 3.28a1.5 1.5 0 001.455 1.864zm1.315-4.8a.25.25 0 01.244-.2h6.2a5.883 5.883 0 005.639-4.081c4.679 2.495 5.174 2.55 5.72 3.86a1 1 0 01-.339 1.159l-.416.312H3.531a.25.25 0 01-.2-.094.247.247 0 01-.049-.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});