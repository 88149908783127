define("ember-svg-jar/inlined/tank-top-pattern", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tank-top-pattern</title><path d=\"M2.25 20a.25.25 0 00-.25.25V23a1 1 0 001 1h18a1 1 0 001-1v-2.75a.25.25 0 00-.25-.25zM2.542 10.984A1.5 1.5 0 002 12.138v.612a.25.25 0 00.25.25h19.5a.25.25 0 00.25-.25v-.612a1.5 1.5 0 00-.541-1.153 4.038 4.038 0 01-1.12-1.817A.25.25 0 0020.1 9H3.9a.248.248 0 00-.236.168 4.052 4.052 0 01-1.122 1.816zM19.621 7.5a.249.249 0 00.247-.29A41.9 41.9 0 0119.5 1a1 1 0 00-1-1h-1.53a1.008 1.008 0 00-.993.92 4 4 0 01-7.957 0A1.01 1.01 0 007.028 0H5.5a1 1 0 00-1 1 41.9 41.9 0 01-.367 6.21.249.249 0 00.247.29z\"/><rect x=\"2\" y=\"14.5\" width=\"20\" height=\"4\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});