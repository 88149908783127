define("ember-svg-jar/inlined/business-lucky-cat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-lucky-cat</title><path d=\"M0 11.263a4.159 4.159 0 001.409 2.959.5.5 0 00.348.141.521.521 0 00.146-.021.5.5 0 00.344-.375A14.688 14.688 0 013.514 10.3l.43-.82A.5.5 0 004 9.246v-4a1.061 1.061 0 00-1.018-1.028 2.766 2.766 0 00-1.182.177A2.638 2.638 0 000 6.763zM20.6 10.579a.5.5 0 00-.64.652 16.022 16.022 0 011.12 5.546 8.345 8.345 0 01-.411 2.654.5.5 0 00.726.59 5.245 5.245 0 00-.795-9.442zM11.5 23.237c4.463 0 8.081-2 8.081-6.465a14.662 14.662 0 00-1.2-5.43.507.507 0 00-.326-.28.5.5 0 00-.422.077A6.141 6.141 0 0114 12.237H9a6.138 6.138 0 01-3.632-1.1.5.5 0 00-.748.2 14.664 14.664 0 00-1.2 5.43c-.001 4.467 3.617 6.47 8.08 6.47zm0-10a1.5 1.5 0 01.9 2.7.251.251 0 01-.4-.2v-.494a.5.5 0 00-1 0v.493a.251.251 0 01-.4.2 1.5 1.5 0 01.9-2.695zM16.753.831a.5.5 0 00-.5 0l-3.29 1.851a.256.256 0 01-.139.031c-.223-.015-.9-.056-1.324-.056-.423 0-1.1.042-1.325.056a.242.242 0 01-.137-.03L6.762.88a.5.5 0 00-.506-.011.5.5 0 00-.256.436v2.9a.242.242 0 01-.031.121A4.222 4.222 0 005 7.034a3.761 3.761 0 004 3.729h5a3.761 3.761 0 004-3.729 4.216 4.216 0 00-.969-2.707.253.253 0 01-.031-.121V1.263a.5.5 0 00-.247-.432zM9.976 6.667a.49.49 0 01-.254.07.5.5 0 01-.43-.246A1.509 1.509 0 008 5.755a.5.5 0 010-1 2.514 2.514 0 012.152 1.227.5.5 0 01-.176.685zM15 5.755a1.509 1.509 0 00-1.292.736.5.5 0 01-.43.246.49.49 0 01-.254-.07.5.5 0 01-.176-.685A2.514 2.514 0 0115 4.755a.5.5 0 010 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});