define("ember-svg-jar/inlined/style-three-pin-cargo-ship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-cargo-ship</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7 7a.5.5 0 01.5-.5h.756a.5.5 0 00.494-.42l.269-1.66a.5.5 0 01.574-.413l.987.16a.5.5 0 01.413.573L10.8 5.92a.5.5 0 00.494.58h1.342a.5.5 0 00.493-.42l.27-1.66a.5.5 0 01.573-.413l.988.16a.5.5 0 01.413.573l-.192 1.18a.5.5 0 00.494.58h.825a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5zm10 8.5a3.616 3.616 0 01-2.191-.694.5.5 0 00-.618 0 3.809 3.809 0 01-4.383 0 .5.5 0 00-.617 0A3.624 3.624 0 017 15.5a.5.5 0 010-1c.826 0 1.81-.354 2.017-1.129a.52.52 0 01.966 0c.207.775 1.19 1.129 2.017 1.129s1.81-.354 2.017-1.129a.52.52 0 01.966 0c.207.775 1.19 1.129 2.017 1.129a.5.5 0 010 1zm1.706-4.616a6.875 6.875 0 01-1.591 2.2.5.5 0 01-.433.134 1.8 1.8 0 01-.228-.053.5.5 0 01-.323-.3 1.751 1.751 0 00-1.623-1.133 1.726 1.726 0 00-1.632 1.123.5.5 0 01-.3.3 1.743 1.743 0 01-.576.1 1.866 1.866 0 01-.55-.084.5.5 0 01-.318-.3 1.752 1.752 0 00-1.624-1.135 1.726 1.726 0 00-1.631 1.121.5.5 0 01-.3.3 1.735 1.735 0 01-.252.065.5.5 0 01-.437-.134 6.894 6.894 0 01-1.59-2.2A1 1 0 016.217 9.5h11.566a1 1 0 01.923 1.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});