define("ember-svg-jar/inlined/dentistry-tooth-jaws", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-jaws</title><path d=\"M21.32.759a57.714 57.714 0 00-18.64 0A1.982 1.982 0 001 2.729v6.5a2.006 2.006 0 002 2h3a1.93 1.93 0 00.793-.17.5.5 0 01.413 0 1.938 1.938 0 00.794.17h3a1.93 1.93 0 00.793-.17.5.5 0 01.413 0 1.938 1.938 0 00.794.17h3a1.938 1.938 0 00.794-.17.5.5 0 01.413 0 1.93 1.93 0 00.793.17h3a2.006 2.006 0 002-2v-6.5a1.982 1.982 0 00-1.68-1.97zM6 8.729a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.12a1.487 1.487 0 01.39-1 1.482 1.482 0 012.22 0 1.487 1.487 0 01.39 1zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.487.487 0 01.01-.12 1.494 1.494 0 012.98 0 .487.487 0 01.01.12zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3a.487.487 0 01.01-.12 1.494 1.494 0 012.98 0 .487.487 0 01.01.12zm5 0a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.12a1.487 1.487 0 01.39-1 1.482 1.482 0 012.22 0 1.487 1.487 0 01.39 1zM21 12.769h-3a1.914 1.914 0 00-.793.17.5.5 0 01-.413 0 1.922 1.922 0 00-.794-.17h-3a1.922 1.922 0 00-.794.17.5.5 0 01-.413 0 1.914 1.914 0 00-.793-.17H8a1.922 1.922 0 00-.794.17.5.5 0 01-.413 0 1.914 1.914 0 00-.793-.17H3a2.006 2.006 0 00-2 2v6.5a1.99 1.99 0 001.68 1.97A57.253 57.253 0 0012 24a57.253 57.253 0 009.32-.76A2 2 0 0023 21.269v-6.5a2.006 2.006 0 00-2-2zm-15 4.61a1.5 1.5 0 01-3 0v-2.11a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a1.5 1.5 0 01-3 0v-2.11a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a1.5 1.5 0 01-3 0v-2.11a.5.5 0 01.5-.5h2a.5.5 0 01.5.5zm5 0a1.5 1.5 0 11-3 0v-2.11a.5.5 0 01.5-.5h2a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});