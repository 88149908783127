define("ember-svg-jar/inlined/single-man-actions-laptop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-laptop</title><path d=\"M10.5 14a2.989 2.989 0 011.883-2.773.25.25 0 00.005-.462l-1.236-.53a.249.249 0 01-.152-.229V8.87a.252.252 0 01.074-.177 3.311 3.311 0 00.779-2.231.251.251 0 01.088-.176 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.251.251 0 01-.034-.264 2.531 2.531 0 00.157-1.727C11.944.493 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.252.252 0 01-.213.118 1.071 1.071 0 00-.895.375c-.372.493-.106 1.17.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.763 1.763 0 004.32 5a1.521 1.521 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.123a.248.248 0 01-.151.229l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h9.75a.25.25 0 00.25-.25zM23.894 22.634l-.018-.031-1.838-2.953a.247.247 0 01-.038-.132V14a1.5 1.5 0 00-1.5-1.5h-7A1.5 1.5 0 0012 14v5.518a.255.255 0 01-.037.132l-1.856 2.984a.922.922 0 00.043.931.957.957 0 00.808.435h12.084a.96.96 0 00.81-.437.923.923 0 00.042-.929zm-5.574-.254a.232.232 0 01-.2.12h-2.4a.231.231 0 01-.2-.12.241.241 0 010-.238l.272-.476a.232.232 0 01.2-.119h1.854a.232.232 0 01.2.119l.272.476a.243.243 0 01.002.238zM20 18.75a.25.25 0 01-.25.25h-5.5a.25.25 0 01-.25-.25v-4a.25.25 0 01.25-.25h5.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});