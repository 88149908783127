define("ember-svg-jar/inlined/graphic-tablet-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>graphic-tablet-pen</title><path d=\"M13.538 16.366a.248.248 0 00-.176-.073H8a1 1 0 01-1-1v-11a1 1 0 011-1h13a1 1 0 011 1v11a.994.994 0 01-.269.681.249.249 0 00.006.347l1.206 1.207a.251.251 0 00.357 0 2.492 2.492 0 00.7-1.732v-12a2.5 2.5 0 00-2.5-2.5h-19A2.5 2.5 0 000 3.793v12a2.5 2.5 0 002.5 2.5h12.362a.25.25 0 00.176-.427zM4.25 10.545h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5zm.75 3a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 01.75.75zM4.25 6.8h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 010 1.5z\"/><path d=\"M13.669 10.375a.506.506 0 00-.2-.12l-1.94-.647a.5.5 0 00-.632.633l.646 1.939a.494.494 0 00.121.2l9.917 9.917a1.414 1.414 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});