define("ember-svg-jar/inlined/touch-id-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>touch-id-user</title><path d=\"M11.229 21.515a7.629 7.629 0 01-1.824.219.75.75 0 000 1.5 8.9 8.9 0 001.513-.125.251.251 0 00.2-.208 1.816 1.816 0 01.1-.377 8.82 8.82 0 01.289-.657.25.25 0 00-.284-.352zM17.155 9.264v.271a.249.249 0 00.285.247 5.082 5.082 0 01.95-.042.247.247 0 00.186-.068.25.25 0 00.079-.182v-.226a9.153 9.153 0 00-1.931-5.65.748.748 0 10-1.18.92 7.685 7.685 0 011.611 4.73zM6.389 21.129a7.74 7.74 0 01-4.736-7.143V12.1a.75.75 0 00-1.5 0v1.889a9.235 9.235 0 005.652 8.521.74.74 0 00.292.059.75.75 0 00.292-1.44zM.876 9.541h.041a.75.75 0 00.749-.709 7.752 7.752 0 0111.615-6.28.75.75 0 00.75-1.3A9.252 9.252 0 00.168 8.751a.749.749 0 00.708.79z\"/><path d=\"M14.47 11.04a.25.25 0 00.268-.04 4.873 4.873 0 01.95-.641.25.25 0 00.136-.223v-.872a6.418 6.418 0 00-8.419-6.1.751.751 0 00.46 1.43 5.065 5.065 0 011.54-.25 4.928 4.928 0 014.919 4.92v1.549a.251.251 0 00.146.227zM4.624 4.984a6.393 6.393 0 00-1.64 4.28v4.72a6.414 6.414 0 0010.98 4.51.251.251 0 00.02-.326 5.407 5.407 0 01-.537-.854.234.234 0 00-.383-.05 4.915 4.915 0 01-8.58-3.28v-4.72a4.948 4.948 0 011.25-3.28.747.747 0 10-1.11-1z\"/><path d=\"M7.32 13.986a.75.75 0 00-1.5 0 3.584 3.584 0 007.167 0V9.264a3.584 3.584 0 00-7.167 0v1.417a.75.75 0 001.5 0V9.264a2.084 2.084 0 014.167 0v4.722a2.084 2.084 0 01-4.167 0z\"/><path d=\"M10.153 13.514v-2.833a.75.75 0 00-1.5 0v2.833a.75.75 0 001.5 0zM23.8 22.969a6.13 6.13 0 00-3.83-3.677.25.25 0 01-.028-.465 4.252 4.252 0 10-3.619-.024.25.25 0 01-.034.464 6.128 6.128 0 00-3.891 3.7.749.749 0 00.7 1.017h10a.75.75 0 00.7-1.017zm-7.9-7.983a2.174 2.174 0 01.125-.72.251.251 0 01.152-.152.255.255 0 01.214.021 5.648 5.648 0 002.944.851 5.239 5.239 0 00.765-.056.251.251 0 01.285.266 2.247 2.247 0 01-4.485-.21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});