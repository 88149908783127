define("ember-svg-jar/inlined/road-sign-tunnel-ahead-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-tunnel-ahead-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M18 12h-1.576a.251.251 0 01-.231-.154A4.4 4.4 0 0012 9a4.4 4.4 0 00-4.193 2.846.251.251 0 01-.231.154H6a1 1 0 000 2h2.5a1.058 1.058 0 00.986-.836 2.542 2.542 0 015.028 0A1.046 1.046 0 0015.5 14H18a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});