define("ember-svg-jar/inlined/book-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>book-play</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zm-2.4 13.46a2 2 0 01-2 2h-7a2 2 0 01-2-2v-7a2 2 0 012-2h7a2 2 0 012 2zm0-13.75a.25.25 0 01-.25.25H5.25a1 1 0 010-2H17.5a.25.25 0 01.25.25z\"/><path d=\"M10.718 11.806a.429.429 0 00-.218.374v3.64a.43.43 0 00.651.369l3.034-1.82a.43.43 0 000-.738l-3.034-1.82a.432.432 0 00-.433-.005z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});