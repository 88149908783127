define("ember-svg-jar/inlined/easter-egg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>easter-egg</title><path d=\"M4.583 6.858a.5.5 0 00.165.754 15.826 15.826 0 005.2 1.564 16.048 16.048 0 004.882-.1.5.5 0 00.357-.718c-1.245-2.464-3.316-4.09-5.677-4.125h-.078a6.234 6.234 0 00-4.849 2.625zM8.032 20.386a6.35 6.35 0 01-.308-.672.5.5 0 00-.571-.307 1.7 1.7 0 01-.37.04 1.765 1.765 0 01-.425-.052 1.747 1.747 0 01-1.219-1.1l-.723-1.983a.248.248 0 00-.172-.1.244.244 0 00-.218.081l-.893.985a.5.5 0 00-.089.533 6.443 6.443 0 005.431 4 .25.25 0 00.224-.4 6.772 6.772 0 01-.667-1.025z\"/><path d=\"M4.433 14.723A1.738 1.738 0 015.8 15.737l.747 2.045a.249.249 0 00.4.1l.32-.286a7.734 7.734 0 01.4-2.866 7.335 7.335 0 012.491-3.586.25.25 0 00-.123-.447 17.419 17.419 0 01-6.083-1.808.5.5 0 00-.693.249 12.463 12.463 0 00-.942 4.627 11.893 11.893 0 00.07 1.483.25.25 0 00.434.14l.094-.1a1.752 1.752 0 011.518-.565zM3.108 7.264a1 1 0 00-.343-1.371l-1.25-.75a1 1 0 00-1.03 1.714l1.25.75a1 1 0 001.373-.343zM19.911 8.008a1 1 0 00.564-.175l1.839-1.258a1 1 0 00-1.128-1.65l-1.84 1.257a1 1 0 00.565 1.826zM4.418 3.638a1 1 0 001.664-1.109l-1-1.5a1 1 0 10-1.664 1.109zM10.75 3.5a1 1 0 001-1V1a1 1 0 00-2 0v1.5a1 1 0 001 1zM16.254 4.868a1 1 0 001.364-.368l1-1.75a1 1 0 10-1.736-.992l-1 1.75a1 1 0 00.372 1.36zM20.25 10.75a1 1 0 001 1H23a1 1 0 000-2h-1.75a1 1 0 00-1 1z\"/><path d=\"M17.5 11.272c-4.169-1.388-7.657.091-8.886 3.774s.675 6.961 4.844 8.351a11.494 11.494 0 003.615.6 9.323 9.323 0 002.748-.4 5.666 5.666 0 003.927-3.51 5.662 5.662 0 00-1.035-5.164 10.525 10.525 0 00-5.213-3.651zm-7.216 4.593a1 1 0 111 1 1 1 0 01-.997-1zm2 4.5a1 1 0 111-1 1 1 0 01-.997 1zm2-5.5a1 1 0 111-1 1 1 0 01-.997 1zm2 7.5a1 1 0 111-1 1 1 0 01-.997 1zm0-4a1 1 0 111-1 1 1 0 01-.997 1zm2.5-2.5a1 1 0 111 1 1 1 0 01-.997-1zm1.5 5a1 1 0 111-1 1 1 0 01-.997 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});