define("ember-svg-jar/inlined/school-locker-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>school-locker-1</title><path d=\"M6.25 1H1.5A1.5 1.5 0 000 2.5v19A1.5 1.5 0 001.5 23h4.75a.5.5 0 00.5-.5v-21a.5.5 0 00-.5-.5zM4 19.25H3a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-9.5H3a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3.5H3a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM23.645.266a.745.745 0 00-.73-.032L21.8.789a2 2 0 01-.89.211H8.75a.5.5 0 00-.5.5v21a.5.5 0 00.5.5h12.16a2 2 0 01.894.211l1.11.555A.75.75 0 0024 23.1V.905a.748.748 0 00-.355-.639zM12 19.25h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-9.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm0-3.5h-1a.75.75 0 010-1.5h1a.75.75 0 010 1.5zm8.5 14.25a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-10a.5.5 0 01.5-.5H20a.5.5 0 01.5.5zm0-13a.5.5 0 01-.5.5h-4.5a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5H20a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});