define("@gavant/ember-shopify-draggable/components/draggable-group", ["exports", "@ember/component", "@gavant/ember-shopify-draggable/templates/components/draggable-group", "@ember/object", "@ember/object/computed", "@ember/array", "@gavant/ember-shopify-draggable/mixins/base-group"], function (_exports, _component, _draggableGroup, _object, _computed, _array, _baseGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_baseGroup.default, {
    layout: _draggableGroup.default,
    classNames: ['draggable-group'],
    draggable: (0, _computed.alias)('shopifyInstance'),
    async didInsertElement() {
      this._super(...arguments);
      const {
        Draggable,
        Plugins
      } = await emberAutoImportDynamic("@shopify/draggable");
      const plugins = (0, _array.A)();
      if ((0, _object.get)(this, 'resizeMirror')) {
        plugins.pushObject(Plugins.ResizeMirror);
      }
      if ((0, _object.get)(this, 'snappable')) {
        plugins.pushObject(Plugins.Snappable);
      }
      const shopifyInstance = new Draggable([], {
        draggable: '.draggable-item',
        delay: (0, _object.get)(this, 'delay'),
        handle: (0, _object.get)(this, 'handle'),
        mirror: (0, _object.get)(this, 'mirrorOptions'),
        plugins
      });
      (0, _object.setProperties)(this, {
        shopifyInstance,
        plugins: Plugins
      });
      this.initializePublicEventListeners();
      this.trigger('setupContainers');
    }
  });
});