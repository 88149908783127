define("ember-svg-jar/inlined/kitchenware-spoon-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-spoon-set</title><path d=\"M17.25 22.5v-8.383a2.481 2.481 0 00-.23-1.042 1.5 1.5 0 00-.58-.648l-.249-.152a.505.505 0 01-.191-.214l-2.2-4.669a.249.249 0 01.047-.279 3.251 3.251 0 10-2.1.987.251.251 0 01.244.143l2.19 4.657a.5.5 0 01.041.289l-.045.294a1.49 1.49 0 00.123.861l.909 1.955a.25.25 0 01-.387.3l-1.765-1.486a1.5 1.5 0 00-.8-.343l-.293-.033a.5.5 0 01-.267-.115L8.129 11.61a.254.254 0 01-.08-.258 2.748 2.748 0 10-1.475 1.754.251.251 0 01.268.035l3.568 3.005a.491.491 0 01.158.242l.083.284a1.493 1.493 0 00.474.729l1.711 1.441a.25.25 0 01-.227.432l-2.795-.757a1.505 1.505 0 00-.869.026l-.268.09a.5.5 0 01-.283.01l-3.8-.968a.25.25 0 01-.178-.175 2.25 2.25 0 10-.556 2.181.25.25 0 01.241-.068l3.737.953a.5.5 0 01.253.155l.2.232a1.5 1.5 0 00.737.46l8.016 2.171a.251.251 0 00.307-.306 2.986 2.986 0 01-.101-.778zM24 3.75a3.75 3.75 0 10-4.75 3.614v4.083a.5.5 0 01-.084.278l-.166.246a1.493 1.493 0 00-.252.832v9.7a1.5 1.5 0 003 0V12.8a1.493 1.493 0 00-.252-.832l-.164-.246a.5.5 0 01-.084-.278v-4.08A3.755 3.755 0 0024 3.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});