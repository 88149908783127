define("ember-svg-jar/inlined/fitness-weightlift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-weightlift</title><circle cx=\"12\" cy=\"7.712\" r=\"2.5\"/><path d=\"M23.507.628L21.535.3a.5.5 0 00-.576.41l-.176 1.038a.5.5 0 01-.626.4 32.882 32.882 0 00-16.372-.25.5.5 0 01-.611-.4L3.041.705A.5.5 0 002.465.3L.493.628a.5.5 0 00-.41.577l1 5.916a.5.5 0 00.577.41L3.632 7.2a.5.5 0 00.409-.576l-.366-2.17a.5.5 0 01.371-.568 30.823 30.823 0 0115.879.271.5.5 0 01.355.564l-.321 1.9a.5.5 0 00.409.576l1.972.334a.5.5 0 00.577-.41l1-5.916a.5.5 0 00-.41-.577z\"/><path d=\"M17.5 4.712a1.25 1.25 0 00-1.25 1.25v1.5a4.255 4.255 0 01-4.674 4.229A4.378 4.378 0 017.75 7.273V5.962a1.25 1.25 0 00-2.5 0v1.264A7.061 7.061 0 009 13.492v9.72a.5.5 0 00.5.5H11a.5.5 0 00.5-.5v-2.5a.5.5 0 011 0v2.5a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5V13.5a6.749 6.749 0 003.75-6.035v-1.5a1.25 1.25 0 00-1.25-1.253z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});