define("ember-svg-jar/inlined/multiple-actions-edit-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-edit-1</title><path d=\"M18.414 10.758a4.19 4.19 0 01.29-.264.5.5 0 00.033-.74 6 6 0 00-6.713-1.22.5.5 0 00-.182.771A7.42 7.42 0 0113.5 13.5v.025A.5.5 0 0014 14h.969a.5.5 0 00.353-.146z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M12 13.6a6.025 6.025 0 00-12-.267.6.6 0 00.6.667h11.023a.377.377 0 00.275-.119A.372.372 0 0012 13.6z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M15.084 22.74a.5.5 0 00-.13-.479l-2.215-2.215a.5.5 0 00-.834.216l-.886 3.1A.5.5 0 0011.5 24a.459.459 0 00.137-.02l3.1-.885a.5.5 0 00.347-.355zM21.771 16.229l-3-3a.5.5 0 00-.707 0l-5.01 5.01a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.01-5.01a.5.5 0 000-.707zM19.478 11.815a.5.5 0 000 .706l3 3a.458.458 0 00.328.125.616.616 0 00.426-.171 2.621 2.621 0 000-3.707 2.653 2.653 0 00-3.754.047z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});