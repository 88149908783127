define("ember-svg-jar/inlined/make-up-eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>make-up-eye</title><path d=\"M23.875 9.779a1 1 0 10-2 0 2.686 2.686 0 01-.575 1.8.5.5 0 01-.755.011 11.479 11.479 0 00-.911-.906.5.5 0 01-.078-.658A4.418 4.418 0 0020 5.843a1 1 0 10-1.885.672 2.447 2.447 0 01-.206 2.4.5.5 0 01-.628.151 10.961 10.961 0 00-1.015-.443.5.5 0 01-.307-.59 4.448 4.448 0 00-1-4.039A1 1 0 0013.4 5.258a2.51 2.51 0 01.607 2.332.5.5 0 01-.539.366 11.714 11.714 0 00-1.353-.081h-.013a.217.217 0 01-.221-.209 4.435 4.435 0 00-2.3-3.723 1 1 0 10-1.034 1.711 2.511 2.511 0 011.339 1.978.5.5 0 01-.389.529 10.63 10.63 0 00-1.053.292.5.5 0 01-.611-.265A4.413 4.413 0 004.505 5.7a1 1 0 00-.4 1.959A2.436 2.436 0 016.059 9.1a.5.5 0 01-.187.6c-.243.16-.472.325-.689.493a.5.5 0 01-.638-.021 4.346 4.346 0 00-3.528-1.013 1 1 0 00.217 1.989 2.579 2.579 0 011.806.375.5.5 0 01.21.334.505.505 0 01-.1.383 10.343 10.343 0 00-.714 1.048 1.674 1.674 0 000 1.674 11.1 11.1 0 009.681 5.413 11.106 11.106 0 009.683-5.413 1.65 1.65 0 00.205-1.044.5.5 0 01.19-.46 4.4 4.4 0 001.68-3.679zm-11.756 8.6a9.1 9.1 0 01-7.663-3.967.5.5 0 010-.566 9.093 9.093 0 017.663-3.967 9.1 9.1 0 017.665 3.967.5.5 0 010 .566 9.1 9.1 0 01-7.665 3.963z\"/><path d=\"M12.12 10.625c-.053 0-.106 0-.158.005a.5.5 0 00-.389.223.5.5 0 00-.053.446 1.683 1.683 0 01.1.579 1.75 1.75 0 01-1.75 1.75 1.7 1.7 0 01-.578-.1.5.5 0 00-.67.442v.157a3.5 3.5 0 103.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});