define("ember-svg-jar/inlined/video-file-flv-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-file-flv-1</title><path d=\"M23.707 5.707L18.293.293A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.75V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25V21.5a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.414a1 1 0 00-.293-.707z\"/><path d=\"M18 19v-9a1 1 0 00-1-1H1a1 1 0 00-1 1v9a1 1 0 001 1h16a1 1 0 001-1zM5.5 12.125h-2a.375.375 0 00-.375.375v1.125a.25.25 0 00.25.25H4.5a.625.625 0 010 1.25H3.375a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5A1.626 1.626 0 013.5 10.875h2a.625.625 0 010 1.25zm5 6h-2A1.627 1.627 0 016.875 16.5v-5a.625.625 0 011.25 0v5a.375.375 0 00.375.375h2a.625.625 0 010 1.25zM16.125 13a8.176 8.176 0 01-1.625 4.875.625.625 0 01-1 0A8.176 8.176 0 0111.875 13v-1.5a.625.625 0 011.25 0V13a6.93 6.93 0 00.738 3.1c.044.087.237.074.284-.02a6.923 6.923 0 00.728-3.08v-1.5a.625.625 0 011.25 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});