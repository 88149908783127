define("ember-svg-jar/inlined/crypto-currency-bitcoin-world-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-world-alternate</title><path d=\"M13.9 8.448a.251.251 0 01-.15-.229V7.5a.75.75 0 00-1.5 0V8a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5a.75.75 0 00-1.5 0V8a.25.25 0 01-.25.25.75.75 0 00-.75.75v6a.75.75 0 00.75.75.25.25 0 01.25.25v.5a.75.75 0 001.5 0V16a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.719a.251.251 0 01.15-.229 2.244 2.244 0 001.35-2.052 2.219 2.219 0 00-.46-1.349.249.249 0 010-.3 2.219 2.219 0 00.46-1.349 2.244 2.244 0 00-1.35-2.054zM10.25 10a.25.25 0 01.25-.25H13a.75.75 0 010 1.5h-2.5a.25.25 0 01-.25-.25zM13 14.25h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H13a.75.75 0 010 1.5z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm3.238 21.449a.25.25 0 01-.289-.374 15.278 15.278 0 001.237-2.275.75.75 0 00-1.372-.608A13.737 13.737 0 0112.42 21.9a.25.25 0 01-.171.085c-.083 0-.165.012-.249.012s-.166-.007-.249-.012a.25.25 0 01-.171-.085 13.737 13.737 0 01-2.394-3.7.75.75 0 00-1.372.608 15.278 15.278 0 001.237 2.271.249.249 0 01-.289.374 10.017 10.017 0 01-4.629-3.3.25.25 0 01.2-.4H7.1a.75.75 0 000-1.5H3.121a.249.249 0 01-.227-.147 9.907 9.907 0 01-.832-3.077.252.252 0 01.249-.276H5.5a.75.75 0 000-1.5H2.311a.251.251 0 01-.249-.276 9.924 9.924 0 011.369-4.1.251.251 0 01.215-.121H7.5a.75.75 0 000-1.5H5.236a.25.25 0 01-.173-.43 9.99 9.99 0 013.883-2.337.25.25 0 01.293.364A19.079 19.079 0 008.314 4.7a.75.75 0 101.372.6 12.261 12.261 0 011.9-3.2.244.244 0 01.174-.088c.081-.006.159-.012.24-.012s.159.006.239.012a.244.244 0 01.174.088 12.261 12.261 0 011.9 3.2.75.75 0 101.373-.6 19.079 19.079 0 00-.925-1.843.249.249 0 01.021-.282.252.252 0 01.272-.082 9.99 9.99 0 013.883 2.327.25.25 0 01-.173.43H16.5a.75.75 0 000 1.5h3.854a.251.251 0 01.215.121 9.924 9.924 0 011.369 4.1.252.252 0 01-.249.276H18.5a.75.75 0 000 1.5h3.189a.251.251 0 01.249.276 9.907 9.907 0 01-.832 3.077.249.249 0 01-.227.147H16.9a.75.75 0 100 1.5h2.77a.25.25 0 01.2.4 10.017 10.017 0 01-4.632 3.302z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});