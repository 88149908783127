define("ember-svg-jar/inlined/style-three-pin-hearing-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-hearing-aid</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM7.675 16a1 1 0 111-1 1 1 0 01-1 1zm2-2a1 1 0 111-1 1 1 0 01-1 1zm.97-4.033a.749.749 0 011.06 0l1 1a.75.75 0 11-1.06 1.06l-1-1a.749.749 0 010-1.057zm4.7 2.365a3.133 3.133 0 00-.42 1.668 3.254 3.254 0 01-3.25 3.25.75.75 0 010-1.5 1.752 1.752 0 001.75-1.75A4.582 4.582 0 0114 11.664 3.128 3.128 0 0014.425 10a2.75 2.75 0 00-5.5 0 .75.75 0 11-1.5 0 4.25 4.25 0 118.5 0 4.579 4.579 0 01-.579 2.335zm2.827-3.106a.794.794 0 01-.174.02.748.748 0 01-.728-.578 5.772 5.772 0 00-3.386-3.979.75.75 0 11.576-1.384 7.264 7.264 0 014.268 5.018.75.75 0 01-.555.906z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});