define("ember-svg-jar/inlined/monitor-heart-beat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-heart-beat</title><path d=\"M3.758 12.392a.5.5 0 00.423-.234l1.009-1.607a1.587 1.587 0 011.441-.789 1.645 1.645 0 011.378.867l.916 1.641a.25.25 0 00.449-.025l1.6-3.829a1.663 1.663 0 011.7-1.05 1.723 1.723 0 011.447 1.342l1.329 5.558a.248.248 0 00.223.191.25.25 0 00.251-.152l.357-.854a1.673 1.673 0 011.525-1.059H21.6a.5.5 0 00.464-.311A11.516 11.516 0 0023 7.752C23 3.138 20.324.868 17.672.665a5.632 5.632 0 00-5.446 3.417.251.251 0 01-.452 0A5.618 5.618 0 006.328.665C3.676.868 1 3.138 1 7.752a11.516 11.516 0 00.936 4.329.5.5 0 00.463.311z\"/><path d=\"M18.39 14.392a.5.5 0 00-.461.307l-.862 2.058a1.669 1.669 0 01-1.523 1.06 1.75 1.75 0 01-.177-.01 1.721 1.721 0 01-1.446-1.342l-1.329-5.558a.25.25 0 00-.474-.038l-1.372 3.277a1.673 1.673 0 01-1.41 1.054 1.622 1.622 0 01-1.548-.865L6.764 12.5a.25.25 0 00-.43-.012l-.7 1.112a1.639 1.639 0 01-1.386.79h-.279a.5.5 0 00-.42.771 28.806 28.806 0 007.905 8.043 1.119 1.119 0 001.093 0 28.8 28.8 0 007.9-8.042.5.5 0 00-.42-.771z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});