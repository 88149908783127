define("ember-svg-jar/inlined/desktop-monitor-smiley-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-monitor-smiley-1</title><path d=\"M9.25 8.251a1 1 0 001-1V6a1 1 0 00-2 0v1.25a1 1 0 001 1.001zM14.75 8.251a1 1 0 001-1V6a1 1 0 00-2 0v1.25a1 1 0 001 1.001zM15.192 10.682a4.5 4.5 0 01-6.363 0A1 1 0 107.415 12.1a6.5 6.5 0 009.191 0 1 1 0 10-1.414-1.414z\"/><path d=\"M22 .251H2a2 2 0 00-2 2v14a2 2 0 002 2h20a2 2 0 002-2v-14a2 2 0 00-2-2zm-.5 16h-19a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v13a.5.5 0 01-.5.5zM14.3 21.022a.5.5 0 01-.384-.421l-.114-.912a.5.5 0 00-.5-.438h-2.611a.5.5 0 00-.5.438l-.114.912a.5.5 0 01-.385.421 4.241 4.241 0 00-3.059 2.04.5.5 0 00.464.689h9.8a.5.5 0 00.463-.689 4.237 4.237 0 00-3.06-2.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});