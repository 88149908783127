define("ember-svg-jar/inlined/hair-dress-comb-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hair-dress-comb-1</title><path d=\"M23.778 3.2A3.913 3.913 0 0020.8.223a3.992 3.992 0 00-4.09 1.857 40.173 40.173 0 00-1.776 2.7 13.785 13.785 0 01-.911 1.371.3.3 0 01-.434.018 4.577 4.577 0 00-3.04-1.469 2.94 2.94 0 00-2.259.764L.51 13.236A1.251 1.251 0 00.51 15a1.281 1.281 0 001.768 0l3.535-3.535a.751.751 0 011.061 1.062l-3.535 3.537a1.252 1.252 0 000 1.768 1.281 1.281 0 001.768 0L8.641 14.3a.744.744 0 01.53-.22.751.751 0 01.531 1.28l-3.533 3.533a1.248 1.248 0 000 1.768 1.278 1.278 0 001.767 0l3.533-3.534a.778.778 0 011.062 0 .748.748 0 010 1.06L9 21.722a1.25 1.25 0 101.767 1.768l7.778-7.779a2.858 2.858 0 00.78-2.05 4.664 4.664 0 00-1.488-3.253.293.293 0 01-.087-.22.3.3 0 01.1-.213 12.612 12.612 0 011.389-.925c.727-.447 1.633-1 2.683-1.762A4 4 0 0023.778 3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});