define("ember-svg-jar/inlined/modern-tv-hd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>modern-tv-hd</title><path d=\"M24 1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5zm-2 13a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M13.5 3.75a.75.75 0 00-.75.75v8a.75.75 0 00.75.75 4.75 4.75 0 000-9.5zm1.084 7.814a.25.25 0 01-.228-.032.247.247 0 01-.106-.2v-5.66a.217.217 0 01.106-.2.25.25 0 01.228-.032 3.25 3.25 0 010 6.128zM10.5 3.75a.75.75 0 00-.75.75v3a.25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25v-3a.75.75 0 00-1.5 0v8a.75.75 0 001.5 0v-3a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v3a.75.75 0 001.5 0v-8a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});