define("ember-svg-jar/inlined/antenna-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>antenna-2</title><path d=\"M12 3.585a4.785 4.785 0 00-4.78 4.78 1 1 0 102 0 2.78 2.78 0 115.559 0 1 1 0 002 0A4.784 4.784 0 0012 3.585z\"/><path d=\"M4.433 9.567a1 1 0 001-1 6.567 6.567 0 0113.134 0 1 1 0 002 0 8.567 8.567 0 00-17.134 0 1 1 0 001 1z\"/><path d=\"M20.5 11.866c-.127-.323-.417-.316-.868-.4a1 1 0 00-1.156.809l-.3 1.693a.25.25 0 01-.287.2l-3.195-.533a.247.247 0 01-.207-.22 1.494 1.494 0 00-1.269-1.355.249.249 0 01-.218-.24V8.567a1 1 0 00-2 0v3.253a.25.25 0 01-.213.247 1.493 1.493 0 00-1.269 1.355.248.248 0 01-.207.22l-3.195.533a.25.25 0 01-.287-.2l-.3-1.693a1 1 0 00-1.156-.809c-.431.077-.739.069-.868.4a6.637 6.637 0 001.223 6.887c.241.273.558.142.95.073a1 1 0 00.808-1.156l-.219-1.236a.251.251 0 01.205-.291l2.747-.458a.251.251 0 01.291.247v1.987a1.5 1.5 0 001.28 1.483.249.249 0 01.213.247V23.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-3.851a.25.25 0 01.213-.247 1.5 1.5 0 001.28-1.483v-1.987a.251.251 0 01.291-.247l2.747.458a.251.251 0 01.205.291l-.219 1.236a1 1 0 00.808 1.156c.4.07.711.2.95-.073a6.637 6.637 0 001.222-6.887z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});