define("ember-svg-jar/inlined/outdoors-water-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-water-sun</title><path d=\"M7.8 17.377A1 1 0 009.144 15.9a4.25 4.25 0 115.712 0 1 1 0 101.345 1.48 6.25 6.25 0 10-8.4 0zM12 5.5a1 1 0 001-1v-3a1 1 0 00-2 0v3a1 1 0 001 1z\"/><path d=\"M5.459 7.623a1 1 0 001.415-1.414L4.752 4.088A1 1 0 003.338 5.5zM1 13.75h2.75a1 1 0 000-2H1a1 1 0 000 2zM18.541 7.623L20.662 5.5a1 1 0 00-1.414-1.414l-2.122 2.123a1 1 0 101.415 1.414zM19.25 12.75a1 1 0 001 1H23a1 1 0 000-2h-2.75a1 1 0 00-1 1zM23.174 21.515a3.221 3.221 0 01-2.274-1.462 1.042 1.042 0 00-1.79 0A3.525 3.525 0 0116 21.5a3.525 3.525 0 01-3.1-1.447 1.042 1.042 0 00-1.79 0A3.525 3.525 0 018 21.5a3.525 3.525 0 01-3.105-1.447 1.042 1.042 0 00-1.79 0 3.224 3.224 0 01-2.279 1.462 1 1 0 00.348 1.97 5.757 5.757 0 002.538-1.128.5.5 0 01.61-.007A6.086 6.086 0 008 23.5a6.077 6.077 0 003.7-1.166.5.5 0 01.6 0A6.083 6.083 0 0016 23.5a6.086 6.086 0 003.678-1.15.5.5 0 01.61.007 5.757 5.757 0 002.538 1.128A1.043 1.043 0 0023 23.5a1 1 0 00.173-1.985z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});