define("ember-svg-jar/inlined/disability-hearing-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-hearing-aid</title><path d=\"M10.454 17.743a2.939 2.939 0 011.53-2.467A7.488 7.488 0 101.777 5.1a.988.988 0 001.723.964 5.5 5.5 0 015.326-2.8 5.608 5.608 0 015 5.521 5.527 5.527 0 01-2.815 4.771 4.968 4.968 0 00-2.54 4.155 3.266 3.266 0 01-6.505.156.988.988 0 10-1.966.2 5.219 5.219 0 003.771 4.479 5.4 5.4 0 001.442.2 5.236 5.236 0 005.23-4.89c.006-.018.01-.101.011-.113z\"/><circle cx=\"5.376\" cy=\"12.696\" r=\"2.717\"/><path d=\"M6.684 6.972a2.377 2.377 0 011.233-.343c1.581 0 2.441 1.411 2.591 2.81a.987.987 0 00.982.882 1 1 0 00.107-.006.987.987 0 00.876-1.088c-.291-2.693-2.163-4.574-4.556-4.574a4.348 4.348 0 00-2.258.631.987.987 0 101.025 1.688zM17.865 15.566a.988.988 0 001.356-.339 5.78 5.78 0 000-5.957.988.988 0 00-1.694 1.017 3.81 3.81 0 010 3.923.988.988 0 00.338 1.356zM21.406 17.048a.988.988 0 001.356-.339 8.653 8.653 0 000-8.921.988.988 0 00-1.694 1.017 6.683 6.683 0 010 6.887.988.988 0 00.338 1.356z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});