define("ember-svg-jar/inlined/people-man-6.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-6</title><path d=\"M4 13.459a2.5 2.5 0 002.5 2.5 2.458 2.458 0 001.447-.485.249.249 0 01.312.015 6.527 6.527 0 003.72 1.72 6.539 6.539 0 003.739-1.736.25.25 0 01.314-.013 2.459 2.459 0 001.468.5 2.5 2.5 0 002.5-2.5v-5.5a8 8 0 00-16 0zm3.772-1.488a1.717 1.717 0 00-.235-.609.248.248 0 01-.037-.13V6.566a.251.251 0 01.329-.237 14.106 14.106 0 008.342 0 .251.251 0 01.329.237v4.593a.244.244 0 01-.042.138 1.741 1.741 0 00-.27.673c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738z\"/><path d=\"M17.282 16.985a.5.5 0 00-.624.294 4.994 4.994 0 01-9.316 0 .5.5 0 00-.625-.294l-1.646.391a7.132 7.132 0 00-3.03 1.124A4.46 4.46 0 00.25 22.044v.929a.75.75 0 001.5 0v-.929A2.95 2.95 0 012.939 19.7a4.54 4.54 0 011.755-.71.251.251 0 01.306.244v4.239a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-4.239a.251.251 0 01.306-.244 4.54 4.54 0 011.755.71 2.95 2.95 0 011.189 2.344v.929a.75.75 0 001.5 0v-.929a4.46 4.46 0 00-1.791-3.544 7.132 7.132 0 00-3.03-1.123zM10.092 12.834a.751.751 0 00.3 1.016 2.8 2.8 0 00.511.211 3.584 3.584 0 002.036 0 2.7 2.7 0 00.513-.213.75.75 0 10-.715-1.318 1.952 1.952 0 01-1.634 0 .751.751 0 00-1.011.304zM9.932 10.016A.75.75 0 0010.938 8.9a2.094 2.094 0 00-2.638 0 .75.75 0 001.006 1.113.633.633 0 01.626.003zM13.369 10.209a.749.749 0 00.5-.193.633.633 0 01.625 0A.75.75 0 1015.5 8.9a2.094 2.094 0 00-2.637 0 .75.75 0 00.5 1.306z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});