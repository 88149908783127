define("ember-svg-jar/inlined/advertising-billboard-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>advertising-billboard-house</title><path d=\"M23 1.5A1.5 1.5 0 0021.5 0h-19A1.5 1.5 0 001 1.5v16A1.5 1.5 0 002.5 19a.5.5 0 01.5.5V23a1 1 0 001 1h2a1 1 0 001-1v-3.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5V23a1 1 0 001 1h2a1 1 0 001-1v-3.5a.5.5 0 01.5-.5 1.5 1.5 0 001.5-1.5zm-4.03 8.172a.5.5 0 01-.47.328h-1a.5.5 0 00-.5.5V15a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-2.25a1.5 1.5 0 00-3 0V15a.5.5 0 01-.5.5H7.5A.5.5 0 017 15v-4.5a.5.5 0 00-.5-.5h-1a.5.5 0 01-.323-.881l6.5-5.5a.5.5 0 01.647 0l6.5 5.5a.5.5 0 01.146.553z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});