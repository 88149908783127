define("ember-svg-jar/inlined/single-neutral-book.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-book</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM12.241 14.5a5.011 5.011 0 015.008 4.667.308.308 0 01-.084.233.313.313 0 01-.229.1H7.751a.5.5 0 01-.5-.556 5.012 5.012 0 014.99-4.444zm-3.011-4a3.012 3.012 0 113.012 3 3.007 3.007 0 01-3.012-3zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});