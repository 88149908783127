define("ember-svg-jar/inlined/car-dashboard-gear-automatic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-dashboard-gear-automatic</title><path d=\"M14.312 21.25h-2.25a.25.25 0 01-.25-.25v-5.75a1.5 1.5 0 00-1.5-1.5h-3.75a.25.25 0 01-.25-.25V6.738a.251.251 0 01.154-.231 3 3 0 10-2.307 0 .25.25 0 01.153.231v7.512a1.5 1.5 0 001.5 1.5h3.75a.249.249 0 01.25.25v5.75a1.5 1.5 0 001.5 1.5h3a1 1 0 000-2zM17.312 8.125a.875.875 0 00.875-.875V5.875a.25.25 0 01.25-.25h1.125a2.125 2.125 0 000-4.25h-2.25a.875.875 0 00-.875.875v5a.875.875 0 00.875.875zm.875-4.75a.25.25 0 01.25-.25h1.125a.375.375 0 010 .75h-1.125a.25.25 0 01-.25-.25zM18.312 16.375h-1a.875.875 0 00-.875.875v5a.875.875 0 00.875.875h1a3.375 3.375 0 000-6.75zm0 5a.125.125 0 01-.125-.125v-3a.125.125 0 01.125-.125 1.625 1.625 0 010 3.25zM17.312 15.625a.875.875 0 00.875-.875v-.938a.251.251 0 01.465-.129l.91 1.517a.876.876 0 00.75.425.852.852 0 00.234-.032.875.875 0 00.641-.843v-5a.875.875 0 00-1.75 0v.938a.25.25 0 01-.464.129l-.91-1.517a.875.875 0 00-1.626.45v5a.875.875 0 00.875.875z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});