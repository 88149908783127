define("ember-svg-jar/inlined/multiple-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-woman-2</title><path d=\"M16.039 15.679a5.22 5.22 0 012.46 4.705v1.767a.25.25 0 00.25.25h5a.25.25 0 00.25-.25v-1.4a3.066 3.066 0 00-1.425-2.793 18.015 18.015 0 00-3.225-1.518c-.292-.1-.6-.362-.6-.653V15.4a.251.251 0 01.252-.25 6.178 6.178 0 003.959-1.117.421.421 0 00.159-.314.448.448 0 00-.133-.339c-.544-.539-.872-.864-1.016-2.971C21.719 6.714 19.136 5.4 17 5.4a5.013 5.013 0 00-1.061.124.249.249 0 00-.189.305 10.062 10.062 0 01.283 1.8c.136 2.017.329 2.21.8 2.677a2.043 2.043 0 01.6 1.6 2 2 0 01-.833 1.484 8.962 8.962 0 01-1.959 1.064.25.25 0 00-.029.453z\"/><path d=\"M17 20.385a3.761 3.761 0 00-1.731-3.418 21.7 21.7 0 00-3.915-1.858c-.355-.124-.729-.442-.729-.8v-.531a.254.254 0 01.075-.178.259.259 0 01.179-.072 7.478 7.478 0 004.86-1.367.519.519 0 00.194-.384.556.556 0 00-.162-.414 3.027 3.027 0 01-.8-1.1 8.561 8.561 0 01-.435-2.537 6.212 6.212 0 00-3.067-5.384 6.281 6.281 0 00-5.938 0 6.212 6.212 0 00-3.067 5.386 8.471 8.471 0 01-.439 2.542 2.594 2.594 0 01-.169.342 3.839 3.839 0 01-.656.788.5.5 0 00.067.763 7.351 7.351 0 004.671 1.37h.188a.251.251 0 01.254.25v.53c0 .357-.374.675-.729.8a21.7 21.7 0 00-3.915 1.858A3.764 3.764 0 000 20.385v1.767a.25.25 0 00.25.25h16.5a.25.25 0 00.25-.25v-1.767z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});