define("ember-svg-jar/inlined/keyboard-asterisk-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-asterisk-3</title><path d=\"M22.5 10.459h-7.447a.25.25 0 01-.216-.375l3.95-6.842a1.5 1.5 0 10-2.6-1.5l-3.97 6.881a.251.251 0 01-.434 0L7.811 1.742a1.5 1.5 0 00-2.6 1.5l3.95 6.842a.25.25 0 01-.216.375H1.5a1.5 1.5 0 000 3h7.492a.25.25 0 01.216.375l-3.995 6.92a1.5 1.5 0 102.6 1.5l3.972-6.881a.251.251 0 01.434 0l3.972 6.881a1.5 1.5 0 102.6-1.5l-3.995-6.92a.25.25 0 01.216-.375H22.5a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});