define("ember-svg-jar/inlined/deer-footprint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>deer-footprint</title><path d=\"M16.709 13.093C16.558 13.2 13 15.779 13 19c0 3.317.976 5 2.9 5 2.1 0 2.1-3.187 2.1-6a21.066 21.066 0 00-.514-4.621.5.5 0 00-.777-.286zM20.291 13.093a.5.5 0 00-.776.286A20.959 20.959 0 0019 18c0 2.813 0 6 2.1 6 1.925 0 2.9-1.683 2.9-5 0-3.221-3.557-5.8-3.709-5.907zM14 10.43V9h1c1.809 0 2.861-3 2.975-3.342A.5.5 0 0017.5 5h-2a3.108 3.108 0 00-2.576 1.146 5.128 5.128 0 00-1.174-.658V5.25c0-.436 0-.75 1.25-.75 2.066 0 3.25-1.367 3.25-3.75a.75.75 0 00-1.5 0c0 1.385-.451 1.932-1 2.136V1.75a.75.75 0 00-1.5 0v1.3a2.028 2.028 0 00-2 2.039A8.609 8.609 0 009 5a8.043 8.043 0 00-1.751.181 2.036 2.036 0 00-2-2.131v-1.3a.75.75 0 00-1.5 0v1.136c-.548-.2-1-.751-1-2.136a.75.75 0 00-1.5 0C1.25 3.133 2.435 4.5 4.5 4.5c1.25 0 1.25.314 1.25.75v.469a4.9 4.9 0 00-.673.427A3.109 3.109 0 002.5 5h-2a.5.5 0 00-.474.658C.14 6 1.192 9 3 9h1v1.43a2.5 2.5 0 001.113 2.08l.476.316.956 2.552A2.512 2.512 0 008.886 17h.227a2.511 2.511 0 002.341-1.622l.957-2.552.476-.316A2.5 2.5 0 0014 10.43zm-3-.18a1 1 0 111-1 1 1 0 01-1 1zm-5-1a1 1 0 111 1 1 1 0 01-1-1zm2.5 4.5h1a.75.75 0 010 1.5h-1a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});