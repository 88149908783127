define("ember-svg-jar/inlined/religion-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-shield-1</title><path d=\"M22.5 1.951a1 1 0 00-1.525-.851 7.463 7.463 0 01-3.988 1.3A5.861 5.861 0 0112.413.209.545.545 0 0011.6.192 5.864 5.864 0 017.013 2.4a7.464 7.464 0 01-3.987-1.3 1 1 0 00-1.526.852v13.806a1.014 1.014 0 00.051.316c.305.915 2.229 5.595 10.177 7.871a1.016 1.016 0 00.547 0c7.946-2.277 9.869-6.955 10.174-7.869a.993.993 0 00.051-.317zm-3.951 2.315a.751.751 0 011.2.6v10.628a.746.746 0 01-.059.291c-.065.153-1.649 3.778-7.488 5.415a.777.777 0 01-.406 0c-5.839-1.637-7.423-5.262-7.488-5.415a.746.746 0 01-.059-.291V4.866a.751.751 0 011.014-.7c.144.055.337.144.568.251C7.85 5.36 9.92 5.966 11.4 4.029a.789.789 0 011.2 0c1.89 2.471 5.347.69 5.949.237z\"/><path d=\"M12.166 5.738a.249.249 0 00-.324 0c-1.9 1.646-4.263 1.031-5.748.43a.25.25 0 00-.344.232v8.859a.25.25 0 00.03.119c.364.669 1.909 3.027 6.149 4.295a.249.249 0 00.143 0c4.235-1.267 5.78-3.62 6.148-4.3a.25.25 0 00.03-.119V6.53a.25.25 0 00-.341-.233 5.635 5.635 0 01-5.743-.559zM15 12a.5.5 0 01-.5.5h-1.25a.25.25 0 00-.25.25V17a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4.25a.25.25 0 00-.25-.25H9.5A.5.5 0 019 12v-1a.5.5 0 01.5-.5h1.25a.25.25 0 00.25-.25V9a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v1.25a.25.25 0 00.25.25h1.25a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});