define("ember-svg-jar/inlined/love-it-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-it-bubble</title><path d=\"M23.447 13.406a2.078 2.078 0 000-2.813L22.2 9.267l.422-1.773a2.075 2.075 0 00-1.408-2.437l-1.746-.519-.52-1.746a2.072 2.072 0 00-2.437-1.409l-1.778.417L13.407.554a2.077 2.077 0 00-2.815 0L9.267 1.8l-1.773-.417a2.079 2.079 0 00-2.437 1.408l-.519 1.745-1.748.521a2.075 2.075 0 00-1.407 2.437L1.8 9.268.553 10.594a2.08 2.08 0 000 2.815L1.8 14.734l-.422 1.774a2.077 2.077 0 001.409 2.437l1.746.52.519 1.744a2.072 2.072 0 002.437 1.409l1.778-.418 1.326 1.252a2.079 2.079 0 002.814 0l1.326-1.252 1.773.422a2.074 2.074 0 002.436-1.408l.52-1.745 1.747-.52a2.075 2.075 0 001.408-2.438l-.417-1.778zM16.1 12.68l-3.417 3.565a.946.946 0 01-1.372 0L7.9 12.68a2.427 2.427 0 113.433-3.433l.493.493a.249.249 0 00.354 0l.493-.493A2.427 2.427 0 1116.1 12.68z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});