define("ember-svg-jar/inlined/wedding-bride-cake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wedding-bride-cake</title><path d=\"M22 13.5h-2.5a.5.5 0 010-1H22a1 1 0 001-1V9a2 2 0 00-.8-1.6.5.5 0 01-.2-.4V5.5a2 2 0 00-2-2h-.7a.25.25 0 01-.178-.425l1.01-1.025a1.159 1.159 0 00.235-1.386 1.294 1.294 0 00-2.019-.312.5.5 0 01-.7 0 1.294 1.294 0 00-2.019.312 1.159 1.159 0 00.236 1.386l1.01 1.025a.249.249 0 01-.175.425H16a2 2 0 00-2 2V7a.5.5 0 01-.2.4A2 2 0 0013 9v2.5a1 1 0 001 1h2.5a.5.5 0 010 1H14a2 2 0 00-2 2V23a1 1 0 002 0v-2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v2a1 1 0 002 0v-7.5a2 2 0 00-2-2zM16 6a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5zm-.5 4.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5zM7.888 9.032a.5.5 0 00-.7.111L5.7 11.215a.249.249 0 01-.406 0L3.817 9.143a.5.5 0 00-.7-.111 4 4 0 00-1.527 2.346L.012 18.391a.506.506 0 00.1.422A.5.5 0 00.5 19h1.6a.5.5 0 01.5.45l.4 4.1a.5.5 0 00.5.45h4a.5.5 0 00.5-.45l.41-4.1A.5.5 0 018.9 19h1.6a.5.5 0 00.488-.609L9.41 11.378a4 4 0 00-1.522-2.346z\"/><path d=\"M2.02 8.638a5.744 5.744 0 00.6-.88.25.25 0 01.367-.08 4.249 4.249 0 005.024 0 .252.252 0 01.367.08 5.816 5.816 0 00.6.88 1 1 0 101.542-1.276 3.147 3.147 0 01-.77-1.98V4.25a4.25 4.25 0 00-8.5 0v1.132a3.141 3.141 0 01-.771 1.98A1 1 0 002.02 8.638zM3.473 4.26a3.409 3.409 0 00.909-.28 2.471 2.471 0 00.918-.74.251.251 0 01.4 0 2.471 2.471 0 00.916.74 3.409 3.409 0 00.909.28.25.25 0 01.2.278 2.249 2.249 0 01-4.462 0 .251.251 0 01.21-.278z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});