define("ember-svg-jar/inlined/style-three-pin-golf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-golf</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm2 15.5a1.252 1.252 0 00-1.25 1.25v1a.75.75 0 01-1.5 0v-1A1.252 1.252 0 0010 15.5a.75.75 0 010-1.5h4a.75.75 0 010 1.5zM12 13a4.5 4.5 0 114.5-4.5A4.505 4.505 0 0112 13z\"/><circle cx=\"12\" cy=\"11\" r=\"1\"/><circle cx=\"14.5\" cy=\"9\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});