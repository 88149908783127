define("ember-svg-jar/inlined/app-window-minimize-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-minimize_1</title><path d=\"M8.211 10.991a.25.25 0 010 .354l-.271.271a.5.5 0 00.354.854h1.956a.5.5 0 00.5-.5v-1.957a.5.5 0 00-.85-.354l-.271.272a.25.25 0 01-.353 0L7.78 8.439A.75.75 0 106.72 9.5zM13.251 12.47h1.956a.5.5 0 00.354-.854l-.271-.271a.25.25 0 010-.354L16.781 9.5a.75.75 0 00-1.06-1.061l-1.492 1.492a.25.25 0 01-.353 0l-.276-.272a.5.5 0 00-.853.354v1.957a.5.5 0 00.504.5zM7.25 18.751a.744.744 0 00.53-.22l1.491-1.491a.25.25 0 01.354 0l.271.271a.5.5 0 00.354.147.508.508 0 00.191-.038.5.5 0 00.309-.462V15a.5.5 0 00-.5-.5H8.293a.5.5 0 00-.354.853l.272.272a.249.249 0 010 .353L6.72 17.471a.75.75 0 00.53 1.28zM13.059 17.42a.508.508 0 00.191.038.5.5 0 00.354-.147l.271-.271a.25.25 0 01.354 0l1.491 1.491a.75.75 0 001.06-1.06l-1.491-1.492a.249.249 0 010-.353l.272-.272a.5.5 0 00-.354-.853H13.25a.5.5 0 00-.5.5v1.957a.5.5 0 00.309.462z\"/><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h19.5A.25.25 0 0122 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});