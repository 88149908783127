define("ember-svg-jar/inlined/cash-payment-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bill</title><path d=\"M23.8 3.028a1.5 1.5 0 00-1.5-1.5h-14a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h14a1.5 1.5 0 001.5-1.5zm-2 7.25a.25.25 0 01-.25.25H9.053a.25.25 0 01-.25-.25v-6.5a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25z\"/><circle cx=\"15.303\" cy=\"7.028\" r=\"2\"/><path d=\"M20.2 16.527h-.011L16.671 17.7a.255.255 0 00-.153.142 2 2 0 01-1.818 1.186h-4a.5.5 0 010-1h4a1 1 0 000-2h-3.5a7.12 7.12 0 00-4-1.5H5.141a4 4 0 00-1.789.423L.335 16.459a.25.25 0 00-.138.224v5.394a.249.249 0 00.382.212l3.221-2.01a1.018 1.018 0 01.857-.1c9.966 3.358 6.628 3.373 17.866-2.312A.518.518 0 0022.6 17a2.48 2.48 0 00-2.4-.473z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});