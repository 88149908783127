define("ember-svg-jar/inlined/insect-colony", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-colony</title><path d=\"M11.071 13.466a1 1 0 00-1.336.462 1.047 1.047 0 01-.949.592 1.748 1.748 0 00.243-1.2l1.117-1a3.352 3.352 0 001.042-3.19 1 1 0 00-1.956.418 1.344 1.344 0 01-.418 1.28s-1.126 1-1.13 1l-.5-1.418a1.342 1.342 0 01.256-1.322 1 1 0 00-1.513-1.317 3.349 3.349 0 00-.637 3.293l.492 1.416a1.75 1.75 0 00-.366 1.229 1.112 1.112 0 01-.9-1.13 1 1 0 00-.943-1.054 1.011 1.011 0 00-1.054.942A3.123 3.123 0 004.6 15.542l.517.18a6.61 6.61 0 00-.225.4l-2.355-.56a1 1 0 10-.462 1.945l2.171.517a8.17 8.17 0 00-.095.532 3.943 3.943 0 00-2.671.8l-1.085.827a1 1 0 001.213 1.59l1.085-.827a1.877 1.877 0 011.2-.418c.329.1.438 1.079 1.183 1.277.695.188 1.534-.7 1.829-.63s.689.6.847.953l.544 1.25a1 1 0 001.834-.8l-.544-1.249a3.845 3.845 0 00-2.1-2.057c.05-.145.092-.3.135-.449l2.24.532a1 1 0 10.462-1.945l-2.392-.569c0-.145 0-.283-.013-.42a3.828 3.828 0 00.785.092 3.069 3.069 0 002.832-1.713 1 1 0 00-.464-1.334zM23.525 11.938l-1.16-.717a3.863 3.863 0 00-2.9-.488 7.468 7.468 0 00-.145-.446l2.143-.841a1 1 0 10-.731-1.861l-2.288.9a5.81 5.81 0 00-.25-.338l.29-.135a3.123 3.123 0 001.756-3.266 1 1 0 10-1.976.31 1.046 1.046 0 01-.443 1.028 1.761 1.761 0 00-.1-.5 1.807 1.807 0 00-.384-.619l.347-1.465A3.346 3.346 0 0016.72.288a1 1 0 10-1.368 1.459 1.344 1.344 0 01.387 1.289L15.39 4.5l-1.235-.872a1.346 1.346 0 01-.545-1.234 1 1 0 00-1.988-.218 3.355 3.355 0 001.355 3.068l1.212.882a1.752 1.752 0 00.4 1.218 1.113 1.113 0 01-1.382-.417 1 1 0 10-1.7 1.048 3.1 3.1 0 003.458 1.34l.528-.147c.009.149.023.3.046.46l-2.254.885a1 1 0 00-.566 1.3 1.011 1.011 0 001.3.566l2.078-.816c.072.169.146.335.226.493a3.97 3.97 0 00-1.737 2.181l-.419 1.3a1 1 0 101.9.613l.418-1.3c.1-.314.438-.925.748-1.029.332-.116.956.643 1.7.374.676-.243.866-1.447 1.143-1.56a1.85 1.85 0 011.238.3l1.16.716a1 1 0 001.051-1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});