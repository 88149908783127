define("ember-svg-jar/inlined/people-man-beard-3.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-beard-3</title><path d=\"M21.08 18.287c-.01 0 .114.037-.948-.319a.5.5 0 00-.627.3 4.972 4.972 0 01-.325.692c-1.016 1.782-3.028 2.778-5.966 2.99a.5.5 0 00-.464.5v1.05a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1a4.519 4.519 0 00-2.92-4.213zM11.25 22.444a.5.5 0 00-.464-.5c-2.938-.212-4.95-1.208-5.966-2.99a5.015 5.015 0 01-.325-.691.5.5 0 00-.627-.3l-.948.319A4.519 4.519 0 000 22.5v1a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5zM11.09 9.726a2.019 2.019 0 00-2.6 0 .75.75 0 001.022 1.1.557.557 0 01.557 0 .924.924 0 00.511.2.736.736 0 00.692-.472.747.747 0 00-.182-.828zM14.489 10.825a.75.75 0 101.022-1.1 2.019 2.019 0 00-2.6 0 .746.746 0 00-.181.827.735.735 0 001.138.311.559.559 0 01.621-.038z\"/><path d=\"M6.186 13.071c.123.057.123.14.189.319-.452.961-1.234 3.1-.251 4.823C6.988 19.731 8.965 20.5 12 20.5s5.012-.769 5.876-2.287c.983-1.725.2-3.862-.251-4.823.067-.182.067-.263.189-.319a2.233 2.233 0 00.924-.8 3.091 3.091 0 00.511-1.628 1.958 1.958 0 00-.714-1.653 2.234 2.234 0 00-.261-4.09 6.468 6.468 0 00-12.548 0 2.234 2.234 0 00-.259 4.091 1.953 1.953 0 00-.716 1.652 3.091 3.091 0 00.511 1.628 2.233 2.233 0 00.924.8zm.064-2.461a.745.745 0 01.089-.423.747.747 0 00.439-.687.674.674 0 00-.054-.25h10.57c-.011.028-.035.047-.042.077a.748.748 0 00.394.843.634.634 0 01.1.44 1.585 1.585 0 01-.161.654.906.906 0 01-.4.442 1.752 1.752 0 00-1 1.306v.05A2.334 2.334 0 0115 13.5c-.183 0-.3-.1-.577-.4A2.957 2.957 0 0012 12a2.957 2.957 0 00-2.423 1.1c-.28.3-.394.4-.577.4a2.334 2.334 0 01-1.193-.438v-.05a1.752 1.752 0 00-1-1.306.916.916 0 01-.4-.452 1.6 1.6 0 01-.157-.644zm7-4.36a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-2.5a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5zm2 .5a.5.5 0 01.5-.5h1.75a.75.75 0 010 1.5h-1.75a.5.5 0 01-.5-.5zm-8.75-.5h1.75a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5H6.5a.75.75 0 010-1.5zm5.369 11.5h-.006a3.154 3.154 0 01-1.5-.364.75.75 0 11.731-1.31 1.576 1.576 0 00.771.174 1.613 1.613 0 00.764-.172.75.75 0 11.734 1.307 3.1 3.1 0 01-1.494.365z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});