define("ember-svg-jar/inlined/transform-direct-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transform-direct-select</title><path d=\"M18.682 7.035l-18-7a.5.5 0 00-.649.642l7 18.5a.5.5 0 00.926.024l3.348-7.655a.5.5 0 01.247-.252l7.158-3.34a.5.5 0 00-.03-.919zM17 14h-3a1 1 0 100 2h3a5.006 5.006 0 015 5v2a1 1 0 002 0v-2a7.008 7.008 0 00-7-7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});