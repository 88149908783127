define("ember-svg-jar/inlined/cat-cat-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cat-cat-ball</title><path d=\"M2.25 12a17.487 17.487 0 00-.466-4.173.25.25 0 00-.475-.027 11.33 11.33 0 00.006 8.424.25.25 0 00.475-.034A18.024 18.024 0 002.25 12zM21.75 12a18.024 18.024 0 00.46 4.185.25.25 0 00.475.034 11.333 11.333 0 00.008-8.42.251.251 0 00-.476.032A17.659 17.659 0 0021.75 12zM10.961.83a.249.249 0 00-.083-.187.254.254 0 00-.2-.062 11.5 11.5 0 00-8.044 4.763.254.254 0 00-.025.247A16.4 16.4 0 013.75 12a16.972 16.972 0 01-1.138 6.414.249.249 0 00.025.246 11.5 11.5 0 008.046 4.759.254.254 0 00.2-.062.249.249 0 00.083-.187zm-4.5 10.42h1.958a.75.75 0 010 1.5H6.461a.75.75 0 110-1.5zM6.14 6.536a11.958 11.958 0 012.133-.762.75.75 0 11.376 1.452 10.417 10.417 0 00-1.866.666.75.75 0 01-.643-1.356zm3.006 11.188a.752.752 0 01-.916.535 11.786 11.786 0 01-2.092-.753.75.75 0 01.647-1.354 10.321 10.321 0 001.825.656.75.75 0 01.536.916zM20.25 12a16.356 16.356 0 011.15-6.4.249.249 0 00-.025-.247A11.5 11.5 0 0013.238.575a.252.252 0 00-.277.249v22.352a.251.251 0 00.277.249 11.5 11.5 0 008.125-4.765.25.25 0 00.025-.246A16.972 16.972 0 0120.25 12zm-2.789.75H15.5a.75.75 0 010-1.5h1.958a.75.75 0 010 1.5zm.324 4.756a11.769 11.769 0 01-2.093.753.75.75 0 11-.379-1.451 10.321 10.321 0 001.825-.656.75.75 0 01.647 1.354zm-3.05-11.194a.749.749 0 01.914-.538 11.941 11.941 0 012.134.762.75.75 0 01-.643 1.356 10.4 10.4 0 00-1.867-.666.751.751 0 01-.538-.914z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});