define("ember-svg-jar/inlined/people-man-graduate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-graduate</title><path d=\"M12.494 22.338a.5.5 0 01-.738 0L7.783 18a.246.246 0 00-.233-.076 14.359 14.359 0 00-5.959 2.49 2.464 2.464 0 00-.966 1.976v1.11a.5.5 0 00.5.5h22a.5.5 0 00.5-.5v-1.106a2.465 2.465 0 00-.967-1.977 14.363 14.363 0 00-5.958-2.489.248.248 0 00-.233.076zM1.125 8.5a.381.381 0 00.016.1.25.25 0 01-.015.168A8.893 8.893 0 00.375 12.5a.75.75 0 00.75.75h2a.75.75 0 00.75-.75 8.893 8.893 0 00-.751-3.73.256.256 0 01-.015-.17.381.381 0 00.016-.1V5.68a.25.25 0 00-.195-.244L1.43 5.1a.25.25 0 00-.211.048.253.253 0 00-.094.2z\"/><path d=\"M12.347 7.475a1 1 0 01-.444 0L5.43 6a.25.25 0 00-.211.048.253.253 0 00-.094.2V11a7 7 0 0014 0V6.248a.253.253 0 00-.094-.2A.25.25 0 0018.82 6zM12.125 16a5.052 5.052 0 01-5-5V9.255a.249.249 0 01.312-.242 19.2 19.2 0 004.688.487 19.2 19.2 0 004.688-.487.249.249 0 01.312.242V11a5.052 5.052 0 01-5 5z\"/><circle cx=\"14.125\" cy=\"11.25\" r=\"1\"/><circle cx=\"10.125\" cy=\"11.25\" r=\"1\"/><path d=\"M13.2 13.362h-2a.64.64 0 00-.3 1.2 2.376 2.376 0 00.435.179 3.3 3.3 0 00.873.126 2.976 2.976 0 00.863-.125 2.3 2.3 0 00.436-.181.639.639 0 00-.3-1.2zM.647 3.131a.5.5 0 00.367.357l11 2.5a.519.519 0 00.222 0l11-2.5a.5.5 0 00.367-.357.513.513 0 00.022-.131.5.5 0 00-.389-.488l-11-2.5a.519.519 0 00-.222 0l-11 2.5A.5.5 0 00.625 3a.513.513 0 00.022.131z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});