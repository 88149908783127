define("ember-svg-jar/inlined/folder-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-check</title><path d=\"M17.5 9.5a7.914 7.914 0 013.153.657.244.244 0 00.347-.222V4.5A1.5 1.5 0 0019.5 3H9.618a.5.5 0 01-.447-.276L8.223.829A1.5 1.5 0 006.882 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h7.539a.452.452 0 00.461-.5 8.009 8.009 0 018-8z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.273 5.2l-2.905 3.874a1.491 1.491 0 01-1.095.6h-.109a1.5 1.5 0 01-1.057-.442l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.25.25 0 00.377-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});