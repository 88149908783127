define("ember-svg-jar/inlined/disability-sit-pregnancy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>disability-sit-pregnancy</title><circle cx=\"9.73\" cy=\"3\" r=\"2.5\"/><path d=\"M12.98 18.745H8.564a1 1 0 01-.973-.77l-1.637-6.958a1 1 0 00-1.947.457l1.636 6.959a2.992 2.992 0 002.921 2.312h4.416a1 1 0 000-2z\"/><path d=\"M20.013 16.918l-.143-.857a7.5 7.5 0 00-1.07-2.885A7.258 7.258 0 0011.257 10L11.1 8.783a1.5 1.5 0 00-2.969.434l.766 5.247a3.512 3.512 0 003.464 3.036h2.423a.493.493 0 01.5.44l.335 4.18a1.5 1.5 0 102.99-.24l-.336-4.38h1.247a.5.5 0 00.493-.582z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});