define("ember-svg-jar/inlined/user-network", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>user-network</title><path d=\"M21.49 0a2.5 2.5 0 00-2.5 2.5 2.476 2.476 0 00.131.8.252.252 0 01-.061.258l-2.27 2.249a1 1 0 101.41 1.42l2.3-2.279a.251.251 0 01.251-.061A2.5 2.5 0 1021.49 0zM21.5 19a2.5 2.5 0 00-.8.131.248.248 0 01-.258-.061l-1.784-1.8a1 1 0 10-1.418 1.41l1.809 1.821a.251.251 0 01.062.251A2.474 2.474 0 0019 21.5a2.5 2.5 0 102.5-2.5zM7.211 5.807l-2.27-2.252A.252.252 0 014.88 3.3a2.476 2.476 0 00.131-.8 2.5 2.5 0 10-1.756 2.387.25.25 0 01.251.061L5.8 7.227a1 1 0 101.408-1.42zM2.5 24A2.5 2.5 0 005 21.5a2.462 2.462 0 00-.114-.743.25.25 0 01.062-.251l1.81-1.821a1 1 0 00-1.418-1.411l-1.786 1.8a.246.246 0 01-.257.061A2.5 2.5 0 102.5 24zM21.49 9a2.5 2.5 0 00-2.226 1.364.251.251 0 01-.222.136H17.99a1 1 0 000 2h1.052a.25.25 0 01.222.137A2.5 2.5 0 1021.49 9zM4.959 10.5a.251.251 0 01-.222-.136 2.5 2.5 0 100 2.273.25.25 0 01.222-.137h1.052a1 1 0 000-2zM9.143 7.12A2.857 2.857 0 1012 4.263 2.857 2.857 0 009.143 7.12zM8.166 16.263h7.668a1.143 1.143 0 001.1-1.469 5.145 5.145 0 00-9.86 0 1.144 1.144 0 001.1 1.469z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});