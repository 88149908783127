define("ember-svg-jar/inlined/people-woman-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-woman-2</title><path d=\"M10.481 15.178h3.038a16.614 16.614 0 017.3 1.615.249.249 0 00.221 0l.092-.045a1 1 0 00.311-1.547A8.035 8.035 0 0119.5 9.965V7.5a7.5 7.5 0 00-15 0v2.465A8.04 8.04 0 012.562 15.2a1 1 0 00.31 1.548l.106.053a.22.22 0 00.194 0 16.649 16.649 0 017.309-1.623zM7.5 7.745a.25.25 0 01.239-.25A5.453 5.453 0 0011.8 5.43a.251.251 0 01.392 0A5.451 5.451 0 0016.26 7.5a.251.251 0 01.24.25V9.5a4.5 4.5 0 01-9 0z\"/><path d=\"M23 20.394a1.976 1.976 0 00-.77-1.579c-.981-.749-4.735-2.691-4.931-1.964-.816 3.024-4.367 5.777-5.139 6.346a.251.251 0 01-.3 0c-.772-.569-4.328-3.325-5.14-6.351a.251.251 0 00-.3-.177 13.135 13.135 0 00-4.65 2.146A1.976 1.976 0 001 20.394v3.356a.25.25 0 00.25.25h21.5a.25.25 0 00.25-.25z\"/><circle cx=\"14\" cy=\"8.5\" r=\"1\"/><circle cx=\"10\" cy=\"8.5\" r=\"1\"/><path d=\"M10.384 10.985a.626.626 0 00.311.7 2.281 2.281 0 00.426.176 2.935 2.935 0 00.849.124h.006a2.9 2.9 0 00.846-.123 2.321 2.321 0 00.427-.177.627.627 0 00-.3-1.177h-1.958a.625.625 0 00-.607.477z\"/><circle cx=\"9.25\" cy=\"16.938\" r=\".75\"/><circle cx=\"12\" cy=\"20.188\" r=\".75\"/><circle cx=\"10.25\" cy=\"18.938\" r=\".75\"/><circle cx=\"14.75\" cy=\"16.938\" r=\".75\"/><circle cx=\"13.75\" cy=\"18.938\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});