define("ember-svg-jar/inlined/cocktail-coconut", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cocktail-coconut</title><path d=\"M20.18 10.794a3.106 3.106 0 01-.667-.174 4.134 4.134 0 01-.352-.157.5.5 0 00-.449 0 4.384 4.384 0 01-.35.156 3.54 3.54 0 01-3.11-.27 2.027 2.027 0 01-.266-.193c-.017-.014-.032-.03-.047-.045a.5.5 0 00-.309-.157 4.8 4.8 0 01-2.7-1.192 1 1 0 00-.313-.2.989.989 0 00-.722 0 1.015 1.015 0 00-.313.2 5.021 5.021 0 01-5.034.938.5.5 0 01-.254-.732l1.064-1.73A.5.5 0 016.784 7h6.637a.323.323 0 00.231-.548 3.524 3.524 0 01-.237-.265 3.281 3.281 0 01-.507-.875.5.5 0 00-.464-.312H9.731a.5.5 0 01-.449-.279L7.689 1.488A3 3 0 003.434.5L.7 2.325a1 1 0 001.11 1.664l2.733-1.823a1 1 0 011.419.33l1.056 2.143A.25.25 0 016.794 5h-.289a2.021 2.021 0 00-1.712.957C2.8 9.205 1.255 11.19 1.255 15c0 5.162 3.739 8.016 7.238 8.965a1.014 1.014 0 00.262.035h5a1.014 1.014 0 00.262-.035c3.5-.949 7.238-3.8 7.238-8.965a10.734 10.734 0 00-.683-3.887.5.5 0 00-.392-.319z\"/><path d=\"M23.3 5.238a2.052 2.052 0 00.379-1.822 2.032 2.032 0 00-1.352-1.172 2.541 2.541 0 00-1.132-.04.25.25 0 01-.3-.214A2.558 2.558 0 0020.51.924 2.05 2.05 0 0018.894 0a2.033 2.033 0 00-1.532.923 2.554 2.554 0 00-.387 1.065.251.251 0 01-.3.214 2.526 2.526 0 00-1.132.04A2.051 2.051 0 0014.169 3.5a2.036 2.036 0 00.4 1.744 2.574 2.574 0 00.894.7.249.249 0 01.113.347 2.538 2.538 0 00-.313 1.089 2.042 2.042 0 00.763 1.7 2.04 2.04 0 001.784.155 2.559 2.559 0 00.938-.634.249.249 0 01.366 0 2.563 2.563 0 00.939.634 2.052 2.052 0 001.849-.2 2.032 2.032 0 00.7-1.648 2.566 2.566 0 00-.313-1.089.249.249 0 01.113-.347 2.561 2.561 0 00.898-.713zM18.936 6.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});