define("ember-svg-jar/inlined/architecture-window-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>architecture-window-1</title><path d=\"M12 0A10.011 10.011 0 002 10v12a2 2 0 002 2h16a2 2 0 002-2V10A10.011 10.011 0 0012 0zm-.75 7.938a.25.25 0 01-.434.169L7.072 4.042a.246.246 0 01-.065-.19.252.252 0 01.1-.177 7.959 7.959 0 013.865-1.606.25.25 0 01.282.248zm1.5-5.621a.25.25 0 01.282-.248A7.959 7.959 0 0116.9 3.675a.252.252 0 01.1.177.249.249 0 01-.065.19l-3.751 4.065a.25.25 0 01-.434-.169zM5.645 5.14a.251.251 0 01.382-.017l4.106 4.458a.25.25 0 01-.184.419H4.254A.249.249 0 014 9.742 7.966 7.966 0 015.645 5.14zM8 21.5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm0-6a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm6.5 6a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm0-6a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h4a.5.5 0 01.5.5zm5.5 6a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V18a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zm0-6a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5h3a.5.5 0 01.5.5zM14.051 10a.25.25 0 01-.184-.419l4.106-4.458a.249.249 0 01.2-.081.254.254 0 01.187.1 7.966 7.966 0 011.64 4.6.249.249 0 01-.25.258z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});