define("ember-svg-jar/inlined/reception-hotel-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>reception-hotel-bell</title><path d=\"M15.464 14.1H21.4a.494.494 0 00.495-.495 3.466 3.466 0 00-2.382-3.286.5.5 0 01-.34-.47v-.2a.248.248 0 01.248-.247.742.742 0 100-1.484h-1.98a.742.742 0 100 1.484.248.248 0 01.248.247v.2a.5.5 0 01-.34.47 3.466 3.466 0 00-2.382 3.286.494.494 0 00.497.495zM4.506 8.571a5.449 5.449 0 00-3.393 5.037.5.5 0 00.5.495h9.9a.494.494 0 00.487-.495 5.448 5.448 0 00-3.392-5.037.246.246 0 01-.026-.445 4.193 4.193 0 002.181-3.672V1.732A1.734 1.734 0 009.031 0H4.082a1.734 1.734 0 00-1.731 1.732v2.722a4.191 4.191 0 002.181 3.672.247.247 0 01-.026.445zm2.051 4.542a.742.742 0 11.742-.742.743.743 0 01-.742.742zm0-2.474A.742.742 0 117.3 9.9a.743.743 0 01-.743.739zM4.33 4.454a.323.323 0 01.27-.32 11.578 11.578 0 013.913 0 .325.325 0 01.271.32 2.227 2.227 0 01-4.454 0zM22.887 15.588H1.113a.99.99 0 00-.989.989v6.433a.991.991 0 00.989.99h21.774a.991.991 0 00.989-.99v-6.433a.99.99 0 00-.989-.989zm-8.165 6.433a.743.743 0 01-1.485 0v-.743a.494.494 0 00-.495-.494h-1.484a.494.494 0 00-.495.494v.743a.743.743 0 01-1.485 0v-3.959a.743.743 0 011.485 0v.738a.5.5 0 00.495.5h1.484a.5.5 0 00.495-.5v-.742a.743.743 0 011.485 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});