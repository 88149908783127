define("ember-svg-jar/inlined/common-file-award", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-award</title><path d=\"M23.875 4.415A2 2 0 0023.289 3L20.874.586A2 2 0 0019.46 0H7.875a2 2 0 00-2 2v3.787a.246.246 0 00.163.229A2.77 2.77 0 017.415 7.1a.25.25 0 00.46-.137V2.5a.5.5 0 01.5-.5h10.878a.5.5 0 01.354.147l2.121 2.121a.5.5 0 01.147.353V18a.5.5 0 01-.5.5H10.031a.25.25 0 00-.247.288l.2 1.287a.5.5 0 00.494.424h11.4a2 2 0 002-2z\"/><path d=\"M9.327 12.6a.5.5 0 010-.75A1.856 1.856 0 007.508 8.7a.5.5 0 01-.65-.375 1.855 1.855 0 00-3.637 0 .5.5 0 01-.65.376 1.856 1.856 0 00-1.819 3.15.5.5 0 010 .75 1.856 1.856 0 001.821 3.15.509.509 0 01.159-.026.5.5 0 01.49.4 1.855 1.855 0 003.637 0 .5.5 0 01.65-.375 1.856 1.856 0 001.818-3.15zm-4.288 1.651a2.031 2.031 0 112.031-2.03 2.03 2.03 0 01-2.031 2.03z\"/><path d=\"M7.983 16.944a.178.178 0 00-.178-.177c-.1 0-.111.038-.178.177a2.843 2.843 0 01-5.176 0c-.06-.129-.08-.173-.177-.173s-.159.049-.178.173l-.964 6.279a.677.677 0 001.005.69l2.655-1.517a.5.5 0 01.5 0l2.656 1.517a.676.676 0 001-.69z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});