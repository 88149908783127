define("ember-svg-jar/inlined/tools-palette-spatula", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-palette-spatula</title><path d=\"M6.849 15.384a1.03 1.03 0 00-1.415 0L.659 20.159a2.25 2.25 0 003.182 3.182l4.776-4.776a1 1 0 000-1.413zM23.976 3.968A5.185 5.185 0 0020.033.023a1 1 0 00-.922.27l-1.949 1.949a.5.5 0 00.142.807l5.3 2.475a.5.5 0 00.565-.1l.535-.535a.994.994 0 00.272-.921zM21.148 6.5l-5.3-2.475a.5.5 0 00-.565.1L13.62 5.784a.5.5 0 00.142.807l5.3 2.475a.5.5 0 00.565-.1l1.661-1.66a.5.5 0 00-.143-.807zM14.077 13.57L8.775 11.1a.5.5 0 00-.565.1l-.226.226a1.5 1.5 0 000 2.121.5.5 0 010 .706l-.075.075a.5.5 0 000 .707l1.061 1.056a.5.5 0 00.707 0l.074-.075a.5.5 0 01.707 0 1.5 1.5 0 002.122 0l1.639-1.639a.5.5 0 00-.142-.807zM17.607 10.041L12.3 7.566a.5.5 0 00-.565.1l-1.644 1.648a.5.5 0 00.142.807l5.3 2.474a.5.5 0 00.565-.1l1.648-1.648a.5.5 0 00-.142-.807z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});