define("ember-svg-jar/inlined/bin-paper-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bin-paper-2</title><path d=\"M4.2 6h15.61a1 1 0 00.943-1.331 4.531 4.531 0 00-5.1-3.078 4.985 4.985 0 00-7.3 0 4.534 4.534 0 00-5.1 3.078A1 1 0 004.2 6zM22 7H2a1 1 0 000 2h1.093l1.245 12.768A2.444 2.444 0 006.783 24h10.434a2.442 2.442 0 002.444-2.226L20.907 9H22a1 1 0 000-2zM8.934 10.5a1 1 0 011.066.934l.5 7.5A1 1 0 019.566 20H9.5a1 1 0 01-1-.934l-.5-7.5a1 1 0 01.934-1.066zM16 11.566l-.5 7.5a1 1 0 01-1 .934h-.067a1 1 0 01-.932-1.064l.5-7.5a1 1 0 012 .132z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});