define("ember-svg-jar/inlined/signal-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>signal-loading</title><path d=\"M23.707 11.293l-5-5a1 1 0 00-1.414 1.414l4.116 4.116a.25.25 0 010 .354l-4.116 4.116a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414zM2.591 12.177a.25.25 0 010-.354l4.116-4.116a1 1 0 00-1.414-1.414l-5 5a1 1 0 000 1.414l5 5a1 1 0 101.414-1.414z\"/><circle cx=\"12\" cy=\"12\" r=\"1.5\"/><circle cx=\"16.5\" cy=\"12\" r=\"1.5\"/><circle cx=\"7.5\" cy=\"12\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});