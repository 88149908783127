define("ember-svg-jar/inlined/hotel-double-bed-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-double-bed-upload</title><path d=\"M.77 15.5h8.353a.5.5 0 00.477-.352A8.023 8.023 0 0117.25 9.5a7.925 7.925 0 014.883 1.669.5.5 0 00.8-.486l-.7-3.773a.5.5 0 00-.49-.41H2.26a.5.5 0 00-.49.41l-1.49 8a.56.56 0 00.11.41.527.527 0 00.38.18zM9.25 17.5c0-.159 0-.316.013-.472a.5.5 0 00-.5-.528H.5a.5.5 0 00-.5.5v3.25a2.253 2.253 0 002.25 2.25.248.248 0 01.25.25v.5a.75.75 0 001.5 0V23a.5.5 0 01.5-.5h5.547a.5.5 0 00.424-.766A7.911 7.911 0 019.25 17.5zM2.5 5.5h1.576a.5.5 0 00.494-.418l.36-2.165a.5.5 0 01.494-.417H10a.5.5 0 01.5.5v2a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V3a.5.5 0 01.5-.5h4.576a.5.5 0 01.494.417l.36 2.165a.5.5 0 00.494.418H21.5A.5.5 0 0022 5V2.25A2.253 2.253 0 0019.75 0H4.25A2.253 2.253 0 002 2.25V5a.5.5 0 00.5.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.45 6.217a.5.5 0 01-.45.283H19a.5.5 0 00-.5.5v2.5a1 1 0 01-2 0V18a.5.5 0 00-.5-.5h-.5a.5.5 0 01-.391-.812l2-2.5a.519.519 0 01.782 0l2 2.5a.5.5 0 01.059.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});