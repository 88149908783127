define("ember-svg-jar/inlined/shield-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shield-add</title><path d=\"M22.043.006H1.959A1.958 1.958 0 00.012 1.965L0 9.306a15.146 15.146 0 0011.861 14.669 1 1 0 00.4 0A15.145 15.145 0 0023.988 9.2L24 1.953A1.959 1.959 0 0022.043.006zM18 14.506a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5h9a1.5 1.5 0 011.5 1.5z\"/><path d=\"M15.5 9.006h-2a.5.5 0 01-.5-.5v-2a1 1 0 00-2 0v2a.5.5 0 01-.5.5h-2a1 1 0 000 2h2a.5.5 0 01.5.5v2a1 1 0 002 0v-2a.5.5 0 01.5-.5h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});