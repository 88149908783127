define("ember-svg-jar/inlined/baggage-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>baggage-cart</title><circle cx=\"8.25\" cy=\"22\" r=\"2\"/><circle cx=\"19.25\" cy=\"22\" r=\"2\"/><path d=\"M23 17.25H6a1 1 0 01-1-1V4.508A4.228 4.228 0 002.651.707l-1.2-.6A1 1 0 00.553 1.9l1.2.6A2.238 2.238 0 013 4.508V16.25a3 3 0 003 3h17a1 1 0 000-2z\"/><path d=\"M17.25 16h4a1.5 1.5 0 001.5-1.5V5.75a1.5 1.5 0 00-1.5-1.5h-.5a.5.5 0 01-.5-.5V2.5a1 1 0 00-2 0v1.25a.5.5 0 01-.5.5h-.5a1.5 1.5 0 00-1.5 1.5v8.75a1.5 1.5 0 001.5 1.5zM8.25 16h1a.5.5 0 00.5-.5V4.75a.5.5 0 00-.5-.5h-1a1.5 1.5 0 00-1.5 1.5v8.75a1.5 1.5 0 001.5 1.5zM11.25 16h1a1.5 1.5 0 001.5-1.5V5.75a1.5 1.5 0 00-1.5-1.5h-1a.5.5 0 00-.5.5V15.5a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});