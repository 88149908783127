define("ember-svg-jar/inlined/search-engine-bing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-engine-bing</title><path d=\"M12 7a10.79 10.79 0 00-7.25 2.6V2.4a1.25 1.25 0 00-2.5 0v12.47c0 4.38 4.37 7.95 9.75 7.95s9.75-3.57 9.75-7.95S17.38 7 12 7zm0 13.4c-4 0-7.24-2.44-7.25-5.44 0-3 3.26-5.42 7.25-5.42s7.25 2.44 7.25 5.45S16 20.35 12 20.35z\"/><ellipse cx=\"12\" cy=\"14.9\" rx=\"4\" ry=\"3.15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});