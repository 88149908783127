define("ember-svg-jar/inlined/warehouse-storage-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-storage-2</title><path d=\"M2 9.5a1 1 0 00-1 1V23a1 1 0 002 0V10.5a1 1 0 00-1-1zM22 9.5a1 1 0 00-1 1V23a1 1 0 002 0V10.5a1 1 0 00-1-1z\"/><rect x=\"8.5\" y=\"11\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"12.5\" y=\"18\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><rect x=\"4.5\" y=\"18\" width=\"7\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M23.655 3.541C22.707 2.71 19.062 0 12 0S1.293 2.71.345 3.541A1 1 0 000 4.3v3.256a1.018 1.018 0 001.022 1.011h21.956A1.018 1.018 0 0024 7.556V4.3a1 1 0 00-.345-.759z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});