define("ember-svg-jar/inlined/light-mode-hdr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>light-mode-hdr</title><path d=\"M13.5 12a3.256 3.256 0 00-2.166-3.064.25.25 0 00-.334.235v5.658a.25.25 0 00.334.235A3.256 3.256 0 0013.5 12zM20 10a1.252 1.252 0 00-1.25-1.25h-1a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1A1.252 1.252 0 0020 10z\"/><path d=\"M24 7.75A3.755 3.755 0 0020.25 4H3.75A3.755 3.755 0 000 7.75v8.5A3.755 3.755 0 003.75 20h16.5A3.755 3.755 0 0024 16.25zm-2.72 7.72a.75.75 0 11-1.06 1.06l-2.293-2.293a.25.25 0 00-.427.177V16a.75.75 0 01-1.5 0V8a.75.75 0 01.75-.75h2a2.75 2.75 0 01.356 5.476.25.25 0 00-.145.425zM15 12a4.756 4.756 0 01-4.75 4.75.75.75 0 01-.75-.75V8a.75.75 0 01.75-.75A4.756 4.756 0 0115 12zm-10.75.75A.25.25 0 004 13v3a.75.75 0 01-1.5 0V8A.75.75 0 014 8v3a.25.25 0 00.25.25h2A.25.25 0 006.5 11V8A.75.75 0 018 8v8a.75.75 0 01-1.5 0v-3a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});