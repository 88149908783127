define("ember-svg-jar/inlined/content-quill-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-quill-pen</title><path d=\"M11.511 6.126a.254.254 0 00-.032-.385A53.573 53.573 0 00.676.032a.5.5 0 00-.644.644 53.568 53.568 0 005.709 10.8.254.254 0 00.385.031zM19.586 8.658a.5.5 0 000-.707L16.05 4.415a.5.5 0 00-.707 0l-9.922 9.922a1.5 1.5 0 000 2.121l2.121 2.121a1.5 1.5 0 002.121 0zM4.318 17.467a2.744 2.744 0 00-2.433.759c-.9.9-1.66 4.4-1.881 5.5a.23.23 0 00.063.207.227.227 0 00.207.067c1.1-.22 4.6-.978 5.5-1.88a2.746 2.746 0 00-1.456-4.648zM4.28 20.78a.75.75 0 110-1.06.75.75 0 010 1.06zM16.757 3.707l3.536 3.536a.522.522 0 00.674.028c.012-.009 2.154-2.149 2.154-2.149A3 3 0 0018.878.879s-2.145 2.16-2.159 2.179a.494.494 0 00.038.649zM19.613 18.2a4.115 4.115 0 00-.1-3.285 11.779 11.779 0 00-1.272-2.36.258.258 0 00-.392-.04l-5.343 5.342a.26.26 0 00.042.393 7.963 7.963 0 004.278 1.663 3.185 3.185 0 001.375-.295l4.09 4.09a1.01 1.01 0 001.414 0 1 1 0 000-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});