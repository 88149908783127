define("ember-svg-jar/inlined/road-sign-airport-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-airport-close</title><path d=\"M23.53 12.53a.749.749 0 000-1.06l-11-11a.749.749 0 00-1.06 0l-11 11a.749.749 0 000 1.06l11 11a.748.748 0 001.06 0zM6 10.37a1.29 1.29 0 01.077-.44 1.379 1.379 0 01.99-.889 1.292 1.292 0 011.018.2l2.213 1.51a.25.25 0 00.228.028l4.893-1.822a.332.332 0 01.312.043l.889.659a.314.314 0 01.053.05.331.331 0 01-.037.467l-3.316 2.049a.25.25 0 00-.011.417l1.476 1.03a.247.247 0 00.271.01l1.337-.8a.258.258 0 01.295.022l1.212 1.068a.24.24 0 01.045.045.266.266 0 01-.04.374l-2.879 2.02a.481.481 0 01-.532.016l-7.857-4.876A1.364 1.364 0 016 10.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});