define("ember-svg-jar/inlined/road-sign-school-bus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-school-bus</title><path d=\"M18.25 7.5h-10a1 1 0 00-1 1v3.75a.25.25 0 01-.25.25H5.25a1 1 0 00-1 1v1a1 1 0 001 1h.05a.252.252 0 00.223-.136 2.494 2.494 0 014.454.015.219.219 0 00.2.121h3.146a.22.22 0 00.2-.121 2.494 2.494 0 014.454-.015.252.252 0 00.223.136h.054a1 1 0 001-1v-6a1 1 0 00-1.004-1zM10 12a.5.5 0 01-.5.5h-.75a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5H9a1 1 0 011 1zm1.75-.25a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm6.384 17a.252.252 0 00-.228.182 2.5 2.5 0 01-4.811 0A.251.251 0 0013.1 17h-2.7a.251.251 0 00-.241.182 2.5 2.5 0 01-4.812 0A.25.25 0 005.116 17a2.52 2.52 0 01-2.366-2.5v-1a2.5 2.5 0 012.5-2.5h.25a.25.25 0 00.25-.25V8.5A2.5 2.5 0 018.25 6h10a2.5 2.5 0 012.5 2.5v6a2.529 2.529 0 01-2.366 2.5z\"/><path d=\"M8.86 15.5a1.483 1.483 0 00-2.221 0 1.5 1.5 0 102.22 0zM16.86 15.5a1.483 1.483 0 00-2.221 0 1.5 1.5 0 102.22 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});