define("ember-svg-jar/inlined/sql", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sql</title><path d=\"M12 9.626a.876.876 0 00-.875.875V13a.875.875 0 001.75 0v-2.5A.876.876 0 0012 9.626z\"/><path d=\"M22.5 4.5h-21A1.5 1.5 0 000 6v12a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 18V6a1.5 1.5 0 00-1.5-1.5zM6.427 10.868l1.84 1.226A1.928 1.928 0 017.2 15.626H5.5a.625.625 0 110-1.25h1.7a.678.678 0 00.376-1.242l-1.84-1.226A1.928 1.928 0 016.8 8.376h1.7a.625.625 0 010 1.25H6.8a.678.678 0 00-.376 1.242zM14.125 13a2.114 2.114 0 01-.974 1.774.1.1 0 00.01.159l.727.577a.634.634 0 01.1.878.626.626 0 01-.878.1l-1.93-1.53A2.126 2.126 0 019.875 13v-2.5a2.125 2.125 0 114.25 0zm4.375 2.626h-2A1.627 1.627 0 0114.875 14V9a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});