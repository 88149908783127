define("ember-svg-jar/inlined/warehouse-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>warehouse-settings</title><path d=\"M8.838 9.272a.25.25 0 01.307.037l2.4 2.4a1 1 0 001.414-1.414l-2.4-2.4a.25.25 0 01-.037-.307 5.014 5.014 0 10-1.684 1.684zM3.25 5a3 3 0 113 3 3 3 0 01-3-3z\"/><path d=\"M21.679 13.628l-1-2.5a1 1 0 00-1.858.744l.315.785A.25.25 0 0118.9 13H5.6a.25.25 0 01-.232-.343l.086-.214a1 1 0 00-1.861-.743l-.772 1.928a.987.987 0 00-.063.333c0 .014-.008.025-.008.039v8.05a1.978 1.978 0 002 1.95h15a2 2 0 002-2v-8c0-.014-.007-.025-.008-.038a.988.988 0 00-.063-.334zM18.75 20v1a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h5a.5.5 0 01.5.5zM22.733 5.938a.5.5 0 00-.233-.3l-.409-.238a.251.251 0 01-.121-.265 3.125 3.125 0 000-1.267.249.249 0 01.121-.264l.408-.236a.5.5 0 00.233-.3.5.5 0 00-.05-.379l-.5-.866a.5.5 0 00-.683-.183l-.409.236a.252.252 0 01-.287-.026 3.483 3.483 0 00-1.1-.628.25.25 0 01-.166-.236V.5a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.481a.251.251 0 01-.167.236 3.483 3.483 0 00-1.1.628.252.252 0 01-.287.026l-.409-.236a.5.5 0 00-.683.183l-.5.866a.5.5 0 00-.05.379.5.5 0 00.233.3l.408.236a.249.249 0 01.121.264 3.125 3.125 0 000 1.267.251.251 0 01-.121.265l-.409.236a.5.5 0 00-.233.3.5.5 0 00.05.38l.5.866a.5.5 0 00.3.233.5.5 0 00.379-.05l.41-.237a.25.25 0 01.287.026 3.492 3.492 0 001.1.627.251.251 0 01.167.236V8.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.481a.25.25 0 01.166-.236 3.476 3.476 0 001.1-.627.25.25 0 01.287-.026l.411.237a.5.5 0 00.683-.183l.5-.866a.5.5 0 00.053-.38zM18.536 6a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});