define("ember-svg-jar/inlined/ui-phone-slider-vertical-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ui-phone-slider-vertical-alternate</title><path d=\"M17 22H7a1 1 0 000 2h10a1 1 0 000-2zM12 21.25a.744.744 0 00.53-.22l1-1a.75.75 0 00-.53-1.28h-2a.75.75 0 00-.53 1.28l1 1a.744.744 0 00.53.22zM18 1a1 1 0 00-1-1H7a1 1 0 000 2h10a1 1 0 001-1zM11.47 2.97l-1 1A.75.75 0 0011 5.25h2a.75.75 0 00.53-1.28l-1-1a.749.749 0 00-1.06 0z\"/><rect x=\"5.25\" y=\"6.25\" width=\"13.5\" height=\"11.5\" rx=\"1.75\" ry=\"1.75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});