define("ember-svg-jar/inlined/landmark-saint-basil-s-cathderal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-saint-basil&amp;apos;s-cathderal</title><path d=\"M24 14.484a.5.5 0 00-.5-.5h-1.25a.25.25 0 01-.25-.25V6.962a.248.248 0 01.1-.2 2.428 2.428 0 00.9-1.928c0-1.14-1.28-1.85-2.08-3.12a.5.5 0 00-.84 0c-.8 1.27-2.08 1.98-2.08 3.12a2.428 2.428 0 00.9 1.931.248.248 0 01.1.2v1.903a.249.249 0 00.063.166A2.929 2.929 0 0120 11a2.8 2.8 0 01-.918 2.138.252.252 0 00-.082.185v.407a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.1a.249.249 0 01.129-.219A1.629 1.629 0 0019 10.984c0-.881-.953-1.31-1.593-2.208a.5.5 0 00-.814 0C15.953 9.674 15 10.1 15 10.984a1.629 1.629 0 00.871 1.436.249.249 0 01.129.219v1.1a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1a.245.245 0 00-.04-.134A2.9 2.9 0 0114 11a2.379 2.379 0 01.458-1.368.258.258 0 00.042-.132V5.414a.252.252 0 01.1-.2 2.087 2.087 0 00.9-1.728 2.434 2.434 0 00-.99-1.74A3.527 3.527 0 0113.47.324a.513.513 0 00-.94 0 3.527 3.527 0 01-1.04 1.42 2.434 2.434 0 00-.99 1.74 2.087 2.087 0 00.9 1.728.252.252 0 01.1.2v13.07a.75.75 0 01-1.5 0v-8.143a.247.247 0 01.079-.182A2.934 2.934 0 0011 7.984a3.344 3.344 0 00-1.207-2.217 5.415 5.415 0 01-1.318-1.941.521.521 0 00-.95 0 5.415 5.415 0 01-1.318 1.941A3.344 3.344 0 005 7.984a2.934 2.934 0 00.921 2.173.247.247 0 01.079.182v.367a.252.252 0 00.062.165c.88 1 1.938 1.646 1.938 3.305a3.493 3.493 0 01-.942 2.239.251.251 0 00-.058.16v2.159a.75.75 0 01-1.5 0v-2.822a.25.25 0 01.1-.2 2.073 2.073 0 00.9-1.725 2.433 2.433 0 00-.992-1.738 3.545 3.545 0 01-1.033-1.42.521.521 0 00-.95 0 3.545 3.545 0 01-1.033 1.42 2.433 2.433 0 00-.992 1.735 2.073 2.073 0 00.9 1.725.25.25 0 01.1.2v.554a.252.252 0 01-.166.236A3.49 3.49 0 000 19.984v3a1 1 0 001 1h16.25a.25.25 0 00.25-.25v-2.59a1.988 1.988 0 01.371-1.161l2-2.8a2 2 0 013.258 0l.417.583a.25.25 0 00.454-.144zm-8.5 7.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2a1.5 1.5 0 013 0z\"/><path d=\"M21.907 18.053a.5.5 0 00-.814 0l-2 2.8a.506.506 0 00-.093.291v2.34a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-2.34a.506.506 0 00-.093-.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});