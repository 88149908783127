define("ember-svg-jar/inlined/segway", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>segway</title><path d=\"M16.472 1.986L13.367.434a3.017 3.017 0 00-2.683 0L7.578 1.986a1 1 0 00.448 1.895.991.991 0 00.446-.106l2.191-1.095a.25.25 0 01.362.223v15.216a.25.25 0 01-.25.25H8.311a.249.249 0 00-.248.221l-.229 2a.251.251 0 00.248.279h7.836a.251.251 0 00.248-.279l-.229-2a.249.249 0 00-.248-.221h-2.414a.25.25 0 01-.25-.25V2.9a.25.25 0 01.362-.223l2.191 1.095a1 1 0 00.894-1.789zM4.62 13.431a2.749 2.749 0 00-3.04 2.42l-.56 4.97a2.745 2.745 0 002.42 3.04c.11.01.21.02.31.02a2.755 2.755 0 002.73-2.44l.57-4.97a2.752 2.752 0 00-2.43-3.04zM22.98 20.821l-.56-4.97a2.749 2.749 0 00-3.04-2.42 2.752 2.752 0 00-2.43 3.04l.57 4.97a2.755 2.755 0 002.73 2.44c.1 0 .21-.01.31-.02a2.745 2.745 0 002.42-3.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});