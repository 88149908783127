define("ember-svg-jar/inlined/file-copyright-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-copyright-user</title><path d=\"M23.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.276a.248.248 0 00.242.249 19.68 19.68 0 011.485.083A.247.247 0 008 9.359V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-6.859a.245.245 0 00-.24.21 7.935 7.935 0 01-.364 1.458.244.244 0 00.228.331H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M6.5 11a6.5 6.5 0 106.5 6.5A6.508 6.508 0 006.5 11zm3.716 9.85a.265.265 0 01-.017.348 5.184 5.184 0 01-7.4 0 .264.264 0 01-.016-.347 5.007 5.007 0 012.509-1.685.241.241 0 00.049-.449 2.513 2.513 0 01-1.224-3 2.382 2.382 0 011.716-1.633 2.5 2.5 0 011.815 4.635.243.243 0 00.065.446 5.009 5.009 0 012.503 1.685z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});