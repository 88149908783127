define("ember-svg-jar/inlined/lucide-bomb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"11\" cy=\"13\" r=\"9\"/><path d=\"M19.5 9.5l1.8-1.8a2.4 2.4 0 000-3.4l-1.6-1.6a2.41 2.41 0 00-3.4 0l-1.8 1.8M22 2l-1.5 1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});