define("ember-svg-jar/inlined/bicycle-lock-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bicycle-lock-key</title><path d=\"M24 19.125a4.881 4.881 0 00-4.875-4.875c-.144 0-.279.03-.42.042L17.06 9.453l.933-.233a1 1 0 10-.486-1.94l-1.458.365A1.5 1.5 0 0015 9.6l.395 1.161a.25.25 0 01-.15.315l-5.056 1.861a.251.251 0 01-.311-.124l-.523-1.063H9.5a1 1 0 000-2h-3a1 1 0 000 2h.627l1.449 2.95a1 1 0 00.9.56A29.069 29.069 0 0013.088 14a.25.25 0 01.287.384L10.5 18.25h-.838a4.884 4.884 0 10-.056 2h1.387a1.172 1.172 0 00.81-.4l4.3-5.784a.25.25 0 01.437.069l.252.739A4.865 4.865 0 1024 19.125zM4.875 22A2.875 2.875 0 117.6 18.25H5.123a1 1 0 000 2h2.4A2.88 2.88 0 014.875 22zm14.25 0a2.868 2.868 0 01-1.678-5.2l.857 2.521a1 1 0 001.892-.644l-.816-2.4A2.867 2.867 0 0119.125 22zM8.093 8a4 4 0 003.7-2.5l6.081.007a.5.5 0 00.354-.146l.935-.935a.5.5 0 00.02-.685l-.952-1.073a.5.5 0 00-.374-.168H11.8A4 4 0 108.093 8zM7 2.939a1.5 1.5 0 110 2.122 1.5 1.5 0 010-2.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});