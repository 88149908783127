define("ember-svg-jar/inlined/science-cells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-cells</title><path d=\"M4.375 12.5v-1a1 1 0 00-2 0v1a1 1 0 002 0zM8.338 19.365l-.866-.5a1 1 0 00-1 1.732l.866.5a1 1 0 101-1.732zM16.265 19.365l-.865.5a1 1 0 101 1.732l.865-.5a1 1 0 10-1-1.732zM20.229 12.5v1a1 1 0 002 0v-1a1 1 0 00-2 0zM16.265 5.635l.866.5a1 1 0 101-1.732l-.866-.5a1 1 0 00-1 1.732zM8.338 5.635l.866-.5A1 1 0 108.2 3.4l-.866.5a1 1 0 101 1.732zM4.125 7.313a1 1 0 110-1.324 1 1 0 101.5-1.328 3 3 0 10.006 3.974 1 1 0 10-1.5-1.322zM4.125 18.063a1 1 0 110-1.324 1 1 0 101.5-1.328 3 3 0 10.006 3.974 1 1 0 10-1.5-1.322zM21.125 10.9a3 3 0 002.25-1.017 1 1 0 00-1.5-1.322 1 1 0 110-1.324 1 1 0 101.5-1.328 3 3 0 10-2.25 4.991zM21.875 19.063a1 1 0 110-1.324 1 1 0 101.5-1.328 3 3 0 10.006 3.974 1 1 0 00-1.5-1.322zM12.874 6a3 3 0 002.25-1.016 1 1 0 10-1.5-1.324 1 1 0 110-1.323 1 1 0 001.5-1.327A3 3 0 1012.874 6zM12.875 21.661a1 1 0 110-1.324 1 1 0 001.5-1.327 3 3 0 10.006 3.973 1 1 0 10-1.5-1.322z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});