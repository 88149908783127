define("ember-svg-jar/inlined/arrow-double-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-double-up</title><path d=\"M18.687 6.355L12.708.694a1 1 0 00-1.372 0L5.315 6.353A1.008 1.008 0 005 7.082v4.761a1 1 0 001.686.729l5.164-4.857a.25.25 0 01.343 0l5.119 4.848a1 1 0 001.084.192.986.986 0 00.6-.918V7.081a1 1 0 00-.309-.726z\"/><path d=\"M18.688 17.089l-5.98-5.662a1 1 0 00-1.372 0l-6.021 5.661a1.006 1.006 0 00-.315.728v4.761a.985.985 0 00.6.917 1 1 0 00.4.085.991.991 0 00.681-.274l5.164-4.856a.251.251 0 01.343 0l5.124 4.851a1 1 0 001.084.193.987.987 0 00.6-.919v-4.759a1 1 0 00-.308-.726z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});