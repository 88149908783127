define("ember-svg-jar/inlined/gaming-treasure-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gaming-treasure-add</title><path d=\"M3.75 7H.5a.5.5 0 00-.5.5V14a2.5 2.5 0 002.5 2.5h1.25a.5.5 0 00.5-.5V7.5a.5.5 0 00-.5-.5zM20.5 7h-3.25a.5.5 0 00-.5.5V9a.5.5 0 00.515.5 7.967 7.967 0 013.058.512.5.5 0 00.677-.465V7.5a.5.5 0 00-.5-.5zM14.912 9.93a.5.5 0 00.338-.473V7.5a.5.5 0 00-.5-.5H13.5a.5.5 0 00-.5.5v1a2.5 2.5 0 01-5 0v-1a.5.5 0 00-.5-.5H6.25a.5.5 0 00-.5.5V16a.5.5 0 00.5.5h2.883a.5.5 0 00.492-.412 8.026 8.026 0 015.287-6.158zM4.5 0h-1A3.5 3.5 0 000 3.5V5a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5V2.5A5.329 5.329 0 014.722.355.25.25 0 004.5 0zM6.25 5.5h8.5a.5.5 0 00.5-.5V2.5a3.349 3.349 0 00-.829-2.228A1 1 0 0013.735 0h-6.47a1 1 0 00-.686.272A3.351 3.351 0 005.75 2.5V5a.5.5 0 00.5.5zM16.75 2.5V5a.5.5 0 00.5.5h3.25A.5.5 0 0021 5V3.5A3.5 3.5 0 0017.5 0h-1a.25.25 0 00-.226.355A5.329 5.329 0 0116.75 2.5z\"/><circle cx=\"10.5\" cy=\"8.5\" r=\"1\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3 7.5H19a.5.5 0 00-.5.5v1.5a1 1 0 01-2 0V19a.5.5 0 00-.5-.5h-1.5a1 1 0 010-2H16a.5.5 0 00.5-.5v-1.5a1 1 0 012 0V16a.5.5 0 00.5.5h1.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});