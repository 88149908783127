define("ember-svg-jar/inlined/party-music-dance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-music-dance</title><path d=\"M2.532 8.7a2.517 2.517 0 00.274.016 2.408 2.408 0 002.376-2.01.931.931 0 00.028-.094l.382-3.422A.25.25 0 016 3.027l.87.732a1 1 0 101.29-1.531L6.217.594a1.5 1.5 0 00-2.457.981l-.235 2.108a.251.251 0 01-.1.173.245.245 0 01-.2.044q-.077-.015-.156-.024A2.426 2.426 0 102.532 8.7zM6.84 13.8a1 1 0 00-.591-1.284L5.121 12.1a1.5 1.5 0 00-1.983 1.752l.206.865a.25.25 0 01-.119.275 1.686 1.686 0 102.481 1.071c-.023-.092-.254-1.125-.343-1.5a.146.146 0 01.193-.17A1 1 0 006.84 13.8z\"/><circle cx=\"15.625\" cy=\"4.258\" r=\"2.5\"/><path d=\"M22.125 7.477a1.5 1.5 0 00-.937.328l-.725.58a2.5 2.5 0 01-2.44.389 6.83 6.83 0 00-4.8 0l-.321.121a2.487 2.487 0 01-2.163-.2L9.9 8.191a1.5 1.5 0 00-1.544 2.572l.845.507a5.469 5.469 0 004.762.433.3.3 0 01.106-.019.3.3 0 01.3.272l.13 1.3a.5.5 0 01-.274.5l-1.555.778a5.47 5.47 0 00-3.041 4.92v2.028a1.5 1.5 0 103 0v-2.033a2.485 2.485 0 011.382-2.236l.407-.2a.144.144 0 01.142.006.147.147 0 01.069.124 5.531 5.531 0 001.105 3.306l2.04 2.713a1.5 1.5 0 102.4-1.8l-2.041-2.714a2.513 2.513 0 01-.5-1.5v-2.671l-.007-.15-.194-1.937a.5.5 0 01.5-.55.566.566 0 01.082.006 5.517 5.517 0 004.333-1.118l.725-.58a1.5 1.5 0 00-.937-2.671z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});