define("ember-svg-jar/inlined/style-two-pin-gym", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-gym</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.547 6.538 12.488 8.541 14.153a1.5 1.5 0 001.917 0c2-1.665 8.542-7.608 8.542-14.154A9.511 9.511 0 0012 0zm7 10.25h-1a.5.5 0 00-.5.5v.75a1.75 1.75 0 01-3.5 0v-.75a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5v.75a1.75 1.75 0 01-3.5 0v-.75a.5.5 0 00-.5-.5H5a.75.75 0 010-1.5h1a.5.5 0 00.5-.5V7.5a1.75 1.75 0 013.5 0v.75a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V7.5a1.75 1.75 0 013.5 0v.75a.5.5 0 00.5.5h1a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});