define("ember-svg-jar/inlined/garbage-bin-throw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>garbage-bin-throw</title><circle cx=\"17\" cy=\"2.749\" r=\"2.5\"/><path d=\"M9 12.749H2.567a.249.249 0 01-.237-.329l2.118-6.354a1 1 0 10-1.9-.633l-2.5 7.5A1 1 0 001 14.249v4.8a.251.251 0 00.3.246 2.327 2.327 0 01.453-.046A2.754 2.754 0 014.5 22a2.68 2.68 0 01-.38 1.373.25.25 0 00.215.377H7.65a.993.993 0 00.988-.851l1.35-9A1 1 0 009 12.749z\"/><circle cx=\"1.75\" cy=\"21.999\" r=\"1.75\"/><path d=\"M7 11.749A2.25 2.25 0 104.75 9.5 2.253 2.253 0 007 11.749zm0-3a.75.75 0 11-.75.75.75.75 0 01.75-.75z\"/><circle cx=\"10.75\" cy=\"6.749\" r=\"1.25\"/><path d=\"M23.946 21.851l-4-14.5a1.5 1.5 0 00-2.788-.273l-.2.395a6.02 6.02 0 01-4.648 3.288 1.5 1.5 0 00.373 2.977A9.018 9.018 0 0017.6 11.5a.25.25 0 01.408.12l.5 1.828a.252.252 0 01-.061.24 14.453 14.453 0 00-3.945 8.4 1.5 1.5 0 102.982.33 11.45 11.45 0 011.721-4.872.25.25 0 01.452.068l1.389 5.038a1.5 1.5 0 102.893-.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});