define("ember-svg-jar/inlined/shop-like-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shop-like-circle</title><path d=\"M19.1 6.838a.254.254 0 00-.183-.079.248.248 0 00-.183.08 3.729 3.729 0 01-5.408 0 .248.248 0 00-.182-.08.251.251 0 00-.183.079 3.726 3.726 0 01-5.407 0 .251.251 0 00-.366 0 3.73 3.73 0 01-3.64 1.043.124.124 0 00-.107.021A.126.126 0 003.4 8v1.41a.251.251 0 01-.1.2 8 8 0 1011.658 10.511.25.25 0 01.214-.121h6.681a.249.249 0 00.25-.25V8.25A.249.249 0 0021.85 8h-.044A3.687 3.687 0 0119.1 6.838zM8.1 22a6 6 0 116-6 6.007 6.007 0 01-6 6z\"/><path d=\"M4.652 19.046A6 6 0 007.834 20c.152 0 .315 0 .5-.007.251-.006.542-.012.891-.012a2.211 2.211 0 002.155-1.852l.648-2.162.049-.174a1.2 1.2 0 00-1.177-1.475H9.16a.21.21 0 01-.166-.082.2.2 0 01-.035-.174l.312-1.179a1.108 1.108 0 00-.21-.976 1.148 1.148 0 00-1.763.059l-1.73 2.44a.21.21 0 01-.171.088H5.1a1 1 0 00-1 1v2.656a1 1 0 00.552.896zM23.822 5.163a1.155 1.155 0 00-.01-.842L22.154.172A.3.3 0 0021.87 0H4.424a.3.3 0 00-.283.171L2.48 4.32a1.162 1.162 0 00-.008.843A2.134 2.134 0 004.487 6.5a2.074 2.074 0 002.137-2 .75.75 0 111.5 0 2.074 2.074 0 002.137 2 2.073 2.073 0 002.135-2 .75.75 0 011.5 0 2.142 2.142 0 004.274 0 .75.75 0 011.5 0 2.073 2.073 0 002.136 2 2.137 2.137 0 002.016-1.337z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});