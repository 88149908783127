define("ember-svg-jar/inlined/multiple-actions-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-share-1</title><circle cx=\"9.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.25 15a.25.25 0 00.25-.25V14a6.954 6.954 0 011.591-4.429.249.249 0 00.005-.31A4.528 4.528 0 009.5 7.5 4.456 4.456 0 005 11.9v2.6a.5.5 0 00.5.5zM13 15.261a.251.251 0 00.123.216 1.987 1.987 0 01.99 1.716v.557a.25.25 0 00.25.25h.947a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"18.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M1 24a1 1 0 001-1v-5.613a1 1 0 00-2 0V23a1 1 0 001 1zM3.148 17.306a.5.5 0 00-.148.355v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1-.871h-7a.354.354 0 01-.323-.386.372.372 0 01.371-.371H7.5a.888.888 0 00.819-1.241 1.907 1.907 0 00-1.814-1.006l-3-.032a.586.586 0 00-.357.144z\"/><circle cx=\"9.5\" cy=\"3.5\" r=\"3\"/><path d=\"M11.25 15a.25.25 0 00.25-.25V14a6.954 6.954 0 011.591-4.429.249.249 0 00.005-.31A4.528 4.528 0 009.5 7.5 4.456 4.456 0 005 11.9v2.6a.5.5 0 00.5.5zM13 15.261a.251.251 0 00.123.216 1.987 1.987 0 01.99 1.716v.557a.25.25 0 00.25.25h.947a.25.25 0 01.249.229L16 23.542a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313A.25.25 0 0121.69 18h1.81a.5.5 0 00.5-.5V14a5.5 5.5 0 00-11 0z\"/><circle cx=\"18.5\" cy=\"3.5\" r=\"3.5\"/><path d=\"M1 24a1 1 0 001-1v-5.613a1 1 0 00-2 0V23a1 1 0 001 1zM3.148 17.306a.5.5 0 00-.148.355v5.032a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.924v-.2a.971.971 0 00-1-.871h-7a.354.354 0 01-.323-.386.372.372 0 01.371-.371H7.5a.888.888 0 00.819-1.241 1.907 1.907 0 00-1.814-1.006l-3-.032a.586.586 0 00-.357.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});