define("ember-svg-jar/inlined/rhino-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>rhino-body</title><path d=\"M24 8.252a2.933 2.933 0 00-1.415-2.671.755.755 0 00-.959.255 6.8 6.8 0 01-1.506 1.618.25.25 0 01-.281 0 11.658 11.658 0 00-1.158-.7.249.249 0 01-.131-.254 3.276 3.276 0 00-.95-2.6.469.469 0 00-.382-.146.5.5 0 00-.363.187l-1.27 1.591a.249.249 0 01-.258.086A11.758 11.758 0 0012 5.25H5.5a5.506 5.506 0 00-5.5 5.5v9a.5.5 0 00.5.5H3a.5.5 0 00.485-.379l.749-3a.5.5 0 01.6-.364 12.5 12.5 0 005.7.03.5.5 0 01.609.425l.356 2.849a.5.5 0 00.5.437h2.246a.5.5 0 00.491-.41l.732-4.023a.5.5 0 01.76-.333 7.389 7.389 0 00.694.394 3.508 3.508 0 003.666-.295c.822.56.905.6.934.614a.5.5 0 00.578-.095c.967-.967 1.456-3.5.79-5.38A3.044 3.044 0 0024 8.252zm-6.5 2.738a1 1 0 111 1 1 1 0 01-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});