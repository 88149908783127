define("ember-svg-jar/inlined/meeting-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-camera</title><rect x=\".25\" y=\"5.499\" width=\"15\" height=\"13\" rx=\"1\" ry=\"1\"/><path d=\"M23.513 6.074a.5.5 0 00-.487-.022l-6 3a.5.5 0 00-.276.448v5a.5.5 0 00.276.447l6 3a.5.5 0 00.724-.447v-11a.5.5 0 00-.237-.426z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});