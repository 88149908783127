define("ember-svg-jar/inlined/style-three-pin-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-car</title><path d=\"M13 6.5h-2c-.8 0-1.725.6-1.949 2.215A.25.25 0 009.3 9h5.4a.249.249 0 00.189-.086.253.253 0 00.059-.2C14.725 7.1 13.8 6.5 13 6.5z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.286 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm4.611 12.653a.25.25 0 00-.111.207v.89a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-5.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.89a.247.247 0 00-.111-.207 2.03 2.03 0 01-.689-.795.25.25 0 01.226-.358H8.5a.5.5 0 000-1H6.928a.25.25 0 01-.226-.358 2 2 0 011.172-1.036.249.249 0 00.17-.214C8.254 6.568 9.628 5.5 11 5.5h2c1.372 0 2.746 1.068 2.956 3.392a.249.249 0 00.17.214 2 2 0 011.174 1.036.25.25 0 01-.226.358H15.5a.5.5 0 000 1h1.574a.25.25 0 01.226.358 2.03 2.03 0 01-.689.795z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});