define("ember-svg-jar/inlined/single-man-actions-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-address</title><path d=\"M12.668 11.358a.25.25 0 00-.056-.426l-1.335-.572a.251.251 0 01-.152-.23V8.994a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.176 1.875 1.875 0 00.538-1.4A1.575 1.575 0 0012.287 4a.252.252 0 01-.034-.265 2.529 2.529 0 00.157-1.727C12.069.617 10.367.125 9.013.125A3.752 3.752 0 005.85 1.449a.251.251 0 01-.212.118 1.069 1.069 0 00-.9.375c-.372.492-.106 1.17.176 1.732a.249.249 0 01-.046.287 1.352 1.352 0 00-.162.2 1.766 1.766 0 00-.265.962 1.524 1.524 0 00.638 1.331.249.249 0 01.117.193 3.165 3.165 0 00.845 2.175.251.251 0 01.08.183v1.125a.25.25 0 01-.151.23l-3.933 1.686C.765 12.592.582 13.75.33 15.354c-.055.359-.117.752-.2 1.18a.5.5 0 00.491.591h8.797a.25.25 0 00.249-.228 7.982 7.982 0 013.001-5.539z\"/><path d=\"M17.542 10.876A6.5 6.5 0 0015.848 23.7a6.844 6.844 0 001.528.175 6.474 6.474 0 001.671-.218 1 1 0 10-.513-1.933 4.617 4.617 0 01-2.239.026A4.474 4.474 0 0113 18.44a4.543 4.543 0 01.9-3.916 4.479 4.479 0 013.6-1.648 4.664 4.664 0 014.382 4.753v.777a.709.709 0 01-.536.681.656.656 0 01-.182.038.72.72 0 01-.719-.719v-1.031a3.063 3.063 0 10-3.063 3.062 3.009 3.009 0 001.4-.349.247.247 0 01.306.06 2.745 2.745 0 00.9.7 2.688 2.688 0 001.18.281 2.722 2.722 0 002.718-2.719v-.777a6.676 6.676 0 00-6.344-6.757zm-.168 7.561a1.063 1.063 0 111.063-1.062 1.063 1.063 0 01-1.063 1.062z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});