define("ember-svg-jar/inlined/messages-bubble-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-quote</title><path d=\"M20.094 4.083A12.79 12.79 0 0012 1.341C5.661 1.341.5 5.588.5 10.811a8.6 8.6 0 003.172 6.538l-2.3 4.584a.5.5 0 00.658.677l6.149-2.86a13.627 13.627 0 003.821.534 12.782 12.782 0 008.088-2.738 8.662 8.662 0 003.412-6.729 8.665 8.665 0 00-3.406-6.734zM9.5 13.874a1.75 1.75 0 111.75-1.75v2a1.753 1.753 0 01-1.75 1.75.75.75 0 010-1.5.253.253 0 00.25-.25v-.275a1.736 1.736 0 01-.25.025zm1-4.5a.749.749 0 01-.75-.75v-2.5a.75.75 0 111.5 0v2.5a.75.75 0 01-.75.75zm3.5-1.5a.75.75 0 010-1.5c1.822 0 3.25 1.867 3.25 4.25s-1.428 4.25-3.25 4.25a.75.75 0 010-1.5c.949 0 1.75-1.259 1.75-2.75s-.802-2.75-1.75-2.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});