define("ember-svg-jar/inlined/link-broken", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>link-broken</title><path d=\"M5.727 5.283a1.016 1.016 0 001.414 0 1 1 0 000-1.414l-2-2a1 1 0 00-1.414 1.414zM9.5 4.5a1 1 0 001-1V1a1 1 0 00-2 0v2.5a1 1 0 001 1zM2.909 9h2.5a1 1 0 000-2h-2.5a1 1 0 000 2zM10.727 17.869l-3.4 3.4a2.57 2.57 0 01-3.535 0l-1.061-1.06a2.511 2.511 0 010-3.536l3.4-3.4a1 1 0 10-1.414-1.414l-3.4 3.4a4.51 4.51 0 000 6.364l1.061 1.06a4.509 4.509 0 006.363 0l3.4-3.4a1 1 0 10-1.414-1.414zM19.377 7h-4.5a1 1 0 100 2h4.5A2.7 2.7 0 0122 11.576v2A2.576 2.576 0 0119.353 16h-4.476a1 1 0 000 2h4.476A4.544 4.544 0 0024 13.576v-2A4.662 4.662 0 0019.377 7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});