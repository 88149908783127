define("ember-svg-jar/inlined/road-sign-turn-left-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left-alternate</title><path d=\"M1.573 12.884a1.252 1.252 0 010-1.768l3.94-3.939a.25.25 0 00-.177-.427H.75A.75.75 0 000 7.5v9a.75.75 0 00.75.75h4.586a.25.25 0 00.176-.427z\"/><path d=\"M9.573 12.884a1.252 1.252 0 010-1.768l3.94-3.939a.25.25 0 00-.177-.427H8.165a.249.249 0 00-.177.073l-5 5a.251.251 0 000 .354l5 5a.249.249 0 00.177.073h5.172a.25.25 0 00.177-.427z\"/><path d=\"M17.573 12.884a1.252 1.252 0 010-1.768l3.94-3.939a.25.25 0 00-.177-.427h-5.172a.251.251 0 00-.177.073l-5 5a.252.252 0 000 .354l5 5a.251.251 0 00.177.073h5.172a.25.25 0 00.177-.427z\"/><path d=\"M23.989 7.377a.25.25 0 00-.423-.132l-4.579 4.578a.251.251 0 000 .354l4.579 4.579a.25.25 0 00.423-.133A.712.712 0 0024 16.5v-9a.712.712 0 00-.011-.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});