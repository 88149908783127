define("ember-svg-jar/inlined/move-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>move-up</title><path d=\"M11.5 12.5a1.25 1.25 0 001.25-1.25V5.5a.25.25 0 01.25-.25h2.5a.5.5 0 00.354-.854l-4-4a.5.5 0 00-.708 0l-4 4a.5.5 0 00.354.854H10a.25.25 0 01.25.25v5.75a1.25 1.25 0 001.25 1.25z\"/><rect y=\"15.249\" width=\"24\" height=\"8.5\" rx=\"2\" ry=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});