define("ember-svg-jar/inlined/parking-ramp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>parking-ramp</title><path d=\"M16.055 13.73l.886-.709a.99.99 0 00-1.241-1.547l-.7.561a.247.247 0 01-.4-.168l-.113-1.105a2.989 2.989 0 00-2.961-2.725H5.763A2.985 2.985 0 002.8 10.745l-.11 1.105a.247.247 0 01-.4.168l-.68-.544a.99.99 0 10-1.238 1.547l.876.7a.247.247 0 010 .385 2.709 2.709 0 00-1 2.1v2.229a1.735 1.735 0 001.733 1.733h.743a.248.248 0 01.248.248v1.239a1.486 1.486 0 102.972 0V20.42a.248.248 0 01.248-.248h4.953a.247.247 0 01.247.248v1.239a1.486 1.486 0 002.972 0V20.42a.248.248 0 01.248-.248h.7a1.735 1.735 0 001.734-1.733V16.21a2.716 2.716 0 00-.991-2.1.247.247 0 010-.384zM3.963 18.439a1.486 1.486 0 111.486-1.486 1.486 1.486 0 01-1.486 1.486zm.852-5.2a.249.249 0 01-.246-.273l.207-2.038a1 1 0 01.987-.908h5.765a1 1 0 01.989.926l.205 2.02a.249.249 0 01-.246.273zm8.559 5.2a1.486 1.486 0 111.485-1.486 1.486 1.486 0 01-1.485 1.486zM23.847 13.424a.246.246 0 00-.27.053l-4.96 4.955a.223.223 0 00-.065.158v2.163a.247.247 0 00.422.175l4.953-4.953A.248.248 0 0024 15.8v-2.148a.246.246 0 00-.153-.228z\"/><path d=\"M18.552 15.8a.247.247 0 00.422.175l4.568-4.563a.249.249 0 00-.015-.365 1.183 1.183 0 00-.765-.285h-2.72a.25.25 0 01-.162-.059L8.671 1.094a.991.991 0 10-1.29 1.5l11.084 9.5a.248.248 0 01.087.188zM23.847 18.373a.246.246 0 00-.27.053l-4.244 4.244a.251.251 0 00-.066.229.248.248 0 00.156.179 1.062 1.062 0 00.367.066h2.972A1.239 1.239 0 0024 21.906V18.6a.246.246 0 00-.153-.227z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});