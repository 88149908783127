define("ember-svg-jar/inlined/cash-payment-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cash-payment-bag</title><path d=\"M16.363 16.278c3.971 0 7.191-2.884 7.191-6.444 0-2.773-2.61-5.816-5.088-7.024a.251.251 0 01-.105-.354l.948-1.579a.561.561 0 00-.48-.849h-4.745a.561.561 0 00-.484.849l.943 1.571a.251.251 0 01-.108.354c-2.532 1.2-5.266 4.253-5.266 7.032.003 3.56 3.222 6.444 7.194 6.444zm-.419-6.5c-1.006-.676-1.956-1.316-1.956-2.247a1.416 1.416 0 011.563-1.562.063.063 0 00.062-.063V5.5a.75.75 0 011.5 0v.219a.25.25 0 00.25.25h.625a.75.75 0 010 1.5h-2.469a.03.03 0 00-.03.031.11.11 0 00.022.07 7.992 7.992 0 001.271.964c1.006.677 1.956 1.317 1.956 2.247a1.45 1.45 0 01-1.562 1.563.063.063 0 00-.063.062v.407a.75.75 0 11-1.5 0v-.219a.25.25 0 00-.25-.25h-.625a.75.75 0 010-1.5h2.469a.031.031 0 00.031-.03.114.114 0 00-.022-.069 7.921 7.921 0 00-1.272-.97zM20.441 18.03L16.92 19.2a.257.257 0 00-.153.143 2 2 0 01-1.821 1.187h-4a.5.5 0 110-1h4a1 1 0 000-2h-3.5c-.307 0-1.51-1.5-4-1.5H5.391a4 4 0 00-1.789.423L.584 17.959a.252.252 0 00-.138.224v5.394a.25.25 0 00.383.212l3.216-2.01a1.02 1.02 0 01.858-.1c9.965 3.358 6.628 3.373 17.865-2.312a.511.511 0 00.1-.853 2.486 2.486 0 00-2.427-.484z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});