define("ember-svg-jar/inlined/lucide-flower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 7.5a4.5 4.5 0 114.5 4.5M12 7.5A4.5 4.5 0 107.5 12M12 7.5V9m-4.5 3a4.5 4.5 0 104.5 4.5M7.5 12H9m7.5 0a4.5 4.5 0 11-4.5 4.5m4.5-4.5H15m-3 4.5V15\"/><circle cx=\"12\" cy=\"12\" r=\"3\"/><path d=\"M8 16l1.5-1.5M14.5 9.5L16 8M8 8l1.5 1.5M14.5 14.5L16 16\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});