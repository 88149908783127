define("ember-svg-jar/inlined/astronomy-eclipse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-eclipse</title><path d=\"M14 2c-.156 0-.31 0-.464.011a.25.25 0 00-.095.476 10.5 10.5 0 010 19.026.25.25 0 00.095.476c.154.011.308.011.464.011a10 10 0 000-20z\"/><path d=\"M16.7 7.72a.248.248 0 00.038-.3 9.06 9.06 0 00-3.178-3.17.25.25 0 00-.3.039L1.285 16.26a.25.25 0 00-.039.3 9.06 9.06 0 003.17 3.178.248.248 0 00.3-.038zM11.643 3.781a.251.251 0 00-.106-.417A9 9 0 00.364 14.537a.25.25 0 00.417.106zM9 21a9 9 0 008.628-11.562.249.249 0 00-.416-.105L6.333 20.212a.25.25 0 00.1.416A8.962 8.962 0 009 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});