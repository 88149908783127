define("ember-svg-jar/inlined/phone-actions-smile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-actions-smile</title><path d=\"M11.045 15.46a2.494 2.494 0 01-2.358-3.315c-.418-.461-.839-.922-1.229-1.388l.386-.385a2.508 2.508 0 000-3.536L5.721 4.715a2.563 2.563 0 00-3.536 0L1.022 5.879a3.509 3.509 0 00-.442 4.4 46.906 46.906 0 0013.141 13.145 3.538 3.538 0 004.4-.443l1.164-1.163a2.5 2.5 0 000-3.535l-2.121-2.122a2.5 2.5 0 00-3.536 0l-.386.385c-.469-.392-.937-.81-1.4-1.234a2.485 2.485 0 01-.797.148z\"/><path d=\"M11.045 13.958a.994.994 0 00.429-.1l2.972-1.415a6.593 6.593 0 10-2.889-2.889l-1.416 2.976a1 1 0 00.9 1.428zM22 6.584a4.57 4.57 0 01-6.986 3.888 1 1 0 00-.958-.053l-.895.426.426-.9a1 1 0 00-.053-.957A4.576 4.576 0 1122 6.584z\"/><circle cx=\"15.271\" cy=\"5.316\" r=\".75\"/><circle cx=\"19.771\" cy=\"5.316\" r=\".75\"/><path d=\"M18.937 7.566h-2.831a.493.493 0 00-.487.62 2 2 0 003.8 0 .493.493 0 00-.482-.62z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});