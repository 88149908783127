define("ember-svg-jar/inlined/diet-waist-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diet-waist-1</title><path d=\"M20.866 15.759a.251.251 0 00-.211-.039 18.73 18.73 0 00-7.822 3.98.249.249 0 00-.083.186V23a.5.5 0 00.5.5h6.391a.494.494 0 00.489-.4l.709-3.591v-.018a19.639 19.639 0 00.127-3.544.248.248 0 00-.1-.188zM20.7 14.41a.246.246 0 00.054-.205 11.047 11.047 0 00-1.564-4.055 9.785 9.785 0 01-1.46-7.05l.391-2a.533.533 0 00-.1-.42.534.534 0 00-.391-.18H6.371a.534.534 0 00-.391.18.533.533 0 00-.1.42l.391 2a9.785 9.785 0 01-1.46 7.05 11.047 11.047 0 00-1.568 4.055.246.246 0 00.054.205.249.249 0 00.192.09h17.023a.249.249 0 00.188-.09zM12 11.25a.75.75 0 11.75-.75.756.756 0 01-.75.75zM3.346 15.72a.25.25 0 00-.314.227 19.626 19.626 0 00.128 3.543v.019l.711 3.591a.5.5 0 00.489.4h6.39a.5.5 0 00.5-.5v-3.109a.249.249 0 00-.083-.186 18.738 18.738 0 00-7.821-3.985z\"/><path d=\"M3.25 9a.5.5 0 00.379-.175l1.5-1.75a.5.5 0 000-.65l-1.5-1.75a.492.492 0 00-.552-.144A.5.5 0 002.75 5v.5a.25.25 0 01-.25.25H1.25a1 1 0 000 2H2.5a.25.25 0 01.25.25v.5a.5.5 0 00.327.469A.494.494 0 003.25 9zM22.75 5.75H21.5a.25.25 0 01-.25-.25V5a.5.5 0 00-.327-.469.49.49 0 00-.552.144l-1.5 1.75a.5.5 0 000 .65l1.5 1.75a.5.5 0 00.879-.325V8a.25.25 0 01.25-.25h1.25a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});