define("ember-svg-jar/inlined/credit-card-laptop-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-laptop-payment</title><path d=\"M6.437 2.62a.968.968 0 100-1.935H4.73A2.235 2.235 0 002.588 3v8.011a.249.249 0 01-.087.19C.373 13.019.471 12.932.452 12.952a1.333 1.333 0 00-.326 1.013c.135 1.589 1.086 1.721 2.134 1.721h6.853a.25.25 0 00.25-.25V15.2a2.9 2.9 0 012.9-2.9h6.385a.251.251 0 00.162-.441l-.652-.556a.25.25 0 01-.088-.19V3A2.234 2.234 0 0015.932.685h-1.246a.968.968 0 100 1.935h.458a1 1 0 01.994 1v5.26a1 1 0 01-1 1h-9.61a1 1 0 01-1-1V3.621a1 1 0 01.994-1z\"/><path d=\"M9.245 4.706l1.729.693a.4.4 0 01-.147.77H9.121a.726.726 0 000 1.452H9.4a.25.25 0 01.25.25v.073a.726.726 0 001.452 0V7.8a.25.25 0 01.187-.242 1.846 1.846 0 00.222-3.5l-1.73-.692a.4.4 0 01.148-.77h1.705a.726.726 0 100-1.452h-.282a.25.25 0 01-.25-.25v-.08a.726.726 0 10-1.452 0v.147a.249.249 0 01-.187.242 1.846 1.846 0 00-.221 3.5zM21.944 13.266h-9.678a1.938 1.938 0 00-1.935 1.934v6.774a1.937 1.937 0 001.935 1.936h9.678a1.938 1.938 0 001.936-1.936V15.2a1.939 1.939 0 00-1.936-1.934zM18.28 15.2h3.414a.25.25 0 01.25.25v.709a.25.25 0 01-.25.25h-9.178a.25.25 0 01-.25-.25v-.709a.25.25 0 01.25-.25zm-6.014 3.879a.25.25 0 01.25-.25h9.178a.25.25 0 01.25.25v2.645a.25.25 0 01-.25.25h-9.178a.25.25 0 01-.25-.25z\"/><path d=\"M20.735 20.525a.726.726 0 00-.726-.726H18.8a.726.726 0 000 1.452h1.21a.726.726 0 00.725-.726z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});