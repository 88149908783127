define("ember-svg-jar/inlined/road-sign-3-way-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-3-way-alternate</title><path d=\"M7 10.743a2 2 0 00-1.991-2H4.25a1.753 1.753 0 00-1.75 1.75.75.75 0 001.5 0 .25.25 0 01.247-.25h.756a.5.5 0 010 1 .75.75 0 000 1.5.5.5 0 010 1H4.25a.25.25 0 01-.25-.25.75.75 0 00-1.5 0 1.752 1.752 0 001.752 1.75h.757a2 2 0 001.654-3.113.251.251 0 010-.278A1.983 1.983 0 007 10.743zM12.75 8.741a.75.75 0 00-.75.75v1.361a.25.25 0 01-.49.067l-.039-.134a.75.75 0 00-1.442 0l-.039.134a.25.25 0 01-.49-.067V9.491a.75.75 0 00-1.5 0v1.345a6.727 6.727 0 00.483 2.507l.571 1.427a.75.75 0 001.417-.073l.039-.134a.249.249 0 01.48 0l.039.134a.749.749 0 00.683.543h.038a.748.748 0 00.7-.471l.571-1.427a6.727 6.727 0 00.483-2.507V9.491a.75.75 0 00-.754-.75zM15.75 8.741a1.752 1.752 0 00-1.75 1.75v4a.75.75 0 001.5 0v-1a.25.25 0 01.5 0v1a.75.75 0 001.5 0v-4a1.752 1.752 0 00-1.75-1.75zm.25 2.75a.25.25 0 01-.5 0v-1c0-.275.5-.275.5 0zM20.75 8.741a.75.75 0 00-.75.75v1.5c0 .276-.5.276-.5 0v-1.5a.75.75 0 00-1.5 0v1.5a1.746 1.746 0 00.875 1.509.25.25 0 01.125.216v1.775a.75.75 0 001.5 0v-1.775a.251.251 0 01.125-.216 1.746 1.746 0 00.875-1.509v-1.5a.75.75 0 00-.75-.75z\"/><path d=\"M23 4.5H1a1 1 0 00-1 1v13a1 1 0 001 1h22a1 1 0 001-1v-13a1 1 0 00-1-1zm-1 12.75a.25.25 0 01-.25.25H2.25a.25.25 0 01-.25-.25V6.75a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});