define("ember-svg-jar/inlined/social-video-mubi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-mubi</title><circle cx=\"20.5\" cy=\"4\" r=\"3\"/><circle cx=\"20.5\" cy=\"12\" r=\"3\"/><circle cx=\"20.5\" cy=\"20\" r=\"3\"/><circle cx=\"12\" cy=\"4\" r=\"3\"/><circle cx=\"12\" cy=\"12\" r=\"3\"/><circle cx=\"3.5\" cy=\"12\" r=\"3\"/><circle cx=\"12\" cy=\"20\" r=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});