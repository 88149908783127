define("ember-svg-jar/inlined/flying-insect-dragonfly-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-dragonfly-1</title><path d=\"M9.5 5.825c.534 0 1.282-.671 1.635-1.467.322-.726.618-2.209-1-4a.5.5 0 00-.559-.15A2.816 2.816 0 007.5 2.825c0 1.741 1.194 3 2 3zM14.5 5.825c.806 0 2-1.259 2-3A2.816 2.816 0 0014.421.207a.5.5 0 00-.559.15c-1.615 1.792-1.319 3.275-1 4 .356.797 1.104 1.468 1.638 1.468zM6.31 11.084c1.721 0 5.164-.574 5.164-2.3s-1.148-1.717-1.721-1.717H1.145c-1.147 0-1.865 1.058 0 2.3a9.291 9.291 0 005.165 1.717zM11 11.424a.25.25 0 00-.359-.225 10.214 10.214 0 01-4.357.884c-2 .828-5.284 2.3-5.284 2.992 0 1 2 1.5 3.5 1.5H8a3.193 3.193 0 003-3zM22.855 7.067h-8.608c-.573 0-1.721 0-1.721 1.721s3.443 2.3 5.164 2.3a9.291 9.291 0 005.165-1.722c1.865-1.241 1.145-2.299 0-2.299zM17.716 12.083a10.21 10.21 0 01-4.357-.884.25.25 0 00-.359.225v2.151a3.193 3.193 0 003 3h3.5c1.5 0 3.5-.5 3.5-1.5 0-.696-3.287-2.164-5.284-2.992zM12 14.575a1 1 0 00-1 1v7.25a1 1 0 102 0v-7.25a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});