define("ember-svg-jar/inlined/messages-bubble-remove-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-remove-alternate</title><path d=\"M8.6 17.328a1 1 0 00-.662.038l-3.648 1.52a.25.25 0 01-.331-.316l.851-2.339a1 1 0 00-.275-1.089A7.038 7.038 0 012 9.9C2 5.546 6.486 2 12 2s10 3.546 10 7.9a6.1 6.1 0 01-.048.743.249.249 0 00.1.236 8.031 8.031 0 011.268 1.1.25.25 0 00.425-.11A8.063 8.063 0 0024 9.9C24 4.443 18.617 0 12 0S0 4.443 0 9.9a8.876 8.876 0 002.707 6.26l-1.6 4.4a1 1 0 001.325 1.265l5.959-2.483c.342.09.688.163 1.036.224a.25.25 0 00.287-.3 7.859 7.859 0 01-.205-1.521.249.249 0 00-.2-.235q-.354-.081-.709-.182z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm-1.591 9.151a.75.75 0 11-1.06-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06l-1.414 1.414a.252.252 0 000 .354l1.414 1.414a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});