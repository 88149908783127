define("ember-svg-jar/inlined/road-sign-divided-highway-2-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-divided-highway-2-alternate</title><path d=\"M23.707 11.293l-11-11a1 1 0 00-1.414 0l-11 11a1 1 0 000 1.414l11 11a1 1 0 001.414 0l11-11a1 1 0 000-1.414zm-2.3.884l-9.232 9.232a.25.25 0 01-.354 0l-9.23-9.232a.25.25 0 010-.354l9.232-9.232a.25.25 0 01.354 0l9.232 9.232a.25.25 0 010 .354z\"/><path d=\"M14.25 6.5a.75.75 0 00-1.5 0v3a.75.75 0 01-1.5 0v-3a.75.75 0 00-1.5 0v3a2.25 2.25 0 004.5 0zM8.323 12.263a.251.251 0 01-.073-.177V10.5a.25.25 0 01.25-.25.75.75 0 00.53-1.28l-1-1a.749.749 0 00-1.06 0l-1 1a.75.75 0 00.53 1.28.25.25 0 01.25.25v1.793a1.244 1.244 0 00.366.884l1.561 1.561a.251.251 0 01.073.177V17.5a.75.75 0 001.5 0v-2.792a1.244 1.244 0 00-.366-.884zM17.03 7.97a.749.749 0 00-1.06 0l-1 1a.75.75 0 00.53 1.28.25.25 0 01.25.25v1.586a.251.251 0 01-.073.177l-1.561 1.561a1.244 1.244 0 00-.366.884V17.5a.75.75 0 001.5 0v-2.585a.251.251 0 01.073-.177l1.561-1.561a1.244 1.244 0 00.366-.884V10.5a.25.25 0 01.25-.25.75.75 0 00.53-1.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});