define("ember-svg-jar/inlined/messages-bubble-image-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-image-alternate</title><path d=\"M12 .874C5.406.874.041 5.333.041 10.815a8.981 8.981 0 003.045 6.634L.969 21.675a1 1 0 001.316 1.355l5.957-2.774a14.2 14.2 0 003.758.5c6.594 0 11.959-4.459 11.959-9.94S18.594.874 12 .874zm0 17.881a12.123 12.123 0 01-3.547-.52 1.009 1.009 0 00-.715.05L4.692 19.7a.25.25 0 01-.329-.338l.86-1.716a1 1 0 00-.269-1.229 7.186 7.186 0 01-2.913-5.6c0-4.379 4.468-7.941 9.959-7.941s9.959 3.562 9.959 7.941-4.468 7.938-9.959 7.938z\"/><path d=\"M16.66 10.014a1.5 1.5 0 00-2.392-.087l-1.359 1.749-1.428-1.088a1.5 1.5 0 00-1.925.328l-2.451 3.151a.5.5 0 00.395.807H19a.5.5 0 00.415-.78z\"/><circle cx=\"9\" cy=\"6.874\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});