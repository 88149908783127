define("ember-svg-jar/inlined/bread-gluten-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bread-gluten-free</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zm10 12a10 10 0 01-15.866 8.1.5.5 0 01-.06-.758l1.633-1.634a1 1 0 00-1.414-1.414L4.66 17.926a.5.5 0 01-.759-.06A10 10 0 0117.866 3.9a.5.5 0 01.06.758l-1.633 1.635a1 1 0 001.414 1.414l1.633-1.633a.5.5 0 01.759.06A9.951 9.951 0 0122 12z\"/><path d=\"M18.235 18.235a.749.749 0 000-1.06l-.766-.767a.5.5 0 01-.027-.677 4.028 4.028 0 00.922-1.825 4.452 4.452 0 00-.551-2.826.5.5 0 00-.335-.233.5.5 0 00-.4.1 4.459 4.459 0 00-1.54 2.432 3.088 3.088 0 00-.053.461.25.25 0 01-.427.165l-.528-.527a.5.5 0 01-.027-.678 4.028 4.028 0 00.922-1.825 4.457 4.457 0 00-.551-2.826.5.5 0 00-.732-.138 4.464 4.464 0 00-1.542 2.427 3.222 3.222 0 00-.052.461.25.25 0 01-.427.165l-.573-.572a.5.5 0 01-.027-.678 4.023 4.023 0 00.922-1.825 4.455 4.455 0 00-.543-2.826.5.5 0 00-.335-.233.5.5 0 00-.4.1 4.459 4.459 0 00-1.54 2.432.23.23 0 01-.434.055 3.543 3.543 0 00-.737-1.091 4.466 4.466 0 00-2.644-1.14.5.5 0 00-.527.528 4.455 4.455 0 001.138 2.639 3.513 3.513 0 001.092.737.23.23 0 01-.055.433 4.475 4.475 0 00-2.433 1.54.5.5 0 00.138.733 4.692 4.692 0 002.2.608 3.424 3.424 0 00.622-.056 4.043 4.043 0 001.826-.923.5.5 0 01.677.027l.573.573a.251.251 0 01-.166.427 3.222 3.222 0 00-.461.052 4.463 4.463 0 00-2.432 1.541.5.5 0 00.138.732 4.692 4.692 0 002.2.608 3.34 3.34 0 00.622-.057 4.032 4.032 0 001.826-.922.5.5 0 01.677.027l.527.528a.249.249 0 01-.165.426 3.344 3.344 0 00-.46.053 4.464 4.464 0 00-2.433 1.541.5.5 0 00.138.732 4.692 4.692 0 002.2.608 3.414 3.414 0 00.621-.056 4.037 4.037 0 001.826-.923.5.5 0 01.678.027l.766.766a.748.748 0 001.06 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});