define("ember-svg-jar/inlined/diagram-dash-wave-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diagram-dash-wave-down</title><path d=\"M6.216 15.242A1.438 1.438 0 007.654 13.8v-1.914a1.439 1.439 0 10-2.877 0V13.8a1.438 1.438 0 001.439 1.442zM3.465.526L1.48.523a1.439 1.439 0 100 2.877h1.919A1.438 1.438 0 003.465.526zM6.216 3.734a1.438 1.438 0 00-1.439 1.438V7.09a1.439 1.439 0 102.877 0V5.172a1.438 1.438 0 00-1.438-1.438zM7.654 19.225V18.6a1.439 1.439 0 00-2.877 0v.626a4.329 4.329 0 00.429 1.874A1.439 1.439 0 107.8 19.846a1.416 1.416 0 01-.146-.621zM19.643 6.748a1.439 1.439 0 001.438-1.439v-.471a4.315 4.315 0 00-.535-2.081 1.439 1.439 0 10-2.519 1.39 1.419 1.419 0 01.177.692v.47a1.439 1.439 0 001.439 1.439zM16.441 1.685A1.439 1.439 0 0014.677.672a4.339 4.339 0 00-2.333 1.589 1.439 1.439 0 102.307 1.72 1.444 1.444 0 01.777-.531 1.439 1.439 0 001.013-1.765zM11.491 7.794v1.918a1.439 1.439 0 102.877 0V7.794a1.439 1.439 0 10-2.877 0zM10.356 20.631a1.439 1.439 0 00.3 2.846 1.407 1.407 0 00.3-.032 4.332 4.332 0 002.416-1.46 1.438 1.438 0 10-2.21-1.841 1.445 1.445 0 01-.806.487zM11.491 16.425a1.439 1.439 0 102.877 0v-1.918a1.439 1.439 0 00-2.877 0zM23.849 11.961a1.438 1.438 0 00-1.329-.888h-1.189a.25.25 0 01-.25-.25V10.1a1.439 1.439 0 00-2.877 0v.719a.25.25 0 01-.25.25h-1.188a1.439 1.439 0 00-1.018 2.456l2.878 2.877a1.439 1.439 0 002.034 0l2.877-2.877a1.438 1.438 0 00.312-1.564z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});