define("ember-svg-jar/inlined/style-one-pin-circle-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-one-pin-circle-remove</title><path d=\"M12 0a9.5 9.5 0 00-4 18.119 2.509 2.509 0 011.218 1.222l1.872 4.076a1 1 0 001.817 0l1.876-4.079A2.5 2.5 0 0116 18.118 9.5 9.5 0 0012 0zm0 15.5a6 6 0 116-6 6.006 6.006 0 01-6 6z\"/><path d=\"M14.651 6.848a1.248 1.248 0 00-1.767 0l-.707.707a.252.252 0 01-.354 0l-.707-.707a1.249 1.249 0 10-1.767 1.768l.707.707a.251.251 0 010 .354l-.707.707a1.249 1.249 0 101.767 1.767l.707-.707a.252.252 0 01.354 0l.707.707a1.249 1.249 0 101.767-1.767l-.707-.707a.251.251 0 010-.354l.707-.707a1.249 1.249 0 000-1.768z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});