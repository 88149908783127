define("ember-svg-jar/inlined/single-man-actions.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions</title><path d=\"M12 12.5a5 5 0 10-5-5 5.006 5.006 0 005 5zM9.1 6.757a.5.5 0 01.741-.3A6.959 6.959 0 0013.46 7.5a6.36 6.36 0 00.929-.07.5.5 0 01.426.139.5.5 0 01.142.425A3 3 0 019 7.5a2.841 2.841 0 01.1-.743zM12 13.5A7.508 7.508 0 004.5 21a.5.5 0 00.5.5h14a.5.5 0 00.5-.5 7.508 7.508 0 00-7.5-7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});