define("ember-svg-jar/inlined/history-eye-mythology", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-eye-mythology</title><circle cx=\"15.333\" cy=\"10.5\" r=\"2\"/><path d=\"M22.707 9.721A8.543 8.543 0 008.173 9.39a.25.25 0 01-.207.11H1a1 1 0 000 2h6.967a.249.249 0 01.207.11 8.8 8.8 0 005.545 3.733.254.254 0 01.177.133.249.249 0 010 .221c-2.688 5.6-6.621 5.023-7.442 3.1-.049-.116-.092-.269-.02-.342a1 1 0 00-1.414-1.414 2.237 2.237 0 00-.406 2.539 4.727 4.727 0 004.396 2.564c1.679-.034 4.774-.962 7.121-6.55a.252.252 0 01.2-.152 7.6 7.6 0 00.377-.052.249.249 0 01.29.247v5.613a1 1 0 002 0v-6.427a.252.252 0 01.136-.223 9.228 9.228 0 003.571-3.321 1.5 1.5 0 000-1.556zM15.334 13.5a6.6 6.6 0 01-5.395-2.855.249.249 0 010-.292 6.527 6.527 0 0110.79 0 .252.252 0 010 .292 6.66 6.66 0 01-5.395 2.855z\"/><path d=\"M23.8 6.15a11.743 11.743 0 00-8.8-4.4 11.606 11.606 0 00-8.395 3.917.25.25 0 01-.186.083h-5.4a1 1 0 000 2H7a1.009 1.009 0 00.8-.4 9.864 9.864 0 017.2-3.6 9.87 9.87 0 017.2 3.6 1 1 0 00.8.4 1 1 0 00.8-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});