define("ember-svg-jar/inlined/swimming-diving-board", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-diving-board</title><path d=\"M14 14.25a1 1 0 00-1-1H9v-1a2 2 0 00-2-2H5.166a7.021 7.021 0 014.251-5H17a1 1 0 000-2h-5v-1a2 2 0 00-2-2H2a2 2 0 00-2 2v21a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-7.5a.5.5 0 01.5-.5H13a1 1 0 001-1zm-12-12h8v1H2zm5 11H5v-1h2z\"/><path d=\"M23.979 21.527a1 1 0 00-1.182-.776c-1.777.369-2.961-1.456-3.008-1.529a1.043 1.043 0 00-1.719.028 3.364 3.364 0 01-2.755 1.557 3.564 3.564 0 01-2.766-1.586 1.048 1.048 0 00-1.715.023 3.283 3.283 0 01-3.209 1.522 1 1 0 00-1.162.807c-.064.36-.041 1.234 1.613 1.234a5.161 5.161 0 003.638-1.507 5.219 5.219 0 003.6 1.511 5.155 5.155 0 003.628-1.5 4.656 4.656 0 004.26 1.4 1 1 0 00.777-1.184z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});