define("ember-svg-jar/inlined/multiple-actions-download.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-download</title><path d=\"M14.427 9.891a.251.251 0 00.3.11 7.8 7.8 0 011.557-.4.25.25 0 00.061-.477 39.954 39.954 0 00-1.877-.726l-.485-.172a1.8 1.8 0 01-.056-1.172 4.647 4.647 0 001.216-3.569A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.1.363 5 5 0 01.841 2.808 6.272 6.272 0 01-.921 3.624.249.249 0 00.122.372c1.524.564 3.112 1.176 3.826 2.41z\"/><path d=\"M13.206 10.763a.251.251 0 00.073-.35C12.763 9.646 11.642 9.2 9.471 8.4l-.485-.172a1.8 1.8 0 01-.056-1.174 4.647 4.647 0 001.216-3.569A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.485 4.609 4.609 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.531 7.531 0 000 13.5a.5.5 0 00.5.5h9.663a.251.251 0 00.222-.136 8.046 8.046 0 012.821-3.101zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.4 6.25a.5.5 0 01.391.813l-2.4 3a.5.5 0 01-.781 0l-2.4-3a.5.5 0 01.391-.813h1.15A.25.25 0 0016.5 17v-2.5a1 1 0 012 0V17a.249.249 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});