define("ember-svg-jar/inlined/vinyl-record-player-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vinyl-record-player-2</title><path d=\"M.636 16.75a.248.248 0 00-.212.117.25.25 0 00-.013.243l1.277 2.621A1.934 1.934 0 003.5 21h17a1.913 1.913 0 001.792-1.221l1.3-2.67a.249.249 0 00-.224-.359zM23.7 15.25a.247.247 0 00.191-.09.25.25 0 00.055-.205 3.107 3.107 0 00-.146-.555L20.323 4.256A1.961 1.961 0 0018.5 3h-13a1.968 1.968 0 00-1.829 1.272L.21 14.385a3.2 3.2 0 00-.159.57.252.252 0 00.249.295zM10 12.2c-3.038 0-5.5-1.567-5.5-3.5S6.962 5.2 10 5.2s5.5 1.567 5.5 3.5-2.463 3.5-5.5 3.5zm7.836-.216a.75.75 0 11-.938-1.171 2.255 2.255 0 00.69-2.613l-1.275-2.9a.75.75 0 111.373-.6l1.285 2.921a3.756 3.756 0 01-1.135 4.363z\"/><path d=\"M10 7.533c-.911 0-1.65.523-1.65 1.167S9.089 9.867 10 9.867s1.65-.523 1.65-1.167-.739-1.167-1.65-1.167z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});