define("ember-svg-jar/inlined/smart-watch-square-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-music</title><path d=\"M16.43 2.75a.251.251 0 00.238-.329L15.975.342A.5.5 0 0015.5 0h-7a.5.5 0 00-.475.342l-.693 2.079a.251.251 0 00.238.329zM7.57 21.25a.248.248 0 00-.2.1.251.251 0 00-.035.225l.693 2.079A.5.5 0 008.5 24h7a.5.5 0 00.475-.342l.693-2.079a.251.251 0 00-.035-.225.248.248 0 00-.2-.1zM17.5 4h-11A2.5 2.5 0 004 6.5v11A2.508 2.508 0 006.5 20h11a2.5 2.5 0 002.5-2.5v-11A2.5 2.5 0 0017.5 4zm-1.75 9.25A1.75 1.75 0 1114 11.5a.255.255 0 00.177-.073.251.251 0 00.073-.177V8.887a.252.252 0 00-.1-.2.249.249 0 00-.224-.034l-3.3.848a.254.254 0 00-.12.222v5.03A1.75 1.75 0 118.75 13a.255.255 0 00.177-.073A.251.251 0 009 12.75V9.72a1.747 1.747 0 011.2-1.66l3.3-.848a1.731 1.731 0 011.526.256 1.751 1.751 0 01.727 1.419z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});