define("ember-svg-jar/inlined/expand-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>expand-6</title><path d=\"M3.75 17.241a3 3 0 003 3h8a3 3 0 003-3v-5.033a1 1 0 00-.294-.71 1 1 0 00-1.419 0l-4.52 4.514a2.5 2.5 0 01-3.668-.146 2.606 2.606 0 01.244-3.5l4.407-4.4a1 1 0 000-1.42l-.007-.007a1 1 0 00-.71-.294H6.75a3 3 0 00-3 3z\"/><path d=\"M16.924 8.491a.25.25 0 01.353 0L19.4 10.61a.5.5 0 00.354.147.512.512 0 00.191-.038.5.5 0 00.309-.462v-6a.5.5 0 00-.5-.5h-6a.5.5 0 00-.354.853l2.113 2.113a.249.249 0 010 .354l-6.47 6.456a1 1 0 001.414 1.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});