define("ember-svg-jar/inlined/cog-desktop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-desktop</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.682a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.786h8.079a.5.5 0 00.41-.787z\"/><circle cx=\"12\" cy=\"8.532\" r=\"1\"/><path d=\"M15.8 8.7a.247.247 0 010-.335l.766-.828a1.5 1.5 0 00-1.428-2.48l-1.1.255a.243.243 0 01-.283-.166l-.329-1.083a1.493 1.493 0 00-2.858 0l-.329 1.082a.242.242 0 01-.283.167l-1.1-.255a1.5 1.5 0 00-1.429 2.48l.768.829a.247.247 0 010 .335l-.766.828a1.5 1.5 0 001.428 2.481l1.1-.255a.24.24 0 01.283.166L10.571 13a1.493 1.493 0 002.858 0l.329-1.082a.239.239 0 01.283-.167l1.1.255a1.5 1.5 0 001.429-2.481zM12 6.532a2 2 0 11-2 2 2 2 0 012-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});