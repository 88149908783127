define("ember-svg-jar/inlined/footwear-boots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>footwear-boots</title><path d=\"M9.344 14.5a.25.25 0 00-.192.409A11.231 11.231 0 0017.823 19h5.412a.5.5 0 00.451-.715A4 4 0 0020.073 16h-.5a9.452 9.452 0 01-4.888-1.356 1 1 0 00-.515-.144zM12.1 13a.25.25 0 00.191-.41 9.494 9.494 0 01-1.613-2.765.5.5 0 00-.469-.325h-3.2a.249.249 0 00-.246.3 11.141 11.141 0 00.973 2.926.5.5 0 00.446.274zM6.823 4.5a.249.249 0 00-.25.25v3a.25.25 0 00.25.25H9.9a.25.25 0 00.248-.282 9.6 9.6 0 01-.075-1.218V4.75a.25.25 0 00-.25-.25zM.573 20.5a.5.5 0 00-.5.5v.5a2.5 2.5 0 002.5 2.5h17.5a4.006 4.006 0 003.833-2.857.5.5 0 00-.479-.643z\"/><path d=\"M10.9 19a.25.25 0 00.134-.462A12.75 12.75 0 015.073 7.75V.25a.25.25 0 00-.25-.25h-3.75a1 1 0 00-1 1v17.5a.5.5 0 00.5.5zM6.573 2.75a.25.25 0 00.25.25h3a.25.25 0 00.25-.25V1a1 1 0 00-1-1h-2.25a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});