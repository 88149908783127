define("ember-svg-jar/inlined/credit-card-give", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>credit-card-give</title><path d=\"M23.5 4.75a2.5 2.5 0 00-2.5-2.5H6a2.5 2.5 0 00-2.5 2.5l.024.546a1 1 0 001 .954 1 1 0 001-1.044L5.5 4.75a.5.5 0 01.5-.5h15a.5.5 0 01.5.5V6.5a.25.25 0 01-.25.25h-6.8a1 1 0 00-.977 1.15v.02a.973.973 0 00.977.834h6.8A.25.25 0 0121.5 9v4.75a.5.5 0 01-.5.5H6a.5.5 0 01-.5-.5v-.179c.063-.025.124-.054.185-.084a5.508 5.508 0 002.328-3.068.249.249 0 01.236-.169h1.818a2.006 2.006 0 002-2c0-1-1.028-1.5-2-1.5h-3c-1.4 0-4.466.494-5.962 2.736a4.846 4.846 0 00-.6 1.261H.51.5v9.5c.907.924 3.6 1.5 5 1.5H15a1.5 1.5 0 000-3h3.5a1.5 1.5 0 001.5-1.5 1.462 1.462 0 00-.147-.641.25.25 0 01.225-.359H21a2.5 2.5 0 002.5-2.5z\"/><path d=\"M13.365 13.5h3a.75.75 0 000-1.5h-3a.75.75 0 000 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});