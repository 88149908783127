define("ember-svg-jar/inlined/party-music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>party-music</title><path d=\"M22.033 19.661a.25.25 0 01-.265-.075l-4.82-5.562a.25.25 0 00-.439.167 5.763 5.763 0 01-1.168 3.588.25.25 0 00.035.341l4.21 3.648a.25.25 0 01.074.266 1.5 1.5 0 002.487 1.527l1.414-1.415a1.5 1.5 0 000-2.121 1.538 1.538 0 00-1.528-.364zM5.19 9.294a.25.25 0 00.346-.018 5.794 5.794 0 015.242-1.7.25.25 0 00.23-.411L5.781 1.137A3.3 3.3 0 003.41 0 3.384 3.384 0 00.964.964a3.294 3.294 0 00.172 4.817z\"/><path d=\"M14.47 11.651l4.13-4.126a.251.251 0 01.258-.059 1.559 1.559 0 001.042-.018.25.25 0 01.265.057l.64.64a.75.75 0 001.06-1.061l-.53-.531a.247.247 0 010-.353l.5-.5a.251.251 0 01.354 0l.53.53a.75.75 0 001.06-1.06l-.662-.663a.248.248 0 01-.052-.277A2.5 2.5 0 0019.771.935a.248.248 0 01-.277-.052L18.831.22a.75.75 0 10-1.061 1.06l.53.531a.249.249 0 010 .353l-.5.5a.249.249 0 01-.353 0l-.53-.53a.75.75 0 00-1.061 1.06l.636.636a.249.249 0 01.056.267 1.514 1.514 0 00-.014 1.047.249.249 0 01-.059.26L12.349 9.53a.249.249 0 01-.292.044 4.522 4.522 0 00-5.346.644 4.053 4.053 0 00-.985 1.628.25.25 0 01-.218.172 6 6 0 104.739 10.22 5.942 5.942 0 001.734-3.746.255.255 0 01.173-.218 4.06 4.06 0 001.628-.985 4.522 4.522 0 00.643-5.345.252.252 0 01.045-.293zM5.28 21.28a.749.749 0 01-1.06 0l-1.5-1.5a.75.75 0 011.06-1.06l1.5 1.5a.749.749 0 010 1.06zm5.07-4.8a2 2 0 110-2.83 2 2 0 010 2.829z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});