define("ember-svg-jar/inlined/lucide-repeat-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 2l4 4-4 4\"/><path d=\"M3 11v-1a4 4 0 014-4h14M7 22l-4-4 4-4\"/><path d=\"M21 13v1a4 4 0 01-4 4H3M11 10h1v4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});