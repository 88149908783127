define("ember-svg-jar/inlined/cloud-flash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cloud-flash</title><path d=\"M3.8 16.479A1.009 1.009 0 005 15.5a.983.983 0 00-.8-.976 2.912 2.912 0 01-1.563-.736A2.371 2.371 0 012 12a2.588 2.588 0 012.585-2.588 2.645 2.645 0 01.536.056 1 1 0 001.2-.965c.081-5.754 8.3-7.363 10.818-2.045a.977.977 0 00.94.571 3.693 3.693 0 012.96 1.257 3.862 3.862 0 01.947 2.833 3.63 3.63 0 01-3.143 3.394A1.01 1.01 0 0018 15.5a1.007 1.007 0 001.13 1.006C23.426 15.9 24 11.8 24 10.8a5.814 5.814 0 00-1.773-4.167 5.7 5.7 0 00-3.446-1.585.25.25 0 01-.191-.12 7.685 7.685 0 00-14.1 2.3.251.251 0 01-.227.2A4.642 4.642 0 00.62 9.672 4.471 4.471 0 000 12.119a4.287 4.287 0 001.235 3.09 4.864 4.864 0 002.565 1.27z\"/><path d=\"M16.291 15.246a.875.875 0 00-.666-1.442H13.75a.25.25 0 01-.25-.25V8.3a.5.5 0 00-.89-.313l-5.9 7.371a.876.876 0 00.665 1.442H9.25a.25.25 0 01.25.25v5.25a.5.5 0 00.89.312l3.453-4.312z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});