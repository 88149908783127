define("ember-svg-jar/inlined/file-html-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-html-1</title><path d=\"M23.707 5.708L18.293.294A1 1 0 0017.586 0H6a2 2 0 00-2 2v5.75a.25.25 0 00.25.25h1.5A.25.25 0 006 7.751V2.5a.5.5 0 01.5-.5h10.25a.25.25 0 01.25.25V5a2 2 0 002 2h2.75a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H1.2A1.114 1.114 0 000 10v9a1.114 1.114 0 001.2 1h20.55a.25.25 0 01.25.25v1.25a.5.5 0 01-.5.5H6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25V22a2 2 0 002 2h16a2 2 0 002-2V6.415a1 1 0 00-.293-.707zM6.125 17.5a.625.625 0 01-1.25 0v-2.124a.25.25 0 00-.25-.25h-1.25a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-6a.625.625 0 011.25 0v2.125a.25.25 0 00.25.25h1.25a.25.25 0 00.25-.25V11.5a.625.625 0 111.25 0zm5.375-5.374h-1.125a.25.25 0 00-.25.25V17.5a.625.625 0 01-1.25 0v-5.124a.25.25 0 00-.25-.25H7.5a.625.625 0 110-1.25h4a.625.625 0 010 1.25zm5.625 5.374a.625.625 0 11-1.25 0v-3.046c0-.141-.12-.067-.16.013l-.156.313a.65.65 0 01-1.118 0s-.1-.211-.15-.3-.166-.086-.166 0v3.02a.625.625 0 01-1.25 0v-6a.625.625 0 011.184-.279s.72 1.436.783 1.566a.182.182 0 00.32-.009l.779-1.557a.625.625 0 011.184.279zm4.375.625h-2a1.627 1.627 0 01-1.625-1.625v-5a.625.625 0 111.25 0v5a.376.376 0 00.375.375h2a.625.625 0 010 1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});