define("ember-svg-jar/inlined/style-three-pin-train", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-train</title><circle cx=\"9.5\" cy=\"12.5\" r=\"1\"/><circle cx=\"14.5\" cy=\"12.5\" r=\"1\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 2.525 1.412 5.2 3.183 7.564a.5.5 0 00.829-.042l.844-1.408a.751.751 0 011.288.772l-1.309 2.18a.5.5 0 00.055.59 45.129 45.129 0 004.65 4.5 1.5 1.5 0 001.921 0 45.048 45.048 0 004.648-4.5.5.5 0 00.055-.589l-1.308-2.18a.751.751 0 011.288-.772l.844 1.407a.5.5 0 00.829.042C21.087 14.7 22.5 12.024 22.5 9.5 22.5 4.262 17.79 0 12 0zm-1 3h2a.75.75 0 010 1.5h-2A.75.75 0 0111 3zm5.5 10a1.5 1.5 0 01-1.5 1.5H9A1.5 1.5 0 017.5 13v-2a.5.5 0 01.5-.5h8a.5.5 0 01.5.5zm0-4a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5V7A1.5 1.5 0 019 5.5h6A1.5 1.5 0 0116.5 7z\"/><path d=\"M10 6a1.5 1.5 0 00-1.5 1.5.5.5 0 001 0A.5.5 0 0110 7a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});