define("ember-svg-jar/inlined/hat-retro-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hat-retro-1</title><path d=\"M5.1 13.544a.5.5 0 00.5.456h12.811a.5.5 0 00.5-.456L20 1.086A1 1 0 0019 0H5a1 1 0 00-1 1.086zM23.811 20.937a1.014 1.014 0 00-1.377-.258 10.5 10.5 0 01-3.616.924.5.5 0 01-.414-.132.5.5 0 01-.154-.406l.221-2.521a.5.5 0 00-.5-.544H6.036a.5.5 0 00-.5.544l.221 2.522a.5.5 0 01-.568.539 10.75 10.75 0 01-3.584-.905A1 1 0 00.4 22.3C.632 22.474 2.929 24 12 24s11.368-1.526 11.6-1.7a.987.987 0 00.211-1.363z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});