define("ember-svg-jar/inlined/job-seach-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>job-seach-man</title><path d=\"M10.5 20.25a10.708 10.708 0 01-4.4-.939.25.25 0 00-.352.228V21.5a.5.5 0 00.5.5H7.5a.249.249 0 01.25.25v1.25a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1.25A.249.249 0 0114 22h1.25a.5.5 0 00.5-.5v-2.2a.25.25 0 00-.364-.222A10.707 10.707 0 0110.5 20.25z\"/><path d=\"M22.311 18.939l-4.132-4.131a.25.25 0 01-.029-.318 9.271 9.271 0 10-2.41 2.41.25.25 0 01.318.029l4.131 4.131a1.5 1.5 0 002.122-2.121zM8.523 7.566a.251.251 0 01.371-.136 5.36 5.36 0 002.856.82 5.425 5.425 0 001.043-.1.1.1 0 01.124.1 2.25 2.25 0 11-4.394-.684zM3.25 9.26a7.261 7.261 0 1112.25 5.272.218.218 0 01-.334-.04 5.421 5.421 0 00-2.43-2.07.249.249 0 01-.021-.448 4.251 4.251 0 10-4.081 0 .251.251 0 01-.027.452 4.615 4.615 0 00-2.5 2.2.25.25 0 01-.39.077A7.244 7.244 0 013.25 9.26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});