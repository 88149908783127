define("ember-svg-jar/inlined/travel-insurance-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-insurance-shield</title><path d=\"M21.312 1.914A22.881 22.881 0 0012 0a22.877 22.877 0 00-9.312 1.914A2.045 2.045 0 001.5 3.774v7.639a12.865 12.865 0 008.2 11.936 9.167 9.167 0 002.31.651 8.943 8.943 0 002.293-.651 12.865 12.865 0 008.2-11.936V3.774a2.045 2.045 0 00-1.191-1.86zm-2.126 7.318l-3.1 1.365-2.451 4.478a1.008 1.008 0 01-.42.411s-.706.333-1.112.473A.963.963 0 0111 14.643l.918-2.382-2.968 1.171a1.007 1.007 0 01-.92-.091l-3.366-2.173a1.012 1.012 0 01.045-1.727l.932-.541a1.017 1.017 0 01.919-.046l2.066.925 2.63-1.05-1.962-1.3a1.012 1.012 0 01.1-1.741l.882-.457a1.009 1.009 0 011 .044l3.365 2.107 3.226-1.288a1.7 1.7 0 012.185.883 1.86 1.86 0 01-.866 2.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});