define("ember-svg-jar/inlined/megaphone-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>megaphone-1</title><path d=\"M24 1.749a1 1 0 10-2 0v.774L3.846 8.384a.5.5 0 00-.346.476v5.957a.5.5 0 00.35.477l1.3.408a.5.5 0 01.35.477v2.591a4.505 4.505 0 004.5 4.48h.021a4.505 4.505 0 004.479-4.517v-.1l7.5 2.35v.766a1 1 0 002 0zM12.5 18.738a2.5 2.5 0 01-2.488 2.512H10a2.5 2.5 0 01-2.5-2.484v-1.647a.5.5 0 01.65-.477l4 1.254a.5.5 0 01.35.477zM2 8.249a1 1 0 00-1-1 1 1 0 00-1 1l.005 7a1 1 0 001 1H1a1 1 0 001-.976z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});