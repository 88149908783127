define("ember-svg-jar/inlined/single-woman-actions-mobilephone.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-mobilephone</title><path d=\"M13.25 17.494a.249.249 0 00.249-.25V12.5a1.244 1.244 0 01.009-.137.25.25 0 00-.161-.262c-.585-.218-1.213-.444-1.873-.686a.778.778 0 01-.512-.73v-.467a.25.25 0 01.249-.25A6.371 6.371 0 0015 9.077a.387.387 0 00.084-.592c-.494-.537-.835-1.185-.981-3.316C13.838 1.345 11.236 0 9 0S4.164 1.345 3.9 5.169c-.145 2.131-.487 2.779-.98 3.316a.386.386 0 00.083.592 6.344 6.344 0 003.78.9.249.249 0 01.254.25v.462a.778.778 0 01-.512.73c-2.9 1.064-5.21 1.824-5.762 2.92a7.824 7.824 0 00-.76 2.73.388.388 0 00.1.3.394.394 0 00.29.127zM22.522 11.005h-6.05A1.492 1.492 0 0015 12.513v9.979A1.493 1.493 0 0016.472 24h6.05A1.493 1.493 0 0024 22.492v-9.979a1.493 1.493 0 00-1.478-1.508zM17 13.5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});