define("ember-svg-jar/inlined/tools-kitchen-serving-dome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tools-kitchen-serving-dome</title><path d=\"M23 17.375H1a1 1 0 000 2h1.061a1 1 0 01.832.445l.443.664A2 2 0 005 21.375h14a2 2 0 001.664-.891l.443-.664a1 1 0 01.832-.445H23a1 1 0 000-2zM3 15.875h18a.5.5 0 00.5-.5 9.517 9.517 0 00-7.276-9.237.251.251 0 01-.17-.346 2.228 2.228 0 00.2-.917 2.25 2.25 0 00-4.5 0 2.228 2.228 0 00.2.917.251.251 0 01-.17.346A9.517 9.517 0 002.5 15.375a.5.5 0 00.5.5zm3.464-5.923a7.774 7.774 0 014.43-2.252.75.75 0 11.212 1.484A6.279 6.279 0 007.535 11a.75.75 0 11-1.071-1.048z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});