define("ember-svg-jar/inlined/bus-ticket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>bus-ticket</title><path d=\"M8.959 13.123H4.75a.25.25 0 01-.25-.25V3a1 1 0 011-1h13a1 1 0 011 1v8.377a.25.25 0 00.25.25h1.5a.25.25 0 00.25-.25v-.5a.25.25 0 01.25-.25h.5A1.752 1.752 0 0024 8.877v-3a1.752 1.752 0 00-1.75-1.75h-.5a.25.25 0 01-.25-.25V3a3 3 0 00-3-3h-13a3 3 0 00-3 3v.877a.25.25 0 01-.25.25h-.5A1.752 1.752 0 000 5.877v3a1.752 1.752 0 001.75 1.75h.5a.25.25 0 01.25.25v6.746a3 3 0 003 3h3.018a.25.25 0 00.25-.25v-5.246a3.457 3.457 0 01.411-1.636.249.249 0 00-.22-.368zM7.5 16.877h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zM7.866 21.627h-3a.25.25 0 00-.25.25v.25a1.75 1.75 0 003.5 0v-.25a.25.25 0 00-.25-.25z\"/><path d=\"M22 13H12a2 2 0 00-2 2v7a2 2 0 002 2h10a2 2 0 002-2v-7a2 2 0 00-2-2zm0 8.75a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25V19a.25.25 0 01.25-.25h9.5A.25.25 0 0122 19zm0-5.283a.25.25 0 01-.25.25h-9.5a.25.25 0 01-.25-.25V15.25a.25.25 0 01.25-.25h9.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});