define("ember-svg-jar/inlined/casino-chip-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chip-diamond</title><path d=\"M11 .026a.251.251 0 00-.276.249V3.8a.248.248 0 00.21.246 5.98 5.98 0 012.548 1.058.25.25 0 00.323-.025L16.3 2.588a.249.249 0 00-.019-.37A9.96 9.96 0 0011 .026zM3.805 9.223a.248.248 0 00.246-.21 5.981 5.981 0 011.058-2.547.249.249 0 00-.026-.323L2.589 3.649a.25.25 0 00-.371.019 9.951 9.951 0 00-2.191 5.28.249.249 0 00.248.275zM4.051 10.934a.25.25 0 00-.246-.211H.275A.251.251 0 00.027 11a9.951 9.951 0 002.191 5.28.252.252 0 00.371.019L5.083 13.8a.251.251 0 00.026-.324 5.972 5.972 0 01-1.058-2.542zM9.224.275a.251.251 0 00-.082-.186.254.254 0 00-.194-.063 9.956 9.956 0 00-5.279 2.192.25.25 0 00-.019.371l2.494 2.494a.249.249 0 00.323.025A5.971 5.971 0 019.014 4.05a.249.249 0 00.21-.246zM19.635 9.723h.07a.248.248 0 00.256-.262 9.95 9.95 0 00-2.231-5.793.25.25 0 00-.371-.019l-2.494 2.494a.249.249 0 00-.025.323 5.964 5.964 0 011.115 3.027.25.25 0 00.249.23zM7.592 18.43l-.006-.006a2.8 2.8 0 01-.656-3.037.25.25 0 00-.1-.306q-.186-.116-.363-.243a.249.249 0 00-.323.026L3.65 17.358a.25.25 0 00.019.371 9.96 9.96 0 004.787 2.129.25.25 0 00.208-.43zM23.686 15.543l-2.85-3.968a1.506 1.506 0 00-1.2-.6h-7.323a1.514 1.514 0 00-1.212.618l-2.825 3.931a1.514 1.514 0 00.165 1.989l6.491 6.04a1.5 1.5 0 002.077.007l6.523-6.071a1.5 1.5 0 00.154-1.946zM12.42 13.182a.5.5 0 01.406-.209h6.3a.5.5 0 01.406.209l1.362 1.9a.249.249 0 01-.2.4h-9.432a.249.249 0 01-.2-.4zm3.725 8.45a.249.249 0 01-.341 0L11.8 17.906a.25.25 0 01.171-.433h8.008a.25.25 0 01.17.433z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});