define("ember-svg-jar/inlined/team-meeting-message-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>team-meeting-message-lock</title><path d=\"M21.443 23.08l-1.794-1.071a1 1 0 01-.649-.936v-.187a.975.975 0 01.223-.628 5.364 5.364 0 001.277-3.249c0-1.987-.987-3.48-2.96-3.5a2.869 2.869 0 00-2.47 1.44 4.655 4.655 0 00.71 5.313.971.971 0 01.22.624v.187a1 1 0 01-.639.932l-.019.007-3.271.976a.245.245 0 01-.142 0l-3.271-.976-.019-.007A1 1 0 018 21.073v-.187a.975.975 0 01.223-.628A5.364 5.364 0 009.5 17.009c0-1.987-.987-3.48-2.96-3.5a2.869 2.869 0 00-2.47 1.44 4.655 4.655 0 00.71 5.313.971.971 0 01.22.624v.187a1 1 0 01-.649.936L2.557 23.08a.5.5 0 00.257.929h18.373a.5.5 0 00.256-.929z\"/><path d=\"M5.5 12.006h6a.25.25 0 01.25.25v2a.751.751 0 00.463.693.738.738 0 00.287.057.746.746 0 00.53-.22l2.707-2.709a.252.252 0 01.177-.074H19.5a1.752 1.752 0 001.75-1.75V1.74A1.752 1.752 0 0019.5-.01h-3a.75.75 0 000 1.5h3a.25.25 0 01.25.25v8.516a.25.25 0 01-.25.25h-4a.747.747 0 00-.53.22l-1.293 1.294a.25.25 0 01-.427-.177v-.587a.75.75 0 00-.75-.75h-7a.25.25 0 01-.25-.25V1.74a.25.25 0 01.25-.25h3a.75.75 0 000-1.5h-3a1.752 1.752 0 00-1.75 1.75v8.516a1.752 1.752 0 001.75 1.75z\"/><path d=\"M8.75 4.759v3a1.752 1.752 0 001.75 1.75h4a1.752 1.752 0 001.75-1.75v-3a1.751 1.751 0 00-.875-1.515.25.25 0 01-.125-.216v-.269a2.75 2.75 0 00-5.5 0v.269a.25.25 0 01-.125.216 1.751 1.751 0 00-.875 1.515zm6 3a.25.25 0 01-.25.25h-4a.25.25 0 01-.25-.25v-3a.251.251 0 01.25-.25h4a.251.251 0 01.25.25zm-3.5-5a1.25 1.25 0 012.5 0 .25.25 0 01-.25.25h-2a.25.25 0 01-.25-.25z\"/><circle cx=\"12.5\" cy=\"6.279\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});