define("ember-svg-jar/inlined/love-heart-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>love-heart-key</title><path d=\"M22.974 5.974a3.5 3.5 0 00-4.948-4.95l-9.757 9.758a1 1 0 01-.849.283A6.5 6.5 0 1013 17.5a6.631 6.631 0 00-.065-.92 1 1 0 01.283-.849zM6.788 21.451a.514.514 0 01-.64 0c-.949-.744-3.68-3.04-3.68-4.822 0-2.15 2.447-3.028 3.579-1.271a.5.5 0 00.841 0c1.132-1.757 3.58-.879 3.58 1.271 0 1.782-2.732 4.078-3.68 4.822zm5.919-8.744a1 1 0 01-1.414-1.414l8.5-8.5a1 1 0 111.414 1.414z\"/><path d=\"M17.03 14.97a.75.75 0 00-1.061 1.06l1 1a.75.75 0 001.061-1.06zM19.53 12.47a.75.75 0 00-1.061 1.06l1 1a.75.75 0 101.061-1.06zM22.03 9.97a.75.75 0 00-1.061 1.06l1 1a.75.75 0 101.061-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});