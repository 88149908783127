define("ember-svg-jar/inlined/underwear-bikini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>underwear-bikini</title><path d=\"M2.5 10.917a.5.5 0 00.211.426 6.122 6.122 0 003.6 1.214 8.066 8.066 0 004.034-1.239.5.5 0 01.251-.068h2.814a.493.493 0 01.251.068 8.066 8.066 0 004.034 1.239 6.128 6.128 0 003.6-1.214.5.5 0 00.211-.426 9.058 9.058 0 00-2.032-5.829.5.5 0 01-.1-.486l1.081-3.29a1 1 0 10-1.9-.624l-1.097 3.324a.5.5 0 01-.212.268 8.95 8.95 0 00-4.188 5.12.5.5 0 01-.477.35h-1.162a.5.5 0 01-.477-.35 8.95 8.95 0 00-4.188-5.12.5.5 0 01-.212-.268L5.45.688a1 1 0 00-1.9.624L4.631 4.6a.5.5 0 01-.1.486A9.058 9.058 0 002.5 10.917zM21.142 15.127a1 1 0 00-1.078-.823 84.7 84.7 0 01-16.128 0 1 1 0 00-1.078.822l-.268 1.582a1 1 0 00.916 1.159c.146-.007 3.8-.07 7.067 5.63a1 1 0 00.867.5h1.119a1 1 0 00.868-.5c3.277-5.715 7.032-5.628 7.062-5.63a.991.991 0 00.921-1.159z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});