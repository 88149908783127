define("ember-svg-jar/inlined/data-file-bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars</title><path d=\"M4 24h16a2 2 0 002-2V6.329a2 2 0 00-.586-1.415L17.086.586A2 2 0 0015.672 0H4a2 2 0 00-2 2v20a2 2 0 002 2zm10-12a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm-3.5-2a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zM7 14.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V17a.5.5 0 01-.5.5h-1A.5.5 0 017 17z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});