define("ember-svg-jar/inlined/messages-bubble-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-upload-1</title><path d=\"M17.5 9.5a7.95 7.95 0 014.962 1.737.25.25 0 00.386-.1A7.907 7.907 0 0023.5 8a8.009 8.009 0 00-8-8H8a7.992 7.992 0 00-3 15.4v4.1a.5.5 0 00.309.462.5.5 0 00.545-.108l3.726-3.727A.247.247 0 009.649 16 8.008 8.008 0 0117.5 9.5z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.391 5.688a.5.5 0 01-.391.812h-1a.25.25 0 00-.25.25v2.75a.75.75 0 01-1.5 0v-2.75a.25.25 0 00-.25-.25h-1a.5.5 0 01-.391-.812l2-2.5a.519.519 0 01.782 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});