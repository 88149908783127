define("ember-svg-jar/inlined/lucide-timer-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 2h4M4.6 11a8 8 0 001.7 8.7 8 8 0 008.7 1.7M7.4 7.4a8 8 0 0110.3 1 8 8 0 01.9 10.2M2 2l20 20M12 12v-2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  };
});