define("ember-svg-jar/inlined/natural-disaster-hurricane-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-hurricane-map</title><path d=\"M21.929 16.628a1 1 0 00-1.858.744l1.178 2.942a.5.5 0 01-.464.686H3.215a.5.5 0 01-.464-.686l2.8-7A.5.5 0 016.015 13H10.5a1 1 0 000-2H5a1 1 0 00-.929.628l-4 10A1 1 0 001 23h22a1 1 0 00.929-1.372z\"/><path d=\"M15.561 16.39c.059.047.064.073.068.042v.129a.851.851 0 00.875.844.9.9 0 00.876-.905 1.835 1.835 0 00-.74-1.491.875.875 0 10-1.074 1.381zM10.506 17.625h-1a.875.875 0 000 1.75h1a.875.875 0 000-1.75zM12.633 18.515a.875.875 0 00.873.819h.056c.4-.025.759-.063 1.075-.114a.875.875 0 10-.274-1.728c-.268.042-.572.073-.913.1a.875.875 0 00-.817.923zm.875.444zM6.5 17.625h-1a.875.875 0 000 1.75h1a.875.875 0 000-1.75zM11.966 15.56a7.5 7.5 0 001.111.249l.142.011a.874.874 0 00.137-1.738 5.948 5.948 0 01-.839-.184.876.876 0 00-1.106.556.874.874 0 00.555 1.106zM24 2a1 1 0 00-1-1h-9a1 1 0 000 2h9a1 1 0 001-1zM21.5 4H15a1 1 0 000 2h6.5a1 1 0 000-2zM20 7h-3a1 1 0 000 2h3a1 1 0 000-2zM19 11a1 1 0 00-1-1h-2a1 1 0 000 2h2a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});