define("ember-svg-jar/inlined/real-estate-deal-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-deal-document</title><path d=\"M21.5 0h-19A2.5 2.5 0 000 2.5v19A2.5 2.5 0 002.5 24H17a1.008 1.008 0 00.708-.294l6-6A1.1 1.1 0 0024 17V2.5A2.5 2.5 0 0021.5 0zm.5 15.5a.5.5 0 01-.5.5H18a2 2 0 00-2 2v3.5a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M17.9 3.739l-2.88 2.645a.5.5 0 01-.715-.039l-.617-.707a1.25 1.25 0 00-1.883 1.644L13.2 8.875a1.851 1.851 0 002.647.147L19.6 5.581a1.251 1.251 0 00-1.7-1.842zM8.825 8.727a.5.5 0 00-.65 0l-5 4.286a.5.5 0 00.325.88h.429a.5.5 0 01.5.5v4a.5.5 0 00.5.5h7.142a.5.5 0 00.5-.5v-4a.5.5 0 01.5-.5h.429a.5.5 0 00.325-.88z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});