define("ember-svg-jar/inlined/single-woman-shield.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-shield</title><path d=\"M23.5 1.957A1.458 1.458 0 0022.044.5H1.956A1.458 1.458 0 00.5 1.957V9.25a14.645 14.645 0 0011.4 14.24.5.5 0 00.2 0A14.645 14.645 0 0023.5 9.25zm-5.569 14.952a.252.252 0 01-.193.091H6.262a.256.256 0 01-.194-.091A.288.288 0 016 16.7a5.922 5.922 0 01.507-1.951c.368-.783 1.909-1.326 3.843-2.087a.555.555 0 00.341-.521v-.261a.247.247 0 00-.074-.177.251.251 0 00-.177-.073A3.962 3.962 0 018 10.986a.283.283 0 01-.122-.2.294.294 0 01.066-.225c.329-.384.557-.846.654-2.37C8.774 5.461 10.509 4.5 12 4.5s3.226.961 3.4 3.693c.1 1.524.325 1.986.654 2.37a.289.289 0 01.065.225.278.278 0 01-.121.2 3.937 3.937 0 01-2.441.633.249.249 0 00-.249.25v.269a.556.556 0 00.341.521c1.934.761 3.474 1.3 3.843 2.087A5.922 5.922 0 0118 16.7a.285.285 0 01-.069.209z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});