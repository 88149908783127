define("ember-svg-jar/inlined/single-woman-circle.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-circle</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><path d=\"M15.484 13a3.539 3.539 0 00-1.031-1.771.416.416 0 00-.636.081l-1.463 2.3a.416.416 0 01-.7 0L10.2 11.3a.417.417 0 00-.636-.085 3.517 3.517 0 00-1.044 1.774l-1.5 4.486a.415.415 0 00.07.362.421.421 0 00.327.163h1.891a.251.251 0 01.248.215l.224 1.57a.25.25 0 00.247.215h3.928a.25.25 0 00.247-.215l.224-1.57a.251.251 0 01.248-.215h1.909a.417.417 0 00.4-.526zM11.982 10.625a3.468 3.468 0 10-3.467-3.468 3.471 3.471 0 003.467 3.468zm0-2a1.462 1.462 0 01-1.4-1.04.251.251 0 01.139-.3 3.553 3.553 0 001.083-.72.251.251 0 01.35 0 3.561 3.561 0 001.082.72.25.25 0 01.14.3 1.462 1.462 0 01-1.394 1.04z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 21.5a9.5 9.5 0 119.5-9.5 9.511 9.511 0 01-9.5 9.5z\"/><path d=\"M15.484 13a3.539 3.539 0 00-1.031-1.771.416.416 0 00-.636.081l-1.463 2.3a.416.416 0 01-.7 0L10.2 11.3a.417.417 0 00-.636-.085 3.517 3.517 0 00-1.044 1.774l-1.5 4.486a.415.415 0 00.07.362.421.421 0 00.327.163h1.891a.251.251 0 01.248.215l.224 1.57a.25.25 0 00.247.215h3.928a.25.25 0 00.247-.215l.224-1.57a.251.251 0 01.248-.215h1.909a.417.417 0 00.4-.526zM11.982 10.625a3.468 3.468 0 10-3.467-3.468 3.471 3.471 0 003.467 3.468zm0-2a1.462 1.462 0 01-1.4-1.04.251.251 0 01.139-.3 3.553 3.553 0 001.083-.72.251.251 0 01.35 0 3.561 3.561 0 001.082.72.25.25 0 01.14.3 1.462 1.462 0 01-1.394 1.04z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});