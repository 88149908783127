define("ember-svg-jar/inlined/space-moon-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>space-moon-flag</title><path d=\"M4.753 9.5A4.75 4.75 0 100 4.75 4.756 4.756 0 004.753 9.5zM1.679 3.7a3.249 3.249 0 014.45-1.889.25.25 0 01-.069.474 1.372 1.372 0 00-1.31 1.464v1a1.5 1.5 0 001.5 1.5.5.5 0 01.5.5v.306a.5.5 0 01-.228.42 3.244 3.244 0 01-3.251.167A.5.5 0 013 7.2V5.749a1.5 1.5 0 00-1.012-1.418.5.5 0 01-.309-.631zM23.266 20.088a40.471 40.471 0 00-8.719-1.412 1 1 0 00-1.047.994 1.006 1.006 0 00.953 1 38.431 38.431 0 018.28 1.342 1 1 0 10.533-1.928z\"/><path d=\"M23 6H11a1 1 0 00-1 1v11.224a.5.5 0 01-.465.5A42.154 42.154 0 00.721 20.3a1 1 0 10.558 1.921 40.184 40.184 0 018.183-1.483.5.5 0 01.538.5V23a1 1 0 002 0v-5a.5.5 0 01.5-.5H23a1 1 0 001-1V7a1 1 0 00-1-1zm-1 9a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zm0-3a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5zm0-3a.5.5 0 01-.5.5h-5A.5.5 0 0116 9v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});