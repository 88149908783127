define("ember-svg-jar/inlined/family-child-jumping-rope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>family-child-jumping-rope</title><circle cx=\"11.989\" cy=\"7.25\" r=\"2.5\"/><path d=\"M4.989 9.75a1 1 0 001-1V8a6 6 0 0112 0v.757a1 1 0 102 0V8a8 8 0 00-16 0v.75a1 1 0 001 1zM19.636 11.714a1 1 0 00-1.4.155c-.54.674-.767 1.1-1.331 1.13-.513.038-.738-.268-1.1-.626a2.984 2.984 0 00-2.121-.879H10.3a2.982 2.982 0 00-2.121.879c-.358.358-.584.663-1.1.626s-.695-.389-1.288-1.13a1 1 0 00-1.561 1.25l.6.755A2.911 2.911 0 006.97 15a3.158 3.158 0 002.541-1.087v1.684a3.005 3.005 0 01-.5 1.664l-1.27 1.9a.219.219 0 00.182.34h1.338a.25.25 0 01.25.25v1.128a1 1 0 01-.181.573l-.693.99A1 1 0 1010.3 23.55l1-1.5a1.051 1.051 0 00.21-.554v-1.753a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25V21.5a.994.994 0 00.168.554l1 1.5a1 1 0 101.664-1.109l-.664-1a1 1 0 01-.168-.555v-1.14a.25.25 0 01.25-.25h1.283a.25.25 0 00.208-.389l-1.237-1.855a3 3 0 01-.5-1.664v-1.684a3.02 3.02 0 004.677-.034l.6-.755a1 1 0 00-.155-1.405z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});