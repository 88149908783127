define("ember-svg-jar/inlined/army-sword-fight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-sword-fight</title><path d=\"M19.03 17.53a.749.749 0 000-1.06.739.739 0 00-.876-.127.25.25 0 01-.295-.044l-1.122-1.121a.255.255 0 01-.073-.178.251.251 0 01.073-.177l1.293-1.292a.75.75 0 00-1.061-1.061l-.823.823a.25.25 0 01-.354 0l-.731-.731a.5.5 0 00-.708 0l-1.292 1.292a.5.5 0 000 .708l.731.731a.25.25 0 010 .354l-.823.823a.75.75 0 001.061 1.061l1.293-1.293a.249.249 0 01.353 0L16.8 17.36a.251.251 0 01.045.294.741.741 0 00.127.876.749.749 0 001.06 0z\"/><circle cx=\"12\" cy=\"20\" r=\"1\"/><circle cx=\"20.5\" cy=\"12\" r=\"1\"/><circle cx=\"12\" cy=\"3.987\" r=\"1\"/><circle cx=\"3.5\" cy=\"12\" r=\"1\"/><path d=\"M.866 11.3a.778.778 0 00.134.015.751.751 0 00.737-.615A10.4 10.4 0 0110.7 1.738a.75.75 0 00-.269-1.476A11.941 11.941 0 00.262 10.431a.75.75 0 00.604.869zM12.7.866a.75.75 0 00.6.872 10.4 10.4 0 018.962 8.962.75.75 0 00.737.615.778.778 0 00.135-.012.75.75 0 00.6-.872A11.941 11.941 0 0013.569.262a.75.75 0 00-.869.604zM11.3 23.134a.75.75 0 00-.6-.872A10.4 10.4 0 011.738 13.3a.75.75 0 00-1.476.269 11.941 11.941 0 0010.169 10.169.787.787 0 00.135.012.75.75 0 00.734-.616zM23.134 12.7a.749.749 0 00-.872.6 10.4 10.4 0 01-8.962 8.962.75.75 0 00.134 1.488.787.787 0 00.135-.012 11.941 11.941 0 0010.169-10.169.75.75 0 00-.604-.869zM7.962 9.39a.508.508 0 00.121.2l.856.856a.5.5 0 00.708 0l1.292-1.293a.5.5 0 000-.707l-.856-.856a.508.508 0 00-.2-.121l-1.94-.646a.5.5 0 00-.632.632z\"/><path d=\"M5.97 18.53a.749.749 0 001.06 0 .741.741 0 00.127-.876.251.251 0 01.043-.294l1.122-1.121a.249.249 0 01.353 0l1.295 1.293a.75.75 0 001.061-1.061l-.823-.823a.25.25 0 010-.354l5.709-5.709a.508.508 0 00.121-.2l.646-1.94a.5.5 0 00-.632-.632l-1.94.646a.508.508 0 00-.2.121l-5.704 5.714a.249.249 0 01-.354 0l-.823-.823a.75.75 0 00-1.061 1.061l1.293 1.292a.251.251 0 01.073.176.255.255 0 01-.073.177L6.141 16.3a.252.252 0 01-.295.044.739.739 0 00-.876.127.749.749 0 000 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});