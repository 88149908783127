define("ember-svg-jar/inlined/instrument-tambourine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>instrument-tambourine</title><path d=\"M21.063 8.9a.251.251 0 00.447.015 4 4 0 00-4.942-5.65.25.25 0 00-.046.444 12.4 12.4 0 012.088 1.68A12.2 12.2 0 0121.063 8.9zM22.824 15.175a3.586 3.586 0 00-.267-.244.248.248 0 00-.256-.039.251.251 0 00-.153.21 12.2 12.2 0 01-2.548 6.492.251.251 0 00.184.4 4 4 0 003.037-6.823zM9.946 1.8a.2.2 0 00.153-.322 3.916 3.916 0 00-.275-.3 4 4 0 00-6.787 2.255.249.249 0 00.381.247A12.167 12.167 0 019.946 1.8z\"/><path d=\"M13.665 23.859A2.633 2.633 0 0015.9 23.6a11.079 11.079 0 002-1.589A11.253 11.253 0 101.989 6.1 11.145 11.145 0 00.4 8.1a2.651 2.651 0 00.685 3.534.256.256 0 01.1.179 1.991 1.991 0 00.578 1.231 1.983 1.983 0 00.516.372 20.231 20.231 0 018.3 8.306 2 2 0 00.806.838 2.066 2.066 0 00.792.248.245.245 0 01.178.1 2.646 2.646 0 001.31.951zM4 6.983a.249.249 0 01.332 0 4 4 0 006.527-1.928.251.251 0 01.273-.182 9.187 9.187 0 012.86.85.252.252 0 01.133.287 4 4 0 004.342 4.965.249.249 0 01.266.17 9.3 9.3 0 01.464 2.734.25.25 0 01-.188.247 4 4 0 00-2.372 6.043.249.249 0 01-.03.309q-.056.059-.114.117a9.137 9.137 0 01-1.645 1.3.641.641 0 01-.544.064.211.211 0 01-.123-.289 2 2 0 00-.018-1.759A24.224 24.224 0 004.08 9.839a1.987 1.987 0 00-1.527-.113 1.921 1.921 0 00-.231.093.207.207 0 01-.169 0 .2.2 0 01-.114-.119.645.645 0 01.061-.55 9.137 9.137 0 011.3-1.64c.021-.018.376-.332.6-.527z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});