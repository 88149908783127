define("ember-svg-jar/inlined/controls-movie-next", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-movie-next</title><path d=\"M9.493 17.156a7.783 7.783 0 018.021-7.5 8.407 8.407 0 014.09 1.05.25.25 0 00.371-.218V6.375a.486.486 0 00-.5-.469H.47a.486.486 0 00-.5.469v9.375a2.431 2.431 0 002.506 2.344h6.8a.25.25 0 00.249-.274q-.031-.328-.032-.664z\"/><path d=\"M17.514 11.063A6.323 6.323 0 0011 17.156a6.324 6.324 0 006.517 6.094 6.323 6.323 0 006.517-6.094 6.322 6.322 0 00-6.52-6.093zm-3.509 3.75a.506.506 0 01.823-.36l3.007 2.347a.449.449 0 010 .72l-3.007 2.34a.506.506 0 01-.823-.36zm5.765 5.156a.729.729 0 01-.752-.7v-4.222a.754.754 0 011.5 0v4.219a.729.729 0 01-.748.703zM4.472 4.1a.224.224 0 00-.054.255.251.251 0 00.231.145h3.183a.261.261 0 00.178-.069l3.506-3.281a.224.224 0 00.055-.25.252.252 0 00-.232-.15H8.156a.26.26 0 00-.177.069zM16.5 4.1a.224.224 0 00-.054.255.251.251 0 00.232.145h4.8a.486.486 0 00.5-.469v-.937A2.375 2.375 0 0020.169.843a.263.263 0 00-.247.057zM.475 4.5h1.342a.26.26 0 00.177-.069L5.5 1.15A.224.224 0 005.555.9a.251.251 0 00-.232-.15H2.48A2.432 2.432 0 00-.027 3.094v.937a.487.487 0 00.502.469zM10.488 4.1a.222.222 0 00-.054.255.25.25 0 00.231.145h3.183a.258.258 0 00.177-.069l3.507-3.281a.222.222 0 00.054-.25.251.251 0 00-.231-.15h-3.183a.261.261 0 00-.178.069z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});