define("ember-svg-jar/inlined/business-ladder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-ladder</title><path d=\"M14.691 23.921a1.614 1.614 0 002.032-1.036L23.6 3.373a1.613 1.613 0 00-3.068-1l-.5 1.431a.251.251 0 01-.265.165l-9.294-1.12a.25.25 0 01-.206-.332l.146-.406a1.613 1.613 0 10-3.068-1L.4 20.44a1.613 1.613 0 003.068 1l.343-.956a.25.25 0 01.26-.164l9.476.944a.251.251 0 01.211.332l-.1.294a1.614 1.614 0 001.033 2.031zM9.4 4.932a.247.247 0 01.265-.163L18.97 5.89a.245.245 0 01.183.116.25.25 0 01.023.215l-.963 2.731a.079.079 0 01-.082.053l-9.526-.951a.251.251 0 01-.211-.333zm-1.877 5.214a.252.252 0 01.26-.165l9.409.94a.253.253 0 01.186.115.248.248 0 01.024.217l-.951 2.7a.251.251 0 01-.263.165l-9.407-.993a.249.249 0 01-.208-.333zm-2.63 8.244a.251.251 0 01-.21-.333L5.7 15.216a.249.249 0 01.261-.164l9.423 1a.249.249 0 01.209.331l-.982 2.789a.249.249 0 01-.26.165z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});