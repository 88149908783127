define("ember-svg-jar/inlined/video-game-bowl-city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-bowl-city</title><circle cx=\"13.534\" cy=\"2.021\" r=\".75\"/><circle cx=\"16.75\" cy=\"1.25\" r=\"1.25\"/><path d=\"M14.833 7.483a.5.5 0 01-.333-.47V6.5A.5.5 0 0115 6h1a1 1 0 000-2H8a1 1 0 000 2h1a.5.5 0 01.5.5v.513a.5.5 0 01-.333.47 8.5 8.5 0 105.666 0zM13 18.75H8a.75.75 0 010-1.5h1.25a.5.5 0 00.5-.5V14.5a.75.75 0 011.5 0v2.25a.5.5 0 00.5.5H13a.75.75 0 010 1.5zm3.25-1.25a.75.75 0 01-1.5 0v-1.25a.5.5 0 00-.5-.5h-.75a.75.75 0 01-.75-.75v-2.5a.75.75 0 011.5 0v1.25a.5.5 0 00.5.5h.75a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});