define("ember-svg-jar/inlined/keyboard-usb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>keyboard-usb</title><path d=\"M16.309 3.244l2.006-1.958A.75.75 0 1017.269.212L15.262 2.17a.75.75 0 101.047 1.074z\"/><path d=\"M22.22 5.714a.249.249 0 010-.277 1.485 1.485 0 00-.149-1.88l-2.1-2.147a.5.5 0 00-.708-.009l-2.855 2.793a.5.5 0 00-.008.706l2.095 2.146a1.485 1.485 0 001.054.453h.02a1.447 1.447 0 00.872-.281.249.249 0 01.366.085A9.991 9.991 0 112 12a9.947 9.947 0 018.42-9.876.5.5 0 01.58.493v1.81a.249.249 0 01-.184.241A4.5 4.5 0 007.5 9v5a4.5 4.5 0 109 0V9a4.5 4.5 0 00-3.316-4.333.249.249 0 01-.184-.24V2.618A2.5 2.5 0 0010.106.151 12 12 0 1024 12a11.929 11.929 0 00-1.78-6.286zM13 9.5a1 1 0 01-2 0V8a1 1 0 012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});