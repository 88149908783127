define("ember-svg-jar/inlined/travel-map-finger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-map-finger</title><path d=\"M19.208 17.374h-2a.5.5 0 01-.5-.5v-5.5a2 2 0 10-4 0v7.061a.5.5 0 01-.884.319l-.578-.7a2 2 0 10-3.07 2.564l2.653 3.082a.5.5 0 00.379.174h12a.5.5 0 00.5-.5v-1.5a4.5 4.5 0 00-4.5-4.5zM1.187 17.03a1 1 0 001.1-.89 14.054 14.054 0 01.318-1.84 1 1 0 00-1.944-.47 16.188 16.188 0 00-.364 2.1 1 1 0 00.89 1.1zM2.39 19.859a1 1 0 10-1.973.324A15.86 15.86 0 00.9 22.259a1 1 0 001.913-.584 14.067 14.067 0 01-.423-1.816zM3.367 8.077a16.219 16.219 0 00-1.156 1.791 1 1 0 001.749.971 13.954 13.954 0 011.012-1.567 1 1 0 00-1.605-1.2zM9.466 4.185A1 1 0 008.1 3.83a16.069 16.069 0 00-1.762 1.2 1 1 0 101.233 1.575 13.893 13.893 0 011.54-1.05 1 1 0 00.355-1.37zM12.421 2.889a.5.5 0 01.047.706l-.762.871a1 1 0 101.505 1.316l.762-.871a.493.493 0 01.343-.169.5.5 0 01.362.122l.872.763a1 1 0 001.317-1.506L16 3.359a.5.5 0 01-.047-.706l.762-.871a1 1 0 10-1.5-1.316l-.762.87a.5.5 0 01-.706.047l-.88-.762a1 1 0 00-1.317 1.506z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});