define("ember-svg-jar/inlined/duck-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>duck-1</title><path d=\"M14.036 0a7.416 7.416 0 00-6.045 3.13 4 4 0 00-3.651 7.016c-.508 1.306-1.39 3.513-2.043 4.821a2.9 2.9 0 00.876 3.718 3.182 3.182 0 004.227-.564c1.21-1.21 3.2-3.007 4.548-4.214l.126.282c.815 2.189.166 3.736-.522 5.374a9.845 9.845 0 00-1.016 3.937.5.5 0 00.5.5h8.5a.5.5 0 00.5-.5 29.761 29.761 0 011.114-8.81 21.5 21.5 0 00.886-6.19c0-5.481-2.842-8.5-8-8.5zM4.342 17.063a.913.913 0 01-.256-1.2c.865-1.731 2.081-4.883 2.433-5.809a1 1 0 00-.565-1.284 2 2 0 112.737-1.857c0 1.8 1.731 3.037 3.5 3.4v.763a.987.987 0 00-.23.152c-.954.838-4.2 3.712-5.976 5.483-.56.557-1.174.689-1.643.352zM14.786 7.75a1 1 0 111-1 1 1 0 01-1 1z\"/><path d=\"M7.871 10.829a.752.752 0 00-1.006.336l-.5 1a.75.75 0 101.342.67l.5-1a.75.75 0 00-.336-1.006z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});