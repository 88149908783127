define("ember-svg-jar/inlined/sport-hockey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sport-hockey</title><circle cx=\"11.026\" cy=\"2.501\" r=\"2.5\"/><path d=\"M20.482 19.29l.6-1.171a1 1 0 00-1.78-.916s-.642 1.233-.671 1.254a.225.225 0 01-.3-.021l-3.8-5.2a1.023 1.023 0 00-.142-.13 1.5 1.5 0 00-.251-1.612l-4.5-5a1.5 1.5 0 00-2.392.217c-4.316 7.014-4.285 7.034-4.361 7.217a1.505 1.505 0 00-.112.57v2.7a1.5 1.5 0 01-.2.744L.421 21.756a1.5 1.5 0 102.605 1.489l2.158-3.809a4.509 4.509 0 00.593-2.236v-.453l1.349.562a1.507 1.507 0 01.846 1.859L6.681 22a1.5 1.5 0 102.845.949l1.292-2.829a4.517 4.517 0 00-2.538-5.576L6.742 13.9a.25.25 0 01-.119-.36l1.957-3.255a.249.249 0 01.4-.039l2.932 3.259a1.465 1.465 0 00.838.444.959.959 0 00.17.47l3.818 5.222a2.227 2.227 0 003.744-.351zM23.777 22.251a1 1 0 00-1-1h-3.5a1 1 0 000 2h3.5a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});