define("ember-svg-jar/inlined/building-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-2</title><path d=\"M24 3a.75.75 0 00-.75-.75H.75a.75.75 0 000 1.5h.75a.5.5 0 01.5.5v17a.5.5 0 00.5.5H9a.5.5 0 00.5-.5v-2.5a2.5 2.5 0 015 0v2.5a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5v-17a.5.5 0 01.5-.5h.75A.75.75 0 0024 3zM9.25 14.25h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm0-4h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm0-4h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm9.5 8h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm0-4h-4a.75.75 0 010-1.5h4a.75.75 0 010 1.5zm.75-4.75a.75.75 0 01-.75.75h-4a.75.75 0 010-1.5h4a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});