define("ember-svg-jar/inlined/outdoors-watch-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>outdoors-watch-house</title><path d=\"M21 13.5H3a.5.5 0 00-.5.5v.5a2 2 0 002 2h.658a.5.5 0 01.48.642L4.08 22.4a1.247 1.247 0 001.8 1.445L12 20.467l6.126 3.38A1.245 1.245 0 0019.92 22.4l-1.558-5.259a.5.5 0 01.48-.642h.658a2 2 0 002-2V14a.5.5 0 00-.5-.5zM9.533 19.543l-2.479 1.368a.25.25 0 01-.361-.289l.7-2.352a.253.253 0 01.145-.161.25.25 0 01.215.013l1.783.983a.249.249 0 010 .438zM14.2 16.97l-2.077 1.146a.253.253 0 01-.242 0L9.8 16.97a.25.25 0 01.121-.469h4.154a.25.25 0 01.121.469zm3.026 3.917a.25.25 0 01-.278.024l-2.479-1.368a.25.25 0 010-.438l1.783-.983a.249.249 0 01.36.148l.7 2.352a.248.248 0 01-.088.265zM6.97 10a.53.53 0 01.53.53v.97a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-.97a.53.53 0 01.53-.53 1 1 0 00.61-1.793L13.2 4.79a.5.5 0 01-.2-.4v-.835a.5.5 0 01.342-.475l1.833-.61a.5.5 0 00.071-.918L12.5.136A1 1 0 0011 1v3.393a.5.5 0 01-.2.4L6.36 8.208A1 1 0 006.97 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});