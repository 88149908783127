define("ember-svg-jar/inlined/smiley-crazy-tongue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-crazy-tongue</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm5.392 8.51a1 1 0 01-.284 1.98l-3.5-.5a1 1 0 01-.565-1.7l3-3a1 1 0 111.414 1.414l-1.586 1.589zM6.043 6.707a1 1 0 011.414-1.414l3 3a1 1 0 01-.543 1.693l-3 .5a.927.927 0 01-.165.014 1 1 0 01-.163-1.986l1.083-.181zM8 19v-2.75a.25.25 0 00-.25-.25H6.5a1 1 0 010-2h11a1 1 0 010 2h-1.25a.25.25 0 00-.25.25V19a4 4 0 01-8 0z\"/><path d=\"M11 16.25v2.25a1 1 0 002 0v-2.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});