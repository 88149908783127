define("ember-svg-jar/inlined/navigation-arrows-right-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>navigation-arrows-right-1</title><path d=\"M8.69 2.027V3.5a.751.751 0 00.278.583l8.579 6.945a1.288 1.288 0 010 1.943l-8.579 6.944a.75.75 0 00-.278.583v1.471a1.25 1.25 0 002.022.983L23.4 12.981a1.289 1.289 0 000-1.966L10.712 1.043a1.25 1.25 0 00-2.022.984z\"/><path d=\"M14.855 12.981a1.289 1.289 0 000-1.966L2.163 1.043a1.249 1.249 0 00-2.021.984v19.942a1.249 1.249 0 002.021.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});