define("ember-svg-jar/inlined/monitor-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-shield-1</title><path d=\"M19 23.751a1 1 0 000-2h-6.25a.25.25 0 01-.25-.25V20a.25.25 0 01.25-.25H21a3 3 0 003-3V2.84A2.548 2.548 0 0021.5.251h-19A2.548 2.548 0 000 2.84v13.911a3 3 0 003 3h7.25a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2zM2 2.84a.551.551 0 01.5-.589h19a.551.551 0 01.5.589v11.911a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5z\"/><path d=\"M16.7 5.215a.87.87 0 00-.523-.8 13.172 13.172 0 00-4.495-.832 12.939 12.939 0 00-4.459.832.87.87 0 00-.523.8v2.827a6.06 6.06 0 004.057 5.282l.461.176a1.3 1.3 0 00.928 0l.461-.176c2.193-.835 4.093-2.937 4.093-5.282zm-2.25 2.87a.75.75 0 01-.75.75h-1a.25.25 0 00-.25.25v1a.75.75 0 01-1.5 0v-1a.25.25 0 00-.25-.25h-1a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-1a.75.75 0 011.5 0v1a.25.25 0 00.25.25h1a.75.75 0 01.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});