define("ember-svg-jar/inlined/temperature-celsius-high", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-celsius-high</title><path d=\"M6.25 5.494a.75.75 0 00.6-1.2L4.1.625a.772.772 0 00-1.2 0L.15 4.294a.75.75 0 00.6 1.2H2a.25.25 0 01.25.25v3.25a1.25 1.25 0 002.5 0v-3.25a.25.25 0 01.25-.25zM15 5.66a9 9 0 109 9 9 9 0 00-9-9zm-4 7a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm2.75 2.75A3.254 3.254 0 0017 18.66h1.5a.75.75 0 010 1.5H17a4.75 4.75 0 010-9.5h1.5a.75.75 0 010 1.5H17a3.254 3.254 0 00-3.25 3.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});