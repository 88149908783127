define("ember-svg-jar/inlined/style-three-pin-basket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-basket</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm5.485 10.621l-.81 3.243A1.5 1.5 0 0115.219 15H8.781a1.5 1.5 0 01-1.456-1.136l-.81-3.243A.5.5 0 017 10h10a.5.5 0 01.485.621zM17.25 9H6.75a1.25 1.25 0 010-2.5h.55a.25.25 0 00.208-.111l1.87-2.8a.75.75 0 111.248.832l-1.132 1.69A.25.25 0 009.7 6.5h4.6a.25.25 0 00.208-.389l-1.13-1.695a.75.75 0 111.248-.832l1.87 2.805a.25.25 0 00.204.111h.548a1.25 1.25 0 010 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});