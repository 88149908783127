define("ember-svg-jar/inlined/car-repair-wash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-repair-wash-1</title><path d=\"M12.249 3.493a.873.873 0 00.874-.873V.874a.874.874 0 00-1.747 0V2.62a.873.873 0 00.873.873zM15.736 2.808a.874.874 0 00.838.626.849.849 0 00.247-.036.873.873 0 00.591-1.084L16.915.63a.874.874 0 00-1.676.494zM15.2 7.126a.874.874 0 001.676-.494l-.5-1.684a.874.874 0 00-1.676.494zM18.707 7.126a.873.873 0 00.838.626.872.872 0 00.838-1.12l-.5-1.684a.874.874 0 10-1.676.494zM4.769 7.717a.889.889 0 00.248.035.873.873 0 00.837-.626l.5-1.684a.873.873 0 10-1.675-.494l-.5 1.684a.874.874 0 00.59 1.085zM7.713 3.4a.849.849 0 00.238.033.875.875 0 00.84-.636l.476-1.689A.873.873 0 107.586.637L7.11 2.326A.873.873 0 007.713 3.4zM8.17 4.956l-.476 1.688a.874.874 0 00.6 1.078.9.9 0 00.238.032.874.874 0 00.84-.636l.48-1.688a.874.874 0 00-1.682-.474zM12.281 4.291a.873.873 0 00-.874.873V6.91a.874.874 0 001.747 0V5.164a.873.873 0 00-.873-.873zM7.01 22H3.018a.25.25 0 00-.249.25v.5A1.248 1.248 0 004.016 24h2a1.249 1.249 0 001.244-1.247v-.5A.25.25 0 007.01 22zM20.982 22H16.99a.25.25 0 00-.25.25v.5A1.249 1.249 0 0017.988 24h2a1.248 1.248 0 001.247-1.247v-.5a.25.25 0 00-.253-.253zM21.863 16.589l-1.082-1.083a.5.5 0 01-.111-.168l-2.046-5.115a1.489 1.489 0 00-1.39-.943H6.932a1.49 1.49 0 00-1.39.941l-1.937 4.842a.481.481 0 01-.11.167l-1.5 1.5a.749.749 0 00-.219.529v2.5a1.248 1.248 0 001.247 1.247h17.959a1.248 1.248 0 001.247-1.247V17.47a1.249 1.249 0 00-.366-.881zm-3.931-2.429a.25.25 0 01-.207.11H6.441a.25.25 0 01-.232-.342l1-2.5a.25.25 0 01.232-.157h9.288a.25.25 0 01.232.157l1 2.5a.248.248 0 01-.029.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});