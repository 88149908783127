define("ember-svg-jar/inlined/style-three-pin-judge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-judge</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM6.569 7.53a.75.75 0 011.045.184l3.156 4.5a.75.75 0 01-1.229.86l-3.155-4.5a.75.75 0 01.183-1.044zm6.831 9.788a.5.5 0 01-.4.192H8a.5.5 0 01-.485-.621l.405-1.621a1 1 0 01.971-.758h3.218a1 1 0 01.971.758l.4 1.621a.5.5 0 01-.08.429zm3.868-3.238a.75.75 0 01-1.229.86l-2.868-4.095a.251.251 0 00-.348-.061l-.823.574a.505.505 0 01-.287.09.427.427 0 01-.087-.008.5.5 0 01-.322-.205L8.721 7.55a.5.5 0 01.122-.7l3.275-2.295a.5.5 0 01.7.123L15.4 8.366a.5.5 0 01-.122.7l-.818.574a.249.249 0 00-.062.348zm.285-5.694A.75.75 0 0116.5 8.2l-3.152-4.5a.75.75 0 111.228-.86l3.155 4.5a.749.749 0 01-.183 1.046z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});