define("ember-svg-jar/inlined/surveillance-location-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>surveillance-location-1</title><path d=\"M24 12a1 1 0 00-1-1h-8.586l5.4-5.395a1 1 0 000-1.414A10.954 10.954 0 0013 1.006V1a1 1 0 00-2 0v.008A11.054 11.054 0 001.007 11H1a1 1 0 000 2h.007A11.055 11.055 0 0011 22.993V23a1 1 0 002 0v-.007A11.055 11.055 0 0022.993 13H23a1 1 0 001-1zM13 3.3a.247.247 0 01.086-.188.25.25 0 01.2-.059 8.947 8.947 0 014.136 1.707.25.25 0 01.026.377l-1.134 1.134a.249.249 0 01-.32.028 6.889 6.889 0 00-2.787-1.154A.25.25 0 0113 4.9zm0 3.64a.25.25 0 01.31-.243 5.411 5.411 0 011.514.627.25.25 0 01.047.391l-1.444 1.444A.25.25 0 0113 8.982zm-2.286-3.888A.25.25 0 0111 3.3v1.6a.249.249 0 01-.207.245 7 7 0 00-1.275.353.25.25 0 00-.086.412 2.526 2.526 0 01.539.781.25.25 0 00.307.137A5.428 5.428 0 0111 6.636v4.114a.25.25 0 01-.25.25h-3.8a.25.25 0 01-.243-.31c.037-.148.079-.294.128-.438a.218.218 0 00-.121-.271 2.517 2.517 0 01-.8-.55.25.25 0 00-.229-.07.253.253 0 00-.183.156 6.943 6.943 0 00-.353 1.277.25.25 0 01-.255.206H3.3a.25.25 0 01-.248-.286 9.058 9.058 0 017.662-7.662zM13 20.985v-.341a.25.25 0 00-.319-.24 2.463 2.463 0 01-1.362 0 .25.25 0 00-.319.24v.341a9.056 9.056 0 01-7.949-7.7A.252.252 0 013.3 13h1.6a.25.25 0 01.246.207 6.978 6.978 0 004 5.142.251.251 0 00.354-.239V18a2.45 2.45 0 01.145-.825.249.249 0 00-.121-.307A5.481 5.481 0 016.7 13.31a.247.247 0 01.046-.214.251.251 0 01.2-.1h3.804a.25.25 0 01.25.25v2.106a.252.252 0 00.1.2.248.248 0 00.219.04 2.463 2.463 0 011.362 0 .248.248 0 00.219-.04.252.252 0 00.1-.2V13.25a.25.25 0 01.25-.25h3.812a.25.25 0 01.242.31 5.484 5.484 0 01-2.828 3.558.25.25 0 00-.121.307A2.45 2.45 0 0114.5 18v.11a.251.251 0 00.353.239 6.978 6.978 0 004-5.142A.25.25 0 0119.1 13h1.6a.25.25 0 01.248.286A9.056 9.056 0 0113 20.985z\"/><circle cx=\"12\" cy=\"18\" r=\"1.5\"/><circle cx=\"7.696\" cy=\"7.695\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});