define("ember-svg-jar/inlined/phonebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phonebook</title><path d=\"M20.15 4.04a.5.5 0 01-.4-.489V2a2 2 0 00-2-2H5.25a3 3 0 00-3 3v18a3 3 0 003 3h14.5a2 2 0 002-2V6a2 2 0 00-1.6-1.96zM4.25 3a1 1 0 011-1H17.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5.25a1 1 0 01-1-1zm13.68 15.606c-.3 1.455-3.194 1.619-3.939 1.619A7.917 7.917 0 018.37 17.9c-3.4-3.367-2.587-9.159-.7-9.555a1.356 1.356 0 011.2.35l1.716 1.725a1.742 1.742 0 01-.006 2.481l-.392.392a2.458 2.458 0 01-1.738.812 1.783 1.783 0 01-.252-.02.249.249 0 00-.273.328 6.3 6.3 0 003.944 3.941.25.25 0 00.329-.269 2.355 2.355 0 01.787-2l.4-.4a1.788 1.788 0 012.482.007l1.7 1.693a1.361 1.361 0 01.363 1.221z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});