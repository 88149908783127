define("ember-svg-jar/inlined/laptop-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>laptop-lock</title><path d=\"M20.5 12a1 1 0 001-1V3a3 3 0 00-3-3h-13a3 3 0 00-3 3v8a1 1 0 002 0V3a1 1 0 011-1h13a1 1 0 011 1v8a1 1 0 001 1zM12 20.5a1 1 0 00-1 1v.25a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2h-9.75a.25.25 0 01-.25-.25v-.25a1 1 0 00-1-1zM17.26 19a7.076 7.076 0 006.714-4.842.5.5 0 00-.474-.658H16a.5.5 0 00-.5.5c0 .708-1.5 1.5-3.5 1.5s-3.5-.792-3.5-1.5a.5.5 0 00-.5-.5H.5a.5.5 0 00-.474.658A7.076 7.076 0 006.74 19z\"/><path d=\"M12 3.5a3.254 3.254 0 00-3.25 3.25v.322a.251.251 0 01-.107.205A1.489 1.489 0 008 8.5v2A1.5 1.5 0 009.5 12h5a1.5 1.5 0 001.5-1.5v-2a1.489 1.489 0 00-.643-1.223.251.251 0 01-.107-.205V6.75A3.254 3.254 0 0012 3.5zm1 6a1 1 0 11-1-1 1 1 0 011 1zM12 5a1.752 1.752 0 011.75 1.75.25.25 0 01-.25.25h-3a.25.25 0 01-.25-.25A1.752 1.752 0 0112 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});