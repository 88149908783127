define("ember-svg-jar/inlined/calendar-setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-setting</title><path d=\"M18.5 8.659V3a1 1 0 00-1-1H15a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 01-1.5 0v-2a.5.5 0 00-.5-.5H7a.25.25 0 01-.25-.25v-1a.75.75 0 10-1.5 0V4.5a.75.75 0 11-1.5 0v-2a.5.5 0 00-.5-.5H1a1 1 0 00-1 1v13.5a2 2 0 002 2h7.569a7.178 7.178 0 010-2H2.5A.5.5 0 012 16V7.5a.5.5 0 01.5-.5H16a.5.5 0 01.5.5v1.319a3.1 3.1 0 012-.16z\"/><circle cx=\"17.749\" cy=\"17.032\" r=\"1.5\"/><path d=\"M23.516 15.655a1.782 1.782 0 00-1.7-2.953l-1.41.327a.455.455 0 01-.535-.311l-.423-1.392a1.775 1.775 0 00-3.4 0l-.423 1.391a.459.459 0 01-.534.312l-1.41-.329a1.782 1.782 0 00-1.7 2.953l.988 1.064a.464.464 0 010 .626l-.988 1.065a1.783 1.783 0 001.7 2.953l1.411-.327a.453.453 0 01.534.311l.423 1.392a1.775 1.775 0 003.4 0l.423-1.392a.452.452 0 01.534-.311l1.411.327a1.783 1.783 0 001.7-2.953l-.988-1.065a.464.464 0 010-.626zm-5.767 4.377a3 3 0 113-3 3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});