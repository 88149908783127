define("ember-svg-jar/inlined/cake-cream", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cake-cream</title><path d=\"M14.248 7.5h-4.5a.5.5 0 00-.5.527l.838 15.5a.5.5 0 00.5.473h2.82a.5.5 0 00.5-.473l.838-15.5a.5.5 0 00-.5-.527zM7.248 7.5H4a1 1 0 00-.991 1.132L4.889 22.7A1.5 1.5 0 006.375 24h1.711a.5.5 0 00.5-.527l-.838-15.5a.5.5 0 00-.5-.473zM20 7.5h-3.248a.5.5 0 00-.5.473l-.838 15.5a.5.5 0 00.5.527h1.711a1.5 1.5 0 001.486-1.3l1.88-14.065A1 1 0 0020 7.5zM16.26 3.757a4.294 4.294 0 00-8.52 0C6.486 1.639 3.056 1.8 2.749 5.458a.5.5 0 00.5.542h17.503a.5.5 0 00.5-.542c-.308-3.669-3.744-3.813-4.992-1.701z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});