define("ember-svg-jar/inlined/app-window-move-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-move-right</title><path d=\"M24 4.751a3 3 0 00-3-3H3a3 3 0 00-3 3v14.5a3 3 0 003 3h18a3 3 0 003-3zm-14.346-1a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.5 0a.966.966 0 011.692 0 .969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.969.969 0 01.154-.5zm-3.562.091a1 1 0 01.908-.591.983.983 0 01.846.5.969.969 0 01.154.5.972.972 0 01-.154.5.966.966 0 01-1.692 0 .972.972 0 01-.154-.5.987.987 0 01.092-.409zM22 19.251a1 1 0 01-1 1H8.25A.25.25 0 018 20v-1.5a.75.75 0 00-1.5 0V20a.25.25 0 01-.25.25H3a1 1 0 01-1-1V7a.25.25 0 01.25-.25h4A.25.25 0 016.5 7v1.5a.75.75 0 001.5 0V7a.25.25 0 01.25-.25h13.5A.25.25 0 0122 7z\"/><path d=\"M7.25 11.751a.75.75 0 00-.75.75v2a.75.75 0 001.5 0v-2a.75.75 0 00-.75-.75zM15.854 10.646a.505.505 0 00-.545-.109A.5.5 0 0015 11v1.25a.25.25 0 01-.25.25H11a1 1 0 000 2h3.75a.25.25 0 01.25.25V16a.5.5 0 00.309.462.5.5 0 00.545-.108l2.5-2.5a.5.5 0 000-.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});