define("ember-svg-jar/inlined/monitor-programming-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monitor-programming-book</title><path d=\"M2.5 16a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h14.625a.5.5 0 01.5.5V8a1 1 0 002 0V4a2 2 0 00-2-2H2a2 2 0 00-2 2v12a2 2 0 002 2h5.375a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25H5a1 1 0 000 2h7.625a1 1 0 100-2h-2.75a.25.25 0 01-.25-.25v-1.524a.25.25 0 01.2-.245 1 1 0 00-.2-1.98z\"/><path d=\"M3.47 9.971a.75.75 0 101.06 1.06l2-2a.749.749 0 000-1.06l-2-2a.75.75 0 10-1.06 1.06l1.293 1.293a.252.252 0 010 .354zM7 11.751h2.125a.75.75 0 000-1.5H7a.75.75 0 100 1.5zM18.25 16.5a.5.5 0 11-1 0v-5.226a.5.5 0 00-.239-.426 8.957 8.957 0 00-4.177-1.1 1.2 1.2 0 00-1.253 1.2v7.279a1.2 1.2 0 001.156 1.2 7.082 7.082 0 014.523 1.593.742.742 0 00.528.218.751.751 0 00.529-.215 7.084 7.084 0 014.527-1.6A1.2 1.2 0 0024 18.235v-7.279a1.208 1.208 0 00-.369-.867 1.186 1.186 0 00-.886-.337A8.851 8.851 0 0018.48 10.9a.5.5 0 00-.23.421z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});