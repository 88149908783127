define("ember-svg-jar/inlined/data-transfer-square-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-square-vertical</title><path d=\"M22.043 0H1.957A1.959 1.959 0 000 1.956v20.088A1.959 1.959 0 001.957 24h20.086A1.959 1.959 0 0024 22.044V1.956A1.959 1.959 0 0022.043 0zM5.75 9.5a.749.749 0 01-.6-1.2L7.9 4.634a.78.78 0 011.2 0L11.85 8.3a.75.75 0 01-.6 1.2h-1.5a.25.25 0 00-.25.25v6.75a1 1 0 01-2 0V9.75a.25.25 0 00-.25-.25zm12.5 5a.749.749 0 01.6 1.2l-2.75 3.666a.75.75 0 01-1.2 0L12.15 15.7a.75.75 0 01.6-1.2h1.5a.25.25 0 00.25-.25V7.5a1 1 0 012 0v6.75a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});