define("ember-svg-jar/inlined/sports-car", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sports-car</title><path d=\"M23 14.75a.25.25 0 01-.25-.25v-3a1.247 1.247 0 00-.625-1.079.251.251 0 01-.125-.216V8.468a.25.25 0 01.187-.242l1-.258a1 1 0 00-.5-1.936l-2.905.75a1 1 0 00.024 1.938.25.25 0 01.194.243v.645a.25.25 0 01-.393.205l-4.929-3.429c-.059-.041-.178-.134-.678-.134H9a2.988 2.988 0 00-2.684 1.662l-1.1 2.2a.249.249 0 01-.223.138H2.26A2.253 2.253 0 00.01 12.5v3a1.252 1.252 0 001.25 1.25h.546a.25.25 0 00.246-.293A2.651 2.651 0 012.01 16a3 3 0 016 0 2.651 2.651 0 01-.042.457.25.25 0 00.247.293h5.83a.25.25 0 00.247-.293A2.651 2.651 0 0114.25 16a3 3 0 016 0 2.651 2.651 0 01-.042.457.248.248 0 00.055.2.253.253 0 00.192.089H23a1 1 0 000-2zM12.432 8.588l-.376 1a1.01 1.01 0 01-.946.659H7.786a.25.25 0 01-.224-.362L8.1 8.808A1 1 0 019 8.25h3.2a.248.248 0 01.205.108.251.251 0 01.027.23z\"/><circle cx=\"5.01\" cy=\"16\" r=\"2\"/><circle cx=\"17.25\" cy=\"16\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});