define("ember-svg-jar/inlined/social-picasa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-picasa</title><path d=\"M17.25 15.25a.5.5 0 00.5.5H23a.5.5 0 00.48-.36A11.94 11.94 0 0018 1.63a.5.5 0 00-.75.43zM14.9 7.45a.5.5 0 00.85-.35V1a.5.5 0 00-.36-.5A11.79 11.79 0 008.9.42a.5.5 0 00-.22.84zM8.75 17.25a.5.5 0 00-.5.5V23a.5.5 0 00.36.48A11.94 11.94 0 0022.37 18a.5.5 0 00-.43-.75zM6.75 13.15a.5.5 0 00-.85-.35l-4.34 4.34a.5.5 0 00-.08.59A12.06 12.06 0 006 22.37a.5.5 0 00.75-.43zM10.59 6a.5.5 0 000-.71L6.86 1.56a.5.5 0 00-.59-.08A12 12 0 000 12a11.81 11.81 0 00.42 3.1.5.5 0 00.84.22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});