define("ember-svg-jar/inlined/delivery-truck-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>delivery-truck-2</title><path d=\"M22.566 4H11.934A1.47 1.47 0 0010.5 5.5v9.777a.249.249 0 00.115.21 2.993 2.993 0 011.376 2.282.251.251 0 00.249.231h3.52a.25.25 0 00.249-.231 3 3 0 015.982 0 .251.251 0 00.249.231h1.26a.5.5 0 00.5-.5v-12A1.47 1.47 0 0022.566 4zM0 16.5a2 2 0 002 2h3.763a.251.251 0 00.249-.275A2.386 2.386 0 016 18a3 3 0 012.769-2.991A.251.251 0 009 14.76V7.5a1 1 0 00-1-1H5a5.006 5.006 0 00-5 5zm5-8h1.75a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H2.261a.25.25 0 01-.249-.273A3 3 0 015 8.5z\"/><circle cx=\"9\" cy=\"18\" r=\"2\"/><circle cx=\"19\" cy=\"18\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});