define("ember-svg-jar/inlined/align-left-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>align-left-move</title><path d=\"M1 0a1 1 0 00-1 1v22a1 1 0 002 0V1a1 1 0 00-1-1z\"/><rect x=\"5\" y=\"4.5\" width=\"19\" height=\"6\" rx=\"1\" ry=\"1\"/><rect x=\"5\" y=\"13.5\" width=\"13\" height=\"6\" rx=\"1\" ry=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});