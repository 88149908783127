define("ember-svg-jar/inlined/style-three-pin-snow-shoes-skies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-snow-shoes-skies</title><path d=\"M8.75 10.5a.25.25 0 00-.25.25v1.069a.179.179 0 00.32.111 9.078 9.078 0 00.751-1.05.25.25 0 00-.213-.38zM7.18 11.93a.179.179 0 00.32-.111V10.75a.25.25 0 00-.25-.25h-.612a.25.25 0 00-.213.379 8.169 8.169 0 00.755 1.051zM8.75 8a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.3a.251.251 0 00.239-.175 4.489 4.489 0 00.2-1.094.217.217 0 00-.214-.231zM7.5 8.25A.25.25 0 007.25 8H5.758a.25.25 0 00-.182.079.248.248 0 00-.067.187 4.459 4.459 0 00.2 1.059.249.249 0 00.241.175h1.3a.25.25 0 00.25-.25zM7.25 7a.25.25 0 00.25-.25v-.87a.25.25 0 00-.334-.236 2.52 2.52 0 00-1.242.967.25.25 0 00.208.389zM9.868 7a.25.25 0 00.208-.389 2.52 2.52 0 00-1.242-.967.25.25 0 00-.334.236v.87a.25.25 0 00.25.25zM16.75 14a.25.25 0 00-.25.25v.87a.25.25 0 00.334.236 2.52 2.52 0 001.242-.967.25.25 0 00-.208-.389zM15.25 10.5a.25.25 0 00.25-.25V9.181a.179.179 0 00-.32-.111 9.144 9.144 0 00-.75 1.05.25.25 0 00.213.38zM16.75 11.5a.25.25 0 00-.25.25v1a.25.25 0 00.25.25h1.492a.25.25 0 00.182-.079.248.248 0 00.067-.187 4.459 4.459 0 00-.2-1.059.249.249 0 00-.238-.175z\"/><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9.069 13.158A.252.252 0 009 13.33v2.17a1 1 0 01-2 0v-2.17a.252.252 0 00-.069-.172A7.977 7.977 0 014.5 8a3.5 3.5 0 017 0 7.975 7.975 0 01-2.431 5.158zM16 16.5a3.508 3.508 0 01-3.5-3.5 7.975 7.975 0 012.431-5.158A.252.252 0 0015 7.67V5.5a1 1 0 012 0v2.17a.252.252 0 00.069.172A7.977 7.977 0 0119.5 13a3.508 3.508 0 01-3.5 3.5z\"/><path d=\"M16.82 9.07a.179.179 0 00-.32.111v1.069a.25.25 0 00.25.25h.608a.249.249 0 00.218-.128.245.245 0 000-.252 9.078 9.078 0 00-.756-1.05zM14.132 14a.25.25 0 00-.208.389 2.52 2.52 0 001.242.967.25.25 0 00.334-.236v-.87a.25.25 0 00-.25-.25zM15.5 11.75a.25.25 0 00-.25-.25h-1.3a.249.249 0 00-.238.175 4.459 4.459 0 00-.2 1.059.244.244 0 00.067.187.25.25 0 00.182.079h1.489a.25.25 0 00.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});