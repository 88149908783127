define("ember-svg-jar/inlined/single-man-actions-share-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-share-1</title><path d=\"M23.8 15.232c-.253-1.6-.435-2.765-1.707-3.31l-3.936-1.687a.25.25 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.3 3.3 0 00.779-2.23.248.248 0 01.089-.177 1.876 1.876 0 00.537-1.4 1.58 1.58 0 00-.317-1.015.249.249 0 01-.033-.264 2.537 2.537 0 00.156-1.727C18.944.493 17.242 0 15.888 0a3.754 3.754 0 00-3.163 1.325.25.25 0 01-.212.118 1.07 1.07 0 00-.9.375c-.372.492-.105 1.17.176 1.732a.248.248 0 01-.046.287 1.287 1.287 0 00-.161.2A1.766 1.766 0 0011.32 5a1.522 1.522 0 00.639 1.331.252.252 0 01.117.194 3.158 3.158 0 00.845 2.175.25.25 0 01.08.183v1.122a.25.25 0 01-.152.23l-3.936 1.687c-1.184.507-1.424 1.547-1.655 2.981a.249.249 0 00.247.29h4.608a1.991 1.991 0 011.951 1.607.25.25 0 00.245.2H23.5a.506.506 0 00.385-.18.5.5 0 00.106-.411c-.078-.427-.14-.819-.191-1.177zM1 16.387a1 1 0 00-1 1V23a1 1 0 002 0v-5.614a1 1 0 00-1-.999zM12.5 20.193h-7a.353.353 0 01-.322-.387.371.371 0 01.371-.371H7.5a.887.887 0 00.819-1.235 1.909 1.909 0 00-1.814-1l-3-.032a.5.5 0 00-.5.5V22.7a.5.5 0 00.5.5h8a2.04 2.04 0 002-1.925v-.2a.97.97 0 00-1.005-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});