define("ember-svg-jar/inlined/segway-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>segway-2</title><path d=\"M7.9.5H4.6a1.983 1.983 0 00-1.54.72 2.04 2.04 0 00-.42 1.68l3.29 14.94A1.989 1.989 0 007.9 19.5h.256a.25.25 0 00.25-.264c0-.078-.006-.157-.006-.236a5.257 5.257 0 01.137-1.22.251.251 0 00-.236-.309l-.216-.006a.249.249 0 01-.237-.2L4.664 2.8a.251.251 0 01.245-.3H7.9a1 1 0 000-2zM19.9 18h-.3a.25.25 0 00-.248.282A5.7 5.7 0 0119.4 19a5.355 5.355 0 01-.261 1.672.25.25 0 00.238.328h.523a1.5 1.5 0 000-3zM13.9 14.5a4.5 4.5 0 104.5 4.5 4.494 4.494 0 00-4.5-4.5zm0 6a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});