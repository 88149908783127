define("ember-svg-jar/inlined/cog-browser-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-browser-alternate</title><path d=\"M7.8 13.289a.3.3 0 010 .422l-.859.9a1.553 1.553 0 00-.229 1.868 1.588 1.588 0 001.764.771l1.224-.272a.329.329 0 01.389.215l.368 1.184a1.617 1.617 0 003.08 0l.368-1.183a.33.33 0 01.39-.215l1.227.277a1.587 1.587 0 001.764-.771 1.553 1.553 0 00-.229-1.868l-.859-.9a.3.3 0 010-.422l.859-.9a1.553 1.553 0 00.229-1.868 1.588 1.588 0 00-1.764-.771l-1.228.277a.33.33 0 01-.389-.215l-.365-1.194a1.617 1.617 0 00-3.08 0l-.368 1.183a.327.327 0 01-.39.215l-1.227-.277a1.585 1.585 0 00-1.764.771 1.553 1.553 0 00.229 1.868zm4.2-1.7a1.913 1.913 0 11-1.957 1.911A1.935 1.935 0 0112 11.587z\"/><path d=\"M22 1H2a2 2 0 00-2 2v18a2 2 0 002 2h20a2 2 0 002-2V3a2 2 0 00-2-2zM5.5 3.583a1 1 0 111 1 1 1 0 01-1-1zm3.5 0a1 1 0 111 1 1 1 0 01-1-1zm-6-1a1 1 0 11-1 1 1 1 0 011-1zM21.5 21h-19a.5.5 0 01-.5-.5V7a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v13.5a.5.5 0 01-.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});