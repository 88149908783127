define("ember-svg-jar/inlined/army-symbol-platoon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>army-symbol-platoon</title><path d=\"M22 24H2a2 2 0 01-2-2V9a2 2 0 012-2h20a2 2 0 012 2v13a2 2 0 01-2 2zm0-14.5a.5.5 0 00-.5-.5h-19a.5.5 0 00-.5.5v12a.5.5 0 00.5.5h19a.5.5 0 00.5-.5zM5 6a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM19 6a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1zM12 6a3 3 0 113-3 3 3 0 01-3 3zm0-4a1 1 0 101 1 1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});