define("ember-svg-jar/inlined/agriculture-machine-plow-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>agriculture-machine-plow-3</title><path d=\"M22.5 11.5a1.5 1.5 0 00-1.3.75.5.5 0 01-.433.25H3.232a.5.5 0 01-.433-.25A1.5 1.5 0 000 13v8a1 1 0 001 1h1a1 1 0 001-1v-.5a.5.5 0 01.5-.5h1.219a.5.5 0 01.486.379l.31 1.242a.5.5 0 00.97 0l.31-1.242A.5.5 0 017.281 20h1.438a.5.5 0 01.486.379l.31 1.242a.5.5 0 00.97 0l.31-1.242a.5.5 0 01.486-.379h1.438a.5.5 0 01.486.379l.31 1.242a.5.5 0 00.97 0l.31-1.242a.5.5 0 01.486-.379h1.438a.5.5 0 01.486.379l.31 1.242a.5.5 0 00.97 0l.31-1.242a.5.5 0 01.486-.379H20.5a.5.5 0 01.5.5v.5a1 1 0 001 1h1a1 1 0 001-1v-8a1.5 1.5 0 00-1.5-1.5zm-1.5 5a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h1.344a.5.5 0 01.486.379l.185.742a.5.5 0 00.97 0l.185-.742a.5.5 0 01.486-.379h1.688a.5.5 0 01.486.379l.185.742a.5.5 0 00.97 0l.185-.742a.5.5 0 01.486-.379h1.688a.5.5 0 01.486.379l.185.742a.5.5 0 00.97 0l.185-.742a.5.5 0 01.486-.379h1.688a.5.5 0 01.486.379l.185.742a.5.5 0 00.97 0l.185-.742a.5.5 0 01.486-.379H20.5a.5.5 0 01.5.5zM6.386 11.244a1 1 0 001.107-.88L7.854 7.2a.5.5 0 01.5-.443l7.3.012a.5.5 0 01.5.443l.36 3.157a1 1 0 001.987-.226l-.408-3.585a2 2 0 00-.671-1.308.5.5 0 01-.118-.6l.6-1.2a1 1 0 10-1.789-.894l-.553 1.105a.5.5 0 01-.67.224L12.67 2.777a1.506 1.506 0 00-1.341 0L9.118 3.883a.5.5 0 01-.671-.224l-.553-1.1a1 1 0 10-1.789.894l.6 1.2a.5.5 0 01-.119.6 1.971 1.971 0 00-.668 1.293l-.41 3.6a1 1 0 00.878 1.098z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});