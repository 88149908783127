define("ember-svg-jar/inlined/road-traffic-lights-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-traffic-lights-1</title><path d=\"M13 18.5h-2a.25.25 0 00-.25.25v4a1.25 1.25 0 002.5 0v-4a.25.25 0 00-.25-.25zM16.654 5.334a.249.249 0 00.273-.054l2-2A.75.75 0 0018.4 2h-1.65a.25.25 0 00-.25.25V5.1a.249.249 0 00.154.234zM18.4 7h-1.65a.25.25 0 00-.25.25v2.85a.25.25 0 00.427.177l2-2A.75.75 0 0018.4 7zM18.4 12h-1.65a.25.25 0 00-.25.25v2.85a.25.25 0 00.427.177l2-2A.75.75 0 0018.4 12zM7.25 2H5.6a.75.75 0 00-.531 1.28l2 2A.25.25 0 007.5 5.1V2.25A.25.25 0 007.25 2zM7.25 7H5.6a.75.75 0 00-.531 1.28l2 2a.25.25 0 00.431-.18V7.25A.25.25 0 007.25 7zM7.25 12H5.6a.75.75 0 00-.531 1.28l2 2a.25.25 0 00.431-.18v-2.85a.25.25 0 00-.25-.25zM13.5 0h-3a2 2 0 00-2 2v14.5a1 1 0 001 1h5a1 1 0 001-1V2a2 2 0 00-2-2zM12 16.5a2 2 0 112-2 2 2 0 01-2 2zm0-5.25a2 2 0 112-2 2 2 0 01-2 2zM12 6a2 2 0 112-2 2 2 0 01-2 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});