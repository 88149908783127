define("ember-svg-jar/inlined/cursor-question-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-question-alternate</title><path d=\"M9.959 4.538a9.5 9.5 0 109.5 9.5 9.511 9.511 0 00-9.5-9.5zm0 17a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5zM23.393.6a.5.5 0 00-.457-.136l-8.81 1.861a.5.5 0 00-.073.957 11.48 11.48 0 016.658 6.658.5.5 0 00.957-.072l1.861-8.811A.5.5 0 0023.393.6z\"/><circle cx=\"9.959\" cy=\"19.038\" r=\"1.5\"/><path d=\"M9.959 8.038a3.589 3.589 0 00-3.585 3.586 1 1 0 002 0 1.586 1.586 0 112.22 1.454 2.725 2.725 0 00-1.635 2.5 1 1 0 002 0 .724.724 0 01.436-.664 3.587 3.587 0 00-1.436-6.876z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});