define("ember-svg-jar/inlined/diving-fins", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>diving-fins</title><path d=\"M23.015.486A1.509 1.509 0 0021.909 0H21.7a.5.5 0 00-.5.479l-.45 10.574a.751.751 0 01-.749.718h-.033a.75.75 0 01-.717-.782L19.7.521A.5.5 0 0019.2 0h-2.4a.5.5 0 00-.5.52l.447 10.97a.751.751 0 01-.719.78H16a.75.75 0 01-.749-.72L14.8.48a.5.5 0 00-.5-.48h-.209A1.5 1.5 0 0012.6 1.63l1.646 18.925a3.772 3.772 0 007.516 0L23.4 1.631a1.5 1.5 0 00-.385-1.145zM20 20a2 2 0 01-4 0v-2a2 2 0 014 0zM6 0a3.751 3.751 0 00-3.758 3.446L.6 22.371A1.5 1.5 0 002.091 24H2.3a.5.5 0 00.5-.479l.449-10.533a.74.74 0 01.781-.717.752.752 0 01.718.782l-.443 10.426a.5.5 0 00.5.521H7.2a.5.5 0 00.5-.521l-.449-10.426a.75.75 0 011.5-.065L9.2 23.521a.5.5 0 00.5.479h.211a1.5 1.5 0 001.489-1.629L9.758 3.446A3.75 3.75 0 006 0zm2 6a2 2 0 01-4 0V4a2 2 0 014 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});