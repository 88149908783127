define("ember-svg-jar/inlined/single-neutral-actions-view.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-view</title><path d=\"M10.98 12.213a.5.5 0 00-.149-.744 7.378 7.378 0 00-3.625-.969 7.543 7.543 0 00-7.185 5.357.5.5 0 00.479.643h8.517a.5.5 0 00.5-.466 6.951 6.951 0 011.463-3.821z\"/><circle cx=\"7\" cy=\"4.75\" r=\"4.75\"/><path d=\"M21.173 19.759a.25.25 0 01-.034-.311 5.509 5.509 0 10-1.691 1.691.25.25 0 01.311.034l2.534 2.534a1 1 0 001.414-1.414zM16.5 13a3.5 3.5 0 11-3.5 3.5 3.5 3.5 0 013.5-3.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});