define("ember-svg-jar/inlined/download-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>download-square</title><path d=\"M22.286 0H1.714A1.716 1.716 0 000 1.714v20.571A1.716 1.716 0 001.714 24h20.572A1.716 1.716 0 0024 22.285V1.714A1.716 1.716 0 0022.286 0zM9.551 7.347h4.9a.489.489 0 01.49.489v.98a.49.49 0 01-.49.49h-4.9a.49.49 0 01-.49-.49v-.98a.489.489 0 01.49-.489zM9.061 4.9v-.98a.49.49 0 01.49-.49h4.9a.49.49 0 01.49.49v.98a.489.489 0 01-.49.489h-4.9a.489.489 0 01-.49-.489zm.49 6.367h4.9a.49.49 0 01.49.49v1.709a.25.25 0 00.25.25H16.9a.844.844 0 01.633 1.529l-4.492 4.492a1.47 1.47 0 01-2.078 0l-4.494-4.494a.844.844 0 01.631-1.529h1.711a.25.25 0 00.25-.25v-1.709a.49.49 0 01.49-.49z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});