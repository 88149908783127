define("ember-svg-jar/inlined/farming-barn-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>farming-barn-2</title><path d=\"M23.7 9.823L20.514 3.89a.984.984 0 00-.463-.413L12.4.084a1 1 0 00-.8 0L3.949 3.477a1 1 0 00-.471.427L.3 9.822a.8.8 0 00-.009.805A.8.8 0 001 11h.5a.5.5 0 01.5.5v12a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-12a.5.5 0 01.5-.5h.5a.769.769 0 00.7-1.177zM10 6.5a1 1 0 011-1h2a1 1 0 011 1v3a1 1 0 01-1 1h-2a1 1 0 01-1-1zm-2.7 7.651A.2.2 0 017.5 14h9a.205.205 0 01.1.382l-4.351 2.559a.5.5 0 01-.506 0L7.4 14.382a.205.205 0 01-.1-.231zM6.126 20.4A.25.25 0 016 20.183v-3.865a.251.251 0 01.377-.216l3.285 1.933a.249.249 0 010 .43L6.377 20.4a.253.253 0 01-.251 0zm10.45 1.916a.25.25 0 01-.241.184h-8.67a.25.25 0 01-.127-.465l4.209-2.476a.5.5 0 01.507 0l4.208 2.476a.249.249 0 01.114.281zM18 20.183a.25.25 0 01-.126.217.253.253 0 01-.251 0l-3.285-1.933a.249.249 0 010-.43l3.285-1.937a.251.251 0 01.377.216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});