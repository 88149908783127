define("ember-svg-jar/inlined/grid-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>grid-monitor</title><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v16A1.5 1.5 0 001.5 19h21a1.5 1.5 0 001.5-1.5v-16A1.5 1.5 0 0022.5 0zM22 14.5a.5.5 0 01-.5.5h-19a.5.5 0 01-.5-.5v-12a.5.5 0 01.5-.5h19a.5.5 0 01.5.5zM14.35 20.681a.5.5 0 00-.385-.181h-3.93a.5.5 0 00-.385.181l-2.1 2.532a.5.5 0 00.41.787h8.079a.5.5 0 00.41-.787z\"/><path d=\"M16.5 6.25a.75.75 0 000-1.5H16a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25V4a.75.75 0 00-1.5 0v.5a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5H8a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5H8a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-.5a.75.75 0 000 1.5H8a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v.5a.75.75 0 001.5 0v-.5a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5H16a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h.5a.75.75 0 000-1.5H16a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm-6.75.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25zm.25 4.25a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25zm4.25-.25a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25zm0-3a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25h1a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});