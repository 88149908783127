define("ember-svg-jar/inlined/app-window-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>app-window-expand</title><path d=\"M10.563 10.059a.5.5 0 01.462-.308h4.5a.5.5 0 01.5.5v4.5a.5.5 0 01-.854.353l-1.366-1.366a.25.25 0 00-.353 0l-3.72 3.72a1 1 0 01-1.414-1.414l3.72-3.72a.25.25 0 000-.353L10.671 10.6a.5.5 0 01-.108-.541z\"/><rect x=\"-.025\" y=\"18.751\" width=\"10\" height=\"4\" rx=\"1\" ry=\"1\"/><path d=\"M15.025 19.751a1 1 0 00-1 1 1 1 0 001 1h6a3 3 0 003-3v-14.5a3 3 0 00-3-3h-18a3 3 0 00-3 3v9.5a1 1 0 001 1 1 1 0 001-1V6.5a.25.25 0 01.25-.25h19.5a.25.25 0 01.25.25v12.25a1 1 0 01-1 1zm-5.4-16.5a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.158-.5zm-3.5 0a.967.967 0 011.693 0 .977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.969.969 0 01.158-.5zm-3.562.091a1 1 0 01.908-.591.986.986 0 01.847.5.977.977 0 01.153.5.98.98 0 01-.153.5.967.967 0 01-1.693 0 .972.972 0 01-.154-.5.987.987 0 01.096-.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});