define("ember-svg-jar/inlined/smart-watch-circle-play-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-play-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.25.25 0 01.109.148l.908 3.407A.5.5 0 008.5 24h7a.5.5 0 00.482-.368l.935-3.428a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M15.718 13.216a1.112 1.112 0 000-1.929l-4.5-2.628a1.15 1.15 0 00-1.137-.014 1.12 1.12 0 00-.581.978v5.258a1.12 1.12 0 00.582.978 1.158 1.158 0 001.135-.014z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});