define("ember-svg-jar/inlined/marine-mammal-penguin-crested", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-penguin-crested</title><path d=\"M23.993 17.669c-.021-.129-.545-3.177-2.139-4.771a5.75 5.75 0 00-2.288-1.443 17.031 17.031 0 00-2.456-6.2 4.568 4.568 0 011.943.384 1 1 0 10.894-1.788 7.385 7.385 0 00-3.84-.556 5.952 5.952 0 01.493-.413 3.266 3.266 0 012.584-.852 1 1 0 10.632-1.9c-.2-.07-2.065-.615-4.417 1.148a8.6 8.6 0 00-1.021.908A9.554 9.554 0 0012 1.752a9.554 9.554 0 00-2.378.438A8.686 8.686 0 008.6 1.282C6.251-.48 4.39.064 4.184.134a.986.986 0 00-.627 1.235 1.01 1.01 0 001.236.669 3.24 3.24 0 012.607.844 6.136 6.136 0 01.494.413 7.4 7.4 0 00-3.84.556 1 1 0 10.894 1.788 4.583 4.583 0 011.942-.384 16.987 16.987 0 00-2.456 6.2A5.739 5.739 0 002.146 12.9C.552 14.492.028 17.54.007 17.669a.5.5 0 00.18.471.494.494 0 00.5.074L4 16.887C4.071 22.128 8.166 24 12 24s7.929-1.872 8-7.113l3.315 1.327a.493.493 0 00.5-.074.5.5 0 00.178-.471zm-7.746 2.437A6.464 6.464 0 0112 21.5a6.464 6.464 0 01-4.247-1.394.5.5 0 01-.175-.383 10.919 10.919 0 01.93-5.09.5.5 0 01.745-.234 4.931 4.931 0 005.494 0 .5.5 0 01.745.234 10.919 10.919 0 01.93 5.09.5.5 0 01-.175.383zM9 6.752A1.25 1.25 0 117.75 8 1.25 1.25 0 019 6.752zm3 2.489a1.5 1.5 0 011.5 1.5c0 .829-1 2.5-1.5 2.5s-1.5-1.671-1.5-2.5a1.5 1.5 0 011.5-1.5zm3-2.489A1.25 1.25 0 1113.75 8 1.25 1.25 0 0115 6.752z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});