define("ember-svg-jar/inlined/real-estate-house-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-house-person</title><path d=\"M17.033 10.478a.042.042 0 00.011-.035 3.036 3.036 0 01-.044-.45 3.383 3.383 0 01.251-1.267.5.5 0 00-.1-.53c-1.64-1.74-6.314-6.7-7.42-7.886a1.033 1.033 0 00-1.46 0c-.7.746-8 8.5-8 8.5A1 1 0 001 10.493h1.5a.5.5 0 01.5.5v4a1 1 0 001 1h3a.5.5 0 00.5-.5v-3.5a1.5 1.5 0 013 0v3.5a.5.5 0 00.5.5h3a1 1 0 001-1v-4a.5.5 0 01.5-.5H17a.045.045 0 00.033-.015zM20.5 13.493a3.5 3.5 0 00-3.5 3.5v2a.5.5 0 00.5.5h.605a.5.5 0 01.5.445l.4 3.611a.5.5 0 00.5.444h2a.5.5 0 00.5-.444l.4-3.611a.5.5 0 01.5-.445h.595a.5.5 0 00.5-.5v-2a3.5 3.5 0 00-3.5-3.5z\"/><circle cx=\"20.5\" cy=\"9.993\" r=\"2.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});