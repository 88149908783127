define("ember-svg-jar/inlined/medical-specialty-hearing-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-specialty-hearing-1</title><path d=\"M9.239 14.986a1.343 1.343 0 00.2.015 1.485 1.485 0 001.283-.794l1.216-2.186a.248.248 0 01.218-.129h.986a1 1 0 100-2h-1.473a1.469 1.469 0 00-1.279.8l-.568 1.02a.25.25 0 01-.454-.04l-1.243-3.58a1.534 1.534 0 00-1.368-1.086 1.519 1.519 0 00-1.429 1.025l-.949 2.459a.25.25 0 01-.441.049 1.3 1.3 0 00-1.16-.647H1a1 1 0 000 2h1.21a.249.249 0 01.236.168l.308.885a1.54 1.54 0 001.359 1.084h.018a1.561 1.561 0 001.42-1.025l.893-2.314a.25.25 0 01.47.008l1.118 3.217a1.543 1.543 0 001.207 1.071z\"/><path d=\"M16.582.036a8.162 8.162 0 00-7.9 4.245 1 1 0 001.755.959 6.148 6.148 0 015.957-3.213 6.371 6.371 0 012.445 11.828A5.473 5.473 0 0016.1 18.38a3.8 3.8 0 01-1.57 2.932 3.61 3.61 0 01-3.149.543 3.784 3.784 0 01-2.643-3.229 1 1 0 10-1.99.2 5.745 5.745 0 004.09 4.955A5.81 5.81 0 0012.4 24a5.607 5.607 0 003.3-1.066 5.794 5.794 0 002.4-4.464 3.437 3.437 0 011.742-2.881A8.37 8.37 0 0016.582.036z\"/><path d=\"M18.338 10.316a1.059 1.059 0 00.136.009 1 1 0 00.99-.865 4.766 4.766 0 00-3.78-5.433 4.834 4.834 0 00-3.538.792 1 1 0 101.175 1.619 2.841 2.841 0 012.085-.431 2.818 2.818 0 012.076 3.183 1 1 0 00.856 1.126z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});