define("ember-svg-jar/inlined/social-video-viddler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-video-viddler</title><path d=\"M18.16 2.21a6.21 6.21 0 00-5.55 3.44 5.57 5.57 0 00-5.18-3.44A5.79 5.79 0 007 13.77v.85l-6.39-1.5a.5.5 0 00-.42.09.5.5 0 00-.19.39v7a.5.5 0 00.63.48L7 19.47v1.82a.5.5 0 00.5.5h9a.5.5 0 00.47-.66l-2.42-7.34h3.6a5.79 5.79 0 100-11.59zM7.43 9.74A1.75 1.75 0 119.19 8a1.75 1.75 0 01-1.76 1.74zm10.84 0A1.75 1.75 0 1120 8a1.75 1.75 0 01-1.73 1.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});