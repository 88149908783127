define("ember-svg-jar/inlined/monetization-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>monetization-phone</title><path d=\"M23 6.75H8a1 1 0 00-1 1v8.5a1 1 0 001 1h15a1 1 0 001-1v-8.5a1 1 0 00-1-1zm-8.139 3.964a7.727 7.727 0 001.058.8c.941.632 1.831 1.231 1.831 2.121a1.454 1.454 0 01-1.292 1.465.25.25 0 00-.208.247v.164a.75.75 0 11-1.5 0v-.125a.25.25 0 00-.25-.25H14a.75.75 0 010-1.5h2.105a.05.05 0 00.047-.031.049.049 0 00-.011-.055 8.546 8.546 0 00-1.06-.791c-.941-.634-1.831-1.234-1.831-2.123a1.411 1.411 0 011.286-1.466.25.25 0 00.214-.247V8.76a.75.75 0 111.5 0v.125a.25.25 0 00.25.25h.5a.75.75 0 010 1.5h-2.1a.047.047 0 00-.034.079z\"/><path d=\"M0 21a2.752 2.752 0 002.75 2.75h8A2.752 2.752 0 0013.5 21v-1.5a.75.75 0 00-.75-.75h-11a.249.249 0 01-.25-.25v-13a.25.25 0 01.25-.25h11a.75.75 0 00.75-.75V3A2.752 2.752 0 0010.75.25h-8A2.752 2.752 0 000 3zm6.75-1a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});