define("ember-svg-jar/inlined/smartphone-payment-touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smartphone-payment-touch</title><path d=\"M16.556 14.667a.75.75 0 000 1.5H17a.249.249 0 01.25.25v.083a.75.75 0 001.5 0v-.161a.187.187 0 01.169-.186 1.346 1.346 0 001.275-1.459c0-.871-.862-1.452-1.775-2.066-.144-.1-.325-.219-.5-.339a.25.25 0 01.142-.456h1.381a.75.75 0 000-1.5H19a.249.249 0 01-.25-.25V10a.75.75 0 00-1.5 0v.157a.187.187 0 01-.172.187 1.318 1.318 0 00-1.272 1.462c0 .871.862 1.452 1.775 2.066.143.1.323.217.5.342a.25.25 0 01-.146.453z\"/><path d=\"M21 5.25h-6A2.752 2.752 0 0012.25 8v13A2.752 2.752 0 0015 23.75h6A2.752 2.752 0 0023.75 21V8A2.752 2.752 0 0021 5.25zM17 21.5a1 1 0 111 1 1 1 0 01-1-1zM15 6.75h6A1.25 1.25 0 0122.25 8v10.25a1 1 0 01-1 1h-6.5a1 1 0 01-1-1V8A1.25 1.25 0 0115 6.75zM5.5.25a.75.75 0 000 1.5A3.755 3.755 0 019.25 5.5V11a.75.75 0 001.5 0V5.5A5.256 5.256 0 005.5.25zM7.584 14.118A3.75 3.75 0 011.75 11V5.5A3.757 3.757 0 013 2.705a.75.75 0 10-1-1.117A5.259 5.259 0 00.25 5.5V11a5.251 5.251 0 008.168 4.365.75.75 0 00-.834-1.247z\"/><path d=\"M3.5 10.25a.75.75 0 00-.75.75 2.75 2.75 0 005.5 0V5.5a2.75 2.75 0 00-5.5 0 .75.75 0 001.5 0 1.25 1.25 0 012.5 0V11a1.25 1.25 0 01-2.5 0 .75.75 0 00-.75-.75z\"/><path d=\"M5.75 9V7.5a.75.75 0 00-1.5 0V9a.75.75 0 001.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});