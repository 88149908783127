define("ember-svg-jar/inlined/cell-border-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cell-border-full</title><path d=\"M22 0H2a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2zm0 3v8h-8.75a.25.25 0 01-.25-.25V2h8a1 1 0 011 1zM3 2h8v8.75a.25.25 0 01-.25.25H2V3a1 1 0 011-1zM2 21v-8h8.75a.25.25 0 01.25.25V22H3a1 1 0 01-1-1zm19 1h-8v-8.75a.25.25 0 01.25-.25H22v8a1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});