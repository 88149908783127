define("ember-svg-jar/inlined/shipment-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shipment-packages</title><path d=\"M10.25 12.25H8.5a.25.25 0 00-.25.25v3.71a.5.5 0 01-.812.39l-1.532-1.225a.25.25 0 00-.312 0L4.062 16.6a.5.5 0 01-.812-.39V12.5a.25.25 0 00-.25-.25H1.25a1 1 0 00-1 1v8a1 1 0 001 1h9a1 1 0 001-1v-8a1 1 0 00-1-1zM22.75 12.25H21a.25.25 0 00-.25.25v3.71a.5.5 0 01-.812.39l-1.532-1.225a.25.25 0 00-.312 0L16.562 16.6a.5.5 0 01-.812-.39V12.5a.25.25 0 00-.25-.25h-1.75a1 1 0 00-1 1v8a1 1 0 001 1h9a1 1 0 001-1v-8a1 1 0 00-1-1zM7.25 10.75h9a1 1 0 001-1v-7a1 1 0 00-1-1H14.5a.25.25 0 00-.25.25v3.71a.5.5 0 01-.812.39l-1.532-1.225a.25.25 0 00-.312 0L10.062 6.1a.5.5 0 01-.812-.39V2A.25.25 0 009 1.75H7.25a1 1 0 00-1 1v7a1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});