define("ember-svg-jar/inlined/real-estate-favorite-hold-house", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-favorite-hold-house</title><path d=\"M17 13V8a.5.5 0 01.5-.5h2a.5.5 0 00.332-.874L12.664.252a1 1 0 00-1.328 0L4.168 6.626A.5.5 0 004.5 7.5h2A.5.5 0 017 8v5a1 1 0 001 1h2a.5.5 0 00.5-.5V11a1.5 1.5 0 013 0v2.5a.5.5 0 00.5.5h2a1 1 0 001-1zM4.124 15.836a1.435 1.435 0 00-.458 1.823L5 19.876a.5.5 0 11-.857.516l-1.352-2.25a2.461 2.461 0 01.126-2.474.5.5 0 00.083-.275V12.5a1.5 1.5 0 00-3 0v5.171a4.5 4.5 0 00.986 2.811l2.664 3.33a.5.5 0 00.39.188H8a.5.5 0 00.5-.5v-3.01a4.5 4.5 0 00-.757-2.5c-1.888-1.965-2.477-2.916-3.619-2.154zM22.5 11a1.5 1.5 0 00-1.5 1.5v2.891a.5.5 0 00.083.276 2.437 2.437 0 01.145 2.442l-1.371 2.283a.5.5 0 11-.857-.516l1.352-2.25a1.4 1.4 0 00-.476-1.79c-1.142-.762-1.731.189-3.619 2.156a4.5 4.5 0 00-.757 2.5V23.5a.5.5 0 00.5.5h3.96a.5.5 0 00.39-.188l2.664-3.33A4.5 4.5 0 0024 17.671V12.5a1.5 1.5 0 00-1.5-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});