define("ember-svg-jar/inlined/crypto-currency-bitcoin-monitor-mining", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-monitor-mining</title><path d=\"M8.75 13.5a.75.75 0 00.75.75.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1a.75.75 0 001.5 0v-1.219a.251.251 0 01.15-.229A2.244 2.244 0 0015.25 12a2.219 2.219 0 00-.46-1.349.249.249 0 010-.3A2.219 2.219 0 0015.25 9a2.244 2.244 0 00-1.35-2.052.251.251 0 01-.15-.229v-1.2a.75.75 0 00-1.5 0V6.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.979a.75.75 0 00-1.5 0V6.5a.25.25 0 01-.25.25.75.75 0 00-.75.75zM13 8.25a.75.75 0 010 1.5h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25zm0 4.5h-2.5a.25.25 0 01-.25-.25v-1a.25.25 0 01.25-.25H13a.75.75 0 010 1.5zM15 1.5H9a1 1 0 000 2h6a1 1 0 000-2zM15 18.5H9a1 1 0 000 2h6a1 1 0 000-2zM20.5 16a1 1 0 001-1V9a1 1 0 00-2 0v6a1 1 0 001 1zM4.5 15V9a1 1 0 00-2 0v6a1 1 0 002 0zM6 17H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 4.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zM6 0H1a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V1a1 1 0 00-1-1zM5 4.75a.25.25 0 01-.25.25h-2.5A.25.25 0 012 4.75v-2.5A.25.25 0 012.25 2h2.5a.25.25 0 01.25.25zM23 0h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1V1a1 1 0 00-1-1zm-1 4.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25zM23 17h-5a1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 001-1v-5a1 1 0 00-1-1zm-1 4.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25v-2.5a.25.25 0 01.25-.25h2.5a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});