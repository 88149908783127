define("ember-svg-jar/inlined/smart-watch-square-sound-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-square-sound-alternate</title><path d=\"M21 6a3 3 0 00-3-3h-1.078a.249.249 0 01-.24-.182L15.98.365A.5.5 0 0015.5 0h-7a.5.5 0 00-.481.362l-.7 2.456A.248.248 0 017.077 3H6a3 3 0 00-3 3v12a3 3 0 003 3h1.077a.249.249 0 01.24.181l.7 2.456A.5.5 0 008.5 24h7a.5.5 0 00.48-.363l.7-2.456a.25.25 0 01.24-.181H18a3 3 0 003-3zm-2 12a1 1 0 01-1 1H6a1 1 0 01-1-1V6a1 1 0 011-1h12a1 1 0 011 1z\"/><path d=\"M15.313 8.169a.751.751 0 00-.113 1.055 4.391 4.391 0 010 5.557.751.751 0 001.168.943 5.885 5.885 0 000-7.443.75.75 0 00-1.055-.112z\"/><path d=\"M14.65 9.782a.75.75 0 00-1.168.941 2.026 2.026 0 010 2.559.75.75 0 001.168.941 3.511 3.511 0 000-4.441zM12.066 8.5h-.5a3.5 3.5 0 00-3.083 1.866.25.25 0 01-.221.134h-1.2a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h1.2a.249.249 0 01.221.133 3.5 3.5 0 003.083 1.867h.5a.5.5 0 00.5-.5V9a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});