define("ember-svg-jar/inlined/fishing-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-line</title><path d=\"M21.035 16.667a.249.249 0 01-.079-.342c1.417-2.3 2.809-6.019.544-7.491-1.272-.825-3.409-.38-5.031 2.072-1.762 2.662-3.572 3.251-4.292 2.819s-1.1-2.49.9-6.209c1.494-2.776 1.655-5.154.442-6.523a3.547 3.547 0 00-4.1-.638C4.9 2.124 2.4 8.41 1.918 14.447a.9.9 0 00.9.97.49.49 0 01.49.49.51.51 0 01-.51.51h-.74a.715.715 0 00-.75.673v5.063C1.3 23.5 1.974 24 2.8 24s1.5-.5 1.5-1.847v-4.182a3 3 0 102-5.221 2.946 2.946 0 00-1.838.643.25.25 0 01-.4-.229c.932-7.007 4.19-10.2 6.089-10.946.83-.324 1.529-.286 1.873.1.454.512.54 1.932-.707 4.25-2.692 5-1.852 7.864-.167 8.872 1.626.972 4.506.315 6.987-3.431.886-1.34 1.909-1.734 2.274-1.5.416.27.667 1.9-1.3 4.994a2.1 2.1 0 00.939 2.884 1.029 1.029 0 001.342-.511.967.967 0 00-.357-1.209zM6.3 14.75a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});