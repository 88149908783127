define("ember-svg-jar/inlined/smart-house-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-house-window</title><path d=\"M9.919 9.674A2.447 2.447 0 019.4 8.9a.25.25 0 00-.23-.152H8.5A.25.25 0 008.25 9v6.25a.25.25 0 00.25.25h6.25a.25.25 0 00.25-.25v-2.574a.249.249 0 00-.19-.242 10.419 10.419 0 01-4.891-2.76zM6.5 15.5a.25.25 0 00.25-.25V9a.25.25 0 00-.25-.25h-5a1.5 1.5 0 00-1.5 1.5v5a.25.25 0 00.25.25zM8.5 17a.25.25 0 00-.25.25v6.25a.25.25 0 00.25.25h5a1.5 1.5 0 001.5-1.5v-5a.25.25 0 00-.25-.25zM6.75 17.25A.25.25 0 006.5 17H.25a.25.25 0 00-.25.25v5a1.5 1.5 0 001.5 1.5h5a.25.25 0 00.25-.25zM17.343 2.834a2.992 2.992 0 002.122-.877A1 1 0 1018.051.543a1 1 0 01-1.415 0 1 1 0 10-1.414 1.414 2.99 2.99 0 002.121.877zM17.343 6.835a5.965 5.965 0 004.243-1.757 1 1 0 00-1.414-1.414 4 4 0 01-5.657 0A1 1 0 1013.1 5.078a5.957 5.957 0 004.243 1.757z\"/><path d=\"M17.343 10.831A8.976 8.976 0 0023.707 8.2a1 1 0 10-1.414-1.414 7.008 7.008 0 01-9.9 0A1 1 0 1010.979 8.2a8.976 8.976 0 006.364 2.631z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});