define("ember-svg-jar/inlined/chef-gear-dumplings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>chef-gear-dumplings</title><path d=\"M12 0a12 12 0 1012 12A12 12 0 0012 0zM9.952 5.783l2.828-3.089a.6.6 0 01.846-.037A2.692 2.692 0 019.99 6.629a.6.6 0 01-.038-.846zm-2.746 11.2a2.692 2.692 0 01-3.7-.9.6.6 0 01.2-.822l3.579-2.176a.6.6 0 01.822.2 2.692 2.692 0 01-.901 3.702zm1.115-6.76a2.692 2.692 0 01-3.776-3.835.6.6 0 01.846.007l2.937 2.986a.6.6 0 01-.007.846zM12 9.5A2.5 2.5 0 119.5 12 2.5 2.5 0 0112 9.5zm2.919 10.163a2.693 2.693 0 01-4.653-2.712.6.6 0 01.818-.215l3.616 2.109a.6.6 0 01.219.818zm3.868-2.676a2.693 2.693 0 01-3.7-.9.6.6 0 01.2-.822l3.578-2.176a.6.6 0 01.822.2 2.692 2.692 0 01-.9 3.698zm-.565-6.406a2.692 2.692 0 01-3.075-2.246.6.6 0 01.5-.683l4.138-.645a.6.6 0 01.684.5 2.693 2.693 0 01-2.247 3.074z\"/><circle cx=\"12\" cy=\"12\" r=\"1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});