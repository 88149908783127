define("ember-svg-jar/inlined/single-woman-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-home</title><path d=\"M22.875 7.747L12.832.76a1.464 1.464 0 00-1.664 0L1.125 7.747A1.455 1.455 0 00.5 8.943v13.1A1.458 1.458 0 001.957 23.5h20.086a1.458 1.458 0 001.457-1.458V8.943a1.455 1.455 0 00-.625-1.196zm-6.316 11.717a.5.5 0 01-.451.285h-8a.5.5 0 01-.388-.815 5.689 5.689 0 014.39-2.185 5.68 5.68 0 014.39 2.185.5.5 0 01.059.53zm.055-4.57a.633.633 0 01-1.064.686 4.087 4.087 0 01-.517-1.206.25.25 0 00-.416-.116 3.593 3.593 0 01-5.018 0 .25.25 0 00-.416.117 4.131 4.131 0 01-.517 1.207.633.633 0 01-1.066-.688 3.51 3.51 0 00.5-1.934v-1.7a4.013 4.013 0 118.025 0v1.7a3.518 3.518 0 00.489 1.934z\"/><path d=\"M15.306 11.651a.5.5 0 00-.4-.585A4.349 4.349 0 0112.5 9.684a.383.383 0 00-.326-.184.506.506 0 00-.068-.009.424.424 0 00-.393.193 4.347 4.347 0 01-2.408 1.382.5.5 0 00-.4.585.5.5 0 00.585.4 5.3 5.3 0 002.449-1.21.248.248 0 01.327 0 5.3 5.3 0 002.449 1.21.505.505 0 00.591-.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});