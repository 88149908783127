define("ember-svg-jar/inlined/history-armor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>history-armor</title><path d=\"M23.707 22.293l-2.877-2.877a.5.5 0 01-.083-.6A9.928 9.928 0 0019.118 7a.5.5 0 010-.7l1.147-1.148A.5.5 0 0120.621 5h.7a1.492 1.492 0 001.393-.944L23.946.98A.96.96 0 0024 .71a.71.71 0 00-.71-.71 1 1 0 00-.26.049l-3.087 1.235A1.492 1.492 0 0019 2.677v.7a.5.5 0 01-.147.353l-1.437 1.44a.5.5 0 01-.6.083 9.9 9.9 0 00-9.64 0 .5.5 0 01-.6-.083l-1.43-1.438A.5.5 0 015 3.379v-.7a1.489 1.489 0 00-.943-1.392L.974.051A1 1 0 00.709 0 .71.71 0 000 .71a.973.973 0 00.051.262l1.233 3.084A1.492 1.492 0 002.677 5h.7a.5.5 0 01.353.146l1.15 1.148a.5.5 0 010 .7A9.928 9.928 0 003.253 18.82a.5.5 0 01-.083.6L.293 22.293a1 1 0 001.414 1.414l2.587-2.587a.5.5 0 01.7 0 9.944 9.944 0 0014 0 .5.5 0 01.7 0l2.587 2.587a1 1 0 001.414-1.414zM12 22a8 8 0 118-8 8.005 8.005 0 01-8 8z\"/><path d=\"M12 7.5a6.5 6.5 0 106.5 6.5A6.5 6.5 0 0012 7.5zm3.359 9.154a.75.75 0 01-1.017-.3l-1.9-3.488a.5.5 0 00-.878 0l-1.9 3.488a.75.75 0 11-1.316-.719l3-5.5a.781.781 0 011.316 0l3 5.5a.749.749 0 01-.305 1.023z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});