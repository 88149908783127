define("ember-svg-jar/inlined/folder-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-open</title><path d=\"M23.44 9.549A1.492 1.492 0 0022.28 9H10.42a.5.5 0 01-.491-.4l-.278-1.395A1.5 1.5 0 008.18 6H1.72A1.5 1.5 0 00.249 7.8l2.6 13A1.5 1.5 0 004.32 22h15.961a1.5 1.5 0 001.47-1.2l2-10a1.5 1.5 0 00-.311-1.251zM3.22 5a1 1 0 00.98-.778A.263.263 0 014.443 4H19.72a.5.5 0 01.5.5V7a1 1 0 002 0V4a2 2 0 00-2-2h-16a2 2 0 00-2 2 1 1 0 001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});