define("ember-svg-jar/inlined/casino-chip-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>casino-chip-5</title><path d=\"M13.75 13.25a2 2 0 01-2 2h-.878a.75.75 0 010-1.5h.878a.5.5 0 000-1H11a.75.75 0 01-.75-.75V9.5a.75.75 0 01.75-.75h2a.75.75 0 010 1.5h-1a.25.25 0 00-.25.25v.514a.25.25 0 00.222.248 2 2 0 011.778 1.988z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4.827 3.571a.251.251 0 01.361-.117 10.1 10.1 0 013.358 3.358.247.247 0 01.024.207.25.25 0 01-.141.154L17.62 8.355a.248.248 0 01-.3-.087 6.572 6.572 0 00-1.587-1.587.249.249 0 01-.087-.3zM12 2.5a1 1 0 11-1 1 1 1 0 011-1zm-5.188.954a.251.251 0 01.361.117L8.355 6.38a.248.248 0 01-.087.3 6.558 6.558 0 00-1.587 1.589.249.249 0 01-.3.086l-2.81-1.182a.251.251 0 01-.117-.361 10.1 10.1 0 013.358-3.358zM2.5 12a1 1 0 111 1 1 1 0 01-1-1zm4.673 8.429a.251.251 0 01-.361.117 10.1 10.1 0 01-3.358-3.358.247.247 0 01-.024-.207.25.25 0 01.141-.154l2.809-1.182a.248.248 0 01.3.087 6.572 6.572 0 001.587 1.587.249.249 0 01.087.3zM12 21.5a1 1 0 111-1 1 1 0 01-1 1zm0-5a4.5 4.5 0 114.5-4.5 4.505 4.505 0 01-4.5 4.5zm5.188 4.046a.251.251 0 01-.361-.117l-1.182-2.809a.248.248 0 01.087-.3 6.558 6.558 0 001.587-1.588.249.249 0 01.3-.086l2.809 1.182a.251.251 0 01.117.361 10.1 10.1 0 01-3.357 3.357zM20.5 13a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});