define("ember-svg-jar/inlined/social-media-friends-feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>social-media-friends-feed</title><path d=\"M19.5 5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-4A4.5 4.5 0 0014 5.5v2a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-1A1.5 1.5 0 0110.5 5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-4A4.5 4.5 0 005 5.5v2a.5.5 0 01-.5.5h-3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 01.5.5v10a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-10a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v10a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-10a.5.5 0 01.5-.5h4a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-4a.5.5 0 01-.5-.5v-1A1.5 1.5 0 0119.5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});