define("ember-svg-jar/inlined/electronics-resistor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-resistor_1</title><path d=\"M10.052 8.235a.5.5 0 00.707 0L13.2 5.793a1.506 1.506 0 00.372-1.511.216.216 0 01.054-.22L15.9 1.785A1 1 0 1014.488.371l-2.265 2.265a.249.249 0 01-.249.062 1.5 1.5 0 00-1.494.374L8.038 5.514a.5.5 0 000 .707zM1.8 15.886L4.386 13.3a.25.25 0 01.247-.064 1.477 1.477 0 001.5-.374l2.441-2.441a.5.5 0 000-.707L6.557 7.7a.5.5 0 00-.707 0l-2.441 2.444a1.5 1.5 0 00-.374 1.494.249.249 0 01-.062.249L.387 14.472A1 1 0 001.8 15.886zM10.157 16.893l.741-.741a.5.5 0 000-.707l-2.014-2.014a.5.5 0 00-.707 0l-.74.74a1.5 1.5 0 00-.374 1.5.249.249 0 01-.063.244l-6.3 6.3a1 1 0 101.413 1.415l6.3-6.3a.25.25 0 01.249-.063 1.5 1.5 0 001.495-.374zM11.959 14.384a.5.5 0 00.707 0l2.121-2.121a.5.5 0 000-.707l-2.014-2.014a.5.5 0 00-.707 0l-2.121 2.121a.5.5 0 000 .707zM15.848 10.5a.5.5 0 00.707 0l.674-.674a1.5 1.5 0 00.371-1.498.25.25 0 01.063-.249l5.95-5.949A1 1 0 0022.2.716l-5.949 5.948a.252.252 0 01-.251.063 1.493 1.493 0 00-1.494.374l-.674.673a.5.5 0 000 .707zM14.613 15.758a.5.5 0 00-.707 0L11.464 18.2a1.5 1.5 0 00-.373 1.494.252.252 0 01-.063.249L8.766 22.2a1 1 0 101.414 1.414l2.262-2.261a.248.248 0 01.249-.063 1.5 1.5 0 001.494-.374l2.442-2.442a.5.5 0 000-.707zM23.6 8.783a1 1 0 00-1.414 0l-1.909 1.909a.252.252 0 01-.249.063 1.5 1.5 0 00-1.494.373l-2.44 2.442a.5.5 0 000 .707l2.014 2.013a.5.5 0 00.707 0l2.441-2.441a1.5 1.5 0 00.374-1.494.249.249 0 01.062-.249L23.6 10.2a1 1 0 000-1.417z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});