define("ember-svg-jar/inlined/business-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>business-scale</title><path d=\"M12 24a1.5 1.5 0 001.5-1.5v-2.25a.25.25 0 01.25-.25h2.4a1.5 1.5 0 001.479-1.253l1.864-11.226a.3.3 0 000-.042A7.5 7.5 0 104.5 7.562a.3.3 0 000 .041l1.864 11.145A1.5 1.5 0 007.843 20h2.4a.25.25 0 01.25.25v2.25A1.5 1.5 0 0012 24zm0-7a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0112 17zM7.072 7.422A.251.251 0 017 7.237a5 5 0 019.987 0 .251.251 0 01-.25.263h-2.4a.25.25 0 01-.208-.389l.711-1.065a1 1 0 10-1.662-1.111L11.537 7.39a.252.252 0 01-.208.11H7.253a.245.245 0 01-.181-.078zM8.5 11.75a.75.75 0 010-1.5h7a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});