define("ember-svg-jar/inlined/style-two-pin-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-two-pin-restaurant</title><path d=\"M12 0a9.511 9.511 0 00-9.5 9.5c0 6.544 6.537 12.487 8.541 14.153a1.5 1.5 0 001.917 0c2-1.666 8.542-7.609 8.542-14.154A9.511 9.511 0 0012 0zm.5 8.75a3.248 3.248 0 01-2.167 3.056.5.5 0 00-.333.471v3.473a.75.75 0 01-1.5 0v-3.473a.5.5 0 00-.333-.471A3.248 3.248 0 016 8.75v-3a.75.75 0 011.5 0V9a.5.5 0 001 0V5.75a.75.75 0 011.5 0V9a.5.5 0 001 0V5.75a.75.75 0 011.5 0zm4.75 5h-.75a.5.5 0 00-.5.5v1.5a.75.75 0 01-1.5 0v-10a.749.749 0 011.084-.671C17.813 6.188 18 9.749 18 13a.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});