define("ember-svg-jar/inlined/building-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-flag</title><path d=\"M16.5 7.5h-9A.5.5 0 007 8v15.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V8a.5.5 0 00-.5-.5zm-5.5 14a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm4 10a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5zm0-5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z\"/><rect x=\"1\" y=\"11.5\" width=\"4.5\" height=\"12.5\" rx=\".5\" ry=\".5\"/><rect x=\"18.5\" y=\"11.5\" width=\"4.5\" height=\"12.5\" rx=\".5\" ry=\".5\"/><path d=\"M20 5a1 1 0 00-1-1h-6.25a.25.25 0 01-.25-.25v-.5a.25.25 0 01.25-.25H14a1 1 0 001-1V1a1 1 0 00-1-1h-2a1 1 0 00-1 1v2.75a.25.25 0 01-.25.25H5.5a1 1 0 000 2H19a1 1 0 001-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});