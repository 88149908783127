define("ember-svg-jar/inlined/style-three-pin-plane-fly", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-plane-fly</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm8 11.361a.789.789 0 01-1.067.739l-1.7-.638a.25.25 0 00-.334.193l-.266 1.592a1.493 1.493 0 01-1.48 1.253.152.152 0 00-.153.153v.6a.75.75 0 01-1.5 0v-.5a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25v.5a.75.75 0 01-1.5 0v-.6a.152.152 0 00-.153-.153 1.493 1.493 0 01-1.479-1.253L7.1 11.655a.25.25 0 00-.334-.193l-1.7.638a.79.79 0 01-.795-1.336l6.85-5.937a1.342 1.342 0 011.756 0l6.85 5.937a.79.79 0 01.273.597z\"/><path d=\"M8.2 10.925a.252.252 0 00-.159.276l.314 1.882a.5.5 0 00.493.417.152.152 0 00.152-.153v-.1a.75.75 0 011.5 0 .25.25 0 00.25.25h2.5a.25.25 0 00.25-.25.75.75 0 011.5 0v.1a.152.152 0 00.153.153.5.5 0 00.493-.417l.314-1.883a.252.252 0 00-.159-.276l-3.713-1.391a.254.254 0 00-.176 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});