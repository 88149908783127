define("ember-svg-jar/inlined/calendar-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calendar-plane</title><path d=\"M21.5 3h-2.75a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v4.75a.75.75 0 11-1.5 0V3.5a.5.5 0 00-.5-.5H8.25A.25.25 0 018 2.751V1a1 1 0 10-2 0v4.75a.75.75 0 11-1.5 0V3.5A.5.5 0 004 3H2.5a2 2 0 00-2 2v17a2 2 0 002 2h19a2 2 0 002-2V5a2 2 0 00-2-2zm0 18.5a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5v-12A.5.5 0 013 9h18a.5.5 0 01.5.5z\"/><path d=\"M15.4 12.379l-2.01 1.372-4.563-1.7a.814.814 0 00-.772.108s-.963.73-.986.758a.831.831 0 00.153 1.2l2.862 1.77-1.294.959-1.254-.738a.761.761 0 00-.733.132s-.934.791-.96.823a.764.764 0 00.142 1.081l2.755 1.934a.96.96 0 001.06.032l7.529-4.675a1.809 1.809 0 10-1.929-3.056z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});