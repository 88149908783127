define("ember-svg-jar/inlined/hotel-single-bed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hotel-single-bed</title><path d=\"M21 8.5a.5.5 0 00.5-.5V1.5A1.5 1.5 0 0020 0H4a1.5 1.5 0 00-1.5 1.5V8a.5.5 0 00.5.5zm-12.5-5a1 1 0 011-1h5a1 1 0 011 1v2a1 1 0 01-1 1h-5a1 1 0 01-1-1zM3 9.5a.5.5 0 00-.5.5v12.5A1.5 1.5 0 004 24h16a1.5 1.5 0 001.5-1.5V10a.5.5 0 00-.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});