define("ember-svg-jar/inlined/arrow-button-up-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-up-3</title><path d=\"M23.7 13.178l-11-10.939a1 1 0 00-1.409 0L.3 13.186a1.01 1.01 0 00-.3.714v7.15a1 1 0 001.708.706l10.114-10.129a.249.249 0 01.354 0L22.291 21.75A1 1 0 0024 21.042v-7.155a1.012 1.012 0 00-.3-.709z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});