define("ember-svg-jar/inlined/crime-woman-inmate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crime-woman-inmate</title><path d=\"M23.16 19.044a16.191 16.191 0 00-7.284-2.668.073.073 0 00-.081.057 4.174 4.174 0 01-8.117.24.249.249 0 00-.285-.181A15.679 15.679 0 00.84 19.044a2.034 2.034 0 00-.651.795.25.25 0 00.228.354h23.166a.25.25 0 00.228-.354 2.034 2.034 0 00-.651-.795z\"/><rect y=\"22.193\" width=\"24\" height=\"1.792\" rx=\".25\" ry=\".25\"/><path d=\"M10.969 8.515a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM12.969 11.954a.75.75 0 00-.75-.75h-1a.75.75 0 000 1.5h1a.75.75 0 00.75-.75zM14.219 7.765h-1a.75.75 0 000 1.5h1a.75.75 0 000-1.5z\"/><path d=\"M7.6 14.763a.249.249 0 01.189.039 6.959 6.959 0 007.863-.008.253.253 0 01.191-.039c.508.1 3.675 1.069 5.2-.759a.5.5 0 00-.231-.8c-2.559-.822-2.1-4.094-2.1-4.184V3.646a3.007 3.007 0 00-.129-.877A2.954 2.954 0 0016.9.887a13.526 13.526 0 00-5.181-.872 13.53 13.53 0 00-5.182.872 2.977 2.977 0 00-1.818 2.759v5.369c0 .089.429 3.368-2.113 4.184a.5.5 0 00-.231.8c1.525 1.826 4.739.859 5.225.764zM6.719 3.646a.984.984 0 01.591-.915 11.468 11.468 0 014.409-.716 12.353 12.353 0 014.409.716.966.966 0 01.549.623 9.641 9.641 0 01.042 2.1.251.251 0 01-.332.237 14.277 14.277 0 00-4.668-.676 14.277 14.277 0 00-4.668.676.251.251 0 01-.332-.237zm5 10.369a5.006 5.006 0 01-5-5v-.841a.253.253 0 01.131-.221 10.293 10.293 0 014.869-.938 10.309 10.309 0 014.868.936.25.25 0 01.132.22v.844a5.006 5.006 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});