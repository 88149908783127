define("ember-svg-jar/inlined/equipment-pressure-measure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>equipment-pressure-measure</title><path d=\"M21 2.5A2.5 2.5 0 0018.5 0h-13A2.5 2.5 0 003 2.5v19A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5zm-14.5 15a2.992 2.992 0 012.087-2.846.125.125 0 01.163.119v5.277a.25.25 0 01-.341.233A2.988 2.988 0 016.5 17.5zm3.83 2.872a.067.067 0 01-.055-.01.063.063 0 01-.025-.05V14.95a.25.25 0 01.341-.233 2.984 2.984 0 01-.261 5.655zM17 21a1 1 0 111-1 1 1 0 01-1 1zm0-4a1 1 0 111-1 1 1 0 01-1 1zm1.5-6a.5.5 0 01-.5.5h-2a.251.251 0 01-.236-.169 3.981 3.981 0 00-3.434-2.677.254.254 0 01-.2-.138L9.9 4.053a1 1 0 10-1.79.894l1.963 3.925a.249.249 0 01-.1.327 3.993 3.993 0 00-1.737 2.132A.251.251 0 018 11.5H6a.5.5 0 01-.5-.5V3a.5.5 0 01.5-.5h12a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});