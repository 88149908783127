define("ember-svg-jar/inlined/construction-house-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>construction-house-1</title><path d=\"M23.5 17.125h-2a.25.25 0 01-.25-.25v-5.75a.25.25 0 01.25-.25h1.25a1 1 0 000-2h-2.092a.25.25 0 01-.206-.108l-1.032-1.5a.251.251 0 01.206-.392h2.124a1 1 0 000-2h-3.842a.25.25 0 01-.206-.108l-1.032-1.5a.251.251 0 01.206-.392h2.874a1 1 0 000-2H7.25a.984.984 0 00-.931.682C6.3 1.627.454 8.808.414 8.837A1.045 1.045 0 000 9.625v2.5a1 1 0 002 0v-1.5a.25.25 0 01.25-.25h11a.25.25 0 01.25.25v5.75a.25.25 0 00.25.25 1.5 1.5 0 011.5 1.5v1a1.5 1.5 0 01-1.286 1.485.249.249 0 00-.214.247v1.768a.5.5 0 00.5.5h9.25a.5.5 0 00.5-.5v-5a.5.5 0 00-.5-.5zM10.782 4.783l-1.219-1.5a.25.25 0 01.194-.408h4.085a.25.25 0 01.206.108l1.032 1.5a.251.251 0 01-.206.392h-3.9a.249.249 0 01-.192-.092zm3.444 4.092a.249.249 0 01-.194-.092l-1.219-1.5a.25.25 0 01.194-.408h3.585a.25.25 0 01.206.108l1.032 1.5a.251.251 0 01-.206.392zm5.024 8a.25.25 0 01-.25.25h-3.25a.25.25 0 01-.25-.25v-5.75a.25.25 0 01.25-.25H19a.25.25 0 01.25.25z\"/><rect x=\"2.25\" y=\"17.625\" width=\"12\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\".25\" y=\"14.125\" width=\"12\" height=\"2\" rx=\".5\" ry=\".5\"/><rect x=\".25\" y=\"21.125\" width=\"12\" height=\"2\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});