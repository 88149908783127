define("ember-svg-jar/inlined/shark-hammer-fish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shark-hammer-fish</title><path d=\"M21.5 8.89l1.6-3.328a.5.5 0 00-.377-.712 5.586 5.586 0 00-4.273 1.309 6.475 6.475 0 00-4.515-.611l-.622-.725a8.952 8.952 0 012.829-1.17 1 1 0 00.508-1.681L15.425.746a1 1 0 00-.75-.3A9 9 0 006.125 9a1 1 0 00.3.749l1.225 1.223a1 1 0 001.682-.509 8.963 8.963 0 011.5-3.318l.67.67a5.141 5.141 0 00.018 3.892 5.9 5.9 0 00-1.788 4.828.5.5 0 00.711.377l3.862-1.855c4.189 2.853 6.44.567 7.005 2.826.28 1.121-1.136 1.9-2.3 2.344a2.749 2.749 0 01-2.5-.3l-1.81-1.208a.5.5 0 00-.769.508 4.6 4.6 0 01-.815 3.413.5.5 0 00-.016.522.5.5 0 00.457.251c4.5-.2 10.069-1.736 10.069-6.455A13.968 13.968 0 0021.5 8.89zM4.044 19.834a2.812 2.812 0 00-3.095.319 2.042 2.042 0 00-.436 2.155 2.186 2.186 0 002.275 1.244 8.9 8.9 0 001.583-.144 7.787 7.787 0 013.262.125.5.5 0 00.489-.8 9.979 9.979 0 00-4.078-2.899zM5.668 12.95a2.024 2.024 0 00-1.907 1.093 1.96 1.96 0 00.267 2.173 8.818 8.818 0 001.453 1.45A7.782 7.782 0 017.539 20.2a.5.5 0 00.92-.158 9.953 9.953 0 00-.506-4.979 2.815 2.815 0 00-2.285-2.113z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});