define("ember-svg-jar/inlined/cursor-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-1</title><path d=\"M22.662 9.286L2.1.8A1 1 0 00.8 2.1l8.488 20.559a1 1 0 001.885-.108l2.491-8.718a.249.249 0 01.172-.171l8.718-2.491a1 1 0 00.108-1.885z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});