define("ember-svg-jar/inlined/professions-woman-doctor-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>professions-woman-doctor-2</title><circle cx=\"14\" cy=\"9\" r=\"1\"/><circle cx=\"10\" cy=\"9\" r=\"1\"/><path d=\"M13.077 11.131h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 3.024 3.024 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2z\"/><path d=\"M22.837 18.019a11.514 11.514 0 00-1.537-.979.249.249 0 01-.019-.428c.366-.242.571-.4.589-.415a.5.5 0 00.031-.759c-2.927-2.724-2.4-6.591-2.405-7.955a7.5 7.5 0 10-14.993.081c0 .767.7 4.984-2.412 7.874a.5.5 0 00.031.759c.017.013.223.173.589.415a.25.25 0 01-.02.429 11.489 11.489 0 00-1.531.977A2.957 2.957 0 000 20.393V23a1 1 0 002 0v-2.607a.974.974 0 01.377-.785A10.971 10.971 0 015.09 18.14a.25.25 0 00.16-.233V15.5a.5.5 0 011 0v3.719a.251.251 0 01-.15.229 2.25 2.25 0 101.8 0 .251.251 0 01-.15-.229v-1.626a.248.248 0 01.205-.245 21.308 21.308 0 013.035-.326.248.248 0 01.183.069.252.252 0 01.077.181V23a.75.75 0 001.5 0v-5.729a.249.249 0 01.26-.249 21.681 21.681 0 012.7.269.251.251 0 00.29-.247V15.5a.5.5 0 011 0v4.168a.251.251 0 01-.173.238 2.994 2.994 0 00-2.077 2.844v.5a.75.75 0 001.5 0v-.5a1.5 1.5 0 013 0v.5a.75.75 0 001.5 0v-.5a3 3 0 00-2.076-2.843.249.249 0 01-.173-.237v-1.323a.25.25 0 01.338-.234 10.98 10.98 0 012.785 1.5.973.973 0 01.376.784V23a1 1 0 002 0v-2.607a2.955 2.955 0 00-1.163-2.374zM6.25 21.5a.75.75 0 11.75.75.75.75 0 01-.75-.75zm5.75-7a5 5 0 01-5-5V7.243a.249.249 0 01.078-.181.246.246 0 01.184-.068 5 5 0 004.524-2.418.249.249 0 01.428 0 5 5 0 004.524 2.419.246.246 0 01.184.068.249.249 0 01.078.18V9.5a5 5 0 01-5 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});