define("ember-svg-jar/inlined/content-pen-write-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-write-2</title><path d=\"M8.707 19.8a1 1 0 00-1.414 0L6 21.089 4.707 19.8a1 1 0 00-1.348-.062l-3 2.5a1 1 0 00-.128 1.409 1.016 1.016 0 001.41.128l2.3-1.916 1.352 1.351a1 1 0 001.414 0L8 21.917l.793.793a1 1 0 001.414-1.41zM14.589 14.8a2.183 2.183 0 00-2.975.831c-.5.882-.328 3.718-.262 4.608a.179.179 0 00.092.145.181.181 0 00.172 0c.8-.405 3.308-1.732 3.8-2.614a2.182 2.182 0 00-.827-2.97zm-.584 2.15a.546.546 0 11-.208-.744.547.547 0 01.208.746zM23.981 1.83a.5.5 0 00-.237-.3L21.125.066a.5.5 0 00-.681.193l-.289.517a.907.907 0 00-.119-.1L19.719.5a2.455 2.455 0 00-3.345.934l-2.666 4.73a1 1 0 101.742.981l2.666-4.734a.455.455 0 01.621-.174s.422.211.474.23l-.962 1.72a.5.5 0 00-.045.38.509.509 0 00.237.3l2.62 1.463a.5.5 0 00.68-.193l2.2-3.928a.5.5 0 00.04-.379z\"/><path d=\"M20.573 7.2l-2.619-1.46a.5.5 0 00-.38-.044.5.5 0 00-.3.236l-3.659 6.548a.5.5 0 00.193.681l2.619 1.463a.5.5 0 00.68-.193l3.659-6.546a.5.5 0 00-.193-.685z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});