define("ember-svg-jar/inlined/protection-water-fortress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>protection-water-fortress</title><path d=\"M23.3 10.656l-1.914-1.915a1.5 1.5 0 00-1.06-.439H17.49a.25.25 0 01-.25-.25V4.8a.25.25 0 01.25-.25h.832c.13 0 .168.25.168.25a.75.75 0 00.75.75h3a.75.75 0 00.624-1.166l-.63-.945a.246.246 0 010-.277l.63-.946a.751.751 0 00-.624-1.166h-2.048c-.223 0-.2-.25-.2-.25a.75.75 0 00-.75-.75h-3a1 1 0 00-1 1v7a.25.25 0 01-.25.25h-3.338a1.5 1.5 0 00-1.06.439l-6.415 6.417a1.5 1.5 0 00-.439 1.06v2.09c0 .33.213.423.326.364a2.429 2.429 0 011.109-.27 2.507 2.507 0 012.261 1.425 1.281 1.281 0 001.051.627 1.863 1.863 0 001.476-.852 2.416 2.416 0 012.137-1.2 2.506 2.506 0 012.127 1.18c.113.169.727.932 1.3.865a2.536 2.536 0 011.074.174 1.792 1.792 0 00.8.187h5.839a.5.5 0 00.5-.5v-8.59a1.5 1.5 0 00-.44-1.06zM16.24 14.3h-7a.5.5 0 01-.354-.853l2-2a.5.5 0 01.354-.147h5a1.5 1.5 0 010 3z\"/><path d=\"M15.631 21.937a3.349 3.349 0 01-2.675-1.569 1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.405-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.105 1.638 1 1 0 000 2 5.8 5.8 0 003.64-1.361.241.241 0 01.327.017 4.552 4.552 0 003.26 1.344 5.115 5.115 0 003.456-1.348.244.244 0 01.331 0 4.714 4.714 0 003.5 1.327 1 1 0 00.929-1.067 1.016 1.016 0 00-1.072-.923z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});