define("ember-svg-jar/inlined/drawer-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>drawer-home</title><path d=\"M3.51 11.5a1 1 0 001 1 1 1 0 001-1L5.5 2.25a.247.247 0 01.073-.176.252.252 0 01.18-.074h12.494a.25.25 0 01.25.25l.003 9.25a1 1 0 001 1 1 1 0 001-1V2a2 2 0 00-2-2h-13a2 2 0 00-2 2z\"/><path d=\"M14 12h1.5a.5.5 0 00.5-.5V8.25a.25.25 0 01.25-.25h.911a.338.338 0 00.318-.219.329.329 0 00-.1-.369l-4.91-3.833a.344.344 0 00-.438 0L7.12 7.412a.329.329 0 00-.1.369.338.338 0 00.319.219h.911a.25.25 0 01.25.25v3.25a.5.5 0 00.5.5h1.5a.5.5 0 00.5-.5v-1.25a1.25 1.25 0 012.5 0v1.25a.5.5 0 00.5.5zM23.868 16l-1.75-3a1 1 0 10-1.728 1l1.164 2H17.5a1.5 1.5 0 00-1.5 1.5 1.5 1.5 0 01-1.5 1.5h-5A1.5 1.5 0 018 17.5 1.5 1.5 0 006.5 16H2.454l1.164-2a1 1 0 10-1.728-1L.14 16a1.414 1.414 0 00-.14.5v6A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-6a1.255 1.255 0 00-.132-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});