define("ember-svg-jar/inlined/swimming-lifeguard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>swimming-lifeguard</title><path d=\"M12.153 23.624a4.287 4.287 0 002.576-.966.249.249 0 01.31 0 4.01 4.01 0 002.546.936 4.448 4.448 0 002.622-.892.25.25 0 01.309.012 3.63 3.63 0 002.386.88 1 1 0 000-2 1.8 1.8 0 01-1.53-.939 1.043 1.043 0 00-1.717-.157 2.875 2.875 0 01-2.07 1.1 2.361 2.361 0 01-1.807-1.038 1.039 1.039 0 00-1.637-.091c-.009.012-1.02 1.234-2.065 1.153a.98.98 0 00-1.052.946 1 1 0 00.947 1.052c.061.003.122.004.182.004zM14.733 18.558a.249.249 0 01.3 0 4.529 4.529 0 001.7.846 3.846 3.846 0 00.852.095 4.085 4.085 0 001.165-.184 5.246 5.246 0 001.46-.713.248.248 0 01.3.012 3.835 3.835 0 001.876.832 3.664 3.664 0 00.514.053 1 1 0 000-2 1.8 1.8 0 01-1.53-.94 1.046 1.046 0 00-1.713-.162 2.9 2.9 0 01-2.074 1.1 2.36 2.36 0 01-1.807-1.039 1.041 1.041 0 00-1.637-.09c-.009.011-1.02 1.21-2.065 1.152a.981.981 0 00-1.052.946 1 1 0 00.947 1.052h.182a3.566 3.566 0 001.016-.164 5.29 5.29 0 001.566-.796zM3.07 2.251a2.231 2.231 0 104.461 0A2.293 2.293 0 005.237.021a2.176 2.176 0 00-2.167 2.23zM10.181 15.73a1.482 1.482 0 002.094.607 1.557 1.557 0 00.512-2.029l-1.793-3.588a1.981 1.981 0 00-1.77-1.093H6.813a.25.25 0 01-.25-.25V7.155a1.484 1.484 0 10-2.967 0v3.956a1.483 1.483 0 001.483 1.483h3.379a.251.251 0 01.224.138z\"/><path d=\"M1.439 14.556a.25.25 0 01.082.217L.6 22.615a1 1 0 00.876 1.11.961.961 0 00.119.007 1 1 0 00.992-.883l.161-1.369a.25.25 0 01.248-.221h3.84a.25.25 0 01.248.221l.19 1.616a1 1 0 00.992.883.961.961 0 00.119-.007 1 1 0 00.876-1.11l-.989-8.406a1.493 1.493 0 00-1.241-1.13H3.412a.984.984 0 01-.975-.9l-.343-4.12a.981.981 0 00-1.08-.906A1 1 0 00.1 8.474l.343 4.12a2.954 2.954 0 00.996 1.962zm2.005.99a.251.251 0 01.249-.22h2.445a.251.251 0 01.249.22l.055.467a.252.252 0 01-.062.2.249.249 0 01-.187.083H3.638a.249.249 0 01-.187-.083.252.252 0 01-.062-.2zM3.1 18.513a.251.251 0 01.248-.221h3.139a.249.249 0 01.248.221l.055.467a.249.249 0 01-.248.279H3.288a.251.251 0 01-.248-.279z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});