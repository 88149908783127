define("ember-svg-jar/inlined/building-nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>building-nature</title><path d=\"M2 5h15a.5.5 0 00.468-.676L16.211.973A1.508 1.508 0 0014.807 0H4.193a1.508 1.508 0 00-1.4.973L1.532 4.324A.5.5 0 002 5zM23 22h-1.5a.25.25 0 01-.25-.25v-.476a.245.245 0 01.151-.223A2.243 2.243 0 0022.75 19a67.259 67.259 0 00-1.77-7.14.521.521 0 00-.96 0A67.259 67.259 0 0018.25 19a2.243 2.243 0 001.35 2.051.245.245 0 01.151.223v.476a.25.25 0 01-.25.25H16.25a.25.25 0 01-.25-.25V6.25a.25.25 0 00-.25-.25H3.25a.25.25 0 00-.25.25v15.5a.25.25 0 01-.25.25H1a1 1 0 000 2h22a1 1 0 000-2zM5.5 8.75H8a.75.75 0 010 1.5H5.5a.75.75 0 010-1.5zM4.75 14a.75.75 0 01.75-.75H8a.75.75 0 010 1.5H5.5a.75.75 0 01-.75-.75zM11 21.75a.25.25 0 01-.25.25h-2.5a.25.25 0 01-.25-.25V19.5a1.5 1.5 0 013 0zm2.5-7H11a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5zm0-4.5H11a.75.75 0 010-1.5h2.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});