define("ember-svg-jar/inlined/people-man-glasses-1.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>people-man-glasses-1</title><path d=\"M16.951 16.979l-.259-.074a.5.5 0 00-.537.18 5.556 5.556 0 01-2.129 1.553.247.247 0 00.017.461l3.19 1.111a.25.25 0 00.329-.274l-.433-2.755a.25.25 0 00-.178-.202zM7.845 17.085a.5.5 0 00-.537-.18l-.259.074a.25.25 0 00-.178.2l-.432 2.747a.251.251 0 00.331.275l3.156-1.12a.247.247 0 00.02-.459 5.515 5.515 0 01-2.101-1.537z\"/><path d=\"M5.917 21.566a.5.5 0 01-.661-.548l.518-3.3a.247.247 0 00-.081-.226.252.252 0 00-.234-.053l-2.538.727A4.52 4.52 0 000 22.375v1a.5.5 0 00.5.5h10.25a.5.5 0 00.5-.5v-2.991a.5.5 0 00-.667-.472zM21.042 18.149l-2.5-.715a.25.25 0 00-.316.279l.519 3.305a.5.5 0 01-.659.548l-4.67-1.627a.5.5 0 00-.665.472v2.964a.5.5 0 00.5.5H23.5a.5.5 0 00.5-.5v-1a4.531 4.531 0 00-2.958-4.226zM14.75 6.625a2.493 2.493 0 00-2.372 1.75h-.756a2.5 2.5 0 100 1.5h.756a2.495 2.495 0 102.372-3.25zm0 3.5a1 1 0 111-1 1 1 0 01-1 1zm-6.5-1a1 1 0 111 1 1 1 0 01-1-1zM12.792 12.453a1.566 1.566 0 01-.764.172 1.653 1.653 0 01-.772-.174.75.75 0 10-.731 1.31 3.181 3.181 0 001.5.364h.006a3.146 3.146 0 001.5-.365.75.75 0 10-.734-1.307z\"/><path d=\"M6.149 12.447a.25.25 0 01.144.187c.511 3.048 4.021 4.991 5.686 4.991s5.176-1.943 5.687-4.989a.252.252 0 01.145-.189 2.731 2.731 0 001.439-2.434 2.116 2.116 0 00-.465-1.443c.438-3.12.115-8.445-6.806-8.445S4.737 5.452 5.174 8.57a2.112 2.112 0 00-.464 1.444 2.729 2.729 0 001.439 2.433zm11.042-1.366a1.746 1.746 0 00-1 1.3c-.378 2.249-3.176 3.739-4.209 3.739s-3.83-1.49-4.207-3.738a1.747 1.747 0 00-1-1.306 1.275 1.275 0 01-.559-1.1.626.626 0 01.1-.439.752.752 0 00.406-.794 11.859 11.859 0 01-.176-2.386.25.25 0 01.25-.241H9a.75.75 0 00.75-.75v-.708a.25.25 0 01.377-.215 3.545 3.545 0 011.2 1.267.75.75 0 00.673.415.721.721 0 00.172-.02.75.75 0 00.578-.73V4.55a.25.25 0 01.11-.207.253.253 0 01.234-.024 1.98 1.98 0 011.178 1.238.75.75 0 00.728.568h2.166a.249.249 0 01.249.24 11.777 11.777 0 01-.176 2.386.752.752 0 00.406.8.616.616 0 01.105.439 1.279 1.279 0 01-.559 1.091z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});