define("ember-svg-jar/inlined/tree-christmas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tree-christmas</title><path d=\"M13 19.6h-2a.5.5 0 00-.5.5v2.4a1.5 1.5 0 003 0v-2.4a.5.5 0 00-.5-.5zM19.3 16.471l-2.9-3.491a.248.248 0 01-.034-.265.252.252 0 01.227-.144h.469a1 1 0 00.832-1.553l-2.731-4.1a.25.25 0 01.153-.383.913.913 0 00.627-.494.994.994 0 00-.043-1.023l-3.068-4.6a1.037 1.037 0 00-1.664 0L8.1 5.018a.994.994 0 00-.049 1.025.913.913 0 00.627.494.25.25 0 01.153.383L6.1 11.018a1 1 0 00.832 1.553H7.4a.252.252 0 01.227.144.248.248 0 01-.034.265L4.7 16.471a1 1 0 00.8 1.6h13a1 1 0 00.8-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});