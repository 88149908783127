define("ember-svg-jar/inlined/religion-islam-pray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>religion-islam-pray</title><circle cx=\"11.25\" cy=\"3.75\" r=\"2.5\"/><path d=\"M18.25 19.75h-2.793l1.743-1.427a2.5 2.5 0 00.8-2.652L15.681 8.3a1.5 1.5 0 00-2.281-.783l-4.856 3.36a.251.251 0 01-.354-.073L7.02 8.95a1.5 1.5 0 10-2.539 1.6l1.464 2.325A2.5 2.5 0 009.6 13.8l3.554-2.46a.249.249 0 01.223-.031.253.253 0 01.158.161l1.445 4.6a.251.251 0 01-.081.269L10.3 20.088a1.5 1.5 0 00.949 2.662h7a1.5 1.5 0 000-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});