define("ember-svg-jar/inlined/science-apple-newton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>science-apple-newton</title><path d=\"M10.731 8.914c.515-.021.709-.333 1.331-.333s.816.312 1.33.333c.534.02.942-.534 1.282-1.027.7-1.009 1.232-2.85.516-4.093a1.989 1.989 0 00-1.681-1.019c-.524-.009-1.127.354-1.447.354s-.923-.363-1.447-.354a1.989 1.989 0 00-1.681 1.019c-.716 1.243-.183 3.084.516 4.093.34.493.75 1.047 1.281 1.027zM12.313 2.164a.747.747 0 00.366-.1L13.7 1.5a.75.75 0 10-.734-1.313L11.944.76a.75.75 0 00.369 1.4zM17.64 23.215a6.052 6.052 0 00-3.964-3.694.247.247 0 01-.026-.469 4.255 4.255 0 10-3.365.017.246.246 0 01-.02.468 6.067 6.067 0 00-3.9 3.678.51.51 0 00.477.693h10.321a.51.51 0 00.477-.693zm-6.186-5.853a2.264 2.264 0 01-1.608-1.4 2.149 2.149 0 01-.041-1.479.249.249 0 01.373-.136 5.364 5.364 0 003.822.728c.062-.012.2-.079.2.089a2.254 2.254 0 01-2.746 2.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});