define("ember-svg-jar/inlined/award-ribbon-star-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>award-ribbon-star-2</title><path d=\"M23.366 19.766l-3-5.18a.25.25 0 00-.439.011 4.663 4.663 0 01-4.317 2.548.249.249 0 00-.191.078 4.505 4.505 0 01-.963.765.251.251 0 00-.086.341l2.942 4.978a1 1 0 001.8-.17l.832-2.288 2.392.405a1 1 0 001.031-1.488zM8.583 17.224a.249.249 0 00-.191-.078A4.666 4.666 0 014.074 14.6a.251.251 0 00-.216-.136.247.247 0 00-.223.125l-3 5.181a1 1 0 001.032 1.487l2.391-.405.833 2.289a1 1 0 001.8.167l2.941-4.976a.251.251 0 00-.086-.341 4.551 4.551 0 01-.963-.767zM14.813 15.5a3.169 3.169 0 003.978-3.978 3.169 3.169 0 000-5.625 3.169 3.169 0 00-3.978-3.978 3.17 3.17 0 00-5.627 0 3.169 3.169 0 00-3.978 3.975 3.169 3.169 0 000 5.625A3.169 3.169 0 009.187 15.5a3.169 3.169 0 005.626 0zm-5.508-4.18l.774-1.775a.252.252 0 00-.054-.278l-1.4-1.374a.428.428 0 01.3-.748h1.591A.25.25 0 0010.747 7l.833-1.793a.463.463 0 01.824 0L13.237 7a.251.251 0 00.227.145h1.591a.428.428 0 01.3.748l-1.4 1.374a.25.25 0 00-.054.278l.773 1.775a.458.458 0 01-.652.574l-1.912-1.075a.249.249 0 00-.245 0l-1.907 1.075a.459.459 0 01-.653-.574z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});