define("ember-svg-jar/inlined/natural-disaster-flood-building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-flood-building</title><path d=\"M19.564 16.822A4.637 4.637 0 0023 18.281a1 1 0 000-2 2.774 2.774 0 01-2.43-1.427 1 1 0 00-.807-.566 1.016 1.016 0 00-.9.4 4.617 4.617 0 01-1.237 1.073.251.251 0 00-.123.216v1.672a.25.25 0 00.344.232 6.359 6.359 0 001.717-1.059zM5.144 14.7a1.012 1.012 0 00-.9-.41 1 1 0 00-.811.569A2.75 2.75 0 011 16.281a1 1 0 000 2 4.637 4.637 0 003.436-1.459 6.359 6.359 0 001.72 1.061.249.249 0 00.344-.232v-1.672a.252.252 0 00-.123-.216A4.592 4.592 0 015.144 14.7zM19.564 11.1A4.638 4.638 0 0023 12.563a1 1 0 000-2 2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.566 1.012 1.012 0 00-.9.4 4.656 4.656 0 01-1.237 1.073.251.251 0 00-.123.215v1.672a.249.249 0 00.344.232 6.334 6.334 0 001.717-1.061zM5.144 8.977a1.013 1.013 0 00-.9-.411 1 1 0 00-.811.569A2.75 2.75 0 011 10.563a1 1 0 000 2A4.638 4.638 0 004.436 11.1a6.334 6.334 0 001.72 1.061.249.249 0 00.344-.232V10.26a.251.251 0 00-.123-.215 4.612 4.612 0 01-1.233-1.068zM17.456 23.343a.25.25 0 00.338.279 6.346 6.346 0 001.77-1.081A4.641 4.641 0 0023 24a1 1 0 000-2 2.772 2.772 0 01-2.43-1.428 1 1 0 00-.807-.565 1.014 1.014 0 00-.9.4 4.637 4.637 0 01-1.237 1.073.251.251 0 00-.123.215v1.178a2.492 2.492 0 01-.047.47zM6.5 21.7a.251.251 0 00-.123-.215 4.612 4.612 0 01-1.233-1.068 1.01 1.01 0 00-.9-.41 1 1 0 00-.811.568A2.748 2.748 0 011 22a1 1 0 000 2 4.641 4.641 0 003.436-1.459 6.346 6.346 0 001.77 1.081.253.253 0 00.252-.04.25.25 0 00.086-.239 2.492 2.492 0 01-.044-.468zM16 2a2 2 0 00-2-2h-4a2 2 0 00-2 2v21a1 1 0 002 0V2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3.25a.25.25 0 01-.25.25H12.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H12.5a1 1 0 000 2h1.25a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25H12.5a1 1 0 000 2h1.25a.25.25 0 01.25.25V23a1 1 0 002 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});