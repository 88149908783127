define("ember-svg-jar/inlined/data-transfer-diagonal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-diagonal-1</title><path d=\"M6.811 13.627l7.147-7.147a.247.247 0 01.353 0l.884.884a1 1 0 00.707.293 1.016 1.016 0 00.267-.036 1 1 0 00.7-.722l1.414-5.657A1 1 0 0017.074.03l-5.657 1.414a1 1 0 00-.464 1.677l.884.884a.249.249 0 010 .353l-7.148 7.148a1.5 1.5 0 002.122 2.121zM17.189 10.373l-7.147 7.147a.25.25 0 01-.353 0l-.884-.885a1 1 0 00-1.678.465l-1.414 5.658A1 1 0 006.683 24a.964.964 0 00.243-.031l5.657-1.414a1 1 0 00.464-1.676L12.163 20a.25.25 0 010-.354l7.148-7.147a1.5 1.5 0 10-2.122-2.121z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});