define("ember-svg-jar/inlined/single-man-actions-coding.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-coding</title><path d=\"M17.5 11.006a6.464 6.464 0 106.5 6.463 6.489 6.489 0 00-6.5-6.463zm-2.513 6.639l1.293 1.286a.742.742 0 010 1.054.751.751 0 01-1.06 0l-2-1.985a.743.743 0 010-1.055l2-1.989a.753.753 0 011.06 0 .743.743 0 010 1.055l-1.293 1.285a.249.249 0 000 .349zM21.78 18l-2 1.988a.751.751 0 01-1.06 0 .742.742 0 010-1.054l1.293-1.286a.249.249 0 000-.352l-1.293-1.288a.743.743 0 010-1.055.753.753 0 011.06 0l2 1.989a.743.743 0 010 1.058zM7 10.011a4.972 4.972 0 10-5-4.972 4.991 4.991 0 005 4.972zM4.1 4.3a.5.5 0 01.3-.332.506.506 0 01.445.036A6.991 6.991 0 008.46 5.039a6.36 6.36 0 00.929-.07.5.5 0 01.569.562A3 3 0 014 5.039a2.8 2.8 0 01.1-.739zM11.443 12.022a.5.5 0 00-.225-.342 7.493 7.493 0 00-11.2 4.156.5.5 0 00.479.639H9.14a.5.5 0 00.492-.409 7.894 7.894 0 011.7-3.649.5.5 0 00.111-.395z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});