define("ember-svg-jar/inlined/movie-cinema", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>movie-cinema</title><path d=\"M24 8.5A1.5 1.5 0 0022.5 7h-3.158a.5.5 0 01-.489-.4 6.993 6.993 0 00-13.706 0 .5.5 0 01-.489.4H1.5A1.5 1.5 0 000 8.5v13A1.5 1.5 0 001.5 23h21a1.5 1.5 0 001.5-1.5zM2.75 21a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-5.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm13.5-5a.75.75 0 11-.75.75.75.75 0 01.75-.75zM12 3a.75.75 0 11-.75.75A.75.75 0 0112 3zM7.75 6a.75.75 0 11-.75.75.75.75 0 01.75-.75zm-.5 15a.75.75 0 11.75-.75.75.75 0 01-.75.75zM12 21a.75.75 0 11.75-.75.75.75 0 01-.75.75zm3.846-3.907a.655.655 0 01-.933.819l-2.66-1.5a.5.5 0 00-.491 0l-2.66 1.5a.655.655 0 01-.933-.819l1.039-2.385a.5.5 0 00-.172-.61L7.2 12.819a.612.612 0 01.429-1.069h2.157a.5.5 0 00.468-.323l1.164-3.069a.664.664 0 011.179 0l1.162 3.069a.5.5 0 00.468.323h2.154a.612.612 0 01.432 1.069L14.979 14.1a.5.5 0 00-.172.61zM16.75 21a.75.75 0 11.75-.75.75.75 0 01-.75.75zm4.5 0a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-5.5a.75.75 0 11.75-.75.75.75 0 01-.75.75zm0-4.5a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});