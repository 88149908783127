define("ember-svg-jar/inlined/clothes-design-tape-measure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>clothes-design-tape-measure</title><path d=\"M12.925 10.25a.249.249 0 00-.192.09l-2.5 3a.25.25 0 00.192.41h8.65a.249.249 0 00.192-.09l2.5-3a.25.25 0 00-.192-.41zM22.5 15h-2a.25.25 0 00-.25.25v3.25a.75.75 0 01-1.5 0v-3.25a.25.25 0 00-.25-.25H15a.25.25 0 00-.25.25v3.25a.75.75 0 01-1.5 0v-3.25A.25.25 0 0013 15H9.5a.5.5 0 00-.5.5v7a1.5 1.5 0 001.5 1.5h12a1.5 1.5 0 001.5-1.5v-6a1.5 1.5 0 00-1.5-1.5zM2.5 0h-1A1.5 1.5 0 000 1.5v6A1.5 1.5 0 001.5 9h1a.25.25 0 00.25-.25V.25A.25.25 0 002.5 0zM23 8.5v-7A1.5 1.5 0 0021.5 0H20a.25.25 0 00-.25.25V3.5a.75.75 0 01-1.5 0V.25A.25.25 0 0018 0h-3.5a.25.25 0 00-.25.25V3.5a.75.75 0 01-1.5 0V.25A.25.25 0 0012.5 0H9a.25.25 0 00-.25.25V3.5a.75.75 0 01-1.5 0V.25A.25.25 0 007 0H4.5a.25.25 0 00-.25.25v8.5A.25.25 0 004.5 9h18a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});