define("ember-svg-jar/inlined/notes-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-check</title><path d=\"M11.75 4.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1a.25.25 0 01.25.25v4.7a.244.244 0 00.3.238 7.881 7.881 0 011.46-.188.244.244 0 00.24-.243V4.5a2 2 0 00-2-2h-1.25a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.25.25 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h7.453a.244.244 0 00.238-.3 7.881 7.881 0 01-.191-1.46.244.244 0 00-.243-.24H2.25a.25.25 0 01-.25-.25V4.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.25.25 0 01.25-.25z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.273 5.2l-2.905 3.874a1.49 1.49 0 01-1.095.6h-.106a1.5 1.5 0 01-1.061-.441l-1.5-1.5a.75.75 0 011.061-1.06l1.3 1.3a.25.25 0 00.377-.027l2.732-3.643a.75.75 0 111.2.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});