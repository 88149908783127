define("ember-svg-jar/inlined/multiple-actions-share-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-share-2</title><path d=\"M13.5 13.527A.5.5 0 0014 14h1.35a.5.5 0 00.257-.071l1.257-.754a.5.5 0 00.232-.323 4.254 4.254 0 011.519-2.432.5.5 0 00.034-.754 6 6 0 00-6.625-1.132.5.5 0 00-.182.771A7.42 7.42 0 0113.5 13.5z\"/><ellipse cx=\"14.013\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11.9 13.881a.372.372 0 00.1-.28 6.025 6.025 0 00-12-.268.6.6 0 00.6.667h11.023a.377.377 0 00.277-.119z\"/><ellipse cx=\"5.513\" cy=\"3.5\" rx=\"3.513\" ry=\"3.5\"/><path d=\"M11 18.25a2.741 2.741 0 004.637 1.989.253.253 0 01.266-.051l2.448.98a.251.251 0 01.158.224 2.42 2.42 0 00.183.867 2.761 2.761 0 10.671-3 .253.253 0 01-.266.051l-2.445-.978a.253.253 0 01-.157-.219c0-.041-.007-.1-.019-.178a.22.22 0 01.1-.219L19.437 16a.251.251 0 01.271.009 2.708 2.708 0 001.542.487 2.75 2.75 0 10-2.75-2.75 2.4 2.4 0 00.021.295.247.247 0 01-.119.247L15.563 16a.249.249 0 01-.27-.009 2.714 2.714 0 00-1.543-.491A2.752 2.752 0 0011 18.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});