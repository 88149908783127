define("ember-svg-jar/inlined/calculator-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>calculator-app</title><path d=\"M10.5 11.25a.25.25 0 00.25-.25V.5a.25.25 0 00-.25-.25H4A3.755 3.755 0 00.25 4v7a.25.25 0 00.25.25zm-2.5-4H6.5a.25.25 0 00-.25.25V9a.75.75 0 01-1.5 0V7.5a.25.25 0 00-.25-.25H3a.75.75 0 010-1.5h1.5a.25.25 0 00.25-.25V4a.75.75 0 011.5 0v1.5a.25.25 0 00.25.25H8a.75.75 0 010 1.5zM12.25 11a.25.25 0 00.25.25h11a.25.25 0 00.25-.25V4A3.755 3.755 0 0020 .25h-7.5a.25.25 0 00-.25.25zM20 7.25h-5a.75.75 0 010-1.5h5a.75.75 0 010 1.5zM10.75 13a.25.25 0 00-.25-.25H.5a.25.25 0 00-.25.25v7A3.755 3.755 0 004 23.75h6.5a.25.25 0 00.25-.25zm-7.78 3.03a.75.75 0 111.06-1.06l1.293 1.293a.252.252 0 00.354 0L6.97 14.97a.75.75 0 111.06 1.06l-1.293 1.293a.252.252 0 000 .354L8.03 18.97a.75.75 0 11-1.06 1.06l-1.293-1.293a.252.252 0 00-.354 0L4.03 20.03a.75.75 0 01-1.06-1.06l1.293-1.293a.252.252 0 000-.354zM12.5 12.75a.25.25 0 00-.25.25v10.5a.25.25 0 00.25.25H20A3.755 3.755 0 0023.75 20v-7a.25.25 0 00-.25-.25zm1.75 3.75a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75zm.75 2.25h5a.75.75 0 010 1.5h-5a.75.75 0 010-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});