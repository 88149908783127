define("ember-svg-jar/inlined/insect-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-4</title><path d=\"M22.56.045c-4.9.754-9.417 3.873-10.308 11.173h-.5C10.869 3.914 6.325.8 1.44.045a1.25 1.25 0 00-.38 2.47 9.113 9.113 0 018 7.661.25.25 0 00.148.181c1.7.711 2.472 2.948 1.743 5.248a4.491 4.491 0 01-3.495 3.343.5.5 0 00-.448.5v1.107c.006.034.62 3.418 4.993 3.418s4.987-3.384 5-3.5v-1.027a.5.5 0 00-.448-.5 4.485 4.485 0 01-3.5-3.343c-.729-2.3.039-4.537 1.743-5.248a.25.25 0 00.148-.18 9.113 9.113 0 018-7.662 1.25 1.25 0 10-.38-2.47z\"/><path d=\"M15.9 11.624c-1.4 0-1.953 1.835-1.416 3.528.313.988 1.421 2.7 2.813 2.255 1.053-.334 1.5-1.885 1-3.464a2.968 2.968 0 00-2.397-2.319zM9.518 15.152c.551-1.74-.063-3.528-1.416-3.528a2.968 2.968 0 00-2.4 2.319c-.5 1.579-.053 3.13 1 3.464 1.383.437 2.492-1.234 2.816-2.255z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});