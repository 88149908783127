define("ember-svg-jar/inlined/electronics-rocker-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>electronics-rocker-switch</title><path d=\"M8.5 4a.5.5 0 00-.5.5v15a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-15a.5.5 0 00-.5-.5zm6 6.5a1 1 0 01-1 1h-3a1 1 0 01-1-1v-4a1 1 0 011-1h3a1 1 0 011 1z\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zm-2 4.5a1 1 0 111-1 1 1 0 01-1 1zm1 16a1 1 0 11-1-1 1 1 0 011 1zM17 4.5v15a1.5 1.5 0 01-1.5 1.5h-7A1.5 1.5 0 017 19.5v-15A1.5 1.5 0 018.5 3h7A1.5 1.5 0 0117 4.5zm-13.5 0a1 1 0 111-1 1 1 0 01-1 1zm1 16a1 1 0 11-1-1 1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});