define("ember-svg-jar/inlined/database-heart-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-heart-alternate</title><path d=\"M20.573 11.076a.99.99 0 001.179-.971v-5.1c0-3.248-5.41-5-10.5-5S.766 1.751.754 4.993v12.76c0 3.006 4.813 4.918 10.126 5a1 1 0 00.75-1.7 1 1 0 00-.712-.3c-5.067-.073-8.164-1.827-8.164-3v-.789a.249.249 0 01.373-.217 14.292 14.292 0 004.634 1.48.985.985 0 001.1-1.194v-.021a.978.978 0 00-.821-.76c-3.3-.53-5.28-1.8-5.28-2.749v-.788a.251.251 0 01.372-.219 15.005 15.005 0 005.3 1.574.987.987 0 00.978-.513l.013-.022a.977.977 0 00-.735-1.44c-3.682-.458-5.924-1.836-5.924-2.842v-.789a.25.25 0 01.373-.218A17.574 17.574 0 0011.253 10a17.581 17.581 0 008.126-1.756.25.25 0 01.373.218V10.1a.987.987 0 00.8.971zM11.253 2c5.185 0 8.5 1.773 8.5 3v.009C19.742 6.232 16.434 8 11.253 8s-8.5-1.777-8.5-3 3.311-3 8.5-3z\"/><path d=\"M20.286 12.548a3.493 3.493 0 00-3.057.988l-.3.3a.25.25 0 01-.353 0l-.305-.3a3.525 3.525 0 00-4.993 4.977l5.113 5.335a.5.5 0 00.722 0l5.106-5.327a3.525 3.525 0 00-1.928-5.973z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});