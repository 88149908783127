define("ember-svg-jar/inlined/video-game-monitor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-monitor</title><circle cx=\"14.5\" cy=\"9\" r=\".75\"/><circle cx=\"17.5\" cy=\"9\" r=\".75\"/><path d=\"M22.5 0h-21A1.5 1.5 0 000 1.5V18a1.5 1.5 0 001.5 1.5h9a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-3a1 1 0 000 2h9a1 1 0 000-2h-3a.5.5 0 01-.5-.5V20a.5.5 0 01.5-.5h9A1.5 1.5 0 0024 18V1.5A1.5 1.5 0 0022.5 0zM22 15a.5.5 0 01-.5.5h-19A.5.5 0 012 15V2.5a.5.5 0 01.5-.5h19a.5.5 0 01.5.5z\"/><path d=\"M10.854 9.354a.5.5 0 010-.708l2.452-2.452a.5.5 0 00-.046-.749 4.5 4.5 0 100 7.11.5.5 0 00.046-.749zM9 8.25a.75.75 0 11.75-.75.75.75 0 01-.75.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});