define("ember-svg-jar/inlined/smiley-lol-alternate-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-lol-alternate-2</title><path d=\"M12 2a10.013 10.013 0 019.967 9.187.251.251 0 00.145.208 12.61 12.61 0 011.527.8A.236.236 0 0024 12a12 12 0 00-24 0v.031a.249.249 0 00.386.208 12.026 12.026 0 011.5-.8.251.251 0 00.144-.208A10.013 10.013 0 0112 2zM20.4 17.837a.249.249 0 00-.329.067 9.99 9.99 0 01-16.106.035.251.251 0 00-.324-.069 3.256 3.256 0 01-1.409.419.25.25 0 00-.192.389 11.99 11.99 0 0019.934-.006.25.25 0 00-.186-.388 3.271 3.271 0 01-1.388-.447z\"/><path d=\"M16.692 8.067a3.208 3.208 0 00-2.83 1.6 1 1 0 001.748.971 1.076 1.076 0 01.591-.485 1.441 1.441 0 01.491-.083 1.441 1.441 0 01.491.083 1.077 1.077 0 01.589.484 1 1 0 101.749-.969 3.2 3.2 0 00-2.829-1.601zM6.519 10.635a1.076 1.076 0 01.591-.485 1.441 1.441 0 01.491-.083 1.44 1.44 0 01.49.083 1.074 1.074 0 01.589.484 1 1 0 101.749-.969A3.2 3.2 0 007.6 8.067a3.207 3.207 0 00-2.83 1.6 1 1 0 001.748.971zM17.534 14.216a.493.493 0 00-.544-.01A9.083 9.083 0 0112 15.422a9.089 9.089 0 01-4.99-1.216.489.489 0 00-.742.516 5.868 5.868 0 0011.464 0 .49.49 0 00-.198-.506zM3.466 16.049a7.862 7.862 0 00.555-2.983.586.586 0 00-.813-.572 7.844 7.844 0 00-2.62 1.529 1.76 1.76 0 102.878 2.026zM20.88 12.494a.585.585 0 00-.812.572 7.864 7.864 0 00.554 2.983 1.76 1.76 0 002.878-2.026 7.849 7.849 0 00-2.62-1.529z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});