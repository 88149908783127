define("ember-svg-jar/inlined/single-man-actions-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-add</title><path d=\"M12.543 11.233a.25.25 0 00-.057-.426l-1.334-.572a.251.251 0 01-.152-.23V8.87a.252.252 0 01.074-.177 3.305 3.305 0 00.779-2.23.251.251 0 01.088-.177 1.873 1.873 0 00.538-1.4 1.58 1.58 0 00-.317-1.015.252.252 0 01-.034-.265 2.527 2.527 0 00.157-1.726C11.944.492 10.242 0 8.888 0a3.752 3.752 0 00-3.163 1.324.251.251 0 01-.212.118 1.072 1.072 0 00-.9.375c-.372.493-.106 1.171.176 1.732a.251.251 0 01-.046.288 1.3 1.3 0 00-.162.2A1.765 1.765 0 004.32 5a1.524 1.524 0 00.638 1.331.249.249 0 01.117.194A3.167 3.167 0 005.92 8.7a.25.25 0 01.08.183v1.122a.25.25 0 01-.151.23l-3.937 1.687C.64 12.468.457 13.626.205 15.23c-.055.359-.117.751-.2 1.179A.5.5 0 00.5 17h8.793a.249.249 0 00.249-.227 7.986 7.986 0 013.001-5.54z\"/><path d=\"M17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm3.5 7.5h-2.25a.25.25 0 00-.25.25V21a1 1 0 01-2 0v-2.25a.25.25 0 00-.25-.25H14a1 1 0 010-2h2.25a.25.25 0 00.25-.25V14a1 1 0 012 0v2.25a.25.25 0 00.25.25H21a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});