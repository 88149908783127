define("ember-svg-jar/inlined/data-transfer-vertical-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-vertical-diamond</title><path d=\"M23 10.586L13.415 1A1.983 1.983 0 0012 .416 1.984 1.984 0 0010.586 1L1 10.586a2 2 0 000 2.828L10.586 23a2.006 2.006 0 002.829 0L23 13.414a2 2 0 000-2.828zm-7.97 3.83a.5.5 0 01.353.853l-3 3a.5.5 0 01-.707 0l-3-3a.5.5 0 01.354-.853h1.5a.25.25 0 00.25-.25v-4.5a.25.25 0 00-.25-.25h-1.5a.5.5 0 01-.354-.854l3-3a.5.5 0 01.707 0l3 3a.5.5 0 01-.353.854h-1.5a.25.25 0 00-.25.25v4.5a.25.25 0 00.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});