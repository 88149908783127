define("ember-svg-jar/inlined/gesture-tap-swipe-up-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-tap-swipe-up-1</title><path d=\"M12.32 3.5h1.25a.249.249 0 01.25.25V9a1 1 0 002 0V3.749a.25.25 0 01.25-.25h1.25a.75.75 0 00.469-1.335l-2.5-2a.748.748 0 00-.937 0l-2.5 2A.75.75 0 0012.32 3.5zM17.707 11.363a6.655 6.655 0 00-6.841.173.75.75 0 00.8 1.271 5.237 5.237 0 11-.01 8.88.75.75 0 10-.8 1.269A6.678 6.678 0 0014.422 24a6.766 6.766 0 003.277-.858 6.726 6.726 0 00.008-11.779z\"/><path d=\"M15.84 16.751a1 1 0 00-1-1H7.776a.25.25 0 01-.142-.455c2.138-1.489 1.449-3.855-.224-2.761l-4.367 2.912a.5.5 0 00-.223.415V21.3a.5.5 0 00.411.492c1.1.2 3.683.664 4.165.775a1.282 1.282 0 001.52-.893l1.116-3.741a.251.251 0 01.24-.178h4.568a1 1 0 001-1.004z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});