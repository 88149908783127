define("ember-svg-jar/inlined/shopping-cart-empty-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-cart-empty-1</title><path d=\"M23.663 6.763A1 1 0 0022.7 5.5H5.547a.249.249 0 01-.247-.2l-.572-2.72A3.264 3.264 0 001.551 0a1.25 1.25 0 000 2.5.752.752 0 01.733.6L5.45 18.169A3.252 3.252 0 006.687 20.1a.249.249 0 01.013.389 2 2 0 103.1.623.248.248 0 01.011-.243.252.252 0 01.213-.118h5.97a.25.25 0 01.224.361A2 2 0 0018.008 24a2 2 0 001.785-2.9.241.241 0 01.215-.35 1.25 1.25 0 000-2.5H8.631a.753.753 0 01-.734-.595l-.179-.855a.25.25 0 01.245-.3h10.754a3 3 0 002.894-2.211z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});