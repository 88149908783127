define("ember-svg-jar/inlined/multiple-actions-graduate.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-graduate</title><path d=\"M18.288 13.09l.341.147a.25.25 0 00.347-.25 6.888 6.888 0 00-.594-2.409c-.454-.908-1.9-1.441-3.911-2.179l-.485-.173a1.8 1.8 0 01-.056-1.172 4.644 4.644 0 001.216-3.568A3.219 3.219 0 0012 0a3 3 0 00-1.341.314.247.247 0 00-.131.16.253.253 0 00.034.2 5 5 0 01.838 2.812 6.263 6.263 0 01-.922 3.624.25.25 0 00.122.372c1.917.716 3.3 1.327 3.9 2.537a8.4 8.4 0 01.745 3.327.251.251 0 00.348.224l1.118-.48a2.008 2.008 0 011.577 0z\"/><path d=\"M14 13.5a7.521 7.521 0 00-.618-2.922c-.454-.908-1.9-1.441-3.911-2.179l-.485-.173a1.8 1.8 0 01-.056-1.172 4.644 4.644 0 001.216-3.568A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.486 4.611 4.611 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.178A7.53 7.53 0 000 13.5a.5.5 0 00.5.5h13a.5.5 0 00.5-.5zM11 20.965a.75.75 0 001.5 0v-2.154a.25.25 0 01.334-.235l4.5 1.589a.526.526 0 00.166.028.516.516 0 00.166-.028l6-2.119a.5.5 0 00.019-.935l-6-2.383a.5.5 0 00-.369 0l-6 2.383a.5.5 0 00-.316.474z\"/><path d=\"M21.094 22.917a.5.5 0 00.334-.471V20.3a.5.5 0 00-.666-.471l-3.179 1.122a.252.252 0 01-.167 0l-3.178-1.122a.5.5 0 00-.666.471v2.146a.5.5 0 00.334.471l2.571.908a3.075 3.075 0 002.045 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});