define("ember-svg-jar/inlined/arrow-button-right-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-right-2</title><path d=\"M21.058 10.289L5.014.564a2 2 0 00-3.036 1.711v19.45a2 2 0 003.037 1.711l16.043-9.725a2 2 0 000-3.422z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});