define("ember-svg-jar/inlined/audio-book-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>audio-book-headphones</title><path d=\"M21.265 10.857a.5.5 0 01-.3-.416 9 9 0 00-17.928 0 .5.5 0 01-.3.416 4.5 4.5 0 001.105 8.59.922.922 0 00.148.011 1.017 1.017 0 00.666-.247A1 1 0 005 18.455v-7.209a7 7 0 0114 0v7.209a1 1 0 00.346.756 1.017 1.017 0 00.666.247.934.934 0 00.149-.011 4.5 4.5 0 001.1-8.59z\"/><path d=\"M16.081 10.652a8.686 8.686 0 00-3.834.939.5.5 0 01-.494 0 8.683 8.683 0 00-3.834-.939 1.417 1.417 0 00-1.419 1.41v6.656a1.409 1.409 0 001.408 1.407 5.447 5.447 0 012.907.792.983.983 0 01.317.337 1 1 0 001.736 0 1 1 0 01.317-.337 5.447 5.447 0 012.907-.792 1.409 1.409 0 001.408-1.407v-6.656a1.417 1.417 0 00-1.419-1.41zM11 18.041a.5.5 0 01-.654.475 8.182 8.182 0 00-1.414-.316.5.5 0 01-.432-.5v-4.441a.5.5 0 01.593-.492 6.115 6.115 0 011.643.538.5.5 0 01.264.441zm4.5-.341a.5.5 0 01-.432.5 8.182 8.182 0 00-1.414.321.5.5 0 01-.654-.48v-4.295a.5.5 0 01.264-.441 6.127 6.127 0 011.643-.538.5.5 0 01.593.492z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});