define("ember-svg-jar/inlined/folder-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-edit</title><path d=\"M18.468 10.705a4.048 4.048 0 012.318-1.143.244.244 0 00.214-.241V4.5A1.5 1.5 0 0019.5 3H9.619a.5.5 0 01-.447-.276L8.224.829A1.5 1.5 0 006.883 0H1.5A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h9.464a.5.5 0 00.354-.146zM12.062 20.132a.25.25 0 00-.423.127l-.628 3.141a.5.5 0 00.49.6.473.473 0 00.1-.01l3.143-.628a.25.25 0 00.128-.422zM23.035 11.591a2.71 2.71 0 00-3.606.274l-6.58 6.58a.249.249 0 000 .353l3.351 3.353a.249.249 0 00.353 0l6.679-6.679a2.622 2.622 0 00-.197-3.881z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});