define("ember-svg-jar/inlined/road-sign-no-u-turn-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-no-u-turn-left</title><path d=\"M21.154 4.269a.247.247 0 00-.18-.089.25.25 0 00-.187.073L17.645 7.4a.75.75 0 01-1.061-1.061l3.14-3.141a.25.25 0 00-.016-.368A11.98 11.98 0 002.826 19.709a.251.251 0 00.369.016l3.034-3.035a.75.75 0 111.06 1.061l-3.035 3.036a.246.246 0 00-.073.187.254.254 0 00.088.181A11.98 11.98 0 0021.154 4.269zM16.229 17a.75.75 0 11-1.5 0v-6a2.75 2.75 0 10-5.5 0v1.188a.25.25 0 00.425.179l.926-.9a.75.75 0 011.048 1.074L9 15.1a.763.763 0 01-1.1-.062l-2.095-2.562a.75.75 0 111.161-.95l.319.391a.25.25 0 00.444-.158V11a4.25 4.25 0 018.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});