define("ember-svg-jar/inlined/os-system-blackberry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>os-system-blackberry</title><path d=\"M5.44 4.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.51c2.45 0 3.14-1.16 3.14-2.23 0-.76-.46-1.77-2.41-1.77zM13.44 4.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.51c2.45 0 3.14-1.16 3.14-2.23 0-.76-.46-1.77-2.41-1.77zM21.09 7.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.5c2.45 0 3.14-1.16 3.14-2.23.01-.76-.49-1.77-2.4-1.77zM20.09 13.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.5c2.45 0 3.14-1.16 3.14-2.23.01-.76-.49-1.77-2.4-1.77zM4.44 10.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.51c2.45 0 3.14-1.16 3.14-2.23 0-.76-.46-1.77-2.41-1.77zM12.44 10.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.51c2.45 0 3.14-1.16 3.14-2.23 0-.76-.46-1.77-2.41-1.77zM11.44 16.5h-2.6a.5.5 0 00-.49.4l-.64 3a.5.5 0 00.49.6h2.51c2.45 0 3.14-1.16 3.14-2.23 0-.76-.46-1.77-2.41-1.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});