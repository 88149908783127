define("ember-svg-jar/inlined/smiley-kiss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-kiss</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm-1.815 8.856A3.187 3.187 0 017.25 11a3.187 3.187 0 01-2.935-2.144 1 1 0 111.87-.712A1.207 1.207 0 007.25 9a1.207 1.207 0 001.065-.856 1 1 0 011.87.712zM13.5 18a1 1 0 010-2 .5.5 0 000-1h-2a1 1 0 010-2h2a2.5 2.5 0 012 4 2.5 2.5 0 01-2 4h-2a1 1 0 010-2h2a.5.5 0 000-1zm3.25-7a3.187 3.187 0 01-2.935-2.144 1 1 0 111.87-.712A1.207 1.207 0 0016.75 9a1.207 1.207 0 001.065-.856 1 1 0 111.87.712A3.187 3.187 0 0116.75 11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});