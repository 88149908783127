define("ember-svg-jar/inlined/phone-action-bin-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-bin-1</title><path d=\"M14.625 12.244v6.25a1 1 0 01-1 1h-7a1 1 0 01-1-1v-11a1 1 0 011-1h3.75a.25.25 0 00.25-.25v-2a.25.25 0 00-.25-.25h-4.25a2.5 2.5 0 00-2.5 2.5v15a2.5 2.5 0 002.5 2.5h8a2.5 2.5 0 002.5-2.5v-9.25a.25.25 0 00-.25-.25h-1.5a.25.25 0 00-.25.25z\"/><path d=\"M18.625 4.494h-6a.5.5 0 00-.5.5v3.875a1.666 1.666 0 001.7 1.625h3.6a1.666 1.666 0 001.7-1.625V4.994a.5.5 0 00-.5-.5zM19.625 1.494h-3.25V.756a.75.75 0 00-1.5 0v.738H11.6a.75.75 0 100 1.5h8.021a.75.75 0 000-1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});