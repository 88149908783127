define("ember-svg-jar/inlined/time-clock-circle-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>time-clock-circle-2</title><path d=\"M12 4.251A7.75 7.75 0 1019.75 12 7.759 7.759 0 0012 4.251zm3 9h-3.5a.75.75 0 01-.75-.75V7a.75.75 0 011.5 0v4.75H15a.75.75 0 010 1.5z\"/><path d=\"M19.5 0h-15A4.5 4.5 0 000 4.5v15A4.505 4.505 0 004.5 24h15a4.505 4.505 0 004.5-4.5v-15A4.5 4.5 0 0019.5 0zm1.75 12A9.25 9.25 0 1112 2.751 9.26 9.26 0 0121.25 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});