define("ember-svg-jar/inlined/trip-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>trip-distance</title><path d=\"M5.5 8.5A5.506 5.506 0 000 14c0 3.555 4.545 8.922 4.74 9.15a1 1 0 001.52 0C6.453 22.923 11 17.561 11 14a5.506 5.506 0 00-5.5-5.5zm0 8A2.5 2.5 0 118 14a2.5 2.5 0 01-2.5 2.5zM19.5.5A4.505 4.505 0 0015 5c0 3.171 3.978 7.185 4.146 7.354a.5.5 0 00.708 0C20.022 12.185 24 8.171 24 5A4.505 4.505 0 0019.5.5zm0 6A1.5 1.5 0 1121 5a1.5 1.5 0 01-1.5 1.5zM17.187 14.643l-7.2 6.22a1 1 0 001.307 1.514l7.2-6.221a1 1 0 10-1.306-1.513z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});