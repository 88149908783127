define("ember-svg-jar/inlined/phone-action-cash-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-cash-1</title><path d=\"M12 13.559V18.5a1 1 0 01-1 1H4a1 1 0 01-1-1v-11a1 1 0 011-1h4.26a.245.245 0 00.24-.241 7.969 7.969 0 01.3-1.942A.245.245 0 008.569 4H3.5A2.5 2.5 0 001 6.5v15A2.5 2.5 0 003.5 24h8a2.5 2.5 0 002.5-2.5v-7.228a.241.241 0 00-.163-.228 7.97 7.97 0 01-1.46-.691.244.244 0 00-.377.206z\"/><path d=\"M16.5 0A6.5 6.5 0 1023 6.5 6.508 6.508 0 0016.5 0zm-.75 10.25H15a.75.75 0 010-1.5h2.033a.592.592 0 00.22-1.141l-2.063-.826a2.091 2.091 0 01.56-4.021V2.25a.75.75 0 011.5 0v.5H18a.75.75 0 010 1.5h-2.033a.592.592 0 00-.22 1.141l2.063.826a2.091 2.091 0 01-.56 4.021v.512a.75.75 0 01-1.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});