define("ember-svg-jar/inlined/kitchen-knife-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchen-knife-set</title><path d=\"M17.386 15.775a11.385 11.385 0 01-2.329 2.362.25.25 0 01-.375-.307l.481-1.033a1 1 0 00-1.812-.845L12.1 18.633a1.7 1.7 0 00.823 2.26 1.653 1.653 0 001.4 0 12.312 12.312 0 004.66-3.916 1 1 0 10-1.6-1.2zM8.059 15.775a11.355 11.355 0 01-2.329 2.361.25.25 0 01-.376-.306l.482-1.03a1 1 0 00-1.813-.845l-1.249 2.678a1.7 1.7 0 00.824 2.26 1.651 1.651 0 001.4 0 12.3 12.3 0 004.66-3.916 1 1 0 10-1.6-1.2zM15.085 12.4a.249.249 0 01-.226.144H12.7a.25.25 0 01-.226-.356l.51-1.093a2 2 0 00-.968-2.658l-.668-.312a.25.25 0 01-.121-.332l1.327-2.849a1.248 1.248 0 00-.6-1.659l-.446-.21a1.252 1.252 0 00-1.661.605l-4.09 8.72a.249.249 0 01-.226.144H1a1 1 0 000 2h22a1 1 0 000-2h-.971a.251.251 0 01-.227-.356l.511-1.094a2 2 0 00-.969-2.657l-.668-.312a.25.25 0 01-.121-.332l1.328-2.849a1.249 1.249 0 00-.6-1.659l-.446-.21a1.249 1.249 0 00-1.661.605zm4.406 0a.249.249 0 01-.226.144h-1.649a.25.25 0 01-.226-.356l1.085-2.33a.5.5 0 01.665-.242l.906.422a.5.5 0 01.243.665zM9.148 9.853a.5.5 0 01.664-.242l.907.422a.5.5 0 01.242.665l-.415.889c-.042.093-.215.457-.382.809a.25.25 0 01-.226.143H8.289a.251.251 0 01-.227-.356z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});