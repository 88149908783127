define("ember-svg-jar/inlined/water-fountain-sink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-fountain-sink</title><path d=\"M17.011 7.279a.5.5 0 00-.391.188A4.668 4.668 0 0015.512 9.5a1.5 1.5 0 003 0A4.661 4.661 0 0017.4 7.466a.5.5 0 00-.389-.187zM21.441 13.5H2.559a1.012 1.012 0 00-1 1.123 10.5 10.5 0 0020.882 0 1.012 1.012 0 00-1-1.123zM11.509 12h1a.5.5 0 00.5-.5L13 3.5a1.5 1.5 0 113 .008l.012 1.5a1 1 0 002-.016L18 3.5a3.5 3.5 0 10-7 0l.009 8a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});