define("ember-svg-jar/inlined/shape-pyramid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shape-pyramid</title><path d=\"M22.511 21.677L13.321.979a1.373 1.373 0 00-2.628-.007L1.487 21.685a1.656 1.656 0 00.093 1.594A1.658 1.658 0 003.005 24H21a1.6 1.6 0 001.516-2.323zM19.665 20.2a.25.25 0 01-.357.316l-6.441-3.865a.251.251 0 01-.122-.215l.007-10.628a.25.25 0 01.478-.1zm-7.8-2.246a.254.254 0 01.258 0l5.977 3.582a.25.25 0 01-.129.464H6.028a.25.25 0 01-.128-.464zM10.773 5.715a.25.25 0 01.479.1l-.007 10.617a.249.249 0 01-.121.214L4.7 20.509a.25.25 0 01-.357-.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});