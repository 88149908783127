define("ember-svg-jar/inlined/data-transfer-three-back-forth-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-transfer-three-back-forth-back</title><path d=\"M22.542 10.527H8.9a.25.25 0 01-.25-.25V9.089a.959.959 0 00-1.452-.821L2.4 11.143a.957.957 0 000 1.643l4.791 2.875a.959.959 0 001.452-.822v-1.187a.25.25 0 01.25-.25h13.649a1.438 1.438 0 000-2.875zM.021 4.333a1.437 1.437 0 001.437 1.438H15.1a.25.25 0 01.25.25v1.187a.958.958 0 001.452.822L21.6 5.155a.957.957 0 000-1.643L16.806.637a.958.958 0 00-1.452.821v1.188a.25.25 0 01-.25.25H1.458A1.437 1.437 0 00.021 4.333zM21.6 18.845l-4.794-2.875a.959.959 0 00-1.452.822v1.187a.25.25 0 01-.25.25H1.458a1.438 1.438 0 000 2.875H15.1a.25.25 0 01.25.25v1.188a.958.958 0 001.452.821l4.798-2.875a.957.957 0 000-1.643z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});