define("ember-svg-jar/inlined/cricket-bat-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cricket-bat-ball</title><circle cx=\"3.25\" cy=\"3.25\" r=\"3.25\"/><path d=\"M23.561 3.975L20.025.44a1.535 1.535 0 00-2.125 0L7.718 10.626a.5.5 0 000 .707l4.949 4.949a.5.5 0 00.708 0L23.561 6.1a1.5 1.5 0 000-2.121zM6.657 12.394a.5.5 0 00-.851.3l-.295 2.659a1 1 0 01-.287.6L.293 20.879a1 1 0 000 1.414l1.414 1.414a1 1 0 001.414 0l4.932-4.931a1 1 0 01.6-.287l2.659-.3a.5.5 0 00.3-.85z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});