define("ember-svg-jar/inlined/sailing-boat-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>sailing-boat-person</title><path d=\"M22 16.257a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 00-1.5 1.5v.176a.249.249 0 00.227.249l5.5.5a.249.249 0 00.273-.249zM17.5 7a1 1 0 00-1-1h-13a1 1 0 000 2h13a1 1 0 001-1zM21.5 4a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1zM21.5 1a1 1 0 00-1-1h-17a1 1 0 000 2h17a1 1 0 001-1z\"/><circle cx=\"11.75\" cy=\"13.507\" r=\"2.25\"/><path d=\"M3.708 16.407a.249.249 0 01-.237.12l-2.409-.219h-.047a.5.5 0 00-.5.533 6.55 6.55 0 001.128 3.3.251.251 0 00.336.074 4.175 4.175 0 001-.85 2.5 2.5 0 014.089.227 2.112 2.112 0 001.487.874 2.792 2.792 0 001.888-1.017 2.5 2.5 0 014.287.39 1.281 1.281 0 001.051.627 1.868 1.868 0 001.477-.846 2.415 2.415 0 012.142-1.207 2.5 2.5 0 012.127 1.18c.114.17.741.922 1.3.865.056 0 .109.005.164.005l-.451-2.028a.251.251 0 00-.222-.2L6.249 16.779a.247.247 0 01-.2-.137.25.25 0 01.011-.242l.378-.606 1.877-3a1 1 0 10-1.695-1.06L4.207 15.59l-.499.817z\"/><path d=\"M22.931 21.954c-1.568.127-2.668-1.556-2.676-1.569a1.049 1.049 0 00-1.716.022 3.372 3.372 0 01-2.753 1.558 2.8 2.8 0 01-2.4-1.482 1.044 1.044 0 00-1.717-.156 4.3 4.3 0 01-3.1 1.638 3.558 3.558 0 01-2.76-1.58 1.041 1.041 0 00-1.638-.091c-.014.019-1.461 1.84-3.109 1.7A.983.983 0 000 22.942a1 1 0 00.946 1.052q.114.006.223.006a5.7 5.7 0 003.559-1.463.251.251 0 01.329.007 5.182 5.182 0 003.5 1.421 5.8 5.8 0 003.643-1.358.25.25 0 01.332.015 4.548 4.548 0 003.258 1.343 5.118 5.118 0 003.455-1.342.25.25 0 01.334 0 4.722 4.722 0 003.494 1.325 1 1 0 10-.138-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});