define("ember-svg-jar/inlined/megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>megaphone</title><path d=\"M24 9.495a2 2 0 00-1.33-1.873.5.5 0 00-.67.471l.007 3.824a.5.5 0 00.67.469A2 2 0 0024 10.5zM20.5 3.5a1.492 1.492 0 00-.7-1.26c-.834-.53-1.469-.086-2.726.519A22.5 22.5 0 016.972 5.447a.5.5 0 00-.472.5v8.606a8.312 8.312 0 004.6 7.334 1 1 0 10.888-1.792 6.288 6.288 0 01-3.48-5.421 21.913 21.913 0 018.55 2.47c1.376.647 1.938 1.035 2.756.5a1.494 1.494 0 00.683-1.264zM5 6.017a.5.5 0 00-.5-.5l-1.013.008A3.505 3.505 0 000 9.042l.009 2a3.5 3.5 0 003.516 3.483H4.5a.5.5 0 00.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});