define("ember-svg-jar/inlined/data-file-bars-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-bars-share</title><rect x=\"14.5\" y=\"5.5\" width=\"2\" height=\"8\" rx=\".5\" ry=\".5\"/><rect x=\"18\" y=\"7.5\" width=\"2\" height=\"6\" rx=\".5\" ry=\".5\"/><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v9.7a.248.248 0 00.469.116 3.559 3.559 0 011.4-1.593.239.239 0 00.131-.214V2.25A.25.25 0 018.25 2h11.232a.249.249 0 01.177.073l2.268 2.268a.249.249 0 01.073.177V18.25a.25.25 0 01-.25.25h-7.768a.247.247 0 00-.212.38 4.231 4.231 0 01.616 1.424.246.246 0 00.238.2H22a2 2 0 002-2V4.414A2 2 0 0023.414 3z\"/><path d=\"M8.442 16a.245.245 0 01.266.012 2.741 2.741 0 004.168-1.425A2.759 2.759 0 0010.25 11a2.808 2.808 0 00-2.713 3.059.245.245 0 01-.115.221L4.558 16a.245.245 0 01-.266-.012 2.71 2.71 0 00-2.406-.349 2.706 2.706 0 00-1.762 1.774A2.759 2.759 0 002.75 21a2.729 2.729 0 001.887-.761.245.245 0 01.26-.053l2.463 1a.239.239 0 01.14.214 2.753 2.753 0 105.361-1.025 2.683 2.683 0 00-1.736-1.74 2.74 2.74 0 00-2.767.623.24.24 0 01-.258.053L5.553 18.3a.114.114 0 01-.06-.084l-.03-.353a.118.118 0 01.056-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});