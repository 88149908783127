define("ember-svg-jar/inlined/vintage-woman-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-woman-1</title><path d=\"M17.146 16.213a5.351 5.351 0 001.317.161 3.8 3.8 0 002.921-1.12.5.5 0 00-.231-.8C18.582 13.632 19 9.965 19 9.875V8.563a.25.25 0 01.211-.247 39.91 39.91 0 002.506-.465 1 1 0 00.759-1.193.987.987 0 00-1.011-.764A44.7 44.7 0 0112 6.88a44.858 44.858 0 01-9.466-.986.985.985 0 00-1.01.764 1 1 0 00.759 1.193c.82.182 1.658.333 2.506.465A.25.25 0 015 8.563v1.312c0 .089.391 3.706-2.153 4.523a.5.5 0 00-.327.337.5.5 0 00.1.46 3.8 3.8 0 002.921 1.119 5.351 5.351 0 001.317-.161 3.48 3.48 0 001.028-.436.25.25 0 01.274 0 6.97 6.97 0 007.643.032.252.252 0 01.275 0 3.478 3.478 0 001.068.464zM7 9.875v-.983a.251.251 0 01.275-.249A47.247 47.247 0 0012 8.88a47.247 47.247 0 004.725-.237.252.252 0 01.275.249v.983a5 5 0 01-10 0z\"/><circle cx=\"14\" cy=\"10.375\" r=\"1\"/><circle cx=\"10\" cy=\"10.375\" r=\"1\"/><path d=\"M13.077 12.506h-2a.639.639 0 00-.3 1.2 2.451 2.451 0 00.435.18 3.024 3.024 0 00.867.126h.006a2.976 2.976 0 00.863-.125 2.433 2.433 0 00.436-.181.64.64 0 00-.3-1.2zM18.23 5.443a.25.25 0 00.2-.319C18.4 5 18.376 4.818 18.24 4c-.3-1.834-1.933-3.877-3.782-3.877H9.541a3.741 3.741 0 00-3.7 3.14c-.2 1.286-.257 1.686-.3 1.873a.252.252 0 00.039.2.256.256 0 00.171.1 45.679 45.679 0 0012.479.007zM11.517 18.5l-1.25 4.75a.5.5 0 00.483.627h2.5a.5.5 0 00.483-.627l-1.25-4.75a.519.519 0 00-.966 0zM.5 23.375a.5.5 0 00.5.5h8a.5.5 0 00.462-.691l-2.24-5.415a.5.5 0 00-.58-.295 13.808 13.808 0 00-5.175 2.318A2.462 2.462 0 00.5 21.769zM15 23.875h8a.5.5 0 00.5-.5v-1.606a2.462 2.462 0 00-.967-1.977 13.808 13.808 0 00-5.175-2.318.5.5 0 00-.58.295l-2.24 5.415a.5.5 0 00.462.691z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});