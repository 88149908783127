define("ember-svg-jar/inlined/tea-glass-herbal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tea-glass-herbal</title><path d=\"M9.749 23a2.232 2.232 0 01.66-1.591l1.191-1.194a.5.5 0 00.146-.354V17.5A2.253 2.253 0 0114 15.25c.069 0 .138 0 .205.009a.5.5 0 00.545-.5V14.5a2.238 2.238 0 011.028-1.877.5.5 0 00.222-.372L17 1.61a1.588 1.588 0 00-.42-1.157A1.518 1.518 0 0015.5 0h-14A1.568 1.568 0 00.357.524 1.561 1.561 0 000 1.629l1.8 19.627A2.983 2.983 0 004.789 24H9.3a.5.5 0 00.492-.6 2.073 2.073 0 01-.043-.4zM2.792 7.5a.249.249 0 01-.248-.227l-.472-5a.248.248 0 01.063-.191A.253.253 0 012.321 2h12.358a.249.249 0 01.249.273l-.473 5a.248.248 0 01-.248.227z\"/><path d=\"M24 15a.75.75 0 00-.75-.75h-.836a.25.25 0 01-.177-.427l.293-.293a.75.75 0 00-1.061-1.06l-.293.293a.25.25 0 01-.427-.177V11.5a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V14.5a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-.927.927a.25.25 0 01-.427-.177V17.5a.75.75 0 00-1.5 0v2.982a.5.5 0 01-.146.354l-1.634 1.634a.75.75 0 101.061 1.06l1.634-1.63a.5.5 0 01.353-.146H17.5a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.353-.146H20.5a.75.75 0 000-1.5h-1.086a.25.25 0 01-.177-.427l.927-.927a.5.5 0 01.353-.146h2.733A.75.75 0 0024 15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});