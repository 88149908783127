define("ember-svg-jar/inlined/single-neutral-actions-up-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-neutral-actions-up-down</title><path d=\"M14.25 13.25v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0l2.5-3.5A.5.5 0 0018 18.75h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0zM20.593 12.459l-2.5 3.5a.5.5 0 00.407.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0V17a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791l-2.5-3.5a.52.52 0 00-.814 0zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/><path d=\"M14.25 13.25v5.25a.25.25 0 01-.25.25h-1a.5.5 0 00-.407.791l2.5 3.5a.5.5 0 00.814 0l2.5-3.5A.5.5 0 0018 18.75h-1a.25.25 0 01-.25-.25v-5.25a1.25 1.25 0 00-2.5 0zM20.593 12.459l-2.5 3.5a.5.5 0 00.407.791h1a.25.25 0 01.25.25v5.25a1.25 1.25 0 002.5 0V17a.25.25 0 01.25-.25h1a.5.5 0 00.407-.791l-2.5-3.5a.52.52 0 00-.814 0zM.5 17.75h1.81a.25.25 0 01.249.229L3 23.292a.5.5 0 00.5.458h4a.5.5 0 00.5-.458l.443-5.313a.25.25 0 01.249-.229H10.5a.5.5 0 00.5-.5v-3.5a5.5 5.5 0 00-11 0v3.5a.5.5 0 00.5.5z\"/><circle cx=\"5.5\" cy=\"3.75\" r=\"3.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});