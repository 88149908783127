define("ember-svg-jar/inlined/e-commerce-basket-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-basket-browser</title><path d=\"M9.075 17.75h5.85a1.174 1.174 0 001.2-.975l.859-3.917a.5.5 0 00-.488-.607h-.758a.249.249 0 01-.223-.138L14.4 9.9a.75.75 0 10-1.34.673l.664 1.319a.25.25 0 01-.224.362h-3a.25.25 0 01-.224-.362l.664-1.319A.75.75 0 109.6 9.9l-1.119 2.212a.249.249 0 01-.223.138H7.5a.5.5 0 00-.488.607l.859 3.919a1.174 1.174 0 001.204.974z\"/><path d=\"M24 5.25a3 3 0 00-3-3H3a3 3 0 00-3 3v13.5a3 3 0 003 3h18a3 3 0 003-3zm-18-.5a1 1 0 111 1 1 1 0 01-1-1zm3.5 0a1 1 0 111 1 1 1 0 01-1-1zm-5 0a1 1 0 11-1-1 1 1 0 011 1zm17.5 14a1 1 0 01-1 1H3a1 1 0 01-1-1V8.25a1 1 0 011-1h18a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});