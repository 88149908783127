define("ember-svg-jar/inlined/office-eraser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-eraser</title><path d=\"M10.25 20.816a.249.249 0 00-.076-.18.253.253 0 00-.183-.07c-3.1.11-5.027-.049-5.619-2.134a4.549 4.549 0 01.015-2.161c.693-3.367 3.085-5.988 5.772-8.19a.254.254 0 00.091-.194V5.971a.25.25 0 00-.4-.2c-3.339 2.578-6.521 5.721-7.422 10.097a6.48 6.48 0 00.02 3.11c.891 3.139 3.619 3.622 6.278 3.622.495 0 .987-.016 1.465-.036a.062.062 0 00.059-.062zM21.5 19.748a.25.25 0 00.25-.25V8.248A.25.25 0 0021.5 8H12a.25.25 0 00-.25.25V19.5a.25.25 0 00.25.25zm-3.5-9a.75.75 0 111.5 0v6.5a.75.75 0 11-1.5 0zm-4 6.5v-6.5a.75.75 0 111.5 0v6.5a.75.75 0 01-1.5 0zM12 20.748a.25.25 0 00-.25.25v1.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V21a.25.25 0 00-.25-.25zM11.75 6.748A.25.25 0 0012 7h9.5a.25.25 0 00.25-.25V1.5A1.426 1.426 0 0020.337 0H13.25a1.5 1.5 0 00-1.5 1.5zm3-1.75a.75.75 0 01-.75-.75v-2a.75.75 0 111.5 0v2a.75.75 0 01-.75.752zm4.75-.75a.75.75 0 11-1.5 0v-2a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});