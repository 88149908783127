define("ember-svg-jar/inlined/router-signal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>router-signal</title><path d=\"M21 13.585h-7.25a.25.25 0 01-.25-.25v-6.75a1.5 1.5 0 00-3 0v6.75a.25.25 0 01-.25.25H3a3 3 0 00-3 3v2a3 3 0 002.769 2.991.25.25 0 01.231.249v.26a1.5 1.5 0 003 0v-.25a.249.249 0 01.25-.25h11.5a.249.249 0 01.25.25v.25a1.5 1.5 0 003 0v-.26a.25.25 0 01.231-.249A3 3 0 0024 18.585v-2a3 3 0 00-3-3zM5.159 18.243A1.2 1.2 0 013 17.54a1.206 1.206 0 012.411 0 1.176 1.176 0 01-.252.703zm2.922.422a1.125 1.125 0 111.125-1.125 1.125 1.125 0 01-1.125 1.125z\"/><path d=\"M6.967 3.017a1 1 0 00-1.367.369 6.419 6.419 0 00-.119 6.2 1 1 0 001.772-.93 4.424 4.424 0 01.081-4.278 1 1 0 00-.367-1.361zM3.355.546A1 1 0 001.991.92a11.547 11.547 0 00-.186 10.989 1 1 0 101.77-.93 9.529 9.529 0 01.154-9.069A1 1 0 003.355.546zM17.166 10.01a.989.989 0 00.463.115 1 1 0 00.887-.535 6.421 6.421 0 00-.119-6.2 1 1 0 00-1.734 1 4.426 4.426 0 01.081 4.278 1 1 0 00.422 1.342zM20.425 10.98a1 1 0 101.77.93A11.549 11.549 0 0022.009.92a1 1 0 10-1.738.99 9.534 9.534 0 01.154 9.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});