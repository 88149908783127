define("ember-svg-jar/inlined/target-center-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>target-center-2</title><path d=\"M22.962 3.808A.5.5 0 0022.5 3.5h-2.75a.249.249 0 01-.25-.25V.5a.5.5 0 00-.853-.354L16.233 2.56a2.484 2.484 0 00-.733 1.768v1.684a.25.25 0 01-.117.212.49.49 0 00-.09.069l-5 5a1 1 0 101.414 1.414l5-5a.463.463 0 00.07-.09.253.253 0 01.212-.117h1.683a2.483 2.483 0 001.768-.733l2.414-2.414a.5.5 0 00.108-.545z\"/><path d=\"M11 8a1 1 0 000-2 6 6 0 106 6 1 1 0 00-2 0 4 4 0 11-4-4z\"/><path d=\"M21 12a1 1 0 00-2 0 8 8 0 11-8-8 1 1 0 000-2 9.991 9.991 0 00-7.161 16.968.25.25 0 01.011.337L1.241 22.35a1 1 0 001.519 1.3l2.662-3.105a.249.249 0 01.321-.05 9.94 9.94 0 0010.514 0 .25.25 0 01.322.05l2.662 3.105a1 1 0 001.519-1.3l-2.609-3.045a.249.249 0 01.01-.336A9.962 9.962 0 0021 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});