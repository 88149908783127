define("ember-svg-jar/inlined/kitchenware-spatula-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kitchenware-spatula-2</title><path d=\"M23.529 10.8a.954.954 0 00.34-1.3 26.31 26.31 0 00-4.085-5.274A26.311 26.311 0 0014.511.132a.954.954 0 00-1.306.339l-4.2 7.172a3.67 3.67 0 00.569 4.449l2.334 2.334a3.67 3.67 0 004.449.574zM3.123 19.183a.5.5 0 00-.707 0L.5 21.1a1.7 1.7 0 002.4 2.4l1.918-1.918a.5.5 0 000-.707zM10.548 15.854a.5.5 0 000-.708l-1.694-1.693a.5.5 0 00-.707 0l-3.963 3.962a.5.5 0 000 .708l1.694 1.694a.5.5 0 00.707 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});