define("ember-svg-jar/inlined/medical-personnel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>medical-personnel</title><path d=\"M12.458 12.743a.25.25 0 00.172-.431 7.421 7.421 0 00-2.722-1.665 5.623 5.623 0 10-4.926.04A7.506 7.506 0 000 17.743a.5.5 0 00.5.5H9a.5.5 0 00.5-.5v-3a2 2 0 012-2zM8.25 15.118a.75.75 0 11-1.5 0v-1.655a.75.75 0 011.5 0zM7.4 9.243a3.626 3.626 0 01-3.619-3.622 3.516 3.516 0 01.283-1.366.252.252 0 01.378-.1 7.621 7.621 0 004.5 1.472 7.571 7.571 0 001.8-.222.223.223 0 01.194.043.225.225 0 01.087.178A3.626 3.626 0 017.4 9.243z\"/><path d=\"M22.5 14h-1.75a.5.5 0 01-.5-.5V13a1.752 1.752 0 00-1.75-1.75h-2A1.752 1.752 0 0014.75 13v.5a.5.5 0 01-.5.5H12.5a1.5 1.5 0 00-1.5 1.5v7a1.5 1.5 0 001.5 1.5h10a1.5 1.5 0 001.5-1.5v-7a1.5 1.5 0 00-1.5-1.5zm-6.25-1a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5zM20 19.75a.25.25 0 01-.25.25H19a.5.5 0 00-.5.5v.75a.25.25 0 01-.25.25h-1.5a.25.25 0 01-.25-.25v-.75a.5.5 0 00-.5-.5h-.75a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25H16a.5.5 0 00.5-.5v-.75a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v.75a.5.5 0 00.5.5h.75a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});