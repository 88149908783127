define("ember-svg-jar/inlined/pollution-barrel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pollution-barrel</title><circle cx=\"10\" cy=\"12\" r=\"1\"/><circle cx=\"14\" cy=\"12\" r=\"1\"/><path d=\"M21 11h.25a1.25 1.25 0 000-2.5H21a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h.25a1.25 1.25 0 000-2.5H19.5a.5.5 0 01-.5-.5V1a1 1 0 00-1-1h-3a1 1 0 00-1 1v.5a.5.5 0 01-.5.5H2.75a1.25 1.25 0 000 2.5H3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-.25a1.25 1.25 0 000 2.5H3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-.25a1.25 1.25 0 000 2.5H3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-.25a1.25 1.25 0 000 2.5h18.5a1.25 1.25 0 000-2.5H21a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5h.25a1.25 1.25 0 000-2.5H21a.5.5 0 01-.5-.5v-3a.5.5 0 01.5-.5zm-6.276 4.277a.5.5 0 00-.224.416V17a.5.5 0 01-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 01-1 0v-1a.5.5 0 00-1 0v1a.5.5 0 11-1 0v-1.3a.5.5 0 00-.223-.417A3.774 3.774 0 017.5 12a4.5 4.5 0 019 0 3.775 3.775 0 01-1.776 3.277z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});