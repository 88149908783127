define("ember-svg-jar/inlined/designer-community-adobe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>designer-community-adobe</title><path d=\"M12.43 8.85a.5.5 0 00-.43-.3.5.5 0 00-.45.29l-4.19 8.93a.5.5 0 00.45.71h3.83l1.7 4.21a.5.5 0 00.46.31h4.3a.5.5 0 00.46-.71zM23.5 1h-8.38a.5.5 0 00-.46.7L23 21.3a.5.5 0 00.46.3h.1a.5.5 0 00.4-.49V1.5a.5.5 0 00-.46-.5zM9.32 1.22A.5.5 0 008.9 1H.5a.5.5 0 00-.5.5v19.56a.5.5 0 00.4.49h.1a.5.5 0 00.46-.3L9.36 1.7a.5.5 0 00-.04-.48z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});