define("ember-svg-jar/inlined/weather-cloud-rain-thunder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>weather-cloud-rain-thunder</title><path d=\"M20.844 7.074a.252.252 0 01-.1-.193A7 7 0 007.088 4.84a.249.249 0 01-.22.173A5.5 5.5 0 007.25 16h10.5a5 5 0 003.094-8.926zM20.75 11a3 3 0 01-3 3H7.25a3.5 3.5 0 11.45-6.971A1 1 0 008.814 6.2a5 5 0 019.936.8c0 .146-.007.289-.02.433a1 1 0 00.5.959A3.007 3.007 0 0120.75 11zM8.289 19.408A.75.75 0 008.3 20.6l.953.714a.25.25 0 010 .4l-1.217.946a.75.75 0 00.922 1.184l2.25-1.75A.75.75 0 0011.2 20.9l-.953-.714a.25.25 0 010-.4l1.217-.946a.75.75 0 00-.922-1.184zM6.72 17.47l-5.25 5.25a.75.75 0 101.06 1.06l5.25-5.25a.749.749 0 00.028-1.03.4.4 0 00-.028-.03.749.749 0 00-1.06 0zM16.72 17.97l-4.75 4.75a.75.75 0 101.06 1.06l4.75-4.75a.75.75 0 00-1.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});