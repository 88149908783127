define("ember-svg-jar/inlined/astronomy-sun-eclipse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>astronomy-sun-eclipse</title><path d=\"M11.927 4.4a7.552 7.552 0 00-4.188 1.262.25.25 0 00.2.451 6.046 6.046 0 11-.02 11.76.25.25 0 00-.2.451A7.6 7.6 0 1011.927 4.4z\"/><path d=\"M4.786 12a4.546 4.546 0 104.546-4.55A4.546 4.546 0 004.786 12zM12 3.716a1 1 0 001-1V1.077a1 1 0 00-2 0v1.639a1 1 0 001 1zM19.272 6.142l1.159-1.16a1 1 0 00-1.414-1.414l-1.159 1.16a1 1 0 101.414 1.414zM22.923 11h-1.638a1 1 0 100 2h1.638a1 1 0 100-2zM19.272 17.857a1 1 0 10-1.414 1.414l1.159 1.159a1 1 0 101.414-1.414zM12 20.284a1 1 0 00-1 1v1.639a1 1 0 102 0v-1.639a1 1 0 00-1-1zM4.728 17.857l-1.159 1.159a1 1 0 001.414 1.414l1.159-1.159a1 1 0 10-1.414-1.414zM3.716 12a1 1 0 00-1-1H1.077a1 1 0 000 2h1.639a1 1 0 001-1zM4.728 6.142a1 1 0 101.414-1.414l-1.159-1.16a1 1 0 00-1.414 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});