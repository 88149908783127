define("ember-svg-jar/inlined/arrow-thick-up-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-thick-up-2</title><path d=\"M22.41 10.2L12.706.4A.99.99 0 0012 .1a.992.992 0 00-.7.289L1.573 10.02a2.6 2.6 0 00.1 3.657 2.59 2.59 0 003.657.1l3.743-3.732a.25.25 0 01.426.178v11.666C9.483 23.448 11.164 23.9 12 23.9a2.276 2.276 0 002.5-2.034V10.241a.25.25 0 01.431-.173l3.722 3.881A2.658 2.658 0 0022.41 10.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});