define("ember-svg-jar/inlined/flying-insect-bee-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>flying-insect-bee-4</title><path d=\"M16.714 18.05H7.285a.5.5 0 00-.449.72 5.765 5.765 0 003.836 3.074.5.5 0 01.359.329l.495 1.485a.5.5 0 00.948 0l.495-1.485a.5.5 0 01.359-.329 5.763 5.763 0 003.836-3.074.5.5 0 00-.45-.72zM22.755 9.995l-6.2-6.2a.5.5 0 01.034-.737l1.551-1.289A1 1 0 1016.86.232l-2.113 1.761a.5.5 0 01-.515.077 5.772 5.772 0 00-4.464 0 .5.5 0 01-.515-.077L7.14.232a1 1 0 10-1.28 1.537L7.409 3.06a.5.5 0 01.034.737l-6.2 6.2a4.238 4.238 0 005.1 6.685.994.994 0 01.494-.13h10.326a1.007 1.007 0 01.494.13 4.241 4.241 0 005.1-6.685zm-1.061 4.949a2.827 2.827 0 01-3.647.214.773.773 0 01-.3-.608.5.5 0 00-.5-.5H6.75a.5.5 0 00-.5.5.769.769 0 01-.3.608 2.827 2.827 0 01-3.646-.214 2.747 2.747 0 010-3.888l3.519-3.518a.25.25 0 01.427.177v.335a.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5v-.335a.25.25 0 01.427-.177l3.517 3.518a2.747 2.747 0 010 3.888z\"/><rect x=\"6.25\" y=\"10.05\" width=\"11.5\" height=\"2.5\" rx=\".5\" ry=\".5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});