define("ember-svg-jar/inlined/landmark-bagan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>landmark-bagan</title><path d=\"M24 23.25a.75.75 0 00-.75-.75H8.928a.251.251 0 01-.205-.107 1.507 1.507 0 00-.581-.492.249.249 0 01-.142-.225V18.25a1.5 1.5 0 00-1.01-1.418.249.249 0 01-.155-.157l-1.361-4.083a.5.5 0 00-.948 0l-1.362 4.083a.247.247 0 01-.155.157A1.5 1.5 0 002 18.249v3.427a.249.249 0 01-.142.225 1.507 1.507 0 00-.581.492.25.25 0 01-.2.107H.75a.75.75 0 000 1.5h22.5a.75.75 0 00.75-.75zM19.572 15a.25.25 0 00.237-.171l.268-.8a1.5 1.5 0 012.846 0l.268.8a.25.25 0 00.237.171h.072a.5.5 0 00.5-.5 1.5 1.5 0 00-.857-1.355.25.25 0 01-.143-.226V10a1.5 1.5 0 00-1.01-1.418.249.249 0 01-.155-.157l-1.361-4.083a.5.5 0 00-.948 0l-1.362 4.083a.247.247 0 01-.155.157A1.5 1.5 0 0017 10v2.922a.25.25 0 01-.139.224 1.518 1.518 0 00-.387.283.252.252 0 01-.176.072H15A.75.75 0 0015 15z\"/><path d=\"M23.5 21a.5.5 0 00.5-.5v-2a1.5 1.5 0 00-1.009-1.418.247.247 0 01-.155-.157l-.862-2.583a.52.52 0 00-.948 0l-.862 2.583a.247.247 0 01-.155.157A1.5 1.5 0 0019 18.5v.75a.25.25 0 01-.25.25h-9a.75.75 0 000 1.5z\"/><path d=\"M17.25 18a.75.75 0 000-1.5h-3a.25.25 0 01-.25-.25v-.75a1.5 1.5 0 00-1.009-1.418.247.247 0 01-.155-.157l-.862-2.583a.52.52 0 00-.948 0l-.862 2.583a.247.247 0 01-.155.157A1.5 1.5 0 009 15.5v2a.5.5 0 00.5.5zM2 9.5a1 1 0 101 1 1 1 0 00-1-1zM0 6a2.364 2.364 0 001.055 1.971l.668.445a.5.5 0 00.554 0l.668-.445A2.364 2.364 0 004 6a2 2 0 00-4 0z\"/><circle cx=\"9.5\" cy=\"9\" r=\"1.5\"/><path d=\"M8.671 6.206l.643.258a.5.5 0 00.372 0l.642-.258A3.434 3.434 0 0012.5 3a3 3 0 00-6 0 3.434 3.434 0 002.171 3.206z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});