define("ember-svg-jar/inlined/mstore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"100\" height=\"100\" rx=\"10\" fill=\"#2E2C32\"/><path d=\"M57.804 41.66c.594-2.487 2.6-4.381 5.682-4.381 3.825 0 6.09 2.86 6.09 6.424 0 2.451-1.3 4.568-3.38 5.57-1.299.632-3.156 1.003-5.904.892v6.61c3.937-.148 7.242.78 9.099 2.6 1.633 1.596 2.562 3.75 2.562 6.535 0 5.2-3.528 9.396-9.173 9.396-4.53 0-7.947-2.488-11.363-7.613L36.674 45.226 49.226 32.6h-9.581L27.279 45.003V16H20v54.887h7.316V54.584l4.122-4.16 14.817 22.58c4.01 6.09 9.321 8.838 16.414 8.838 4.27 0 8.393-1.449 11.4-4.197 3.306-3.045 5.348-7.241 5.348-12.18 0-5.905-2.97-10.658-8.467-12.366 3.64-1.634 5.868-5.31 5.868-9.916 0-3.342-1.263-6.573-3.565-8.875-2.377-2.377-5.905-3.64-9.73-3.64-3.676 0-7.167 1.189-9.618 3.788-1.894 2.006-2.897 4.494-3.23 7.168h7.13v.037z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "100",
      "height": "100",
      "viewBox": "0 0 100 100",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});