define("ember-svg-jar/inlined/network-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>network-add</title><path d=\"M23.171 7.62A11.941 11.941 0 0011.938.014 12.253 12.253 0 00.034 11.092a12.012 12.012 0 0011.214 12.893.912.912 0 00.908-.633.923.923 0 00-.272-.981 19 19 0 01-3.2-4.577.251.251 0 01.144-.347.988.988 0 00.672-.936 1 1 0 00-1-1h-.4a.25.25 0 01-.244-.2 15.606 15.606 0 01-.1-6.1.249.249 0 01.244-.2c10.543 0 13.454 0 14.268-.016a.939.939 0 00.903-1.375zM2.819 15.511a.25.25 0 01-.235-.166 9.886 9.886 0 01-.177-6.155.249.249 0 01.239-.179h2.807A.251.251 0 015.7 9.3a17.881 17.881 0 00-.2 2.712 17.27 17.27 0 00.295 3.2.251.251 0 01-.245.3zm5.222 5.158a.25.25 0 01-.311.371 10.048 10.048 0 01-3.8-3.131.25.25 0 01.2-.4h2.094a.25.25 0 01.234.163 12.881 12.881 0 001.583 2.997zM6.3 6.839a.249.249 0 01-.237.172H3.8a.249.249 0 01-.21-.385 10.042 10.042 0 014.143-3.645.25.25 0 01.311.37A13.165 13.165 0 006.3 6.839zm2.425.172a.25.25 0 01-.231-.346 10.07 10.07 0 013.36-4.383.251.251 0 01.291 0A10.163 10.163 0 0115.5 6.665a.25.25 0 01-.231.346zm9.216 0a.25.25 0 01-.241-.171 13.174 13.174 0 00-1.776-3.521.25.25 0 01.308-.373 9.9 9.9 0 014.2 3.681.25.25 0 01-.211.384z\"/><path d=\"M17.63 10.766a6.37 6.37 0 106.37 6.37 6.377 6.377 0 00-6.37-6.37zm-2.445 7.12a.75.75 0 110-1.5h1.445a.25.25 0 00.25-.25V14.69a.75.75 0 011.5 0v1.446a.25.25 0 00.25.25h1.445a.75.75 0 010 1.5H18.63a.25.25 0 00-.25.25v1.446a.75.75 0 01-1.5 0v-1.446a.25.25 0 00-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});