define("ember-svg-jar/inlined/crypto-currency-peercoin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-peercoin</title><path d=\"M10.5 6.75a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25v8.5a.25.25 0 00.25.25h.5a.25.25 0 00.25-.25v-8.5z\"/><path d=\"M12 1a11 11 0 1011 11A11 11 0 0012 1zm2.5 12.5h-2.75a.25.25 0 00-.25.25v1.5a.25.25 0 00.25.25h.75a.5.5 0 010 1h-.75a.25.25 0 00-.25.25V19a.5.5 0 01-1 0v-2.25a.25.25 0 00-.25-.25h-.5a.25.25 0 00-.25.25V19a.5.5 0 01-1 0v-2.25a.25.25 0 00-.25-.25H7.5a.5.5 0 010-1h.75a.25.25 0 00.25-.25V6.5a1 1 0 011-1h5a4 4 0 010 8z\"/><path d=\"M14.5 6.5h-2.75a.25.25 0 00-.25.25v5.5a.25.25 0 00.25.25h2.75a3 3 0 000-6zm-.745 4.649A1.83 1.83 0 0113 9.666V7.761a.25.25 0 01.273-.249 3 3 0 012.554 1.98 1.414 1.414 0 01-2.072 1.657z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});