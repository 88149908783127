define("ember-svg-jar/inlined/server-phone-exchange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-phone-exchange</title><path d=\"M9.026 3.947A.508.508 0 009.25 4a.5.5 0 00.3-.1l2-1.5a.5.5 0 000-.8l-2-1.5a.505.505 0 00-.524-.048A.5.5 0 008.75.5v.25A.25.25 0 018.5 1H6.75a3 3 0 00-3 3v1.5a.25.25 0 01-.25.25h-.25a.5.5 0 00-.4.8l1.5 2a.5.5 0 00.8 0l1.5-2a.5.5 0 00-.4-.8H6a.25.25 0 01-.25-.25V4a1 1 0 011-1H8.5a.25.25 0 01.25.25v.25a.5.5 0 00.276.447zM24 2a2 2 0 00-2-2h-6a2 2 0 000 4h6a2 2 0 002-2zm-8 0a1 1 0 111 1 1 1 0 01-1-1zM22 5h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM22 10h-6a2 2 0 000 4h6a2 2 0 000-4zm-6 2a1 1 0 111 1 1 1 0 01-1-1zM1.75 24h6a1.752 1.752 0 001.75-1.75v-11A1.752 1.752 0 007.75 9.5h-6A1.752 1.752 0 000 11.25v11A1.752 1.752 0 001.75 24zM2 11.75a.25.25 0 01.25-.25h5a.25.25 0 01.25.25v8.5a.25.25 0 01-.25.25h-5a.25.25 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});