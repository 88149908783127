define("ember-svg-jar/inlined/file-xls-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>file-xls-1</title><path d=\"M22 0H2A1.5 1.5 0 00.5 1.5v21A1.5 1.5 0 002 24h20a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022 0zm-8 20.123h-2.5A2.127 2.127 0 019.375 18v-6.5a.625.625 0 111.25 0V18a.876.876 0 00.875.875H14a.625.625 0 110 1.25zm3.305-5.672l1.949.975a2.479 2.479 0 01-1.108 4.7H16a.625.625 0 010-1.25h2.146a1.229 1.229 0 00.549-2.328l-1.95-.975a2.48 2.48 0 011.109-4.7H20a.625.625 0 010 1.25h-2.146a1.229 1.229 0 00-.549 2.328zm-8.746 4.768a.624.624 0 01-.28.838.618.618 0 01-.279.066.625.625 0 01-.559-.346l-1.217-2.435a.251.251 0 00-.448 0l-1.217 2.435a.625.625 0 01-.559.346.618.618 0 01-.279-.066.624.624 0 01-.28-.838l1.805-3.609a.252.252 0 000-.224l-1.805-3.609a.625.625 0 011.118-.558l1.217 2.435a.25.25 0 00.448 0l1.217-2.435a.625.625 0 011.118.558l-1.8 3.609a.252.252 0 000 .224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});