define("ember-svg-jar/inlined/smiley-wink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-wink</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM7.75 7a2 2 0 11-2 2 2 2 0 012-2zm-.8 7.662a1 1 0 011.384.292 4.369 4.369 0 007.324 0 1 1 0 011.676 1.092 6.37 6.37 0 01-10.676 0 1 1 0 01.296-1.384zm11.4-4.227a1 1 0 01-1.291-.579A1.207 1.207 0 0016 9a1.207 1.207 0 00-1.065.856 1 1 0 01-1.87-.712 3.081 3.081 0 015.87 0 1 1 0 01-.579 1.291z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});