define("ember-svg-jar/inlined/style-three-pin-fishing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-fishing</title><path d=\"M19.331 2.715a.5.5 0 00-.831.374V6.75a.75.75 0 01-1.5 0V1.464a.5.5 0 00-.293-.455A11.347 11.347 0 0012 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5a9.046 9.046 0 00-3.169-6.785zM10.5 13a5.481 5.481 0 01-3.809-1.976.252.252 0 00-.193-.08.249.249 0 00-.187.093l-.531.663A1 1 0 014 11.078V8.927A1 1 0 015.779 8.3l.532.664a.253.253 0 00.189.096.256.256 0 00.193-.081A5.481 5.481 0 0110.5 7c2.538 0 4.338 2.608 4.414 2.72a.5.5 0 010 .561C14.838 10.4 13.038 13 10.5 13zm8 1.748a2.75 2.75 0 01-5.5 0 .75.75 0 011.5 0 1.25 1.25 0 002.5 0v-1.5a.75.75 0 011.5 0zm-.75-3.248a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/><circle cx=\"12.007\" cy=\"9.744\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});