define("ember-svg-jar/inlined/common-file-text-home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>common-file-text-home</title><path d=\"M12 8.5h7a1 1 0 000-2h-7a1 1 0 000 2zM12 13h4.5a1 1 0 000-2H12a1 1 0 000 2z\"/><path d=\"M24 4.414A2 2 0 0023.414 3L21 .585A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.868a.25.25 0 00.33.232 2.489 2.489 0 011.34 0A.249.249 0 008 9.867V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.121a.5.5 0 01.146.354V18a.5.5 0 01-.5.5h-5.262a.458.458 0 00-.451.321 2.4 2.4 0 01-.924 1.23.247.247 0 00.148.448H22a2 2 0 002-2z\"/><path d=\"M7.324 12.118a.5.5 0 00-.647 0l-6.5 5.5A.5.5 0 00.5 18.5h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5h9a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.324-.882z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});