define("ember-svg-jar/inlined/video-game-logo-microsoft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>video-game-logo-microsoft</title><path d=\"M20.471 18H11a1 1 0 00-1 1v3.948a1.011 1.011 0 00.9 1Q11.443 24 12 24a11.955 11.955 0 009.243-4.354A1.006 1.006 0 0020.471 18zM6 18H3.515a1 1 0 00-.771 1.637 12.063 12.063 0 002.709 2.422 1 1 0 00.547.163 1 1 0 001-1V19a1 1 0 00-1-1zM6.478 1.9a1 1 0 00-1.024.04A11.961 11.961 0 000 12a12.082 12.082 0 00.751 4.173.5.5 0 00.47.327H6.5A.5.5 0 007 16V2.779a1 1 0 00-.522-.879zM12 0a10.767 10.767 0 00-1.1.059 1 1 0 00-.9 1V16a.5.5 0 00.5.5h12.276a.5.5 0 00.469-.326A11.987 11.987 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});