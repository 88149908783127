define("ember-svg-jar/inlined/road-sign-turn-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>road-sign-turn-left</title><path d=\"M23.25 17.25a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75H.75A.75.75 0 000 7.5v9a.75.75 0 00.75.75zm-21.5-1.5a.25.25 0 01-.25-.25v-7a.25.25 0 01.25-.25h2.59a.25.25 0 01.177.427l-2.423 2.429a1.235 1.235 0 00-.015 1.774l2.538 2.543a.188.188 0 01-.133.321zm9.915 0a.249.249 0 01-.177-.073L8 12.178a.252.252 0 010-.354l3.492-3.5a.249.249 0 01.177-.073h3.171a.25.25 0 01.177.427l-2.423 2.429a1.235 1.235 0 00-.015 1.774l2.538 2.543a.188.188 0 01-.133.321zM22.5 15.5a.25.25 0 01-.25.25h-.085a.249.249 0 01-.177-.073l-3.492-3.5a.252.252 0 010-.354l3.492-3.5a.249.249 0 01.177-.073h.085a.25.25 0 01.25.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});