define("ember-svg-jar/inlined/temperature-snowflake-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>temperature-snowflake-1</title><path d=\"M24 12a1 1 0 00-1-1h-1.982a.25.25 0 01-.177-.427l1.366-1.366a1 1 0 10-1.414-1.414l-2.914 2.914a1 1 0 01-.707.293h-.4a.5.5 0 01-.477-.35 4.506 4.506 0 00-.3-.73.5.5 0 01.089-.585l4.76-4.76a1 1 0 00-1.414-1.414l-4.76 4.76a.5.5 0 01-.585.089 4.506 4.506 0 00-.73-.3.5.5 0 01-.355-.48v-.4a1 1 0 01.293-.707l2.914-2.914a1 1 0 00-1.414-1.414l-1.366 1.364A.25.25 0 0113 2.982V1a1 1 0 00-2 0v1.982a.25.25 0 01-.427.177L9.207 1.793a1 1 0 00-1.414 1.414l2.914 2.914a1 1 0 01.293.707v.4a.5.5 0 01-.35.477 4.506 4.506 0 00-.73.3.5.5 0 01-.585-.089l-4.76-4.76a1 1 0 00-1.414 1.419l4.76 4.76a.5.5 0 01.089.585 4.506 4.506 0 00-.3.73.5.5 0 01-.48.35h-.4a1 1 0 01-.707-.293L3.207 7.793a1 1 0 10-1.414 1.414l1.366 1.366a.25.25 0 01-.177.427H1a1 1 0 000 2h1.982a.25.25 0 01.177.427l-1.366 1.366a1 1 0 101.414 1.414l2.914-2.914A1 1 0 016.828 13h.4a.5.5 0 01.477.35 4.506 4.506 0 00.3.73.5.5 0 01-.089.585l-4.76 4.76a1 1 0 101.414 1.414l4.76-4.76a.5.5 0 01.585-.089 4.506 4.506 0 00.73.3.5.5 0 01.35.477v.4a1 1 0 01-.293.707l-2.909 2.919a1 1 0 101.414 1.414l1.366-1.366a.25.25 0 01.427.177V23a1 1 0 002 0v-1.982a.25.25 0 01.427-.177l1.366 1.366a1 1 0 001.414-1.414l-2.914-2.914a1 1 0 01-.293-.707v-.4a.5.5 0 01.35-.477 4.506 4.506 0 00.73-.3.5.5 0 01.585.089l4.76 4.76a1 1 0 001.414-1.414l-4.76-4.76a.5.5 0 01-.089-.585 4.544 4.544 0 00.3-.73.5.5 0 01.48-.355h.4a1 1 0 01.707.293l2.914 2.914a1 1 0 001.414-1.414l-1.366-1.366a.25.25 0 01.179-.427H23a1 1 0 001-1zm-12 2.5a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});