define("ember-svg-jar/inlined/lock-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock-8</title><rect x=\"16.746\" y=\"17.5\" width=\"4.754\" height=\"2\" rx=\".25\" ry=\".25\"/><path d=\"M17 21a1.752 1.752 0 01-1.75-1.75v-5A1.752 1.752 0 0117 12.5h4.25a.25.25 0 00.25-.25v-.75a2 2 0 00-2-2h-.75V6.75a6.75 6.75 0 00-13.5 0V9.5H4.5a2 2 0 00-2 2V22a2 2 0 002 2h15a2 2 0 002-2v-.75a.25.25 0 00-.25-.25zM7.75 6.75a4.25 4.25 0 018.5 0V9a.5.5 0 01-.5.5h-7.5a.5.5 0 01-.5-.5z\"/><rect x=\"16.746\" y=\"14\" width=\"4.754\" height=\"2\" rx=\".25\" ry=\".25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});