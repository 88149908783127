define("ember-svg-jar/inlined/vintage-car-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vintage-car-1</title><circle cx=\"4.75\" cy=\"17.75\" r=\"3.5\"/><circle cx=\"19.25\" cy=\"17.75\" r=\"3.5\"/><path d=\"M23.715 12.885a6.244 6.244 0 00-1.821-1.295.25.25 0 01-.144-.227V4.25a1.5 1.5 0 00-1.5-1.5H9.75a3.529 3.529 0 00-2.1.7l-1.2.9a.5.5 0 00.3.9H8a.25.25 0 01.25.25v3.75a.5.5 0 01-.5.5h-3.5A.25.25 0 014 9.5V9a1 1 0 00-2 0v2.492a.25.25 0 01-.134.221 6.242 6.242 0 00-1.581 1.172 1 1 0 001.43 1.4A4.229 4.229 0 018.9 16.4a2.007 2.007 0 001.954 1.6h2.292a2.007 2.007 0 001.959-1.6 4.229 4.229 0 017.18-2.115 1 1 0 101.43-1.4zM14 9.5a.25.25 0 01-.25.25h-2.5a1 1 0 01-1-1v-3a1 1 0 011-1h2.5A.25.25 0 0114 5zm5.75-.75a1 1 0 01-1 1h-2.5A.25.25 0 0116 9.5V5a.25.25 0 01.25-.25h2.5a1 1 0 011 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});