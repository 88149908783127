define("ember-svg-jar/inlined/travel-suitcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>travel-suitcase</title><path d=\"M10.076 13.75H16a.131.131 0 00.1-.216 8.321 8.321 0 01-.545-1.913.5.5 0 00-.483-.371h-.292a1.464 1.464 0 01-1.18-.58 1.438 1.438 0 01-.271-1.277v-.006l.5-1.994A1.489 1.489 0 0115.28 6.25h.18a.5.5 0 00.267-.923A7 7 0 005.54 8.556.5.5 0 006 9.25h2.22a1.489 1.489 0 011.45 1.138v.005l.5 1.993v.007a1.432 1.432 0 01-.184 1.151.123.123 0 00.09.206z\"/><path d=\"M1.5 13.75h2.252a.5.5 0 00.485-.622A7.727 7.727 0 014 11.25a8 8 0 0116 0 7.727 7.727 0 01-.237 1.878.5.5 0 00.485.622H22.5a.5.5 0 00.5-.5v-11a1.5 1.5 0 00-1.5-1.5h-19A1.5 1.5 0 001 2.25v11a.5.5 0 00.5.5zM22.9 14.84H1.1A1.413 1.413 0 000 16.25v5.5a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-5.5a1.413 1.413 0 00-1.1-1.41zM4.926 21.033a.5.5 0 01-.412.217H2.5a.5.5 0 01-.5-.5v-2.014a.5.5 0 01.679-.467 4.016 4.016 0 012.3 2.3.5.5 0 01-.053.464zM16 18.5a1.752 1.752 0 01-1.75 1.75h-4.5A1.752 1.752 0 018 18.5v-1a.75.75 0 011.5 0v1a.25.25 0 00.25.25h4.5a.25.25 0 00.25-.25v-1a.75.75 0 011.5 0zm6 2.25a.5.5 0 01-.5.5h-2.014a.5.5 0 01-.467-.679 4.011 4.011 0 012.3-2.3.5.5 0 01.679.466z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});