define("ember-svg-jar/inlined/gesture-two-finger-zoom-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-finger-zoom-out</title><path d=\"M24 4.749a.75.75 0 00-1.28-.53l-.489.489a.25.25 0 01-.312.033 13.705 13.705 0 00-6.076-1.988 1 1 0 00-.186 1.992 12.063 12.063 0 014.706 1.38.246.246 0 01.126.184.25.25 0 01-.07.211l-.2.2A.75.75 0 0020.75 8h2.5a.75.75 0 00.75-.75zM3.58 6.519a.25.25 0 01.054-.4 11.8 11.8 0 014.705-1.374 1 1 0 10-.178-1.992 13.433 13.433 0 00-6.09 1.977.25.25 0 01-.312-.03l-.479-.481a.75.75 0 00-1.28.53v2.5A.75.75 0 00.75 8h2.5a.75.75 0 00.53-1.28zM14.7 14.818a.249.249 0 01-.139-.293l1.247-4.658A.93.93 0 0014 9.413l-1.43 4.592a.249.249 0 01-.3.168l-.585-.143a.25.25 0 01-.19-.243V9.229a1 1 0 00-2 0v7.064a.25.25 0 01-.455.143c-1.49-2.139-3.856-1.45-2.762.223L9.2 21.026a.5.5 0 00.416.223h5.435a.5.5 0 00.492-.411c.2-1.1.664-3.683.775-4.165.248-1.094-.666-1.43-1.618-1.855z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});