define("ember-svg-jar/inlined/office-desk-lamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>office-desk-lamp</title><path d=\"M23.943 22.666l-2.588-7.334a.25.25 0 01.236-.332h1.15a1 1 0 00.92-1.394L21.02 7.442a.253.253 0 01-.02-.1V4a4 4 0 00-4-4h-6.5a1.25 1.25 0 00-1.25 1.25v.5A1.75 1.75 0 0011 3.5h5a1.75 1.75 0 001.615-1.083.277.277 0 01.422-.12A1.993 1.993 0 0119 4v2.75a.25.25 0 01-.25.25H3.5a.5.5 0 00-.46.3l-2.7 6.3a1 1 0 00.918 1.4h1.151a.25.25 0 01.236.333L.057 22.666a1 1 0 00.61 1.276 1.01 1.01 0 001.276-.61S2.019 23 2.23 23h3.506c.177 0 .321.333.321.333a1.017 1.017 0 001.276.61 1 1 0 00.61-1.276l-2.588-7.335A.25.25 0 015.591 15h12.818a.25.25 0 01.236.333l-2.588 7.334a1 1 0 101.886.666s.1-.333.295-.333h3.506c.188 0 .313.333.313.333a1 1 0 001.886-.666zm-21.059-2l.88-2.493a.25.25 0 01.472 0l.88 2.493A.251.251 0 014.88 21H3.12a.251.251 0 01-.236-.334zM15.806 12.5H8.194a.5.5 0 01-.475-.658l.667-2A.5.5 0 018.86 9.5h6.28a.5.5 0 01.474.342l.667 2a.5.5 0 01-.475.658zm3.078 8.167l.88-2.493a.25.25 0 01.472 0l.88 2.493a.251.251 0 01-.236.333h-1.76a.251.251 0 01-.236-.334z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});