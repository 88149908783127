define("ember-svg-jar/inlined/3d-box-corner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-box-corner</title><path d=\"M23.308 19.363l-2.669-1.317a.25.25 0 01-.139-.224V9.5a1 1 0 00-.59-.912L13.4 5.641a.252.252 0 01-.147-.228V2a1.25 1.25 0 00-2.5 0v3.416a.25.25 0 01-.147.228L4.088 8.589A1 1 0 003.5 9.5v8.323a.252.252 0 01-.138.224l-2.67 1.33a1.25 1.25 0 001.116 2.238l3-1.5a.11.11 0 01.094 0l6.789 3.067h.009a.725.725 0 00.6 0h.007l6.785-3.067a.115.115 0 01.1 0l3 1.482a1.251 1.251 0 001.117-2.238zM7.432 9.728a.25.25 0 010-.456l4.468-2.02a.255.255 0 01.206 0l4.5 2.02a.25.25 0 010 .456l-4.5 2.02a.251.251 0 01-.206 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});