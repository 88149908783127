define("ember-svg-jar/inlined/dentistry-tooth-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dentistry-tooth-shield-1</title><path d=\"M13.338 13.144a.5.5 0 00-.19-.488A9.088 9.088 0 019.6 7.032a.5.5 0 00-.566-.413 3.849 3.849 0 00-1.958.9.5.5 0 01-.654 0 3.863 3.863 0 00-2.538-.944C1.707 6.569 0 8.63 0 11.26a7.8 7.8 0 001.584 5.086.5.5 0 01.086.483 11.536 11.536 0 00-.629 3.806 11.973 11.973 0 00.245 2.422A1.187 1.187 0 002.444 24a1.211 1.211 0 001.206-1.081c.257-2.353 1.589-4.127 3.1-4.127s2.843 1.774 3.1 4.128A1.211 1.211 0 0011.056 24a1.187 1.187 0 001.158-.943 11.973 11.973 0 00.245-2.422 11.53 11.53 0 00-.629-3.805.506.506 0 01.085-.484 6.93 6.93 0 001.423-3.202zM23.072 1.04A16.418 16.418 0 0017.479 0a16.019 16.019 0 00-5.551 1.04A1.545 1.545 0 0011 2.456v3.393a7.715 7.715 0 005.19 6.8 3.2 3.2 0 001.288.351 3.246 3.246 0 001.289-.347C21.848 11.479 24 8.682 24 5.849V2.456a1.545 1.545 0 00-.928-1.416zM20.5 6.5H19a.5.5 0 00-.5.5v1.5a1 1 0 01-2 0V7a.5.5 0 00-.5-.5h-1.5a1 1 0 010-2H16a.5.5 0 00.5-.5V2.5a1 1 0 012 0V4a.5.5 0 00.5.5h1.5a1 1 0 010 2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});