define("ember-svg-jar/inlined/gesture-two-fingers-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-two-fingers-scroll</title><path d=\"M15.524 12.059a.249.249 0 01-.137-.3l1.239-4.132a.93.93 0 00-1.8-.454L13.4 11.254a.25.25 0 01-.295.161l-.593-.144a.25.25 0 01-.191-.243V6.99a1 1 0 00-2 0v6.544a.25.25 0 01-.455.143c-1.49-2.139-3.856-1.45-2.762.221l2.911 3.434a.5.5 0 00.383.177l5.085-.018a.5.5 0 00.465-.319c.333-.861 1.074-2.766 1.187-3.258.251-1.091-.658-1.429-1.611-1.855zM11.314 3.749h5a.751.751 0 00.468-1.336l-2.5-2a.75.75 0 00-.937 0l-2.5 2a.751.751 0 00.469 1.336zM16.314 20.249h-5a.75.75 0 00-.469 1.335l2.5 2a.75.75 0 00.937 0l2.5-2a.75.75 0 00-.468-1.335z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});