define("ember-svg-jar/inlined/natural-disaster-hurricane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>natural-disaster-hurricane</title><path d=\"M21.5.5h-18a1 1 0 000 2h18a1 1 0 000-2zM19 6a1 1 0 000-2H6a1 1 0 000 2zM17 8.5a1 1 0 00-1-1H2.5a1 1 0 000 2H16a1 1 0 001-1zM19 12a1 1 0 00-1-1H7a1 1 0 000 2h11a1 1 0 001-1zM21 15.5a1 1 0 00-1-1h-9a1 1 0 000 2h9a1 1 0 001-1zM21 18h-6.5a1 1 0 000 2H21a1 1 0 000-2zM17 21.5h-2a1 1 0 000 2h2a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});