define("ember-svg-jar/inlined/data-file-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>data-file-shield</title><path d=\"M21 .586A2 2 0 0019.586 0H8a2 2 0 00-2 2v7.267a.248.248 0 00.244.25c.368 0 1.045.02 1.5.046A.246.246 0 008 9.314V2.5a.5.5 0 01.5-.5h10.879a.5.5 0 01.353.146l2.122 2.122a.5.5 0 01.146.353V18a.5.5 0 01-.5.5h-7.01a.242.242 0 00-.235.184 8.188 8.188 0 01-.53 1.46.245.245 0 00.218.355H22a2 2 0 002-2V4.415A2 2 0 0023.414 3z\"/><path d=\"M14.268 9.7l1.507.9A1 1 0 0017 10.452a.968.968 0 00.105-.124l2.688-3.734a1 1 0 10-1.624-1.168l-2.015 2.8a.251.251 0 01-.332.068L14.3 7.387a1 1 0 00-1.329.28L11.4 9.892a.246.246 0 00.123.379c.489.164 1.106.411 1.418.551a.247.247 0 00.308-.082l.689-.972a.249.249 0 01.33-.068zM12.072 12.041A16.353 16.353 0 006.479 11a16.006 16.006 0 00-5.551 1.04A1.545 1.545 0 000 13.456v3.393a7.716 7.716 0 005.19 6.805A9 9 0 006.479 24a9.023 9.023 0 001.288-.346C10.848 22.48 13 19.682 13 16.849v-3.393a1.543 1.543 0 00-.928-1.415zM9.5 17.25h-2a.25.25 0 00-.25.25v2a.75.75 0 01-1.5 0v-2a.25.25 0 00-.25-.25h-2a.75.75 0 010-1.5h2a.249.249 0 00.25-.25v-2a.75.75 0 011.5 0v2a.249.249 0 00.25.25h2a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});