define("ember-svg-jar/inlined/canoe-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>canoe-3</title><path d=\"M5 .5a.5.5 0 00-.5-.5h-4a.5.5 0 00-.5.5v2.984a2.5 2.5 0 001.251 2.162.5.5 0 01.249.433V23a1 1 0 002 0V6.079a.5.5 0 01.249-.433A2.5 2.5 0 005 3.484zM22.749 18.354a.5.5 0 01-.249-.433V1a1 1 0 00-2 0v16.921a.5.5 0 01-.249.433A2.5 2.5 0 0019 20.516V23.5a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-2.984a2.5 2.5 0 00-1.251-2.162zM15.33 7.43l-2.425-2.245a1.338 1.338 0 00-1.806 0L8.672 7.43a1.336 1.336 0 00-.422.98v.84a.5.5 0 00.5.5h6.5a.5.5 0 00.5-.5v-.84a1.335 1.335 0 00-.42-.98z\"/><rect x=\"8.25\" y=\"11.25\" width=\"7.5\" height=\"3.5\" rx=\".5\" ry=\".5\"/><path d=\"M15.239 16.25H8.761a.5.5 0 00-.5.539 3.749 3.749 0 007.476 0 .5.5 0 00-.5-.54z\"/><path d=\"M19 7.813a3.508 3.508 0 00-1.125-2.571l-3.5-3.231a3.517 3.517 0 00-4.749 0l-3.5 3.231A3.507 3.507 0 005 7.813V17a7 7 0 0014 0zm-1.75 8.672a5.25 5.25 0 11-10.5 0V8.41a2.842 2.842 0 01.9-2.082l2.427-2.244a2.85 2.85 0 013.843 0l2.424 2.246a2.837 2.837 0 01.9 2.081z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});