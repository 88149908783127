define("ember-svg-jar/inlined/single-man-id-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-id-card</title><path d=\"M21.5 5a2 2 0 00-2-2h-2.75a.25.25 0 00-.25.25v1.5a.251.251 0 00.25.25H19a.5.5 0 01.5.5v16a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-16A.5.5 0 015 5h2.25a.251.251 0 00.25-.25v-1.5A.25.25 0 007.25 3H4.5a2 2 0 00-2 2v17a2 2 0 002 2h15a2 2 0 002-2z\"/><path d=\"M6.932 20.236a.49.49 0 00.427.264h9.292a.492.492 0 00.427-.262.559.559 0 000-.527 5.8 5.8 0 00-5.069-3.211 5.814 5.814 0 00-5.08 3.21.56.56 0 00.003.526zM12 15.5a3.75 3.75 0 003.75-3.75c0-.655-.6-3.748-3.768-3.748A3.749 3.749 0 0012 15.5zm1.75-3.75a1.75 1.75 0 01-3.5 0 1.709 1.709 0 01.138-.668.25.25 0 01.32-.135 7.93 7.93 0 002.792.553.249.249 0 01.251.249zM10 6.5h4a1 1 0 001-1V3A3.008 3.008 0 0011.192.107 3.046 3.046 0 009 3.088V5.5a1 1 0 001 1zm1.25-3.75a.75.75 0 11.75.75.75.75 0 01-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});