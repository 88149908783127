define("ember-svg-jar/inlined/crypto-currency-bitcoin-dollar-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>crypto-currency-bitcoin-dollar-equal</title><path d=\"M11.891 17.438a.519.519 0 00-.782 0l-4 5a.5.5 0 00.391.812h8a.5.5 0 00.391-.812zM23 14.5H1a1 1 0 000 2h22a1 1 0 000-2zM1.25 11.25a1 1 0 001 1h.275a.25.25 0 01.245.2 1 1 0 001.961-.006.25.25 0 01.228-.2A3 3 0 007.75 9.25a2.961 2.961 0 00-.65-1.845.249.249 0 010-.31 2.961 2.961 0 00.65-1.845 3 3 0 00-2.791-2.993.251.251 0 01-.228-.2A1 1 0 002.77 2.05a.25.25 0 01-.245.2H2.25a1 1 0 00-1 1zm3.5-1H3.5a.25.25 0 01-.25-.25V8.5a.25.25 0 01.25-.25h1.25a1 1 0 010 2zm1-5a1 1 0 01-1 1H3.5A.25.25 0 013.25 6V4.5a.25.25 0 01.25-.25h1.25a1 1 0 011 1zM19.646 10.25H17.5a1 1 0 000 2h.25a.25.25 0 01.25.25v.25a1 1 0 002 0v-.324a.248.248 0 01.2-.244 2.845 2.845 0 00.721-5.339l-1.949-.975a.854.854 0 01.381-1.618H21.5a1 1 0 000-2h-.25A.25.25 0 0121 2v-.25a1 1 0 00-2 0v.324a.248.248 0 01-.2.244 2.845 2.845 0 00-.722 5.339l1.95.975a.854.854 0 01-.381 1.618z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});