define("ember-svg-jar/inlined/railroad-locomotive-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>railroad-locomotive-1</title><path d=\"M20.332 19.347h2.688a.98.98 0 100-1.959h-.5a.245.245 0 01-.245-.245v-6.367a.244.244 0 01.245-.245h.5a.98.98 0 000-1.96H.98a.98.98 0 000 1.96h.5a.245.245 0 01.245.245v6.367a.245.245 0 01-.245.245h-.5a.98.98 0 100 1.959h2.682a.245.245 0 01.218.356 1.9 1.9 0 000 1.737.245.245 0 01-.219.356H1.236a.98.98 0 100 1.959h21.551a.98.98 0 100-1.959h-2.455a.245.245 0 01-.218-.356 1.909 1.909 0 000-1.737.245.245 0 01.218-.356zm-15.667-4.9V11.51a.979.979 0 01.979-.979h3.918a.979.979 0 01.98.979v2.939a.98.98 0 01-.98.98H5.644a.979.979 0 01-.979-.98zm11.941 7.23a.248.248 0 01-.209.117H7.6a.245.245 0 01-.218-.356 1.9 1.9 0 000-1.737.245.245 0 01.01-.239.242.242 0 01.208-.117h8.8a.245.245 0 01.219.356 1.9 1.9 0 000 1.737.245.245 0 01-.013.241zm2.752-7.23a.979.979 0 01-.979.98H14.46a.979.979 0 01-.979-.98V11.51a.979.979 0 01.979-.979h3.919a.979.979 0 01.979.979zM1.236 2.2h6.906a.245.245 0 01.162.429l-.545.476-.01.01a1.468 1.468 0 00-.011 2.089l.006.007a.244.244 0 01-.173.418H7.1a.98.98 0 000 1.959h9.8a.98.98 0 100-1.959h-.472a.245.245 0 01-.174-.418l.007-.007a1.481 1.481 0 00.431-1.05 1.509 1.509 0 00-.479-1.074l-.01-.009-.506-.442a.245.245 0 01.161-.429h6.934a.98.98 0 100-1.959H1.236a.98.98 0 100 1.959zm8.455 1.821L11.7 2.265a.246.246 0 01.161-.061h.27a.246.246 0 01.161.061L14.3 4.02a.246.246 0 01.084.176.25.25 0 01-.071.182l-1.18 1.183a.245.245 0 01-.173.072h-1.926a.245.245 0 01-.173-.072L9.679 4.379a.247.247 0 01.012-.358z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});