define("ember-svg-jar/inlined/single-woman-actions-refresh.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-woman-actions-refresh</title><path d=\"M9.254 17.5a.25.25 0 00.25-.242 7.958 7.958 0 012.286-5.343.251.251 0 00-.092-.41l-.224-.082a.779.779 0 01-.513-.73v-.467a.25.25 0 01.25-.25A6.37 6.37 0 0015 9.081a.388.388 0 00.084-.593c-.494-.537-.835-1.185-.981-3.317C13.838 1.346 11.236 0 9 0S4.161 1.346 3.9 5.171c-.146 2.132-.487 2.78-.981 3.317A.388.388 0 003 9.081a6.348 6.348 0 003.782.9.251.251 0 01.254.25v.462a.779.779 0 01-.513.73c-2.9 1.065-5.212 1.825-5.764 2.922A7.822 7.822 0 000 17.076a.386.386 0 00.1.3.4.4 0 00.29.126zM23.392 19.871a1 1 0 00-1.893-.648 4.091 4.091 0 01-6.918 1.419.25.25 0 01.008-.345l1.721-1.721a.5.5 0 00-.353-.853H11.5a.5.5 0 00-.5.5v4.457a.5.5 0 00.854.353l.969-.97a.251.251 0 01.359.005 6.085 6.085 0 0010.21-2.2z\"/><path d=\"M23.691 11.772a.5.5 0 00-.545.109l-1.025 1.026a.251.251 0 01-.358 0 6.1 6.1 0 00-10.2 2.226 1 1 0 001.893.648 4.091 4.091 0 016.9-1.445.249.249 0 01-.006.346l-1.659 1.66a.5.5 0 00.353.853H23.5a.5.5 0 00.5-.5v-4.461a.5.5 0 00-.309-.462z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});