define("ember-svg-jar/inlined/water-protection-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>water-protection-drop</title><path d=\"M12 20.063a5.506 5.506 0 005.5-5.5c0-2.358-3.252-7.843-4.651-10.091a1.036 1.036 0 00-1.7 0C9.752 6.721 6.5 12.206 6.5 14.563a5.506 5.506 0 005.5 5.5zm-3.25-6a.5.5 0 01.5.5 2.753 2.753 0 002.75 2.75.5.5 0 010 1 3.754 3.754 0 01-3.75-3.75.5.5 0 01.5-.5zM7.789 21.073a10 10 0 01-1.506-.867 1 1 0 10-1.146 1.639 11.935 11.935 0 001.808 1.041 1 1 0 00.844-1.813zM22.384 15.776a1 1 0 00-1.331.478 9.992 9.992 0 01-.875 1.5 1 1 0 101.634 1.153 11.994 11.994 0 001.05-1.8 1 1 0 00-.478-1.331zM2.95 16.261a1 1 0 00-1.809.854 12.13 12.13 0 001.051 1.8 1 1 0 001.634-1.154 10 10 0 01-.876-1.5zM12.874 21.962a10.213 10.213 0 01-1.74 0 1 1 0 00-.172 1.993 12.185 12.185 0 002.084 0 1 1 0 00-.172-1.993zM17.722 20.2a10.082 10.082 0 01-1.505.868 1 1 0 00.845 1.813 12.152 12.152 0 001.807-1.042 1 1 0 00-1.147-1.639z\"/><path d=\"M12 0A12.013 12.013 0 000 12c0 .367.017.731.049 1.09a1 1 0 001.992-.181Q2 12.461 2 12a10 10 0 0120 0q0 .46-.041.909a1 1 0 001.992.181c.032-.359.049-.723.049-1.09A12.013 12.013 0 0012 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});