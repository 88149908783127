define("ember-svg-jar/inlined/fitness-heart-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fitness-heart-rate</title><path d=\"M21.566 12.133h-5.135a.5.5 0 00-.457.3l-1.159 2.606a1.5 1.5 0 01-1.358.891h-.015a1.5 1.5 0 01-1.357-.868l-2.1-4.489a.251.251 0 00-.435-.033l-.618.929a1.5 1.5 0 01-1.249.668H2.434a.25.25 0 00-.179.424l9 9.263a1.046 1.046 0 001.5 0l9-9.263a.25.25 0 00-.179-.424z\"/><path d=\"M23.361 5.166a6.093 6.093 0 00-9.691-1.552l-1.333 1.215a.5.5 0 01-.674 0L10.33 3.614A6.093 6.093 0 00.639 5.166 5.9 5.9 0 00.336 9.8a.5.5 0 00.471.335h6.346a.5.5 0 00.416-.223l1.062-1.594a1.482 1.482 0 011.362-.664 1.5 1.5 0 011.246.861l1.961 4.207a.25.25 0 00.455 0l.754-1.7a1.5 1.5 0 011.37-.89h7.411a.5.5 0 00.472-.334 5.9 5.9 0 00-.301-4.632z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});