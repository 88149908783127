define("ember-svg-jar/inlined/resize-expand-corner-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>resize-expand-corner-1</title><path d=\"M7 7.25a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM11.75 1.5a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM16.75 1.5a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM16.75 18.5a.75.75 0 010-1.5h1a.75.75 0 010 1.5zM24 12.25a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM24 7.25a.75.75 0 01-1.5 0v-1a.75.75 0 011.5 0zM9 .75A.75.75 0 008.25 0H7a1.5 1.5 0 00-1.5 1.5v1.25a.75.75 0 001.5 0v-1a.25.25 0 01.25-.25h1A.75.75 0 009 .75zM23.25 3.5a.75.75 0 00.75-.75V1.5A1.5 1.5 0 0022.5 0h-1.25a.75.75 0 000 1.5h1a.25.25 0 01.25.25v1a.75.75 0 00.75.75zM23.25 15a.75.75 0 01.75.75V17a1.5 1.5 0 01-1.5 1.5h-1.25a.75.75 0 010-1.5h1a.25.25 0 00.25-.25v-1a.75.75 0 01.75-.75zM13.5 17.75a.75.75 0 00-.75-.75h-1.5a.25.25 0 01-.25-.25V15a2 2 0 00-2-2H7.25a.25.25 0 01-.25-.25v-1.5a.75.75 0 00-1.5 0v1.5a.25.25 0 01-.25.25H2a2 2 0 00-2 2v7a2 2 0 002 2h7a2 2 0 002-2v-3.25a.25.25 0 01.25-.25h1.5a.75.75 0 00.75-.75zM9 21.5a.5.5 0 01-.5.5h-6a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5h6a.5.5 0 01.5.5zM20.5 4.25a.75.75 0 00-.75-.75h-5.543a.707.707 0 00-.5 1.207l1.909 1.909a.25.25 0 010 .354l-3.323 3.323a1 1 0 001.414 1.414l3.323-3.323a.25.25 0 01.354 0l1.909 1.909a.707.707 0 001.207-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});