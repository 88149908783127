define("ember-svg-jar/inlined/content-pen-write-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>content-pen-write-1</title><path d=\"M22.862.671a2.264 2.264 0 00-2.435.113.9.9 0 00-.166-.147l-.328-.189a2.515 2.515 0 00-3.433.922l-2.823 4.892a1 1 0 001.733 1l2.822-4.893a.515.515 0 01.7-.189l.327.19-6.2 10.756a1.135 1.135 0 00.416 1.55l1.967 1.135a1.135 1.135 0 001.55-.416L23.7 3.773a2.27 2.27 0 00-.838-3.102zM15.583 17.065L12.5 15.287a.5.5 0 00-.748.392l-.351 4.166a.5.5 0 00.248.476.507.507 0 00.535-.024l3.431-2.389a.5.5 0 00-.036-.843zM9.758 21.819a6.168 6.168 0 01-3.466-.7 6.652 6.652 0 00-2.36-.659 5.421 5.421 0 00-3.64 1.393A1 1 0 001.7 23.276a3.388 3.388 0 012.233-.815 5.869 5.869 0 011.607.511 9.586 9.586 0 003.576.916 4.952 4.952 0 001.084-.118 1 1 0 10-.44-1.951z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});