define("ember-svg-jar/inlined/wifi-cash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wifi-cash</title><path d=\"M17.662 10.949A6.338 6.338 0 1024 17.287a6.345 6.345 0 00-6.338-6.338zm-.75 10.474v-.217a.25.25 0 00-.25-.25H16.2a.75.75 0 010-1.5h1.978a.556.556 0 00.207-1.071l-2.008-.8a2.048 2.048 0 01.334-3.908.251.251 0 00.2-.245v-.277a.75.75 0 011.5 0v.216a.25.25 0 00.25.25h.46a.75.75 0 010 1.5h-1.977a.555.555 0 00-.207 1.071l2.007.8a2.048 2.048 0 01-.334 3.908.251.251 0 00-.2.244v.279a.75.75 0 01-1.5 0zM9.62 6.5a7.654 7.654 0 015.448 2.258 1 1 0 001.414-1.414A9.638 9.638 0 009.62 4.5a9.64 9.64 0 00-6.86 2.844 1 1 0 001.411 1.415A7.655 7.655 0 019.618 6.5z\"/><path d=\"M17.53 5.645a1 1 0 101.414-1.414 13.2 13.2 0 00-18.651 0 1 1 0 001.414 1.414 11.2 11.2 0 0115.823 0zM13.363 11.1a.964.964 0 00.153-.456.99.99 0 00-.423-.931 6.228 6.228 0 00-7.875.763 1 1 0 101.414 1.414 4.23 4.23 0 015.344-.519.969.969 0 00.388.152 1 1 0 00.999-.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});