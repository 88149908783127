define("ember-svg-jar/inlined/notes-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>notes-warning</title><path d=\"M11.59 17.866a.25.25 0 00-.222-.366H2.251A.249.249 0 012 17.25V4.75a.249.249 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1.5a.25.25 0 01.25.25V5a1 1 0 002 0v-.25a.249.249 0 01.25-.25h1a.25.25 0 01.25.25v5.544a.246.246 0 00.383.206 2.891 2.891 0 011.382-.49.248.248 0 00.235-.244V4.5a2 2 0 00-2-2h-1.25a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5a.249.249 0 01-.25-.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25h-1.5A.249.249 0 016 2.25V1a1 1 0 00-2 0v1.25a.25.25 0 01-.25.25H2a2 2 0 00-2 2v13a2 2 0 002 2h8.58a.253.253 0 00.222-.134zM23.836 21.905l-5.055-9.634a1.449 1.449 0 00-2.561 0l-5.055 9.634a1.426 1.426 0 00.047 1.408 1.454 1.454 0 001.233.687h10.111a1.456 1.456 0 001.233-.686 1.428 1.428 0 00.047-1.409zM16.751 16a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm.75 6.25a1 1 0 111-1 1 1 0 01-1.001 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});