define("ember-svg-jar/inlined/database-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>database-lock</title><path d=\"M10.875 21.5a.25.25 0 00.25-.25v-3a.252.252 0 00-.074-.177.255.255 0 00-.177-.073c-4.338.007-7.517-1.143-9.092-2.41a.25.25 0 00-.407.2v1.71c0 2.321 4.519 4 9.5 4z\"/><path d=\"M12.378 13.449c-.5.034-1 .052-1.5.052-3.5 0-7.155-.833-9.092-2.41a.25.25 0 00-.408.194V13c0 2.32 4.519 4 9.5 4a.25.25 0 00.25-.25V16a1.994 1.994 0 011.335-1.88.251.251 0 00.168-.222c0-.046.007-.1.015-.171a.25.25 0 00-.265-.279z\"/><path d=\"M19.791 9.519a.25.25 0 00.328-.1 1.866 1.866 0 00.256-.917V6.785a.249.249 0 00-.407-.193C18.03 8.169 14.38 9 10.876 9S3.72 8.168 1.783 6.59a.25.25 0 00-.408.194V8.5c0 2.21 4.255 4 9.5 4 .642 0 1.267-.028 1.873-.08a.25.25 0 00.213-.161A4.983 4.983 0 0117.625 9a4.9 4.9 0 012.166.519z\"/><ellipse cx=\"10.876\" cy=\"4.001\" rx=\"9.5\" ry=\"4\"/><path d=\"M21.339 15.514a.248.248 0 01-.214-.247v-1.018a3.5 3.5 0 00-7 0v1.018a.25.25 0 01-.215.247A1.5 1.5 0 0012.625 17v5.5a1.5 1.5 0 001.5 1.5h7a1.5 1.5 0 001.5-1.5V17a1.5 1.5 0 00-1.286-1.486zm-4.714 4.735a1 1 0 111 1 1 1 0 01-1-1zm-.5-6a1.5 1.5 0 013 0v1a.25.25 0 01-.25.25h-2.5a.249.249 0 01-.25-.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});