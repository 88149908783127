define("ember-svg-jar/inlined/vegetables-mushroom-soup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vegetables-mushroom-soup</title><circle cx=\"8.5\" cy=\"6\" r=\"1\"/><circle cx=\"12.5\" cy=\"4\" r=\"1\"/><circle cx=\"15\" cy=\"7\" r=\"1\"/><path d=\"M11.554 17a3.5 3.5 0 10-.107 7 1 1 0 001.015-.985l.007-.487a.5.5 0 01.5-.493l1.5.011a1.5 1.5 0 10.046-3l-1.5-.01a.5.5 0 01-.5-.508l.008-.512A1 1 0 0011.554 17z\"/><path d=\"M7.929 17l.09-.09a5.01 5.01 0 013.558-1.41 2.506 2.506 0 012.332 1.7.5.5 0 00.473.341c.252 0 .439.022.643.02a.5.5 0 00.364-.165.5.5 0 00.127-.378 5 5 0 016.608-5.135.5.5 0 00.605-.241A11.034 11.034 0 0024 6.5v-1C24 1.928 17.817 0 12 0S0 1.928 0 5.5v1a11.525 11.525 0 007.386 10.615.5.5 0 00.543-.115zM2 5.5C2 4.039 5.8 2 12 2s10 2.039 10 3.5S18.2 9 12 9 2 6.962 2 5.5z\"/><path d=\"M23.864 15.648a3.5 3.5 0 10-6.726 1.938 1 1 0 001.237.684l.469-.135a.5.5 0 01.617.338l.429 1.442a1.5 1.5 0 002.883-.831l-.426-1.433a.5.5 0 01.341-.623l.492-.142a1 1 0 00.684-1.238z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});