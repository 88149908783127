define("ember-svg-jar/inlined/smart-watch-circle-missed-call-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smart-watch-circle-missed-call-alternate</title><path d=\"M21.5 12A9.5 9.5 0 0017 3.927a.245.245 0 01-.109-.148L15.983.373A.5.5 0 0015.5 0h-7a.5.5 0 00-.484.371l-.908 3.408A.245.245 0 017 3.927a9.5 9.5 0 000 16.149.247.247 0 01.109.148l.908 3.406A.5.5 0 008.5 24h7a.5.5 0 00.482-.369l.935-3.427a.247.247 0 01.109-.146A9.5 9.5 0 0021.5 12zM12 19.5a7.5 7.5 0 117.5-7.5 7.509 7.509 0 01-7.5 7.5z\"/><path d=\"M13.945 13.709a1.529 1.529 0 00-1.085-.449 1.52 1.52 0 00-1.081.45l-.8.8a.249.249 0 01-.345.008 17.48 17.48 0 01-1.147-1.147.25.25 0 01.008-.346l.8-.8a1.532 1.532 0 000-2.166l-.832-.833a1.536 1.536 0 00-2.17 0l-.455.456a1.932 1.932 0 00-.245 2.418 18.962 18.962 0 005.305 5.3 1.934 1.934 0 002.422-.244l.455-.455a1.534 1.534 0 000-2.169zM15.445 7.5l-1.47 1.469a.249.249 0 01-.354 0l-.292-.293a.5.5 0 00-.854.354v2a.5.5 0 00.5.5h2a.5.5 0 00.354-.854l-.293-.293a.247.247 0 010-.353l1.469-1.47a.75.75 0 00-1.06-1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});