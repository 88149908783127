define("ember-svg-jar/inlined/wine-restaurant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>wine-restaurant</title><path d=\"M8 17.248a2 2 0 00-2-2H3.342a.5.5 0 01-.5-.445l-.848-7.666a1 1 0 10-1.988.221l.984 8.854-.974 5.357a1 1 0 001.968.357l.776-4.268a.5.5 0 01.492-.41H5.5a.5.5 0 01.5.5v4a1 1 0 002 0zM23.994 7.358a1 1 0 10-1.988-.221l-.851 7.663a.5.5 0 01-.5.445H18a2 2 0 00-2 2v4.5a1 1 0 102 0v-4a.5.5 0 01.5-.5h2.248a.5.5 0 01.492.41l.776 4.268a1.012 1.012 0 001.163.805 1 1 0 00.805-1.162l-.974-5.357z\"/><path d=\"M19 12.248a1 1 0 000-2H5a1 1 0 100 2h5.5a.5.5 0 01.5.5v9a1 1 0 102 0v-9a.5.5 0 01.5-.5zM14 9.248h3a.5.5 0 00.5-.5v-2.6a2.971 2.971 0 00-.454-1.58 3.18 3.18 0 01-.546-1.674V1.748a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v1.1a2.978 2.978 0 01-.454 1.58A3.178 3.178 0 0013.5 6.1v2.648a.5.5 0 00.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});