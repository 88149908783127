define("ember-svg-jar/inlined/arrow-button-left-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-left-3</title><path d=\"M21.766 22.292l-10.08-10.1a.25.25 0 010-.353L21.764 1.706A1 1 0 0021.055 0h-7.162a1 1 0 00-.709.3L2.23 11.31a1 1 0 000 1.412l10.963 10.984A1 1 0 0013.9 24h7.159a1 1 0 00.707-1.707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});