define("ember-svg-jar/inlined/search-bar-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>search-bar-1</title><path d=\"M21.239 20.146a.249.249 0 01-.03-.316 5.05 5.05 0 10-1.381 1.381.25.25 0 01.316.03l2.535 2.534a.784.784 0 001.1 0 .774.774 0 000-1.094zm-4.206.373a3.484 3.484 0 113.484-3.484 3.488 3.488 0 01-3.484 3.484z\"/><path d=\"M10.5 17.241a.249.249 0 00-.245-.241H6.5a4.5 4.5 0 010-9h10a4.506 4.506 0 014.436 3.744.25.25 0 00.092.154 6.564 6.564 0 011.451 1.6.25.25 0 00.458-.1A6.4 6.4 0 0023 12.5 6.508 6.508 0 0016.5 6h-10a6.5 6.5 0 000 13h3.979a.25.25 0 00.242-.315 6.538 6.538 0 01-.221-1.444z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});