define("ember-svg-jar/inlined/desktop-computer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>desktop-computer-1</title><path d=\"M12.359 19.014a.172.172 0 01-.008-.174.169.169 0 01.149-.089 1 1 0 000-2h-10a.5.5 0 01-.5-.5v-11.5a.5.5 0 01.5-.5H19a.5.5 0 01.5.5v.5a1 1 0 102 0v-1a2 2 0 00-2-2H2a2 2 0 00-2 2v12.5a2 2 0 002 2h6.349a.25.25 0 01.208.388l-1.223 1.834a.5.5 0 00.416.778h5.5a.5.5 0 00.416-.778z\"/><path d=\"M23 7.751h-7a1 1 0 00-1 1v12a1 1 0 001 1h7a1 1 0 001-1v-12a1 1 0 00-1-1zM22 11h-2a.75.75 0 010-1.5h2a.75.75 0 010 1.5zm.25 7.75a.75.75 0 01-.75.75h-4a.75.75 0 010-1.5h4a.75.75 0 01.75.751z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});