define("ember-svg-jar/inlined/insect-earth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>insect-earth</title><path d=\"M22.5 0H8a.5.5 0 00-.474.342A11.291 11.291 0 007 3.5a.5.5 0 00.621.485c1.692-.424 8.032-.071 9.732 3.776.2.46.674.239.9.239 1.792 0 3.25 2.019 3.25 4.5a5.2 5.2 0 01-.974 3.192 1.8 1.8 0 00-.329 2.02.5.5 0 00.411.259 2 2 0 11-2.111 1.987 1.97 1.97 0 01.446-1.24.5.5 0 00.108-.348c-.061-.873-.792-1.218-1.5-1.552C16.417 16.281 15 15.613 15 12.5a5.345 5.345 0 011.053-3.294.5.5 0 00.032-.57 6.426 6.426 0 00-8.309-2.583.5.5 0 00-.276.447 8.6 8.6 0 00.565 2.966.5.5 0 00.311.3A6.481 6.481 0 0112.5 16c0 3.584-2.58 6.5-5.75 6.5S1 19.584 1 16a6.534 6.534 0 013.783-6.1.49.49 0 00.265-.255A9.127 9.127 0 006 6 12.344 12.344 0 004.429.243.5.5 0 004 0H1.5A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0z\"/><path d=\"M17.96 11.49l.251-.753a.749.749 0 10-1.422-.474l-.251.753a.749.749 0 101.422.474zM20.024 14.023a.749.749 0 000-1.06l-.494-.493a.75.75 0 10-1.06 1.06l.493.493a.75.75 0 001.061 0zM5.971 12.279l.672 1.421a.9.9 0 00-.092.159.98.98 0 00.025.664.586.586 0 00-.26.068c-.01 0-.242.063-.629-.336a.75.75 0 10-1.078 1.043 2.392 2.392 0 001.514.788.743.743 0 00.038.561l-.507-.259c-.058-.029-1.43-.7-2.762 1.145a.75.75 0 101.216.877c.439-.608.784-.7.863-.686l.292.149a2.934 2.934 0 00-.22.491c-.307.916-.131 1.8.393 1.977s1.2-.426 1.5-1.342a3.087 3.087 0 00.12-.525l.254.045c.11.042.318.3.324.96a.75.75 0 00.75.743H8.4a.75.75 0 00.743-.757c-.015-1.693-.955-2.312-1.506-2.41l-.44-.079a.745.745 0 00.341-.394 2.138 2.138 0 001.1.279 3.868 3.868 0 001.264-.236A.75.75 0 009.4 15.2c-.771.268-1.131.13-1.186.069a.732.732 0 00-.212-.25.983.983 0 00.442-.532.893.893 0 00.022-.178l1.484-.659a.75.75 0 00-.609-1.371l-1.414.628-.6-1.272a.75.75 0 10-1.355.642z\"/><circle cx=\"20.75\" cy=\"19.708\" r=\".75\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});