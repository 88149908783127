define("ember-svg-jar/inlined/global-warming-high-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>global-warming-high-temperature</title><path d=\"M14.75 0a9.278 9.278 0 00-4.312 1.064 1 1 0 00.934 1.769 7.142 7.142 0 012.217-.741.506.506 0 01.38.094.5.5 0 01.2.339 1.5 1.5 0 00.774 1.138l1.758.954a.5.5 0 01.2.678l-.293.538a1.5 1.5 0 001.057 2.193l1.035.184a1.468 1.468 0 00.263.023 1.5 1.5 0 001.317-.784l.64-1.179a.25.25 0 01.447.017A7.249 7.249 0 0117 16.142a.5.5 0 01-.5-.112l-2.389-2.262a.8.8 0 01-.154-.965 1.5 1.5 0 00-.6-2.033L11.6 9.815a1.492 1.492 0 00-1.142-.12.64.64 0 00-.457.612v4.22a.994.994 0 00.2.6 6.476 6.476 0 011.087 2.242 1.007 1.007 0 00.665.7A9.25 9.25 0 1014.75 0zM8.5 15.058V3.5a3.5 3.5 0 00-7 0v11.558a1 1 0 01-.25.661A4.993 4.993 0 1010 19a4.963 4.963 0 00-1.25-3.281 1 1 0 01-.25-.661zM5 21a2 2 0 01-1.2-3.6.5.5 0 00.2-.4V8.5a1 1 0 012 0V17a.5.5 0 00.2.4A2 2 0 015 21z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});