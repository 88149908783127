define("ember-svg-jar/inlined/multiple-users-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-users-2</title><path d=\"M13 13.25v-2a1 1 0 00-2 0v2a1 1 0 002 0zM8.6 10.45a1 1 0 00-1.4.2l-1.5 2a1 1 0 101.6 1.2l1.5-2a1 1 0 00-.2-1.4zM18.1 14.05a1 1 0 00.2-1.4l-1.5-2a1 1 0 10-1.6 1.2l1.5 2a1 1 0 001.4.2zM3.5 20.152a2.2 2.2 0 10-2.2-2.2 2.2 2.2 0 002.2 2.2zM3.5 20.952a3.592 3.592 0 00-3.41 2.473.255.255 0 00.246.325h6.33a.254.254 0 00.246-.325A3.592 3.592 0 003.5 20.952z\"/><circle cx=\"20.499\" cy=\"17.949\" r=\"2.199\"/><path d=\"M20.5 20.947a3.592 3.592 0 00-3.411 2.473.254.254 0 00.246.325h6.33a.255.255 0 00.246-.325 3.592 3.592 0 00-3.411-2.473z\"/><circle cx=\"12\" cy=\"17.949\" r=\"2.199\"/><path d=\"M12 20.947a3.592 3.592 0 00-3.411 2.473.255.255 0 00.246.325h6.33a.255.255 0 00.246-.325A3.592 3.592 0 0012 20.947z\"/><circle cx=\"12.003\" cy=\"2.449\" r=\"2.199\"/><path d=\"M12 5.447A3.592 3.592 0 008.592 7.92a.255.255 0 00.246.325h6.33a.254.254 0 00.246-.325A3.591 3.591 0 0012 5.447z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});