define("ember-svg-jar/inlined/style-three-pin-perfume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-perfume</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.747 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zM9 7.707a1 1 0 010-1.414L11.793 3.5a1 1 0 011.414 0L15.5 5.793a1 1 0 010 1.414l-1.147 1.147A.5.5 0 0114 8.5h-4a.5.5 0 01-.353-.146zm8.5 6.793A1.5 1.5 0 0116 16H8a1.5 1.5 0 01-1.5-1.5V11A1.5 1.5 0 018 9.5h8a1.5 1.5 0 011.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});