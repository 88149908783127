define("ember-svg-jar/inlined/arrow-button-circle-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>arrow-button-circle-left</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm4 17.138a.5.5 0 01-.748.435l-8.996-5.139a.5.5 0 010-.868l8.992-5.139a.5.5 0 01.752.435z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});