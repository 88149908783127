define("ember-svg-jar/inlined/e-commerce-etsy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>e-commerce-etsy</title><path d=\"M21.72 19.83a3 3 0 00.15-2.44 1.86 1.86 0 00-1.36-.81.5.5 0 00-.43.12 2.27 2.27 0 00-.73 1.81 2.26 2.26 0 01-.2 1c-.43.85-2 1.38-4.65 1.59-3.14.24-4.92 0-5.28-.67A5.62 5.62 0 019 17.76V16.6v-1.06a11.15 11.15 0 01.13-2.61 21.21 21.21 0 002.43-.13c1.51-.14 3.08-.28 4 .27a1.4 1.4 0 01.57.95 2 2 0 001.29 1.55.5.5 0 00.29 0c1.1-.29.93-1.4.81-2.21 0-.24-.07-.5-.09-.74v-2.4c0-.2 0-.4.05-.6a2.37 2.37 0 00-.27-1.86 1.28 1.28 0 00-1-.4.62.62 0 00-.31.11 2.17 2.17 0 00-.76 1.61c-.06.48-.11.73-.39.93a2.9 2.9 0 01-1.17.26 25.83 25.83 0 01-5.48-.17 45.07 45.07 0 010-6.55C9.92 3 11.67 3 12.54 3c3.83 0 5.51.54 5.8 1a2.25 2.25 0 01.15.73c.06.56.15 1.32.87 1.49a1.06 1.06 0 001-.2c.55-.47.46-1.45.38-2.39a7.27 7.27 0 010-.9 3.87 3.87 0 01.09-.73 2.35 2.35 0 00.08-1.26.5.5 0 00-.08-.17A1.48 1.48 0 0019.14.1h-.19A89.73 89.73 0 018.09.4L4.67.37h-.32A1.79 1.79 0 002.47 1a.49.49 0 00-.06.17c-.24 1.3.9 1.52 1.51 1.64a1.74 1.74 0 01.84.29 5.36 5.36 0 01.47 1.71 42.07 42.07 0 010 4.57v2.83c0 .94 0 2 .05 3a35.13 35.13 0 010 4.51 5.92 5.92 0 01-.45 1.46 1.92 1.92 0 01-1 .28 2.16 2.16 0 00-1.57.6A1.51 1.51 0 002 23.23a.5.5 0 00.22.35 3.35 3.35 0 002.36.27c.26 0 .51-.06.76-.07 1.27-.06 2.71 0 4.23 0h4.76c1.57 0 3-.12 4.24 0a3.91 3.91 0 01.65.13 3.71 3.71 0 00.92.15 1.54 1.54 0 00.86-.25.48.48 0 00.12-.11 2.27 2.27 0 00.34-1.71 4.09 4.09 0 010-.81 7.31 7.31 0 01.26-1.35z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});