define("ember-svg-jar/inlined/cursor-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-type</title><path d=\"M22.75 18.248a1 1 0 00-1 1V21.5a.25.25 0 01-.25.25h-2.25a1 1 0 000 2h3.5a1 1 0 001-1v-3.5a1 1 0 00-1-1.002zM22.75 11.248a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM22.75 3.748h-3.5a1 1 0 000 2h2.25a.25.25 0 01.25.25v2.25a1 1 0 002 0v-3.5a1 1 0 00-1-1zM15.25 21.748h-3a1 1 0 000 2h3a1 1 0 100-2zM8.25 21.748H6a.25.25 0 01-.25-.25v-2.25a1 1 0 00-2 0v3.5a1 1 0 001 1h3.5a1 1 0 000-2zM4.75 16.248a1 1 0 001-1v-3a1 1 0 10-2 0v3a1 1 0 001 1zM12.25 5.748h3a1 1 0 000-2h-3a1 1 0 000 2zM9.146 4.078L1.7.353A1 1 0 00.355 1.7l3.726 7.444a1 1 0 001.865-.2l.561-2.249a.248.248 0 01.182-.182l2.252-.564a1 1 0 00.205-1.865zM19.25 20.248a1 1 0 000-2 1.5 1.5 0 01-1.5-1.5v-1.5h1a1 1 0 000-2h-1v-2.5a1.5 1.5 0 011.5-1.5 1 1 0 100-2 3.482 3.482 0 00-2.5 1.059 3.482 3.482 0 00-2.5-1.059 1 1 0 100 2 1.5 1.5 0 011.5 1.5v2.5h-1a1 1 0 000 2h1v1.5a1.5 1.5 0 01-1.5 1.5 1 1 0 000 2 3.482 3.482 0 002.5-1.059 3.482 3.482 0 002.5 1.059z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});