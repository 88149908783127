define("ember-svg-jar/inlined/cog-hand-give-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-hand-give-1</title><circle cx=\"16.5\" cy=\"7.532\" r=\"1.5\"/><path d=\"M21.28 7.845a.463.463 0 010-.625l.988-1.065a1.783 1.783 0 00-1.7-2.953l-1.411.327a.454.454 0 01-.533-.311L18.2 1.826a1.775 1.775 0 00-3.4 0l-.424 1.392a.455.455 0 01-.533.311l-1.41-.329a1.782 1.782 0 00-1.7 2.952l.987 1.068a.463.463 0 010 .625l-.988 1.065a1.782 1.782 0 001.7 2.952l1.411-.326a.454.454 0 01.533.311l.424 1.391a1.774 1.774 0 003.4 0l.424-1.392a.452.452 0 01.533-.311l1.411.326a1.74 1.74 0 001.937-.84 1.758 1.758 0 00-.236-2.112zm-4.78 2.687a3 3 0 113-3 3 3 0 01-3 3zM1 14.751a1 1 0 00-1 1V23a1 1 0 002 0v-7.25a1 1 0 00-1-.999zM15.5 19.5H6.375a.625.625 0 010-1.25H8.9a1 1 0 00.92-1.393A2.232 2.232 0 007.764 15.5H3.5a.5.5 0 00-.5.5v6.5a.5.5 0 00.5.5h10.764a2.238 2.238 0 002.236-2.235V20.5a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});