define("ember-svg-jar/inlined/multiple-actions-music.2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>multiple-actions-music</title><path d=\"M17.213 10.041a.25.25 0 00.078-.463 20.441 20.441 0 00-2.82-1.178l-.485-.173a1.8 1.8 0 01-.056-1.172 4.646 4.646 0 001.216-3.568A3.219 3.219 0 0012 0a2.986 2.986 0 00-1.341.314.25.25 0 00-.131.16.253.253 0 00.034.2 4.994 4.994 0 01.838 2.812 6.263 6.263 0 01-.922 3.624.251.251 0 00-.029.215.249.249 0 00.151.156c1.917.716 3.3 1.328 3.9 2.537.055.108.1.219.151.33a.249.249 0 00.306.142 3.278 3.278 0 01.4-.1z\"/><path d=\"M13.536 11.356a.251.251 0 00.06-.259 4.208 4.208 0 00-.214-.52c-.454-.907-1.9-1.44-3.911-2.178l-.485-.173a1.8 1.8 0 01-.056-1.172 4.646 4.646 0 001.216-3.568A3.219 3.219 0 007 0a3.219 3.219 0 00-3.145 3.486 4.613 4.613 0 001.192 3.538 1.877 1.877 0 010 1.185L4.53 8.4c-2.007.737-3.457 1.27-3.911 2.177A7.534 7.534 0 000 13.5a.5.5 0 00.5.5h11.75a.25.25 0 00.25-.244 3.488 3.488 0 011.036-2.4zM13.5 24a2.5 2.5 0 002.5-2.5v-7.463a.251.251 0 01.2-.246l5.5-1.031a.252.252 0 01.3.246v3.755a.252.252 0 01-.082.185.248.248 0 01-.191.064c-.075-.01-.151-.01-.227-.01a2.5 2.5 0 102.5 2.5v-6.795a2 2 0 00-2.369-1.966l-6 1.125A2 2 0 0014 13.83v4.931a.252.252 0 01-.082.185.248.248 0 01-.191.064c-.075-.01-.151-.01-.227-.01a2.5 2.5 0 000 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});