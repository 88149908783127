define("ember-svg-jar/inlined/server-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-share</title><path d=\"M0 3.484a2.5 2.5 0 002.5 2.5h17a2.5 2.5 0 002.5-2.5v-1a2.5 2.5 0 00-2.5-2.5h-17a2.5 2.5 0 00-2.5 2.5zm3.635-.5A1.115 1.115 0 114.75 4.1a1.115 1.115 0 01-1.115-1.116zm3.865 0A1.115 1.115 0 118.615 4.1 1.114 1.114 0 017.5 2.984zM21.25 9.515a4.21 4.21 0 01.75.076 2.506 2.506 0 00-2.5-2.607h-17a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h14.183a.5.5 0 00.481-.365 4.25 4.25 0 014.086-3.104zm-11.521.469a1.115 1.115 0 11-1.114-1.115 1.114 1.114 0 011.114 1.115zm-3.864 0A1.115 1.115 0 114.75 8.869a1.115 1.115 0 011.115 1.115zM2.5 13.984a2.5 2.5 0 00-2.5 2.5v1a2.5 2.5 0 002.5 2.5h6.663a.5.5 0 00.4-.2.506.506 0 00.086-.435 4.111 4.111 0 01-.149-1.084 4.256 4.256 0 012.328-3.79.248.248 0 00-.12-.466zm1.135 3A1.115 1.115 0 114.75 18.1a1.115 1.115 0 01-1.115-1.116z\"/><path d=\"M24 13.765a2.746 2.746 0 10-5.437.539.25.25 0 01-.116.268l-2.665 1.6a.248.248 0 01-.288-.022 2.752 2.752 0 10.311 3.925.252.252 0 01.281-.067l2.272.909a.25.25 0 01.156.246 2.758 2.758 0 10.681-1.706.25.25 0 01-.281.068l-2.264-.906a.251.251 0 01-.156-.253 2.1 2.1 0 00-.057-.638.252.252 0 01.116-.269l2.665-1.6a.25.25 0 01.288.021 2.72 2.72 0 001.744.634A2.752 2.752 0 0024 13.765z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});