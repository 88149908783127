define("ember-svg-jar/inlined/style-three-pin-shirt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>style-three-pin-shirt</title><path d=\"M12 0C6.21 0 1.5 4.262 1.5 9.5c0 5.748 7.3 12.285 9.54 14.152a1.5 1.5 0 001.921 0C15.2 21.784 22.5 15.246 22.5 9.5 22.5 4.262 17.79 0 12 0zm6.854 7.646a.5.5 0 010 .708l-2 2a.5.5 0 01-.708 0l-.219-.22a.25.25 0 00-.427.177V14a1 1 0 01-1 1h-5a1 1 0 01-1-1v-3.689a.25.25 0 00-.427-.177l-.219.22a.5.5 0 01-.708 0l-2-2a.5.5 0 010-.708l2.329-2.328a4.469 4.469 0 012.444-1.257.5.5 0 01.582.48 1.5 1.5 0 003 0 .5.5 0 01.582-.48 4.469 4.469 0 012.444 1.257z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});