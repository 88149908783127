define("ember-svg-jar/inlined/analytics-pie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-pie</title><path d=\"M19.7 5.515a1 1 0 001.539-1.277A12.018 12.018 0 0014.345.125a1 1 0 00-.389 1.962A10.014 10.014 0 0119.7 5.515zM15.37 21.314a10.09 10.09 0 01-6.691.017 1 1 0 10-.664 1.887 12.049 12.049 0 008.029-.021 1 1 0 00-.674-1.883zM22.737 9.3a1 1 0 00-.843 1.135A10.2 10.2 0 0122 11.9a10.018 10.018 0 01-2.236 6.3 1 1 0 001.552 1.262 12.019 12.019 0 002.556-9.32.992.992 0 00-1.135-.842zM0 11.957a12.023 12.023 0 002.723 7.551 1 1 0 101.545-1.27A10.009 10.009 0 012 11.947a1 1 0 00-2 .01zM7.9.614a12.058 12.058 0 00-6.193 5.109 1 1 0 001.715 1.03 10.049 10.049 0 015.163-4.259A1 1 0 007.9.614zM18.278 12.721a.246.246 0 00-.183-.1l-4.807-.371a.247.247 0 00-.208.085.256.256 0 00-.055.219l1.046 4.706a.25.25 0 00.337.178 6.515 6.515 0 003.91-4.509.244.244 0 00-.04-.208zM9.859 5.257a.248.248 0 00-.137.355L12.4 10.56a.244.244 0 00.2.129l5.608.434a.25.25 0 00.268-.27 6.5 6.5 0 00-8.618-5.6zM8.236 6.2a.255.255 0 00-.205.047A6.5 6.5 0 0012 17.9c.126 0 .252 0 .376-.011a.248.248 0 00.185-.1.251.251 0 00.045-.205L11.3 11.692a.248.248 0 00-.024-.064L8.4 6.327a.256.256 0 00-.164-.127z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});