define("ember-svg-jar/inlined/server-edit-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>server-edit-1</title><path d=\"M1 20a.75.75 0 000 1.5h1A.75.75 0 102 20zM0 2.5A2.5 2.5 0 002.5 5h13a2.5 2.5 0 100-5h-13A2.5 2.5 0 000 2.5zm3 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 2.5zm3.75 0A1.25 1.25 0 118 3.75 1.249 1.249 0 016.751 2.5zM0 8.5A2.5 2.5 0 002.5 11h13a2.5 2.5 0 100-5h-13A2.5 2.5 0 000 8.5zm3 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 8.5zm3.75 0A1.25 1.25 0 118 9.75 1.249 1.249 0 016.751 8.5zM0 14.5A2.5 2.5 0 002.5 17H8a.25.25 0 01.25.25v2.5A.25.25 0 018 20H5a.75.75 0 000 1.5h4.444a.251.251 0 00.25-.247l.054-4.007A.25.25 0 0110 17h1.968a.5.5 0 00.353-.147l3.952-3.953a.5.5 0 00-.244-.842A2.413 2.413 0 0015.5 12h-13A2.5 2.5 0 000 14.5zm3 0a1.25 1.25 0 111.25 1.25A1.249 1.249 0 013 14.5zm5 1.25a1.25 1.25 0 111.25-1.25A1.25 1.25 0 018 15.75zM12.739 20.046a.5.5 0 00-.479-.131.5.5 0 00-.355.346l-.886 3.1A.5.5 0 0011.5 24a.459.459 0 00.137-.02l3.1-.886a.5.5 0 00.216-.834zM13.054 18.239a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l5.011-5.011a.5.5 0 000-.707l-3-3a.5.5 0 00-.707 0zM22.479 15.521a.454.454 0 00.327.125.612.612 0 00.426-.172 2.621 2.621 0 10-3.753-3.66.5.5 0 000 .707z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});