define("ember-svg-jar/inlined/meeting-team-laptop-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>meeting-team-laptop-man-1</title><path d=\"M23.5 11.5h-.75a.249.249 0 01-.25-.25V2a2 2 0 00-2-2h-13a2 2 0 00-2 2v5.3a.25.25 0 00.077.18.245.245 0 00.183.07H6a6.008 6.008 0 011.2.121.248.248 0 00.208-.052.251.251 0 00.092-.195V2.5A.5.5 0 018 2h12a.5.5 0 01.5.5V11a.5.5 0 01-.5.5h-8.013a.25.25 0 00-.24.322 6.014 6.014 0 01.215 2.4.252.252 0 00.063.194.249.249 0 00.186.083H21.5A2.5 2.5 0 0024 12a.5.5 0 00-.5-.5z\"/><circle cx=\"14.294\" cy=\"4.624\" r=\"1.625\"/><path d=\"M12 9.5h4.588a.409.409 0 00.383-.555 2.844 2.844 0 00-5.353 0A.408.408 0 0012 9.5zM10.5 13.475a4.575 4.575 0 10-6.9 3.936.25.25 0 01-.02.44 6.326 6.326 0 00-1.021.611A6.245 6.245 0 000 23.5a.5.5 0 00.5.5H12a.5.5 0 00.5-.5 6.273 6.273 0 00-3.947-5.81.249.249 0 01-.049-.438 4.574 4.574 0 001.996-3.777zm-7.008-.845a.253.253 0 01.156-.154.248.248 0 01.218.028 5.9 5.9 0 003.251.971 5.971 5.971 0 001.226-.128.13.13 0 01.109.027.127.127 0 01.048.1 2.575 2.575 0 11-5.008-.845zM7 20.038v1.338a.75.75 0 01-1.5 0v-1.338a.75.75 0 011.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});