define("ember-svg-jar/inlined/cog-double", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-double</title><path d=\"M23.3 9.006a.509.509 0 00-.233-.3L22 8.086a4.932 4.932 0 000-2.17l1.051-.616a.5.5 0 00.181-.684l-1.014-1.737a.5.5 0 00-.678-.179l-1.105.625A3.613 3.613 0 0018.5 2.231V1a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v1.23a3.62 3.62 0 00-1.936 1.09L12.46 2.7a.5.5 0 00-.678.184l-1.014 1.733a.5.5 0 00.181.684l1.059.615a4.917 4.917 0 000 2.17l-1.07.616a.5.5 0 00-.183.684l1 1.738a.5.5 0 00.676.187l1.132-.629a3.627 3.627 0 001.937 1.09V13a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.228a3.62 3.62 0 001.936-1.09l1.133.629a.5.5 0 00.676-.187l1-1.738a.5.5 0 00.055-.38zM17 9.5A2.5 2.5 0 1119.5 7 2.5 2.5 0 0117 9.5zM13.065 18.7L12 18.086a4.932 4.932 0 000-2.17l1.059-.615a.5.5 0 00.181-.684l-1.014-1.738a.5.5 0 00-.678-.184l-1.1.625A3.613 3.613 0 008.5 12.231V11a.5.5 0 00-.5-.5H6a.5.5 0 00-.5.5v1.23a3.62 3.62 0 00-1.936 1.09L2.46 12.7a.5.5 0 00-.678.184L.768 14.617a.5.5 0 00.181.684l1.059.615a4.917 4.917 0 000 2.17L.935 18.7a.5.5 0 00-.183.684l1 1.738a.5.5 0 00.676.187l1.132-.629a3.627 3.627 0 001.94 1.092V23a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-1.228a3.62 3.62 0 001.936-1.09l1.133.629a.5.5 0 00.676-.187l1-1.738a.5.5 0 00-.183-.684zM7 19.5A2.5 2.5 0 119.5 17 2.5 2.5 0 017 19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});