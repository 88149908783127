define("ember-svg-jar/inlined/halloween-spider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>halloween-spider</title><path d=\"M23.53 10.4l-1.971-1.231a5.147 5.147 0 00-5.538.132.5.5 0 01-.524-.018 6.237 6.237 0 00-2.1-.883.5.5 0 01-.397-.489V1a1 1 0 00-2 0v6.911a.5.5 0 01-.393.489 6.237 6.237 0 00-2.1.883.5.5 0 01-.524.018 5.121 5.121 0 00-5.542-.131L.47 10.4a1 1 0 001.06 1.7l1.97-1.234a2.994 2.994 0 013.078-.061.286.286 0 01.106.393 5.235 5.235 0 00-.471 1.092.5.5 0 01-.507.355 4.61 4.61 0 00-3.269.972l-1.064.852a1 1 0 101.25 1.562l1.063-.852a2.691 2.691 0 012.036-.529.5.5 0 01.441.371 4.956 4.956 0 00.181.561.5.5 0 01-.294.651 5.294 5.294 0 00-2.514 2.055 1 1 0 101.631 1.156 3.066 3.066 0 012.137-1.481.8.8 0 00.524-.331 5.144 5.144 0 018.34 0 .8.8 0 00.524.331 3.066 3.066 0 012.137 1.481 1 1 0 101.631-1.156 5.29 5.29 0 00-2.514-2.055.5.5 0 01-.283-.264.5.5 0 01-.011-.387 4.956 4.956 0 00.181-.561.5.5 0 01.441-.371 2.691 2.691 0 012.035.529l1.064.852a1 1 0 001.25-1.562l-1.065-.852a4.608 4.608 0 00-3.268-.971.5.5 0 01-.507-.356 5.235 5.235 0 00-.471-1.092.286.286 0 01.106-.393 2.994 2.994 0 013.078.06L22.47 12.1a1 1 0 001.06-1.7z\"/><path d=\"M12 16.5a3.885 3.885 0 00-4 3.75A3.885 3.885 0 0012 24a3.885 3.885 0 004-3.75 3.885 3.885 0 00-4-3.75zm-1.5 4.75a1 1 0 111-1 1 1 0 01-1 1zm3 0a1 1 0 111-1 1 1 0 01-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});