define("ember-svg-jar/inlined/soft-drinks-can-coffee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>soft-drinks-can-coffee</title><path d=\"M7 6h10a1 1 0 000-2h-6.15a.5.5 0 01-.429-.243L9.358 1.985a1.006 1.006 0 00-1.372-.342 1 1 0 00-.344 1.372l.364.606A.25.25 0 017.792 4H7a1 1 0 000 2z\"/><circle cx=\"12.25\" cy=\".75\" r=\".75\"/><circle cx=\"14.75\" cy=\"2\" r=\".75\"/><circle cx=\"12.25\" cy=\"3\" r=\".75\"/><path d=\"M17.914 9.586l-1.765-1.924a.5.5 0 00-.369-.162H8.22a.5.5 0 00-.369.162L6.086 9.586A2 2 0 005.5 11v9.379a5.122 5.122 0 00.82 2.779A1.933 1.933 0 007.955 24h8.089a1.936 1.936 0 001.637-.843 5.118 5.118 0 00.819-2.778V11a2 2 0 00-.586-1.414zM12 19.25A4.25 4.25 0 1116.25 15 4.255 4.255 0 0112 19.25z\"/><path d=\"M14.278 15.75H9.722a.25.25 0 00-.229.35 2.73 2.73 0 005.014 0 .25.25 0 00-.229-.35zM12 12.25a2.738 2.738 0 00-2.507 1.65.25.25 0 00.229.35h4.556a.25.25 0 00.229-.35A2.738 2.738 0 0012 12.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});