define("ember-svg-jar/inlined/ranking-ribbon-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ranking-ribbon-1</title><path d=\"M7.859 10.506a.25.25 0 00.177-.16l.106-.3a.249.249 0 00-.069-.27A2.362 2.362 0 019.414 5.47h.235l.107-.335a2.357 2.357 0 014.489 0l.107.332h.234a2.362 2.362 0 011.341 4.3.252.252 0 00-.07.27l.107.3a.248.248 0 00.177.16 18.341 18.341 0 012.56.826.256.256 0 00.216-.013A3.148 3.148 0 0020.5 8.5a3.152 3.152 0 00-1.709-2.813 3.17 3.17 0 00-3.978-3.978 3.169 3.169 0 00-5.626 0 3.17 3.17 0 00-3.979 3.979A3.152 3.152 0 003.5 8.5a3.16 3.16 0 001.584 2.817.245.245 0 00.216.014 18.464 18.464 0 012.559-.825zM5.494 22.023a1.993 1.993 0 01-1.873-1.338l-.554-1.522a.248.248 0 00-.343-.139A5.866 5.866 0 01.5 19.5a.5.5 0 00-.257.929l2.1 1.261-.793 1.586A.5.5 0 002 24c.482 0 4.157 0 4.878-1.959a.251.251 0 00-.076-.279.249.249 0 00-.288-.021 1.965 1.965 0 01-1.02.282zM23.982 19.867a.5.5 0 00-.482-.367 5.866 5.866 0 01-2.224-.476.25.25 0 00-.343.139l-.554 1.522A2 2 0 0118.615 22a1.921 1.921 0 01-1.133-.28.25.25 0 00-.365.3C17.824 24.005 21.519 24 22 24a.5.5 0 00.447-.724l-.793-1.586 2.1-1.261a.5.5 0 00.228-.562zM18.053 20.225a.5.5 0 00.917-.053l1.722-4.736a1.493 1.493 0 00-.671-1.818 16.708 16.708 0 00-3.868-1.562.25.25 0 00-.289.131 2.349 2.349 0 01-3.483.871l-.236-.168a.249.249 0 00-.29 0l-.239.171a2.351 2.351 0 01-3.481-.874.248.248 0 00-.288-.131 16.708 16.708 0 00-3.868 1.562 1.5 1.5 0 00-.671 1.818l1.722 4.736a.5.5 0 00.916.055C6 20.116 7.4 17.5 12 17.5s6 2.616 6.053 2.725z\"/><path d=\"M9.442 10.841a.858.858 0 001.3 1l1.111-.794a.249.249 0 01.29 0l1.11.793a.858.858 0 001.3-1l-.474-1.262a.252.252 0 01.074-.28l.976-.813a.857.857 0 00-.548-1.516H13.44a.249.249 0 01-.24-.169l-.386-1.2a.857.857 0 00-1.632 0L10.8 6.8a.249.249 0 01-.238.174H9.414a.857.857 0 00-.549 1.516l.977.813a.252.252 0 01.074.28z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});