define("ember-svg-jar/inlined/phone-action-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>phone-action-upload</title><path d=\"M17 0H7a3 3 0 00-3 3v18a3 3 0 003 3h10a3 3 0 003-3V3a3 3 0 00-3-3zm1 19a.5.5 0 01-.5.5h-11A.5.5 0 016 19V3.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5z\"/><path d=\"M12 6.5a4.5 4.5 0 104.5 4.5A4.505 4.505 0 0012 6.5zm1.962 4.191A.5.5 0 0113.5 11h-.75a.25.25 0 00-.25.25V13a.5.5 0 01-1 0v-1.75a.25.25 0 00-.25-.25h-.75a.5.5 0 01-.354-.854l1.5-1.5a.5.5 0 01.708 0l1.5 1.5a.5.5 0 01.108.545z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});