define("ember-svg-jar/inlined/lab-tubes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lab-tubes</title><path d=\"M23 8a1 1 0 00-1 1v1.5a.5.5 0 01-.5.5H20a.5.5 0 01-.5-.5V1.4A1.4 1.4 0 0018.1 0h-3.2a1.4 1.4 0 00-1.4 1.4v9.1a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5V1.4A1.4 1.4 0 009.1 0H5.9a1.4 1.4 0 00-1.4 1.4v9.1a.5.5 0 01-.5.5H2.5a.5.5 0 01-.5-.5V9a1 1 0 00-2 0v10a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V9a1 1 0 00-1-1zm-7.5-5.5A.5.5 0 0116 2h1a.5.5 0 01.5.5V7a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5zm-9 0A.5.5 0 017 2h1a.5.5 0 01.5.5V7a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5zM19 22H5a3 3 0 01-3-3v-5.5a.5.5 0 01.5-.5H4a.5.5 0 01.5.5v3.355a3.092 3.092 0 002.7 3.1c.1.01.2.015.3.015a3 3 0 003-3V13.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v3.355a3.092 3.092 0 002.7 3.1c.1.01.2.015.3.015a3 3 0 003-3V13.5a.5.5 0 01.5-.5h1.5a.5.5 0 01.5.5V19a3 3 0 01-3 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});