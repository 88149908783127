define("ember-svg-jar/inlined/analytics-bars-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>analytics-bars-horizontal</title><path d=\"M23 22H2.5a.5.5 0 01-.5-.5V1a1 1 0 00-2 0v21.522A1.48 1.48 0 001.479 24H23a1 1 0 000-2z\"/><path d=\"M5.5 20H21a1.443 1.443 0 001.5-1.375v-1.75A1.443 1.443 0 0021 15.5H5.5A1.443 1.443 0 004 16.875v1.75A1.443 1.443 0 005.5 20zM4 11.625A1.443 1.443 0 005.5 13H15a1.443 1.443 0 001.5-1.375v-1.75A1.443 1.443 0 0015 8.5H5.5A1.443 1.443 0 004 9.875zM5.5 6H10a1.443 1.443 0 001.5-1.375v-1.75A1.443 1.443 0 0010 1.5H5.5A1.443 1.443 0 004 2.875v1.75A1.443 1.443 0 005.5 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});