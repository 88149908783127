define("ember-svg-jar/inlined/shopping-basket-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>shopping-basket-2</title><path d=\"M21.5 14.625h-19a.5.5 0 00-.49.6l1.281 6.4a2.354 2.354 0 002.475 1.5h12.468a2.354 2.354 0 002.475-1.5l1.281-6.4a.5.5 0 00-.49-.6zm-13.75 5.5v-2.5a.75.75 0 011.5 0v2.5a.75.75 0 01-1.5 0zm8.5 0a.75.75 0 01-1.5 0v-2.5a.75.75 0 011.5 0zM24 11.125a2 2 0 00-2-2h-1.858a.25.25 0 01-.229-.148l-3.27-7.359a1.251 1.251 0 00-2.286 1.015l2.73 6.141a.252.252 0 01-.019.238.249.249 0 01-.21.113H6.981a.25.25 0 01-.226-.356l2.876-6.111a1.25 1.25 0 00-2.262-1.065L3.892 8.982a.248.248 0 01-.226.143H2a2 2 0 000 4h20a2 2 0 002-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});