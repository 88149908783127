define("ember-svg-jar/inlined/lens-shutter-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lens-shutter-1</title><path d=\"M24 12C24 4.184 17.347.052 12 0a12 12 0 000 24 12.059 12.059 0 0012-12zM12.31 22a.248.248 0 01-.13-.031 6.574 6.574 0 01-3.043-3.937 8.922 8.922 0 01-.363-2.276.25.25 0 01.443-.166 11.572 11.572 0 006.109 3.854 9.5 9.5 0 002.186.262h.121a.249.249 0 01.148.453A9.933 9.933 0 0112.31 22zm-.62-19.99a.258.258 0 01.13.032 6.574 6.574 0 013.043 3.937 8.876 8.876 0 01.362 2.275.249.249 0 01-.442.167 11.566 11.566 0 00-6.109-3.859A9.435 9.435 0 006.366 4.3a.25.25 0 01-.149-.454 9.951 9.951 0 015.473-1.841zM22 12a9.936 9.936 0 01-1.193 4.733.249.249 0 01-.092.1 6.566 6.566 0 01-4.928.664 8.926 8.926 0 01-2.151-.824.25.25 0 01.077-.467 11.564 11.564 0 006.392-3.363 9.442 9.442 0 001.384-1.875.25.25 0 01.467.1c.029.303.044.616.044.932zM2 12a9.932 9.932 0 011.193-4.732.243.243 0 01.092-.1 6.576 6.576 0 014.928-.664 8.923 8.923 0 012.151.823.25.25 0 01-.077.467A11.566 11.566 0 003.9 11.162a9.466 9.466 0 00-1.384 1.874.25.25 0 01-.467-.1A8.5 8.5 0 012 12zm18.5-5.26a.255.255 0 01.038.129 6.571 6.571 0 01-1.89 4.6 8.907 8.907 0 01-1.788 1.451.252.252 0 01-.29-.019.249.249 0 01-.075-.281 11.564 11.564 0 00.283-7.22 9.41 9.41 0 00-.929-2.133.25.25 0 01.028-.3.253.253 0 01.291-.06A10.064 10.064 0 0120.5 6.74zm-17 10.521a.258.258 0 01-.038-.129 6.571 6.571 0 011.89-4.6 8.907 8.907 0 011.788-1.451.25.25 0 01.366.3 11.556 11.556 0 00-.284 7.219 9.41 9.41 0 00.929 2.133.25.25 0 01-.319.355A10.062 10.062 0 013.5 17.261z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});