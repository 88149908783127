define("ember-svg-jar/inlined/famous-character-wall-e", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>famous-character-wall-e</title><rect y=\"15\" width=\"4.5\" height=\"9\" rx=\"1\" ry=\"1\"/><rect x=\"19.5\" y=\"15\" width=\"4.5\" height=\"9\" rx=\"1\" ry=\"1\"/><circle cx=\"5.5\" cy=\"4\" r=\"1.25\"/><circle cx=\"18.5\" cy=\"4\" r=\"1.25\"/><path d=\"M17 11.5h-3.25a.5.5 0 01-.5-.5V5.971a.25.25 0 01.46-.135 6.936 6.936 0 005.807 3.131 4.484 4.484 0 000-8.967H4.483a4.484 4.484 0 000 8.967 6.936 6.936 0 005.807-3.131.25.25 0 01.46.135V11a.5.5 0 01-.5.5H7a1 1 0 00-1 1V21a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-8.5a1 1 0 00-1-1zm-2.239-9.318A.5.5 0 0115.146 2h4.371a2.484 2.484 0 010 4.967c-2.223 0-4.282-1.337-4.862-4.373a.5.5 0 01.106-.412zM4.483 6.967a2.484 2.484 0 010-4.967h4.371a.5.5 0 01.491.594C8.766 5.63 6.707 6.967 4.483 6.967z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});