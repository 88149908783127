define("ember-svg-jar/inlined/real-estate-market-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>real-estate-market-scale</title><path d=\"M19.35 10.245a.5.5 0 00-.651 0l-3.5 3a.5.5 0 00.325.88.5.5 0 01.5.5v2.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2.5a.5.5 0 01.5-.5.5.5 0 00.326-.88z\"/><path d=\"M23.42 10.433L20.241 2.67a.25.25 0 01.165-.335l.886-.246a1 1 0 00-.535-1.928l-18 5a1 1 0 00.266 1.964 1.042 1.042 0 00.269-.036.2.2 0 01.246.27C.781 14.582.524 14.968.524 16.125a4.5 4.5 0 009 0c0-1.15-.176-1.334-3.318-9.562a.25.25 0 01.167-.33l4.018-1.116a.5.5 0 01.633.481v15.777a.5.5 0 01-.5.5h-1.75a1 1 0 000 2h6.5a1 1 0 100-2h-1.75a.5.5 0 01-.5-.5V4.765a.5.5 0 01.367-.482l3.746-1.04a.25.25 0 01.3.335l-2.806 6.855a.75.75 0 101.386.567l2.776-6.78a.25.25 0 01.463 0L22.031 11a.751.751 0 101.389-.569zm-18.9 4.952l1.375.55a1.39 1.39 0 01-.359 2.674.015.015 0 00-.014.016.5.5 0 01-.933.25.5.5 0 00-.433-.25h-.132a.5.5 0 010-1H5.38a.4.4 0 00.146-.762l-1.375-.55a1.389 1.389 0 01.359-2.672.016.016 0 00.014-.016.5.5 0 01.934-.25.5.5 0 00.433.25h.133a.5.5 0 110 1H4.669a.394.394 0 00-.147.76zm1.947-3.935a.25.25 0 01-.3.33 4.289 4.289 0 00-2.287 0 .249.249 0 01-.3-.33l1.211-3.167a.25.25 0 01.467 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});