define("ember-svg-jar/inlined/controls-camera-stop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>controls-camera-stop</title><path d=\"M23.226 11.919a.251.251 0 00.359 0A1.485 1.485 0 0024 10.882V3.118a1.5 1.5 0 00-2.171-1.342l-4 2A1.491 1.491 0 0017 5.118v3.764a1.492 1.492 0 00.067.444.249.249 0 00.245.176h.188a7.979 7.979 0 015.726 2.417zM15.8 9.682a.25.25 0 00.2-.244V1.5A1.5 1.5 0 0014.5 0h-13A1.5 1.5 0 000 1.5v11A1.5 1.5 0 001.5 14h8.653a.252.252 0 00.223-.136A8.024 8.024 0 0115.8 9.682zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm2.5 8.5a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h4a.5.5 0 01.5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});