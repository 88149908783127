define("ember-svg-jar/inlined/3d-print-fail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>3d-print-fail</title><path d=\"M9.553 16.574a.248.248 0 00-.061-.2.253.253 0 00-.187-.084H2.25a.249.249 0 01-.25-.25V2.25A.25.25 0 012.25 2h6.023a.25.25 0 01.25.25v.818a.4.4 0 00.082.245L9.423 4.4a.409.409 0 00.654 0l.823-1.087a.4.4 0 00.082-.245V2.25a.25.25 0 01.25-.25h6.018a.25.25 0 01.25.25v7a.25.25 0 00.242.25 7.919 7.919 0 011.455.177.25.25 0 00.3-.245V1.75A1.752 1.752 0 0017.75 0h-16A1.752 1.752 0 000 1.75v16a1.752 1.752 0 001.75 1.75h7.687a.25.25 0 00.244-.3 8.07 8.07 0 01-.128-2.623z\"/><path d=\"M6.8 4.2a.75.75 0 00-1.061 1.06l1.027 1.02a.75.75 0 001.06-1.06zM12.7 4.2l-1.026 1.02a.75.75 0 001.06 1.06l1.024-1.023A.75.75 0 0012.7 4.2zM9.318 7.5l-3.5 6a.5.5 0 00.432.752h3.78a.249.249 0 00.226-.144 8.042 8.042 0 012-2.644.249.249 0 00.052-.315L10.182 7.5a.519.519 0 00-.864 0zM17.5 11a6.5 6.5 0 106.5 6.5 6.508 6.508 0 00-6.5-6.5zm1.237 6.323a.252.252 0 000 .354l1.414 1.414a.75.75 0 11-1.06 1.06l-1.414-1.414a.252.252 0 00-.354 0l-1.414 1.414a.75.75 0 11-1.06-1.06l1.414-1.414a.252.252 0 000-.354l-1.414-1.414a.75.75 0 011.06-1.06l1.414 1.414a.252.252 0 00.354 0l1.414-1.414a.75.75 0 111.06 1.06z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});