define("ember-svg-jar/inlined/transportation-ticket-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>transportation-ticket-plane</title><path d=\"M22.906 9.271A1.252 1.252 0 0024 8.031V5a1.752 1.752 0 00-1.75-1.75H1.75A1.752 1.752 0 000 5v3.031a1.253 1.253 0 001.094 1.24 2.751 2.751 0 010 5.458A1.252 1.252 0 000 15.969V19a1.752 1.752 0 001.75 1.75h20.5A1.752 1.752 0 0024 19v-3.031a1.252 1.252 0 00-1.094-1.24 2.751 2.751 0 010-5.458zm-15.536.8a.588.588 0 01-.233-.418.577.577 0 01.137-.423.518.518 0 01.1-.09l.887-.658a.581.581 0 01.545-.082l4.771 1.777a.252.252 0 00.229-.027l2.106-1.438a1.547 1.547 0 011.214-.232A1.619 1.619 0 0118.3 9.528a1.541 1.541 0 01.091.522 1.621 1.621 0 01-.754 1.394L9.779 16.32a.717.717 0 01-.385.112.731.731 0 01-.423-.135l-2.878-2.02a.513.513 0 01-.2-.356.508.508 0 01.107-.379.553.553 0 01.088-.087L7.284 12.4a.531.531 0 01.507-.091l1.279.758a.251.251 0 00.271-.01l1.033-.72a.25.25 0 00-.012-.418z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});