define("ember-svg-jar/inlined/pets-paw-heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>pets-paw-heart</title><path d=\"M23.332 5.114a6.337 6.337 0 00-10.068-1.631L12 4.634 10.751 3.5a6.271 6.271 0 00-5.476-1.741A6.225 6.225 0 00.668 5.114 6.16 6.16 0 001.842 12.3l9.451 9.732a.983.983 0 001.414 0l9.444-9.732a6.16 6.16 0 001.181-7.186zM8.247 9.474A1.247 1.247 0 117 10.72a1.247 1.247 0 011.247-1.246zm.969 4.9l1.822-2.487a1.193 1.193 0 011.923 0l1.824 2.5a1.229 1.229 0 01-.961 1.943h-3.648a1.218 1.218 0 01-1.063-.683 1.246 1.246 0 01.103-1.276zm1.523-6.143a1.246 1.246 0 111.246 1.246 1.246 1.246 0 01-1.246-1.249zm3.769 2.492a1.246 1.246 0 111.246 1.247 1.247 1.247 0 01-1.246-1.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});