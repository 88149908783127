define("ember-svg-jar/inlined/retouch-magic-wand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>retouch-magic-wand</title><path d=\"M15 16.583h.006c.91 0 1.236.33 1.244 1.257a.749.749 0 00.734.743H17a.749.749 0 00.749-.712c.054-1.069.512-1.288 1.234-1.288a.1.1 0 00.017 0 .75.75 0 00.016-1.5c-.936-.02-1.268-.344-1.266-1.24v-.007a.75.75 0 00-.75-.753.75.75 0 00-.75.748c0 .912-.333 1.239-1.261 1.252a.75.75 0 00.011 1.5zM.763 7C4.01 7 5.471 8.468 5.5 11.757a.75.75 0 00.734.743h.016a.749.749 0 00.75-.712C7.163 8.522 8.66 7 11.713 7h.037a.75.75 0 00.016-1.5C8.507 5.431 6.992 3.926 7 .764V.752A.749.749 0 006.253 0 .75.75 0 005.5.747C5.489 3.945 3.977 5.455.739 5.5A.75.75 0 00.75 7z\"/><path d=\"M12 0a11.943 11.943 0 00-1.2.06 1 1 0 10.2 1.99c.329-.033.664-.05 1-.05a10 10 0 11-4.823 18.757.25.25 0 01-.056-.4l4.379-4.374a.25.25 0 000-.354l-2.377-2.376a.25.25 0 00-.354 0L4.193 17.83a.223.223 0 01-.173.063.219.219 0 01-.16-.091A9.946 9.946 0 012 12q0-.457.041-.9a1 1 0 00-1.992-.184C.016 11.271 0 11.633 0 12A12 12 0 1012 0z\"/><path d=\"M15.081 12.4a1.288 1.288 0 000-1.82l-.91-.91a1.286 1.286 0 00-1.82 0L9.83 12.192a.25.25 0 000 .354l2.376 2.376a.25.25 0 00.354 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});