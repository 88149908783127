define("ember-svg-jar/inlined/gardening-cultivator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gardening-cultivator</title><path d=\"M23.707 13.807l-2.828-2.828a3.071 3.071 0 00-4.243 0l-1.767 1.767a.5.5 0 01-.707 0l-4.031-4.03a.5.5 0 01-.113-.536 2 2 0 00-.447-2.145l-5.3-5.3A2.5 2.5 0 00.733 4.266l5.3 5.305a2 2 0 002.145.446.5.5 0 01.535.113l4.031 4.03a.5.5 0 010 .708l-1.764 1.767a3 3 0 000 4.243l2.828 2.828a1 1 0 101.414-1.414l-1.061-1.06a.5.5 0 010-.707l.708-.708a.5.5 0 01.707 0l1.06 1.061a1 1 0 001.415-1.414L16.99 18.4a.5.5 0 010-.707l.707-.707a.5.5 0 01.707 0l1.061 1.06a1 1 0 001.414-1.414l-1.061-1.06a.5.5 0 010-.708l.708-.707a.5.5 0 01.707 0l1.06 1.061a1 1 0 001.414-1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});