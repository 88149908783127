define("ember-svg-jar/inlined/smiley-nasty-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>smiley-nasty-alternate</title><path d=\"M18.387 13.577a1 1 0 00-1.308.538 5.508 5.508 0 01-7.194 2.964 1 1 0 10-.77 1.846 7.508 7.508 0 009.81-4.04 1 1 0 00-.538-1.308zM9.5 10.5a1 1 0 00.5-1.868l-2.625-1.5a1 1 0 00-.992 1.736L9 10.368a.988.988 0 00.5.132zM14.5 10.5a.988.988 0 00.5-.132l2.625-1.5a1 1 0 10-.992-1.736L14 8.632a1 1 0 00.5 1.868z\"/><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});