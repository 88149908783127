define("ember-svg-jar/inlined/single-man-actions-edit-2.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>single-man-actions-edit-2</title><path d=\"M7 10a5 5 0 10-5-5 5.006 5.006 0 005 5zM4.1 4.258a.5.5 0 01.741-.3A6.959 6.959 0 008.46 5a6.224 6.224 0 00.929-.071.5.5 0 01.568.565A3 3 0 014 5a2.841 2.841 0 01.1-.742zM13.845 15.329a.5.5 0 00.1-.572A7.532 7.532 0 007.206 10.5a7.543 7.543 0 00-7.185 5.358.5.5 0 00.479.642h11.966a.5.5 0 00.353-.147zM23.228 11.765a2.62 2.62 0 00-3.7 0l-6.5 6.5a.5.5 0 000 .707l3 3a.5.5 0 00.707 0l6.5-6.5a2.621 2.621 0 00-.007-3.707zM12.393 20.462a.5.5 0 00-.844.255l-.537 2.683a.5.5 0 00.588.588l2.685-.537a.5.5 0 00.255-.844z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});