define("ember-svg-jar/inlined/task-list-pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>task-list-pen</title><rect x=\"20\" y=\"13.999\" width=\"4\" height=\"6.5\" rx=\".25\" ry=\".25\"/><path d=\"M23.75 13a.249.249 0 00.25-.25V7.5a2 2 0 00-3.886-.666.249.249 0 01-.235.166H19.5A2.5 2.5 0 0017 9.5V17a1 1 0 002 0V9.5a.5.5 0 01.5-.5h.25a.25.25 0 01.25.25v3.5a.249.249 0 00.25.25zM20.25 21.5a.25.25 0 00-.25.25V22a2 2 0 004 0v-.25a.25.25 0 00-.25-.25zM11.969 10.413a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.181.094.251.251 0 01-.191-.073l-.71-.71A.75.75 0 007.47 12.53l1.5 1.5a.748.748 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.751.751 0 00-.117-1.056zM11.969 15.913a.752.752 0 00-1.055.118l-1.3 1.627a.248.248 0 01-.181.094.251.251 0 01-.191-.073l-.71-.71A.75.75 0 007.47 18.03l1.5 1.5a.748.748 0 00.53.219h.041a.75.75 0 00.545-.28l2-2.5a.751.751 0 00-.117-1.056z\"/><path d=\"M17 22H5.25a.249.249 0 01-.25-.25V8.249A.25.25 0 015.25 8H15a1 1 0 000-2H7.872a.25.25 0 01-.228-.353 3.991 3.991 0 000-3.293A.251.251 0 017.873 2H17a2 2 0 012 2 1 1 0 002 0 4 4 0 00-4-4H4a4 4 0 00-4 4v14.5a1 1 0 002 0V4a2 2 0 112 2 1 1 0 00-1 1v16a1 1 0 001 1h13a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});