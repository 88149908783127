define("ember-svg-jar/inlined/cog-double-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cog-double-2</title><path d=\"M15.852 12.367l-1-1.732a1 1 0 00-1.366-.366l-.708.409a6.535 6.535 0 00-2.353-1.354V8.5a1 1 0 00-1-1h-2a1 1 0 00-1 1v.821a6.455 6.455 0 00-2.357 1.356l-.706-.408A1 1 0 002 10.635l-1 1.732a1 1 0 00.366 1.366l.712.412a6.46 6.46 0 000 2.713l-.712.411A1 1 0 001 18.635l1 1.732a1 1 0 001.366.366l.708-.409a6.508 6.508 0 002.355 1.355v.821a1 1 0 001 1h2a1 1 0 001-1v-.82a6.471 6.471 0 002.356-1.355l.705.408a1 1 0 001.366-.366l1-1.732a1 1 0 00-.366-1.366l-.711-.41a6.453 6.453 0 000-2.715l.711-.411a1 1 0 00.362-1.366zM8.425 19a3.5 3.5 0 113.5-3.5 3.5 3.5 0 01-3.5 3.5zM22.888 6.134l-.568-.328a3.452 3.452 0 00-.006-1.606l.574-.331a.5.5 0 00.183-.683l-.5-.867a.5.5 0 00-.683-.183l-.574.332a3.459 3.459 0 00-1.389-.81V1a.5.5 0 00-.5-.5h-1a.5.5 0 00-.5.5v.656a3.5 3.5 0 00-1.395.806l-.569-.329a.5.5 0 00-.683.183l-.5.867a.5.5 0 00.183.683l.567.327a3.461 3.461 0 000 1.61l-.572.33a.5.5 0 00-.183.683l.5.867a.5.5 0 00.683.183l.572-.331a3.462 3.462 0 001.392.812V9a.5.5 0 00.5.5h1a.5.5 0 00.5-.5v-.655a3.489 3.489 0 001.394-.807l.569.329a.5.5 0 00.683-.183l.5-.867a.5.5 0 00-.178-.683zm-3.963.366a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});