define("ember-svg-jar/inlined/cursor-choose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>cursor-choose</title><path d=\"M4.07 8.39A.994.994 0 004.8 8l3.907-5.21a1 1 0 00-1.6-1.2L3.892 5.878 1.707 3.694A1 1 0 00.293 5.108l3 3a1 1 0 00.777.282zM21.762 4.749l1.945-1.944a1 1 0 10-1.414-1.414l-1.945 1.944L18.4 1.391a1 1 0 10-1.414 1.414l1.944 1.944-1.941 1.945a1 1 0 000 1.414 1.011 1.011 0 001.414 0l1.945-1.945 1.945 1.945a1.013 1.013 0 001.414 0 1 1 0 000-1.414zM16.878 15.9H14V9.276a2.375 2.375 0 00-4.75 0v9.311l-1.339-1.879a2.156 2.156 0 00-2.8-.87 2.1 2.1 0 00-.87 2.8l2.1 3.992a.5.5 0 00.443.267H21a.5.5 0 00.5-.5v-1.649a4.862 4.862 0 00-4.622-4.848z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});