define("ember-svg-jar/inlined/messages-bubble-square-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-square-forward</title><path d=\"M17.308 13.516a.249.249 0 00.2-.22 2 2 0 013.235-1.361l.349.277a.25.25 0 00.406-.2V1.5A1.5 1.5 0 0020 0H1.5A1.5 1.5 0 000 1.5v12.053A1.459 1.459 0 001.5 15H3v3.5a.5.5 0 00.832.374L8.19 15h5.439a.249.249 0 00.14-.043 9.355 9.355 0 013.539-1.441z\"/><path d=\"M19.811 13.109a.492.492 0 00-.528-.059.5.5 0 00-.283.45v1.329c-7.906.334-8 8.586-8 8.671a.5.5 0 00.46.5h.04a.5.5 0 00.493-.419c.009-.05.852-4.782 7.007-5.026v1.763a.5.5 0 00.836.37l4-3.636a.5.5 0 00-.025-.761z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});