define("ember-svg-jar/inlined/golf-ball", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>golf-ball</title><path d=\"M16.472 15.719a8.647 8.647 0 01-8.945 0 1 1 0 00-1.054 1.7 10.461 10.461 0 004.1 1.472.5.5 0 01.432.5v3.619a1 1 0 102 0v-3.623a.5.5 0 01.432-.5 10.449 10.449 0 004.1-1.474 1 1 0 00-1.055-1.7zM12 15.49a7.75 7.75 0 10-7.75-7.75A7.75 7.75 0 0012 15.49zm3.5-2.98a1 1 0 111-1 1 1 0 01-1 1zm1.5-5a1 1 0 11-1 1 1 1 0 011-1zm-2-3a1 1 0 11-1 1 1 1 0 011-1zm-1.5 3a1 1 0 11-1 1 1 1 0 011-1zm-1.5 3.5a1 1 0 11-1 1 1 1 0 011-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});