define("ember-svg-jar/inlined/beacon-remote-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>beacon-remote-plane</title><path d=\"M10.647 15.433a.249.249 0 01-.32-.275l.685-4.64a1 1 0 00-1.3-1.1l-3.495 1.15a1 1 0 01-1.06-.289l-2.906-3.3a.25.25 0 01.109-.4l1.264-.414a.248.248 0 01.255.061l1.029 1.037a1 1 0 001.022.247L17.8 3.616a1.5 1.5 0 011.339.206.253.253 0 00.124.046 8.527 8.527 0 012.019.425.247.247 0 00.255-.057.25.25 0 00.065-.254l-.011-.034a3.5 3.5 0 00-4.414-2.232l-2.729.9a.248.248 0 01-.221-.032L11.05.36A2.01 2.01 0 009.281.1L7.5.686a2 2 0 00-.8 3.306l.583.588a.25.25 0 01-.1.414l-1.143.375a.251.251 0 01-.255-.062L4.756 4.269a1 1 0 00-1.022-.246L1.377 4.8A2 2 0 00.5 8.016L3.655 11.6a3 3 0 003.185.867l1.573-.516a.25.25 0 01.325.274l-.447 3.031A2.009 2.009 0 008.97 17.1a1.9 1.9 0 001.817.354l.535-.161a.249.249 0 00.178-.239v-1.543a.25.25 0 00-.323-.239zM8.4 2.873a.249.249 0 01.1-.413l1.283-.422a.252.252 0 01.221.033l1.533 1.072a.251.251 0 01-.065.443l-1.637.537a.251.251 0 01-.256-.062z\"/><path d=\"M13.551 7.379a1 1 0 001.414 1.414 5 5 0 017.07 0 1 1 0 001.414-1.414 7 7 0 00-9.9 0zM24 14.5a1.5 1.5 0 00-1.5-1.5h-8a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 001.5 1.5h8a1.5 1.5 0 001.5-1.5v-8zm-4.659 5.772a.248.248 0 00-.091.193v1.285a.75.75 0 01-1.5 0v-1.277a.249.249 0 00-.091-.193 1.277 1.277 0 01-.492-.989 1.333 1.333 0 112.666 0 1.267 1.267 0 01-.492.981zm2.409-.772a.75.75 0 01-.75-.75 2.5 2.5 0 00-5 0 .75.75 0 01-1.5 0 4 4 0 018 0 .75.75 0 01-.75.75z\"/><path d=\"M21.328 12a1 1 0 00.707-1.707 5 5 0 00-7.07 0 1 1 0 001.414 1.414 3.072 3.072 0 014.242 0 1 1 0 00.707.293z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});