define("ember-svg-jar/inlined/paralympics-tennis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>paralympics-tennis</title><path d=\"M22.9 12.258A7.017 7.017 0 0016.44 8h-6.385a4.851 4.851 0 01-2.909-1.025l-.619-1.118a3.474 3.474 0 00.222-3.92A3.263 3.263 0 002.427.332 3.285 3.285 0 001.5 4.849a3.49 3.49 0 003.239 1.908l.516.931a1.241 1.241 0 00.424 1.284A7.021 7.021 0 0010 10.5a.249.249 0 01.247.25v4.5a1 1 0 001 1h.5V17a.5.5 0 01-.5.5H8.491l.425-1.7a1.25 1.25 0 00-2.425-.6l-1.455 5.818a1.249 1.249 0 002.424.606l.531-2.124h3.256a.5.5 0 01.5.5v2.5a1.5 1.5 0 003 0V20a.5.5 0 01.5-.5H18.5l.531 2.124a1.249 1.249 0 002.424-.606L20 15.2a1.249 1.249 0 10-2.424.607L18 17.5h-2.753a.5.5 0 01-.5-.5v-.75h.5a1 1 0 001-1v-4.5a.25.25 0 01.25-.25h.417a4.084 4.084 0 013.686 2.732 1.249 1.249 0 102.3-.974zM3.252 3.878c-.388-.7-.32-1.539.146-1.8.423-.233 1.173.055 1.6.827.388.7.32 1.538-.146 1.8-.418.229-1.169-.049-1.6-.827z\"/><circle cx=\"13.248\" cy=\"4\" r=\"2.5\"/><circle cx=\"8.748\" cy=\"1.25\" r=\"1.25\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});