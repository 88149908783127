define("ember-svg-jar/inlined/fishing-lure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>fishing-lure</title><path d=\"M23.953.982a1 1 0 00-.922-.928C21.445-.067 16.491-.2 12.837 2.24a.5.5 0 00-.075.77l8.244 8.234a.5.5 0 00.772-.08C24.175 7.508 24.063 2.57 23.953.982zM22.06 6.45a1.5 1.5 0 110-2.12 1.5 1.5 0 010 2.12zM11.6 3.971a.5.5 0 00-.736.029c-2.692 3.214-2.94 7.877-2.879 10.153a1 1 0 01-.292.734L4.56 18.025A1.5 1.5 0 112.438 15.9l2.453-2.451a1 1 0 00-1.414-1.415l-2.453 2.455a3.5 3.5 0 002.092 5.95.5.5 0 01.441.442 3.5 3.5 0 005.952 2.094l2.453-2.453a1 1 0 10-1.414-1.414l-2.454 2.453a1.535 1.535 0 01-2.12 0 1.5 1.5 0 010-2.121l3.113-3.113a1 1 0 01.745-.292c3.542.133 7.529-.6 10.2-2.879a.5.5 0 00.029-.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});