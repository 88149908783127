define("ember-svg-jar/inlined/focus-m", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>focus-m</title><path d=\"M14.143 6.235l-1.929 3.214a.249.249 0 01-.428 0L9.857 6.235A1 1 0 008 6.75v10.5a1 1 0 002 0v-5.987a.25.25 0 01.464-.129l.679 1.131a1.039 1.039 0 001.714 0l.679-1.131a.25.25 0 01.464.129v5.987a1 1 0 002 0V6.75a1 1 0 00-1.857-.515zM4 1.5H2a2 2 0 00-2 2v2a1 1 0 002 0V4a.5.5 0 01.5-.5H4a1 1 0 000-2zM4 20.5H2.5A.5.5 0 012 20v-1.5a1 1 0 00-2 0v2a2 2 0 002 2h2a1 1 0 000-2zM22 1.5h-2a1 1 0 000 2h1.5a.5.5 0 01.5.5v1.5a1 1 0 002 0v-2a2 2 0 00-2-2zM23 17.5a1 1 0 00-1 1V20a.5.5 0 01-.5.5H20a1 1 0 000 2h2a2 2 0 002-2v-2a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});