define("ember-svg-jar/inlined/gesture-swipe-horizontal-left-two-fingers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>gesture-swipe-horizontal-left-two-fingers</title><path d=\"M23.7 7.454a1 1 0 00-.27-1.389 20.146 20.146 0 00-11.305-3.046 24.021 24.021 0 00-9.282 1.753.249.249 0 01-.322-.118l-.485-.97A.751.751 0 00.625 3.9L.136 6.83a.75.75 0 00.464.82l2.445.978A.75.75 0 003.992 7.6l-.352-.7a.252.252 0 01.128-.343 22.115 22.115 0 018.357-1.53 18.171 18.171 0 0110.19 2.705 1 1 0 001.385-.278z\"/><path d=\"M8.626 7.938a3 3 0 00-3 3v9.613a.429.429 0 00.429.428H11.2a.429.429 0 00.429-.428v-9.613a3 3 0 00-3.003-3zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0zM15.626 7.979a3 3 0 00-3 3v9.573a.429.429 0 00.429.428H18.2a.429.429 0 00.429-.428v-9.574a3 3 0 00-3.003-2.999zm1.5 4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-1a1.5 1.5 0 013 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});