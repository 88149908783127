define("ember-svg-jar/inlined/ecology-plant-hand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ecology-plant-hand</title><rect y=\"14.057\" width=\"4\" height=\"9\" rx=\"1\" ry=\"1\"/><path d=\"M23.853 18.266a3.236 3.236 0 00-3.49-1.127h-.015l-2 .639a.5.5 0 00-.348.484 2.25 2.25 0 01-2.249 2.291H10.5a.5.5 0 010-1h5.25a1.25 1.25 0 100-2.5l-3.685.008a4.339 4.339 0 01-.572-.383A6.836 6.836 0 007.5 15.057h-2a.5.5 0 00-.5.5v5.9a.5.5 0 00.339.474c.706.24 1.341.46 1.917.66C9.88 23.5 11.344 24 12.674 24c1.624 0 3.049-.743 6.139-2.387 1.281-.681 2.876-1.529 4.911-2.547a.5.5 0 00.129-.8zM8.659 11.25a2.377 2.377 0 00.317-.022 2.318 2.318 0 001-.395.5.5 0 00.163-.636A24.175 24.175 0 007.5 6.063a.5.5 0 11.785-.621c1.943 2.461 4.02 6.313 4.469 9.1a.75.75 0 00.687.634.515.515 0 00.055 0 .75.75 0 00.717-.53c.642-2.073 2.183-4.988 4.169-5.98a.5.5 0 01.447.895 6.761 6.761 0 00-2.466 2.689.5.5 0 00.232.709 2.078 2.078 0 00.436.136 2.172 2.172 0 00.4.037 3.442 3.442 0 002.391-1.194 7.3 7.3 0 00.954-6.72.5.5 0 00-.332-.276.506.506 0 00-.423.085 6.479 6.479 0 01-2.541 1.09 6.967 6.967 0 00-2.258.892 2.737 2.737 0 00-1.37 2.191 2.108 2.108 0 00.376 1.188.5.5 0 01.017.528c-.12.207-.232.412-.337.612a.249.249 0 01-.455-.028 20.277 20.277 0 00-1.1-2.444.5.5 0 01.127-.623 2.879 2.879 0 001.069-1.59A3.792 3.792 0 0012.4 3.572 6.674 6.674 0 008.916 1.71 8.865 8.865 0 015.526.118a.5.5 0 00-.417-.109.5.5 0 00-.347.258c-1.378 2.606-.73 7.352.778 9.13a4.406 4.406 0 003.119 1.853z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});