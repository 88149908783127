define("ember-svg-jar/inlined/conversation-question-warning-1-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>conversation-question-warning-1-alternate</title><path d=\"M6.421 18.083L4 19.208l1.19-2.376a1 1 0 00-.265-1.232A7.213 7.213 0 012 9.975C2 5.577 6.486 2 12 2c5.041 0 9.307 3.006 9.924 6.992a1 1 0 001.976-.306C23.134 3.734 18.018 0 12 0 5.383 0 0 4.475 0 9.975a9.01 9.01 0 003.057 6.658L.931 20.877a1 1 0 001.316 1.354L7.265 19.9a1 1 0 10-.844-1.813z\"/><path d=\"M22.166 20.132A5.971 5.971 0 0024 15.878c0-3.656-3.544-6.63-7.9-6.63s-7.9 2.974-7.9 6.63 3.544 6.629 7.9 6.629a9.33 9.33 0 002.331-.293l3.63 1.689a1 1 0 001.315-1.354zm-1.86-1.016a1 1 0 00-.27 1.229l.268.535-1.365-.635a.993.993 0 00-.713-.05 7.277 7.277 0 01-2.126.312c-3.253 0-5.9-2.077-5.9-4.629s2.647-4.63 5.9-4.63 5.9 2.077 5.9 4.63a4.162 4.162 0 01-1.694 3.238zM11.417 7.177a1.754 1.754 0 00-1.167 1.65V9a.75.75 0 001.5 0v-.173a.251.251 0 01.167-.236A2.75 2.75 0 108.25 6a.75.75 0 101.5 0 1.25 1.25 0 111.667 1.179z\"/><circle cx=\"16\" cy=\"17.632\" r=\".75\"/><path d=\"M16 12.632a.75.75 0 00-.75.75v2a.75.75 0 101.5 0v-2a.75.75 0 00-.75-.75z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});