define("ember-svg-jar/inlined/messages-bubble-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>messages-bubble-share</title><path d=\"M13.75 14a4.177 4.177 0 011.25.193.247.247 0 00.2-.024l1.752-1.051a.248.248 0 00.116-.167A4.258 4.258 0 0121.25 9.5a3.609 3.609 0 01.455.032.248.248 0 00.19-.054.253.253 0 00.092-.175c.007-.1.013-.2.013-.3 0-4.962-4.935-9-11-9S0 4.038 0 9a8.08 8.08 0 002.657 5.854L1.03 19.329a.5.5 0 00.662.632l5.832-2.429a13.09 13.09 0 001.757.348.25.25 0 00.28-.213A4.24 4.24 0 0113.75 14z\"/><path d=\"M24 21.25a2.739 2.739 0 00-4.916-1.674l-2.612-1.045c.009-.094.028-.185.028-.281a2.741 2.741 0 00-.105-.712l2.979-1.788a2.858 2.858 0 10-.769-1.288l-2.979 1.788a2.752 2.752 0 10.29 3.674l2.612 1.045c-.009.094-.028.185-.028.281a2.75 2.75 0 005.5 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});