define("ember-svg-jar/inlined/vr-controller-headphones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>vr-controller-headphones</title><path d=\"M22.025 6H22v-.05a6 6 0 00-12 0V6h-.025A1.977 1.977 0 008 7.975v1.05A1.977 1.977 0 009.975 11H11.5a.5.5 0 00.5-.5V5.95a4 4 0 018 0v4.55a.5.5 0 00.5.5v6.5a.5.5 0 01-1 0v-2A2.5 2.5 0 0017 13h-1a1 1 0 000 2h1a.5.5 0 01.5.5v2a2.5 2.5 0 005 0v-6.558A1.978 1.978 0 0024 9.025v-1.05A1.977 1.977 0 0022.025 6z\"/><path d=\"M.312 16.333a.25.25 0 00.272.023A2.98 2.98 0 012 16h10a2.98 2.98 0 011.416.356.25.25 0 00.362-.279c-.268-1.088-.793-3.2-.793-3.2a.5.5 0 00-.485-.377H11a.5.5 0 00-.354.146 2.892 2.892 0 01-2.06.854H5.414a2.892 2.892 0 01-2.06-.854A.5.5 0 003 12.5H1.5a.5.5 0 00-.485.379s-.525 2.11-.793 3.2a.251.251 0 00.09.254zM12 17H2a2 2 0 00-2 2v3a2 2 0 002 2h10a2 2 0 002-2v-3a2 2 0 00-2-2zm-8 5a1.5 1.5 0 111.5-1.5A1.5 1.5 0 014 22zm6 0a1.5 1.5 0 111.5-1.5A1.5 1.5 0 0110 22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});