define("ember-svg-jar/inlined/folder-home-alternate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>folder-home-alternate</title><path d=\"M23.823 17.618l-6.5-5.5a.5.5 0 00-.647 0l-6.5 5.5a.5.5 0 00.324.882h1.25a.25.25 0 01.25.25v4.75a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-2.25a1.5 1.5 0 013 0v2.25a.5.5 0 00.5.5h2.5a.5.5 0 00.5-.5v-4.75a.25.25 0 01.25-.25h1.25a.5.5 0 00.323-.882z\"/><path d=\"M19 5a.5.5 0 01.5.5v5.726a.252.252 0 00.088.191l1.5 1.269a.25.25 0 00.412-.191V5a2 2 0 00-2-2h-9.073a.5.5 0 01-.447-.276l-.81-1.619A2 2 0 007.382 0H2a2 2 0 00-2 2v14.5a2 2 0 002 2h5.767a.25.25 0 00.244-.282 2.5 2.5 0 01.276-1.364.245.245 0 00-.223-.354H2.5A.5.5 0 012 16V2.5a.5.5 0 01.5-.5h4.573a.5.5 0 01.447.276L8.33 3.9A2 2 0 0010.118 5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});