define("ember-svg-jar/inlined/developer-community-www", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>developer-community-www</title><path d=\"M20.38 10l3.45-5.5A1 1 0 0023 3h-6a1 1 0 00-1 .73l-3.54 12.6-2.5-9a1 1 0 00-.07-.25l-1-3.42A1 1 0 007 4.24l.91 3.27-2.43 8.79L2 3.7a1 1 0 10-1.93.54L4.52 20.3a1 1 0 001.93 0L9 11.23l2.5 9.07a1 1 0 001 .73 1 1 0 001-.73L17.74 5h3.43L18 10a1 1 0 00.71 1.52A4 4 0 0122 15.85c0 2-1.12 2.95-2.09 3.14s-2.18-.22-2.88-1.9a1 1 0 00-1.85.77 4.62 4.62 0 005.12 3.09c1.84-.37 3.69-2.09 3.69-5.1A6 6 0 0020.38 10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});