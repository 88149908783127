define("ember-svg-jar/inlined/heater-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>heater-1</title><path d=\"M21.006 8.462c.024-2.837.047-5.518-1.538-7.117A5.311 5.311 0 0015.5 0h-7a5.3 5.3 0 00-3.963 1.347C2.946 2.951 2.97 5.631 2.994 8.469L3 21.5A2.5 2.5 0 005.5 24h13a2.5 2.5 0 002.5-2.5zM6.512 6.25a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v1a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5zm2-3.75h7a2 2 0 011.415.585 1.959 1.959 0 01.32.425.5.5 0 01-.437.743H7.221a.5.5 0 01-.434-.753 1.982 1.982 0 011.724-1zM6.787 10a.5.5 0 01.434-.748H16.8a.5.5 0 01.434.749 1.985 1.985 0 01-1.724 1h-7A1.982 1.982 0 016.787 10zm10.725 11.75h-3.5a.75.75 0 010-1.5h3.5a.75.75 0 010 1.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});