define("ember-svg-jar/inlined/marine-mammal-dolphin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>marine-mammal-dolphin</title><path d=\"M2.262 12.574a19.753 19.753 0 012.475-1.481.5.5 0 01.622.147 5.756 5.756 0 003.071 2.171 1 1 0 001.17-1.368 7.165 7.165 0 01-.482-1.806.5.5 0 01.461-.571c3.128-.213 5.751 1.143 7.82 4.041a.5.5 0 01-.04.63 6.693 6.693 0 00-1.558 3.1.853.853 0 00.376.919.854.854 0 00.994-.045l1.977-1.538a.5.5 0 01.564-.034l2.433 1.46a.863.863 0 001.3-.869 5.809 5.809 0 00-2.284-3.678.506.506 0 01-.2-.384c-.2-6.51-4.25-9.291-6.363-10.3a.5.5 0 01-.259-.608 4.514 4.514 0 01.264-.636 1.006 1.006 0 00-.041-.989 1.022 1.022 0 00-.871-.468C9.68.36 8.082 1.074 7.2 2.147a1 1 0 01-.484.322 7.387 7.387 0 00-4.522 3.4 4.162 4.162 0 00-.388 3.116L.26 11.017a1.053 1.053 0 00.1 1.406 1.514 1.514 0 001.902.151zm3.989-6.659a.75.75 0 11-.75.75.75.75 0 01.75-.75zM23 21.736a3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.546.023 3.72 3.72 0 01-2.884 1.623 3.743 3.743 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024 3.72 3.72 0 01-2.884 1.622 3.745 3.745 0 01-2.9-1.646 1.055 1.055 0 00-1.547.024A3.718 3.718 0 011 21.736a1 1 0 000 2 5.121 5.121 0 003.345-1.243.5.5 0 01.64 0 5.128 5.128 0 006.694 0 .5.5 0 01.639 0 5.128 5.128 0 006.694 0 .5.5 0 01.64 0A5.131 5.131 0 0023 23.736a1 1 0 000-2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});