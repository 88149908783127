define("ember-svg-jar/inlined/couple-man-man-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>couple-man-man-1</title><path d=\"M14.854.018a2.821 2.821 0 00-2.495 1.021.47.47 0 01-.717 0A2.815 2.815 0 009.146.018 3.1 3.1 0 006.5 3.231c0 2.427 3.423 5.312 4.9 6.44a.981.981 0 001.208 0c1.472-1.127 4.9-4.013 4.9-6.44A3.1 3.1 0 0014.854.018zM6.847 13.47a.247.247 0 01-.119-.2.251.251 0 01.094-.211 2.918 2.918 0 10-3.616 0 .251.251 0 01.094.211.246.246 0 01-.118.2 3.515 3.515 0 00-1.682 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.413 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45h.6a.5.5 0 00.5-.5v-2.014a3.514 3.514 0 00-1.688-2.997zm-1.833-1.279A1.419 1.419 0 013.6 10.773a1.481 1.481 0 01.028-.285.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.251.251 0 01.273.3 1.421 1.421 0 01-1.4 1.141zM20.818 13.47a.246.246 0 01-.118-.2.251.251 0 01.094-.211 2.919 2.919 0 10-3.616 0 .251.251 0 01.094.211.247.247 0 01-.119.2 3.516 3.516 0 00-1.681 3v2.011a.5.5 0 00.5.5h.6a.5.5 0 01.5.45l.412 4.123a.5.5 0 00.5.45h2.01a.5.5 0 00.5-.45l.412-4.123a.5.5 0 01.5-.45H22a.5.5 0 00.5-.5v-2.014a3.515 3.515 0 00-1.682-2.997zm-1.832-1.279a1.42 1.42 0 01-1.419-1.418 1.406 1.406 0 01.029-.285.25.25 0 01.367-.168 3.576 3.576 0 001.746.452 3.453 3.453 0 00.4-.022.252.252 0 01.273.3 1.422 1.422 0 01-1.396 1.141z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});