define("ember-svg-jar/inlined/discount-parking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>discount-parking</title><path d=\"M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm0 22a10 10 0 1110-10 10.011 10.011 0 01-10 10z\"/><path d=\"M12 3.5a8.5 8.5 0 108.5 8.5A8.5 8.5 0 0012 3.5zm.75 9.5h-1a.25.25 0 00-.25.25v3.25a1 1 0 01-2 0v-9a1 1 0 011-1h2.25a3.25 3.25 0 010 6.5z\"/><path d=\"M12.75 8.5h-1a.25.25 0 00-.25.25v2a.25.25 0 00.25.25h1a1.25 1.25 0 000-2.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});