define("ember-svg-jar/inlined/car-key-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>car-key-1</title><path d=\"M9.5 9a4.482 4.482 0 004.032-2.5H19.5a.5.5 0 00.354-.146l1.5-1.5a.5.5 0 000-.708l-1.5-1.5A.5.5 0 0019.5 2.5h-5.968A4.5 4.5 0 109.5 9zm0-6.5a2 2 0 11-2 2 2 2 0 012-2zM18.889 16a1.278 1.278 0 01-1.2-.786l-.742-1.856A3.751 3.751 0 0013.469 11H9.14a2.5 2.5 0 00-2.452 2.011l-.356 1.783A1.5 1.5 0 014.861 16H3.5A2.5 2.5 0 001 18.5v2a1.5 1.5 0 001.214 1.473.252.252 0 00.2-.047.242.242 0 00.1-.178 3 3 0 015.98.01.259.259 0 00.246.242h6.52a.259.259 0 00.25-.242A3 3 0 0118.5 19a3.059 3.059 0 013.009 2.734.251.251 0 00.3.234A1.5 1.5 0 0023 20.5v-.354A4.151 4.151 0 0018.889 16zm-2.605-.073a.167.167 0 01-.139.073H13.5a1.5 1.5 0 01-1.5-1.5V13a.5.5 0 01.5-.5h.969a2.239 2.239 0 012.089 1.414l.743 1.857a.17.17 0 01-.017.156z\"/><circle cx=\"5.5\" cy=\"22\" r=\"2\"/><circle cx=\"18.5\" cy=\"22\" r=\"2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});